import { TFormSaveState } from "_react/shared/forms/_types";

export const FORM_NAVIGATION_ID = "form-navigation";

export const TEXT_DEBOUNCE_TIMEOUT = 600;

export const DEFAULT_SAVING_STATE: TFormSaveState = {
	saving: false,
	publishing: false
};
