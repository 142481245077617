import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useMachine } from "@xstate/react";
import { FixedSizeList as List } from "react-window";

import { createTableMachine } from "_react/table/machine";
import {
	ContainerPrintDiv,
	TableWrapperDiv,
	StyledHeader,
	StyledHeaderCell,
	TableBorderDiv,
	LoadMoreDiv,
	LoadMoreButtonStyle
} from "_react/table/styled";
import {
	TCheckboxProps,
	TColumn,
	TColumnTypes,
	isLkColumn,
	TMakeColumnOptParams,
	TSort,
	isDateColumn,
	TScrollTo,
	IMetaBase,
	IGet,
	IGetLk
} from "_react/table/_types";
import Row from "_react/table/Row";
import { useInfiniteScroll, usePager } from "_react/table/_hooks";
import { Button } from "_react/shared/legacy/ui/Button";
import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { useWindowSize } from "_react/_hooks";
export * from "_react/table/_types";

const toPascalCase = (str: string) => str.replace(/\w\S*/g, m => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase());

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function pluckValue<T extends { [key: string]: any }, M>(
	key: string,
	type: TColumnTypes | undefined
): ({ datum }: IGet<T, M>) => string | number {
	return ({ datum }: IGet<T, M>) => {
		const value = datum.hasOwnProperty(key) ? datum[key] : undefined;
		if (type === "number") return value ?? 0;
		return value ?? "";
	};
}

function lkGetValueDisplayDefault<T, M>({ lkItem }: IGetLk<T, M>): React.ReactChild {
	return lkItem.abbreviation ?? lkItem.label;
}

export function makeColumn<T, M>(params: TMakeColumnOptParams<T, M>): TColumn<T, M> {
	const getValue = params.getValue ?? pluckValue(params.key, params.type);
	const retColumn: TColumn<T> = {
		key: params.key,
		type: params.type ?? "text",
		label: params.label ?? toPascalCase(params.key),
		getValue: getValue,
		getValueDisplay: params.getValueDisplay ?? getValue,
		getValueSort: params.getValueSort ?? getValue,
		options: params.options ?? [],
		flex: params.flex ?? 1,
		allowWrapping: params.allowWrapping ?? false
	};
	if (isLkColumn(params)) {
		return {
			...retColumn,
			lkTable: params.lkTable,
			lkGetValueDisplay: params.lkGetValueDisplay ?? lkGetValueDisplayDefault,
			lkGetValueSort: ({ datum, lkItem, metadata }: IGetLk<T, M>) =>
				params.lkGetValueSort?.({ datum, lkItem, metadata }) ??
				pluckValue(params.key, params.type)({ datum, metadata })
		};
	}
	if (isDateColumn(params)) {
		return {
			...retColumn,
			dateFormat: params.dateFormat
		};
	}
	return retColumn;
}

export const TABLE_BORDER_SIZE = 1;

const LoadingRowDiv = styled.div<{ height: number }>(({ height }) => ({ height }));

function LoadingTableRow({ height }: { height: number }) {
	return <LoadingRowDiv className="loading-item" height={height} />;
}

function LoadingTable({ height = 35, rows = 10 }: { height?: number; rows?: number }) {
	return (
		<div className="loading-container">
			{[...Array(rows).keys()].map(rowIdx => (
				<LoadingTableRow key={rowIdx} height={height} />
			))}
		</div>
	);
}

export type TTableProps<T, M> = {
	checkbox?: TCheckboxProps<T>;
	columns: Array<TColumn<T>>;
	data: Array<T> | null;
	hoverStyle?: React.CSSProperties;
	getRowKey?: Function;
	handleRowClick?: (row: T) => void;
	maxHeight?: number;
	minWidth?: string | number;
	rowHeight?: number;
	sort: TSort;
	isLoading?: boolean;
	metadata?: M;
	loadingRowsNum?: number;
	loadingRowHeight?: number;
	styles?: { table: React.CSSProperties };
	scrollTo?: TScrollTo | null;
	isPrinting?: boolean;
	getRowStyle?: Function;
	showScroll?: boolean;
	lazyLoad?: boolean;
	usePaging?: boolean;
	pageSize?: number;
};

function Table<T, M extends IMetaBase = IMetaBase>({
	checkbox,
	columns,
	data: rawData,
	handleRowClick = () => null,
	hoverStyle = {},
	maxHeight,
	minWidth,
	rowHeight = 25,
	sort,
	isLoading = false,
	loadingRowHeight,
	loadingRowsNum,
	metadata,
	styles = { table: {} },
	scrollTo,
	isPrinting = false,
	getRowStyle = () => null,
	showScroll = true,
	lazyLoad = false,
	usePaging = false,
	pageSize = 50
}: TTableProps<T, M>) {
	loadingRowHeight = loadingRowHeight ? loadingRowHeight : rowHeight;
	const [current, send] = useMachine(createTableMachine<T, M>(), { context: { sort } });
	const listBottomRef = useRef<HTMLDivElement | null>(null);
	const listRef = useRef<List>(null);
	const listContainerRef = useRef<HTMLDivElement>(null);
	const [listMinWidth, setListMinWidth] = useState(minWidth);
	const windowSize = useWindowSize();
	const clientWidth = listContainerRef?.current?.clientWidth;
	useEffect(() => {
		// We don't need windowSize.width in this hook, but need a change in that to trigger re-running this
		if (clientWidth != null && clientWidth !== listMinWidth && windowSize.width != null) {
			setListMinWidth(listContainerRef?.current?.clientWidth);
		}
	}, [clientWidth, windowSize.width, listMinWidth]);
	const [page, setPage] = useState(0);
	const incrementPage = useCallback(() => setPage(page + 1), [page, setPage]);
	const dataPager = usePager<T>(page, pageSize, rawData ?? []);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const data = lazyLoad || usePaging ? dataPager : rawData ?? [];

	// Infinite Scroll
	const fullyLoaded = data.length >= (rawData ?? []).length;
	useInfiniteScroll(listBottomRef, page, incrementPage, fullyLoaded, true);

	useEffect(() => {
		if (scrollTo?.scrollIdx != null && listRef.current) {
			listRef.current.scrollToItem(scrollTo?.scrollIdx, scrollTo?.align);
		}
	}, [scrollTo]);

	useEffect(() => {
		send({ type: "SET_COLUMNS", data: columns });
	}, [columns, send]);

	useEffect(() => {
		if (metadata) send({ type: "SET_METADATA", data: metadata });
	}, [metadata, send]);

	useEffect(() => {
		send({ type: "SET_DATA", data });
	}, [data, send]);

	const handleSort = useCallback(
		(columnKey: string) => {
			send({ type: "SORT", data: columnKey });
		},
		[send]
	);

	const handleClick = useCallback(
		(e: React.MouseEvent, filteredRowIdx: number) => {
			e.stopPropagation();
			handleRowClick(current.context.data[filteredRowIdx].datum);
		},
		[handleRowClick, current.context.data]
	);

	if (isLoading) {
		return <LoadingTable height={loadingRowHeight} rows={loadingRowsNum} />;
	}

	const tableContents = (
		<TableBorderDiv maxHeight={maxHeight} ref={listContainerRef} showScrollBars={showScroll}>
			<TableWrapperDiv minWidth={minWidth ?? listMinWidth}>
				<tbody>
					<StyledHeader>
						{checkbox && <StyledHeaderCell flex={"0 1 25px"} rowHeight={rowHeight} sort={null} />}
						{columns.map(column => (
							<StyledHeaderCell
								data-cy={`header-${column.key}`}
								flex={column.flex}
								key={column.key}
								onClick={() => handleSort(column.key)}
								rowHeight={rowHeight}
								sort={current.context.sort.key === column.key ? current.context.sort.asc : null}
							>
								{column.label}
							</StyledHeaderCell>
						))}
					</StyledHeader>
					{current.context.data.map((dataPoint, idx) => (
						<Row<T>
							checkbox={checkbox}
							columns={columns}
							data={current.context.data}
							hoverStyle={hoverStyle}
							index={idx}
							// TODO: Change this to be an identifying item in the data (ex: enforce an id property)
							key={idx}
							handleClick={handleClick}
							rowHeight={rowHeight}
							style={getRowStyle(dataPoint.datum)}
						/>
					))}
				</tbody>
			</TableWrapperDiv>
			<LoadMoreDiv ref={listBottomRef} fullyLoaded={fullyLoaded} minWidth={listMinWidth}>
				<Button
					title={"Load More"}
					onClick={() => incrementPage()}
					style={LoadMoreButtonStyle}
					colorScheme={defaultColorScheme.secondary}
				/>
			</LoadMoreDiv>
		</TableBorderDiv>
	);

	if (isPrinting) {
		return (
			<ContainerPrintDiv data-cy="table-print" style={styles.table}>
				{tableContents}
			</ContainerPrintDiv>
		);
	}

	return tableContents;
}

export default Table;
