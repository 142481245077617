import React, { FC, useEffect, useState } from "react";

import axios from "_redux/_utils/_axios";
import { $TSFixMe } from "utils/tsutils";
import { Button } from "_react/shared/legacy/ui/Button";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { useHistory } from "react-router-dom";

type TPlayerRank = {
	listId: number;
	name: string;
	type: string;
	rank: number;
};

type ListRanksProps = {
	colorSchemeGroup?: ColorSchemeGroup;
	playerId: number;
};

// const SpacedContractsCard = styled.div`
// 	@media (min-width: 992px) {
// 		margin-right: -4px;
// 	}
// `;

function getNumberWithOrdinal(n: number) {
	const s = ["th", "st", "nd", "rd"],
		v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

function fetchListRanks(playerId: number) {
	return axios.get(`/list_new_player/ranks?playerId=${playerId}`).then(response => response.data);
}

export const ListRanks: FC<ListRanksProps> = ({ colorSchemeGroup = defaultColorScheme, playerId }) => {
	const history = useHistory();
	const [playerRanks, setPlayerRanks] = useState<$TSFixMe>(null);
	useEffect(() => {
		fetchListRanks(playerId).then(setPlayerRanks);
	}, [playerId]);

	return (
		<div style={{ backgroundColor: "white" }}>
			<div
				style={{
					color: "#727372",
					fontSize: "14px",
					fontWeight: 800,
					textAlign: "center",
					marginBottom: "5px"
				}}
			>
				My Lists
			</div>
			{playerRanks != null && playerRanks.length === 0 && (
				<div style={{ textAlign: "center" }}>Not in any list</div>
			)}
			{playerRanks != null && playerRanks.length > 0 && (
				<div style={{ textAlign: "center" }}>
					{playerRanks.map((playerRank: TPlayerRank) => (
						<div key={`${playerRank.listId}-${playerRank.rank}`} style={{ marginBottom: "5px" }}>
							<Button
								colorScheme={colorSchemeGroup.primary}
								onClick={() => history.push(`/list?listId=${playerRank.listId}`)}
								style={{ fontSize: "14px", margin: 0, padding: "3px" }}
								title={
									<span>
										<span
											style={{
												backgroundColor: colorSchemeGroup.secondary.color,
												color: colorSchemeGroup.secondary.text,
												margin: "2px",
												borderRadius: "2px",
												padding: "0px 2px",
												marginRight: "5px"
											}}
										>
											{getNumberWithOrdinal(playerRank.rank)}
										</span>
										<span>{playerRank.name}</span>
									</span>
								}
							/>
						</div>
					))}
				</div>
			)}
			{playerRanks == null && (
				<div>
					<div className="loading-item" style={{ height: "25px", marginBottom: "5px", width: "100%" }} />
					<div className="loading-item" style={{ height: "25px", marginBottom: "5px", width: "100%" }} />
					<div className="loading-item" style={{ height: "25px", marginBottom: "5px", width: "100%" }} />
				</div>
			)}
		</div>
	);
};
