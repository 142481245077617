import { round10 } from "_react/shared/_helpers/numbers";
import { _1B, ALL_POSITIONS, POSITION_SORT_ORDER } from "_react/shared/_constants/stats_player_fielding_internal";
import { getColorGradientFunctionBlueGreen } from "_react/shared/dataviz/_helpers";
import {
	VALUE_TYPE_NUMBER,
	VALUE_TYPE_OTHER,
	VALUE_TYPE_STRING
} from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType, TParentColumn } from "_react/shared/ui/presentation/components/Table/_types";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";
import { TOOLTIP_OAA } from "_react/shared/_constants/tooltips";

import {
	createPositionsToParentColumnsMapping,
	createPositionToColumnsMapping,
	getInningsFromStatsPlayerFielding,
	getOaaFromStatsPlayerFielding,
	getOpportunitiesFromStatsPlayerFielding,
	getPositionProportionFromStatsPlayerFielding,
	proportionColorGradientFunction
} from "_react/shared/ui/data/tables/FieldingOaaTable/_helpers";
import { TFieldingOaaRow, TFieldingOaaRowMobile } from "_react/shared/ui/data/tables/FieldingOaaTable/_types";

export const NULL_FILLER_TEXT = "";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

// Table Data Background Color
// One out saved (used for OAA) is roughly 0.7 runs (used for RAA)
export const OAA_TO_RAA_RATIO = 0.7;
// Use color gradient endpoints that correspond to an RAA scale of -50 to 50
export const OAA_COLOR_GRADIENT_MINIMUM = (50 / OAA_TO_RAA_RATIO) * -1;
export const OAA_COLOR_GRADIENT_MAXIMUM = 50 / OAA_TO_RAA_RATIO;

export const PROPORTION_UPPER_THRESHOLD = 0.5;

export const [COLOR_GRADIENT_FUNCTION_BLUE_GREEN] = getColorGradientFunctionBlueGreen(
	OAA_COLOR_GRADIENT_MINIMUM,
	OAA_COLOR_GRADIENT_MAXIMUM
);

// Cancel Sources
export const FIELDING_OAA_CANCEL_SOURCE = "fieldingOaa";
export const FIELDING_OAA_OPPS_CANCEL_SOURCE = "fieldingOaaOpps";

// Non-mobile columns
const SEASON_COLUMN_ID = "season";
const LEVEL_COLUMN_ID = "level";
const SEASON_COLUMN = {
	value: "season",
	id: SEASON_COLUMN_ID,
	label: "Season",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: false,
	getValueFunction: (row: TFieldingOaaRow) => row.season
};

const LEVEL_COLUMN = {
	value: "level",
	id: LEVEL_COLUMN_ID,
	label: "Level",
	valueType: VALUE_TYPE_OTHER as TValueType,
	isMobile: false,
	getValueFunction: (row: TFieldingOaaRow) => TeamLevelBadge({ level: row.level })
};

export const NON_MOBILE_COLUMNS = [SEASON_COLUMN, LEVEL_COLUMN];

export const POSITION_TO_COLUMN_MAPPING = createPositionToColumnsMapping(ALL_POSITIONS);

export const NON_MOBILE_PARENT_COLUMNS: Array<TParentColumn> = [
	{ label: "", id: "parentColumns", childColumnIds: [SEASON_COLUMN_ID, LEVEL_COLUMN_ID] },
	...createPositionsToParentColumnsMapping(ALL_POSITIONS)
];

// Mobile Columns
const SEASON_COLUMN_MOBILE = {
	value: "seasonMobile",
	label: "Season",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (row: TFieldingOaaRowMobile) => row.season
};

const LEVEL_COLUMN_MOBILE = {
	value: "levelMobile",
	label: "Level",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (row: TFieldingOaaRowMobile) => TeamLevelBadge({ level: row.level })
};

const POSITION_COLUMN_MOBILE = {
	value: "positionMobile",
	label: "Pos",
	valueType: VALUE_TYPE_STRING as TValueType,
	isMobile: true,
	getValueFunction: (row: TFieldingOaaRowMobile) => (row.position ?? "").toLocaleUpperCase(),
	getSortValueFunction: (row: TFieldingOaaRowMobile) => POSITION_SORT_ORDER[row.position]
};

const INNINGS_COLUMN_MOBILE = {
	value: `innMobile`,
	label: `Inn`,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (row: TFieldingOaaRowMobile) => {
		const innings = getInningsFromStatsPlayerFielding(row.fieldingOaa);
		return innings === NULL_FILLER_TEXT ? innings : round10(innings, -1);
	},
	getSortValueFunction: (row: TFieldingOaaRowMobile) => getInningsFromStatsPlayerFielding(row.fieldingOaa)
};

const OPP_COLUMN_MOBILE = {
	value: `oppMobile`,
	label: `Opp`,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (row: TFieldingOaaRowMobile) => {
		const opportunities: number | typeof NULL_FILLER_TEXT = getOpportunitiesFromStatsPlayerFielding(
			row.fieldingOaa,
			row.position === _1B
		);
		return opportunities === NULL_FILLER_TEXT ? opportunities : Math.round(opportunities).toString();
	},
	getSortValueFunction: (row: TFieldingOaaRowMobile) =>
		getOpportunitiesFromStatsPlayerFielding(row.fieldingOaa, row.position === _1B),
	getColorGradientValueFunction: (row: TFieldingOaaRowMobile): number | string => {
		return getPositionProportionFromStatsPlayerFielding(row.fieldingOaa, row.position === _1B) ?? "";
	},
	colorGradientFunction: proportionColorGradientFunction
};

const OAA_COLUMN_MOBILE = {
	value: `oaaMobile`,
	label: `OAA`,
	tooltip: TOOLTIP_OAA,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (row: TFieldingOaaRowMobile) => {
		const oaa: number | typeof NULL_FILLER_TEXT = getOaaFromStatsPlayerFielding(
			row.fieldingOaa,
			row.position === _1B
		);
		return oaa === NULL_FILLER_TEXT ? oaa : round10(oaa, -1);
	},
	getSortValueFunction: (row: TFieldingOaaRowMobile) =>
		getOaaFromStatsPlayerFielding(row.fieldingOaa, row.position === _1B),
	getColorGradientValueFunction: (row: TFieldingOaaRowMobile) => {
		const proportion: number | null = getPositionProportionFromStatsPlayerFielding(
			row.fieldingOaa,
			row.position === _1B
		);
		if (proportion !== null && proportion < 0.1) return null;
		return getOaaFromStatsPlayerFielding(row.fieldingOaa, row.position === _1B);
	},
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
};

export const MOBILE_COLUMNS = [
	SEASON_COLUMN_MOBILE,
	LEVEL_COLUMN_MOBILE,
	POSITION_COLUMN_MOBILE,
	INNINGS_COLUMN_MOBILE,
	OPP_COLUMN_MOBILE,
	OAA_COLUMN_MOBILE
];
