import React, { FunctionComponent, useRef, useState, Suspense, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/core";
import { useCookies } from "react-cookie";

import philliesLogo from "static/PhilliesLogo.svg";
import philliesLogoRetro from "static/PhilliesLogoRetro.png";
import {
	applySidebarIconStyle,
	applySidebarPinIconStyle,
	applySidebarPinnedIconStyle,
	SidebarNavText,
	SidebarListItemContents,
	SidebarListItem,
	SidebarMenuWrapper
} from "_react/shared/legacy/ui/Sidebar";
import { ILinkModal, ILinkSidebar, ELinkType, TLink } from "_react/app/_types";
import {
	PinnedButton,
	PinButton,
	ArrowButton,
	LogoContainer,
	LogoTagline,
	PinnedLinksContainer,
	SearchContainer,
	TooltipPinStyle,
	SearchFieldContainer,
	SearchTextFieldStyle,
	ClearSearchContainer,
	SearchMatchBold,
	SearchMatchNonBold,
	LogoStyle
} from "_react/app/_styles";
import { $TSFixMe } from "utils/tsutils";
import { defaultColorScheme, setRetroGlobal, retroGlobal } from "_react/shared/legacy/ui/Colors";
import { useOnClickOutside } from "_react/_hooks";
import Search from "_react/shared/legacy/ui/icons/Search";
import Cancel from "_react/shared/legacy/ui/icons/Cancel";
import Pin from "_react/shared/legacy/ui/icons/Pin";
import KeyboardArrowUp from "_react/shared/legacy/ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "_react/shared/legacy/ui/icons/KeyboardArrowDown";
import { TextField } from "_react/shared/legacy/ui/TextField";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import { SlideToggleContent } from "_react/shared/legacy/ui/SlideToggleContent";

type SidebarProps = {
	handleNavClose?: () => void;
	links?: $TSFixMe;
	show?: boolean;
	shouldAnimate?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function instanceOfILinkSidebar(object: any): object is ILinkSidebar {
	return "children" in object;
}

export const Sidebar: FunctionComponent<SidebarProps> = ({ handleNavClose = () => null, links, show = false }) => {
	const history = useHistory();
	const modalRef = useRef<$TSFixMe>(null);
	const [showModalBool, setShowModalBool] = useState<boolean>(false);
	const [isSearching, setIsSearching] = useState(false);
	const [searchText, setSearchText] = useState<string>();
	const [expanded, setExpanded] = useState<{ [index: string]: boolean }>({});

	const isMatch = useCallback(
		(linkText: string) => {
			const searchTextValue = searchText?.toLowerCase().replace(/\s/g, "");
			const linkTextValue = linkText.toLowerCase().replace(/\s/g, "");
			return searchTextValue != null && linkTextValue.includes(searchTextValue);
		},
		[searchText]
	);

	const _filterLinks = useCallback(
		(filteredLinks: (ILinkSidebar | ILinkModal)[], links: (ILinkSidebar | ILinkModal)[]) => {
			for (let i = 0; i < links.length; i++) {
				const link = { ...links[i] };
				let keep = isMatch(link.text);
				if (instanceOfILinkSidebar(link)) {
					if (link.children != null) {
						link.children = keep ? link.children! : _filterLinks([], link.children!);
						keep = keep || link.children!.length > 0;
					}
				}
				if (keep) filteredLinks.push(link);
			}
			return filteredLinks;
		},
		[isMatch]
	);

	const filteredLinks = useMemo(() => {
		const renderLinks = links.filter((link: TLink) => link.type !== "hidden");
		return isSearching && searchText ? _filterLinks([], renderLinks) : renderLinks;
	}, [searchText, isSearching, _filterLinks, links]);

	const _hasInnerMatches = (children: (ILinkSidebar | ILinkModal)[]) => {
		for (let i = 0; i < children.length; i++) {
			if (isMatch(children[i].text)) return true;
		}
		return false;
	};

	const hasInnerMatches = (link: ILinkSidebar | ILinkModal) => {
		const children = (link as ILinkSidebar).children;
		return _hasInnerMatches(children ?? []);
	};

	const PINNED_LINKS = "pinnedLinks";
	const [pinnedLinkCookies, setPinnedLinkCookies] = useCookies([PINNED_LINKS]);
	const pinnedLinks = useMemo(
		() => (pinnedLinkCookies[PINNED_LINKS] ? pinnedLinkCookies[PINNED_LINKS].split(",") : []),
		[pinnedLinkCookies]
	);

	const onStopSearching = () => {
		setIsSearching(false);
		setSearchText(undefined);
	};

	const onNavClose = () => {
		onStopSearching();
		handleNavClose();
	};

	const ref = useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, onNavClose);

	const showModal = (newModalContext: React.ReactNode) => {
		modalRef.current = newModalContext;
		setShowModalBool(true);
	};

	const hideModal = () => {
		modalRef.current = null;
		setShowModalBool(false);
	};

	const setPin = (link: ILinkModal | ILinkSidebar) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		if (pinnedLinks.includes(link.route)) {
			const newPinnedLinks = pinnedLinks.filter((route: string) => route !== link.route).join(",");
			setPinnedLinkCookies(PINNED_LINKS, newPinnedLinks);
		} else {
			const newPinnedLinks = [...pinnedLinks, link.route].join(",");
			setPinnedLinkCookies(PINNED_LINKS, newPinnedLinks);
		}
	};

	const _findPinnedLink = (links: (ILinkModal | ILinkSidebar)[], route: string): ILinkModal | ILinkSidebar | null => {
		for (let i = 0; i < links.length; i++) {
			if (links[i].route === route) return links[i];
			else if ((links[i] as ILinkSidebar).children != null) {
				const nestedMatch = _findPinnedLink((links[i] as ILinkSidebar).children!, route);
				if (nestedMatch != null) return nestedMatch;
			}
		}
		return null;
	};

	const findPinnedLink = (route: string) => {
		return _findPinnedLink(links, route);
	};

	const advanced_split = (text: string, delimiter: string) => {
		return text.split(new RegExp(`(${delimiter})`, "i"));
	};

	const boldText = (str: string, find: string) => {
		if (!str.toLowerCase().includes(find.toLowerCase()) || find === "") {
			return <SearchMatchNonBold>{str}</SearchMatchNonBold>;
		}
		const splitText = advanced_split(str, find);

		const components = [];
		for (let i = 0; i < splitText.length; i++) {
			if (splitText[i].toLowerCase() === find.toLowerCase()) {
				components.push(<SearchMatchBold key={i}>{splitText[i]}</SearchMatchBold>);
			} else {
				components.push(<SearchMatchNonBold key={i}>{splitText[i]}</SearchMatchNonBold>);
			}
		}
		return components;
	};

	const formatLinkText = (text: string) => {
		if (!isSearching || searchText == null) return text;
		return boldText(text, searchText ?? "");
	};

	const renderLink = (link: ILinkModal | ILinkSidebar, nestedLevel = 0) => {
		const Icon = applySidebarIconStyle(link.icon);
		const pinned = pinnedLinks.includes(link.route);
		const children = (link as ILinkSidebar).children;
		const hasChildren = children != null;
		const isExpanded = expanded[link.text] === true || (isSearching && searchText != null && hasInnerMatches(link));

		return (
			<React.Fragment key={link.text}>
				<SidebarListItem
					key={link.route ? link.route : link.text}
					onClick={() => {
						if (hasChildren) {
							setExpanded({ ...expanded, [link.text]: !isExpanded });
							return;
						} else if (link.type === ELinkType.modal) {
							showModal(link.comp);
						} else if (link.route) {
							history.push(link.route);
						}
						onNavClose();
					}}
					nestedLevel={nestedLevel}
				>
					<SidebarListItemContents>
						<Suspense fallback={""}>
							<Icon />
							<SidebarNavText>{formatLinkText(link.text)}</SidebarNavText>
							{!hasChildren && (
								<PinButton pinned={pinned} onClick={setPin(link)}>
									<PinIcon fill={pinned ? "blue" : ""} />
								</PinButton>
							)}
							{hasChildren && (
								<ArrowButton>{isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}</ArrowButton>
							)}
						</Suspense>
					</SidebarListItemContents>
				</SidebarListItem>
				{hasChildren && (
					<SlideToggleContent isVisible={isExpanded}>
						{children?.map(child => renderLink(child, nestedLevel + 1))}
					</SlideToggleContent>
				)}
			</React.Fragment>
		);
	};

	const SearchIcon = applySidebarPinnedIconStyle(Search);
	const CancelIcon = applySidebarPinnedIconStyle(Cancel);
	const PinIcon = applySidebarPinIconStyle(Pin);
	const ArrowDownIcon = applySidebarPinIconStyle(KeyboardArrowDown);
	const ArrowUpIcon = applySidebarPinIconStyle(KeyboardArrowUp);

	return (
		<React.Fragment>
			<div
				css={
					show
						? css`
								height: 100%;
								position: fixed;
								top: 0px;
								left: 0px;
								width: 100%;
								background-color: ${show ? "rgba(0, 0, 0, 0.79)" : ""};
								z-index: 1000;
								transition: all 0.15s ease-in-out;
						  `
						: ""
				}
			></div>
			<div ref={ref}>
				<SidebarMenuWrapper clickOutsideDisabled={true} show={show} onClose={onNavClose}>
					<LogoContainer onDoubleClick={() => setRetroGlobal(!retroGlobal)}>
						<img
							alt="PhilliesLogo"
							src={retroGlobal ? philliesLogoRetro : philliesLogo}
							style={LogoStyle}
						/>
						<LogoTagline retro={retroGlobal}>{retroGlobal ? "ROCKY" : "R O C K Y"}</LogoTagline>
					</LogoContainer>
					<PinnedLinksContainer>
						{!isSearching && (
							<React.Fragment>
								<PinnedButton isSearch={true} onClick={() => setIsSearching(true)}>
									<SearchIcon fill={"white"} />
									{pinnedLinks.length === 0 && <SearchContainer>Search</SearchContainer>}
								</PinnedButton>
								{pinnedLinks.map((pinnedLink: string) => {
									const pinnedLinkEntry = findPinnedLink(pinnedLink);
									if (pinnedLinkEntry) {
										const Icon = applySidebarPinnedIconStyle(pinnedLinkEntry.icon);
										return (
											<PinnedButton key={pinnedLinkEntry.text}>
												<Tooltip
													placement="top"
													style={TooltipPinStyle}
													title={pinnedLinkEntry.text}
													colorScheme={defaultColorScheme.tertiary}
												>
													<Icon
														fill={"white"}
														onClick={() => {
															if (pinnedLinkEntry.type === ELinkType.modal) {
																showModal(pinnedLinkEntry.comp);
															} else if (pinnedLinkEntry.route) {
																history.push(pinnedLinkEntry.route);
															}
															onNavClose();
														}}
													/>
												</Tooltip>
											</PinnedButton>
										);
									}
									return null;
								})}
							</React.Fragment>
						)}
						{isSearching && (
							<SearchFieldContainer>
								<TextField
									autofocus={isSearching}
									placeholder="Search..."
									value={searchText}
									onChange={e => setSearchText(e.target.value)}
									style={SearchTextFieldStyle}
								/>
								<ClearSearchContainer>
									<PinnedButton>
										<CancelIcon fill={"white"} onClick={() => onStopSearching()} />
									</PinnedButton>
								</ClearSearchContainer>
							</SearchFieldContainer>
						)}
					</PinnedLinksContainer>
					<ul>{filteredLinks.map((link: ILinkSidebar | ILinkModal) => renderLink(link))}</ul>
				</SidebarMenuWrapper>
				{showModalBool &&
					modalRef.current != null &&
					React.createElement(modalRef.current, {
						hideModal: hideModal
					})}
			</div>
		</React.Fragment>
	);
};
