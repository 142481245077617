import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { TPlayerClassification, PRO } from "utils/tsutils";
import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import {
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_POSTSEASON,
	BATS_R,
	BATS_L
} from "_react/shared/data_models/baseline_hit_probs/_constants";
import { PITCH_GROUP_OVERALL } from "_react/shared/_constants/pitch_types";
import { SCHEMA_TYPE_EXPANDED } from "_react/shared/data_models/arsenal_scores/_constants";
import {
	fetchPitchOutcomeObservedPitch,
	fetchPitchOutcomeObservedPitchByTeam
} from "_react/shared/data_models/pitch_outcome_observed/_network";
import {
	IPitchOutcomeObservedPitch,
	IPitchOutcomeObservedPitchByTeam
} from "_react/shared/data_models/pitch_outcome_observed/_types";
import {
	IPitchOutcomeProbabilitiesPitch,
	IPitchOutcomeProbabilitiesPitchByTeam
} from "_react/shared/data_models/pitch_outcome_probabilities/_types";
import {
	fetchPitchOutcomeProbabilitiesPitch,
	fetchPitchOutcomeProbabilitiesPitchByTeam
} from "_react/shared/data_models/pitch_outcome_probabilities/_network";

import { TPitcherPitchOutcomesTableData } from "_react/shared/ui/data/tables/PitcherPitchOutcomesTable/_types";

export const PLAYER_SEASON_POP_PITCH_CANCEL_SOURCE = "playerSeasonPopPitch";
export const PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE = "playerSeasonPopPitchByTeam";
export const PLAYER_SEASON_POO_PITCH_CANCEL_SOURCE = "playerSeasonPooPitch";
export const PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE = "playerSeasonPooPitchByTeam";

export type TPitcherPitchOutcomesTableCancelSource = {
	[PLAYER_SEASON_POP_PITCH_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_POO_PITCH_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPitcherPitchOutcomesTableFilters = {
	gameTypes: Array<string>;
	bats: Array<string>;
	pitchTypes?: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TPitcherPitchOutcomesTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TPitcherPitchOutcomesTableFilters;
	playerSeasonPopPitch?: Array<IPitchOutcomeProbabilitiesPitch> | null;
	playerSeasonPopPitchByTeam?: Array<IPitchOutcomeProbabilitiesPitchByTeam> | null;
	playerSeasonPooPitch?: Array<IPitchOutcomeObservedPitch> | null;
	playerSeasonPooPitchByTeam?: Array<IPitchOutcomeObservedPitchByTeam> | null;
	cancelSources: TPitcherPitchOutcomesTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPitcherPitchOutcomesTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				contextRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all PoP Pitch by player season
				playerSeasonPopPitch: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all PoP Pitch by player season team
				playerSeasonPopPitchByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all PoO Pitch by player season
				playerSeasonPooPitch: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all PoO Pitch by player season team
				playerSeasonPooPitchByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_PLAYER_SEASON_POP_PITCH = { initialized: { playerSeasonPopPitch: "fetching" } };
export const FETCHING_PLAYER_SEASON_POP_PITCH_BYTEAM = {
	initialized: { playerSeasonPopPitchByTeam: "fetching" }
};

export const FETCHING_PLAYER_SEASON_POO_PITCH = { initialized: { playerSeasonPooPitch: "fetching" } };
export const FETCHING_PLAYER_SEASON_POO_PITCH_BYTEAM = {
	initialized: { playerSeasonPooPitchByTeam: "fetching" }
};

const FETCH_PLAYER_SEASON_POP_PITCH_DONE = "done.invoke.fetchPlayerSeasonPopPitch:invocation[0]";
const FETCH_PLAYER_SEASON_POP_PITCH_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonPopPitchByTeam:invocation[0]";

const FETCH_PLAYER_SEASON_POO_PITCH_DONE = "done.invoke.fetchPlayerSeasonPooPitch:invocation[0]";
const FETCH_PLAYER_SEASON_POO_PITCH_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonPooPitchByTeam:invocation[0]";

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_SEASON_POP_PITCH = "SET_PLAYER_SEASON_POP_PITCH";
export const SET_PLAYER_SEASON_POP_PITCH_BYTEAM = "SET_PLAYER_SEASON_POP_PITCH_BYTEAM";
export const SET_PLAYER_SEASON_POO_PITCH = "SET_PLAYER_SEASON_POO_PITCH";
export const SET_PLAYER_SEASON_POO_PITCH_BYTEAM = "SET_PLAYER_SEASON_POO_PITCH_BYTEAM";
export const SET_FILTERS = "SET_FILTERS";

type TFetchPlayerSeasonPopPitchEvent = {
	type: typeof FETCH_PLAYER_SEASON_POP_PITCH_DONE;
	data: Array<IPitchOutcomeProbabilitiesPitch> | undefined;
};
type TFetchPlayerSeasonPopPitchByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_POP_PITCH_BYTEAM_DONE;
	data: Array<IPitchOutcomeProbabilitiesPitchByTeam> | undefined;
};

type TFetchPlayerSeasonPooPitchEvent = {
	type: typeof FETCH_PLAYER_SEASON_POO_PITCH_DONE;
	data: Array<IPitchOutcomeObservedPitch> | undefined;
};
type TFetchPlayerSeasonPooPitchByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_POO_PITCH_BYTEAM_DONE;
	data: Array<IPitchOutcomeObservedPitchByTeam> | undefined;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetPlayerSeasonPopPitchEvent = {
	type: typeof SET_PLAYER_SEASON_POP_PITCH;
	value?: Array<IPitchOutcomeProbabilitiesPitch> | null;
};
type TSetPlayerSeasonPopPitchByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_POP_PITCH_BYTEAM;
	value?: Array<IPitchOutcomeProbabilitiesPitchByTeam> | null;
};
type TSetPlayerSeasonPooPitchEvent = {
	type: typeof SET_PLAYER_SEASON_POO_PITCH;
	value?: Array<IPitchOutcomeObservedPitch> | null;
};
type TSetPlayerSeasonPooPitchByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_POO_PITCH_BYTEAM;
	value?: Array<IPitchOutcomeObservedPitchByTeam> | null;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TPitcherPitchOutcomesTableFilters;
};

type TPitcherPitchOutcomesTableEvent =
	| TFetchPlayerSeasonPopPitchEvent
	| TFetchPlayerSeasonPopPitchByTeamEvent
	| TFetchPlayerSeasonPooPitchEvent
	| TFetchPlayerSeasonPooPitchByTeamEvent
	| TSetPlayerIdEvent
	| TSetPlayerSeasonPopPitchEvent
	| TSetPlayerSeasonPopPitchByTeamEvent
	| TSetPlayerSeasonPooPitchEvent
	| TSetPlayerSeasonPooPitchByTeamEvent
	| TSetFiltersEvent;

export type TPitcherPitchOutcomesTableSend = Interpreter<
	TPitcherPitchOutcomesTableContext,
	IPitcherPitchOutcomesTableStateSchema,
	TPitcherPitchOutcomesTableEvent
>["send"];

const PitcherPitchOutcomesTableMachine = (
	playerIdProp?: number,
	playingLevel: TPlayerClassification = PRO,
	data?: TPitcherPitchOutcomesTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPitcherPitchOutcomesTableContext, IPitcherPitchOutcomesTableStateSchema, TPitcherPitchOutcomesTableEvent>(
		{
			id: "pitcherPitchOutcomesTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					gameTypes: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_POSTSEASON],
					bats: [BATS_L, BATS_R],
					pitchTypes: undefined,
					levels: undefined
				},
				playerSeasonPopPitch: data?.playerSeasonPopPitch,
				playerSeasonPopPitchByTeam: data?.playerSeasonPopPitchByTeam,
				playerSeasonPooPitch: data?.playerSeasonPooPitch,
				playerSeasonPooPitchByTeam: data?.playerSeasonPooPitchByTeam,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: { actions: "setPlayerId" },
						SET_PLAYER_SEASON_POP_PITCH: { actions: "setPlayerSeasonPopPitch" },
						SET_PLAYER_SEASON_POP_PITCH_BYTEAM: { actions: "setPlayerSeasonPopPitchByTeam" },
						SET_PLAYER_SEASON_POO_PITCH: { actions: "setPlayerSeasonPooPitch" },
						SET_PLAYER_SEASON_POO_PITCH_BYTEAM: { actions: "setPlayerSeasonPooPitchByTeam" },
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						contextRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						playerSeasonPopPitch: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonPopPitch",
												cond: "shouldFetchPlayerSeasonPopPitch"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonPopPitch",
									entry: ["refreshPlayerSeasonPopPitchCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonPopPitch",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonPopPitchSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonPopPitchErrored"
										}
									}
								}
							}
						},
						playerSeasonPopPitchByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonPopPitchByTeam",
												cond: "shouldFetchPlayerSeasonPopPitchByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonPopPitchByTeam",
									entry: ["refreshPlayerSeasonPopPitchByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonPopPitchByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonPopPitchByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonPopPitchByTeamErrored"
										}
									}
								}
							}
						},
						playerSeasonPooPitch: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonPooPitch",
												cond: "shouldFetchPlayerSeasonPooPitch"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonPooPitch",
									entry: ["refreshPlayerSeasonPooPitchCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonPooPitch",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonPooPitchSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonPooPitchErrored"
										}
									}
								}
							}
						},
						playerSeasonPooPitchByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonPooPitchByTeam",
												cond: "shouldFetchPlayerSeasonPooPitchByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonPooPitchByTeam",
									entry: ["refreshPlayerSeasonPooPitchByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonPooPitchByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonPooPitchByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonPooPitchByTeamErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) => context.playerId !== context.lastPlayerId,
				shouldFetchPlayerSeasonPopPitch: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) =>
					context.playerSeasonPopPitch === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonPopPitchByTeam: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) =>
					context.playerSeasonPopPitchByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonPooPitch: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) =>
					context.playerSeasonPooPitch === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonPooPitchByTeam: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) =>
					context.playerSeasonPooPitchByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearContext: assign<TPitcherPitchOutcomesTableContext, TPitcherPitchOutcomesTableEvent>({
					lastPlayerId: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => context.playerId,
					playerSeasonPopPitch: (
						_context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => undefined,
					playerSeasonPopPitchByTeam: (
						_context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => undefined,
					playerSeasonPooPitch: (
						_context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => undefined,
					playerSeasonPooPitchByTeam: (
						_context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => undefined,
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TPitcherPitchOutcomesTableContext, TPitcherPitchOutcomesTableEvent>({
					playerId: (context: TPitcherPitchOutcomesTableContext, event: TPitcherPitchOutcomesTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setPlayerSeasonPopPitch: assign<TPitcherPitchOutcomesTableContext, TPitcherPitchOutcomesTableEvent>({
					playerSeasonPopPitch: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_POP_PITCH) return context.playerSeasonPopPitch;
						return event.value;
					},
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_POP_PITCH) return cancelSources;
						cancelSources[PLAYER_SEASON_POP_PITCH_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_POP_PITCH_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerSeasonPopPitchByTeam: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPopPitchByTeam: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_POP_PITCH_BYTEAM)
							return context.playerSeasonPopPitchByTeam;
						return event.value;
					},
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_POP_PITCH_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerSeasonPooPitch: assign<TPitcherPitchOutcomesTableContext, TPitcherPitchOutcomesTableEvent>({
					playerSeasonPooPitch: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_POO_PITCH) return context.playerSeasonPooPitch;
						return event.value;
					},
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_POO_PITCH) return cancelSources;
						cancelSources[PLAYER_SEASON_POO_PITCH_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_POO_PITCH_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerSeasonPooPitchByTeam: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPooPitchByTeam: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_POO_PITCH_BYTEAM)
							return context.playerSeasonPooPitchByTeam;
						return event.value;
					},
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_POO_PITCH_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TPitcherPitchOutcomesTableContext, TPitcherPitchOutcomesTableEvent>({
					filters: (context: TPitcherPitchOutcomesTableContext, event: TPitcherPitchOutcomesTableEvent) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonPopPitchCancelSource: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_POP_PITCH_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_POP_PITCH_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_POP_PITCH_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonPopPitchByTeamCancelSource: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonPooPitchCancelSource: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_POO_PITCH_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_POO_PITCH_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_POO_PITCH_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonPooPitchByTeamCancelSource: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					cancelSources: (
						context: TPitcherPitchOutcomesTableContext,
						_event: TPitcherPitchOutcomesTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonPopPitchSuccess: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPopPitch: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_POP_PITCH_DONE) return context.playerSeasonPopPitch;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonPopPitchByTeamSuccess: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPopPitchByTeam: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_POP_PITCH_BYTEAM_DONE)
							return context.playerSeasonPopPitchByTeam;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonPooPitchSuccess: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPooPitch: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_POO_PITCH_DONE) return context.playerSeasonPooPitch;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonPooPitchByTeamSuccess: assign<
					TPitcherPitchOutcomesTableContext,
					TPitcherPitchOutcomesTableEvent
				>({
					playerSeasonPooPitchByTeam: (
						context: TPitcherPitchOutcomesTableContext,
						event: TPitcherPitchOutcomesTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_POO_PITCH_BYTEAM_DONE)
							return context.playerSeasonPooPitchByTeam;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchPlayerSeasonPopPitchErrored: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Pitch Outcomes - PoP Pitch",
							description: "Error fetching PoP Pitch by player and season.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchPlayerSeasonPopPitchByTeamErrored: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Pitch Outcomes - PoP Pitch by Team",
							description: "Error fetching PoP Pitch by player, season, and team",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchPlayerSeasonPooPitchErrored: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Pitch Outcomes - PoO Pitch",
							description: "Error fetching PoO Pitch by player and season.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchPlayerSeasonPooPitchByTeamErrored: (
					context: TPitcherPitchOutcomesTableContext,
					_event: TPitcherPitchOutcomesTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Pitch Outcomes - PoO Pitch by Team",
							description: "Error fetching PoO Pitch by player, season, and team",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchPlayerSeasonPopPitch: (context: TPitcherPitchOutcomesTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPitchOutcomeProbabilitiesPitch(
							{
								playerId,
								countSplit: PITCH_GROUP_OVERALL,
								isUseCache: true,
								playingLevel
							},
							context.cancelSources[PLAYER_SEASON_POP_PITCH_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonPopPitchByTeam: (
					context: TPitcherPitchOutcomesTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPitchOutcomeProbabilitiesPitchByTeam(
							{
								playerId,
								countSplit: PITCH_GROUP_OVERALL,
								isUseCache: true,
								playingLevel
							},
							context.cancelSources[PLAYER_SEASON_POP_PITCH_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonPooPitch: (context: TPitcherPitchOutcomesTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPitchOutcomeObservedPitch(
							{
								playerId,
								countSplit: PITCH_GROUP_OVERALL,
								isUseCache: true,
								playingLevel
							},
							context.cancelSources[PLAYER_SEASON_POO_PITCH_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonPooPitchByTeam: (
					context: TPitcherPitchOutcomesTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPitchOutcomeObservedPitchByTeam(
							{
								playerId,
								countSplit: PITCH_GROUP_OVERALL,
								isUseCache: true,
								schema: SCHEMA_TYPE_EXPANDED,
								playingLevel
							},
							context.cancelSources[PLAYER_SEASON_POO_PITCH_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherPitchOutcomesTableMachine;
