import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const tables = [
	{
		title: "Arm Accuracy",
		columns: ["Grade", "Description"],
		rows: [
			["20", "He'll throw it into stands regularly."],
			["30", "Erratic, don't know what you're going to get."],
			["40", "Occasional adventure. Uneasy arm action."],
			["50", "Usually on target and from all angles. Usually easy on 1B and Cutoff Men."],
			["60", "Majority on target and from all angles. Is easy on 1B and Cutoff Men. Knows how to play bounce."],
			[
				"70",
				"Majority on target and from all angles. Is easy on 1B and Cutoff Men. On non-force outs is usually in tag zone (knee to waist). Knows how to play bounce."
			],
			[
				"80",
				"Always on target and from all angles. Is easy on 1B and Cutoff Men. On non-force outs is usually in tag zone (knee to waist). Knows how to play bounce."
			]
		]
	},
	{
		title: "Fielding Ability",
		columns: ["Grade", "Present", "Future"],
		rows: [
			[
				"20",
				"Will be overmatched at entry level. Lacks actions and instincts in the field. Looks lost and is a risk on routine plays.",
				"A liability in the field. Likely a DH only."
			],
			[
				"30",
				"Will struggle at entry level. Either actions or instincts are well below average. Game is going to be fast for him. Going to take a lot of work.",
				"Not a defender the team has faith in. Lacks actions, athleticism and instincts to be a ML defender."
			],
			[
				"40",
				"Will compete at entry level. Actions and/or instincts give him potential but still needs development in order to become an everyday defender in the Major Leagues.",
				"Can hold his own on ML routine plays. Defense is not his calling card and he will need a big bat to remain on the field as an everyday player."
			],
			[
				"50",
				"Will excel at entry level. Actions standout, instincts allow him to keep up with the speed of the game. The coaching he needs is minor.",
				"Solid defensive ability. Handles routine plays with relative ease and confidence. There is something holding him back from being above avg."
			],
			[
				"60",
				"Will be a premium defender at entry level. Type of defensive ability that aids or even headlines his prospect status. Natural defender.",
				"ML league routine players come with ease. Instinctive. Has the ability to make highlight reel type plays."
			],
			[
				"70",
				"Will overmatch and dominate entry level. Best at his position in the league, could step on a ML field tomorrow and be above average at his position.",
				"Elite defensive. Instinctual and fearless, has the ability to make almost any play imaginable for his position."
			],
			[
				"80",
				"",
				"Ozzie Smith, Omar Vizquel, Johnny Bench, Roberto Alomar, Ivan Rodriguez, Brooks Robinson, Willie Mays type of defense."
			]
		]
	}
];

const FieldingTooltip = () => {
	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<div
					style={{
						overflow: "scroll"
					}}
				>
					{tables.map((table, idx) => {
						return (
							<Table key={idx} style={{ marginTop: "25px" }}>
								<TableHead
									style={{
										backgroundColor: defaultColorScheme.group.backgroundColor
									}}
								>
									<TableRow>
										<TableCell
											colSpan={table.columns.length}
											style={{
												color: defaultColorScheme.group.color,
												fontSize: "16px",
												textAlign: "center"
											}}
										>
											{table.title}
										</TableCell>
									</TableRow>
									{table.description != null && (
										<TableRow>
											<TableCell
												colSpan={table.columns.length}
												style={{
													color: defaultColorScheme.group.color,
													textAlign: "center"
												}}
											>
												{table.description}
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										{table.columns.map((column, idx) => {
											return (
												<TableCell
													key={idx}
													style={{
														color: defaultColorScheme.group.color,
														textAlign: "center",
														textTransform: "capitalize"
													}}
												>
													{column}
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
								<TableBody
									style={{
										border: "1px solid #dfe0df"
									}}
								>
									{table.rows.map((row, idx) => {
										return (
											<TableRow key={idx}>
												{row.map((cell, idx) => {
													return (
														<TableCell
															key={idx}
															style={{
																textAlign: "center",
																fontWeight: idx === 0 ? "700" : ""
															}}
														>
															{cell}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default FieldingTooltip;
