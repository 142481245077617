import dayjs from "dayjs";

import { TVideoWDPL } from "_react/video/shared/_types";

export function getCreatedDate(video: TVideoWDPL) {
	if (video.type === "media") return video.updated;
	return video.createdDate;
}

export function getModifiedDate(video: TVideoWDPL) {
	if (video.type === "media") return video.updated;
	return video.modifiedDate;
}

export function sortVideos(videos: TVideoWDPL[], newestFirst = true) {
	const sortAValue = newestFirst ? -1 : 1;
	const sortBValue = newestFirst ? 1 : -1;
	return videos.sort((videoA, videoB) => {
		const dateA = getCreatedDate(videoA) ?? getModifiedDate(videoA);
		const dateB = getCreatedDate(videoB) ?? getModifiedDate(videoB);
		if (dateA == null && dateB == null) return 0;
		if (dateA == null) return sortBValue;
		if (dateB == null) return sortAValue;
		return dayjs(dateA).isAfter(dayjs(dateB)) ? sortAValue : sortBValue;
	});
}

export const DATE_TEMPLATE = "YYYY-MM-DD";

export function getVideoId(video: TVideoWDPL) {
	return video.type === "media" ? video.dplSharedLink : video.videoId;
}
