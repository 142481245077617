import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Person from "_react/shared/legacy/ui/icons/Person";
// import Print from "_react/shared/ui/icons/Print";

import { defaultColorScheme } from "_react/forms/Form";
import { isMobile } from "utils/_helpers";
import { formatHeight } from "utils/functions";
import { PROSPECT_TYPE_OPTS_PRO, PRO_RISK_OPTS } from "_react/evals/_constants";
import { getAgeFromBirthDate } from "utils/helpers";
import { IconButton } from "_react/shared/legacy/ui/IconButton";

export const getBannerText = (type, data) => {
	let bannerText = "";

	if (type === "eval") {
		if (data.ofp != null) {
			bannerText = `${data.ofp}`;
		}
		if (data.category != null) {
			if (bannerText !== "") {
				bannerText = `${bannerText} / `;
			}
			let categoryText = data.category;
			if (PROSPECT_TYPE_OPTS_PRO.filter(e => e.value === categoryText).length > 0)
				categoryText = PROSPECT_TYPE_OPTS_PRO.filter(e => e.value === categoryText)[0].text;
			bannerText = `${bannerText}CATEGORY: ${categoryText}`;
		}
		if (data.risk_factor != null && data.publish_date != null && data.publish_date.year() < 2024) {
			if (bannerText !== "") {
				bannerText = `${bannerText} / `;
			}
			let riskText = data.risk_factor;
			if (PRO_RISK_OPTS.filter(e => e.value === riskText).length > 0)
				riskText = PRO_RISK_OPTS.filter(e => e.value === riskText)[0].text;
			bannerText = `${bannerText}RISK FACTOR: ${riskText}`;
		}
		if (data.acquire != null) {
			if (bannerText !== "") {
				bannerText = `${bannerText} / `;
			}
			bannerText = `${bannerText}ACQUIRE: ${["Y", "YES"].indexOf(data.acquire) > -1 ? "Yes" : "No"}`;
		}
	} else if (type === "bio") {
		if (data.evaluation.height != null) {
			if (bannerText !== "") {
				bannerText = `${bannerText} / `;
			}
			bannerText = `${bannerText}HT: ${formatHeight(data.evaluation.height)}`;
		}
		if (data.evaluation.weight != null) {
			if (bannerText !== "") {
				bannerText = `${bannerText} / `;
			}
			bannerText = `${bannerText}WT: ${data.evaluation.weight}`;
		}
		if (data.player.birth_date != null) {
			if (bannerText !== "") {
				bannerText = `${bannerText} / `;
			}
			bannerText = `${bannerText}AGE: ${getAgeFromBirthDate(data.player.birth_date)}`;
		}
		if (data.player.is_r5_eligible === 1) {
			if (bannerText !== "") {
				bannerText = `${bannerText} / `;
			}
			bannerText = `${bannerText}R5 ELIGIBLE: ${
				data.player.r5_eligible_year != null ? data.player.r5_eligible_year : "-"
			}`;
		}
		if (data.player.mn_fa_year != null) {
			if (bannerText !== "") {
				bannerText = `${bannerText} / `;
			}
			bannerText = `${bannerText}MiFA: ${data.player.mn_fa_year}`;
		}

		if (data.team != null) {
			if (bannerText !== "") {
				bannerText = `${bannerText} / `;
			}
			bannerText = `${bannerText}${data.team}`;
		}
	}

	return bannerText;
};

class ProEvalHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showingFloatingHeader: false,
			headerHeight: 80
		};
	}

	componentDidMount() {
		document.addEventListener("scroll", this.trackScrolling);
		window.addEventListener("resize", this.trackWidth);
	}

	trackScrolling = () => {
		if (window.pageYOffset > 79 && !this.state.showingFloatingHeader) {
			this.setState({
				showingFloatingHeader: !isMobile(this.props.windowWidth)
			});
		} else if (window.pageYOffset < 4 && this.state.showingFloatingHeader) {
			this.setState({
				showingFloatingHeader: false
			});
		}
	};

	trackWidth = () => {
		if (window.innerWidth < 1150 && this.state.headerHeight === 60) {
			this.setState({
				headerHeight: 80
			});
		} else if (window.innerWidth > 1150 && this.state.headerHeight === 80) {
			this.setState({
				headerHeight: 80
			});
		}
	};

	createDetailBanner = (evaluation, player, team) => {
		const evalBannerText = getBannerText("eval", evaluation);
		const bioBannerText = getBannerText("bio", { team, evaluation, player });

		if (evalBannerText != null || bioBannerText != null) {
			return (
				<div
					key={1}
					style={{
						backgroundColor: "yellow",
						fontWeight: "bold",
						padding: "5px",
						borderRadius: "4px",
						display: "inline-block",
						border: "2px solid #BABA3D",
						color: "black",
						fontSize: "0.8em"
					}}
				>
					{evalBannerText}
					{evalBannerText !== "" && bioBannerText !== "" && <br />}
					{bioBannerText}
				</div>
			);
		}

		return null;
	};

	createPlayerHeader = () => {
		const { windowWidth, player, evaluation, matches, author } = this.props;
		return (
			<div
				style={{
					display: "flex",
					textAlign: "left",
					width: !isMobile(windowWidth) ? "30%" : "",
					alignItems: "center",
					justifyContent: isMobile(windowWidth) ? "center" : ""
				}}
			>
				{!matches && !isMobile(windowWidth) && (
					<Tooltip placement="right" title="Go To Player Page">
						<IconButton
							onClick={() => {
								this.props.history.push(`player?philId=${player.phil_id}`);
							}}
							style={{ marginRight: "10px", backgroundColor: "white" }}
						>
							<Person style={{ cursor: "pointer", color: "black" }} />
						</IconButton>
					</Tooltip>
				)}
				<div
					style={{
						display: "inline-block",
						textAlign: isMobile(windowWidth) ? "center" : "",
						marginBottom: isMobile(windowWidth) ? "5px" : ""
					}}
				>
					<span
						style={{
							margin: isMobile(windowWidth) ? "auto" : 0,
							display: "inline-block",
							fontSize: "0.8em",
							fontWeight: isMobile(windowWidth) ? "600" : "700"
						}}
					>
						{`Phillies Pro ${evaluation.eval_type === "PRO_HIT" ? "Player" : "Pitcher"} Scouting Report`}
					</span>
					{matches && <br />}
					<span
						style={{
							fontSize: "0.8em"
						}}
					>
						{!matches && " by"} {author}{" "}
						{evaluation.seen_date != null ? `(${evaluation.seen_date.format("M/D/YYYY")})` : ""}
					</span>
				</div>
			</div>
		);
	};

	render() {
		const { matches, player, evaluation, teams, windowWidth } = this.props;
		const { showingFloatingHeader, headerHeight } = this.state;

		return (
			<div
				key={0}
				style={{
					display: isMobile(windowWidth) ? "block" : "flex",
					position: matches ? "absolute" : showingFloatingHeader ? "fixed" : "",
					height: !isMobile(windowWidth)
						? !matches && showingFloatingHeader
							? `${headerHeight}px`
							: `${headerHeight + 10}px`
						: "",
					left: 0,
					right: 0,
					top: matches ? 5 : "46px",
					color: defaultColorScheme.form.color,
					backgroundColor: "#ffffff",
					borderBottom: showingFloatingHeader ? `3px solid #BABABA` : "",
					paddingBottom: matches ? 5 : 0,
					padding: matches ? 0 : "10px",
					alignItems: "center",
					zIndex: 5
				}}
			>
				{!isMobile(windowWidth) && this.createPlayerHeader()}
				<div style={{ flexGrow: 1 }} />
				<div
					style={{
						display: !isMobile(windowWidth) ? "inline-block" : "block",
						textAlign: "center",
						width: !isMobile(windowWidth) ? "30%" : ""
					}}
				>
					<div
						style={{
							margin: "0",
							textAlign: "center",
							fontSize: "0.9em",
							fontWeight: "700",
							verticalAlign: "middle",
							lineHeight: "28px",
							display: isMobile(windowWidth) ? "flex" : "",
							justifyContent: "center"
						}}
					>
						{isMobile(windowWidth) && (
							<Tooltip placement="right" title="Go To Player Page">
								<IconButton
									onClick={() => {
										this.props.history.push(`player?philId=${player.phil_id}`);
									}}
									style={{ marginRight: "10px", backgroundColor: "white" }}
								>
									<Person style={{ cursor: "pointer", color: "black" }} />
								</IconButton>
							</Tooltip>
						)}
						<span>
							{player
								? `${player.roster_first_name ? player.roster_first_name : player.first_name} ${
										player.last_name
								  }`
								: ""}
						</span>
					</div>
					{isMobile(windowWidth) && this.createPlayerHeader()}
					<div
						style={{
							fontSize: "14px"
						}}
					>
						{this.createDetailBanner(
							evaluation,
							player,
							teams[evaluation.team_bam_id] ? teams[evaluation.team_bam_id].name : null
						)}
					</div>
				</div>
				<div style={{ flexGrow: 1 }} />
				<div
					style={{
						textAlign: !isMobile(windowWidth) ? "right" : "center",
						width: !isMobile(windowWidth) ? "30%" : "",
						display: "flex",
						alignItems: "center",
						justifyContent: !isMobile(windowWidth) ? "flex-end" : "center"
					}}
				>
					<span
						style={{
							lineHeight: "28px",
							fontStyle: "italic",
							fontWeight: "normal",
							fontSize: "0.9em",
							verticalAlign: "middle"
						}}
					>
						{`${evaluation.publish_date ? "Published" : "Unpublished"}`}
					</span>
				</div>
			</div>
		);
	}
}

ProEvalHeader.propTypes = {
	author: PropTypes.string,
	evaluation: PropTypes.object,
	handlePrintClick: PropTypes.func,
	history: PropTypes.object,
	matches: PropTypes.bool,
	player: PropTypes.object,
	teams: PropTypes.object,
	windowWidth: PropTypes.number
};

export default withRouter(ProEvalHeader);
