import React from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { Box, VStack, HStack } from "@chakra-ui/react";

import { round10 } from "_react/shared/_helpers/numbers";
import { TSimpleHitterProjections, TSimplePitcherProjections } from "_react/shared/data_models/projections/_types";
import { getTeamName } from "_react/shared/_helpers/team";
import TeamBadge from "_react/shared/ui/presentation/components/TeamBadge/TeamBadge";

import {
	TPlayerPageCombinedPlayer,
	TPlayerPagePlayerProProfile,
	TPlayerPageDraftedPlayer
} from "_react/playerpage/_types";
import { TSimpleProjectionsAll } from "_react/shared/data_models/projections/_types";

dayjs.extend(isSameOrAfter);

export const getFreeAgentOrTeamName = (player?: TPlayerPageCombinedPlayer | null, nullFillValue = ""): string => {
	const freeAgentRosterStatuses = ["REL", "FA"];
	if (
		(player?.proProfile?.rosterStatusMj && freeAgentRosterStatuses.includes(player?.proProfile?.rosterStatusMj)) ||
		(player?.proProfile?.rosterStatusMn && freeAgentRosterStatuses.includes(player?.proProfile?.rosterStatusMn)) ||
		(player?.proProfile?.rosterStatusMj === null && player?.proProfile?.rosterStatusMn === null)
	)
		return "Free Agent";
	return getTeamName(player?.team, nullFillValue);
};

export const getBirthplace = (player: TPlayerPageCombinedPlayer) => {
	return `${player.birthCity ? `${player.birthCity}, ` : ""}${
		player.birthState ? player.birthState : player.birthCountry
	}`;
};

export const getR5Eligible = (proProfile?: TPlayerPagePlayerProProfile | null) => {
	return proProfile
		? `${proProfile.isR5Eligible ? `Yes` : "No"}${
				proProfile.r5EligibleYear ? ` (${proProfile.r5EligibleYear})` : ""
		  }`
		: undefined;
};

export const getPriorOutright = (proProfile?: TPlayerPagePlayerProProfile | null) => {
	return proProfile
		? proProfile.priorOutrightDate != null
			? `Yes (${dayjs(proProfile.priorOutrightDate).format("M/D/YYYY")})`
			: "No"
		: undefined;
};

export const getMifaYear = (proProfile?: TPlayerPagePlayerProProfile | null, nullFillerText = "-") => {
	return proProfile
		? !proProfile.mnFaYear
			? nullFillerText
			: proProfile.mnFaYear >= dayjs().year()
			? proProfile.mnFaYear.toString()
			: nullFillerText
		: undefined;
};

export const getMls = (proProfile?: TPlayerPagePlayerProProfile | null, nullFillerText = "-") => {
	// Returns "OD MLS | Cur MLS"
	return proProfile
		? `${round10(proProfile.mlsOpeningDay, -3) ?? nullFillerText} | ${round10(proProfile.mlsCurrent, -3) ??
				nullFillerText}`
		: undefined;
};

export const getRyc = (proProfile?: TPlayerPagePlayerProProfile | null, nullFillerText = "-") => {
	return proProfile ? `${proProfile.ryc ?? nullFillerText} + ${proProfile.rycOptions ?? nullFillerText}` : undefined;
};

export const getOptionsUsed = (proProfile?: TPlayerPagePlayerProProfile | null, nullFillerText = "-") => {
	return proProfile
		? `(${proProfile.optionsUsed != null ? proProfile.optionsUsed : nullFillerText}/${
				proProfile.optionsTotal != null ? proProfile.optionsTotal : nullFillerText
		  })`
		: undefined;
};

export const getCurrentSeasonOptions = (proProfile?: TPlayerPagePlayerProProfile | null, nullFillerText = "-") => {
	return proProfile ? `(${proProfile.optionsUsedCurrentSeason ?? nullFillerText}/5)` : undefined;
};

export const getDraftPick = (
	draftedPlayer?: TPlayerPageDraftedPlayer | null,
	proProfile?: TPlayerPagePlayerProProfile | null,
	nullFillerText = "-"
) => {
	return draftedPlayer ? (
		draftedPlayer.r4Status === "Drafted" ? (
			<VStack gap="0" alignItems="start">
				<HStack gap="1">
					<Box>{draftedPlayer.r4Year}</Box>
					<TeamBadge teamAbbreviation={draftedPlayer.r4Org} />
				</HStack>
				<Box>
					#{draftedPlayer.r4Overall} (Rd. {draftedPlayer.r4Round})
				</Box>
			</VStack>
		) : (
			<VStack gap="0" alignItems="start">
				<Box>{draftedPlayer.r4Year}</Box>
				<Box>Undrafted FA</Box>
			</VStack>
		)
	) : proProfile ? (
		proProfile.signingCountry !== "US" && proProfile.firstProContractDate != null ? (
			<VStack gap="0" alignItems="start">
				<Box>{dayjs(proProfile.firstProContractDate).format("YYYY") ?? nullFillerText}</Box>
				<Box>Int'l FA</Box>
			</VStack>
		) : (
			undefined
		)
	) : (
		undefined
	);
};

export const getDraftPickSchool = (draftedPlayer?: TPlayerPageDraftedPlayer | null) => {
	return draftedPlayer
		? `${draftedPlayer?.r4SchoolName ?? ""} ${draftedPlayer?.r4State ? `(${draftedPlayer?.r4State})` : ""}`
		: undefined;
};

export const formatSurplusValue = (surplusValue?: number, nullFillerText = "-") => {
	return surplusValue ? `$${surplusValue}M` : nullFillerText;
};

export const getCurrentSimpleHitterProjections = (
	currentYear: number,
	projections?: TSimpleProjectionsAll | null,
	ros?: number
) => {
	const currentSimpleHitterProjections = projections?.simpleHitterProjections.find(
		(proj: TSimpleHitterProjections) => proj.season === currentYear && proj.ros === (ros ?? 1)
	);
	return currentSimpleHitterProjections;
};

export const getCurrentSimplePitcherProjections = (
	year: number,
	projections?: TSimpleProjectionsAll | null,
	ros?: number
) => {
	const currentSimplePitcherProjections = projections?.simplePitcherProjections.find(
		(proj: TSimplePitcherProjections) => proj.season === year && proj.ros === (ros ?? 1)
	);
	return currentSimplePitcherProjections;
};

// Season utils

export const getDefaultSeason = () => {
	// Default to previous year before 4/15 and then default to current year
	const today = dayjs();
	const currentYear = today.year();
	return today.isBefore(`${currentYear}-04-15`, "date") ? currentYear - 1 : currentYear;
};

export const getSeasonOptions = (currentSeason: number | undefined, isFetchingCurrentSeason?: boolean) => {
	// Season options should be the current season, current season - 1 and current season - 2
	if (isFetchingCurrentSeason) {
		return [{ label: "Current Season", value: 0 }];
	}
	if (currentSeason) {
		const offsets = [0, 1, 2];
		return offsets.map(offset => {
			const season = currentSeason - offset;
			return { label: `${season}`, value: season };
		});
	}
	return [];
};
