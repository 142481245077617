import React from "react";
import Check from "_react/shared/ui/icons/Check";

import { TProModelProfile } from "_react/promodel/leaderboard/_types";
import { R540ManBox } from "_react/promodel/leaderboard/_styles";

type T40ManR5Props = {
	leaderboardPlayerProProfile: TProModelProfile;
};

export const get40ManR5Color = ({ leaderboardPlayerProProfile }: T40ManR5Props) => {
	if (leaderboardPlayerProProfile.isOn40ManRoster === true) {
		return (
			<R540ManBox>
				<Check></Check>
				40 Man
			</R540ManBox>
		);
	}
	return leaderboardPlayerProProfile.r5EligibleYear;
};
