import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "_react/shared/legacy/ui/IconButton";
import { isMobile } from "utils/_helpers";
import Save from "_react/shared/legacy/ui/icons/Save";
import Edit from "_react/shared/legacy/ui/icons/Edit";

const FormGroupHeader = ({
	editable,
	editing,
	colorScheme,
	startAdornment,
	style,
	title,
	windowWidth,
	triggerSave,
	handleEditClick
}) => {
	return (
		<div
			style={{
				marginTop: "-10px",
				marginBottom: "2px",
				color: colorScheme.group.color,
				fontWeight: "900",
				fontSize: !isMobile(windowWidth) ? "16px" : "0.8em",
				display: "flex",
				alignItems: "center",
				height: !isMobile(windowWidth) ? "30px" : "",
				marginLeft: "5px",
				...style
			}}
		>
			{startAdornment}
			<span style={{ flex: 1 }}>{title.toUpperCase()}</span>
			{editable && editing && (
				<IconButton onClick={triggerSave} style={{ backgroundColor: "transparent" }}>
					<Save
						fontSize="small"
						style={{
							color: "white"
						}}
					/>
				</IconButton>
			)}
			{editable && !editing && (
				<IconButton onClick={handleEditClick} style={{ backgroundColor: "transparent" }}>
					<Edit
						fontSize="small"
						style={{
							color: "white"
						}}
					/>
				</IconButton>
			)}
		</div>
	);
};

FormGroupHeader.propTypes = {
	colorScheme: PropTypes.object,
	editable: PropTypes.bool,
	editing: PropTypes.bool,
	handleEditClick: PropTypes.func,
	startAdornment: PropTypes.object,
	style: PropTypes.object,
	title: PropTypes.string,
	triggerSave: PropTypes.func,
	windowWidth: PropTypes.number
};

export default FormGroupHeader;
