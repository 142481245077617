import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Clear from "_react/shared/legacy/ui/icons/Clear";
import FocusWithin from "react-focus-within";
import levenshtein from "fast-levenshtein";

import { debounce } from "utils/helpers";
import AutocompleteSuggestionsList from "_react/shared/legacy/autocomplete/AutocompleteSuggestionsList";

const DEBOUNCE_MIN = 150;

class Autocomplete extends React.PureComponent {
	constructor(props) {
		super(props);

		let searchText = this.props.getValueSelectedText(props.valueSelected);
		if (searchText === "" && props.valueSelected) {
			const selectedOption = this.props.dataSource.filter(
				e => e.value === props.valueSelected || e.value === props.valueSelected.value
			);
			if (selectedOption.length === 1) searchText = selectedOption[0].text;
		}
		this.state = {
			cursor: null,
			searchText,
			suggestions: [],
			enter: false,
			textfieldWith: null,
			textfieldBottom: null,
			focused: props.autoFocus ? true : false
		};

		this.handleInputFocus = this.handleInputFocus.bind(this);
	}

	componentDidMount() {
		this.setState({
			textfieldWith: this.divRef.clientWidth,
			textfieldBottom: this.divRef.offsetTop + this.divRef.offsetHeight
		});
		this._ismounted = true;
	}

	componentWillReceiveProps(nextProps) {
		//const { searchText } = nextProps
		//const { searchText:prevSearchText } = this.props
		if (!_.isEqual(this.props.valueSelected, nextProps.valueSelected)) {
			this.setState({
				searchText: this.props.getValueSelectedText(nextProps.valueSelected)
			});
		} else if (this.props.searchText !== nextProps.searchText) {
			this.setState({
				searchText: nextProps.searchText
			});
		}

		// Updating the suggestions when the value changes from props
		if (
			this.props.valueSelected &&
			this.props.valueSelected.value &&
			(this.props.valueSelected ? this.props.valueSelected.text : "") !==
				(nextProps.valueSelected ? nextProps.valueSelected.text : "") &&
			this.state.focused
		) {
			this.fetchSuggestions(nextProps.valueSelected ? nextProps.valueSelected.text : "");
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// Updating suggestions when data source changes
		if (
			this.props.dataSource != null &&
			prevProps.dataSource != null &&
			this.props.dataSource.length !== prevProps.dataSource.length
		) {
			this.fetchSuggestions(this.state.searchText);
		}
		if (this.state.textfieldBottom !== prevState.textfieldBottom) {
			this.setState({
				textfieldWith: this.divRef.clientWidth,
				textfieldBottom: this.divRef.offsetTop + this.divRef.offsetHeight
			});
		}
	}

	componentWillUnmount() {
		this._ismounted = false;
	}

	getSuggestionMatches = searchText => {
		if (searchText.length >= this.props.numCharToShowSuggestions) {
			const searchTerms = searchText.split(" ");
			return this.props.dataSource
				.filter(obj => {
					if (obj.persistent) return true;
					let match = false;
					["text", "hiddenText"].map(t => {
						let matchCount = 0;
						if (obj.hasOwnProperty(t) && !match && obj[t]) {
							searchTerms.map(term => {
								if (obj[t].toLowerCase().includes(term.toLowerCase())) matchCount += 1;
								return null;
							});
						}
						if (matchCount === searchTerms.length) match = true;
						return null;
					});
					return match;
				})
				.slice(0, this.props.maxSuggestions);
		}
		return [...this.state.suggestions.filter(e => e.persistent)];
	};

	performSort = (suggestions, sortFunction) => {
		const persistentSuggestions = suggestions.filter(e => e.persistent);
		const NonPersistentSuggestions = suggestions.filter(e => e.persistent !== true);
		return [...persistentSuggestions, ...sortFunction(NonPersistentSuggestions)];
	};

	sortSuggestions = (suggestions, value) => {
		value = value ? value.toLowerCase() : "";
		if (value === "") {
			return this.performSort(suggestions, s => s);
		} else if (this.props.sortSuggestions) {
			suggestions = this.performSort(suggestions, this.props.sortSuggestions);
		} else {
			const sortFunction = s => {
				return s.sort((a, b) => {
					const idxDiff = a.text.toLowerCase().indexOf(value) - b.text.toLowerCase().indexOf(value);
					if (idxDiff === 0) {
						const levDiff =
							levenshtein.get(a.text.toLowerCase(), value) - levenshtein.get(b.text.toLowerCase(), value);
						if (levDiff === 0) {
							return a.text.localeCompare(b.text);
						}
						return levDiff;
					}
					return idxDiff;
				});
			};
			suggestions = this.performSort(suggestions, sortFunction);
		}
		return suggestions;
	};

	fetchSuggestions = value => {
		let suggestions = [];
		if (value && value.length >= this.props.numCharToShowSuggestions) {
			suggestions = this.state.suggestions;
			suggestions = this.getSuggestionMatches(value);
			suggestions = this.sortSuggestions(suggestions, value);
			suggestions = suggestions.slice(0, this.props.maxSearchResults);
		}
		if (this._ismounted) {
			this.setState({
				suggestions,
				cursor: suggestions.length > 0 ? 0 : null
			});
		}
	};

	fetchSuggestionsDebounce = debounce(value => this.fetchSuggestions(value), 500);

	handleSearchTextUpdate = e => {
		const searchText = e.target.value;
		this.setState({ searchText });
		if (this.props.dataSource.length > DEBOUNCE_MIN) {
			this.fetchSuggestionsDebounce(searchText);
		} else {
			this.fetchSuggestions(searchText);
		}
		if (this.props.onUpdateInput) {
			this.props.onUpdateInput(searchText);
		}
	};

	async handleInputFocus() {
		if (this.props.handleFocus) {
			this.props.handleFocus();
		}
		const suggestions = [...this.props.dataSource.filter(e => e.persistent)];
		let searchText = this.state.searchText;
		if (this.props.clearOnFocus) {
			searchText = "";
		}
		if (this.props.numCharToShowSuggestions === 0) {
			const value = searchText == null ? "" : searchText;
			let suggestions = await this.getSuggestionMatches(value);
			suggestions = await this.sortSuggestions(suggestions, value);
			this.setState({ searchText, suggestions, focused: true });
		} else {
			this.setState({ searchText, suggestions, focused: true });
		}
	}

	handleInputBlur = () => {
		this.setState({ focused: false });
		if (this._ismounted) {
			let searchText = this.state.searchText;
			if (this.props.handleBlur) {
				this.props.handleBlur();
			}

			if (this.props.clearOnBlur && searchText === "" && !this.props.valueSelected) {
				// Value was cleared out
				this.handleSelected({ text: "", value: null });
			} else if (
				this.props.clearOnBlur &&
				(searchText.length > 0 || this.state.suggestions === 1) &&
				(!this.state.valueSelected || !this.state.valueSelected.value)
			) {
				// Text is in autocomplete field, but no value selected on blur (and clearOnBlur prop is set to true)
				const matchedValues = this.props.dataSource.filter(obj => {
					return (
						(obj.text && obj.text.toLowerCase() === searchText.toLowerCase()) ||
						(obj.value && `${obj.value}`.toLowerCase() === searchText.toLowerCase()) ||
						(obj.hasOwnProperty("hiddenText") &&
							obj.hiddenText &&
							obj.hiddenText.toLowerCase() === searchText.toLowerCase())
					);
				});

				if (matchedValues.length === 1 && matchedValues[0].persistent !== true) {
					// Exact match found
					this.handleSelected(matchedValues[0]);
				} else {
					// No exact match, clear text
					searchText = "";
					this.handleSelected({ text: "", value: null });
				}
			} else if (this.props.clearOnBlur && searchText === "") {
				this.handleSelected({ text: "", value: null });
			} else if (!this.props.clearOnBlur && searchText === "" && this.props.valueSelected) {
				searchText = this.props.getValueSelectedText(this.props.valueSelected);
			}

			this.setState({ suggestions: [], searchText });
		}
	};

	handleSelected = valueSelected => {
		if (valueSelected) {
			const searchText = this.props.clearOnSelect ? "" : valueSelected.text;
			this.setState({
				suggestions: [],
				searchText,
				valueSelected: this.props.clearOnSelect ? false : true
			});
			this.props.handleSelected(valueSelected);
		}
	};

	handleKeyDown = e => {
		const { cursor, suggestions } = this.state;
		// arrow up/down button should select next/previous list element
		switch (e.key) {
			case "Enter":
			case "Tab":
				if (cursor !== null && this.state.suggestions) {
					this.handleSelected(this.state.suggestions[cursor]);
				} else if (
					this.state.suggestions.length &&
					this.state.searchText.toLowerCase() === this.state.suggestions[0].text.toLowerCase()
				) {
					this.handleSelected(this.state.suggestions[0]);
				}
				this.setState({ cursor: null, focused: e.key === "Enter" });
				break;
			case "ArrowDown":
				if (cursor !== 0 && !cursor) {
					this.setState({ cursor: 0 });
				} else if (cursor < suggestions.length - 1) {
					this.setState({ cursor: cursor + 1 });
				} else this.setState({ cursor: 0 });
				break;
			case "ArrowUp":
				if (cursor > 0) this.setState({ cursor: cursor - 1 });
				else this.setState({ cursor: suggestions.length - 1 });
				break;
			default:
				break;
		}
	};

	render() {
		const { autoFocus, disabled, label, inputProps, placeholder, showClear, style } = this.props;
		const { suggestions, enter, cursor, searchText, textfieldWith, textfieldBottom, focused } = this.state;

		let endAdornment = null;
		if (showClear && this.state.searchText) {
			endAdornment = {
				endAdornment: (
					<InputAdornment position="end" variant="filled">
						<IconButton
							aria-label="Clear"
							onClick={() => {
								this.handleSelected({ value: null });
								this.setState(
									{
										cursor: null,
										searchText: ""
									},
									() => this.textField.focus()
								);
							}}
							style={{
								color: style != null ? style.color : ""
							}}
							tabIndex="-1"
						>
							<Clear style={{ color: "gray" }} />
						</IconButton>
					</InputAdornment>
				)
			};
		}

		return (
			<FocusWithin onBlur={this.handleInputBlur}>
				{({ focusProps }) => (
					<div
						{...focusProps}
						id="divRef"
						ref={element => {
							this.divRef = element;
						}}
						style={{ width: "100%" }}
					>
						<style>{// Overrides word-wrap set by Chakra Provider
						`#divRef * {
								word-wrap: normal;
							}
						`}</style>
						<TextField
							autoComplete="no"
							autoFocus={autoFocus}
							disabled={disabled}
							fullWidth={true}
							InputProps={{
								...inputProps,
								...endAdornment
							}}
							inputRef={field => (this.textField = field)}
							label={label}
							margin="dense"
							onChange={this.handleSearchTextUpdate}
							onClick={this.handleInputClick}
							onFocus={this.handleInputFocus}
							onKeyDown={this.handleKeyDown}
							placeholder={placeholder}
							style={style}
							value={searchText}
						/>

						<AutocompleteSuggestionsList
							cursor={cursor}
							enter={enter}
							handleSelected={this.handleSelected}
							style={{
								width: textfieldWith,
								top: textfieldBottom
							}}
							suggestions={focused ? suggestions : []}
						/>
					</div>
				)}
			</FocusWithin>
		);
	}
}

Autocomplete.propTypes = {
	autoFocus: PropTypes.bool,
	clearOnBlur: PropTypes.bool,
	clearOnFocus: PropTypes.bool,
	clearOnSelect: PropTypes.bool,
	dataSource: PropTypes.array,
	disabled: PropTypes.bool,
	getValueSelectedText: PropTypes.func,
	handleBlur: PropTypes.func,
	handleFocus: PropTypes.func,
	handleSelected: PropTypes.func,
	inputProps: PropTypes.object,
	label: PropTypes.string,
	maxSearchResults: PropTypes.number,
	maxSuggestions: PropTypes.number,
	numCharToShowSuggestions: PropTypes.number,
	onUpdateInput: PropTypes.func,
	placeholder: PropTypes.string,
	searchText: PropTypes.string,
	showClear: PropTypes.bool,
	sortSuggestions: PropTypes.func,
	style: PropTypes.object,
	valueSelected: PropTypes.object
};

Autocomplete.defaultProps = {
	getValueSelectedText: text => (text && text.text ? text.text : ""),
	inputProps: {},
	maxSearchResults: 10,
	maxSuggestions: 50,
	numCharToShowSuggestions: 1,
	showClear: false
};

export default Autocomplete;
