import dayjs from "dayjs";
import React from "react";

import { TVideoWDPLValid } from "_react/video/shared/_types";
import { DATE_TEMPLATE } from "_react/video/shared/_helpers";

type TPlaylistSidebar = {
	selectVideo(idx: number): void;
	videoSelected: TVideoWDPLValid;
	videos: Array<TVideoWDPLValid>;
};

function PlaylistSidebar({ selectVideo, videoSelected, videos }: TPlaylistSidebar) {
	const idSelected = videoSelected.type === "media" ? videoSelected.dplSharedLink : videoSelected.videoId;
	return (
		<ul style={{ listStyle: "none", overflowY: "scroll", height: "100%" }}>
			{videos.map((v, idx) => {
				const isSelected =
					(v.type === "media" && v.dplSharedLink === idSelected) ||
					(v.type !== "media" && v.videoId === idSelected);
				const description = v.type === "media" ? "" : v.description;
				const date =
					v.type === "media" ? v.updated : v.type === "video" && v.videoDate ? v.videoDate : v.createdDate;
				return (
					<li
						key={v.type === "media" ? v.dplSharedLink : v.videoId}
						onClick={() => selectVideo(idx)}
						style={{
							marginBottom: "5px",
							padding: "5px",
							cursor: "pointer",
							backgroundColor: isSelected ? "gray" : "white",
							borderRadius: "5px",
							fontWeight: isSelected ? "bold" : "normal",
							color: "black",
							marginLeft: "-35px",
							marginRight: "5px"
						}}
					>
						<p style={{ margin: 0, padding: 0 }}>
							{(v.type === "video" || v.type === "phil") && v.source ? v.source.toUpperCase() : ""}
						</p>
						<p style={{ margin: 0, padding: 0 }}>{description}</p>
						<p style={{ margin: 0, padding: 0 }}>
							{date ? dayjs(date, DATE_TEMPLATE).format("MM/DD/YYYY") : ""}
						</p>
					</li>
				);
			})}
		</ul>
	);
}

export default PlaylistSidebar;
