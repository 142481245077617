import React, { CSSProperties } from "react";

import { $TSFixMePlayer } from "utils/tsutils";

import { NULL_TEXT } from "_react/evals/list/_constants";
import { TEvalProHit } from "_react/evals/list/_types";
import { GradeSection, EvalSection, EvalAttr, EvalLabel } from "_react/evals/list/_styles";
import {
	getPlayerR5,
	getPlayerFortyMan,
	getPlayerAge,
	getPlayerMls,
	getPlayerProfile
} from "_react/evals/list/_helpers";

export type TEvalProHitGradesProps = {
	evaluation: TEvalProHit;
	player: $TSFixMePlayer;
	style?: CSSProperties;
};

export const EvalProHitGrades = ({ evaluation, player, style }: TEvalProHitGradesProps) => {
	return (
		<>
			<GradeSection width={"40%"} inlineFlex>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Profile</EvalLabel> {getPlayerProfile(evaluation)}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Age</EvalLabel> {getPlayerAge(player)}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>B/T</EvalLabel> {evaluation.bats ?? NULL_TEXT}/{evaluation.throws ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>MLS</EvalLabel> {getPlayerMls(player)}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Opt</EvalLabel> {evaluation.options_used ?? NULL_TEXT}/
						{player?.options_total ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>40 Man</EvalLabel> {getPlayerFortyMan(player)}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>R5</EvalLabel> {getPlayerR5(player)}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
			<GradeSection width={"60%"} inlineFlex>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Hit</EvalLabel> {evaluation.hitting_ability_present ?? NULL_TEXT}/
						{evaluation.hitting_ability_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Raw</EvalLabel> {evaluation.raw_power_present ?? NULL_TEXT}/
						{evaluation.raw_power_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Pwr</EvalLabel> {evaluation.power_production_present ?? NULL_TEXT}/
						{evaluation.power_production_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Run</EvalLabel> {evaluation.run_speed_present ?? NULL_TEXT}/
						{evaluation.run_speed_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Instincts</EvalLabel> {evaluation.instinct ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Def</EvalLabel> {evaluation.defense_present ?? NULL_TEXT}/
						{evaluation.defense_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Arm Str</EvalLabel> {evaluation.arm_strength_present ?? NULL_TEXT}/
						{evaluation.arm_strength_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Arm Acc</EvalLabel> {evaluation.arm_accuracy_present ?? NULL_TEXT}/
						{evaluation.arm_accuracy_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Field</EvalLabel> {evaluation.fielding_ability_present ?? NULL_TEXT}/
						{evaluation.fielding_ability_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Range</EvalLabel> {evaluation.fielding_range_present ?? NULL_TEXT}/
						{evaluation.fielding_range_future ?? NULL_TEXT}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
		</>
	);
};
