import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useMachine } from "@xstate/react";
import {
	useToast,
	VStack,
	HStack,
	Box,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	Portal,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Tooltip,
	Spacer,
	Text,
	Flex
} from "@chakra-ui/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { isDefaultFilters } from "_react/shared/ui/data/tables/shared/Filters";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import {
	IStatsPlayerFielding,
	IStatsPlayerFieldingOpps,
	IStatsPlayerRangeOpps,
	IStatsPlayerReceivingOpps,
	IStatsPlayerRangeByLevel,
	IStatsPlayerReceivingByLevel
} from "_react/shared/data_models/defensive_observed/_types";
import { getMinAndMaxSeason, getSeasonFilters } from "_react/shared/_helpers/stats";

import {
	MOBILE_COLUMNS,
	NON_MOBILE_COLUMNS,
	NON_MOBILE_PARENT_COLUMNS,
	NULL_FILLER_TEXT,
	POSITION_TO_COLUMN_MAPPING,
	NUM_DISPLAY_SEASONS
} from "_react/shared/ui/data/tables/FieldingOaaTable/_constants";
import { getInningsFromStatsPlayerFielding } from "_react/shared/ui/data/tables/FieldingOaaTable/_helpers";
import FieldingOaaTableMachine, {
	TFieldingOaaTableContext,
	FETCHING_FIELDING_OAA,
	FETCHING_FIELDING_OAA_OPPS,
	SET_FIELDING_OAA,
	SET_FIELDING_OAA_OPPS,
	SET_PLAYER_ID,
	SET_FILTERS
} from "_react/shared/ui/data/tables/FieldingOaaTable/_machine";
import {
	TFieldingOaaRow,
	TFieldingOaaRowMobile,
	TStatsPlayerFieldingBasic
} from "_react/shared/ui/data/tables/FieldingOaaTable/_types";

export type TFieldingOaaTableData = {
	fieldingOaa?: IStatsPlayerFielding | null;
	fieldingOaaOpps?: IStatsPlayerFieldingOpps | null;
	isLoading?: boolean;
};

type TFieldingOaaTableProps = {
	title?: string;
	playerId?: number;
	data?: TFieldingOaaTableData;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	columns?: Array<string>;
	columnsMobile?: Array<string>;
	tableProps?: TTableProps<TFieldingOaaRow, keyof TFieldingOaaRow>;
	tablePropsMobile?: TTableProps<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile>;
};

const FieldingOaaTable = ({
	title,
	playerId,
	data,
	shouldFetchData = true,
	isShowFilters = true,
	columns,
	columnsMobile,
	tableProps,
	tablePropsMobile
}: TFieldingOaaTableProps) => {
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);
	const toast = useToast();

	const [current, send] = useMachine(FieldingOaaTableMachine(playerId, shouldFetchData, data, toast));
	const context = current.context;
	const { filters, fieldingOaa, fieldingOaaOpps } = context as TFieldingOaaTableContext;

	const isFetchingFieldingOaa = current.matches(FETCHING_FIELDING_OAA);
	const isFetchingFieldingOaaOpps = current.matches(FETCHING_FIELDING_OAA_OPPS);
	const isLoading = shouldFetchData ? isFetchingFieldingOaa || isFetchingFieldingOaaOpps : data?.isLoading;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.fieldingOaa !== fieldingOaa && !shouldFetchData) {
			send({ type: SET_FIELDING_OAA, data: data?.fieldingOaa });
		}
	}, [data?.fieldingOaa, shouldFetchData, send, fieldingOaa]);

	useEffect(() => {
		if (data?.fieldingOaaOpps !== fieldingOaaOpps && !shouldFetchData) {
			send({ type: SET_FIELDING_OAA_OPPS, data: data?.fieldingOaaOpps });
		}
	}, [data?.fieldingOaaOpps, shouldFetchData, send, fieldingOaaOpps]);

	const combinedOpps = useMemo(
		() => (fieldingOaaOpps?.statsPlayerRangeOpps ?? []).concat(fieldingOaaOpps?.statsPlayerReceivingOpps ?? []),
		[fieldingOaaOpps?.statsPlayerRangeOpps, fieldingOaaOpps?.statsPlayerReceivingOpps]
	);

	const combinedOaa = useMemo(
		() => (fieldingOaa?.statsPlayerRangeBylevel ?? []).concat(fieldingOaa?.statsPlayerReceivingBylevel ?? []),
		[fieldingOaa?.statsPlayerRangeBylevel, fieldingOaa?.statsPlayerReceivingBylevel]
	);

	// Get max and min season
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<IStatsPlayerRangeByLevel>(fieldingOaa?.statsPlayerRangeBylevel ?? []),
		[fieldingOaa?.statsPlayerRangeBylevel]
	);

	//
	// Season filter options
	//
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		return isDefaultFilters(filters, undefined, undefined, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, value: {} });
	}, [send]);

	//
	// Filter data for table
	//

	const filteredFieldingOaaOpps: Array<IStatsPlayerRangeOpps> | undefined | null = useMemo(() => {
		if (isLoading) return undefined;
		if (!combinedOpps) return combinedOpps;
		return combinedOpps.filter(
			(scores: IStatsPlayerRangeOpps) =>
				scores.season <= seasonFilters.maxSeason && scores.season >= seasonFilters.minSeason
		);
	}, [isLoading, seasonFilters, combinedOpps]);

	const filteredFieldingOaa: Array<IStatsPlayerRangeByLevel> | undefined | null = useMemo(() => {
		if (isLoading) return undefined;
		if (!combinedOaa) return combinedOaa;
		return combinedOaa.filter(
			(scores: IStatsPlayerRangeByLevel) =>
				scores.season <= seasonFilters.maxSeason && scores.season >= seasonFilters.minSeason
		);
	}, [isLoading, seasonFilters, combinedOaa]);

	// Group fielding OAA Opportunities by season and position
	const groupedFieldingOaaOpps: Record<number, Record<string, number | null>> = useMemo(() => {
		const newGroupedFieldingOaaOpps: Record<number, Record<string, number | null>> = {};
		filteredFieldingOaaOpps?.forEach((fieldingOaaOpp: IStatsPlayerRangeOpps | IStatsPlayerReceivingOpps) => {
			const season = fieldingOaaOpp.season;
			const position = fieldingOaaOpp.position;
			const opportunities = fieldingOaaOpp.opportunitiesPerFullSeason;

			const seasonRecord: Record<string, number | null> | undefined = newGroupedFieldingOaaOpps[season];
			// Handle case where we don't have any data for a season
			if (seasonRecord === undefined) {
				newGroupedFieldingOaaOpps[season] = { [position]: opportunities };
			}
			// Handle case where we do have data for a season
			else {
				const positionOpportunities: number | null = seasonRecord[position] ?? null;
				if (opportunities !== null) seasonRecord[position] = (positionOpportunities ?? 0) + opportunities;
				else seasonRecord[position] = positionOpportunities;
			}
		});

		return newGroupedFieldingOaaOpps;
	}, [filteredFieldingOaaOpps]);

	// Get non-mobile rows
	const fieldingOaaRows: Array<TFieldingOaaRow> = useMemo(() => {
		const newfieldingOaaRows: Array<TFieldingOaaRow> = [];
		filteredFieldingOaa?.forEach((fieldingOaaDatum: IStatsPlayerRangeByLevel | IStatsPlayerReceivingByLevel) => {
			const season = fieldingOaaDatum.season;
			const level = fieldingOaaDatum.level;
			const position = fieldingOaaDatum.position;

			const newStatsPlayerFieldingBasic: TStatsPlayerFieldingBasic = {
				opportunities: fieldingOaaDatum.opportunities,
				innings: fieldingOaaDatum.innings,
				fullSeasonScaledOaaCentered: fieldingOaaDatum.fullSeasonScaledOaaCentered,
				opportunitiesPerFullSeason: groupedFieldingOaaOpps[season]
					? groupedFieldingOaaOpps[season][position]
					: null
			};

			const recordToUpdate: TFieldingOaaRow | undefined = newfieldingOaaRows.find(
				(fieldingOaaRow: TFieldingOaaRow) => fieldingOaaRow.season === season && fieldingOaaRow.level === level
			);
			// Handle case where fieldingOaaRows does not have any data for a given season and level
			if (recordToUpdate === undefined)
				newfieldingOaaRows.push({
					season: season,
					level: level,
					fieldingOaaByPosition: { [position]: [newStatsPlayerFieldingBasic] }
				});
			// Handle case where we already have some fieldingOaa data for a season and level
			else {
				recordToUpdate.fieldingOaaByPosition[position] !== undefined
					? // Handle case where we have fieldingOaa data for a season and position
					  recordToUpdate.fieldingOaaByPosition[position].push(newStatsPlayerFieldingBasic)
					: // Handle case where we have fieldingOaa data for the season, but not the position
					  (recordToUpdate.fieldingOaaByPosition[position] = [newStatsPlayerFieldingBasic]);
			}
		});
		return newfieldingOaaRows;
	}, [filteredFieldingOaa, groupedFieldingOaaOpps]);

	// Get non-mobile columns
	const filteredColumns = useMemo(() => {
		const newColumns: Array<TColumn<TFieldingOaaRow, keyof TFieldingOaaRow>> = [...NON_MOBILE_COLUMNS];

		// Whether to include each set of columns - is there at least one entry where innings is not null?
		for (const position in POSITION_TO_COLUMN_MAPPING) {
			const row = fieldingOaaRows.find(
				(fieldingOaaRow: TFieldingOaaRow) =>
					getInningsFromStatsPlayerFielding(fieldingOaaRow.fieldingOaaByPosition[position]) !==
					NULL_FILLER_TEXT
			);
			if (row) newColumns.push(...POSITION_TO_COLUMN_MAPPING[position]);
		}

		if (!columns) return newColumns;
		return newColumns.filter((col: TColumn<TFieldingOaaRow, keyof TFieldingOaaRow>) => columns.includes(col.value));
	}, [fieldingOaaRows, columns]);

	// Get mobile rows
	const fieldingOaaRowsMobile: Array<TFieldingOaaRowMobile> = useMemo(() => {
		const newfieldingOaaRowsMobile: Array<TFieldingOaaRowMobile> = [];
		filteredFieldingOaa?.forEach((fieldingOaaDatum: IStatsPlayerRangeByLevel | IStatsPlayerReceivingByLevel) => {
			const season = fieldingOaaDatum.season;
			const level = fieldingOaaDatum.level;
			const position = fieldingOaaDatum.position;

			const newStatsPlayerFieldingBasic: TStatsPlayerFieldingBasic = {
				opportunities: fieldingOaaDatum.opportunities,
				innings: fieldingOaaDatum.innings,
				fullSeasonScaledOaaCentered: fieldingOaaDatum.fullSeasonScaledOaaCentered,
				opportunitiesPerFullSeason: groupedFieldingOaaOpps[season]
					? groupedFieldingOaaOpps[season][position]
					: null
			};

			const recordToUpdate: TFieldingOaaRowMobile | undefined = newfieldingOaaRowsMobile.find(
				(fieldingOaaRowMobile: TFieldingOaaRowMobile) =>
					fieldingOaaRowMobile.season === season &&
					fieldingOaaRowMobile.level === level &&
					fieldingOaaRowMobile.position === position
			);
			// Handle case where fieldingOaaRows does not have any data for a given season, level, and position
			if (recordToUpdate === undefined)
				newfieldingOaaRowsMobile.push({
					season: season,
					level: level,
					position: position,
					fieldingOaa: [newStatsPlayerFieldingBasic]
				});
			// Handle case where we already have some fieldingOaa data for a season, level, and position
			else recordToUpdate.fieldingOaa.push(newStatsPlayerFieldingBasic);
		});
		return newfieldingOaaRowsMobile;
	}, [filteredFieldingOaa, groupedFieldingOaaOpps]);

	// Get mobile columns
	const filteredColumnsMobile = useMemo(() => {
		const newColumns: Array<TColumn<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile>> = [...MOBILE_COLUMNS];

		if (!columnsMobile) return newColumns;
		return newColumns.filter((col: TColumn<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile>) =>
			columnsMobile.includes(col.value)
		);
	}, [columnsMobile]);

	const isMobile = useBreakpointValue({
		base: true,
		"2xl": false
	});

	return (
		<VStack align="start">
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
				</HStack>
				{isShowFilters && (
					<Menu closeOnSelect={false}>
						<ButtonGroup
							isAttached
							variant={defaultFiltersSet ? "outline" : "solid"}
							colorScheme={defaultFiltersSet ? undefined : "blue"}
						>
							{!defaultFiltersSet && (
								<IconButton
									aria-label="Close"
									icon={<CloseIcon fill="white" />}
									onClick={resetFilters}
								/>
							)}
							<MenuButton
								as={IconButton}
								aria-label="Options"
								icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
							>
								MenuItem
							</MenuButton>
						</ButtonGroup>
						<Portal>
							<MenuList minWidth="240px">
								<MenuOptionGroup title="Seasons">
									<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
										{minSeason === maxSeason && (
											<Tooltip hasArrow placement="top" label="Only one season of data exists">
												<HStack>
													<OutlineInfo color="gray.500" />
													<Text>{minSeason}</Text>
												</HStack>
											</Tooltip>
										)}
										{minSeason !== maxSeason && (
											<VStack>
												<RangeSlider
													value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
													min={minSeason}
													max={maxSeason}
													step={1}
													onChange={(seasons: number[]) => {
														send({
															type: SET_FILTERS,
															value: {
																...filters,
																minSeason: seasons[0],
																maxSeason: seasons[1]
															}
														});
													}}
													onMouseEnter={() => setShowSeasonRangeTooltip(true)}
													onMouseLeave={() => setShowSeasonRangeTooltip(false)}
												>
													<RangeSliderTrack>
														<RangeSliderFilledTrack bg="black" />
													</RangeSliderTrack>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.minSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
													</Tooltip>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.maxSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
													</Tooltip>
												</RangeSlider>
												<Flex sx={{ width: "100%" }}>
													<Text fontSize="sm">{minSeason}</Text>
													<Spacer />
													<Text fontSize="sm">{maxSeason}</Text>
												</Flex>
											</VStack>
										)}
									</VStack>
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
				)}
			</HStack>
			{isMobile ? (
				<Table<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile>
					columns={
						filteredColumnsMobile as Array<TColumn<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile>>
					}
					data={fieldingOaaRowsMobile}
					isLoadingData={isLoading}
					emptyDataDisplayText={"No Fielding OAA Data"}
					defaultSortColumns={[
						{
							columnValue: "seasonMobile",
							sortDirection: ASC
						},
						{
							columnValue: "levelMobile",
							sortDirection: DESC
						},
						{
							columnValue: "positionMobile",
							sortDirection: ASC
						}
					]}
					{...tablePropsMobile}
				/>
			) : (
				<Table<TFieldingOaaRow, keyof TFieldingOaaRow>
					columns={filteredColumns as Array<TColumn<TFieldingOaaRow, keyof TFieldingOaaRow>>}
					parentColumns={NON_MOBILE_PARENT_COLUMNS}
					data={fieldingOaaRows}
					isLoadingData={isLoading}
					emptyDataDisplayText={"No Fielding OAA Data"}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: ASC
						},
						{
							columnValue: "level",
							sortDirection: DESC
						}
					]}
					{...tableProps}
				/>
			)}
		</VStack>
	);
};

export default FieldingOaaTable;
