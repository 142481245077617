import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";

import {
	IPitchOutcomeProbabilitiesPitch,
	IPitchOutcomeProbabilitiesPitchByTeam,
	IPitchOutcomeProbabilitiesPa,
	IPitchOutcomeProbabilitiesPaByTeam
} from "_react/shared/data_models/pitch_outcome_probabilities/_types";

// PoP Pitch

export type TPitchOutcomeProbabilitiesPitchGetQueryParams = {
	playerId?: number;
	season?: number;
	"season[in]"?: string;
	playingLevel?: string;
	throws?: string;
	bats?: string;
	gameType?: string;
	countSplit?: string;
	pitchType?: string;

	total?: number;
	pSwingIntrinsic?: number;
	pWhiffIntrinsic?: number;
	pChaseIntrinsic?: number;
	pCswIntrinsic?: number;
	pSwingStuff?: number;
	pWhiffStuff?: number;
	pChaseStuff?: number;
	pCswStuff?: number;

	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchPitchOutcomeProbabilitiesPitch = (
	queryParams: TPitchOutcomeProbabilitiesPitchGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPitchOutcomeProbabilitiesPitchGetQueryParams, Array<IPitchOutcomeProbabilitiesPitch>>(
		"/pitch_outcome_probabilities/player_season",
		queryParams,
		cancelToken
	);

export type TPitchOutcomeProbabilitiesPitchByTeamGetQueryParams = TPitchOutcomeProbabilitiesPitchGetQueryParams & {
	teamId?: number;
	"teamId[in]"?: string;
};

export const fetchPitchOutcomeProbabilitiesPitchByTeam = (
	queryParams: TPitchOutcomeProbabilitiesPitchByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPitchOutcomeProbabilitiesPitchByTeamGetQueryParams, Array<IPitchOutcomeProbabilitiesPitchByTeam>>(
		"/pitch_outcome_probabilities/player_season/team",
		queryParams,
		cancelToken
	);

// PoP PA

export type TPitchOutcomeProbabilitiesPaGetQueryParams = {
	pitcherId?: number;
	season?: number;
	"season[in]"?: string;
	bats?: string;
	gameType?: string;

	battersFaced?: number;
	xK?: number;
	xGb?: number;
	xUbb?: number;
	xHr?: number;

	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchPitchOutcomeProbabilitiesPa = (
	queryParams: TPitchOutcomeProbabilitiesPaGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPitchOutcomeProbabilitiesPaGetQueryParams, Array<IPitchOutcomeProbabilitiesPa>>(
		"/pitch_outcome_probabilities/pa",
		queryParams,
		cancelToken
	);

export type TPitchOutcomeProbabilitiesPaByTeamGetQueryParams = TPitchOutcomeProbabilitiesPaGetQueryParams & {
	teamId?: number;
	"teamId[in]"?: string;
};

export const fetchPitchOutcomeProbabilitiesPaByTeam = (
	queryParams: TPitchOutcomeProbabilitiesPaByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPitchOutcomeProbabilitiesPaByTeamGetQueryParams, Array<IPitchOutcomeProbabilitiesPaByTeam>>(
		"/pitch_outcome_probabilities/pa/team",
		queryParams,
		cancelToken
	);
