import React, { FunctionComponent, CSSProperties } from "react";
import styled from "@emotion/styled";
import { ColorScheme } from "_react/shared/legacy/ui/Colors";

import { $TSFixMe, Style } from "utils/tsutils";
import { HeavyText } from "_react/shared/legacy/ui/Text";

type CardHeaderProps = {
	children?: React.ReactNode;
	colorScheme?: ColorScheme;
	fontSize?: CSSProperties["fontSize"];
	Icon?: $TSFixMe;
	style?: Style;
	text?: $TSFixMe;
};

const StyledCardHeader = styled.div({
	padding: "5px 12px 0px 0px",
	display: "flex",
	height: "32px",
	alignItems: "center",
	borderRadius: "inherit",
	borderBottomLeftRadius: "0px",
	borderBottomRightRadius: "0px"
});

const StylesCardHeaderIcon = styled.div({
	paddingRight: "5px"
});

const StyledCardHeaderContents = styled.div({
	fontSize: "14px",
	fontWeight: 500,
	display: "flex",
	alignItems: "center",
	flex: "1 1 auto"
});

export const CardHeader: FunctionComponent<CardHeaderProps> = ({ children, fontSize, Icon = null, text }) => {
	// const colorScheme = COLOR_TO_CARD_HEADER_COLOR_SCHEME[color];

	if (text) {
		return (
			<StyledCardHeader>
				{Icon != null && (
					<StylesCardHeaderIcon>
						<Icon />
					</StylesCardHeaderIcon>
				)}
				<StyledCardHeaderContents>
					<HeavyText size={fontSize}>{text}</HeavyText>
					{children}
				</StyledCardHeaderContents>
			</StyledCardHeader>
		);
	} else {
		return <StyledCardHeader>{children}</StyledCardHeader>;
	}
};
