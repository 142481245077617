import React, { useEffect } from "react";

import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import Info from "_react/shared/legacy/ui/icons/Info";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";

import { Button } from "_react/shared/legacy/ui/Button";
import { formatValue, getMostRecentMetricValue, getPreviousSeasonMetricValue } from "_react/playerplan/shared/_helpers";
import {
	GoalDetailsTr,
	GoalDetailsTd,
	GoalDetailsCenteredTd,
	GoalDetailsFlexCenteredContainer,
	GoalDetailsDescriptionTd,
	GoalDetailsMetricValueContainer,
	SecondaryButtonStyle
} from "_react/playerplan/shared/_styles";
import { TPlayerPlanGoal, TPlayerPlanTarget } from "_react/playerplan/shared/_types";
import { DISABLED_OPACITY } from "_react/playerplan/shared/_constants";

export type TGoalDetailsSectionProps = {
	goal?: TPlayerPlanGoal;
	goalSeason: number;
	target?: TPlayerPlanTarget;
	tier: number;
	isSeasonValue: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	canAddTierTwo?: boolean;
	setIsSecondaryGoalModalOpen?: (value: boolean) => void;
	setModalPrimaryGoal?: (goal: TPlayerPlanGoal | null) => void;
	isGoalActionsDisabled?: boolean;
	isEditable: boolean;
	primaryGoal?: TPlayerPlanGoal;
	canAddTierTwoStrength?: boolean;
};

export const GoalDetailsSection = ({
	goal,
	goalSeason,
	target,
	tier,
	isSeasonValue,
	colorSchemeGroup = defaultColorScheme,
	canAddTierTwo = false,
	setIsSecondaryGoalModalOpen = () => null,
	setModalPrimaryGoal = () => null,
	isGoalActionsDisabled = true,
	isEditable,
	primaryGoal,
	canAddTierTwoStrength = false
}: TGoalDetailsSectionProps) => {
	const openSecondaryGoalModal = () => {
		setIsSecondaryGoalModalOpen(true);
		setModalPrimaryGoal(primaryGoal || null);
	};

	useEffect(() => {
		setModalPrimaryGoal(primaryGoal || null);
	}, [primaryGoal, setModalPrimaryGoal]);

	const mostRecentMetricValueSeason: number | null = getMostRecentMetricValue(
		goal?.playerPlanMetricValues,
		goal?.metricScale,
		true,
		goal?.playerAppearances,
		true,
		goalSeason
	);

	const mostRecentMetricValueRolling: number | null = getMostRecentMetricValue(
		goal?.playerPlanMetricValues,
		goal?.metricScale,
		true,
		goal?.playerAppearances,
		false,
		goalSeason
	);

	const previousSeasonMetricValue: number | null = getPreviousSeasonMetricValue(
		goal?.playerPlanMetricValues,
		goal?.metricScale,
		true,
		goalSeason
	);

	return goal ? (
		<>
			<GoalDetailsTr>
				<GoalDetailsCenteredTd>
					<b>Tier {tier}:</b> {`${target?.direction?.label ?? "Improve"} ${goal.playerPlanMetric.label}`}
				</GoalDetailsCenteredTd>
			</GoalDetailsTr>
			{(target?.value?.toString() ||
				mostRecentMetricValueSeason !== null ||
				previousSeasonMetricValue !== null) && (
				<GoalDetailsTr>
					<GoalDetailsTd>
						<GoalDetailsFlexCenteredContainer>
							{isSeasonValue ? (
								<>
									{previousSeasonMetricValue && (
										<GoalDetailsMetricValueContainer>
											<div>
												<b>{`${goalSeason - 1}:`}</b>
												{`${` `}${formatValue(
													previousSeasonMetricValue,
													goal.playerPlanMetric.format
												)}`}
											</div>
											{goal.playerPlanMetric.rollingAveragePeriodDays > 0 && (
												<Tooltip
													title={`Full Season Value`}
													colorScheme={colorSchemeGroup.primary}
												>
													<Info></Info>
												</Tooltip>
											)}
										</GoalDetailsMetricValueContainer>
									)}
									<GoalDetailsMetricValueContainer>
										<div>
											<b>{`${goalSeason}:`}</b>
											{`${` `}${
												mostRecentMetricValueSeason
													? formatValue(
															mostRecentMetricValueSeason,
															goal.playerPlanMetric.format
													  )
													: "N/A"
											}`}
										</div>
										{goal.playerPlanMetric.rollingAveragePeriodDays > 0 && (
											<Tooltip title={`Full Season Value`} colorScheme={colorSchemeGroup.primary}>
												<Info></Info>
											</Tooltip>
										)}
									</GoalDetailsMetricValueContainer>
								</>
							) : (
								<GoalDetailsMetricValueContainer>
									<div>
										<b>Recent:</b>
										{`${` `}${
											mostRecentMetricValueRolling
												? formatValue(
														mostRecentMetricValueRolling,
														goal.playerPlanMetric.format
												  )
												: "N/A"
										}`}
									</div>
									{goal.playerPlanMetric.rollingAveragePeriodDays > 0 && (
										<Tooltip
											title={`Rolling ${goal.playerPlanMetric.rollingAveragePeriodDays} day average up to most recent appearance of ${goalSeason}`}
											colorScheme={colorSchemeGroup.primary}
										>
											<Info></Info>
										</Tooltip>
									)}
								</GoalDetailsMetricValueContainer>
							)}
							{target?.value?.toString() && (
								<div>
									{" "}
									<b>Goal:</b>
									{` ${formatValue(target.value, goal.playerPlanMetric.format)}`}
								</div>
							)}
						</GoalDetailsFlexCenteredContainer>
					</GoalDetailsTd>
				</GoalDetailsTr>
			)}
			{target?.description && target?.description.trim() && (
				<GoalDetailsTr>
					<GoalDetailsDescriptionTd>
						<b>Description:</b> {`${target?.description}`}
					</GoalDetailsDescriptionTd>
				</GoalDetailsTr>
			)}
		</>
	) : (
		<GoalDetailsTr>
			<GoalDetailsTd>
				<GoalDetailsFlexCenteredContainer>
					{tier === 2 &&
					((!primaryGoal?.isStrength && canAddTierTwo) ||
						(primaryGoal?.isStrength && canAddTierTwoStrength)) &&
					isEditable ? (
						<Button
							onClick={() => openSecondaryGoalModal()}
							colorScheme={colorSchemeGroup.primary}
							title={"Add Tier 2 Goal"}
							disabled={isGoalActionsDisabled}
							style={SecondaryButtonStyle}
							disabledOpacity={DISABLED_OPACITY}
						/>
					) : (
						<div>
							<b>No Tier {tier} Goal</b>
						</div>
					)}
				</GoalDetailsFlexCenteredContainer>
			</GoalDetailsTd>
		</GoalDetailsTr>
	);
};
