import dayjs from "dayjs";
import React from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";

import { AMA } from "_react/playerpage/_constants";
import { getPlayerFullNameFromPlayerProps } from "_react/shared/data_models/player/_helpers";
import { getTeamName } from "_react/shared/_helpers/team";
import { TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import {
	IAmaProspectValueSurplusValuePresentValueByseason,
	IAmaProspectValuePlayer,
	IAmaProspectValueDraftedPlayer
} from "_react/shared/data_models/phred/_types";
import { getMostRecentDraftPick } from "_react/shared/data_models/player/_helpers";

import {
	MIN_R4_YEAR,
	NULL_FILLER_TEXT,
	NULL_VALUE,
	NUMBER_OF_SEASONS,
	BATS_THROWS_COLUMN,
	SV_COLUMN,
	DRAFT_YEAR_PICK_COLUMN,
	SV_OVER_TIME_COLUMN,
	SV_CHANGE_COLUMN,
	HIGHEST_LEVEL_COLUMN
} from "_react/shared/ui/data/tables/AmaSurplusValueSimilarPlayersTable/_constants";
import {
	TSvData,
	TAmaSurplusValueSimilarPlayer
} from "_react/shared/ui/data/tables/AmaSurplusValueSimilarPlayersTable/_types";

export const getR4YearOptions = (): Array<{ label: string; value: number }> => {
	const options: Array<{ label: string; value: number }> = [];
	const maxR4Year = dayjs().year();
	for (let i = 0; i <= maxR4Year - MIN_R4_YEAR; i++) {
		const year = MIN_R4_YEAR + i;
		options.push({ label: `${year}`, value: year });
	}
	return options;
};

export const getXAxisLabels = (parsedPlotData: TSvData[] | undefined): Array<{ label: string; value: Date }> => {
	// Only compute xAxisLabels if there is at least 1 point in the plot data
	if (parsedPlotData === undefined || parsedPlotData.length <= 0) return [];

	const minSeason: TSvData = parsedPlotData[0];
	const maxSeason: TSvData | undefined =
		parsedPlotData.length > 1 ? parsedPlotData[parsedPlotData.length - 1] : undefined;

	return maxSeason
		? [
				{ label: `${minSeason.season}`, value: minSeason.date },
				{ label: `${maxSeason.season}`, value: maxSeason.date }
		  ]
		: [{ label: `${minSeason.season}`, value: minSeason.date }];
};

export const getAllSvData = (row: IAmaProspectValuePlayer, r4Year: number | undefined): TSvData[] | undefined => {
	if (r4Year === undefined) return undefined;

	const plotData: TSvData[] = [];
	if (row.xsurplusMillions !== null) {
		plotData.push({
			sv: row.xsurplusMillions,
			season: r4Year,
			date: dayjs(`${r4Year}-01-02`).toDate(),
			totalDifference: 0
		});
		row?.player?.surplusValuePresentValueByseason?.forEach(
			(sv: IAmaProspectValueSurplusValuePresentValueByseason) => {
				if (
					sv.surplusValue !== null &&
					// TODO: Change this to the blended model when that value is available
					sv.predType === "pro_model_xsv" &&
					row.xsurplusMillions !== null &&
					dayjs(sv.date).year() >= r4Year &&
					dayjs(sv.date).isBefore(`${r4Year + 3}-01-02`)
				) {
					plotData.push({
						sv: sv.surplusValue,
						season: dayjs(sv.date).year(),
						date: new Date(sv.date),
						totalDifference: sv.surplusValue - row.xsurplusMillions
					});
				}
			}
		);
	}

	// Don't return any plot data if there is only one point
	if (plotData.length === 1) return undefined;

	plotData.sort((a: TSvData, b: TSvData) => {
		return dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1;
	});

	return plotData;
};

// Get earliest entry from a given season
export const getSurplusValueToDisplay = (allSvData: TSvData[] | null | undefined, season: number): TSvData | null => {
	if (!allSvData?.length) return null;

	const processedSurplusValues: TSvData[] = allSvData
		.filter((sv: TSvData) => {
			return sv.season === season;
		})
		.sort((a: TSvData, b: TSvData) => {
			return dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1;
		});
	if (!processedSurplusValues.length) return null;
	return processedSurplusValues[0];
};

export const getPlotDataForPoints = (
	allPlotData: TSvData[] | undefined,
	r4Year: number | undefined
): TSvData[] | undefined => {
	if (r4Year === undefined) return undefined;

	const pointPlotData: Array<TSvData> = [];

	for (let i = 0; i < NUMBER_OF_SEASONS; i++) {
		const season: number = r4Year + i;
		const svToDisplay = getSurplusValueToDisplay(allPlotData, season);
		if (svToDisplay !== null) {
			pointPlotData.push(svToDisplay);
		}
	}

	return pointPlotData;
};

export const parseSimilarPlayersData = (
	similarPlayers: Array<IAmaProspectValuePlayer> | undefined
): Array<TAmaSurplusValueSimilarPlayer> | undefined => {
	return similarPlayers?.map((playerData: IAmaProspectValuePlayer) => {
		const mostRecentDraftPick: IAmaProspectValueDraftedPlayer | null = playerData?.player
			? getMostRecentDraftPick(playerData.player)
			: null;
		const r4Year: number | undefined = mostRecentDraftPick?.r4Year ?? playerData?.r4Year ?? undefined;
		const allSvData = getAllSvData(playerData, r4Year);
		return { parsedR4Year: r4Year, parsedPlotData: allSvData, ...playerData };
	});
};

const createPlayerColumn = (currentDraftYear: number | undefined) => {
	return {
		value: "player",
		label: "Player",
		width: 44,
		isMobile: true,
		getValueFunction: (row: TAmaSurplusValueSimilarPlayer) => {
			const mostRecentDraftPick: IAmaProspectValueDraftedPlayer | null = row?.player
				? getMostRecentDraftPick(row.player)
				: null;
			// Only use the current school in the ama profile if the player has not been drafted
			// and the player's eligible year is equal to or after the current draft year
			// OR
			// if they were drafted, but a different r4 year than their r4 projection year
			const useCurrentAmaSchool =
				Boolean(
					mostRecentDraftPick?.r4Status !== "Drafted" &&
						row?.player?.amaProfile?.eligibleYear &&
						currentDraftYear &&
						row.player.amaProfile.eligibleYear >= currentDraftYear
				) || row.parsedR4Year !== row.r4Year;
			const schoolName = useCurrentAmaSchool
				? getTeamName(row?.player?.amaProfile?.team)
				: mostRecentDraftPick?.r4SchoolName;
			const schoolType = useCurrentAmaSchool
				? row?.player?.amaProfile?.team?.level
				: mostRecentDraftPick?.r4SchoolType;

			return (
				<VStack align="start" gap="0">
					<Text fontSize="md" fontWeight="semibold">
						{getPlayerFullNameFromPlayerProps(
							row?.player?.firstName,
							row?.player?.firstNameLegal,
							row?.player?.lastName,
							row?.player?.lastNameExtended,
							row.player?.amaProfile?.firstName,
							row?.player?.amaProfile?.lastName,
							false,
							AMA
						)}
					</Text>
					<HStack marginTop="0 !important" fontSize="sm" color="gray.500">
						<>
							<Text sx={{ textWrap: "pretty" }}>
								{schoolName
									? `${schoolName}${` | ${schoolType ?? NULL_FILLER_TEXT}`}`
									: `${NULL_FILLER_TEXT}`}
							</Text>
						</>
					</HStack>
				</VStack>
			);
		},
		getSortValueFunction: (row: TAmaSurplusValueSimilarPlayer) =>
			row?.player?.amaProfile?.lastName ?? row?.player?.lastName ?? row?.player?.lastNameExtended ?? NULL_VALUE,
		onClickFunction: (row: TAmaSurplusValueSimilarPlayer) => {
			window.open(`../player?philId=${row?.player?.playerAmaId}&view=summary`, "_blank");
		}
	};
};

export const createAmaSurplusValueSimilarPlayersColumns = (
	currentDraftYear: number | undefined
): Array<TColumn<TAmaSurplusValueSimilarPlayer, keyof TAmaSurplusValueSimilarPlayer>> => {
	const playerColumn = createPlayerColumn(currentDraftYear);

	return [
		playerColumn,
		BATS_THROWS_COLUMN,
		SV_COLUMN,
		DRAFT_YEAR_PICK_COLUMN,
		SV_OVER_TIME_COLUMN,
		SV_CHANGE_COLUMN,
		HIGHEST_LEVEL_COLUMN
	];
};
