/*
    Eventually merge with _types
 */
export const LOAD_SEASON_STATS = "load_season_stats";
export const LOAD_SEASON_STATS_FAILURE = "load_season_stats_failure";
export const LOAD_CAREER_STATS = "load_career_stats";
export const LOAD_CAREER_STATS_FAILURE = "load_career_stats_failure";
export const FETCH_SEASON_STATS = "fetch_season_stats";
export const FETCH_CAREER_STATS = "fetch_career_stats";
export const LOAD_TEAM_STATS = "load_team_stats";
export const LOAD_TEAM_STATS_FAILURE = "load_team_stats_failure";
export const FETCH_TEAM_STATS = "fetch_team_stats";
export const FETCH_PROJECTION_STATS = "fetch_projection_stats";
export const LOAD_PROJECTION_STATS = "load_projection_stats";
export const LOAD_PROJECTION_STATS_FAILURE = "load_projection_stats_failure";
export const FETCH_AVERAGE_AGES = "fetch_average_ages";
