import React, { FunctionComponent, useMemo, useState, CSSProperties } from "react";
import { PropsValue } from "react-select";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { TRANSACTIONS_COLUMNS } from "_react/playerpage/transaction/_helpers";
import { TPlayerPageTransaction, TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import LkSelectCustom, { TLkOption } from "_react/inputs/lks/LKSelectCustom";

import { selectStyles } from "_react/playerpage/transaction/_styles";
import { TLkValueConstraint } from "_react/inputs";

type TTransactionsTableStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

type TransactionsTableProps = {
	colorScheme?: ColorSchemeGroup;
	player: TPlayerPageCombinedPlayer;
	style?: TTransactionsTableStyle;
	selectedTransactionId?: number;
	transactions?: Array<TPlayerPageTransaction>;
	tableMaxHeight?: React.CSSProperties["maxHeight"];
};

const TableDiv = styled.div<{ maxHeight?: React.CSSProperties["maxHeight"] }>(({ maxHeight }) => ({
	maxHeight,
	overflow: "auto"
}));

export const TransactionsTable: FunctionComponent<TransactionsTableProps> = ({
	colorScheme = defaultColorScheme,
	player,
	style,
	selectedTransactionId,
	transactions = [],
	tableMaxHeight
}) => {
	const history = useHistory();

	const [transactionFilters, setTransactionFilters] = useState<PropsValue<TLkOption<TLkValueConstraint>>>();

	const filteredTransactions = useMemo<TPlayerPageTransaction[] | undefined>(() => {
		if (transactionFilters && Array.isArray(transactionFilters) && transactionFilters.length !== 0) {
			let selectedTransactions = new Array(transactionFilters.length);
			if (Array.isArray(transactionFilters)) {
				selectedTransactions = transactionFilters.map(value => Object.values(value)[0]);
			}
			const transactionFilterResults: TPlayerPageTransaction[] = transactions.filter(transaction =>
				selectedTransactions.includes(transaction.transactionType)
			);
			return transactionFilterResults;
		}

		return transactions;
	}, [transactions, transactionFilters]);

	return (
		<Card style={style?.card}>
			<ColoredCardHeader
				colorScheme={colorScheme.secondary}
				text={"Transactions"}
				style={style?.cardHeader}
			></ColoredCardHeader>
			<TableDiv maxHeight={tableMaxHeight}>
				<LkSelectCustom<TLkValueConstraint>
					query={"transaction_types"}
					value={transactionFilters}
					onChange={setTransactionFilters}
					styles={selectStyles("black")}
					placeholder={"Filter Transaction Type..."}
					isClearable={true}
					isMulti={true}
				/>
				<AutoFitTable
					columns={TRANSACTIONS_COLUMNS(player.playerProId)}
					defaultSortColumn={"Date"}
					defaultSortState={"desc"}
					highlightIndexFunc={(row: TPlayerPageTransaction) => row.transactionId === selectedTransactionId}
					onRowClick={(row: TPlayerPageTransaction) =>
						history.push(
							`?philId=${player.playerProId}&view=transactions&transactionId=${row.transactionId}&viewClassification=pro`
						)
					}
					rows={filteredTransactions}
					rowKey="transactionId"
				/>
			</TableDiv>
		</Card>
	);
};
