import { $TSFixMe } from "utils/tsutils";

import {
	TFormComponent,
	TFormSection,
	TFormSectionRow,
	TFormStructure,
	TFormComponentColumn,
	TFormComponentColumnRow,
	TFormComponentRow,
	TFormValidationError,
	TFormValue,
	ENTIRE_FORM,
	TLkProps
} from "_react/shared/forms/_types";
import { FORM_NAVIGATION_ID } from "_react/shared/forms/_constants";

export const buildComponentCollection = (section: TFormSection) => {
	const columnArray = section.components
		.sort((a, b) => {
			const aColumn = a.column ?? 1;
			const bColumn = b.column ?? 1;
			if (aColumn !== bColumn) return aColumn - bColumn;
			const aRank = a.rank ?? 1;
			const bRank = b.rank ?? 1;
			return aRank - bRank;
		})
		.reduce((rowArray, component) => {
			// Extract the row number
			const row = component.row ?? 1;

			// Ensure the specific row array exists
			for (let i = rowArray.length; i < row; i++) {
				rowArray.push({
					rowNumber: i,
					components: []
				});
			}

			rowArray[row - 1].components.push({
				columnNumber: 1,
				components: [component]
			});
			return rowArray;
		}, [] as TFormComponentColumnRow[]);

	return columnArray;
};

export const buildComponentColumArray = (components: TFormComponent[]) =>
	// Take a 1D array of components, and return a 2D array of columns with their components sorted
	components.reduce((columnArray, component) => {
		// Extract the column number
		const column = component.column ?? 1;

		// Ensure the specific column array exists
		for (let i = columnArray.length; i < column; i++) {
			columnArray.push({
				columnNumber: i,
				components: []
			});
		}

		// Add the  component to the column
		columnArray[column - 1].components.push(component);
		// Sort the components in the column by rank
		columnArray[column - 1].components = columnArray[column - 1].components.sort((a, b) => a.rank - b.rank);

		return columnArray;
	}, [] as TFormComponentColumn[]);

export const buildComponentRowArray = (components: TFormComponent[]) =>
	// Take a 1D array of components, and return a 2D array of rows with their components sorted
	components.reduce((rowArray, component) => {
		// Extract the row number
		const row = component.row ?? 1;

		// Ensure the specific row array exists
		for (let i = rowArray.length; i < row; i++) {
			rowArray.push({
				rowNumber: i,
				components: []
			});
		}

		// Add the component to the row
		rowArray[row - 1].components.push(component);
		// Sort the components in the row by rank
		rowArray[row - 1].components = rowArray[row - 1].components.sort((a, b) => a.rank - b.rank);

		return rowArray;
	}, [] as TFormComponentRow[]);

export const buildSectionRowArray = (sections: TFormSection[]) => {
	// Take a 1D array of sections, and return a 2D array of rows with their sections and widths

	// Holders for the current row and the current row's width
	let currentRow: TFormSection[] = [];
	let currentWidth = 0;

	// Iterate over each section and build row array
	const rows = sections.reduce((rowArray, section) => {
		// Extract the current section's width
		const sectionWidth = section.width ?? 12;

		// Make sure current section will fit in current row
		if (currentWidth + sectionWidth > 12) {
			// Current section will not fit in current row, make a new row
			rowArray.push({
				sections: currentRow,
				width: currentWidth,
				rowNumber: rowArray.length + 1
			});
			currentRow = [];
			currentWidth = 0;
		}

		// Add the current section to the current row and increment the current row's width
		currentRow.push(section);
		currentWidth += sectionWidth;

		// If this completes a row, make a new row
		if (sectionWidth >= 12) {
			rowArray.push({
				sections: currentRow,
				width: currentWidth,
				rowNumber: rowArray.length + 1
			});
			currentRow = [];
			currentWidth = 0;
		}

		return rowArray;
	}, [] as TFormSectionRow[]);

	// If the last row does not complete a full row, make sure to add the current row
	if (currentRow.length > 0)
		rows.push({
			sections: currentRow,
			width: currentWidth,
			rowNumber: rows.length + 1
		});

	return rows;
};

export const getFormNavigationHeightOffset = () => {
	// If the form navigation floating header is present (mobile only) calculate the additional top margin to be added to the form as to not hide the beginning of the form under the navigation component
	const formNavigationReference = document.getElementById(FORM_NAVIGATION_ID);
	const heightOfFormNavigation = formNavigationReference?.offsetHeight;
	return (heightOfFormNavigation ?? 0) + 6;
};

const getFormNavigationOffset = () => {
	// Get the offset between the top of the window scroll position and the bottom of the form navigation header (to be used as a constant that will be subtracted to the new desired scroll position)
	// Aka this represents the distance from the top of the window to the bottom of the form navigation
	const formNavigationReference = document.getElementById(FORM_NAVIGATION_ID);
	const topOfFormNavigation = formNavigationReference?.getBoundingClientRect().top;
	const heightOfFormNavigation = formNavigationReference?.offsetHeight;
	const bottomOfFormNavigation = (topOfFormNavigation ?? 0) + (heightOfFormNavigation ?? 0);
	return bottomOfFormNavigation + 6;
};

export const getElementOffset = (id: string) => {
	const el = document.getElementById(id);
	const rect = el?.getBoundingClientRect(),
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;

	return (rect?.top ?? 0) + scrollTop;
};

const scrollToPositionSmoothly = (position: number) => {
	// Smoothly scroll to that position
	window.scroll({
		top: position,
		left: 0,
		behavior: "smooth"
	});
};

export const handleSectionScroll = (formStructure: TFormStructure, next: boolean) => () => {
	// Get the form navigation offset
	const formNavigationOffset = getFormNavigationOffset();

	// Get the current scroll position on the page (with the nav offset)
	const currentScrollPosition = window.pageYOffset + formNavigationOffset;

	// Find the scroll positions of the previous and next sections
	let previousScrollPosition = 0;
	let nextScrollPosition = 0;
	for (let i = 0; i < formStructure.sections.length; i++) {
		const sectionId = formStructure.sections[i].id;
		// Get the position of the top of the element
		const sectionOffsetTop = getElementOffset(sectionId);

		// Check if the position of the element is above the current scroll position
		if (currentScrollPosition > sectionOffsetTop) {
			// If so, then the position to scroll to (if scrolling back) is this element
			previousScrollPosition = sectionOffsetTop;
		}

		// Check if the position of the element is below the current scroll position
		if (currentScrollPosition < sectionOffsetTop) {
			// If so, then the position to scroll to (if scrolling forward) is this element
			nextScrollPosition = sectionOffsetTop;
			// We've already gotten the previous and next scroll positions, stop iterating
			break;
		}
	}

	// We want to scroll to the desired position, but we need to subtract the form navigation offset so that the top of the element isn't covered by the header and form navigation
	const scrollToPosition = (next ? nextScrollPosition : previousScrollPosition) - formNavigationOffset;

	// Smoothly scroll to that position
	scrollToPositionSmoothly(scrollToPosition);
};

export const scrollToSection = (id: string) => {
	// Get the form navigation offset
	const formNavigationOffset = getFormNavigationOffset();

	// Get the position of the top of the element
	const sectionOffsetTop = getElementOffset(id);

	// We want to scroll to the desired position, but we need to subtract the form navigation offset so that the top of the element isn't covered by the header and form navigation
	const scrollToPosition = sectionOffsetTop - formNavigationOffset;

	// Smoothly scroll to that position
	scrollToPositionSmoothly(scrollToPosition);
};

export const removeValidationRule = (
	validationErrors: TFormValidationError[],
	component: TFormComponent | typeof ENTIRE_FORM
) => {
	return validationErrors.filter(error => {
		if (error.component === ENTIRE_FORM || component === ENTIRE_FORM) return error.component === component;
		return error.component.key !== component.key;
	});
};

export const hasValidationError = (validationErrors: TFormValidationError[], component: TFormComponent) => {
	return (
		validationErrors.filter(error => error.component !== ENTIRE_FORM && error.component.key === component.key)
			.length > 0
	);
};

export const extractValueUsingKey = (key: string, document: $TSFixMe) => {
	if (key.includes(".")) {
		// Part of a collection
		// Decompress the key and update the correct value in the collection
		const keyComponents = key.split(".");
		const keyUsed = keyComponents[0];
		const index = parseInt(keyComponents[1], 10);
		const componentKey = keyComponents[2];
		return document[keyUsed][index][componentKey];
	} else if (key.includes("->")) {
		return key
			.split("->")
			.reduce((subDocument, key) => (subDocument ? subDocument[key] : undefined), document as $TSFixMe);
	}

	return document[key];
};

export const updateValueUsingKey = (key: string, document: $TSFixMe, value: TFormValue) => {
	const newDocument = { ...document };
	if (key.includes(".")) {
		// Part of a collection
		// Decompress the key and update the correct value in the collection
		const keyComponents = key.split(".");
		const keyUsed = keyComponents[0];
		const index = parseInt(keyComponents[1], 10);
		const componentKey = keyComponents[2];
		newDocument[keyUsed][index][componentKey] = value;
	} else if (key.includes("->")) {
		const keyComponents = key.split("->");
		let subDocument = newDocument;
		for (let i = 0; i < keyComponents.length - 1; i++) {
			subDocument = subDocument[keyComponents[i]];
		}
		subDocument[keyComponents[keyComponents.length - 1]] = value;
	} else {
		newDocument[key] = value;
	}

	return newDocument;
};

export const getLkParametersFromComponentKey = (componentKey: string, lkProps?: TLkProps) => {
	// First check if there is an lkProps dict
	if (lkProps == null) return undefined;

	// First check actual key for exact match
	if (lkProps[componentKey] != null) return lkProps[componentKey];

	// If the component is nested, or part of a collection, get the last component of the key and use that
	const keyComponents = componentKey.split(".");
	const lkPropsKey = keyComponents[keyComponents.length - 1];

	return lkProps[lkPropsKey];
};
