import dayjs from "dayjs";

import {
	FETCH_PRO_TEAMS_REQUEST,
	FETCH_PRO_TEAMS_SUCCESS,
	FETCH_PRO_TEAMS_FAILURE,
	FETCH_AMA_TEAMS_REQUEST,
	FETCH_AMA_TEAMS_SUCCESS,
	FETCH_AMA_TEAMS_FAILURE,
	CREATE_AMA_TEAM_REQUEST,
	CREATE_AMA_TEAM_SUCCESS,
	CREATE_AMA_TEAM_FAILURE,
	FETCH_INTL_TEAMS_REQUEST,
	FETCH_INTL_TEAMS_SUCCESS,
	FETCH_INTL_TEAMS_FAILURE
} from "_redux/Team/_types";
import { AMA_TEAMS_METADATA } from "_redux/Team/_helpers";
import { combineNormalizedData } from "_redux/_utils/functions";

export const INITIAL_STATE = {
	pro: {
		fetching: false,
		fetched: false,
		[dayjs().year()]: {
			bam_id: {}
		}
	},
	ama: {
		creating: false,
		fetching: false,
		teams: {
			team_id: {}
		}
	},
	intl: {
		fetching: false,
		teams: {
			team_id: {}
		}
	}
};

const teamReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_PRO_TEAMS_REQUEST:
			return {
				...state,
				pro: {
					...state.pro,
					[action.payload.year]: {
						...state.pro[action.payload.year],
						fetching: true
					}
				}
			};
		case FETCH_PRO_TEAMS_SUCCESS:
			return {
				...state,
				pro: {
					...state.pro,
					[action.payload.year]: {
						...state.pro[action.payload.year],
						...action.payload.data,
						fetching: false,
						fetched: true
					}
				}
			};
		case FETCH_PRO_TEAMS_FAILURE:
			return {
				...state,
				pro: {
					...state.pro,
					[action.payload.year]: {
						...state.pro[action.payload.year],
						fetching: false
					}
				}
			};
		case FETCH_INTL_TEAMS_REQUEST:
			return { ...state, intl: { ...state.intl, fetching: true } };
		case FETCH_INTL_TEAMS_SUCCESS:
			return {
				...state,
				intl: { fetching: false, teams: { ...action.payload } }
			};
		case FETCH_INTL_TEAMS_FAILURE:
			return { ...state, intl: { ...state.intl, fetching: false } };
		case FETCH_AMA_TEAMS_REQUEST:
			return { ...state, ama: { ...state.ama, fetching: true } };
		case FETCH_AMA_TEAMS_SUCCESS:
			return {
				...state,
				ama: {
					...state.ama,
					fetching: false,
					teams: { ...action.payload }
				}
			};
		case FETCH_AMA_TEAMS_FAILURE:
			return { ...state, ama: { ...state.ama, fetching: false } };
		case CREATE_AMA_TEAM_REQUEST:
			return { ...state, ama: { ...state.ama, creating: true } };
		case CREATE_AMA_TEAM_SUCCESS:
			return {
				...state,
				ama: {
					...state.ama,
					creating: false,
					teams: combineNormalizedData(state.ama.teams, action.payload, AMA_TEAMS_METADATA)
				}
			};
		case CREATE_AMA_TEAM_FAILURE:
			return { ...state, ama: { ...state.ama, creating: false } };
		default:
			return state;
	}
};

export default teamReducer;
