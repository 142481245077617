import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

function Book({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path
				fill={fill}
				d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 4h5v8l-2.5-1.5L6 12V4z"
			/>
		</IconSVG>
	);
}
export default Book;
