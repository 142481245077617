import React, { FC, useMemo } from "react";
import styled from "@emotion/styled";

import { defaultColorScheme, ColorScheme, GRAY, GRAYTEXT } from "_react/shared/legacy/ui/Colors";
import { TOption } from "_react/inputs";

type StyledButtonProps = {
	isSelected: boolean;
	isMulti?: boolean;
	disabled?: boolean;
	style?: React.CSSProperties;
	colorScheme?: ColorScheme;
};

type ButtonSelectedProps = StyledButtonProps & {
	cypress?: string;
	onClick?: () => void;
	label: React.ReactNode;
};

const StyledButton = styled.button<StyledButtonProps>(
	{
		padding: "5px 8px",
		borderRadius: "3px",
		boxShadow:
			"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
		":focus": {
			outline: 0
		}
	},
	({ isSelected, isMulti, disabled = false, style = {}, colorScheme = defaultColorScheme.secondary }) => {
		if (disabled) {
			return {
				backgroundColor: GRAY,
				border: undefined,
				color: GRAYTEXT,
				cursor: "not-allowed"
			};
		}
		return {
			backgroundColor: isSelected ? colorScheme.color : "transparent",
			color: isSelected ? colorScheme.text : colorScheme.color,
			border: `1px solid ${colorScheme.color}`,
			cursor: isSelected && !isMulti ? "auto" : "pointer",
			":hover": {
				backgroundColor: isSelected ? colorScheme.color : colorScheme.hover,
				color: isSelected ? colorScheme.text : colorScheme.hoverText,
				border: `1px solid ${isSelected ? colorScheme.color : colorScheme.hover}`
			},
			":active": {
				backgroundColor: isSelected ? colorScheme.color : colorScheme!.selected,
				color: isSelected ? colorScheme.text : colorScheme.selectedText,
				border: `1px solid ${isSelected ? colorScheme.color : colorScheme!.selected}`
			},
			...style
		};
	}
);

export const ButtonSelected: FC<ButtonSelectedProps> = ({
	isMulti,
	isSelected,
	colorScheme = defaultColorScheme.secondary,
	cypress = "",
	disabled = false,
	onClick = () => null,
	style = {},
	label = ""
}) => {
	return (
		<StyledButton
			colorScheme={colorScheme}
			data-cy={cypress}
			disabled={disabled}
			onClick={onClick}
			isMulti={isMulti}
			isSelected={isSelected}
			style={style}
			type="button"
		>
			{label}
		</StyledButton>
	);
};

type TSelectedSingle = string | number;
type TSelectedMulti = Array<TSelectedSingle>;
type TSelected = TSelectedSingle | TSelectedMulti;

function isSelectedMulti(selected: TSelected | undefined): selected is TSelectedMulti {
	return Array.isArray(selected);
}

type TButtonsSelectedProps = {
	title?: string;
	titlePlacement?: string;
	buttons: Array<TOption<string | number>>;
	onSelect: Function;
	selected: TSelected | undefined;
	disabled?: boolean;
	style?: React.CSSProperties;
	colorScheme?: ColorScheme;
};

export const ButtonsSelected: FC<TButtonsSelectedProps> = ({
	title,
	titlePlacement = "left",
	buttons,
	onSelect = () => null,
	selected,
	disabled = false,
	style = {},
	colorScheme = defaultColorScheme.secondary
}) => {
	const stringifiedSelected = useMemo(() => {
		if (selected) {
			if (isSelectedMulti(selected)) {
				return selected.map(value => value.toString());
			}
			return selected.toString();
		}
	}, [selected]);

	function isSelected(value: TSelectedSingle): boolean {
		value = value.toString();
		if (stringifiedSelected) {
			if (isSelectedMulti(stringifiedSelected)) {
				return stringifiedSelected.includes(value);
			}
			return stringifiedSelected === value;
		}
		return false;
	}

	function handleSelect(value: TSelectedSingle): void {
		if (selected && isSelectedMulti(selected)) {
			if (isSelected(value)) {
				onSelect(selected.filter(curValue => curValue !== value));
			} else {
				onSelect(selected.concat([value]));
			}
		} else {
			onSelect(value);
		}
	}

	return (
		<div
			style={{
				borderRadius: "3px",
				border: `2px solid ${colorScheme.color}`,
				display: "flex",
				boxShadow:
					"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
				flexDirection: titlePlacement === "top" ? "column" : "row",
				width: "100%",
				boxSizing: "border-box",
				...style
			}}
		>
			{title != null && (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: colorScheme.color,
						color: colorScheme.text,
						padding: "0 5px",
						marginRight: titlePlacement === "left" ? "1px" : "",
						fontSize: titlePlacement === "top" ? "10px" : "",
						fontWeight: 900,
						marginBottom: titlePlacement === "top" ? "-1px" : "",
						paddingBottom: titlePlacement === "top" ? "2px" : ""
					}}
				>
					{title}
				</div>
			)}
			<div style={{ display: "flex", flexGrow: 1, flexWrap: "wrap" }}>
				{buttons.map((button: TOption<string | number>, idx: number) => (
					<ButtonSelected
						isMulti={isSelectedMulti(selected)}
						isSelected={isSelected(button.value)}
						colorScheme={colorScheme}
						disabled={disabled}
						key={idx}
						onClick={() => handleSelect(button.value)}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flex: 1,
							borderRadius: 0,
							margin: 0,
							borderTop: "none",
							borderBottom: "none",
							borderRight: idx === buttons.length - 1 ? "none" : "",
							borderLeftColor: idx === 0 && title != null ? "transparent" : "",
							boxShadow: "none",
							paddingTop: titlePlacement === "top" ? 0 : "",
							paddingBottom: titlePlacement === "top" ? 0 : "",
							fontWeight: titlePlacement === "top" ? 600 : 500,
							fontSize: titlePlacement === "top" ? "inherit" : "12px"
						}}
						label={button.label}
					/>
				))}
			</div>
		</div>
	);
};
