import { stackOrderAscending, stackOrderNone, stackOrderDescending, stackOrderInsideOut } from "d3";

export const NONE = "none";
export const ASCENDING = "ascending";
export const DESCENDING = "descending";
export const INSIDEOUT = "insideout";
export const EXPAND = "expand";
export const DIVERGING = "diverging";
export const SILHOUETTE = "silhouette";
export const WIGGLE = "wiggle";

export const LEGEND_WIDTH = 15;
export const PITCH_TYPE_LABEL_STYLE = { container: { width: LEGEND_WIDTH, height: 5 } };

export const STACK_ORDER_DICT = {
	none: stackOrderNone,
	ascending: stackOrderAscending,
	descending: stackOrderDescending,
	insideout: stackOrderInsideOut
};
