import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import axios from "_redux/_utils/_axios";
import { Style, $TSFixMe } from "utils/tsutils";
import { StylesConfig, CSSObjectWithLabel } from "react-select";
import debounce from "debounce-promise";

import { TOption } from "_react/inputs";
import { makeStyledSelectStyles } from "_react/shared/selects";
import { buildQueryString } from "_react/shared/selects/TeamSelect";
import { formatOptionLabel, TPlayerClassification } from "_react/shared/searches/PlayerSearch";
import { createReactSelectTeamOption } from "_react/shared/searches/_helpers";

export type $TSFixMeTeamOpt = $TSFixMe;

type TType = "PRO" | "INTL" | "AMA";

type TeamSearchProps = {
	isClearable?: boolean;
	isStyledSelectStyles?: boolean;
	onSelect?: Function;
	style?: Style;
	placeholder?: string;
	type?: TType;
	creatable?: boolean;
	onCreateOption?: Function;
	initialValue?: $TSFixMeTeamOpt;
	value?: $TSFixMeTeamOpt;
	levels?: Array<string>;
	states?: Array<string>;
	isActive?: boolean;
	disabled?: boolean;
	valueOnlyValue?: string | number;
	selectStyles?: StylesConfig;
};

const teamSelectStyle: (color: string) => StylesConfig<TOption<number>, boolean> = color => ({
	control: () => ({
		display: "flex",
		transition: "all 100ms",
		fontSize: 17,
		borderBottom: `1px solid ${color}`,
		marginBottom: "2px",
		zIndex: 1005
	}),
	input: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontSize: 17,
		color: color,
		padding: 0
	}),
	loadingIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: color
	}),
	option: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: "black",
		cursor: "pointer"
	}),
	placeholder: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: color,
		fontSize: 17,
		fontWeight: 400,
		opacity: 0.5
	}),
	singleValue: (provided: CSSObjectWithLabel, state: $TSFixMe) => ({
		...provided,
		display: state.selectProps.menuIsOpen ? "none" : "block",
		color: color,
		fontSize: 17
	}),
	menuPortal: (provided: CSSObjectWithLabel) => ({
		...provided,
		zIndex: 1005
	}),
	valueContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontWeight: 400
	})
});

export const TeamSearch: FunctionComponent<TeamSearchProps> = ({
	isClearable = false,
	isStyledSelectStyles = false,
	onSelect,
	placeholder = "Team Search...",
	style = {},
	type,
	levels,
	states,
	isActive,
	creatable = false,
	onCreateOption = () => null,
	initialValue = null,
	value: valueProp = undefined,
	disabled = false,
	valueOnlyValue = undefined,
	selectStyles = undefined
}) => {
	const [value, setValue] = useState<$TSFixMeTeamOpt | null>(initialValue);

	useEffect(() => {
		if (valueOnlyValue) {
			axios.get(`/search/team/initial?values=${valueOnlyValue}`).then(response => {
				if (response.data.length > 0) {
					setValue(createReactSelectTeamOption(response.data[0]));
				}
			});
		}
	}, [valueOnlyValue]);

	const history = useHistory();
	if (!onSelect) {
		onSelect = (t: $TSFixMeTeamOpt) => history.push(`/team?rockyId=${t.value}`);
	}
	const fetchTeamSuggestions = (query: string) => {
		const queryString = buildQueryString(query, type, levels, states, isActive);
		return axios.get(`/search/team${queryString}`).then(suggestions => {
			return suggestions.data.map(createReactSelectTeamOption);
		});
	};
	const debouncedFetchTeamSuggestions = debounce(fetchTeamSuggestions, 200, {
		leading: true
	});
	const color = style.color ? style.color : "black";

	const selectStylesFinal = isStyledSelectStyles
		? makeStyledSelectStyles()
		: selectStyles
		? selectStyles
		: teamSelectStyle(color);

	const getName = (t: $TSFixMeTeamOpt) => {
		return t.label;
	};

	return (
		<div style={{ flex: 1, ...style }}>
			{creatable ? (
				<AsyncCreatableSelect<TOption<number>, boolean>
					components={{ DropdownIndicator: null }}
					formatOptionLabel={formatOptionLabel}
					getOptionLabel={getName}
					getOptionValue={(t: $TSFixMeTeamOpt) => t.value}
					loadOptions={debouncedFetchTeamSuggestions}
					isClearable={isClearable}
					onChange={(selectedValue: $TSFixMeTeamOpt) => {
						if (onSelect != null) onSelect(selectedValue);
						setValue(selectedValue);
					}}
					placeholder={placeholder}
					styles={selectStylesFinal as $TSFixMe}
					value={valueProp ? valueProp : value}
					getNewOptionData={(inputValue: string, _: React.ReactNode) => {
						return {
							value: 0,
							label: 'Create "' + inputValue + '"',
							playerClassification: "AMA" as TPlayerClassification,
							__isNew__: true
						};
					}}
					onCreateOption={(selectedValue: $TSFixMeTeamOpt) => {
						if (onCreateOption != null) onCreateOption(selectedValue);
						setValue({ label: selectedValue, player_classification: "AMA" });
					}}
					isDisabled={disabled}
				/>
			) : (
				<AsyncSelect<TOption<number>, boolean>
					menuPlacement={"auto"}
					components={{ DropdownIndicator: null }}
					formatOptionLabel={formatOptionLabel}
					getOptionLabel={getName}
					getOptionValue={(t: $TSFixMeTeamOpt) => t.value}
					isClearable={isClearable}
					loadOptions={debouncedFetchTeamSuggestions}
					onChange={(selectedValue: $TSFixMeTeamOpt) => {
						if (onSelect != null) onSelect(selectedValue);
						setValue(selectedValue);
					}}
					placeholder={placeholder}
					styles={selectStylesFinal as $TSFixMe}
					value={valueProp !== undefined ? valueProp : value}
					isDisabled={disabled}
				/>
			)}
		</div>
	);
};
