import { CELL_STYLE, HEADER_STYLE } from "_react/css";

export const tableStyle = {
	width: "100%",
	borderCollapse: "collapse",
	fontSize: ".75em",
	tableLayout: "auto",
	border: "1px solid black"
};

export const headerRowStyle = {
	...HEADER_STYLE
};

export const rowStyle = idx => ({
	...CELL_STYLE,
	fontWeight: "bold",
	backgroundColor: idx % 2 === 0 ? "white" : "#EEEEEE",
	height: 27,
	borderBottom: "0px solid black",
	borderTop: "0px solid black"
});

export const cellStyle = {
	borderRight: "1px solid #cbcbcb",
	borderBottom: "0px solid black",
	borderTop: "0px solid black"
};

export const totalRowCellStyle = {
	...CELL_STYLE,
	backgroundColor: "#C3C3C3",
	height: 27,
	borderBottom: "0px solid black"
};

export const careerRowCellStyle = {
	...CELL_STYLE,
	backgroundColor: "white",
	height: 27,
	borderTop: "1px solid black",
	borderBottom: "0px solid black",
	color: "black",
	fontWeight: "bold"
};

export const steamerProjectionRowCellStyle = {
	...CELL_STYLE,
	backgroundColor: "hsla(101, 64%, 42%, .3)",
	height: 27
};

export const philliesProjectionRowCellStyle = {
	...CELL_STYLE,
	backgroundColor: "hsla(208, 69%, 66%, .3)",
	height: 27
};

export const philliesProjectionRowEndCellStyle = {
	borderBottom: "3px solid black"
};

export const philliesProjectionChildRowCellStyle = {
	...philliesProjectionRowCellStyle,
	borderTop: "none",
	borderBottom: "none"
};

export const BORDER_AFTER_COLS_PITCHER = ["REL AGE", "SV", "BB", "BABIP", "RwOBA", "FBv", "xFIP"];
export const BORDER_AFTER_COLS_HITTER = ["REL AGE", "CS", "BABIP", "wRC+", "pRC+"];
export const BORDER_AFTER_COLS_FIELDER = ["POSITION", "FIELDING %"];

export const borderColumnBorder = "2px solid gray";
export const nonBorderColumnBorderHeader = "1px solid rgb(203, 203, 203)";
export const nonBorderColumnBorder = "1px solid rgb(128, 128, 128)";
export const edgeColumnBorder = tableStyle.border;

export const mouseOverRowColor = "#FFF472";
export const mouseOverColumnColor = "rgba(255, 244, 114, 0.5)";

export const nestedRowEven = "#FFE2DF";
export const nestedRowOdd = "#FFD9D3";

export const selectedRow = "#FABE92";
export const selectedRowEdge = "#DCB08B";
