import React, { ReactElement } from "react";

import Baseball from "_react/shared/ui/icons/Baseball";
import BaseballBat from "_react/shared/ui/icons/BaseballBat";

export const AMA_PLAYER_TYPE_LABEL_MAP = {
	batter: "Position Player",
	pitcher: "Pitcher"
} as Record<string, string>;

const ICON_STYLE = {
	marginRight: 1,
	color: "gray",
	height: 13,
	width: 13
};
export const AMA_PLAYER_TYPE_ICON_MAP = {
	batter: <BaseballBat sx={ICON_STYLE} />,
	pitcher: <Baseball sx={ICON_STYLE} />
} as Record<string, ReactElement>;

export const THROWS_S = "S";
