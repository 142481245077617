import React, { FC, CSSProperties } from "react";
import styled from "@emotion/styled";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/core";

type TextProps = {
	size?: CSSProperties["fontSize"];
	padding?: CSSProperties["padding"];
	children: React.ReactNode;
};

type TextGroupProps = {
	style?: object;
	Icon?: React.ComponentType;
	children?: React.ReactNode;
};

type TextPairProps = {
	title: string;
	body: string | React.ReactNode;
	style?: object;
	height?: CSSProperties["height"];
};

export const HeavyText = styled("div")<TextProps>`
	font-weight: 800;
	color: rgb(33, 33, 33);
	font-size: ${props => props.size || "13px"};
	padding: ${props => props.padding || ""};
`;

export const MediumText = styled("div")<TextProps>`
	font-weight: 500;
	#color: rgb(74, 74, 74);
	color: black;
	font-size: ${props => props.size || "13px"};
	padding: ${props => props.padding || ""};
`;

export const LightText = styled("div")<TextProps>`
	font-weight: 500;
	color: rgb(115, 115, 115);
	font-size: ${props => props.size || "13px"};
	padding: ${props => props.padding || ""};
`;

export const LabelText = styled("div")<TextProps>`
	font-weight: 700;
	color: rgb(115, 115, 115);
	font-size: ${props => props.size || "11px"};
	padding: ${props => props.padding || ""};
`;

export const ContentText = styled("div")<TextProps>`
	font-weight: 500;
	color: black;
	font-size: ${props => props.size || "12px"};
	padding: ${props => props.padding || ""};
`;

export const StyledTextGroup = styled("div")`
	position: relative;
	display: flex;
	height: 30px;
	justify-content: space-between;
	& > div:not(:first-of-type):not(:last-of-type) {
		padding: 0px 5px 0px 5px;
	}
	& > div:first-of-type:not(:last-of-type) {
		padding: 0px 5px 0px 0px;
	}
	& > div:last-of-type:not(:first-of-type) {
		padding: 0px 0px 0px 5px;
	}
`;

export const StyledTextGroupIcon = css`
	position: absolute;
	left: 0px;
	top: 2px;
	width: 12px;
	height: 12px;
	color: rgb(33, 33, 33);
`;

export const TextPair: FC<TextPairProps> = ({ title, style = {}, body, height = 30 }) => {
	return (
		<div style={{ ...style, height }}>
			<LabelText>{title}</LabelText>
			<ContentText>{body}</ContentText>
		</div>
	);
};

export const TextGroup: FC<TextGroupProps> = ({ children, style = {}, Icon }) => {
	return (
		<div style={{ ...style, position: "relative" }}>
			{Icon && <Icon css={StyledTextGroupIcon} />}
			<StyledTextGroup style={{ marginLeft: Icon ? "20px" : "0px" }}>{children}</StyledTextGroup>
		</div>
	);
};
