import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import { IPlayerBasic, IPlayerPitchType, IPlayerDupeId } from "_react/shared/data_models/player/_types";
// TODO move these types to `_react/shared/data_models/player/_types`
import { BattingAdvancePlayer } from "_react/advance/batting/_types";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";

export type TPlayerGetQueryParams = {
	id: number;
	playerClassification?: string;
	isUsePhilId?: boolean;
	isUseCombinedId?: boolean;
	isBasicSchema?: boolean;
	isBattingAdvanceSchema?: boolean;
	// isApolloSchema?: boolean; // TODO: Define response interface for isApolloSchema
	fields?: string;
};

export type TPlayerLegacyGetQueryParams = {
	philId: number;
	viewClassification?: string;
};

export type TPlayerLegacyViaPhilIdGetQueryParams = {
	philId: number;
	modelType?: string;
};

export type TPlayerPitchTypesGetQueryParams = {
	playerId?: number;
	"playerId[in]"?: string;
	season?: number;
	"season[in]"?: string;
	"season[gte]"?: number;
	"season[lte]"?: number;
	isCurrentSeason?: boolean;
	pitchType?: string;
	"pitchType[in]"?: string;
	nThrown?: number;
	"nThrown[gte]"?: number;
	"nThrown[lte]"?: number;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
};

export type TPlayerDupeIdsGetQueryParams = {
	idType: string;
	targetId?: number;
	dupeId?: number;
};

export const fetchPlayer = <T>(queryParams: TPlayerGetQueryParams, cancelToken?: CancelToken) => {
	const path = "/player";
	if (queryParams.fields) return fetchResource<TPlayerGetQueryParams, T>(path, queryParams, cancelToken);
	if (queryParams.isBasicSchema)
		return fetchResource<TPlayerGetQueryParams, IPlayerBasic>(path, queryParams, cancelToken);
	if (queryParams.isBattingAdvanceSchema)
		return fetchResource<TPlayerGetQueryParams, BattingAdvancePlayer>(path, queryParams, cancelToken);
	return fetchResource<TPlayerGetQueryParams, TPlayerPageCombinedPlayer>(path, queryParams, cancelToken);
};

export const fetchPlayerFromPhilId = (queryParams: TPlayerLegacyViaPhilIdGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TPlayerLegacyViaPhilIdGetQueryParams, TPlayerPageCombinedPlayer>(
		`/player/${queryParams.philId}`,
		queryParams,
		cancelToken
	);

export const fetchPlayerLegacy = <T>(queryParams: TPlayerLegacyGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TPlayerLegacyGetQueryParams, T>(`/player/${queryParams.philId}`, queryParams, cancelToken);

export const fetchPlayerPitchTypes = (queryParams: TPlayerPitchTypesGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TPlayerPitchTypesGetQueryParams, Array<IPlayerPitchType>>(
		"/player/pitch_type",
		queryParams,
		cancelToken
	);

export const fetchPlayerDupeIds = (queryParams: TPlayerDupeIdsGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TPlayerDupeIdsGetQueryParams, Array<IPlayerDupeId>>("/player/dupe", queryParams, cancelToken);
