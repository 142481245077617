import React from "react";
import { Text } from "@chakra-ui/react";
import { OrgNameBox } from "_react/promodel/leaderboard/_styles";

type TPlayerTeamCellProps = {
	playerFirstName?: string | null;
	playerLastName?: string | null;
	teamName?: string | null;
	nullFillerText?: string;
};

const PlayerTeamCell = ({ playerFirstName, playerLastName, teamName, nullFillerText = "" }: TPlayerTeamCellProps) => {
	return (
		<Text>
			{playerLastName ?? nullFillerText}, {playerFirstName ?? nullFillerText}
			<OrgNameBox>{teamName ?? nullFillerText}</OrgNameBox>
		</Text>
	);
};

export default PlayerTeamCell;
