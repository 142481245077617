import React from "react";
import dayjs from "dayjs";
import PitcherToolsTable from "_react/evals/PitcherToolsTable";
import BioFields from "_react/evals/BioFields";
import AmaPlayerToolsTable from "_react/evals/ama/AmaPlayerToolsTable";
import { isIntegerInput, isValidDate } from "utils/functions";
import { checkSeenDate, addInnings, isNumeric } from "_react/evals/helpers";
import {
	PLAYER_AMA_POSITION_OPTS,
	PITCHER_POSITION_OPTS,
	OFP_OPTS,
	GRADE_OPTS,
	INTEREST_OPS,
	MISC_OPTS,
	RISK_OPTS,
	EVAL_MISC_TEXT_FIELDS,
	EVAL_MISC_TEXT_FIELDS_LEG,
	HITTER_POTENTIAL_OPTIONS,
	PITCHER_POTENTIAL_OPTIONS,
	PROSPECT_TYPE_OPTS,
	DEFAULT_PITCH_TYPES,
	DEFAULT_PITCH_TYPES_OTH3,
	MAKEUP_FIELDS,
	MAKEUP_LEG_FIELDS,
	MISC_FIELDS_PITCH,
	MISC_FIELDS_HIT,
	SNAP_SUM_FIELDS,
	OTH_PITCH_OPTS,
	ARM_ANGLE_OPTS,
	PLAYER_TOOL_FIELDS,
	HIT_TYPE_OPTS,
	PHYSICAL_TOOLS_FIELDS,
	HITTING_FIELDS,
	FIELDING_FIELDS,
	PHYSICAL_ATTRIBUTES_FIELDS,
	COMFORT_LEVEL_OPTS,
	PITCHING_ATTRIBUTES_FIELDS,
	ARM_SPEED_OPTS,
	GROUP_OPTS,
	GROUP_OPTS_LEGACY,
	CHANCE_OPTS,
	FB_MOV_OPTS,
	PROSPECT_IDEALS_OPTS
} from "_react/evals/_constants";

// eslint-disable-next-line no-unused-vars
const BIO_FIELDS_LEG = evaluation => ({
	title: `${evaluation.eval_report_type === "FRE" ? "Amateur" : "Follow"} ${
		evaluation.eval_type === "AMA_PITCH" ? "Pitcher" : "Position Player"
	} Report`,
	mobileTab: "Bio",
	sections: [
		{
			objects: [
				{
					id: "position",
					label: "Primary Position Reported",
					type: "autocomplete",
					options: evaluation.eval_type === "AMA_PITCH" ? PITCHER_POSITION_OPTS : PLAYER_AMA_POSITION_OPTS,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "additional_positions",
					label: "Additional Position(s) Reported",
					type: "autocompletemulti",
					options: evaluation.eval_type === "AMA_PITCH" ? PITCHER_POSITION_OPTS : PLAYER_AMA_POSITION_OPTS,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "interest_level",
					label: "Interest",
					type: "autocomplete",
					numCharToShowSuggestions: 0,
					options: INTEREST_OPS,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "seen_date",
					label: "Seen Date",
					type: "date",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "eval_create_date",
					label: "Report Date",
					type: "date",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				}
			]
		},
		{
			objects: [
				{
					id: "innings_seen_thirds",
					label: "Innings Seen",
					type: "text",
					validation: [{ validate: isNumeric, message: "Must be a number" }],
					show: evaluation.eval_type === "AMA_PITCH",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "total_innings_seen_thirds",
					label: "Total Innings",
					type: "label",
					show: evaluation.eval_type === "AMA_PITCH",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "games_seen",
					label: "Games Seen",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "plate_appearances_seen",
					label: "Plate Appearances Seen",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "total_plate_appearances_seen",
					label: "Total Plate Appearances Seen",
					type: "label",
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "workouts",
					label: "Workouts",
					type: "text",
					validation: [{ validate: isNumeric, message: "Must be a number" }],
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "60_yard",
					label: "60 Yard Time",
					type: "text",
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				}
			]
		}
	],
	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
});

// eslint-disable-next-line no-unused-vars
const BIO_FIELDS = evaluation => ({
	title: `${
		evaluation.eval_report_type === "FRE"
			? "Amateur"
			: evaluation.eval_report_type === "FOL"
			? "Follow"
			: evaluation.eval_report_type === "LVR"
			? "Limited View"
			: "Video"
	} ${evaluation.eval_type === "AMA_PITCH" ? "Pitcher" : "Position Player"} Report`,
	mobileTab: "Bio",
	displayComponent: <BioFields evaluation={evaluation} />,
	sections: [
		{
			objects: [
				{
					id: "position",
					label: "Primary Position Seen",
					type: "autocomplete",
					options: evaluation.eval_type === "AMA_PITCH" ? PITCHER_POSITION_OPTS : PLAYER_AMA_POSITION_OPTS,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "additional_positions",
					label: "Additional Position(s) Seen",
					type: "autocompletemulti",
					options: evaluation.eval_type === "AMA_PITCH" ? PITCHER_POSITION_OPTS : PLAYER_AMA_POSITION_OPTS,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "seen_date",
					label: "Seen Date",
					type: "date",
					validation: [
						{
							validate: value => {
								if (!isValidDate(value)) return false;
								const earliestValue = dayjs("2016-01-01");
								const dateValue = dayjs(value);
								const now = dayjs();
								if (now.isBefore(dateValue)) {
									return false;
								} else if (dateValue.isBefore(earliestValue)) {
									return false;
								} else {
									return true;
								}
							},
							message: "Must be a valid date, today or earlier"
						}
					],
					show: evaluation.eval_report_type !== "LVR",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "seen_date_reference",
					label: "Last Seen Date",
					type: "date",
					show: evaluation.eval_report_type === "LVR",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "eval_create_date",
					label: "Report Date",
					type: "date",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				}
			]
		},
		{
			objects: [
				{
					type: "none",
					label: "Seen",
					partialWidth: evaluation.eval_type === "AMA_PITCH" ? 7 : 5,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "games_seen",
					placeholder: "G",
					type: "text",
					partialWidth: 1,
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" },
					style: { maxWidth: "45px" }
				},
				{
					id: "innings_seen_thirds",
					partialWidth: 1,
					placeholder: "IP",
					type: "text",
					validation: [{ validate: isNumeric, message: "Must be a number" }],
					show: evaluation.eval_type === "AMA_PITCH",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" },
					style: { maxWidth: "45px" }
				},
				{
					id: "hits_seen",
					partialWidth: 1,
					placeholder: "H",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					show: evaluation.eval_type === "AMA_PITCH",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" },
					style: { maxWidth: "45px" }
				},
				{
					id: "strikeouts_seen",
					partialWidth: 1,
					placeholder: "K",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					show: evaluation.eval_type === "AMA_PITCH",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" },
					style: { maxWidth: "45px" }
				},
				{
					id: "walks_seen",
					partialWidth: 1,
					placeholder: "BB",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					show: evaluation.eval_type === "AMA_PITCH",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" },
					style: { maxWidth: "45px" }
				},
				{
					id: "plate_appearances_seen",
					partialWidth: 1,
					style: { maxWidth: "35px" },
					placeholder: "PA",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "at_bats_seen",
					partialWidth: 1,
					style: { maxWidth: "35px" },
					placeholder: "AB",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "hits_seen",
					partialWidth: 1,
					style: { maxWidth: "35px" },
					placeholder: "H",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "extra_base_hits_seen",
					partialWidth: 1,
					style: { maxWidth: "35px" },
					placeholder: "XBH",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "strikeouts_seen",
					partialWidth: 1,
					style: { maxWidth: "35px" },
					placeholder: "K",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "walks_seen",
					partialWidth: 1,
					style: { maxWidth: "35px" },
					placeholder: "BB",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					],
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},

				{
					type: "none",
					label: "Total Seen",
					partialWidth: evaluation.eval_type === "AMA_PITCH" ? 7 : 5,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "total_games_seen",
					placeholder: "G",
					type: "label",
					value: evaluation.eval_report_type === "FOL" ? "-" : evaluation.total_games_seen,
					partialWidth: 1,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" },
					style: { maxWidth: "45px" }
				},
				{
					id: "total_innings_seen_thirds",
					partialWidth: 1,
					placeholder: "IP",
					value: evaluation.eval_report_type === "FOL" ? "-" : evaluation.total_innings_seen_thirds,
					type: "label",
					show: evaluation.eval_type === "AMA_PITCH",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" },
					style: { maxWidth: "45px" }
				},
				{
					id: "total_hits_seen",
					partialWidth: 1,
					placeholder: "H",
					value: evaluation.eval_report_type === "FOL" ? "-" : evaluation.total_hits_seen,
					type: "label",
					show: evaluation.eval_type === "AMA_PITCH",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" },
					style: { maxWidth: "45px" }
				},
				{
					id: "total_strikeouts_seen",
					partialWidth: 1,
					placeholder: "K",
					value: evaluation.eval_report_type === "FOL" ? "-" : evaluation.total_strikeouts_seen,
					type: "label",
					show: evaluation.eval_type === "AMA_PITCH",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" },
					style: { maxWidth: "45px" }
				},
				{
					id: "total_walks_seen",
					partialWidth: 1,
					placeholder: "BB",
					value: evaluation.eval_report_type === "FOL" ? "-" : evaluation.total_walks_seen,
					type: "label",
					show: evaluation.eval_type === "AMA_PITCH",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" },
					style: { maxWidth: "45px" }
				},
				{
					id: "total_plate_appearances_seen",
					partialWidth: 1,
					style: { maxWidth: "35px" },
					placeholder: "PA",
					value: evaluation.eval_report_type === "FOL" ? "-" : evaluation.total_plate_appearances_seen,
					type: "label",
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "total_at_bats_seen",
					partialWidth: 1,
					style: { maxWidth: "45px" },
					placeholder: "AB",
					value: evaluation.eval_report_type === "FOL" ? "-" : evaluation.total_at_bats_seen,
					type: "label",
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "total_hits_seen",
					partialWidth: 1,
					style: { maxWidth: "35px" },
					placeholder: "H",
					value: evaluation.eval_report_type === "FOL" ? "-" : evaluation.total_hits_seen,
					type: "label",
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "total_extra_base_hits_seen",
					partialWidth: 1,
					style: { maxWidth: "35px" },
					placeholder: "XBH",
					value: evaluation.eval_report_type === "FOL" ? "-" : evaluation.total_extra_base_hits_seen,
					type: "label",
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "total_strikeouts_seen",
					partialWidth: 1,
					style: { maxWidth: "35px" },
					placeholder: "K",
					value: evaluation.eval_report_type === "FOL" ? "-" : evaluation.total_strikeouts_seen,
					type: "label",
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "total_walks_seen",
					partialWidth: 1,
					style: { maxWidth: "35px" },
					placeholder: "BB",
					value: evaluation.eval_report_type === "FOL" ? "-" : evaluation.total_walks_seen,
					type: "label",
					show: evaluation.eval_type === "AMA_HIT",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "game_view_comments",
					label: "Game View Comments",
					multiline: true,
					type: "text",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				}
			]
		}
	],
	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
});

// eslint-disable-next-line no-unused-vars
const PHYSICAL_TOOLS = (evaluation, startAdornment) => {
	let objects = [...PHYSICAL_TOOLS_FIELDS]
		.filter(field => {
			if (evaluation.eval_report_type === "AVR" && field.id === "raw_power") return false;
			return true;
		})
		.map(field => {
			return [
				{
					type: "none",
					label: field.name,
					partialWidth: 1,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: `${field.id}_present`,
					type: "autocomplete",
					options: GRADE_OPTS,
					partialWidth: 6,
					numCharToShowSuggestions: 0,
					placeholder: "Present",
					preventMobileBreak: true,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: `${field.id}_future`,
					type: "autocomplete",
					options: GRADE_OPTS,
					partialWidth: 6,
					numCharToShowSuggestions: 0,
					placeholder: "Future",
					preventMobileBreak: true,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				}
			];
		});
	objects = objects.flat(1);

	objects.splice(9, 0, {
		id: "home_to_1b",
		label: "Home to 1B Time",
		type: "text",
		show: evaluation.eval_type === "AMA_HIT",
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	if (evaluation.eval_report_type !== "AVR")
		objects.splice(10, 0, {
			id: "60_yard",
			label: "60 Yard Time",
			type: "text",
			validation: [{ validate: isNumeric, message: "Must be a number" }],
			show: evaluation.eval_type === "AMA_HIT",
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		});

	objects.push({
		id: "physical_tools_comments",
		type: "text",
		partialWidth: 3,
		placeholder: "Comments",
		multiline: true,
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	return {
		title: "Physical Tools",
		mobileTab: "Physical Tools",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.filter(e => e !== null) : []
			}
		],
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const HITTING = (evaluation, startAdornment) => {
	let objects = HITTING_FIELDS.map(field => {
		if (evaluation.eval_report_type === "LVR" && ["pitch_recognition", "calmness_box_presence"].includes(field.id))
			return [];
		if (field.id === "power_production") {
			return [
				{
					type: "none",
					label: field.name,
					partialWidth: 1,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: `${field.id}_future`,
					type: "autocomplete",
					options: GRADE_OPTS,
					partialWidth: 12,
					numCharToShowSuggestions: 0,
					placeholder: "Future",
					preventMobileBreak: true,
					containerStyle: { fontSize: "14px", width: "50%", marginLeft: "44px", float: "right" },
					objectStyle: { fontSize: "14px", width: "50%", marginLeft: "44px", float: "right" }
				}
			];
		}
		return [
			{
				type: "none",
				label: field.name,
				partialWidth: 1,
				containerStyle: { fontSize: "14px", flex: null },
				objectStyle: { fontSize: "14px" }
			},
			{
				id: `${field.id}_present`,
				type: "autocomplete",
				options: GRADE_OPTS,
				partialWidth: 6,
				numCharToShowSuggestions: 0,
				placeholder: "Present",
				preventMobileBreak: true,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			},
			{
				id: `${field.id}_future`,
				type: "autocomplete",
				options: GRADE_OPTS,
				partialWidth: 6,
				numCharToShowSuggestions: 0,
				placeholder: "Future",
				preventMobileBreak: true,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			}
		];
	});
	objects = objects.flat(1);

	objects.push({
		id: "hit_type",
		label: "Hitter Type",
		type: "autocomplete",
		options: HIT_TYPE_OPTS,
		numCharToShowSuggestions: 0,
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	objects.push({
		id: "hitting_comments",
		type: "text",
		partialWidth: 3,
		placeholder: "Comments",
		multiline: true,
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	return {
		title: "Hitting",
		mobileTab: "Hitting",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.filter(e => e !== null) : []
			}
		],
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const FIELDING = (evaluation, startAdornment) => {
	let objects = FIELDING_FIELDS.map(field => {
		if (evaluation.eval_report_type === "LVR" && ["arm_accuracy", "hands", "feet"].includes(field.id)) return [];
		if (evaluation.eval_report_type === "AVR" && ["arm_accuracy", "feet", "fielding_range"].includes(field.id))
			return [];
		return [
			{
				type: "none",
				label: field.name,
				partialWidth: 1,
				containerStyle: { fontSize: "14px", flex: null },
				objectStyle: { fontSize: "14px" }
			},
			{
				id: `${field.id}_present`,
				type: "autocomplete",
				options: GRADE_OPTS,
				partialWidth: 6,
				numCharToShowSuggestions: 0,
				placeholder: "Present",
				preventMobileBreak: true,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			},
			{
				id: `${field.id}_future`,
				type: "autocomplete",
				options: GRADE_OPTS,
				partialWidth: 6,
				numCharToShowSuggestions: 0,
				placeholder: "Future",
				preventMobileBreak: true,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			}
		];
	});
	objects = objects.flat(1);

	objects.splice(0, 0, {
		id: "position_2",
		label: "Position 2",
		placeholder: "Alternate/Best Case Position",
		type: "autocompletemulti",
		options: evaluation.eval_type === "AMA_PITCH" ? PITCHER_POSITION_OPTS : PLAYER_AMA_POSITION_OPTS,
		numCharToShowSuggestions: 0,
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	objects.splice(0, 0, {
		id: "position_1",
		label: "Position 1",
		placeholder: "Primary MLB Position",
		type: "autocomplete",
		options: evaluation.eval_type === "AMA_PITCH" ? PITCHER_POSITION_OPTS : PLAYER_AMA_POSITION_OPTS,
		numCharToShowSuggestions: 0,
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	objects.push({
		id: "fielding_comments",
		type: "text",
		partialWidth: 3,
		placeholder: "Comments",
		multiline: true,
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	return {
		title: "Fielding",
		mobileTab: "Fielding",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.filter(e => e !== null) : []
			}
		],
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const PHYSICAL_ATTRIBUTES = (evaluation, startAdornment) => {
	let objects = [...PHYSICAL_ATTRIBUTES_FIELDS]
		.filter(field => {
			if (
				evaluation.eval_report_type === "AVR" &&
				["ease_of_actions", "body_control", "explosiveness"].includes(field.id)
			)
				return false;
			return true;
		})
		.map(field => {
			if (field.id === "strength" || field.id === "ath") {
				return [
					{
						type: "none",
						label: field.name,
						partialWidth: 1,
						containerStyle: { fontSize: "14px", flex: null },
						objectStyle: { fontSize: "14px" }
					},
					{
						id: `${field.id}_present`,
						type: "autocomplete",
						options: GRADE_OPTS,
						partialWidth: 6,
						numCharToShowSuggestions: 0,
						placeholder: "Present",
						preventMobileBreak: true,
						containerStyle: { fontSize: "14px" },
						objectStyle: { fontSize: "14px" }
					},
					{
						id: `${field.id}_future`,
						type: "autocomplete",
						options: GRADE_OPTS,
						partialWidth: 6,
						numCharToShowSuggestions: 0,
						placeholder: "Future",
						preventMobileBreak: true,
						containerStyle: { fontSize: "14px" },
						objectStyle: { fontSize: "14px" }
					}
				];
			}
			return {
				id: `${field.id}_present`,
				type: "autocomplete",
				options: GRADE_OPTS,
				label: field.name,
				numCharToShowSuggestions: 0,
				placeholder: "Grade",
				preventMobileBreak: true,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			};
		});
	objects = objects.flat(1);

	objects = [
		...objects,
		{
			id: "ath_body_overall_proj",
			type: "autocomplete",
			label: "Overall Projection",
			options: GRADE_OPTS,
			numCharToShowSuggestions: 0,
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "player_comparison",
			type: "text",
			label: "Body Comparison",
			multiline: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "physical_attributes_comments",
			type: "text",
			partialWidth: 3,
			placeholder: "Comments",
			multiline: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		}
	];

	return {
		title: "Body/Athleticism",
		mobileTab: "Body/Athleticism",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.filter(e => e !== null) : []
			}
		],
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const PITCHING_ATTRIBUTES = (evaluation, startAdornment) => {
	let objects = PITCHING_ATTRIBUTES_FIELDS.map(field => {
		if (field.id === "arm_speed")
			return [
				{
					label: field.name,
					id: field.id,
					type: "autocomplete",
					options: ARM_SPEED_OPTS,
					numCharToShowSuggestions: 0,
					preventMobileBreak: true,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				}
			];
		return [
			{
				type: "none",
				label: field.name,
				partialWidth: 1,
				containerStyle: { fontSize: "14px", flex: null },
				objectStyle: { fontSize: "14px" }
			},
			{
				id: `${field.id}_present`,
				type: "autocomplete",
				options: GRADE_OPTS,
				partialWidth: 6,
				numCharToShowSuggestions: 0,
				placeholder: "Present",
				preventMobileBreak: true,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			},
			{
				id: `${field.id}_future`,
				type: "autocomplete",
				options: GRADE_OPTS,
				partialWidth: 6,
				numCharToShowSuggestions: 0,
				placeholder: "Future",
				preventMobileBreak: true,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			}
		];
	});
	objects = objects.flat(1);

	objects.splice(0, 0, {
		id: "angle",
		label: "Arm Angle",
		type: "autocomplete",
		options: ARM_ANGLE_OPTS,
		numCharToShowSuggestions: 0,
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	objects.push({
		id: "pitching_attributes_comments",
		type: "text",
		partialWidth: 3,
		placeholder: "Comments",
		multiline: true,
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	return {
		title: "Pitching Attributes",
		mobileTab: "Pitching",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.filter(e => e !== null) : []
			}
		],
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const PHYSICAL_QUALITIES = (evaluation, startAdornment) => {
	const objects = [
		{
			type: "none",
			label: "Strength",
			partialWidth: 1,
			containerStyle: { fontSize: "14px", flex: null },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "strength_present",
			type: "autocomplete",
			options: GRADE_OPTS,
			partialWidth: 6,
			numCharToShowSuggestions: 0,
			placeholder: "Present",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "strength_future",
			type: "autocomplete",
			options: GRADE_OPTS,
			partialWidth: 6,
			numCharToShowSuggestions: 0,
			placeholder: "Future",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			type: "none",
			label: "Overall Athleticism",
			partialWidth: 1,
			containerStyle: { fontSize: "14px", flex: null },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "ath_present",
			type: "autocomplete",
			options: GRADE_OPTS,
			partialWidth: 6,
			numCharToShowSuggestions: 0,
			placeholder: "Present",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "ath_future",
			type: "autocomplete",
			options: GRADE_OPTS,
			partialWidth: 6,
			numCharToShowSuggestions: 0,
			placeholder: "Future",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "ath_body_overall_proj",
			type: "autocomplete",
			label: "Overall Projection",
			options: GRADE_OPTS,
			numCharToShowSuggestions: 0,
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "player_comparison",
			type: "text",
			label: "Body Comparison",
			multiline: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		}
	];

	objects.push({
		id: "physical_qualities_comments",
		type: "text",
		partialWidth: 3,
		placeholder: "Comments",
		multiline: true,
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	return {
		title: "Body/Athleticism",
		mobileTab: "Physical",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.filter(e => e !== null) : []
			}
		],
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const GROUP_OFP = (evaluation, startAdornment) => {
	const objects = [
		{
			id: "group",
			type: "autocomplete",
			label: "Group",
			options: dayjs(evaluation.eval_create_date).year() >= 2022 ? GROUP_OPTS : GROUP_OPTS_LEGACY,
			numCharToShowSuggestions: 0,
			// show: evaluation.eval_report_type === "FRE",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "ofp",
			label: "OFP",
			type: "autocomplete",
			options: OFP_OPTS,
			numCharToShowSuggestions: 0,
			show: evaluation.eval_report_type === "FRE",
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "risk",
			label: "Risk",
			type: "autocomplete",
			show:
				dayjs(evaluation.eval_create_date).year() < 2022 &&
				["FRE", "FOL"].includes(evaluation.eval_report_type),
			options: RISK_OPTS,
			numCharToShowSuggestions: 0,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "comfort_level",
			label: "Comfort Level",
			type: "autocomplete",
			show:
				dayjs(evaluation.eval_create_date).year() < 2022 &&
				["FRE", "FOL"].includes(evaluation.eval_report_type),
			options: COMFORT_LEVEL_OPTS,
			numCharToShowSuggestions: 0,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "round_recommendation",
			label: "Round Recommendation",
			type: "text",
			show: evaluation.eval_report_type !== "FOL",
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "prospect_ideals",
			type: "autocompletemulti",
			label: "Prospect Ideals",
			options: PROSPECT_IDEALS_OPTS(evaluation.eval_type),
			numCharToShowSuggestions: 0,
			show: evaluation.eval_report_type === "FRE",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		}
	];

	return {
		title: "Group/OFP",
		mobileTab: "Group/OFP",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.filter(e => e !== null) : []
			}
		],
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const FASTBALL = (evaluation, startAdornment) => {
	let objects = [
		{
			type: "none",
			label: "FB Velocity",
			partialWidth: 1,
			containerStyle: { fontSize: "14px", flex: null },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: `fb_vel_present`,
			type: "autocomplete",
			options: GRADE_OPTS,
			numCharToShowSuggestions: 0,
			partialWidth: 2,
			style: { minWidth: "75px" },
			placeholder: "Present",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: `fb_vel_future`,
			type: "autocomplete",
			options: GRADE_OPTS,
			numCharToShowSuggestions: 0,
			partialWidth: 2,
			style: { minWidth: "75px" },
			placeholder: "Future",
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "fb_vel_low",
			type: "text",
			partialWidth: 2,
			style: { minWidth: "35px" },
			placeholder: "MPH",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "fb_vel_high",
			type: "text",
			partialWidth: 2,
			style: { minWidth: "35px" },
			placeholder: "MPH",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "fb_vel_comfort_zone_low",
			type: "text",
			partialWidth: 2,
			style: { minWidth: "35px" },
			placeholder: "Comfort",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "fb_vel_comfort_zone_high",
			type: "text",
			partialWidth: 2,
			style: { minWidth: "35px" },
			placeholder: "Comfort",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			type: "none",
			label: "FB Spin Rate",
			partialWidth: 1,
			containerStyle: { fontSize: "14px", flex: null },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "fb_spin_low",
			type: "text",
			partialWidth: 10,
			style: { minWidth: "35px" },
			placeholder: "RPM",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "fb_spin_high",
			type: "text",
			partialWidth: 10,
			style: { minWidth: "35px" },
			placeholder: "RPM",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		}
	];

	[
		{ label: "FB Movement", key: "mov" },
		{ label: "FB Command", key: "cmd" },
		{ label: "Overall Fastball", key: "overall" }
	].map(e => {
		objects = [
			...objects,
			...[
				{
					type: "none",
					label: e.label,
					partialWidth: 1,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: `fb_${e.key}_present`,
					type: "autocomplete",
					options: GRADE_OPTS,
					numCharToShowSuggestions: 0,
					partialWidth: e.key === "mov" ? 4 : 6,
					style: { maxWidth: "75px" },
					placeholder: "Present",
					preventMobileBreak: true,
					containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: `fb_${e.key}_future`,
					type: "autocomplete",
					options: GRADE_OPTS,
					numCharToShowSuggestions: 0,
					partialWidth: e.key === "mov" ? 4 : 6,
					style: { maxWidth: "75px" },
					placeholder: "Future",
					containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
					objectStyle: { fontSize: "14px" }
				}
			],
			...(e.key === "mov"
				? [
						{
							id: "fb_mov",
							type: "autocompletemulti",
							placeholder: "Description...",
							options: FB_MOV_OPTS,
							numCharToShowSuggestions: 0,
							partialWidth: 4,
							preventMobileBreak: true,
							containerStyle: { fontSize: "14px" },
							objectStyle: { fontSize: "14px" }
						}
				  ]
				: [])
		];
		return null;
	});

	objects.push({
		id: "fb_vel_comments",
		type: "text",
		placeholder: "Comments",
		multiline: true,
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	return {
		title: "Fastball",
		mobileTab: "Fastball",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.filter(e => e !== null) : []
			}
		],
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const SECONDARY_PITCHES = (evaluation, startAdornment) => {
	const objects = DEFAULT_PITCH_TYPES_OTH3.map(pitchType => {
		return [
			{ label: pitchType.name, type: "grade", key: "none" },
			// { label: `Velocity`, type: "vel", key: "vel" },
			{ label: `Command`, type: "grade", key: "cmd" },
			{ label: `Spin Rate`, type: "range", key: "spin" }
		].map(row => {
			if (pitchType.abb === "FB") return null;

			// OTH issue (TODO: Fix in DB)
			if ((row.key === "cmd" || row.key === "spin") && pitchType.abb === "OTH_PITCH") {
				pitchType.abb = "OTH";
			} else if (row.key !== "cmd" && pitchType.abb === "OTH") {
				pitchType.abb = "OTH_PITCH";
			}

			if ((row.key === "cmd" || row.key === "spin") && pitchType.abb === "OTH2_PITCH") {
				pitchType.abb = "OTH2";
			} else if (row.key !== "cmd" && pitchType.abb === "OTH2") {
				pitchType.abb = "OTH2_PITCH";
			}

			if ((row.key === "cmd" || row.key === "spin") && pitchType.abb === "OTH3_PITCH") {
				pitchType.abb = "OTH3";
			} else if (row.key !== "cmd" && pitchType.abb === "OTH3") {
				pitchType.abb = "OTH3_PITCH";
			}

			let rows = [];
			if (pitchType.abb === "OTH_PITCH" && row.label === pitchType.name) {
				rows.push({
					id: "oth_pitch_type",
					type: "autocomplete",
					options: OTH_PITCH_OPTS,
					placeholder: "Select Pitch",
					partialWidth: 3,
					numCharToShowSuggestions: 0,
					containerStyle: {
						fontSize: "14px",
						flex: null,
						width: "134px",
						marginRight: "-20px"
					},
					objectStyle: { fontSize: "14px", width: "134px" }
				});
			} else if (pitchType.abb === "OTH_PITCH" || pitchType.abb === "OTH") {
				rows.push({
					type: "none",
					label: `${evaluation.oth_pitch_type ? `${evaluation.oth_pitch_type} ` : ""}${row.label}`,
					partialWidth: 3,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				});
			} else if (
				!(pitchType.abb === "OTH2_PITCH" || pitchType.abb === "OTH2") &&
				!(pitchType.abb === "OTH3_PITCH" || pitchType.abb === "OTH3")
			) {
				rows.push({
					type: "none",
					label: row.label === pitchType.name ? pitchType.name : `${pitchType.abb} ${row.label}`,
					partialWidth: pitchType.abb === "FB" && row.type === "vel" ? 1 : 3,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				});
			}

			if (
				evaluation.oth_pitch_type != null &&
				evaluation.oth_pitch_type !== "" &&
				(pitchType.abb === "OTH2_PITCH" || pitchType.abb === "OTH2")
			) {
				if (pitchType.abb === "OTH2_PITCH" && row.label === pitchType.name) {
					rows.push({
						id: "oth2_pitch_type",
						type: "autocomplete",
						options: OTH_PITCH_OPTS,
						placeholder: "Select Pitch",
						partialWidth: 3,
						numCharToShowSuggestions: 0,
						containerStyle: {
							fontSize: "14px",
							flex: null,
							width: "134px",
							marginRight: "-20px"
						},
						objectStyle: { fontSize: "14px", width: "134px" },
						show: evaluation.oth_pitch_type != null
					});
				} else if (pitchType.abb === "OTH2_PITCH" || pitchType.abb === "OTH2") {
					rows.push({
						type: "none",
						label: `${evaluation.oth2_pitch_type ? `${evaluation.oth2_pitch_type} ` : ""}${row.label}`,
						partialWidth: 3,
						containerStyle: { fontSize: "14px", flex: null },
						objectStyle: { fontSize: "14px" },
						show: evaluation.oth2_pitch_type != null
					});
				} else if (!(pitchType.abb === "OTH3_PITCH" || pitchType.abb === "OTH3")) {
					rows.push({
						type: "none",
						label: row.label === pitchType.name ? pitchType.name : `${pitchType.abb} ${row.label}`,
						partialWidth: pitchType.abb === "FB" && row.type === "vel" ? 1 : 3,
						containerStyle: { fontSize: "14px", flex: null },
						objectStyle: { fontSize: "14px" },
						show: evaluation.oth2_pitch_type != null
					});
				}
			}

			if (
				evaluation.oth2_pitch_type != null &&
				evaluation.oth2_pitch_type !== "" &&
				(pitchType.abb === "OTH3_PITCH" || pitchType.abb === "OTH3")
			) {
				if (pitchType.abb === "OTH3_PITCH" && row.label === pitchType.name) {
					rows.push({
						id: "oth3_pitch_type",
						type: "autocomplete",
						options: OTH_PITCH_OPTS,
						placeholder: "Select Pitch",
						partialWidth: 3,
						numCharToShowSuggestions: 0,
						containerStyle: {
							fontSize: "14px",
							flex: null,
							width: "134px",
							marginRight: "-20px"
						},
						objectStyle: { fontSize: "14px", width: "134px" },
						show: evaluation.oth2_pitch_type != null
					});
				} else if (pitchType.abb === "OTH3_PITCH" || pitchType.abb === "OTH3") {
					rows.push({
						type: "none",
						label: `${evaluation.oth3_pitch_type ? `${evaluation.oth3_pitch_type} ` : ""}${row.label}`,
						partialWidth: 3,
						containerStyle: { fontSize: "14px", flex: null },
						objectStyle: { fontSize: "14px" },
						show: evaluation.oth3_pitch_type != null
					});
				} else {
					rows.push({
						type: "none",
						label: row.label === pitchType.name ? pitchType.name : `${pitchType.abb} ${row.label}`,
						partialWidth: pitchType.abb === "FB" && row.type === "vel" ? 1 : 3,
						containerStyle: { fontSize: "14px", flex: null },
						objectStyle: { fontSize: "14px" },
						show: evaluation.oth3_pitch_type != null
					});
				}
			}

			if (row.type === "range") {
				rows = [
					...rows,
					{
						id: `${pitchType.abb.toLowerCase()}_spin_low`,
						type: "text",
						partialWidth: 5,
						style: { minWidth: "35px" },
						placeholder: "RPM",
						preventMobileBreak: true,
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" },
						show:
							((pitchType.abb !== "OTH2_PITCH" && pitchType.abb !== "OTH2") ||
								evaluation.oth2_pitch_type != null) &&
							((pitchType.abb !== "OTH3_PITCH" && pitchType.abb !== "OTH3") ||
								evaluation.oth3_pitch_type != null)
					},
					{
						id: `${pitchType.abb.toLowerCase()}_spin_high`,
						type: "text",
						partialWidth: 5,
						style: { minWidth: "35px" },
						placeholder: "RPM",
						preventMobileBreak: pitchType.abb === "FB" && row.type === "vel",
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" },
						show:
							((pitchType.abb !== "OTH2_PITCH" && pitchType.abb !== "OTH2") ||
								evaluation.oth2_pitch_type != null) &&
							((pitchType.abb !== "OTH3_PITCH" && pitchType.abb !== "OTH3") ||
								evaluation.oth3_pitch_type != null)
					}
				];
			} else if (row.type === "grade") {
				rows = [
					...rows,
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: `_${row.key}`
						}_present`,
						type: "autocomplete",
						options: GRADE_OPTS,
						numCharToShowSuggestions: 0,
						partialWidth: row.label === pitchType.name ? 3 : 6,
						style: { minWidth: "75px" },
						placeholder: "Present",
						preventMobileBreak: true,
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" },
						show:
							((pitchType.abb !== "OTH2_PITCH" && pitchType.abb !== "OTH2") ||
								evaluation.oth2_pitch_type != null) &&
							((pitchType.abb !== "OTH3_PITCH" && pitchType.abb !== "OTH3") ||
								evaluation.oth3_pitch_type != null)
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: `_${row.key}`
						}_future`,
						type: "autocomplete",
						options: GRADE_OPTS,
						numCharToShowSuggestions: 0,
						partialWidth: row.label === pitchType.name ? 3 : 6,
						style: { minWidth: "75px" },
						placeholder: "Future",
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" },
						show:
							((pitchType.abb !== "OTH2_PITCH" && pitchType.abb !== "OTH2") ||
								evaluation.oth2_pitch_type != null) &&
							((pitchType.abb !== "OTH3_PITCH" && pitchType.abb !== "OTH3") ||
								evaluation.oth3_pitch_type != null)
					}
				];
			}
			if (row.label === pitchType.name) {
				rows = [
					...rows,
					{
						id: `${pitchType.abb.toLowerCase()}_vel_low`,
						type: "text",
						partialWidth: 2,
						style: { minWidth: "35px" },
						placeholder: "MPH",
						preventMobileBreak: true,
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" },
						show:
							((pitchType.abb !== "OTH2_PITCH" && pitchType.abb !== "OTH2") ||
								evaluation.oth2_pitch_type != null) &&
							((pitchType.abb !== "OTH3_PITCH" && pitchType.abb !== "OTH3") ||
								evaluation.oth3_pitch_type != null)
					},
					{
						id: `${pitchType.abb.toLowerCase()}_vel_high`,
						type: "text",
						partialWidth: 2,
						style: { minWidth: "35px" },
						placeholder: "MPH",
						preventMobileBreak: pitchType.abb === "FB" && row.type === "vel",
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" },
						show:
							((pitchType.abb !== "OTH2_PITCH" && pitchType.abb !== "OTH2") ||
								evaluation.oth2_pitch_type != null) &&
							((pitchType.abb !== "OTH3_PITCH" && pitchType.abb !== "OTH3") ||
								evaluation.oth3_pitch_type != null)
					}
				];
			}

			return rows;
		});
	});

	objects.push({
		id: "sec_pitch_comments",
		type: "text",
		placeholder: "Comments",
		multiline: true,
		containerStyle: { fontSize: "14px" },
		objectStyle: { fontSize: "14px" }
	});

	return {
		title: "Secondary Pitches",
		mobileTab: "Secondary",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.flat(2).filter(e => e !== null) : []
			}
		],
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const TOOLS_GRADING_STANDARDS_PITCH = (evaluation, startAdornment) => {
	const objects = DEFAULT_PITCH_TYPES.map(pitchType => {
		return [
			{ label: pitchType.name, type: "grade", key: "none" },
			{ label: `Velocity`, type: "vel", key: "vel" },
			{ label: `Movement`, type: "grade", key: "mov" },
			{ label: `Command`, type: "grade", key: "cmd" }
		].map(row => {
			if (pitchType.abb !== "FB" && row.label === "Movement") return null;

			// OTH issue (TODO: Fix in DB)
			if (row.key === "cmd" && pitchType.abb === "OTH_PITCH") {
				pitchType.abb = "OTH";
			} else if (row.key !== "cmd" && pitchType.abb === "OTH") {
				pitchType.abb = "OTH_PITCH";
			}

			let rows = [];
			if (pitchType.abb === "OTH_PITCH" && row.label === pitchType.name) {
				rows.push({
					id: "oth_pitch_type",
					type: "autocomplete",
					options: OTH_PITCH_OPTS,
					placeholder: "Select Pitch",
					partialWidth: 3,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				});
			} else if (pitchType.abb === "OTH_PITCH" || pitchType.abb === "OTH") {
				rows.push({
					type: "none",
					label: `${evaluation.oth_pitch_type ? `${evaluation.oth_pitch_type} ` : ""}${row.label}`,
					partialWidth: 3,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				});
			} else {
				rows.push({
					type: "none",
					label: row.label === pitchType.name ? pitchType.name : `${pitchType.abb} ${row.label}`,
					partialWidth: pitchType.abb === "FB" && row.type === "vel" ? 1 : 3,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				});
			}

			if (row.type === "grade") {
				rows = [
					...rows,
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: `_${row.key}`
						}_present`,
						type: "autocomplete",
						options: GRADE_OPTS,
						numCharToShowSuggestions: 0,
						partialWidth: 3,
						style: { maxWidth: "75px" },
						placeholder: "Present",
						preventMobileBreak: true,
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: `_${row.key}`
						}_future`,
						type: "autocomplete",
						options: GRADE_OPTS,
						numCharToShowSuggestions: 0,
						partialWidth: 3,
						style: { maxWidth: "75px" },
						placeholder: "Future",
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: `_${row.key}`
						}_comments`,
						type: "text",
						partialWidth: 3,
						placeholder: "Comments",
						multiline: true,
						containerStyle: { fontSize: "14px" },
						objectStyle: { fontSize: "14px" }
					}
				];
			} else if (row.type === "vel") {
				rows = [
					...rows,
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_low`,
						type: "text",
						partialWidth: pitchType.abb === "FB" ? 3 : 6,
						style: { maxWidth: "75px" },
						placeholder: "MPH",
						preventMobileBreak: true,
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_high`,
						type: "text",
						partialWidth: pitchType.abb === "FB" ? 3 : 6,
						style: { maxWidth: "75px" },
						placeholder: "MPH",
						preventMobileBreak: pitchType.abb === "FB" && row.type === "vel",
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					}
				];
			}

			if (pitchType.abb === "FB" && row.type === "vel") {
				rows = [
					...rows,
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_comfort_zone_low`,
						type: "text",
						partialWidth: 3,
						style: { maxWidth: "75px" },
						placeholder: "Comfort",
						preventMobileBreak: true,
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_comfort_zone_high`,
						type: "text",
						partialWidth: 3,
						style: { maxWidth: "75px" },
						placeholder: "Comfort",
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					}
				];
			}
			return rows;
		});
	});

	objects.push([
		{ type: "none", label: "CHP / Pitchability", partialWidth: 1 },
		{
			id: evaluation.eval_type === "AMA_PITCH" ? "pitchability_present" : "can_he_play_present",
			type: "autocomplete",
			options: GRADE_OPTS,
			numCharToShowSuggestions: 0,
			partialWidth: 3,
			style: { maxWidth: "75px" },
			placeholder: "Present",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: evaluation.eval_type === "AMA_PITCH" ? "pitchability_future" : "can_he_play_future",
			type: "autocomplete",
			options: GRADE_OPTS,
			numCharToShowSuggestions: 0,
			partialWidth: 3,
			style: { maxWidth: "75px" },
			placeholder: "Future",
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: evaluation.eval_type === "AMA_PITCH" ? "pitchability_comments" : "can_he_play_comments",
			type: "text",
			partialWidth: 3,
			placeholder: "Comments",
			multiline: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		}
	]);

	return {
		title: "Tools Grading Standards",
		mobileTab: "Tools",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.flat(2).filter(e => e !== null) : []
			}
		],
		displayComponent: <PitcherToolsTable evaluation={evaluation} />,
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const TOOLS_GRADING_STANDARDS_HIT = (evaluation, startAdornment) => {
	const objects = PLAYER_TOOL_FIELDS.map(field => {
		return [
			{ type: "none", label: field.name, partialWidth: 3 },
			{
				id: `${field.id}_present`,
				type: "autocomplete",
				options: field.id === "hitting_ability" ? GRADE_OPTS.filter(({ value }) => value <= 50) : GRADE_OPTS,
				numCharToShowSuggestions: 0,
				partialWidth: 3,
				style: { maxWidth: "75px", display: field.id === "power_production" ? "none" : "block" },
				placeholder: "Present",
				preventMobileBreak: true,
				containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
				objectStyle: { fontSize: "14px" }
			},
			{
				id: `${field.id}_future`,
				type: "autocomplete",
				options: GRADE_OPTS,
				numCharToShowSuggestions: 0,
				partialWidth: 3,
				style: { maxWidth: "75px" },
				placeholder: "Future",
				containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
				objectStyle: { fontSize: "14px" }
			},
			{
				id: `${field.id}_comments`,
				type: "text",
				partialWidth: 3,
				placeholder: "Comments",
				multiline: true,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			}
		];
	});

	return {
		title: "Tools Grading Standards",
		mobileTab: "Tools",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.flat(1).filter(e => e !== null) : []
			}
		],
		displayComponent: <AmaPlayerToolsTable evaluation={evaluation} />,
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const MAKEUP = () => ({
	title: "Makeup",
	mobileTab: "Makeup",
	partialWidth: 6,
	sections: [
		{
			objects: MAKEUP_FIELDS.map(rating => ({
				id: rating.id,
				label: rating.name,
				type: "autocomplete",
				options: MISC_OPTS,
				numCharToShowSuggestions: 0,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			}))
		}
	],
	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
});

// eslint-disable-next-line no-unused-vars
const UPSIDE_AND_RISK = evaluation => {
	const objects = [
		{
			id: "ml_chance",
			label: "Chance of Making It to the ML",
			type: "autocomplete",
			options: CHANCE_OPTS,
			numCharToShowSuggestions: 0,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "projections_chance",
			label: "Chance of Fully Making Projections",
			type: "autocomplete",
			options: CHANCE_OPTS,
			numCharToShowSuggestions: 0,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "player_upside",
			label: "Player Upside",
			type: "autocomplete",
			options: evaluation.eval_type === "AMA_PITCH" ? PITCHER_POTENTIAL_OPTIONS : HITTER_POTENTIAL_OPTIONS,
			numCharToShowSuggestions: 0,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "player_outcome",
			label: "What do you think this player will be?",
			type: "autocomplete",
			options: evaluation.eval_type === "AMA_PITCH" ? PITCHER_POTENTIAL_OPTIONS : HITTER_POTENTIAL_OPTIONS,
			numCharToShowSuggestions: 0,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "comfort_level",
			label: "Comfort Level",
			type: "autocomplete",
			options: COMFORT_LEVEL_OPTS,
			numCharToShowSuggestions: 0,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: "upside_and_risk_comments",
			type: "text",
			partialWidth: 3,
			placeholder: "Comments",
			multiline: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		}
	];

	return {
		title: "Upside and Risk",
		mobileTab: "Upside and Risk",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.filter(e => e !== null) : []
			}
		],
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const MAKEUP_LEG = () => ({
	title: "Makeup",
	mobileTab: "Makeup",
	partialWidth: 6,
	sections: [
		{
			objects: MAKEUP_LEG_FIELDS.map(rating => ({
				id: rating.id,
				label: rating.name,
				type: "autocomplete",
				options: MISC_OPTS,
				numCharToShowSuggestions: 0,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			}))
		}
	],
	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
});

// eslint-disable-next-line no-unused-vars
const MISCELLANEOUS_PITCH = evaluation => ({
	title: `Miscellaneous`,
	mobileTab: "Misc",
	partialWidth: 6,
	sections: [
		{
			objects: [
				{
					id: "angle",
					label: "Arm Angle",
					type: "autocomplete",
					options: ARM_ANGLE_OPTS,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				...MISC_FIELDS_PITCH.map(field => ({
					id: field.id,
					label: field.name,
					type: "autocomplete",
					options: MISC_OPTS,
					numCharToShowSuggestions: 0,
					value:
						field.id === "del" && evaluation._del
							? evaluation._del
							: evaluation[field.id]
							? evaluation[field.id]
							: null,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				}))
			]
		}
	],
	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
});

// eslint-disable-next-line no-unused-vars
// const MISCELLANEOUS_HIT = evaluation => ({
// 	title: `Miscellaneous`,
// 	mobileTab: "Misc",
// 	partialWidth: 6,
// 	sections: [
// 		{
// 			objects: [
// 				...MISC_FIELDS_HIT.map(field => ({
// 					id: field.id,
// 					label: field.name,
// 					type: "autocomplete",
// 					options: MISC_OPTS,
// 					numCharToShowSuggestions: 0,
// 					value:
// 						field.id === "del" && evaluation._del
// 							? evaluation._del
// 							: evaluation[field.id]
// 							? evaluation[field.id]
// 							: null,
// 					containerStyle: { fontSize: "14px" },
// 					objectStyle: { fontSize: "14px" }
// 				})),
// 				{
// 					id: "hit_type",
// 					label: "Hitter Type",
// 					type: "autocomplete",
// 					options: HIT_TYPE_OPTS,
// 					numCharToShowSuggestions: 0,
// 					containerStyle: { fontSize: "14px" },
// 					objectStyle: { fontSize: "14px" }
// 				},
// 				{
// 					id: "_release",
// 					label: "Average Release Time",
// 					type: "text",
// 					containerStyle: { fontSize: "14px" },
// 					objectStyle: { fontSize: "14px" }
// 				}
// 			]
// 		}
// 	],
// 	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
// });

// eslint-disable-next-line no-unused-vars
const MISCELLANEOUS_HIT_LEG = evaluation => ({
	title: `Miscellaneous`,
	mobileTab: "Misc",
	partialWidth: 6,
	sections: [
		{
			objects: [
				...MISC_FIELDS_HIT.map(field => ({
					id: field.id,
					label: field.name,
					type: "autocomplete",
					options: MISC_OPTS,
					numCharToShowSuggestions: 0,
					value:
						field.id === "del" && evaluation._del
							? evaluation._del
							: evaluation[field.id]
							? evaluation[field.id]
							: null,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				})),
				{
					id: "hit_type",
					label: "Hitter Type",
					type: "autocomplete",
					options: HIT_TYPE_OPTS,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "home_to_1b",
					label: "Home to 1B",
					type: "text",
					validation: [{ validate: isNumeric, message: "Must be a number" }],
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "_release",
					label: "Average Release Time",
					type: "text",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				}
			]
		}
	],
	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
});

// eslint-disable-next-line no-unused-vars
const MISC_TEXT_FIELDS_LEG = () =>
	EVAL_MISC_TEXT_FIELDS_LEG.map(descriptionField => ({
		title: descriptionField.name,
		mobileTab: descriptionField.mobileTab,
		partialWidth: 6,
		sections: [
			{
				objects: [
					{
						id: descriptionField.id,
						type: "text",
						multiline: true,
						containerStyle: { fontSize: "14px" },
						objectStyle: { fontSize: "14px" }
					}
				]
			}
		],
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	}));

// eslint-disable-next-line no-unused-vars
const MISC_TEXT_FIELDS = evaluation =>
	[...EVAL_MISC_TEXT_FIELDS]
		.filter(field => {
			if (evaluation.eval_report_type === "AVR" && field.id === "injury_notes") return false;
			return true;
		})
		.map(descriptionField => {
			return {
				title: descriptionField.name,
				mobileTab: descriptionField.mobileTab,
				partialWidth: 6,
				sections: [
					{
						objects: [
							{
								id: descriptionField.id,
								type: "text",
								multiline: true,
								containerStyle: { fontSize: "14px" },
								objectStyle: { fontSize: "14px" }
							}
						]
					}
				],
				headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
			};
		});

// eslint-disable-next-line no-unused-vars
const OFP = (evaluation, startAdornment) => ({
	title: evaluation.eval_report_type === "FRE" ? "OFP/Prospect Type" : "Prospect Type",
	mobileTab: "OFP",
	partialWidth: 6,
	sections: [
		{
			objects: [
				{
					id: "ofp",
					label: "OFP",
					type: "autocomplete",
					options: OFP_OPTS,
					numCharToShowSuggestions: 0,
					show: evaluation.eval_report_type === "FRE",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "prospect_type",
					label: "Prospect Type",
					type: "autocomplete",
					options: PROSPECT_TYPE_OPTS,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "risk_factor",
					label: "Risk Factor",
					type: "autocomplete",
					options: RISK_OPTS,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				}
			]
		}
	],
	startAdornment: startAdornment,
	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
});

// eslint-disable-next-line no-unused-vars
const SNAP_SUM = evaluation =>
	SNAP_SUM_FIELDS.map((descriptionField, idx) => ({
		title:
			evaluation.eval_report_type === "AVR" && descriptionField.id === "summary"
				? "Comments"
				: descriptionField.name,
		mobileTab: descriptionField.mobileTab,
		sections: [
			{
				objects: [
					{
						id: descriptionField.id,
						type: "text",
						multiline: true,
						format: descriptionField.format,
						maxLength: descriptionField.id === "current_snapshot" ? 140 : null,
						onBottom:
							idx === SNAP_SUM_FIELDS.length - 1 && evaluation != null && evaluation.publish_date == null,
						containerStyle: { fontSize: "14px" },
						objectStyle: { fontSize: "14px" }
					}
				]
			}
		],
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	}));

const LEGACY_AMA_EVAL = (evaluation, getStartAdornment) => {
	if (evaluation == null) return { style: {}, groups: [] };
	const toolsStartAdornment = getStartAdornment(evaluation ? evaluation.eval_type : "AMA_HIT");
	const ofpAdornment = getStartAdornment(
		evaluation && evaluation.eval_report_type === "FRE" ? "AmaOFPProspectType" : "AmaProspectType"
	);

	return {
		style: {},
		groups: [
			BIO_FIELDS_LEG(evaluation),
			evaluation.eval_type === "AMA_PITCH"
				? TOOLS_GRADING_STANDARDS_PITCH(evaluation, toolsStartAdornment)
				: TOOLS_GRADING_STANDARDS_HIT(evaluation, toolsStartAdornment),
			MAKEUP_LEG(),
			evaluation.eval_type === "AMA_PITCH" ? MISCELLANEOUS_PITCH(evaluation) : MISCELLANEOUS_HIT_LEG(evaluation),
			...MISC_TEXT_FIELDS_LEG(),
			OFP(evaluation, ofpAdornment),
			...SNAP_SUM(evaluation)
		]
	};
};

const AMA_EVAL = (evaluation, getStartAdornment) => {
	if (evaluation == null) return { style: {}, groups: [] };
	const createDate = dayjs(evaluation.eval_create_date);
	const cutoffDate = dayjs("2022-01-01 00:00:00");
	const physicalAdornment = getStartAdornment("physical");
	const ofpPitchingAdornment = getStartAdornment("group_ofp_pitching");
	const ofpHittingAdornment = getStartAdornment("group_ofp_hitting");
	const pitchingAdornment = getStartAdornment("pitching");
	const fastballAdornment = getStartAdornment("fastball");
	const secondaryPitchesAdornment = getStartAdornment("secondary_pitches");
	const fieldingAdornment = getStartAdornment("fielding");
	const physicalHittingAdornment = getStartAdornment("physical_hitting");
	const hittingAdornment = getStartAdornment("hitting");

	let groups =
		evaluation.eval_type === "AMA_PITCH"
			? [
					BIO_FIELDS(evaluation),
					FASTBALL(evaluation, fastballAdornment),
					SECONDARY_PITCHES(evaluation, secondaryPitchesAdornment),
					PITCHING_ATTRIBUTES(evaluation, pitchingAdornment),
					PHYSICAL_QUALITIES(evaluation, physicalAdornment),
					GROUP_OFP(evaluation, ofpPitchingAdornment),
					MAKEUP(),
					UPSIDE_AND_RISK(evaluation),
					...MISC_TEXT_FIELDS(evaluation),
					...SNAP_SUM(evaluation)
			  ]
			: [
					BIO_FIELDS(evaluation),
					PHYSICAL_TOOLS(evaluation, physicalHittingAdornment),
					HITTING(evaluation, hittingAdornment),
					FIELDING(evaluation, fieldingAdornment),
					PHYSICAL_ATTRIBUTES(evaluation, physicalAdornment),
					GROUP_OFP(evaluation, ofpHittingAdornment),
					MAKEUP(),
					UPSIDE_AND_RISK(evaluation),
					...MISC_TEXT_FIELDS(evaluation),
					...SNAP_SUM(evaluation)
			  ];
	// Remove Makeup section from AVR reports and reports published in 2022 or later
	// Remove Upside and risk section from non FRE reports and reports published before 2022
	groups = groups.filter(group => {
		if (
			(createDate.isBefore(cutoffDate) || evaluation.eval_report_type !== "FRE") &&
			group.title === "Upside and Risk"
		)
			return false;
		if ((evaluation.eval_report_type === "AVR" || createDate.isAfter(cutoffDate)) && group.title === "Makeup")
			return false;
		return true;
	});
	return {
		style: {},
		groups
	};
};

export const getEvalTemplate = (evaluation, getStartAdornment) => {
	const createDate = dayjs(evaluation.eval_create_date);
	const cutoffDate = dayjs("2020-01-22 23:00:00");
	if (createDate.isBefore(cutoffDate)) {
		return LEGACY_AMA_EVAL(evaluation, getStartAdornment);
	} else {
		return AMA_EVAL(evaluation, getStartAdornment);
	}
};

const EVAL_DATE_FIELDS = ["seen_date", "report_date", "eval_create_date", "seen_date_reference"];
export const isEvalDateField = field => EVAL_DATE_FIELDS.includes(field);
export const formatEvalDateField = value => dayjs(value).format("YYYY-MM-DD HH:mm:ss");

export const computeTotalFields = (evaluation, evaluationUpdateObject, value) => {
	// Manage Total Fields
	const totalFields = [
		"innings_seen_thirds",
		"games_seen",
		"hits_seen",
		"strikeouts_seen",
		"walks_seen",
		"plate_appearances_seen",
		"at_bats_seen",
		"extra_base_hits_seen"
	];
	for (let i = 0; i < totalFields.length; i++) {
		if (evaluation["eval_report_type"] === "FOL") continue;
		if (totalFields[i] === value.id) {
			if (checkSeenDate(evaluation["seen_date"])) {
				evaluationUpdateObject[`total_${value.id}`] =
					(parseInt(evaluation[`total_${value.id}`], 10) || 0) +
					((parseInt(value.value, 10) || 0) - (parseInt(evaluation[value.id], 10) || 0));
			}
		} else if (evaluation.hasOwnProperty(`total_${totalFields[i]}`)) {
			evaluationUpdateObject[`total_${totalFields[i]}`] = evaluation[`total_${totalFields[i]}`];
		}
	}

	if (
		value.id === "seen_date" &&
		checkSeenDate(value.value) !== checkSeenDate(evaluation["seen_date"]) &&
		evaluation["eval_report_type"] === "FRE"
	) {
		// Manage Seen Date
		const addSubtract = checkSeenDate(value.value) ? 1 : -1;
		for (let i = 0; i < totalFields.length; i++) {
			if (totalFields[i] === "innings_seen_thirds") {
				evaluationUpdateObject["total_innings_seen_thirds"] = addInnings(
					evaluation["total_innings_seen_thirds"],
					addSubtract * (parseFloat(evaluation["innings_seen_thirds"], 10.0) || 0.0)
				);
			} else {
				evaluationUpdateObject[`total_${totalFields[i]}`] =
					(parseInt(evaluation[`total_${totalFields[i]}`], 10) || 0) +
					addSubtract * (parseInt(evaluation[totalFields[i]], 10) || 0);
			}
		}
	}
};

const isValidEvalSeenDate = evaluation =>
	(evaluation.seen_date == null && evaluation.eval_report_type !== "LVR") ||
	(evaluation.seen_date_reference == null && evaluation.eval_report_type === "LVR");

const CHECKS = [
	{
		fn: evaluation => evaluation.position == null,
		message: _ => "Please choose a position"
	},
	{
		fn: evaluation => evaluation.group == null,
		message: _ => "Please choose a group"
	},
	{
		fn: evaluation => evaluation.current_snapshot == null || evaluation.current_snapshot === "",
		message: _ => "Please enter a current snapshot"
	},
	{
		fn: evaluation => evaluation.summary == null || evaluation.summary === "",
		message: evaluation =>
			evaluation.eval_report_type === "AVR" ? "Please enter comments" : "Please enter a summary"
	},
	{
		fn: isValidEvalSeenDate,
		message: _ => "Please enter a seen date"
	}
];

export const checkIsRequiredFieldsAllPresent = evaluation => {
	for (let i = 0; i < CHECKS.length; i++) {
		if (CHECKS[i].fn(evaluation)) {
			alert(CHECKS[i].message(evaluation));
			return false;
		}
	}
	return true;
};
