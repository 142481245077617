import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";

import { $TSFixMe, Style } from "utils/tsutils";

type TabMiniProps = {
	colorScheme?: $TSFixMe;
	disabled?: boolean;
	label?: string;
	onClick?: $TSFixMe;
	selected?: boolean;
	style?: Style;
	value?: string;
};

const StyledTab = styled.div<TabMiniProps>(
	{
		margin: "2px",
		padding: "2px 4px",
		borderRadius: "3px",

		fontSize: "15px",
		fontWeight: 700
	},
	(props: TabMiniProps) => ({
		color: props.colorScheme!.text,
		backgroundColor: props.selected ? props.colorScheme!.color : "",
		cursor: props.disabled ? "not-allowed" : "pointer",
		":hover": {
			backgroundColor: props.selected
				? props.colorScheme!.selected
				: !props.disabled
				? props.colorScheme!.hover
				: ""
		},
		...props.style
	})
);

export const TabMini: FunctionComponent<TabMiniProps> = ({
	colorScheme,
	disabled = false,
	label,
	onClick,
	selected = false,
	style = {},
	value
}) => {
	return (
		<StyledTab
			colorScheme={colorScheme}
			disabled={disabled}
			onClick={() => {
				if (!disabled) onClick(value);
			}}
			selected={selected}
			style={style}
		>
			{label}
		</StyledTab>
	);
};
