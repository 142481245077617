import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function TableChart({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path
				fill={fill}
				d="M3 8V5q0-.825.587-1.413Q4.175 3 5 3h15q.825 0 1.413.587Q22 4.175 22 5v3Zm5 2v11H5q-.825 0-1.413-.587Q3 19.825 3 19v-9Zm9 0h5v9q0 .825-.587 1.413Q20.825 21 20 21h-3Zm-2 0v11h-5V10Z"
			/>
		</IconSVG>
	);
}
