import React, { useEffect, useMemo, useState } from "react";
import { Text } from "@chakra-ui/react";
import dayjs, { Dayjs } from "dayjs";
import Select from "react-select";
import Creatable from "react-select/creatable";

import axios from "_redux/_utils/_axios";
import { $TSFixMe } from "utils/tsutils";
import { convertCamelToUnderscore } from "utils/functions";
import { capitalizeFirstLetter } from "utils/_helpers";
import { getAgeFromBirthDate } from "utils/helpers";
import { useFetch } from "utils/url_helpers";
import { useUserId, useIsGroupMember } from "_react/app/AppContents";
import { Button } from "_react/shared/legacy/ui/Button";
import { TextField } from "_react/shared/legacy/ui/TextField";
import { useGetAgencies, TAgencyById } from "_react/agents/_helpers";
import { TeamSearch } from "_react/shared/searches/TeamSearch";
import { useFormContext } from "_react/shared/forms/_context";
import {
	INSTANT,
	MANUAL,
	TFormPlugin,
	TDocumentBase,
	TFormValue,
	TFormValidationResult,
	TFormComponent,
	TFieldState,
	AS_LABEL,
	SHOW,
	HIDE,
	FormComponentInternalProps,
	TLkProps
} from "_react/shared/forms/_types";
import { selectStyles } from "_react/shared/forms/FormComponent";
import { MOCK_AGENT_ID, TAgent } from "_react/shared/selects/AgentSelect";
import { TPlayerPageAssignedScout } from "_react/playerpage/_types";
import AddAgentModal from "_react/playerpage/shared_js/AddAgentModal";
import { createReactSelectTeamOption } from "_react/shared/searches/_helpers";
import PlayerDupeIdPopover from "_react/shared/ui/data/modals/PlayerDupeIdPopover/PlayerDupeIdPopover";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import {
	TProBioDocument,
	TAmaBioDocument,
	TIntlBioDocument,
	TAgent as TBioAgent,
	TTeamSearchOption
} from "_react/playerpage/bio/_types";
import {
	ROSEN_USER_ID,
	SWE_IAM_GROUP,
	OTHER_SPORTS,
	PLAYER_FIELDS,
	PLAYER_AMA_FIELDS,
	PLAYER_INTL_FIELDS,
	TEXT_FIELDS_TO_CONVERT,
	PLAYER_FIELDS_CUSTOM_COMPONENT
} from "_react/playerpage/bio/_constants";

export const proBioPlugin: TFormPlugin<TProBioDocument> = {
	formId: "pro-player-bio",
	saveOptions: {
		mode: MANUAL
	},
	customComponents: {}
};

/* Custom Components */
function FormComponentAgentSelect<T extends TDocumentBase>({
	component,
	submitUpdate,
	value,
	readOnly,
	disabled
}: FormComponentInternalProps<T>) {
	const [agentOptions, setAgentOptions] = useState<Array<{ label: string; value: string }>>();
	const [agentUsed, setAgentUsed] = useState<Array<{ label: string; value: string }>>();
	const [addingAgent, setAddingAgent] = useState<boolean>(false);
	const [agencies, setAgencies] = useState<TAgencyById>({});
	const userId = useUserId();
	const isSwe = useIsGroupMember(SWE_IAM_GROUP);
	const { document } = useFormContext<T>();

	// Extract field value
	const valueUsed = value as string;
	const onChange = (value: $TSFixMe) => {
		// TSFixMe because react-select types absolutely suck
		if (Array.isArray(value)) {
			console.log("Multi-Select Not Yet Supported");
			return;
		}
		submitUpdate(value?.value);
	};
	const { responseData: agentResponseData, isInProgress } = useFetch(`/agent`, []);

	function agentLabel(agent: TAgent | TBioAgent) {
		const agentName = `${agent.firstName ? capitalizeFirstLetter(agent.firstName.toLowerCase()) : ""}${
			agent.firstName && agent.lastName ? " " : ""
		}${agent.lastName ? capitalizeFirstLetter(agent.lastName.toLowerCase()) : ""}`;
		const firmName =
			agent.hasOwnProperty("agencyFirmName") && agent.agencyFirmName ? agent.agencyFirmName! : agent.firmName;
		const country = agent.country;
		return `${agentName}${firmName && firmName !== "None" ? ", " + firmName : ""}${
			country ? " (" + country + ")" : ""
		}`;
	}

	useEffect(() => {
		if (agentResponseData) {
			const agentInitialOptions: Array<{ label: string; value: string }> = [];
			agentResponseData
				.filter((agent: TAgent) => agent.isActive && (agent.firstName || agent.lastName))
				.forEach((agent: TAgent) => {
					const agentLabelString = agentLabel(agent);
					agentInitialOptions.push({
						label: agentLabelString,
						value: agent.agentId
					});
				});
			setAgentOptions(agentInitialOptions);
		}
	}, [agentResponseData, setAgentOptions]);

	if (agentOptions)
		agentOptions.sort((a, b) => {
			if (a.value === MOCK_AGENT_ID) return -1;
			if (b.value === MOCK_AGENT_ID) return 1;
			return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
		});

	useMemo(() => {
		setAgentUsed(agentOptions?.filter(agent => agent.value === String(valueUsed)));
	}, [valueUsed, agentOptions, setAgentUsed]);

	const [getAgencies] = useGetAgencies();
	useEffect(() => {
		getAgencies().then((newAgencies: TAgencyById) => {
			setAgencies(newAgencies);
		});
	}, [getAgencies]);

	function handleAgentAdded(agentAdded: TBioAgent) {
		const newAgent = {
			label: agentLabel(agentAdded),
			value: agentAdded.agentId
		};
		if (agentOptions) {
			setAgentOptions([newAgent, ...agentOptions]);
			onChange(newAgent);
		} else setAgentOptions([newAgent]);
	}

	if (readOnly) {
		return <div>{agentUsed && agentUsed.length > 0 && agentUsed[0].label ? agentUsed[0].label : ""}</div>;
	}

	if (userId === ROSEN_USER_ID || isSwe)
		return (
			<React.Fragment>
				<Creatable
					onChange={onChange}
					options={agentOptions}
					value={agentUsed}
					onCreateOption={() => setAddingAgent(true)}
					placeholder={component.placeholder}
					isLoading={isInProgress}
					isDisabled={disabled}
					isClearable={document.playerClassification !== "INTL"}
					styles={selectStyles("black")}
				/>
				{addingAgent && (
					<AddAgentModal
						agencies={agencies}
						handleClose={(agentSelected: TAgent) => {
							setAddingAgent(false);
							if (agentSelected) handleAgentAdded(agentSelected);
						}}
						isAgent={true}
					/>
				)}
			</React.Fragment>
		);
	return (
		<Select
			onChange={onChange}
			options={agentOptions}
			value={agentUsed}
			placeholder={component.placeholder}
			isLoading={isInProgress}
			isDisabled={disabled}
			isClearable={document.playerClassification !== "INTL"}
			styles={selectStyles("black")}
		/>
	);
}

function FormComponentScoutSelect<T extends TDocumentBase>({
	component,
	submitUpdate,
	value,
	readOnly,
	disabled
}: FormComponentInternalProps<T>) {
	const [scoutOptions, setScoutOptions] = useState<Array<{ label: string; value: string }>>();
	const [scoutUsed, setScoutUsed] = useState<Array<{ label: string; value: string }>>();

	// Extract field value
	const valueUsed = value as string;
	const onChange = (value: $TSFixMe) => {
		// TSFixMe because react-select types absolutely suck
		if (Array.isArray(value)) {
			console.log("Multi-Select Not Yet Supported");
			return;
		}
		submitUpdate(value?.value);
	};
	const { responseData: scoutResponseData, isInProgress } = useFetch(`/logins?isActiveOnly=True&isBasic=True`, []);

	function scoutLabel(scout: TPlayerPageAssignedScout) {
		return `${scout.firstName ? capitalizeFirstLetter(scout.firstName.toLowerCase()) : ""}${
			scout.firstName && scout.lastName ? " " : ""
		}${scout.lastName ? capitalizeFirstLetter(scout.lastName.toLowerCase()) : ""}`;
	}

	useEffect(() => {
		if (scoutResponseData) {
			const scoutInitialOptions: Array<{ label: string; value: string }> = [];
			scoutResponseData.forEach((scout: TPlayerPageAssignedScout) => {
				const scoutLabelString = scoutLabel(scout);
				scoutInitialOptions.push({
					label: scoutLabelString,
					value: String(scout.userId)
				});
			});
			setScoutOptions(scoutInitialOptions);
		}
	}, [scoutResponseData, setScoutOptions]);

	if (scoutOptions) scoutOptions.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

	useMemo(() => {
		setScoutUsed(scoutOptions?.filter(scout => scout.value === String(valueUsed)));
	}, [valueUsed, scoutOptions, setScoutUsed]);

	if (readOnly) {
		return <div>{scoutUsed && scoutUsed.length > 0 && scoutUsed[0].label ? scoutUsed[0].label : ""}</div>;
	}

	return (
		<Select
			onChange={onChange}
			options={scoutOptions}
			value={scoutUsed}
			placeholder={component.placeholder}
			isLoading={isInProgress}
			isDisabled={disabled}
			isClearable={true}
			styles={selectStyles("black")}
		/>
	);
}

function FormComponentHyperlinkText<T extends TDocumentBase>({
	component,
	submitUpdate,
	value,
	readOnly,
	disabled
}: FormComponentInternalProps<T>) {
	const valueString = value as string;
	const valueStringParsed = valueString ? valueString.replace("@", "") : undefined;
	const baseLink =
		component.key === "instagram"
			? "http://instagram.com/"
			: component.key === "twitter"
			? "http://twitter.com/"
			: "";
	const valueLink = valueStringParsed ? `${baseLink}${valueStringParsed}` : "";

	if (readOnly) {
		if (valueStringParsed) {
			return (
				<Button
					disabled={!valueStringParsed}
					onClick={() => window.open(valueLink)}
					style={{ fontSize: "12px", margin: 0, padding: "0 2px" }}
					title={valueString}
				/>
			);
		}
		return <div></div>;
	}

	return (
		<TextField
			style={{ flexGrow: 1 }}
			value={valueString}
			onChange={e => submitUpdate(e.target.value !== "" ? e.target.value : undefined)}
			multiline={component.multiline}
			placeholder={component.placeholder}
			disabled={disabled}
		/>
	);
}

function FormComponentSportsPlayedSelect<T extends TDocumentBase>({
	component,
	submitUpdate,
	value,
	readOnly,
	disabled
}: FormComponentInternalProps<T>) {
	const valueString = value as string;
	const valueArray = valueString ? valueString.split(", ") : undefined;
	const options = OTHER_SPORTS;
	const valueUsed = options?.filter(option => valueArray?.includes(option.value));

	const onChange = (value: $TSFixMe) => {
		if (Array.isArray(value)) {
			if (value.length < 1) {
				submitUpdate(undefined);
			} else {
				submitUpdate(value.map(option => option.value).join(", "));
			}
		}
	};

	if (readOnly) return <div>{valueString}</div>;

	return (
		<Select
			onChange={onChange}
			options={options}
			value={valueUsed}
			placeholder={component.placeholder}
			isMulti={true}
			isDisabled={disabled}
			isClearable={true}
			styles={selectStyles("black")}
		/>
	);
}

function FormComponentTeamSelect<T extends TDocumentBase>({
	submitUpdate,
	value,
	readOnly,
	disabled
}: FormComponentInternalProps<T>) {
	// Extract field value
	const [valueUsed, setValueUsed] = useState<number>();
	const [selectedTeamData, setSelectedTeamData] = useState<Array<TTeamSearchOption>>();
	const { document } = useFormContext<T>();
	const playerClassification = document.playerClassification === "INTL" ? "INTL" : "AMA";

	if (!valueUsed && value) {
		setValueUsed(value as number);
	}

	const onChange = (value: $TSFixMe) => {
		// TSFixMe because react-select types absolutely suck
		if (Array.isArray(value)) {
			console.log("Multi-Select Not Yet Supported");
			return;
		}
		submitUpdate(value ? value.value : null);
		setValueUsed(value ? value.value : null);
	};

	// If display only, pull team display name from API route
	useEffect(() => {
		if (valueUsed && readOnly) {
			axios.get(`/search/team/initial?values=${valueUsed}`).then(response => {
				if (response.data.length > 0) {
					setSelectedTeamData(response.data.map(createReactSelectTeamOption));
				}
			});
		}
	}, [valueUsed, readOnly]);

	if (readOnly) {
		if (selectedTeamData && selectedTeamData.length > 0) return <div>{selectedTeamData[0].label}</div>;
		else return <div></div>;
	}

	return (
		<TeamSearch
			valueOnlyValue={valueUsed}
			onSelect={onChange}
			type={playerClassification === "AMA" ? "AMA" : undefined}
			isClearable
			levels={playerClassification === "INTL" ? ["NPB", "KBO", "MEX", "CPBL"] : undefined}
			isActive={playerClassification === "INTL" ? true : undefined}
			creatable={playerClassification === "AMA"}
			disabled={disabled}
			selectStyles={selectStyles("black")}
		/>
	);
}

function formComponentDupeIds<T extends TDocumentBase>({ component, value }: FormComponentInternalProps<T>) {
	return (
		<>
			<Text marginRight="1">{`${value ?? ""}`}</Text>
			{value && typeof value === "number" && (
				<PlayerDupeIdPopover targetId={value} idType={component.key.slice(0, -2)} shouldFetchData />
			)}
		</>
	);
}

function processAmaUpdate<_T extends TDocumentBase>(
	newDocument: TAmaBioDocument,
	key: keyof TAmaBioDocument
): TAmaBioDocument {
	if (key === "birthDate") {
		newDocument.age = getAgeFromBirthDate(newDocument.birthDate).toString();
	}
	if (key === "currentTeamId") {
		newDocument.assignedScout = undefined;
	}
	return newDocument;
}

function validate<T extends TDocumentBase>(newDocument: T, key?: keyof T, value?: TFormValue): TFormValidationResult {
	if (key === "heightFeet" && value != null) {
		const castedValue = value as number;
		if (castedValue > 7 || castedValue < 4)
			return {
				success: false,
				messages: ["Not a valid height (must be 4 - 7 ft)"]
			};
	}

	if (key === "heightInches" && value != null) {
		const castedValue = value as number;
		if (castedValue > 11)
			return {
				success: false,
				messages: ["Must be less than 12 inches"]
			};
	}

	if (key === "weight" && value != null) {
		const castedValue = value as string;
		if (castedValue.length > 3)
			return {
				success: false,
				messages: ["Not a valid weight"]
			};
	}

	if ((key === "eligibleYear" || key === "highSchoolGradYear") && value != null) {
		const castedValue = value as string;
		if (castedValue.length !== 4)
			return {
				success: false,
				messages: ["Must be in format YYYY"]
			};
	}

	return {
		success: true,
		messages: []
	};
}

function getFieldState<T extends TDocumentBase>(component: TFormComponent, document: T): TFieldState {
	const playerFields = PLAYER_FIELDS as Array<string>;
	if (
		(playerFields.includes(component.key) &&
			!(PLAYER_FIELDS_CUSTOM_COMPONENT as Array<string>).includes(component.key)) ||
		component.key === "createDate" ||
		component.key === "lastChangeDate" ||
		component.key === "age" ||
		component.key === "assignedScout" ||
		component.key.includes("placeholder")
	)
		return AS_LABEL;
	if (component.key === "otherSportNew") {
		if (document.otherSportsPlayed != null) {
			const otherSportsPlayedValue = document.otherSportsPlayed as number;
			if (otherSportsPlayedValue === 1) return SHOW;
		}
		return HIDE;
	}
	if (component.key === "tjDate") {
		if (document.isPriorTj) return SHOW;
		return HIDE;
	}
	if (component.key === "mlLineage") {
		if (document.isMlLineage) return SHOW;
		return HIDE;
	}
	return SHOW;
}

function updateLkProps<T extends TDocumentBase>(
	newDocument: T, // The new version of the document
	oldLkProps: TLkProps, // The old LkProps dictionary
	key: keyof T, // The key of the field that was updated (mainly for reference)
	value: TFormValue, // The new value (mainly for reference)
	callback: (newLkProps: TLkProps) => void // The function to be called with the new version of the LkProps dictionary
): void {
	if (key === "initial") {
		// The team field was updated, get the org
		axios.get(`/team/${newDocument.currentTeamId}`).then(response => {
			if (response.data != null && response.data.level != null) console.log(response.data.level);
			callback({ ...oldLkProps, schoolClass: { ...oldLkProps.schoolClass, level: response.data.level } });
		});
	}
	if (key === "currentTeamId") {
		// The team field was updated, get the org
		if (value != null)
			axios.get(`/team/${value}`).then(response => {
				if (response.data != null && response.data.level != null) console.log(response.data.level);
				callback({ ...oldLkProps, schoolClass: { ...oldLkProps.schoolClass, level: response.data.level } });
			});
		else callback({ ...oldLkProps, schoolClass: { ...oldLkProps.schoolClass, level: 0 } }); // level is 0 because we want to clear the level filter
	}
}

export const amaBioPlugin: TFormPlugin<TAmaBioDocument> = {
	formId: "ama-player-bio",
	saveOptions: {
		mode: INSTANT
	},
	customComponents: {
		AGENT_SELECT: FormComponentAgentSelect,
		TEAM_SEARCH: FormComponentTeamSelect,
		OTHER_SPORTS: FormComponentSportsPlayedSelect,
		HYPERLINK: FormComponentHyperlinkText
	},
	processUpdate: processAmaUpdate,
	validate,
	getFieldState,
	updateLkProps
};

export const createAmaBioDocument = (player: TPlayerPageCombinedPlayer | null): TAmaBioDocument => {
	const amaBioDocument: { [index: string]: string | number | Dayjs | undefined } = {};
	if (player) {
		PLAYER_FIELDS.forEach(field => {
			if (player.hasOwnProperty(field) && player[field] !== null) amaBioDocument[field] = player[field]!;
		});
		if (player.amaProfile?.scoutAssigned) {
			const values = [player.amaProfile.scoutAssigned.firstName, player.amaProfile.scoutAssigned.lastName];
			amaBioDocument.assignedScout = values.filter(Boolean).join(" ");
		}
		if (player.amaProfile) {
			const amaProfile = player.amaProfile;
			PLAYER_AMA_FIELDS.forEach(field => {
				if (amaProfile.hasOwnProperty(field) && amaProfile[field] !== null)
					amaBioDocument[field] = amaProfile[field]!;
			});
			if (amaProfile.birthDate) {
				amaBioDocument.age = getAgeFromBirthDate(amaProfile.birthDate).toString();
				amaBioDocument.birthDate = dayjs(amaProfile.birthDate);
			}
			if (amaProfile.height) {
				const heightFeet = Math.floor(amaProfile.height / 12);
				amaBioDocument.heightFeet = heightFeet.toString();
				const heightInches = amaProfile.height % 12;
				amaBioDocument.heightInches = heightInches.toString();
			}
			amaBioDocument.lastChangeDate = dayjs(player.amaProfile.lastChangeDate);
			if (amaProfile.tjDate) amaBioDocument.tjDate = dayjs(amaProfile.tjDate);
		}
	}
	return amaBioDocument;
};

export const parseAmaBioDocument = (player: TPlayerPageCombinedPlayer | null, newAmaBioDocument: TAmaBioDocument) => {
	if (player && newAmaBioDocument && newAmaBioDocument.id) {
		const newPlayerAmaProfile: { [index: string]: string | number | null | undefined } = {
			id: newAmaBioDocument.id,
			philId: newAmaBioDocument.playerAmaId
		};
		PLAYER_AMA_FIELDS.forEach(field => {
			if (
				player?.amaProfile &&
				newAmaBioDocument.hasOwnProperty(field) &&
				(!player.amaProfile.hasOwnProperty(field) ||
					((newAmaBioDocument[field] != null || player.amaProfile[field] != null) &&
						newAmaBioDocument[field] !== player.amaProfile[field]))
			) {
				if (TEXT_FIELDS_TO_CONVERT.includes(field)) {
					const numValue = player.amaProfile[field];
					const stringValue = numValue?.toString();
					if (newAmaBioDocument[field] !== stringValue) {
						newPlayerAmaProfile[field] = newAmaBioDocument[field];
					}
				} else {
					// Quick fix before fixing convertCamelToUnderscore function that doesn't convert fields with numbers in them correctly
					const fieldName = field === "address1" ? "address_1" : field === "address2" ? "address_2" : field;
					newPlayerAmaProfile[fieldName] = newAmaBioDocument[field];
				}
			}
		});
		const dateFields: Array<"birthDate" | "tjDate"> = ["birthDate", "tjDate"];
		dateFields.forEach(field => {
			if (newAmaBioDocument.hasOwnProperty(field)) {
				const dateFormatted = dayjs(newAmaBioDocument[field]).format("YYYY-MM-DDTHH:mm:ss");
				if (
					player.amaProfile &&
					(!player.amaProfile.hasOwnProperty(field) || dateFormatted !== player.amaProfile[field])
				) {
					newPlayerAmaProfile[field] = dateFormatted;
				}
			}
		});
		let initialPlayerHeight = player.amaProfile?.height ?? 0;
		// TODO: This is a hack fix because the original types are numbers but the text field converts them to strings
		const heightFeet = newAmaBioDocument.heightFeet ? parseInt(newAmaBioDocument.heightFeet) : null;
		const heightInches = newAmaBioDocument.heightInches ? parseInt(newAmaBioDocument.heightInches) : null;
		if (heightFeet != null && initialPlayerHeight - (initialPlayerHeight % 12) !== heightFeet * 12) {
			newPlayerAmaProfile.height = (initialPlayerHeight % 12) + heightFeet * 12;
			initialPlayerHeight = newPlayerAmaProfile.height;
		}
		if (heightInches != null && initialPlayerHeight % 12 !== heightInches) {
			newPlayerAmaProfile.height = initialPlayerHeight - (initialPlayerHeight % 12) + heightInches;
		}
		if (Object.keys(newPlayerAmaProfile).length > 2) {
			// Form objects return undefined when a field value is deleted, but the put request requires a null value
			Object.entries(newPlayerAmaProfile).forEach(object => {
				if (object[1] === undefined) newPlayerAmaProfile[object[0]] = null;
			});
			return convertCamelToUnderscore(newPlayerAmaProfile);
		}
	}
};

function processIntlUpdate<_T extends TDocumentBase>(
	newDocument: TIntlBioDocument,
	key: keyof TIntlBioDocument
): TIntlBioDocument {
	if (key === "dateOfBirth") {
		newDocument.age = getAgeFromBirthDate(newDocument.dateOfBirth).toString();
	}
	return newDocument;
}

export const intlBioPlugin: TFormPlugin<TIntlBioDocument> = {
	formId: "intl-player-bio",
	saveOptions: {
		mode: INSTANT
	},
	customComponents: {
		AGENT_SELECT: FormComponentAgentSelect,
		TEAM_SEARCH: FormComponentTeamSelect,
		HYPERLINK: FormComponentHyperlinkText,
		USER_SELECT: FormComponentScoutSelect,
		DUPE_ID: formComponentDupeIds
	},
	dropdownComponents: {
		// Dictionary of options for each dropdown component that isn't db driven
		internationalPlayerType: [
			{ label: "International Amateur", value: "AMA" },
			{ label: "International Professional", value: "PRO" }
		]
	},
	processUpdate: processIntlUpdate,
	validate,
	getFieldState
};

export const createIntlBioDocument = (player: TPlayerPageCombinedPlayer | null): TIntlBioDocument => {
	const intlBioDocument: { [index: string]: string | number | Dayjs | undefined } = {};
	if (player) {
		PLAYER_FIELDS.forEach(field => {
			if (player.hasOwnProperty(field) && player[field] !== null) intlBioDocument[field] = player[field]!;
		});
		if (player.intlProfile) {
			const intlProfile = player.intlProfile;
			PLAYER_INTL_FIELDS.forEach(field => {
				if (intlProfile.hasOwnProperty(field) && intlProfile[field] !== null)
					intlBioDocument[field] = intlProfile[field]!;
			});
			if (intlProfile.dateOfBirth) {
				intlBioDocument.age = getAgeFromBirthDate(intlProfile.dateOfBirth).toString();
				intlBioDocument.dateOfBirth = dayjs(intlProfile.dateOfBirth);
			}
			if (intlProfile.schoolGradDate) {
				intlBioDocument.schoolGradDate = dayjs(intlProfile.schoolGradDate);
			}
			if (intlProfile.height) {
				const heightFeet = Math.floor(intlProfile.height / 12);
				intlBioDocument.heightFeet = heightFeet.toString();
				const heightInches = intlProfile.height % 12;
				intlBioDocument.heightInches = heightInches.toString();
			}
			intlBioDocument.createDate = dayjs(intlProfile.createDate);
			intlBioDocument.lastChangeDate = dayjs(intlProfile.lastChangeDate);
		}
	}
	return intlBioDocument;
};

export const parseIntlBioDocument = (
	player: TPlayerPageCombinedPlayer | null,
	newIntlBioDocument: TIntlBioDocument
) => {
	if (player && newIntlBioDocument && newIntlBioDocument.id) {
		const newPlayerIntlProfile: { [index: string]: string | number | null | undefined } = {
			id: newIntlBioDocument.id,
			philId: newIntlBioDocument.playerIntlId
		};
		PLAYER_INTL_FIELDS.forEach(field => {
			if (
				player?.intlProfile &&
				newIntlBioDocument.hasOwnProperty(field) &&
				(!player.intlProfile.hasOwnProperty(field) ||
					((newIntlBioDocument[field] != null || player.intlProfile[field] != null) &&
						newIntlBioDocument[field] !== player.intlProfile[field]))
			) {
				if (TEXT_FIELDS_TO_CONVERT.includes(field)) {
					const numValue = player.intlProfile[field];
					const stringValue = numValue?.toString();
					if (newIntlBioDocument[field] !== stringValue) {
						newPlayerIntlProfile[field] = newIntlBioDocument[field];
					}
				} else {
					// Quick fix before fixing convertCamelToUnderscore function that doesn't convert fields with numbers in them correctly
					const fieldName = field === "address1" ? "address_1" : field === "address2" ? "address_2" : field;
					newPlayerIntlProfile[fieldName] = newIntlBioDocument[field];
				}
			}
		});
		const dateFields: Array<"dateOfBirth" | "schoolGradDate"> = ["dateOfBirth", "schoolGradDate"];
		dateFields.forEach(field => {
			if (newIntlBioDocument.hasOwnProperty(field)) {
				const dateFormatted = dayjs(newIntlBioDocument[field]).format("YYYY-MM-DDTHH:mm:ss");
				if (
					player.intlProfile &&
					(!player.intlProfile.hasOwnProperty(field) || dateFormatted !== player.intlProfile[field])
				) {
					newPlayerIntlProfile[field] = dateFormatted;
				}
			}
		});
		let initialPlayerHeight = player.intlProfile?.height ?? 0;
		// // TODO: This is a hack fix because the original types are numbers but the text field converts them to strings
		const heightFeet = newIntlBioDocument.heightFeet ? parseInt(newIntlBioDocument.heightFeet) : null;
		const heightInches = newIntlBioDocument.heightInches ? parseInt(newIntlBioDocument.heightInches) : null;
		if (heightFeet != null && initialPlayerHeight - (initialPlayerHeight % 12) !== heightFeet * 12) {
			newPlayerIntlProfile.height = (initialPlayerHeight % 12) + heightFeet * 12;
			initialPlayerHeight = newPlayerIntlProfile.height;
		}
		if (heightInches != null && initialPlayerHeight % 12 !== heightInches) {
			newPlayerIntlProfile.height = initialPlayerHeight - (initialPlayerHeight % 12) + heightInches;
		}
		if (Object.keys(newPlayerIntlProfile).length > 2) {
			// Form objects return undefined when a field value is deleted, but the put request requires a null value
			Object.entries(newPlayerIntlProfile).forEach(object => {
				if (object[1] === undefined) newPlayerIntlProfile[object[0]] = null;
			});
			return convertCamelToUnderscore(newPlayerIntlProfile);
		}
	}
};
