import axios from "_redux/_utils/_axios";

import { TFormStructure, TFormSection } from "_react/shared/forms/_types";

export const loadFormStructure = (formId: string) => {
	const path = `/form_structure?id=${formId}`;
	return axios
		.get(path)
		.then(response => {
			if (response.data != null) {
				return {
					...response.data,
					sections: response.data.sections.sort(
						(a: TFormSection, b: TFormSection) => (a.rank ?? 0) - (b.rank ?? 0)
					)
				};
			}

			return response.data as TFormStructure | null;
		})
		.catch(_error => {
			return Promise.reject();
		});
};
