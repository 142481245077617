import React, { CSSProperties } from "react";

import { $TSFixMePlayer } from "utils/tsutils";

import { NULL_TEXT } from "_react/evals/list/_constants";
import { TEvalIntlHit } from "_react/evals/list/_types";
import { GradeSection, EvalSection, EvalAttr, EvalLabel } from "_react/evals/list/_styles";
import { getPlayerAge, getRiskFactor, getHittingRisk } from "_react/evals/list/_helpers";

export type TEvalIntlHitGradesProps = {
	evaluation: TEvalIntlHit;
	player: $TSFixMePlayer;
	style?: CSSProperties;
};

export const EvalIntlHitGrades = ({ evaluation, player, style }: TEvalIntlHitGradesProps) => {
	return (
		<>
			<GradeSection width={"20%"} inlineFlex>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Age</EvalLabel> {getPlayerAge(player)}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>B/T</EvalLabel> {evaluation.bats ?? player?.bats ?? NULL_TEXT}/
						{evaluation.throws ?? player?.throws ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Risk</EvalLabel> {getRiskFactor(evaluation)}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
			<GradeSection width={"80%"} inlineFlex>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Hit</EvalLabel> {evaluation.hitting_ability_present ?? NULL_TEXT}/
						{evaluation.hitting_ability_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Raw</EvalLabel> {evaluation.raw_power_present ?? NULL_TEXT}/
						{evaluation.raw_power_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Pwr</EvalLabel> {evaluation.power_production_present ?? NULL_TEXT}/
						{evaluation.power_production_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Arm Str</EvalLabel> {evaluation.arm_strength_present ?? NULL_TEXT}/
						{evaluation.arm_strength_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Arm Acc</EvalLabel> {evaluation.arm_accuracy_present ?? NULL_TEXT}/
						{evaluation.arm_accuracy_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Field</EvalLabel> {evaluation.fielding_ability_present ?? NULL_TEXT}/
						{evaluation.fielding_ability_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Range</EvalLabel> {evaluation.fielding_range_present ?? NULL_TEXT}/
						{evaluation.fielding_range_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Run</EvalLabel> {evaluation.run_speed_present ?? NULL_TEXT}/
						{evaluation.run_speed_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>HP to 1B</EvalLabel> {evaluation.home_to_1b ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>60 Yard</EvalLabel> {evaluation["60_yard"] ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Hit Risk</EvalLabel> {getHittingRisk(evaluation)}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
		</>
	);
};
