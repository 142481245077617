import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const tables = [
	{
		title: "Group/OFP",
		columns: ["Group", "OFP", "Round", "Description"],
		rows: [
			[
				"1",
				"58-80",
				"1",
				"Plus to elite level of our ideals. Highly evident premium prospect status right away. High conviction to project an impact MLB player."
			],
			[
				"2",
				"54-57",
				"1A-3",
				"Not all ideals are necessary, but should have most and must include plus tools, skills, and performance. Highly evident prospect upon entry to pro ball. Conviction to project at least an above avg MLB player."
			],
			[
				"3",
				"51-53",
				"4-6",
				"Combinations of ideals are possible, but driven by tools and baseball skills that will stand out. Positive performance is desired, but can be dependent on strength of tool package. Immediate prospect ability. Conviction to make it to the ML's with upside for everyday status."
			],
			[
				"4",
				"47-50",
				"7-10",
				"Highly projectable with at least one avg tool. Some avg to above avg tools or one plus profile tool. Standout tool(s) with below avg performance. Quality performance with avg tools. Should come in with prospect ability, but will still need development to achieve MLB."
			],
			[
				"5",
				"40-46",
				"11-20",
				"Projection player. Will be limited in entire ideal and/or tool package, but should have one plus that could carry to MLB. Well above avg performer with limited tool package. Makeup is a must. MLB upside, but requires significant further development or could perform his way to MLB."
			]
		]
	},
	{
		title: "Position Players",
		columns: ["Scale", "AVG", "BB%", "K%", "HR/600 PA", "OPS", "RH - 1B (s)", "LH - 1B (s)", "SB (s)"],
		rows: [
			["20", ".165", "0%", "41%", "0", ".465", "4.90", "4.80", "3.70"],
			["30", ".195", "3%", "35%", "1", ".560", "4.70", "4.60", "3.60"],
			["40", ".220", "6%", "29%", "10", ".650", "4.50", "4.40", "3.50"],
			["45", ".235", "7%", "26%", "15", ".695", "4.45", "4.35", "3.45"],
			["50", ".250", "9%", "23%", "20", ".740", "4.40", "4.30", "3.40"],
			["55", ".265", "10%", "19%", "25", ".790", "4.30", "4.20", "3.35"],
			["60", ".280", "12%", "16%", "30", ".835", "4.25", "4.15", "3.30"],
			["70", ".305", "15%", "10%", "38", ".925", "4.10", "4.00", "3.20"],
			["80", ".335", "17%", "4%", "45", "1.015", "3.95", "3.85", "3.10"]
		]
	}
];

const GroupOfpTooltipHitting = () => {
	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<div
					style={{
						overflow: "scroll"
					}}
				>
					<div style={{ color: "black", fontSize: "16px" }}>
						<span style={{ fontWeight: 700, marginRight: "6px" }}>Prospect Ideals:</span>
						<span style={{ color: "black" }}>
							Select the ideal(s) that best describe this player as a prospect.
						</span>
					</div>
					{tables.map((table, idx) => {
						return (
							<Table key={idx} style={{ marginTop: "25px" }}>
								<TableHead
									style={{
										backgroundColor: defaultColorScheme.group.backgroundColor
									}}
								>
									<TableRow>
										<TableCell
											colSpan={table.columns.length}
											style={{
												color: defaultColorScheme.group.color,
												fontSize: "16px",
												textAlign: "center"
											}}
										>
											{table.title}
										</TableCell>
									</TableRow>
									<TableRow>
										{table.columns.map((column, idx) => {
											return (
												<TableCell
													key={idx}
													style={{
														color: defaultColorScheme.group.color,
														textAlign: "center",
														textTransform: "capitalize"
													}}
												>
													{column}
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
								<TableBody
									style={{
										border: "1px solid #dfe0df"
									}}
								>
									{table.rows.map((row, idx) => {
										return (
											<TableRow key={idx}>
												{row.map((cell, idx) => {
													return (
														<TableCell
															key={idx}
															style={{
																textAlign: "center",
																fontWeight: idx === 0 ? "700" : ""
															}}
														>
															{cell}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default GroupOfpTooltipHitting;
