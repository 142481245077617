import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { ColorScheme } from "_react/shared/legacy/ui/Colors";
import { Tab } from "_react/shared/legacy/ui/Tab";

import { $TSFixMe, Style } from "utils/tsutils";
import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";

type TabsProps = {
	children: $TSFixMe;
	colorScheme?: ColorScheme;
	fullWidth?: boolean;
	onChange?: $TSFixMe;
	style?: Style;
	value?: $TSFixMe;
};

const StyledTabsContainer = styled.div<TabsProps>({}, (props: TabsProps) => ({
	backgroundColor: props.colorScheme!.color,
	width: props.fullWidth ? "100%" : "",
	...props.style
}));

const StyledTabs = styled.div<TabsProps>(
	{
		borderRadius: "3px",
		padding: "1px",
		display: "flex",
		overflow: "auto"
	},
	(props: TabsProps) => ({
		backgroundColor: props.colorScheme!.color,
		...props.style
	})
);

export const Tabs: FunctionComponent<TabsProps> = ({
	children,
	colorScheme = defaultColorScheme.primary,
	onChange = () => null,
	fullWidth = false,
	style = {},
	value
}) => {
	return (
		<StyledTabsContainer colorScheme={colorScheme} fullWidth={fullWidth} style={style}>
			<StyledTabs colorScheme={colorScheme} style={style}>
				{Array.isArray(children)
					? children.map((c: $TSFixMe, idx: number) => {
							if (c === false) return null;
							const selected = value === c.props.value;
							if (c.type === Tab) {
								return React.cloneElement(
									c,
									{
										key: idx,
										onClick: () => onChange(c, c.props.value),
										selected,
										colorScheme
									},
									null
								);
							}
							return null;
					  })
					: React.cloneElement(children, {
							onClick: () => onChange(children, children.props.value),
							selected: value === children.props.value,
							colorScheme
					  })}
			</StyledTabs>
		</StyledTabsContainer>
	);
};
