import { useCallback, useEffect, RefObject, useMemo } from "react";

export function usePager<T>(page = 0, pageSize = 50, data: T[]) {
	/*
		Used to allow paging through large data sets, usually in conjunction with lazy loading
	*/
	return useMemo<T[]>(() => {
		// End of the next page
		const endViaPages = pageSize * (page + 1);
		// End of the next page or end of data, whichever is lower
		const end = data.length <= endViaPages ? data.length : endViaPages;
		// Get slice of array
		// TODO: You could implement reverse lazy loading (scrolling up) by changing the start here
		//    and adding another load (previous) div
		return data.slice(0, end);
	}, [page, pageSize, data]);
}

export const useInfiniteScroll = (
	scrollRef: RefObject<HTMLDivElement | null>,
	page: number,
	incrementPage: () => void,
	fullyLoaded = false,
	waitUntilAfterFirstButtonPressForLazyLoading = true // This feels like a Swift 2 variable name
) => {
	/*
		Used to carry out lazy loading based on the observation of the intersection of two elements (the load more div and the scroll container parent)
	*/
	const okToLoadMoreAutomatically = !waitUntilAfterFirstButtonPressForLazyLoading || page > 0;
	// Observe the scrolling of an element (node)
	const scrollObserver = useCallback(
		(node: Element) => {
			// Observe the overlap between this and it's parent
			const intObs = new IntersectionObserver(entries => {
				// Iterate through overlaps (should only be one)
				for (let i = 0; i < entries.length; i++) {
					const en = entries[i];
					// If the data hasn't fully loaded, it's ok to load more automatically, and the intersectionRatio > 0 (aka the load more scroll div has intersected with the visible part of the scroll container (List))
					if (!fullyLoaded && okToLoadMoreAutomatically && en.intersectionRatio > 0) {
						// Increment the page and ditch this element
						incrementPage();
						intObs.unobserve(node);
					}
				}
			});
			intObs.observe(node);
		},
		[incrementPage, fullyLoaded, okToLoadMoreAutomatically]
	);

	// Apply the observer
	const scrollRefCurrent = scrollRef?.current;
	useEffect(() => {
		if (scrollRefCurrent) {
			scrollObserver(scrollRefCurrent);
		}
	}, [scrollObserver, scrollRefCurrent]);
};
