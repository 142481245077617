import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function SwapVert(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 96 960 960" {...props}>
			<path
				fill="currentColor"
				d="M320 616V329L217 432l-57-56 200-200 200 200-57 56-103-103v287h-80Zm280 360L400 776l57-56 103 103V536h80v287l103-103 57 56-200 200Z"
			/>
		</Icon>
	);
}
