import React, { FunctionComponent } from "react";

import { Form } from "_react/shared/forms";

import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { amaBioPlugin } from "_react/playerpage/bio/_helpers";
import { TAmaBioDocument } from "_react/playerpage/bio/_types";

type TAmaBioProps = {
	colorScheme?: ColorSchemeGroup;
	bioDisplayOnly: boolean;
	amaBioDocument: TAmaBioDocument | null;
	setAmaBioDocument: (newDocument: TAmaBioDocument) => void;
};

export const AmaBio: FunctionComponent<TAmaBioProps> = ({
	colorScheme = defaultColorScheme,
	bioDisplayOnly,
	amaBioDocument,
	setAmaBioDocument
}) => {
	if (amaBioDocument == null) return <div />;
	return (
		<Form<TAmaBioDocument>
			colorScheme={colorScheme}
			document={amaBioDocument}
			plugin={amaBioPlugin}
			onDocumentUpdate={setAmaBioDocument}
			readOnly={bioDisplayOnly}
			showFooter={false}
			floatingFooter={false}
		/>
	);
};

export default AmaBio;
