import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function Map({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path
				fill={fill}
				d="m20.5 3-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM15 19l-6-2.11V5l6 2.11V19z"
			/>
		</IconSVG>
	);
}
