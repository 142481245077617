import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import { IListRank } from "_react/shared/data_models/list_new/_types";
import { IListGet } from "_react/shared/_types/schema/draftboard";

// List New Player

export type TListRankGetQueryParams = {
	playerId?: number;
};

export const fetchListRank = (queryParams: TListRankGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TListRankGetQueryParams, Array<IListRank>>("/list_new_player/ranks", queryParams, cancelToken);

export type TFetchDraftBoardListGetQueryParams = {
	listId?: number;
};

export const fetchDraftBoardList = (queryParams: TFetchDraftBoardListGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TFetchDraftBoardListGetQueryParams, IListGet>("/draftboard", queryParams, cancelToken);
