import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const tables = [
	{
		title:
			"Average Major League starting pitcher performance by rotation tier from 2016-2018   (130+ innings pitched)",
		columns: ["Rotation Tier", "GS", "IP", "K/9", "BB/9", "HR/9", "GB%", "WHIP", "ERA", "FIP", "ERA+"],
		rows: [
			["ACE", "32", "205", "11.71", "2.11", "0.85", "44.4%", "0.97", "2.53", "2.50", "165"],
			["TOP", "31", "190", "9.21", "2.46", "0.97", "46.8%", "1.15", "3.31", "3.52", "130"],
			["MIDDLE", "30", "170", "8.17", "2.93", "1.18", "45.5%", "1.28", "3.97", "4.10", "105"],
			["BOTTOM", "28", "156", "7.16", "3.43", "1.39", "44.7%", "1.41", "4.79", "4.84", "90"]
		]
	},
	{
		title: "Average Major League relief pitcher performance by bullpen role 2016-2018",
		columns: ["Bullpen Role", "G", "IP", "K/9", "BB/9", "HR/9", "GB%", "WHIP", "ERA", "FIP", "ERA+"],
		rows: [
			["9TH INNING", "63", "64", "10.86", "3.10", "0.78", "44.5%", "1.12", "2.72", "3.11", "160"],
			["HIGH LEV.", "65", "62", "10.13", "3.23", "0.79", "47.2%", "1.21", "3.13", "3.40", "140"],
			["MEDIUM LEV.", "63", "58", "9.30", "3.28", "0.90", "44.7%", "1.22", "3.43", "3.62", "130"],
			["LOW LEV.", "50", "49", "8.63", "3.41", "1.06", "44.3%", "1.34", "4.05", "4.01", "110"]
		]
	}
];

const ProPitcherSnapshotTooltip = () => {
	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<h1
					style={{
						textAlign: "center",
						color: defaultColorScheme.group.backgroundColor,
						fontSize: "1.2em",
						fontWeight: "bold",
						marginTop: "13px",
						marginBottom: "13px"
					}}
				>
					PITCHER SNAPSHOT
				</h1>
				<div>
					<div style={{ marginBottom: "10px" }}>
						<span
							style={{
								fontSize: "1em",
								color: "#f44335",
								fontWeight: "700"
							}}
						>
							Snapshot
						</span>
						<br />
						<span style={{ fontSize: "0.85em" }}>
							This is where you should pull together all the pitchers qualities and ultimately summarize
							the pitcher and his role. As in the past, always lead the Snapshot off with what you think
							the pitcher is or will eventually be based upon your OFP. Following that initial valuation,
							mention the pitcher’s strengths or weaknesses that led you to your decision. Examples below:
						</span>
					</div>
					<div
						style={{
							overflow: "scroll"
						}}
					>
						{tables.map((table, idx) => {
							return (
								<Table key={idx} style={{ marginTop: "25px" }}>
									<TableHead
										style={{
											backgroundColor: defaultColorScheme.group.backgroundColor
										}}
									>
										<TableRow>
											<TableCell
												colSpan={table.columns.length}
												style={{
													color: defaultColorScheme.group.color,
													fontSize: "16px",
													textAlign: "center"
												}}
											>
												{table.title}
											</TableCell>
										</TableRow>
										<TableRow>
											{table.columns.map((column, idx) => {
												return (
													<TableCell
														key={idx}
														style={{
															color: defaultColorScheme.group.color,
															textAlign: "center",
															textTransform: "capitalize"
														}}
													>
														{column}
													</TableCell>
												);
											})}
										</TableRow>
									</TableHead>
									<TableBody
										style={{
											border: "1px solid #dfe0df"
										}}
									>
										{table.rows.map((row, idx) => {
											return (
												<TableRow key={idx}>
													{row.map((cell, idx) => {
														return (
															<TableCell
																key={idx}
																style={{
																	textAlign: "center",
																	fontWeight: idx === 0 ? "700" : ""
																}}
															>
																{cell}
															</TableCell>
														);
													})}
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							);
						})}
					</div>
					<div style={{ marginBottom: "10px", marginTop: "25px" }}>
						<span
							style={{
								fontSize: "1em",
								color: "#f44335",
								fontWeight: "700"
							}}
						>
							Pitchers
						</span>
						<br />
						<span style={{ fontSize: "0.85em" }}>
							When determining what type of starter or reliever a player will be, keep in mind the above
							stat lines. These lines represent the averages over the last three Major League seasons for
							the various pitching tiers. These statistics can be used as a guideline to help with
							determining a pitcher’s OFP. Keep in mind that these numbers are not the definitive
							indicators of a pitchers value but rather provide a baseline for the type of production each
							group has produced since 2016. When labeling a starting pitcher please be sure the future
							grades agree with the definition of the role.
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProPitcherSnapshotTooltip;
