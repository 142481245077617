import { aggregateStat, getAge } from "_react/shared/_helpers/stats";
import {
	AVERAGE_AGE_PLAYER_TYPE_PITCHER,
	SOURCE_GUMBO,
	VALID_PRO_LEVELS
} from "_react/shared/data_models/stats/_constants";
import { getAgeDiff } from "_react/shared/data_models/stats/_helpers";
import { IAverageAge, IStatsPlayerPitching, IStatsPlayerPitchingByTeam } from "_react/shared/data_models/stats/_types";
import { AGE_BASE_DATE } from "_react/shared/ui/data/tables/PitcherPerformanceTable/_constants";
import {
	ICombinedStatsPlayerPitching,
	TPitcherPerformanceRow
} from "_react/shared/ui/data/tables/PitcherPerformanceTable/_types";

//
// DATA PREPROCESSING
//

// Given an array of IStatsPlayerPitching filtered by player, dedupe the data based on source
export const dedupePlayerStatsPlayerPitchingBySource = (
	statsPlayerPitching: Array<IStatsPlayerPitching>
): Array<IStatsPlayerPitching> => {
	// Populate a record to track relevant statsPlayerPitching instances
	// The record is keyed on "season-gameType"
	const statsPlayerPitchingRecord: Record<string, IStatsPlayerPitching> = {};
	statsPlayerPitching.forEach((s: IStatsPlayerPitching) => {
		// If season-gameType does not exist, or if season-gameType has the highest priority source,
		// update/create an entry in the record
		if (!(`${s.season}-${s.gameType}` in statsPlayerPitchingRecord) || s.source === SOURCE_GUMBO)
			statsPlayerPitchingRecord[`${s.season}-${s.gameType}`] = s;
	});

	// Return based on the nested values of our record.
	return Object.values(statsPlayerPitchingRecord);
};

// Given an array of IStatsPlayerPitching filtered by player and a player's birth date,
// Append their age to the data
export const appendAgeToStatsPlayerPitching = (
	statsPlayerPitching: Array<IStatsPlayerPitching>,
	birthDate: string | null
): Array<ICombinedStatsPlayerPitching> => {
	return statsPlayerPitching.map((s: IStatsPlayerPitching) => {
		return {
			...s,
			age: getAge(birthDate, s.season, AGE_BASE_DATE)
		};
	});
};

// Given an array of IStatsPlayerPitchingByTeam filtered by player, dedupe the data based on source
// Remove teams with invalid levels
export const dedupePlayerStatsPlayerPitchingByTeamAndSource = (
	statsPlayerPitchingByTeam: Array<IStatsPlayerPitchingByTeam>
): Array<IStatsPlayerPitchingByTeam> => {
	// Populate a record to track relevant statsPlayerPitchingByTeam instances
	// The record is keyed on "season-teamId-gameType"
	const statsPlayerPitchingByTeamRecord: Record<string, IStatsPlayerPitchingByTeam> = {};
	statsPlayerPitchingByTeam.forEach((s: IStatsPlayerPitchingByTeam) => {
		// Filter teams down to valid levels
		if (!VALID_PRO_LEVELS.includes(s.teamBam?.level ?? "")) return;
		// If season-teamId-gameType does not exist, or if season-teamId-gameType has the highest priority source,
		// update/create an entry in the record
		if (!(`${s.season}-${s.teamId}-${s.gameType}` in statsPlayerPitchingByTeamRecord) || s.source === SOURCE_GUMBO)
			statsPlayerPitchingByTeamRecord[`${s.season}-${s.teamId}-${s.gameType}`] = s;
	});

	// Return based on the nested values of our record.
	return Object.values(statsPlayerPitchingByTeamRecord);
};

// Given an array of IStatsPlayerPitchingByTeam filtered by player and a player's birth date,
// and the list of average ages, append their age and relative age to the data
export const appendAgeToStatsPlayerPitchingByTeam = (
	statsPlayerPitchingByTeam: Array<IStatsPlayerPitchingByTeam>,
	birthDate: string | null,
	averageAges: Array<IAverageAge>
): Array<ICombinedStatsPlayerPitching> => {
	const acc: Array<ICombinedStatsPlayerPitching> = [];
	statsPlayerPitchingByTeam.forEach((s: IStatsPlayerPitchingByTeam) => {
		const age: number | null = getAge(birthDate, s.season, AGE_BASE_DATE);
		acc.push({
			...s,
			age: age,
			relAge:
				age && s.teamBam?.level
					? getAgeDiff(age, s.teamBam.level, AVERAGE_AGE_PLAYER_TYPE_PITCHER, s.season, averageAges)
					: null
		});
	});
	return acc;
};

//
// Aggregating Data
//

export const aggregatePitcherPerformanceDataRows = (
	prev: ICombinedStatsPlayerPitching,
	curr: ICombinedStatsPlayerPitching
): ICombinedStatsPlayerPitching => {
	return {
		...prev,
		g: (curr.g ?? 0) + (prev.g ?? 0),
		gs: (curr.gs ?? 0) + (prev.gs ?? 0),
		ip: (curr.ip ?? 0) + (prev.ip ?? 0),
		eraMinus: aggregateStat(prev.tbf ?? 0, prev.eraMinus ?? null, curr.tbf ?? 0, curr.eraMinus ?? null),
		xfipMinus: aggregateStat(prev.tbf ?? 0, prev.xfipMinus ?? null, curr.tbf ?? 0, curr.xfipMinus ?? null),
		tbf: (curr.tbf ?? 0) + (prev.tbf ?? 0),
		fbv: aggregateStat(prev.fbPitches ?? 0, prev.fbv ?? null, curr.fbPitches ?? 0, curr.fbv ?? null),
		fbPitches: (curr.fbPitches ?? 0) + (prev.fbPitches ?? 0)
	};
};

// Used to get the level to display for a row
export const getLevelsFromRow = (row: TPitcherPerformanceRow): Array<string> => {
	// Child Rows or rows with no nested data
	if ("teamBam" in row.pitcherPerformanceData)
		return row.pitcherPerformanceData.teamBam?.level ? [row.pitcherPerformanceData.teamBam?.level] : [];
	// Parent Rows with nested data
	if (row.childData && row.childData.length > 1) {
		return [
			...new Set(
				row.childData.reduce((acc: Array<string>, childRow: TPitcherPerformanceRow) => {
					if ("teamBam" in childRow.pitcherPerformanceData && childRow.pitcherPerformanceData.teamBam?.level)
						acc.push(childRow.pitcherPerformanceData.teamBam?.level);
					return acc;
				}, [])
			)
		];
	}
	return [];
};
