import { round10 } from "_react/shared/_helpers/numbers";
import { IAverageAge } from "_react/shared/data_models/stats/_types";

export const formatThreeDecimal = (value?: string | number | null) => {
	if (value == null) return value;
	if (typeof value === "string") value = parseFloat(value);
	return round10(value, -3, true);
};

export const formatPlusOrMinusStat = (value?: string | number | null) => {
	if (value == null) return value;
	if (typeof value === "string") value = parseFloat(value);
	return round10(value, 0);
};

export const formatIp = (value?: string | number | null) => {
	if (value == null) return value;
	if (typeof value !== "string") value = String(value);

	const splitValue = value.split(".");
	if (splitValue.length < 2 || splitValue[1].length < 1) return `${value}.0`;

	return splitValue[1][0] === "0"
		? `${splitValue[0]}.0`
		: splitValue[1][0] === "3"
		? `${splitValue[0]}.1`
		: splitValue[1][0] === "6"
		? `${splitValue[0]}.2`
		: null;
};

export const formatFastballVelo = (value?: string | number | null) => {
	if (value == null) return value;
	if (typeof value === "string") value = parseFloat(value);
	return round10(value, -1);
};

export const getAgeDiff = (
	age: number,
	level: string,
	playerType: string,
	year: number,
	averageAges: Array<IAverageAge>
): number | null => {
	const avgAgeIndex = averageAges.findIndex((averageAge: IAverageAge) => {
		return averageAge.level === level && averageAge.playerType === playerType && averageAge.year === year;
	});
	if (avgAgeIndex === -1) return null;
	const averageAge = averageAges[avgAgeIndex].averageAge;
	if (averageAge === null) return null;
	return age - averageAge;
};
