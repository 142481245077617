import { CSSProperties } from "react";
import styled from "@emotion/styled";
import { RED, REDDARK, REDTEXT, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

export const FormContainer = styled.div<{ formNavigationHeightOffset: number; paddingBottom: string }>(
	({ formNavigationHeightOffset = 0, paddingBottom = "" }) => ({
		margin: "10px",
		"@media (max-width: 700px)": {
			marginTop: `${formNavigationHeightOffset + 4}px`
		},
		paddingBottom
	})
);

export const FormSectionContainer = styled.div<{ flexDirection?: CSSProperties["flexDirection"] }>(
	({ flexDirection = "row" }) => ({
		display: "flex",
		alignItems: "stretch",
		justifyContent: "center",
		flexDirection,
		"@media (max-width: 700px)": {
			display: "block"
		}
	})
);

export const FormSectionContainerInner = styled.div({
	display: "flex",
	alignItems: "stretch",
	justifyContent: "center",
	flexDirection: "column",
	"@media (max-width: 700px)": {
		display: "block"
	}
});

export const FormColumnRowContainer = styled.div({
	display: "flex",
	alignItems: "stretch",
	justifyContent: "center",
	flexDirection: "column",
	flexGrow: 1,
	"@media (max-width: 700px)": {
		display: "block"
	}
});

export const FormColumnContainer = styled.div<{
	flexBasis?: string;
	flex?: number;
	items: number;
	isCollection?: boolean;
}>(({ flexBasis, flex, items = 0, isCollection = false }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "stretch",
	justifyContent: isCollection ? "center" : "flex-start",
	flexBasis,
	flex,
	backgroundColor: items % 2 === 0 ? "#e7e8ed" : "white"
}));

export const FormRowContainer = styled.div<{
	flexBasis?: string;
	flex?: number;
	items: number;
}>(({ flexBasis, flex, items = 0 }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "stretch",
	justifyContent: "flex-start",
	flexBasis,
	flex,
	backgroundColor: items % 2 === 0 ? "#e7e8ed" : "white"
}));

export const FormComponentContainer = styled.div<{
	padding: string;
	isInvalid: boolean;
	evenRow?: boolean;
	isRowBased?: boolean;
	flex?: number;
}>(({ padding = "5px", isInvalid = false, evenRow = false, isRowBased = false, flex }) => ({
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center",
	padding,
	backgroundColor: isInvalid ? "#FF000040" : evenRow ? "white" : "#e7e8ed",
	height: isRowBased ? undefined : "100%",
	width: isRowBased ? "100%" : undefined,
	flex: flex
}));

export const FormCollectionDeleteRowContainer = styled.div<{ evenRow?: boolean }>(({ evenRow = false }) => ({
	backgroundColor: evenRow ? "white" : "#e7e8ed",
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
}));

export const FormComponentLabelSpan = styled.span({
	fontWeight: "bold",
	marginRight: "5px"
});

export const FormSectionRow = styled.div({
	display: "flex",
	justifyContent: "center",
	alignItems: "flex-start",
	"@media (max-width: 1600px)": {
		display: "block"
	}
});

export const FormSectionRowContainer = styled.div<{ flexBasis: number }>(({ flexBasis = 100 }) => ({
	flexBasis: `${flexBasis}%`,
	paddingLeft: "4px",
	paddingRight: "4px",
	"&:first-of-type": {
		paddingLeft: 0
	},
	"&:last-of-type": {
		paddingRight: 0
	},
	"@media (max-width: 1600px)": {
		padding: 0
	}
}));

export const FormNavigationContainer = styled.div({
	padding: "1px 3px",
	backgroundColor: RED,
	color: REDTEXT,
	fontWeight: "bold",
	fontSize: "14px",
	borderRadius: "3px",
	marginBottom: "10px",
	display: "none",
	alignItems: "center",
	justifyContent: "center",
	position: "fixed",
	top: "45px",
	width: "calc(100% - 26px)",
	zIndex: 999,
	"@media (max-width: 700px)": {
		display: "flex"
	},
	"@media print": {
		display: "none"
	}
});

export const FormNavigationJumpTo = styled.span({
	cursor: "pointer",
	backgroundColor: REDDARK,
	color: REDTEXT,
	padding: "6px 4px",
	borderRadius: "3px",
	flexGrow: 1,
	margin: "6px 0px",
	textAlign: "center"
});

export const FORM_NAVIGATION_ARROW_STYLE = { cursor: "pointer", padding: "2px 4px" };

export const ICON_BUTTON_STYLE = { margin: "4px", height: "24px", width: "24px" };
export const BUTTON_STYLE = { margin: "4px", alignSelf: "center" };
export const ADD_ICON_STYLE = { marginRight: "4px", height: "18px", width: "18px" };
export const DELETE_ICON_STYLE = { height: "16px", width: "16px" };

export const FormCollectionContainer = styled.div({
	display: "flex",
	alignItems: "stretch",
	justifyContent: "center",
	flexDirection: "column"
});

export const AddButtonTitle = styled.span({ display: "flex", alignItems: "center", justifyContent: "center" });

type TFormFooterContainerProps = { floating?: boolean };
export const FormFooterContainer = styled.div<TFormFooterContainerProps>(({ floating = false }) => {
	if (floating)
		return {
			position: "fixed",
			left: 0,
			right: 0,
			bottom: 0,
			borderTop: `2px solid ${defaultColorScheme.secondary.color}`,
			padding: "6px",
			backgroundColor: "white",
			display: "flex",
			alignItems: "center",
			"@media print": {
				display: "none"
			}
		};

	return {
		padding: "6px",
		display: "flex",
		alignItems: "center",
		"@media print": {
			display: "none"
		}
	};
});
