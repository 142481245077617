import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function ExpandLess({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
			<path fill={"none"} d="M0 0h24v24H0z" />
		</IconSVG>
	);
}
