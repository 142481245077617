import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";

import {
	IPitchOutcomeObservedPitch,
	IPitchOutcomeObservedPitchByTeam
} from "_react/shared/data_models/pitch_outcome_observed/_types";

// PoO Pitch

export type TPitchOutcomeObservedPitchGetQueryParams = {
	playerId?: number;
	season?: number;
	"season[in]"?: string;
	playingLevel?: string;
	throws?: string;
	bats?: string;
	gameType?: string;
	countSplit?: string;
	pitchType?: string;

	total?: number;
	swingPct?: number;
	whiffPct?: number;
	chasePct?: number;
	cswPct?: number;

	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
	schema?: string;
};

export const fetchPitchOutcomeObservedPitch = (
	queryParams: TPitchOutcomeObservedPitchGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPitchOutcomeObservedPitchGetQueryParams, Array<IPitchOutcomeObservedPitch>>(
		"/observed_pitch_outcomes/player_season",
		queryParams,
		cancelToken
	);

export type TPitchOutcomeObservedPitchByTeamGetQueryParams = TPitchOutcomeObservedPitchGetQueryParams & {
	teamId?: number;
	"teamId[in]"?: string;
};

export const fetchPitchOutcomeObservedPitchByTeam = (
	queryParams: TPitchOutcomeObservedPitchByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPitchOutcomeObservedPitchByTeamGetQueryParams, Array<IPitchOutcomeObservedPitchByTeam>>(
		"/observed_pitch_outcomes/player_season/team",
		queryParams,
		cancelToken
	);
