import React from "react";

import { SquareBadge } from "_react/shared/legacy/ui/SquareBadge";
import { RED, ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import Check from "_react/shared/legacy/ui/icons/Check";
import Cancel from "_react/shared/legacy/ui/icons/Cancel";
import { $TSFixMeEval } from "_react/evals/shared/_types";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { getProIntlAvgOfp } from "_react/playerpage/shared_js/evals/_helpers";
import { BadgeRow } from "_react/playerpage/header/badges";

type TProBadgesProps = {
	colorSchemeGroup: ColorSchemeGroup;
	evaluations?: $TSFixMeEval[] | null;
	player: TPlayerPageCombinedPlayer;
};

export default function ProBadges({ colorSchemeGroup, evaluations, player }: TProBadgesProps) {
	return (
		<div>
			<BadgeRow>
				<SquareBadge
					colorSchemeGroup={colorSchemeGroup}
					label={"Org/Level"}
					content={player.proProfile?.orgCode ?? "FA"}
					subText={player.proProfile?.orgCode ? player.team?.level ?? undefined : undefined}
				/>
				<SquareBadge
					colorSchemeGroup={colorSchemeGroup}
					label={"OFP"}
					loading={evaluations == null}
					content={getProIntlAvgOfp(evaluations, "PRO")}
				/>
			</BadgeRow>
			<BadgeRow>
				<SquareBadge
					colorSchemeGroup={colorSchemeGroup}
					label={player.proProfile?.isOn40ManRoster ? "40 Man" : "Rule 5"}
					content={
						player.proProfile?.isOn40ManRoster || player.proProfile?.isR5Eligible ? (
							<Check
								style={{
									color: "#003831",
									height: "38px",
									width: "38px"
								}}
							/>
						) : (
							<Cancel
								style={{
									color: RED,
									height: "38px",
									width: "38px"
								}}
							/>
						)
					}
				/>
				<SquareBadge
					colorSchemeGroup={colorSchemeGroup}
					label={"Bats/Throws"}
					content={`${player.bats}/${player.throws}`}
				/>
			</BadgeRow>
		</div>
	);
}
