export const FETCH_AGENTS_SUCCESS = "AGENT_FETCH_AGENTS_SUCCESS",
	CREATE_AGENT_SUCCESS = "AGENT_CREATE_AGENT_SUCCESS",
	CREATE_AGENT_FAILURE = "AGENT_CREATE_AGENT_FAILURE",
	CREATE_AGENCY_SUCCESS = "AGENT_CREATE_AGENCY_SUCCESS",
	CREATE_AGENCY_FAILURE = "AGENT_CREATE_AGENCY_FAILURE",
	FETCH_AGENCIES_SUCCESS = "AGENT_FETCH_AGENCIES_SUCCESS",
	FETCH_AGENCIES_FAILURE = "AGENT_FETCH_AGENCIES_FAILURE",
	FETCH_AGENT_PLAYERS_REQUEST = "AGENT_FETCH_AGENT_PLAYERS_REQUEST",
	FETCH_AGENT_PLAYERS_SUCCESS = "AGENT_FETCH_AGENT_PLAYERS_SUCCESS",
	FETCH_AGENT_PLAYERS_FAILURE = "AGENT_FETCH_AGENT_PLAYERS_FAILURE",
	FETCH_AGENCY_PLAYERS_REQUEST = "AGENT_FETCH_AGENCY_PLAYERS_REQUEST",
	FETCH_AGENCY_PLAYERS_SUCCESS = "AGENT_FETCH_AGENCY_PLAYERS_SUCCESS",
	FETCH_AGENCY_PLAYERS_FAILURE = "AGENT_FETCH_AGENCY_PLAYERS_FAILURE";
