import React, { createContext, useState } from "react";
import axios from "_redux/_utils/_axios";
import { $TSFixMe } from "utils/tsutils";
import { MAJOR } from "_react/playerpage/_constants";
import { TContract, TLevel } from "_react/playerpage/contract/_types";

type TContext = { getContract(contractId: number, level: TLevel): Promise<TContract> };

const ContractContext = createContext<TContext>({
	getContract: (_contractId, _level) =>
		Promise.resolve({
			contract_id: 0,
			contract_mj_years: [],
			contract_mj_option_years: [],
			contract_mj_bonuses: []
		})
});

function makeLookupKey(contractId: number, level: TLevel) {
	return `${contractId}~${level}`;
}

const ContractProvider = (props: $TSFixMe) => {
	const [contracts, setContracts] = useState<{ [index: string]: TContract }>({});

	const getContract: TContext["getContract"] = (contractId, level) => {
		const lookupKey = makeLookupKey(contractId, level);
		if (contracts.hasOwnProperty(lookupKey)) {
			return Promise.resolve(contracts[lookupKey]);
		} else {
			const resource = level === MAJOR ? "contract_majors" : "contract_minors";
			return axios.get(`/${resource}/${contractId}`).then((response: { data: TContract }) => {
				const newContracts = {
					...contracts,
					[lookupKey]: response.data
				};
				setContracts(newContracts);
				return response.data;
			});
		}
	};

	return (
		<ContractContext.Provider
			value={{
				getContract
			}}
			{...props}
		/>
	);
};

export { ContractContext, ContractProvider };
