import { CancelToken } from "axios";

import axios from "_redux/_utils/_axios";

export const loadSearchResults = (
	searchText: string,
	classifications: string[],
	orgId?: number,
	cancelToken?: CancelToken
) => {
	// Hit API
	const orgIdSuffix = orgId != null ? `&orgId=${orgId}` : "";
	const path = `/search?q=${searchText}&classifications=${classifications.join(",")}${orgIdSuffix}`;
	return axios.get(path, { cancelToken }).catch(error => {
		return axios.isCancel(error) ? Promise.resolve({ data: [] }) : Promise.reject(error);
	});
};
