import React from "react";
import { $TSFixMe } from "utils/tsutils";
import FastForward from "_react/shared/legacy/ui/icons/FastForward";
import FastRewind from "_react/shared/legacy/ui/icons/FastRewind";
import FullScreenExit from "_react/shared/legacy/ui/icons/FullscreenExit";
import FullScreen from "_react/shared/legacy/ui/icons/Fullscreen";
import Cancel from "_react/shared/legacy/ui/icons/Cancel";
import Loop from "_react/shared/legacy/ui/icons/Loop";
import Pause from "_react/shared/legacy/ui/icons/Pause";
import PlayArrow from "_react/shared/legacy/ui/icons/PlayArrow";
import RotateRight from "_react/shared/legacy/ui/icons/RotateRight";
import SkipNext from "_react/shared/legacy/ui/icons/SkipNext";
import SkipPrevious from "_react/shared/legacy/ui/icons/SkipPrevious";
import { useIsMobile } from "_react/_hooks";
import { TOption } from "_react/inputs";
import VideoViewSelect from "_react/video/shared/VideoViewSelect";
import { RED } from "_react/shared/legacy/ui/Colors";
import VolumeUp from "_react/shared/legacy/ui/icons/VolumeUp";
import VolumeOff from "_react/shared/legacy/ui/icons/VolumeOff";

const formatDuration = (video: $TSFixMe) => {
	if (!video || !video.duration || !video.currentTime) {
		return "";
	}
	const timeRemaining = video.duration - video.currentTime;
	const timeRemainingMinutes = timeRemaining / 60.0;
	const [minutes, fractionalMinutes] = timeRemainingMinutes.toString().split(".");
	const secondsPart = parseInt((parseFloat("." + fractionalMinutes) * 60).toFixed(0));
	if (secondsPart < 10) {
		return `${minutes}:${"0" + secondsPart}`;
	}
	return `${minutes}:${secondsPart === 60 ? 59 : secondsPart}`;
};

const pausePlayStyle: React.CSSProperties = {
	display: "inline-block",
	cursor: "pointer"
};
type TVideoControls = {
	handleExitVideoScreen: Function | undefined;
	handleKeyDown: ((event: React.KeyboardEvent<HTMLDivElement>) => void) | undefined;
	handlePlayNext: Function | undefined;
	handlePlayPrev: Function | undefined;
	handleRotate: Function;
	handleSeek: Function;
	isInset?: boolean;
	seekBarValue: number;
	videoPlayer: $TSFixMe;
	videoContainerId: string;
	views?: Array<TOption<string>>;
	viewIndex?: string;
	setViewIndex: (viewIndex: string) => void;
	srcViews?: { [index: string]: string };
	muted: boolean;
	setMuted: (muted: boolean) => void;
};

function VideoControls({
	handleSeek,
	handleKeyDown,
	handleRotate,
	videoPlayer,
	seekBarValue,
	handleExitVideoScreen,
	handlePlayPrev,
	handlePlayNext,
	isInset,
	videoContainerId,
	views,
	viewIndex,
	setViewIndex,
	srcViews,
	muted,
	setMuted
}: TVideoControls) {
	function canFullScreen() {
		const container = document.getElementById(videoContainerId) as $TSFixMe;
		return (
			container &&
			(document.fullscreenEnabled ||
				container.webkitRequestFullscreen ||
				container.mozRequestFullScreen ||
				container.msRequestFullscreen)
		);
	}

	const isMobile = useIsMobile();

	if (!videoPlayer) {
		return <div />;
	}

	const container = document.getElementById(videoContainerId) as $TSFixMe;
	const isFullScreen =
		document.fullscreenElement ||
		container.webkitFullscreenElement ||
		container.mozFullscreenElement ||
		container.msFullscreenElement;

	return (
		<div
			onKeyDown={handleKeyDown}
			style={{
				display: "flex",
				position: isInset ? "static" : "absolute",
				bottom: isInset ? "" : 0,
				left: isInset ? "" : 0,
				right: isInset ? "" : 0,
				zIndex: isInset ? 990 : 1102,
				backgroundColor: "black",
				color: "white",
				justifyContent: "stretch",
				alignItems: "stretch",
				flexDirection: "column",
				padding: "4px"
			}}
		>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<Loop
					fill={RED}
					onClick={() => (videoPlayer.loop = videoPlayer.loop ? false : true)}
					style={{
						display: "inline-block",
						cursor: "pointer",
						backgroundColor: videoPlayer.loop ? "yellow" : "inherit"
					}}
				/>
				{!isMobile && handlePlayPrev && (
					<SkipPrevious
						fill={RED}
						onClick={() => {
							videoPlayer.loop = false;
							handlePlayPrev();
						}}
						style={{
							display: "inline-block",
							cursor: "pointer"
						}}
					/>
				)}
				<FastRewind
					fill={RED}
					onClick={() => {
						videoPlayer.pause();
						videoPlayer.currentTime -= 1 / 29.97;
					}}
					style={{
						display: "inline-block",
						cursor: "pointer"
					}}
				/>
				{videoPlayer && videoPlayer.paused ? (
					<PlayArrow fill={RED} onClick={() => videoPlayer.play()} style={pausePlayStyle} />
				) : (
					<Pause
						fill={RED}
						onClick={() => {
							videoPlayer.pause();
						}}
						style={pausePlayStyle}
					/>
				)}
				<FastForward
					fill={RED}
					onClick={() => {
						videoPlayer.pause();
						videoPlayer.currentTime += 1 / 29.97;
					}}
					style={{
						display: "inline-block",
						cursor: "pointer"
					}}
				/>
				{!isMobile && handlePlayNext && (
					<SkipNext
						fill={RED}
						onClick={() => {
							videoPlayer.loop = false;
							handlePlayNext();
						}}
						style={{
							display: "inline-block",
							cursor: "pointer"
						}}
					/>
				)}
				{!isMobile && (
					<input
						id="seek-bar"
						onChange={() => null}
						onInput={e => handleSeek(e.currentTarget.value)}
						onKeyDown={handleKeyDown}
						style={{
							display: "inline-block",
							boxShadow: "none",
							flexGrow: 1
						}}
						type="range"
						value={seekBarValue ? seekBarValue : 0}
					/>
				)}
				<span
					style={{
						display: "inline-block",
						color: RED
					}}
				>
					{formatDuration(videoPlayer)}
				</span>
				{!muted && (
					<VolumeUp
						fill={RED}
						onClick={e => {
							e.preventDefault();
							setMuted(true);
						}}
						style={{
							display: "inline-block",
							cursor: "pointer"
						}}
					/>
				)}
				{muted && (
					<VolumeOff
						fill={RED}
						onClick={e => {
							e.preventDefault();
							setMuted(false);
						}}
						style={{
							display: "inline-block",
							cursor: "pointer"
						}}
					/>
				)}
				<RotateRight
					fill={RED}
					onClick={e => {
						e.preventDefault();
						handleRotate(e);
					}}
					style={{
						display: "inline-block",
						cursor: "pointer"
					}}
				/>
				{canFullScreen() && !isFullScreen && (
					<FullScreen
						fill={RED}
						onClick={e => {
							if (e) {
								e.preventDefault();
								const container = document.getElementById(videoContainerId) as $TSFixMe;
								if (document.fullscreenEnabled && !document.fullscreenElement) {
									container.requestFullscreen();
								} else if (container.webkitRequestFullscreen && !container.webkitFullscreenElement) {
									container.webkitRequestFullscreen();
								} else if (container.mozRequestFullscreen && !container.mozFullscreenElement) {
									container.mozRequestFullScreen();
								} else if (container.msRequestFullscreen && !container.msFullscreenElement) {
									container.msRequestFullscreen();
								}
							}
						}}
						style={{
							display: "inline-block",
							cursor: "pointer"
						}}
					/>
				)}
				{canFullScreen() && isFullScreen && (
					<FullScreenExit
						fill={RED}
						onClick={e => {
							if (e) {
								e.preventDefault();
								const documentTyped = document as $TSFixMe;
								if (document.fullscreenElement) {
									document.exitFullscreen();
								} else if (documentTyped.webkitExitFullscreen) {
									documentTyped.webkitExitFullscreen();
								} else if (documentTyped.mozExitFullscreen) {
									documentTyped.mozExitFullscreen();
								} else if (documentTyped.msExitFullscreen) {
									documentTyped.msExitFullscreen();
								}
							}
						}}
						style={{
							display: "inline-block",
							cursor: "pointer"
						}}
					/>
				)}
				{handleExitVideoScreen && (
					<Cancel
						fill={RED}
						onClick={e => {
							if (e) {
								e.preventDefault();
							}
							handleExitVideoScreen();
						}}
						style={{
							display: "inline-block",
							cursor: "pointer"
						}}
					/>
				)}
			</div>
			{srcViews != null && views && (
				<div style={{ display: "flex" }}>
					<VideoViewSelect views={views} viewIndex={viewIndex} setViewIndex={setViewIndex} />
				</div>
			)}
		</div>
	);
}

export default VideoControls;
