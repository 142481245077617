import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import Checkbox from "@material-ui/core/Checkbox";
import { EditorState, ContentState, convertFromRaw, Editor, CompositeDecorator } from "draft-js";
import Select, { CSSObjectWithLabel } from "react-select";

import Autocomplete from "_react/shared/legacy/autocomplete/Autocomplete";
import { isValidDate } from "utils/functions";
import { isMobile } from "utils/_helpers";

const NOT_ADDITIONAL_PROPS = new Set(["partialWidth", "noWidthConform", "noLeftPad", "noRightPad", "windowWidth"]);
const getFilteredAdditionalProps = additionalProps => {
	return Object.keys(additionalProps)
		.filter(key => !NOT_ADDITIONAL_PROPS.has(key))
		.reduce((e, v) => {
			e[v] = additionalProps[v];
			return e;
		}, {});
};

export function getFormObjectDisplayValue(configuration, value) {
	if (configuration.type === "autocomplete") {
		const options = configuration.options.filter(e => e.value === value);
		if (options.length > 0) return options[0].text;
	} else if (configuration.type === "date" && value) {
		if (value.length === 8) {
			return dayjs(value, "MMDDYYYY").format("MM/DD/YYYY");
		}
	}

	return value;
}

export const FormObjectText = ({
	id,
	multiline = false,
	onBlur = () => null,
	onChange,
	onFocus = () => null,
	placeholder = "",
	value,
	style = {},
	...additionalProps
}) => {
	["onBottom", "windowWidth", "format", "partialWidth", "show", "label", "containerStyle", "objectStyle"].forEach(
		prop => {
			additionalProps = _.omit(additionalProps, prop);
		}
	);
	return (
		<TextField
			{...additionalProps}
			fullWidth
			id={id}
			inputProps={{ maxLength: additionalProps.maxLength }}
			InputProps={{
				style: {
					fontSize: isMobile(additionalProps.windowWidth) ? "1em" : style ? style.fontSize : ""
				}
			}}
			margin="normal"
			multiline={multiline}
			onBlur={onBlur}
			onChange={e => onChange({ value: e.target.value })}
			onFocus={onFocus}
			placeholder={placeholder}
			style={{
				height: multiline ? null : "32px",
				marginTop: "0px",
				marginBottom: "0px",
				display: "inline-block",
				...style
			}}
			value={value !== null ? value : ""}
		/>
	);
};

FormObjectText.propTypes = {
	id: PropTypes.string,
	multiline: PropTypes.bool,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	placeholder: PropTypes.string,
	style: PropTypes.object,
	value: PropTypes.string
};

// DraftJS Customizations
// eslint-disable-next-line no-useless-escape
const SNAPSHOT_REGEX = /^.*?([\.!\?]|$)(?:\s|$)/g;
function findWithRegex(regex, contentBlock, callback) {
	const text = contentBlock.getText();
	let matchArr, start;
	while ((matchArr = regex.exec(text)) !== null) {
		start = matchArr.index;
		callback(start, start + (matchArr[0].length > 250 ? 250 : matchArr[0].length));
	}
}

function findSnapshot(contentBlock, callback, _contentState) {
	findWithRegex(SNAPSHOT_REGEX, contentBlock, callback);
}

const SnapshotBolded = props => {
	return <span style={{ fontWeight: 900 }}>{props.children}</span>;
};

SnapshotBolded.propTypes = {
	children: PropTypes.object
};

export const FormObjectSummarySnapshot = ({
	id,
	onBlur = () => null,
	onChange,
	onFocus = () => null,
	value,
	...additionalProps
}) => {
	["onBottom", "windowWidth", "format", "partialWidth", "show", "label", "containerStyle", "objectStyle"].forEach(
		prop => {
			additionalProps = _.omit(additionalProps, prop);
		}
	);
	const legacyNotePlaintext = value == null || (typeof value === "string" && !value.includes("{"));
	const convertedFromRaw = value != null && typeof value !== "string";

	const decorator = new CompositeDecorator([
		{
			strategy: findSnapshot,
			component: SnapshotBolded
		}
	]);
	const editorState = convertedFromRaw
		? value
		: EditorState.createWithContent(
				legacyNotePlaintext || value === ""
					? ContentState.createFromText(value ?? "")
					: convertFromRaw(JSON.parse(value)),
				decorator
		  );

	return (
		<Editor
			{...additionalProps}
			onBlur={onBlur}
			onFocus={onFocus}
			id={id}
			onChange={newEditorState => onChange({ value: newEditorState })}
			editorState={editorState}
			readOnly={false}
			textAlignment="left"
			spellCheck={true}
		/>
	);
};

FormObjectSummarySnapshot.propTypes = {
	id: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	placeholder: PropTypes.string,
	style: PropTypes.object,
	value: PropTypes.string
};
function DateNumberFormat(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			format="##/##/####"
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						value: values.value
					}
				});
			}}
			placeholder="MM/DD/YYYY"
		/>
	);
}

DateNumberFormat.propTypes = {
	inputRef: PropTypes.object,
	onChange: PropTypes.func
};

export const FormObjectDate = ({
	id,
	onBlur = () => null,
	onChange,
	onFocus = () => null,
	value,
	style = {},
	...additionalProps
}) => {
	["windowWidth", "show", "type", "label", "objectStyle", "containerStyle"].forEach(prop => {
		additionalProps = _.omit(additionalProps, prop);
	});
	return (
		<TextField
			{...additionalProps}
			fullWidth
			id={id}
			InputProps={{
				style: {
					fontSize: isMobile(additionalProps.windowWidth) ? "1em" : style ? style.fontSize : ""
				}
			}}
			margin="normal"
			onBlur={onBlur}
			onChange={e => {
				onChange({
					value: e.target.value !== "" ? e.target.value : null
				});
			}}
			onFocus={onFocus}
			style={{
				marginTop: "0px",
				marginBottom: "0px",
				display: "inline-block",
				...style
			}}
			type="date"
			value={value != null ? value.replace(" 00:00:00", "") : ""}
		/>
	);
};

FormObjectDate.propTypes = {
	id: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	style: PropTypes.object,
	value: PropTypes.string
};

function formattedHeight(val) {
	if (val.length === 0) return val;
	if (val.length === 1) return `${val}'`;
	const feet = val.substring(0, 1);
	let inches = val.substring(1);
	if (inches.length > 2) inches = inches.substring(0, 2);

	return `${feet}' ${inches}"`;
}

function HeightNumberFormat(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			format={formattedHeight}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						value: values.value
					}
				});
			}}
			placeholder={`ft' in"`}
		/>
	);
}

HeightNumberFormat.propTypes = {
	inputRef: PropTypes.object,
	onChange: PropTypes.func
};

const FormObjectHeight = ({ id, onBlur, onChange, onFocus, value, style, ...additionalProps }) => (
	<TextField
		{..._.omit(_.omit(_.omit(_.omit(additionalProps, "windowWidth"), "show"), "type"), "label")}
		fullWidth
		id={id}
		InputProps={{
			inputComponent: HeightNumberFormat,
			style: {
				fontSize: isMobile(additionalProps.windowWidth) ? "1em" : style ? style.fontSize : ""
			}
		}}
		margin="normal"
		onBlur={onBlur}
		onChange={e => {
			onChange({
				value: e.target.value !== "" ? e.target.value : null
			});
		}}
		onFocus={onFocus}
		style={{
			marginTop: "0px",
			marginBottom: "0px",
			display: "inline-block",
			...style
		}}
		value={value !== null ? value : ""}
	/>
);

FormObjectHeight.propTypes = {
	id: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	style: PropTypes.object,
	value: PropTypes.number
};

const FormObjectAutocomplete = ({
	id,
	placeholder,
	options,
	onChange,
	numCharToShowSuggestions,
	style,
	value,
	...additionalProps
}) => (
	<Autocomplete
		{..._.omit(_.omit(additionalProps, "show"), "label")}
		clearOnBlur={true}
		dataSource={options}
		handleSelected={onChange}
		inputProps={{
			style: {
				fontSize: isMobile(additionalProps.windowWidth) ? "1em" : style ? style.fontSize : ""
			}
		}}
		maxSearchResults={additionalProps.maxSearchResults}
		numCharToShowSuggestions={numCharToShowSuggestions}
		placeholder={placeholder}
		showClear={true}
		style={{
			height: "32px",
			margin: 0,
			...style
		}}
		tabSelect={true}
		valueSelected={{
			value,
			text: getFormObjectDisplayValue({ id, options, type: "autocomplete" }, value)
		}}
	/>
);

FormObjectAutocomplete.propTypes = {
	id: PropTypes.string,
	numCharToShowSuggestions: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	style: PropTypes.object,
	value: PropTypes.string
};

const FormObjectAutocompleteMulti = ({ id, placeholder, options, onChange, value }) => {
	const values = value ? value.split(",") : null;
	const optionSelected = values ? options.filter(o => values.includes(o.value)) : null;

	return (
		<Select
			components={{ DropdownIndicator: null }}
			getOptionLabel={t => (t ? t.text : "")}
			id={id}
			isMulti={true}
			onChange={val => onChange({ value: val ? val.map(v => v.value).join(",") : null })}
			options={options}
			placeholder={placeholder}
			styles={{
				control: () => ({
					display: "flex",
					transition: "all 100ms",
					fontSize: 14,
					borderBottom: "1px solid darkGray",
					marginBottom: "2px"
				}),
				input: provided => ({
					...provided,
					fontSize: 14
				}),
				loadingIndicator: provided => ({
					...provided
				}),
				option: provided => ({
					...provided,
					color: "black",
					cursor: "pointer"
				}),
				placeholder: provided => ({
					...provided,
					fontSize: 14,
					fontWeight: 400,
					opacity: 0.5,
					color: "darkGray"
				}),
				singleValue: (provided, state) => ({
					...provided,
					display: state.selectProps.menuIsOpen ? "none" : "block",
					fontSize: 14
				}),
				valueContainer: (provided: CSSObjectWithLabel) => ({
					...provided,
					fontWeight: 400
				})
			}}
			value={optionSelected}
		/>
	);
};

FormObjectAutocompleteMulti.propTypes = {
	id: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	value: PropTypes.string
};

export const FormObjectCheckbox = ({ disabled = false, id, onChange, value, style = {}, ...additionalProps }) => (
	<Checkbox
		{...getFilteredAdditionalProps(additionalProps)}
		checked={value}
		disabled={disabled}
		id={id}
		onChange={e => onChange({ value: e.target.checked })}
		style={style}
		value="checked"
	/>
);

FormObjectCheckbox.propTypes = {
	disabled: PropTypes.bool,
	id: PropTypes.string,
	onChange: PropTypes.func,
	style: PropTypes.object,
	value: PropTypes.bool
};

const FormObjectLabel = ({ multiline, value, style, format }) => (
	<div
		style={{
			height: multiline ? null : "32px",
			display: "flex",
			alignItems: "center",
			minHeight: "32px",
			...style
		}}
	>
		<span>
			{format != null
				? format(value)
				: multiline && value
				? value.split("\n").map((item, idx) => (
						<span key={idx}>
							{item}
							<br />
						</span>
				  ))
				: value != null && value._isAdayjsObject
				? dayjs(value).format("MM/DD/YYYY")
				: value}
		</span>
	</div>
);

FormObjectLabel.propTypes = {
	format: PropTypes.func,
	multiline: PropTypes.bool,
	style: PropTypes.object,
	value: PropTypes.object
};

const FormObjectButton = ({ label, onClick, style, buttonText, ...additionalProps }) => (
	<Button {...additionalProps} color="secondary" fullWidth onClick={onClick} style={style} variant="contained">
		{buttonText != null ? buttonText : label}
	</Button>
);

FormObjectButton.propTypes = {
	buttonText: PropTypes.string,
	label: PropTypes.string,
	onClick: PropTypes.func,
	style: PropTypes.object
};

const FormObjectNone = () => <div />;

export const FORM_OBJECT_DICT = {
	text: FormObjectText,
	date: FormObjectDate,
	height: FormObjectHeight,
	autocomplete: FormObjectAutocomplete,
	autocompletemulti: FormObjectAutocompleteMulti,
	checkbox: FormObjectCheckbox,
	label: FormObjectLabel,
	button: FormObjectButton,
	none: FormObjectNone,
	summarySnapshot: FormObjectSummarySnapshot
};

export const USES_AUTOSAVE = {
	text: true,
	date: true,
	height: true,
	autocomplete: false,
	checkbox: false,
	label: false,
	button: false,
	none: false,
	summarySnapshot: true
};

export const DEFAULT_VALIDATION = {
	date: [
		{
			validate: isValidDate,
			message: "Invalid Date"
		}
	]
};
