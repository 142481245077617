import React from "react";
import dayjs from "dayjs";

import { $TSFixMePlayer } from "utils/tsutils";
import { getPlayerClassificationTag } from "utils/PlayerTypeHelpers";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { formatDraftAge } from "_react/playerpage/_helpers";
import { useDraftDate } from "_react/_hooks";

import { NULL_TEXT, AMA_HIT, AMA_PITCH } from "_react/evals/list/_constants";
import { TEvalAmaHit, TEvalAmaPitch, TEvalStyle } from "_react/evals/list/_types";
import {
	EvalListCard,
	EvalListCardHeader,
	LeftDiv,
	RightDiv,
	NameDiv,
	PositionDiv,
	OrgTeamDiv,
	PlayerClassificationDiv,
	AmaDateSeenDiv,
	ScoutDiv,
	EvalContentDiv,
	EvalHeader,
	EvalHeaderAttr,
	EvalHeaderLabel,
	EvalContainer
} from "_react/evals/list/_styles";
import { getPlayerName, getScoutName, getBatsThrows, getRisk } from "_react/evals/list/_helpers";
import { EvalAmaHitGrades } from "_react/evals/list/shared/EvalAmaHitGrades";
import { EvalAmaPitchGrades } from "_react/evals/list/shared/EvalAmaPitchGrades";
import { EvalSnapshotSummary } from "_react/evals/list/shared/EvalSnapshotSummary";

export type TPlayerEvalAmaProps = {
	evals: Array<TEvalAmaHit | TEvalAmaPitch>;
	player: $TSFixMePlayer;
	isShowSummaries?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TEvalStyle;
};

export const PlayerEvalAma = ({
	evals,
	player,
	isShowSummaries = true,
	colorSchemeGroup = defaultColorScheme,
	style
}: TPlayerEvalAmaProps) => {
	const draftDate = useDraftDate(player?.eligible_year);
	return (
		<EvalListCard>
			{evals.length > 0 && (
				<>
					<EvalListCardHeader colorSchemeGroup={colorSchemeGroup}>
						<LeftDiv>
							<PlayerClassificationDiv>
								{getPlayerClassificationTag("AMA", { fontSize: "12px" })}
							</PlayerClassificationDiv>
							<NameDiv>{getPlayerName(player)}</NameDiv>
							<PositionDiv>({player?.position ?? NULL_TEXT})</PositionDiv>
							<PositionDiv>
								(
								{player?.date_of_birth && draftDate
									? formatDraftAge(player.date_of_birth, draftDate)
									: NULL_TEXT}
								)
							</PositionDiv>
							<OrgTeamDiv>({getBatsThrows(null, player)})</OrgTeamDiv>
							<OrgTeamDiv>({player?.team?.name_display ?? player?.team?.name ?? NULL_TEXT})</OrgTeamDiv>
						</LeftDiv>
					</EvalListCardHeader>
					{evals.map((e: TEvalAmaHit | TEvalAmaPitch) => (
						<EvalContainer key={e.eval_id}>
							<EvalHeader>
								<LeftDiv>
									<EvalHeaderAttr>
										<EvalHeaderLabel>Group:</EvalHeaderLabel> {e.group ?? NULL_TEXT}
									</EvalHeaderAttr>
									<EvalHeaderAttr>
										<EvalHeaderLabel>OFP:</EvalHeaderLabel> {e.ofp ?? NULL_TEXT}
									</EvalHeaderAttr>
									<EvalHeaderAttr>
										<EvalHeaderLabel>Risk:</EvalHeaderLabel> {getRisk(e, false)}
									</EvalHeaderAttr>
									<EvalHeaderAttr>
										<EvalHeaderLabel>Round:</EvalHeaderLabel> {e.round_recommendation ?? NULL_TEXT}
									</EvalHeaderAttr>
								</LeftDiv>
								<RightDiv>
									<AmaDateSeenDiv>
										{e.seen_date ? dayjs(e.seen_date).format("M/D/YY") : NULL_TEXT}
									</AmaDateSeenDiv>
									<ScoutDiv>{getScoutName(e.scout)}</ScoutDiv>
								</RightDiv>
							</EvalHeader>
							{e.eval_type === AMA_HIT && (
								<EvalContentDiv style={style?.evalContentDiv}>
									<EvalAmaHitGrades
										evaluation={e as TEvalAmaHit}
										isShowRound={false}
										style={style?.evalSection}
									/>
									<EvalSnapshotSummary
										snapshot={e.current_snapshot}
										summary={e.summary}
										isShowSummaries={isShowSummaries}
										style={style?.summarySection}
									/>
								</EvalContentDiv>
							)}
							{e.eval_type === AMA_PITCH && (
								<EvalContentDiv style={style?.evalContentDiv}>
									<EvalAmaPitchGrades
										evaluation={e as TEvalAmaPitch}
										isShowRound={false}
										style={style?.evalSection}
									/>
									<EvalSnapshotSummary
										snapshot={e.current_snapshot}
										summary={e.summary}
										isShowSummaries={isShowSummaries}
										style={style?.summarySection}
									/>
								</EvalContentDiv>
							)}
						</EvalContainer>
					))}
				</>
			)}
		</EvalListCard>
	);
};
