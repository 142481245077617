import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function Pause({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
			<path fill={"none"} d="M0 0h24v24H0z" />
		</IconSVG>
	);
}
