import React from "react";
import { assign, Machine } from "xstate";
import dayjs from "dayjs";

import { $TSFixMe } from "utils/tsutils";
import { fetchLkItems } from "_react/inputs/lks/LkContext";
import { isLkColumn, isDateColumn, isComponentColumn } from "_react/table";
import { TValueSort, TColumn, TLkMap, TSort, IMetaBase } from "_react/table/_types";

type TTableDataCell = {
	rawValue: number | string;
	displayValue: React.ReactChild;
	sortValue: TValueSort;
};

export type TTableDataRow<T> = {
	datum: T;
	row: Array<TTableDataCell>;
};

function sortData<T>(data: Array<TTableDataRow<T>>, columns: Array<TColumn<T>>, sort: TSort): Array<TTableDataRow<T>> {
	const colIdx = columns.findIndex(({ key }) => key === sort.key);
	const column = columns[colIdx];
	if (!sort.key || colIdx < 0) {
		return data;
	}
	return data.sort((row1, row2) => {
		const row1Val = row1.row[colIdx].sortValue;
		const row2Val = row2.row[colIdx].sortValue;

		if (row1Val == null || row1Val === "") return 1;
		if (row2Val == null || row2Val === "") return -1;
		if ((row1Val == null || row1Val === "") && (row2Val == null || row2Val === "")) return 0;
		if (isDateColumn(column)) {
			const isBefore = dayjs(row1Val, column.dateFormat).isBefore(dayjs(row2Val, column.dateFormat));
			if (sort.asc) {
				return isBefore ? -1 : 1;
			}
			return isBefore ? 1 : -1;
		}

		if (typeof row1Val === "number" && typeof row2Val === "number")
			return sort.asc ? row1Val - row2Val : row2Val - row1Val;
		else if (typeof row1Val === "string" && typeof row2Val === "string")
			return sort.asc ? row1Val.localeCompare(row2Val) : row2Val.localeCompare(row1Val);
		return 0;
	});
}

function makeTableDataRows<T, M>(
	data: Array<T>,
	columns: Array<TColumn<T, M>>,
	lks: TLkMap,
	metadata: M
): Array<TTableDataRow<T>> {
	return data.map((datum: T) => ({
		datum,
		row: columns.map(column => {
			const rawValue: string | number = column.getValue({ datum, metadata });
			let displayValue: React.ReactChild = "";
			if (isComponentColumn(column)) {
				const DisplayValue = column.getValueDisplay;
				displayValue = <DisplayValue datum={datum} metadata={metadata} />;
			} else {
				displayValue = column.getValueDisplay({ datum, metadata });
			}
			let sortValue: TValueSort = column.getValueSort({ datum, metadata });
			if (isLkColumn(column) && lks.hasOwnProperty(column.lkTable)) {
				const rawValueKey = rawValue as string;
				const lkItem = lks[column.lkTable][rawValueKey];
				displayValue = column.lkGetValueDisplay({ datum, lkItem, metadata });
				sortValue = column.lkGetValueSort({ datum, lkItem, metadata });
			}
			return { rawValue, displayValue, sortValue };
		})
	}));
}

function makeTableData<T, M>(
	data: Array<T>,
	cols: Array<TColumn<T, M>>,
	lks: TLkMap,
	sort: TSort,
	metadata: M
): Array<TTableDataRow<T>> {
	return sortData(makeTableDataRows(data, cols, lks, metadata), cols, sort);
}

const updateSort = (curSort: TSort, sortKey: string): TSort => ({
	key: sortKey,
	asc: sortKey === curSort.key ? !curSort.asc : true
});

function fetchLks<T>(columns: Array<TColumn<T>>): Promise<TLkMap> {
	const lkColumns = columns.filter(isLkColumn);
	const lkTableNames: Array<string> = lkColumns.map(column => column.lkTable);
	const distinctLkTableNames: Array<string> = [...new Set(lkTableNames)];
	const promises = distinctLkTableNames.map((lkName: string) =>
		fetchLkItems(lkName).then(data => ({ [lkName]: data }))
	);
	return Promise.all(promises).then((lkObjs: Array<TLkMap>) => {
		return lkObjs.reduce((obj, lkObj: TLkMap) => {
			return { ...obj, ...lkObj };
		}, {});
	});
}

export interface ITableContext<T, M> {
	columns: Array<TColumn<T>>;
	rawData: Array<T>;
	data: Array<TTableDataRow<T>>;
	metadata: M;
	lks: TLkMap;
	sort: TSort;
}

export type TTableState = {
	states: {
		idle: {};
		fetchingLks: {};
		sorting: {};
	};
};

export type TTableEvent<T, M> =
	| { type: "SET_DATA"; data: Array<T> | null }
	| { type: "SET_METADATA"; data: M }
	| { type: "SORT"; data: string }
	| { type: "LKS_FETCHED"; data: TLkMap }
	| { type: "SET_COLUMNS"; data: Array<TColumn<T>> };

export function createTableMachine<T, M extends IMetaBase = IMetaBase>() {
	return Machine<ITableContext<T, M>, $TSFixMe, TTableEvent<T, M>>({
		id: "table",
		initial: "idle",
		strict: true,
		context: {
			columns: [],
			rawData: [],
			metadata: {} as M,
			data: [],
			lks: {},
			sort: { key: null, asc: true }
		},
		states: {
			idle: {},
			fetchingLks: {
				invoke: {
					id: "lkService",
					src: (context, event) =>
						event.type === "SET_COLUMNS" ? fetchLks(event.data) : Promise.resolve(context.lks),
					onDone: {
						target: "idle",
						actions: assign({
							lks: (_context, event) => event.data
						})
					}
				}
			}
		},
		on: {
			SET_DATA: {
				actions: assign({
					data: (context, event) =>
						makeTableData<T, M>(
							event.data ?? [],
							context.columns,
							context.lks,
							context.sort,
							context.metadata
						),
					rawData: (_context, event) => event.data ?? []
				})
			},
			SORT: {
				actions: assign({
					data: (context, event) =>
						sortData(context.data, context.columns, updateSort(context.sort, event.data)),
					sort: (context, event) => updateSort(context.sort, event.data)
				})
			},
			SET_METADATA: {
				actions: assign({
					metadata: (_context, event) => event.data,
					data: (context, event) =>
						makeTableData<T, M>(context.rawData, context.columns, context.lks, context.sort, event.data)
				})
			},
			SET_COLUMNS: {
				target: "fetchingLks",
				actions: assign({
					columns: (_context, event) => event.data,
					data: (context, event) =>
						makeTableData<T, M>(context.rawData, event.data, context.lks, context.sort, context.metadata)
				})
			}
		}
	});
}
