import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const tables = [
	{
		title: "Physical Strength",
		columns: ["Grade", "Description"],
		rows: [
			[
				"20",
				"Poor stability. Based on your observation the player will be unable hold up for an entire minor league season. Weakness creates injury concerns."
			],
			[
				"50",
				"Average stability. Player should hold up for an entire minor league season and maintain his strength. Nothing visibly evident in body to give cause to increased injury risk."
			],
			[
				"80",
				"Highly stable with visibly apparent strength and flexibility. Absolutely no concerns with durability over a minor league season. Body type and strength appear to mitigate any potential injury concerns."
			]
		]
	},
	{
		title: "Body Control/Agility/Ease to Athletic Actions",
		columns: ["Grade", "Description"],
		rows: [
			[
				"20",
				"Body movements are uncoordinated and inconsistent. Lacks agility and coordinated lateral movement. Lacks any quick or graceful actions. Movements appear to require lots of effort and work."
			],
			[
				"50",
				"Body movements are coordinated with the ability to repeat. Display adequate lateral movement with balance. Laborious actions are not evident. Effort level is not noticeably high. Graceful, quick actions are likewise not visibly apparent."
			],
			[
				"80",
				"Body movements are highly coordinated. Able to make difficult plays and display highly quick and coordinated lateral movement. Noticeably graceful and effortless in all actions and movements. Things appear to be very easy and as more effort is required, the player does not visibly show it."
			]
		]
	}
];

const PhysicalTooltip = () => {
	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<div
					style={{
						overflow: "scroll"
					}}
				>
					{tables.map((table, idx) => {
						return (
							<Table key={idx} style={{ marginTop: "25px" }}>
								<TableHead
									style={{
										backgroundColor: defaultColorScheme.group.backgroundColor
									}}
								>
									<TableRow>
										<TableCell
											colSpan={table.columns.length}
											style={{
												color: defaultColorScheme.group.color,
												fontSize: "16px",
												textAlign: "center"
											}}
										>
											{table.title}
										</TableCell>
									</TableRow>
									<TableRow>
										{table.columns.map((column, idx) => {
											return (
												<TableCell
													key={idx}
													style={{
														color: defaultColorScheme.group.color,
														textAlign: "center",
														textTransform: "capitalize"
													}}
												>
													{column}
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
								<TableBody
									style={{
										border: "1px solid #dfe0df"
									}}
								>
									{table.rows.map((row, idx) => {
										return (
											<TableRow key={idx}>
												{row.map((cell, idx) => {
													return (
														<TableCell
															key={idx}
															style={{
																textAlign: "center",
																fontWeight: idx === 0 ? "700" : ""
															}}
														>
															{cell}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default PhysicalTooltip;
