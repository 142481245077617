/*
    Average Ages
 */

const PITCHER = "PITCHER";
const BATTER = "BATTER";

const getAgeDiff = (age, level, playerType, year, averageAges) => {
	let avgAge;
	try {
		avgAge = averageAges[year][level][playerType];
		return (age - avgAge).toFixed(1);
	} catch (err) {
		return "-";
	}
};

export const getPitcherAgeDiff = (age, level, year, averageAges) => {
	return getAgeDiff(age, level, PITCHER, year, averageAges);
};

export const getBatterAgeDiff = (age, level, year, averageAges) => {
	return getAgeDiff(age, level, BATTER, year, averageAges);
};
