import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { defaultColorScheme, ColorScheme } from "_react/shared/legacy/ui/Colors";

import { $TSFixMe } from "utils/tsutils";

interface Dictionary<T> {
	[Key: string]: T;
}

type PlacementCSS = {
	top?: string;
	bottom?: string;
	left?: string;
	right?: string;
	transform?: string;
};

const PLACEMENT_TO_CSS: Dictionary<PlacementCSS> = {
	top: {
		top: "calc(-100% - 30px)",
		left: "50%",
		transform: "translate(-50%)"
	},
	bottom: {
		bottom: "calc(-100% - 5px)",
		left: "50%",
		transform: "translate(-50%)"
	},
	left: {
		top: "50%",
		right: "calc(100% + 5px)",
		transform: "translate(0, -50%)"
	},
	right: {
		top: "50%",
		left: "calc(100% + 5px)",
		transform: "translate(0, -50%)"
	}
};

type TooltipProps = {
	children: $TSFixMe;
	placement?: string;
	style?: $TSFixMe;
	componentWrapperStyle?: $TSFixMe;
	title: string;
	colorScheme?: ColorScheme;
};

const StyledTooltip = styled.div({
	display: "inline-block",
	fontSize: `11px`,
	fontWeight: 600,
	position: "absolute",
	padding: "4px",
	borderRadius: "3px",
	visibility: "hidden",
	opacity: 0,
	transition: "visibility 0s, opacity 0.1s linear",
	minWidth: "100px",
	textAlign: "center"
});
const StyledTooltipElementWrapper = styled.span({
	position: "relative",
	":hover > .styledTooltip": {
		visibility: "visible",
		opacity: 1
	}
});

export const Tooltip: FunctionComponent<TooltipProps> = ({
	children,
	placement = "top",
	style = {},
	componentWrapperStyle = {},
	title,
	colorScheme = defaultColorScheme.primary
}) => {
	// Colors
	let color: string = style.color;
	if (color == null) color = colorScheme.text;
	let backgroundColor: string = style.backgroundColor;
	if (backgroundColor == null) backgroundColor = colorScheme.color;
	let backgroundBorderColor: string = style.backgroundBorderColor;
	if (backgroundBorderColor == null && style.backgroundColor != null) backgroundBorderColor = backgroundColor;
	else backgroundBorderColor = colorScheme.hover;

	// Placement
	const placementCSS = PLACEMENT_TO_CSS[placement];

	return (
		<StyledTooltipElementWrapper style={{ position: "relative", overflow: "show", ...componentWrapperStyle }}>
			<StyledTooltip
				className="styledTooltip"
				style={{
					border: `1px solid ${backgroundBorderColor}`,
					color,
					backgroundColor,
					zIndex: 1000,
					...placementCSS,
					...style
				}}
			>
				{title}
			</StyledTooltip>
			{children}
		</StyledTooltipElementWrapper>
	);
};
