import React from "react";
import { Box, VStack } from "@chakra-ui/react";

type TExpandableOaaLabelProps = {
	totalOaaLabel?: string;
	componentOaaLabels?: Array<string>;
	isExpanded?: boolean;
};

const ExpandableOaaLabel = ({ totalOaaLabel, componentOaaLabels, isExpanded }: TExpandableOaaLabelProps) => {
	return (
		<VStack align="start" gap="0">
			<Box width="100%">{totalOaaLabel}</Box>
			{isExpanded && (
				<Box width="100%" fontSize="2xs" color="gray.200" sx={{ textWrap: "nowrap" }}>
					{componentOaaLabels?.map((componentOaaLabel: string, index: number) => {
						const spacer = index !== componentOaaLabels.length - 1 ? " | " : "";
						return componentOaaLabel + spacer;
					})}
				</Box>
			)}
		</VStack>
	);
};

export default ExpandableOaaLabel;
