import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import {
	getScoutLatestValues,
	EVAL_GROUP_VALUE_MAP,
	getGroupLabelFromAverageValue
} from "_react/playerpage/shared_js/evals/_helpers";
import EvalTableGroup from "_react/playerpage/shared_js/evals/EvalTableGroup";
import { getAmaAvgGroup } from "_react/playerpage/shared_js/evals/_helpers";

const EvalTable = ({ evaluations, isProEvalAdmin }) => {
	const groupedEvals = evaluations.reduce((dict, a) => {
		let year = a.seen_date?.isValid()
			? a.seen_date.year()
			: a.publish_date?.isValid()
			? a.publish_date.year()
			: null;
		if (Number.isNaN(year) || year == null) year = "UNK";
		if (dict[year] == null) dict[year] = {};
		if (dict[year][a.eval_type] == null) dict[year][a.eval_type] = { evals: [], avgOFP: 0, change: 0 };
		dict[year][a.eval_type].evals.push(a);
		dict[year][a.eval_type].evals.sort((a, b) =>
			(a.seen_date ? a.seen_date : a.publish_date ? a.publish_date : dayjs()).diff(
				b.seen_date ? b.seen_date : b.publish_date ? b.publish_date : dayjs()
			)
		);
		return dict;
	}, {});
	const years = Object.keys(groupedEvals).sort((a, b) => b - a);
	// Calculate avg ofp and change
	// REFACTOR THIS WITH NEW EVALS (THIS IS ALL OVER THE PLACE)
	const lastOFP = {};
	[...years].reverse().map(y => {
		Object.keys(groupedEvals[y]).map(t => {
			const scoutEvals = getScoutLatestValues(
				groupedEvals[y][t].evals,
				y >= 2020 &&
					groupedEvals[y][t].evals.length > 0 &&
					["FRE", "FOL", "LVR", "AVR"].includes(groupedEvals[y][t].evals[0].eval_report_type)
					? "group"
					: "ofp",
				false,
				groupedEvals[y][t].evals.length > 0 ? groupedEvals[y][t].evals[0].eval_report_type === "PRO" : false
			);
			groupedEvals[y][t].avgOFP = "-";
			if (Object.values(scoutEvals).length > 0) {
				const sum = Object.values(scoutEvals).reduce((a, b) => {
					if (
						y >= 2020 &&
						groupedEvals[y][t].evals.length > 0 &&
						["FRE", "FOL", "LVR", "AVR"].includes(groupedEvals[y][t].evals[0].eval_report_type)
					) {
						return a + (EVAL_GROUP_VALUE_MAP[b] ? EVAL_GROUP_VALUE_MAP[b] : 0);
					}
					return a + b;
				}, 0);
				groupedEvals[y][t].avgOFP = parseInt((sum / Object.values(scoutEvals).length).toFixed(0));
			}
			groupedEvals[y][t].avgOFP =
				y >= 2020 &&
				groupedEvals[y][t].evals.length > 0 &&
				["FRE", "FOL", "LVR", "AVR"].includes(groupedEvals[y][t].evals[0].eval_report_type)
					? getGroupLabelFromAverageValue(groupedEvals[y][t].avgOFP)
					: groupedEvals[y][t].avgOFP;
			if (
				y < 2020 ||
				(!groupedEvals[y][t].evals.length > 0 &&
					["FRE", "FOL", "LVR", "AVR"].includes(groupedEvals[y][t].evals[0].eval_report_type))
			) {
				groupedEvals[y][t].change =
					Object.values(scoutEvals).length > 0
						? lastOFP[t]
							? groupedEvals[y][t].avgOFP - lastOFP[t]
							: 0
						: "";
			}
			lastOFP[t] = groupedEvals[y][t].avgOFP;
			return null;
		});
		return null;
	});
	return (
		<div>
			{years.map((y, idx) => {
				const types = Object.keys(groupedEvals[y])
					.sort()
					.reverse();
				return types.map((t, idxx) => {
					const isAma = t.toLowerCase().includes("ama");
					// For now just using the ama fn if the group averaging works and the type is ama

					const avgOFP = isAma
						? getAmaAvgGroup(groupedEvals[y][t].evals, `${y}-01-01 00:00:00`) || groupedEvals[y][t].avgOFP
						: groupedEvals[y][t].avgOFP;
					return (
						<EvalTableGroup
							avgOFP={avgOFP}
							change={groupedEvals[y][t].change}
							evaluations={groupedEvals[y][t].evals}
							isProEvalAdmin={isProEvalAdmin}
							key={`${idx}-${idxx}`}
							last={idx === years.length - 1 && idxx === types.length - 1}
							type={t}
							year={y}
						/>
					);
				});
			})}
		</div>
	);
};

EvalTable.propTypes = {
	evaluations: PropTypes.array,
	isProEvalAdmin: PropTypes.bool
};

export default EvalTable;
