export const isTeamNote = (noteType: string): boolean => {
	const teamNoteTypes = [
		"spring_training_org_team_batter_note",
		"spring_training_org_team_pitcher_note",
		"spring_training_org_team_needs_note",
		"spring_training_org_team_notes_note"
	];

	return teamNoteTypes.includes(noteType);
};

export const isPlayerNote = (noteType: string): boolean => {
	return !isTeamNote(noteType);
};
