import { connect } from "react-redux";

import PlayerStats from "_react/stats/player/PlayerStats";
import {
	fetchSeasonStatsAll,
	fetchCareerStatsAll,
	fetchSeasonStats,
	fetchCareerStats,
	getSeasonTableData,
	getCareerTableData,
	fetchProjectionStats,
	fetchAverageAges
} from "_redux/StatsAggregated/betaStats.a";
import { selectPlayerSeasonStatsFetching, selectPlayerCareerStatsFetching } from "_redux/StatsAggregated/_selectors";
import { PLAYER_TYPES } from "_redux/StatsAggregated/_helpers";

const mapStateToProps = (state, props) => {
	const { player } = props;
	const { bam_id } = player;

	return {
		bam_id,
		player,
		fetching: playerType =>
			selectPlayerSeasonStatsFetching(state, bam_id, playerType) ||
			selectPlayerCareerStatsFetching(state, bam_id, playerType)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchSeasonStatsAll: (bamId, forceRefresh = false, playerTypeToExclude) =>
			dispatch(
				fetchSeasonStatsAll(
					bamId,
					forceRefresh,
					PLAYER_TYPES.filter(e => e !== playerTypeToExclude)
				)
			),
		fetchCareerStatsAll: (bamId, forceRefresh = false, playerTypeToExclude) =>
			dispatch(
				fetchCareerStatsAll(
					bamId,
					forceRefresh,
					PLAYER_TYPES.filter(e => e !== playerTypeToExclude)
				)
			),
		fetchSeasonStats: (bamId, playerType, forceRefresh = false) =>
			dispatch(fetchSeasonStats(bamId, playerType, forceRefresh)),
		fetchCareerStats: (bamId, playerType, forceRefresh = false) =>
			dispatch(fetchCareerStats(bamId, playerType, forceRefresh)),
		getSeasonTableData: (player, playerType, gameType, source, level, summary = null, otherLeagues = false) =>
			dispatch(getSeasonTableData(player, playerType, gameType, source, level, summary, otherLeagues)),
		getCareerTableData: (player, playerType, gameType, source, level) =>
			dispatch(getCareerTableData(player, playerType, gameType, source, level)),
		fetchProjectionStats: (bamId, playerType, position) =>
			dispatch(fetchProjectionStats(bamId, playerType, position)),
		fetchAverageAges: () => dispatch(fetchAverageAges())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStats);
