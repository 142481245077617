import React from "react";
import dayjs from "dayjs";

import { $TSFixMePlayer } from "utils/tsutils";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { formatDraftAge } from "_react/playerpage/_helpers";
import { useDraftDate } from "_react/_hooks";

import { NULL_TEXT } from "_react/evals/list/_constants";
import { TEvalAmaHit, TEvalStyle } from "_react/evals/list/_types";
import {
	EvalListCard,
	EvalListCardHeader,
	LeftDiv,
	RightDiv,
	NameDiv,
	PositionDiv,
	BoxDiv,
	BoxLabel,
	OrgTeamDiv,
	AmaDateSeenDiv,
	ScoutDiv,
	EvalContentDiv,
	ExtraLongStyle
} from "_react/evals/list/_styles";
import { getPlayerName, getScoutName, getBatsThrows, getRisk } from "_react/evals/list/_helpers";
import { EvalAmaHitGrades } from "_react/evals/list/shared/EvalAmaHitGrades";
import { EvalSnapshotSummary } from "_react/evals/list/shared/EvalSnapshotSummary";

export type TEvalAmaHitProps = {
	evaluation: TEvalAmaHit;
	player: $TSFixMePlayer;
	isShowSummaries?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TEvalStyle;
};

export const EvalAmaHit = ({
	evaluation,
	player,
	isShowSummaries = true,
	colorSchemeGroup = defaultColorScheme,
	style
}: TEvalAmaHitProps) => {
	const draftDate = useDraftDate(player?.eligible_year);
	return (
		<EvalListCard>
			<EvalListCardHeader colorSchemeGroup={colorSchemeGroup}>
				<LeftDiv>
					<BoxDiv>
						<BoxLabel>Group</BoxLabel>
						{evaluation.group ?? NULL_TEXT}
					</BoxDiv>
					<BoxDiv>
						<BoxLabel>OFP</BoxLabel>
						{evaluation.ofp ?? NULL_TEXT}
					</BoxDiv>
					<BoxDiv>
						<BoxLabel>Risk</BoxLabel>
						{getRisk(evaluation)}
					</BoxDiv>
					<NameDiv>{getPlayerName(player)}</NameDiv>
					<PositionDiv>({evaluation.position ?? player?.position ?? NULL_TEXT})</PositionDiv>
					<PositionDiv>
						(
						{player?.date_of_birth && draftDate
							? formatDraftAge(player?.date_of_birth, draftDate)
							: NULL_TEXT}
						)
					</PositionDiv>
					<OrgTeamDiv>({getBatsThrows(evaluation, player)})</OrgTeamDiv>
					<OrgTeamDiv style={ExtraLongStyle}>
						({player?.team?.name_display ?? player?.team?.name ?? NULL_TEXT})
					</OrgTeamDiv>
				</LeftDiv>
				<RightDiv>
					<AmaDateSeenDiv>
						{evaluation.seen_date ? dayjs(evaluation.seen_date).format("M/D/YY") : NULL_TEXT}
					</AmaDateSeenDiv>
					<ScoutDiv>{getScoutName(evaluation?.scout)}</ScoutDiv>
				</RightDiv>
			</EvalListCardHeader>
			<EvalContentDiv style={style?.evalContentDiv}>
				<EvalAmaHitGrades evaluation={evaluation} style={style?.evalSection} />
				<EvalSnapshotSummary
					snapshot={evaluation.current_snapshot}
					summary={evaluation.summary}
					isShowSummaries={isShowSummaries}
					style={style?.summarySection}
				/>
			</EvalContentDiv>
		</EvalListCard>
	);
};
