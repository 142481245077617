import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function PriorityHigh({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<circle cx="12" cy="19" r="2" fill={fill} />
			<path d="M10 3h4v12h-4z" fill={fill} />
		</IconSVG>
	);
}
