import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { shadeColor, isDark } from "_react/playerpage/shared_js/evals/_helpers";

export const Badge = ({ color, label, value, subLabel, textColor, toolTip }) => {
	let component = (
		<div
			style={{
				backgroundColor: shadeColor(color, -20),
				height: "26px",
				borderRadius: "16px",
				padding: "2px",
				display: "inline-flex",
				alignItems: "center",
				justifyContent: "center"
			}}
		>
			<div
				style={{
					backgroundColor: color,
					color: textColor ? textColor : isDark(color) ? "white" : "black",
					height: "26px",
					borderRadius: "16px",
					position: "relative",
					display: "inline-flex",
					alignItems: "center",
					justifyContent: "center"
				}}
			>
				<div
					style={{
						height: "26px",
						width: "26px",
						top: "1px",
						left: "1px",
						borderRadius: "13px",
						backgroundColor: isDark(color) ? shadeColor(color, 20) : shadeColor(color, -20),
						color: textColor ? textColor : isDark(color) ? "white" : "black",
						display: "inline-block"
					}}
				>
					<div
						style={{
							textAlign: "center",
							height: "26px",
							width: "26px",
							paddingTop: "1px",
							fontWeight: "900"
						}}
					>
						{value}
					</div>
				</div>
				<div
					style={{
						backgroundColor: color,
						color: textColor ? textColor : isDark(color) ? "white" : "black",
						marginRight: "8px",
						display: "inline-block",
						marginLeft: "5px"
					}}
				>
					{label}
				</div>
			</div>
			{subLabel != null && (
				<div
					style={{
						color: textColor ? textColor : isDark(color) ? "white" : "black",
						marginRight: "7px",
						display: "inline-block",
						marginLeft: "3px",
						fontStyle: "italic",
						fontWeight: "400",
						fontSize: "0.8em"
					}}
				>
					{subLabel}
				</div>
			)}
		</div>
	);
	if (toolTip) {
		component = (
			<Tooltip placement="top" title={toolTip}>
				{component}
			</Tooltip>
		);
	}
	return component;
};

export default Badge;
