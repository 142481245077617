import { aggregateStat } from "_react/shared/_helpers/stats";
import {
	ICombinedPlayerSeasonArsenalScoresSchema,
	TPitcherFoundationalSkillsRow
} from "_react/shared/ui/data/tables/PitcherFoundationalSkillsTable/_types";

export const aggregateCombinedPlayerSeasonArsenalScoresDataRows = (
	prev: ICombinedPlayerSeasonArsenalScoresSchema,
	curr: ICombinedPlayerSeasonArsenalScoresSchema
): ICombinedPlayerSeasonArsenalScoresSchema => {
	if (!prev) return curr;
	const prevTotal = prev.total ?? 0;
	const currTotal = curr.total ?? 0;
	return {
		...prev,
		total: prevTotal + currTotal,
		stuffGrade: aggregateStat(prevTotal, prev.stuffGrade, currTotal, curr.stuffGrade),
		locationGrade: aggregateStat(prevTotal, prev.locationGrade, currTotal, curr.locationGrade),
		intrinsicGrade: aggregateStat(prevTotal, prev.intrinsicGrade, currTotal, curr.intrinsicGrade),
		stuffRa9: aggregateStat(prevTotal, prev.stuffRa9, currTotal, curr.stuffRa9),
		locationRa9: aggregateStat(prevTotal, prev.locationRa9, currTotal, curr.locationRa9),
		intrinsicRa9: aggregateStat(prevTotal, prev.intrinsicRa9, currTotal, curr.intrinsicRa9)
	};
};

// Used to get the level to display for a row
export const getLevelsFromRow = (row: TPitcherFoundationalSkillsRow): Array<string> => {
	// Child Rows or rows with no nested data
	if ("teamBam" in row.playerSeasonArsenalScores)
		return row.playerSeasonArsenalScores.teamBam?.level ? [row.playerSeasonArsenalScores.teamBam?.level] : [];
	// Parent Rows with nested data
	if (row.childData && row.childData.length > 1) {
		return [
			...new Set(
				row.childData.reduce((acc: Array<string>, childRow: TPitcherFoundationalSkillsRow) => {
					if (
						"teamBam" in childRow.playerSeasonArsenalScores &&
						childRow.playerSeasonArsenalScores.teamBam?.level
					)
						acc.push(childRow.playerSeasonArsenalScores.teamBam?.level);
					return acc;
				}, [])
			)
		];
	}
	return [];
};
