import React, { FC, useEffect, useMemo, useCallback } from "react";
import { useMachine } from "@xstate/react";
import { useSnackbar } from "notistack";

import { StyledSelect } from "_react/shared/selects";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import createVideoMachine, {
	DELETE_VIDEO,
	EXIT_VIDEO,
	TOGGLE_SELECT_ALL,
	UPDATE_SNACKBAR,
	UPDATE_PLAYER_ID,
	PLAY_VIDEO,
	CHANGE_SELECT_VIDEO,
	TOGGLE_OPEN_UPLOADER,
	INSERT_NEW_VIDEOS,
	UPDATE_TYPE_OPT
} from "_react/playerpage/video/_machine";
import { TabContainerDiv, ToolbarDiv, ToolbarSectionDiv, ToolbarItemDiv } from "_react/playerpage/video/_styles";
import { TVideo } from "_react/video/shared/_types";
import { TCustomPlayerClassification } from "utils/tsutils";
import VideoUploadModal from "_react/playerpage/video/VideoUploadModal";
import { Button } from "_react/shared/legacy/ui/Button";
import PlayerVideos from "_react/video/shared/PlayerVideos";
import VideoTable, { IMetadata } from "_react/playerpage/video/VideoTable";
import { TYPE_OPTIONS, TTypeOpt } from "_react/playerpage/video/_helpers";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";

type VideoProps = {
	player: TPlayerPageCombinedPlayer;
	colorScheme?: ColorSchemeGroup;
	viewClassification: string | null;
};

const Video: FC<VideoProps> = ({ player, colorScheme = defaultColorScheme, viewClassification }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [current, send] = useMachine(createVideoMachine(enqueueSnackbar, player.id));
	const playerClassification = (
		viewClassification ?? player.playerClassification
	)?.toUpperCase() as TCustomPlayerClassification;

	useEffect(() => {
		send({ type: UPDATE_SNACKBAR, data: { enqueueSnackbar } });
	}, [enqueueSnackbar, send]);

	useEffect(() => {
		if (player.id) {
			send({ type: UPDATE_PLAYER_ID, data: { playerId: player.id } });
		}
	}, [player, send]);

	const isShowUploader = current.matches("ui.uploading");
	const isViewingVideo = current.matches("ui.viewingVideo");
	const isFetching = current.matches("data.fetching");
	const isFetchErrored = current.matches("ui.fetchErrored");
	const isInvalidFetch = current.matches("ui.invalidFetch");

	const { videosFiltered, videosSelected, videoIdsSelected, videoTypeOpt } = current.context;

	const onDelete = useCallback(
		(videoId: number | string) => {
			send({ type: DELETE_VIDEO, data: { videoId } });
		},
		[send]
	);

	const onSelectChange = useCallback(
		(videoId: number | string) => {
			send({ type: CHANGE_SELECT_VIDEO, data: { videoId } });
		},
		[send]
	);

	const tableMetadata: IMetadata = useMemo(() => ({ onDelete, onSelectChange, videoIdsSelected }), [
		onDelete,
		videoIdsSelected,
		onSelectChange
	]);

	function handleExitVideoScreen() {
		send({ type: EXIT_VIDEO });
	}

	function toggleSelectAll() {
		send({ type: TOGGLE_SELECT_ALL });
	}

	function handlePlaySelected() {
		send({ type: PLAY_VIDEO });
	}

	function showHideUploader() {
		send({ type: TOGGLE_OPEN_UPLOADER });
	}
	function handleModalClose(vidDicts?: TVideo[]) {
		if (vidDicts) {
			send({ type: INSERT_NEW_VIDEOS, data: vidDicts });
		}
		showHideUploader();
	}

	return (
		<TabContainerDiv>
			<ToolbarDiv>
				<ToolbarSectionDiv>
					<ToolbarItemDiv>
						<Button
							colorScheme={colorScheme.primary}
							title={"Play Selected"}
							onClick={handlePlaySelected}
							disabled={!videosSelected.length}
						/>
					</ToolbarItemDiv>
					<ToolbarItemDiv>
						<Button colorScheme={colorScheme.primary} title={"Toggle All"} onClick={toggleSelectAll} />
					</ToolbarItemDiv>
					<ToolbarItemDiv minWidth={125}>
						<StyledSelect
							options={TYPE_OPTIONS}
							isClearable
							onChange={(typeOpt: TTypeOpt) => send({ type: UPDATE_TYPE_OPT, data: { typeOpt } })}
							value={videoTypeOpt}
							placeholder={"Video Type..."}
						/>
					</ToolbarItemDiv>
				</ToolbarSectionDiv>
				<ToolbarSectionDiv>
					<Button
						colorScheme={colorScheme.primary}
						title={isShowUploader ? "Hide Uploader" : "Upload Video"}
						onClick={showHideUploader}
					/>
				</ToolbarSectionDiv>
			</ToolbarDiv>
			{isFetchErrored ? (
				<div>Failed loading videos</div>
			) : isInvalidFetch ? (
				<div>Missing ID for player</div>
			) : !isViewingVideo ? (
				<VideoTable
					videos={videosFiltered ?? []}
					isFetching={isFetching}
					tableMetadata={tableMetadata}
					onCheckClick={onSelectChange}
				/>
			) : null}
			{isShowUploader && (
				<VideoUploadModal
					defaultPlayerSelected={player}
					colorScheme={colorScheme}
					handleClose={handleModalClose}
					playerClassifications={[playerClassification]}
					show={isShowUploader}
				/>
			)}
			{isViewingVideo && (
				<PlayerVideos
					handleExitVideoScreen={handleExitVideoScreen}
					player={player}
					playerClassification={playerClassification}
					videos={videosSelected}
				/>
			)}
		</TabContainerDiv>
	);
};

export default Video;
