import { extendTheme } from "@chakra-ui/react";

import styles from "_react/shared/theme/styles";
import semanticTokens from "_react/shared/theme/semanticTokens";
import colors from "_react/shared/theme/foundations/colors";
import typography from "_react/shared/theme/foundations/typography";
import { buttonTheme } from "_react/shared/theme/components/button";
import { progressTheme } from "_react/shared/theme/components/progress";
import { statTheme } from "_react/shared/theme/components/stat";
import { tabsTheme } from "_react/shared/theme/components/tabs";

const overrides = {
	// Global style overrides
	styles,
	// Semantic tokens for consistent styles
	semanticTokens,
	// Foundational style overrides
	colors,
	...typography,
	// Component style overrides
	components: {
		Button: buttonTheme,
		Progress: progressTheme,
		Stat: statTheme,
		Tabs: tabsTheme
	}
};

// Should we use extendBaseTheme and ChakraBaseProvider instead? https://chakra-ui.com/getting-started#chakrabaseprovider
// It allows you to use only the component themes you want, which lessens the initial JS payload
export default extendTheme(overrides);
