import React, { useEffect, useMemo } from "react";
import { Box, HStack, VStack, useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import {
	IStatsPlayerRangeAmaByLevel,
	IStatsPlayerRangeAmaOpps
} from "_react/shared/data_models/defensive_observed/_types";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import { TColumn, TTableProps } from "_react/shared/ui/presentation/components/Table/_types";

import {
	AMA_FIELDING_OAA_COLUMNS,
	AMA_FIELDING_OAA_PARENT_COLUMNS,
	LEVEL_COLUMN_ID,
	POSITION_TO_COLUMN_MAPPING,
	SEASON_COLUMN_ID
} from "_react/shared/ui/data/tables/AmaFieldingOaaTable/_constants";
import AmaFieldingOaaTableMachine, {
	FETCHING_STATS_PLAYER_RANGE_AMA_BY_LEVEL,
	FETCHING_STATS_PLAYER_RANGE_AMA_OPPS,
	SET_PLAYER_ID,
	SET_STATS_PLAYER_RANGE_AMA_BY_LEVEL,
	SET_STATS_PLAYER_RANGE_AMA_OPPS,
	TAmaFieldingOaaTableContext
} from "_react/shared/ui/data/tables/AmaFieldingOaaTable/_machine";
import {
	TAmaFieldingOaaBasic,
	TAmaFieldingOaaRow,
	TAmaFieldingOaaTableData
} from "_react/shared/ui/data/tables/AmaFieldingOaaTable/_types";

type TAmaFieldingOaaTableProps = {
	title?: string;
	playerId?: number;
	data?: TAmaFieldingOaaTableData;
	shouldFetchData?: boolean;
	columns?: Array<string>;
	tableProps?: TTableProps<TAmaFieldingOaaRow, keyof TAmaFieldingOaaRow>;
};

const AmaFieldingOaaTable = ({
	title,
	playerId,
	data,
	shouldFetchData = true,
	columns,
	tableProps
}: TAmaFieldingOaaTableProps) => {
	const toast = useToast();

	const [current, send] = useMachine(AmaFieldingOaaTableMachine(playerId, shouldFetchData, data, toast));
	const context = current.context;
	const { statsPlayerRangeAmaByLevel, statsPlayerRangeAmaOpps } = context as TAmaFieldingOaaTableContext;

	const isFetchingStatsPlayerRangeAmaByLevel = current.matches(FETCHING_STATS_PLAYER_RANGE_AMA_BY_LEVEL);
	const isFetchingStatsPlayerRangeAmaOpps = current.matches(FETCHING_STATS_PLAYER_RANGE_AMA_OPPS);
	const isLoading = shouldFetchData
		? isFetchingStatsPlayerRangeAmaByLevel || isFetchingStatsPlayerRangeAmaOpps
		: data?.isLoading;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.statsPlayerRangeAmaByLevel !== statsPlayerRangeAmaByLevel && !shouldFetchData) {
			send({ type: SET_STATS_PLAYER_RANGE_AMA_BY_LEVEL, data: data?.statsPlayerRangeAmaByLevel });
		}
	}, [data?.statsPlayerRangeAmaByLevel, shouldFetchData, send, statsPlayerRangeAmaByLevel]);

	useEffect(() => {
		if (data?.statsPlayerRangeAmaOpps !== statsPlayerRangeAmaOpps && !shouldFetchData) {
			send({ type: SET_STATS_PLAYER_RANGE_AMA_OPPS, data: data?.statsPlayerRangeAmaOpps });
		}
	}, [data?.statsPlayerRangeAmaOpps, shouldFetchData, send, statsPlayerRangeAmaOpps]);

	// Group fielding OAA Opportunities data by position for fast lookups
	const groupedStatsPlayerRangeAmaOpps: Record<string, IStatsPlayerRangeAmaOpps> = useMemo(() => {
		const newGroupedStatsPlayerRangeAmaOpps: Record<string, IStatsPlayerRangeAmaOpps> = {};
		statsPlayerRangeAmaOpps?.forEach((s: IStatsPlayerRangeAmaOpps) => {
			// NOTE: Stats Player Range Ama Opps uses uppercase positions, while
			// Stats Player Range Ama By Level uses lowercase positions.
			newGroupedStatsPlayerRangeAmaOpps[s.position.toLowerCase()] = s;
		});
		return newGroupedStatsPlayerRangeAmaOpps;
	}, [statsPlayerRangeAmaOpps]);

	// Get rows
	const fieldingOaaRows: Array<TAmaFieldingOaaRow> = useMemo(() => {
		const newAmaFieldingOaaRows: Array<TAmaFieldingOaaRow> = [];
		statsPlayerRangeAmaByLevel?.forEach((s: IStatsPlayerRangeAmaByLevel) => {
			const season = s.season;
			const level = s.level;
			const position = s.position;

			const newAmaFieldingOaaBasic: TAmaFieldingOaaBasic = {
				opportunities: s.opportunities,
				opportunitiesPerFullSeason: groupedStatsPlayerRangeAmaOpps[position]?.opportunitiesPerFullSeason,
				innings: s.innings,
				oaaCentered: s.oaaCentered,
				pctBoxScore: s.pctBoxScore,
				pctBallTracking: s.pctBallTracking,
				pctPlayerTracking: s.pctPlayerTracking,
				meanOaa: groupedStatsPlayerRangeAmaOpps[position]?.meanOaa,
				sdOaa: groupedStatsPlayerRangeAmaOpps[position]?.sdOaa
			};

			const recordToUpdate: TAmaFieldingOaaRow | undefined = newAmaFieldingOaaRows.find(
				(amaFieldingOaaRow: TAmaFieldingOaaRow) =>
					amaFieldingOaaRow.season === season && amaFieldingOaaRow.level === level
			);
			// Handle case where amaFieldingOaaRows does not have any data for a given season and level
			if (recordToUpdate === undefined)
				newAmaFieldingOaaRows.push({
					season: season,
					level: level,
					amaFieldingStatsByPosition: { [position]: newAmaFieldingOaaBasic }
				});
			// Handle case where we already have some fieldingOaa data for a season and level
			else recordToUpdate.amaFieldingStatsByPosition[position] = newAmaFieldingOaaBasic;
		});
		return newAmaFieldingOaaRows;
	}, [statsPlayerRangeAmaByLevel, groupedStatsPlayerRangeAmaOpps]);

	// Get columns
	const filteredColumns = useMemo(() => {
		const newColumns: Array<TColumn<TAmaFieldingOaaRow, keyof TAmaFieldingOaaRow>> = [...AMA_FIELDING_OAA_COLUMNS];

		// Whether to include each set of columns - is there at least one entry where innings is not null?
		for (const position in POSITION_TO_COLUMN_MAPPING) {
			const row = fieldingOaaRows.find(
				(fieldingOaaRow: TAmaFieldingOaaRow) =>
					(fieldingOaaRow.amaFieldingStatsByPosition[position]?.innings ?? null) !== null
			);
			if (row) newColumns.push(...POSITION_TO_COLUMN_MAPPING[position]);
		}

		if (!columns) return newColumns;
		return newColumns.filter((col: TColumn<TAmaFieldingOaaRow, keyof TAmaFieldingOaaRow>) =>
			columns.includes(col.value)
		);
	}, [fieldingOaaRows, columns]);

	return (
		<VStack align="start">
			<HStack w="100%" justify="start">
				{title && (
					<Box fontFamily="heading" fontSize="md" fontWeight="bold">
						{title}
					</Box>
				)}
			</HStack>
			<Table<TAmaFieldingOaaRow, keyof TAmaFieldingOaaRow>
				columns={filteredColumns as Array<TColumn<TAmaFieldingOaaRow, keyof TAmaFieldingOaaRow>>}
				parentColumns={AMA_FIELDING_OAA_PARENT_COLUMNS}
				data={fieldingOaaRows}
				isLoadingData={isLoading}
				emptyDataDisplayText={"No Fielding OAA Data"}
				defaultSortColumns={[
					{
						columnValue: SEASON_COLUMN_ID,
						sortDirection: ASC
					},
					{
						columnValue: LEVEL_COLUMN_ID,
						sortDirection: DESC
					}
				]}
				{...tableProps}
			/>
		</VStack>
	);
};

export default AmaFieldingOaaTable;
