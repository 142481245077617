import { assign, Machine } from "xstate";
import { CancelTokenSource } from "axios";
import dayjs from "dayjs";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { fetchNotesStructure } from "_react/shared/data_models/notes/_network";

import { TRockyNote, TRockyNoteStructure, TRockyNoteStructureDictionary } from "_react/shared/data_models/notes/_types";
import { createNoteStructureDictionary } from "_react/playerpage/notes/_helpers";
import { SORT_DATE_COLUMNS } from "_react/playerpage/notes/_constants";

interface IContext {
	playerId: number | null;
	notesCancelSource?: CancelTokenSource;
	notesStructureCancelSource?: CancelTokenSource;
	notes: TRockyNote[];
	structure: TRockyNoteStructureDictionary;
	filters: string[];
	sortDateColumn: keyof TRockyNote;
	notesDisplay: TRockyNote[];
	deletedNotes: TRockyNote[];
}

interface IStateSchema {
	states: {
		notes: {
			states: {
				fetching: {};
				filtering: {};
				sorting: {};
				postFetch: {};
				showingDeleted: {};
			};
		};
		structure: {
			states: {
				fetching: {};
				postFetch: {};
				fetchErrored: {};
			};
		};
	};
}

export const SET_NOTES_STRUCTURE = "SET_NOTES_STRUCTURE";
export const SET_NOTES = "SET_NOTES";
export const RETRY_FETCH_NOTES_STRUCTURE = "RETRY_FETCH_NOTES_STRUCTURE";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const SET_FILTERS = "SET_FILTERS";
export const ADD_NOTE = "ADD_NOTE";
export const SET_SORT_DATE_COLUMN = "SET_SORT_DATE_COLUMN";
export const TOGGLE_DELETED_NOTES_SHOWING = "TOGGLE_DELETED_NOTES_SHOWING";
const FETCH_NOTES_STRUCTURE_SERVICE_DONE = "done.invoke.fetchNotesStructureService";

type TSetNotesStructureEvent = { type: typeof SET_NOTES_STRUCTURE; data: TRockyNoteStructureDictionary };
type TSetNotesEvent = { type: typeof SET_NOTES; data?: TRockyNote[] };
type TRetryFetchNotesStructureEvent = { type: typeof RETRY_FETCH_NOTES_STRUCTURE };
type TAddNoteEvent = { type: typeof ADD_NOTE; data: TRockyNote };
type TUpdateNoteEvent = { type: typeof UPDATE_NOTE; data: TRockyNote };
type TDeleteNoteEvent = { type: typeof DELETE_NOTE; data: string };
type TSetFiltersEvent = { type: typeof SET_FILTERS; data: string[] };
type TFetchNotesStructureDoneEvent = { type: typeof FETCH_NOTES_STRUCTURE_SERVICE_DONE; data?: TRockyNoteStructure[] };
type TSetSortDateColumnEvent = { type: typeof SET_SORT_DATE_COLUMN; data: keyof TRockyNote };
type TToggleDeletedNotesShowingEvent = { type: typeof TOGGLE_DELETED_NOTES_SHOWING };

type TEvent =
	| TSetNotesStructureEvent
	| TSetNotesEvent
	| TRetryFetchNotesStructureEvent
	| TUpdateNoteEvent
	| TAddNoteEvent
	| TDeleteNoteEvent
	| TSetFiltersEvent
	| TFetchNotesStructureDoneEvent
	| TSetSortDateColumnEvent
	| TToggleDeletedNotesShowingEvent;

const createMachine = (playerId: number | null, filters: string[]) =>
	Machine<IContext, IStateSchema, TEvent>(
		{
			id: "rocky-notes-machine",
			type: "parallel",
			context: {
				playerId,
				notes: [],
				structure: {},
				filters,
				notesDisplay: [],
				deletedNotes: [],
				sortDateColumn: SORT_DATE_COLUMNS[0].key
			},
			states: {
				notes: {
					id: "notes",
					initial: "fetching",
					on: {
						[SET_NOTES]: { actions: "setNotes", target: "notes.sorting" }
					},
					states: {
						fetching: {
							on: {
								[SET_NOTES]: { actions: "setNotes", target: "sorting" }
							}
						},
						sorting: {
							always: { actions: "sortNotes", target: "filtering" }
						},
						filtering: {
							always: { actions: "filterNotes", target: "postFetch" }
						},
						postFetch: {
							on: {
								[UPDATE_NOTE]: { actions: "updateNote", target: "sorting" },
								[ADD_NOTE]: { actions: "addNote", target: "sorting" },
								[SET_FILTERS]: { actions: "setFilters", target: "filtering" },
								[DELETE_NOTE]: { actions: "deleteNote", target: "sorting" },
								[SET_SORT_DATE_COLUMN]: { actions: "setSortDateColumn", target: "sorting" },
								[TOGGLE_DELETED_NOTES_SHOWING]: { target: "showingDeleted" }
							}
						},
						showingDeleted: {
							on: {
								[TOGGLE_DELETED_NOTES_SHOWING]: { target: "sorting" },
								[UPDATE_NOTE]: { actions: "updateNote", target: "sorting" }
							}
						}
					}
				},
				structure: {
					initial: "fetching",
					states: {
						fetching: {
							entry: ["getNewNotesStructureCancelSource"],
							invoke: {
								id: "fetchNotesStructureService",
								src: "fetchNotesStructure",
								onDone: {
									target: "postFetch",
									actions: "handleFetchNotesStructureSuccess"
								},
								onError: {
									target: "fetchErrored"
								}
							}
						},
						postFetch: {
							on: { [SET_NOTES_STRUCTURE]: { actions: "setNotesStructure" } }
						},
						fetchErrored: {
							on: { [RETRY_FETCH_NOTES_STRUCTURE]: { target: "fetching" } }
						}
					}
				}
			}
		},
		{
			actions: {
				getNewNotesCancelSource: assign({
					notesCancelSource: (_context, _event) => getCancelSource()
				}),
				getNewNotesStructureCancelSource: assign({
					notesStructureCancelSource: (_context, _event) => getCancelSource()
				}),
				cancelNotesFetch: (context, _event) => {
					if (context.notesCancelSource) context.notesCancelSource.cancel();
				},
				cancelNotesStructureFetch: (context, _event) => {
					if (context.notesStructureCancelSource) context.notesStructureCancelSource.cancel();
				},
				setSortDateColumn: assign({
					sortDateColumn: (context, event) => {
						if (event.type !== SET_SORT_DATE_COLUMN) return context.sortDateColumn;
						return event.data;
					}
				}),
				updateNote: assign({
					notes: (context, event) => {
						if (event.type !== UPDATE_NOTE) return context.notes;
						const notes = context.notes.filter(note => note.noteId !== event.data.noteId);
						notes.push(event.data);
						return notes;
					}
				}),
				addNote: assign({
					notes: (context, event) => {
						if (event.type !== ADD_NOTE) return context.notes;
						const notes = context.notes;
						notes.push(event.data);
						return notes;
					}
				}),
				deleteNote: assign({
					notes: (context, event) => {
						if (event.type !== DELETE_NOTE) return context.notes;
						const notes = context.notes.map(note => {
							if (note.noteId === event.data) return { ...note, recordStatus: "DL" };
							return note;
						});
						return notes;
					}
				}),
				sortNotes: assign({
					notes: (context, _event) =>
						context.notes.sort((a, b) =>
							a.isBeingCreated === true || b.isBeingCreated === true
								? a.isBeingCreated === true
									? -1
									: 1
								: dayjs(a[context.sortDateColumn]).isBefore(dayjs(b[context.sortDateColumn]))
								? 1
								: -1
						)
				}),
				setFilters: assign({
					filters: (context, event) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.data;
					}
				}),
				filterNotes: assign({
					notesDisplay: (context, _event) => {
						const filters = context.filters;
						return context.notes.filter(
							note =>
								(filters.includes(note.noteType) || (filters.length === 1 && filters[0] === "")) &&
								note.recordStatus !== "DL"
						);
					},
					deletedNotes: (context, _event) => context.notes.filter(note => note.recordStatus === "DL")
				}),
				setNotes: assign({
					notes: (context, event) => {
						if (event.type !== SET_NOTES) return context.notes;
						return event.data ?? [];
					}
				}),
				handleFetchNotesStructureSuccess: assign({
					structure: (context, event) => {
						if (event.type !== FETCH_NOTES_STRUCTURE_SERVICE_DONE) return context.structure;
						return createNoteStructureDictionary(event.data ?? []);
					},
					notesStructureCancelSource: (_context, _event) => undefined
				})
			},
			services: {
				fetchNotesStructure: (context, _event) => {
					const { playerId, notesStructureCancelSource } = context;
					if (playerId != null) {
						const cancelToken = notesStructureCancelSource?.token;
						const fetchFunc = () => fetchNotesStructure(cancelToken);
						return promiseWRetry(fetchFunc);
					}
					return Promise.reject("Null player id");
				}
			}
		}
	);

export default createMachine;
