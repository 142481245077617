import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import { IFieldingProjectionsBlend } from "_react/shared/data_models/defensive_projections/_types";

export type TFieldingProjectionsBlendGetQueryParams = {
	playerId?: number;
	"playerId[in]"?: string;
	bamId?: number;
	"bamId[in]"?: string;
	season?: number;
	"season[in]"?: string;
	raaC?: number;
	"raaC[gte]"?: number;
	"raaC[lte]"?: number;
	raa1b?: number;
	"raa1b[gte]"?: number;
	"raa1b[lte]"?: number;
	raa2b?: number;
	"raa2b[gte]"?: number;
	"raa2b[lte]"?: number;
	raa3b?: number;
	"raa3b[gte]"?: number;
	"raa3b[lte]"?: number;
	raaSs?: number;
	"raaSs[gte]"?: number;
	"raaSs[lte]"?: number;
	raaLf?: number;
	"raaLf[gte]"?: number;
	"raaLf[lte]"?: number;
	raaCf?: number;
	"raaCf[gte]"?: number;
	"raaCf[lte]"?: number;
	raaRf?: number;
	"raaRf[gte]"?: number;
	"raaRf[lte]"?: number;
	raaSp?: number;
	"raaSp[gte]"?: number;
	"raaSp[lte]"?: number;
	raaRp?: number;
	"raaRp[gte]"?: number;
	"raaRp[lte]"?: number;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
};

export const fetchFieldingProjectionsBlend = (
	queryParams: TFieldingProjectionsBlendGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TFieldingProjectionsBlendGetQueryParams, Array<IFieldingProjectionsBlend>>(
		"/fielding_projections_blend",
		queryParams,
		cancelToken
	);
