import React, { FunctionComponent, CSSProperties } from "react";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { DetailsTable } from "_react/shared/legacy/tables/DetailsTable";
import { TRANSACTION_DETAILS_COLUMNS } from "_react/playerpage/transaction/_helpers";
import { transaction } from "_react/shared/_types/mesa/transaction";

type TTransactionDetailsStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

type TransactionDetailsProps = {
	colorScheme?: ColorSchemeGroup;
	style?: TTransactionDetailsStyle;
	transaction?: transaction;
};

export const TransactionDetails: FunctionComponent<TransactionDetailsProps> = ({
	colorScheme = defaultColorScheme,
	style,
	transaction = null
}) => {
	return (
		<Card style={style?.card}>
			<ColoredCardHeader
				colorScheme={colorScheme.secondary}
				text={
					transaction != null
						? `${transaction.transaction_name} ${transaction.date}`
						: "Loading Transaction..."
				}
				style={style?.cardHeader}
			></ColoredCardHeader>
			{transaction != null && (
				<DetailsTable
					columns={TRANSACTION_DETAILS_COLUMNS(transaction)}
					onColClick={() => null}
					row={transaction}
				/>
			)}
			{transaction == null && (
				<div className="loading-container">
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
				</div>
			)}
		</Card>
	);
};
