import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function Check({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={"none"} d="M0 0h24v24H0z" />
			<path fill={fill} d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
		</IconSVG>
	);
}
