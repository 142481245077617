import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const tables = [
	{
		title: "Raw Power",
		columns: ["Scale", "Max Distance (ft)", "Max Exit Velo (mph)", "Top Eighth Exit Velo (mph)"],
		rows: [
			["20", "385", "101", "98"],
			["30", "400", "104", "101"],
			["40", "415", "107", "103"],
			["45", "425", "109", "105"],
			["50", "435", "110", "106"],
			["55", "445", "112", "107"],
			["60", "455", "114", "109"],
			["70", "470", "116", "111"],
			["80", "485", "118", "114"]
		]
	},
	{
		title: "Run Times",
		columns: ["Scale", "RH - 1B (s)", "LH - 1B (s)", "SB (s)"],
		rows: [
			["20", "4.90", "4.80", "3.70"],
			["30", "4.70", "4.60", "3.60"],
			["40", "4.50", "4.40", "3.50"],
			["45", "4.45", "4.35", "3.45"],
			["50", "4.40", "4.30", "3.40"],
			["55", "4.30", "4.20", "3.35"],
			["60", "4.25", "4.15", "3.30"],
			["70", "4.10", "4.00", "3.20"],
			["80", "3.95", "3.85", "3.10"]
		]
	},
	{
		title: "Arm Strength",
		columns: ["Scale", "Description"],
		rows: [
			["20", "Looks like player is throwing with wrong arm"],
			["30", "No carry to throws, dies in flight. Looks like Change-up."],
			[
				"40",
				"Arc to carry. Gets job done, must exchange quick. Runners advance on his arm. Slows noticeably after bounce."
			],
			["50", "On line. Still shows carry after bounce. Ab avg runners can advance."],
			["60", "Above average carry to throw, shows velocity. Solid carry after bounce."],
			[
				"70",
				"Well above avg velocity on throw, no arc in throws even if off- balanced. Slows down running game."
			],
			[
				"80",
				"Outstanding velocity on throws, would recommend as pitcher if he struggles to hit. Stops running game."
			]
		]
	}
];

const PhysicalHittingTooltip = () => {
	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<div
					style={{
						overflow: "scroll"
					}}
				>
					{tables.map((table, idx) => {
						return (
							<Table key={idx} style={{ marginTop: "25px" }}>
								<TableHead
									style={{
										backgroundColor: defaultColorScheme.group.backgroundColor
									}}
								>
									<TableRow>
										<TableCell
											colSpan={table.columns.length}
											style={{
												color: defaultColorScheme.group.color,
												fontSize: "16px",
												textAlign: "center"
											}}
										>
											{table.title}
										</TableCell>
									</TableRow>
									{table.subTitle != null && (
										<TableRow>
											<TableCell
												colSpan={table.columns.length}
												style={{
													color: defaultColorScheme.group.color,
													fontSize: "12px",
													textAlign: "center"
												}}
											>
												{table.subTitle}
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										{table.columns.map((column, idx) => {
											return (
												<TableCell
													key={idx}
													style={{
														color: defaultColorScheme.group.color,
														textAlign: "center",
														textTransform: "capitalize"
													}}
												>
													{column}
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
								<TableBody
									style={{
										border: "1px solid #dfe0df"
									}}
								>
									{table.rows.map((row, idx) => {
										return (
											<TableRow key={idx}>
												{row.map((cell, idx) => {
													return (
														<TableCell
															key={idx}
															style={{
																textAlign: "center",
																fontWeight: idx === 0 ? "700" : ""
															}}
														>
															{cell}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default PhysicalHittingTooltip;
