import React, { useState, useMemo, useEffect, CSSProperties } from "react";

import DrillSelect, { TDrillOption } from "_react/shared/selects/DrillSelect";
import { Button } from "_react/shared/legacy/ui/Button";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { TChangeEvent, TextField } from "_react/shared/legacy/ui/TextField";

import { DISABLED_OPACITY } from "_react/playerplan/shared/_constants";
import {
	SmallCircularProgressRightStyle,
	PaddedDiv,
	FloatRightDiv,
	LeftButtonStyle,
	WideDrillSelectContainer,
	FlexCenteredColumnDiv,
	GoalDrillContentTextFieldStyle,
	AddGoalDrillContentDiv,
	ModalContainer
} from "_react/playerplan/shared/_styles";
import { TGoalDrillForm, TPlayerPlanGoal, TPlayerPlanGoalDrill } from "_react/playerplan/shared/_types";

export type TAddGoalDrillModalStyle = {
	textField?: CSSProperties;
};

export type TAddGoalDrillModalProps = {
	goal: TPlayerPlanGoal;
	focusArea: TPlayerPlanGoal;
	addGoalDrill: (goalDrillForm: TGoalDrillForm) => void;
	isAddingGoalDrill: boolean;
	isGoalActionsDisabled: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TAddGoalDrillModalStyle;
	setIsGoalDrillModalOpen: (isGoalDrillModalOpen: boolean) => void;
	setModalFocusArea: (modalFocusArea: TPlayerPlanGoal | null) => void;
};

export const AddGoalDrillModal = ({
	goal,
	focusArea,
	addGoalDrill,
	isAddingGoalDrill,
	isGoalActionsDisabled,
	colorSchemeGroup = defaultColorScheme,
	style,
	setIsGoalDrillModalOpen,
	setModalFocusArea
}: TAddGoalDrillModalProps) => {
	const DEFAULT_GOAL_DRILL_FORM: TGoalDrillForm = useMemo(() => {
		return {
			playerPlanGoalId: focusArea.id,
			playerPlanDrillId: null,
			content: null
		};
	}, [focusArea]);

	const [isConfirmingSave, setIsConfirmingSave] = useState<boolean>(false);
	const [goalDrillForm, setGoalDrillForm] = useState<TGoalDrillForm>(DEFAULT_GOAL_DRILL_FORM);

	useEffect(() => {
		setGoalDrillForm(DEFAULT_GOAL_DRILL_FORM);
	}, [goal, DEFAULT_GOAL_DRILL_FORM]);

	const filteredDrills: TPlayerPlanGoalDrill[] = focusArea.playerPlanDrills.filter(
		(goalDrill: TPlayerPlanGoalDrill) => !goalDrill.isDeleted
	);

	return (
		<>
			<ModalContainer>
				Add New Supporting Action for {focusArea.playerPlanMetric.metricType.abbreviation} -{" "}
				{focusArea.playerPlanMetric.label}
			</ModalContainer>
			<FlexCenteredColumnDiv>
				<WideDrillSelectContainer>
					<DrillSelect
						isDisabled={isGoalActionsDisabled}
						filterMetricType={focusArea.playerPlanMetric.metricType.value}
						metricSubtypes={
							goal.playerPlanMetric.metricSubtype
								? [goal.playerPlanMetric.metricSubtype.value]
								: undefined
						}
						isClearable
						handleSelect={(opt: TDrillOption) =>
							setGoalDrillForm({
								...goalDrillForm,
								playerPlanDrillId: opt?.value
							})
						}
						value={goalDrillForm.playerPlanDrillId ? [goalDrillForm.playerPlanDrillId] : null}
						excludeDrillIds={filteredDrills?.map(
							(goalDrill: TPlayerPlanGoalDrill) => goalDrill.playerPlanDrillId ?? -1
						)}
						placeholder="Select Supporting Action"
						shouldUseAvailabilityMap={true}
						goalMetricId={goal.playerPlanMetric.id}
						focusAreaMetricId={focusArea.playerPlanMetric.id}
					/>
				</WideDrillSelectContainer>
				<AddGoalDrillContentDiv>
					<TextField
						onChange={(e: TChangeEvent) => setGoalDrillForm({ ...goalDrillForm, content: e.target.value })}
						value={goalDrillForm.content}
						multiline={true}
						fullWidth
						placeholder={"Enter a description..."}
						style={{ ...GoalDrillContentTextFieldStyle, ...style?.textField }}
					/>
				</AddGoalDrillContentDiv>
			</FlexCenteredColumnDiv>
			<PaddedDiv>
				{isAddingGoalDrill ? (
					<CircularProgress center={false} style={SmallCircularProgressRightStyle} />
				) : isConfirmingSave ? (
					<FloatRightDiv>
						<Button
							style={LeftButtonStyle}
							disabled={
								isGoalActionsDisabled ||
								// Must include a drill id
								goalDrillForm.playerPlanDrillId == null
							}
							onClick={() => {
								setIsConfirmingSave(false);
								addGoalDrill(goalDrillForm);
								setGoalDrillForm(DEFAULT_GOAL_DRILL_FORM);
								setIsGoalDrillModalOpen(false);
								setModalFocusArea(null);
							}}
							title="Confirm"
							colorScheme={colorSchemeGroup.secondary}
							disabledOpacity={DISABLED_OPACITY}
						/>
						<Button
							disabled={false}
							onClick={() => setIsConfirmingSave(false)}
							title="Cancel"
							colorScheme={colorSchemeGroup.primary}
						/>
					</FloatRightDiv>
				) : (
					<FloatRightDiv>
						<Button
							disabled={false}
							onClick={() => setIsConfirmingSave(true)}
							title="Save"
							colorScheme={colorSchemeGroup.secondary}
						/>
					</FloatRightDiv>
				)}
			</PaddedDiv>
		</>
	);
};
