import React, { ReactNode, createContext, useState } from "react";
import axios from "_redux/_utils/_axios";
import { transaction } from "_react/shared/_types/mesa/transaction";

const TransactionContext = createContext({
	getTransaction: (transactionId: number) => Promise.resolve({ ebis_id: 0, transaction_id: transactionId })
});

type TTransactionProviderProps = {
	children: ReactNode;
};

const TransactionProvider = (props: TTransactionProviderProps) => {
	const [transactions, setTransactions] = useState<{ [index: string]: transaction }>({});

	const getTransaction = (transactionId: number): Promise<transaction> => {
		if (transactions.hasOwnProperty(transactionId)) {
			return Promise.resolve(transactions[transactionId]);
		} else {
			return axios
				.get(`/transactions/transaction_id/${transactionId}`)
				.then((response: { data: Array<transaction> }) => {
					const newTransactions = {
						...transactions,
						[response.data[0].transaction_id]: response.data[0]
					};
					setTransactions(newTransactions);
					return response.data[0];
				});
		}
	};

	return (
		<TransactionContext.Provider
			value={{
				getTransaction
			}}
			{...props}
		/>
	);
};

export { TransactionContext, TransactionProvider };
