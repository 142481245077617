export const AMA = "AMA";
export const PRO = "PRO";
export const INTL = "INTL";
export const CLASSIFICATION_OPTIONS = [
	{ label: "Professional", value: PRO },
	{ label: "Amateur", value: AMA },
	{ label: "International", value: INTL }
];
export const ALL_CLASSIFICATIONS = CLASSIFICATION_OPTIONS.map(option => option.value);

export const CLASSIFICATION_OPTIONS_COOKIE = "searchClassifications";

export const LOGIN_REDIRECT_DISABLED_COOKIE = "loginRedirectDisabled";
