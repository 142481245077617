import { AxiosError } from "axios";
import * as Sentry from "@sentry/browser";

import { BASE_URL, cookies } from "utils/redux_constants";
import { $TSFixMe } from "utils/tsutils";

import { TLink, ILinkSidebar, ILinkModal } from "_react/app/_types";
import {
	ALL_ACCESS_ROUTES,
	OKTA_CLIENT,
	OKTA_AXIOS_INSTANCE,
	OKTA_BOOKMARK_APP_REDIRECT_URI
} from "_react/app/_constants";

//
// All of this is legacy js code
//
export const filterLinks = (links: Array<TLink>, routes: Array<string> | undefined): Array<TLink> => {
	const filteredLinks = [];
	for (let i = 0; i < links.length; i++) {
		const link = { ...links[i] };
		if ("children" in link && link.children != null) {
			const childLinks = filterLinks([...link.children], routes);
			if (childLinks.length > 0) {
				link.children = childLinks as Array<ILinkSidebar | ILinkModal>;
				filteredLinks.push(link);
			}
		} else {
			if (routes && link.route && (routes.includes(link.route) || ALL_ACCESS_ROUTES.includes(link.route)))
				filteredLinks.push(link);
		}
	}
	return filteredLinks;
};

export function getNonceFromToken(idToken: $TSFixMe) {
	const decodedToken = OKTA_CLIENT.token.decode(idToken);
	return decodedToken.payload.nonce || decodedToken.payload.at_hash;
}

export const getExpFromToken = (idToken: $TSFixMe) => {
	const decodedToken = OKTA_CLIENT.token.decode(idToken);
	return decodedToken.payload.exp;
};

export function setUpOktaCookies(oktaResponse: $TSFixMe) {
	const { refresh_token, id_token } = oktaResponse.data;
	// TODO: Can probably just decode once but unsure if anything else depends on the getNonceFromToken
	// const decodedToken = OKTA_CLIENT.token.decode(id_token);
	// console.log(decodedToken)
	cookies.set("nonce", getNonceFromToken(id_token), { path: "/" });
	cookies.set("exp", getExpFromToken(id_token), { path: "/" });
	cookies.set("token", id_token, { path: "/" });
	cookies.set("idToken", id_token, { path: "/" });
	cookies.set("refreshToken", refresh_token, { path: "/" });
	// console.log(`EXP ${cookies.get("exp")}, Refresh ${cookies.get('refreshToken')}`)
}

export const clearCookies = () => {
	cookies.remove("token", { path: "/" });
	cookies.remove("username", { path: "/" });
	cookies.remove("userId", { path: "/" });
	cookies.remove("idToken", { path: "/" });
	cookies.remove("refreshToken", { path: "/" });
	cookies.remove("nonce", { path: "/" });
	cookies.remove("exp", { path: "/" });
};

export const refreshOktaTokens = () => {
	return OKTA_AXIOS_INSTANCE.post(`${BASE_URL}/okta/refresh_token`, {
		refresh_token: cookies.get("refreshToken"),
		redirect_uri: OKTA_BOOKMARK_APP_REDIRECT_URI
	})
		.then(setUpOktaCookies)
		.catch((err: Error | AxiosError) => {
			const { message } = err;
			Sentry.captureMessage(`Error Refreshing Okta Tokens: ${message}`, Sentry.Severity.Error);
		});
};
