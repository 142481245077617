import React from "react";
import dayjs from "dayjs";

import { $TSFixMePlayer } from "utils/tsutils";
import { getPlayerClassificationTag } from "utils/PlayerTypeHelpers";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

import { NULL_TEXT, INTL_HIT, INTL_PITCH } from "_react/evals/list/_constants";
import { TEvalIntlHit, TEvalIntlPitch, TEvalStyle } from "_react/evals/list/_types";
import {
	EvalListCard,
	EvalListCardHeader,
	LeftDiv,
	RightDiv,
	NameDiv,
	PositionDiv,
	OrgTeamDiv,
	PlayerClassificationDiv,
	LabelSpan,
	IpDiv,
	GamesDiv,
	DateSeenDiv,
	ScoutDiv,
	EvalContentDiv,
	EvalHeader,
	EvalHeaderAttr,
	EvalHeaderLabel,
	EvalContainer
} from "_react/evals/list/_styles";
import { getPlayerName, getScoutName, getAgentName } from "_react/evals/list/_helpers";
import { EvalIntlHitGrades } from "_react/evals/list/shared/EvalIntlHitGrades";
import { EvalIntlPitchGrades } from "_react/evals/list/shared/EvalIntlPitchGrades";
import { EvalSnapshotSummary } from "_react/evals/list/shared/EvalSnapshotSummary";

export type TPlayerEvalIntlProps = {
	evals: Array<TEvalIntlHit | TEvalIntlPitch>;
	player: $TSFixMePlayer;
	isShowSummaries?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TEvalStyle;
};

export const PlayerEvalIntl = ({
	evals,
	player,
	isShowSummaries = true,
	colorSchemeGroup = defaultColorScheme,
	style
}: TPlayerEvalIntlProps) => {
	return (
		<EvalListCard>
			{evals.length > 0 && (
				<>
					<EvalListCardHeader colorSchemeGroup={colorSchemeGroup}>
						<LeftDiv>
							<PlayerClassificationDiv>
								{getPlayerClassificationTag("INTL", { fontSize: "12px" })}
							</PlayerClassificationDiv>
							<NameDiv>{getPlayerName(player)}</NameDiv>
							<PositionDiv>({player?.position ?? NULL_TEXT})</PositionDiv>
							<OrgTeamDiv>({player?.eligible_year ?? NULL_TEXT})</OrgTeamDiv>
							<OrgTeamDiv>({getAgentName(player)})</OrgTeamDiv>
						</LeftDiv>
					</EvalListCardHeader>
					{evals.map((e: TEvalIntlHit | TEvalIntlPitch) => (
						<EvalContainer key={e.eval_id}>
							<EvalHeader>
								<LeftDiv>
									<EvalHeaderAttr>
										<EvalHeaderLabel>OFP:</EvalHeaderLabel> {e.ofp ?? NULL_TEXT}
									</EvalHeaderAttr>
								</LeftDiv>
								<RightDiv>
									{e.eval_type === INTL_PITCH && (
										<IpDiv>
											{e.innings_seen_thirds?.toFixed(1) ?? NULL_TEXT}
											<LabelSpan>IP</LabelSpan>
										</IpDiv>
									)}
									<GamesDiv>
										{e.games_seen ?? NULL_TEXT}
										<LabelSpan>G</LabelSpan>
									</GamesDiv>
									<DateSeenDiv>
										{e.seen_date ? dayjs(e.seen_date).format("M/D/YY") : NULL_TEXT}
									</DateSeenDiv>
									<ScoutDiv>{getScoutName(e?.scout)}</ScoutDiv>
								</RightDiv>
							</EvalHeader>
							{e.eval_type === INTL_HIT && (
								<EvalContentDiv style={style?.evalContentDiv}>
									<EvalIntlHitGrades
										evaluation={e as TEvalIntlHit}
										player={player}
										style={style?.evalSection}
									/>
									<EvalSnapshotSummary
										snapshot={e.current_snapshot}
										summary={e.summary}
										isShowSummaries={isShowSummaries}
										style={style?.summarySection}
									/>
								</EvalContentDiv>
							)}
							{e.eval_type === INTL_PITCH && (
								<EvalContentDiv style={style?.evalContentDiv}>
									<EvalIntlPitchGrades
										evaluation={e as TEvalIntlPitch}
										player={player}
										style={style?.evalSection}
									/>
									<EvalSnapshotSummary
										snapshot={e.current_snapshot}
										summary={e.summary}
										isShowSummaries={isShowSummaries}
										style={style?.summarySection}
									/>
								</EvalContentDiv>
							)}
						</EvalContainer>
					))}
				</>
			)}
		</EvalListCard>
	);
};
