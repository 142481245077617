import React from "react";
import PropTypes from "prop-types";

import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import CheckCircle from "_react/shared/legacy/ui/icons/CheckCircle";
import Error from "_react/shared/legacy/ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";

const FormObjectIndicator = ({ saved, saving, required, valid, validationMessage, value }) => {
	return (
		<div
			style={{
				marginLeft: "10px",
				display: "flex",
				alignItems: "center",
				width: "15px"
			}}
		>
			{saving && <CircularProgress style={{ color: "black", height: 15 }} />}
			{saved && <CheckCircle style={{ color: "green" }} />}
			{!valid && !saving && (
				<Tooltip
					placement="left"
					title={value === null && required ? "This field is required" : validationMessage}
				>
					<Error style={{ color: "red" }} />
				</Tooltip>
			)}
		</div>
	);
};

FormObjectIndicator.propTypes = {
	required: PropTypes.bool,
	saved: PropTypes.bool,
	saving: PropTypes.bool,
	valid: PropTypes.bool,
	validationMessage: PropTypes.string,
	value: PropTypes.object
};

export default FormObjectIndicator;
