import React, { FC } from "react";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";

import { AMA, PRO, INTL } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { AmaHeaderFields } from "_react/playerpage/header/fields/AmaHeaderFields";
import { ProHeaderFields } from "_react/playerpage/header/fields/ProHeaderFields";
import { IntlHeaderFields } from "_react/playerpage/header/fields/IntlHeaderFields";
import { InfoCardsResponsiveGrid, PlayerInfoCardWrapper } from "_react/playerpage/header/fields/_styles";

type HeaderFieldsProps = {
	colorSchemeGroup?: ColorSchemeGroup;
	playerCombined?: TPlayerPageCombinedPlayer | null;
	playerType?: string | null;
	showingAdditionalInfo?: boolean;
};

const HeaderFields: FC<HeaderFieldsProps> = ({ colorSchemeGroup, playerCombined, playerType }) => {
	if (playerType === PRO && playerCombined) {
		return <ProHeaderFields colorSchemeGroup={colorSchemeGroup} player={playerCombined} />;
	}

	if (playerType === INTL && playerCombined) {
		return <IntlHeaderFields colorSchemeGroup={colorSchemeGroup} player={playerCombined} />;
	}

	if (playerType === AMA && playerCombined) {
		return <AmaHeaderFields colorSchemeGroup={colorSchemeGroup} player={playerCombined} />;
	}

	return (
		<InfoCardsResponsiveGrid playerType={playerType ?? ""}>
			<PlayerInfoCardWrapper idx={0} showingAdditionalInfo={false}>
				<div className="loading-item" style={{ padding: "12px", height: "130px" }} />
			</PlayerInfoCardWrapper>
			<PlayerInfoCardWrapper idx={1} showingAdditionalInfo={false}>
				<div className="loading-item" style={{ padding: "12px", height: "130px" }} />
			</PlayerInfoCardWrapper>
			<PlayerInfoCardWrapper idx={2} showingAdditionalInfo={false}>
				<div className="loading-item" style={{ padding: "12px", height: "130px" }} />
			</PlayerInfoCardWrapper>
		</InfoCardsResponsiveGrid>
	);
};

export default HeaderFields;
