import React, { CSSProperties, useState } from "react";
import dayjs from "dayjs";
import { MultiValue, SingleValue } from "react-select";

import { ColorScheme, ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { Button } from "_react/shared/legacy/ui/Button";
import { TOption } from "_react/inputs/_types";
import { Checkbox } from "_react/shared/legacy/ui/Checkbox";
import { StyledSelect } from "_react/shared/selects";
import SplitSelect, { TSplitOption } from "_react/shared/selects/SplitSelect";
import MetricSelect, { TMetricOption } from "_react/shared/selects/MetricSelect";
import MetricSubtypeSelect, { TMetricSubtypeOption } from "_react/shared/selects/MetricSubtypeSelect";
import LkSelect from "_react/inputs/lks/LkSelect";
import { GoalFormSection } from "_react/playerplan/shared/GoalFormSection";

import {
	FlexCenteredDiv,
	StepCircleContainer,
	StepCircleDiv,
	StepDivider,
	CheckboxDiv,
	ModalContents,
	SplitContents,
	TitleSpan,
	MetricTypeSelectContainer,
	MetricSubtypeSelectContainer,
	SplitSelectContainer,
	BackButtonStyle,
	CheckboxLeftStyle,
	WideMetricSelectContainer,
	SecondaryGoalCheckboxDiv,
	FlexCenteredPaddedDiv,
	SeasonContents,
	SeasonSelectContainer
} from "_react/playerplan/shared/_styles";
import {
	PARENT,
	PARENT_PRIORITY,
	CHILD,
	STRENGTH,
	PHP,
	BASEBALL_METRIC_LKS,
	NON_BASEBALL_METRIC_LKS,
	DEFAULT_SECONDARY_GOAL_FORM,
	DEFAULT_ADD_GOAL_FORM,
	SEASON_SELECT_OPTIONS,
	DISABLED_OPACITY
} from "_react/playerplan/shared/_constants";
import { createGoalStatement, isPrimaryGoalValid, isSecondaryGoalValid } from "_react/playerplan/shared/_helpers";
import { TGoalForm } from "_react/playerplan/shared/_types";
import { PHP_TIER } from "_react/playerplan/metrics/_constants";

export type TAddGoalModalStyle = {
	checkbox?: CSSProperties;
	stepCircle?: CSSProperties;
	submitButtonColorScheme?: ColorScheme;
};

export type TAddGoalModalProps = {
	addGoalForm: TGoalForm;
	addGoal: (usesTarget?: boolean) => void;
	setAddGoalForm: (value: TGoalForm) => void;
	canCreateAllGoalTypes: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TAddGoalModalStyle;
};

export const AddGoalModal = ({
	addGoalForm,
	addGoal,
	setAddGoalForm,
	canCreateAllGoalTypes,
	colorSchemeGroup = defaultColorScheme,
	style
}: TAddGoalModalProps) => {
	const [activeStep, setActiveStep] = useState<number>(1);
	const [goalType, setGoalType] = useState<string | undefined>();
	const [parentGoalId, setParentGoalId] = useState<number | undefined>();
	const [parentGoalStatement, setParentGoalStatement] = useState<string | undefined>();
	const [hasSecondaryGoal, setHasSecondaryGoal] = useState<boolean>(false);

	return (
		<>
			<ModalContents>
				<StepCircleContainer>
					<StepCircleDiv isActive={activeStep === 1} isComplete={activeStep > 1} style={style?.stepCircle}>
						Type
					</StepCircleDiv>
					<StepDivider isComplete={activeStep > 1} />
					<StepCircleDiv isActive={activeStep === 2} isComplete={activeStep > 2} style={style?.stepCircle}>
						Dept
					</StepCircleDiv>
					<StepDivider isComplete={activeStep > 2} />
					<StepCircleDiv isActive={activeStep === 3} isComplete={activeStep > 3} style={style?.stepCircle}>
						What
					</StepCircleDiv>
					<StepDivider isComplete={activeStep > 3} />
					<StepCircleDiv isActive={activeStep === 4} isComplete={activeStep > 4} style={style?.stepCircle}>
						Add
					</StepCircleDiv>
				</StepCircleContainer>
				{activeStep === 1 && (
					<>
						<FlexCenteredDiv>
							{canCreateAllGoalTypes && (
								<>
									<CheckboxDiv>
										Priority Development Goal
										<Checkbox
											style={{ ...CheckboxLeftStyle, ...style?.checkbox }}
											checked={goalType === PARENT_PRIORITY}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												setGoalType(e ? PARENT_PRIORITY : undefined);
												setParentGoalId(undefined);
												setParentGoalStatement(undefined);
												setAddGoalForm(DEFAULT_ADD_GOAL_FORM);
											}}
											colorScheme={colorSchemeGroup.tertiary}
										/>
									</CheckboxDiv>
									<CheckboxDiv>
										Development Goal
										<Checkbox
											style={{ ...CheckboxLeftStyle, ...style?.checkbox }}
											checked={goalType === PARENT}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												setGoalType(e ? PARENT : undefined);
												setParentGoalId(undefined);
												setParentGoalStatement(undefined);
												setAddGoalForm(DEFAULT_ADD_GOAL_FORM);
											}}
											colorScheme={colorSchemeGroup.tertiary}
										/>
									</CheckboxDiv>
									<CheckboxDiv>
										Strength
										<Checkbox
											style={{ ...CheckboxLeftStyle, ...style?.checkbox }}
											checked={goalType === STRENGTH}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												setGoalType(e ? STRENGTH : undefined);
												setParentGoalId(undefined);
												setParentGoalStatement(undefined);
												setAddGoalForm(DEFAULT_ADD_GOAL_FORM);
											}}
											colorScheme={colorSchemeGroup.tertiary}
										/>
									</CheckboxDiv>
								</>
							)}
							<CheckboxDiv>
								PHP
								<Checkbox
									style={{ ...CheckboxLeftStyle, ...style?.checkbox }}
									checked={goalType === PHP}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										setGoalType(e ? PHP : undefined);
										setParentGoalId(undefined);
										setParentGoalStatement(undefined);
										setAddGoalForm(DEFAULT_ADD_GOAL_FORM);
									}}
									colorScheme={colorSchemeGroup.tertiary}
								/>
							</CheckboxDiv>
						</FlexCenteredDiv>
					</>
				)}
				{activeStep === 2 && (
					<FlexCenteredDiv>
						<MetricTypeSelectContainer>
							<LkSelect<string>
								lkName={"lk_player_plan_metric_type"}
								lkFilters={
									goalType !== PHP
										? [{ key: "value", value: BASEBALL_METRIC_LKS }]
										: [{ key: "value", value: NON_BASEBALL_METRIC_LKS }]
								}
								onChange={(opt: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null) => {
									setAddGoalForm({
										...addGoalForm,
										metricType: (opt as TOption<string>)?.value,
										metricSubtype: null,
										metricSubtypeLabel: null,
										metric: null,
										isQualitative: false,
										metricLabel: null,
										metricFormat: null,
										secondaryGoal: !hasSecondaryGoal
											? null
											: {
													direction: addGoalForm.secondaryGoal?.direction || null,
													directionLabel: addGoalForm.secondaryGoal?.directionLabel || null,
													target: addGoalForm.secondaryGoal?.target || null,
													description: addGoalForm.secondaryGoal?.description || null,
													metric: null,
													isQualitative: false,
													metricLabel: null,
													metricFormat: null
											  }
									});
								}}
								valueOnlyValue={addGoalForm.metricType ?? undefined}
								placeholder={"Select Department..."}
								menuPortalTarget={document.body}
							/>
						</MetricTypeSelectContainer>
						{goalType !== PHP && (
							<MetricSubtypeSelectContainer>
								<MetricSubtypeSelect
									isClearable={true}
									metricTypes={addGoalForm.metricType ? [addGoalForm.metricType] : []}
									handleSelect={(opt: TMetricSubtypeOption | null) => {
										setAddGoalForm({
											...addGoalForm,
											metricSubtype: opt?.value || null,
											metricSubtypeLabel: opt?.rawLabel || null,
											metric: null,
											isQualitative: false,
											metricLabel: null,
											metricFormat: null,
											secondaryGoal: !hasSecondaryGoal
												? null
												: {
														direction: addGoalForm.secondaryGoal?.direction || null,
														directionLabel:
															addGoalForm.secondaryGoal?.directionLabel || null,
														target: addGoalForm.secondaryGoal?.target || null,
														description: addGoalForm.secondaryGoal?.description || null,
														metric: null,
														isQualitative: false,
														metricLabel: null,
														metricFormat: null
												  }
										});
									}}
									value={{
										value:
											addGoalForm.metricType && addGoalForm.metricSubtype
												? addGoalForm.metricSubtype
												: "",
										lkMetricType: addGoalForm.metricType ?? ""
									}}
									placeholder={"Select Dev Goal Category..."}
								/>
							</MetricSubtypeSelectContainer>
						)}
					</FlexCenteredDiv>
				)}
				{activeStep === 3 && goalType !== PHP && (
					<div>
						<GoalFormSection
							addGoalForm={addGoalForm}
							secondary={false}
							setAddGoalForm={setAddGoalForm}
							goalType={goalType}
							colorSchemeGroup={colorSchemeGroup}
						/>
						<SecondaryGoalCheckboxDiv>
							Tier 2 Goal?
							<Checkbox
								style={{ ...CheckboxLeftStyle, ...style?.checkbox }}
								checked={hasSecondaryGoal}
								onChange={() => {
									setAddGoalForm({
										...addGoalForm,
										secondaryGoal: hasSecondaryGoal ? null : DEFAULT_SECONDARY_GOAL_FORM
									});
									setHasSecondaryGoal(!hasSecondaryGoal);
								}}
								colorScheme={colorSchemeGroup.tertiary}
							/>
						</SecondaryGoalCheckboxDiv>
						<GoalFormSection
							addGoalForm={addGoalForm}
							secondary={true}
							setAddGoalForm={setAddGoalForm}
							goalType={goalType}
							colorSchemeGroup={colorSchemeGroup}
							hasSecondaryGoal={hasSecondaryGoal}
						/>
						<SplitContents>
							<TitleSpan>Splits</TitleSpan>
							<SplitSelectContainer>
								<SplitSelect
									isMulti
									isMetricSplits
									isDrillSplits={false}
									metricTypeLk={addGoalForm.metricType ?? undefined}
									excludeSplitTypes={addGoalForm.splitOpts?.flatMap((split: TSplitOption) => {
										// Only allow user to select one item from either group
										if (["pitch_type", "pitch_type_group"].includes(split.splitType.value))
											return ["pitch_type", "pitch_type_group"];
										// Otherwise, only allow user to select one item per group
										return split.splitType.value;
									})}
									handleSelect={(opts: Array<TSplitOption>) =>
										setAddGoalForm({
											...addGoalForm,
											splits: opts?.length
												? (opts.map((opt: TSplitOption) => opt.value) as Array<string>)
												: null,
											splitOpts: opts
										})
									}
									value={addGoalForm.splits}
									placeholder={"(Optional) Select splits..."}
								/>
							</SplitSelectContainer>
						</SplitContents>
						<SeasonContents>
							<TitleSpan>Season</TitleSpan>
							<SeasonSelectContainer>
								<StyledSelect
									options={SEASON_SELECT_OPTIONS}
									isClearable={false}
									value={
										addGoalForm.season
											? { label: addGoalForm.season, value: addGoalForm.season }
											: undefined
									}
									onChange={(opt: SingleValue<TOption<number>>) => {
										setAddGoalForm({
											...addGoalForm,
											season: opt?.value ?? dayjs().year()
										});
									}}
									placeholder="Select season..."
								/>
							</SeasonSelectContainer>
						</SeasonContents>
					</div>
				)}
				{activeStep === 3 && goalType === PHP && (
					<FlexCenteredDiv>
						<WideMetricSelectContainer>
							<MetricSelect
								isClearable={false}
								metricTiers={[PHP_TIER]}
								metricTypes={addGoalForm.metricType ? [addGoalForm.metricType] : undefined}
								handleSelect={(opt: TMetricOption) => {
									setAddGoalForm({
										...addGoalForm,
										metric: opt?.value,
										isQualitative: opt?.isQualitative,
										metricLabel: opt?.label,
										metricFormat: opt?.format
									});
								}}
								value={addGoalForm.metric ? [addGoalForm.metric] : null}
							/>
						</WideMetricSelectContainer>
					</FlexCenteredDiv>
				)}
				{activeStep === 4 && (
					<div>
						<FlexCenteredPaddedDiv>
							<i>{`Add New ${
								addGoalForm.isStrength
									? "Strength"
									: addGoalForm.isParentGoal
									? `${addGoalForm.isPriority ? "Priority " : ""}Development Goal`
									: goalType === PHP
									? "PHP Goal"
									: `Supporting Goal for "${addGoalForm.parentGoalStatement}"`
							}?`}</i>
						</FlexCenteredPaddedDiv>
						{goalType === PHP && (
							<FlexCenteredPaddedDiv>{addGoalForm.metricLabel ?? ""}</FlexCenteredPaddedDiv>
						)}
						{goalType !== PHP && (
							<>
								<FlexCenteredPaddedDiv>
									<div>
										<b>Tier 1:</b>
										{`${" "}${createGoalStatement(
											addGoalForm.metricLabel ?? undefined,
											addGoalForm.metricSubtypeLabel ?? undefined,
											undefined,
											addGoalForm.directionLabel ?? undefined,
											addGoalForm.target ? parseFloat(addGoalForm.target) : undefined,
											undefined,
											addGoalForm.metricFormat,
											false,
											undefined,
											goalType === STRENGTH
										)}`}
									</div>
								</FlexCenteredPaddedDiv>
								{hasSecondaryGoal && (
									<FlexCenteredPaddedDiv>
										<div>
											<b>Tier 2:</b>
											{`${" "}${createGoalStatement(
												addGoalForm.secondaryGoal?.metricLabel ?? undefined,
												addGoalForm.metricSubtypeLabel ?? undefined,
												undefined,
												addGoalForm.secondaryGoal?.directionLabel ?? undefined,
												addGoalForm.secondaryGoal?.target
													? parseFloat(addGoalForm.secondaryGoal?.target)
													: undefined,
												undefined,
												addGoalForm.secondaryGoal?.metricFormat,
												false,
												undefined,
												goalType === STRENGTH
											)}`}
										</div>
									</FlexCenteredPaddedDiv>
								)}
								<FlexCenteredPaddedDiv>
									<div>
										<b>Splits:</b>
										{`${" "}${
											addGoalForm.splitOpts?.length
												? ` ${addGoalForm.splitOpts
														.map((splitOpt: TSplitOption) => splitOpt.rawLabel)
														.join(", ")}`
												: "None"
										}`}
									</div>
								</FlexCenteredPaddedDiv>
								<FlexCenteredPaddedDiv>
									<div>
										<b>Season:</b>
										{`${" "}${addGoalForm.season}`}
									</div>
								</FlexCenteredPaddedDiv>
							</>
						)}
					</div>
				)}
			</ModalContents>
			{activeStep > 1 && (
				<Button
					onClick={() => setActiveStep(activeStep - 1)}
					colorScheme={colorSchemeGroup.primary}
					title={"Back"}
					style={BackButtonStyle}
				/>
			)}
			{activeStep < 4 && (
				<Button
					onClick={() => {
						if (activeStep === 1) {
							setAddGoalForm({
								...addGoalForm,
								parentGoalId: goalType === CHILD ? parentGoalId || null : null,
								parentGoalStatement: goalType === CHILD ? parentGoalStatement || null : null,
								isParentGoal: goalType === PARENT || goalType === PARENT_PRIORITY,
								isStrength: goalType === STRENGTH,
								needsApproval: ![PHP, STRENGTH].includes(goalType ?? ""),
								isPriority: goalType === PARENT_PRIORITY
							});
						}
						setActiveStep(activeStep + 1);
					}}
					disabled={
						(activeStep === 1 && (goalType == null || (goalType === CHILD && parentGoalId == null))) ||
						(activeStep === 2 &&
							(addGoalForm.metricType == null ||
								(goalType !== PHP && addGoalForm.metricSubtype == null))) ||
						(activeStep === 3 &&
							(!isPrimaryGoalValid(addGoalForm, goalType === PHP) ||
								(hasSecondaryGoal && !isSecondaryGoalValid(addGoalForm))))
					}
					colorScheme={colorSchemeGroup.secondary}
					title={"Continue"}
					disabledOpacity={DISABLED_OPACITY}
				/>
			)}
			{activeStep === 4 && (
				<Button
					onClick={() => addGoal(goalType !== PHP)}
					colorScheme={style?.submitButtonColorScheme ?? colorSchemeGroup.secondary}
					title={"Add"}
				/>
			)}
		</>
	);
};
