import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { formatHeight } from "utils/functions";

export const getStyledHeaderText = (playerCombined: TPlayerPageCombinedPlayer | null | undefined) => {
	const combinedFirstName = playerCombined?.firstName ?? playerCombined?.firstNameLegal;
	if (playerCombined?.playerClassification === "AMA" && playerCombined.amaProfile) {
		return `
			${playerCombined.amaProfile.firstName ?? combinedFirstName}
			${playerCombined.amaProfile.lastName ?? playerCombined.lastName} |
			${playerCombined.amaProfile.position ?? playerCombined.position} |
			${playerCombined.amaProfile.bats ?? playerCombined.bats}/${playerCombined.amaProfile.throws ?? playerCombined.throws} |
			${formatHeight(playerCombined.amaProfile.height ?? playerCombined.height)} /
			${playerCombined.amaProfile.weight ?? playerCombined.weight}
		`;
	}
	return playerCombined != null
		? `${combinedFirstName} ${playerCombined.lastName} |
		${playerCombined.position} | ${playerCombined.bats}/${playerCombined.throws}
		${playerCombined.uniformNumber ? `| #${playerCombined.uniformNumber}` : ""}`
		: "";
};
