import React, { FC } from "react";
import styled from "@emotion/styled";

export type TLineScore = {
	awayScore: number;
	awayHits: number;
	awayErrors: number;
	awayTeamName?: string;
	homeScore: number;
	homeHits: number;
	homeErrors: number;
	homeTeamName?: string;
	innings?: Array<{ homeRuns?: number; awayRuns: number; inning: number }>;
};

type LineScoreProps = {
	lineScore: TLineScore;
	showInnings?: boolean;
	printing?: boolean;
};

export const LineScoreTable = styled.table({
	borderCollapse: "collapse",
	margin: "auto"
});

export const LineScoreHeaderPlaceholder = styled.td<{ printing?: boolean }>(({ printing }) => ({
	border: printing ? "1px solid black" : "2px solid black",
	padding: "2px 8px",
	fontWeight: 600,
	borderLeft: "1px solid white",
	borderTop: "1px solid white"
}));

export const LineScoreHeaderCell = styled.td<{ printing?: boolean }>(({ printing }) => ({
	border: printing ? "1px solid black" : "2px solid black",
	padding: "2px 8px",
	color: "white",
	backgroundColor: "black"
}));

export const LineScoreSummaryHeaderCell = styled.td<{ printing?: boolean }>(({ printing }) => ({
	border: printing ? "1px solid black" : "2px solid black",
	padding: "2px 8px",
	fontWeight: 600,
	color: "white",
	backgroundColor: "black"
}));

export const LineScoreRow = styled.tr<{ printing?: boolean }>(({ printing }) => ({
	border: printing ? "1px solid black" : "2px solid black"
}));

export const LineScoreCell = styled.td<{ printing?: boolean }>(({ printing }) => ({
	border: printing ? "1px solid black" : "2px solid black",
	padding: "2px 8px",
	fontWeight: 600
}));

export const LineScoreTeamCell = styled.td<{ printing?: boolean }>(({ printing }) => ({
	border: printing ? "1px solid black" : "2px solid black",
	padding: "2px 8px",
	fontWeight: 500
}));

export const LineScoreBorderCell = styled.td<{ printing?: boolean }>(({ printing }) => ({
	border: printing ? "1px solid black" : "2px solid black",
	padding: "2px 8px",
	fontWeight: 600,
	borderLeft: "2px solid black"
}));

export const LineScoreScoreCell = styled.td<{ printing?: boolean }>(({ printing }) => ({
	border: printing ? "1px solid black" : "2px solid black",
	padding: "2px 8px"
}));

export const LineScore: FC<LineScoreProps> = ({ lineScore, showInnings = true, printing = false }) => {
	return (
		<div>
			<LineScoreTable>
				<tbody>
					<tr>
						<LineScoreHeaderPlaceholder printing={printing} />

						{showInnings &&
							lineScore.innings?.map((_, idx) => {
								return (
									<LineScoreHeaderCell printing={printing} key={idx}>
										{idx + 1}
									</LineScoreHeaderCell>
								);
							})}
						<LineScoreSummaryHeaderCell printing={printing}>R</LineScoreSummaryHeaderCell>
						<LineScoreSummaryHeaderCell printing={printing}>H</LineScoreSummaryHeaderCell>
						<LineScoreSummaryHeaderCell printing={printing}>E</LineScoreSummaryHeaderCell>
					</tr>
					<LineScoreRow printing={printing}>
						<LineScoreTeamCell printing={printing}>{lineScore.awayTeamName ?? "Away"}</LineScoreTeamCell>
						<React.Fragment>
							{showInnings &&
								lineScore.innings?.map((inning, idx) => {
									return (
										<LineScoreScoreCell printing={printing} key={idx}>
											{inning.awayRuns}
										</LineScoreScoreCell>
									);
								})}
						</React.Fragment>
						<LineScoreBorderCell printing={printing}>{lineScore.awayScore ?? 0}</LineScoreBorderCell>
						<LineScoreCell printing={printing}>{lineScore.awayHits ?? 0}</LineScoreCell>
						<LineScoreCell printing={printing}>{lineScore.awayErrors ?? 0}</LineScoreCell>
					</LineScoreRow>
					<tr>
						<LineScoreTeamCell printing={printing}>{lineScore.homeTeamName ?? "Home"}</LineScoreTeamCell>
						<React.Fragment>
							{showInnings &&
								lineScore.innings?.map((inning, idx) => {
									return (
										<LineScoreScoreCell printing={printing} key={idx}>
											{inning.homeRuns ?? "X"}
										</LineScoreScoreCell>
									);
								})}
						</React.Fragment>
						<LineScoreBorderCell printing={printing}>{lineScore.homeScore ?? 0}</LineScoreBorderCell>
						<LineScoreCell printing={printing}>{lineScore.homeHits ?? 0}</LineScoreCell>
						<LineScoreCell printing={printing}>{lineScore.homeErrors ?? 0}</LineScoreCell>
					</tr>
				</tbody>
			</LineScoreTable>
		</div>
	);
};
