import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Dialog from "@material-ui/core/Dialog";
import Info from "_react/shared/legacy/ui/icons/Info";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { convertToRaw } from "draft-js";

import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import Form from "_react/forms/Form";
import { isMobile } from "utils/_helpers";
import {
	PLAYER_POSITION_OPTS,
	PITCHER_POSITION_OPTS,
	OFP_OPTS_PRO,
	GRADE_OPTS_PRO,
	MISC_OPTS,
	DEFAULT_PITCH_TYPES_2OTH,
	OTH_PITCH_OPTS_PRO,
	HIT_TYPE_OPTS,
	YESNO_OPTS,
	TREND_OPTS,
	LOOK_OPTS,
	LEVEL_OPTS,
	MISC_OPTS_NO_EXC,
	PROSPECT_TYPE_MAP_PRO,
	OUT_PITCH_OPTS,
	PLAYER_PROFILE_OPTS,
	PITCHER_PROFILE_OPTS,
	ARM_ANGLE_OPTS,
	PRO_PROFILE_OPTS_VIEW,
	INTEREST_OPS,
	INTEREST_OPS_PRO,
	OFP_OPTS_PRO_LEGACY,
	PRO_RISK_OPTS
} from "_react/evals/_constants";
import { isIntegerInput, getPriorOutright } from "utils/functions";
import { getProspectTypePro, isNumeric, correctIP } from "_react/evals/helpers";
import { getAgeFromBirthDate, getCurrency } from "utils/helpers";
import { getTeamName } from "_react/shared/_helpers/team";
import ProPitcherToolsTable from "_react/evals/pro/ProPitcherToolsTable";
import ProEvalFooter from "_react/evals/pro/ProEvalFooter";
import ProEvalHeader from "_react/evals/pro/ProEvalHeader";
import ProPlayerOffenseToolsTable from "_react/evals/pro/ProPlayerOffenseToolsTable";
import ProPlayerDefenseToolsTable from "_react/evals/pro/ProPlayerDefenseToolsTable";
import ProGradeTooltip from "_react/evals/pro/tooltips/ProGradeTooltip";
import ProPlayerSnapshotTooltip from "_react/evals/pro/tooltips/ProPlayerSnapshotTooltip";
import ProPlayerToolsTooltip from "_react/evals/pro/tooltips/ProPlayerToolsTooltip";
import ProPitcherSnapshotTooltip from "_react/evals/pro/tooltips/ProPitcherSnapshotTooltip";
import ProMiscTooltip from "_react/evals/pro/tooltips/ProMiscTooltip";
import ProPitcherToolsTooltip from "_react/evals/pro/tooltips/ProPitcherToolsTooltip";
import RecentStatistics from "_react/stats/player";
import { EYEWEARS } from "_react/playerpage/shared_js/form_constants";
import { generateProEval } from "_react/evals/shared/_helpers";

export const ONDO_UID = 1039;

// eslint-disable-next-line no-unused-vars
const BIO_FIELDS = (evaluation, player, team, teamLevel, org, agent, scout, gradeAdornment) => ({
	title: "Bio",
	mobileTab: "Bio",
	sections: [
		{
			objects: [
				{
					type: "label",
					label: "Player",
					value: `${player.first_name} ${player.last_name}`
				},
				{ id: "position", label: "Position", type: "label" },
				{
					id: "date_of_birth",
					label: "D.O.B.",
					type: "label",
					value:
						player.birth_date != null
							? `${player.birth_date.format("M/D/YYYY")} (${getAgeFromBirthDate(player.birth_date)})`
							: player.date_of_birth != null
							? `${dayjs(player.date_of_birth).format("M/D/YYYY")} (${getAgeFromBirthDate(
									dayjs(player.date_of_birth)
							  )})`
							: ""
				},
				{
					id: "country",
					label: "Birth Country",
					type: "label",
					value: player.birth_country
				},
				{
					id: "bats",
					label: "Bats",
					type: "label",
					partialWidth: 6,
					objectStyle: { maxWidth: "70px" }
				},
				{
					id: "throws",
					label: "Throws",
					type: "label",
					partialWidth: 6,
					objectStyle: { maxWidth: "70px" }
				},
				{
					id: "height",
					label: "Height",
					type: "label",
					value: evaluation.height
						? `${Math.floor(parseInt(evaluation.height, 10) / 12.0)}" ${parseInt(evaluation.height, 10) %
								12}'`
						: "",
					partialWidth: 6,
					objectStyle: { maxWidth: "70px" }
				},
				{
					id: "weight",
					label: "Weight",
					type: "label",
					partialWidth: 6,
					objectStyle: { maxWidth: "70px" }
				},
				{ label: "Org", type: "label", value: org },
				{ label: "Team", type: "label", value: team },
				{ label: "Level", type: "label", value: teamLevel }
			]
		},
		{
			objects: [
				{
					label: "Draft",
					type: "label",
					value:
						player.r4_year != null ? `${player.r4_year} / Round ${player.r4_round} / ${player.r4_org}` : "-"
				},
				{
					id: "signing_bonus",
					label: "Bonus $",
					type: "label",
					value: getCurrency(player.first_pro_signing_bonus)
				},
				{
					id: "first_year",
					label: "FYOB",
					type: "label",
					value:
						player.first_pro_contract_date != null
							? dayjs(player.first_pro_contract_date).format("YYYY")
							: "-"
				},
				{ label: "Agent", type: "label", value: agent },
				{
					id: "options_used",
					label: "Opt Used",
					type: "label",
					value: player.options_used
				},
				{
					label: "Prior Outright",
					type: "label",
					value: getPriorOutright(player)
				},
				{
					label: "Current MLS",
					type: "label",
					value: player.mls_current ? player.mls_current.toFixed(3) : "-"
				},
				{
					id: "eyewear",
					label: "Eyewear",
					type: "autocomplete",
					options: EYEWEARS,
					numCharToShowSuggestions: 0,
					show: evaluation.publish_date != null && evaluation.publish_date.year() < 2019
				}
			]
		},
		{
			objects: [
				{
					id: "ofp",
					label: "OFP",
					type: "autocomplete",
					options: evaluation.eval_create_date.year() < 2019 ? OFP_OPTS_PRO_LEGACY : OFP_OPTS_PRO,
					numCharToShowSuggestions: 0
				},
				{
					id: "category",
					label: "Category",
					type: "label",
					value:
						evaluation.ofp != null
							? PROSPECT_TYPE_MAP_PRO[getProspectTypePro(evaluation.ofp, evaluation)]
							: ""
				},
				{
					id: "risk_factor",
					label: "Risk",
					type: "autocomplete",
					options: PRO_RISK_OPTS,
					numCharToShowSuggestions: 0,
					show: evaluation.publish_date != null && evaluation.publish_date.year() <= 2023
				},
				{
					id: "interest_level",
					label: "Interest",
					type: "autocomplete",
					options: INTEREST_OPS,
					numCharToShowSuggestions: 0,
					show: evaluation.publish_date != null && evaluation.publish_date.year() < 2019 // Show Interest with old options, above acquire, for Pre-2019 evals
				},
				{
					id: "acquire",
					label: "Acquire",
					type: "autocomplete",
					options: YESNO_OPTS,
					numCharToShowSuggestions: 0
				},
				{
					id: "interest_level",
					label: "Interest",
					type: "autocomplete",
					options: INTEREST_OPS_PRO,
					numCharToShowSuggestions: 0,
					show: evaluation.publish_date == null || evaluation.publish_date.year() >= 2024 // Show Interest with new options, below acquire, for 2024+ evals
				},
				{
					id: "interest_level",
					label: "Interest",
					type: "autocomplete",
					options: INTEREST_OPS,
					numCharToShowSuggestions: 0,
					show:
						evaluation.publish_date != null &&
						evaluation.publish_date.year() <= 2023 &&
						evaluation.publish_date.year() >= 2022 // Show Interest with old options, below acquire, for 2022-2023 evals
				},
				{
					id: "profile",
					label: "Profile",
					type: "autocomplete",
					options:
						evaluation.eval_create_date.year() < 2019
							? PRO_PROFILE_OPTS_VIEW
							: evaluation.eval_type === "PRO_HIT"
							? PLAYER_PROFILE_OPTS
							: PITCHER_PROFILE_OPTS,
					numCharToShowSuggestions: 0
				},
				{
					id: "trend",
					label: "Trend",
					type: "autocomplete",
					options: TREND_OPTS,
					numCharToShowSuggestions: 0
				},
				{
					id: "look",
					label: "Look Type",
					type: "autocomplete",
					options: LOOK_OPTS,
					numCharToShowSuggestions: 0
				},
				{ id: "scout", label: "Scout", type: "label", value: scout }
			]
		}
	],
	startAdornment: gradeAdornment
});

// eslint-disable-next-line no-unused-vars
const DETAILS_FIELDS = (evaluation, teams, teamLevel, player) => ({
	title: "Report Details",
	mobileTab: "Report Details",
	sections: [
		{
			objects: [
				{ id: "seen_date", label: "Seen Date", type: "date" },
				{
					id: "publish_date",
					label: "Publish Date",
					type: "label",
					value: evaluation.publish_date != null ? evaluation.publish_date.format("YYYY-MM-DD") : "-"
				},
				{
					id: "date_of_birth",
					label: "Age Reported On",
					type: "label",
					value:
						player.birth_date != null
							? `${getAgeFromBirthDate(
									player.birth_date,
									evaluation.seen_date != null
										? dayjs(evaluation.seen_date).format("YYYY-MM-DD")
										: dayjs().format("YYYY-MM-DD")
							  )}`
							: player.date_of_birth != null
							? `${getAgeFromBirthDate(
									player.date_of_birth,
									evaluation.seen_date != null
										? dayjs(evaluation.seen_date).format("YYYY-MM-DD")
										: dayjs().format("YYYY-MM-DD")
							  )}`
							: ""
				}
			]
		},
		{
			objects: [
				{
					id: "games_seen",
					label: "Games Seen",
					type: "text",
					validation: [
						{
							validate: isIntegerInput,
							message: "Must be an integer"
						}
					]
				},
				{
					id: "innings_seen_thirds",
					label: "Innings Pitched",
					type: "text",
					show: evaluation.eval_type === "PRO_PITCH",
					validation: [{ validate: isNumeric, message: "Must be a number" }],
					value: correctIP(evaluation.innings_seen_thirds)
				},
				{
					id: "highest_level_of_play",
					label: "Highest Level",
					type: "autocomplete",
					options: LEVEL_OPTS,
					numCharToShowSuggestions: 0
				},
				{
					id: "projected_ml_year",
					label: "Projected ML",
					type: "text",
					validation: [{ validate: isIntegerInput, message: "Must be a year" }]
				}
			]
		},
		{
			objects: [
				{
					id: "team_bam_id",
					label: "Team Reported On",
					type: "autocomplete",
					options: teams,
					numCharToShowSuggestions: 0
				},
				{
					id:
						evaluation.publish_date != null && evaluation.publish_date.year() < 2019
							? "position"
							: "position_reported",
					label: "Position Reported On",
					type: "autocomplete",
					options: evaluation.eval_type === "PRO_PITCH" ? PITCHER_POSITION_OPTS : PLAYER_POSITION_OPTS,
					numCharToShowSuggestions: 0
				},
				{
					id: "level_reported",
					label: "Level Reported On",
					type: "autocomplete",
					options: LEVEL_OPTS,
					numCharToShowSuggestions: 0,
					value: evaluation.level_reported ? evaluation.level_reported : teamLevel
				}
			]
		}
	]
});

const OFFENSE = [
	{ id: "hitting_ability", label: "Hitting Ability" },
	{ id: "ab_quality", label: "AB Quality" },
	{ id: "raw_power", label: "Raw Power" },
	{ id: "power_production", label: "Power Production" },
	{ id: "run_speed", label: "Run Speed" },
	{ id: "base_running", label: "Baserunning" }
];

// eslint-disable-next-line no-unused-vars
const OFFENSE_FIELDS = (evaluation, startAdornment) => {
	const objects = [];
	OFFENSE.map((field, idx) => {
		if (idx > 2) return null;
		objects.push(
			...[
				{
					id: `${field.id}_present`,
					label: field.label,
					placeholder: "Present",
					type:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? "autocomplete"
							: "label",
					options: GRADE_OPTS_PRO,
					numCharToShowSuggestions: 0,
					partialWidth: 6,
					value:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? evaluation[`${field.id}_present`]
							: ""
				},
				{
					id: `${field.id}_future`,
					placeholder: "Future",
					type:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? "autocomplete"
							: "label",
					options: GRADE_OPTS_PRO,
					numCharToShowSuggestions: 0,
					partialWidth: 6,
					value:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? evaluation[`${field.id}_future`]
							: ""
				}
				// {id: `${field.id}_comments`, placeholder: "Comments", type: "text", partialWidth: 8}
			]
		);
		return null;
	});

	objects.push({
		id: "hit_type",
		label: "Type of Hitter",
		type:
			evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
				? "autocomplete"
				: "label",
		options: HIT_TYPE_OPTS,
		numCharToShowSuggestions: 0,
		value:
			evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
				? evaluation.hit_type
				: ""
	});

	OFFENSE.map((field, idx) => {
		if (idx < 3) return null;
		objects.push(
			...[
				{
					id: `${field.id}_present`,
					label: field.label,
					placeholder: "Present",
					type:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? "autocomplete"
							: "label",
					options: GRADE_OPTS_PRO,
					numCharToShowSuggestions: 0,
					partialWidth: 6,
					value:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? evaluation[`${field.id}_present`]
							: ""
				},
				{
					id: `${field.id}_future`,
					placeholder: "Future",
					type:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? "autocomplete"
							: "label",
					options: GRADE_OPTS_PRO,
					numCharToShowSuggestions: 0,
					partialWidth: 6,
					value:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? evaluation[`${field.id}_future`]
							: ""
				}
				// {id: `${field.id}_comments`, placeholder: "Comments", type: "text", multiline: true, partialWidth: 8}
			]
		);
		return null;
	});

	objects.push({
		id: "home_to_1b",
		label: "Home to 1B",
		type:
			evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
				? "text"
				: "label",
		validation: [{ validate: isNumeric, message: "Must be a number" }],
		value:
			evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
				? evaluation.home_to_1b
				: ""
	});

	return {
		title: "Offense",
		mobileTab: "Offense",
		partialWidth: 6,
		sections: [
			{
				objects: objects
			}
		],
		displayComponent: <ProPlayerOffenseToolsTable evaluation={evaluation} />,
		show: evaluation.eval_type === "PRO_HIT",
		startAdornment
	};
};

const DEFENSE = [
	{ id: "position_in_ml", label: "Position in ML" },
	{ id: "arm_strength", label: "Arm Strength" },
	{ id: "arm_accuracy", label: "Arm Accuracy" },
	{ id: "fielding_ability", label: "Hands" },
	{ id: "receiving", label: "Receiving" },
	{ id: "blocking", label: "Blocking" },
	{ id: "fielding_range", label: "Range" },
	{ id: "defense", label: "Overall Defense" }
];

// eslint-disable-next-line no-unused-vars
const DEFENSE_FIELDS = (evaluation, startAdornment) => {
	const objects = [];
	DEFENSE.map(field => {
		if (
			evaluation.publish_date != null &&
			evaluation.publish_date.year() < 2019 &&
			field.id === "fielding_ability"
		) {
			// Legacy "Fielding Ability" Field
			objects.push(
				...[
					{
						id: `${field.id}_present`,
						label: "Fielding Ability",
						placeholder: "Present",
						type: "autocomplete",
						options: GRADE_OPTS_PRO,
						numCharToShowSuggestions: 0,
						partialWidth: 8
					},
					{
						id: `${field.id}_future`,
						placeholder: "Future",
						type: "autocomplete",
						options: GRADE_OPTS_PRO,
						numCharToShowSuggestions: 0,
						partialWidth: 4
					}
					// {id: `${field.id}_comments`, placeholder: "Comments", type: "text", multiline: true, partialWidth: 8}
				]
			);
			return null;
		}

		if (["hands", "range"].includes(field.id) && evaluation.position_reported === "C") return null;
		if (["receiving", "blocking"].includes(field.id) && evaluation.position_reported !== "C") return null;

		objects.push(
			...[
				{
					id: `${field.id}_present`,
					label: field.label,
					placeholder: "Present",
					type:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? "autocomplete"
							: "label",
					options: field.id === "position_in_ml" ? PLAYER_POSITION_OPTS : GRADE_OPTS_PRO,
					numCharToShowSuggestions: 0,
					partialWidth: 8,
					value:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? evaluation[`${field.id}_present`]
							: ""
				},
				{
					id: `${field.id}_future`,
					placeholder: "Future",
					type:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? "autocomplete"
							: "label",
					options: field.id === "position_in_ml" ? PLAYER_POSITION_OPTS : GRADE_OPTS_PRO,
					numCharToShowSuggestions: 0,
					partialWidth: 4,
					value:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? evaluation[`${field.id}_future`]
							: ""
				}
				// {id: `${field.id}_comments`, placeholder: "Comments", type: "text", multiline: true, partialWidth: 8}
			]
		);
		return null;
	});

	if (evaluation.position_reported !== "C")
		objects.push({
			id: "instinct",
			label: "Instincts",
			type:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? "autocomplete"
					: "label",
			options: MISC_OPTS,
			numCharToShowSuggestions: 0,
			partialWidth: 12,
			value:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? evaluation.instinct
					: ""
		});
	// if(evaluation.position_reported !== "C") objects.push({id: "instinct_comments", placeholder: "Comments", type: "text", partialWidth: 8});
	if (evaluation.position_reported === "C")
		objects.push({
			id: "avg_release_time",
			label: "Average Release Time",
			type:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? "text"
					: "label",
			validation: [{ validate: isNumeric, message: "Must be a number" }],
			value:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? evaluation.avg_release_time
					: ""
		});
	if (evaluation.position_reported === "C")
		objects.push({
			id: "intangibles",
			label: "Intangibles",
			type:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? "autocomplete"
					: "label",
			options: MISC_OPTS,
			numCharToShowSuggestions: 0,
			value:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? evaluation.intangibles
					: ""
		});
	objects.push({
		id: "ath",
		label: "Athleticism",
		type:
			evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
				? "autocomplete"
				: "label",
		options: MISC_OPTS,
		numCharToShowSuggestions: 0,
		partialWidth: 12,
		value:
			evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
				? evaluation.ath
				: ""
	});
	// objects.push({id: "ath_comments", placeholder: "Comments", type: "text", partialWidth: 8});

	return {
		title: "Defense",
		mobileTab: "Defense",
		partialWidth: 6,
		sections: [
			{
				objects: objects
			}
		],
		displayComponent: <ProPlayerDefenseToolsTable evaluation={evaluation} />,
		show: evaluation.eval_type === "PRO_HIT",
		startAdornment
	};
};

// eslint-disable-next-line no-unused-vars
const OFFENSE_SUMMARY = (evaluation, isMobile) => ({
	title: "Offense Summary",
	mobileTab: "Offense Summary",
	partialWidth: 6,
	sections: [
		{
			objects: [
				{
					id: "offense_summary",
					type: "text",
					multiline: true,
					format: value =>
						value
							? value.split("\n").map((item, idx) => (
									<span key={idx}>
										{item}
										<br />
									</span>
							  ))
							: value
				}
			]
		}
	],
	style: { minHeight: isMobile ? "" : "260px" },
	show: evaluation.eval_type === "PRO_HIT"
});

// eslint-disable-next-line no-unused-vars
const DEFENSE_SUMMARY = (evaluation, isMobile) => ({
	title: "Defense Summary",
	mobileTab: "Defense Summary",
	partialWidth: 6,
	sections: [
		{
			objects: [
				{
					id: "defense_summary",
					type: "text",
					multiline: true,
					format: value =>
						value
							? value.split("\n").map((item, idx) => (
									<span key={idx}>
										{item}
										<br />
									</span>
							  ))
							: value
				}
			]
		}
	],
	style: { minHeight: isMobile ? "" : "260px" },
	show: evaluation.eval_type === "PRO_HIT"
});

// eslint-disable-next-line no-unused-vars
const MISC_FIELDS = (evaluation, player, startAdornment) => ({
	title: "Miscellaneous",
	mobileTab: "Miscellaneous",
	sections: [
		{
			objects: [
				{
					id: "arm_slot",
					label: "Arm Slot",
					type: "autocomplete",
					options: ARM_ANGLE_OPTS,
					numCharToShowSuggestions: 0,
					show: evaluation.eval_type === "PRO_PITCH"
				},
				{
					id: "arm_act",
					label: "Arm Action",
					type: "autocomplete",
					options: MISC_OPTS_NO_EXC,
					numCharToShowSuggestions: 0,
					show: evaluation.eval_type === "PRO_PITCH"
				},
				{
					id: "del",
					label: "Delivery",
					type: "autocomplete",
					options: MISC_OPTS,
					numCharToShowSuggestions: 0,
					show: evaluation.eval_type === "PRO_PITCH"
				},
				{
					id: "deception",
					label: "Deception",
					type: "autocomplete",
					options: MISC_OPTS,
					numCharToShowSuggestions: 0,
					show: evaluation.eval_type === "PRO_PITCH"
				},
				{
					id: "ath",
					label: "Athleticism",
					type: "autocomplete",
					options: MISC_OPTS,
					numCharToShowSuggestions: 0,
					show: evaluation.eval_type === "PRO_PITCH"
				},

				// Legacy Fields
				{
					id: "comp",
					label: "Competitiveness",
					type: "autocomplete",
					options: MISC_OPTS,
					numCharToShowSuggestions: 0,
					show: evaluation.publish_date != null && evaluation.publish_date.year() < 2019
				},
				{
					id: "instinct",
					label: "Baseball Instincts",
					type: "autocomplete",
					options: MISC_OPTS,
					numCharToShowSuggestions: 0,
					show: evaluation.publish_date != null && evaluation.publish_date.year() < 2019
				},
				{
					id: "poise",
					label: "Poise",
					type: "autocomplete",
					options: MISC_OPTS,
					numCharToShowSuggestions: 0,
					show: evaluation.publish_date != null && evaluation.publish_date.year() < 2019
				},

				{
					id: "physical_description",
					label: "Physical Description",
					type: "text",
					multiline: true
				},
				{
					id: "makeup",
					label: "On-Field Makeup",
					type: "autocomplete",
					options: MISC_OPTS_NO_EXC,
					numCharToShowSuggestions: 0,
					partialWidth: 3
				},
				{
					id: "makeup_comments",
					placeholder: "Comments",
					type: "text",
					multiline: true,
					partialWidth: 9
				},
				{
					id: "off_field_comments",
					label: "Off-Field Comments",
					type: "text",
					multiline: true
				},
				{
					id: "injury_notes",
					label: "Injury Notes",
					type: "text",
					multiline: true
				},

				// Legacy Fields
				{
					id: "player_comparison",
					label: "Player Comparison",
					type: "text",
					multiline: true,
					show: evaluation.publish_date != null && evaluation.publish_date.year() < 2019
				},
				{
					id: "future_level_of_play",
					label: "Next Year",
					type: "text",
					multiline: true,
					show: evaluation.publish_date != null && evaluation.publish_date.year() < 2019
				}
			]
		}
	],
	startAdornment
});

// eslint-disable-next-line no-unused-vars
const SNAP_SUM_PITCH = evaluation => ({
	title: "Summary (Snapshot is the first sentence and will appear in bold)",
	mobileTab: "Sum",
	sections: [
		{
			objects: [
				{
					id: "summary",
					type: "summarySnapshot",
					multiline: true,
					format: valueRaw => {
						let value = valueRaw;
						if (typeof value === "object" && typeof value !== "string" && value != null) {
							value = convertToRaw(value.getCurrentContent())
								.blocks.map(block => block.text)
								.join(" ")
								.replace(/  +/g, " ");
						}
						let snapshot =
							// eslint-disable-next-line no-useless-escape
							evaluation.current_snapshot ?? (value ?? "").match(/^.*?([\.!\?]|$)(?:\s|$)/g) ?? "";
						if (typeof snapshot !== "string" && snapshot.length > 0)
							snapshot = snapshot[0].substring(0, 250);
						// eslint-disable-next-line no-useless-escape
						if (snapshot && snapshot.match(/\.([^\S]*)$/g) == null) snapshot = `${snapshot.trim()}.`;
						if (snapshot) snapshot = `${snapshot.trim()} `;

						let summary =
							evaluation.current_snapshot &&
							(value == null || typeof value !== "string" || !value.includes(evaluation.current_snapshot))
								? value
								: // eslint-disable-next-line no-useless-escape
								  (value ?? "").replace(/^.*?([\.!\?]|$)(?:\s|$)/g, "");
						if (snapshot.length > 250) summary = value.substring(250);
						return (
							<div>
								<span style={{ fontWeight: 900 }}>{snapshot}</span>
								{summary}
							</div>
						);
					},
					onBottom: evaluation != null && evaluation.publish_date == null
				}
			]
		}
	]
});

// eslint-disable-next-line no-unused-vars
const SNAP_SUM_HIT = evaluation => ({
	title: "Current Snapshot",
	mobileTab: "Snap",
	sections: [
		{
			objects: [
				{
					id: "current_snapshot",
					type: "text",
					multiline: true,
					format: value =>
						value
							? value.split("\n").map((item, idx) => (
									<span key={idx}>
										{item}
										<br />
									</span>
							  ))
							: value,
					maxLength: 250,
					onBottom: evaluation != null && evaluation.publish_date == null
				}
			]
		}
	]
});

// eslint-disable-next-line no-unused-vars
const TOOLS_GRADING_STANDARDS_PITCH = (evaluation, startAdornment) => {
	const sections = [];
	let objects = [];

	DEFAULT_PITCH_TYPES_2OTH.map(pitchType => {
		return [
			{ label: pitchType.name, type: "grade", key: "none" },
			{ label: `Velocity`, type: "vel", key: "vel" },
			{ label: `Movement`, type: "grade", key: "mov" },
			{ label: `Command`, type: "grade", key: "cmd" }
		].map(row => {
			if (pitchType.abb !== "FB" && row.label === "Movement") return null;

			// OTH issue (TODO: Fix in DB)
			if (row.key === "cmd" && pitchType.abb === "OTH_PITCH") {
				pitchType.abb = "OTH";
			} else if (row.key !== "cmd" && pitchType.abb === "OTH") {
				pitchType.abb = "OTH_PITCH";
			}
			if (row.key === "cmd" && pitchType.abb === "OTH2_PITCH") {
				pitchType.abb = "OTH2";
			} else if (row.key !== "cmd" && pitchType.abb === "OTH2") {
				pitchType.abb = "OTH2_PITCH";
			}

			if (row.key === "none") {
				if (row.label === pitchType.name) {
					if (objects.length > 0 && (objects[0].id !== "oth_pitch_type" || evaluation.oth_pitch_type != null))
						sections.push(objects);
					if (objects.length > 0 && objects[0].id === "oth_pitch_type" && evaluation.oth_pitch_type == null)
						sections.push([objects[0]]);
					objects = [];
				}
			}

			if (pitchType.abb === "OTH_PITCH" && row.label === pitchType.name) {
				objects.push({
					id: "oth_pitch_type",
					type:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? "autocomplete"
							: "label",
					options: OTH_PITCH_OPTS_PRO,
					placeholder: "Select Pitch",
					partialWidth: 3,
					numCharToShowSuggestions: 0,
					containerStyle: { flex: null, width: "162px" },
					value:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? evaluation.oth_pitch_type
							: ""
				});
			} else if (pitchType.abb === "OTH_PITCH" || pitchType.abb === "OTH") {
				objects.push({
					type: "none",
					label: `${evaluation.oth_pitch_type ? `${evaluation.oth_pitch_type} ` : ""}${row.label}`,
					partialWidth: 3,
					containerStyle: { flex: null },
					show: evaluation.oth_pitch_type != null
				});
			} else if (pitchType.abb === "OTH2_PITCH" && row.label === pitchType.name) {
				objects.push({
					id: "oth2_pitch_type",
					type:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? "autocomplete"
							: "label",
					options: OTH_PITCH_OPTS_PRO,
					placeholder: "Select Pitch",
					partialWidth: 3,
					numCharToShowSuggestions: 0,
					containerStyle: { flex: null, width: "162px" },
					value:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? evaluation.oth2_pitch_type
							: ""
				});
			} else if (pitchType.abb === "OTH2_PITCH" || pitchType.abb === "OTH2") {
				objects.push({
					type: "none",
					label: `${evaluation.oth2_pitch_type ? `${evaluation.oth2_pitch_type} ` : ""}${row.label}`,
					partialWidth: 3,
					containerStyle: { flex: null },
					show: evaluation.oth2_pitch_type != null
				});
			} else {
				objects.push({
					type: "none",
					label: row.label === pitchType.name ? pitchType.name : `${pitchType.abb} ${row.label}`,
					partialWidth: pitchType.abb === "FB" && row.type === "vel" ? 1 : 3,
					containerStyle: { flex: null }
				});
			}

			if (row.type === "grade") {
				objects = [
					...objects,
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_quality"
								: row.key === "none"
								? ""
								: `_${row.key}`
						}_present`,
						type:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? "autocomplete"
								: "label",
						options: GRADE_OPTS_PRO,
						numCharToShowSuggestions: 0,
						partialWidth: row.label !== "Command" && row.label !== "Movement" ? 3 : 5,
						style: { maxWidth: "75px" },
						containerStyle: { display: "inline-flex", maxWidth: "100px" },
						placeholder: "Present",
						preventMobileBreak: true,
						value:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? evaluation[
										`${pitchType.abb.toLowerCase()}${
											row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
												? "_quality"
												: row.key === "none"
												? ""
												: `_${row.key}`
										}_present`
								  ]
								: ""
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_quality"
								: row.key === "none"
								? ""
								: `_${row.key}`
						}_future`,
						type:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? "autocomplete"
								: "label",
						options: GRADE_OPTS_PRO,
						numCharToShowSuggestions: 0,
						partialWidth: row.label !== "Command" && row.label !== "Movement" ? 3 : 5,
						style: { maxWidth: "75px" },
						containerStyle: { display: "inline-flex", maxWidth: "100px" },
						placeholder: "Future",
						value:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? evaluation[
										`${pitchType.abb.toLowerCase()}${
											row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
												? "_quality"
												: row.key === "none"
												? ""
												: `_${row.key}`
										}_future`
								  ]
								: ""
					}
				];
				if (row.label !== "Command" && row.label !== "Movement")
					objects.push({
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: `_${row.key}`
						}_comments`,
						type:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? "text"
								: "label",
						partialWidth: 3,
						placeholder: "Comments",
						multiline: true,
						style: { paddingBottom: "3px", marginTop: "-3px" },
						value:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? evaluation[
										`${pitchType.abb.toLowerCase()}${
											row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
												? "_vel"
												: row.key === "none"
												? ""
												: `_${row.key}`
										}_comments`
								  ]
								: ""
					});
			} else if (row.type === "vel") {
				objects = [
					...objects,
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_low`,
						type:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? "text"
								: "label",
						partialWidth: pitchType.abb === "FB" ? 3 : 6,
						style: { maxWidth: "75px" },
						containerStyle: { display: "inline-flex", maxWidth: "100px" },
						placeholder: "MPH",
						preventMobileBreak: true,
						validation: [
							{
								validate: isIntegerInput,
								message: "Must be an integer"
							},
							{
								validate: e => parseInt(e, 10) >= 55 && parseInt(e, 10) <= 110,
								message: "Must be withing range 55 - 110"
							}
						],
						value:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? evaluation[
										`${pitchType.abb.toLowerCase()}${
											row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
												? "_vel"
												: row.key === "none"
												? ""
												: row.key
										}_low`
								  ]
								: ""
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_high`,
						type:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? "text"
								: "label",
						partialWidth: pitchType.abb === "FB" ? 3 : 6,
						style: { maxWidth: "75px" },
						containerStyle: { display: "inline-flex", maxWidth: "100px" },
						placeholder: "MPH",
						preventMobileBreak: pitchType.abb === "FB" && row.type === "vel",
						validation: [
							{
								validate: isIntegerInput,
								message: "Must be an integer"
							},
							{
								validate: e => parseInt(e, 10) >= 55 && parseInt(e, 10) <= 110,
								message: "Must be withing range 55 - 110"
							}
						],
						value:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? evaluation[
										`${pitchType.abb.toLowerCase()}${
											row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
												? "_vel"
												: row.key === "none"
												? ""
												: row.key
										}_high`
								  ]
								: ""
					}
				];
			}

			if (pitchType.abb === "FB" && row.type === "vel") {
				objects = [
					...objects,
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_comfort_zone_low`,
						type:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? "text"
								: "label",
						partialWidth: 2,
						style: { maxWidth: "75px" },
						containerStyle: { display: "inline-flex", maxWidth: "100px" },
						placeholder: "Comfort",
						preventMobileBreak: true,
						validation: [
							{
								validate: isIntegerInput,
								message: "Must be an integer"
							},
							{
								validate: e => parseInt(e, 10) >= 55 && parseInt(e, 10) <= 110,
								message: "Must be withing range 55 - 110"
							}
						],
						value:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? evaluation[
										`${pitchType.abb.toLowerCase()}${
											row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
												? "_vel"
												: row.key === "none"
												? ""
												: row.key
										}_comfort_zone_low`
								  ]
								: ""
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_comfort_zone_high`,
						type:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? "text"
								: "label",
						partialWidth: 2,
						style: { maxWidth: "75px" },
						containerStyle: { display: "inline-flex", maxWidth: "100px" },
						placeholder: "Comfort",
						validation: [
							{
								validate: isIntegerInput,
								message: "Must be an integer"
							},
							{
								validate: e => parseInt(e, 10) >= 55 && parseInt(e, 10) <= 110,
								message: "Must be withing range 55 - 110"
							}
						],
						value:
							evaluation.ofp == null ||
							evaluation.ofp > 30 ||
							evaluation.ofp === 25 ||
							evaluation.ofp === 0
								? evaluation[
										`${pitchType.abb.toLowerCase()}${
											row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
												? "_vel"
												: row.key === "none"
												? ""
												: row.key
										}_comfort_zone_high`
								  ]
								: ""
					}
				];
				objects[5].partialWidth = 2;
				objects[6].partialWidth = 2;
				objects.splice(5, 0, {
					id: "fb_vel_present",
					type:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? "autocomplete"
							: "label",
					options: GRADE_OPTS_PRO,
					numCharToShowSuggestions: 0,
					partialWidth: 2,
					style: { maxWidth: "75px" },
					containerStyle: { display: "inline-flex", maxWidth: "100px" },
					placeholder: "Present",
					preventMobileBreak: true,
					value:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? evaluation.fb_vel_present
							: ""
				});
				objects.splice(6, 0, {
					id: "fb_vel_future",
					type:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? "autocomplete"
							: "label",
					options: GRADE_OPTS_PRO,
					numCharToShowSuggestions: 0,
					partialWidth: 2,
					style: { maxWidth: "75px" },
					containerStyle: { display: "inline-flex", maxWidth: "100px" },
					placeholder: "Future",
					value:
						evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
							? evaluation.fb_vel_future
							: ""
				});
			}
			return null;
		});
	});

	// Add final section
	if (evaluation.oth2_pitch_type != null) {
		sections.push(objects);
		objects = [];
	} else {
		sections.push([objects[0]]);
		objects = [];
	}

	sections.push([
		{
			type: "none",
			label: "Outpitch",
			partialWidth: 1,
			containerStyle: { flex: "" }
		},
		{
			id: "outpitch",
			type:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? "autocomplete"
					: "label",
			options: OUT_PITCH_OPTS,
			numCharToShowSuggestions: 0,
			show: evaluation.eval_type === "PRO_PITCH",
			partialWidth:
				evaluation.outpitch == null || evaluation.outpitch === ""
					? 12
					: evaluation.outpitch2 == null || evaluation.outpitch2 === ""
					? 6
					: 4,
			value:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? evaluation.outpitch
					: ""
		},
		{
			id: "outpitch2",
			placeholder: "Second Outpitch",
			type:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? "autocomplete"
					: "label",
			options: OUT_PITCH_OPTS,
			numCharToShowSuggestions: 0,
			show: evaluation.eval_type === "PRO_PITCH" && evaluation.outpitch != null && evaluation.outpitch !== "",
			partialWidth: evaluation.outpitch2 == null || evaluation.outpitch2 === "" ? 6 : 4,
			value:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? evaluation.outpitch2
					: ""
		},
		{
			id: "outpitch3",
			placeholder: "Third Outpitch",
			type:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? "autocomplete"
					: "label",
			options: OUT_PITCH_OPTS,
			numCharToShowSuggestions: 0,
			show: evaluation.eval_type === "PRO_PITCH" && evaluation.outpitch2 != null && evaluation.outpitch2 !== "",
			partialWidth: 4,
			value:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? evaluation.outpitch3
					: ""
		},
		{
			type: "none",
			label: "CHP / Pitchability",
			partialWidth: 1,
			containerStyle: { flex: "" }
		},
		{
			id: evaluation.eval_type === "PRO_PITCH" ? "pitchability_present" : "can_he_play_present",
			type:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? "autocomplete"
					: "label",
			options: GRADE_OPTS_PRO,
			numCharToShowSuggestions: 0,
			partialWidth: 3,
			style: { maxWidth: "75px" },
			containerStyle: { display: "inline-flex", maxWidth: "100px" },
			placeholder: "Present",
			preventMobileBreak: true,
			value:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? evaluation[evaluation.eval_type === "PRO_PITCH" ? "pitchability_present" : "can_he_play_present"]
					: ""
		},
		{
			id: evaluation.eval_type === "PRO_PITCH" ? "pitchability_future" : "can_he_play_future",
			type:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? "autocomplete"
					: "label",
			options: GRADE_OPTS_PRO,
			numCharToShowSuggestions: 0,
			partialWidth: 3,
			style: { maxWidth: "75px" },
			containerStyle: { display: "inline-flex", maxWidth: "100px" },
			placeholder: "Future",
			value:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? evaluation[evaluation.eval_type === "PRO_PITCH" ? "pitchability_future" : "can_he_play_future"]
					: ""
		},
		{
			id: evaluation.eval_type === "PRO_PITCH" ? "pitchability_comments" : "can_he_play_comments",
			type:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? "text"
					: "label",
			partialWidth: 3,
			placeholder: "Comments",
			multiline: true,
			value:
				evaluation.ofp == null || evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
					? evaluation[
							evaluation.eval_type === "PRO_PITCH" ? "pitchability_comments" : "can_he_play_comments"
					  ]
					: ""
		}
	]);

	return {
		title: "Tools Grading Standards",
		mobileTab: "Tools",
		sectionDirection: "vertical",
		labelWidth: 152,
		sections: sections.map(section => {
			return {
				objects: section
			};
		}),
		displayComponent: <ProPitcherToolsTable evaluation={evaluation} />,
		show: evaluation.eval_type === "PRO_PITCH",
		startAdornment
	};
};

const getRecentStatsComponent = player => {
	return {
		show: player.player_classification === "pro",
		componentOnly: true,
		displayComponent: (
			<RecentStatistics
				player={player}
				showCareerRows={false}
				showControls={false}
				summary={true}
				colorScheme={defaultColorScheme}
			/>
		)
	};
};

// eslint-disable-next-line no-unused-vars
class ProEval extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	onSave = valueRaw => {
		const { evaluation } = this.props;
		const value = { ...valueRaw };

		if (value.id === "oth_pitch_type") {
			this.setState({ extraPitch: value.value });
		}

		if (value.id === "height" && value.value != null) {
			value.value = parseInt(value.value.substring(0, 1), 10) * 12 + parseInt(value.value.substring(1), 10);
		}

		if (value.id === "seen_date") value.value = dayjs(value.value).format("YYYY-MM-DD HH:mm:ss");

		if (
			value.id === "summary" &&
			value.value != null &&
			typeof value.value === "object" &&
			typeof value.value !== "string"
		) {
			value.value = convertToRaw(value.value.getCurrentContent())
				.blocks.map(block => block.text)
				.join(" ")
				.replace(/  +/g, " ");
		}

		if (value.value === "") {
			// Null cleared values
			value.value = null;
		}

		if (evaluation[value.id] !== value.value) {
			// Create Update Object
			const evaluationUpdateObject = {
				eval_create_user_id: evaluation.eval_create_user_id,
				eval_id: evaluation.eval_id,
				eval_type: evaluation.eval_type,
				phil_id: evaluation.phil_id,
				[value.id]: value.value
			};

			if (value.id === "summary") {
				// eslint-disable-next-line no-useless-escape
				let snapshot = value.value ? value.value.match(/^.*?([\.!\?]|$)(?:\s|$)/g) : null;
				if (snapshot && snapshot.length > 0) snapshot = snapshot[0].substring(0, 250).trim();
				evaluationUpdateObject["current_snapshot"] = snapshot;
			}

			if (value.id === "ofp") {
				evaluationUpdateObject["category"] = getProspectTypePro(value.value, evaluation);
				if (evaluationUpdateObject["category"] === "INJ") {
					this.setState({ showingInjMessage: true });
				} else if (evaluationUpdateObject["category"] === "DNS") {
					this.setState({ showingDnsMessage: true });
				}
			}
			if (value.id === "team_bam_id") {
				evaluationUpdateObject["level_reported"] = value.level;
			}
			this.props.updatePlayerEval(evaluationUpdateObject);
		}
	};

	triggerSave = () => {
		return this.form.getWrappedInstance().triggerSave();
	};

	getStartAdornmentModal = type => {
		return (
			<div>
				<Info
					onClick={() => this.setState({ [type]: true })}
					style={{ cursor: "pointer", marginRight: "5px" }}
				/>
				<Dialog
					maxWidth={"lg"}
					onClose={() => this.setState({ [type]: false })}
					open={Boolean(this.state[type])}
				>
					{type === "grade" && <ProGradeTooltip />}
					{type === "snapshot-player" && <ProPlayerSnapshotTooltip />}
					{(type === "offense" || type === "defense") && <ProPlayerToolsTooltip type={type} />}
					{type === "snapshot-pitcher" && <ProPitcherSnapshotTooltip />}
					{(type === "player" || type === "pitcher") && <ProMiscTooltip type={type} />}
					{type === "pitcher-tools" && <ProPitcherToolsTooltip />}
					<Button
						onClick={() => this.setState({ [type]: false })}
						style={{ margin: "15px" }}
						variant={"contained"}
					>
						Close
					</Button>
				</Dialog>
			</div>
		);
	};

	getStartAdornmentToolTip = content => {
		return (
			<div>
				<Tooltip placement="top" title={content}>
					<div>
						<Info style={{ cursor: "pointer", marginRight: "5px" }} />
					</div>
				</Tooltip>
			</div>
		);
	};

	clearExistingFields = reportCategory => {
		const { player, evaluation } = this.props;
		const evalFields = Object.keys(evaluation);
		const nonCopiedFields = ["player", "player_ref"];
		const clearedEval = generateProEval(
			player,
			evalFields.reduce((newEval, field) => {
				if (!nonCopiedFields.includes(field)) newEval[field] = null;
				return newEval;
			}, {})
		);
		const copiedFields = ["eval_id", "eval_create_user_id"];
		for (let i = 0; i < copiedFields.length; i++) {
			const field = copiedFields[i];
			clearedEval[field] = evaluation[field];
		}
		if (reportCategory === "INJ") {
			clearedEval["category"] = "INJ";
			clearedEval["ofp"] = 20;
		} else if (reportCategory === "DNS") {
			clearedEval["category"] = "DNS";
			clearedEval["ofp"] = 30;
		}
		clearedEval["phil_id"] = evaluation.phil_id;
		delete clearedEval["player"];
		delete clearedEval["player_ref"];
		this.props.updatePlayerEval(clearedEval);

		this.setState({ showingInjMessage: false, showingDnsMessage: false });
	};

	render() {
		const {
			evaluation,
			mostRecentEvalOppositeType,
			userId,
			player,
			teams,
			deletePlayerEval,
			updatePlayerEval,
			isEvalSaving,
			windowWidth,
			isEvalManager,
			reportEvalPublishError,
			addListPlayerOnPublish,
			createPlayerEval,
			nextEval,
			previousEvals,
			nextEvalScout,
			scout,
			getEvalForCreate,
			scoutLastName,
			getPlayerScoutEvalsFromEvalId,
			enqueueSnackbar,
			closeSnackbar
		} = this.props;
		const { showingInjMessage, showingDnsMessage } = this.state;

		const canEdit =
			evaluation != null
				? (evaluation.eval_create_user_id === userId && evaluation.publish_date == null) || isEvalManager
				: false;
		const editing =
			evaluation != null ? evaluation.eval_create_user_id === userId && evaluation.publish_date == null : false;
		// const evalYear =
		// 	evaluation != null
		// 		? evaluation.seen_date != null
		// 			? evaluation.seen_date.year()
		// 			: evaluation.eval_create_date.year()
		// 		: dayjs().year();
		const teamOptions = Object.keys(teams)
			// .filter(
			// 	team =>
			// 		player.org_bam_id == null ||
			// 		teams[team].org_id === player.org_bam_id ||
			// 		evalYear !== dayjs().year() ||
			// 		(evaluation != null && parseInt(team, 10) === evaluation.team_bam_id)
			// )
			.map(team => ({
				text: teams[team].name,
				value: teams[team].bam_id,
				level: teams[team].level
			}));
		const teamLevel =
			evaluation != null && teams[evaluation.team_bam_id] != null ? teams[evaluation.team_bam_id].level : "";

		const agent =
			player.agent != null
				? player.agency != null
					? `${player.agent} (${player.agency})`
					: `${player.agent}`
				: "None";
		// let scout =
		// 	evaluation.scout != null
		// 		? `${evaluation.scout.first_name} ${evaluation.scout.last_name}`
		// 		: "";
		const team = player != null && player._team != null ? getTeamName(player._team) : "";
		const currentTeamLevel = player != null && player._team != null ? player._team.level : "";

		const org = player != null && player._org != null ? player._org.abbreviation : "";

		const gradeAdornment = this.getStartAdornmentModal("grade");

		const offensiveToolsAdornment = this.getStartAdornmentModal("offense");
		const defensiveToolsAdornment = this.getStartAdornmentModal("defense");

		const miscAdornment =
			evaluation != null
				? this.getStartAdornmentModal(evaluation.eval_type === "PRO_HIT" ? "player" : "pitcher")
				: null;

		const pitcherToolsAdornment = this.getStartAdornmentModal("pitcher-tools");

		const configuration =
			evaluation != null
				? {
						style: {},
						groups: [
							BIO_FIELDS(evaluation, player, team, currentTeamLevel, org, agent, scout, gradeAdornment),
							DETAILS_FIELDS(evaluation, teamOptions, teamLevel, player),
							OFFENSE_FIELDS(evaluation, offensiveToolsAdornment),
							OFFENSE_SUMMARY(evaluation, isMobile(windowWidth)),
							DEFENSE_FIELDS(evaluation, defensiveToolsAdornment),
							DEFENSE_SUMMARY(evaluation, isMobile(windowWidth)),
							TOOLS_GRADING_STANDARDS_PITCH(evaluation, pitcherToolsAdornment),
							MISC_FIELDS(evaluation, player, miscAdornment),
							evaluation.eval_type === "PRO_HIT" ? SNAP_SUM_HIT(evaluation) : SNAP_SUM_PITCH(evaluation),
							getRecentStatsComponent(player)
						]
				  }
				: null;

		const author = scout;

		return (
			<div>
				<ProEvalHeader
					author={author}
					evaluation={evaluation}
					key={"header"}
					player={player}
					teams={teams}
					windowWidth={windowWidth}
				/>
				<div
					key={"form"}
					style={{
						paddingBottom: "65px"
					}}
				>
					<Form
						configuration={configuration}
						dataSource={evaluation}
						editing={editing}
						onSave={this.onSave}
						ref={node => (this.form = node)}
						showEditButtons={canEdit}
					/>
				</div>
				{evaluation != null && (
					<ProEvalFooter
						closeSnackbar={closeSnackbar}
						createPlayerEval={createPlayerEval}
						deletePlayerEval={deletePlayerEval}
						enqueueSnackbar={enqueueSnackbar}
						evaluation={evaluation}
						getEvalForCreate={getEvalForCreate}
						getPlayerScoutEvalsFromEvalId={getPlayerScoutEvalsFromEvalId}
						isEvalManager={isEvalManager}
						key={"footer"}
						mostRecentEvalOppositeType={mostRecentEvalOppositeType}
						nextEval={nextEval ? nextEval : {}}
						nextEvalScout={nextEvalScout ? nextEvalScout : {}}
						player={player}
						previousEvals={previousEvals}
						previousEvalScout={nextEvalScout ? nextEvalScout : {}}
						reportEvalPublishError={reportEvalPublishError}
						scoutLastName={scoutLastName}
						triggerSave={this.triggerSave}
						addListPlayerOnPublish={addListPlayerOnPublish}
						updatePlayerEval={updatePlayerEval}
						isEvalSaving={isEvalSaving}
						userId={userId}
						windowWidth={windowWidth}
					/>
				)}
				<Dialog
					maxWidth={"sm"}
					onClose={() => this.setState({ showingInjMessage: false, showingDnsMessage: false })}
					open={Boolean(showingInjMessage) || Boolean(showingDnsMessage)}
				>
					<div style={{ padding: "15px" }}>
						{`It looks like you're writing ${
							showingInjMessage ? "an injury" : "a did not see"
						} report. Would you like to clear the existing fields from this report? (This cannot be un-done)`}
					</div>
					<Button
						color="secondary"
						onClick={() => this.clearExistingFields(showingInjMessage ? "INJ" : "DNS")}
						style={{ margin: "15px" }}
						variant={"contained"}
					>
						Clear Existing Fields
					</Button>
					<Button
						onClick={() => this.setState({ showingInjMessage: false, showingDnsMessage: false })}
						style={{ margin: "15px" }}
						variant={"contained"}
					>
						Close
					</Button>
				</Dialog>
			</div>
		);
	}
}

ProEval.propTypes = {
	addListPlayerOnPublish: PropTypes.func,
	closeSnackbar: PropTypes.func,
	createPlayerEval: PropTypes.func,
	deletePlayerEval: PropTypes.func,
	evaluation: PropTypes.object,
	getEvalForCreate: PropTypes.func,
	getPlayerScoutEvalsFromEvalId: PropTypes.func,
	isEvalManager: PropTypes.bool,
	mostRecentEvalOppositeType: PropTypes.object,
	nextEval: PropTypes.object,
	nextEvalScout: PropTypes.object,
	player: PropTypes.object,
	previousEvals: PropTypes.object,
	reportEvalPublishError: PropTypes.func,
	scout: PropTypes.string,
	scoutLastName: PropTypes.string,
	teams: PropTypes.object,
	updatePlayerEval: PropTypes.func,
	isEvalSaving: PropTypes.bool,
	userId: PropTypes.number,
	windowWidth: PropTypes.number,
	enqueueSnackbar: PropTypes.func
};

export default ProEval;
