import React, { useState, FunctionComponent, CSSProperties } from "react";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup, defaultColorScheme, BLACKGRAY } from "_react/shared/legacy/ui/Colors";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import ExpandLess from "_react/shared/legacy/ui/icons/ExpandLess";
import ExpandMore from "_react/shared/legacy/ui/icons/ExpandMore";

import {
	CONTRACT_COVENANTS_COLUMNS,
	CONTRACT_ASSIGNMENT_BONUSES_COLUMNS,
	CONTRACT_ASSIGNMENT_BONUSES_EARNED_COLUMNS
} from "_react/playerpage/contract/_helpers";
import {
	IContract as IContractMajors,
	IContractCovenant,
	IContractCovenantAssignmentBonus
} from "_react/shared/_types/schema/contracts";
import { FlexDiv, LeftTableContainer, TableText, ExpandStyle } from "_react/playerpage/contract/_styles";

type TContractCovenantsStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

type ContractCovenantsProps = {
	colorScheme?: ColorSchemeGroup;
	style?: TContractCovenantsStyle;
	contract: IContractMajors;
};

export const ContractCovenants: FunctionComponent<ContractCovenantsProps> = ({
	colorScheme = defaultColorScheme,
	style,
	contract
}) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [selectedCovenant, setSelectedCovenant] = useState<IContractCovenant>();
	const [selectedAssignmentBonus, setSelectedAssignmentBonus] = useState<IContractCovenantAssignmentBonus>();
	const { contract_mj_covenants } = contract;

	return (
		<Card style={style?.card}>
			<ColoredCardHeader
				colorScheme={contract_mj_covenants?.length ? colorScheme.secondary : BLACKGRAY.secondary}
				text={
					<FlexDiv>
						{contract_mj_covenants?.length ? (
							isExpanded ? (
								<ExpandMore style={ExpandStyle} fill={"white"} />
							) : (
								<ExpandLess style={ExpandStyle} fill={"white"} />
							)
						) : (
							undefined
						)}{" "}
						Covenants
					</FlexDiv>
				}
				onClick={() => (contract_mj_covenants?.length ? setIsExpanded(!isExpanded) : null)}
				style={style?.cardHeader}
			></ColoredCardHeader>
			{isExpanded && (
				<>
					<AutoFitTable
						columns={CONTRACT_COVENANTS_COLUMNS()}
						defaultSortColumn={"Year"}
						defaultSortState={"desc"}
						highlightIndexFunc={(rows: Array<IContractCovenant>) =>
							rows.findIndex(
								(row: IContractCovenant) => row.covenant_id === selectedCovenant?.covenant_id
							)
						}
						onRowClick={(row: IContractCovenant) => {
							setSelectedCovenant(row);
							setSelectedAssignmentBonus(undefined);
						}}
						rows={contract_mj_covenants}
						rowKey="covenant_id"
					/>
					<FlexDiv>
						<LeftTableContainer>
							<AutoFitTable
								columns={CONTRACT_ASSIGNMENT_BONUSES_COLUMNS()}
								defaultSortColumn={"Year"}
								defaultSortState={"desc"}
								highlightIndexFunc={(rows: Array<IContractCovenantAssignmentBonus>) =>
									rows?.findIndex(
										(row: IContractCovenantAssignmentBonus) =>
											row.assignment_bonus_id === selectedAssignmentBonus?.assignment_bonus_id
									)
								}
								onRowClick={(row: IContractCovenantAssignmentBonus) => setSelectedAssignmentBonus(row)}
								rows={selectedCovenant?.assignment_bonuses}
								rowKey="assignment_bonus_id"
							/>
							{!selectedCovenant && <TableText>Select a covenant to see assignment bonuses...</TableText>}
							{selectedCovenant && !selectedCovenant?.assignment_bonuses?.length && (
								<TableText>No assignment bonuses</TableText>
							)}
						</LeftTableContainer>
						<div>
							<AutoFitTable
								columns={CONTRACT_ASSIGNMENT_BONUSES_EARNED_COLUMNS()}
								defaultSortColumn={"Year"}
								defaultSortState={"desc"}
								rows={selectedAssignmentBonus?.earned_assignment_bonuses}
								rowKey="assignment_bonus_earned_id"
							/>
							{!selectedAssignmentBonus && (
								<TableText>Select an assignment bonus to see assignment bonuses earned...</TableText>
							)}
							{selectedAssignmentBonus && !selectedAssignmentBonus?.earned_assignment_bonuses?.length && (
								<TableText>No assignment bonuses earned</TableText>
							)}
						</div>
					</FlexDiv>
				</>
			)}
		</Card>
	);
};
