import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function ArrowDropDown({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path d="M7 10l5 5 5-5z" fill={fill} />
			<path d="M0 0h24v24H0z" fill={"none"} />
		</IconSVG>
	);
}
