import React from "react";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import RockyNotes from "_react/playerpage/notes";

type TNotesTabProps = {
	player: TPlayerPageCombinedPlayer;
};

const NotesTab = ({ player }: TNotesTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	return <RockyNotes colorScheme={chakraColorSchemeGroup} player={player} />;
};

export default NotesTab;
