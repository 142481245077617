import React, { ReactNode } from "react";
import { Text, VStack, Tooltip, Flex, useToken, PlacementWithLogical } from "@chakra-ui/react";

import { convertRemTokenToPixelInt } from "_react/shared/_helpers/chakra";
import GradeDistributionPlot from "_react/shared/ui/presentation/plots/GradeDistributionPlot/GradeDistributionPlot";
import { ITwentyEightyGradeDistribution } from "_react/shared/data_models/dataviz/_types";
import { TGradeThresholdIconProps } from "_react/shared/ui/presentation/components/GradeThresholdIcon/GradeThresholdIcon";
import PlotStatLabel from "_react/shared/ui/presentation/components/PlotStatLabel/PlotStatLabel";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";

import { GRADE_DISTRIBUTION_PLOT_PROPS } from "_react/shared/ui/data/plots/PositionStatDistributions/_constants";
import { ICON_DANGEROUS } from "_react/shared/ui/presentation/components/GradeThresholdIcon/_constants";

type TPositionStatDistributionPlotProps = {
	plotLabel: string;
	value?: number | null;
	valueLabel?: number | string | null;
	distributionData?: Array<ITwentyEightyGradeDistribution> | null;
	thresholdIcon?: TGradeThresholdIconProps;
	tooltipIcon?: ReactNode;
	tooltipLabel?: string;
	tooltipPlacement?: PlacementWithLogical;
};
const PositionStatDistributionPlot = ({
	plotLabel,
	value,
	valueLabel,
	distributionData,
	thresholdIcon,
	tooltipLabel,
	tooltipPlacement = "top",
	tooltipIcon = <OutlineInfo />
}: TPositionStatDistributionPlotProps) => {
	// `GradeDistributionPlot` needs Chakra tokens converted to pixel values
	const [size20, size40] = useToken("sizes", [20, 40]);
	return (
		<VStack alignItems="start" gap="0">
			<Flex alignItems="center">
				<Text fontWeight="bold" fontSize="md">
					{plotLabel}
				</Text>
				{tooltipLabel && (
					<Tooltip hasArrow placement={tooltipPlacement} label={tooltipLabel} whiteSpace="pre-wrap">
						<Flex align="center" marginLeft="1" color="gray.500">
							{tooltipIcon}
						</Flex>
					</Tooltip>
				)}
			</Flex>
			<VStack alignItems="start" gap="0" border="1px solid" borderColor="gray.300" borderRadius="lg" padding="7">
				<PlotStatLabel
					value={thresholdIcon?.shape !== ICON_DANGEROUS ? valueLabel ?? value ?? "-" : ""}
					thresholdIcon={thresholdIcon}
					style={{ container: { marginBottom: "2" }, value: { minHeight: 8 } }}
				/>
				<GradeDistributionPlot<ITwentyEightyGradeDistribution>
					{...GRADE_DISTRIBUTION_PLOT_PROPS}
					distributionData={distributionData ?? undefined}
					playerValue={thresholdIcon?.shape !== ICON_DANGEROUS ? value : null}
					width={convertRemTokenToPixelInt(size40)}
					height={convertRemTokenToPixelInt(size20)}
				/>
			</VStack>
		</VStack>
	);
};

export default PositionStatDistributionPlot;
