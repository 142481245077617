import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const offensiveFields = [
	{
		title: "Hitting Ability",
		overall: "How good of a hitter will the player be? Please refer to the Hitting scale."
	},
	{
		title: "AB Quality",
		overall: "How good are the players at-bats? How well does he know the strike zone and compete at the plate?"
	},
	{
		title: "Raw Power",
		overall:
			"How much power does this player possess? Simply how far can he hit a ball. Please refer to the HR scale."
	},
	{
		title: "Power Production",
		overall:
			"Projection on how many homeruns the player will hit in the ML now and in the future. This grade should correspond to the HR scale and should take into consideration both the Hitting Ability and Raw Power."
	},
	{
		title: "Type of Hitter",
		overall:
			"A description of the player’s offensive approach in a word or two. The choices will be in a drop down list."
	},
	{
		title: "Run Speed",
		overall:
			"How fast is this runner? The grade is subjective and should not be tied solely to his time from home plate to 1B."
	},
	{
		title: "Baserunning",
		overall: "How good of a baserunner is this player? How instinctual, aggressive and smart is he on the bases?"
	}
];

const defensiveFields = [
	{
		title: "Position in ML",
		overall:
			"The present box should be current position the player is playing. The future box is where you feel he will play in the Major Leagues."
	},
	{
		title: "Arm Strength",
		overall:
			"How well a position players arm plays from the position he was observed at. It takes into account velocity and the carry he gets on his throws."
	},
	{
		title: "Arm Accuracy",
		overall: "How accurate are infielders and outfielders throws?"
	},
	{
		title: "Hands",
		overall:
			"This grade represents how good the players hands are when playing the position being reported on. How reliable and confident are they at catching the ball?"
	},
	{
		title: "Receiving",
		overall:
			"(catchers only) This grade represents how well the catcher receives. How good and strong are his hands and how well does he present the pitch?"
	},
	{
		title: "Range",
		overall:
			"This grade represents how well a player moves on defense. For infielders and outfielders, what type of first step do they have and how much ground do they cover? How well or quickly do they get to balls?"
	},
	{
		title: "Blocking",
		overall:
			"(catchers only) This grade represents how well and quickly a catcher shifts, blocks, and controls pitches in the dirt."
	},
	{
		title: "Overall Defense",
		overall:
			"This grade should take into account a players arm strength, accuracy, hands and range to determine what type of overall defender he is or will be."
	},
	{
		title: "Instincts",
		overall: "How much natural feel does the player have to play the game and his position?"
	},
	{
		title: "Athleticism",
		overall: "What kind of natural athlete is this player?"
	}
];

const stats = {
	columns: [
		"Pos",
		"G",
		"PA",
		"2B",
		"3B",
		"HR",
		"SB",
		"AVG",
		"OBP",
		"SLG",
		"OPS",
		"ISO",
		"wOBA",
		"pRC+",
		"Hit",
		"Pwr",
		"Off Total"
	],
	rows: [
		[
			"C",
			"125",
			"471",
			"23",
			"1",
			"16",
			"1",
			".251",
			".323",
			".419",
			".742",
			".169",
			".322",
			"95",
			"45",
			"50",
			"95"
		],
		[
			"1B",
			"148",
			"608",
			"29",
			"1",
			"25",
			"2",
			".265",
			".352",
			".460",
			".812",
			".195",
			".349",
			"113",
			"50",
			"60",
			"110"
		],
		[
			"2B",
			"149",
			"630",
			"30",
			"3",
			"18",
			"8",
			".272",
			".338",
			".444",
			".782",
			".167",
			".338",
			"105",
			"55",
			"50",
			"105"
		],
		[
			"3B",
			"150",
			"622",
			"32",
			"2",
			"24",
			"3",
			".272",
			".341",
			".472",
			".813",
			".200",
			".348",
			"111",
			"55",
			"55",
			"110"
		],
		[
			"SS",
			"146",
			"583",
			"28",
			"3",
			"15",
			"9",
			".263",
			".320",
			".415",
			".735",
			".152",
			".319",
			"93",
			"50",
			"45",
			"95"
		],
		[
			"LF",
			"145",
			"609",
			"28",
			"2",
			"21",
			"8",
			".271",
			".338",
			".465",
			".802",
			".194",
			".343",
			"111",
			"55",
			"55",
			"110"
		],
		[
			"CF",
			"140",
			"584",
			"24",
			"4",
			"16",
			"14",
			".266",
			".329",
			".430",
			".759",
			".164",
			".332",
			"101",
			"50",
			"50",
			"100"
		],
		[
			"RF",
			"146",
			"593",
			"29",
			"2",
			"22",
			"5",
			".263",
			".340",
			".459",
			".799",
			".196",
			".347",
			"111",
			"50",
			"55",
			"105"
		]
	]
};

const ProPlayerToolsTooltip = ({ type }) => {
	const fields = type === "offense" ? offensiveFields : defensiveFields;

	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<h1
					style={{
						textAlign: "center",
						color: defaultColorScheme.group.backgroundColor,
						fontSize: "1.2em",
						fontWeight: "bold",
						marginTop: "13px",
						marginBottom: "13px"
					}}
				>
					POSITION PLAYER {type === "offense" ? "OFFENSIVE" : "DEFENSIVE"} TOOLS
				</h1>
				<div>
					{fields.map((field, idx) => {
						return (
							<div key={idx} style={{ marginBottom: "10px" }}>
								<span
									style={{
										fontSize: "1em",
										color: "#f44335",
										fontWeight: "700"
									}}
								>
									{field.title}
								</span>
								<br />
								<div
									style={{
										borderBottom: "1px solid gray",
										paddingBottom: "10px",
										fontSize: "0.85em"
									}}
								>
									<span>{field.overall}</span>
								</div>
							</div>
						);
					})}
					<div style={{ marginBottom: "10px" }}>
						<span
							style={{
								fontSize: "1em",
								color: "#f44335",
								fontWeight: "700"
							}}
						>
							Position Player OFP’s
						</span>
						<br />
						<span style={{ fontSize: "0.85em" }}>
							When determining what your OFP will be, keep in mind the stat lines below. If you deem a
							Major League player to be an average ML player his offensive total, which is the sum of the
							future hit and power grades, should fall somewhere near the offensive total number
							associated with each position. Obviously, if your future grades add up to more than that
							number he is a better than average offensive player. In addition, keep in mind that the
							defense, speed, instincts and plate discipline can have a positive or negative impact on
							what you eventually settle on as an OFP. For example, if you have a catcher whose future
							offensive grades add up to an 85, he potentially can be an average player if you feel the
							defense is good enough to make up the difference. Conversely, if you have a 3B whose future
							offensive grades add up to 115 but his defense is liability, he potentially could be a
							Marginal player.
						</span>
					</div>
					<div
						style={{
							overflow: "scroll"
						}}
					>
						<Table style={{ marginTop: "25px" }}>
							<TableHead
								style={{
									backgroundColor: defaultColorScheme.group.backgroundColor
								}}
							>
								<TableRow>
									<TableCell
										colSpan={stats.columns.length}
										style={{
											color: defaultColorScheme.group.color,
											fontSize: "16px",
											textAlign: "center"
										}}
									>
										Average Full Season Offensive Production for Major League starting players by
										position from 2016-2018
									</TableCell>
								</TableRow>
								<TableRow>
									{stats.columns.map((column, idx) => {
										return (
											<TableCell
												key={idx}
												style={{
													color: defaultColorScheme.group.color,
													textAlign: "center",
													textTransform: "capitalize"
												}}
											>
												{column}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody
								style={{
									border: "1px solid #dfe0df"
								}}
							>
								{stats.rows.map((row, idx) => {
									return (
										<TableRow key={idx}>
											{row.map((cell, idx) => {
												return (
													<TableCell
														key={idx}
														style={{
															textAlign: "center",
															fontWeight: idx === 0 ? "700" : ""
														}}
													>
														{cell}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</div>
				</div>
			</div>
		</div>
	);
};

ProPlayerToolsTooltip.propTypes = {
	type: PropTypes.string
};

export default ProPlayerToolsTooltip;
