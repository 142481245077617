import { CSSObjectWithLabel } from "react-select";

import { StylesConfig } from "react-select";
import { $TSFixMe } from "utils/tsutils";
import { TOptions, TOptionOrGroup, TBaseOption, TValueConstraint, TOption } from "_react/inputs/_types";

function isGroupType(optionOrGroup: $TSFixMe) {
	return optionOrGroup.hasOwnProperty("options");
}

export function getOptionsFlattened<TOption>(options: TOptions<TOption>) {
	const optionsFlat: TOption[] = [];
	options.forEach((optionOrGroup: TOptionOrGroup<TOption>) => {
		if (isGroupType(optionOrGroup)) {
			optionOrGroup.options.forEach((option: TOption) => {
				optionsFlat.push(option);
			});
		} else {
			optionsFlat.push(optionOrGroup as TOption);
		}
	});
	return optionsFlat;
}

// Allows passing in the selected value or array of values into a react-select instead of needing to pass in the
// whole option or array of options.
// It works even if the options are grouped options like in the ScoutSelect
// See ScoutSelect or LkSelect for an example
export function getOptsFromValues<
	TValue extends TValueConstraint,
	TOption extends TBaseOption<TValue> = TBaseOption<TValue>
>(values: TValue[] | TValue | null, options: TOptions<TOption>) {
	if (values == null) return null;
	const optionsFlat = getOptionsFlattened<TOption>(options);
	if (Array.isArray(values)) {
		const valuesSet = new Set(values);
		const addedSet = new Set<TValue>();
		const selected: TOption[] = [];
		optionsFlat.forEach(option => {
			if (valuesSet.has(option.value) && !addedSet.has(option.value)) {
				selected.push(option);
				addedSet.add(option.value);
			}
		});
		return selected;
	}
	for (const option of optionsFlat) {
		if (values === option.value || `${values}` === option.value || values === `${option.value}`) return option;
	}
	// Also check the label
	for (const option of optionsFlat) {
		if (values === option.label) return option;
	}
	return null;
}

export const getNameLabel = (firstName?: string | null, lastName?: string | null, shorten = false) => {
	let firstNameDisplay = "";
	if (firstName != null) {
		firstNameDisplay = shorten ? `${firstName.charAt(0)}.` : firstName;
	}

	const lastNameDisplay = lastName || "";

	const space = lastNameDisplay === "" || firstNameDisplay === "" ? "" : " ";

	return `${firstNameDisplay}${space}${lastNameDisplay}`;
};

export const DEFAULT_STYLES = {
	control: (provided: CSSObjectWithLabel) => ({
		...provided,
		minHeight: 24,
		fontSize: 11
	}),
	dropdownIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0,
		width: 15
	}),
	indicatorsContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0,
		height: 24
	}),
	indicatorSeparator: () => ({}),
	menu: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontSize: 11
	}),
	menuPortal: (provided: CSSObjectWithLabel) => ({
		...provided,
		zIndex: 1003
	}),
	multiValue: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0,
		fontSize: 11
	}),
	multiValueLabel: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 3
	}),
	multiValueRemove: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0
	}),
	valueContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		minHeight: 24
	}),
	clearIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0
	})
};

export const playerSearchStyles: (color: string) => StylesConfig<TOption<number>, boolean> = color => ({
	control: (provided: CSSObjectWithLabel, state) => ({
		display: "flex",
		transition: "all 100ms",
		fontSize: 17,
		borderBottom: `1px solid ${color}`,
		marginBottom: "2px",
		opacity: state.isDisabled ? 0.33 : 1
	}),
	input: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontSize: 17,
		color: color,
		padding: 0
	}),
	loadingIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: color
	}),
	option: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: "black",
		cursor: "pointer"
	}),
	placeholder: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: color,
		fontSize: 17,
		fontWeight: 400,
		opacity: 0.5
	}),
	singleValue: (provided: CSSObjectWithLabel, state) => ({
		...provided,
		display: state.selectProps.menuIsOpen ? "none" : "block",
		color: color,
		fontSize: 17
	}),
	menuPortal: (provided: CSSObjectWithLabel) => ({
		...provided,
		zIndex: 1005
	}),
	valueContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontWeight: 400
	})
});

export type TYearOpt = TOption<number>;

export function makeYearOpts(startYear: number, numOpts: number): TYearOpt[] {
	return [...Array(numOpts).keys()].map((offset: number) => {
		const year = startYear + offset;
		return {
			label: `${year}`,
			value: year
		};
	});
}
