import styled from "@emotion/styled";

export const ProjectionValueContainer = styled.div({
	display: "flex"
});

export const ProjectionValueText = styled.h1({
	flexGrow: 1,
	textAlign: "center",
	marginTop: "19px",
	marginBottom: "19px",
	fontWeight: "bold",
	fontSize: "28px"
});

export const SurplusValueTextNoValue = styled.h3({
	flexGrow: 1,
	textAlign: "center",
	color: "gray",
	fontStyle: "italic"
});

export const SurplusValueLoading = styled.h3({
	flexGrow: 1,
	height: "33px",
	margin: "18px"
});
