import React, { FC } from "react";

import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import AmaBio from "_react/playerpage/bio/AmaBio";
import { TAmaBioDocument } from "_react/playerpage/bio/_types";

type TBioTabProps = {
	bioDisplayOnly: boolean;
	amaBioDocument: TAmaBioDocument | null;
	setAmaBioDocument: (newDocument: TAmaBioDocument) => void;
};

const BioTab: FC<TBioTabProps> = ({ bioDisplayOnly, amaBioDocument, setAmaBioDocument }) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	// Override to not mess with existing colors
	chakraColorSchemeGroup.primary = chakraColorSchemeGroup.secondary;

	return (
		<AmaBio
			colorScheme={chakraColorSchemeGroup}
			bioDisplayOnly={bioDisplayOnly}
			amaBioDocument={amaBioDocument}
			setAmaBioDocument={setAmaBioDocument}
		/>
	);
};

export default BioTab;
