import React from "react";
import dayjs from "dayjs";

import { $TSFixMePlayer } from "utils/tsutils";
import { getPriorOutright } from "utils/functions";
import { getAgeFromBirthDate } from "utils/helpers";
import { ILoginSnake } from "_react/shared/_types/schema/login";

import {
	PRO_PROFILE_OPTS_VIEW,
	COMFORT_LEVEL_OPTS,
	RISK_OPTS,
	ARM_ANGLE_OPTS,
	ARM_SPEED_OPTS,
	HITTING_RISK_OPTIONS
} from "_react/evals/_constants";
import { NULL_TEXT, FB, CH, CB, SL, OTH, OTH_SECOND } from "_react/evals/list/_constants";
import {
	TEval,
	TEvalProPitch,
	TEvalAmaHit,
	TEvalAmaPitch,
	TEvalIntlHit,
	TEvalIntlPitch
} from "_react/evals/list/_types";

export const getPlayerName = (player: $TSFixMePlayer) => {
	return player ? `${player?.first_name ?? player?.first_name_legal} ${player?.last_name}` : "";
};

export const getPlayerR5Eligible = (player: $TSFixMePlayer, showYear = true) => {
	return player.r5_eligible_year && player.r5_eligible_year <= dayjs().year() && player.on_40_man_roster === 0
		? `Yes${player.r5_eligible_year != null && showYear ? ` (${player.r5_eligible_year})` : ""}`
		: "No";
};

export const getPlayerR5 = (player: $TSFixMePlayer) => {
	return player ? getPlayerR5Eligible(player) : NULL_TEXT;
};

export const getPlayerPrior = (player: $TSFixMePlayer) => {
	return player ? getPriorOutright(player) : NULL_TEXT;
};

const getDateOfBirth = (player: $TSFixMePlayer) => {
	return player.player_classification === "pro" || player.date_of_birth == null
		? player.birth_date
		: player.date_of_birth;
};

export const getPlayerAge = (player: $TSFixMePlayer) => {
	return player ? getAgeFromBirthDate(getDateOfBirth(player)) : NULL_TEXT;
};

export const getPlayerMls = (player: $TSFixMePlayer) => {
	return player?.mls_current ? player.mls_current.toFixed(3) : NULL_TEXT;
};

export const getPlayerFortyMan = (player: $TSFixMePlayer) => {
	return player?.on_40_man_roster === 1 ? "Yes" : player?.on_40_man_roster === 0 ? "No" : NULL_TEXT;
};

export const getPlayerOrgTeam = (player: $TSFixMePlayer) => {
	if (!player) return NULL_TEXT;
	else if (player?.org && player?.team) return `${player?.org}: ${player?.team?.name}`;
	else if (player?.org) return player.org;
	else if (player?.team?.name) return player.team.name;
	else return NULL_TEXT;
};

export const getPlayerProfile = (evaluation: TEval) => {
	const option = PRO_PROFILE_OPTS_VIEW.find(opt => opt.value === evaluation.profile);
	return option?.abbreviation ?? option?.text ?? evaluation.profile ?? NULL_TEXT;
};

export const getScoutName = (scout?: ILoginSnake) => {
	return scout ? `${scout.first_name} ${scout.last_name}` : NULL_TEXT;
};

export const getAgentName = (player: $TSFixMePlayer) => {
	return player?.agent?.last_name
		? `${player.agent.firstName ? `${player.agent.firstName} ` : ``}${player.agent.lastName}`
		: NULL_TEXT;
};

export const getVelocity = (low: number | undefined, high: number | undefined) => {
	if (low && high) return `${low}-${high}`;
	else if (low) return `${low}`;
	else if (high) return `${high}`;
	else return NULL_TEXT;
};

export const getOfp = (ofp: number | null | undefined) => {
	if (!ofp) return NULL_TEXT;
	switch (ofp) {
		case 30:
			return "D";
		case 25:
			return "L";
		case 20:
			return "I";
		case 0:
			return "NP";
		default:
			return ofp;
	}
};

export const getBatsThrows = (evaluation: TEval | null, player: $TSFixMePlayer) => {
	return `${evaluation?.bats ?? player?.bats ?? NULL_TEXT}/${evaluation?.throws ?? player?.throws ?? NULL_TEXT}`;
};

export const getComfortLevel = (evaluation: TEvalAmaHit | TEvalAmaPitch) => {
	const option = COMFORT_LEVEL_OPTS.find(opt => opt.value === evaluation.comfort_level?.toString());
	return option?.abbreviation ?? option?.text ?? evaluation.comfort_level ?? NULL_TEXT;
};

export const getRisk = (evaluation: TEvalAmaHit | TEvalAmaPitch, isUseAbbreviation = true) => {
	if (!evaluation.risk) return NULL_TEXT;
	const option = RISK_OPTS.find(opt => opt.value === evaluation.risk);
	if (isUseAbbreviation) return option?.abbreviation ?? option?.text ?? evaluation.risk ?? NULL_TEXT;
	return option?.text ?? evaluation.risk ?? NULL_TEXT;
};

export const getRiskFactor = (evaluation: TEvalIntlHit | TEvalIntlPitch) => {
	if (!evaluation.risk_factor) return NULL_TEXT;
	const option = RISK_OPTS.find(opt => opt.value === evaluation.risk_factor);
	return option?.text ?? evaluation.risk_factor ?? NULL_TEXT;
};

export const getArmAngle = (evaluation: TEvalAmaPitch) => {
	const option = ARM_ANGLE_OPTS.find(opt => opt.value === evaluation.angle);
	return option?.text ?? evaluation.angle ?? NULL_TEXT;
};

export const getArmSpeed = (evaluation: TEvalAmaPitch) => {
	const option = ARM_SPEED_OPTS.find(opt => opt.value === evaluation.arm_speed);
	return option?.abbreviation ?? option?.text ?? evaluation.arm_speed ?? NULL_TEXT;
};

export const getHittingRisk = (evaluation: TEvalIntlHit) => {
	const option = HITTING_RISK_OPTIONS.find(opt => opt.value === evaluation.hitting_risk);
	return option?.text ?? evaluation.hitting_risk ?? NULL_TEXT;
};

export const checkProPitchExists = (evaluation: TEvalProPitch, pitchType: string) => {
	switch (pitchType) {
		case FB:
			return (
				evaluation.fb_quality_present ||
				evaluation.fb_quality_future ||
				evaluation.fb_cmd_present ||
				evaluation.fb_cmd_future ||
				evaluation.fb_vel_low ||
				evaluation.fb_vel_high
			);
		case CH:
			return (
				evaluation.ch_present ||
				evaluation.ch_future ||
				evaluation.ch_cmd_present ||
				evaluation.ch_cmd_future ||
				evaluation.ch_vel_low ||
				evaluation.ch_vel_high
			);
		case CB:
			return (
				evaluation.cb_present ||
				evaluation.cb_future ||
				evaluation.cb_cmd_present ||
				evaluation.cb_cmd_future ||
				evaluation.cb_vel_low ||
				evaluation.cb_vel_high
			);
		case SL:
			return (
				evaluation.sl_present ||
				evaluation.sl_future ||
				evaluation.sl_cmd_present ||
				evaluation.sl_cmd_future ||
				evaluation.sl_vel_low ||
				evaluation.sl_vel_high
			);
		case OTH:
			return (
				evaluation.oth_pitch_type &&
				(evaluation.oth_pitch_present ||
					evaluation.oth_pitch_future ||
					evaluation.oth_cmd_present ||
					evaluation.oth_cmd_future ||
					evaluation.oth_pitch_vel_low ||
					evaluation.oth_pitch_vel_high)
			);
		case OTH_SECOND:
			return (
				evaluation.oth2_pitch_type &&
				(evaluation.oth2_pitch_present ||
					evaluation.oth2_pitch_future ||
					evaluation.oth2_cmd_present ||
					evaluation.oth2_cmd_future ||
					evaluation.oth2_pitch_vel_low ||
					evaluation.oth2_pitch_vel_high)
			);
		default:
			return false;
	}
};

export const checkAmaPitchExists = (evaluation: TEvalAmaPitch, pitchType: string) => {
	switch (pitchType) {
		case FB:
			return (
				evaluation.fb_overall_present ||
				evaluation.fb_overall_future ||
				evaluation.fb_vel_present ||
				evaluation.fb_vel_future ||
				evaluation.fb_mov_present ||
				evaluation.fb_mov_future ||
				evaluation.fb_cmd_present ||
				evaluation.fb_cmd_future ||
				evaluation.fb_vel_low ||
				evaluation.fb_vel_high ||
				evaluation.fb_vel_comfort_zone_low ||
				evaluation.fb_vel_comfort_zone_high
			);
		case CH:
			return (
				evaluation.ch_present ||
				evaluation.ch_future ||
				evaluation.ch_cmd_present ||
				evaluation.ch_cmd_future ||
				evaluation.ch_vel_low ||
				evaluation.ch_vel_high
			);
		case CB:
			return (
				evaluation.cb_present ||
				evaluation.cb_future ||
				evaluation.cb_cmd_present ||
				evaluation.cb_cmd_future ||
				evaluation.cb_vel_low ||
				evaluation.cb_vel_high
			);
		case SL:
			return (
				evaluation.sl_present ||
				evaluation.sl_future ||
				evaluation.sl_cmd_present ||
				evaluation.sl_cmd_future ||
				evaluation.sl_vel_low ||
				evaluation.sl_vel_high
			);
		case OTH:
			return (
				evaluation.oth_pitch_type &&
				(evaluation.oth_pitch_present ||
					evaluation.oth_pitch_future ||
					evaluation.oth_cmd_present ||
					evaluation.oth_cmd_future ||
					evaluation.oth_pitch_vel_low ||
					evaluation.oth_pitch_vel_high)
			);
		default:
			return false;
	}
};

export const sortEvalsOfp = (a: TEval, b: TEval) => {
	const aOfp = a?.ofp ?? 0;
	const bOfp = b?.ofp ?? 0;
	if (aOfp > bOfp) return -1;
	else if (aOfp < bOfp) return 1;
	else return 0;
};

export const sortEvalsSeenDate = (a: TEval, b: TEval) => {
	const aDate = a?.seen_date ? dayjs(a?.seen_date) : dayjs("1/1/1990");
	const bDate = b?.seen_date ? dayjs(b?.seen_date) : dayjs("1/1/1990");
	if (aDate > bDate) return -1;
	else if (aDate < bDate) return 1;
	else return 0;
};

export const getPitcherSnapshotSummary = (evaluation: TEvalProPitch, isShowSummaries = true) => {
	// This code references the same logic as apps/src/_react/evals/pro/ProEval.js#L877
	const value = evaluation.summary ?? "";
	// eslint-disable-next-line no-useless-escape
	let snapshot = evaluation.current_snapshot ?? value.match(/^.*?([\.!\?]|$)(?:\s|$)/g) ?? "";
	if (typeof snapshot !== "string" && snapshot!.length > 0) snapshot = snapshot![0].substring(0, 250);
	// eslint-disable-next-line no-useless-escape
	if (snapshot && (snapshot as string).match(/\.([^\S]*)$/g) == null) snapshot = `${(snapshot as string).trim()}.`;
	if (snapshot) snapshot = `${(snapshot as string).trim()} `;

	let summary =
		evaluation.current_snapshot &&
		(value == null || typeof value !== "string" || !value.includes(evaluation.current_snapshot))
			? value
			: // eslint-disable-next-line no-useless-escape
			  value.replace(/^.*?([\.!\?]|$)(?:\s|$)/g, "");
	if (snapshot!.length > 250) summary = value.substring(250);
	return (
		<div>
			<span style={{ fontWeight: 700 }}>{snapshot}</span>
			{isShowSummaries && <>{summary}</>}
		</div>
	);
};
