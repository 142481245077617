import React, { useMemo, useEffect } from "react";
import { useToast, VStack, HStack, Box } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { ASC } from "_react/shared/ui/presentation/components/Table/_constants";
import { useProjectionsSeason } from "_react/shared/_helpers/hooks";
import {
	TFutureSeasonBattingProj,
	TSimpleHitterProjections,
	TSimpleProjections
} from "_react/shared/data_models/projections/_types";

import {
	DEFAULT_NUM_SEASONS,
	PROJECTIONS_HITTERS_COLUMNS
} from "_react/shared/ui/data/tables/ProjectionsTable/_constants";
import ProjectionsTableMachine, {
	TProjectionsTableContext,
	FETCHING_SIMPLE_PROJECTIONS,
	FETCHING_REPLACEMENT_LEVEL,
	SET_PLAYER_ID,
	SET_SIMPLE_PROJECTIONS,
	SET_PLATOON_PROJECTIONS,
	SET_REPLACEMENT_LEVEL
} from "_react/shared/ui/data/tables/ProjectionsTable/_machine";
import { TProjectionsTableData } from "_react/shared/ui/data/tables/ProjectionsTable/_types";

type TProjectionsTableProps = {
	title?: string;
	playerId?: number;
	data?: TProjectionsTableData;
	columns?: Array<string>;
	minSeason?: number | null;
	maxSeason?: number | null;
	shouldFetchData?: boolean;
	tableProps?: TTableProps<TSimpleProjections, keyof TSimpleProjections>;
};

const ProjectionsTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	minSeason: minSeasonProp,
	maxSeason: maxSeasonProp,
	shouldFetchData = true,
	tableProps
}: TProjectionsTableProps) => {
	const toast = useToast();
	const [projectionsSeason, ros, isFetchingProjectionsSeason] = useProjectionsSeason();
	const minSeason = minSeasonProp ?? projectionsSeason;
	const maxSeason = maxSeasonProp ?? projectionsSeason + DEFAULT_NUM_SEASONS - 1;

	const [current, send] = useMachine(ProjectionsTableMachine(playerIdProp, data, shouldFetchData, toast));
	const isFetchingSimpleProjections = current.matches(FETCHING_SIMPLE_PROJECTIONS);
	const isFetchingReplacementLevel = current.matches(FETCHING_REPLACEMENT_LEVEL);
	const isLoading = isFetchingSimpleProjections || isFetchingReplacementLevel || isFetchingProjectionsSeason;

	const context = current.context as TProjectionsTableContext;
	const { playerId, simpleProjections, platoonProjections, replacementLevel } = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.simpleProjections !== simpleProjections && shouldFetchData === false)
			send({ type: SET_SIMPLE_PROJECTIONS, value: data?.simpleProjections });
	}, [send, data?.simpleProjections, simpleProjections, shouldFetchData]);

	useEffect(() => {
		if (data?.platoonProjections !== platoonProjections && shouldFetchData === false)
			send({ type: SET_PLATOON_PROJECTIONS, value: data?.platoonProjections });
	}, [send, data?.platoonProjections, platoonProjections, shouldFetchData]);

	useEffect(() => {
		if (data?.replacementLevel !== replacementLevel && shouldFetchData === false)
			send({ type: SET_REPLACEMENT_LEVEL, value: data?.replacementLevel });
	}, [send, data?.replacementLevel, replacementLevel, shouldFetchData]);

	// Extend simple projections with platoon projections and replacement level data
	const simpleProjectionsExtended: TSimpleProjections[] | null | undefined = useMemo(() => {
		if (!simpleProjections) return simpleProjections;
		return simpleProjections.reduce(
			(projections: TSimpleProjections[], projection: TSimpleProjections): TSimpleProjections[] => {
				// Only show
				// - one row (either ros or not) for the current year projections
				// - rows between the min and max season
				if (
					(projection.season !== projectionsSeason || projection.ros === ros) &&
					minSeason <= projection.season &&
					projection.season <= maxSeason
				) {
					projections.push({
						...projection,
						platoonProjection: platoonProjections?.find(
							(platoon: TFutureSeasonBattingProj) =>
								platoon.bamId === projection.bamId && platoon.season === projection.season
						),
						replacementLevelActive: replacementLevel ?? undefined
					});
				}
				return projections;
			},
			[]
		);
	}, [simpleProjections, platoonProjections, replacementLevel, projectionsSeason, ros, minSeason, maxSeason]);

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns) return PROJECTIONS_HITTERS_COLUMNS;
		return PROJECTIONS_HITTERS_COLUMNS.filter(
			(col: TColumn<TSimpleHitterProjections, keyof TSimpleHitterProjections>) => columns.includes(col.value)
		);
	}, [columns]);

	return (
		<VStack alignItems="start">
			{/* Height of 31px to match the height of tables with toggle buttons */}
			<HStack w="100%" justify="space-between" height="31px">
				{title && (
					<Box fontFamily="heading" fontSize="md" fontWeight="bold">
						{title}
					</Box>
				)}
			</HStack>
			<Table<TSimpleProjections, keyof TSimpleProjections>
				columns={filteredColumns as Array<TColumn<TSimpleProjections, keyof TSimpleProjections>>}
				data={simpleProjectionsExtended}
				emptyDataDisplayText={"No Projections Found"}
				isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
				highlightIndexFunction={(data: Array<TSimpleProjections>) => {
					return data.findIndex((proj: TSimpleProjections) => proj.player?.id === playerId);
				}}
				defaultSortColumns={[
					{
						columnValue: "season",
						sortDirection: ASC
					}
				]}
				{...tableProps}
			/>
		</VStack>
	);
};

export default ProjectionsTable;
