import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

import { Style } from "utils/tsutils";

const StyledSquareBadge = styled.div<SquareBadgeProps>(
	{
		display: "inline-block",
		height: "63px",
		width: "63px",
		border: "none",
		borderRadius: "3px",
		backgroundColor: "white",
		boxShadow:
			"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
		overflow: "hidden"
	},
	(props: SquareBadgeProps) => ({
		...props.style
	})
);

const StyledSquareBadgeLabel = styled.div<SquareBadgeProps>(
	{
		height: "15px",
		width: "100%",
		border: "none",
		fontSize: "0.6em",
		fontWeight: "bold",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	(props: SquareBadgeProps) => ({
		backgroundColor: props.colorSchemeGroup!.primary.color,
		color: props.colorSchemeGroup!.primary.text
	})
);
const StyledSquareBadgeContent = styled.div<SquareBadgeProps>(
	{
		width: "100%",
		border: "none",
		fontWeight: 900,
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	(props: SquareBadgeProps) => ({
		fontSize: props.style?.fontSize ?? (props.subText ? "1.2em" : "1.2em"),
		height: `calc(100% - ${props.subText != null ? 34 : "15"}px)`,
		color: props.style!.color ? props.style!.color : props.colorSchemeGroup!.secondary.color
	})
);
const StyledSquareSubContentBadge = styled.div<SquareBadgeProps>(
	{
		display: "inline-block",
		borderRadius: "2px",
		fontSize: "0.65em",
		fontWeight: "bold",
		textAlign: "center",
		alignItems: "center",
		justifyContent: "center",
		padding: "1px 3px"
	},
	(props: SquareBadgeProps) => ({
		backgroundColor: props.colorSchemeGroup!.primary.color,
		color: props.colorSchemeGroup!.primary.text
	})
);
const StyledSquareSubContentBadgeWrapper = styled.div({
	height: "19px",
	display: "flex",
	justifyContent: "center",
	alignItems: "flex-start"
});

type SquareBadgeProps = {
	colorSchemeGroup?: ColorSchemeGroup;
	label?: string;
	loading?: boolean;
	style?: Style;
	subText?: string;
	content?: React.ReactNode;
};

export const SquareBadge: FunctionComponent<SquareBadgeProps> = ({
	colorSchemeGroup = defaultColorScheme,
	label,
	loading,
	style = {},
	subText,
	content
}) => {
	if (loading) {
		return (
			<div
				className="loading-item"
				style={{
					height: "63px",
					width: "63px",
					display: "inline-block",
					borderRadius: "3px",
					boxShadow:
						"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
					...style
				}}
			/>
		);
	}
	return (
		<StyledSquareBadge style={style}>
			<StyledSquareBadgeLabel colorSchemeGroup={colorSchemeGroup}>{label}</StyledSquareBadgeLabel>
			<StyledSquareBadgeContent
				colorSchemeGroup={colorSchemeGroup}
				style={{ color: style.color, backgroundColor: style.backgroundColor, fontSize: style.fontSize }}
				subText={subText}
			>
				{content}
			</StyledSquareBadgeContent>
			{subText != null && (
				<StyledSquareSubContentBadgeWrapper>
					<StyledSquareSubContentBadge colorSchemeGroup={colorSchemeGroup}>
						{subText}
					</StyledSquareSubContentBadge>
				</StyledSquareSubContentBadgeWrapper>
			)}
		</StyledSquareBadge>
	);
};
