import styled from "@emotion/styled";
import { TAnswer } from "_react/dpl/_types";

/*
	SURVEY
*/

export const SurveyTitle = styled.div`
	position: relative;
`;

export const SurveyWrapper = styled.div(`
    background-color: white;
		max-height: calc(100vh - 300px);
		overflow: auto;
`);

export const AnswerWrapper = styled.div`
	display: flex;
	padding-bottom: 5px;
	padding-top: 5px;
	border-bottom: 1px solid #e8e8e8;
`;

export const AnswerNum = styled.div<{ answer: TAnswer }>(
	// note: margin set by checking if letters in question number
	({ answer }) => `
	width: 40px;
	font-weight: bold;
	margin-left: ${answer.indent ? 40 : 0}px;
	`
);

export const AnswerContent = styled.div`
	flex: 1;
`;

export const AnswerQuestion = styled.div`
	font-style: italic;
	font-weight: 300;
	font-size: 0.9rem;
`;

export const AnswerResponse = styled.div`
	font-weight: 400;
	font-size: 1rem;
`;

export const Boolean = styled.span<{ answer: TAnswer }>(
	({ answer }) => `
	color: ${answer.questionAnswer === "False" ? "red" : "green"};
`
);

export const AnswerTable = styled.table`
	table-layout: auto;
	text-align: center;
	border: 1px solid black;
	border-collapse: collapse;
	box-sizing: border-box;
	thead {
		tr {
			background-color: #e8e8e8;
			border-bottom: 1px solid black;
		}
	}
	tbody {
		tr {
			td {
				padding: 4px;
				:first-of-type {
					font-weight: 500;
					padding-right: 8px;
				}
			}
			:nth-child(even) {
				background-color: #f2f2f2;
			}
		}
	}
`;

/*
	SURVEY BUTTON
*/

export const ButtonStyle = { fontSize: "14px", margin: 0, padding: "0 0px", lineHeight: "16px" };

export const IconStyle = { height: "12px", width: "12px", marginRight: "3px" };

export const LoadingStyle = { height: 14, width: 14, marginRight: "4px" };

export const TextContainer = styled.div({ display: "flex", alignItems: "center", justifyContent: "center" });
