import React, { useState } from "react";
import PropTypes from "prop-types";

import EvalTableGroupHeader from "_react/playerpage/shared_js/evals/EvalTableGroupHeader";
import EvalTableGroupRows from "_react/playerpage/shared_js/evals/EvalTableGroupRows";

const EvalTableGroup = ({ evaluations, year, last, type, avgOFP, change, isProEvalAdmin }) => {
	const [expanded, setExpanded] = useState(false);
	const [hover, setHover] = useState(false);

	return (
		<div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
			<EvalTableGroupHeader
				avgOFP={avgOFP}
				change={change}
				evaluations={evaluations}
				expanded={expanded}
				hover={hover}
				last={last}
				toggleExpanded={() => setExpanded(!expanded)}
				type={type}
				year={year}
			/>
			{expanded && <EvalTableGroupRows evaluations={evaluations} hover={hover} isProEvalAdmin={isProEvalAdmin} />}
		</div>
	);
};

EvalTableGroup.propTypes = {
	avgOFP: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	change: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	evaluations: PropTypes.array,
	isProEvalAdmin: PropTypes.bool,
	last: PropTypes.bool,
	type: PropTypes.string,
	year: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EvalTableGroup;
