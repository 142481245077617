import React from "react";
import PropTypes from "prop-types";

import {
	PLAYER_DEFENSE_TOOL_FIELDS,
	CATCHER_DEFENSE_TOOL_FIELDS,
	MISC_OPTS,
	PLAYER_POSITION_OPTS
} from "_react/evals/_constants";

const CELL_STYLE = {
	borderRight: "1px solid lightGray",
	paddingLeft: 10,
	paddingRight: 10
};

const getVal = (evaluation, field) => {
	if (evaluation.hasOwnProperty(field)) {
		return evaluation[field];
	}

	return "";
};

const getSpecialColVal = (evaluation, field, options) => {
	let value = getVal(evaluation, field);
	if (value === "") return "";
	value = options.filter(e => e.value === value);
	if (value.length < 1) return "";
	return value[0].text;
};

const ProPlayerDefenseToolsTable = ({ evaluation, fontSize, height }) => {
	let fields = evaluation.position_reported === "C" ? CATCHER_DEFENSE_TOOL_FIELDS : PLAYER_DEFENSE_TOOL_FIELDS;
	if (evaluation.publish_date != null && evaluation.publish_date.year() < 2019) {
		fields = fields.filter(e => e.id !== "fielding_ability");
		fields.push({ id: "fielding_ability", name: "Fielding Ability" });
		fields.push({ id: "body_control", name: "Body Control" });
		fields.push({ id: "can_he_play", name: "CHP/Pitchability" });
	}

	return (
		<table
			style={{
				width: "100%",
				tableLayout: "auto",
				borderCollapse: "collapse"
			}}
		>
			<thead>
				<tr
					style={{
						backgroundColor: "#E8E9ED",
						fontSize,
						fontWeight: 600,
						height
					}}
				>
					<th style={CELL_STYLE}>Tool</th>
					<th style={CELL_STYLE}>Present</th>
					<th style={CELL_STYLE}>Future</th>
					{evaluation.publish_date != null && evaluation.publish_date.year() < 2019 && (
						<th style={CELL_STYLE}>Comments</th>
					)}
				</tr>
			</thead>
			<tbody>
				{fields.map(({ name, id }, idx) => {
					// Special Columns
					if (id === "instinct" || id === "ath") {
						return (
							<tr
								key={id}
								style={{
									backgroundColor: idx % 2 === 0 ? "white" : "#E8E9ED",
									fontWeight: "normal",
									height,
									fontSize
								}}
							>
								<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{name}</td>
								<td colSpan={2} style={CELL_STYLE}>
									{evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
										? getSpecialColVal(evaluation, id, MISC_OPTS)
										: ""}
								</td>
								{evaluation.publish_date != null && evaluation.publish_date.year() < 2019 && (
									<td style={CELL_STYLE}>
										{evaluation[`${id}_comments`] != null ? evaluation[`${id}_comments`] : ""}
									</td>
								)}
							</tr>
						);
					}

					if (id === "position_in_ml") {
						return (
							<tr
								key={id}
								style={{
									backgroundColor: idx % 2 === 0 ? "white" : "#E8E9ED",
									fontWeight: "normal",
									height,
									fontSize
								}}
							>
								<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{name}</td>
								<td style={CELL_STYLE}>
									{evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
										? getSpecialColVal(evaluation, "position_in_ml_present", PLAYER_POSITION_OPTS)
										: ""}
								</td>
								<td style={CELL_STYLE}>
									{evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
										? getSpecialColVal(evaluation, "position_in_ml_future", PLAYER_POSITION_OPTS)
										: ""}
								</td>
								{evaluation.publish_date != null && evaluation.publish_date.year() < 2019 && (
									<th style={CELL_STYLE} />
								)}
							</tr>
						);
					}

					if (id === "intangibles" || id === "comp") {
						return (
							<tr
								key={id}
								style={{
									backgroundColor: idx % 2 === 0 ? "white" : "#E8E9ED",
									fontWeight: "normal",
									height,
									fontSize
								}}
							>
								<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{name}</td>
								<td colSpan={2} style={CELL_STYLE}>
									{evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
										? getSpecialColVal(evaluation, id, MISC_OPTS)
										: ""}
								</td>
								{evaluation.publish_date != null && evaluation.publish_date.year() < 2019 && (
									<th style={CELL_STYLE} />
								)}
							</tr>
						);
					}
					if (id === "avg_release_time") {
						return (
							<tr
								key={id}
								style={{
									backgroundColor: idx % 2 === 0 ? "white" : "#E8E9ED",
									fontWeight: "normal",
									height,
									fontSize
								}}
							>
								<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{name}</td>
								<td colSpan={2} style={CELL_STYLE}>
									{evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
										? getVal(evaluation, id)
										: ""}
								</td>
							</tr>
						);
					}

					return (
						<tr
							key={id}
							style={{
								backgroundColor: idx % 2 === 0 ? "white" : "#E8E9ED",
								fontWeight: "normal",
								height,
								fontSize
							}}
						>
							<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{name}</td>
							<td style={CELL_STYLE}>
								{evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
									? getVal(evaluation, `${id.toLowerCase()}_present`)
									: ""}
							</td>
							<td style={CELL_STYLE}>
								{evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
									? getVal(evaluation, `${id.toLowerCase()}_future`)
									: ""}
							</td>
							{evaluation.publish_date != null && evaluation.publish_date.year() < 2019 && (
								<td style={CELL_STYLE}>
									{evaluation[`${id}_comments`] != null ? evaluation[`${id}_comments`] : ""}
								</td>
							)}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

ProPlayerDefenseToolsTable.propTypes = {
	evaluation: PropTypes.object,
	fontSize: PropTypes.string,
	height: PropTypes.string
};

ProPlayerDefenseToolsTable.defaultProps = {
	height: "32px",
	fontSize: ".875em"
};

export default ProPlayerDefenseToolsTable;
