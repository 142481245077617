import styled from "@emotion/styled";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { PRO } from "_react/playerpage/_constants";

export const TEXT_PAIR_STYLE = { flex: 1 };
export const CARD_STYLE = { padding: "12px", height: "100%", marginBottom: "-1px" };

export const RedHightlightText = styled.span({
	color: "red",
	fontWeight: "bold"
});

export const InfoCardsResponsiveGrid = styled.div<{ playerType: string }>(({ playerType }) => ({
	flex: 1,
	display: "grid",
	alignContent: "center",
	alignItems: "stretch",
	"@media (max-width: 991px)": {
		display: "block"
	},
	gridAutoFlow: "column",
	gridAutoColumns: playerType === PRO ? "fr2 fr1" : "fr1"
}));

export const ExtraPlayerInfoMobile = styled.div<{ showingAdditionalInfo: boolean }>(({ showingAdditionalInfo }) => ({
	display: "none",
	"@media (max-width: 991px)": {
		display: showingAdditionalInfo ? "none" : "block"
	}
}));

export const ExtraPlayerInfoButton = styled.div<{ colorSchemeGroup: ColorSchemeGroup | undefined }>(
	({ colorSchemeGroup }) => ({
		display: "none",
		"@media (max-width: 991px)": {
			display: "flex",
			justifyContent: "center"
		},
		margin: "-13px",
		marginTop: "3px",
		padding: "3px",
		paddingTop: "0px",
		cursor: "pointer",
		textAlign: "center",
		fontWeight: "bold",
		fontSize: "0.8em",
		borderRadius: "inherit",
		borderTopLeftRadius: "0px",
		borderTopRightRadius: "0px",
		backgroundColor: colorSchemeGroup!.secondary.color,
		color: colorSchemeGroup!.secondary.text
	})
);

export const PlayerInfoCardWrapper = styled.div<{ idx: number; showingAdditionalInfo: boolean }>(
	({ idx, showingAdditionalInfo }) => ({
		padding: "5px",
		"@media (max-width: 991px)": {
			display: idx === 0 || showingAdditionalInfo ? undefined : "none",
			marginTop: idx === 0 || showingAdditionalInfo ? "2px" : undefined
		}
	})
);

export const InnerCardDiv = styled.div<{ rowIdx: number }>(({ rowIdx }) => ({
	display: "flex",
	gap: "5px",
	marginTop: rowIdx > 0 ? "10px" : 0
}));
