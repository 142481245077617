import { PITCH_USAGE_METRIC_IDS, STUFF_METRIC_ID } from "_react/shared/data_models/metric/_constants";
import {
	IMetricProbabilityDensities,
	IMetricRollingAverage,
	IMetricRollingAverages
} from "_react/shared/data_models/metric/_types";

export const getMetricRollingAverageRowNumber = (
	metricRollingAverage: IMetricRollingAverage,
	overallRollingAverages?: IMetricRollingAverages
) => {
	// There are more efficient ways to do this logic
	// But its not particularly slow and this is the simplest to maintain with the least assumptions
	if (overallRollingAverages) {
		const overallRollingAverage = overallRollingAverages.rollingAverages.find(
			(rollingAverage: IMetricRollingAverage) => rollingAverage.playId === metricRollingAverage.playId
		);
		if (overallRollingAverage) return overallRollingAverage.rowNumber;
	}
	return metricRollingAverage.rowNumber;
};

export const getMetricGroupPitchType = (metric: IMetricRollingAverages | IMetricProbabilityDensities) => {
	if (metric.id === STUFF_METRIC_ID) return metric.requestArgs.pitchType;
	else if (PITCH_USAGE_METRIC_IDS.includes(metric.id)) return metric.abbreviation?.split("%")[0];
	return undefined;
};
