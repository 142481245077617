import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import ReactResizeDetector from "react-resize-detector";
import { convertToRaw } from "draft-js";

import { FORM_OBJECT_DICT, USES_AUTOSAVE, getFormObjectDisplayValue } from "_react/forms/FormObjects";
import FormObjectIndicator from "_react/forms/FormObjectIndicator";
import { isMobile } from "utils/_helpers";

function correctMaxWidthForInvalid(containerStyle, valid) {
	if (containerStyle != null && containerStyle.maxWidth != null && !valid) {
		const maxWidth = parseInt(containerStyle.maxWidth.slice(0, -2), 10);
		return `${maxWidth + 25}px`;
	}
	if (containerStyle != null) return containerStyle.maxWidth;
	return null;
}

class FormObject extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			valid: true,
			validationMessage: "",
			saveTimer: null,
			saveSuccessTimer: null,
			value: this.getValueFromProps(props),
			focused: false
		};

		this.autosaveTriggerEvent = this.autosaveTriggerEvent.bind(this);
		this.onChange = this.onChange.bind(this);
		this.triggerSave = this.triggerSave.bind(this);
		this.save = this.save.bind(this);
		this.validate = this.validate.bind(this);
		this.getValueFromProps = this.getValueFromProps.bind(this);
	}

	componentDidMount() {
		if (this.props.reportLabelWidth && this.labelElement)
			this.props.reportLabelWidth(this.labelElement.clientWidth);
	}

	componentWillReceiveProps(nextProps) {
		const options = nextProps.configuration.options ? nextProps.configuration.options.length : 0;
		const prevOptions = this.props.configuration.options ? this.props.configuration.options.length : 0;

		const value = this.getValueFromProps(nextProps);
		const prevValue = this.getValueFromProps(this.props);

		if (
			(value !== prevValue || options !== prevOptions) &&
			!this.state.saving &&
			(!this.state.focused || !nextProps.configuration.multiline) &&
			this.props.configuration.type !== "summarySnapshot"
		) {
			this.setState({ value });
		}
	}

	componentWillUnmount() {
		clearInterval(this.state.saveTimer);
		clearInterval(this.state.saveSuccessTimer);
	}

	getValueFromProps = props => {
		const { dataSource, configuration } = props;

		let value = null;
		if (configuration.hasOwnProperty("value")) value = configuration.value;
		else if (dataSource) value = dataSource[configuration.id];

		value = props.configuration.type === "date" && value != null ? dayjs(value).format("YYYY-MM-DD") : value;

		return value;
	};

	// Saving Functions
	validate = value => {
		const { configuration } = this.props;
		const validation = configuration.validation;
		let valid = true;
		let validationMessage = "";
		validation.map(validationRule => {
			if (value != null && value !== "" && !validationRule.validate(value)) {
				valid = false;
				validationMessage = validationRule.message;
			}
			return null;
		});
		// console.log("validationMessage");
		// console.log(validationMessage);
		if (!valid) {
			this.setState({
				saving: false,
				saved: false,
				valid,
				validationMessage
			});
		}
		return valid;
	};

	save = valueObject => {
		const { handleChange, configuration } = this.props;

		// Change empty to null
		if (valueObject.value === "") valueObject.value = null;

		// Lookup old values, convert if necessary
		let newValueCheck = null;
		if (valueObject && valueObject.value !== "" && valueObject.value != null) {
			if (configuration.type === "date") {
				newValueCheck = dayjs(valueObject.value, "YYYY-MM-DD").format("MM/DD/YYYY");
			} else if (
				configuration.type === "summarySnapshot" &&
				valueObject.value != null &&
				typeof valueObject.value !== "string"
			) {
				newValueCheck = { ...convertToRaw(valueObject.value.getCurrentContent()) }.blocks
					.map(block => block.text)
					.join(" ")
					.replace(/  +/g, " ");
			} else newValueCheck = valueObject.value;
		}

		let oldValueCheck = null;
		const oldValue = this.getValueFromProps(this.props);
		if (oldValue) {
			if (configuration.type === "date" && oldValue !== "" && oldValue != null) {
				oldValueCheck = dayjs(oldValue, "YYYY-MM-DD").format("MM/DD/YYYY");
			} else if (configuration.type === "summarySnapshot") {
				oldValueCheck = oldValue.replace(/  +/g, " ");
			} else oldValueCheck = oldValue;
		}

		// Check if value has changed
		if (newValueCheck === oldValueCheck) {
			this.setState({
				valid: true,
				saving: false,
				saved: false,
				saveTimer: null
			});
			return true;
		}

		const record = {
			id: configuration.id,
			...valueObject,
			extra: configuration.extra,
			value: valueObject.value
		};

		if (configuration.validation) {
			if (!this.validate(record.value)) {
				// console.log("Not validated");
				return false;
			}
		}

		if (handleChange) {
			handleChange(record);
		}

		const saveSuccessTimer = setInterval(() => {
			this.setState({
				saved: false
			});
		}, 1000);

		clearInterval(this.state.saveTimer);
		this.setState({
			valid: true,
			saving: false,
			saved: true,
			saveTimer: null,
			value: record.value,
			saveSuccessTimer
		});

		return true;
	};

	triggerSave = () => {
		// const { value } = this.state;
		// return this.save({ id: this.props.configuration.id, value });
		// Disabling the actual saving as it reverts pending changes from autocomplete
		return true;
	};

	// Autosave Functions
	autosaveTriggerEvent = value => {
		const { autosaveTimer } = this.props;

		clearInterval(this.state.saveTimer);
		const timer = setInterval(() => {
			clearInterval(this.state.saveTimer);
			this.save(value);
		}, autosaveTimer);

		this.setState({
			saveTimer: timer,
			saving: true,
			value: value ? value.value : null
		});
	};

	// Component Functions
	onChange = valueObject => {
		const { autosave, configuration } = this.props;
		if (USES_AUTOSAVE[configuration.type] && autosave) {
			this.autosaveTriggerEvent(valueObject);
		} else {
			this.save(valueObject);
		}
	};

	// Used to stop the autosave timer and immediately save the value (used with onBlur for text fields)
	overrideAutosaveSave = () => {
		const { configuration } = this.props;
		const { value, saveTimer } = this.state;
		clearInterval(saveTimer);
		this.setState({
			saveTimer: null
		});
		this.save({
			id: configuration.id,
			value
		});
	};

	onResize = () => {
		const { configuration } = this.props;
		const { resized } = this.state;

		if (configuration.onBottom && resized) {
			// Re-Enable if they want to revert to auto scroll when at the bottom of the page for evals
			// window.scrollTo(0, document.body.scrollHeight);
		} else if (!resized) {
			this.setState({ resized: true });
		}
	};

	render() {
		const { configuration, labelWidth, editing, idx, colorScheme, windowWidth } = this.props;
		const { value, saving, saved, valid, validationMessage } = this.state;

		let containerStyle = {
			width: configuration.partialWidth == null || isMobile(windowWidth) ? "100%" : null,
			flex: configuration.partialWidth && !isMobile(windowWidth) ? configuration.partialWidth : null,
			display: "flex",
			alignItems: "center",
			backgroundColor: valid
				? idx % 2 === 0
					? colorScheme.section.backgroundColorEven
					: colorScheme.section.backgroundColorOdd
				: "pink",
			paddingLeft: configuration.noLeftPad ? null : "10px",
			paddingRight: configuration.noRightPad ? null : "10px",
			fontSize: isMobile(windowWidth) ? "0.9em" : "",
			...configuration.containerStyle,
			maxWidth: correctMaxWidthForInvalid(configuration.containerStyle, valid)
		};

		const shouldOverrideWidthStyle = isMobile(windowWidth) && configuration.id === "power_production_future";
		if (shouldOverrideWidthStyle) {
			// Required to make the power production future float to the right on mobile
			containerStyle = { ...containerStyle, width: "100%" };
		}

		return (
			<div style={containerStyle}>
				{configuration.label != null && (
					<div
						ref={node => {
							this.labelElement = node;
						}}
						style={{
							width: labelWidth && !configuration.noWidthConform ? `${labelWidth}px` : null,
							paddingRight: "10px",
							fontWeight: "bold",
							minHeight: "29px",
							display: "flex",
							alignItems: "center",
							...configuration.labelStyle
						}}
					>
						<span>{configuration.label}</span>
					</div>
				)}
				{configuration.type !== "none" &&
					!(configuration.type === "button" && !editing) && [
						<div
							key={0}
							style={{
								flex: "1",
								...configuration.style
							}}
						>
							<ReactResizeDetector handleHeight onResize={this.onResize}>
								{FORM_OBJECT_DICT[
									editing || configuration.type === "checkbox" ? configuration.type : "label"
								]({
									disabled: configuration.type === "checkbox" && !editing,
									...configuration,
									style: {
										...configuration.objectStyle,
										width: shouldOverrideWidthStyle ? "50%" : configuration.objectStyle?.width
									}, // Required to make the power production future float to the right on mobile
									value: editing ? value : getFormObjectDisplayValue(configuration, value),
									onChange: this.onChange,
									onBlur: ["text", "date", "summarySnapshot"].includes(configuration.type)
										? () => {
												if (configuration.onBlur != null) configuration.onBlur();
												this.overrideAutosaveSave();
												this.setState({
													focused: false
												});
										  }
										: () => {
												if (configuration.onBlur != null) configuration.onBlur();
												this.setState({
													focused: false
												});
										  },
									onFocus: () => {
										if (configuration.onFocus != null) configuration.onFocus();
										this.setState({ focused: true });
									},
									windowWidth: windowWidth
								})}
							</ReactResizeDetector>
						</div>,
						!["button", "label"].includes(configuration.type) ? (
							<FormObjectIndicator
								key={1}
								required={configuration.required}
								saved={saved}
								saving={saving}
								valid={valid}
								validationMessage={validationMessage}
								value={value ? value.value : null}
							/>
						) : null
					]}
			</div>
		);
	}
}

FormObject.propTypes = {
	autosave: PropTypes.bool,
	autosaveTimer: PropTypes.number,
	colorScheme: PropTypes.object,
	configuration: PropTypes.object,
	editing: PropTypes.bool,
	handleChange: PropTypes.func,
	idx: PropTypes.number,
	labelWidth: PropTypes.number,
	reportLabelWidth: PropTypes.func,
	windowWidth: PropTypes.number
};

FormObject.displayName = "FormObject";

export default FormObject;
