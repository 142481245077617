import {
	LOAD_SEASON_STATS,
	LOAD_CAREER_STATS,
	FETCH_SEASON_STATS,
	FETCH_CAREER_STATS,
	LOAD_TEAM_STATS,
	FETCH_TEAM_STATS,
	FETCH_PROJECTION_STATS,
	LOAD_PROJECTION_STATS,
	FETCH_AVERAGE_AGES
} from "_redux/StatsAggregated/_betaTypes";

const INITIAL_STATE = {
	playerSeasonStats: {}, // Player Season stats indexed by bam_id
	playerCareerStats: {}, // Player Career Stats indexed by bam_id
	teamSeasonStats: {}, // TeamId
	playerProjectionStats: {}, // Player Projection stats indexed by bam_id
	averageAges: {} // Dictionary of averages keyed by year => level => playerType
};

const betaStats = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOAD_SEASON_STATS: {
			const { bamId, playerType, stats } = action.payload;
			return {
				...state,
				playerSeasonStats: {
					...state.playerSeasonStats,
					[bamId]: {
						...state.playerSeasonStats[bamId],
						[playerType]: {
							stats,
							fetching: false
						}
					}
				}
			};
		}
		case LOAD_CAREER_STATS: {
			const { bamId, playerType, stats } = action.payload;

			return {
				...state,
				playerCareerStats: {
					...state.playerCareerStats,
					[bamId]: {
						...state.playerCareerStats[bamId],
						[playerType]: {
							stats,
							fetching: false
						}
					}
				}
			};
		}
		case FETCH_SEASON_STATS: {
			const { bamId, playerType } = action.payload;
			return {
				...state,
				playerSeasonStats: {
					...state.playerSeasonStats,
					[bamId]: {
						...state.playerSeasonStats[bamId],
						[playerType]: {
							fetching: true
						}
					}
				}
			};
		}
		case FETCH_CAREER_STATS: {
			const { bamId, playerType } = action.payload;

			return {
				...state,
				playerCareerStats: {
					...state.playerCareerStats,
					[bamId]: {
						...state.playerCareerStats[bamId],
						[playerType]: {
							fetching: true
						}
					}
				}
			};
		}
		case FETCH_TEAM_STATS: {
			const { bamId, playerType, season } = action.payload;
			let latestPlayerTypeTeamStats = {},
				latestSeasonStatsForPlayerType = {};
			if (state.teamSeasonStats.hasOwnProperty(bamId)) {
				latestPlayerTypeTeamStats = { ...state.teamSeasonStats[bamId] };

				if (state.teamSeasonStats[bamId].hasOwnProperty(playerType)) {
					latestSeasonStatsForPlayerType = {
						...state.teamSeasonStats[bamId][playerType]
					};
				}
			}

			return {
				...state,
				teamSeasonStats: {
					...state.teamSeasonStats,
					[bamId]: {
						...latestPlayerTypeTeamStats,
						[playerType]: {
							...latestSeasonStatsForPlayerType,
							[season]: {
								fetching: true
							}
						}
					}
				}
			};
		}
		case LOAD_TEAM_STATS: {
			const { bamId, playerType, season, stats } = action.payload;
			let latestPlayerTypeTeamStats = {},
				latestSeasonStatsForPlayerType = {};
			if (state.teamSeasonStats.hasOwnProperty(bamId)) {
				latestPlayerTypeTeamStats = { ...state.teamSeasonStats[bamId] };

				if (state.teamSeasonStats[bamId].hasOwnProperty(playerType)) {
					latestSeasonStatsForPlayerType = {
						...state.teamSeasonStats[bamId][playerType]
					};
				}
			}

			return {
				...state,
				teamSeasonStats: {
					...state.teamSeasonStats,
					[bamId]: {
						...latestPlayerTypeTeamStats,
						[playerType]: {
							...latestSeasonStatsForPlayerType,
							[season]: {
								stats,
								fetching: false
							}
						}
					}
				}
			};
		}
		case FETCH_PROJECTION_STATS: {
			const { bamId, playerType } = action.payload;

			// Add check to get stats for a bamId if it already has been loaded
			return {
				...state,
				playerProjectionStats: {
					...state.playerProjectionStats,
					[bamId]: {
						...state.playerProjectionStats[bamId],
						[playerType]: {
							fetching: true
						}
					}
				}
			};
		}
		case LOAD_PROJECTION_STATS: {
			const { bamId, playerType, stats } = action.payload;
			return {
				...state,
				playerProjectionStats: {
					...state.playerProjectionStats,
					[bamId]: {
						...state.playerProjectionStats[bamId],
						[playerType]: {
							stats,
							fetching: false
						}
					}
				}
			};
		}
		case FETCH_AVERAGE_AGES: {
			const averageAges = action.payload;
			return {
				...state,
				averageAges
			};
		}
		default:
			return state;
	}
};

export default betaStats;
