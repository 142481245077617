import React, { FunctionComponent, useState } from "react";
import dayjs from "dayjs";

import { getAgeFromBirthDate } from "utils/helpers";
import { Form } from "_react/shared/forms";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { proBioPlugin } from "_react/playerpage/bio/_helpers";
import { TProBioDocument } from "_react/playerpage/bio/_types";

type ProBioProps = {
	player: TPlayerPageCombinedPlayer;
};

export const ProBio: FunctionComponent<ProBioProps> = ({ player }) => {
	const [document, _] = useState<TProBioDocument>({
		// If we want to make any of these fields editable, will need to reference playerProProfile instead
		lastName: player.lastName ?? undefined,
		firstName: player.firstName ?? undefined,
		middleName: player.middleName ?? undefined,
		firstNameLegal: player.firstNameLegal ?? undefined,
		lastNameExtended: player.lastNameExtended ?? undefined,
		birthDate: player.birthDate ? dayjs(player.birthDate, "YYYY-MM-DD") : undefined,
		age: player.birthDate ? getAgeFromBirthDate(player.birthDate).toString() : undefined,
		philId: player.playerProId ?? undefined,
		id: player.id,
		bamId: player.bamId ?? undefined,
		ebisId: player.ebisId ?? undefined,
		fangraphsId: player.fangraphsId ?? undefined
	});

	return (
		<Form<TProBioDocument>
			document={document}
			plugin={proBioPlugin}
			onDocumentUpdate={() => {
				return null;
			}}
			readOnly={true}
			showFooter={false}
		/>
	);
};

export default ProBio;
