import React, { FunctionComponent, CSSProperties } from "react";

import { PlayerProSummary } from "_react/shared/legacy/globalcontext/player/_types";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import StatisticsPage from "_react/stats/player";
import { SHOHEI_PHIL_ID } from "_react/playerpage/_constants";

type TStatisticsStyle = {
	cardHeader?: CSSProperties;
};

type StatisticsProps = {
	colorScheme: ColorSchemeGroup;
	player: PlayerProSummary;
	allowProjections?: boolean;
	sortSeasonsDesc?: boolean;
	style?: TStatisticsStyle;
};

export const Statistics: FunctionComponent<StatisticsProps> = ({
	colorScheme,
	player,
	allowProjections,
	sortSeasonsDesc,
	style
}) => {
	return (
		<div style={{ overflow: "auto" }}>
			<StatisticsPage
				colorScheme={colorScheme}
				player={player}
				// hardcode shohei as a batter
				playerType={player.is_pitcher && player.phil_id !== SHOHEI_PHIL_ID ? "pitcher" : "batter"}
				summary={false}
				allowProjections={allowProjections}
				sortSeasonsDesc={sortSeasonsDesc}
				style={style}
			/>
		</div>
	);
};
