import { CH, FF, SI, SL, CT, CB, SP, SW, SV, KN } from "_react/shared/_constants/pitch_types";
import { TPitchTypes } from "_react/shared/_types/pitch_types";

import { TMargins } from "_react/shared/dataviz/_types";

export const DEFAULT_HEIGHT = 300;
export const DEFAULT_WIDTH = 400;
export const DEFAULT_MARGINS: TMargins = { top: 40, right: 40, bottom: 40, left: 40 };
export const DEFAULT_OPACITY = 0.7;
export const IS_HOVERED_OPACITY = 1.0;
export const IS_ELSEWHERE_HOVERED_OPACITY = 0.5;
export const DEFAULT_FILL_OPACITY = 0.2;

// Use the hook useDataVizColors whenever possible
// Only use these constants when using a hook would require significant refactoring
export const AXIS_COLOR_HEX = "#262626";
export const AXIS_COLOR_RGB = "rgb(38, 38, 38)";
export const BACKGROUND_COLOR_HEX = "#FAFAFA";
export const BACKGROUND_COLOR_RGB = "rgb(250, 250, 250)";
export const DATA_COLOR_PRIMARY_BLUE_HEX = "#4299E1";
export const DATA_COLOR_PRIMARY_BLUE_RGB = "rgb(66, 153, 225)";
export const DATA_COLOR_PRIMARY_GREEN_HEX = "#68D391";
export const DATA_COLOR_PRIMARY_GREEN_RGB = "rgb(104, 211, 145)";
export const DATA_COLOR_PRIMARY_PURPLE_HEX = "#6B46C1";
export const DATA_COLOR_PRIMARY_PURPLE_RGB = "rgb(107, 70, 193)";
export const DATA_COLOR_PRIMARY_RED_HEX = "#E53E3E";
export const DATA_COLOR_PRIMARY_RED_RGB = "rgb(229, 62, 62)";
export const DATA_COLOR_PRIMARY_YELLOW_HEX = "#F6E05E";
export const DATA_COLOR_PRIMARY_YELLOW_RGB = "rgb(246, 223, 94)";
export const DATA_COLOR_PRIMARY_ARRAY_HEX: [string, string, string, string, string] = [
	DATA_COLOR_PRIMARY_BLUE_HEX,
	DATA_COLOR_PRIMARY_GREEN_HEX,
	DATA_COLOR_PRIMARY_PURPLE_HEX,
	DATA_COLOR_PRIMARY_RED_HEX,
	DATA_COLOR_PRIMARY_YELLOW_HEX
];
export const DATA_COLOR_PRIMARY_ARRAY_RGB: [string, string, string, string, string] = [
	DATA_COLOR_PRIMARY_BLUE_RGB,
	DATA_COLOR_PRIMARY_GREEN_RGB,
	DATA_COLOR_PRIMARY_PURPLE_RGB,
	DATA_COLOR_PRIMARY_RED_RGB,
	DATA_COLOR_PRIMARY_YELLOW_RGB
];
export const DATA_COLOR_PRIMARY_GRAY_HEX = "#171717";
export const DATA_COLOR_PRIMARY_GRAY_RGB = "rgb(23, 23, 23)";
export const DATA_COLOR_SECONDARY_HEX = "#525252";
export const DATA_COLOR_SECONDARY_RGB = "rgb(82, 82, 82)";
export const GRID_STROKE_COLOR_HEX = "#E5E5E5";
export const GRID_STROKE_COLOR_RGB = "rgb(229, 229, 229)";

// Pitch type colors - the semantic tokens should be used instead whenever possible
export const SI_PINK_RGB = "rgb(237, 100, 166)";
export const CH_ORANGE_RGB = "rgb(237, 137, 54)";
export const SL_BLUE_RGB = "rgb(49, 130, 206)";
export const SW_CYAN_RGB = "rgb(11, 197, 234)";
export const SV_TEAL_RGB = "rgb(44, 122, 123)";
export const KN_GRAY_RGB = "rgb(163, 163, 163)";
export const TRUE_WHITE_HEX = "#FFFFFF";

// THIS SHOULD MATCH THE SEMANTIC TOKENS
export const PITCH_TYPE_COLORS = {
	[FF]: DATA_COLOR_PRIMARY_RED_RGB,
	[SI]: SI_PINK_RGB,
	[CT]: DATA_COLOR_PRIMARY_PURPLE_RGB,
	[SL]: SL_BLUE_RGB,
	[SW]: SW_CYAN_RGB,
	[SV]: SV_TEAL_RGB,
	[CB]: DATA_COLOR_PRIMARY_GREEN_RGB,
	[CH]: CH_ORANGE_RGB,
	[SP]: DATA_COLOR_PRIMARY_YELLOW_RGB,
	[KN]: KN_GRAY_RGB,
	UN: "rgb(160, 174, 192)"
};

export const PITCH_TYPE_CHAKRA_COLOR_SCHEME: { [key in TPitchTypes]: string } = {
	[FF]: "red",
	[SI]: "pink",
	[CT]: "purple",
	[SL]: "blue",
	[SW]: "cyan",
	[SV]: "teal",
	[CB]: "green",
	[CH]: "orange",
	[SP]: "yellow",
	[KN]: "gray"
};

// Uses the 500 chakra value for each color
export const PITCH_TYPE_COLORSCALE_MAX_VALUES: { [index: string]: string } = {
	[FF]: "#E53E3E",
	[SI]: "#D53F8C",
	[CT]: "#805AD5",
	[SL]: "#3182CE",
	[SW]: "#00B5D8",
	[SV]: "#319795",
	[CB]: "#38A169",
	[CH]: "#DD6B20",
	[SP]: "#D69E2E",
	[KN]: "#737373",
	UN: "#718096"
};

// Text color that is darker than the DATA_COLOR_PRIMARY_GREEN
export const TEXT_COLOR_PRIMARY_GREEN_RGB = "rgb(56,161,105)";

// Colors used for a discrete red-blue color gradient
export const DARK_RED = "#F56565";
export const LIGHT_RED = "#FEB2B2";
export const LIGHT_BLUE = "#90CDF4";
export const DARK_BLUE = "#4299E1";
export const WHITE = "#FFFFFF";

// Below are unofficial colors that might be useful, but should be used sparingly

// Red that is halfway between white and DATA_COLOR_PRIMARY_RED
export const DATA_COLOR_PRIMARY_RED_MIXED_WITH_WHITE_RGB = "rgb(242, 159, 159)";
export const DATA_COLOR_PRIMARY_RED_MIXED_WITH_WHITE_HEX = "#f29f9f";

export const HIGHCHARTS_THEME = {
	chart: {
		backgroundColor: "transparent"
	},
	xAxis: {
		lineColor: AXIS_COLOR_RGB,
		labels: {
			style: {
				color: AXIS_COLOR_RGB,
				font: `11px Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`
			}
		}
	},
	yAxis: {
		lineColor: AXIS_COLOR_RGB,
		labels: {
			style: {
				color: AXIS_COLOR_RGB,
				font: `11px Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`
			}
		}
	}
};
