import React, { Suspense, useMemo, useCallback, useEffect, useContext } from "react";
import { useSelector } from "@xstate/react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { CookiesProvider } from "react-cookie";
import ReactGA from "react-ga4";

import { $TSFixMe } from "utils/tsutils";
import { GOOGLE_ANALYTICS_REACT_APP_ENVS } from "_react/shared/_constants/google_analytics";
import Login from "_react/app/Login";
import { Navigation } from "_react/app/Navigation";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import { Loading } from "_react/shared/legacy/ui/Loading";
import { LkProvider } from "_react/inputs/lks/LkContext";
import { useIsMobile } from "_react/_hooks";
import { PageContainerDiv, VersionFooter } from "_react/app/_styles";
import { StatusBanner } from "_react/status/StatusBanner";
import store from "_redux/_utils/configureStore";
import { IIamUserSchema, IIamUserGroupSchema } from "_react/shared/data_models/iam_new/_types";
import { pushAllGroupIds } from "_react/shared/data_models/iam_new/_helpers";

import { TLink } from "_react/app/_types";
import { LOGIN, LOGOUT, TAppState } from "_react/app/_machine";
import { AppContext } from "_react/app/AppProvider";

const EMPTY_USER = {
	userId: null,
	firstName: null,
	lastName: null,
	name: null,
	active: null,
	playerId: null,
	routes: null,
	groups: null,
	role: null,
	permissions: null
};
// TODO remove these in favor of using the AppContext directly
const UserDataContext = React.createContext<IIamUserSchema>(EMPTY_USER); // TODO we should allow undefined and null values from the AppContext
const MobileContext = React.createContext(false);

const AppContents = () => {
	const isMobile = useIsMobile();
	const appContext = useContext(AppContext);

	// App machine actions
	const handleLogin = (username: string, password: string) =>
		appContext.appService.send({ type: LOGIN, data: { username, password } });
	const handleLogout = () => appContext.appService.send({ type: LOGOUT });

	// Load context values
	const user: IIamUserSchema | null | undefined = useSelector(
		appContext.appService,
		(state: TAppState) => state.context.user
	);
	const viewableRoutes: Array<TLink> = useSelector(
		appContext.appService,
		(state: TAppState) => state.context.viewableRoutes
	);
	const isLoginRequiredState: boolean = useSelector(appContext.appService, (state: TAppState) =>
		state.matches("initialized.loginRequired")
	);
	const isLoginRequiredIdleState: boolean = useSelector(appContext.appService, (state: TAppState) =>
		state.matches("initialized.loginRequired.idle")
	);
	const isAuthenticatedState: boolean = useSelector(appContext.appService, (state: TAppState) =>
		state.matches("initialized.authenticated")
	);

	const processChildRoutes = useCallback((viewableRoutesWithChildroutes: Array<TLink>, routes: Array<TLink>) => {
		routes.forEach((route: TLink) => {
			if ("children" in route && route.children != null)
				viewableRoutesWithChildroutes = processChildRoutes(viewableRoutesWithChildroutes, route.children);
			else viewableRoutesWithChildroutes.push(route);
		});
		return viewableRoutesWithChildroutes;
	}, []);

	const viewableRoutesWithChildRoutes = useMemo(() => processChildRoutes([], viewableRoutes), [
		viewableRoutes,
		processChildRoutes
	]);

	// Google analytics user identification
	useEffect(() => {
		if (
			process.env.REACT_APP_ENV &&
			GOOGLE_ANALYTICS_REACT_APP_ENVS.includes(process.env.REACT_APP_ENV) &&
			user != null
		) {
			ReactGA.gtag("set", "user_properties", {
				user_id: `${user.userId}`,
				name: `${user.name}`,
				role: `${user.role?.label ?? "Unknown"}`
			});
		}
	}, [user]);

	return (
		<div style={{ height: "100%" }}>
			{isLoginRequiredState ? (
				<Login handleLogin={handleLogin} loading={!isLoginRequiredIdleState} />
			) : isAuthenticatedState && user ? (
				<PageContainerDiv data-cy="authenticated">
					<StatusBanner />
					<Loading />
					<Provider store={store as $TSFixMe}>
						<BrowserRouter>
							<Suspense fallback={<CircularProgress center={true} />}>
								{/* TODO get rid of user context */}
								<UserDataContext.Provider value={user}>
									<LkProvider>
										<CookiesProvider>
											<SnackbarProvider dense={isMobile}>
												<MobileContext.Provider value={isMobile}>
													<Navigation handleLogout={handleLogout} links={viewableRoutes} />
													<Switch>
														{viewableRoutesWithChildRoutes.map(link => (
															<Route
																component={link.comp}
																key={link.route}
																path={link.route}
															/>
														))}
													</Switch>
													<VersionFooter>Version 2.4</VersionFooter>
												</MobileContext.Provider>
											</SnackbarProvider>
										</CookiesProvider>
									</LkProvider>
								</UserDataContext.Provider>
							</Suspense>
						</BrowserRouter>
					</Provider>
				</PageContainerDiv>
			) : (
				<CircularProgress center={true} />
			)}
		</div>
	);
};

//
// TODO remove all of these in favor of using the AppContext directly
//

export const useUserData = () => useContext(UserDataContext);

export const useUserId = () => useContext(UserDataContext).userId;

export const useUserGroupIds = () => {
	const groups = useContext(UserDataContext).groups;
	const groupIds: Array<number> = [];
	if (groups) {
		groups.forEach((group: IIamUserGroupSchema) => {
			pushAllGroupIds(group, groupIds);
		});
	}
	return groupIds;
};

export const useIsGroupMember = (groupIds: number | Array<number>) => {
	const userGroupIds = useUserGroupIds();
	if (typeof groupIds === "number") return userGroupIds.includes(groupIds);
	return groupIds.some((groupId: number) => userGroupIds.includes(groupId));
};

export const useMobileContext = () => useContext(MobileContext);

export default AppContents;
