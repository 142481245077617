import React, { FC, useState } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import { formatHeight } from "utils/functions";
import { getAgeFromBirthDate } from "utils/helpers";
import { useDraftDate } from "_react/_hooks";
import { getTeamName } from "_react/shared/_helpers/team";
import { Button } from "_react/shared/legacy/ui/Button";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import KeyboardArrowDown from "_react/shared/legacy/ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "_react/shared/legacy/ui/icons/KeyboardArrowUp";
import { TextPair } from "_react/shared/legacy/ui/Text";

import { AMA } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { ListRanks } from "_react/playerpage/header/ListRanks";
import { AgentOrAgencyField } from "_react/playerpage/header/AgentOrAgencyField/Field";
import {
	InfoCardsResponsiveGrid,
	ExtraPlayerInfoButton,
	PlayerInfoCardWrapper,
	CARD_STYLE,
	InnerCardDiv,
	TEXT_PAIR_STYLE
} from "_react/playerpage/header/fields/_styles";

export type HeaderFieldsProps = {
	colorSchemeGroup?: ColorSchemeGroup;
	player: TPlayerPageCombinedPlayer;
	playerType?: string;
	showingAdditionalInfo?: boolean;
};

export const AmaHeaderFields: FC<HeaderFieldsProps> = ({ colorSchemeGroup, player }) => {
	const history = useHistory();
	const [showingAdditionalInfo, setShowingAdditionalInfo] = useState(false);
	const org = player && player.proProfile?.orgCode ? player.proProfile.orgCode.toLowerCase() : "none";
	const birthDate = player.amaProfile?.birthDate ?? player.birthDate;
	const age = getAgeFromBirthDate(birthDate);
	const draftDate = useDraftDate(player.amaProfile?.eligibleYear ? player.amaProfile.eligibleYear : undefined);
	const draftAge = getAgeFromBirthDate(birthDate, draftDate);
	const height = player.amaProfile?.height ?? player.height;
	const team = player.amaProfile?.team ?? player.team;
	const teamId = team?.id ?? player.amaProfile?.currentTeamId ?? player.team?.id;
	const weight = player.amaProfile?.weight ?? player.weight;

	const agentName =
		!player.amaProfile?.agent?.firstName && !player.amaProfile?.agent?.lastName
			? "--"
			: `${player.amaProfile?.agent?.firstName ?? ""} ${player.amaProfile?.agent?.lastName ?? ""}`;

	const agentField = player.amaProfile?.agentId ? (
		<AgentOrAgencyField
			label={agentName}
			infoId={player.amaProfile?.agentId}
			infoKey={"agent"}
			playerType={AMA}
			org={org}
		/>
	) : (
		"--"
	);
	const agencyField = player.amaProfile?.agent?.agencyId ? (
		<AgentOrAgencyField
			label={player.amaProfile.agent.agency?.agencyName ?? "--"}
			infoId={player.amaProfile.agent.agencyId}
			infoKey={"agency"}
			playerType={AMA}
			org={org}
		/>
	) : (
		"--"
	);

	return (
		<InfoCardsResponsiveGrid playerType={AMA}>
			<PlayerInfoCardWrapper idx={0} showingAdditionalInfo={showingAdditionalInfo}>
				<Card style={CARD_STYLE}>
					<InnerCardDiv rowIdx={0}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"DOB/Age"}
								body={
									birthDate && birthDate != null
										? `${dayjs(birthDate).format("M/D/YYYY")} (${age})`
										: "--"
								}
								height={"max-content"}
							/>
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Draft Eligible"}
								body={`${player.amaProfile?.eligibleYear}`}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={1}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"School"}
								body={
									<Button
										disabled={!teamId}
										onClick={() => history.push(`/team?rockyId=${teamId}`)}
										style={{ fontSize: "14px", margin: 0, padding: "0 2px", lineHeight: "16px" }}
										title={getTeamName(team)}
									/>
								}
								height={"max-content"}
							/>
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Draft Age"} body={`${draftAge ?? ""}`} height={"max-content"} />
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={2}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Weight"} body={weight} height={"max-content"} />
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Height"}
								body={height ? formatHeight(height) : "--"}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<ExtraPlayerInfoButton
						colorSchemeGroup={colorSchemeGroup}
						onClick={() => {
							setShowingAdditionalInfo(val => !val);
						}}
						style={{ marginBottom: "-12px" }}
					>
						{" "}
						{showingAdditionalInfo ? (
							<KeyboardArrowUp style={{ height: "18px", marginBottom: "-5px" }} />
						) : (
							<KeyboardArrowDown style={{ height: "18px", marginBottom: "-5px" }} />
						)}
						More Information
					</ExtraPlayerInfoButton>
				</Card>
			</PlayerInfoCardWrapper>
			<PlayerInfoCardWrapper idx={1} showingAdditionalInfo={showingAdditionalInfo}>
				<Card style={CARD_STYLE}>
					<InnerCardDiv rowIdx={0}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Scout Assigned"}
								body={
									!player.scoutAssigned?.firstName && !player.scoutAssigned?.lastName
										? "--"
										: `${player.scoutAssigned?.firstName ?? ""} ${player.scoutAssigned?.lastName ??
												""}`
								}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={1}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Agent"} body={agentField} height={"max-content"} />
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={2}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Agency"} body={agencyField} height={"max-content"} />
						</div>
					</InnerCardDiv>
				</Card>
			</PlayerInfoCardWrapper>
			<PlayerInfoCardWrapper idx={2} showingAdditionalInfo={showingAdditionalInfo}>
				<Card style={CARD_STYLE}>
					<InnerCardDiv rowIdx={0}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"ID"} body={player.id} height={"max-content"} />
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"COLLEGE SPLITS ID"}
								body={player.collegesplitsId}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={1}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"PHIL ID"} body={player.playerAmaId} height={"max-content"} />
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"TRACKMAN ID"} body={player.trackmanId} height={"max-content"} />
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={2}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"DPL ID"} body={player.dplId} height={"max-content"} />
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"EBIS ID"} body={player.ebisId} height={"max-content"} />
						</div>
					</InnerCardDiv>
				</Card>
			</PlayerInfoCardWrapper>
			{player.playerAmaId && (
				<PlayerInfoCardWrapper idx={3} showingAdditionalInfo={showingAdditionalInfo}>
					<Card style={CARD_STYLE}>
						<ListRanks playerId={player.id} />
					</Card>
				</PlayerInfoCardWrapper>
			)}
		</InfoCardsResponsiveGrid>
	);
};
