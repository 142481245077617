import React, { useEffect, useState } from "react";

import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { MinorSection } from "_react/shared/legacy/ui/SectionWrapper";

import { FOCUS_AREA_TIER } from "_react/playerplan/metrics/_constants";
import { getInitialGoalDetailsSeason } from "_react/playerplan/shared/_helpers";
import {
	DetailsWrapperStyle,
	MarginWrapperStyle,
	MaxWidthDiv,
	TextAlignLeftDiv,
	WrapperStyle
} from "_react/playerplan/shared/_styles";
import { TGoalDrillForm, TGoalForm, TPlayerPlanGoal, TTargetForm } from "_react/playerplan/shared/_types";
import { FocusAreasWrapper, TFocusAreasWrapperStyle } from "_react/playerplan/shared/FocusAreasWrapper";
import { GoalAdjustments, TGoalAdjustmentsStyle } from "_react/playerplan/shared/GoalAdjustments";
import { GoalDetails } from "_react/playerplan/shared/GoalDetails";

export type TDraftModalContentsStyle = {
	focusAreasWrapperStyle?: TFocusAreasWrapperStyle;
	goalAdjustmentsStyle?: TGoalAdjustmentsStyle;
};

export type TDraftModalContentsProps = {
	goals: Array<TPlayerPlanGoal>;
	modalGoal: TPlayerPlanGoal;
	isEditGoalModal: boolean;
	addFocusArea: (focusAreaForm: TGoalForm) => void;
	isAddingFocusArea: boolean;
	addGoalDrill: (goalDrillForm: TGoalDrillForm) => void;
	isAddingGoalDrill?: boolean;
	deleteFocusArea: (goal: TPlayerPlanGoal, deleteGoalDrillIds: Array<number>) => void;
	circularProgressFocusAreaId: number | null;
	setCircularProgressFocusAreaId: (focusAreaId: number) => void;
	circularProgressGoalDrillId: number | null;
	setCircularProgressGoalDrillId: (goalDrillId: number) => void;
	deleteGoalDrill: (goalDrillId: number) => void;
	isGoalActionsDisabled?: boolean;
	addTarget?: (targetForm: TTargetForm) => void;
	isAddingTarget?: boolean;
	isGroup1?: boolean;
	isGroup2?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TDraftModalContentsStyle;
	setIsSecondaryGoalModalOpen?: (value: boolean) => void;
	setModalPrimaryGoal?: (goal: TPlayerPlanGoal | null) => void;
	isEditable?: boolean;
};

export const DraftModalContents = ({
	goals,
	modalGoal,
	isEditGoalModal,
	addFocusArea,
	isAddingFocusArea,
	addGoalDrill,
	isAddingGoalDrill = false,
	deleteFocusArea,
	circularProgressFocusAreaId,
	setCircularProgressFocusAreaId,
	circularProgressGoalDrillId,
	setCircularProgressGoalDrillId,
	deleteGoalDrill,
	isGoalActionsDisabled = false,
	addTarget,
	isAddingTarget,
	isGroup1 = false,
	isGroup2 = false,
	colorSchemeGroup = defaultColorScheme,
	style,
	setIsSecondaryGoalModalOpen = () => null,
	setModalPrimaryGoal = () => null,
	isEditable = true
}: TDraftModalContentsProps) => {
	const secondaryGoal: TPlayerPlanGoal | undefined = goals.find(
		(goal: TPlayerPlanGoal) => goal.primaryGoalId === modalGoal.id
	);

	const focusAreas: TPlayerPlanGoal[] | undefined = goals.filter(
		(goal: TPlayerPlanGoal) =>
			goal.parentGoalId === modalGoal.id && goal.playerPlanMetric.metricTierLk === FOCUS_AREA_TIER
	);

	// Handle season selection
	const [selectedModalGoalSeason, setSelectedModalGoalSeason] = useState<number>(
		getInitialGoalDetailsSeason(modalGoal)
	);

	// When modal goal changes, reset season value to initial state
	useEffect(() => {
		setSelectedModalGoalSeason(getInitialGoalDetailsSeason(modalGoal));
	}, [modalGoal]);

	return (
		<>
			<MinorSection title="Goal Details" style={DetailsWrapperStyle}>
				<GoalDetails
					goal={modalGoal}
					selectedGoalSeason={selectedModalGoalSeason}
					setSelectedGoalSeason={setSelectedModalGoalSeason}
					secondaryGoal={secondaryGoal}
					isSeasonValue={true}
					colorSchemeGroup={colorSchemeGroup}
					// Anyone with access to edit a draft goal can add a Tier 2 goal
					canAddTierTwo={true}
					isGoalActionsDisabled={isGoalActionsDisabled}
					setIsSecondaryGoalModalOpen={setIsSecondaryGoalModalOpen}
					setModalPrimaryGoal={setModalPrimaryGoal}
					isEditable={isEditable}
				/>
			</MinorSection>
			<MinorSection
				title={"Focus Areas & Supporting Actions"}
				style={isEditGoalModal ? WrapperStyle : MarginWrapperStyle}
			>
				<TextAlignLeftDiv>
					<FocusAreasWrapper
						goal={modalGoal}
						isReadOnly={!isEditGoalModal}
						focusAreas={focusAreas}
						addFocusArea={addFocusArea}
						isAddingFocusArea={isAddingFocusArea}
						addGoalDrill={addGoalDrill}
						isAddingGoalDrill={isAddingGoalDrill}
						deleteFocusArea={deleteFocusArea}
						circularProgressFocusAreaId={circularProgressFocusAreaId}
						setCircularProgressFocusAreaId={setCircularProgressFocusAreaId}
						circularProgressGoalDrillId={circularProgressGoalDrillId}
						setCircularProgressGoalDrillId={setCircularProgressGoalDrillId}
						deleteGoalDrill={deleteGoalDrill}
						isGoalActionsDisabled={isGoalActionsDisabled}
						colorSchemeGroup={colorSchemeGroup}
						style={style?.focusAreasWrapperStyle}
					/>
				</TextAlignLeftDiv>
			</MinorSection>
			{isEditGoalModal && (
				<MinorSection title={"Adjustments"} style={MarginWrapperStyle}>
					<MaxWidthDiv maxWidth="900px">
						<GoalAdjustments
							goal={modalGoal}
							isGoalActionsDisabled={isGoalActionsDisabled}
							secondaryGoal={secondaryGoal}
							addTarget={addTarget}
							isAddingTarget={isAddingTarget}
							isGroup1={isGroup1}
							isGroup2={isGroup2}
							colorSchemeGroup={colorSchemeGroup}
							style={style?.goalAdjustmentsStyle}
						/>
					</MaxWidthDiv>
				</MinorSection>
			)}
		</>
	);
};
