import React from "react";

import GameSummaryWidget from "_react/gamesummary/GameSummaryWidget";

type TGameReportTabProps = {
	playerId: number;
};

const GameReportsTab = ({ playerId }: TGameReportTabProps) => {
	return <GameSummaryWidget playerId={playerId} openLinksInNewWindow={true} />;
};

export default GameReportsTab;
