import React, { FunctionComponent, useContext } from "react";

import { Form } from "_react/shared/forms";

import { PlayerPageContext } from "_react/playerpage/_context";
import { intlBioPlugin } from "_react/playerpage/bio/_helpers";
import { TIntlBioDocument } from "_react/playerpage/bio/_types";

export const IntlBio: FunctionComponent = () => {
	const { bioDisplayOnly, intlBioDocument, setIntlBioDocument } = useContext(PlayerPageContext);
	if (intlBioDocument == null) return <div />;
	return (
		<Form<TIntlBioDocument>
			document={intlBioDocument}
			plugin={intlBioPlugin}
			onDocumentUpdate={setIntlBioDocument}
			readOnly={bioDisplayOnly}
			showFooter={false}
			floatingFooter={false}
		/>
	);
};

export default IntlBio;
