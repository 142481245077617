import { CancelToken } from "axios";
import dayjs from "dayjs";

import axios from "_redux/_utils/_axios";
import { transaction } from "_react/shared/_types/mesa/transaction";
import { IPlayerBasic } from "_react/shared/data_models/player/_types";
import { handleAxiosError } from "_react/shared/_helpers/axios";

import { DAILY_DRILLS_VIEW, TEAM_DRILLS_VIEW, MISSING_DRILLS_VIEW } from "_react/playerplan/drills_log/_constants";
import { CALL_OTHER_INJURY } from "_react/playerplan/shared/_constants";
import {
	TPlayerPlanGoal,
	TPlayerPlanTarget,
	TPlayerPlanNote,
	TPlayerPlanMetric,
	TPlayerPlanDrill,
	TPlayerPlanGoalDrill,
	TPlayerPlanGoalSplit,
	TPlayerPlanAttachment,
	TGoalForm,
	TGoalDrillForm,
	TNoteForm,
	TTargetForm,
	TSplitsForm,
	TDrillForm,
	TMetricForm,
	TAttachmentForm,
	TNoteFilters,
	TPlayerPlanDrillLog,
	TDrillLogFilters,
	TPlayerPlanMetricAvailableDrill,
	TPlayerPlanMetricAvailableFocusArea,
	TSeasonsForm,
	TPlayerPlanGoalSeason
} from "_react/playerplan/shared/_types";

export const fetchPlayerData = (playerId: number) => {
	const path = `/player?id=${playerId}&isBasicSchema=True&isUseCombinedId=True`;
	return axios
		.get(path)
		.then(response => response.data as Array<IPlayerBasic>)
		.catch(handleAxiosError);
};

export const fetchGoalData = (
	playerId: number,
	includeMetricValues = true,
	statusLks: Array<string> | null = null,
	focusAreaStatusLks: Array<string> | null = null,
	limit: number | null = null,
	isStrength: boolean | null = null,
	isParentGoal: boolean | null = null,
	isPrimaryGoal: boolean | null = null,
	metricTierLks: string | null = null,
	cancelToken?: CancelToken
) => {
	const path = `/playerplan/goal?playerId=${playerId}&includeMetricValues=${includeMetricValues}${
		statusLks ? `&statusLk=${statusLks.join(",")}` : ""
	}${limit ? `&limit=${limit}` : ""}${isStrength != null ? `&isStrength=${isStrength}` : ""}${
		isParentGoal != null ? `&isParentGoal=${isParentGoal}` : ""
	}${isPrimaryGoal != null ? `&isPrimaryGoal=${isPrimaryGoal}` : ""}${
		metricTierLks ? `&metricTierLks=${metricTierLks}` : ""
	}${focusAreaStatusLks != null ? `&focusAreaStatusLks=${focusAreaStatusLks}` : ""}`;
	return axios
		.get(path, { cancelToken })
		.then(response => response.data as Array<TPlayerPlanGoal>)
		.catch(handleAxiosError);
};

export const fetchMetricData = (
	metricTypeLk: string | null = null,
	label: string | null = null,
	metricTierLks: string | null = null,
	cancelToken?: CancelToken
) => {
	const path = `/playerplan/metric?isDeleted=false${metricTypeLk ? `&metricTypeLk=${metricTypeLk}` : ""}${
		label ? `&label=${label}` : ""
	}${metricTierLks ? `&metricTierLks=${metricTierLks}` : ""}`;
	return axios
		.get(path, { cancelToken })
		.then(response => response.data as Array<TPlayerPlanMetric>)
		.catch(handleAxiosError);
};

export const fetchDrillData = (
	id: number | null = null,
	ids: string | null = null,
	metricTypeLk: string | null = null,
	metricSubtypeLk: string | null = null,
	name: string | null = null,
	details: string | null = null,
	videoGcsUri: string | null = null,
	cancelToken?: CancelToken
) => {
	const path = `/playerplan/drill?isDeleted=false${id ? `&id=${id}` : ""}${ids ? `&ids=${ids}` : ""}${
		metricTypeLk ? `&metricTypeLk=${metricTypeLk}` : ""
	}${metricSubtypeLk ? `&metricSubtypeLk=${metricSubtypeLk}` : ""}${name ? `&name=${name}` : ""}${
		details ? `&details=${details}` : ""
	}${videoGcsUri ? `&videoGcsUri=${videoGcsUri}` : ""}`;
	return axios
		.get(path, { cancelToken })
		.then(response => response.data as Array<TPlayerPlanDrill>)
		.catch(handleAxiosError);
};

export const fetchAttachmentData = (playerId: number, cancelToken?: CancelToken) => {
	const path = `/playerplan/attachment?playerId=${playerId}`;
	return axios
		.get(path, { cancelToken })
		.then(response => response.data as Array<TPlayerPlanAttachment>)
		.catch(handleAxiosError);
};

export const fetchTransactionData = (ebisId: number) => {
	const path = `/transactions/ebis_id/${ebisId}`;
	return axios
		.get(path)
		.then(response => response.data as Array<transaction>)
		.catch(handleAxiosError);
};

export const fetchPlayerId = (philId: number, playerClassification: string) => {
	const path = `/player/player_id?philId=${philId}&playerClassification=${playerClassification}`;
	return axios
		.get(path)
		.then(response => response.data.playerId)
		.catch(handleAxiosError);
};

export const fetchNoteData = (noteFilters: TNoteFilters, cancelToken?: CancelToken) => {
	let path = `/playerplan/note?`;
	if (noteFilters.isInjuryOnly) path += `typeLk=${CALL_OTHER_INJURY}&`;
	else if (noteFilters.types) path += `typeLk=${noteFilters.types?.join(",")}&`;
	if (noteFilters.dateFrom)
		path += `dateFrom=${dayjs(noteFilters.dateFrom)
			.utc()
			.format()}&`;
	if (noteFilters.dateTo)
		path += `dateTo=${dayjs(noteFilters.dateTo)
			.add(1, "day")
			.utc()
			.format()}&`;
	if (noteFilters.userId) path += `lastChangeUserId=${noteFilters.userId}&`;
	if (noteFilters.playerId) path += `playerId=${noteFilters.playerId}&`;
	if (noteFilters.isNoContactOnly) path += `isNoContact=${noteFilters.isNoContactOnly.toString()}&`;
	if (noteFilters.isIncorrectContactOnly)
		path += `isIncorrectContact=${noteFilters.isIncorrectContactOnly.toString()}&`;
	if (noteFilters.isLackingEquipmentOnly)
		path += `isLackingEquipment=${noteFilters.isLackingEquipmentOnly.toString()}`;
	return axios
		.get(path, { cancelToken })
		.then(response => response.data as Array<TPlayerPlanNote>)
		.catch(handleAxiosError);
};

export const fetchDrillLog = (filters: TDrillLogFilters, tableView: string, cancelToken?: CancelToken) => {
	let path = "/playerplan/drilllog?";

	if (filters.startDate) path += `startDate=${filters.startDate}&`;
	if (filters.endDate) path += `endDate=${filters.endDate}&`;

	if (tableView === DAILY_DRILLS_VIEW) path += "hasTeamId=false&";
	else if (tableView === TEAM_DRILLS_VIEW) path += "hasTeamId=true&hasPlayerPlanDrillId=true";
	else if (tableView === MISSING_DRILLS_VIEW) path += "hasTeamId=true&hasDescription=true";

	return axios
		.get(path, { cancelToken })
		.then(response => response.data as Array<TPlayerPlanDrillLog>)
		.catch(handleAxiosError);
};

export const addGoal = (playerId: number | null, goalForm: TGoalForm, usesTarget = true) => {
	const path = `/playerplan/goal`;
	const payload = {
		playerId: playerId,
		parentGoalId: goalForm.parentGoalId,
		usesTarget: usesTarget,
		directionLk: goalForm.direction,
		playerPlanMetricId: goalForm.metric,
		value: goalForm.target,
		description: goalForm.description && goalForm.description.trim() ? goalForm.description : undefined,
		secondaryGoal: goalForm.secondaryGoal
			? {
					directionLk: goalForm.secondaryGoal.direction,
					playerPlanMetricId: goalForm.secondaryGoal.metric,
					value: goalForm.secondaryGoal.target,
					description:
						goalForm.secondaryGoal?.description && goalForm.secondaryGoal?.description.trim()
							? goalForm.secondaryGoal.description
							: undefined,
					isActive: true
			  }
			: null,
		splits: goalForm.splits,
		drills: goalForm.drills,
		needsApproval: goalForm.needsApproval,
		isActive: true,
		isStrength: goalForm.isStrength,
		isPriority: goalForm.isPriority,
		isLead: goalForm.isLead,
		season: goalForm.season,
		primaryGoalStatus: goalForm.primaryGoalStatus,
		primaryGoalId: goalForm.primaryGoalId
	};

	return axios
		.post(path, payload)
		.then(response => response.data as Array<TPlayerPlanGoal>)
		.catch(handleAxiosError);
};

export const updateGoal = (
	goal: TPlayerPlanGoal,
	target?: TPlayerPlanTarget,
	updateApprovalDate = false,
	deleteGoalDrillIds?: Array<number>
) => {
	const path = `/playerplan/goal`;
	const payload = {
		id: goal.id,
		parentGoalId: goal.parentGoalId,
		playerId: goal.player.id,
		playerPlanMetricId: goal.playerPlanMetric.id,
		statusLk: goal.status.value,
		rank: goal.rank,
		isPriority: goal.isPriority,
		isLead: goal.isLead,
		isInactive: goal.isInactive,
		isNeedsReview: goal.isNeedsReview,
		target: target ? { ...target, lastChangeUser: undefined, direction: undefined } : undefined,
		updateApprovalDate: updateApprovalDate,
		deleteGoalDrillIds: deleteGoalDrillIds ? deleteGoalDrillIds : undefined
	};
	return axios
		.put(path, payload)
		.then(response => response.data as TPlayerPlanGoal)
		.catch(handleAxiosError);
};

export const rerankGoal = (sourceGoalId: number, destinationGoalId: number) => {
	const path = `/playerplan/goal/rerank`;
	return axios
		.put(path, { sourceGoalId, destinationGoalId })
		.then(response => response.data as Array<TPlayerPlanGoal>)
		.catch(handleAxiosError);
};

export const addNote = (noteForm: TNoteForm) => {
	const path = `/playerplan/note`;
	const formData = new FormData();
	// playerPlanGoalId should either be set to the goal id or the focus area id
	if (noteForm.focusAreaId) formData.append("playerPlanGoalId", noteForm.focusAreaId.toString());
	if (noteForm.playerPlanGoalId && !noteForm.focusAreaId)
		formData.append("playerPlanGoalId", noteForm.playerPlanGoalId.toString());
	if (noteForm.playerPlanDrillId) formData.append("playerPlanDrillId", noteForm.playerPlanDrillId.toString());
	// playerId should only be set if there is no goal id
	if (noteForm.playerId && !noteForm.playerPlanGoalId) formData.append("playerId", noteForm.playerId.toString());
	if (noteForm.metricType) formData.append("metricTypeLk", noteForm.metricType);
	if (noteForm.type) formData.append("typeLk", noteForm.type);
	if (noteForm.content) formData.append("content", noteForm.content);
	if (noteForm.isPositive != null) formData.append("isPositive", noteForm.isPositive.toString());
	if (noteForm.isPrivate != null) formData.append("isPrivate", noteForm.isPrivate.toString());
	if (noteForm.video) formData.append("video", noteForm.video);
	const config = {
		headers: {
			"content-type": "multipart/form-data"
		}
	};
	return axios
		.post(path, formData, config)
		.then(response => response.data as TPlayerPlanNote)
		.catch(handleAxiosError);
};

export const addNotes = (
	noteForms: Array<TNoteForm>,
	formDate: Date | string,
	formNoContact?: boolean,
	formIncorrectContact?: boolean
) => {
	const path = `/playerplan/notes`;
	const formData = new FormData();
	noteForms.forEach((noteForm: TNoteForm, index: number) => {
		if (noteForm.playerPlanGoalId)
			formData.append(`playerPlanGoalId-${index}`, noteForm.playerPlanGoalId.toString());
		if (noteForm.playerId) formData.append(`playerId-${index}`, noteForm.playerId.toString());
		if (noteForm.type) formData.append(`typeLk-${index}`, noteForm.type);
		if (noteForm.content) formData.append(`content-${index}`, noteForm.content);
		if (noteForm.isPositive != null) formData.append(`isPositive-${index}`, noteForm.isPositive.toString());
		if (formNoContact === true) formData.append(`isNoContact-${index}`, formNoContact.toString());
		if (formIncorrectContact === true)
			formData.append(`isIncorrectContact-${index}`, formIncorrectContact.toString());
		if (noteForm.isLackingEquipment != null)
			formData.append(`isLackingEquipment-${index}`, noteForm.isLackingEquipment.toString());
		if (noteForm.video) formData.append(`video-${index}`, noteForm.video);
		// Turn selected date and local time into utc datetime
		formData.append(
			`createDate-${index}`,
			dayjs(`${formDate}T${dayjs().format("HH:mm:ssZ")}`)
				.utc()
				.format()
		);
	});
	formData.append("length", noteForms.length.toString());
	const config = {
		headers: {
			"content-type": "multipart/form-data"
		}
	};
	return axios
		.post(path, formData, config)
		.then(response => response.data as Array<TPlayerPlanNote>)
		.catch(handleAxiosError);
};

export const addTarget = (targetForm: TTargetForm) => {
	const path = `/playerplan/target`;
	const payload = {
		playerPlanGoalId: targetForm.playerPlanGoalId,
		directionLk: targetForm.directionLk ?? undefined,
		value: targetForm.value ? targetForm.value : undefined,
		description: targetForm.description && targetForm.description.trim() ? targetForm.description : undefined
	};

	return axios
		.post(path, payload)
		.then(response => response.data as TPlayerPlanTarget)
		.catch(handleAxiosError);
};

export const addSeasons = (seasonsForm: TSeasonsForm) => {
	const path = `/playerplan/goalseason`;
	const requestSeasonsForm = {
		playerPlanGoalIds: seasonsForm.playerPlanGoalIds,
		seasons: seasonsForm.seasons
	};
	return axios
		.put(path, requestSeasonsForm)
		.then(response => response.data as Array<TPlayerPlanGoalSeason>)
		.catch(handleAxiosError);
};

export const addGoalSplits = (splitsForm: TSplitsForm) => {
	const path = `/playerplan/goalsplit`;
	const requestSplitsForm = {
		playerPlanGoalId: splitsForm.playerPlanGoalId,
		splits: splitsForm.splits ? splitsForm.splits.join(",") : ""
	};
	return axios
		.put(path, requestSplitsForm)
		.then(response => response.data as Array<TPlayerPlanGoalSplit>)
		.catch(handleAxiosError);
};

export const addMetric = (metricForm: TMetricForm) => {
	const path = `/playerplan/metric`;
	const formData = new FormData();
	if (metricForm.metricTypeLk) formData.append("metricTypeLk", metricForm.metricTypeLk);
	if (metricForm.metricSubtypeLk) formData.append("metricSubtypeLk", metricForm.metricSubtypeLk);
	if (metricForm.metricTierLk?.toString()) formData.append("metricTierLk", metricForm.metricTierLk.toString());
	if (metricForm.label) formData.append("label", metricForm.label);
	if (metricForm.abbreviation) formData.append("abbreviation", metricForm.abbreviation);
	formData.append("isQualitative", "true");
	formData.append("isDeleted", "false");
	const config = {
		headers: {
			"content-type": "multipart/form-data"
		}
	};
	return axios
		.post(path, formData, config)
		.then(response => response.data as TPlayerPlanMetric)
		.catch(handleAxiosError);
};

export const updateMetric = (metricForm: TMetricForm) => {
	const path = `/playerplan/metric`;
	const formData = new FormData();
	if (metricForm.id) formData.append("id", metricForm.id.toString());
	if (metricForm.metricTypeLk) formData.append("metricTypeLk", metricForm.metricTypeLk);
	if (metricForm.label) formData.append("label", metricForm.label);
	if (metricForm.abbreviation) formData.append("abbreviation", metricForm.abbreviation);
	formData.append("isQualitative", "true");
	formData.append("isDeleted", "false");
	const config = {
		headers: {
			"content-type": "multipart/form-data"
		}
	};
	return axios
		.put(path, formData, config)
		.then(response => response.data as TPlayerPlanMetric)
		.catch(handleAxiosError);
};

export const deleteMetric = (metricId: number) => {
	const path = `/playerplan/metric?id=${metricId}`;
	return axios
		.delete(path)
		.then(response => response.data as TPlayerPlanMetric)
		.catch(handleAxiosError);
};

export const addFocusAreaAvailableGoalMetrics = (focusAreaMetricId: number, goalMetricIds: Array<number>) => {
	const path = `/playerplan/metric_available_focus_area`;
	const formData = {
		focusAreaMetricId: focusAreaMetricId,
		goalMetricIds: goalMetricIds ? goalMetricIds.join(",") : ""
	};

	return axios
		.put(path, formData)
		.then(response => response.data as TPlayerPlanMetricAvailableFocusArea)
		.catch(handleAxiosError);
};

export const addDrill = (drillForm: TDrillForm) => {
	const path = `/playerplan/drill`;
	const formData = new FormData();
	if (drillForm.metricTypeLk) formData.append("metricTypeLk", drillForm.metricTypeLk);
	if (drillForm.metricSubtypeLk) formData.append("metricSubtypeLk", drillForm.metricSubtypeLk);
	if (drillForm.name) formData.append("name", drillForm.name);
	if (drillForm.details) formData.append("details", drillForm.details);
	if (drillForm.videoGcsUri) formData.append("video", drillForm.videoGcsUri);
	formData.append("isDeleted", "false");
	const config = {
		headers: {
			"content-type": "multipart/form-data"
		}
	};
	return axios
		.post(path, formData, config)
		.then(response => response.data as TPlayerPlanDrill)
		.catch(handleAxiosError);
};

export const updateDrill = (drillForm: TDrillForm) => {
	const path = `/playerplan/drill`;
	const formData = new FormData();
	if (drillForm.id) formData.append("id", drillForm.id.toString());
	if (drillForm.metricTypeLk) formData.append("metricTypeLk", drillForm.metricTypeLk);
	if (drillForm.metricSubtypeLk) formData.append("metricSubtypeLk", drillForm.metricSubtypeLk);
	if (drillForm.name) formData.append("name", drillForm.name);
	if (drillForm.details) formData.append("details", drillForm.details);
	if (drillForm.videoGcsUri) formData.append("video", drillForm.videoGcsUri);
	formData.append("isDeleted", "false");
	const config = {
		headers: {
			"content-type": "multipart/form-data"
		}
	};
	return axios
		.put(path, formData, config)
		.then(response => response.data as TPlayerPlanDrill)
		.catch(handleAxiosError);
};

export const deleteDrill = (drillId: number) => {
	const path = `/playerplan/drill?id=${drillId}`;
	return axios
		.delete(path)
		.then(response => response.data as TPlayerPlanDrill)
		.catch(handleAxiosError);
};

export const addMetricAvailableDrills = (drillId: number, metricIds: Array<number>) => {
	const path = `/playerplan/metric_available_drill`;
	const formData = {
		drillId: drillId,
		metricIds: metricIds ? metricIds.join(",") : ""
	};

	return axios
		.put(path, formData)
		.then(response => response.data as TPlayerPlanMetricAvailableDrill)
		.catch(handleAxiosError);
};

export const addGoalDrill = (goalDrillForm: TGoalDrillForm) => {
	const path = `/playerplan/goaldrill`;
	const parsedGoalDrillForm: TGoalDrillForm = {
		...goalDrillForm,
		content: goalDrillForm.content?.trim() ? goalDrillForm.content?.trim() : null
	};
	return axios
		.post(path, parsedGoalDrillForm)
		.then(response => response.data as TPlayerPlanGoalDrill)
		.catch(handleAxiosError);
};

export const deleteGoalDrill = (goalDrillId: number) => {
	const path = `/playerplan/goaldrill?id=${goalDrillId}`;
	return axios
		.delete(path)
		.then(response => response.data as TPlayerPlanGoalDrill)
		.catch(handleAxiosError);
};

export const addAttachment = (attachmentForm: TAttachmentForm) => {
	const path = `/playerplan/attachment`;
	const formData = new FormData();
	if (attachmentForm.playerId) formData.append("playerId", attachmentForm.playerId.toString());
	if (attachmentForm.file) formData.append("file", attachmentForm.file);
	if (attachmentForm.url) formData.append("url", attachmentForm.url);
	if (attachmentForm.urlName) formData.append("urlName", attachmentForm.urlName);
	const config = {
		headers: {
			"content-type": "multipart/form-data"
		}
	};
	return axios
		.post(path, formData, config)
		.then(response => response.data as TPlayerPlanAttachment)
		.catch(handleAxiosError);
};

export const deleteAttachment = (attachment: TPlayerPlanAttachment) => {
	const path = `/playerplan/attachment?playerId=${attachment.playerId}&gcsUri=${encodeURIComponent(
		attachment.gcsUri
	)}`;
	return axios
		.delete(path)
		.then(response => response.data as TPlayerPlanAttachment)
		.catch(handleAxiosError);
};
