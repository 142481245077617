// Stats Player Fielding Internal Positions
// Pitchers
export const SP = "sp";
export const RP = "rp";

// Hitters
export const C = "c";
export const _1B = "1b";
export const _2B = "2b";
export const _3B = "3b";
export const SS = "ss";
export const LF = "lf";
export const CF = "cf";
export const RF = "rf";

// All Positions
export const ALL_POSITIONS = [C, _1B, _2B, _3B, SS, LF, CF, RF, SP, RP];

// Position Sort Order
export const POSITION_SORT_ORDER: Record<string, number> = {
	[C]: 0,
	[_1B]: 1,
	[_2B]: 2,
	[_3B]: 3,
	[SS]: 4,
	[LF]: 5,
	[CF]: 6,
	[RF]: 7,
	[SP]: 8,
	[RP]: 9
};
