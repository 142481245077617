import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import AutocompleteSuggestionsListItem from "_react/shared/legacy/autocomplete/AutocompleteSuggestionsListItem";

function AutocompleteSuggestionsList(props) {
	const listItems = props.suggestions.map((suggestion, idx) => (
		<AutocompleteSuggestionsListItem
			handleSelected={props.handleSelected}
			hover={props.cursor === idx ? true : false}
			key={idx}
			suggestion={suggestion}
		/>
	));
	if (listItems.length === 0) {
		return <div />;
	}

	return (
		<List
			style={{
				position: "absolute",
				width: props.style.width,
				maxHeight: "350px",
				background: "white",
				overflow: "auto",
				zIndex: 1000000,
				top: props.style.top,
				borderTop: "1px solid rgb(217, 217, 217)",
				borderRight: "1px solid rgb(217, 217, 217)",
				borderBottom: "1px solid rgb(217, 217, 217)",
				borderLeft: "1px solid rgb(217, 217, 217)"
			}}
		>
			{listItems}
		</List>
	);
}

AutocompleteSuggestionsList.propTypes = {
	cursor: PropTypes.number,
	handleSelected: PropTypes.func,
	style: PropTypes.object.isRequired,
	suggestions: PropTypes.array
};

export default AutocompleteSuggestionsList;
