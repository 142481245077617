import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";

import { $TSFixMe, Style } from "utils/tsutils";

type CardContentsProps = {
	children?: $TSFixMe;
	style?: Style;
};

const StyledCardContents = styled.div({
	padding: "12px"
});

export const CardContents: FunctionComponent<CardContentsProps> = ({ children, style = {} }) => {
	return <StyledCardContents style={style}>{children}</StyledCardContents>;
};
