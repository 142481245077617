import {
	FETCH_AGENTS_SUCCESS,
	FETCH_AGENCIES_SUCCESS,
	FETCH_AGENCY_PLAYERS_REQUEST,
	FETCH_AGENCY_PLAYERS_SUCCESS,
	FETCH_AGENCY_PLAYERS_FAILURE,
	FETCH_AGENT_PLAYERS_REQUEST,
	FETCH_AGENT_PLAYERS_SUCCESS,
	FETCH_AGENT_PLAYERS_FAILURE,
	CREATE_AGENCY_SUCCESS,
	CREATE_AGENT_SUCCESS
} from "_redux/Agent/_types";
import { combineNormalizedData, combineFetchingData, combineFailedData } from "_redux/_utils/functions";
import { AGENT_METADATA, AGENCY_METADATA } from "_redux/Agent/_helpers";

export const INITIAL_STATE = {
	agentsFetched: false,
	agenciesFetched: false,
	agents: {
		agent_id: {}
	},
	agencies: {
		agency_id: {}
	},
	agencyPlayers: {
		fetching: {},
		failed: {},
		data: {}
	},
	agentPlayers: {
		fetching: {},
		failed: {},
		data: {}
	}
};

const agent = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_AGENTS_SUCCESS:
			return {
				...state,
				agents: action.payload,
				agentsFetched: true
			};
		case FETCH_AGENCIES_SUCCESS:
			return {
				...state,
				agencies: action.payload,
				agenciesFetched: true
			};
		case FETCH_AGENCY_PLAYERS_REQUEST:
			return {
				...state,
				agencyPlayers: {
					...state.agencyPlayers,
					fetching: combineFetchingData(state.agencyPlayers.fetching, action.payload)
				}
			};
		case FETCH_AGENCY_PLAYERS_SUCCESS:
			return {
				...state,
				agencyPlayers: {
					...state.agencyPlayers,
					data: {
						...state.agencyPlayers.data,
						[action.payload.agencyId]: action.payload.data
					},
					fetching: combineFetchingData(state.agencyPlayers.fetching, action.payload.fetching),
					failed: combineFailedData(state.agencyPlayers.failed, action.payload.fetching, true)
				}
			};
		case FETCH_AGENCY_PLAYERS_FAILURE:
			return {
				...state,
				agencyPlayers: {
					...state.agencyPlayers,
					fetching: combineFetchingData(state.agencyPlayers.fetching, action.payload.fetching),
					failed: combineFailedData(state.agencyPlayers.failed, action.payload.fetching, false)
				}
			};
		case FETCH_AGENT_PLAYERS_REQUEST:
			return {
				...state,
				agentPlayers: {
					...state.agentPlayers,
					fetching: combineFetchingData(state.agentPlayers.fetching, action.payload)
				}
			};
		case FETCH_AGENT_PLAYERS_SUCCESS:
			return {
				...state,
				agentPlayers: {
					...state.agentPlayers,
					data: {
						...state.agentPlayers.data,
						[action.payload.agentId]: action.payload.data
					},
					fetching: combineFetchingData(state.agentPlayers.fetching, action.payload.fetching),
					failed: combineFailedData(state.agentPlayers.failed, action.payload.fetching, true)
				}
			};
		case FETCH_AGENT_PLAYERS_FAILURE:
			return {
				...state,
				agentPlayers: {
					...state.agentPlayers,
					fetching: combineFetchingData(state.agentPlayers.fetching, action.payload.fetching),
					failed: combineFailedData(state.agentPlayers.failed, action.payload.fetching, false)
				}
			};
		case CREATE_AGENT_SUCCESS:
			return {
				...state,
				agents: combineNormalizedData(state.agents, action.payload, AGENT_METADATA)
			};
		case CREATE_AGENCY_SUCCESS:
			return {
				...state,
				agencies: combineNormalizedData(state.agencies, action.payload, AGENCY_METADATA)
			};
		default:
			return state;
	}
};

export default agent;
