export const PLAYER_CLASSIFICATION_AMA = "ama";

export const PITCH_USAGE_METRIC_GROUP = "pitch_usage";
export const STUFF_METRIC_GROUP = "stuff";
export const PITCH_MOVEMENT_GROUP = "pitch_movement";
export const PITCH_VELOCITY_METRIC_GROUP = "pitch_velocity";
export const RELEASE_POINT_GROUP = "release_point";

export const STUFF_METRIC_ID = 1;
export const CH_USAGE_METRIC_ID = 2;
export const CB_USAGE_METRIC_ID = 3;
export const FF_USAGE_METRIC_ID = 4;
export const SI_USAGE_METRIC_ID = 5;
export const SL_USAGE_METRIC_ID = 6;
export const SP_USAGE_METRIC_ID = 7;
export const CT_USAGE_METRIC_ID = 8;
export const SW_USAGE_METRIC_ID = 12;
export const SV_USAGE_METRIC_ID = 13;
export const KN_USAGE_METRIC_ID = 14;

export const PITCH_USAGE_METRIC_IDS = [
	CH_USAGE_METRIC_ID,
	CB_USAGE_METRIC_ID,
	FF_USAGE_METRIC_ID,
	SI_USAGE_METRIC_ID,
	SL_USAGE_METRIC_ID,
	SP_USAGE_METRIC_ID,
	CT_USAGE_METRIC_ID,
	SW_USAGE_METRIC_ID,
	SV_USAGE_METRIC_ID,
	KN_USAGE_METRIC_ID
];
export const PITCH_MOVEMENT_ID = 10;
export const RELEASE_POINT_ID = 11;
