import React, { FC, useContext, useState, useEffect, CSSProperties } from "react";
import dayjs from "dayjs";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { TransactionsTable } from "_react/playerpage/transaction/TransactionsTable";
import { TransactionDetails } from "_react/playerpage/transaction/TransactionDetails";
import { TransactionContext, TransactionProvider } from "_react/playerpage/transaction/_context";
import { Button } from "_react/shared/legacy/ui/Button";
import { transaction as TTransaction } from "_react/shared/_types/mesa/transaction";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";

type TTransactionsStyle = {
	cardHeader?: CSSProperties;
};

type TransactionsProps = {
	colorScheme: ColorSchemeGroup;
	player: TPlayerPageCombinedPlayer;
	style?: TTransactionsStyle;
};

const TransactionsComp: FC<TransactionsProps> = ({ colorScheme, player, style }) => {
	const filteredTransactions = player.proProfile?.transactions.filter(
		transaction =>
			transaction.transactionName !== "Disabled List Recertification" &&
			transaction.transactionName !== "Injured List Recertification"
	);
	const history = useHistory();
	const location = useLocation();
	const { getTransaction } = useContext(TransactionContext);

	const rawTransactionId = queryString.parse(location.search).transactionId;
	const transactionId = rawTransactionId !== undefined ? parseInt(rawTransactionId as string, 10) : 0;

	const [transaction, setTransaction] = useState<TTransaction>();

	useEffect(() => {
		if (transactionId !== 0) {
			getTransaction(transactionId).then((newTransaction: TTransaction) => {
				setTransaction(newTransaction);
			});
		}
	}, [transactionId, getTransaction, setTransaction]);

	return (
		<React.Fragment>
			<TransactionsTable
				colorScheme={colorScheme}
				player={player}
				selectedTransactionId={transactionId}
				transactions={filteredTransactions}
				style={{ cardHeader: style?.cardHeader }}
			/>
			{transactionId !== 0 && (
				<div style={{ marginTop: "10px" }}>
					<TransactionDetails
						colorScheme={colorScheme}
						transaction={transaction}
						style={{ cardHeader: style?.cardHeader }}
					/>
					{transaction != null && (transaction as TTransaction).injury != null && (
						<div style={{ marginTop: "10px", textAlign: "center" }}>
							<Button
								onClick={() =>
									history.push(
										`/player?philId=${player.playerProId}&view=injuries&injuryId=${
											(transaction as TTransaction).transaction_id
										}&injurySource=EBIS&year=${dayjs(
											transaction.date
										).year()}&viewClassification=pro`
									)
								}
								title="View Injury"
							/>
						</div>
					)}
				</div>
			)}
		</React.Fragment>
	);
};

export const Transactions: FC<TransactionsProps> = props => (
	<TransactionProvider>
		<TransactionsComp {...props} />
	</TransactionProvider>
);
