import { UseBreakpointOptions, useBreakpointValue as chakraUseBreakpointValue } from "@chakra-ui/react";

import { $TSAnyRequired } from "utils/tsutils";

export const convertRemTokenToPixelInt = (remToken: number) => {
	const remUnit = parseInt(getComputedStyle(document.documentElement).fontSize);
	return parseInt(remToken.toString()) * remUnit;
};

// Wraps the Chakra implementation of useBreakpointValue, but provides `ssr: false` by default
export const useBreakpointValue = <T = $TSAnyRequired>(
	values: Partial<Record<string, T>> | Array<T | null>,
	arg?: UseBreakpointOptions // Technically this should be `UseBreakpointOptions | string`, but only want to accept objects
): T | undefined => {
	return chakraUseBreakpointValue(values, { ssr: false, ...arg });
};
