import React, { useState, useEffect, useMemo } from "react";

import MetricSelect, { TMetricOption } from "_react/shared/selects/MetricSelect";
import { Button } from "_react/shared/legacy/ui/Button";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import Modal from "_react/shared/legacy/ui/Modal";

import { FOCUS_AREA_TIER } from "_react/playerplan/metrics/_constants";
import {
	CANCELED,
	DEFAULT_ADD_GOAL_FORM,
	DISABLED_OPACITY,
	IN_PROGRESS,
	NON_BASEBALL_METRIC_LKS
} from "_react/playerplan/shared/_constants";
import {
	AddFADiv,
	ExpandCollapseAllDiv,
	FocusAreasOptionsContainer,
	NoFocusAreaDiv,
	RightButtonStyle,
	ShowAllDeletedDiv,
	SmallCircularProgressStyle,
	WideMetricSelectContainer
} from "_react/playerplan/shared/_styles";
import { TGoalDrillForm, TGoalForm, TPlayerPlanGoal, TPlayerPlanGoalDrill } from "_react/playerplan/shared/_types";
import { AddGoalDrillModal, TAddGoalDrillModalStyle } from "_react/playerplan/shared/AddGoalDrillModal";
import { FocusArea } from "_react/playerplan/shared/FocusArea";
import { filterFastDeletes } from "_react/playerplan/shared/_helpers";

export type TFocusAreasWrapperStyle = {
	addGoalDrillModalStyle?: TAddGoalDrillModalStyle;
};

export type TFocusAreasWrapperProps = {
	goal: TPlayerPlanGoal;
	isReadOnly: boolean;
	focusAreas?: TPlayerPlanGoal[];
	addFocusArea: (focusAreaForm: TGoalForm) => void;
	isAddingFocusArea: boolean;
	addGoalDrill: (goalDrillForm: TGoalDrillForm) => void;
	isAddingGoalDrill: boolean;
	deleteFocusArea: (goal: TPlayerPlanGoal, deleteGoalDrillIds: Array<number>) => void;
	circularProgressFocusAreaId: number | null;
	setCircularProgressFocusAreaId: (focusAreaId: number) => void;
	circularProgressGoalDrillId: number | null;
	setCircularProgressGoalDrillId: (goalDrillId: number) => void;
	deleteGoalDrill: (goalDrillId: number) => void;
	isGoalActionsDisabled: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TFocusAreasWrapperStyle;
};

export const FocusAreasWrapper = ({
	goal,
	isReadOnly,
	focusAreas,
	addFocusArea,
	isAddingFocusArea,
	addGoalDrill,
	isAddingGoalDrill,
	deleteFocusArea,
	circularProgressFocusAreaId,
	setCircularProgressFocusAreaId,
	circularProgressGoalDrillId,
	setCircularProgressGoalDrillId,
	deleteGoalDrill,
	isGoalActionsDisabled,
	colorSchemeGroup = defaultColorScheme,
	style
}: TFocusAreasWrapperProps) => {
	const [newFocusAreaId, setNewFocusAreaId] = useState<number>();
	const [isDeletedVisible, setIsDeletedVisible] = useState(false);
	const [collapse, setCollapse] = useState(false);

	const [isGoalDrillModalOpen, setIsGoalDrillModalOpen] = useState<boolean>(false);
	const [modalFocusArea, setModalFocusArea] = useState<TPlayerPlanGoal | null>(null);

	useEffect(() => {
		setCollapse(false);
		setNewFocusAreaId(undefined);
	}, [goal, focusAreas]);

	const sortedFocusAreas: TPlayerPlanGoal[] | undefined = focusAreas?.sort(
		(a: TPlayerPlanGoal, b: TPlayerPlanGoal) => {
			// Sort in progress Focus Areas before canceled Focus Areas
			if (a.status.value !== b.status.value) {
				return a.status.value === IN_PROGRESS ? -1 : 1;
			}

			// Sort the Focus Areas that match the Goal metric type lk to the top of the list
			if (
				a.playerPlanMetric.metricTypeLk === goal.playerPlanMetric.metricTypeLk &&
				b.playerPlanMetric.metricTypeLk === goal.playerPlanMetric.metricTypeLk
			) {
				return a.playerPlanMetric.label.localeCompare(b.playerPlanMetric.label);
			}
			if (a.playerPlanMetric.metricTypeLk === goal.playerPlanMetric.metricTypeLk) return -1;
			if (b.playerPlanMetric.metricTypeLk === goal.playerPlanMetric.metricTypeLk) return 1;

			// Then, sort the rest by metric type
			const metricTypeComparison = a.playerPlanMetric.metricTypeLk.localeCompare(b.playerPlanMetric.metricTypeLk);
			if (metricTypeComparison) return metricTypeComparison;

			// If metric types are the same, sort based on metric label
			return a.playerPlanMetric.label.localeCompare(b.playerPlanMetric.label);
		}
	);

	const nonDeletedFocusAreas: number = useMemo(() => {
		if (focusAreas) {
			return focusAreas.reduce(
				(total: number, focusArea: TPlayerPlanGoal) => total + Number(focusArea.status.value !== CANCELED),
				0
			);
		}
		return 0;
	}, [focusAreas]);

	const fastDeletedFocusAreas: number = useMemo(() => {
		if (focusAreas) {
			return focusAreas.reduce(
				(total: number, focusArea: TPlayerPlanGoal) =>
					total +
					Number(
						focusArea.status.value === CANCELED &&
							!filterFastDeletes(focusArea.createDate, focusArea.lastChangeDate)
					),
				0
			);
		}
		return 0;
	}, [focusAreas]);

	const viableDeletedSupportingActions: number = useMemo(() => {
		if (focusAreas) {
			return focusAreas.reduce(
				(total: number, focusArea: TPlayerPlanGoal) =>
					total +
					focusArea.playerPlanDrills.reduce(
						(subTotal: number, goalDrill: TPlayerPlanGoalDrill) =>
							subTotal +
							Number(
								goalDrill.isDeleted && filterFastDeletes(goalDrill.createDate, goalDrill.lastChangeDate)
							),
						0
					),
				0
			);
		}
		return 0;
	}, [focusAreas]);

	// This is essentially calculating the number of deleted items that weren't deleted in a 24 hour window
	const viableTotalDeletedItems: number = useMemo(() => {
		if (focusAreas) {
			return Math.max(
				focusAreas.length - nonDeletedFocusAreas - fastDeletedFocusAreas + viableDeletedSupportingActions,
				0
			);
		}
		return 0;
	}, [focusAreas, nonDeletedFocusAreas, fastDeletedFocusAreas, viableDeletedSupportingActions]);

	return (
		<>
			<FocusAreasOptionsContainer>
				{((isDeletedVisible &&
					sortedFocusAreas?.length !== undefined &&
					sortedFocusAreas?.length - fastDeletedFocusAreas > 1) ||
					(!isDeletedVisible && nonDeletedFocusAreas > 1)) && (
					<ExpandCollapseAllDiv onClick={() => setCollapse(!collapse)}>
						{`${collapse ? "Collapse" : "Expand"} All`}
					</ExpandCollapseAllDiv>
				)}
				{viableTotalDeletedItems > 0 && (
					<ShowAllDeletedDiv onClick={() => setIsDeletedVisible(!isDeletedVisible)}>
						{`${isDeletedVisible ? "Hide" : "Show"} Deleted`}
					</ShowAllDeletedDiv>
				)}
			</FocusAreasOptionsContainer>
			<div>
				{((!isDeletedVisible && nonDeletedFocusAreas === 0) ||
					(isDeletedVisible &&
						(sortedFocusAreas?.length === undefined ||
							(sortedFocusAreas?.length !== undefined &&
								sortedFocusAreas?.length - fastDeletedFocusAreas <= 0)))) && (
					<NoFocusAreaDiv>No Focus Areas Yet</NoFocusAreaDiv>
				)}
				{sortedFocusAreas
					?.filter((focusArea: TPlayerPlanGoal) => {
						if (isDeletedVisible) {
							if (focusArea.status.value === CANCELED) {
								return filterFastDeletes(focusArea.createDate, focusArea.lastChangeDate);
							} else {
								return true;
							}
						} else {
							return focusArea.status.value !== CANCELED;
						}
					})
					.map((focusArea: TPlayerPlanGoal) => (
						<FocusArea
							focusArea={focusArea}
							isReadOnly={isReadOnly}
							collapse={collapse}
							key={focusArea.id}
							deleteFocusArea={deleteFocusArea}
							circularProgressFocusAreaId={circularProgressFocusAreaId}
							setCircularProgressFocusAreaId={setCircularProgressFocusAreaId}
							isGoalActionsDisabled={isGoalActionsDisabled}
							setIsGoalDrillModalOpen={setIsGoalDrillModalOpen}
							setModalFocusArea={setModalFocusArea}
							circularProgressGoalDrillId={circularProgressGoalDrillId}
							setCircularProgressGoalDrillId={setCircularProgressGoalDrillId}
							deleteGoalDrill={deleteGoalDrill}
							colorSchemeGroup={colorSchemeGroup}
							showDeleted={isDeletedVisible}
						/>
					))}
			</div>
			{!isReadOnly && (
				<div>
					<AddFADiv>
						<WideMetricSelectContainer>
							<MetricSelect
								isClearable
								metricTypes={[goal.playerPlanMetric.metricTypeLk, ...NON_BASEBALL_METRIC_LKS]}
								metricTiers={[FOCUS_AREA_TIER]}
								handleSelect={(opt?: TMetricOption) => {
									setNewFocusAreaId(opt?.value);
								}}
								value={newFocusAreaId ? [newFocusAreaId] : null}
								placeholder={"Select Focus Area..."}
								menuPlacement="top"
								shouldUseAvailabilityMap={true}
								goalMetricId={goal.playerPlanMetric.id}
								excludeMetricIds={
									focusAreas
										?.filter((focusArea: TPlayerPlanGoal) => {
											return focusArea.status.value === IN_PROGRESS;
										})
										.map((focusArea: TPlayerPlanGoal) => focusArea.playerPlanMetric.id) ?? []
								}
								isDisabled={isGoalActionsDisabled}
							/>
						</WideMetricSelectContainer>
						{isAddingFocusArea ? (
							<CircularProgress center={false} style={SmallCircularProgressStyle} />
						) : (
							<Button
								title={"Add New"}
								disabled={newFocusAreaId === undefined || isGoalActionsDisabled}
								onClick={() => {
									const metricId = newFocusAreaId;
									addFocusArea({
										...DEFAULT_ADD_GOAL_FORM,
										parentGoalId: goal.id,
										metric: metricId ?? null,
										needsApproval: false
									});
									setNewFocusAreaId(undefined);
								}}
								style={RightButtonStyle}
								colorScheme={colorSchemeGroup.secondary}
								disabledOpacity={DISABLED_OPACITY}
							/>
						)}
					</AddFADiv>
				</div>
			)}
			<Modal
				isOpen={isGoalDrillModalOpen}
				onRequestClose={() => {
					setIsGoalDrillModalOpen(false);
					setModalFocusArea(null);
				}}
				showX
			>
				{modalFocusArea ? (
					<AddGoalDrillModal
						goal={goal}
						focusArea={modalFocusArea}
						addGoalDrill={addGoalDrill}
						isAddingGoalDrill={isAddingGoalDrill}
						isGoalActionsDisabled={isGoalActionsDisabled}
						colorSchemeGroup={colorSchemeGroup}
						style={style?.addGoalDrillModalStyle}
						setIsGoalDrillModalOpen={setIsGoalDrillModalOpen}
						setModalFocusArea={setModalFocusArea}
					/>
				) : (
					undefined
				)}
			</Modal>
		</>
	);
};
