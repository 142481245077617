import { assign, Machine } from "xstate";
import { CancelTokenSource } from "axios";
import dayjs from "dayjs";

import { promiseWRetry } from "utils/helpers";
import { TJsonMessage } from "utils/tsutils";
import { getCancelSource } from "utils/url_helpers";
import { createNote, deleteNote, updateNote } from "_react/shared/data_models/notes/_network";

import { TRockyNote, TRockyNoteStructure } from "_react/shared/data_models/notes/_types";
import { preProcessNote, exportNoteForSaving } from "_react/playerpage/notes/_helpers";
import { TEnqueueSnackbar } from "_react/shared/_helpers/snackbar";

interface IContext {
	cancelSource?: CancelTokenSource;
	enqueueSnackbar: TEnqueueSnackbar;
	note: TRockyNote;
	originalNote: TRockyNote;
	structure: TRockyNoteStructure[];
}

interface IStateSchema {
	states: {
		preProcessing: {};
		viewing: {};
		editing: {};
		saving: {};
		saveErrored: {};
		deleting: {};
		deleteErrored: {};
	};
}

export const SAVE_NOTE = "SAVE_NOTE";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const EDIT_NOTE = "EDIT_NOTE";
export const CANCEL_EDIT_NOTE = "CANCEL_EDIT_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const RESTORE_NOTE = "RESTORE_NOTE";
export const PUBLISH_NOTE = "PUBLISH_NOTE";
const SAVE_NOTE_SERVICE_DONE = "done.invoke.saveNoteService";
const DELETE_NOTE_SERVICE_DONE = "done.invoke.deleteNoteService";
type TSaveNoteEvent = { type: typeof SAVE_NOTE; data: TRockyNote };
type TUpdateNoteEvent = { type: typeof UPDATE_NOTE; data: TRockyNote };
type TDeleteNoteEvent = { type: typeof DELETE_NOTE; data: string };
type TEditNoteEvent = { type: typeof EDIT_NOTE };
type TCancelEditNoteEvent = { type: typeof CANCEL_EDIT_NOTE };
type TSaveNoteDoneEvent = { type: typeof SAVE_NOTE_SERVICE_DONE; data?: TRockyNote };
type TDeleteNoteDoneEvent = { type: typeof DELETE_NOTE_SERVICE_DONE; data?: TJsonMessage };
type TRestoreNoteEvent = { type: typeof RESTORE_NOTE };
type TPublishNoteEvent = { type: typeof PUBLISH_NOTE };

type TEvent =
	| TSaveNoteEvent
	| TUpdateNoteEvent
	| TEditNoteEvent
	| TCancelEditNoteEvent
	| TSaveNoteDoneEvent
	| TDeleteNoteEvent
	| TDeleteNoteDoneEvent
	| TRestoreNoteEvent
	| TPublishNoteEvent;

const createMachine = (
	note: TRockyNote,
	structure: TRockyNoteStructure[],
	relayUpdatedNote: (note: TRockyNote) => void,
	relayDeleteNote: (noteId: string) => void,
	enqueueSnackbar: TEnqueueSnackbar
) =>
	Machine<IContext, IStateSchema, TEvent>(
		{
			id: "rocky-notes-machine",
			context: {
				note,
				originalNote: note,
				structure,
				enqueueSnackbar
			},
			initial: "preProcessing",
			states: {
				preProcessing: {
					always: [
						{ actions: "preProcessNote", target: "editing", cond: "isNewNote" },
						{ actions: "preProcessNote", target: "viewing" }
					]
				},
				viewing: {
					on: {
						[EDIT_NOTE]: { target: "editing" },
						[DELETE_NOTE]: { target: "deleting" },
						[RESTORE_NOTE]: { actions: "restoreNote", target: "saving" }
					}
				},
				editing: {
					on: {
						[UPDATE_NOTE]: { actions: "updateNote" },
						[PUBLISH_NOTE]: { actions: "publishNote", target: "saving" },
						[CANCEL_EDIT_NOTE]: { actions: "cancelEditNote", target: "viewing" },
						[SAVE_NOTE]: { target: "saving" }
					}
				},
				saving: {
					entry: ["getNewCancelSource"],
					invoke: {
						id: "saveNoteService",
						src: "saveNote",
						onDone: {
							target: "preProcessing",
							actions: "handleSaveNoteSuccess"
						},
						onError: {
							target: "saveErrored",
							actions: "handleSaveNoteError"
						}
					}
				},
				saveErrored: {},
				deleting: {
					entry: ["getNewCancelSource"],
					invoke: {
						id: "deleteNoteService",
						src: "deleteNote",
						onDone: {
							target: "viewing",
							actions: "handleDeleteNoteSuccess"
						},
						onError: {
							target: "deleteErrored",
							actions: "handleDeleteNoteError"
						}
					}
				},
				deleteErrored: {}
			}
		},
		{
			actions: {
				preProcessNote: assign({
					originalNote: (context, _event) => preProcessNote(context.originalNote, context.structure),
					note: (context, _event) => preProcessNote(context.note, context.structure)
				}),
				getNewCancelSource: assign({
					cancelSource: (_context, _event) => getCancelSource()
				}),
				cancelNoteSave: (context, _event) => {
					context.cancelSource?.cancel();
				},
				updateNote: assign({
					note: (context, event) => {
						if (event.type !== UPDATE_NOTE) return context.note;
						return event.data;
					}
				}),
				publishNote: assign({
					note: (context, event) => {
						if (event.type !== PUBLISH_NOTE) return context.note;
						return { ...context.note, publishDate: dayjs().format("YYYY-MM-DD HH:mm:ss") };
					}
				}),
				cancelEditNote: assign({
					note: (context, event) => {
						if (event.type !== CANCEL_EDIT_NOTE) return context.note;
						if (context.note.isBeingCreated === true) relayDeleteNote(context.note.noteId);
						return context.originalNote;
					}
				}),
				restoreNote: assign({
					originalNote: (context, event) => {
						if (event.type !== RESTORE_NOTE) return context.originalNote;
						return { ...context.originalNote, recordStatus: "AP" };
					},
					note: (context, event) => {
						if (event.type !== RESTORE_NOTE) return context.note;
						return { ...context.note, recordStatus: "AP" };
					}
				}),
				handleSaveNoteSuccess: assign({
					originalNote: (context, event) => {
						if (event.type !== SAVE_NOTE_SERVICE_DONE || event?.data === undefined)
							return context.originalNote;
						return event.data;
					},
					note: (context, event) => {
						if (event.type !== SAVE_NOTE_SERVICE_DONE || event?.data === undefined) return context.note;
						relayUpdatedNote(event.data);
						return event.data;
					},
					cancelSource: (_context, _event) => undefined
				}),
				handleSaveNoteError: assign({
					originalNote: (context, event) => {
						if (event.type !== SAVE_NOTE_SERVICE_DONE) return context.originalNote;
						return context.originalNote;
					},
					note: (context, event) => {
						if (event.type !== SAVE_NOTE_SERVICE_DONE) return context.note;
						return context.originalNote;
					},
					cancelSource: (context, _event) => {
						const { enqueueSnackbar } = context;
						enqueueSnackbar("Error saving note. Please refresh the page", { variant: "error" });
						return undefined;
					}
				}),
				handleDeleteNoteSuccess: assign({
					originalNote: (context, event) => {
						if (event.type !== DELETE_NOTE_SERVICE_DONE || event?.data === undefined)
							return context.originalNote;
						return { ...context.note, recordStatus: "DL" };
					},
					note: (context, event) => {
						if (event.type !== DELETE_NOTE_SERVICE_DONE || event?.data === undefined) return context.note;
						relayDeleteNote(context.note.noteId);
						return { ...context.note, recordStatus: "DL" };
					},
					cancelSource: (_context, _event) => undefined
				}),
				handleDeleteNoteError: assign({
					cancelSource: (context, _event) => {
						const { enqueueSnackbar } = context;
						enqueueSnackbar("Error deleting note. Please refresh the page", { variant: "error" });
						return undefined;
					}
				})
			},
			guards: {
				isNewNote: (context, _event) => {
					const { note } = context;
					return note.isBeingCreated === true;
				}
			},
			services: {
				saveNote: (context, _event) => {
					const { note, structure, cancelSource } = context;
					const cancelToken = cancelSource?.token;
					const noteJson = exportNoteForSaving(note, structure);
					const fetchFunc = note.isBeingCreated
						? () => createNote(noteJson, undefined, cancelToken)
						: () => updateNote(noteJson, undefined, cancelToken);
					return promiseWRetry(fetchFunc);
				},
				deleteNote: (context, _event) => {
					const { note, cancelSource } = context;
					const cancelToken = cancelSource?.token;
					const noteId = note.noteId;
					const fetchFunc = () => deleteNote({ noteId: noteId }, cancelToken);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default createMachine;
