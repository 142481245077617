import React, { useEffect, useState, useMemo } from "react";
import { HStack, Text } from "@chakra-ui/react";

import { useFetch } from "utils/url_helpers";
import { StyledSelect } from "_react/shared/selects";
import { METRIC_SUBTYPE_TO_ICON_MAPPING } from "_react/playerplan/shared/_constants";
import { TLkPlayerPlanMetricType, TLkPlayerPlanMetricSubtype } from "_react/playerplan/shared/_types";

export type TMetricSubtypeOption = {
	label: string;
	rawLabel: string;
	value: string;
	metricType: TLkPlayerPlanMetricType;
};

export type TMetricSubtypeValue = {
	value: string;
	lkMetricType: string;
};

type TMetricSubtypeSelect = {
	handleSelect: Function;
	metricTypes?: Array<string>;
	placeholder?: string;
	value?: TMetricSubtypeValue | null;
	isDisabled?: boolean;
	isClearable?: boolean;
	menuPlacement?: string;
};

function MetricSubtypeSelect({
	handleSelect,
	metricTypes,
	placeholder = "Select metric subtype...",
	value,
	isDisabled = false,
	isClearable = true,
	menuPlacement
}: TMetricSubtypeSelect) {
	const { responseData: metricSubtypeResponseData, isInProgress } = useFetch(
		`lk?lkTableName=lk_player_plan_metric_subtype&dbName=phil_data`,
		[]
	);
	metricSubtypeResponseData.sort((a: TLkPlayerPlanMetricSubtype, b: TLkPlayerPlanMetricSubtype) => {
		if (a.metricType.label.toLowerCase() < b.metricType.label.toLowerCase()) return -1;
		else if (a.metricType.label.toLowerCase() > b.metricType.label.toLowerCase()) return 1;
		else {
			if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
			else if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
			return 0;
		}
	});

	const [metricSubtypeDataSource, setMetricSubtypeDataSource] = useState([]);

	useEffect(() => {
		if (metricSubtypeResponseData) {
			setMetricSubtypeDataSource(
				metricSubtypeResponseData.map((metricSubtype: TLkPlayerPlanMetricSubtype) => ({
					label: `${metricSubtype.label} (${metricSubtype.metricType.abbreviation})`,
					rawLabel: metricSubtype.label,
					value: metricSubtype.value,
					metricType: metricSubtype.metricType
				}))
			);
		}
	}, [metricSubtypeResponseData, setMetricSubtypeDataSource]);

	const metric = useMemo(() => {
		if (metricSubtypeDataSource.length && value) {
			return (
				metricSubtypeDataSource.find(
					(metric: TMetricSubtypeOption) =>
						metric.value === value.value && metric.metricType.value === value.lkMetricType
				) ?? null
			);
		}
		return null;
	}, [metricSubtypeDataSource, value]);

	const valueProps = value === undefined ? {} : metric ? { value: metric } : { value: null };

	const isOptionSelected: (option: TMetricSubtypeOption, selectValues: TMetricSubtypeOption[]) => boolean = (
		option,
		selectValues
	) =>
		selectValues.some(
			(val: TMetricSubtypeOption) =>
				val?.value === option.value && val?.metricType.value === option.metricType.value
		);

	return (
		<StyledSelect
			options={metricSubtypeDataSource.filter(
				(opt: TMetricSubtypeOption) => metricTypes === undefined || metricTypes.includes(opt.metricType.value)
			)}
			formatOptionLabel={(option: TMetricSubtypeOption) => (
				<HStack>
					{METRIC_SUBTYPE_TO_ICON_MAPPING[option.value]}
					<Text>{option.label}</Text>
				</HStack>
			)}
			onChange={handleSelect}
			placeholder={placeholder}
			isClearable={isClearable}
			isDisabled={isDisabled}
			isLoading={isInProgress}
			{...valueProps}
			styles={{ container: { width: "100%" } }}
			menuPlacement={menuPlacement}
			isOptionSelected={isOptionSelected}
		/>
	);
}

export default MetricSubtypeSelect;
