import { chakra, Flex } from "@chakra-ui/react";
import { CSSObjectWithLabel } from "react-select";
import { StylesConfig } from "react-select";

import { TSearchResult } from "_react/shared/searches/_types";

export const searchStyles: (color: string) => StylesConfig<TSearchResult, boolean> = color => ({
	control: (_provided: CSSObjectWithLabel, state) => ({
		display: "flex",
		transition: "all 100ms",
		border: "1px solid gray",
		borderRadius: 4,
		backgroundColor: state.isFocused ? "lightGray" : "clear",
		color: state.isFocused ? "black" : "white",
		":hover": {
			backgroundColor: "lightGray",
			color: "black",
			cursor: "pointer"
		}
	}),
	menu: (provided: CSSObjectWithLabel) => ({
		...provided,
		borderRadius: 6,
		fontSize: 14,
		boxShadow: "1px 2px 2px 1px rgba(0, 0, 0, 0.2)",
		zIndex: 1005
	}),
	downChevron: (provided: CSSObjectWithLabel) => ({
		...provided,
		display: "none"
	}),
	dropdownIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		display: "none"
	}),
	indicatorSeparator: (provided: CSSObjectWithLabel) => ({
		...provided,
		display: "none"
	}),
	input: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontSize: 16,
		padding: 0,
		":hover": {
			color: "black"
		}
	}),
	loadingIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: color
	}),
	option: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: "black",
		cursor: "pointer",
		fontSize: 18
	}),
	placeholder: (provided: CSSObjectWithLabel, state) => ({
		...provided,
		color: state.isFocused ? "black" : undefined,
		fontSize: 16,
		fontWeight: 400,
		opacity: 0.5
	}),
	singleValue: (provided: CSSObjectWithLabel, state) => ({
		...provided,
		display: state.selectProps.menuIsOpen ? "none" : "block",
		color: color,
		fontSize: 16
	}),
	menuPortal: (provided: CSSObjectWithLabel) => ({
		...provided,
		zIndex: 9999,
		borderRadius: 6
	}),
	valueContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontWeight: 400,
		padding: "2px 4px"
	})
});

export const NavigationResultStyle = (selected = false) => {
	return {
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "white",
		border: "1px solid #d3d3d3",
		borderRadius: 4,
		paddingLeft: 3,
		paddingRight: 3,
		marginLeft: 2,
		":hover": {
			backgroundColor: "#d3d3d3"
		},
		background: selected ? "lightGray" : "white",
		color: "black"
	};
};

export const NavigationResultContainerStyle = chakra(Flex, {
	baseStyle: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center"
	}
});

export const SearchResultNavigationLabelStyle = chakra(Flex, {
	baseStyle: {
		display: "inline-box",
		color: "gray",
		fontSize: "14px",
		fontWeight: 500
	}
});

export const FlexContainerStyle = chakra(Flex, {
	baseStyle: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}
});

export const SearchResultRouteIconContainerStyle = chakra(Flex, {
	baseStyle: {
		alignSelf: "center",
		display: "inline-flex"
	}
});

export const SearchResultRouteIconStyle = {
	color: "black",
	display: "inline-flex",
	alignSelf: "center",
	height: "16px",
	width: "auto"
};

export const SearchResultRouteTextContainerStyle = chakra(Flex, {
	baseStyle: {
		fontSize: "16px",
		display: "inline-flex",
		marginRight: 2,
		marginLeft: 2,
		alignSelf: "center",
		fontWeight: 500
	}
});

export const SearchResultContainerStyle = chakra(Flex, {
	baseStyle: {
		cursor: "pointer",
		display: "flex",
		justifyContent: "left",
		alignItems: "center"
	}
});

export const SearchResultAvatarStyle = (borderColor: string) => ({
	border: `2px solid ${borderColor}`,
	color: "black",
	fontWeight: 600
});

export const SearchResultMainLabelStyle = chakra(Flex, {
	baseStyle: {
		display: "inline-block",
		fontWeight: 600,
		marginRight: 2,
		marginLeft: 2
	}
});

export const SearchResultTagStyle = chakra(Flex, {
	baseStyle: {
		display: "inline-block",
		marginRight: 2,
		backgroundColor: "black",
		color: "white",
		paddingLeft: 2,
		paddingRight: 2,
		paddingTop: 1,
		paddingBottom: 1,
		borderRadius: 3,
		fontSize: "10px",
		fontWeight: 800
	}
});

export const SearchResultReducedLabelStyle = chakra(Flex, {
	baseStyle: {
		display: "inline-block",
		fontSize: "14px",
		marginRight: 2,
		fontWeight: 500
	}
});

export const SearchResultReducedItalicLabelStyle = chakra(Flex, {
	baseStyle: {
		display: "inline-block",
		fontSize: "12px",
		marginRight: 2,
		fontWeight: 500,
		fontStyle: "italic",
		color: "gray"
	}
});

export const TeamImageStyle = {
	margin: "4px 8px"
};
