import React from "react";
import dayjs from "dayjs";

import { $TSFixMePlayer } from "utils/tsutils";
import { ColorSchemeGroup, defaultColorScheme, RED } from "_react/shared/legacy/ui/Colors";
import Check from "_react/shared/legacy/ui/icons/Check";
import Clear from "_react/shared/legacy/ui/icons/Clear";

import { NULL_TEXT } from "_react/evals/list/_constants";
import { TEvalProHit, TEvalStyle } from "_react/evals/list/_types";
import {
	EvalListCard,
	EvalListCardHeader,
	LeftDiv,
	RightDiv,
	NameDiv,
	PositionDiv,
	BoxDiv,
	BoxLabel,
	OrgTeamDiv,
	LabelSpan,
	GamesDiv,
	DateSeenDiv,
	ScoutDiv,
	EvalContentDiv
} from "_react/evals/list/_styles";
import { getPlayerName, getPlayerOrgTeam, getScoutName, getOfp } from "_react/evals/list/_helpers";
import { EvalProHitGrades } from "_react/evals/list/shared/EvalProHitGrades";
import { EvalProHitSummary } from "_react/evals/list/shared/EvalProHitSummary";

export type TEvalProHitProps = {
	evaluation: TEvalProHit;
	player: $TSFixMePlayer;
	isShowSummaries?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TEvalStyle;
};

export const EvalProHit = ({
	evaluation,
	player,
	isShowSummaries = true,
	colorSchemeGroup = defaultColorScheme,
	style
}: TEvalProHitProps) => {
	return (
		<EvalListCard>
			<EvalListCardHeader colorSchemeGroup={colorSchemeGroup}>
				<LeftDiv>
					<BoxDiv>
						<BoxLabel>OFP</BoxLabel>
						{getOfp(evaluation.ofp)}
					</BoxDiv>
					<BoxDiv>
						<BoxLabel>ACQ</BoxLabel>
						{(evaluation.acquire?.toLowerCase() === "y" || evaluation.acquire?.toLowerCase() === "yes") && (
							<Check fill={colorSchemeGroup.primary.color} />
						)}
						{(evaluation.acquire?.toLowerCase() === "n" || evaluation.acquire?.toLowerCase() === "no") && (
							<Clear fill={RED} />
						)}
						{!evaluation.acquire && <Clear fill={"#FFFBEC"} />}
					</BoxDiv>
					<NameDiv>{getPlayerName(player)}</NameDiv>
					<PositionDiv>({evaluation.position ?? player?.position ?? NULL_TEXT})</PositionDiv>
					<OrgTeamDiv>({getPlayerOrgTeam(player)})</OrgTeamDiv>
				</LeftDiv>
				<RightDiv>
					<GamesDiv>
						{evaluation.games_seen ?? NULL_TEXT}
						<LabelSpan>G</LabelSpan>
					</GamesDiv>
					<DateSeenDiv>
						{evaluation.seen_date ? dayjs(evaluation.seen_date).format("M/D/YY") : NULL_TEXT}
					</DateSeenDiv>
					<ScoutDiv>{getScoutName(evaluation?.scout)}</ScoutDiv>
				</RightDiv>
			</EvalListCardHeader>
			<EvalContentDiv style={style?.evalContentDiv}>
				<EvalProHitGrades evaluation={evaluation} player={player} style={style?.evalSection} />
				<EvalProHitSummary
					evaluation={evaluation}
					isShowSummaries={isShowSummaries}
					style={style?.summarySection}
				/>
			</EvalContentDiv>
		</EvalListCard>
	);
};
