import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import { IArmSlotsMonthly } from "_react/shared/data_models/arm_slots/_types";

export type TArmSlotsMonthlyGetQueryParams = {
	// combined id
	playerId?: number;
	// bam id
	pitcherId?: number;
	"pitcherId[in]"?: string;
	season?: number;
	month?: number;
	throws?: string;
	"month[gte]"?: number;
	"month[lte]"?: number;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
	fields?: string;
};

export const fetchArmSlotsMonthly = (queryParams: TArmSlotsMonthlyGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TArmSlotsMonthlyGetQueryParams, IArmSlotsMonthly>("/arm_slot/monthly", queryParams, cancelToken);
