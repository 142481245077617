// TODO: this file could be fixed up to not use $TSFixMe like LkSelectWrapper with shared/_helpers
import React, { useContext, useState, useEffect, useCallback, useRef } from "react";

import axios from "_redux/_utils/_axios";
import { $TSFixMe, T_DBs } from "utils/tsutils";
import { TLk } from "_react/inputs/lks";

type TLkProviderProps = {
	children: React.ReactNode;
};

export type TLkFilter = {
	key: string;
	value: Array<string | number>;
};

export const LkContext = React.createContext<$TSFixMe>(() => null);

function makeLkFetchUrl(lkTableName: string, dbName: T_DBs) {
	return `lk?lkTableName=${lkTableName}&dbName=${dbName}`;
}

function makeLkCustomFetchUrl(query: string, parameters?: { [index: string]: string | number }) {
	const parameterString = parameters
		? Object.keys(parameters)
				.map(parameter => `&${parameter}=${parameters[parameter]}`)
				.join("")
		: "";
	return `lk/custom?query=${query}${parameterString}`;
}

export function fetchLkItems(lkTableName: string, dbName: T_DBs = "phil_data") {
	return axios.get(makeLkFetchUrl(lkTableName, dbName)).then(response => response.data);
}

export function filterLkItems(lkItems: Array<TLk<$TSFixMe>>, lkFilters: Array<TLkFilter>) {
	if (!lkFilters.length) {
		return lkItems;
	}
	const filteredItems = lkItems.filter((lkItem: $TSFixMe) => {
		for (const { key, value } of lkFilters) {
			if (!value || (lkItem.hasOwnProperty(key) && !value.includes(lkItem[key]))) {
				return false;
			}
		}
		return true;
	});
	return filteredItems;
}

export function LkProvider(props: TLkProviderProps) {
	const lks = useRef<{ [key: string]: TLk<$TSFixMe> }>({});
	const lksCustom = useRef<{ [key: string]: TLk<$TSFixMe> }>({});
	const lksFetching = useRef<{ [key: string]: boolean }>({});
	const lksSuccessCallbacks = useRef<{ [key: string]: Array<(items: TLk<$TSFixMe>) => void> }>({});

	const fetchLkItems = useCallback(
		(lkTableName: string, dbName: T_DBs = "phil_data", callback?: (items: TLk<$TSFixMe>) => void) => {
			if (!lkTableName) {
				return Promise.resolve(null);
			} else if (lks.current.hasOwnProperty(lkTableName)) {
				if (callback) callback(lks.current[lkTableName]);
				else return Promise.resolve(lks.current[lkTableName]);
				return;
			} else if (lksFetching.current.hasOwnProperty(lkTableName) && callback != null) {
				lksSuccessCallbacks.current = {
					...lksSuccessCallbacks.current,
					[lkTableName]: lksSuccessCallbacks.current[lkTableName]
						? [...lksSuccessCallbacks.current[lkTableName], callback]
						: [callback]
				};
				return;
			}
			lksFetching.current = { ...lksFetching.current, [lkTableName]: true };
			return axios.get(makeLkFetchUrl(lkTableName, dbName)).then(response => {
				lks.current = { ...lks.current, [lkTableName]: response.data };
				lksFetching.current = { ...lksFetching.current, [lkTableName]: false };
				if (callback) callback(response.data);
				if (lksSuccessCallbacks.current[lkTableName]) {
					lksSuccessCallbacks.current[lkTableName].forEach(cachedCallback => cachedCallback(response.data));
				}
				lksSuccessCallbacks.current[lkTableName] = [];
				return response.data;
			});
		},
		[]
	);

	const fetchLkCustomItems = useCallback(
		(
			query: string,
			callback: (items: TLk<$TSFixMe>) => void,
			parameters?: { [index: string]: string | number }
		) => {
			const serializedParameters = parameters
				? Object.keys(parameters)
						.sort()
						.map(key => `${key}=${parameters[key]}`)
						.join("&")
				: "";
			const key = `${query}?${serializedParameters}`;
			if (lksCustom.current.hasOwnProperty(key)) {
				callback(lksCustom.current[key]);
				return;
			} else if (lksFetching.current.hasOwnProperty(key)) {
				lksSuccessCallbacks.current = {
					...lksSuccessCallbacks.current,
					[key]: lksSuccessCallbacks.current[key]
						? [...lksSuccessCallbacks.current[key], callback]
						: [callback]
				};
				return;
			}
			lksFetching.current = { ...lksFetching.current, [key]: true };
			axios.get(makeLkCustomFetchUrl(query, parameters)).then(response => {
				lksCustom.current = { ...lksCustom.current, [key]: response.data };
				lksFetching.current = { ...lksFetching.current, [key]: false };

				callback(response.data);
				if (lksSuccessCallbacks.current[key]) {
					lksSuccessCallbacks.current[key].forEach(cachedCallback => cachedCallback(response.data));
				}
				lksSuccessCallbacks.current[key] = [];
			});
		},
		[]
	);

	return (
		<LkContext.Provider value={{ fetchLkItems, fetchLkCustomItems }}>
			<>{props.children}</>
		</LkContext.Provider>
	);
}

export function useLks<T = $TSFixMe>(lkTableName: string, dbName: T_DBs = "phil_data") {
	const [optionsRaw, setOptionsRaw] = useState<TLk<T>[]>([]);
	const [isFetching, setIsFetching] = useState(false);
	const { fetchLkItems } = useContext(LkContext);
	useEffect(() => {
		setIsFetching(true);
		fetchLkItems(lkTableName, dbName, (options: TLk<T>[]) => {
			setOptionsRaw(options);
			setIsFetching(false);
		});
	}, [lkTableName, fetchLkItems, dbName]);
	return [optionsRaw, isFetching] as const;
}

export function useLksCustom<T = $TSFixMe>(query: string, parameters?: { [index: string]: string | number }) {
	const [optionsRaw, setOptionsRaw] = useState<{ [index: string]: TLk<T>[] }>({});
	const [isFetching, setIsFetching] = useState<{ [index: string]: boolean }>({});
	const { fetchLkCustomItems } = useContext(LkContext);

	const serializedParameters = parameters
		? Object.keys(parameters)
				.sort()
				.map(key => `${key}=${parameters[key]}`)
				.join("&")
		: "";
	const key = `${query}?${serializedParameters}`;

	useEffect(() => {
		if (optionsRaw[key] != null || isFetching[key] === true) return;
		setIsFetching({ ...isFetching, [key]: true });
		fetchLkCustomItems(
			query,
			(options: TLk<T>[]) => {
				setOptionsRaw({ ...optionsRaw, [key]: options });
				setIsFetching({ ...isFetching, [key]: false });
			},
			parameters
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query, parameters, fetchLkCustomItems, optionsRaw, key]);
	return [optionsRaw[key], isFetching[key]] as const;
}

export function useLkItem<T = $TSFixMe>(
	lkName: string,
	initialLkValue: T | undefined
): [TLk<T> | undefined, React.Dispatch<React.SetStateAction<T | undefined>>] {
	const [lkValue, setLkValue] = useState<T | undefined>(initialLkValue);
	const [lkItem, setLkItem] = useState<TLk<T>>();
	const fetchLkItems = useContext(LkContext);
	useEffect(() => {
		fetchLkItems(lkName).then((options: TLk<T>[]) => {
			setLkItem(options.find(lkItemIter => lkItemIter.value === lkValue));
		});
	}, [lkName, lkValue, fetchLkItems]);
	return [lkItem, setLkValue];
}
