import React, { useCallback } from "react";
import styled from "@emotion/styled";

import { TCheckboxProps, TColumn } from "_react/table/_types";
import { TTableDataRow } from "_react/table/machine";

type TStyledRowProps = {
	hoverStyle: React.CSSProperties;
	index: number;
	style?: object;
};

const StyledRow = styled("tr")<TStyledRowProps>(({ hoverStyle, index, style = {} }) => ({
	backgroundColor: index % 2 !== 0 ? "rgb(240, 240, 240)" : "",
	cursor: "pointer",
	"&:hover": {
		backgroundColor: "yellow",
		...hoverStyle
	},
	position: "relative",
	zIndez: 0,
	...style
}));

type TStyledCellProps = {
	backgroundColor?: string;
	flex: string | number;
	rowHeight: number;
	allowWrapping?: boolean;
};

const StyledCell = styled("td")<TStyledCellProps>(
	({ backgroundColor = "inherit", flex, rowHeight, allowWrapping = false }) => ({
		flex,
		textAlign: "center",
		textOverflow: "ellipsis",
		overflow: "hidden",
		lineHeight: `${rowHeight}px`,
		userSelect: "none",
		backgroundColor,
		paddingLeft: "4px",
		paddingRight: "4px",
		whiteSpace: allowWrapping ? undefined : "nowrap"
	})
);

type TRowProps<T> = {
	data: Array<TTableDataRow<T>>;
	hoverStyle: React.CSSProperties;
	index: number;
	style?: object;
	checkbox?: TCheckboxProps<T>;
	columns: Array<TColumn<T>>;
	handleClick: (e: React.MouseEvent, filteredDataIdx: number) => void;
	rowHeight?: number;
};

function Row<T>({ checkbox, columns, data, handleClick, hoverStyle, index, rowHeight = 25, style }: TRowProps<T>) {
	const onClickRow = useCallback(
		(e: React.MouseEvent) => {
			handleClick(e, index);
		},
		[handleClick, index]
	);
	const checkboxChecked = checkbox?.checkedKey && checkbox.checked.includes(data[index].datum[checkbox.checkedKey]);
	return (
		<StyledRow data-cy={`row-${index}`} hoverStyle={hoverStyle} index={index} onClick={onClickRow} style={style}>
			{checkbox && (
				<StyledCell data-cy={`cell-${index}-checkbox`} flex={"0 1 25px"} rowHeight={rowHeight}>
					<input
						onChange={() => checkbox.handleClick(data[index])}
						onClick={e => e.stopPropagation()}
						checked={checkboxChecked}
						style={{ transform: "scale(1.15)", cursor: "pointer" }}
						type="checkbox"
					/>
				</StyledCell>
			)}
			{columns.map((column, columnIndex) => (
				<StyledCell
					backgroundColor={
						column.computeColor ? column.computeColor(data[index].row[columnIndex].rawValue) : undefined
					}
					data-cy={`cell-${index}-${columnIndex}`}
					flex={column.flex}
					key={columnIndex}
					rowHeight={rowHeight}
					allowWrapping={column.allowWrapping}
				>
					{data[index].row[columnIndex].displayValue}
				</StyledCell>
			))}
		</StyledRow>
	);
}

export default Row;
