import React, { useEffect, useState, MouseEventHandler, MouseEvent } from "react";
import {
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Text,
	Button,
	useToast
} from "@chakra-ui/react";
import Survey from "_react/dpl/Survey";
import Assignment from "_react/shared/ui/icons/Assignment";
import { useMachine } from "@xstate/react";

import createDPLQuestionnaireSurveyResponseMachine, {
	TDPLQuestionnaireSurveyResponseContext,
	SET_DPL_ID,
	SET_SURVEY_ID,
	SET_SURVEY_RESPONSE,
	FETCHING_SURVEY_RESPONSE
} from "_react/playerpage/ama/profile/DPLQuestionnaire/DPLQuestionnaireSurveyResponse/_machine";
import { TSurvey } from "_react/shared/data_models/dpl/_types";

export type TDPLQuestionnaireSurveyResponseData = {
	surveyResponse?: TSurvey | null;
};

type TDPLQuestionnaireSurveyResponseProps = {
	dplId?: number | null;
	surveyId?: string;
	surveyName?: string;
	data?: TDPLQuestionnaireSurveyResponseData;
	onOpenModal: MouseEventHandler<HTMLButtonElement>;
	onCloseModal: () => void;
	isOpenModal: boolean;
};

const DPLQuestionnaireSurveyResponse = ({
	dplId,
	surveyId,
	surveyName,
	data,
	onOpenModal,
	onCloseModal,
	isOpenModal
}: TDPLQuestionnaireSurveyResponseProps) => {
	// Since the modal disclosure is required to be defined in the DPL popover parent (and thus one disclosure is shared for all questionnaire modals) this flag is required to control which modal is being displayed
	const [showingModal, setShowingModal] = useState(false);

	const toast = useToast();
	const [current, send] = useMachine(createDPLQuestionnaireSurveyResponseMachine(dplId, surveyId, data, toast));
	const { surveyResponse } = current.context as TDPLQuestionnaireSurveyResponseContext;

	const fetchingSurveyResponse = current.matches(FETCHING_SURVEY_RESPONSE);

	// Update machine context when props change
	useEffect(() => {
		send({ type: SET_DPL_ID, data: dplId ?? undefined });
	}, [dplId, send]);

	useEffect(() => {
		send({ type: SET_SURVEY_ID, data: surveyId });
	}, [surveyId, send]);

	useEffect(() => {
		send({ type: SET_SURVEY_RESPONSE, data: surveyResponse });
	}, [surveyResponse, send]);

	return (
		<>
			<IconButton
				aria-label={`Show Response`}
				variant="ghost"
				h="fit-content"
				minWidth="fit-content"
				icon={<Assignment />}
				isLoading={fetchingSurveyResponse}
				onClick={(e: MouseEvent<HTMLButtonElement>) => {
					setShowingModal(true);
					onOpenModal(e);
				}}
			/>
			{showingModal && (
				<Modal
					isOpen={isOpenModal}
					onClose={() => {
						onCloseModal();
						setShowingModal(false);
					}}
				>
					<ModalOverlay />
					<ModalContent maxW={900}>
						<ModalHeader>{surveyName} Response</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							{surveyResponse ? (
								<Survey survey={surveyResponse} showTitle={false} />
							) : (
								<Text>No Questionnaire Response Found</Text>
							)}
						</ModalBody>
						<ModalFooter>
							<Button
								colorScheme="blue"
								mr={3}
								onClick={() => {
									onCloseModal();
									setShowingModal(false);
								}}
							>
								Close
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default DPLQuestionnaireSurveyResponse;
