import Cookies from "universal-cookie";
// Just initialize axios onces and use it all over
// Load up token saved in cookies
const myCookies = new Cookies();

export const USER_ID = myCookies.get("userId");
export const TOKEN = myCookies.get("token");

// Axios Initialization
export const cookies = myCookies;

export const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL = process.env.REACT_APP_API_URL;
export const OKTA_BOOKMARK_APP_REDIRECT_URI = process.env.REACT_APP_OKTA_BOOKMARK_APP_REDIRECT_URI;
