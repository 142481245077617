import styled from "@emotion/styled";

import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";

export const PageContainerDiv = styled.div({
	height: "100%",
	paddingTop: "50px",
	boxSizing: "border-box",
	display: "flex",
	flexDirection: "column"
});

export const PinnedButton = styled.div<{ disableCursor?: boolean; isSearch?: boolean }>(
	({ disableCursor = false, isSearch = false }) => ({
		borderRadius: "3px",
		padding: "4px",
		backgroundColor: defaultColorScheme.secondary.hover,
		"&:hover": {
			backgroundColor: defaultColorScheme.secondary.selected
		},
		cursor: !disableCursor ? "pointer" : "",
		display: isSearch ? "flex" : "inherit",
		justifyContent: isSearch ? "center" : "inherit",
		alignItems: isSearch ? "center" : "inherit"
	})
);

export const PinButton = styled.div<{ pinned?: boolean }>(({ pinned = false }) => ({
	display: "flex",
	width: "45px",
	height: "45px",
	alignItems: "center",
	justifyContent: "center",
	paddingTop: "2px",
	"> svg": {
		display: !pinned ? "none" : ""
	},
	"&:hover": {
		"> svg": {
			display: "flex"
		}
	}
}));

export const ArrowButton = styled.div({
	display: "flex",
	width: "45px",
	height: "45px",
	alignItems: "center",
	justifyContent: "center",
	paddingTop: "2px"
});

export const LogoContainer = styled.div({
	textAlign: "center",
	backgroundColor: defaultColorScheme.secondary.color,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	padding: "5px"
});

export const LogoTagline = styled.span<{ retro?: boolean }>(({ retro = false }) => ({
	fontFamily: retro ? "PhilliesRetro" : "Phillies",
	fontSize: "26px",
	fontWeight: "bold",
	color: "white",
	marginLeft: "15px",
	marginTop: retro ? "-12px" : "5px"
}));

export const PinnedLinksContainer = styled.div({
	backgroundColor: defaultColorScheme.secondary.hover,
	padding: "5px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
});

export const SearchContainer = styled.span({
	marginLeft: "5px",
	color: "white",
	fontWeight: "bold",
	marginRight: "1px"
});

export const TooltipPinStyle = { width: "100px", textAlign: "center" };

export const SearchFieldContainer = styled.div({
	flexGrow: 1,
	marginLeft: "5px",
	marginRight: "5px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
});

export const SearchTextFieldStyle = { color: "white", fontSize: "18px", width: "100%" };

export const ClearSearchContainer = styled.div({
	flexGrow: 1,
	marginLeft: "5px",
	marginRight: "5px"
});

export const SearchMatchBold = styled.span({
	fontWeight: 800
});

export const SearchMatchNonBold = styled.span({
	fontWeight: 400
});

export const LogoStyle = { height: "40px" };

export const VersionFooter = styled.div({
	fontSize: "8px",
	color: "gray",
	fontWeight: 300,
	textAlign: "center",
	margin: "auto",
	padding: "10px"
});
