import React, { FC, useState, useMemo } from "react";

import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Box } from "@chakra-ui/react";

import HamburgerMenu from "_react/shared/legacy/ui/icons/HamburgerMenu";
import Person from "_react/shared/legacy/ui/icons/Person";
import Settings from "_react/shared/legacy/ui/icons/Settings";
import Check from "_react/shared/legacy/ui/icons/Check";
import Home from "_react/shared/legacy/ui/icons/Home";
import { Menu } from "_react/shared/legacy/ui/Menu";
import { MenuItem } from "_react/shared/legacy/ui/MenuItem";
import { useUserData } from "_react/app/AppContents";

import { Search } from "_react/shared/searches/Search";
import {
	NavigationIconStyle,
	HomeFlex,
	MenuButtonStyle,
	HeaderBoxStyle,
	SearchBoxStyle,
	MenuOptionStyle
} from "_react/app/header/_styles";
import {
	ALL_CLASSIFICATIONS,
	CLASSIFICATION_OPTIONS,
	CLASSIFICATION_OPTIONS_COOKIE,
	LOGIN_REDIRECT_DISABLED_COOKIE
} from "_react/app/header/_constants";
import { SWE_IAM_GROUP } from "_react/shared/data_models/iam_new/_constants";
import { useIsGroupMember, useUserId } from "_react/app/AppContents";

type HeaderProps = {
	handleLogout: Function;
	handleToggleNavDrawer: React.MouseEventHandler<HTMLDivElement>;
};

export const Header: FC<HeaderProps> = ({ handleLogout, handleToggleNavDrawer }) => {
	// Menu placement
	const [anchorElUserMenu, setAnchorElUserMenu] = useState<SVGSVGElement | null>();
	const [anchorElSearchMenu, setAnchorElSearchMenu] = useState<SVGSVGElement | null>();
	// User info
	const { name } = useUserData();
	// Navigation
	const history = useHistory();

	// Classification
	const [classificationsCookies, setClassificationsCookie] = useCookies([CLASSIFICATION_OPTIONS_COOKIE]);
	const classifications = useMemo(() => {
		if (classificationsCookies[CLASSIFICATION_OPTIONS_COOKIE] != null)
			return classificationsCookies[CLASSIFICATION_OPTIONS_COOKIE].split(",");
		return ALL_CLASSIFICATIONS;
	}, [classificationsCookies]);
	const setClassifications = (classifications: string[]) => {
		const newClassifications = classifications.length > 0 ? classifications : ALL_CLASSIFICATIONS;
		setClassificationsCookie(CLASSIFICATION_OPTIONS_COOKIE, newClassifications.join(","));
	};
	const onClassificationSelected = (classification: string) => () => {
		if (classifications.includes(classification)) {
			setClassifications(classifications.filter((c: string) => c !== classification));
		} else {
			setClassifications([...classifications, classification]);
		}
	};
	const [loginRedirectDisabledCookie, setLoginRedirectDisabledCookie] = useCookies([LOGIN_REDIRECT_DISABLED_COOKIE]);
	const loginRedirectDisabled = useMemo(() => {
		if (loginRedirectDisabledCookie[LOGIN_REDIRECT_DISABLED_COOKIE] != null)
			return loginRedirectDisabledCookie[LOGIN_REDIRECT_DISABLED_COOKIE] === "YES";
		return false;
	}, [loginRedirectDisabledCookie]);
	const toggleLoginRedirectDisabled = () => {
		if (loginRedirectDisabled) {
			setLoginRedirectDisabledCookie(LOGIN_REDIRECT_DISABLED_COOKIE, "NO");
		} else {
			setLoginRedirectDisabledCookie(LOGIN_REDIRECT_DISABLED_COOKIE, "YES");
		}
	};
	const isSWE = useIsGroupMember(SWE_IAM_GROUP);
	const userId = useUserId() ?? 0;
	const isCalThoms = [40031, 40011].includes(userId);
	const canDisableLoginRedirect = isSWE || isCalThoms;

	return (
		<Box style={HeaderBoxStyle}>
			<NavigationIconStyle onClick={handleToggleNavDrawer}>
				<HamburgerMenu fill="white" style={{ height: "26px" }} />
			</NavigationIconStyle>
			<HomeFlex>
				<Home fill="gray" onClick={() => history.push("/")} />
			</HomeFlex>
			<Box color="black" style={SearchBoxStyle}>
				<Search classifications={classifications} />
			</Box>
			<MenuButtonStyle>
				<Settings
					fill="gray"
					onClick={(e: React.MouseEvent<SVGSVGElement>) => setAnchorElSearchMenu(e.currentTarget)}
				/>
			</MenuButtonStyle>
			<Menu
				absolute={true}
				anchorEl={anchorElSearchMenu}
				id="searchMenu"
				onClose={() => setAnchorElSearchMenu(null)}
				open={Boolean(anchorElSearchMenu)}
			>
				{CLASSIFICATION_OPTIONS.map((option: { label: string; value: string }) => (
					<MenuItem
						key={option.value}
						id={option.value}
						handleClick={onClassificationSelected(option.value)}
						style={MenuOptionStyle}
					>
						{classifications.includes(option.value) && <Check />}
						{`${option.label}`}
					</MenuItem>
				))}
			</Menu>
			<MenuButtonStyle>
				<Person
					fill="gray"
					onClick={(e: React.MouseEvent<SVGSVGElement>) => setAnchorElUserMenu(e.currentTarget)}
				/>
			</MenuButtonStyle>
			<Menu
				absolute={true}
				anchorEl={anchorElUserMenu}
				id="userMenu"
				onClose={() => setAnchorElUserMenu(null)}
				open={Boolean(anchorElUserMenu)}
			>
				<MenuItem id="profileButton">{`${name}`}</MenuItem>
				{canDisableLoginRedirect && (
					<MenuItem id="disableLoginRedirect" handleClick={toggleLoginRedirectDisabled}>
						{loginRedirectDisabled ? "Enable" : "Disable"} Login Redirect
					</MenuItem>
				)}
				<MenuItem id="signOutButton" handleClick={handleLogout}>
					Sign Out
				</MenuItem>
			</Menu>
		</Box>
	);
};
