import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function ViewList({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z" />
		</IconSVG>
	);
}
