import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import IntrinsicValue from "_react/shared/data_models/inference/intrinsic_value";
import { IIntrinsicValue } from "_react/shared/data_models/inference/_types";
import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";

import { TPitcherIntrinsicValueHexbinPlotData } from "_react/shared/ui/data/plots/PitcherIntrinsicValueHexbinPlot/PitcherIntrinsicValueHexbinPlot";

const INTRINSIC_VALUE_CANCEL_SOURCE = "intrinsicValues";

export type TPitcherIntrinsicValueHexbinPlotCancelSource = {
	[INTRINSIC_VALUE_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPitcherIntrinsicValueHexbinPlotContext = {
	seasonFilter: number;
	playerId?: number;
	shouldFetchPrimaryData?: boolean;
	intrinsicValues?: Array<IIntrinsicValue> | null;
	cancelSources: TPitcherIntrinsicValueHexbinPlotCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPitcherIntrinsicValueHexbinPlotStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches intrinsic value data for a player
				intrinsicValues: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_INTRINSIC_VALUE = "SET_INTRINSIC_VALUE";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_SEASON_FILTER = "SET_SEASON_FILTER";
export const FETCHING_INTRINSIC_VALUE = {
	initialized: { intrinsicValues: "fetching" }
};

const FETCH_INTRINSIC_VALUE_DONE = "done.invoke.fetchingIntrinsicValue:invocation[0]";

type TSetIntrinsicValueEvent = {
	type: typeof SET_INTRINSIC_VALUE;
	data?: Array<IIntrinsicValue> | null;
};

type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};

type TSetSeasonFilterEvent = {
	type: typeof SET_SEASON_FILTER;
	data: number;
};

type TFetchIntrinsicValueEvent = {
	type: typeof FETCH_INTRINSIC_VALUE_DONE;
	data?: Array<IIntrinsicValue>;
};

type TPitcherIntrinsicValueHexbinPlotEvent =
	| TSetIntrinsicValueEvent
	| TSetPlayerIdEvent
	| TSetSeasonFilterEvent
	| TFetchIntrinsicValueEvent;

export type TPitcherIntrinsicValueHexbinPlotSend = Interpreter<
	TPitcherIntrinsicValueHexbinPlotContext,
	IPitcherIntrinsicValueHexbinPlotStateSchema,
	TPitcherIntrinsicValueHexbinPlotEvent
>["send"];

const PitcherIntrinsicValueHexbinPlotMachine = (
	seasonFilterProp: number,
	playerIdProp?: number,
	shouldFetchPrimaryData = true,
	data?: TPitcherIntrinsicValueHexbinPlotData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TPitcherIntrinsicValueHexbinPlotContext,
		IPitcherIntrinsicValueHexbinPlotStateSchema,
		TPitcherIntrinsicValueHexbinPlotEvent
	>(
		{
			id: "PitcherIntrinsicValueHexbinPlot",
			initial: "initializing",
			context: {
				seasonFilter: seasonFilterProp,
				playerId: playerIdProp,
				shouldFetchPrimaryData: shouldFetchPrimaryData,
				intrinsicValues: data?.intrinsicValues ?? [],
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: {
						target: "initialized"
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_INTRINSIC_VALUE]: { actions: "setIntrinsicValue" },
						[SET_PLAYER_ID]: { actions: ["setPlayerId", "clearIntrinsicValue"] },
						[SET_SEASON_FILTER]: { actions: ["setSeasonFilter", "clearIntrinsicValue"] }
					},
					states: {
						intrinsicValues: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingIntrinsicValue",
												cond: "shouldFetchIntrinsicValue"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingIntrinsicValue",
									entry: ["refreshIntrinsicValueCancelSource"],
									invoke: {
										src: "fetchIntrinsicValue",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchIntrinsicValueSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchIntrinsicValueErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldFetchIntrinsicValue: (
					context: TPitcherIntrinsicValueHexbinPlotContext,
					_event: TPitcherIntrinsicValueHexbinPlotEvent
				) => {
					const { intrinsicValues, playerId, seasonFilter } = context;
					return (
						intrinsicValues === undefined &&
						shouldFetchPrimaryData &&
						playerId !== undefined &&
						seasonFilter !== undefined
					);
				}
			},
			actions: {
				setIntrinsicValue: assign<
					TPitcherIntrinsicValueHexbinPlotContext,
					TPitcherIntrinsicValueHexbinPlotEvent
				>({
					intrinsicValues: (
						context: TPitcherIntrinsicValueHexbinPlotContext,
						event: TPitcherIntrinsicValueHexbinPlotEvent
					) => {
						if (event.type !== SET_INTRINSIC_VALUE) return context.intrinsicValues;
						return event.data;
					},
					cancelSources: (
						context: TPitcherIntrinsicValueHexbinPlotContext,
						event: TPitcherIntrinsicValueHexbinPlotEvent
					) => {
						if (event.type !== SET_INTRINSIC_VALUE) return context.cancelSources;
						if (context.cancelSources[INTRINSIC_VALUE_CANCEL_SOURCE] != null)
							context.cancelSources[INTRINSIC_VALUE_CANCEL_SOURCE].cancel();
						delete context.cancelSources[INTRINSIC_VALUE_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TPitcherIntrinsicValueHexbinPlotContext, TPitcherIntrinsicValueHexbinPlotEvent>({
					playerId: (
						context: TPitcherIntrinsicValueHexbinPlotContext,
						event: TPitcherIntrinsicValueHexbinPlotEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setSeasonFilter: assign<TPitcherIntrinsicValueHexbinPlotContext, TPitcherIntrinsicValueHexbinPlotEvent>(
					{
						seasonFilter: (
							context: TPitcherIntrinsicValueHexbinPlotContext,
							event: TPitcherIntrinsicValueHexbinPlotEvent
						) => {
							if (event.type !== SET_SEASON_FILTER) return context.seasonFilter;
							return event.data;
						}
					}
				),
				clearIntrinsicValue: assign<
					TPitcherIntrinsicValueHexbinPlotContext,
					TPitcherIntrinsicValueHexbinPlotEvent
				>({
					intrinsicValues: (
						_context: TPitcherIntrinsicValueHexbinPlotContext,
						_event: TPitcherIntrinsicValueHexbinPlotEvent
					) => {
						return undefined;
					},
					cancelSources: (
						context: TPitcherIntrinsicValueHexbinPlotContext,
						_event: TPitcherIntrinsicValueHexbinPlotEvent
					) => {
						context.cancelSources[INTRINSIC_VALUE_CANCEL_SOURCE]?.cancel();
						return {};
					}
				}),
				// Cancel Source Actions
				refreshIntrinsicValueCancelSource: assign<
					TPitcherIntrinsicValueHexbinPlotContext,
					TPitcherIntrinsicValueHexbinPlotEvent
				>({
					cancelSources: (
						context: TPitcherIntrinsicValueHexbinPlotContext,
						_event: TPitcherIntrinsicValueHexbinPlotEvent
					) => {
						if (context.cancelSources[INTRINSIC_VALUE_CANCEL_SOURCE] != null)
							context.cancelSources[INTRINSIC_VALUE_CANCEL_SOURCE].cancel();
						context.cancelSources[INTRINSIC_VALUE_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchIntrinsicValueSuccess: assign<
					TPitcherIntrinsicValueHexbinPlotContext,
					TPitcherIntrinsicValueHexbinPlotEvent
				>({
					intrinsicValues: (
						context: TPitcherIntrinsicValueHexbinPlotContext,
						event: TPitcherIntrinsicValueHexbinPlotEvent
					) => {
						if (event.type !== FETCH_INTRINSIC_VALUE_DONE) return context.intrinsicValues;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchIntrinsicValueErrored: (
					context: TPitcherIntrinsicValueHexbinPlotContext,
					_event: TPitcherIntrinsicValueHexbinPlotEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Intrinsic Value Hexbin Plot",
							description: "Error fetching intrinsic value inference data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchIntrinsicValue: (context: TPitcherIntrinsicValueHexbinPlotContext, _event: AnyEventObject) => {
					const { playerId, seasonFilter } = context;
					if (!playerId || !seasonFilter) return Promise.resolve(undefined);
					const fetchFunc = () =>
						IntrinsicValue.postResource(
							{
								playerId: playerId,
								season: seasonFilter,
								isUseCache: true
							},
							context.cancelSources[INTRINSIC_VALUE_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherIntrinsicValueHexbinPlotMachine;
