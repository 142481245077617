import React from "react";
import { MultiValue, SingleValue } from "react-select";
import dayjs from "dayjs";

import LkSelect from "_react/inputs/lks/LkSelect";
import { TOption } from "_react/inputs/_types";
import MetricSubtypeSelect, {
	TMetricSubtypeOption,
	TMetricSubtypeValue
} from "_react/shared/selects/MetricSubtypeSelect";
import { Button } from "_react/shared/legacy/ui/Button";
import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import { DatePicker } from "_react/shared/legacy/ui/DatePicker";
import Edit from "_react/shared/legacy/ui/icons/Edit";
import Map from "_react/shared/legacy/ui/icons/Map";
import Delete from "_react/shared/legacy/ui/icons/Delete";
import { TextField, TChangeEvent } from "_react/shared/legacy/ui/TextField";

import { NON_BASEBALL_METRIC_LKS } from "_react/playerplan/shared/_constants";
import { getAssignedCellValue } from "_react/playerplan/shared/_helpers";
import { TPlayerPlanMetric } from "_react/playerplan/shared/_types";
import { MaxWidthSelectStyle } from "_react/playerplan/shared/_styles";

import {
	ActionsDiv,
	FilterTextFieldStyle,
	FixedHeightDiv,
	FixedWidthDiv,
	LeftButtonStyle,
	METRIC_DATEPICKER_WRAPPER_CLASS_NAME,
	MinWidthDiv,
	ModalLinkDiv,
	SmallCircularProgressStyle
} from "_react/playerplan/metrics/_styles";

// Random

export const NULL_TEXT = "";

// Tiers
export const PHP_TIER = 0;
export const SKILL_TIER = 1;
export const PERFORMANCE_TIER = 2;
export const FOCUS_AREA_TIER = 4;
export const EDITABLE_TIERS = [PHP_TIER, FOCUS_AREA_TIER];

// Pagination

export const DEFAULT_OFFSET = 0;
export const DEFAULT_LIMIT = 25;
export const PAGE_SIZE_OPTIONS = [
	{ value: "5", display: "5" },
	{ value: "10", display: "10" },
	{ value: "25", display: "25" },
	{ value: "50", display: "50" },
	{ value: "100", display: "100" }
];

// Modal Types
export type MODAL_TYPES = typeof LINK_MODAL | typeof METRIC_DISPLAY_MODAL | typeof METRIC_MAP_MODAL;

export const LINK_MODAL = "linkModal";
export const METRIC_DISPLAY_MODAL = "metricDisplayModal";
export const METRIC_MAP_MODAL = "metricMapModal";

// Table Columns

export const METRIC_COLUMNS = [
	{
		title: "Tier",
		name: "metricTierLk",
		getCellValue: (row: TPlayerPlanMetric) => (
			<FixedHeightDiv height="27px">{row.metricTier?.label ?? NULL_TEXT}</FixedHeightDiv>
		),
		getFilter: (onChange: Function, _value?: string, _metricTypes?: Array<string>) => (
			<MinWidthDiv minWidth="100px">
				<LkSelect<string>
					lkName={"lk_player_plan_metric_tier"}
					onChange={(opt: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null) =>
						onChange({ metricTierLk: (opt as TOption<string>)?.value })
					}
					placeholder={"Filter tier..."}
					isClearable
					menuPortalTarget={document.body}
					styles={MaxWidthSelectStyle}
				/>
			</MinWidthDiv>
		),
		getAddInput: (onChange: Function, _addMetric: Function, value?: string, _metricTierLk?: number | null) => (
			<div>
				<LkSelect<string>
					lkName={"lk_player_plan_metric_tier"}
					lkFilters={[{ key: "value", value: EDITABLE_TIERS }]}
					onChange={(opt: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null) =>
						onChange({
							metricTierLk: (opt as TOption<string>)?.value,
							metricTypeLk: null,
							mericSubtypeLk: null
						})
					}
					placeholder={"Select tier..."}
					valueOnlyValue={value}
					menuPlacement="auto"
					isClearable
					menuPortalTarget={document.body}
					styles={MaxWidthSelectStyle}
				/>
			</div>
		),
		getEditInput: (row: TPlayerPlanMetric) => (
			<FixedHeightDiv height="27px">{row.metricTier?.label ?? NULL_TEXT}</FixedHeightDiv>
		),
		sortFunc: (a: TPlayerPlanMetric, b: TPlayerPlanMetric) => {
			const aValue = a.metricTier?.sortOrder ?? Number.MAX_SAFE_INTEGER;
			const bValue = b.metricTier?.sortOrder ?? Number.MAX_SAFE_INTEGER;
			return aValue - bValue;
		}
	},
	{
		title: "Department",
		name: "metricTypeLk",
		getCellValue: (row: TPlayerPlanMetric) => row.metricType?.label ?? NULL_TEXT,
		getFilter: (onChange: Function, _value?: string, _metricTypes?: Array<string>) => (
			<MinWidthDiv minWidth="100px">
				<LkSelect<string>
					lkName={"lk_player_plan_metric_type"}
					onChange={(opt: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null) =>
						onChange({ metricTypeLk: (opt as TOption<string>)?.value })
					}
					placeholder={"Filter department..."}
					isClearable
					menuPortalTarget={document.body}
					styles={MaxWidthSelectStyle}
				/>
			</MinWidthDiv>
		),
		getAddInput: (onChange: Function, _addMetric: Function, value?: string, metricTierLk?: number | null) => (
			<div>
				<LkSelect<string>
					lkName={"lk_player_plan_metric_type"}
					onChange={(opt: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null) =>
						onChange({
							metricTypeLk: (opt as TOption<string>)?.value,
							metricSubtypeLk: null
						})
					}
					lkFilters={
						metricTierLk === PHP_TIER ? [{ key: "value", value: NON_BASEBALL_METRIC_LKS }] : undefined
					}
					placeholder={"Select department..."}
					valueOnlyValue={value}
					menuPlacement="auto"
					isClearable
					menuPortalTarget={document.body}
					styles={MaxWidthSelectStyle}
				/>
			</div>
		),
		getEditInput: (
			row: TPlayerPlanMetric,
			onChange: Function,
			_updateMetric: Function,
			_setMetricsIsEditable: Function,
			value?: string
		) => (
			<MinWidthDiv minWidth="100px">
				<LkSelect<string>
					lkName={"lk_player_plan_metric_type"}
					onChange={(opt: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null) =>
						onChange(row.id, {
							metricTypeLk: (opt as TOption<string>)?.value,
							metricSubtypeLk: null
						})
					}
					lkFilters={
						row.metricTierLk === PHP_TIER ? [{ key: "value", value: NON_BASEBALL_METRIC_LKS }] : undefined
					}
					placeholder={"Select department..."}
					valueOnlyValue={value}
					menuPlacement="auto"
					isClearable
					menuPortalTarget={document.body}
					styles={MaxWidthSelectStyle}
				/>
			</MinWidthDiv>
		),
		sortFunc: (a: TPlayerPlanMetric, b: TPlayerPlanMetric) => {
			const aValue = a.metricType?.label ?? "";
			const bValue = b.metricType?.label ?? "";
			return aValue.localeCompare(bValue);
		}
	},
	{
		title: "Dev Goal Category",
		name: "metricSubtypeLk",
		tooltip: "Only available for Tier 1 and 2 Metrics",
		getCellValue: (row: TPlayerPlanMetric) => row.metricSubtype?.label ?? NULL_TEXT,
		getFilter: (onChange: Function, value?: TMetricSubtypeValue, metricTypes?: Array<string>) => (
			<MinWidthDiv minWidth="150px">
				<MetricSubtypeSelect
					metricTypes={metricTypes}
					handleSelect={(opt: TMetricSubtypeOption) =>
						onChange({ metricSubtype: { value: opt?.value, lkMetricType: opt?.metricType.value } })
					}
					value={value}
					placeholder={"Filter category..."}
					isClearable
				/>
			</MinWidthDiv>
		),
		sortFunc: (a: TPlayerPlanMetric, b: TPlayerPlanMetric) => {
			const aValue = a.metricSubtype?.label ?? "";
			const bValue = b.metricSubtype?.label ?? "";
			return aValue.localeCompare(bValue);
		}
	},
	{
		title: "Label",
		name: "label",
		getFilter: (onChange: Function, value?: string, _metricTypes?: Array<string>) => (
			<MinWidthDiv minWidth="75px">
				<TextField
					fullWidth
					onChange={(e: TChangeEvent) => onChange({ label: e.target.value })}
					value={value}
					placeholder={"Search..."}
					style={FilterTextFieldStyle}
				/>
			</MinWidthDiv>
		),
		getAddInput: (
			onChange: Function,
			_addMetric: Function,
			value?: string,
			_metricTierLk?: number | null,
			_isIdle?: boolean,
			_showCircularProgress?: boolean
		) => (
			<div>
				<TextField
					fullWidth
					multiline
					onChange={(e: TChangeEvent) => onChange({ label: e.target.value })}
					value={value}
					placeholder={"Enter label..."}
					style={FilterTextFieldStyle}
				/>
			</div>
		),
		getEditInput: (
			row: TPlayerPlanMetric,
			onChange: Function,
			_updateMetric: Function,
			_setMetricsIsEditable: Function,
			value?: string,
			_isIdle?: boolean
		) => (
			<div>
				<TextField
					fullWidth
					multiline
					onChange={(e: TChangeEvent) => onChange(row.id, { label: e.target.value })}
					value={value}
					placeholder={"Enter label..."}
					style={FilterTextFieldStyle}
				/>
			</div>
		)
	},
	{
		title: "Abbreviation",
		name: "abbreviation",
		tooltip: "Must be less than 10 characters",
		getFilter: (onChange: Function, value?: string, _metricTypes?: Array<string>) => (
			<MinWidthDiv minWidth="50px">
				<TextField
					fullWidth
					onChange={(e: TChangeEvent) => onChange({ abbreviation: e.target.value })}
					value={value}
					placeholder={"Search..."}
					style={FilterTextFieldStyle}
				/>
			</MinWidthDiv>
		),
		getAddInput: (
			onChange: Function,
			_addMetric: Function,
			value?: string,
			_metricTierLk?: number | null,
			_isIdle?: boolean,
			_showCircularProgress?: boolean
		) => (
			<div>
				<TextField
					fullWidth
					multiline
					onChange={(e: TChangeEvent) => onChange({ abbreviation: e.target.value })}
					value={value}
					placeholder={"Enter abbreviation..."}
					style={FilterTextFieldStyle}
				/>
			</div>
		),
		getEditInput: (
			row: TPlayerPlanMetric,
			onChange: Function,
			_updateMetric: Function,
			_setMetricsIsEditable: Function,
			value?: string,
			_isIdle?: boolean
		) => (
			<div>
				<TextField
					fullWidth
					multiline
					onChange={(e: TChangeEvent) => onChange(row.id, { abbreviation: e.target.value })}
					value={value}
					placeholder={"Enter abbreviation..."}
					style={FilterTextFieldStyle}
				/>
			</div>
		)
	},
	{
		title: "Assigned to:",
		name: "assignedTo",
		tooltip: "Which players are currently assigned this metric?",
		getCellValue: (row: TPlayerPlanMetric, onChange?: Function) => (
			<FixedWidthDiv width="150px">
				{getAssignedCellValue(row.playersAssignedMetric)}
				{row.playersAssignedMetric && row.playersAssignedMetric.length > 2 && (
					<ModalLinkDiv onClick={onChange ? () => onChange(row ?? null, LINK_MODAL) : undefined}>{`${row
						.playersAssignedMetric.length - 1} others`}</ModalLinkDiv>
				)}
			</FixedWidthDiv>
		),
		sortFunc: (a: TPlayerPlanMetric, b: TPlayerPlanMetric) => {
			const aValue = a.playersAssignedMetric?.length ?? 0;
			const bValue = b.playersAssignedMetric?.length ?? 0;
			return aValue - bValue;
		}
	},
	{
		title: "Available for:",
		name: "availableFor",
		tooltip: "When can a Focus Area be used?",
		getCellValue: (row: TPlayerPlanMetric, onChange?: Function) => (
			<FixedWidthDiv width="100px">
				{// Handle case where there are goal metrics mapped to a Focus Area
				row.metricTier?.value === FOCUS_AREA_TIER ? (
					row.focusAreaAvailableGoalMetrics?.length ? (
						<ModalLinkDiv
							onClick={onChange ? () => onChange(row ?? null, METRIC_DISPLAY_MODAL) : undefined}
						>{`${row.focusAreaAvailableGoalMetrics.length} metric${
							row.focusAreaAvailableGoalMetrics.length > 1 ? "s" : ""
						}`}</ModalLinkDiv>
					) : // If there are no goal metrics mapped to a Focus Area, the Focus Area will be available based on metric type
					// For non baseball departments, Focus Area is always available
					NON_BASEBALL_METRIC_LKS.includes(row.metricTypeLk) ? (
						`All Goals`
					) : (
						// For baseball departments, Focus Area is available for goal metrics with matching type
						`All ${row.metricType.label} Goals`
					)
				) : (
					undefined
				)}
			</FixedWidthDiv>
		),
		sortFunc: (a: TPlayerPlanMetric, b: TPlayerPlanMetric) => {
			// Handle cases where cell has no text
			if (a.metricTier?.value === FOCUS_AREA_TIER && b.metricTier?.value !== FOCUS_AREA_TIER) return -1;
			if (a.metricTier?.value !== FOCUS_AREA_TIER && b.metricTier?.value !== FOCUS_AREA_TIER) return 0;
			if (a.metricTier?.value !== FOCUS_AREA_TIER && b.metricTier?.value === FOCUS_AREA_TIER) return 1;

			// For arrays with greater than 0 length, longer array comes first
			const aValue = a.focusAreaAvailableGoalMetrics?.length ?? 0;
			const bValue = b.focusAreaAvailableGoalMetrics?.length ?? 0;
			if (aValue > 0 && bValue > 0) return aValue > bValue ? 1 : -1;
			if (aValue > 0) return -1;
			if (bValue > 0) return 1;
			// Otherwise, sort by metric type, subtype labels
			const aValueStr = `${a.metricTypeLk}${a.metricSubtypeLk ?? ""}`;
			const bValueStr = `${b.metricTypeLk}${b.metricSubtypeLk ?? ""}`;
			return aValueStr.localeCompare(bValueStr);
		}
	},
	{
		title: "Create Date",
		name: "createDate",
		getCellValue: (row: TPlayerPlanMetric) => `${dayjs(row.createDate).format("MM-DD-YYYY")}` ?? NULL_TEXT,
		getFilter: (onChange: Function, value?: string, _metricTypes?: Array<string>) => (
			<MinWidthDiv minWidth="80px" flex={true}>
				<DatePicker
					placeholder={"Filter Date..."}
					onChange={(date?: dayjs.Dayjs) => {
						onChange({ createDate: date });
					}}
					value={value ? dayjs(value) : undefined}
					dateFormatString="MM/YYYY"
					showMonthYearPicker
					isClearable
					showBorderBottom={false}
					wrapperClassName={METRIC_DATEPICKER_WRAPPER_CLASS_NAME}
				/>
			</MinWidthDiv>
		),
		sortFunc: (a: TPlayerPlanMetric, b: TPlayerPlanMetric) => {
			const aValue = dayjs(a.createDate);
			const bValue = dayjs(b.createDate);
			if (aValue.isSame(bValue)) return 0;
			return aValue.isAfter(bValue) ? 1 : -1;
		}
	},
	{
		title: "Last Updated",
		name: "lastChangeDate",
		getCellValue: (row: TPlayerPlanMetric) => `${dayjs(row.lastChangeDate).format("MM-DD-YYYY")}` ?? NULL_TEXT,
		getFilter: (onChange: Function, value?: string, _metricTypes?: Array<string>) => (
			<MinWidthDiv minWidth="80px" flex={true}>
				<DatePicker
					placeholder="Filter Date..."
					onChange={(date?: dayjs.Dayjs) => {
						onChange({ lastChangeDate: date });
					}}
					value={value ? dayjs(value) : undefined}
					dateFormatString="MM/YYYY"
					showMonthYearPicker
					isClearable
					showBorderBottom={false}
					wrapperClassName={METRIC_DATEPICKER_WRAPPER_CLASS_NAME}
				/>
			</MinWidthDiv>
		),
		sortFunc: (a: TPlayerPlanMetric, b: TPlayerPlanMetric) => {
			const aValue = dayjs(a.lastChangeDate);
			const bValue = dayjs(b.lastChangeDate);
			if (aValue.isSame(bValue)) return 0;
			return aValue.isAfter(bValue) ? 1 : -1;
		}
	},
	{
		title: "Actions",
		name: "actions",
		tooltip: "Only available for Focus Areas/PHP",
		isNotSortable: true,
		getCellValue: (
			row: TPlayerPlanMetric,
			onChange?: Function,
			setMetricsIsEditable?: Function,
			setMetricsIsConfirmDelete?: Function,
			deleteMetric?: Function,
			isConfirmDelete?: boolean,
			isIdle?: boolean,
			circularProgressMetricId?: number | null,
			setCircularProgressMetricId?: Function
		) =>
			row.metricTier && EDITABLE_TIERS.includes(row.metricTier.value) ? (
				circularProgressMetricId === row.id ? (
					<CircularProgress center={false} style={SmallCircularProgressStyle} />
				) : isConfirmDelete === true ? (
					<FixedWidthDiv width="150px">
						<p>Are you sure you want to delete this Metric?</p>
						<Button
							style={LeftButtonStyle}
							colorScheme={defaultColorScheme.secondary}
							onClick={() => {
								if (deleteMetric && setCircularProgressMetricId) {
									setCircularProgressMetricId(row.id);
									deleteMetric(row.id);
								}
							}}
							title={"Yes"}
							disabled={isIdle === false}
						/>
						<Button
							colorScheme={defaultColorScheme.primary}
							onClick={() =>
								setMetricsIsConfirmDelete ? setMetricsIsConfirmDelete(row.id, false) : null
							}
							title={"Cancel"}
						/>
					</FixedWidthDiv>
				) : (
					<ActionsDiv>
						<Edit
							style={LeftButtonStyle}
							fill={defaultColorScheme.primary.color}
							onClick={() => (setMetricsIsEditable ? setMetricsIsEditable(row.id, true) : null)}
						/>
						{!NON_BASEBALL_METRIC_LKS.includes(row.metricTypeLk) &&
							row.metricTier?.value === FOCUS_AREA_TIER && (
								<Map
									style={LeftButtonStyle}
									onClick={onChange ? () => onChange(row ?? null, METRIC_MAP_MODAL) : undefined}
								/>
							)}
						<Delete
							fill={defaultColorScheme.secondary.color}
							onClick={() => (setMetricsIsConfirmDelete ? setMetricsIsConfirmDelete(row.id, true) : null)}
						/>
					</ActionsDiv>
				)
			) : (
				undefined
			),
		getAddInput: (
			_onChange: Function,
			addMetric: Function,
			_value?: string,
			_metricTierLk?: number | null,
			isIdle?: boolean,
			showCircularProgress?: boolean
		) =>
			showCircularProgress ? (
				<CircularProgress center={false} style={SmallCircularProgressStyle} />
			) : (
				<Button
					colorScheme={defaultColorScheme.primary}
					onClick={() => addMetric()}
					disabled={isIdle === false}
					title={"Add"}
				/>
			),
		getEditInput: (
			row: TPlayerPlanMetric,
			_onChange: Function,
			updateMetric: Function,
			setMetricsIsEditable: Function,
			_value?: string,
			isIdle?: boolean,
			circularProgressMetricId?: number | null,
			setCircularProgressMetricId?: Function
		) =>
			circularProgressMetricId === row.id ? (
				<CircularProgress center={false} style={SmallCircularProgressStyle} />
			) : (
				<FixedWidthDiv width="150px">
					<Button
						colorScheme={defaultColorScheme.secondary}
						onClick={() => {
							if (updateMetric && setCircularProgressMetricId) {
								setCircularProgressMetricId(row.id);
								updateMetric(row.id);
							}
						}}
						disabled={isIdle === false}
						title={"Save"}
						style={LeftButtonStyle}
					/>
					<Button
						colorScheme={defaultColorScheme.primary}
						onClick={() => setMetricsIsEditable(row.id, false)}
						title={"Cancel"}
					/>
				</FixedWidthDiv>
			)
	}
];
