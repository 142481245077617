import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import { MODEL_NAME_V2, PLAYER_TYPE_BATTER, PLAYER_TYPE_PITCHER } from "_react/shared/data_models/phred/_constants";
import {
	TReplacementLevelActive,
	IAmaProspectValueApiResponse,
	IAmaProspectValuePlayer
} from "_react/shared/data_models/phred/_types";

// Replacement Level Active

export type TReplacementLevelActiveGetQueryParams = {
	date?: string;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
};

export const fetchReplacementLevelActive = (
	queryParams: TReplacementLevelActiveGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TReplacementLevelActiveGetQueryParams, Array<TReplacementLevelActive>>(
		"/phred/replacement_level_active",
		queryParams,
		cancelToken
	);

// AMA Prospect Value

export type TAmaProspectValueGetQueryParams = {
	playerId?: number;
	r4Year?: number;
	"r4Year[gte]"?: number;
	"r4Year[lte]"?: number;
	playerType?: string;
	modelName?: string;
	xsurplus?: number;
	xsurplusRank?: number;
	schema?: string;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchAmaProspectValue = (queryParams: TAmaProspectValueGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TAmaProspectValueGetQueryParams, Array<IAmaProspectValueApiResponse>>(
		"/phred/ama_prospect_value",
		queryParams,
		cancelToken
	);

// AMA Prospect Value Player
export type TAmaProspectValueSimilarPlayersPlayerType = typeof PLAYER_TYPE_BATTER | typeof PLAYER_TYPE_PITCHER;
export type TAmaProspectValueSimilarPlayersModelName = typeof MODEL_NAME_V2;

export type TAmaProspectValueSimilarPlayersGetQueryParams = {
	playerId?: number;
	r4YearPlayer?: number;
	playerType?: TAmaProspectValueSimilarPlayersPlayerType;
	modelName?: TAmaProspectValueSimilarPlayersModelName;
	r4Year?: number;
	"r4Year[gte]"?: number;
	"r4Year[lte]"?: number;
	position?: string;
	positionGroup?: string;
	numberSimilarPlayers?: number;
	isMostRecentEntry?: boolean;
	isUseCache?: boolean;
};

export const fetchAmaProspectValueSimilarPlayers = (
	queryParams: TAmaProspectValueSimilarPlayersGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TAmaProspectValueSimilarPlayersGetQueryParams, Array<IAmaProspectValuePlayer>>(
		"/phred/ama_prospect_value/similar_player",
		queryParams,
		cancelToken
	);
