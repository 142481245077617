import { TVideoWDPL } from "_react/video/shared/_types";

export const TYPE_OPTIONS = [
	{ value: "media", label: "DPL" },
	{ value: "fangraphs", label: "Fangraphs" },
	{ value: "clips", label: "Clips" },
	{ value: "other", label: "Other" }
] as const;

export type TTypeOpt = typeof TYPE_OPTIONS[number];

type TVideoTypes = Array<TVideoWDPL["type"]>;

export function getVideoTypes(typeOpt: TTypeOpt | null): TVideoTypes | null {
	if (typeOpt == null) return null;
	if (typeOpt.value !== "other") return [typeOpt.value];
	return ["phil", "video"];
}
