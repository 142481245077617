import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const helpers = createMultiStyleConfigHelpers(["filledTrack", "track"]);

const variantStat = helpers.definePartsStyle(() => {
	return {
		filledTrack: {
			bg: "green.700"
		},
		track: {
			bg: "gray.300"
		}
	};
});

const variants = {
	stat: variantStat
};

export const progressTheme = helpers.defineMultiStyleConfig({
	variants
});
