import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";

import { IIamUserSchema } from "_react/shared/data_models/iam_new/_types";

// IAM user

export type TUserGetQueryParams = {
	userId?: number;
	include?: string;
};

export const fetchUser = (queryParams: TUserGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TUserGetQueryParams, IIamUserSchema>("/iam/user", queryParams, cancelToken);
