import styled from "@emotion/styled";
import React, { FunctionComponent, useContext } from "react";

import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import {
	VIEW_BIO,
	VIEW_STATISTICS,
	VIEW_CONTRACT,
	VIEW_TRANSACTIONS,
	VIEW_INJURIES,
	VIEW_MANUEL,
	VIEW_VIDEO,
	VIEW_NOTES
} from "_react/shared/_constants/query_strings";

import { Bio } from "_react/playerpage/bio/Bio";
import { Contract } from "_react/playerpage/contract/Contract";
import { Injuries } from "_react/playerpage/injuries/Injuries";
import RockyNotes from "_react/playerpage/notes";
import { Statistics } from "_react/playerpage/stat/Statistics";
import Summary from "_react/playerpage/Summary";
import { Transactions } from "_react/playerpage/transaction/Transactions";
import Video from "_react/playerpage/video/Video";
import { Plan } from "_react/playerpage/plan";
import { PlayerPageContext } from "_react/playerpage/_context";
import { TPlayerPageCombinedPlayer, TPlayer } from "_react/playerpage/_types";

type TContentProps = {
	playerCombined?: TPlayerPageCombinedPlayer | null;
	player?: TPlayer | null;
	colorSchemeGroup: ColorSchemeGroup;
	view?: string;
	hasManuelsPermissions?: boolean;
};

const StyledContent = styled.div({
	textAlign: "center",
	padding: "10px",
	paddingBottom: "65px",
	boxSizing: "border-box",
	height: "100%"
});

export const Content: FunctionComponent<TContentProps> = ({
	playerCombined,
	player,
	colorSchemeGroup,
	view,
	hasManuelsPermissions = false
}) => {
	const { evals, viewClassification } = useContext(PlayerPageContext);

	if (player == null || playerCombined == null) return <div />;

	return (
		<StyledContent>
			{view === VIEW_BIO ? (
				<Bio colorScheme={colorSchemeGroup} player={playerCombined} viewClassification={viewClassification} />
			) : view === VIEW_STATISTICS ? (
				<Statistics colorScheme={colorSchemeGroup} player={player} />
			) : view === VIEW_CONTRACT ? (
				<Contract colorScheme={colorSchemeGroup} player={playerCombined} />
			) : view === VIEW_TRANSACTIONS ? (
				<Transactions colorScheme={colorSchemeGroup} player={playerCombined} />
			) : view === VIEW_INJURIES ? (
				<Injuries
					colorSchemeGroup={colorSchemeGroup}
					player={playerCombined}
					viewClassification={viewClassification}
				/>
			) : view === VIEW_MANUEL && hasManuelsPermissions ? (
				<Plan playerId={playerCombined.id} colorScheme={colorSchemeGroup} />
			) : view === VIEW_VIDEO ? (
				<Video player={playerCombined} viewClassification={viewClassification} />
			) : view === VIEW_NOTES ? (
				<RockyNotes player={playerCombined} colorScheme={colorSchemeGroup} />
			) : (
				<Summary
					colorScheme={colorSchemeGroup}
					evaluations={evals}
					player={player}
					playerCombined={playerCombined}
					viewClassification={viewClassification}
				/>
			)}
		</StyledContent>
	);
};
