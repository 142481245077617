import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { correctOFPLabel, shadeColor } from "_react/playerpage/shared_js/evals/_helpers";
import { Badge } from "_react/playerpage/shared_js/evals/Badge";

const EvalTableGroupRowNested = ({ evaluation, history, idx }) => {
	const [hover, setHover] = useState(false);

	const color = evaluation.publish_date ? "#535353" : "#FF0000";
	const isPro = evaluation && evaluation.eval_type.startsWith("PRO");

	const link = `eval?id=${evaluation.eval_id}`;
	return (
		<div
			key={idx}
			onClick={() => history.push(link)}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			style={{
				padding: "2px 4px",
				border: "none",
				cursor: "pointer",
				overflow: "hidden"
			}}
		>
			<Badge
				color={hover ? shadeColor(color, 40) : color}
				label={
					evaluation.seen_date && evaluation.seen_date.isValid()
						? evaluation.seen_date.format("MMM D")
						: "Seen Unknown"
				}
				subLabel={evaluation.publish_date ? null : isPro ? "Unpublished Update" : "Unpublished"}
				textColor={evaluation.publish_date ? null : "#FFFFFF"}
				value={
					<div style={{ marginTop: "3px" }}>
						{evaluation.ofp || evaluation.group
							? correctOFPLabel(evaluation.eval_report_type, evaluation.ofp, evaluation)
							: "-"}
					</div>
				}
			/>
		</div>
	);
};

EvalTableGroupRowNested.propTypes = {
	evaluation: PropTypes.object,
	history: PropTypes.object,
	idx: PropTypes.number
};

export default withRouter(EvalTableGroupRowNested);
