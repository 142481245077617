import { CancelToken } from "axios";

import axios from "_redux/_utils/_axios";
import { fetchResource, handleAxiosError } from "_react/shared/_helpers/axios";
import { IBlendedSurplusValue, TAllSurplusValues } from "_react/shared/data_models/surplus_value/_types";

export type TAllSurplusValuesGetQueryParams = {
	playerId?: number;
	"playerId[in]"?: string;
	year?: number;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export type TBlendedSurplusValueQueryParams = {
	bamId?: number;
	isCurrent?: number;
	percentSim?: number;
	blendedSv?: number;
	"blendedSv[gte]"?: number;
	"blendedSv[lte]"?: number;
	simSvTotal?: number;
	"simSvTotal[gte]"?: number;
	"simSvTotal[lte]"?: number;
	xSv?: number;
	"xSv[gte]"?: number;
	"xSv[lte]"?: number;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export type TBlendedSurplusValueGetQueryParams = TBlendedSurplusValueQueryParams & {
	"bamId[in]"?: string;
};
export type TBlendedSurplusValuePostQueryParams = TBlendedSurplusValueQueryParams & {
	bamIds?: number[] | null;
};

export const fetchAllSurplusValues = (queryParams: TAllSurplusValuesGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TAllSurplusValuesGetQueryParams, Array<TAllSurplusValues>>(
		"/surplus_value/all",
		queryParams,
		cancelToken
	);

export const fetchBlendedSurplusValues = (queryParams: TBlendedSurplusValueGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TBlendedSurplusValueGetQueryParams, Array<IBlendedSurplusValue>>(
		"/surplus_value/blended",
		queryParams,
		cancelToken
	);

export const searchBlendedSurplusValues = (
	queryParams?: TBlendedSurplusValuePostQueryParams,
	cancelToken?: CancelToken
) => {
	const path = `/surplus_value/blended/search`;

	return axios
		.post(path, queryParams, { cancelToken })
		.then(response => response.data as Array<IBlendedSurplusValue>)
		.catch(handleAxiosError);
};
