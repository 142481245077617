import React from "react";

import { Button } from "_react/shared/legacy/ui/Button";
import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";

import { TDocumentBase, TFormSaveState, ENTIRE_FORM } from "_react/shared/forms/_types";
import { FormFooterContainer } from "_react/shared/forms/_styles";
import { useFormContext } from "_react/shared/forms/_context";
import { $TSFixMe } from "utils/tsutils";

interface FormSectionProps<_T extends TDocumentBase> {
	savingState: TFormSaveState;
	floating: boolean;
	additionalFooterContents?: $TSFixMe;
	showSaveButton: boolean;
}

export function FormFooter<T extends TDocumentBase>({
	savingState,
	floating,
	additionalFooterContents,
	showSaveButton
}: FormSectionProps<T>) {
	const { save, validationErrors } = useFormContext<T>();
	const formLevelInvalidMessage = validationErrors.find(error => error.component === ENTIRE_FORM)?.message;
	const fieldLevelInvalidFields = validationErrors
		.filter(error => error.component !== ENTIRE_FORM && error.component.label != null)
		?.map(error => (error.component !== ENTIRE_FORM ? error.component.label : "unknown field"))
		.join(", ");
	const isInvalid = validationErrors.length > 0;

	const saveButton = (
		<Button
			title={savingState.saving && !savingState.publishing ? "Saving..." : "Save"}
			onClick={() => save()}
			colorScheme={defaultColorScheme.secondary}
			disabled={savingState.saving || isInvalid || savingState.publishing}
		/>
	);

	return (
		<FormFooterContainer floating={floating}>
			{!isInvalid && showSaveButton && saveButton}
			{isInvalid && showSaveButton && (
				<Tooltip
					placement="right"
					title={formLevelInvalidMessage ?? "Validation Errors Exist on: \n" + fieldLevelInvalidFields}
					colorScheme={defaultColorScheme.secondary}
					componentWrapperStyle={{ height: "21px" }}
				>
					{saveButton}
				</Tooltip>
			)}
			{showSaveButton && savingState.saveErrored === true && (
				<span style={{ fontSize: "14px", fontWeight: 600, paddingLeft: "5px", color: "red" }}>
					ERROR SAVING
				</span>
			)}
			{showSaveButton && savingState.unsavedChanges === true && !savingState.saving && (
				<span style={{ fontSize: "14px", fontWeight: 600, paddingLeft: "5px" }}>Unsaved Changes</span>
			)}
			{showSaveButton && savingState.lastSaved != null && !savingState.saving && (
				<span
					style={{
						fontStyle: "italic",
						fontSize: "14px",
						fontWeight: 600,
						color: "gray",
						paddingLeft: "5px"
					}}
				>
					Last Saved: {savingState.lastSaved.format("h:mm a")}
				</span>
			)}
			{additionalFooterContents != null && (
				<>
					{showSaveButton && <div style={{ flexGrow: 1 }} />}
					{additionalFooterContents}
				</>
			)}
		</FormFooterContainer>
	);
}
