import { useState } from "react";
import axios from "_redux/_utils/_axios";
import { BASE_URL } from "utils/redux_constants";
import { isEmpty } from "utils/helpers";
import { agent } from "_react/shared/_types/mesa/agent";
import { agency } from "_react/shared/_types/mesa/agency";

export const getKeyFromAgentAgency = (agentId: string | number, type: string) => {
	return `${type === "intl" ? "ama" : type}~${agentId}`;
};

export const getKeyFromAgentAgencySet = (type: string) => {
	return type === "intl" ? "ama" : type;
};

export type TAgentById = { [key: string]: agent };
export type TAgencyById = { [key: string]: agency };

export const useGetAgents: () => [() => Promise<TAgentById>, boolean, boolean] = () => {
	const [agents, setAgents] = useState<TAgentById>({});
	const [fetching, setFetching] = useState(false);
	const [failed, setFailed] = useState(false);
	const [axiosPromise, setAxiosPromise] = useState<Promise<TAgentById>>(Promise.resolve({}));
	const requestUrl = `${BASE_URL}/agent`;
	const getAgents = () => {
		if (fetching) {
			return axiosPromise;
		} else if (!isEmpty(agents)) {
			return Promise.resolve(agents);
		} else {
			setFailed(false);
			setFetching(true);
			const newPromise = axios
				.get(requestUrl)
				.then((response: { data: agent[] }) => {
					const newAgents: TAgentById = {};
					response.data.forEach((responseAgent: agent) => {
						newAgents[responseAgent.agentId] = responseAgent;
					});
					setAgents(newAgents);
					setFailed(false);
					setFetching(false);
					return newAgents;
				})
				.catch(() => {
					setFailed(true);
					setFetching(false);
					setAgents({});
					return {};
				});
			setAxiosPromise(newPromise);
			return newPromise;
		}
	};

	return [getAgents, fetching, failed];
};

export const useGetAgencies: () => [() => Promise<TAgencyById>, boolean, boolean] = () => {
	const [agencies, setAgencies] = useState<TAgencyById>({});
	const [fetching, setFetching] = useState(false);
	const [failed, setFailed] = useState(false);
	const [axiosPromise, setAxiosPromise] = useState<Promise<TAgencyById>>(Promise.resolve({}));
	const requestUrl = `${BASE_URL}/agency`;
	const getAgencies = () => {
		if (fetching) {
			return axiosPromise;
		} else if (!isEmpty(agencies)) {
			return Promise.resolve(agencies);
		} else {
			setFailed(false);
			setFetching(true);
			const newPromise = axios
				.get(requestUrl)
				.then((response: { data: agency[] }) => {
					const newAgencies: TAgencyById = {};
					response.data.forEach((responseAgency: agency) => {
						newAgencies[responseAgency.agencyId] = responseAgency;
					});
					setAgencies(newAgencies);
					setFailed(false);
					setFetching(false);
					return newAgencies;
				})
				.catch(() => {
					setFailed(true);
					setFetching(false);
					setAgencies({});
					return {};
				});
			setAxiosPromise(newPromise);
			return newPromise;
		}
	};

	return [getAgencies, fetching, failed];
};
