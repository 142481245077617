import { getColorGradientFunctionBlueGreen, getColorGradientFunctionWhiteGreen } from "_react/shared/dataviz/_helpers";

// Colors

export const RAR_MIN = -50;
export const RAR_MAX = 50;
export const RAR_COLOR_GRADIENT = getColorGradientFunctionBlueGreen(RAR_MIN, RAR_MAX);
export const [RAR_COLOR_GRADIENT_FUNCTION] = RAR_COLOR_GRADIENT;
export const RAR_COLOR_GRADIENT_TEXT = getColorGradientFunctionBlueGreen(RAR_MIN, RAR_MAX, undefined, true);
export const [RAR_COLOR_GRADIENT_TEXT_FUNCTION] = RAR_COLOR_GRADIENT_TEXT;

export const XWAR_MIN = -0.1;
export const XWAR_MAX = 30;
export const XWAR_COLOR_GRADIENT = getColorGradientFunctionWhiteGreen(XWAR_MIN, XWAR_MAX);
export const [XWAR_COLOR_GRADIENT_FUNCTION] = XWAR_COLOR_GRADIENT;
export const XWAR_COLOR_GRADIENT_TEXT = getColorGradientFunctionWhiteGreen(XWAR_MIN, XWAR_MAX, true);
export const [XWAR_COLOR_GRADIENT_TEXT_FUNCTION] = XWAR_COLOR_GRADIENT_TEXT;

export const PERCENTAGE_COLOR_GRADIENT = getColorGradientFunctionBlueGreen(0, 1);
export const [PERCENTAGE_COLOR_GRADIENT_FUNCTION] = PERCENTAGE_COLOR_GRADIENT;

// Table Columns

// Removed position and age column
export const SIMILAR_PROJECTION_SP_COLUMNS_MOBILE = ["playerName", "org", "sv", "spRar"];
export const SIMILAR_PROJECTION_RP_COLUMNS_MOBILE = ["playerName", "org", "sv", "rpRar"];
export const SIMILAR_PROJECTION_PLAYERS_COLUMNS_SUBSET = [
	"playerName",
	"org",
	"sv",
	"totalBattingRar",
	"battingRaa",
	"baserunningRaa",
	"defRaa",
	"posAdj",
	"spRar",
	"rpRar"
];
export const SIMILAR_PROJECTION_PLAYERS_COLUMNS = [
	"level",
	"bats",
	"throws",
	"mls",
	...SIMILAR_PROJECTION_PLAYERS_COLUMNS_SUBSET
];

// Removed position and age columns
export const SIMILAR_PRO_MODEL_COLUMNS_MOBILE = ["playerName", "xWAR", "blendedSv", "mlbProb"];
export const SIMILAR_PRO_MODEL_COLUMNS_SUBSET = [
	"40ManR5",
	"level",
	"bats",
	"throws",
	...SIMILAR_PRO_MODEL_COLUMNS_MOBILE
];

export const SIMILAR_PRO_MODEL_COLUMNS = ["age", ...SIMILAR_PRO_MODEL_COLUMNS_SUBSET];
