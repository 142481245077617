import styled from "@emotion/styled";

export const PLAYER_CLASSIFICATION_SEARCH_COOKIE = "playerClassificationSearch";
export const PLAYER_CLASSIFICATION_SEARCH_PRO = "PRO";
export const PLAYER_CLASSIFICATION_SEARCH_AMA = "AMA";
export const PLAYER_CLASSIFICATION_SEARCH_INTL = "INTL";
export const PLAYER_CLASSIFICATION_SEARCH_ALL_COMPONENTS = [
	PLAYER_CLASSIFICATION_SEARCH_PRO,
	PLAYER_CLASSIFICATION_SEARCH_AMA,
	PLAYER_CLASSIFICATION_SEARCH_INTL
];
export const PLAYER_CLASSIFICATION_SEARCH_ALL = PLAYER_CLASSIFICATION_SEARCH_ALL_COMPONENTS.join(",");
export type PLAYER_CLASSIFICATION_SEARCH_OPTION =
	| typeof PLAYER_CLASSIFICATION_SEARCH_PRO
	| typeof PLAYER_CLASSIFICATION_SEARCH_AMA
	| typeof PLAYER_CLASSIFICATION_SEARCH_INTL;
export const PLAYER_CLASSIFICATION_SEARCH_OPTIONS = [
	{ text: "Pro Players", value: PLAYER_CLASSIFICATION_SEARCH_PRO },
	{ text: "Amateur Players", value: PLAYER_CLASSIFICATION_SEARCH_AMA },
	{ text: "International Players", value: PLAYER_CLASSIFICATION_SEARCH_INTL }
];
export const getPlayerSearchClassifications = (cookieValue: string) => {
	return cookieValue.split(",");
};
export const includesPlayerClassification = (
	playerSearchClassifications: string[],
	playerSearchClassification: string
) => {
	return playerSearchClassifications.includes(playerSearchClassification);
};
export const getNewPlayerSearchClassifications = (
	playerSearchClassifications: string[],
	playerSearchClassification: string
) => {
	if (playerSearchClassifications.includes(playerSearchClassification)) {
		// Remove
		const newPlayerSearchClassifications = playerSearchClassifications.filter(
			classification => classification !== playerSearchClassification
		);
		return newPlayerSearchClassifications.length === 0
			? PLAYER_CLASSIFICATION_SEARCH_ALL_COMPONENTS
			: newPlayerSearchClassifications;
	} else {
		// Add
		return [...playerSearchClassifications, playerSearchClassification];
	}
};
export const getPlayerSearchClassificationsButtonLabel = (playerSearchClassifications: string[]) => {
	if (
		PLAYER_CLASSIFICATION_SEARCH_ALL_COMPONENTS.reduce(
			(currentResult, classification) =>
				playerSearchClassifications.includes(classification) ? currentResult : false,
			true
		)
	)
		return "All Players";
	return playerSearchClassifications.join(", ");
};

export const CHECK_STYLE = { marginRight: "5px", color: "black" };
export const CheckPlaceholder = styled.span({ height: "24px", width: "29px" });
