import styled from "@emotion/styled";
import { TPartialStyles } from "utils/tsutils";
import { generateColorScheme } from "_react/shared/legacy/ui/Colors";

export const ToolbarDiv = styled.div({
	boxSizing: "border-box",
	paddingRight: 5,
	display: "flex",
	justifyContent: "flex-end"
});

export const SelectDiv = styled.div<TPartialStyles<"marginLeft" | "minWidth">>(({ marginLeft, minWidth = 75 }) => ({
	minWidth,
	boxSizing: "border-box",
	marginLeft
}));

export const LevelsDivContainer = styled.div({
	boxSizing: "border-box",
	marginLeft: 4,
	display: "flex"
});

export const ButtonsContainer = styled.div({ width: 275 });

export const BUTTONS_COLOR_SCHEME = generateColorScheme({
	color: "gainsboro",
	border: "black",
	backgroundColor: "gainsboro",
	text: "black",
	hover: "white",
	hoverText: "gray",
	selected: "lightgray",
	selectedText: "rgb(48,48,48)"
});
