// Copied from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor#decimal_adjustment

/**
 * Adjusts a number to the specified digit.
 *
 * @param {"round" | "floor" | "ceil"} type The type of adjustment.
 * @param {number} value The number.
 * @param {number} exp The exponent (the 10 logarithm of the adjustment base).
 * @returns {number} The adjusted value.
 */
function decimalAdjust(type, value, exp) {
	type = String(type);
	if (!["round", "floor", "ceil"].includes(type)) {
		throw new TypeError("The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'.");
	}
	exp = Number(exp);
	value = Number(value);
	if (exp % 1 !== 0 || Number.isNaN(value)) {
		return NaN;
	} else if (exp === 0) {
		return Math[type](value);
	}
	const [magnitude, exponent = 0] = value.toString().split("e");
	const adjustedValue = Math[type](`${magnitude}e${exponent - exp}`);
	// Shift back
	const [newMagnitude, newExponent = 0] = adjustedValue.toString().split("e");
	return Number(`${newMagnitude}e${+newExponent + exp}`);
}

// Decimal round
export const round10 = (value, exp = 0, isRemoveLeadingZero = false) => {
	if (value == null) return undefined;
	const roundValue = decimalAdjust("round", value, exp);
	const result = exp >= 0 ? roundValue.toString() : roundValue.toFixed(-1 * exp);
	// Remove leading zero if necessary
	return isRemoveLeadingZero && result?.charAt(0) === "0" ? result?.substring(1) : result;
};
// Decimal floor
export const floor10 = (value, exp = 0) => {
	if (value == null) return undefined;
	const floorValue = decimalAdjust("floor", value, exp);
	return exp >= 0 ? floorValue.toString() : floorValue.toFixed(-1 * exp);
};
// Decimal ceil
export const ceil10 = (value, exp = 0) => {
	if (value == null) return undefined;
	const ceilValue = decimalAdjust("ceil", value, exp);
	return exp >= 0 ? ceilValue.toString() : ceilValue.toFixed(-1 * exp);
};
