import { extent, interpolateRgb } from "d3";
import { scaleLinear } from "@visx/scale";

import { getColorGradientFunction, getRgbNumberArray, colorToRGBA, TColorGradientFunction } from "utils/color";

import {
	DEFAULT_WIDTH,
	DEFAULT_HEIGHT,
	DEFAULT_MARGINS,
	DATA_COLOR_PRIMARY_BLUE_RGB,
	DATA_COLOR_PRIMARY_GREEN_RGB,
	DATA_COLOR_PRIMARY_RED_RGB,
	TRUE_WHITE_HEX,
	PITCH_TYPE_COLORSCALE_MAX_VALUES,
	TEXT_COLOR_PRIMARY_GREEN_RGB
} from "_react/shared/dataviz/_constants";
import { TMarginsProps, TMargins, TPlotDimensions } from "_react/shared/dataviz/_types";

// Wrapper for d3 extent because we want to be able to set default values instead of returning undefined
type TExtentType<T extends number | Date> = [T, T];
export const getExtent = <T extends number | Date>(
	defaultMinValue: T,
	defaultMaxValue: T,
	dataArray?: Array<T>
): TExtentType<T> => {
	if (dataArray) {
		const extentArray = extent(dataArray);
		const min = extentArray[0] ?? defaultMinValue;
		const max = extentArray[1] ?? defaultMaxValue;
		return [min, max];
	}
	return [defaultMinValue, defaultMaxValue];
};

export const getMiddleValue = (firstValue: number, secondValue: number) => {
	return (firstValue + secondValue) / 2;
};

export const getPlotDimensions = (
	width = DEFAULT_WIDTH,
	height = DEFAULT_HEIGHT,
	margins?: TMarginsProps
): TPlotDimensions => {
	const actualMargins: TMargins = {
		left: margins?.left ?? DEFAULT_MARGINS.left,
		right: margins?.right ?? DEFAULT_MARGINS.right,
		top: margins?.top ?? DEFAULT_MARGINS.top,
		bottom: margins?.bottom ?? DEFAULT_MARGINS.bottom
	};
	const innerWidth = width - actualMargins.left - actualMargins.right;
	const innerHeight = height - actualMargins.top - actualMargins.bottom;
	return { width, height, margins: actualMargins, innerWidth, innerHeight };
};

// Get the plot dimensions if we want the x and y axis to be scaled the same
// Can pass in either height or width and function will determine other dimension
export const getSameScalePlotDimensions = (
	xMin: number,
	xMax: number,
	yMin: number,
	yMax: number,
	margins: TMarginsProps,
	size?: { value: number; dimension: "width" | "height" }
) => {
	const actualMargins: TMargins = {
		left: margins?.left ?? DEFAULT_MARGINS.left,
		right: margins?.right ?? DEFAULT_MARGINS.right,
		top: margins?.top ?? DEFAULT_MARGINS.top,
		bottom: margins?.bottom ?? DEFAULT_MARGINS.bottom
	};

	// Have to use the max and min tick values because we have `nice: true` set for both axes and that can pad the values
	const xTickValues = scaleLinear<number>({
		domain: [xMin, xMax],
		nice: true
	}).ticks();
	const yTickValues = scaleLinear<number>({
		domain: [yMin, yMax],
		nice: true
	}).ticks();

	const rangeX = xTickValues[xTickValues.length - 1] - xTickValues[0];
	const rangeY = yTickValues[yTickValues.length - 1] - yTickValues[0];

	// Default is for width to determine plot dimensions
	let width = size?.value ?? DEFAULT_WIDTH;
	let innerWidth = width - actualMargins.left - actualMargins.right;
	let innerHeight = (rangeY / rangeX) * innerWidth;
	let height = innerHeight + actualMargins.bottom + actualMargins.top;

	// Can also determine plot dimensions using provided height
	if (size?.dimension === "height") {
		height = size.value;
		innerHeight = height - actualMargins.bottom - actualMargins.top;
		innerWidth = (rangeX / rangeY) * innerHeight;
		width = innerWidth + actualMargins.left + actualMargins.right;
		return { width, height, margins: actualMargins, innerWidth, innerHeight };
	}

	return { width, height, margins: actualMargins, innerWidth, innerHeight };
};

export const getColorGradientFunctionBlueRed = (minValue: number, maxValue: number, middleValue?: number) => {
	const BLUE_ARRAY = getRgbNumberArray(DATA_COLOR_PRIMARY_BLUE_RGB);
	const RED_ARRAY = getRgbNumberArray(DATA_COLOR_PRIMARY_RED_RGB);

	return getColorGradientFunction(minValue, maxValue, BLUE_ARRAY, [255, 255, 255], RED_ARRAY, middleValue);
};

export const getColorGradientFunctionBlueGreen = (
	minValue: number,
	maxValue: number,
	middleValue?: number,
	isText = false
) => {
	const BLUE_ARRAY = getRgbNumberArray(DATA_COLOR_PRIMARY_BLUE_RGB);
	// Make green text two shades darker
	const GREEN_ARRAY = getRgbNumberArray(isText ? TEXT_COLOR_PRIMARY_GREEN_RGB : DATA_COLOR_PRIMARY_GREEN_RGB);

	return getColorGradientFunction(minValue, maxValue, BLUE_ARRAY, [255, 255, 255], GREEN_ARRAY, middleValue);
};

export const getColorGradientFunctionWhiteRed = (minValue: number, maxValue: number) => {
	const WHITE_ARRAY: [number, number, number] = [255, 255, 255];
	const MID_ARRAY: [number, number, number] = [242, 159, 159];
	const RED_ARRAY = getRgbNumberArray(DATA_COLOR_PRIMARY_RED_RGB);

	return getColorGradientFunction(minValue, maxValue, WHITE_ARRAY, MID_ARRAY, RED_ARRAY);
};

export const getColorGradientFunctionWhiteGreen = (minValue: number, maxValue: number, isText = false) => {
	const WHITE_ARRAY: [number, number, number] = [255, 255, 255];
	const MID_ARRAY: [number, number, number] = [180, 233, 200];
	const GREEN_ARRAY = getRgbNumberArray(DATA_COLOR_PRIMARY_GREEN_RGB);
	const GREEN_TEXT_ARRAY = getRgbNumberArray(TEXT_COLOR_PRIMARY_GREEN_RGB);

	if (isText)
		return getColorGradientFunction(minValue, maxValue, GREEN_TEXT_ARRAY, GREEN_TEXT_ARRAY, GREEN_TEXT_ARRAY); // Always green
	return getColorGradientFunction(minValue, maxValue, WHITE_ARRAY, MID_ARRAY, GREEN_ARRAY);
};

export const getPitchTypeColorScale = () => {
	const pitchTypeColorscale: { [index: string]: Plotly.ColorScale } = {};
	Object.keys(PITCH_TYPE_COLORSCALE_MAX_VALUES).forEach((pitchType: string) => {
		const colorInterpolator = interpolateRgb(TRUE_WHITE_HEX, PITCH_TYPE_COLORSCALE_MAX_VALUES[pitchType]);
		pitchTypeColorscale[pitchType] = [
			[0, "rgba(255,255,255,0)"],
			[0.4, colorToRGBA(colorInterpolator(0.25), 0.6)],
			[0.55, colorToRGBA(colorInterpolator(0.45), 0.7)],
			[0.7, colorToRGBA(colorInterpolator(0.65), 0.8)],
			[0.85, colorToRGBA(colorInterpolator(0.85), 0.9)],
			[1, colorToRGBA(colorInterpolator(1), 1)]
		] as Plotly.ColorScale;
	});
	return pitchTypeColorscale;
};

export const getColorGradientBinary = (
	value: number | null | undefined,
	colorGradientInfo: TColorGradientFunction | [undefined, undefined, undefined]
) => {
	const [_getColorFromGradient, colorGradientColors, colorGradientValues] = colorGradientInfo;
	return colorGradientColors && colorGradientValues && value
		? value < colorGradientValues.middleValue
			? `rgb(${colorGradientColors.minColor})`
			: value > colorGradientValues.middleValue
			? `rgb(${colorGradientColors.maxColor})`
			: undefined
		: undefined;
};

export const getZScore = (
	mean: number | null | undefined,
	sd: number | null | undefined,
	value: number | null | undefined
): number | null => {
	// If any values are null/undefined, or if sd is 0, return null
	if (!sd || mean == null || value == null) return null;
	return (value - mean) / sd;
};
