import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function Check(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 96 960 960" {...props}>
			<path fill="currentColor" d="M378 810L154 586l43-43 181 181 384-384 43 43-427 427z" />
		</Icon>
	);
}
