import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import Autocomplete from "_react/shared/legacy/autocomplete/Autocomplete";
import { STATES, COUNTRIES } from "_react/playerpage/shared_js/form_constants";
import { createAgency } from "_redux/Agent/agent.a";

class AddAgencyModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			errorText: "",
			adding: false
		};
	}

	handleAdd = () => {
		const { agencyName, address1, address2, address3, city, state, country, zip, phone } = this.state;

		if (!agencyName) {
			this.setState({ errorText: "Name is required" });
		} else {
			this.setState({ adding: true });
			const agencyDict = {
				agencyName,
				address1,
				address2,
				address3,
				city,
				state,
				country,
				zip,
				phone,
				isActive: 1
			};
			this.props.createAgency(agencyDict);
			this.setState({ errorText: "", adding: false });
			this.props.handleClose(agencyDict);
		}
	};

	handleUpdate = value => this.setState({ [value.id]: value.value });

	render() {
		const { agencyName, address1, address2, address3, city, state, country, zip, phone } = this.state;

		const fields = [
			{
				name: "Firm Name",
				id: "agencyName",
				value: agencyName,
				type: "text"
			},
			{
				name: "Address 1",
				id: "address1",
				value: address1,
				type: "text"
			},
			{
				name: "Address 2",
				id: "address2",
				value: address2,
				type: "text"
			},
			{
				name: "Address 3",
				id: "address3",
				value: address3,
				type: "text"
			},
			{ name: "City", id: "city", value: city, type: "text" },
			{
				name: "State",
				id: "state",
				value: state,
				type: "autocomplete",
				options: STATES
			},
			{
				name: "Country",
				id: "country",
				value: country,
				type: "autocomplete",
				options: COUNTRIES["three_letter_abbreviations"]
			},
			{ name: "Zip", id: "zip", value: zip, type: "text" },
			{ name: "Phone", id: "phone", value: phone, type: "text" }
		];

		return (
			<div>
				<Dialog
					onClose={() => this.props.handleClose(null)}
					open={true}
					PaperProps={{
						style: {
							overflowY: "visible"
						}
					}}
					scroll="body"
				>
					<DialogTitle>Add Agency</DialogTitle>
					<DialogContent>
						<Grid container spacing={8}>
							{fields.map((field, idx) => {
								let selectedOption = null;
								switch (field.type) {
									case "text":
										return (
											<Grid item key={idx} xs={12}>
												<TextField
													fullWidth={true}
													id={field.id}
													label={field.name}
													onChange={e =>
														this.handleUpdate({
															id: field.id,
															value: e.target.value
														})
													}
													style={{ height: "72px" }}
													value={field.value}
												/>
											</Grid>
										);
									case "autocomplete":
										selectedOption = field.options.filter(e => e.value === field.value);
										return (
											<Grid item key={idx} xs={12}>
												<Autocomplete
													autoFocus={false}
													dataSource={field.options}
													getValueSelectedText={option => (option ? option.text : null)}
													handleSelected={value =>
														this.handleUpdate({
															id: field.id,
															value: value.value
														})
													}
													numCharToShowSuggestions={0}
													placeholder={field.name}
													showSelected={true}
													tabSelect={true}
													valueSelected={
														selectedOption.length > 0
															? {
																	text: selectedOption[0].text,
																	value: field.value
															  }
															: null
													}
												/>
											</Grid>
										);
									default:
										return <div />;
								}
							})}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							disabled={agencyName === ""}
							key={"add"}
							onClick={this.handleAdd}
							variant="contained"
						>
							Add Agency
						</Button>
						<Button color="primary" key={"close"} onClick={() => this.props.handleClose(null)}>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

AddAgencyModal.propTypes = {
	createAgency: PropTypes.func,
	handleClose: PropTypes.func
};

function mapDispatchToProps(dispatch) {
	return {
		createAgency: agencyDict => dispatch(createAgency(agencyDict))
	};
}

export default connect(null, mapDispatchToProps)(AddAgencyModal);
