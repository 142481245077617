import { Box, chakra } from "@chakra-ui/react";

// Style Objects

export const FilterTextFieldStyle = {
	padding: "4px",
	backgroundColor: "white",
	color: "black"
};

// Chakra Styles

export const ProModelLeaderboardTableBox = chakra(Box, {
	baseStyle: {
		width: "calc(100% - 30px)",
		margin: "0 15px"
	}
});

export const ProModelLeaderboardTableHeaderBox = chakra(Box, {
	baseStyle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: "2xl",
		fontWeight: "bold",
		backgroundColor: "gray.800",
		color: "white",
		height: "16"
	}
});

export const R540ManBox = chakra(Box, {
	baseStyle: {
		color: "green.500",
		fontFamily: "Inter"
	}
});

export const OrgNameBox = chakra(Box, {
	baseStyle: {
		color: "gray.500",
		fontSize: "0.75rem",
		textAlign: "left",
		paddingLeft: 0,
		marginLeft: 0
	}
});
