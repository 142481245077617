import { createMachine, assign, AnyEventObject, StateFrom } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { TReplacementLevelActive } from "_react/shared/data_models/phred/_types";
import { TSimpleProjectionsAll, IProjectedPitcherProportions } from "_react/shared/data_models/projections/_types";
import { fetchReplacementLevelActive } from "_react/shared/data_models/phred/_network";
import {
	fetchSimpleProjectionsAll,
	fetchProjectedPitcherProportions
} from "_react/shared/data_models/projections/_network";
import { fetchAllSurplusValues } from "_react/shared/data_models/surplus_value/_network";
import { TAllSurplusValues } from "_react/shared/data_models/surplus_value/_types";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";

const SURPLUS_VALUES_CANCEL_SOURCE = "surplusValues";
const PROJECTIONS_CANCEL_SOURCE = "projections";
const REPLACEMENT_LEVEL_CANCEL_SOURCE = "replacementLevel";
const PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE = "projectedPitcherProportions";

export type TPlayerPageProCancelSource = {
	[SURPLUS_VALUES_CANCEL_SOURCE]?: CancelTokenSource;
	[PROJECTIONS_CANCEL_SOURCE]?: CancelTokenSource;
	[REPLACEMENT_LEVEL_CANCEL_SOURCE]?: CancelTokenSource;
	[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPlayerPageProContext = {
	playerId?: number;
	lastPlayerId?: number;
	projectionsSeasonData?: [number, number, boolean];
	lastProjectionsSeasonData?: [number, number, boolean];
	currentSeasonData?: { currentSeason: number | undefined; isFetching: boolean | undefined };
	shouldFetchData?: boolean;
	surplusValues?: TAllSurplusValues | null;
	projections?: TSimpleProjectionsAll | null;
	replacementLevel?: TReplacementLevelActive | null;
	projectedPitcherProportions?: IProjectedPitcherProportions | null;
	cancelSources: TPlayerPageProCancelSource;
	toast?: CreateToastFnReturn;
};

export const FETCHING_SURPLUS_VALUES = { initialized: { surplusValues: "fetching" } };
export const FETCHING_PROJECTIONS = { initialized: { projections: "fetching" } };
export const FETCHING_REPLACEMENT_LEVEL = { initialized: { replacementLevel: "fetching" } };
export const FETCHING_PROJECTED_PITCHER_PROPORTIONS = { initialized: { projectedPitcherProportions: "fetching" } };

const FETCH_SURPLUS_VALUES_DONE = "done.invoke.fetchingSurplusValues:invocation[0]";
const FETCH_PROJECTIONS_DONE = "done.invoke.fetchingProjections:invocation[0]";
const FETCH_REPLACEMENT_LEVEL_DONE = "done.invoke.fetchingReplacementLevel:invocation[0]";
const FETCH_PROJECTED_PITCHER_PROPORTIONS_DONE = "done.invoke.fetchingProjectedPitcherProportions:invocation[0]";
type TFetchSurplusValuesEvent = { type: typeof FETCH_SURPLUS_VALUES_DONE; data?: Array<TAllSurplusValues> };
type TFetchProjectionsEvent = { type: typeof FETCH_PROJECTIONS_DONE; data?: Array<TSimpleProjectionsAll> };
type TFetchReplacementLevelEvent = { type: typeof FETCH_REPLACEMENT_LEVEL_DONE; data?: Array<TReplacementLevelActive> };
type TFetchProjectedPitcherProportionsEvent = {
	type: typeof FETCH_PROJECTED_PITCHER_PROPORTIONS_DONE;
	data: Array<IProjectedPitcherProportions> | undefined;
};

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PROJECTIONS_SEASON_DATA = "SET_PROJECTIONS_SEASON_DATA";
export const SET_CURRENT_SEASON_DATA = "SET_CURRENT_SEASON_DATA";
type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value?: number };
type TSetProjectionsSeasonDataEvent = { type: typeof SET_PROJECTIONS_SEASON_DATA; value?: [number, number, boolean] };
type TSetCurrentSeasonData = {
	type: typeof SET_CURRENT_SEASON_DATA;
	value?: { currentSeason: number | undefined; isFetching: boolean | undefined };
};

export type TPlayerPageProEvent =
	| TFetchSurplusValuesEvent
	| TFetchProjectionsEvent
	| TFetchReplacementLevelEvent
	| TFetchProjectedPitcherProportionsEvent
	| TSetPlayerIdEvent
	| TSetProjectionsSeasonDataEvent
	| TSetCurrentSeasonData;

const PlayerPageProMachine = (
	playerIdProp?: number,
	projectionsSeasonDataProp?: [number, number, boolean],
	currentSeasonDataProp?: { currentSeason: number | undefined; isFetching: boolean | undefined },
	shouldFetchData = true,
	toastProp?: CreateToastFnReturn
) =>
	createMachine<TPlayerPageProContext, TPlayerPageProEvent>(
		{
			id: "playerPagePro",
			predictableActionArguments: true,
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				projectionsSeasonData: projectionsSeasonDataProp,
				currentSeasonData: currentSeasonDataProp,
				lastProjectionsSeasonData: projectionsSeasonDataProp,
				shouldFetchData: shouldFetchData,
				surplusValues: undefined,
				projections: undefined,
				replacementLevel: undefined,
				projectedPitcherProportions: undefined,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					initial: "idle",
					states: {
						idle: {
							always: "#playerPagePro.initialized"
						}
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_PROJECTIONS_SEASON_DATA]: { actions: "setProjectionsSeasonData" },
						[SET_CURRENT_SEASON_DATA]: { actions: "setCurrentSeasonData" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContextPlayerId" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContextPlayerId" }
								}
							}
						},
						projectionsSeasonDataRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContextProjectionsSeasonData" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContextProjectionsSeasonData" }
								}
							}
						},
						surplusValues: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingSurplusValues",
												cond: "shouldFetchSurplusValues"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingSurplusValues",
									entry: ["refreshSurplusValuesCancelSource"],
									invoke: {
										src: "fetchSurplusValues",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSurplusValuesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSurplusValuesErrored"
										}
									}
								}
							}
						},
						projections: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: { target: "#fetchingProjections", cond: "shouldFetchProjections" },
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingProjections",
									entry: ["refreshProjectionsCancelSource"],
									invoke: {
										src: "fetchProjections",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchProjectionsSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchProjectionsErrored"
										}
									}
								}
							}
						},
						replacementLevel: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingReplacementLevel",
												cond: "shouldFetchReplacementLevel"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingReplacementLevel",
									entry: ["refreshReplacementLevelCancelSource"],
									invoke: {
										src: "fetchReplacementLevel",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchReplacementLevelSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchReplacementLevelErrored"
										}
									}
								}
							}
						},
						projectedPitcherProportions: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingProjectedPitcherProportions",
												cond: "shouldFetchProjectedPitcherProportions"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingProjectedPitcherProportions",
									entry: ["refreshProjectedPitcherProportionsCancelSource"],
									invoke: {
										src: "fetchProjectedPitcherProportions",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handlefetchProjectedPitcherProportionSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchProjectedPitcherProportionsErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContextPlayerId: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) =>
					context.playerId !== context.lastPlayerId,
				shouldClearContextProjectionsSeasonData: (
					context: TPlayerPageProContext,
					_event: TPlayerPageProEvent
				) => context.projectionsSeasonData !== context.lastProjectionsSeasonData,
				shouldFetchSurplusValues: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) =>
					context.surplusValues === undefined && shouldFetchData && context.playerId !== undefined,
				shouldFetchProjections: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) =>
					context.projections === undefined && shouldFetchData && context.playerId !== undefined,
				shouldFetchReplacementLevel: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) =>
					context.replacementLevel === undefined && shouldFetchData,
				shouldFetchProjectedPitcherProportions: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) =>
					context.projectedPitcherProportions === undefined &&
					shouldFetchData &&
					context.playerId !== undefined &&
					context.projectionsSeasonData !== undefined
			},
			actions: {
				// Don't clear replacement level or current season data because it's independent of player
				clearContextPlayerId: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					lastPlayerId: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) => context.playerId,
					surplusValues: (_context: TPlayerPageProContext, _event: TPlayerPageProEvent) => undefined,
					projections: (_context: TPlayerPageProContext, _event: TPlayerPageProEvent) => undefined,
					cancelSources: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) => {
							if ([SURPLUS_VALUES_CANCEL_SOURCE, PROJECTIONS_CANCEL_SOURCE].includes(cancelSource[0]))
								cancelSource[1]?.cancel();
						});
						return {};
					}
				}),
				clearContextProjectionsSeasonData: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					lastProjectionsSeasonData: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) =>
						context.projectionsSeasonData,
					projectedPitcherProportions: (_context: TPlayerPageProContext, _event: TPlayerPageProEvent) =>
						undefined,
					cancelSources: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) => {
							if (cancelSource[0] === PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE)
								cancelSource[1]?.cancel();
						});
						return {};
					}
				}),
				setPlayerId: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					playerId: (context: TPlayerPageProContext, event: TPlayerPageProEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					}
				}),
				setProjectionsSeasonData: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					projectionsSeasonData: (context: TPlayerPageProContext, event: TPlayerPageProEvent) => {
						if (event.type !== SET_PROJECTIONS_SEASON_DATA) return context.projectionsSeasonData;
						return event.value;
					}
				}),
				setCurrentSeasonData: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					currentSeasonData: (context: TPlayerPageProContext, event: TPlayerPageProEvent) => {
						if (event.type !== SET_CURRENT_SEASON_DATA) return context.currentSeasonData;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshSurplusValuesCancelSource: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					cancelSources: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) => {
						if (context.cancelSources[SURPLUS_VALUES_CANCEL_SOURCE] != null)
							context.cancelSources[SURPLUS_VALUES_CANCEL_SOURCE].cancel();
						context.cancelSources[SURPLUS_VALUES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshProjectionsCancelSource: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					cancelSources: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) => {
						if (context.cancelSources[PROJECTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTIONS_CANCEL_SOURCE].cancel();
						context.cancelSources[PROJECTIONS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshReplacementLevelCancelSource: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					cancelSources: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) => {
						if (context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE] != null)
							context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE].cancel();
						context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshProjectedPitcherProportionsCancelSource: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					cancelSources: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) => {
						if (context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE].cancel();
						context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchSurplusValuesSuccess: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					surplusValues: (context: TPlayerPageProContext, event: TPlayerPageProEvent) => {
						if (event.type !== FETCH_SURPLUS_VALUES_DONE) return context.surplusValues;
						return event.data == null ? event.data : event.data?.length ? event.data[0] : null;
					}
				}),
				handleFetchProjectionsSuccess: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					projections: (context: TPlayerPageProContext, event: TPlayerPageProEvent) => {
						if (event.type !== FETCH_PROJECTIONS_DONE) return context.projections;
						return event.data == null ? event.data : event.data?.length ? event.data[0] : null;
					}
				}),
				handleFetchReplacementLevelSuccess: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					replacementLevel: (context: TPlayerPageProContext, event: TPlayerPageProEvent) => {
						if (event.type !== FETCH_REPLACEMENT_LEVEL_DONE) return context.replacementLevel;
						return event.data == null ? event.data : event.data?.length ? event.data[0] : null;
					}
				}),
				handlefetchProjectedPitcherProportionSuccess: assign<TPlayerPageProContext, TPlayerPageProEvent>({
					projectedPitcherProportions: (context: TPlayerPageProContext, event: TPlayerPageProEvent) => {
						if (event.type !== FETCH_PROJECTED_PITCHER_PROPORTIONS_DONE)
							return context.projectedPitcherProportions;
						return event.data == null ? event.data : event.data?.length ? event.data[0] : null;
					}
				}),
				// Fetch Errored Actions
				handleFetchSurplusValuesErrored: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Player Page Pro",
							description: "Error fetching surplus value data."
						});
				},
				handleFetchProjectionsErrored: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Player Page Pro",
							description: "Error fetching projection data."
						});
				},
				handleFetchReplacementLevelErrored: (context: TPlayerPageProContext, _event: TPlayerPageProEvent) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Player Page Pro",
							description: "Error fetching replacement level data."
						});
				},
				handleFetchProjectedPitcherProportionsErrored: (
					context: TPlayerPageProContext,
					_event: TPlayerPageProEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Player Page Pro",
							description: "Error fetching projected pitcher proportions data."
						});
				}
			},
			services: {
				fetchSurplusValues: (context: TPlayerPageProContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchAllSurplusValues(
							{
								playerId: context.playerId,
								isUseCache: true
							},
							context.cancelSources[SURPLUS_VALUES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchProjections: (context: TPlayerPageProContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchSimpleProjectionsAll(
							{
								playerId: context.playerId,
								isUseCache: true
							},
							context.cancelSources[PROJECTIONS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchReplacementLevel: (context: TPlayerPageProContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchReplacementLevelActive(
							{
								sort: "date",
								isSortDescending: true,
								limit: 1
							},
							context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchProjectedPitcherProportions: (context: TPlayerPageProContext, _event: AnyEventObject) => {
					if (context.projectionsSeasonData === undefined) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchProjectedPitcherProportions(
							{
								playerId: context.playerId,
								// `context.projectionsSeasonData` should always exist given the if statement above
								// but a type error is occuring without checking again
								projectedSeason: context.projectionsSeasonData
									? context.projectionsSeasonData[0]
									: undefined,
								isUseCache: true
							},
							context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export type TPlayerPageProState = StateFrom<typeof PlayerPageProMachine>;

export default PlayerPageProMachine;
