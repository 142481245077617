import React, { useMemo } from "react";
import Select, { Props } from "react-select";
import { useLksCustom } from "_react/inputs/lks/LkContext";
import { DEFAULT_STYLES, getOptsFromValues } from "_react/inputs/_helpers";
import { TExtendedOption } from "_react/inputs/_types";
import { TLk, TLkValueConstraint } from "_react/inputs";

export type TLkOption<T extends TLkValueConstraint> = TExtendedOption<T>;

interface TLkSelectCustomWrapperProps<T extends TLkValueConstraint> extends Props<TLkOption<T>> {
	query: string;
	parameters?: { [index: string]: string | number };
	valueOnlyValue?: T | T[] | null;
	forDisplay?: boolean; // Indicates if this should show a select or just the value (so the data source is still managed here)
}

export function LkSelectCustomWrapper<T extends TLkValueConstraint>({
	query,
	parameters,
	valueOnlyValue,
	value: valueProp,
	forDisplay = false,
	...props
}: TLkSelectCustomWrapperProps<T>) {
	const [options, isFetching] = useLksCustom<T>(query, parameters);

	const value = useMemo(() => {
		if (valueOnlyValue === undefined || options == null) return valueProp;
		const selectedValues = getOptsFromValues<T>(valueOnlyValue, options);
		return selectedValues;
	}, [valueOnlyValue, valueProp, options]);

	const styles = useMemo(() => {
		if (props.styles == null) return DEFAULT_STYLES;
		return {
			...DEFAULT_STYLES,
			...props.styles
		};
	}, [props.styles]);

	const getOptionLabel = (option: TLk<T>) => option.label || "";

	if (forDisplay) return <span>{value ? getOptionLabel(value as TLk<T>) : valueOnlyValue ?? ""}</span>;

	return (
		<Select<TLkOption<T>, boolean>
			{...props}
			isLoading={isFetching}
			getOptionLabel={getOptionLabel}
			value={value}
			options={options}
			styles={styles}
		/>
	);
}

export default LkSelectCustomWrapper;
