import React from "react";

import { TOOLTIP_ERA_MINUS, TOOLTIP_XFIP_MINUS } from "_react/shared/_constants/tooltips";
import { round10 } from "_react/shared/_helpers/numbers";
import { getLevelDisplayFromLevels } from "_react/shared/_helpers/stats";
import {
	GAME_TYPE_D,
	GAME_TYPE_F,
	GAME_TYPE_L,
	GAME_TYPE_R,
	GAME_TYPE_S,
	GAME_TYPE_W
} from "_react/shared/data_models/stats/_constants";
import { formatFastballVelo, formatIp, formatPlusOrMinusStat } from "_react/shared/data_models/stats/_helpers";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import TeamColumn from "_react/shared/ui/data/tables/shared/TeamColumn";
import { getLevelsFromRow } from "_react/shared/ui/data/tables/PitcherPerformanceTable/_helpers";
import { TPitcherPerformanceRow } from "_react/shared/ui/data/tables/PitcherPerformanceTable/_types";

//
// Non-Column Constants
//

export const NUM_DISPLAY_SEASONS = 3;

export const AGE_BASE_DATE = "6/30";

// Game type filter options
export const GAME_TYPE_REGULAR_SEASON = "RS";
export const GAME_TYPE_POSTSEASON = "PS";
export const GAME_TYPE_SPRING_TRAINING = "ST";

export const GAME_TYPE_FILTER_TO_GAME_TYPE_MAP: Record<string, Array<string>> = {
	[GAME_TYPE_REGULAR_SEASON]: [GAME_TYPE_R],
	[GAME_TYPE_POSTSEASON]: [GAME_TYPE_F, GAME_TYPE_D, GAME_TYPE_L, GAME_TYPE_W],
	[GAME_TYPE_SPRING_TRAINING]: [GAME_TYPE_S]
};

//
// Column Constants
//

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherPerformanceRow) => row.pitcherPerformanceData.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherPerformanceRow) => {
		if ("teamBam" in row.pitcherPerformanceData)
			return <TeamColumn teamBam={row.pitcherPerformanceData.teamBam} childRowCount={row.childData?.length} />;
		return <TeamColumn childRowCount={row.childData?.length} />;
	}
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherPerformanceRow) =>
		getLevelDisplayFromLevels(getLevelsFromRow(row), NULL_FILLER_TEXT),
	getSortValueFunction: (row: TPitcherPerformanceRow) => {
		// Child Rows
		if ("teamBam" in row.pitcherPerformanceData)
			return row.pitcherPerformanceData.teamBam?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
		// Parent Rows with nested data - highest level if multiple levels
		if (row.childData && row.childData.length > 1)
			return Math.min(
				...row.childData.map((childRow: TPitcherPerformanceRow) => {
					if ("teamBam" in childRow.pitcherPerformanceData)
						return childRow.pitcherPerformanceData.teamBam?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
					return Number.MAX_SAFE_INTEGER;
				})
			);
		return NULL_FILLER_TEXT;
	}
};

const AGE_COLUMN = {
	id: "age",
	value: "age",
	label: "Age",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherPerformanceRow) => row.pitcherPerformanceData.age ?? NULL_FILLER_TEXT,
	tooltip: `Age on ${AGE_BASE_DATE}`
};

const REL_AGE_COLUMN = {
	id: "relAge",
	value: "relAge",
	label: "Rel. Age",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherPerformanceRow) =>
		round10(row.pitcherPerformanceData.relAge, -1) ?? NULL_FILLER_TEXT,
	tooltip: `Difference between the player's age on ${AGE_BASE_DATE} and the average age of all pitchers on ${AGE_BASE_DATE} given the season and level`
};

const G_COLUMN = {
	id: "g",
	value: "g",
	label: "G",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherPerformanceRow) => row.pitcherPerformanceData.g ?? NULL_FILLER_TEXT
};

const GS_COLUMN = {
	id: "gs",
	value: "gs",
	label: "GS",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherPerformanceRow) => row.pitcherPerformanceData.gs ?? NULL_FILLER_TEXT
};

const IP_COLUMN = {
	id: "ip",
	value: "ip",
	label: "IP",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherPerformanceRow) => formatIp(row.pitcherPerformanceData.ip) ?? NULL_FILLER_TEXT
};

const ERA_MINUS_COLUMN = {
	id: "eraMinus",
	value: "eraMinus",
	label: "ERA-",
	tooltip: TOOLTIP_ERA_MINUS,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherPerformanceRow) =>
		formatPlusOrMinusStat(row.pitcherPerformanceData.eraMinus) ?? NULL_FILLER_TEXT
};

const XFIP_MINUS_COLUMN = {
	id: "xFipMinus",
	value: "xFipMinus",
	label: "xFIP-",
	tooltip: TOOLTIP_XFIP_MINUS,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherPerformanceRow) =>
		formatPlusOrMinusStat(row.pitcherPerformanceData.xfipMinus) ?? NULL_FILLER_TEXT
};

const FBV_COLUMN = {
	id: "fbv",
	value: "fbv",
	label: "FB Velo",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherPerformanceRow) =>
		formatFastballVelo(row.pitcherPerformanceData.fbv) ?? NULL_FILLER_TEXT
};

export const PITCHER_PERFORMANCE_COLUMNS: Array<TColumn<TPitcherPerformanceRow, keyof TPitcherPerformanceRow>> = [
	SEASON_COLUMN,
	TEAM_COLUMN,
	LEVEL_COLUMN,
	AGE_COLUMN,
	REL_AGE_COLUMN,
	G_COLUMN,
	GS_COLUMN,
	IP_COLUMN,
	ERA_MINUS_COLUMN,
	XFIP_MINUS_COLUMN,
	FBV_COLUMN
];
