import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

function SportsHandball({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<g>
				<rect fill="none" height="24" width="24" />
			</g>
			<g>
				<g>
					<path
						fill={fill}
						d="M14.27,6C13.72,6.95,14.05,8.18,15,8.73c0.95,0.55,2.18,0.22,2.73-0.73c0.55-0.95,0.22-2.18-0.73-2.73 C16.05,4.72,14.82,5.05,14.27,6z"
					/>
					<path
						fill={fill}
						d="M15.84,10.41c0,0-1.63-0.94-2.6-1.5c-2.38-1.38-3.2-4.44-1.82-6.82l-1.73-1C8.1,3.83,8.6,7.21,10.66,9.4l-5.15,8.92 l1.73,1l1.5-2.6l1.73,1l-3,5.2l1.73,1l6.29-10.89c1.14,1.55,1.33,3.69,0.31,5.46l1.73,1C19.13,16.74,18.81,12.91,15.84,10.41z"
					/>
					<path
						fill={fill}
						d="M12.75,3.8c0.72,0.41,1.63,0.17,2.05-0.55c0.41-0.72,0.17-1.63-0.55-2.05c-0.72-0.41-1.63-0.17-2.05,0.55 C11.79,2.47,12.03,3.39,12.75,3.8z"
					/>
				</g>
			</g>
		</IconSVG>
	);
}
export default SportsHandball;
