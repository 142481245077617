import { formatPct, formatWOBACON, formatIP } from "utils/functions";

export const formatFixed = (value, toFixed) => {
	if (value !== null && !isNaN(value)) {
		return value.toFixed(toFixed);
	}
	return "-";
};

export const formatBase = value => {
	if (value === 1) return "1B";
	else if (value === 2) return "2B";
	else if (value === 3) return "3B";
	else if (value === 4) return "H";
	return "-";
};

export const getArrow = (header, sortBy, asc) => {
	if (header === sortBy) {
		if (asc) {
			return String.fromCharCode(9650);
		}
		return String.fromCharCode(9660);
	}
	return "";
};

export const PITCHER_POSITIONS = ["P", "RP", "SP", "LHR", "LHS", "RHR", "RHS", "LHP", "RHP"];

export const isPitcher = player => PITCHER_POSITIONS.indexOf(player.position) > -1;

export const LEVELS = ["MLB", "AAA", "AA", "A+", "A", "A-", "Rk", "FRk", "MEX"];

export const STAT_MAP = {
	"1b": {
		key: "1b",
		name: "1B",
		numerator: "1b",
		split: { event: ["single"] }
	},
	"2b": {
		key: "2b",
		name: "2B",
		numerator: "2b",
		split: { event: ["double"] }
	},
	"2k_quality_takes": {
		key: "2k_quality_takes",
		name: "2K Quality Takes",
		numerator: "2k_quality_takes"
	},
	"3b": {
		key: "3b",
		name: "3B",
		numerator: "3b",
		split: { event: ["triple"] }
	},
	doubles: {
		key: "doubles",
		name: "2B",
		numerator: "2b",
		split: { event: ["double"] }
	},
	triples: {
		key: "triples",
		name: "3B",
		numerator: "3b",
		split: { event: ["triple"] }
	},
	ab: {
		key: "ab",
		name: "AB",
		numerator: "ab",
		split: { is_ab: [1] }
	},
	avg: {
		key: "avg",
		name: "AVG",
		numerator: "avg",
		format: value => formatWOBACON(value)
	},
	avg_ev: {
		key: "avg_ev",
		name: "Avg EV",
		numerator: "avg_ev",
		format: value => parseFloat(value.toFixed(1))
	},
	avg_horz_movement: {
		key: "avg_horz_movement",
		name: "Horz Move",
		numerator: "avg_horz_movement",
		format: value => value.toFixed(1)
	},
	avg_la: {
		key: "avg_la",
		name: "Avg LA",
		numerator: "avg_la",
		format: value => parseFloat(value.toFixed(1))
	},
	avg_release_angle: {
		key: "avg_release_angle",
		name: "Angle",
		numerator: "avg_release_angle",
		format: value => value.toFixed(1)
	},
	avg_vert_movement: {
		key: "avg_vert_movement",
		name: "Vert Move",
		numerator: "avg_vert_movement",
		format: value => value.toFixed(1)
	},
	babip: {
		key: "babip",
		name: "BABIP",
		numerator: "babip",
		format: value => formatWOBACON(value)
	},
	balls_in_play: {
		key: "balls_in_play",
		name: "BIP",
		numerator: "balls_in_play",
		split: { is_in_play: [1] }
	},
	balls_in_play_pct: {
		key: "balls_in_play_pct",
		name: "BIP %",
		numerator: "balls_in_play",
		denominator: "balls_in_play",
		format: value => formatPct(value, 1),
		split: { is_in_play: [1] }
	},
	barrels: {
		key: "barrels",
		name: "BAR",
		numerator: "barrel",
		split: { is_barrel: [1] }
	},
	bb: {
		key: "bb",
		name: "BB",
		numerator: "bb",
		split: { is_bb: [1] }
	},
	bb_pct: {
		key: "bb_pct",
		name: "BB%",
		numerator: "bb_pct",
		format: value => formatPct(value, 1),
		split: { is_bb: [1] }
	},
	belt_pct: {
		key: "belt_pct",
		name: "Belt",
		numerator: "belt_pct",
		format: value => formatPct(value, 1),
		split: { pitch_location_vert: ["belt"] }
	},
	chases: {
		key: "chases",
		name: "Chases",
		numerator: "chases",
		format: value => value,
		split: { is_chase: [1] }
	},
	down_pct: {
		key: "down_pct",
		name: "Down",
		numerator: "down_pct",
		format: value => formatPct(value, 1),
		split: { pitch_location_vert: ["down"] }
	},
	execution_opps: {
		key: "execution_opps",
		name: "Exec Opps",
		numerator: "execution_opps",
		format: value => value
	},
	execution_sucs: {
		key: "execution_sucs",
		name: "Exec Sucs",
		numerator: "execution_sucs",
		format: value => value
	},
	exp_runs: {
		key: "exp_runs",
		name: "Expected Runs"
	},
	extension: {
		key: "extension",
		name: "Ext.",
		numerator: "extension",
		format: value => value.toFixed(1)
	},
	fb_pct: {
		key: "fb_pct",
		name: "FB%",
		numerator: "fb_pct",
		format: value => formatPct(value, 1),
		split: { hit_type: ["fly_ball"] }
	},
	fps_pct: {
		key: "fps_pct",
		name: "FPS%",
		numerator: "fps_pct",
		format: value => formatPct(value, 0)
	},
	g: {
		key: "g",
		name: "G",
		numerator: "g"
	},
	gb_pct: {
		key: "gb_pct",
		name: "GB%",
		numerator: "gb_pct",
		format: value => formatPct(value, 1),
		split: { hit_type: ["ground_ball"] }
	},
	h: {
		key: "h",
		name: "H",
		numerator: "h",
		split: { is_hit: [1] }
	},
	hard_hits: {
		key: "hard_hits",
		name: "HHs",
		numerator: "hard_hits",
		split: { exit_velo: [95, 130] }
	},
	hh_pct: {
		key: "hh_pct",
		name: "HH%",
		numerator: "hh_pct",
		format: value => formatPct(value, 1),
		split: { exit_velo: [95, 130] }
	},
	hr: {
		key: "hr",
		name: "HR",
		numerator: "hr",
		split: { event: ["home_run"] }
	},
	in_pct: {
		key: "in_pct",
		name: "In",
		numerator: "in_pct",
		format: value => formatPct(value, 1),
		split: { pitch_location_horz: ["in"] }
	},
	ip: {
		key: "ip",
		name: "IP",
		format: value => formatIP(value),
		numerator: "ip"
	},
	iso: {
		key: "iso",
		name: "ISO",
		numerator: "iso",
		format: value => formatWOBACON(value)
	},
	k: {
		key: "k",
		name: "K",
		numerator: "k",
		split: { is_strikeout: [1] }
	},
	k_pct: {
		key: "k_pct",
		name: "K%",
		numerator: "k_pct",
		format: value => formatPct(value, 1),
		split: { is_strikeout: [1] }
	},
	ld_pct: {
		key: "ld_pct",
		name: "LD%",
		numerator: "ld_pct",
		format: value => formatPct(value, 1),
		split: { hit_type: ["line_drive"] }
	},
	less_than_2k_soft_hits: {
		key: "less_than_2k_soft_hits",
		name: "<2K Soft Contact",
		numerator: "less_than_2k_soft_hits",
		format: value => value
	},
	lob_pct: {
		key: "lob_pct",
		name: "LOB%",
		numerator: "lob_pct",
		format: value => formatPct(value, 1)
	},
	long_abs: {
		key: "long_abs",
		name: "Long ABs",
		numerator: "long_abs",
		format: value => value
	},
	meatballs: {
		key: "meatballs",
		name: "MBs",
		numerator: "meatballs",
		format: value => value,
		split: { meatball: [1] }
	},
	meatball_swings: {
		key: "meatball_swings",
		name: "MB Swings",
		numerator: "meatball_swings",
		format: value => value,
		split: { meatball: [1], is_swing: [1] }
	},
	meatball_takes: {
		key: "meatball_takes",
		name: "MB Takes",
		numerator: "meatball_takes",
		format: value => value,
		split: { meatball: [1], is_swing: [0] }
	},
	meatball_pct: {
		key: "meatball_pct",
		name: "MB %",
		numerator: "meatball_pct",
		format: value => formatPct(value, 1),
		split: { meatball: [1] }
	},
	mid_pct: {
		key: "mid_pct",
		name: "Mid%",
		numerator: "mid_pct",
		format: value => formatPct(value, 1),
		split: { hit_direction: ["mid"] }
	},
	ninety_percentile_release_velo: {
		key: "ninety_percentile_release_velo",
		name: "Hi Vel",
		numerator: "ninety_percentile_release_velo",
		format: value => value.toFixed(1)
	},
	obp: {
		key: "obp",
		name: "OBP",
		numerator: "obp",
		format: value => formatWOBACON(value)
	},
	oppo_pct: {
		key: "oppo_pct",
		name: "Oppo%",
		numerator: "oppo_pct",
		format: value => formatPct(value, 1),
		split: { hit_direction: ["oppo"] }
	},
	ops: {
		key: "ops",
		name: "OPS",
		numerator: "ops",
		format: value => formatWOBACON(value)
	},
	opt_la_pct: {
		key: "opt_la_pct",
		name: "Opt LA%",
		numerator: "opt_la_pct",
		format: value => formatPct(value, 1),
		split: { hit_characteristic: ["opt_la"] }
	},
	out_of_zone_swing_pct: {
		key: "out_of_zone_swing_pct",
		name: "O-Swing",
		numerator: "out_of_zone_swing_pct",
		format: value => formatPct(value, 1)
	},
	out_pct: {
		key: "out_pct",
		name: "Out",
		numerator: "out_pct",
		format: value => formatPct(value, 1),
		split: { pitch_location_horz: ["away"] }
	},
	p_chase_pct: {
		key: "p_chase_pct",
		name: "Chase-P",
		numerator: "p_chase_pct",
		format: value => formatPct(value, 1),
		split: { is_chase: [1] }
	},
	p_mid_pct: {
		key: "p_mid_pct",
		name: "Mid",
		numerator: "p_mid_pct",
		format: value => formatPct(value, 1),
		split: { pitch_location_horz: ["mid"] }
	},
	p_whiff_pct: {
		key: "p_whiff_pct",
		name: "Whiff-P",
		numerator: "p_whiff_pct",
		format: value => formatPct(value, 1),
		split: { is_miss: [1] }
	},
	pa: {
		key: "pa",
		name: "PA",
		numerator: "pa"
	},
	pitches: {
		key: "pitches",
		name: "Count",
		numerator: "pitches",
		format: value => value
	},
	pu_pct: {
		key: "pu_pct",
		name: "PU%",
		numerator: "pu_pct",
		format: value => formatPct(value, 1),
		split: { hit_type: ["popup"] }
	},
	pull_pct: {
		key: "pull_pct",
		name: "Pull%",
		numerator: "pull_pct",
		format: value => formatPct(value, 1),
		split: { hit_direction: ["pull"] }
	},
	s_chase_pct: {
		key: "s_chase_pct",
		name: "Chase-S",
		numerator: "s_chase_pct",
		format: value => formatPct(value, 1),
		split: { is_chase: [1] }
	},
	s_foul_pct: {
		key: "s_foul_pct",
		name: "Foul-S",
		numerator: "s_foul_pct",
		format: value => formatPct(value, 1),
		split: { is_foul: [1] }
	},
	s_in_play_pct: {
		key: "s_in_play_pct",
		name: "In Play-S",
		numerator: "s_in_play_pct",
		format: value => formatPct(value, 1),
		split: { is_in_play: [1] }
	},
	s_out_of_zone_whiff_pct: {
		key: "s_out_of_zone_whiff_pct",
		name: "O-Whiff-S",
		numerator: "s_out_of_zone_whiff_pct",
		format: value => formatPct(value, 1)
	},
	s_whiff_pct: {
		key: "s_whiff_pct",
		name: "Whiff-S",
		numerator: "s_whiff_pct",
		format: value => formatPct(value, 1),
		split: { is_miss: [1] }
	},
	s_zone_whiff_pct: {
		key: "s_zone_whiff_pct",
		name: "Z-Whiff-S",
		numerator: "s_zone_whiff_pct",
		format: value => formatPct(value, 1)
	},
	seen_pct: {
		key: "seen_pct",
		name: "Seen",
		numerator: "pitches",
		denominator: "pitches",
		format: value => formatPct(value, 1)
	},
	slg: {
		key: "slg",
		name: "SLG",
		numerator: "slg",
		format: value => formatWOBACON(value)
	},
	spin_rate: {
		key: "spin_rate",
		name: "Spin",
		numerator: "spin_rate",
		format: value => value.toFixed(0)
	},
	swing_pct: {
		key: "swing_pct",
		name: "Swing",
		numerator: "swing_pct",
		format: value => formatPct(value, 1),
		split: { is_swing: [1] }
	},
	swings: {
		key: "swings",
		name: "Swings",
		numerator: "swings",
		format: value => value,
		split: { is_swing: [1] }
	},
	tenth_percentile_release_velo: {
		key: "tenth_percentile_release_velo",
		name: "Lo Vel",
		numerator: "tenth_percentile_release_velo",
		format: value => value.toFixed(1)
	},
	two_strike_pct: {
		key: "two_strike_pct",
		name: "2k%",
		numerator: "two_strike_pct",
		format: value => formatPct(value, 0)
	},
	three_pitch_pa_pct: {
		key: "three_pitch_pa_pct",
		name: "3 Pitch PA%",
		numerator: "three_pitch_pa_pct",
		format: value => formatPct(value, 0)
	},
	up_pct: {
		key: "up_pct",
		name: "Up",
		numerator: "up_pct",
		format: value => formatPct(value, 1),
		split: { pitch_location_vert: ["up"] }
	},
	woba: {
		key: "woba",
		name: "wOBA",
		numerator: "woba",
		format: value => formatWOBACON(value)
	},
	xwoba: {
		key: "xwoba",
		name: "xWOBA",
		numerator: "xwoba",
		format: value => formatWOBACON(value)
	},
	wobacon: {
		key: "wobacon",
		name: "wOBACon",
		numerator: "wobacon",
		format: value => formatWOBACON(value)
	},
	"wrc+": {
		key: "wrc+",
		name: "wRC+",
		numerator: "wrc+"
	},
	prc_plus: {
		key: "prc_plus",
		name: "pRC+",
		numerator: "prc_plus",
		format: value => formatFixed(value, 0)
	},
	poba: {
		key: "poba",
		name: "wOBA",
		numerator: "poba",
		format: value => formatWOBACON(value)
	},
	xbh: {
		key: "xbh",
		name: "XBH",
		value: data => {
			let xbh = 0;
			if (data["2b"] === "-" && data["3b"] === "-" && data["hr"] === "-") {
				return "-";
			}
			if (data["2b"] && data["2b"] !== "-") {
				xbh += data["2b"];
			}
			if (data["3b"] && data["3b"] !== "-") {
				xbh += data["3b"];
			}
			if (data["hr"] && data["hr"] !== "-") {
				xbh += data["hr"];
			}
			return xbh;
		},
		split: { event: ["double", "triple", "home_run"] }
	},
	zone_pct: {
		key: "zone_pct",
		name: "Zone",
		numerator: "zone_pct",
		format: value => formatPct(value, 1),
		split: { pitch_location_in_zone: ["in"] }
	},
	zone_swing_pct: {
		key: "zone_swing_pct",
		name: "Z-Swing",
		numerator: "zone_swing_pct",
		format: value => formatPct(value, 1)
	},
	game: {
		key: "g",
		name: "g",
		numerator: "g"
	},
	gs: {
		key: "gs",
		name: "GS",
		numerator: "gs"
	},
	k_per_9: {
		key: "k_per_9",
		name: "K/9",
		numerator: "k_per_9",
		format: value => formatFixed(value, 2)
	},
	bb_per_9: {
		key: "bb_per_9",
		name: "BB/9",
		numerator: "bb_per_9",
		format: value => formatFixed(value, 2)
	},
	hr_per_9: {
		key: "hr_per_9",
		name: "HR/9",
		numerator: "hr_per_9",
		format: value => formatFixed(value, 2)
	},
	runs: {
		key: "runs",
		name: "R",
		numerator: "runs"
	},
	r: {
		key: "r",
		name: "R",
		numerator: "r"
	},
	rbi: {
		key: "rbi",
		name: "RBI",
		numerator: "rbi"
	},
	sb: {
		key: "sb",
		name: "SB",
		numerator: "sb"
	},
	cs: {
		key: "cs",
		name: "CS",
		numerator: "cs"
	},
	w: {
		key: "w",
		name: "W",
		numerator: "w"
	},
	l: {
		key: "l",
		name: "L",
		numerator: "l"
	},
	sv: {
		key: "sv",
		name: "SV",
		numerator: "sv"
	},
	era: {
		key: "era",
		name: "ERA",
		numerator: "era",
		format: value => formatFixed(value, 2)
	},
	era_minus: {
		key: "era_minus",
		name: "ERA-",
		numerator: "era_minus",
		format: value => formatFixed(value, 0)
	},
	fip: {
		key: "fip",
		name: "FIP",
		numerator: "fip",
		format: value => formatFixed(value, 2)
	},
	xfip: {
		key: "xfip",
		name: "xFIP",
		numerator: "xfip",
		format: value => formatFixed(value, 2)
	},
	xfip_minus: {
		key: "xfip_minus",
		name: "xFIP-",
		numerator: "xfip_minus",
		format: value => formatFixed(value, 0)
	},
	fbv: {
		key: "fbv",
		name: "FBv",
		numerator: "fbv",
		format: value => formatFixed(value, 1)
	},
	vl_woba: {
		key: "vl_woba",
		name: "LwOBA",
		numerator: "vl_woba",
		format: value => formatWOBACON(value)
	},
	vr_woba: {
		key: "vr_woba",
		name: "RwOBA",
		numerator: "vr_woba",
		format: value => formatWOBACON(value)
	},
	vl_poba: {
		key: "vl_poba",
		name: "LwOBA",
		numerator: "vl_poba",
		format: value => formatWOBACON(value)
	},
	vr_poba: {
		key: "vr_poba",
		name: "RwOBA",
		numerator: "vr_poba",
		format: value => formatWOBACON(value)
	},
	// Fielding Stats
	assists: {
		key: "assists",
		name: "A"
	},
	chances: {
		key: "chances",
		name: "TC"
	},
	double_plays: {
		key: "double_plays",
		name: "DP"
	},
	errors: {
		key: "errors",
		name: "E"
	},
	fielding_pct: {
		key: "fielding_pct",
		name: "FIELDING %",
		format: value => formatPct(value, 1)
	},
	put_outs: {
		key: "put_outs",
		name: "PO"
	},
	innings: {
		key: "innings",
		name: "INN",
		format: value => formatIP(value)
	},
	outs_played: {
		key: "outs_played",
		name: "OP"
	},
	passed_ball: {
		key: "passed_ball",
		name: "PB"
	},
	range_factor_per_game: {
		key: "range_factor_per_game",
		name: "RF",
		format: value => formatFixed(value, 2)
	},

	start_base: {
		key: "start_base",
		name: "START",
		format: value => formatBase(value)
	},
	target_base: {
		key: "target_base",
		name: "TARGET",
		format: value => formatBase(value)
	},
	distance_lead: {
		key: "distance_lead",
		name: "LEAD",
		format: value => formatFixed(value, 1)
	},
	distance_secondary_lead: {
		key: "distance_secondary_lead",
		name: "S LEAD",
		format: value => formatFixed(value, 1)
	},
	distance_closest_defender: {
		key: "distance_closest_defender",
		name: "CDD",
		format: value => formatFixed(value, 1)
	},
	phillies_oaa: {
		key: "phillies_oaa",
		name: "PHILLIES OAA",
		format: value => formatFixed(value, 1)
	},
	speed_max: {
		key: "speed_max",
		name: "SM",
		format: value => formatFixed(value, 1)
	},
	speed_sprint: {
		key: "speed_sprint",
		name: "SS",
		format: value => formatFixed(value, 1)
	},
	time_hp_fb_contact: {
		key: "time_hp_fb_contact",
		name: "H-1B C",
		format: value => formatFixed(value, 1)
	},
	time_hp_sb_contact: {
		key: "time_hp_sb_contact",
		name: "H-2B C",
		format: value => formatFixed(value, 1)
	},
	time_hp_tb_contact: {
		key: "time_hp_tb_contact",
		name: "H-3B C",
		format: value => formatFixed(value, 1)
	},
	time_hp_hp_contact: {
		key: "time_hp_hp_contact",
		name: "H-H C",
		format: value => formatFixed(value, 1)
	},
	time_fb_sb_contact: {
		key: "time_fb_sb_contact",
		name: "1B-2B C",
		format: value => formatFixed(value, 1)
	},
	time_fb_tb_contact: {
		key: "time_fb_tb_contact",
		name: "1B-3B C",
		format: value => formatFixed(value, 1)
	},
	time_fb_hp_contact: {
		key: "time_fb_hp_contact",
		name: "1B-H C",
		format: value => formatFixed(value, 1)
	},
	time_sb_tb_contact: {
		key: "time_sb_tb_contact",
		name: "2B-3B C",
		format: value => formatFixed(value, 1)
	},
	time_sb_hp_contact: {
		key: "time_sb_hp_contact",
		name: "2B-H C",
		format: value => formatFixed(value, 1)
	},
	time_tb_hp_contact: {
		key: "time_tb_hp_contact",
		name: "3B-H C",
		format: value => formatFixed(value, 1)
	},
	time_hp_fb_first_move: {
		key: "time_hp_fb_first_move",
		name: "H-1B FM",
		format: value => formatFixed(value, 1)
	},
	time_hp_sb_first_move: {
		key: "time_hp_sb_first_move",
		name: "H-2B FM",
		format: value => formatFixed(value, 1)
	},
	time_hp_tb_first_move: {
		key: "time_hp_tb_first_move",
		name: "H-3B FM",
		format: value => formatFixed(value, 1)
	},
	time_hp_hp_first_move: {
		key: "time_hp_hp_first_move",
		name: "H-H FM",
		format: value => formatFixed(value, 1)
	},
	time_fb_sb_first_move: {
		key: "time_fb_sb_first_move",
		name: "1B-2B FM",
		format: value => formatFixed(value, 1)
	},
	time_fb_tb_first_move: {
		key: "time_fb_tb_first_move",
		name: "1B-3B FM",
		format: value => formatFixed(value, 1)
	},
	time_fb_hp_first_move: {
		key: "time_fb_hp_first_move",
		name: "1B-H FM",
		format: value => formatFixed(value, 1)
	},
	time_sb_tb_first_move: {
		key: "time_sb_tb_first_move",
		name: "2B-3B FM",
		format: value => formatFixed(value, 1)
	},
	time_sb_hp_first_move: {
		key: "time_sb_hp_first_move",
		name: "2B-H FM",
		format: value => formatFixed(value, 1)
	},
	time_tb_hp_first_move: {
		key: "time_tb_hp_first_move",
		name: "3B-H FM",
		format: value => formatFixed(value, 1)
	},
	time_max_speed_contact: {
		key: "time_max_speed_contact",
		name: "TMSC",
		format: value => formatFixed(value, 1)
	},
	time_max_speed_release: {
		key: "time_max_speed_release",
		name: "TMSR",
		format: value => formatFixed(value, 1)
	},
	time_first_step_contact: {
		key: "time_first_step_contact",
		name: "TFSC",
		format: value => formatFixed(value, 1)
	},
	time_first_step_release: {
		key: "time_first_step_release",
		name: "TFSR",
		format: value => formatFixed(value, 1)
	},
	angle_0: {
		key: "angle_0",
		name: "A0",
		format: value => formatFixed(value, 1)
	},
	angle_1: {
		key: "angle_1",
		name: "A1",
		format: value => formatFixed(value, 1)
	},
	angle_landing: {
		key: "angle_landing",
		name: "LA",
		format: value => formatFixed(value, 1)
	},
	angle_sprint_speed: {
		key: "angle_sprint_speed",
		name: "ASS",
		format: value => formatFixed(value, 1)
	},
	angle_throw: {
		key: "angle_throw",
		name: "TA",
		format: value => formatFixed(value, 1)
	},
	distance_covered: {
		key: "distance_covered",
		name: "DC",
		format: value => formatFixed(value, 1)
	},
	distance_exchange: {
		key: "distance_exchange",
		name: "ED",
		format: value => formatFixed(value, 1)
	},
	distance_landing: {
		key: "distance_landing",
		name: "LD",
		format: value => formatFixed(value, 1)
	},
	distance_landing_contact: {
		key: "distance_landing_contact",
		name: "LD C",
		format: value => formatFixed(value, 1)
	},
	distance_burst: {
		key: "distance_burst",
		name: "BD",
		format: value => formatFixed(value, 1)
	},
	distance_reaction: {
		key: "distance_reaction",
		name: "RD",
		format: value => formatFixed(value, 1)
	},
	distance_burst_extra: {
		key: "distance_burst_extra",
		name: "BDE",
		format: value => formatFixed(value, 1)
	},
	distance_jump: {
		key: "distance_jump",
		name: "JD",
		format: value => formatFixed(value, 1)
	},
	efficiency_route: {
		key: "efficiency_route",
		name: "RE",
		format: value => formatFixed(value, 3)
	},
	shift_classification: {
		key: "shift_classification",
		name: "SC"
	},
	time_exchange: {
		key: "time_exchange",
		name: "ET",
		format: value => formatFixed(value, 1)
	},
	time_first_step: {
		key: "time_first_step",
		name: "TFS",
		format: value => formatFixed(value, 1)
	},
	time_max_speed: {
		key: "time_max_speed",
		name: "TMS",
		format: value => formatFixed(value, 1)
	},
	time_startup: {
		key: "time_startup",
		name: "TS",
		format: value => formatFixed(value, 1)
	},
	throw_distance_air: {
		key: "throw_distance_air",
		name: "TD A",
		format: value => formatFixed(value, 1)
	},
	throw_distance_bounce: {
		key: "throw_distance_bounce",
		name: "TD B",
		format: value => formatFixed(value, 1)
	},
	throw_velo_max: {
		key: "throw_velo_max",
		name: "MV",
		format: value => formatFixed(value, 1)
	},
	throw_distance_fb: {
		key: "throw_distance_fb",
		name: "TD 1B",
		format: value => formatFixed(value, 1)
	},
	throw_distance_sb: {
		key: "throw_distance_sb",
		name: "TD 2B",
		format: value => formatFixed(value, 1)
	},
	throw_distance_tb: {
		key: "throw_distance_tb",
		name: "TD 3B",
		format: value => formatFixed(value, 1)
	},
	throw_distance_hp: {
		key: "throw_distance_hp",
		name: "TD H",
		format: value => formatFixed(value, 1)
	},
	uzr: {
		key: "uzr",
		name: "FG UZR",
		format: value => formatFixed(value, 1)
	},
	uzr_150: {
		key: "uzr_150",
		name: "FG UZR/150",
		format: value => formatFixed(value, 1)
	},
	x_0: {
		key: "x_0",
		name: "X0",
		format: value => formatFixed(value, 1)
	},
	x_1: {
		key: "x_1",
		name: "X1",
		format: value => formatFixed(value, 1)
	},
	y_0: {
		key: "y_0",
		name: "Y0",
		format: value => formatFixed(value, 1)
	},
	y_1: {
		key: "y_1",
		name: "Y0",
		format: value => formatFixed(value, 1)
	},
	time_base_release: {
		key: "time_base_release",
		name: "TBR",
		format: value => formatFixed(value, 1)
	}
};
