import React from "react";
import dayjs from "dayjs";

export const PORTER_ID = 19590;
export const PRO_PITCH = "PRO_PITCH";
export const PRO_HIT = "PRO_HIT";
export const INTL_HIT = "INTL_HIT";
export const INTL_PITCH = "INTL_PITCH";
export const AMA_HIT = "AMA_HIT";
export const AMA_PITCH = "AMA_PITCH";
export const columns = [
	{ name: "name", title: "Name" },
	{ name: "value", title: "Value" }
];

const _mapToOpts = map => [
	{ value: null, text: "" },
	...Object.keys(map).map(mapKey => ({ value: mapKey, text: map[mapKey] }))
];

const _optsToMap = ops =>
	[...ops].reduce((overallDict, entry) => ({ ...overallDict, [entry["value"]]: entry["text"] }), {});

export const INTEREST_LEVEL_MAP = {
	HGH: "High",
	MED: "Med",
	LOW: "Low",
	NON: "None"
};

export const PROSPECT_TYPE_MAP = {
	EXC: "Excellent",
	GOOD: "Good",
	SAVG: "Strong Average",
	AVG: "Average",
	MGN: "Marginal",
	FRG: "Fringe",
	ORG: "Organizational",
	SUM: "Summary",
	INJ: "Injured",
	DNS: "Did Not See",
	NP: "Non-Prospect"
};

export const PROSPECT_TYPE_OPTS = _mapToOpts(PROSPECT_TYPE_MAP);

export const PROSPECT_TYPE_ORDER = ["NP", "DNS", "INJ", "SUM", "ORG", "FRG", "MGN", "AVG", "SAVG", "GOOD", "EXC"];

export const PROSPECT_TYPE_MAP_PRO = {
	EXC: "Excellent",
	GOOD: "Good",
	SAVG: "Strong Average",
	AVG: "Average",
	MGN: "Marginal",
	HFRG: "High Fringe",
	FRG: "Fringe",
	LFRG: "Low Fringe",
	ORG: "Organizational",
	SUM: "Summary",
	LIM: "Limited Look",
	INJ: "Injured",
	DNS: "Did Not See",
	NP: "Non-Prospect"
};

export const PROSPECT_TYPE_OPTS_PRO = _mapToOpts(PROSPECT_TYPE_MAP_PRO);

export const PROSPECT_TYPE_ORDER_PRO = [
	"NP",
	"DNS",
	"INJ",
	"LIM",
	"SUM",
	"ORG",
	"HFRG",
	"FRG",
	"LFRG",
	"MGN",
	"AVG",
	"SAVG",
	"GOOD",
	"EXC"
];

export const OFP_OPTS = [
	{ value: null, text: "" },
	...Array(46)
		.fill()
		.map((_, idx) => ({ value: 80 - idx, text: `${80 - idx}` })),
	{ value: 30, text: "30" },
	{ value: 25, text: "25" },
	{ value: 20, text: "20" }
];

export const OFP_OPTS_INTL = [
	{ value: null, text: "" },
	...Array(46)
		.fill()
		.map((_, idx) => ({ value: 80 - idx, text: `${80 - idx}` })),
	{ value: 30, text: "30" },
	{ value: 25, text: "25" },
	{ value: 20, text: "20" },
	{ value: 0, text: "0" }
];

export const OFP_OPTS_PRO = [
	{ value: null, text: "" },
	...Array(46)
		.fill()
		.map((_, idx) => ({ value: 80 - idx, text: `${80 - idx}` })),
	{ value: 30, text: "D" },
	{ value: 25, text: "L" },
	{ value: 20, text: "I" },
	{ value: 0, text: "NP" }
];

export const OFP_OPTS_PRO_LEGACY = [
	{ value: null, text: "" },
	...Array(46)
		.fill()
		.map((_, idx) => ({ value: 80 - idx, text: `${80 - idx}` })),
	{ value: 30, text: "L" },
	{ value: 25, text: "D" },
	{ value: 20, text: "I" },
	{ value: 0, text: "NP" }
];

export const getOFPCode = (ofp, ref) => {
	const ofpOptions = ref.seen_date != null && dayjs(ref.seen_date).year() < 2019 ? OFP_OPTS_PRO_LEGACY : OFP_OPTS_PRO;
	const options = ofpOptions.filter(e => e.value === ofp);
	if (options.length === 1) return options[0].text;
	return ofp;
};

export const GRADE_OPTS = [
	{ value: null, text: "" },
	{ value: 80, text: "80" },
	{ value: 70, text: "70" },
	{ value: 65, text: "65" },
	{ value: 60, text: "60" },
	{ value: 55, text: "55" },
	{ value: 50, text: "50" },
	{ value: 45, text: "45" },
	{ value: 40, text: "40" },
	{ value: 35, text: "35" },
	{ value: 30, text: "30" },
	{ value: 20, text: "20" }
];

export const GRADE_OPTS_PRO = [
	{ value: null, text: "" },
	{ value: 80, text: "80" },
	{ value: 70, text: "70" },
	{ value: 60, text: "60" },
	{ value: 55, text: "55" },
	{ value: 50, text: "50" },
	{ value: 45, text: "45" },
	{ value: 40, text: "40" },
	{ value: 35, text: "35" },
	{ value: 30, text: "30" },
	{ value: 20, text: "20" }
];

export const INTEREST_OPS = [
	{ value: null, text: "" },
	{ value: "HGH", text: "High" },
	{ value: "MED", text: "Med" },
	{ value: "LOW", text: "Low" },
	{ value: "NON", text: "None" }
];

export const INTEREST_OPS_PRO = [
	{ value: null, text: "" },
	{ value: "DEF", text: "Definite" },
	{ value: "LOW", text: "Low" },
	{ value: "NON", text: "None" }
];

export const MAKEUP_FIELDS = [
	{ name: "Self Confidence", id: "slf_conf" },
	{ name: "Mental Toughness", id: "ment_tough" },
	{ name: "Aggressiveness", id: "agg" },
	{ name: "Competitiveness", id: "comp" },
	{ name: "Work Habits", id: "wk_hab" },
	{ name: "Personal Habits", id: "ps_hab" },
	{ name: "Aptitude", id: "apt" },
	{ name: "Poise", id: "poise" },
	{ name: "Baseball Instincts", id: "instinct" },
	{ name: "Overall Makeup", id: "makeup" }
];

export const MAKEUP_LEG_FIELDS = [
	{ name: "Self Confidence", id: "slf_conf" },
	{ name: "Mental Toughness", id: "ment_tough" },
	{ name: "Aggressiveness", id: "agg" },
	{ name: "Competitiveness", id: "comp" },
	{ name: "Work Habits", id: "wk_hab" },
	{ name: "Personal Habits", id: "ps_hab" },
	{ name: "Aptitude", id: "apt" },
	{ name: "Poise", id: "poise" },
	{ name: "Baseball Instincts", id: "instinct" },
	{ name: "Overall Makeup", id: "makeup" },
	{ name: "Athleticism", id: "ath" }
];

export const MAKEUP_FIELDS_INTL = [
	{ name: "Self Confidence", id: "slf_conf" },
	{ name: "Mental Toughness", id: "ment_tough" },
	{ name: "Aggressiveness", id: "agg" },
	{ name: "Competitiveness", id: "comp" },
	{ name: "Work Habits", id: "wk_hab" },
	{ name: "Personal Habits", id: "ps_hab" },
	{ name: "Aptitude", id: "apt" },
	{ name: "Poise", id: "poise" },
	{ name: "Baseball Instincts", id: "instinct" },
	{ name: "Overall Makeup", id: "ovm" },
	{ name: "Athleticism", id: "ath" }
];

export const MISC_FIELDS_PITCH = [
	{ name: "Delivery", id: "del" },
	{ name: "Arm Action", id: "arm_act" },
	{ name: "Deception", id: "deception" }
];

export const MISC_FIELDS_HIT = [
	{ name: "Hitting Mechanics", id: "hit_mech" },
	{ name: "Contact", id: "contact" }
];

export const HIT_TYPE_OPTS = [
	{ value: null, text: "" },
	{ value: "PUL", text: "Pull" },
	{ value: "SA", text: "Straight Away" },
	{ value: "OF", text: "Opposite Field" },
	{ value: "SPR", text: "Spray" },
	{ value: "SLP", text: "Slap" },
	{ value: "AF", text: "All Fields" },
	{ value: "FLY", text: "Flyball" },
	{ value: "LD", text: "Line Drive" }
];

export const OTH_PITCH_OPTS = [
	{ value: null, text: "" },
	{ value: "SPL", text: "Split-Finger" },
	{ value: "SI", text: "Sinker" },
	{ value: "FC", text: "Cutter" },
	{ value: "FF", text: "Fastball (4-seam)" },
	{ value: "FT", text: "Fastball (2-seam)" },
	{ value: "FO", text: "Forkball" },
	{ value: "KN", text: "Knuckleball" },
	{ value: "KC", text: "Knuckle Curve" },
	{ value: "SC", text: "Screwball" },
	{ value: "PM", text: "Palm Ball" },
	{ value: "EP", text: "Eephus" },
	{ value: "FS", text: "Foshball" },
	{ value: "SLR", text: "Slurve" },
	{ value: "VL", text: "Vulcan" },
	{ value: "UN", text: "Unknown" },
	{ value: "OTH", text: "Other" }
];

export const OTH_PITCH_OPTS_PRO = [
	{ value: null, text: "" },
	{ value: "SW", text: "Sweeper" },
	{ value: "SPL", text: "Split-Finger" },
	{ value: "FC", text: "Cutter" },
	{ value: "FO", text: "Forkball" },
	{ value: "KN", text: "Knuckleball" },
	{ value: "KC", text: "Knuckle Curve" },
	{ value: "SC", text: "Screwball" },
	{ value: "PM", text: "Palm Ball" },
	{ value: "EP", text: "Eephus" },
	{ value: "FS", text: "Foshball" },
	{ value: "SLR", text: "Slurve" },
	{ value: "VL", text: "Vulcan" },
	{ value: "UN", text: "Unknown" },
	{ value: "OTH", text: "Other" }
];

export const ARM_ANGLE_OPTS = [
	{ value: null, text: "" },
	{ value: "34", text: "3/4" },
	{ value: "H34", text: "H3/4" },
	{ value: "L34", text: "L3/4" },
	{ value: "OH", text: "OH" },
	{ value: "SA", text: "SA" },
	{ value: "SUB", text: "SUB" }
];

export const PLAYER_TOOL_FIELDS = [
	{ name: "Arm Strength", id: "arm_strength" },
	{ name: "Arm Acc", id: "arm_accuracy" },
	{ name: "Fld Ability", id: "fielding_ability" },
	{ name: "Fld Range", id: "fielding_range" },
	{ name: "Hit Ability", id: "hitting_ability" },
	{ name: "Raw Power", id: "raw_power" },
	{ name: "Power Production", id: "power_production" },
	{ name: "Run Speed", id: "run_speed" },
	{ name: "Baserunning", id: "base_running" },
	{ name: "Body Control", id: "body_control" },
	{ name: "CHP", id: "can_he_play" }
];

export const PHYSICAL_TOOLS_FIELDS = [
	{ name: "Bat Speed", id: "bat_speed" },
	{ name: "Raw Power", id: "raw_power" },
	{ name: "Speed", id: "run_speed" },
	{ name: "Arm Strength", id: "arm_strength" }
];

export const HITTING_FIELDS = [
	{ name: "Hitting Ability", id: "hitting_ability" },
	{ name: "Power Production", id: "power_production" },
	{ name: "AB Quality", id: "plate_discipline" }, // Renamed from Plate Discipline (from Barber 1/28/24)
	// { name: "Pitch Recognition", id: "pitch_recognition" }, // Removed (from Barber 1/28/24)
	{ name: "Bat to Ball", id: "contact" } // Renamed from Contact/Barrel Awareness (from Barber 1/28/24)
	// { name: "Calmness/Box Presence:", id: "calmness_box_presence" } // Removed (from Barber 1/28/24)
];

export const FIELDING_FIELDS = [
	{ name: "Fielding Ability", id: "fielding_ability" },
	{ name: "Fielding Range", id: "fielding_range" },
	{ name: "Arm Accuracy", id: "arm_accuracy" },
	{ name: "Hands", id: "hands" },
	{ name: "Feet", id: "feet" }
];

export const RISK_LEVEL_OPTS = [
	{ text: "1", value: "1" },
	{ text: "2", value: "2" },
	{ text: "3", value: "3" },
	{ text: "4", value: "4" },
	{ text: "5", value: "5" }
];

export const GROUP_OPTS = [
	{ text: "1", value: "1" },
	{ text: "2", value: "2" },
	{ text: "3", value: "3" },
	{ text: "4", value: "4" },
	{ text: "5", value: "5" }
];

export const GROUP_OPTS_LEGACY = [
	{ text: "8", value: "8" },
	{ text: "7", value: "7" },
	{ text: "6+", value: "6+" },
	{ text: "6", value: "6" },
	{ text: "5+", value: "5+" },
	{ text: "5", value: "5" },
	{ text: "4", value: "4" },
	{ text: "3", value: "3" },
	{ text: "2", value: "2" },
	{ text: "1", value: "1" }
];

export const CHANCE_OPTS = [
	{ text: "1", value: 1 },
	{ text: "2", value: 2 },
	{ text: "3", value: 3 },
	{ text: "4", value: 4 },
	{ text: "5", value: 5 },
	{ text: "6", value: 6 },
	{ text: "7", value: 7 },
	{ text: "8", value: 8 },
	{ text: "9", value: 9 },
	{ text: "10", value: 10 }
];

export const FB_MOV_OPTS = [
	{ text: "Sink", value: "sink" },
	{ text: "Run", value: "run" },
	{ text: "Ride", value: "ride" },
	{ text: "Cut", value: "cut" },
	{ text: "Straight", value: "straight" }
];

export const COMFORT_LEVEL_OPTS = [
	{ text: "Would bet the house on it", value: "1", abbreviation: "Bet House" },
	{ text: "Good", value: "2" },
	{ text: "Moderate/would like to see more", value: "3", abbreviation: "Mod/See More" },
	{ text: "Moderate/did not see player well", value: "4", abbreviation: "Mod/Didn't See Well" },
	{ text: "Low/Need to see player more", value: "5", abbreviation: "Low/Need More" }
];

export const ROUND_OPTS = [
	{ text: "1st", value: "1" },
	{ text: "1st/1A Round", value: "1/1A" },
	{ text: "2nd/3rd Round", value: "2/3" },
	{ text: "4th/5th Round", value: "4/5" },
	{ text: "6th-10th Round", value: "6-10" },
	{ text: "11th-20th Round", value: "11-20" },
	{ text: "21st-30th Round", value: "21-30" },
	{ text: "31st-40th Round", value: "31-40" }
];

export const ARM_SPEED_OPTS = [
	{ text: "Elite", value: "elite" },
	{ text: "Above Average", value: "above_average", abbreviation: "Above Avg" },
	{ text: "Average", value: "average", abbreviation: "Avg" },
	{ text: "Below Average", value: "below_average", abbreviation: "Below Avg" }
];

export const PHYSICAL_ATTRIBUTES_FIELDS = [
	{ name: "Strength", id: "strength" },
	{ name: "Ease of Actions", id: "ease_of_actions" },
	{ name: "Body Control", id: "body_control" },
	{ name: "Explosiveness", id: "explosiveness" },
	{ name: "Overall Athleticism", id: "ath" }
];

export const PITCHING_ATTRIBUTES_FIELDS = [
	{ name: "Delivery", id: "_del" },
	{ name: "Arm Action", id: "arm_act" },
	{ name: "Arm Speed", id: "arm_speed" },
	{ name: "Deception", id: "deception" },
	{ name: "Feel for Pitching", id: "feel" },
	{ name: "Control", id: "control" }
];

export const PLAYER_TOOL_FIELDS_INTL = [
	{ name: "Arm Strength", id: "arm_strength" },
	{ name: "Arm Acc", id: "arm_accuracy" },
	{ name: "Fld Ability", id: "fielding_ability" },
	{ name: "Fld Range", id: "fielding_range" },
	{ name: "Hit Ability", id: "hitting_ability" },
	{ name: "Raw Power", id: "raw_power" },
	{ name: "Power Production", id: "power_production" },
	{ name: "Run Speed", id: "run_speed" },
	{ name: "Usable Speed", id: "useable_speed" },
	{ name: "Body Control", id: "body_control" }
];

export const PLAYER_OFFENSE_TOOL_FIELDS = [
	{ id: "hitting_ability", name: "Hitting Ability" },
	{ id: "ab_quality", name: "AB Quality" },
	{ id: "raw_power", name: "Raw Power" },
	{ id: "power_production", name: "Power Production" },
	{ id: "hit_type", name: "Type of Hitter" },
	{ id: "run_speed", name: "Run Speed" },
	{ id: "base_running", name: "Baserunning" },
	{ id: "home_to_1b", name: "Home to 1B" }
];

export const PLAYER_DEFENSE_TOOL_FIELDS = [
	{ id: "position_in_ml", name: "Position in ML" },
	{ id: "defense", name: "Overall Defense" },
	{ id: "arm_strength", name: "Arm Strength" },
	{ id: "arm_accuracy", name: "Arm Accuracy" },
	{ id: "fielding_ability", name: "Hands" },
	{ id: "fielding_range", name: "Range" },
	{ id: "instinct", name: "Instincts" },
	{ id: "ath", name: "Athleticism" }
];

export const CATCHER_DEFENSE_TOOL_FIELDS = [
	{ id: "position_in_ml", name: "Position in ML" },
	{ id: "defense", name: "Overall Defense" },
	{ id: "arm_strength", name: "Arm Strength" },
	{ id: "arm_accuracy", name: "Arm Accuracy" },
	{ id: "receiving", name: "Receiving" },
	{ id: "blocking", name: "Blocking" },
	{ id: "avg_release_time", name: "Average Release Time" },
	{ id: "intangibles", name: "Intangibles" },
	{ id: "ath", name: "Athleticism" }
];

export const MISC_OPTS = [
	{ value: null, text: "" },
	{ value: "EXC", text: "Excellent" },
	{ value: "GOOD", text: "Good" },
	{ value: "AVG", text: "Average" },
	{ value: "BLW", text: "Below Average" },
	{ value: "POOR", text: "Poor" }
];

export const MISC_OPTS_NO_EXC = [
	{ value: null, text: "" },
	{ value: "EXC", text: "Excellent" },
	{ value: "GOOD", text: "Good" },
	{ value: "AVG", text: "Average" },
	{ value: "BLW", text: "Below Average" },
	{ value: "POOR", text: "Poor" }
];

export const RISK_OPTS = [
	{ value: null, text: "" },
	{ value: "HGH", text: "High", abbreviation: "H" },
	{ value: "MED", text: "Med", abbreviation: "M" },
	{ value: "LOW", text: "Low", abbreviation: "L" }
];

export const PRO_RISK_OPTS = [
	{ value: null, text: "" },
	{ value: "EXT", text: "Extreme" },
	{ value: "HGH", text: "High" },
	{ value: "MED", text: "Med" },
	{ value: "LOW", text: "Low" },
	{ value: "NON", text: "None" }
];

export const PLAYER_AMA_POSITION_OPTS = [
	{ value: null, text: "" },
	{ value: "C", text: "C" },
	{ value: "1B", text: "1B" },
	{ value: "2B", text: "2B" },
	{ value: "3B", text: "3B" },
	{ value: "SS", text: "SS" },
	{ value: "LF", text: "LF" },
	{ value: "CF", text: "CF" },
	{ value: "RF", text: "RF" },
	{ value: "IF", text: "IF" },
	{ value: "DH", text: "DH" }
];

export const PLAYER_POSITION_OPTS = [
	...PLAYER_AMA_POSITION_OPTS,
	{ value: "UTL", text: "UTL" },
	{ value: "OF", text: "OF" }
];

export const PITCHER_POSITION_OPTS = [
	{ value: null, text: "" },
	{ value: "LHS", text: "LHS" },
	{ value: "LHR", text: "LHR" },
	{ value: "RHS", text: "RHS" },
	{ value: "RHR", text: "RHR" },
	{ value: "SP", text: "SP" },
	{ value: "RP", text: "RP" }
];

export const EVAL_MISC_TEXT_FIELDS = [{ name: "Injury Notes", id: "injury_notes", mobileTab: "Inj" }];

export const EVAL_MISC_TEXT_FIELDS_LEG = [
	{
		name: "Current Signability",
		id: "signability_at_this_time",
		mobileTab: "Sign"
	},
	{ name: "Injury Notes", id: "injury_notes", mobileTab: "Inj" },
	{
		name: "Physical Description",
		id: "physical_description",
		mobileTab: "Phys"
	},
	{ name: "Player Comparison", id: "player_comparison", mobileTab: "Comp" }
];

export const EVAL_MISC_TEXT_FIELDS_INTL = [
	{ name: "Injury Notes", id: "injury_notes", mobileTab: "Inj" },
	{
		name: "Physical Description",
		id: "physical_description",
		mobileTab: "Phys"
	},
	{ name: "Player Comparison", id: "player_comparison", mobileTab: "Comp" }
];

export const SNAP_SUM_FIELDS = [
	{ name: "Current Snapshot", id: "current_snapshot", mobileTab: "Snap" },
	{
		name: "Summary",
		id: "summary",
		format: value =>
			value
				? value.split("\n").map((item, idx) => (
						<span key={idx}>
							{item}
							<br />
						</span>
				  ))
				: value,
		mobileTab: "Sum"
	}
];

export const DEFAULT_PITCH_TYPES = [
	{ name: "Fastball", abb: "FB" },
	{ name: "Curveball", abb: "CB" },
	{ name: "Slider", abb: "SL" },
	{ name: "Changeup", abb: "CH" },
	{ name: "Other Pitch", abb: "OTH_PITCH" }
];

export const DEFAULT_PITCH_TYPES_2OTH = [
	{ name: "Fastball", abb: "FB" },
	{ name: "Curveball", abb: "CB" },
	{ name: "Slider", abb: "SL" },
	{ name: "Changeup", abb: "CH" },
	{ name: "Other Pitch", abb: "OTH_PITCH" },
	{ name: "Other Pitch 2", abb: "OTH2_PITCH" }
];

export const DEFAULT_PITCH_TYPES_OTH3 = [
	{ name: "Fastball", abb: "FB" },
	{ name: "Curveball", abb: "CB" },
	{ name: "Slider", abb: "SL" },
	{ name: "Changeup", abb: "CH" },
	{ name: "Other Pitch", abb: "OTH_PITCH" },
	{ name: "Other Pitch 2", abb: "OTH2_PITCH" },
	{ name: "Other Pitch 3", abb: "OTH3_PITCH" }
];

export const HANDEDNESS_OPTS = [
	{ value: "R", text: "Right" },
	{ value: "L", text: "Left" }
];

export const HIT_HANDEDNESS_OPTS = [
	{ value: "R", text: "Right" },
	{ value: "L", text: "Left" },
	{ value: "S", text: "Switch" }
];

export const YESNO_OPTS = [
	{ value: "Y", text: "Yes" },
	{ value: "N", text: "No" }
];

export const LEGACY_OPTS = [
	{ value: "UTILITY", text: "Def Utility" },
	{ value: "SUPER_UTILITY", text: "Super Utility" },
	{ value: "BENCH", text: "Bench Bat" },
	{ value: "BACKUP_C", text: "Backup C" },
	{ value: "EXTRA", text: "Extra OF" },
	{ value: "AAA", text: "3A Player" },
	{ value: "SITUATION", text: "Situation" },
	{ value: "STARTER5", text: "#5 Starter" },
	{ value: "SETUP", text: "Setup Man" },
	{ value: "STARTER4", text: "#4 Starter" },
	{ value: "STARTER1", text: "#1 Starter" },
	{ value: "STARTER3", text: "#3 Starter" },
	{ value: "STARTER2", text: "#2 Starter" },
	{ value: "ORG_PIT", text: "Org Pitcher" },
	{ value: "INNING7", text: "7th Inning" },
	{ value: "INNING8", text: "8th Inning" },
	{ value: "EMRG", text: "Emergency Up and Down", abbreviation: "Emerg. Up & Down" },
	{ value: "AAA_START", text: "AAA Starter" },
	{ value: "DSP_LM", text: "Depth SP/Long Man", abbreviation: "Depth SP/LR" },
	{ value: "EMRG_RP", text: "Emergency RP" },
	{ value: "MIDDLE", text: "Middle Reliever", abbreviation: "Mid. Reliever" },
	{ value: "ALL_STAR", text: "All-Star" }
];

export const PLAYER_PROFILE_OPTS = [
	{ value: "ALLSTAR", text: "All-Star Player" },
	{ value: "EVERYDAY", text: "Everyday Player" },
	{ value: "PLATOON", text: "Platoon" },
	{ value: "BACKUPC", text: "Backup C" },
	{ value: "UTILINF", text: "Utility Infielder", abbreviation: "Util. Infielder" },
	{ value: "EXTRAOF", text: "Extra OF" },
	{ value: "SUPERUTIL", text: "Super Utility", abbreviation: "Super Util." },
	{ value: "BENCH", text: "Bench Bat" },
	{ value: "EMERGENCY", text: "Emergency Up & Down", abbreviation: "Emerg. Up & Down" },
	{ value: "3A", text: "3A Player" },
	{ value: "ORG", text: "Org Player" },
	{ value: "NP", text: "NP" }
];

export const PITCHER_PROFILE_OPTS = [
	{ value: "1STARTER", text: "#1 Starter" },
	{ value: "2STARTER", text: "#2 Starter" },
	{ value: "3STARTER", text: "#3 Starter" },
	{ value: "4STARTER", text: "#4 Starter" },
	{ value: "5STARTER", text: "#5 Starter" },
	{ value: "CLOSER", text: "Closer" },
	{ value: "7THSET", text: "7th Inning Setup", abbreviation: "7th Inn. Setup" },
	{ value: "8THSET", text: "8th Inning Setup", abbreviation: "8th Inn. Setup" },
	{ value: "SIT", text: "Situational" },
	{ value: "MIDDLEREL", text: "Middle Reliever", abbreviation: "Mid. Reliever" },
	{ value: "EMERGENCY", text: "Emergency Up & Down", abbreviation: "Emerg. Up & Down" },
	{ value: "3A", text: "3A Pitcher" },
	{ value: "ORG", text: "Org Pitcher" },
	{ value: "DEPTH_LONG", text: "Depth Starter/Long Man", abbreviation: "Depth SP/LR" },
	{ value: "NP", text: "NP" }
];

export const PLAYER_PROFILE_MAP = _optsToMap([...LEGACY_OPTS, ...PLAYER_PROFILE_OPTS]);
export const PITCHER_PROFILE_MAP = _optsToMap([
	...LEGACY_OPTS,
	...PITCHER_PROFILE_OPTS,
	{ value: "EVERYDAY", text: "Everyday" },
	{ value: "PLATOON", text: "Platoon" }
]);

export const HITTER_POTENTIAL_OPTIONS = [
	{ value: "ALL_STAR", text: "ML Superstar/All Star" },
	{ value: "ABOVE_AVG", text: "Above Avg ML Player" },
	{ value: "AVG", text: "Avg ML Player" },
	{ value: "PLATOON", text: "Utility/Platoon Player" },
	{ value: "UP_DOWN", text: "Up/down" }
];

export const PITCHER_POTENTIAL_OPTIONS = [
	{ value: "TOP", text: "Top of the rotation" },
	{ value: "MID", text: "Mid rotation" },
	{ value: "BACK", text: "Back end rotation" },
	{ value: "CLOSER", text: "Closer" },
	{ value: "LATE_REL", text: "Late inning reliever" },
	{ value: "MIDDLE_REL", text: "Middle reliever" },
	{ value: "UP_DOWN", text: "Up/down" }
];

export const TREND_OPTS = [
	{ value: "UP", text: "↑ (Up)" },
	{ value: "DOWN", text: "↓ (Down)" },
	{ value: "STEADY", text: "→ (Steady)" }
];

export const LOOK_OPTS = [
	{ value: "COVERAGE", text: "Coverage" },
	{ value: "CROSSCOVERAGE", text: "Cross-Coverage" },
	{ value: "TV_REPORT", text: "TV Report" },
	{ value: "SPECIAL_ASSIGN", text: "Special Assign" },
	{ value: "TRADE_DEADLINE", text: "Trade Deadline" },
	{ value: "INSTRUCT_LG", text: "Instruct Lg" },
	{ value: "SPRING_TRAINING", text: "Spring Training" },
	{ value: "WINTER_BALL", text: "Winter Ball" },
	{ value: "AFL", text: "AFL" },
	{ value: "WORKOUT", text: "Workout" }
];

export const LEVEL_OPTS = [
	{ value: "MLB", text: "MLB" },
	{ value: "AAA", text: "AAA" },
	{ value: "AA", text: "AA" },
	{ value: "A", text: "A" },
	{ value: "1F", text: "1F" },
	{ value: "SA", text: "Short Season A" },
	{ value: "R", text: "R" },
	{ value: "SUM", text: "Summer League" },
	{ value: "OTP", text: "OTP" },
	{ value: "UNK", text: "Unknown" },
	{ value: "WIN", text: "Winter Ball" }
];

export const OUT_PITCH_OPTS = [
	{ value: "FB", text: "Fastball" },
	{ value: "CB", text: "Curveball" },
	{ value: "SL", text: "Slider" },
	{ value: "CH", text: "Changeup" },
	{ value: "SPL", text: "Split-Finger" },
	{ value: "CUT", text: "Cutter" },
	{ value: "KB", text: "Knuckleball" },
	{ value: "SINK", text: "Sinker" },
	{ value: "NONE", text: "None" }
];

export const TO_PLATE_OPTS = [
	{ value: "QUICK", text: "Quick" },
	{ value: "AVG", text: "Average" },
	{ value: "SLOW", text: "Slow" },
	{ value: "LONG", text: "Long" }
];

export const HITTING_RISK_OPTIONS = [
	{ value: "DEFINITE", text: "Definite" },
	{ value: "LIKELY", text: "Likely" },
	{ value: "UNLIKELY", text: "Un-Likely" },
	{ value: "POOR", text: "Poor" }
];

export const PRO_PROFILE_OPTS_VIEW = [
	...PLAYER_PROFILE_OPTS,
	...PITCHER_PROFILE_OPTS,
	{ value: "SITUATION", text: "Situation" },
	{ value: "STARTER5", text: "5th Starter" },
	{ value: "SETUP", text: "Setup Man" },
	{ value: "STARTER4", text: "4th Starter" },
	{ value: "STARTER1", text: "1st Starter" },
	{ value: "STARTER3", text: "3rd Starter" },
	{ value: "STARTER2", text: "2nd Starter" },
	{ value: "ORG_PIT", text: "Org Pitcher" },
	{ value: "INNING7", text: "7th Inning" },
	{ value: "INNING8", text: "8th Inning" },
	{ value: "EMRG", text: "Emergency Up and Down", abbreviation: "Emerg. Up & Down" },
	{ value: "AAA_START", text: "AAA Starter" },
	{ value: "DSP_LM", text: "Depth SP/Long Man", abbreviation: "Depth SP/LR" },
	{ value: "EMRG_RP", text: "Emergency RP" },
	{ value: "MIDDLE", text: "Middle Reliever", abbreviation: "Mid. Reliever" },
	{ value: "ALL_STAR", text: "All-Star" }
];

export const REPORT_TYPE_OPTS = [
	{ value: "AMA_HIT", text: "Amateur Hitter" },
	{ value: "AMA_PITCH", text: "Amateur Pitcher" },
	{ value: "AMA_ALL", text: "Amateur Hitter/Pitcher" },
	{ value: "AMA_HIT_FOL", text: "Amateur Hitter Follow" },
	{ value: "AMA_PITCH_FOL", text: "Amateur Pitcher Follow" },
	{ value: "AMA_ALL_FOL", text: "Amateur Hitter/Pitcher Follow" },
	{ value: "AMA_HIT_FRE", text: "Amateur Hitter Full" },
	{ value: "AMA_PITCH_FRE", text: "Amateur Pitcher Full" },
	{ value: "AMA_ALL_FRE", text: "Amateur Hitter/Pitcher Full" },
	{ value: "PRO_HIT", text: "Pro Hitter" },
	{ value: "PRO_PITCH", text: "Pro Pitcher" },
	{ value: "PRO_ALL", text: "Pro Hitter/Pitcher" },
	{ value: "INTL_HIT", text: "International Hitter" },
	{ value: "INTL_PITCH", text: "International Pitcher" },
	{ value: "INTL_ALL", text: "International Hitter/Pitcher" }
];

export const REPORT_TYPE_MAP = {
	AMA_HIT: "Amateur Hitter",
	AMA_PITCH: "Amateur Pitcher",
	INTL_HIT: "International Hitter",
	INTL_PITCH: "International Pitcher",
	PRO_HIT: "Pro Hitter",
	PRO_PITCH: "Pro Pitcher"
};

// TODO: get these into an LK table
export const EVAL_LEVEL_FILTERS_OPS = [
	{ value: "MLB", label: "MLB" },
	{ value: "Minors", label: "Minors" },
	{ value: "AAA", label: "AAA" },
	{ value: "AA", label: "AA" },
	{ value: "A+", label: "A+" },
	{ value: "A", label: "A" },
	{ value: "A-", label: "A-" },
	{ value: "Rk", label: "Rookie" },
	{ value: "FRk", label: "Rookie (DSL)" },
	{ value: "SUM", label: "Summer League" },
	{ value: "OTP", label: "OTP" },
	{ value: "UNK", label: "Unknown" },
	{ value: "WIN", label: "Winter Ball" },
	{ value: "Other", label: "Other" },
	{ value: "Amateur", label: "Amateur" },
	{ value: "International", label: "International" }
];

export const countries = {
	USA: "United States",
	AFG: "Afghanistan",
	ALA: "Åland Islands",
	ALB: "Albania",
	DZA: "Algeria",
	ASM: "American Samoa",
	AND: "Andorra",
	AGO: "Angola",
	AIA: "Anguilla",
	ATA: "Antarctica",
	ATG: "Antigua and Barbuda",
	ARG: "Argentina",
	ARM: "Armenia",
	ABW: "Aruba",
	AUS: "Australia",
	AUT: "Austria",
	AZE: "Azerbaijan",
	BHS: "Bahamas",
	BHR: "Bahrain",
	BGD: "Bangladesh",
	BRB: "Barbados",
	BLR: "Belarus",
	BEL: "Belgium",
	BLZ: "Belize",
	BEN: "Benin",
	BMU: "Bermuda",
	BTN: "Bhutan",
	BOL: "Bolivia, Plurinational State of",
	BES: "Bonaire, Sint Eustatius and Saba",
	BIH: "Bosnia and Herzegovina",
	BWA: "Botswana",
	BVT: "Bouvet Island",
	BRA: "Brazil",
	IOT: "British Indian Ocean Territory",
	BRN: "Brunei Darussalam",
	BGR: "Bulgaria",
	BFA: "Burkina Faso",
	BDI: "Burundi",
	KHM: "Cambodia",
	CMR: "Cameroon",
	CAN: "Canada",
	CPV: "Cape Verde",
	CYM: "Cayman Islands",
	CAF: "Central African Republic",
	TCD: "Chad",
	CHL: "Chile",
	CHN: "China",
	CXR: "Christmas Island",
	CCK: "Cocos (Keeling) Islands",
	COL: "Colombia",
	COM: "Comoros",
	COG: "Congo",
	COD: "Congo, the Democratic Republic of the",
	COK: "Cook Islands",
	CRI: "Costa Rica",
	CIV: "Côte d'Ivoire",
	HRV: "Croatia",
	CUB: "Cuba",
	CUW: "Curaçao",
	CYP: "Cyprus",
	CZE: "Czech Republic",
	DNK: "Denmark",
	DJI: "Djibouti",
	DMA: "Dominica",
	DOM: "Dominican Republic",
	ECU: "Ecuador",
	EGY: "Egypt",
	SLV: "El Salvador",
	GNQ: "Equatorial Guinea",
	ERI: "Eritrea",
	EST: "Estonia",
	ETH: "Ethiopia",
	FLK: "Falkland Islands (Malvinas)",
	FRO: "Faroe Islands",
	FJI: "Fiji",
	FIN: "Finland",
	FRA: "France",
	GUF: "French Guiana",
	PYF: "French Polynesia",
	ATF: "French Southern Territories",
	GAB: "Gabon",
	GMB: "Gambia",
	GEO: "Georgia",
	DEU: "Germany",
	GHA: "Ghana",
	GIB: "Gibraltar",
	GRC: "Greece",
	GRL: "Greenland",
	GRD: "Grenada",
	GLP: "Guadeloupe",
	GUM: "Guam",
	GTM: "Guatemala",
	GGY: "Guernsey",
	GIN: "Guinea",
	GNB: "Guinea-Bissau",
	GUY: "Guyana",
	HTI: "Haiti",
	HMD: "Heard Island and McDonald Islands",
	VAT: "Holy See (Vatican City State)",
	HND: "Honduras",
	HKG: "Hong Kong",
	HUN: "Hungary",
	ISL: "Iceland",
	IND: "India",
	IDN: "Indonesia",
	IRN: "Iran, Islamic Republic of",
	IRQ: "Iraq",
	IRL: "Ireland",
	IMN: "Isle of Man",
	ISR: "Israel",
	ITA: "Italy",
	JAM: "Jamaica",
	JPN: "Japan",
	JEY: "Jersey",
	JOR: "Jordan",
	KAZ: "Kazakhstan",
	KEN: "Kenya",
	KIR: "Kiribati",
	PRK: "Korea, Democratic People's Republic of",
	KOR: "Korea, Republic of",
	KWT: "Kuwait",
	KGZ: "Kyrgyzstan",
	LAO: "Lao People's Democratic Republic",
	LVA: "Latvia",
	LBN: "Lebanon",
	LSO: "Lesotho",
	LBR: "Liberia",
	LBY: "Libya",
	LIE: "Liechtenstein",
	LTU: "Lithuania",
	LUX: "Luxembourg",
	MAC: "Macao",
	MKD: "Macedonia, the former Yugoslav Republic of",
	MDG: "Madagascar",
	MWI: "Malawi",
	MYS: "Malaysia",
	MDV: "Maldives",
	MLI: "Mali",
	MLT: "Malta",
	MHL: "Marshall Islands",
	MTQ: "Martinique",
	MRT: "Mauritania",
	MUS: "Mauritius",
	MYT: "Mayotte",
	MEX: "Mexico",
	FSM: "Micronesia, Federated States of",
	MDA: "Moldova, Republic of",
	MCO: "Monaco",
	MNG: "Mongolia",
	MNE: "Montenegro",
	MSR: "Montserrat",
	MAR: "Morocco",
	MOZ: "Mozambique",
	MMR: "Myanmar",
	NAM: "Namibia",
	NRU: "Nauru",
	NPL: "Nepal",
	NLD: "Netherlands",
	NCL: "New Caledonia",
	NZL: "New Zealand",
	NIC: "Nicaragua",
	NER: "Niger",
	NGA: "Nigeria",
	NIU: "Niue",
	NFK: "Norfolk Island",
	MNP: "Northern Mariana Islands",
	NOR: "Norway",
	OMN: "Oman",
	PAK: "Pakistan",
	PLW: "Palau",
	PSE: "Palestinian Territory, Occupied",
	PAN: "Panama",
	PCZ: "Panama Canal Zone",
	PNG: "Papua New Guinea",
	PRY: "Paraguay",
	PER: "Peru",
	PHL: "Philippines",
	PCN: "Pitcairn",
	POL: "Poland",
	PRT: "Portugal",
	PRI: "Puerto Rico",
	QAT: "Qatar",
	REU: "Réunion",
	ROU: "Romania",
	RUS: "Russian Federation",
	RWA: "Rwanda",
	BLM: "Saint Barthélemy",
	SHN: "Saint Helena, Ascension and Tristan da Cunha",
	KNA: "Saint Kitts and Nevis",
	LCA: "Saint Lucia",
	MAF: "Saint Martin (French part)",
	SPM: "Saint Pierre and Miquelon",
	VCT: "Saint Vincent and the Grenadines",
	WSM: "Samoa",
	SMR: "San Marino",
	STP: "Sao Tome and Principe",
	SAU: "Saudi Arabia",
	SEN: "Senegal",
	SRB: "Serbia",
	SYC: "Seychelles",
	SLE: "Sierra Leone",
	SGP: "Singapore",
	SXM: "Sint Maarten (Dutch part)",
	SVK: "Slovakia",
	SVN: "Slovenia",
	SLB: "Solomon Islands",
	SOM: "Somalia",
	ZAF: "South Africa",
	SGS: "South Georgia and the South Sandwich Islands",
	SSD: "South Sudan",
	ESP: "Spain",
	LKA: "Sri Lanka",
	SDN: "Sudan",
	SUR: "Suriname",
	SJM: "Svalbard and Jan Mayen",
	SWZ: "Swaziland",
	SWE: "Sweden",
	CHE: "Switzerland",
	SYR: "Syrian Arab Republic",
	TWN: "Taiwan, Province of China",
	TJK: "Tajikistan",
	TZA: "Tanzania, United Republic of",
	THA: "Thailand",
	TLS: "Timor-Leste",
	TGO: "Togo",
	TKL: "Tokelau",
	TON: "Tonga",
	TTO: "Trinidad and Tobago",
	TUN: "Tunisia",
	TUR: "Turkey",
	TKM: "Turkmenistan",
	TCA: "Turks and Caicos Islands",
	TUV: "Tuvalu",
	UGA: "Uganda",
	UKR: "Ukraine",
	SOV: "Union of Soviet Socialist Republics",
	ARE: "United Arab Emirates",
	GBR: "United Kingdom",
	UMI: "United States Minor Outlying Islands",
	URY: "Uruguay",
	UZB: "Uzbekistan",
	VUT: "Vanuatu",
	VEN: "Venezuela, Bolivarian Republic of",
	VNM: "Viet Nam",
	VGB: "Virgin Islands, British",
	VIR: "Virgin Islands, U.S.",
	WLF: "Wallis and Futuna",
	ESH: "Western Sahara",
	YEM: "Yemen",
	ZMB: "Zambia",
	ZWE: "Zimbabwe"
};

const EVAL_METADATA = {
	uniqueFields: ["eval_id"],
	otherFields: [["eval_create_user_id"]],
	dateFields: [
		"date_of_birth",
		"eval_collection_create_date",
		"eval_collection_last_change_date",
		"eval_collection_system_last_change_date",
		"eval_last_change_date",
		"eval_system_last_change_date",
		"eval_create_date",
		"publish_date",
		"school_grad_date",
		"seen_date"
	]
};

export const EVAL_PLAYER_METADATA = {
	...EVAL_METADATA,
	otherFields: [
		["phil_id"],
		["eval_create_user_id"],
		["eval_create_user_id", "publish_date"],
		["eval_create_user_id", "phil_id"],
		["eval_create_user_id", "phil_id", "eval_type"],
		["phil_id", "eval_type"],
		["eval_type"]
	]
};

const PROSPECT_IDEALS_OPTS_COMMON = [
	{ text: "Baseball Skill", value: "baseball_skill" },
	{ text: "Performance", value: "performance" },
	{ text: "Athleticism", value: "athleticism" },
	{ text: "Makeup", value: "makeup" },
	{ text: "Body/Health", value: "body_health" }
];

export const PROSPECT_IDEALS_OPTS = evalReportType => {
	if (evalReportType === "AMA_HIT") {
		return [{ text: "Tools", value: "tools" }, ...PROSPECT_IDEALS_OPTS_COMMON];
	} else if (evalReportType === "AMA_PITCH") {
		return [{ text: "Stuff", value: "stuff" }, ...PROSPECT_IDEALS_OPTS_COMMON];
	}
	return [];
};
