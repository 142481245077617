import { $TSFixMe } from "utils/tsutils";
import { IContractMinors, IContract as IContractMajors } from "_react/shared/_types/schema/contracts";
import { MAJOR, MINORS } from "_react/playerpage/_constants";

export type TContract = IContractMinors | IContractMajors;

export type TLevel = typeof MAJOR | typeof MINORS;

export function isContractMajors(contract: TContract): contract is IContractMajors {
	return contract.hasOwnProperty("contract_mj_option_years");
}

export function isContractMinors(contract: TContract): contract is IContractMinors {
	return contract.hasOwnProperty("addendum_c");
}

export function isNonNullContractMinors(contract: TContract, level: string): contract is IContractMinors {
	return contract != null && level === MINORS && isContractMinors(contract);
}

export function hasAssignmentClauses(contract: IContractMinors) {
	return (contract.addendum_b_assignment_clauses ?? []).length > 0;
}

export function hasForeignInquiries(contract: IContractMinors) {
	return (contract.addendum_b_foreign_inquiries ?? []).length > 0;
}

export function hasMjBaseSalaries(contract: IContractMinors) {
	return (contract.addendum_b_mj_base_salaries ?? []).length > 0;
}

export function hasMjMiscellaneousClauses(contract: IContractMinors) {
	if ((contract.addendum_b_language_entries ?? []).length > 0) {
		const miscClauses = contract.addendum_b_language_entries?.filter(lang => lang.addendum_b_type === "MJMISC");
		return (miscClauses ?? []).length > 0;
	}
}

export function getMjMiscellaneousClauses(contract: IContractMinors) {
	return contract.addendum_b_language_entries?.filter(lang => lang.addendum_b_type === "MJMISC");
}

export function hasPerformanceBonuses(contract: IContractMinors) {
	if ((contract.addendum_b_language_entries ?? []).length > 0) {
		const performanceBonuses = contract.addendum_b_language_entries?.filter(
			lang => lang.addendum_b_type === "PERFBNS"
		);
		return (performanceBonuses ?? []).length > 0;
	}
}

export function getPerformanceBonuses(contract: IContractMinors) {
	return contract.addendum_b_language_entries?.filter(lang => lang.addendum_b_type === "PERFBNS");
}

export function hasMnAssignmentSalaryClauses(contract: IContractMinors) {
	return (contract.addendum_b_mn_assignment_salaries ?? []).length > 0;
}

export function hasReleaseClauses(contract: IContractMinors) {
	return (contract.addendum_b_release_clauses ?? []).length > 0;
}

export type ContractBonusColumn = {
	title: string;
	name: string;
	isSortDisabled?: boolean;
	getCellValue?: (row: $TSFixMe) => $TSFixMe;
};
