import { Machine, assign, AnyEventObject, Interpreter } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";
import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { fetchDPLQuestionnaireSurvey } from "_react/shared/data_models/dpl/_network";
import { TDPLQuestionnaireSurveyResponseData } from ".";
import { TSurvey } from "_react/shared/data_models/dpl/_types";
const SURVEY_RESPONSE_CANCEL_SOURCE = "surveyResponse";

export type TDPLQuestionnaireSurveyResponseCancelSource = {
	[SURVEY_RESPONSE_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TDPLQuestionnaireSurveyResponseContext = {
	dplId?: number | null;
	surveyId?: string;
	surveyResponse?: TSurvey | null;
	cancelSources: TDPLQuestionnaireSurveyResponseCancelSource;
	toast?: CreateToastFnReturn;
};

interface IDPLQuestionnaireSurveyResponseStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches player's survey data
				surveyResponse: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_SURVEY_RESPONSE = "SET_SURVEY_RESPONSE";
export const SET_DPL_ID = "SET_DPL_ID";
export const SET_SURVEY_ID = "SET_SURVEY_ID";
export const SET_SURVEY_NAME = "SET_SURVEY_NAME";
export const FETCHING_SURVEY_RESPONSE = { initialized: { surveyResponse: "fetching" } };

const FETCH_SURVEY_RESPONSE_DONE = "done.invoke.fetchingSurveyResponse:invocation[0]";

type TSetSurveyResponseEvent = {
	type: typeof SET_SURVEY_RESPONSE;
	data: TSurvey | null | undefined;
};
type TSetDPLIdEvent = {
	type: typeof SET_DPL_ID;
	data: number | undefined;
};
type TSetSurveyIdEvent = {
	type: typeof SET_SURVEY_ID;
	data: string | undefined;
};
type TFetchSurveyResponseEvent = {
	type: typeof FETCH_SURVEY_RESPONSE_DONE;
	data?: TSurvey | undefined;
};

type TDPLQuestionnaireSurveyResponseEvent =
	| TSetSurveyResponseEvent
	| TSetDPLIdEvent
	| TSetSurveyIdEvent
	| TFetchSurveyResponseEvent;

export type TDPLQuestionnaireSurveyResponseSend = Interpreter<
	TDPLQuestionnaireSurveyResponseContext,
	IDPLQuestionnaireSurveyResponseStateSchema,
	TDPLQuestionnaireSurveyResponseEvent
>["send"];

const DPLQuestionnaireSurveyResponseMachine = (
	dplIdProp?: number | null,
	surveyIdProp?: string,
	data?: TDPLQuestionnaireSurveyResponseData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TDPLQuestionnaireSurveyResponseContext,
		IDPLQuestionnaireSurveyResponseStateSchema,
		TDPLQuestionnaireSurveyResponseEvent
	>(
		{
			id: "DPLQuestionnaireSurveyResponse",
			initial: "initializing",
			context: {
				dplId: dplIdProp,
				surveyId: surveyIdProp,
				surveyResponse: data?.surveyResponse,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_SURVEY_RESPONSE]: { actions: "setSurveyResponse" },
						[SET_DPL_ID]: { actions: ["setDplId", "clearSurveyResponse"], cond: "shouldSetDplId" },
						[SET_SURVEY_ID]: { actions: ["setSurveyId", "clearSurveyResponse"], cond: "shouldSetSurveyId" }
					},
					states: {
						surveyResponse: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingSurveyResponse",
												cond: "shouldFetchSurveyResponse"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingSurveyResponse",
									entry: ["refreshSurveyResponseCancelSource"],
									invoke: {
										src: "fetchSurveyResponse",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSurveyResponseSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSurveyResponseErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetDplId: (
					context: TDPLQuestionnaireSurveyResponseContext,
					event: TDPLQuestionnaireSurveyResponseEvent
				) => event.type === SET_DPL_ID && context.dplId !== event.data,
				shouldSetSurveyId: (
					context: TDPLQuestionnaireSurveyResponseContext,
					event: TDPLQuestionnaireSurveyResponseEvent
				) => event.type === SET_SURVEY_ID && context.surveyId !== event.data,
				shouldFetchSurveyResponse: (
					context: TDPLQuestionnaireSurveyResponseContext,
					_event: TDPLQuestionnaireSurveyResponseEvent
				) => context.surveyResponse === undefined && context.dplId !== undefined
			},
			actions: {
				setSurveyResponse: assign<TDPLQuestionnaireSurveyResponseContext, TDPLQuestionnaireSurveyResponseEvent>(
					{
						surveyResponse: (
							context: TDPLQuestionnaireSurveyResponseContext,
							event: TDPLQuestionnaireSurveyResponseEvent
						) => {
							if (event.type !== SET_SURVEY_RESPONSE) return context.surveyResponse;
							return event.data;
						},
						cancelSources: (
							context: TDPLQuestionnaireSurveyResponseContext,
							event: TDPLQuestionnaireSurveyResponseEvent
						) => {
							if (event.type !== SET_SURVEY_RESPONSE) return context.cancelSources;
							if (context.cancelSources[SURVEY_RESPONSE_CANCEL_SOURCE] != null)
								context.cancelSources[SURVEY_RESPONSE_CANCEL_SOURCE].cancel();
							delete context.cancelSources[SURVEY_RESPONSE_CANCEL_SOURCE];
							return context.cancelSources;
						}
					}
				),
				setDplId: assign<TDPLQuestionnaireSurveyResponseContext, TDPLQuestionnaireSurveyResponseEvent>({
					dplId: (
						context: TDPLQuestionnaireSurveyResponseContext,
						event: TDPLQuestionnaireSurveyResponseEvent
					) => {
						if (event.type !== SET_DPL_ID) return context.dplId;
						return event.data;
					}
				}),
				setSurveyId: assign<TDPLQuestionnaireSurveyResponseContext, TDPLQuestionnaireSurveyResponseEvent>({
					surveyId: (
						context: TDPLQuestionnaireSurveyResponseContext,
						event: TDPLQuestionnaireSurveyResponseEvent
					) => {
						if (event.type !== SET_SURVEY_ID) return context.surveyId;
						return event.data;
					}
				}),
				// Clear Actions
				clearSurveyResponse: assign<
					TDPLQuestionnaireSurveyResponseContext,
					TDPLQuestionnaireSurveyResponseEvent
				>({
					surveyResponse: (
						_context: TDPLQuestionnaireSurveyResponseContext,
						_event: TDPLQuestionnaireSurveyResponseEvent
					) => undefined,
					cancelSources: (
						context: TDPLQuestionnaireSurveyResponseContext,
						_event: TDPLQuestionnaireSurveyResponseEvent
					) => {
						if (context.cancelSources[SURVEY_RESPONSE_CANCEL_SOURCE] != null) {
							context.cancelSources[SURVEY_RESPONSE_CANCEL_SOURCE].cancel();
							delete context.cancelSources[SURVEY_RESPONSE_CANCEL_SOURCE];
						}
						return context.cancelSources;
					}
				}),
				// Cancel Source Actions
				refreshSurveyResponseCancelSource: assign<
					TDPLQuestionnaireSurveyResponseContext,
					TDPLQuestionnaireSurveyResponseEvent
				>({
					cancelSources: (
						context: TDPLQuestionnaireSurveyResponseContext,
						_event: TDPLQuestionnaireSurveyResponseEvent
					) => {
						if (context.cancelSources[SURVEY_RESPONSE_CANCEL_SOURCE] != null)
							context.cancelSources[SURVEY_RESPONSE_CANCEL_SOURCE].cancel();
						context.cancelSources[SURVEY_RESPONSE_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchSurveyResponseSuccess: assign<
					TDPLQuestionnaireSurveyResponseContext,
					TDPLQuestionnaireSurveyResponseEvent
				>({
					surveyResponse: (
						context: TDPLQuestionnaireSurveyResponseContext,
						event: TDPLQuestionnaireSurveyResponseEvent
					) => {
						if (event.type !== FETCH_SURVEY_RESPONSE_DONE) return context.surveyResponse;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchSurveyResponseErrored: (
					context: TDPLQuestionnaireSurveyResponseContext,
					_event: TDPLQuestionnaireSurveyResponseEvent
				) => {
					if (context.toast)
						context.toast({
							title: "DPL Questionnaire Survey Response",
							description: "Error fetching DPL questionnaire survey response data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchSurveyResponse: (context: TDPLQuestionnaireSurveyResponseContext, _event: AnyEventObject) => {
					const { dplId, surveyId } = context;
					if (dplId === undefined) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchDPLQuestionnaireSurvey(
							{
								dplId: dplId,
								id: surveyId
							},
							context.cancelSources[SURVEY_RESPONSE_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default DPLQuestionnaireSurveyResponseMachine;
