import { CSSProperties } from "react";

export const TABLE_STYLE: CSSProperties = {
	width: "100%",
	border: "1px solid black",
	borderCollapse: "collapse",
	tableLayout: "fixed",
	fontSize: "12px"
};
export const CELL_STYLE: CSSProperties = {
	border: "1px solid black",
	borderCollapse: "collapse",
	padding: "1px",
	textAlign: "center",
	cursor: "pointer",
	wordWrap: "break-word"
};
export const HEADER_STYLE: CSSProperties = {
	...CELL_STYLE,
	backgroundColor: "black",
	color: "white"
};

export const BLUE = "#233F8D";
export const GRAY = "#757575";
export const RED = "#E81628";
