import React from "react";
import { chakra, Flex } from "@chakra-ui/react";

export const NavigationIconStyle = chakra(Flex, {
	baseStyle: {
		cursor: "pointer",
		padding: 3,
		background: "maroon",
		color: "white",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		opacity: 0.8,
		":hover": {
			opacity: 1.0
		}
	}
});

export const MenuButtonStyle = chakra(Flex, {
	baseStyle: {
		cursor: "pointer",
		paddingTop: 1,
		paddingBottom: 1,
		paddingLeft: 2,
		paddingRight: 2,
		color: "white",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		opacity: 0.8,
		":hover": {
			opacity: 1.0
		}
	}
});

export const HomeFlex = chakra(Flex, {
	baseStyle: {
		cursor: "pointer",
		paddingLeft: 2,
		color: "white",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		opacity: 0.8,
		":hover": {
			opacity: 1.0
		}
	}
});

export const HeaderBoxStyle: React.CSSProperties = {
	position: "fixed",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	top: 0,
	left: 0,
	right: 0,
	overflow: "visible",
	backgroundColor: "black",
	zIndex: 1000
};

export const SearchBoxStyle = {
	padding: "4px 0px 4px 8px",
	margin: "4px 0px",
	flexGrow: 1
};

export const MenuOptionStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-start"
};
