import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function FastForward({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M4 18l8.5-6L4 6v12zm9-12v12l8.5-6L13 6z" />
			<path fill={"none"} d="M0 0h24v24H0z" />
		</IconSVG>
	);
}
