import React, { FC } from "react";

import { TPlayerType } from "_react/playerpage/header/AgentOrAgencyField/_helpers";
import { FieldDiv, LabelSpan } from "_react/playerpage/header/AgentOrAgencyField/_styles";
import { AgentOrAgencyPopup } from "_react/playerpage/header/AgentOrAgencyField/Popup";

type AgentOrAgencyFieldProps = {
	label: string;
	infoId: string | number;
	infoKey: "agent" | "agency";
	playerType: TPlayerType;
	org?: string;
};

export const AgentOrAgencyField: FC<AgentOrAgencyFieldProps> = ({ label, infoId, infoKey, playerType, org }) => {
	return (
		<FieldDiv>
			<LabelSpan>{label}</LabelSpan>
			<AgentOrAgencyPopup infoId={infoId} infoKey={infoKey} playerType={playerType} org={org} />
		</FieldDiv>
	);
};
