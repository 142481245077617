import React from "react";

import { getLevelDisplayFromLevels } from "_react/shared/_helpers/stats";
import {
	GAME_TYPE_OVERALL,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_SPRING_TRAINING,
	BATS_L,
	BATS_OVERALL,
	BATS_R
} from "_react/shared/data_models/baseline_hit_probs/_constants";
import {
	GAME_TYPE_D,
	GAME_TYPE_F,
	GAME_TYPE_L,
	GAME_TYPE_R,
	GAME_TYPE_S,
	GAME_TYPE_W
} from "_react/shared/data_models/stats/_constants";
import { TColumn, TParentColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { formatPercentage } from "_react/shared/ui/presentation/components/stat/shared/_helpers";
import TeamColumn from "_react/shared/ui/data/tables/shared/TeamColumn";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import { ICON_CIRCLE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";

import { getLevelsFromRow } from "_react/shared/ui/data/tables/PitcherPitchOutcomesTable/_helpers";
import { TPitcherPitchOutcomesRow } from "_react/shared/ui/data/tables/PitcherPitchOutcomesTable/_types";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

export const GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP: Record<string, Array<string>> = {
	[GAME_TYPE_R]: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_F]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_D]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_L]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_W]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_S]: [GAME_TYPE_SPRING_TRAINING]
};

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherPitchOutcomesRow) => row.combinedPitcherPitchOutcomesData.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherPitchOutcomesRow) => {
		if ("team" in row.combinedPitcherPitchOutcomesData)
			return (
				<TeamColumn teamBam={row.combinedPitcherPitchOutcomesData.team} childRowCount={row.childData?.length} />
			);
		return <TeamColumn childRowCount={row.childData?.length} />;
	}
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherPitchOutcomesRow) =>
		getLevelDisplayFromLevels(getLevelsFromRow(row), NULL_FILLER_TEXT),
	getSortValueFunction: (row: TPitcherPitchOutcomesRow) => {
		// Child Rows
		if ("team" in row.combinedPitcherPitchOutcomesData)
			return row.combinedPitcherPitchOutcomesData.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
		// Parent Rows with nested data - highest level if multiple levels
		if (row.childData && row.childData.length > 1)
			return Math.min(
				...row.childData.map((childRow: TPitcherPitchOutcomesRow) => {
					if ("team" in childRow.combinedPitcherPitchOutcomesData)
						return (
							childRow.combinedPitcherPitchOutcomesData.team?.levelRel?.sortOrder ??
							Number.MAX_SAFE_INTEGER
						);
					return Number.MAX_SAFE_INTEGER;
				})
			);
		return NULL_FILLER_TEXT;
	}
};

const PITCH_TYPE_COLUMN = {
	id: "pitchType",
	value: "pitchType",
	label: "Pitch Type",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherPitchOutcomesRow) =>
		row.combinedPitcherPitchOutcomesData.lkPitchType?.label &&
		row.combinedPitcherPitchOutcomesData.lkPitchType?.abbreviation ? (
			<PitchTypeLabel
				label={row.combinedPitcherPitchOutcomesData.lkPitchType.label}
				abbreviation={row.combinedPitcherPitchOutcomesData.lkPitchType.abbreviation}
				shape={ICON_CIRCLE}
			/>
		) : (
			"Overall"
		),
	getSortValueFunction: (row: TPitcherPitchOutcomesRow) =>
		row.combinedPitcherPitchOutcomesData.lkPitchType?.sortOrder ?? 0
};

export const getPitchesColumn = (batsFilter: string) => {
	return {
		id: "pitches",
		value: "pitches",
		label: "Pitches",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPitchOutcomesRow) => {
			return batsFilter === BATS_L
				? row.combinedPitcherPitchOutcomesData.totalVl && row.combinedPitcherPitchOutcomesData.totalVl !== 0
					? row.combinedPitcherPitchOutcomesData.totalVl
					: NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? row.combinedPitcherPitchOutcomesData.totalVr && row.combinedPitcherPitchOutcomesData.totalVr !== 0
					? row.combinedPitcherPitchOutcomesData.totalVr
					: NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? row.combinedPitcherPitchOutcomesData.total && row.combinedPitcherPitchOutcomesData.total !== 0
					? row.combinedPitcherPitchOutcomesData.total
					: NULL_FILLER_TEXT
				: NULL_FILLER_TEXT;
		}
	};
};

export const getSwingPctColumn = (batsFilter: string) => {
	return {
		id: "swingPct",
		value: "swingPct",
		label: "Act",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPitchOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPitchOutcomesData.swingPctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPitchOutcomesData.swingPctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPitchOutcomesData.swingPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXSwingPctColumn = (batsFilter: string) => {
	return {
		id: "swingPctPIntrinsic",
		value: "swingPctPIntrinsic",
		label: "Exp",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPitchOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPitchOutcomesData.swingPctPIntrinsicVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPitchOutcomesData.swingPctPIntrinsicVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPitchOutcomesData.swingPctPIntrinsic) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getWhiffPctColumn = (batsFilter: string) => {
	return {
		id: "whiffPct",
		value: "whiffPct",
		label: "Act",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPitchOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPitchOutcomesData.whiffPctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPitchOutcomesData.whiffPctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPitchOutcomesData.whiffPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXWhiffPctColumn = (batsFilter: string) => {
	return {
		id: "whiffPctPIntrinsic",
		value: "whiffPctPIntrinsic",
		label: "Exp",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPitchOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPitchOutcomesData.whiffPctPIntrinsicVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPitchOutcomesData.whiffPctPIntrinsicVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPitchOutcomesData.whiffPctPIntrinsic) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getChasePctColumn = (batsFilter: string) => {
	return {
		id: "chasePct",
		value: "chasePct",
		label: "Act",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPitchOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPitchOutcomesData.chasePctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPitchOutcomesData.chasePctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPitchOutcomesData.chasePct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXChasePctColumn = (batsFilter: string) => {
	return {
		id: "chasePctPIntrinsic",
		value: "chasePctPIntrinsic",
		label: "Exp",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPitchOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPitchOutcomesData.chasePctPIntrinsicVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPitchOutcomesData.chasePctPIntrinsicVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPitchOutcomesData.chasePctPIntrinsic) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getCswPctColumn = (batsFilter: string) => {
	return {
		id: "cswPct",
		value: "cswPct",
		label: "Act",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPitchOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPitchOutcomesData.cswPctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPitchOutcomesData.cswPctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPitchOutcomesData.cswPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXCswPctColumn = (batsFilter: string) => {
	return {
		id: "cswPctPIntrinsic",
		value: "cswPctPIntrinsic",
		label: "Exp",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPitchOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPitchOutcomesData.cswPctPIntrinsicVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPitchOutcomesData.cswPctPIntrinsicVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPitchOutcomesData.cswPctPIntrinsic) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getPitcherPitchOutcomesColumns = (
	batsFilter: string
): Array<TColumn<TPitcherPitchOutcomesRow, keyof TPitcherPitchOutcomesRow>> => {
	return [
		SEASON_COLUMN,
		TEAM_COLUMN,
		LEVEL_COLUMN,
		PITCH_TYPE_COLUMN,
		getPitchesColumn(batsFilter),
		getSwingPctColumn(batsFilter),
		getXSwingPctColumn(batsFilter),
		getWhiffPctColumn(batsFilter),
		getXWhiffPctColumn(batsFilter),
		getChasePctColumn(batsFilter),
		getXChasePctColumn(batsFilter),
		getCswPctColumn(batsFilter),
		getXCswPctColumn(batsFilter)
	];
};

export const PITCHER_PITCH_OUTCOMES_PARENT_COLUMNS: Array<TParentColumn> = [
	{
		label: "",
		id: "general",
		childColumnIds: ["season", "team", "level", "pitchType", "pitches"]
	},
	{ label: "Swing%", id: "swingPct", childColumnIds: ["swingPct", "swingPctPIntrinsic"] },
	{ label: "Chase%", id: "chasePct", childColumnIds: ["chasePct", "chasePctPIntrinsic"] },
	{ label: "SwStr%", id: "whiffPct", childColumnIds: ["whiffPct", "whiffPctPIntrinsic"] },
	{ label: "CSW%", id: "cswPct", childColumnIds: ["cswPct", "cswPctPIntrinsic"] }
];
