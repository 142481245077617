import dayjs from "dayjs";
import { getPlayerClassificationTag } from "utils/PlayerTypeHelpers";
import { getCurrentDraftCutoffDate } from "utils/helpers";

export function correctOFPLabel(level, ofp, evaluation = null) {
	let legacy = false;
	switch (level) {
		case "PRO":
			if (ofp > 30) return ofp;
			legacy = evaluation && evaluation.seen_date && dayjs(evaluation.seen_date).year() < 2019;
			if (ofp === 30) return legacy ? "LIM" : "DNS";
			else if (ofp === 25) return legacy ? "DNS" : "LIM";
			else if (ofp === 20) return "INJ";
			else if (ofp === 0) return "NP";
			return ofp;
		case "AMA_HIT":
		case "AMA_PITCH":
		case "FOL":
		case "LVR":
		case "FRE":
			return evaluation && dayjs(evaluation.seen_date).year() >= 2020 ? evaluation.group : ofp;
		default:
			return ofp;
	}
}

export function getLabelFromType(type) {
	let label = "";
	if (type.startsWith("PRO")) label = "PRO ";
	else if (type.startsWith("AMA")) label = "AMATEUR ";
	else if (type.startsWith("INTL")) label = "INTERNATIONAL ";

	if (type.endsWith("PITCH")) label = `${label}PITCHER`;
	else if (type.endsWith("HIT")) label = `${label}HITTER`;

	return label;
}

export function getScoutLatestValues(
	evaluations,
	field = null,
	includeNonOFPValues = false,
	includeNpOFPValue = false
) {
	return evaluations
		.filter(e => field !== "ofp" || e.ofp >= 35 || includeNonOFPValues || (includeNpOFPValue && e.ofp === 0))
		.sort((a, b) => (b.seen_date ? b.seen_date : dayjs()).diff(a.seen_date ? a.seen_date : dayjs()))
		.reduce((dict, e) => {
			dict[e.eval_create_user_id] = dict[e.eval_create_user_id]
				? dict[e.eval_create_user_id]
				: field
				? includeNpOFPValue && e[field] === 0
					? 20
					: e[field]
				: e;
			return dict;
		}, {});
}

export function isDark(color) {
	// Variables for red, green, blue values
	let r, g, b;

	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {
		// If HEX --> store the red, green, blue values in separate variables
		color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

		r = color[1];
		g = color[2];
		b = color[3];
	} else {
		// If RGB --> Convert it to HEX: http://gist.github.com/983661
		color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

		r = color >> 16;
		g = (color >> 8) & 255; // eslint-disable-line no-mixed-operators
		b = color & 255;
	}

	// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

	// Using the HSP value, determine whether the color is light or dark
	if (hsp > 127.5) {
		return false;
	} else {
		return true;
	}
}

export function shadeColor(color, percent) {
	let R = parseInt(color.substring(1, 3), 16);
	let G = parseInt(color.substring(3, 5), 16);
	let B = parseInt(color.substring(5, 7), 16);

	R = parseInt((R * (100 + percent)) / 100, 10);
	G = parseInt((G * (100 + percent)) / 100, 10);
	B = parseInt((B * (100 + percent)) / 100, 10);

	R = R < 255 ? R : 255;
	G = G < 255 ? G : 255;
	B = B < 255 ? B : 255;

	const RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
	const GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
	const BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

	return "#" + RR + GG + BB;
}

export function pickHex(color1, color2, ratio) {
	const r = Math.ceil(
		parseInt(color1.substring(0, 2), 16) * ratio + parseInt(color2.substring(0, 2), 16) * (1 - ratio)
	);
	const g = Math.ceil(
		parseInt(color1.substring(2, 4), 16) * ratio + parseInt(color2.substring(2, 4), 16) * (1 - ratio)
	);
	const b = Math.ceil(
		parseInt(color1.substring(4, 6), 16) * ratio + parseInt(color2.substring(4, 6), 16) * (1 - ratio)
	);
	return `rgb(${r},${g},${b})`;
}

export const getTypeURLFromEval = evaluation => {
	if (evaluation.eval_type.startsWith("AMA")) return "ama";
	if (evaluation.eval_type.startsWith("INTL")) return "intl";
	return "pro";
};

export const getEvalType = evaluation => {
	return evaluation.eval_type.split("_")[0];
};

export const getName = (players, philId) => {
	if (players.hasOwnProperty(philId)) {
		return `${players[philId].roster_first_name ? players[philId].roster_first_name : players[philId].first_name} ${
			players[philId].last_name
		}`;
	}
	return "";
};

export const getPlayerTypeFromEvalType = type => {
	if (type.startsWith("PRO")) return "PRO";
	else if (type.startsWith("AMA")) return "AMA";
	else if (type.startsWith("INTL")) return "INTL";
};

export const renderType = evaluation => {
	const type = getPlayerTypeFromEvalType(evaluation.eval_type);
	return getPlayerClassificationTag(type);
};

export const EVAL_GROUP_VALUE_MAP = {
	1: 1,
	2: 2,
	3: 3,
	4: 4,
	5: 5,
	"5+": 5.5,
	6: 6,
	"6+": 6.5,
	7: 7,
	8: 8
};

export const EVAL_GROUP_VALUE_MAP_REVERSE = {
	1: "1",
	2: "2",
	3: "3",
	4: "4",
	5: "5",
	5.5: "5+",
	6: "6",
	6.5: "6+",
	7: "7",
	8: "8"
};

export const getGroupLabelFromAverageValue = averageValue => {
	let difference = 0;
	let closest = null;
	Object.keys(EVAL_GROUP_VALUE_MAP_REVERSE).map(value => {
		const valueDifference = Math.abs(value - averageValue);
		if (closest == null || difference >= averageValue) {
			closest = EVAL_GROUP_VALUE_MAP_REVERSE[value];
			difference = valueDifference;
		}
	});
	return closest ?? "-";
};

export const sortEvaluations = (evaluations, date_col = "publish_date") =>
	evaluations.sort((a, b) => {
		if (!a[date_col]) return 1;
		if (!b[date_col]) return -1;
		return dayjs(a[date_col]).diff(dayjs(b[date_col]));
	});

const addOneYear = dateString => {
	return dayjs(dateString)
		.add(1, "years")
		.format("YYYY-MM-DD");
};

const convertOldGroup = group => {
	switch (group) {
		case "8":
		case "7":
		case "6+":
			return "1";
		case "6":
			return "2";
		case "5+":
			return "3";
		case "5":
			return "4";
		default:
			return "5";
	}
};

export const getAmaAvgGroup = (evaluations, draftDate) => {
	const fromDate = getCurrentDraftCutoffDate(draftDate);
	const toDate = addOneYear(fromDate);

	if (!evaluations) return null;
	let groupSum = 0;
	let groupCount = 0;
	const evalCreators = {};
	const fromDateJs = dayjs(fromDate);
	const toDateJs = dayjs(toDate);
	for (const evl of evaluations) {
		if (evl.seen_date == null) continue;
		const evalSeenDate = dayjs(evl.seen_date);
		if (
			fromDateJs.isBefore(evalSeenDate) &&
			evalSeenDate.isBefore(toDateJs) &&
			evl.eval_type.startsWith("AMA") &&
			evl.group &&
			!evalCreators.hasOwnProperty(evl.eval_create_user_id)
		) {
			let group = evl.group;
			if (evalSeenDate.isBefore(dayjs("2022-01-01"))) group = convertOldGroup(group);
			groupSum += parseFloat(group);
			groupCount += 1;
			evalCreators[evl.eval_create_user_id] = true;
		}
	}
	if (!groupCount) return null;
	const [groupOnes, groupDecimals] = (groupSum / groupCount).toFixed(2).split(".");
	if (groupDecimals >= 75) {
		return `${parseFloat(groupOnes) + 1}`;
	}
	if (groupDecimals > 25) {
		return `${groupOnes}+`;
	}
	return groupOnes;
};

function filterEvalsForOFP(evaluations, evalType) {
	/**
	 * Filters an array of evals. Result has at most 1 eval per scout (their most recent). Result has only evals
	 * within the last 365 days if that is at least 5. If not it will include the most recent
	 * up to 5 evals from within the past 2 calendar years by year.
	 * Note: Evals with ofp's under 35 are excluded
	 */
	const evalsSorted = sortEvaluations(evaluations, "seen_date");
	evalsSorted.reverse();
	const now = dayjs();
	const currentYear = now.year();
	const targetNum = 5;
	const scoutIds = new Set();
	const evalsFiltered = [];
	for (const evl of evalsSorted) {
		const { eval_create_user_id, seen_date, ofp } = evl;
		const isSeenLastYear = seen_date != null && now.diff(seen_date, "d") < 365;
		const isSeenLast2Year = seen_date != null && dayjs(seen_date).year() >= currentYear - 2;
		const isRecentEnough = isSeenLastYear || (evalsFiltered.length < targetNum && isSeenLast2Year);
		const isOfpEnough = ofp != null && (ofp >= 35 || (ofp === 0 && evalType === "PRO"));
		if (!scoutIds.has(eval_create_user_id) && isRecentEnough && isOfpEnough) {
			evalsFiltered.push(evl);
			scoutIds.add(eval_create_user_id);
		}
	}
	return evalsFiltered;
}

export const calcOfp = ofp => {
	if (ofp === 0) return 20;
	return ofp;
};

export const getProIntlAvgOfp = (evaluations, proIntl) => {
	if (!evaluations) return null;
	const evalsForClass = evaluations.filter(evl => evl.eval_type.startsWith(proIntl));
	const filteredEvals = filterEvalsForOFP(evalsForClass, proIntl);
	const ofpSum = filteredEvals.reduce((sum, evl) => sum + calcOfp(evl.ofp), 0);
	return filteredEvals.length ? (ofpSum / filteredEvals.length).toFixed(0) : null;
};
