import React from "react";
import {
	Spacer,
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useDisclosure
} from "@chakra-ui/react";

import { AMA, PRO, INTL } from "_react/playerpage/_constants";
import { TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import { getColorFromClassification } from "_react/shared/searches/_helpers";

type PlayerPageProviderProps = {
	availableClassifications?: TPlayerPagePlayerClassification[];
	onSelectClassification: (classification: TPlayerPagePlayerClassification) => void;
};

export const PlayerClassificationDialog = ({
	availableClassifications = [AMA, PRO, INTL],
	onSelectClassification
}: PlayerPageProviderProps) => {
	const setPlayerClassification = (classification: TPlayerPagePlayerClassification) => () => {
		onSelectClassification(classification);
	};
	const { onClose } = useDisclosure();
	const cancelRef = React.useRef(null);

	return (
		<AlertDialog isOpen={true} onClose={onClose} leastDestructiveRef={cancelRef}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						Select Player Classification
					</AlertDialogHeader>

					<AlertDialogBody>
						Unable to determine the correct player classification, please choose from the options below.
					</AlertDialogBody>

					<AlertDialogFooter>
						<Spacer />
						{availableClassifications.length === 0 && (
							<>
								<Button ref={cancelRef} disabled={true} isLoading={true} ml={3}>
									Loading...
								</Button>
								<Spacer />
							</>
						)}

						{availableClassifications.includes(AMA) && (
							<>
								<Button
									sx={{
										margin: 0,
										backgroundColor: getColorFromClassification(AMA.toUpperCase()),
										color: "black"
									}}
									onClick={setPlayerClassification(AMA)}
									ml={3}
								>
									Amateur
								</Button>
								<Spacer />
							</>
						)}
						{availableClassifications.includes(INTL) && (
							<>
								<Button
									sx={{
										margin: 0,
										backgroundColor: getColorFromClassification(INTL.toUpperCase()),
										color: "white"
									}}
									onClick={setPlayerClassification(INTL)}
									ml={3}
								>
									International
								</Button>
								<Spacer />
							</>
						)}
						{availableClassifications.includes(PRO) && (
							<>
								<Button
									sx={{
										margin: 0,
										backgroundColor: getColorFromClassification(PRO.toUpperCase()),
										color: "white"
									}}
									onClick={setPlayerClassification(PRO)}
									ml={3}
								>
									Professional
								</Button>
								<Spacer />
							</>
						)}
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
