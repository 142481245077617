import { chakra, Box, HStack, VStack } from "@chakra-ui/react";

// Chakra components

export const PitchTypeLabelHStack = chakra(HStack, {
	baseStyle: {
		width: "100%",
		justifyContent: "space-between",
		marginBottom: "5",
		paddingBottom: "1",
		borderBottom: "1px solid",
		borderColor: "gray.700"
	}
});

export const LocationTileVStack = chakra(VStack, {
	baseStyle: {
		gap: 0,
		border: "1px solid",
		borderColor: "gray.300",
		borderRadius: "lg",
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 6,
		paddingRight: 6,
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "start"
	}
});

export const CountLabelBox = chakra(Box, {
	baseStyle: {
		marginLeft: "2",
		fontSize: "md",
		fontWeight: "bold",
		color: "gray.500"
	}
});

export const OverallHexbinPlotsHStack = chakra(HStack, {
	baseStyle: {
		gap: 0
	}
});

export const OverallHexbinPlotVStack = chakra(VStack, {
	baseStyle: {
		gap: 0
	}
});

export const HandednessLabelBox = chakra(Box, {
	baseStyle: {
		fontSize: "sm",
		fontWeight: "semibold",
		color: "gray.500"
	}
});
