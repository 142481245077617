export const FETCH_PRO_TEAMS_REQUEST = "TEAM_FETCH_PRO_TEAMS_REQUEST",
	FETCH_PRO_TEAMS_FAILURE = "TEAM_FETCH_PRO_TEAMS_FAILURE",
	FETCH_PRO_TEAMS_SUCCESS = "TEAM_FETCH_PRO_TEAMS_SUCCESS",
	FETCH_AMA_TEAMS_REQUEST = "TEAM_FETCH_AMA_TEAMS_REQUEST",
	FETCH_AMA_TEAMS_SUCCESS = "TEAM_FETCH_AMA_TEAMS_SUCCESS",
	FETCH_AMA_TEAMS_FAILURE = "TEAM_FETCH_AMA_TEAMS_FAILURE",
	CREATE_AMA_TEAM_REQUEST = "TEAM_CREATE_AMA_TEAM_REQUEST",
	CREATE_AMA_TEAM_SUCCESS = "TEAM_CREATE_AMA_TEAM_SUCCESS",
	CREATE_AMA_TEAM_FAILURE = "TEAM_CREATE_AMA_TEAM_FAILURE",
	FETCH_INTL_TEAMS_REQUEST = "TEAM_FETCH_INTL_TEAMS_REQUEST",
	FETCH_INTL_TEAMS_SUCCESS = "TEAM_FETCH_INTL_TEAMS_SUCCESS",
	FETCH_INTL_TEAMS_FAILURE = "TEAM_FETCH_INTL_TEAMS_FAILURE";
