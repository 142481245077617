import styled from "@emotion/styled";
import React, { FunctionComponent } from "react";
import { $TSFixMe, Style } from "utils/tsutils";
import { ColorScheme, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import Check from "_react/shared/legacy/ui/icons/Check";

type CheckboxProps = {
	checked?: boolean;
	colorScheme?: ColorScheme;
	disabled?: boolean;
	onChange?: $TSFixMe;
	style?: Style;
	onKeyPress?: $TSFixMe;
};

const StyledCheckbox = styled.div<CheckboxProps>(
	{
		height: "19px",
		width: "20px",
		border: "2px solid black",
		borderRadius: "3px",
		position: "relative"
	},
	(props: CheckboxProps) => ({
		backgroundColor: props.disabled ? "#E4E4E4" : props.checked ? props.colorScheme!.color : "white",
		borderColor: props.disabled ? "rgba(118,118,118,0.3)" : "black",
		cursor: props.disabled ? "not-allowed" : "pointer",
		...props.style
	})
);

export const Checkbox: FunctionComponent<CheckboxProps> = ({
	checked = false,
	colorScheme = defaultColorScheme.primary,
	disabled = false,
	onChange = () => null,
	style = {},
	onKeyPress = null
}) => {
	return (
		<StyledCheckbox
			checked={checked}
			colorScheme={colorScheme}
			onClick={() => {
				if (!disabled) onChange(!checked);
			}}
			disabled={disabled}
			style={style}
			onKeyPress={onKeyPress}
		>
			{checked && (
				<Check
					className="dont-dismiss"
					style={{
						color: "white",
						height: "16px",
						width: "16px",
						marginTop: "-1px"
					}}
				/>
			)}
		</StyledCheckbox>
	);
};
