import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function BatSpeed(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M14.5616 10.9586C13.8929 9.85359 13.8228 8.48795 14.3735 7.3204C14.9254 6.15269 16.0243 5.33895 17.3014 5.15336C17.4858 5.12385 17.6727 5.11033 17.8595 5.11033C18.1459 5.11033 18.4323 5.14229 18.7113 5.20621C19.0395 4.347 19.0481 3.39931 18.7371 2.53396C18.3511 1.47686 17.5251 0.639805 16.473 0.240299C14.6366 -0.432057 12.5852 0.347237 11.6569 2.06932H7.28111C6.91974 2.06932 6.62596 2.36187 6.62596 2.72447C6.62596 3.08706 6.91974 3.37961 7.28111 3.37961H11.0276L10.1131 5.3463H7.29458C6.93198 5.3463 6.63943 5.64008 6.63943 6.00267C6.63943 6.36404 6.93198 6.65782 7.29458 6.65782H9.49359L8.61843 8.51264H4.23255C3.87118 8.51264 3.5774 8.80642 3.5774 9.16901C3.5774 9.53038 3.87118 9.82416 4.23255 9.82416H8.00859C7.5538 10.8776 7.28215 12.0023 7.20593 13.1477C7.13587 14.2503 6.85684 15.3295 6.3836 16.3275L5.21589 18.7858C4.38129 18.4626 3.44096 18.8694 3.1054 19.7003C2.80056 20.5091 3.17792 21.4162 3.96706 21.7715L8.35892 23.8464H8.36015C8.57525 23.9484 8.81003 24 9.04849 24C9.23409 24 9.41971 23.9668 9.59548 23.9054C10.2027 23.6964 10.628 23.1457 10.6784 22.5053C10.7276 21.8649 10.3932 21.2565 9.82535 20.9553L10.9955 18.4773C11.4676 17.4829 12.1239 16.5868 12.929 15.8358C13.8534 14.9643 14.6069 13.9281 15.1514 12.78L15.5115 12.0167C15.1305 11.7266 14.8086 11.3679 14.5616 10.9586ZM9.80597 17.9368L9.75312 18.0449L7.51109 16.9866L7.56394 16.8784C7.73234 16.5195 7.88107 16.152 8.00643 15.7771L10.3763 16.8944C10.1649 17.23 9.97438 17.5778 9.80597 17.9368Z M17.8597 6.42169C16.8272 6.42169 15.8979 7.04367 15.5045 7.9975C15.1112 8.95133 15.3324 10.0478 16.0638 10.7754C16.7964 11.5019 17.894 11.7158 18.8454 11.3163C19.7968 10.9181 20.4126 9.98386 20.4065 8.95259C20.3966 7.55255 19.2597 6.42169 17.8597 6.42169Z M3.68594 6.65771H5.48177C5.84436 6.65771 6.13814 6.36393 6.13814 6.00256C6.13814 5.63997 5.84437 5.34619 5.48177 5.34619H3.68594C3.32335 5.34619 3.0308 5.63996 3.0308 6.00256C3.0308 6.36393 3.32334 6.65771 3.68594 6.65771Z"
			/>
		</Icon>
	);
}
