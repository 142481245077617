import { Dialog } from "_react/shared/legacy/ui/Dialog";
import { CardContents } from "_react/shared/legacy/ui/CardContents";

import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { IVideo } from "_react/shared/_types/schema/video";
import React, { FunctionComponent, useState } from "react";
import { $TSFixMe, TCustomPlayerClassification } from "utils/tsutils";
import VideoUploader from "_react/video/VideoUploader";
import { TabsMini } from "_react/shared/legacy/ui/TabsMini";
import { TabMini } from "_react/shared/legacy/ui/TabMini";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";

type VideoUploadModalProps = {
	defaultPlayerSelected: TPlayerPageCombinedPlayer | null;
	colorScheme?: ColorSchemeGroup;
	handleClose(vidDict?: IVideo[]): void;
	playerClassifications: Array<TCustomPlayerClassification>;
	show: boolean;
};

const VideoUploadModal: FunctionComponent<VideoUploadModalProps> = ({
	defaultPlayerSelected,
	colorScheme = defaultColorScheme,
	handleClose,
	playerClassifications,
	show
}) => {
	const [playerClassification, setplayerClassification] = useState(playerClassifications[0]);
	function localHandleClose(vidDicts?: IVideo[]) {
		if (vidDicts) {
			handleClose(vidDicts);
		} else {
			handleClose();
		}
	}

	return (
		<Dialog
			colorScheme={colorScheme.primary}
			onClose={(e: $TSFixMe) => {
				// Stop PointerEvents from selecting a player from PlayerSearch from causing this function to be called
				// TODO: This also stops clicks outside the modal from working, but there is a close button you can use
				if (e instanceof PointerEvent === false) localHandleClose();
			}}
			open={show ? show : false}
			minWidth="50%"
		>
			<CardContents style={{ minHeight: "80%", height: "100%" }}>
				{playerClassifications.length > 1 && (
					<TabsMini
						onChange={(_: $TSFixMe, value: TCustomPlayerClassification) => setplayerClassification(value)}
						value={playerClassification}
					>
						{playerClassifications.map(playerClassification => {
							return (
								<TabMini
									key={playerClassification}
									label={playerClassification}
									value={playerClassification}
								/>
							);
						})}
					</TabsMini>
				)}
				<VideoUploader
					colorScheme={colorScheme}
					defaultPlayerSelected={defaultPlayerSelected}
					playerClassification={playerClassification}
					uploadSuccess={localHandleClose}
				/>
			</CardContents>
		</Dialog>
	);
};

export default VideoUploadModal;
