// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useMemo, useCallback } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/core";
import Tooltip from "@tippy.js/react";
import dayjs from "dayjs";

import Table, { makeColumn as makeColumnGen, TMakeColumnOptParams, TCheckboxProps } from "_react/table";
import { TVideoWID } from "_react/video/shared/_types";
import Delete from "_react/shared/legacy/ui/icons/Delete";
import { TTableDataRow } from "_react/table/machine";
import { DATE_TEMPLATE, getCreatedDate } from "_react/video/shared/_helpers";
import { titleCase } from "utils/helpers";

export interface IMetadata {
	onDelete(videoId: string | number): void;
	videoIdsSelected: Set<number | string>;
}

interface IVideoTableProps {
	videos: TVideoWID[];
	isFetching: boolean;
	tableMetadata: IMetadata;
	onCheckClick(datum: string | number): void;
}

interface ITableComponentProps {
	datum: TVideoWID;
	metadata: IMetadata;
}

function DeleteIcon({ datum, metadata }: ITableComponentProps) {
	if (datum.type === "media") return <span />;
	const { onDelete } = metadata;
	const videoId = datum.videoId;
	return (
		<Delete
			style={{ color: "black" }}
			onClick={e => {
				e.stopPropagation();
				onDelete(videoId);
			}}
		/>
	);
}

function VideoOverflow({ datum }: ITableComponentProps) {
	const val = datum.type === "media" ? datum.filename : datum.type === "phil" ? datum.name : datum.description;
	if (val == null) return "";
	return (
		<Tooltip
			appendTo={"parent"}
			boundary={"scrollParent"}
			css={css`
				font-size: 0.75rem;
			`}
			content={val}
			duration={[275, 0]}
			placement="top"
			trigger="mouseenter"
		>
			<div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{val}</div>
		</Tooltip>
	);
}

const makeColumn = (params: TMakeColumnOptParams<TVideoWID, IMetadata>) => makeColumnGen(params);

const COLUMNS = [
	makeColumn({
		key: "createdDate",
		flex: 2,
		label: "Date Created",
		type: "date",
		getValue: ({ datum }) => {
			const createdDate = getCreatedDate(datum);
			return createdDate ? dayjs(createdDate, DATE_TEMPLATE).format("MM/DD/YYYY") : "";
		},
		getValueSort: ({ datum }) => {
			const createdDate = getCreatedDate(datum);
			return createdDate ? dayjs(createdDate, DATE_TEMPLATE) : null;
		}
	}),
	makeColumn({
		key: "videoDate",
		flex: 2,
		label: "Date Recorded",
		type: "date",
		getValue: ({ datum }) =>
			"videoDate" in datum && datum.videoDate ? dayjs(datum.videoDate, DATE_TEMPLATE).format("MM/DD/YYYY") : "",
		getValueSort: ({ datum }) =>
			"videoDate" in datum && datum.videoDate ? dayjs(datum.videoDate, DATE_TEMPLATE) : null
	}),
	makeColumn({
		key: "type",
		flex: 2,
		label: "Type",
		getValue: ({ datum }) => datum.type,
		getValueDisplay: ({ datum }) => {
			if (datum.type === "media") return "DPL";
			return datum.type ? titleCase(datum.type) : "";
		}
	}),
	makeColumn({
		key: "name",
		flex: 4,
		label: "Name / Description",
		getValue: ({ datum }) => (datum.type === "phil" ? datum.name : ""),
		getValueDisplay: VideoOverflow
	}),
	makeColumn({
		key: "delete",
		flex: 1,
		label: "Delete",
		getValueDisplay: DeleteIcon,
		type: "component"
	})
];

const HOVER_STYLE = { backgroundColor: "lightGray" };

const SORT = { key: "createdDate", asc: false };

const STYLES = { table: { minHeight: 150 } };

function VideoTable({ videos, isFetching, tableMetadata, onCheckClick }: IVideoTableProps) {
	const checkbox: TCheckboxProps<TVideoWID> = useMemo(() => {
		return {
			checkedKey: "videoId",
			checked: [...tableMetadata.videoIdsSelected],
			handleClick: (rowDatum: TTableDataRow<TVideoWID>) => {
				onCheckClick(rowDatum.datum.videoId);
			}
		};
	}, [tableMetadata.videoIdsSelected, onCheckClick]);

	const handleRowClick = useCallback(
		(row: TVideoWID) => {
			onCheckClick(row.videoId);
		},
		[onCheckClick]
	);

	return (
		<Table<TVideoWID, IMetadata>
			data={videos}
			checkbox={checkbox}
			columns={COLUMNS}
			hoverStyle={HOVER_STYLE}
			isLoading={isFetching}
			metadata={tableMetadata}
			sort={SORT}
			styles={STYLES}
			handleRowClick={handleRowClick}
		/>
	);
}

export default VideoTable;
