import React, { FunctionComponent } from "react";

import { HeavyText } from "_react/shared/legacy/ui/Text";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { Button } from "_react/shared/legacy/ui/Button";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import {
	EVENT_TYPE_SELECT_CONTENT,
	CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION
} from "_react/shared/_constants/google_analytics";
import { sendReactGAEvent } from "_react/shared/_helpers/google_analytics";

import { AMA, INTL, ONE, PRO, ZERO } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer, TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import { PlayerImage } from "_react/playerpage/header/PlayerImage";
import HeaderFields from "_react/playerpage/header/fields/index";
import HeaderBadges from "_react/playerpage/header/badges";
import { getStyledHeaderText } from "_react/playerpage/header/_helpers";
import {
	StyledHeader,
	PlayerInfo,
	StyledPlayerBar,
	MainPlayerInfo,
	ClassificationButtonStyle
} from "_react/playerpage/header/_styles";

type HeaderProps = {
	colorSchemeGroup: ColorSchemeGroup;
	evaluations?: $TSFixMeEval[] | null;
	playerCombined?: TPlayerPageCombinedPlayer | null;
	viewClassification?: string | null;
	setViewClassification?: (viewClassification?: TPlayerPagePlayerClassification) => void;
	showViewOptions?: boolean;
	setIsBeta?: (isBeta: string | null, isReplaceState?: boolean) => void;
};

export const Header: FunctionComponent<HeaderProps> = ({
	colorSchemeGroup,
	evaluations,
	playerCombined,
	viewClassification,
	setViewClassification = () => null,
	showViewOptions,
	setIsBeta
}) => {
	const playerBarText = getStyledHeaderText(playerCombined);
	const playerClassifications = {
		ama: playerCombined?.playerAmaId ?? null,
		intl: playerCombined?.playerIntlId ?? null,
		pro: playerCombined?.playerProId ?? null
	};

	return (
		<StyledHeader>
			{playerCombined == null && <div className="loading-item" style={{ height: "41px" }} />}
			{playerCombined != null && (
				<StyledPlayerBar colorSchemeGroup={colorSchemeGroup}>
					<HeavyText size="18px" style={{ color: colorSchemeGroup.secondary.text }}>
						{playerBarText}
					</HeavyText>
					{showViewOptions && (
						<>
							{Object.values(playerClassifications).filter(philId => philId != null).length > 1 &&
								!(
									playerClassifications.ama &&
									playerClassifications.pro &&
									!playerClassifications.intl &&
									playerCombined.playerClassification === "AMA"
								) && (
									<>
										{playerCombined?.playerProId && setIsBeta && (
											<Button
												onClick={() => {
													if (viewClassification === PRO) {
														setIsBeta(ONE);
													} else {
														setViewClassification(PRO);
														setIsBeta(ONE, true);
													}
													sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
														contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
														contentId: `${PRO}-new`
													});
												}}
												style={ClassificationButtonStyle}
												colorScheme={colorSchemeGroup.primary}
												title={"PRO (NEW)"}
											/>
										)}
										{playerCombined?.playerProId && setIsBeta && (
											<Button
												disabled={viewClassification === PRO}
												onClick={() => {
													setViewClassification(PRO);
													setIsBeta(ZERO, true);
													sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
														contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
														contentId: PRO
													});
												}}
												style={ClassificationButtonStyle}
												colorScheme={colorSchemeGroup.primary}
												title={`PRO (OLD)`}
											/>
										)}
										{playerCombined?.playerIntlId && (
											<Button
												disabled={viewClassification === INTL}
												onClick={() => {
													setViewClassification(INTL);
													sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
														contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
														contentId: INTL
													});
												}}
												style={ClassificationButtonStyle}
												colorScheme={colorSchemeGroup.primary}
												title={"INTL"}
											/>
										)}
									</>
								)}
							{playerCombined?.playerAmaId && setIsBeta && (
								<>
									<Button
										onClick={() => {
											if (viewClassification === AMA) {
												setIsBeta(ONE);
											} else {
												setViewClassification(AMA);
												setIsBeta(ONE, true);
											}
											sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
												contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
												contentId: `${AMA}-new`
											});
										}}
										style={ClassificationButtonStyle}
										colorScheme={colorSchemeGroup.primary}
										title={"AMA (NEW)"}
									/>
									<Button
										disabled={viewClassification === AMA}
										onClick={() => {
											setViewClassification(AMA);
											setIsBeta(ZERO, true);
											sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
												contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
												contentId: AMA
											});
										}}
										style={ClassificationButtonStyle}
										colorScheme={colorSchemeGroup.primary}
										title={`AMA (OLD)`}
									/>
								</>
							)}
						</>
					)}
				</StyledPlayerBar>
			)}
			<PlayerInfo>
				<MainPlayerInfo>
					{playerCombined == null && (
						<div className="loading-item" style={{ height: 130, width: "90px", borderRadius: "3px" }}></div>
					)}
					{playerCombined != null && (
						<PlayerImage
							height={135}
							player={playerCombined}
							includeRosterStatus={
								viewClassification
									? viewClassification === PRO
									: playerCombined.playerClassification === "PRO"
							}
							style={{
								textAlign: "center",
								marginBottom: "5px",
								boxShadow:
									"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
								width: "90px"
							}}
						/>
					)}
					<HeaderBadges
						colorSchemeGroup={colorSchemeGroup}
						evaluations={evaluations}
						playerCombined={playerCombined}
						viewClassification={viewClassification}
					/>
				</MainPlayerInfo>
				<HeaderFields
					colorSchemeGroup={colorSchemeGroup}
					playerCombined={playerCombined}
					playerType={viewClassification}
				/>
			</PlayerInfo>
		</StyledHeader>
	);
};
