import React, { useState } from "react";
import { $TSFixMe } from "utils/tsutils";
import { Button } from "_react/shared/legacy/ui/Button";
import { Checkbox } from "_react/shared/legacy/ui/Checkbox";
import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import Delete from "_react/shared/legacy/ui/icons/Delete";

type FileUploadContainerProps = {
	children: $TSFixMe;
	clipIds: $TSFixMe;
	handleDeleteSelected: $TSFixMe;
	handleDrop: $TSFixMe;
	handleSelectAll: $TSFixMe;
	handleUploadVideos: $TSFixMe;
	selectedClips: $TSFixMe;
};

function FileUploadContainer({
	children,
	clipIds,
	handleDeleteSelected,
	handleDrop,
	handleSelectAll,
	handleUploadVideos,
	selectedClips
}: FileUploadContainerProps) {
	const [dragging, setDragging] = useState<boolean>(false);

	function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
		e.preventDefault();
		setDragging(true);
	}

	function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
		e.preventDefault();
		setDragging(false);
	}

	function handleFileDrop(e: $TSFixMe) {
		e.preventDefault();
		setDragging(false);
		handleDrop(e);
	}

	return (
		<div style={{ height: "100%", width: "100%" }}>
			<div
				onDragLeave={handleDragLeave}
				onDragOver={handleDragOver}
				onDrop={handleFileDrop}
				style={{
					width: "calc(100% - 20px)",
					marginLeft: "10px",
					marginRight: "10px",
					height: "calc(100% - 40px)",
					border: `${dragging ? "3px" : "1px"} ${dragging ? "solid" : "dashed"} ${
						dragging ? "rgb(70, 130, 190)" : "black"
					}`,
					borderRadius: "15px",
					backgroundColor: dragging ? "rgba(70, 130, 190, .25)" : "inherit",
					display: "inline-block",
					position: "relative",
					boxSizing: "border-box",
					overflowY: "scroll"
				}}
			>
				{clipIds.length > 0 && (
					<React.Fragment>
						<div
							style={{
								position: "absolute",
								left: 25,
								top: 3
							}}
						>
							<div style={{ display: "flex" }}>
								Select All
								<Checkbox
									checked={selectedClips.length === clipIds.length}
									onChange={handleSelectAll}
									style={{ padding: 2, marginLeft: "5px" }}
								/>
							</div>
						</div>
						<div
							onClick={handleDeleteSelected}
							style={{
								display: "flex",
								alignItems: "center",
								position: "absolute",
								right: 25,
								top: 3,
								opacity: selectedClips.length ? 1 : 0.5,
								cursor: "pointer"
							}}
						>
							Delete Selected <Delete />
						</div>
					</React.Fragment>
				)}
				{clipIds.length === 0 && (
					<div
						style={{
							position: "absolute",
							left: "50%",
							top: "calc(50% - 10px)"
						}}
					>
						<div style={{ position: "relative", left: "-50%" }}>
							Drop Videos Here To Upload
							<input multiple onChange={handleFileDrop} style={{ display: "block" }} type="file" />
						</div>
					</div>
				)}
				{children}
			</div>

			<div
				style={{
					marginTop: 5,
					width: "calc(100% - 20px)",
					marginLeft: "10px",
					marginRight: "10px",
					display: "flex",
					justifyContent: "space-between"
				}}
				tabIndex={-1}
			>
				<input multiple onChange={handleFileDrop} tabIndex={-1} type="file" />
				<Button
					title="Upload"
					colorScheme={defaultColorScheme.secondary}
					onClick={handleUploadVideos}
					variant="contained"
				/>
			</div>
		</div>
	);
}

export default FileUploadContainer;
