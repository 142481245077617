import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { ColorScheme, GRAY, GRAYTEXT, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

import { $TSFixMe, Style } from "utils/tsutils";

export type TButtonClickEvent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
export type TButtonClickEventOptional = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

type ButtonProps = {
	colorScheme?: ColorScheme;
	cypress?: string;
	disabled?: boolean;
	onClick?: TButtonClickEvent;
	style?: Style;
	title?: $TSFixMe;
	variant?: string;
	ref?: React.Ref<$TSFixMe> | React.RefObject<$TSFixMe>;
	idName?: string;
	disabledOpacity?: string;
};

const StyledButton = styled.button<ButtonProps>(
	{
		padding: "5px 8px",
		borderRadius: "3px",
		fontWeight: 600,
		fontSize: "0.9rem",
		boxShadow:
			"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
		":focus": {
			outline: 0
		}
	},
	(props: ButtonProps) => ({
		backgroundColor:
			props.variant === "contained" ? (!props.disabled ? props.colorScheme?.color : GRAY) : "transparent",
		color:
			props.variant === "contained"
				? !props.disabled
					? props.colorScheme?.text
					: GRAYTEXT
				: !props.disabled
				? props.colorScheme?.color
				: GRAY,

		border: `1px solid ${!props.disabled ? props.colorScheme?.color : GRAY}`,
		cursor: !props.disabled ? "pointer" : "not-allowed",
		opacity: props.disabled ? props.disabledOpacity : "1.0",
		":hover": {
			backgroundColor: !props.disabled ? props.colorScheme?.hover : GRAY,
			color: props.variant === "contained" ? "" : props.colorScheme?.hoverText,
			border: `1px solid ${!props.disabled ? props.colorScheme?.hover : GRAY}`
		},
		":active": {
			backgroundColor: !props.disabled ? props.colorScheme?.selected : GRAY,
			color: props.variant === "contained" ? "" : props.colorScheme?.selectedText,
			border: `1px solid ${!props.disabled ? props.colorScheme?.selected : GRAY}`
		},
		...props.style
	})
);

export const Button: FunctionComponent<ButtonProps> = ({
	colorScheme = defaultColorScheme.primary,
	idName = "",
	disabled = false,
	cypress = "",
	onClick = () => null,
	style = {},
	title = "",
	variant = "contained",
	disabledOpacity = "1.0"
	// ref = null
}) => {
	return (
		<StyledButton
			colorScheme={colorScheme}
			data-cy={cypress}
			disabled={disabled}
			onClick={onClick}
			style={style}
			type="button"
			variant={variant}
			id={idName}
			disabledOpacity={disabledOpacity}
			// ref={ref}
		>
			{title}
		</StyledButton>
	);
};
