import React, { FC, useState, useEffect, useRef } from "react";
import { Button } from "_react/shared/legacy/ui/Button";
import { Menu } from "_react/shared/legacy/ui/Menu";
import { MenuItem } from "_react/shared/legacy/ui/MenuItem";
import { ColorScheme, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import ArrowDropDown from "_react/shared/legacy/ui/icons/ArrowDropDown";

type TDropdownSelectProps = {
	onSelect: (value: string) => void;
	options: { value: string; display: string }[];
	value: string;
	style?: React.CSSProperties;
	buttonVariant?: "contained" | "outlined";
	buttonStyle?: React.CSSProperties;
	colorScheme?: ColorScheme;
	buttonTitle?: string;
};

export const DropdownSelect: FC<TDropdownSelectProps> = ({
	onSelect,
	options,
	value,
	style = {},
	buttonStyle = {},
	buttonVariant = "contained",
	buttonTitle,
	colorScheme = defaultColorScheme.primary
}) => {
	const [anchorElMenu, setAnchorElMenu] = useState<HTMLDivElement | null>();
	const menuButtonRef = useRef<HTMLDivElement | null>(null);
	const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
	const [display, setDisplay] = useState("");
	useEffect(() => {
		const option = options.filter(opt => opt.value === value);
		if (option.length) setDisplay(option[0].display);
	}, [value, options]);
	const handleSelect = (value: string) => {
		onSelect(value);
		setIsOpenMenu(false);
	};
	useEffect(() => {
		setAnchorElMenu(menuButtonRef.current);
	}, [setAnchorElMenu]);

	const menuButtonContent = (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center"
			}}
		>
			{buttonTitle && <span style={{ paddingRight: 5 }}>{buttonTitle}:</span>}
			<span style={{ textDecoration: "underline" }}>{display}</span>
			<ArrowDropDown />
		</div>
	);

	return (
		<React.Fragment>
			<div ref={menuButtonRef} style={style}>
				<Button
					colorScheme={colorScheme}
					onClick={() => setIsOpenMenu(!isOpenMenu)}
					style={buttonStyle}
					title={menuButtonContent}
					variant={buttonVariant}
				/>
			</div>
			<Menu anchorEl={anchorElMenu} onClose={() => setIsOpenMenu(false)} open={isOpenMenu}>
				{options.map(({ value, display }, idx) => (
					<MenuItem handleClick={() => handleSelect(value)} key={idx}>
						{display}
					</MenuItem>
				))}
			</Menu>
		</React.Fragment>
	);
};
