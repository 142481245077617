import React, { SyntheticEvent, useEffect, useState, useCallback } from "react";
import { useCookies } from "react-cookie";

import VideoControls from "_react/video/shared/VideoControls";
import { TOption } from "_react/inputs";
import { $TSFixMe } from "utils/tsutils";

type TVideoPlayer = {
	views?: Array<TOption<string>>;
	handleExitVideoScreen?: Function;
	handleOtherKeyCode?: Function;
	disableKeyDown?: boolean;
	disableWheelControl?: boolean;
	handlePlayNext?: (event: SyntheticEvent<HTMLVideoElement, Event>) => void;
	handlePlayPrev?: Function;
	disableAutoplayNext?: boolean;
	src?: string;
	srcViews?: { [index: string]: string };
	// If you use isInset, video controls don't display in fullscreen
	isInset?: boolean;
	videoPlayerId: string;
	videoContainerId: string;
};

function VideoPlayer({
	handleExitVideoScreen,
	handleOtherKeyCode,
	disableKeyDown,
	disableWheelControl,
	handlePlayNext,
	handlePlayPrev,
	disableAutoplayNext,
	src: srcRaw,
	srcViews,
	isInset,
	videoPlayerId,
	videoContainerId,
	views
}: TVideoPlayer) {
	const [seekBarValue, setSeekBarValue] = useState(0);
	const [rotate, setRotate] = useState(0);
	const [mutedCookie, setMutedCookie] = useCookies(["muted"]);
	const muted = mutedCookie.muted != null ? mutedCookie.muted === "muted" : true;
	const setMuted = (newMuted: boolean) => {
		setMutedCookie("muted", newMuted ? "muted" : "notmuted");
	};
	const [viewsThatExist, setViewsThatExist] = useState<Array<TOption<string>>>();
	useEffect(() => {
		if (views && srcViews) {
			setViewsThatExist(views.filter(view => srcViews[view.value] != null));
		}
	}, [views, srcViews]);

	const [viewIndex, setViewIndex] = useState<string | undefined>();

	useEffect(() => {
		setViewIndex(
			srcViews != null && viewsThatExist
				? viewsThatExist.find(view => srcViews[view.value] != null)?.value ?? undefined
				: undefined
		);
	}, [viewsThatExist, srcViews]);

	const src: string = srcRaw ?? (srcViews != null ? srcViews[viewIndex ?? ""] : "");

	const getVideoPlayer = useCallback(() => {
		return document.getElementById(videoPlayerId) as HTMLVideoElement;
	}, [videoPlayerId]);

	const rewindVid = useCallback(
		(factor = 1) => {
			const vid = getVideoPlayer();
			if (vid) {
				vid.pause();
				vid.currentTime -= factor * (1 / 29.97);
			}
		},
		[getVideoPlayer]
	);

	const forwardVid = useCallback(
		(factor = 1) => {
			const vid = getVideoPlayer();
			if (vid) {
				vid.pause();
				vid.currentTime += factor * (1 / 29.97);
			}
		},
		[getVideoPlayer]
	);

	const handleWheel = useCallback(
		(event: WheelEvent) => {
			if (!disableWheelControl) {
				event.preventDefault();
				event.stopPropagation();
				if (event.deltaY < 0) {
					const factor = Math.round(event.deltaY / 120) ? Math.round(event.deltaY / 120) : -1;
					rewindVid(factor * -1);
				} else {
					const factor = Math.round(event.deltaY / 120) ? Math.round(event.deltaY / 120) : 1;
					forwardVid(factor);
				}
			}
		},
		[disableWheelControl, rewindVid, forwardVid]
	);

	const onTimeChange = useCallback(() => {
		const vid = getVideoPlayer();
		if (vid) {
			// && this._ismounted) {
			const newSeekBarValue = (100 / vid.duration) * vid.currentTime;
			setSeekBarValue(newSeekBarValue);
		}
	}, [setSeekBarValue, getVideoPlayer]);

	const handleSeek = (seekVal: number) => {
		const vid = getVideoPlayer();
		if (vid) {
			const newSeekBarValue = (seekVal / 100.0) * vid.duration;
			setSeekBarValue(seekVal);
			vid.currentTime = newSeekBarValue;
		}
	};

	const handleRotate = useCallback(() => {
		setRotate(rotate + 90);
	}, [setRotate, rotate]);

	const handleResetRotate = () => setRotate(0);

	// Need to disable this when the video player doesn't take up the whole screen and the keyboard is used elsewhere on the page
	const handleKeyDown = useCallback(
		(event: $TSFixMe) => {
			//(event: React.KeyboardEvent<HTMLVideoElement>) => {
			event.preventDefault();
			const vid = getVideoPlayer();
			if (vid && !disableKeyDown) {
				if (event.keyCode === 40 && handlePlayNext) {
					handlePlayNext(event);
				} else if (event.keyCode === 38 && handlePlayPrev) {
					handlePlayPrev();
				} else if (event.keyCode === 27 && handleExitVideoScreen) {
					vid.pause();
					handleExitVideoScreen();
				} else if (event.keyCode === 37) {
					rewindVid();
				} else if (event.keyCode === 39) {
					forwardVid();
				} else if (event.keyCode === 32) {
					if (vid.paused) {
						vid.play();
					} else {
						vid.pause();
					}
				} else if (event.keyCode === 82) {
					handleRotate();
				} else {
					if (handleOtherKeyCode) {
						handleOtherKeyCode(event.keyCode);
					}
				}
			}
		},
		[
			getVideoPlayer,
			disableKeyDown,
			handlePlayNext,
			handlePlayPrev,
			handleExitVideoScreen,
			rewindVid,
			forwardVid,
			handleRotate,
			handleOtherKeyCode
		]
	);

	useEffect(() => {
		if (!disableKeyDown) {
			document.addEventListener("keydown", handleKeyDown);
		}
		const video = getVideoPlayer();
		if (video) {
			video.addEventListener("timeupdate", onTimeChange);
			video.addEventListener("wheel", handleWheel, { passive: false });
		}
		return () => {
			if (!disableKeyDown) {
				document.removeEventListener("keydown", handleKeyDown);
			}
			if (video) {
				video.removeEventListener("wheel", handleWheel);
			}
		};
	}, [disableKeyDown, getVideoPlayer, handleKeyDown, videoPlayerId, handleWheel, onTimeChange]);

	useEffect(() => {
		handleResetRotate();
	}, [src]);

	return (
		<div
			className="hideScroll"
			style={{ height: "100%", overflow: "hidden", width: "100%", flexDirection: "column", display: "flex" }}
			id={videoContainerId}
		>
			<video
				muted={muted}
				playsInline={true}
				autoPlay={true}
				height="100%"
				id={videoPlayerId}
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onEnded={disableAutoplayNext ? () => {} : handlePlayNext}
				onKeyDown={handleKeyDown}
				preload="auto"
				src={src}
				style={{
					transform: `rotate(${rotate}deg)`,
					objectFit: "contain",
					height: "100%"
				}}
				width="100%"
			/>
			<VideoControls
				handleExitVideoScreen={handleExitVideoScreen}
				handleKeyDown={handleKeyDown}
				handlePlayNext={handlePlayNext}
				handlePlayPrev={handlePlayPrev}
				handleRotate={handleRotate}
				isInset={isInset}
				videoContainerId={videoContainerId}
				handleSeek={(seekVal: number) => handleSeek(seekVal)}
				seekBarValue={seekBarValue}
				videoPlayer={getVideoPlayer()}
				views={viewsThatExist}
				viewIndex={viewIndex}
				setViewIndex={setViewIndex}
				srcViews={srcViews}
				muted={muted}
				setMuted={setMuted}
			/>
		</div>
	);
}

export default VideoPlayer;
