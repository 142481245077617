import {
	IArsenalScoresParamsApiResponse,
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import { INormalDistributionStep } from "_react/shared/data_models/dataviz/_types";
import { PITCH_GROUP_OTH } from "_react/shared/_constants/pitch_types";
import {
	ICON_CHECK,
	ICON_WARNING,
	ICON_DANGEROUS
} from "_react/shared/ui/presentation/components/GradeThresholdIcon/_constants";
import { TGradeThresholdIconShape } from "_react/shared/ui/presentation/components/GradeThresholdIcon/_types";
import { THRESHOLD_GRADE_STUFF } from "_react/shared/data_models/arsenal_scores/_constants";

export const getAllStuffRa9NormalDistributionsMinMax = (
	arsenalScoresParams?: Array<IArsenalScoresParamsApiResponse> | null
) => {
	return arsenalScoresParams?.reduce(
		(minMax: { min?: number; max?: number }, pitchTypeDistribution: IArsenalScoresParamsApiResponse) => {
			const distribution = pitchTypeDistribution?.stuffRa9NormalDistribution;
			if (distribution && pitchTypeDistribution.pitchTypeGrouping !== PITCH_GROUP_OTH) {
				const distributionValues = distribution.map((step: INormalDistributionStep) => step.value);
				const min = Math.min(...distributionValues);
				const max = Math.max(...distributionValues);
				if (minMax.min === undefined || min < minMax.min) minMax.min = min;
				if (minMax.max === undefined || max > minMax.max) minMax.max = max;
			}
			return minMax;
		},
		{}
	);
};

export const getThresholdLevel = (
	sampleSize?: number | null,
	threshold?: number
): TGradeThresholdIconShape | undefined => {
	if (threshold && sampleSize) {
		if (sampleSize > threshold) return ICON_CHECK;
		if (sampleSize > threshold / 2) return ICON_WARNING;
		return ICON_DANGEROUS;
	}
	return undefined;
};

export const getStuffThresholdPitchTypesToDisplay = (
	batsFilter: string,
	throwsFilter?: string,
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null,
	playerSeasonArsenalScores?: Array<IPlayerSeasonArsenalScoresSchema>
): Array<string> | undefined => {
	const stuffThreshold = arsenalScoresThreshold?.find(
		(threshold: IArsenalScoresThresholdApiResponse) => threshold.grade === THRESHOLD_GRADE_STUFF
	)?.value;

	return playerSeasonArsenalScores?.reduce(
		(pitchTypes: Array<string>, arsenalScore: IPlayerSeasonArsenalScoresSchema) => {
			// If we're not using the threshold, we want to include all pitch types otherwise only include the pitch types that are above the threshold
			if (
				!stuffThreshold ||
				(arsenalScore.bats === batsFilter &&
					(throwsFilter === undefined || throwsFilter === arsenalScore.throws) &&
					arsenalScore.total &&
					arsenalScore.total > stuffThreshold / 2)
			)
				pitchTypes.push(arsenalScore.pitchType);
			return pitchTypes;
		},
		[]
	);
};

export const getPlayerSeasonArsenalScoresByThrowsFilterStatement = (
	scores: IPlayerSeasonArsenalScoresSchema,
	throwsFilter?: string,
	player?: { throws: string | null }
) => {
	if (throwsFilter) return scores.throws === throwsFilter;
	if (player) return scores.throws === player.throws;
	return true;
};
