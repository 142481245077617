// Environments to use google analytics
export const GOOGLE_ANALYTICS_REACT_APP_ENVS = ["prod"];

// Google analytics event types
// https://developers.google.com/tag-platform/gtagjs/reference/events
export const EVENT_TYPE_SELECT_CONTENT = "select_content";

// Select content event type - content types
export const CONTENT_TYPE_PLAYER_PAGES_TAB = "Player Page Tab";
export const CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION = "Player Page Classification";
