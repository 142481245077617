import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function BaseballBat(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 0 18 22" {...props}>
			<path
				fill="currentColor"
				d="M16.6959 1.3215C16.4045 1.02688 16.0097 0.86142 15.5981 0.86142C15.1865 0.86142 14.7916 1.02688 14.5002 1.3215L10.4588 5.4164C9.17317 6.71959 7.86861 8.70798 5.96918 10.9704C4.99845 12.1273 3.48656 13.7926 2.39711 14.6419C1.7491 15.1405 1.4916 14.7097 0.971242 15.2319C0.897375 15.3053 0.854361 15.4049 0.851182 15.5097C0.832091 15.8118 1.06173 16.2075 1.53374 16.6858C1.8166 16.9725 2.29579 17.4029 2.69442 17.3775C2.79786 17.3743 2.89607 17.3307 2.96854 17.2558C3.49186 16.7204 3.03732 16.464 3.59221 15.7574C4.53876 14.5666 6.02233 13.1835 7.17405 12.1912C9.40956 10.264 11.3674 8.94602 12.6549 7.6417L16.6959 3.54646C16.9866 3.25118 17.1499 2.8511 17.1499 2.43398C17.1499 2.01687 16.9866 1.61679 16.6959 1.3215ZM6.11661 11.3569L6.79254 12.0419C6.61619 12.1951 6.44366 12.3475 6.27494 12.4989L5.66555 11.8814C5.81456 11.711 5.96491 11.5361 6.11661 11.3569ZM5.42541 12.154L6.0061 12.7424C5.83783 12.8962 5.67146 13.0505 5.50882 13.2041L5.17311 12.4348C5.26062 12.3382 5.34472 12.2446 5.42541 12.154ZM4.4792 13.1809C4.62078 13.0328 4.76228 12.8824 4.90662 12.7261L5.23094 13.4693C5.06359 13.6306 4.89691 13.7939 4.73286 13.9579L4.4792 13.1809ZM3.39274 15.4279L2.77517 14.8021C2.95585 14.6517 3.14115 14.4897 3.32839 14.3187L3.86973 14.8673C3.70107 15.0568 3.54132 15.2445 3.39274 15.4279ZM4.11359 14.5984L3.59365 14.0716C3.74395 13.9288 3.89561 13.78 4.04621 13.6273C4.09535 13.5776 4.14543 13.5264 4.19591 13.4747L4.44786 14.2464C4.33442 14.3631 4.22248 14.4808 4.11359 14.5984Z"
			/>
		</Icon>
	);
}
