import qs from "query-string";
import { useCallback, useEffect, useReducer, useRef, useState, useMemo } from "react"; // eslint-disable-line no-unused-vars
import { useLocation } from "react-router-dom";
import { isEmpty, omit } from "utils/helpers";
import axios from "_redux/_utils/_axios";

export function useSearchParam(searchParam) {
	return useQueryStringValue(searchParam)[0];
}

export function useRoute() {
	const location = useLocation();
	return location.pathname;
}

export const getCancelSource = () => {
	const CancelToken = axios.CancelToken;
	return CancelToken.source();
};

const axiosCallWrapper = axiosMethod => {
	// Helper function to get the axios promise
	return (path, cancelToken, postOrPutData) => {
		if (axiosMethod === "post") {
			return axios.post(path, postOrPutData, { cancelToken });
		} else if (axiosMethod === "put") {
			return axios.put(path, postOrPutData, { cancelToken });
		} else if (axiosMethod === "get") {
			return axios.get(path, { cancelToken });
		} else if (axiosMethod === "delete") {
			return axios.delete(path, { cancelToken });
		}
	};
};

// axios reducer things (ensure state changes are determinstic and not affect by batching/ordering)
const defaultAxiosState = (initialPath, placeholderData, initialPostObj) => ({
	params: {
		path: initialPath,
		postObj: initialPostObj,
		isForceRequest: false
	},
	cancelFn: null,
	responseData: placeholderData,
	flags: {
		isInProgress: false,
		isSucceeded: false,
		isFailed: false
	}
});

// actions
// Could make set obj and set path and obj force as needed
const SET_PATH = "SET_PATH";
const SET_PATH_FORCE = "SET_PATH_FORCE";
const SET_POST_OBJ = "SET_POST_OBJ";
const SET_PATH_AND_OBJ = "SET_PATH_AND_OBJ";

const UNFORCE = "UNFORCE";

const AXIOS_CALL = "AXIOS_CALL";
const axiosCallFlags = { isInProgress: true, isFailed: false, isSucceeded: false };

const AXIOS_SUCCESS = "AXIOS_SUCCESS";
const axiosSuccessFlags = { isInProgress: false, isFailed: false, isSucceeded: true };

const AXIOS_FAILURE = "AXIOS_FAILURE";
const axiosFailFlags = { isInProgress: false, isFailed: true, isSucceeded: false };

const axiosReducer = (state, action) => {
	switch (action.type) {
		case SET_PATH:
			return { ...state, params: { ...state.params, path: action.payload, isForceRequest: false } };
		case SET_PATH_FORCE:
			return { ...state, params: { ...state.params, path: action.payload, isForceRequest: true } };
		case SET_POST_OBJ:
			return { ...state, params: { ...state.params, postObj: action.payload, isForceRequest: false } };
		case SET_PATH_AND_OBJ:
			return {
				...state,
				params: {
					...state.params,
					path: action.payload.path,
					postObj: action.payload.postObj,
					isForceRequest: false
				}
			};
		case AXIOS_CALL:
			return {
				...state,
				cancelFn: action.payload,
				flags: axiosCallFlags,
				params: { ...state.params, isForceRequest: false }
			};
		case AXIOS_SUCCESS:
			return {
				...state,
				cancelFn: null,
				flags: axiosSuccessFlags,
				responseData: action.payload
			};
		case AXIOS_FAILURE:
			return {
				...state,
				cancelFn: null,
				flags: axiosFailFlags,
				responseData: action.payload
			};
		case UNFORCE:
			return { ...state, params: { ...state.params, isForceRequest: false } };
		default:
			return { ...state };
	}
};

export const useAxios = (initialPath, placeholderData, axiosMethod = "get", initPostObj) => {
	// Function to make an API request (or any REST request)
	const axiosCall = useMemo(() => axiosCallWrapper(axiosMethod), [axiosMethod]);
	const [state, dispatch] = useReducer(axiosReducer, defaultAxiosState(initialPath, placeholderData, initPostObj));
	const { path, postObj, isForceRequest } = state.params;
	const { isInProgress, isSucceeded, isFailed } = state.flags;
	const { responseData, cancelFn } = state;

	// Canceling
	const isCanceled = useRef(false);
	const cancelRef = useRef(cancelFn);
	cancelRef.current = cancelFn;
	useEffect(() => {
		return () => {
			isCanceled.current = true;
			if (cancelRef.current != null) cancelRef.current();
		};
	}, []);
	const dispatchWrapper = useCallback((type, payload) => {
		if (!isCanceled.current) {
			dispatch({ type, payload });
		}
	}, []);

	const shouldGet = path && axiosMethod === "get";
	const shouldPostOrPut = path && postObj && (axiosMethod === "post" || axiosMethod === "put");
	const shouldDelete = path && axiosMethod === "delete";

	const sendRequest = useCallback(() => {
		const source = getCancelSource();
		dispatchWrapper(AXIOS_CALL, source.cancel);
		// Make the axios call
		axiosCall(path, source.token, postObj)
			.then(response => {
				dispatchWrapper(AXIOS_SUCCESS, response.data);
			})
			.catch(() => {
				// TODO: check if is from cancellation and add separate state
				dispatchWrapper(AXIOS_FAILURE, placeholderData);
			});
	}, [dispatchWrapper, axiosCall, path, placeholderData, postObj]);

	// sending request. Make sure we only send 1 request if force was set to true
	useEffect(() => {
		if (!isForceRequest && (shouldGet || shouldPostOrPut || shouldDelete)) {
			sendRequest();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path, postObj]);

	useEffect(() => {
		if (isForceRequest && (shouldGet || shouldPostOrPut || shouldDelete)) {
			sendRequest();
		} else if (isForceRequest) {
			dispatchWrapper(UNFORCE, null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isForceRequest]);

	// TODO: force should be an optional param instead of an extra functions

	const setPath = useCallback(
		path => {
			dispatchWrapper(SET_PATH, path);
		},
		[dispatchWrapper]
	);

	const setPathForce = useCallback(
		path => {
			dispatchWrapper(SET_PATH_FORCE, path);
		},
		[dispatchWrapper]
	);

	const setPostObj = useCallback(
		postObj => {
			dispatchWrapper(SET_POST_OBJ, postObj);
		},
		[dispatchWrapper]
	);

	const setPathAndObj = useCallback(
		(path, postObj) => {
			dispatchWrapper(SET_PATH_AND_OBJ, { path, postObj });
		},
		[dispatchWrapper]
	);

	return {
		isInProgress,
		isSucceeded,
		isFailed,
		cancelFn,
		responseData,
		setPath,
		setPostObj,
		setPathAndObj,
		setPathForce
	};
};

export const useFetch = (initialPath, placeholderData) => {
	// GET requests - sends request when path is defined
	return useAxios(initialPath, placeholderData, "get");
};

export const usePost = (initialPath, initPostObj, placeholderData) => {
	// POST requests - sends request when both a path and post obj are defined
	return useAxios(initialPath, placeholderData, "post", initPostObj);
};

export const usePut = (initialPath, initPutObj, placeholderData) => {
	// PUT requests - sends request when both a path and put obj are defined
	return useAxios(initialPath, placeholderData, "put", initPutObj);
};

export const useDelete = initialPath => {
	// DELETE requests - sends request when path is defined
	return useAxios(initialPath, {}, "delete");
};

export const getQueryStringValue = (key, queryString = window.location.search) => {
	const values = qs.parse(queryString);
	return values[key];
};

export const setQueryStringWithoutPageReload = (qsValue, isReplaceState = false) => {
	// First, check if query string has changed
	if (qsValue !== window.location.search) {
		// If so, push to history
		window.postMessage("queryParameterChange", "*");
		const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + qsValue;
		isReplaceState
			? window.history.replaceState({ path: newurl }, "", newurl)
			: window.history.pushState({ path: newurl }, "", newurl);
	}
};

export const setQueryStringValue = (key, value, queryString = window.location.search, isReplaceState = false) => {
	let values = qs.parse(queryString);
	values = { ...values, [key]: value };
	Object.keys(values).forEach(curKey => {
		if (values[curKey] == null || (Array.isArray(values[curKey]) && !values[curKey].length)) {
			values = omit(values, [curKey]);
		}
	});
	const newQsValue = qs.stringify(values);
	if (isEmpty(newQsValue)) {
		setQueryStringWithoutPageReload(``, isReplaceState);
	} else {
		setQueryStringWithoutPageReload(`?${newQsValue}`, isReplaceState);
	}
};

export function useQueryString(key, initialValue) {
	// Get and set initial values
	let queryStringVal = getQueryStringValue(key);
	const [value, setValue] = useState(queryStringVal || initialValue);

	// If there is a default value, but no value in the URL, set the value in the URL
	if (queryStringVal == null && initialValue != null) {
		// For default values, uses isReplaceState = true in setQueryStringValue()
		// Using isReplaceState = false would create a loop when using the back button
		setQueryStringValue(key, initialValue, undefined, true);
		queryStringVal = getQueryStringValue(key);
	}

	const onSetValue = useCallback(
		(newValue, isReplaceState = false) => {
			setQueryStringValue(key, newValue, undefined, isReplaceState); // Note: This MUST be called before setValue
			setValue(newValue);
		},
		[setValue, key]
	);

	// Listen for when the queryStringVal changes outside of this hook, and update value accordingly

	// With re-rendering
	useEffect(() => {
		if (queryStringVal !== value) {
			onSetValue(queryStringVal);
		}
	}, [queryStringVal, value, onSetValue]);

	// Without re-rendering
	const handleUrlChange = useCallback(
		(e: { data: string }) => {
			const queryStringVal = getQueryStringValue(key);
			if (e.data === "queryParameterChange") {
				if (queryStringVal !== value) {
					onSetValue(queryStringVal, value == null);
				}
			}
		},
		[key, value, onSetValue]
	);

	useEffect(() => {
		// Listen for URL changes
		window.addEventListener("message", handleUrlChange);
		// Clean up function to remove the event listener
		return () => {
			window.removeEventListener("message", handleUrlChange);
		};
	}, [handleUrlChange]);

	return [value, onSetValue];
}

export function useQueryStringValue(key, initialValue, parseNumber = false) {
	// Wrapper for the above useQueryString function but handles extracting the value from the array and optional number parsing
	const [value, onSetValue] = useQueryString(key, initialValue);
	let extractedValue = value;
	if (extractedValue != null && parseNumber) extractedValue = parseInt(value, 10);
	return [extractedValue, onSetValue];
}

export const useFetchSearchParam = (searchParam, makePath) => {
	const [param, setParam] = useQueryString(searchParam);

	const { isInProgress, isSucceeded, isFailed, responseData, setPath } = useFetch();

	const path = makePath(param);

	useEffect(() => {
		if (param) {
			setPath(path);
		}
	}, [param, setPath, path]);

	const refresh = () => {
		setPath(null);
		// Not psyched about this, but it works and I can't think of a workaround...
		setTimeout(() => setPath(path), 500);
	};

	return { isInProgress, isSucceeded, isFailed, responseData, refresh, setParam, param };
};
