import React, { CSSProperties } from "react";

import { SummarySection, EvalAttr } from "_react/evals/list/_styles";
import { TEvalProHit } from "_react/evals/list/_types";

export type TEvalProHitSummaryProps = {
	evaluation: TEvalProHit;
	isShowSummaries?: boolean;
	style?: CSSProperties;
};

export const EvalProHitSummary = ({ evaluation, isShowSummaries = true, style }: TEvalProHitSummaryProps) => {
	return (
		<SummarySection style={style}>
			<EvalAttr>
				<b>Snapshot:</b> {evaluation.current_snapshot}
				{isShowSummaries && (
					<>
						<br />
						<b>Offense:</b> {evaluation.offense_summary}
						<br />
						<b>Defense:</b> {evaluation.defense_summary}
					</>
				)}
			</EvalAttr>
		</SummarySection>
	);
};
