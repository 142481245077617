import { combineReducers } from "redux";
import agent from "_redux/Agent/_index";
import error from "_redux/Error/_index";
import betaStats from "_redux/StatsAggregated/betaStats.r";
import team from "_redux/Team/_index";

export default combineReducers({
	agent,
	betaStats,
	error,
	team
});
