import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { getTeamName } from "_react/shared/_helpers/team";
import { IPlayer } from "_react/shared/_types/schema/draftboard";
import { getAgeFromBirthDate } from "utils/helpers";
import { $TSFixMe, TCustomPlayerClassification, AMA } from "utils/tsutils";

import playerVideosMachine, { UPDATE_VIDEOS, SELECT_NEXT_OR_PREV, UPDATE_SELECTED } from "_react/video/shared/_machine";
import { TVideoWDPL } from "_react/video/shared/_types";
import PlaylistSidebar from "_react/video/shared/PlaylistSidebar";
import ShowHide from "_react/video/shared/ShowHide";
import VideoPlayer from "_react/video/shared/VideoPlayer";

const AMA_VIDEO_PLAYER_ID = "amaVideo";
const INTL_VIDEO_PLAYER_ID = "intlVideo";

type TPlayerVideos = {
	videos: TVideoWDPL[];
	handleExitVideoScreen: Function;
	player: TPlayerPageCombinedPlayer | IPlayer;
	playerClassification: TCustomPlayerClassification;
	initialScoutAssigned?: $TSFixMe;
	initialTeam?: $TSFixMe;
};

function PlayerVideos({ videos: videosProp, handleExitVideoScreen, player, playerClassification }: TPlayerVideos) {
	const [current, send] = useMachine(playerVideosMachine);
	useEffect(() => {
		send({ type: UPDATE_VIDEOS, data: videosProp });
	}, [videosProp, send]);
	const { videos, selectedVideoIdx } = current.context;
	const videoSelected = selectedVideoIdx != null ? videos[selectedVideoIdx] : null;

	const initialX = window.pageXOffset || document.documentElement.scrollLeft;
	const initialY = window.pageYOffset || document.documentElement.scrollTop;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handlePlayNextOrPrev = (isNext: boolean) => {
		send({ type: SELECT_NEXT_OR_PREV, payload: { isNext } });
	};

	const exitVideoScreen = () => {
		window.scrollTo(initialX, initialY);
		handleExitVideoScreen();
	};

	const handleVideoSelected = (idx: number) => {
		send({ type: UPDATE_SELECTED, payload: { idx } });
	};

	const teamFormatted = getTeamName(player.team, "no team information");

	const playerBirthCountry = player.hasOwnProperty("birthCountry")
		? (player as TPlayerPageCombinedPlayer).birthCountry
		: "N/A";

	const playerYearOrCountry = playerClassification
		? player.amaProfile?.schoolClass ?? playerBirthCountry
		: playerBirthCountry;

	const scoutFormatted = player.amaProfile?.hasOwnProperty("scoutAssigned")
		? (player as TPlayerPageCombinedPlayer).amaProfile?.scoutAssigned?.lastName ?? "no_scout_assigned"
		: "N/A";

	if (!videoSelected) {
		return <div />;
	}

	return (
		<div
			className="hideScroll"
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				height: "100%",
				width: "100%",
				display: videos.length ? "block" : "none",
				zIndex: 11001,
				backgroundColor: "black",
				overflow: "hidden"
			}}
		>
			<div
				style={{
					textAlign: "center",
					height: "auto",
					backgroundColor: "lightGray",
					zIndex: 11002
				}}
			>
				<span style={{ margin: 5, fontSize: 18 }}>
					{`
                            ${player.firstName} ${player.lastName} (${player.position}) |
                            ${getAgeFromBirthDate(player.birthDate)} |
                            ${teamFormatted} |
                            ${playerYearOrCountry} |
                            ${scoutFormatted}
                        `}
				</span>
			</div>
			<ShowHide height="85%" hoverText="Playlist" leftRight="left" top="calc(5% + 5px)" width="300px">
				<PlaylistSidebar selectVideo={handleVideoSelected} videos={videos} videoSelected={videoSelected} />
			</ShowHide>
			<VideoPlayer
				handleExitVideoScreen={exitVideoScreen}
				handlePlayNext={() => handlePlayNextOrPrev(true)}
				handlePlayPrev={() => handlePlayNextOrPrev(false)}
				src={videoSelected.type === "media" ? videoSelected.gcsPublicUrl ?? undefined : videoSelected.url}
				videoContainerId={"rockyVideoContainer"}
				videoPlayerId={playerClassification === AMA ? AMA_VIDEO_PLAYER_ID : INTL_VIDEO_PLAYER_ID}
			/>
		</div>
	);
}

export default PlayerVideos;
