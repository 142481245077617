import React, { FC, useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

import { $TSFixMeComponent } from "utils/tsutils";
import { useOnClickOutside } from "_react/_hooks";

export const applySidebarIconStyle = (Comp: $TSFixMeComponent) => styled(Comp)`
	width: 64px;
	height: 24px;
	display: flex;
	justify-content: center;
	flex-shrink: 0;
	opacity: 0.54;
	transition: 0.3s opacity;
	line-height: 0;
	margin-top: -2px;
	vertical-align: middle;
`;

export const applySidebarPinnedIconStyle = (Comp: $TSFixMeComponent) => styled(Comp)`
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	flex-shrink: 0;
	line-height: 0;
	vertical-align: middle;
	color: white;
	fill: white;
`;

export const applySidebarPinIconStyle = (Comp: $TSFixMeComponent) => styled(Comp)`
	height: 24px;
	justify-content: center;
	opacity: 0.54;
	transition: 0.3s opacity;
	line-height: 0;
	margin-top: -2px;
	vertical-align: middle;
	display: flex;
`;

export const SidebarNavText = styled("div")`
	color: black;
	font-family: "roboto";
	font-size: 15px;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 20px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	flex-grow: 1;
`;

export const SidebarListItem = styled.li<{ nestedLevel: number }>(({ nestedLevel = 0 }) => ({
	display: "flex",
	height: "44px",
	width: "100%",
	flexShrink: 0,
	flexDirection: "row",
	alignItems: "center",
	borderBottom: 0,
	position: "relative",
	border: 0,
	color: "inherit",
	paddingRight: "8px",
	cursor: "pointer",
	textDecoration: "none",
	boxSizing: "border-box",
	userSelect: "none",
	"&:hover, focus": {
		color: "#fff",
		background: `rgba(0, 0, 0, ${(nestedLevel + 1) * 0.1})`
	},
	background: `rgba(0, 0, 0, ${nestedLevel * 0.06})`,
	paddingLeft: `${nestedLevel * 15}px`
}));

export const SidebarListItemContents = styled("div")`
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	flex: 1;
	align-items: center;
	min-width: 0px;
`;

export type SidebarMenuProps = {
	show: boolean;
	shouldAnimate?: boolean;
};

export const sidebarOpen = keyframes`
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0%); }
`;

export const sidebarClose = keyframes`
    0% { transform: translateX(0%); }
	100% { transform: translateX(-100%); }
`;

export const SidebarMenu = styled("div")<SidebarMenuProps>`
	height: 100%;
	background: white;
	border-radius: 0px 3px 3px 0px;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	z-index: 1000;
	position: fixed;
	transform: translateX(-100%);
	width: 260px;
	animation: ${props => (props.shouldAnimate ? (props.show ? sidebarOpen : sidebarClose) : "")} 0.25s forwards;
	overflow: scroll;
	top: 0;
	& > ul {
		list-style: none;
		padding: 0px;
		margin: 0px;
	}
`;

type WrapperProps = {
	show: boolean;
	children: React.ReactNode;
	clickOutsideDisabled?: boolean;
	onClose: () => void;
};
const SidebarMenuWrapperComponent: FC<WrapperProps> = ({ show, children, clickOutsideDisabled, onClose }) => {
	const [shouldAnimate, setShouldAnimate] = useState(false);
	useEffect(() => {
		if (!shouldAnimate && show) setShouldAnimate(true);
	}, [show, shouldAnimate]);
	const menuRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(menuRef, onClose, clickOutsideDisabled);
	return (
		<SidebarMenu ref={menuRef} shouldAnimate={shouldAnimate} show={show}>
			{children}
		</SidebarMenu>
	);
};

export const SidebarMenuWrapper = React.memo(SidebarMenuWrapperComponent);
