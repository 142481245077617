import styled from "@emotion/styled";

export const SelectWrapper = styled.div({
	color: "black",
	width: "200px"
});

export const OptionWrapper = styled.div({
	marginLeft: "5px"
});
