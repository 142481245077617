import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const variantLine = definePartsStyle({
	tablist: {
		color: "gray.400"
	},
	tab: {
		fontWeight: "semibold",
		paddingLeft: "1px !important",
		_selected: {
			color: "maroon",
			fontWeight: "semibold"
		},
		_hover: {
			color: "maroon",
			fontWeight: "semibold"
		}
	},
	tabpanel: {
		padding: "0"
	}
});

const variants = {
	line: variantLine
};

export const tabsTheme = defineMultiStyleConfig({ variants });
