import React from "react";
import { Box, Flex, HStack } from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";

const TabsWithSidebarLoading = () => {
	const tabsLoadingContent = useBreakpointValue({
		base: (
			<>
				<Box className="loading-item-transparent" width="100%" height="8xl" bg="gray.50"></Box>
				<Box
					className="loading-item-transparent"
					height="80"
					width="100%"
					bg="white"
					borderBottom="2px solid"
					borderColor="gray.200"
				></Box>
				<Box className="loading-item-transparent" height="100%" width="100%" bg="white"></Box>
			</>
		),
		md: (
			<>
				<Flex
					className="loading-item-transparent"
					height="20"
					justify="end"
					bg="white"
					borderBottom="2px solid"
					borderColor="gray.200"
					gap="2"
				>
					<Box className="loading-item-transparent" height="100%" width="100%" bg="white"></Box>
					<Box className="loading-item-transparent" height="100%" width="lg" backgroundColor="gray.50"></Box>
				</Flex>
				<HStack h="100%">
					<Box className="loading-item-transparent" height="100%" width="100%" bg="white"></Box>
					<Box className="loading-item-transparent" height="100%" width="lg" bg="gray.50"></Box>
				</HStack>
			</>
		)
	});

	return <>{tabsLoadingContent}</>;
};

export default TabsWithSidebarLoading;
