import styled from "@emotion/styled";
import { TRockyNoteStructure } from "_react/shared/data_models/notes/_types";
import { ColorScheme, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

// DraftJS Customizations
export const LinkStyles = {
	root: {
		fontFamily: "'Georgia', serif",
		padding: 20,
		width: 600
	},
	buttons: {
		marginBottom: 10
	},
	urlInputContainer: {
		marginBottom: 10
	},
	urlInput: {
		fontFamily: "'Georgia', serif",
		marginRight: 10,
		padding: 3
	},
	editor: {
		border: "1px solid #ccc",
		cursor: "text",
		minHeight: 80,
		padding: 10
	},
	button: {
		marginTop: 10,
		textAlign: "center"
	},
	link: {
		color: "#3b5998",
		textDecoration: "underline"
	}
};

// Notes Styles
export const CHECK_STYLE = { marginRight: "5px", color: "black" };
export const CheckPlaceholder = styled.span({ height: "24px", width: "29px" });

export const EditingFieldWrapper = styled.div<{
	structure: TRockyNoteStructure;
	contentLength: number;
	showingEditUI: boolean;
}>(({ structure, contentLength, showingEditUI }) => ({
	display: structure.type === "long_text" || contentLength > 200 ? "block" : "inline-block",
	marginLeft: contentLength > 200 && !showingEditUI ? "10px" : "",
	marginTop: contentLength > 200 ? "4px" : "",
	flexGrow: 1
}));

export const TEXT_FIELD_STYLE = {
	flexGrow: 1,
	backgroundColor: "white",
	marginLeft: "8px",
	border: "1px solid #CCCCCC",
	borderRadius: "3px",
	padding: "4px",
	fontSize: "12px"
};

export const DATE_FIELD_STYLE = {
	backgroundColor: "white",
	border: "1px solid #CCCCCC",
	borderRadius: "3px",
	padding: "4px",
	fontSize: "12px"
};

export const LongTextFieldWrapper = styled.div<{ showingEditUI: boolean }>(({ showingEditUI }) => ({
	marginLeft: showingEditUI ? "" : "10px",
	marginTop: showingEditUI ? "6px" : "2px",
	border: showingEditUI ? "1px solid rgb(204, 204, 204)" : "",
	borderRadius: "3px",
	padding: showingEditUI ? "4px" : "",
	backgroundColor: showingEditUI ? "white" : ""
}));

export const RowWrapper = styled.div<{ structure: TRockyNoteStructure }>(({ structure }) => ({
	margin: "8px 0px",
	display: structure.type === "long_text" ? "block" : "flex",
	alignItems: "center"
}));

export const LabelWrapper = styled.div<{ structure: TRockyNoteStructure }>(({ structure }) => ({
	display: structure.type === "long_text" ? "block" : "inline-block",
	marginRight: "5px"
}));

export const LabelSpan = styled.span<{ colorScheme: ColorScheme }>(({ colorScheme }) => ({
	fontWeight: "bold",
	borderRadius: "2px",
	padding: "2px 3px",
	color: colorScheme.text,
	backgroundColor: colorScheme.color
}));

export const NoteWrapper = styled.div<{ hightlight: boolean; showingDeletedNotes: boolean; isPrivateNote: boolean }>(
	({ hightlight, showingDeletedNotes, isPrivateNote = false }) => ({
		padding: "10px",
		borderBottom: "1px solid black",
		"&:last-child": { borderBottom: "none" },
		backgroundColor: isPrivateNote ? "lightgray" : showingDeletedNotes ? "#6E6E6E" : hightlight ? "#FFFBEC" : ""
	})
);

export const NoteTypeWrapper = styled.div({
	margin: "4px 0px",
	display: "flex",
	alignItems: "center"
});

export const NoteTypeLabelWrapper = styled.div({
	display: "inline-block",
	marginRight: "5px"
});

export const EditButtonsWrapper = styled.div({
	display: "flex",
	alignItems: "center"
});

export const ButtonContentsContainer = styled.div({
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
});

export const BUTTON_STYLE = { marginRight: "4px" };

export const BUTTON_ICON_STYLE = { height: "16px", width: "16px", marginRight: "5px" };

export const FLEX_DIV_STYLE_LEFT = { width: "50%", justifyContent: "start" };

export const FLEX_DIV_STYLE_RIGHT = { justifyContent: "end", width: "50%" };

export const NoteErrorContainer = styled.div({
	margin: "50px",
	textAlign: "center"
});

export const HEADER_BUTTON_STYLE = { marginRight: "8px" };

export const MENU_ITEM_STYLE = { display: "flex", alignItems: "center" };

export const LINK_BUTTON_ICON_STYLE = { height: "18px", width: "12px", marginRight: "3px" };

export const TEXT_EDITOR_BUTTON_CONTAINER = { marginTop: "5px", display: "flex", alignItems: "center" };

export const TEXT_EDITOR_BUTTON_STYLE = (showingLinkURL: boolean) => ({
	fontSize: "12px",
	fontWeight: 900,
	marginLeft: showingLinkURL ? "8px" : ""
});

export const LinkHelpTextSpan = styled.span({
	marginLeft: "8px",
	fontSize: "12px",
	color: "gray",
	fontStyle: "italic"
});

export const LastUpdatedSpan = styled.span<{ showingDeletedNotes: boolean }>(({ showingDeletedNotes }) => ({
	// marginLeft: "8px",
	fontSize: "12px",
	color: showingDeletedNotes ? "white" : "#5C5C5C",
	fontStyle: "italic"
}));

export const TEXT_COLORS = {
	black: {
		color: "rgba(0, 0, 0, 1.0)"
	},
	white: {
		color: "rgba(255, 255, 255, 1.0)"
	},
	red: {
		color: "rgba(255, 0, 0, 1.0)"
	},
	orange: {
		color: "rgba(255, 127, 0, 1.0)"
	},
	yellow: {
		color: "rgba(180, 180, 0, 1.0)"
	},
	green: {
		color: "rgba(0, 180, 0, 1.0)"
	},
	blue: {
		color: "rgba(0, 0, 255, 1.0)"
	},
	indigo: {
		color: "rgba(75, 0, 130, 1.0)"
	},
	violet: {
		color: "rgba(127, 0, 255, 1.0)"
	}
};

export const RICH_TEXT_STYLES = {
	STRIKETHROUGH: {
		textDecoration: "line-through"
	},
	...TEXT_COLORS,
	...Object.keys(TEXT_COLORS).reduce(
		(highlightMap, textColor) => ({
			...highlightMap,
			[`${textColor}Highlight`]: { backgroundColor: TEXT_COLORS[textColor as keyof typeof TEXT_COLORS].color }
		}),
		{}
	)
};

export const NoNotesDiv = styled.div({
	padding: "100px 0px",
	color: "gray",
	fontWeight: 600,
	textAlign: "center",
	fontSize: "16px"
});

export const NoNotesFiltersDiv = styled.div({
	marginTop: "10px",
	fontSize: "14px"
});

export const NoteLink = styled.a({
	color: "black",
	textDecoration: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: "4px",
	border: "1.5px solid #757575",
	cursor: "pointer",
	marginLeft: "10px",
	"&:hover": {
		boxShadow:
			"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
		backgroundColor: defaultColorScheme.tertiary.color
	}
});

export const NoteLinkTitle = styled.span({
	paddingLeft: "10px",
	paddingRight: "10px",
	fontWeight: 600
});

export const FieldWrapper = styled.div({
	display: "inline-block",
	flexGrow: 1
});
