import {
	ICombinedPlayerSeasonHitterGradesSchema,
	TPositionPlayerFoundationalSkillsRow
} from "_react/shared/ui/data/tables/PositionPlayerFoundationalSkillsTable/_types";
import { aggregateStat } from "_react/shared/_helpers/stats";

export const aggregateCombinedPlayerSeasonHitterGradesDataRows = (
	prev: ICombinedPlayerSeasonHitterGradesSchema,
	curr: ICombinedPlayerSeasonHitterGradesSchema
): ICombinedPlayerSeasonHitterGradesSchema => {
	return {
		...prev,
		pa: curr.pa ? curr.pa + (prev.pa ?? 0) : prev.pa,
		pitches: curr.pitches ? curr.pitches + (prev.pitches ?? 0) : prev.pitches,
		swings: curr.swings ? curr.swings + (prev.swings ?? 0) : prev.swings,
		batSpeedGrade: aggregateStat(
			prev.swings ?? 0,
			prev.batSpeedGrade ?? null,
			curr.swings ?? 0,
			curr.batSpeedGrade ?? null
		),
		batToBallGrade: aggregateStat(
			prev.swings ?? 0,
			prev.batToBallGrade ?? null,
			curr.swings ?? 0,
			curr.batToBallGrade ?? null
		),
		swingDecisionsGrade: aggregateStat(
			prev.pitches ?? 0,
			prev.swingDecisionsGrade ?? null,
			curr.pitches ?? 0,
			curr.swingDecisionsGrade ?? null
		),
		compositeGrade: aggregateStat(
			prev.pa ?? 0,
			prev.compositeGrade ?? null,
			curr.pa ?? 0,
			curr.compositeGrade ?? null
		),
		attackAngleVertical: aggregateStat(
			prev.swings ?? 0,
			prev.attackAngleVertical ?? null,
			curr.swings ?? 0,
			curr.attackAngleVertical ?? null
		),
		sprintSpeedGrade: aggregateStat(
			prev.pa ?? 0,
			prev.sprintSpeedGrade ?? null,
			curr.pa ?? 0,
			curr.sprintSpeedGrade ?? null
		),
		// If combined is not measured and there is data, should still indicate this is not measured (since not all of the data will be measured)
		// If grades is not measured and there is data, should indicate this is not measured
		// Otherwise, should indicate that this is measured
		isMeasuredSwingMetrics:
			!curr.isMeasuredSwingMetrics && (curr.attackAngleVertical || curr.batSpeedGrade)
				? curr.isMeasuredSwingMetrics
				: !prev.isMeasuredSwingMetrics && (prev.attackAngleVertical || prev.batSpeedGrade)
				? prev.isMeasuredSwingMetrics
				: true
	};
};

// Used to get the level to display for a row
export const getLevelsFromRow = (row: TPositionPlayerFoundationalSkillsRow): Array<string> => {
	// Child Rows or rows with no nested data
	if ("teamBam" in row.playerSeasonHitterGrades)
		return row.playerSeasonHitterGrades.teamBam?.level ? [row.playerSeasonHitterGrades.teamBam?.level] : [];
	// Parent Rows with nested data
	if (row.childData && row.childData.length > 1) {
		return [
			...new Set(
				row.childData.reduce((acc: Array<string>, childRow: TPositionPlayerFoundationalSkillsRow) => {
					if (
						"teamBam" in childRow.playerSeasonHitterGrades &&
						childRow.playerSeasonHitterGrades.teamBam?.level
					)
						acc.push(childRow.playerSeasonHitterGrades.teamBam?.level);
					return acc;
				}, [])
			)
		];
	}
	return [];
};
