import React, { FunctionComponent, useState } from "react";
import styled from "@emotion/styled";
import { CREAM, GRAY } from "_react/shared/legacy/ui/Colors";

import { $TSFixMe } from "utils/tsutils";

type MenuItemProps = {
	children: $TSFixMe;
	disabled?: boolean;
	hover?: boolean;
	id?: string;
	handleClick?: $TSFixMe;
	setHover?: $TSFixMe;
	style?: $TSFixMe;
};

const StyledMenuItem = styled.div({
	padding: "8px",
	backgroundColor: CREAM,
	cursor: "pointer",
	fontSize: "14px",
	fontWeight: 500,
	color: "black"
});

// TODO: switch the hover to be controled in css for faster performance
export const MenuItem: FunctionComponent<MenuItemProps> = ({
	children,
	disabled = false,
	hover = false,
	id,
	handleClick = () => null,
	setHover = null,
	style = {}
}) => {
	const [defaultHover, setDefaultHover] = useState(false);
	return (
		<StyledMenuItem
			onMouseDown={() => {
				if (!disabled) handleClick();
			}}
			id={id}
			onMouseEnter={() => {
				if (setHover) setHover(true);
				else setDefaultHover(true);
			}}
			onMouseOut={() => {
				if (setHover) setHover(false);
				else setDefaultHover(false);
			}}
			style={{
				backgroundColor: disabled ? "gray" : hover || defaultHover ? GRAY : "",
				cursor: disabled ? "not-allowed" : "pointer",
				...style
			}}
		>
			{children}
		</StyledMenuItem>
	);
};
