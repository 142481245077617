import axios from "_redux/_utils/_axios";
import dayjs from "dayjs";

import { TGameSummaryWidgetGame } from "_react/gamesummary/_types";

export const loadGameSummaryGames = (orgId?: number, teamId?: number, teamIds?: number[], playerId?: number) => {
	const path = `/game_summary_games?fromDate=${dayjs().format("YYYY-MM-DD")}${
		orgId != null ? `&orgId=${orgId}` : ""
	}${teamId != null ? `&teamId=${teamId}` : ""}${teamIds != null ? `&teamIds=${teamIds.join(",")}` : ""}${
		playerId != null ? `&playerId=${playerId}` : ""
	}`;
	return axios
		.get(path)
		.then(response => {
			return response.data as TGameSummaryWidgetGame[];
		})
		.catch(_error => {
			return Promise.reject();
		});
};
