import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import styled from "@emotion/styled";

import axios from "_redux/_utils/_axios";

import { TextField } from "_react/shared/legacy/ui/TextField";
import { Button } from "_react/shared/legacy/ui/Button";
import Error from "_react/shared/legacy/ui/icons/Error";

// Constants

const ERROR_GIFS = [
	require("static/error/fuld_ejection.gif"),
	require("static/error/fuld_slide.gif"),
	require("static/error/jt.gif"),
	require("static/error/outfielder.gif"),
	require("static/error/phanatic_noshirt.gif")
];

// Styles

const HeaderDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	height: "42px",
	backgroundColor: "rgb(186, 12, 47)",
	color: "white",
	fontWeight: "bold",
	fontSize: "24px"
});

const ErrorIconLeftStyle = {
	marginRight: "5px"
};

const ErrorIconRightStyle = {
	marginLeft: "5px"
};

const ContentDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	margin: "20px"
});

const ErrorGifStyle = {
	height: "400px",
	maxWidth: "100%",
	marginBottom: "20px"
};

const ErrorMessageDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	marginBottom: "20px"
});

const ErrorLabelDiv = styled.div({
	fontWeight: "bold",
	fontSize: "24px"
});

const RedirectButtonsDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	marginBottom: "20px"
});

const RefreshButtonStyle = {
	marginRight: "20px"
};

const FeedbackDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	width: "300px"
});

const FeedbackTextFieldStyle = {
	backgroundColor: "lightgray",
	height: "200px"
};

const FeedbackButtonStyle = {
	width: "130px",
	marginTop: "20px"
};

// Util Functions

function getRandomInt(max) {
	return Math.floor(Math.random() * max);
}

export function setWithExpiry(key, value, ttl) {
	const item = {
		value: value,
		expiry: new Date().getTime() + ttl
	};
	localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
	const itemString = window.localStorage.getItem(key);
	if (!itemString) return null;

	const item = JSON.parse(itemString);
	const isExpired = new Date().getTime() > item.expiry;

	if (isExpired) {
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
}

export function sendErrorFeedback(message, error) {
	return axios
		.post("/slack/error/feedback", { message: message, error: error, url: window.location.toString() })
		.then(response => response.data)
		.catch(error => {
			return Promise.reject(error);
		});
}

// Component

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: "", info: "", eventId: null };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({
			hasError: true,
			errorGif: ERROR_GIFS[getRandomInt(ERROR_GIFS.length)],
			feedbackMessage: "",
			reportedError: false,
			error,
			info
		});
		// You can also log the error to an error reporting service
		console.log(error); // eslint-disable-line no-console
		console.log(info); // eslint-disable-line no-console

		// If ChunkLoadError, try reloading once without submitting a Sentry error
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (error?.message && chunkFailedMessage.test(error.message)) {
			if (!getWithExpiry("chunk_failed")) {
				setWithExpiry("chunk_failed", "true", 10000);
				window.location.reload();
				return;
			}
		}

		if (["prod"].includes(process.env.REACT_APP_ENV)) {
			Sentry.withScope(scope => {
				scope.setExtras(info);
				const eventId = Sentry.captureException(error);
				this.setState({ eventId });
			});
		}
	}

	render() {
		if (this.state.hasError) {
			//render fallback UI
			return (
				<div>
					<HeaderDiv>
						<Error fill="white" style={ErrorIconLeftStyle} /> Rocky Error{" "}
						<Error fill="white" style={ErrorIconRightStyle} />
					</HeaderDiv>
					<ContentDiv>
						<img src={this.state.errorGif} alt="error" style={ErrorGifStyle} />
						<ErrorMessageDiv>
							<ErrorLabelDiv>Oops, something went wrong.</ErrorLabelDiv>
							<div>Try refreshing this page or navigating to the Rocky Home Page.</div>
							<div>Feel free to send feedback below.</div>
						</ErrorMessageDiv>
						<RedirectButtonsDiv>
							<Button
								title={"Refresh Page"}
								onClick={() => window.location.reload()}
								style={RefreshButtonStyle}
							/>
							<Button title={"Go to Rocky Home Page"} onClick={() => (window.location.href = "/")} />
						</RedirectButtonsDiv>
						<FeedbackDiv>
							<TextField
								multiline
								fullWidth
								placeholder="Enter feedback here..."
								value={this.state.feedbackMessage}
								onChange={e => {
									this.setState({ feedbackMessage: e.target.value });
								}}
								style={FeedbackTextFieldStyle}
							/>
							<Button
								title={this.state.reportedError ? "Feedback Sent" : "Send Feedback"}
								onClick={() => {
									this.setState({ reportedError: true });
									sendErrorFeedback(this.state.feedbackMessage, this.state.error.toString());
								}}
								disabled={this.state.reportedError}
								style={FeedbackButtonStyle}
							/>
						</FeedbackDiv>
					</ContentDiv>
				</div>
			);
		}

		//when there's not an error, render children untouched
		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.object
};

export default ErrorBoundary;
