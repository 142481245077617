import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const tables = [
	{
		title: "Fastball Command",
		description:
			"Means he must be able to locate his fastball on both sides of the plate as well as being able to go up and down. It does not mean throwing strikes but throwing strikes where he wants to. There are many pitchers that can either pitch to both sides of the plate but only to the outside corner of the respective hitter, or just to arm side only.\n\nA reliable way to measure command is to watch the catcher’s setup and determine the number of times the pitcher can hit his intended target, taking into consideration count and base-runner situation.",
		columns: ["Scale", "Present Command", "Future Command"],
		rows: [
			[
				"20",
				"Commands 6 or less of 20 to catcher’s setup. Sprays zone.  Very Inconsistent.",
				"Commands 6 or less of 20 to catcher’s setup. Sprays zone. Very Inconsistent."
			],
			[
				"30",
				"Commands 7-8 of 20 to catcher’s setup. Erratic at times, struggles to find the zone. Consistently misses in damage zone for a hitter.",
				"Commands 7-8 of 20 to catcher’s setup. Erratic at times, struggles to find the zone. Consistently misses in damage zone for a hitter."
			],
			[
				"40",
				"Commands 9-10 of 20 to catcher’s setup.  Misses are big and often in the damage zone for a hitter out over the plate.",
				"Commands 9-10 of 20 to catcher’s setup. Misses are big and often in the damage zone for a hitter out over the plate."
			],
			[
				"50",
				"Commands 11-12 of 20 to catcher’s setup. Hits spots arm side, inconsistent glove side with occasional misses in damage zone.",
				"Commands 11-12 of 20 to catcher’s setup. Hits spots arm side, inconsistent glove side with occasional misses in damage zone."
			],
			[
				"60",
				"Commands 12-13 of 20 to setup AND has to be able to hit spots to both sides of the plate. Misses occasionally in a damage area but usually in a safe zone.",
				"Commands 12-13 of 20 to setup AND has to be able to hit spots to both sides of the plate. Misses occasionally in a damage area but usually in a safe zone."
			],
			[
				"70",
				"",
				"Command 14-15 of 20 to catcher’s setup AND has to be able to hit spots to both sides of plate against both LH and RH hitter. Misses are small."
			],
			["80", "", "Commands 16, or more, of 20 to catcher’s setup."]
		]
	},
	{
		title: "Velocities",
		columns: ["Scale", "RHS Velocity", "LHS Velocity", "RHR Velocity", "LHR Velocity"],
		rows: [
			["20", "88", "86", "88", "85"],
			["30", "90", "88", "90", "88"],
			["40", "92", "90", "92", "90"],
			["45", "93", "91", "93", "92"],
			["50", "94", "92", "94", "93"],
			["55", "95", "93", "96", "94"],
			["60", "96", "94", "97", "95"],
			["70", "97", "95", "99", "98"],
			["80", "98", "97", "100", "99"]
		]
	}
];

const FastballTooltip = () => {
	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<div
					style={{
						overflow: "scroll"
					}}
				>
					{tables.map((table, idx) => {
						return (
							<Table key={idx} style={{ marginTop: "25px" }}>
								<TableHead
									style={{
										backgroundColor: defaultColorScheme.group.backgroundColor
									}}
								>
									<TableRow>
										<TableCell
											colSpan={table.columns.length}
											style={{
												color: defaultColorScheme.group.color,
												fontSize: "16px",
												textAlign: "center"
											}}
										>
											{table.title}
										</TableCell>
									</TableRow>
									{table.description != null && (
										<TableRow>
											<TableCell
												colSpan={table.columns.length}
												style={{
													color: defaultColorScheme.group.color,
													textAlign: "center"
												}}
											>
												{table.description}
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										{table.columns.map((column, idx) => {
											return (
												<TableCell
													key={idx}
													style={{
														color: defaultColorScheme.group.color,
														textAlign: "center",
														textTransform: "capitalize"
													}}
												>
													{column}
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
								<TableBody
									style={{
										border: "1px solid #dfe0df"
									}}
								>
									{table.rows.map((row, idx) => {
										return (
											<TableRow key={idx}>
												{row.map((cell, idx) => {
													return (
														<TableCell
															key={idx}
															style={{
																textAlign: "center",
																fontWeight: idx === 0 ? "700" : ""
															}}
														>
															{cell}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default FastballTooltip;
