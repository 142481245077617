import React, { CSSProperties } from "react";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { getCurrency } from "utils/helpers";
import { IAddendumBMnAssignmentSalary } from "_react/shared/_types/schema/contracts";

const MN_ASSIGNMENT_SALARY_COLUMNS = [
	{
		title: "Year",
		name: "year",
		getCellValue: (row: IAddendumBMnAssignmentSalary) => row.year
	},
	{
		title: "R",
		name: "salary_r",
		getCellValue: (row: IAddendumBMnAssignmentSalary) => getCurrency(row.salary_r)
	},
	{
		title: "RA",
		name: "salary_ra",
		getCellValue: (row: IAddendumBMnAssignmentSalary) => getCurrency(row.salary_ra)
	},
	{
		title: "1A",
		name: "salary_1a",
		getCellValue: (row: IAddendumBMnAssignmentSalary) => getCurrency(row.salary_1a)
	},
	{
		title: "2A",
		name: "salary_2a",
		getCellValue: (row: IAddendumBMnAssignmentSalary) => getCurrency(row.salary_2a)
	},
	{
		title: "3A",
		name: "salary_3a",
		getCellValue: (row: IAddendumBMnAssignmentSalary) => getCurrency(row.salary_3a)
	},
	{
		title: "1F",
		name: "salary_1f",
		getCellValue: (row: IAddendumBMnAssignmentSalary) => getCurrency(row.salary_1f)
	},
	{
		title: "SA",
		name: "salary_sa",
		getCellValue: (row: IAddendumBMnAssignmentSalary) => getCurrency(row.salary_sa)
	},
	{
		title: "DS",
		name: "salary_ds",
		getCellValue: (row: IAddendumBMnAssignmentSalary) => getCurrency(row.salary_ds)
	},
	{
		title: "VS",
		name: "salary_vs",
		getCellValue: (row: IAddendumBMnAssignmentSalary) => getCurrency(row.salary_vs)
	}
];

type TAddendumBMnAssignmentSalaryTableStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

interface IProps {
	addendumBMnAssignmentSalaries: IAddendumBMnAssignmentSalary[];
	colorSchemeGroup: ColorSchemeGroup;
	style?: TAddendumBMnAssignmentSalaryTableStyle;
}

function AddendumBMnAssignmentSalaryTable({ addendumBMnAssignmentSalaries, colorSchemeGroup, style }: IProps) {
	return (
		<React.Fragment>
			<Card style={style?.card}>
				<ColoredCardHeader
					colorScheme={colorSchemeGroup.secondary}
					text={"Addendum B Minor League Assignment Salary"}
					style={style?.cardHeader}
				/>
				<AutoFitTable
					columns={MN_ASSIGNMENT_SALARY_COLUMNS}
					defaultSortColumn={"Year"}
					defaultSortState={"desc"}
					isHighlightOnHover={false}
					rows={addendumBMnAssignmentSalaries}
					rowKey="addendum_b_id"
				/>
			</Card>
		</React.Fragment>
	);
}

export default AddendumBMnAssignmentSalaryTable;
