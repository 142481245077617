import React, { FC, useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import { REDDARK, REDDARKER } from "_react/shared/legacy/ui/Colors";

import { checkStatus } from "_react/status/_helpers";

const StatusContainer = styled.div({
	padding: "4px",
	border: `2px solid ${REDDARKER}`,
	borderRadius: "3px",
	backgroundColor: REDDARK,
	color: "white",
	textAlign: "center",
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
});

const StatusLabel = styled.span({
	marginRight: "6px"
});

const StatusChip = styled.div<{ variant?: "up" | "down" | "checking" }>(({ variant = "up" }) => ({
	borderRadius: "3px",
	backgroundColor: variant === "up" ? "green" : variant === "down" ? "red" : "blue",
	padding: "4px",
	fontWeight: "bold",
	fontSize: "12px"
}));

type StatusIndicatorProps = {};

const MINISECOND_INTERVAL = 10000; // Every 10 seconds

export const StatusIndicator: FC<StatusIndicatorProps> = () => {
	const checking = useRef(false);
	const [up, setUp] = useState(false);
	const checkingDeep = useRef(false);
	const [upDeep, setUpDeep] = useState(false);
	useEffect(() => {
		// Initial Check
		checkStatus(checking, checkingDeep, setUp, setUpDeep);
	}, []);
	useEffect(() => {
		// Subsequent Checks
		const interval = setInterval(() => {
			if (checking.current || checkingDeep.current) return;
			checkStatus(checking, checkingDeep, setUp, setUpDeep);
		}, MINISECOND_INTERVAL);

		return () => clearInterval(interval);
	}, [checking, checkingDeep, setUp, setUpDeep]);

	return (
		<StatusContainer>
			<StatusLabel>Rocky Status:</StatusLabel>
			{(checking.current || checkingDeep.current) && <StatusChip variant="checking">Checking...</StatusChip>}
			{!(checking.current || checkingDeep.current) && up && upDeep && <StatusChip variant="up">OK</StatusChip>}
			{!(checking.current || checkingDeep.current) && (!up || !upDeep) && (
				<StatusChip variant="down">DOWN</StatusChip>
			)}
		</StatusContainer>
	);
};
