import React from "react";

import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { ScheduleTable } from "_react/teampage/schedule/ScheduleTable";

type TScheduleTabProps = {
	player: TPlayerPageCombinedPlayer;
};

const ScheduleTab = ({ player }: TScheduleTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	return <ScheduleTable player={player} colorSchemeGroup={chakraColorSchemeGroup} />;
};

export default ScheduleTab;
