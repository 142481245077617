import styled from "@emotion/styled";
import React, { FunctionComponent, useCallback } from "react";
import { $TSFixMe, Style } from "utils/tsutils";
import { ColorScheme } from "_react/shared/legacy/ui/Colors";
import RadioChecked from "_react/shared/legacy/ui/icons/RadioChecked";
import RadioUnchecked from "_react/shared/legacy/ui/icons/RadioUnchecked";

type RadioProps = {
	checked?: boolean;
	colorScheme?: ColorScheme;
	disabled?: boolean;
	onChange?: (nowSelected: boolean) => void;
	style?: Style;
	onKeyPress?: $TSFixMe;
	value?: string;
	fill?: string;
};

type TStyledRadioProps = { style: Style; disabled: boolean };
const StyledRadio = styled.div<TStyledRadioProps>(({ style, disabled }) => ({
	backgroundColor: "white",
	cursor: !disabled ? "pointer" : "",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	...style
}));

export const Radio: FunctionComponent<RadioProps> = ({
	checked = false,
	disabled = false,
	onChange = () => null,
	style = {},
	onKeyPress = null,
	fill
}) => {
	const onClick = useCallback(() => {
		if (!disabled) onChange(!checked);
	}, [disabled, checked, onChange]);

	return (
		<StyledRadio disabled={disabled} onClick={onClick} style={style} onKeyPress={onKeyPress}>
			{checked ? (
				<RadioChecked fill={fill} className="dont-dismiss" style={{ color: "black" }} />
			) : (
				<RadioUnchecked fill={fill} className="dont-dismiss" />
			)}
		</StyledRadio>
	);
};
