import React from "react";
import ArrowDropDown from "_react/shared/legacy/ui/icons/ArrowDropDown";
import ArrowDropUp from "_react/shared/legacy/ui/icons/ArrowDropUp";
import Tooltip from "@material-ui/core/Tooltip";
import StatsTooltip from "_react/stats/shared/StatsTooltip";

import {
	headerRowStyle,
	borderColumnBorder,
	nonBorderColumnBorder,
	edgeColumnBorder
} from "_react/stats/shared/formatting";

function StatsTableHeader({ headers, sortColumn, setSortColumn, sortAsc, setSortAsc, sortable, keyIdentifier }) {
	return headers.map((headerColumn, idx) => {
		let contents = <span>{headerColumn.tooltip ? headerColumn.tooltip.abbreviation : headerColumn.name}</span>;
		if (headerColumn.tooltip) {
			contents = (
				<Tooltip placement="top" title={<StatsTooltip tooltip={headerColumn.tooltip} />}>
					{contents}
				</Tooltip>
			);
		}
		return (
			<th
				key={`${keyIdentifier}-${idx}-${headerColumn.name}`}
				onClick={() => {
					if (!sortable) return;
					if (sortColumn == null || headerColumn.key !== sortColumn.key) setSortColumn(headerColumn);
					else setSortAsc(!sortAsc);
				}}
				style={{
					...headerRowStyle,
					borderRight:
						idx === headers.length - 1
							? edgeColumnBorder
							: headerColumn.isBorderColumn
							? borderColumnBorder
							: nonBorderColumnBorder
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					{sortable && sortColumn != null && headerColumn.key === sortColumn.key && (
						<span>{sortAsc ? <ArrowDropUp /> : <ArrowDropDown />}</span>
					)}
					{contents}
				</div>
			</th>
		);
	});
}

export default StatsTableHeader;
