import axios from "_redux/_utils/_axios";

export const apiUptimeCheck = (deep = false) => {
	const path = `/jello${deep ? "/deep" : ""}`;
	return axios
		.get(path)
		.then(response => {
			return response.status === 200 && response.data === "ok\n";
		})
		.catch(_error => {
			return false;
		});
};

export const loggedInCheck = () => {
	const path = "/token/valid";
	return axios
		.get(path)
		.then(response => {
			return response.status === 200 && response.data === "ok\n";
		})
		.catch(_error => {
			return false;
		});
};
