import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";

import { $TSFixMe, Style } from "utils/tsutils";
import { ColorScheme, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

type TabProps = {
	colorScheme?: ColorScheme;
	disabled?: boolean;
	label?: string;
	onClick?: $TSFixMe;
	selected?: boolean;
	style?: Style;
	value?: string;
};

const StyledTab = styled.div<TabProps>(
	{
		textAlign: "center",
		margin: "2px",
		padding: "6px 8px",
		borderRadius: "3px",
		fontSize: "17px",
		fontWeight: 700,
		flexGrow: 1
	},
	(props: TabProps) => ({
		color: props.colorScheme!.text,
		backgroundColor: props.selected ? props.colorScheme!.selected : "",
		cursor: props.disabled ? "not-allowed" : "pointer",
		":hover": {
			backgroundColor: props.selected
				? props.colorScheme!.selected
				: !props.disabled
				? props.colorScheme!.hover
				: ""
		}
	})
);

export const Tab: FunctionComponent<TabProps> = ({
	colorScheme = defaultColorScheme.primary,
	disabled = false,
	label,
	onClick,
	selected = false,
	value
}) => {
	return (
		<StyledTab
			colorScheme={colorScheme}
			disabled={disabled}
			onClick={() => {
				if (!disabled) onClick(value);
			}}
			selected={selected}
		>
			{label}
		</StyledTab>
	);
};
