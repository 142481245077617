import { aggregateStat } from "_react/shared/_helpers/stats";
import { THROWS_L, THROWS_OVERALL, THROWS_R } from "_react/shared/data_models/seasonal_grades/_constants";
import { ISeasonalSwingMetrics } from "_react/shared/data_models/seasonal_grades/_types";
import { SOURCE_GUMBO, VALID_PRO_LEVELS } from "_react/shared/data_models/stats/_constants";
import { IStatsPlayerBatting, IStatsPlayerBattingByTeam } from "_react/shared/data_models/stats/_types";
import { GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP } from "_react/shared/ui/data/tables/SwingMetricsTable/_constants";
import { ICombinedSwingMetricsData, TSwingMetricsRow } from "_react/shared/ui/data/tables/SwingMetricsTable/_types";

//
// DATA PREPROCESSING
//

// Given an array of IStatsPlayerBatting filtered by player, dedupe the data based on source
export const dedupePlayerStatsPlayerBattingBySource = (
	statsPlayerBatting: Array<IStatsPlayerBatting>
): Array<IStatsPlayerBatting> => {
	// Populate a record to track relevant statsPlayerBatting instances
	// The record is keyed on "season-gameType"
	const statsPlayerBattingRecord: Record<string, IStatsPlayerBatting> = {};
	statsPlayerBatting.forEach((s: IStatsPlayerBatting) => {
		// If season-gameType does not exist, or if season-gameType has the highest priority source,
		// update/create an entry in the record
		if (!(`${s.season}-${s.gameType}` in statsPlayerBattingRecord) || s.source === SOURCE_GUMBO)
			statsPlayerBattingRecord[`${s.season}-${s.gameType}`] = s;
	});

	// Return based on the nested values of our record.
	return Object.values(statsPlayerBattingRecord);
};

// Given an array of IStatsPlayerBattingByTeam filtered by player, dedupe the data based on source
// Remove teams with invalid levels
export const dedupePlayerStatsPlayerBattingByTeamAndSource = (
	statsPlayerBattingByTeam: Array<IStatsPlayerBattingByTeam>
): Array<IStatsPlayerBattingByTeam> => {
	// Populate a record to track relevant statsPlayerBattingByTeam instances
	// The record is keyed on "season-teamId-gameType"
	const statsPlayerBattingByTeamRecord: Record<string, IStatsPlayerBattingByTeam> = {};
	statsPlayerBattingByTeam.forEach((s: IStatsPlayerBattingByTeam) => {
		// Filter teams down to valid levels
		if (!VALID_PRO_LEVELS.includes(s.teamBam?.level ?? "")) return;
		// If season-teamId-gameType does not exist, or if season-teamId-gameType has the highest priority source,
		// update/create an entry in the record
		if (!(`${s.season}-${s.teamId}-${s.gameType}` in statsPlayerBattingByTeamRecord) || s.source === SOURCE_GUMBO)
			statsPlayerBattingByTeamRecord[`${s.season}-${s.teamId}-${s.gameType}`] = s;
	});

	// Return based on the nested values of our record.
	return Object.values(statsPlayerBattingByTeamRecord);
};

export const aggregateCombinedSwingMetricsDataRows = (
	prev: ICombinedSwingMetricsData,
	curr: ICombinedSwingMetricsData
): ICombinedSwingMetricsData => {
	return {
		...prev,
		nSwings: (curr.nSwings ?? 0) + (prev.nSwings ?? 0),
		batSpeed: aggregateStat(prev.nSwings ?? 0, prev.batSpeed ?? null, curr.nSwings ?? 0, curr.batSpeed ?? null),
		attackAngleVertical: aggregateStat(
			prev.nSwings ?? 0,
			prev.attackAngleVertical ?? null,
			curr.nSwings ?? 0,
			curr.attackAngleVertical ?? null
		),
		attackAngleHorizontal: aggregateStat(
			prev.nSwings ?? 0,
			prev.attackAngleHorizontal ?? null,
			curr.nSwings ?? 0,
			curr.attackAngleHorizontal ?? null
		),
		batAngleVertical: aggregateStat(
			prev.nSwings ?? 0,
			prev.batAngleVertical ?? null,
			curr.nSwings ?? 0,
			curr.batAngleVertical ?? null
		),
		batAngleHorizontal: aggregateStat(
			prev.nSwings ?? 0,
			prev.batAngleHorizontal ?? null,
			curr.nSwings ?? 0,
			curr.batAngleHorizontal ?? null
		),
		// vL
		nSwingsVl: (curr.nSwingsVl ?? 0) + (prev.nSwingsVl ?? 0),
		batSpeedVl: aggregateStat(
			prev.nSwingsVl ?? 0,
			prev.batSpeedVl ?? null,
			curr.nSwingsVl ?? 0,
			curr.batSpeedVl ?? null
		),
		attackAngleVerticalVl: aggregateStat(
			prev.nSwingsVl ?? 0,
			prev.attackAngleVerticalVl ?? null,
			curr.nSwingsVl ?? 0,
			curr.attackAngleVerticalVl ?? null
		),
		attackAngleHorizontalVl: aggregateStat(
			prev.nSwingsVl ?? 0,
			prev.attackAngleHorizontalVl ?? null,
			curr.nSwingsVl ?? 0,
			curr.attackAngleHorizontalVl ?? null
		),
		batAngleVerticalVl: aggregateStat(
			prev.nSwingsVl ?? 0,
			prev.batAngleVerticalVl ?? null,
			curr.nSwingsVl ?? 0,
			curr.batAngleVerticalVl ?? null
		),
		batAngleHorizontalVl: aggregateStat(
			prev.nSwingsVl ?? 0,
			prev.batAngleHorizontalVl ?? null,
			curr.nSwingsVl ?? 0,
			curr.batAngleHorizontalVl ?? null
		),
		// vR
		nSwingsVr: (curr.nSwingsVr ?? 0) + (prev.nSwingsVr ?? 0),
		batSpeedVr: aggregateStat(
			prev.nSwingsVr ?? 0,
			prev.batSpeedVr ?? null,
			curr.nSwingsVr ?? 0,
			curr.batSpeedVr ?? null
		),
		attackAngleVerticalVr: aggregateStat(
			prev.nSwingsVr ?? 0,
			prev.attackAngleVerticalVr ?? null,
			curr.nSwingsVr ?? 0,
			curr.attackAngleVerticalVr ?? null
		),
		attackAngleHorizontalVr: aggregateStat(
			prev.nSwingsVr ?? 0,
			prev.attackAngleHorizontalVr ?? null,
			curr.nSwingsVr ?? 0,
			curr.attackAngleHorizontalVr ?? null
		),
		batAngleVerticalVr: aggregateStat(
			prev.nSwingsVr ?? 0,
			prev.batAngleVerticalVr ?? null,
			curr.nSwingsVr ?? 0,
			curr.batAngleVerticalVr ?? null
		),
		batAngleHorizontalVr: aggregateStat(
			prev.nSwingsVr ?? 0,
			prev.batAngleHorizontalVr ?? null,
			curr.nSwingsVr ?? 0,
			curr.batAngleHorizontalVr ?? null
		)
	};
};

// Converts raw stats player batting data to a record mapping
// season-gameType to ICombinedSwingMetricsData
export const createStatsPlayerBattingRecord = (
	statsPlayerBatting: Array<IStatsPlayerBatting>
): Record<string, ICombinedSwingMetricsData> => {
	// Populate a record to track relevant statsPlayerBatting instances
	// The record is keyed on "season-gameType"
	return statsPlayerBatting.reduce((acc: Record<string, ICombinedSwingMetricsData>, s: IStatsPlayerBatting) => {
		// Convert the IStatsPlayerBattingByTeam game types to SeasonalSwingMetrics game types
		const convertedGameTypes: Array<string> = GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP[s.gameType];
		convertedGameTypes.forEach((convertedGameType: string) => {
			// If season-convertedGameType does not exist, create an entry in the record
			if (!(`${s.season}-${convertedGameType}` in acc)) {
				acc[`${s.season}-${convertedGameType}`] = {
					season: s.season,
					gameType: convertedGameType
				};
			}
			// No aggregation required because we are only using the season and game type
		});
		return acc;
	}, {});
};

// Converts raw stats player batting byteam data to a record mapping
// season-teamId-gameType to ICombinedSwingMetricsData
export const createStatsPlayerBattingByTeamRecord = (
	statsPlayerBattingByTeam: Array<IStatsPlayerBattingByTeam>
): Record<string, ICombinedSwingMetricsData> => {
	// Populate a record to track relevant statsPlayerBattingByTeam instances
	// The record is keyed on "season-teamId-gameType"
	return statsPlayerBattingByTeam.reduce(
		(acc: Record<string, ICombinedSwingMetricsData>, s: IStatsPlayerBattingByTeam) => {
			const teamId = s.team?.id; // This gets combined team id.  s.teamId is a bamId
			if (!teamId) return acc;

			// Convert the IStatsPlayerBattingByTeam game types to SeasonalSwingMetrics game types
			const convertedGameTypes: Array<string> = GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP[s.gameType];
			convertedGameTypes.forEach((convertedGameType: string) => {
				// If season-teamId-convertedGameType does not exist, create an entry in the record
				if (!(`${s.season}-${teamId}-${convertedGameType}` in acc)) {
					acc[`${s.season}-${teamId}-${convertedGameType}`] = {
						season: s.season,
						gameType: convertedGameType,
						// Use combined team ID in order to match SeasonalSwingMetrics info
						teamId: teamId,
						// Use team Bam to properly display team name, org, abbrev, level
						team: s.teamBam
					};
				}
				// No aggregation required because we are only using the season and game type
			});
			return acc;
		},
		{}
	);
};

// Appends seasonalSwingMetrics data to the statsPlayerBatting record, keyed on season-gameType
export const appendGenericSeasonalSwingMetricsData = (
	seasonalSwingMetrics: Array<ISeasonalSwingMetrics>,
	getKey: (x: ISeasonalSwingMetrics) => string, // Function that gets the key used to index the record
	statsPlayerBattingRecord: Record<string, ICombinedSwingMetricsData>
): Record<string, ICombinedSwingMetricsData> => {
	seasonalSwingMetrics.forEach((x: ISeasonalSwingMetrics) => {
		const key = getKey(x);
		// If the key does not exist, we should not create an entry in the record,
		// as we want the ability to aggregate all data in the record, and we need sample-size data
		// from statsPlayerBatting(ByTeam) in order to properly aggregate seasonalSwingMetrics
		// across game types.

		// As such, skip this case.
		if (!(key in statsPlayerBattingRecord)) return;
		// Otherwise, append seasonalSwingMetrics data into the relevant row.
		// Because this function cannot handle aggregation of seasonalSwingMetrics using
		// seasonalSwingMetrics data alone, before calling this function, there should be at most one
		// instance of seasonalSwingMetrics corresponding to each of the xwoba-related fields
		// we want to populate in the record.
		// When we aggregate the seasonalSwingMetrics data in the table, we will use the corresponding sample
		// sizes from statsPlayerBatting(ByTeam)
		else {
			const prev: ICombinedSwingMetricsData = statsPlayerBattingRecord[key];
			const newField: Partial<ICombinedSwingMetricsData> = {};
			// Map x.model and x.throws to the corresponding field on ICombinedSwingMetricsData
			if (x.throws === THROWS_OVERALL) {
				newField.nSwings = x.nSwings;
				newField.batSpeed = x.batSpeed;
				newField.attackAngleVertical = x.attackAngleVertical;
				newField.attackAngleHorizontal = x.attackAngleHorizontal;
				newField.batAngleVertical = x.batAngleVertical;
				newField.batAngleHorizontal = x.batAngleHorizontal;
			} else if (x.throws === THROWS_L) {
				newField.nSwingsVl = x.nSwings;
				newField.batSpeedVl = x.batSpeed;
				newField.attackAngleVerticalVl = x.attackAngleVertical;
				newField.attackAngleHorizontalVl = x.attackAngleHorizontal;
				newField.batAngleVerticalVl = x.batAngleVertical;
				newField.batAngleHorizontalVl = x.batAngleHorizontal;
			} else if (x.throws === THROWS_R) {
				newField.nSwingsVr = x.nSwings;
				newField.batSpeedVr = x.batSpeed;
				newField.attackAngleVerticalVr = x.attackAngleVertical;
				newField.attackAngleHorizontalVr = x.attackAngleHorizontal;
				newField.batAngleVerticalVr = x.batAngleVertical;
				newField.batAngleHorizontalVr = x.batAngleHorizontal;
			}

			statsPlayerBattingRecord[key] = {
				...prev,
				...newField
			};
		}
	});
	return statsPlayerBattingRecord;
};

// Used to get the levels associated with a row.
export const getLevelsFromRow = (row: TSwingMetricsRow): Array<string> => {
	// Child Rows or rows with no nested data
	if ("team" in row.combinedSwingMetricsData)
		return row.combinedSwingMetricsData.team?.level ? [row.combinedSwingMetricsData.team?.level] : [];
	// Parent Rows with nested data
	if (row.childData && row.childData.length > 1) {
		return [
			...new Set(
				row.childData.reduce((acc: Array<string>, childRow: TSwingMetricsRow) => {
					if ("team" in childRow.combinedSwingMetricsData && childRow.combinedSwingMetricsData.team?.level)
						acc.push(childRow.combinedSwingMetricsData.team?.level);
					return acc;
				}, [])
			)
		];
	}
	return [];
};
