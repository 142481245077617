import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
	useToast,
	VStack,
	HStack,
	Box,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	MenuItemOption,
	MenuDivider,
	Portal,
	Text,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Tooltip,
	Spacer,
	Flex
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { isDefaultFilters, DEFAULT_STATS_TABLE_FILTERS } from "_react/shared/ui/data/tables/shared/Filters";
import { PITCH_GROUP_OVERALL, PITCH_TYPES } from "_react/shared/_constants/pitch_types";
import { ILkPitchType } from "_react/shared/_types/phil_data/lk_pitch_type";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import {
	BATS_L,
	BATS_OVERALL,
	BATS_R,
	GAME_TYPE_OVERALL,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_SPRING_TRAINING
} from "_react/shared/data_models/baseline_hit_probs/_constants";
import { VALID_PRO_LEVELS } from "_react/shared/data_models/stats/_constants";
import { IStatsPlayerPitchingByTeamLkLevel } from "_react/shared/data_models/stats/_types";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import { getMinAndMaxSeason, getSeasonFilters, updateFilters } from "_react/shared/_helpers/stats";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import { ICON_CIRCLE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import { TPlayerClassification, PRO } from "utils/tsutils";
import {
	NUM_DISPLAY_SEASONS,
	getPitcherPitchOutcomesColumns,
	PITCHER_PITCH_OUTCOMES_PARENT_COLUMNS
} from "_react/shared/ui/data/tables/PitcherPitchOutcomesTable/_constants";
import PitcherPitchOutcomesTableMachine, {
	TPitcherPitchOutcomesTableContext,
	FETCHING_PLAYER_SEASON_POP_PITCH,
	FETCHING_PLAYER_SEASON_POP_PITCH_BYTEAM,
	FETCHING_PLAYER_SEASON_POO_PITCH,
	FETCHING_PLAYER_SEASON_POO_PITCH_BYTEAM,
	SET_PLAYER_ID,
	SET_PLAYER_SEASON_POP_PITCH,
	SET_PLAYER_SEASON_POP_PITCH_BYTEAM,
	SET_PLAYER_SEASON_POO_PITCH,
	SET_PLAYER_SEASON_POO_PITCH_BYTEAM,
	SET_FILTERS
} from "_react/shared/ui/data/tables/PitcherPitchOutcomesTable/_machine";
import {
	ICombinedPitcherPitchOutcomesData,
	TPitcherPitchOutcomesTableData,
	TPitcherPitchOutcomesRow
} from "_react/shared/ui/data/tables/PitcherPitchOutcomesTable/_types";
import {
	createPitchOutcomeObservedRecord,
	createPitchOutcomeProbabilitiesRecord,
	createPitchOutcomeObservedByTeamRecord,
	createPitchOutcomeProbabilitiesByTeamRecord,
	mergePitchOutcomeAggregations,
	aggregateCombinedPitcherPitchOutcomesDataRows,
	getLevelsFromRow
} from "_react/shared/ui/data/tables/PitcherPitchOutcomesTable/_helpers";

type TPitcherPitchOutcomesTableStyle = {
	container?: React.CSSProperties;
	tableContainer?: React.CSSProperties;
};

type TPitcherPitchOutcomesTableProps = {
	title?: string;
	playerId?: number;
	playingLevel: TPlayerClassification;
	data?: TPitcherPitchOutcomesTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<TPitcherPitchOutcomesRow, keyof TPitcherPitchOutcomesRow>;
	style?: TPitcherPitchOutcomesTableStyle;
};

const PitcherPitchOutcomesTable = ({
	title,
	playerId: playerIdProp,
	playingLevel = PRO,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TPitcherPitchOutcomesTableProps) => {
	const toast = useToast();
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const [current, send] = useMachine(
		PitcherPitchOutcomesTableMachine(playerIdProp, playingLevel, data, shouldFetchData, toast)
	);
	const isFetchingPlayerSeasonPopPitch = current.matches(FETCHING_PLAYER_SEASON_POP_PITCH);
	const isFetchingPlayerSeasonPopPitchByTeam = current.matches(FETCHING_PLAYER_SEASON_POP_PITCH_BYTEAM);
	const isFetchingPlayerSeasonPooPitch = current.matches(FETCHING_PLAYER_SEASON_POO_PITCH);
	const isFetchingPlayerSeasonPooPitchByTeam = current.matches(FETCHING_PLAYER_SEASON_POO_PITCH_BYTEAM);
	const isLoading =
		isFetchingPlayerSeasonPopPitch ||
		isFetchingPlayerSeasonPopPitchByTeam ||
		isFetchingPlayerSeasonPooPitch ||
		isFetchingPlayerSeasonPooPitchByTeam;

	const context = current.context as TPitcherPitchOutcomesTableContext;
	const {
		playerId,
		filters,
		playerSeasonPopPitch,
		playerSeasonPopPitchByTeam,
		playerSeasonPooPitch,
		playerSeasonPooPitchByTeam
	} = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonPopPitch !== playerSeasonPopPitch && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_POP_PITCH, value: data?.playerSeasonPopPitch });
	}, [send, data?.playerSeasonPopPitch, playerSeasonPopPitch, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonPopPitchByTeam !== playerSeasonPopPitchByTeam && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_POP_PITCH_BYTEAM, value: data?.playerSeasonPopPitchByTeam });
	}, [send, data?.playerSeasonPopPitchByTeam, playerSeasonPopPitchByTeam, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonPooPitch !== playerSeasonPooPitch && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_POO_PITCH, value: data?.playerSeasonPooPitch });
	}, [send, data?.playerSeasonPooPitch, playerSeasonPooPitch, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonPooPitchByTeam !== playerSeasonPooPitchByTeam && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_POO_PITCH_BYTEAM, value: data?.playerSeasonPooPitchByTeam });
	}, [send, data?.playerSeasonPooPitchByTeam, playerSeasonPooPitchByTeam, shouldFetchData]);

	//
	// PREPROCESSING playerSeasonPopPitch, playerSeasonPooPitch
	//

	const combinedPitcherPitchOutcomesData: Array<ICombinedPitcherPitchOutcomesData> | undefined = useMemo(() => {
		// Create record based on observed data
		if (playerSeasonPooPitch != null && playerSeasonPopPitch != null) {
			const observedRecord = createPitchOutcomeObservedRecord(playerSeasonPooPitch);
			const probabilitiesRecord = createPitchOutcomeProbabilitiesRecord(playerSeasonPopPitch);
			const mergedRecord = mergePitchOutcomeAggregations(observedRecord, probabilitiesRecord);
			return Object.values(mergedRecord);
		}
	}, [playerSeasonPooPitch, playerSeasonPopPitch]);

	const combinedPitcherPitchOutcomesDataByTeam: Array<ICombinedPitcherPitchOutcomesData> | undefined = useMemo(() => {
		// Create record based on observed data
		if (playerSeasonPooPitchByTeam != null && playerSeasonPopPitchByTeam != null) {
			const observedRecord = createPitchOutcomeObservedByTeamRecord(playerSeasonPooPitchByTeam);
			const probabilitiesRecord = createPitchOutcomeProbabilitiesByTeamRecord(playerSeasonPopPitchByTeam);
			const mergedRecord = mergePitchOutcomeAggregations(observedRecord, probabilitiesRecord, true);
			return Object.values(mergedRecord);
		}
	}, [playerSeasonPooPitchByTeam, playerSeasonPopPitchByTeam]);

	//
	// Season filter options
	//

	// Get list of seasons using byTeam data so that the min/max seasons have corresponding entries in the table,
	// as a season must have at least one associated team to be displayed
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<ICombinedPitcherPitchOutcomesData>(combinedPitcherPitchOutcomesDataByTeam ?? []),
		[combinedPitcherPitchOutcomesDataByTeam]
	);
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<IStatsPlayerPitchingByTeamLkLevel> = useMemo(
		() =>
			combinedPitcherPitchOutcomesDataByTeam
				?.reduce((acc: Array<IStatsPlayerPitchingByTeamLkLevel>, c: ICombinedPitcherPitchOutcomesData) => {
					const levelRel: IStatsPlayerPitchingByTeamLkLevel | undefined = c.team?.levelRel;
					if (
						levelRel &&
						levelRel.value &&
						!acc.some(val => val.value === levelRel.value) &&
						VALID_PRO_LEVELS.includes(levelRel.value)
					)
						acc.push(levelRel);
					return acc;
				}, [])
				?.sort(
					(a: IStatsPlayerPitchingByTeamLkLevel, b: IStatsPlayerPitchingByTeamLkLevel) =>
						(a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[combinedPitcherPitchOutcomesDataByTeam]
	);

	//
	// Pitch type options
	//

	// Compute the pitch type options
	const pitchTypeOptions: Array<ILkPitchType> = useMemo(
		() =>
			combinedPitcherPitchOutcomesDataByTeam
				?.reduce((acc: Array<ILkPitchType>, curr: ICombinedPitcherPitchOutcomesData) => {
					const pitchType: ILkPitchType | undefined = curr.lkPitchType;
					if (
						pitchType &&
						pitchType.abbreviation &&
						!acc.some(val => val.abbreviation === pitchType.abbreviation) &&
						PITCH_TYPES.includes(pitchType.abbreviation as TPitchTypes)
					)
						acc.push(pitchType);
					return acc;
				}, [])
				?.sort((a: ILkPitchType, b: ILkPitchType) => a.sortOrder - b.sortOrder) ?? [],
		[combinedPitcherPitchOutcomesDataByTeam]
	);

	// Once the pitch type options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.pitchTypes === undefined && pitchTypeOptions.length > 0) {
			const pitchTypeAbbreviations = pitchTypeOptions.map(
				(pitchTypeOption: ILkPitchType) => pitchTypeOption.abbreviation ?? ""
			);
			const newFilters = {
				...filters,
				pitchTypes: [...pitchTypeAbbreviations, PITCH_GROUP_OVERALL]
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, pitchTypeOptions]);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map((option: IStatsPlayerPitchingByTeamLkLevel) => option.value)
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	//
	// Filter data for table
	//

	// Combine game type and throws filters into "OVR" when possible
	const gameTypeFilters: Array<string> = useMemo(() => {
		return filters.gameTypes.includes(GAME_TYPE_REGULAR_SEASON) &&
			filters.gameTypes.includes(GAME_TYPE_POSTSEASON) &&
			!filters.gameTypes.includes(GAME_TYPE_SPRING_TRAINING)
			? [GAME_TYPE_OVERALL]
			: filters.gameTypes;
	}, [filters.gameTypes]);
	const throwsFilter: string = useMemo(() => (filters.bats.length === 2 ? BATS_OVERALL : filters.bats?.[0]), [
		filters.bats
	]);

	// Get columns based on handedness filter
	const pitcherPitchOutcomesColumns = useMemo(() => getPitcherPitchOutcomesColumns(throwsFilter), [throwsFilter]);

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns) return pitcherPitchOutcomesColumns;
		return pitcherPitchOutcomesColumns.filter(
			(col: TColumn<TPitcherPitchOutcomesRow, keyof TPitcherPitchOutcomesRow>) => columns.includes(col.value)
		);
	}, [columns, pitcherPitchOutcomesColumns]);

	const filteredCombinedPositionPlayerPerformanceData:
		| Array<ICombinedPitcherPitchOutcomesData>
		| undefined = useMemo(() => {
		if (isLoading || !throwsFilter) return undefined;
		return combinedPitcherPitchOutcomesData?.filter(
			(c: ICombinedPitcherPitchOutcomesData) =>
				c.season <= seasonFilters.maxSeason &&
				c.season >= seasonFilters.minSeason &&
				gameTypeFilters.includes(c.gameType)
		);
	}, [isLoading, seasonFilters, gameTypeFilters, throwsFilter, combinedPitcherPitchOutcomesData]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		let availablePitchTypes = pitchTypeOptions.map(
			(pitchTypeOption: ILkPitchType) => pitchTypeOption.abbreviation ?? ""
		);
		availablePitchTypes = [...availablePitchTypes, PITCH_GROUP_OVERALL];
		const availableLevels = levelFilterOptions.map((option: IStatsPlayerPitchingByTeamLkLevel) => option.value);

		return isDefaultFilters(filters, availablePitchTypes, availableLevels, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, pitchTypeOptions, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, value: DEFAULT_STATS_TABLE_FILTERS });
	}, [send]);

	//
	// Combine data for table
	//

	const combinedTableData: Array<TPitcherPitchOutcomesRow> | undefined = useMemo(() => {
		if (!filteredCombinedPositionPlayerPerformanceData) return undefined;
		const combinedData: Array<TPitcherPitchOutcomesRow> = [];
		const uniqueSeasons = [
			...new Set(
				filteredCombinedPositionPlayerPerformanceData.map((c: ICombinedPitcherPitchOutcomesData) => c.season)
			)
		];
		uniqueSeasons.forEach((season: number) => {
			// Create an object with the overall combined position player performance data and all related
			// position player performance data by team.

			const uniquePitchTypes = filteredCombinedPositionPlayerPerformanceData.reduce(
				(pitchTypes: Array<string>, data: ICombinedPitcherPitchOutcomesData) => {
					if (data.season === season && data.pitchType != null && !pitchTypes.includes(data.pitchType))
						pitchTypes.push(data.pitchType);
					return pitchTypes;
				},
				[]
			);

			uniquePitchTypes.forEach((pitchType: string) => {
				// Create an object with the by team and pitch type data and all related
				// position player performance data by pitch type.
				// For certain game type combinations, we need to aggregate the data into a single row
				const pitcherPitchOutcomesData: Array<ICombinedPitcherPitchOutcomesData> = filteredCombinedPositionPlayerPerformanceData.filter(
					(c: ICombinedPitcherPitchOutcomesData) => c.season === season && c.pitchType === pitchType
				);
				const pitcherPitchOutcomesDataByTeam: Array<ICombinedPitcherPitchOutcomesData> =
					combinedPitcherPitchOutcomesDataByTeam?.filter(
						(c: ICombinedPitcherPitchOutcomesData) =>
							c.season === season && c.pitchType === pitchType && gameTypeFilters.includes(c.gameType)
					) ?? [];

				// Only one top-level row is returned, so just use those stats
				if (pitcherPitchOutcomesData.length === 1) {
					let combinedParentData = pitcherPitchOutcomesData[0];
					let combinedChildData:
						| Array<TPitcherPitchOutcomesRow>
						| undefined = pitcherPitchOutcomesDataByTeam.map((c: ICombinedPitcherPitchOutcomesData) => {
						return { combinedPitcherPitchOutcomesData: c };
					});
					// If there is only one child row, then we want to display it as the overall row
					if (pitcherPitchOutcomesDataByTeam && pitcherPitchOutcomesDataByTeam.length === 1) {
						combinedParentData = pitcherPitchOutcomesDataByTeam[0];
						combinedChildData = undefined;
					}
					// If there are no child rows, then the overall row must be invalid, and we should not push data.
					// Therefore, only push the data if the length of the byTeam is non-zero
					if (pitcherPitchOutcomesDataByTeam && pitcherPitchOutcomesDataByTeam.length > 0)
						combinedData.push({
							combinedPitcherPitchOutcomesData: combinedParentData,
							childData: combinedChildData
						});
				}
				// Multiple top-level rows are returned, aggregate all of the data
				else if (pitcherPitchOutcomesData?.length) {
					// Aggregate position player performance data across different game types into a single top-level row
					let combinedParentData = pitcherPitchOutcomesData.reduce(
						(prev: ICombinedPitcherPitchOutcomesData, curr: ICombinedPitcherPitchOutcomesData) => {
							return aggregateCombinedPitcherPitchOutcomesDataRows(prev, curr);
						}
					);
					// Aggregate team level position player performance data different game types into singular team-level rows
					let combinedChildData:
						| Array<TPitcherPitchOutcomesRow>
						| undefined = pitcherPitchOutcomesDataByTeam?.reduce(
						(
							combinedDataByTeam: Array<TPitcherPitchOutcomesRow>,
							dataByTeam: ICombinedPitcherPitchOutcomesData
						) => {
							if (!combinedDataByTeam.length) return [{ combinedPitcherPitchOutcomesData: dataByTeam }];
							const combinedDataMatchingTeamIndex = combinedDataByTeam.findIndex(
								(combinedData: TPitcherPitchOutcomesRow) =>
									"teamId" in combinedData.combinedPitcherPitchOutcomesData &&
									combinedData.combinedPitcherPitchOutcomesData.teamId === dataByTeam.teamId
							);
							if (combinedDataMatchingTeamIndex === -1)
								combinedDataByTeam.push({ combinedPitcherPitchOutcomesData: dataByTeam });
							else {
								const combinedDataMatchingTeamData =
									combinedDataByTeam[combinedDataMatchingTeamIndex].combinedPitcherPitchOutcomesData;
								combinedDataByTeam[combinedDataMatchingTeamIndex] = {
									combinedPitcherPitchOutcomesData: aggregateCombinedPitcherPitchOutcomesDataRows(
										combinedDataMatchingTeamData,
										dataByTeam
									)
								};
							}
							return combinedDataByTeam;
						},
						[]
					);
					// If there are no child rows, then the overall row must be invalid, and we should not push data.
					// Therefore, only push the data if the length of the byTeam is non-zero
					if (!combinedChildData || combinedChildData.length === 0) return;
					// If there is only one child row, then we want to display it as the overall row
					if (combinedChildData && combinedChildData.length === 1) {
						combinedParentData = combinedChildData[0].combinedPitcherPitchOutcomesData;
						combinedChildData = undefined;
					}
					combinedData.push({
						combinedPitcherPitchOutcomesData: combinedParentData,
						childData: combinedChildData
					});
				}
			});
		});
		// Apply level filters and pitch type filters
		return combinedData.reduce((acc: TPitcherPitchOutcomesRow[], row: TPitcherPitchOutcomesRow) => {
			// First, filter out everything that doesn't meet the pitch type filters
			if (!filters.pitchTypes?.includes(row.combinedPitcherPitchOutcomesData.pitchType ?? "")) return acc;

			// Next, apply level filters
			const uniqueLevels: Array<string> = getLevelsFromRow(row);
			const shouldDisplayEntireRow = uniqueLevels.every(level => filters.levels?.includes(level));

			// If all of the levels associated with the row meet the level filters, push the entire row and return early
			if (shouldDisplayEntireRow) {
				acc.push(row);
				return acc;
			}

			// Otherwise, filter the child data and create a new parent row.
			const filteredChildData = row.childData?.filter((c: TPitcherPitchOutcomesRow) =>
				filters.levels?.includes(c.combinedPitcherPitchOutcomesData.team?.level ?? "")
			);
			// If exactly one item in child data, that becomes parent row
			if (filteredChildData && filteredChildData?.length === 1)
				acc.push({
					combinedPitcherPitchOutcomesData: filteredChildData[0].combinedPitcherPitchOutcomesData,
					childData: undefined
				});
			// Otherwise, if there multiple items in child data, we must aggregate the child data to create a new parent row
			else if (filteredChildData && filteredChildData?.length > 1) {
				const newParentRow: ICombinedPitcherPitchOutcomesData = filteredChildData.reduce(
					(prev: ICombinedPitcherPitchOutcomesData, curr: TPitcherPitchOutcomesRow, index: number) => {
						if (index === 0) return prev;
						return aggregateCombinedPitcherPitchOutcomesDataRows(
							prev,
							curr.combinedPitcherPitchOutcomesData
						);
					},
					filteredChildData[0].combinedPitcherPitchOutcomesData
				);
				// Remove team, level data from new parent row before pushing
				delete newParentRow.team;
				acc.push({
					combinedPitcherPitchOutcomesData: newParentRow,
					childData: filteredChildData
				});
			}
			return acc;
		}, []);
	}, [
		filters.pitchTypes,
		filters.levels,
		gameTypeFilters,
		filteredCombinedPositionPlayerPerformanceData,
		combinedPitcherPitchOutcomesDataByTeam
	]);

	// Filtering
	const handleGameTypeSelect = (value: string) => {
		const newFilters = {
			...filters,
			gameTypes: updateFilters(filters.gameTypes, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleBatsSelect = (value: string) => {
		const newFilters = {
			...filters,
			bats: updateFilters(filters.bats, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handlePitchTypesSelect = (value: string) => {
		const newFilters = {
			...filters,
			pitchTypes: updateFilters(filters.pitchTypes ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
				</HStack>
				{isShowFilters && (
					<Menu closeOnSelect={false} placement="left-start">
						<ButtonGroup
							isAttached
							variant={defaultFiltersSet ? "outline" : "solid"}
							colorScheme={defaultFiltersSet ? undefined : "blue"}
						>
							{!defaultFiltersSet && (
								<IconButton
									aria-label="Close"
									icon={<CloseIcon fill="white" />}
									onClick={resetFilters}
								/>
							)}
							<MenuButton
								as={IconButton}
								aria-label="Options"
								icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
							>
								MenuItem
							</MenuButton>
						</ButtonGroup>
						<Portal>
							<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
								<MenuOptionGroup title="Bats" type="checkbox" value={filters.bats}>
									<MenuItemOption value={BATS_L} onClick={() => handleBatsSelect(BATS_L)}>
										Left
									</MenuItemOption>
									<MenuItemOption value={BATS_R} onClick={() => handleBatsSelect(BATS_R)}>
										Right
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuDivider />
								<MenuOptionGroup title="Game Type" type="checkbox" value={filters.gameTypes}>
									<MenuItemOption
										value={GAME_TYPE_SPRING_TRAINING}
										onClick={() => handleGameTypeSelect(GAME_TYPE_SPRING_TRAINING)}
									>
										Spring Training
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_REGULAR_SEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_REGULAR_SEASON)}
									>
										Regular Season
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_POSTSEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_POSTSEASON)}
									>
										Postseason
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuOptionGroup title="Seasons">
									<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
										{minSeason === maxSeason && (
											<Tooltip hasArrow placement="top" label="Only one season of data exists">
												<HStack>
													<OutlineInfo color="gray.500" />
													<Text>{minSeason}</Text>
												</HStack>
											</Tooltip>
										)}
										{minSeason !== maxSeason && (
											<VStack>
												<RangeSlider
													value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
													min={minSeason}
													max={maxSeason}
													step={1}
													onChange={(seasons: number[]) => {
														send({
															type: SET_FILTERS,
															value: {
																...filters,
																minSeason: seasons[0],
																maxSeason: seasons[1]
															}
														});
													}}
													onMouseEnter={() => setShowSeasonRangeTooltip(true)}
													onMouseLeave={() => setShowSeasonRangeTooltip(false)}
												>
													<RangeSliderTrack>
														<RangeSliderFilledTrack bg="black" />
													</RangeSliderTrack>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.minSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
													</Tooltip>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.maxSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
													</Tooltip>
												</RangeSlider>
												<Flex sx={{ width: "100%" }}>
													<Text fontSize="sm">{minSeason}</Text>
													<Spacer />
													<Text fontSize="sm">{maxSeason}</Text>
												</Flex>
											</VStack>
										)}
									</VStack>
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Pitch Types"
									type="checkbox"
									value={filters.pitchTypes ?? [PITCH_GROUP_OVERALL, ...PITCH_TYPES]}
								>
									<MenuItemOption
										value={PITCH_GROUP_OVERALL}
										onClick={() => handlePitchTypesSelect(PITCH_GROUP_OVERALL)}
									>
										Overall
									</MenuItemOption>
									{pitchTypeOptions.map((pitchType: ILkPitchType) => (
										<MenuItemOption
											value={pitchType.abbreviation ?? ""}
											onClick={() => handlePitchTypesSelect(pitchType.abbreviation ?? "")}
										>
											<PitchTypeLabel
												label={pitchType.label ?? ""}
												abbreviation={pitchType.abbreviation ?? ""}
												shape={ICON_CIRCLE}
											/>
										</MenuItemOption>
									))}
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Levels"
									type="checkbox"
									value={filters.levels ?? VALID_PRO_LEVELS}
								>
									{levelFilterOptions.map((option: IStatsPlayerPitchingByTeamLkLevel) => (
										<MenuItemOption
											value={option.value}
											onClick={() => handleLevelSelect(option.value)}
										>
											<TeamLevelBadge level={option.value} />
										</MenuItemOption>
									))}
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
				)}
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<TPitcherPitchOutcomesRow, keyof TPitcherPitchOutcomesRow>
					columns={filteredColumns}
					parentColumns={PITCHER_PITCH_OUTCOMES_PARENT_COLUMNS}
					data={combinedTableData}
					emptyDataDisplayText={"No Pitch Outcomes Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					isExpandableRows
					getCustomRowKeyFunction={(row: TPitcherPitchOutcomesRow) => {
						return `${row.combinedPitcherPitchOutcomesData.season}-${row.combinedPitcherPitchOutcomesData.pitchType}`;
					}}
					defaultSortColumns={[
						{
							columnValue: "pitchType",
							sortDirection: ASC
						},
						{
							columnValue: "season",
							sortDirection: ASC
						},
						{
							columnValue: "level",
							sortDirection: DESC
						},
						{
							columnValue: "pitches",
							sortDirection: DESC
						}
					]}
					getRowStyleFunction={(
						obj: TPitcherPitchOutcomesRow,
						index: number,
						data: Array<TPitcherPitchOutcomesRow>
					) => {
						if (
							index < data.length - 1 &&
							obj.combinedPitcherPitchOutcomesData.season !==
								data[index + 1].combinedPitcherPitchOutcomesData.season
						) {
							return {
								borderBottom: "1px solid !important",
								borderBottomColor: "gray.300 !important"
							};
						}
						return {};
					}}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default PitcherPitchOutcomesTable;
