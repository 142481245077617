import { useToken } from "@chakra-ui/react";

export const useChakraColorSchemeGroup = () => {
	// Get colors from Chakra theme
	const [gray50, gray200, gray400, gray600, gray800, white] = useToken("colors", [
		"gray.50",
		"gray.200",
		"gray.400",
		"gray.600",
		"gray.800",
		"white"
	]);

	const ChakraPrimaryColorScheme = {
		color: gray400,
		text: gray800,
		hover: gray200,
		hoverText: gray800,
		selected: gray200,
		selectedText: gray800
	};

	const ChakraSecondaryColorScheme = {
		color: gray800,
		text: white,
		hover: gray800,
		hoverText: white,
		selected: gray800,
		selectedText: white
	};

	const ChakraTertiaryColorScheme = {
		color: gray50,
		text: gray600,
		hover: gray50,
		hoverText: gray600,
		selected: gray50,
		selectedText: gray600
	};

	const ChakraQuaternaryColorScheme = {
		color: gray800,
		text: white,
		hover: gray600,
		hoverText: white,
		selected: gray600,
		selectedText: white
	};

	const ChakraColorSchemeGroup = {
		primary: ChakraPrimaryColorScheme,
		secondary: ChakraSecondaryColorScheme,
		tertiary: ChakraTertiaryColorScheme,
		quaternary: ChakraQuaternaryColorScheme,
		quinary: ChakraPrimaryColorScheme // no quinary
	};

	return ChakraColorSchemeGroup;
};

export const useChakraBlueColorScheme = () => {
	// Get colors from Chakra theme
	const [blue500, blue600, blue700, white] = useToken("colors", ["blue.500", "blue.600", "blue.700", "white"]);

	const ChakraBlueColorScheme = {
		color: blue500,
		text: white,
		hover: blue600,
		hoverText: white,
		selected: blue700,
		selectedText: white
	};

	return ChakraBlueColorScheme;
};
