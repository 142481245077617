/*
    Season/Career/Projection stats for a player
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { cookies } from "utils/redux_constants";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { isEqual } from "lodash";

import {
	getDefaultBounds,
	getYearRange,
	getHeaders,
	compareLevelArray,
	getLevelSelected,
	levelsForGroup,
	formatProjectionDataForRows
} from "_react/stats/shared/helpers";
import { PITCHER, BATTER, FIELDER, MAJORS, MINORS } from "_redux/StatsAggregated/_helpers";
import { SHOHEI_PHIL_ID } from "_react/playerpage/_constants";
import StatsTableContainer from "_react/stats/shared/StatsTableContainer";
import { StyledSelect } from "_react/shared/selects";
import { ButtonsSelected } from "_react/shared/legacy/ui/ButtonSelected";
import { Button } from "_react/shared/legacy/ui/Button";
import PlayerTypeSVG from "_react/shared/legacy/ui/icons/PlayerTypeSVG";
import { capitalize } from "utils/_helpers";
import {
	ToolbarDiv,
	SelectDiv,
	LevelsDivContainer,
	ButtonsContainer,
	BUTTONS_COLOR_SCHEME
} from "_react/stats/player/_styles";

const BUTTONS = [
	{ label: "Affiliates", value: "affiliates" },
	{ label: "All", value: "all" },
	{ label: "Majors", value: MAJORS },
	{ label: "Minors", value: MINORS }
];

const CUSTOM_LEVELS = levelsForGroup("all").map(value => ({ value, label: value }));

const PLAYER_TYPE_OPTS = [PITCHER, BATTER, FIELDER].map(value => ({ value, label: capitalize(value) }));

function mapSelectNumberOpt(value) {
	return value ? { value: value, label: `${value}` } : null;
}

// TODO: Reintegrate cookies into settings
class PlayerStats extends Component {
	constructor(props) {
		super(props);

		this.onSettingChange = this.onSettingChange.bind(this);

		const [defaultBegin, defaultEnd] = getDefaultBounds();
		this.state = {
			showLevelTotalRows: false,
			showProjections: false,
			level: ["MLB", "AAA", "AA", "A+", "A", "A-", "Rk", "FRk", "MEX", "WIN"],
			levelGroup: "affiliates",
			customLevels: null,
			begin: defaultBegin,
			end: defaultEnd,
			yearRange: getYearRange(defaultBegin, defaultEnd),
			source: cookies.get("statsSource") != null ? cookies.get("statsSource") : "brew",
			gameType: { text: "Regular Season", value: "R" },
			seasonData: {},
			totalData: {},
			careerData: {},
			// BBOps conditions on which projections should be available
			allowProjections:
				props.allowProjections !== undefined
					? props.allowProjections
					: Boolean(props.player.on_40_man_roster) || Boolean(props.player.mls_current > 1),
			projectionData: {},
			averageAges: {},
			// hardcode shohei as a batter
			playerType: props.player.is_pitcher && props.player.phil_id !== SHOHEI_PHIL_ID ? PITCHER : BATTER,
			showSettings: false,
			anchorElLevel: false,
			settings: {
				showGameType: {
					label: "Show Game Type",
					key: "showGameType",
					value: cookies.get("showGameType") !== "no"
				},
				showCareerStats: {
					label: "Show Career Stats",
					value: cookies.get("showCareerStats") !== "no",
					key: "showCareerStats"
				},
				showOtherLeagues: {
					label: "Show Other League Stats",
					key: "showOtherLeagues",
					value: cookies.get("showOtherLeagues") != null ? cookies.get("showOtherLeagues") !== "no" : false
				},
				showSource: {
					label: "Show Source",
					key: "showSource",
					value: cookies.get("showSource") !== "no"
				}
			}
		};
	}

	componentDidMount = () => {
		this.onRefresh(false, this.state.playerType, true);
	};

	componentDidUpdate(prevProps, prevState) {
		// Reselect data
		const { summary, player } = this.props;
		const { gameType, level, playerType, source, begin, end } = this.state;
		if (
			gameType.value !== prevState.gameType.value ||
			!compareLevelArray(level, prevState.level) ||
			source !== prevState.source ||
			begin !== prevState.begin ||
			end !== prevState.end ||
			playerType !== prevState.playerType ||
			summary !== prevProps.summary ||
			player.bam_id !== prevProps.player.bam_id
		) {
			this.onRefresh(false, playerType, false, source !== prevState.source);
		}
	}

	onSettingChange = (key, value) => {
		const settings = { ...this.state.settings };
		settings[key].value = value;
		if (key === "showOtherLeagues") {
			this.setState(
				{
					settings
				},
				() => this.onRefresh(false)
			);
		} else {
			this.setState({
				settings
			});
		}
		cookies.set(key, value ? "yes" : "no", { path: "/" });
	};

	onRefresh = (forceRefresh, playerTypeValue, initialRender = false, sourceChanged = false) => {
		const {
			fetchSeasonStatsAll,
			fetchCareerStatsAll,
			fetchSeasonStats,
			fetchCareerStats,
			fetchProjectionStats,
			bam_id,
			getSeasonTableData,
			getCareerTableData,
			player,
			summary,
			fetchAverageAges
		} = this.props;
		const {
			source,
			gameType,
			level,
			playerType: playerTypeState,
			yearRange,
			settings,
			allowProjections
		} = this.state;
		const playerType = playerTypeValue ? playerTypeValue : playerTypeState;
		this.setState({ careerData: {}, seasonData: {}, totalData: {}, projectionData: {} });

		const [defaultBegin, defaultEnd] = getDefaultBounds();
		const seasonBounds = summary
			? { begin: defaultBegin, end: defaultEnd }
			: initialRender || sourceChanged
			? null
			: { begin: this.state.begin, end: this.state.end };
		fetchAverageAges().then(averageAges => {
			this.setState({
				averageAges
			});
		});
		fetchSeasonStats(bam_id, playerType, forceRefresh).then(() => {
			this.setState(
				{
					...getSeasonTableData(
						player,
						playerType,
						gameType.value,
						source,
						level,
						seasonBounds,
						settings.showOtherLeagues.value
					)
				},
				() => {
					const newYearRange = this.state.yearRange;
					if (!isEqual(yearRange, newYearRange)) {
						this.setState({
							begin: newYearRange[newYearRange.length - 1],
							end: newYearRange[0]
						});
					}
				}
			);
		});
		fetchSeasonStatsAll(bam_id, forceRefresh, playerType);
		fetchCareerStats(bam_id, playerType, forceRefresh).then(() => {
			let levelsUsed = [...level];
			if (getLevelSelected(level) === "custom")
				levelsUsed = ["AAA", "AA", "A+", "A", "A-", "Rk", "FRk", "MEX", "WIN"];
			if (level.includes("MLB")) levelsUsed.push("MLB");
			this.setState({
				careerData: getCareerTableData(player, playerType, gameType.value, source, levelsUsed)
			});
		});
		fetchCareerStatsAll(bam_id, forceRefresh, playerType);
		if (playerType !== FIELDER && allowProjections) {
			fetchProjectionStats(bam_id, playerType).then(projectionData => {
				this.setState({
					projectionData
				});
			});
		}
	};

	validateYearRange = (begin, end) => {
		if (begin > end) {
			return false;
		}
		return true;
	};

	// params: key - the property of state to be updated; val - the new value for that property of state
	// Makes sure the state is updated and then onComponent update will call onRefresh if necessary
	onChange = (key, val) => {
		const { begin, end } = this.state;
		let validProps = true;
		if (key === "begin") {
			validProps = this.validateYearRange(val, end);
		} else if (key === "end") {
			validProps = this.validateYearRange(begin, val);
		}
		if (validProps) {
			this.setState({ [key]: val });
		}
	};

	onSelectLevelGroup = levelGroupNew => {
		const { levelGroup } = this.state;
		if (levelGroupNew !== levelGroup) {
			const levelNew = levelsForGroup(levelGroupNew);
			this.setState({ level: levelNew, levelGroup: levelGroupNew, customLevels: null });
		}
	};

	resetLevel = () => {
		this.setState({ level: levelsForGroup("all"), levelGroup: "all", customLevels: null });
	};

	onUpdateCustomLevels = selectedOpts => {
		if (selectedOpts == null || !selectedOpts.length) {
			this.resetLevel();
		} else {
			const level = selectedOpts.map(opt => opt.value);
			this.setState({ level, customLevels: selectedOpts, levelGroup: null });
		}
	};

	toggleProjections = () => {
		this.setState(prevState => ({
			showProjections: !prevState.showProjections
		}));
	};

	render() {
		const { summary, colorScheme, showCareerRows, fetching, sortSeasonsDesc, style } = this.props;
		const {
			seasonData,
			totalData,
			careerData,
			projectionData,
			settings,
			playerType,
			begin,
			end,
			yearRange,
			levelGroup,
			customLevels,
			showProjections,
			allowProjections,
			averageAges
		} = this.state;

		const { player } = this.props;

		const beginOpts = yearRange
			.filter(year => end == null || year <= end)
			.map(mapSelectNumberOpt)
			.reverse();
		const endOpts = yearRange
			.filter(year => begin == null || year >= begin)
			.map(mapSelectNumberOpt)
			.reverse();

		const playerTypeSVG = <PlayerTypeSVG fill={"black"} playerType={playerType} />;
		const playerTypeOpt = PLAYER_TYPE_OPTS.filter(opt => opt.value === playerType) ?? null;

		const headers = getHeaders(true, playerType, averageAges);
		const { showCareerStats, showOtherLeagues } = settings;

		const projectionsExist =
			projectionData &&
			Object.keys(projectionData).length > 0 &&
			Object.keys(projectionData).some(key => projectionData[key].length > 0);

		// Compile Data Array from Season Data
		let data = [];
		if (seasonData) {
			Object.keys(seasonData).map(l => {
				data = [...data, ...seasonData[l]];
				return null;
			});
			data = data
				.filter(e => !e.isLevelTotalRow)
				.sort((a, b) => (sortSeasonsDesc ? b.season - a.season : a.season - b.season));
		}

		// Add Total Data
		if (totalData) data = [...data, { ...totalData, isTotalRow: true }];

		// Add Career Data
		if (showCareerStats.value && showCareerRows) {
			if (careerData.majors != null && careerData.majors.length > 0) {
				data = [
					...data,
					{
						...careerData.majors[0],
						isCareerRow: true,
						level: "MAJORS"
					}
				];
			}
			if (careerData.minors != null && careerData.minors.length > 0) {
				const minorsRows = [...careerData.minors].map(d => ({
					...d,
					isCareerRow: true,
					expandable: careerData.minors.length > 1 && d.level === "MINORS",
					isNestedRow: d.level !== "MINORS"
				}));
				data = [...data, ...minorsRows];
			}
			if (careerData.other != null && careerData.other.length > 0 && showOtherLeagues.value) {
				const otherRows = [...careerData.other].map(d => ({
					...d,
					isCareerRow: true,
					expandable: careerData.other.length > 1 && d.level === "OTHER",
					isNestedRow: d.level !== "OTHER"
				}));
				data = [...data, ...otherRows];
			}
		}

		// Add Projection Data
		if (!summary && showProjections) {
			if (playerType !== FIELDER) {
				data = [...data, { isProjectionStart: true }];
			}
			Object.keys(projectionData).map(key => {
				const transformStats = formatProjectionDataForRows(projectionData[key], key, player);
				data = [...data, ...transformStats];
				return null;
			});
		}

		return (
			<Card style={style?.card}>
				<ColoredCardHeader
					colorScheme={colorScheme.secondary}
					text={`${summary ? `Recent ` : ``}Statistics`}
					style={style?.cardHeader}
				>
					{!summary && (
						<ToolbarDiv>
							{allowProjections && (
								<Button
									style={{
										marginRight: "5px"
									}}
									colorScheme={BUTTONS_COLOR_SCHEME}
									title={`${
										fetching(playerType)
											? "Loading"
											: !projectionsExist
											? "No"
											: showProjections
											? "Hide"
											: "Show"
									} Projections`}
									onClick={this.toggleProjections}
									disabled={!projectionsExist}
								/>
							)}
							<SelectDiv>
								<StyledSelect
									options={beginOpts}
									value={mapSelectNumberOpt(begin)}
									onChange={opt => this.onChange("begin", opt.value)}
									placeholder={"Begin"}
								/>
							</SelectDiv>
							<SelectDiv marginLeft={4}>
								<StyledSelect
									options={endOpts}
									value={mapSelectNumberOpt(end)}
									onChange={opt => this.onChange("end", opt.value)}
									placeholder={"End"}
								/>
							</SelectDiv>
							<LevelsDivContainer>
								<ButtonsContainer>
									<ButtonsSelected
										buttons={BUTTONS}
										colorScheme={BUTTONS_COLOR_SCHEME}
										selected={levelGroup}
										onSelect={this.onSelectLevelGroup}
									/>
								</ButtonsContainer>
								<SelectDiv marginLeft={1} minWidth={100}>
									<StyledSelect
										isMulti
										closeMenuOnSelect={false}
										options={CUSTOM_LEVELS}
										value={customLevels}
										onChange={this.onUpdateCustomLevels}
										placeholder={"Custom"}
										hideSelectedOptions={false}
										controlShouldRenderValue={false}
									/>
								</SelectDiv>
							</LevelsDivContainer>
							<SelectDiv minWidth={61} marginLeft={4}>
								<StyledSelect
									options={PLAYER_TYPE_OPTS}
									value={playerTypeOpt}
									onChange={opt => this.onChange("playerType", opt.value)}
									controlShouldRenderValue={false}
									placeholder={playerTypeSVG}
								/>
							</SelectDiv>
						</ToolbarDiv>
					)}
				</ColoredCardHeader>
				{fetching(playerType) && (
					<div className="loading-container">
						<div className="loading-item" style={{ height: "27px" }} />
						<div className="loading-item" style={{ height: "27px" }} />
						<div className="loading-item" style={{ height: "27px" }} />
						<div className="loading-item" style={{ height: "27px" }} />
						<div className="loading-item" style={{ height: "27px" }} />
						<div className="loading-item" style={{ height: "27px" }} />
					</div>
				)}
				{!fetching(playerType) && (
					<div style={{ overflow: "auto" }}>
						<StatsTableContainer
							colorScheme={colorScheme}
							data={data}
							headers={headers}
							initialSortColumn={null}
							playerType={playerType}
							sortable={false}
						/>
					</div>
				)}
			</Card>
		);
	}
}

PlayerStats.propTypes = {
	bam_id: PropTypes.number.isRequired,
	colorScheme: PropTypes.object,
	fetchAverageAges: PropTypes.func,
	fetchCareerStats: PropTypes.func,
	fetchCareerStatsAll: PropTypes.func,
	fetchSeasonStats: PropTypes.func,
	fetchSeasonStatsAll: PropTypes.func,
	fetchProjectionStats: PropTypes.func,
	fetching: PropTypes.func,
	getCareerTableData: PropTypes.func,
	getSeasonTableData: PropTypes.func,
	player: PropTypes.object,
	showCareerRows: PropTypes.bool,
	sortSeasonsDesc: PropTypes.bool,
	style: PropTypes.object,
	summary: PropTypes.bool
};

PlayerStats.defaultProps = {
	summary: false,
	showControls: true,
	showCareerRows: true
};

export default PlayerStats;
