import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
	useToast,
	VStack,
	HStack,
	Box,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	MenuItemOption,
	MenuDivider,
	Portal,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Tooltip,
	Spacer,
	Text,
	Flex
} from "@chakra-ui/react";

import { useMachine } from "@xstate/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { omit } from "utils/helpers";
import { PITCH_TYPES } from "_react/shared/_constants/pitch_types";
import { ILkPitchType } from "_react/shared/_types/phil_data/lk_pitch_type";
import { getSeasonFilters } from "_react/shared/_helpers/stats";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import { updateFilters, getMinAndMaxSeason } from "_react/shared/_helpers/stats";
import { IPlayerSeasonArsenalScoresLkLevel } from "_react/shared/data_models/arsenal_scores/_types";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import {
	IPlayerSeasonArsenalScoresSchema,
	IPlayerSeasonArsenalScoresByTeamSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import { isDefaultFilters, DEFAULT_STATS_TABLE_FILTERS } from "_react/shared/ui/data/tables/shared/Filters";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import {
	BATS_L,
	BATS_OVERALL,
	BATS_R,
	GAME_TYPE_OVERALL,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_SPRING_TRAINING,
	PITCH_TYPE_OVERALL
} from "_react/shared/data_models/arsenal_scores/_constants";
import { VALID_PRO_LEVELS } from "_react/shared/data_models/stats/_constants";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import { ICON_CIRCLE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import {
	PITCHER_FOUNDATIONAL_SKILLS_COLUMNS,
	PITCHER_FOUNDATIONAL_SKILLS_PARENT_COLUMNS,
	NUM_DISPLAY_SEASONS
} from "_react/shared/ui/data/tables/PitcherFoundationalSkillsTable/_constants";
import {
	aggregateCombinedPlayerSeasonArsenalScoresDataRows,
	getLevelsFromRow
} from "_react/shared/ui/data/tables/PitcherFoundationalSkillsTable/_helpers";
import PitcherFoundationalSkillsTableMachine, {
	TPitcherFoundationalSkillsTableContext,
	FETCHING_PLAYER_SEASON_ARSENAL_SCORES,
	FETCHING_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM,
	SET_PLAYER_ID,
	SET_PLAYER_SEASON_ARSENAL_SCORES,
	SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM,
	SET_FILTERS
} from "_react/shared/ui/data/tables/PitcherFoundationalSkillsTable/_machine";
import {
	TPitcherFoundationalSkillsTableData,
	TPitcherFoundationalSkillsRow,
	ICombinedPlayerSeasonArsenalScoresSchema
} from "_react/shared/ui/data/tables/PitcherFoundationalSkillsTable/_types";

type TPitcherFoundationalSkillsTableStyle = {
	container?: React.CSSProperties;
	tableContainer?: React.CSSProperties;
};

type TPitcherFoundationalSkillsTableProps = {
	title?: string;
	playerId?: number;
	data?: TPitcherFoundationalSkillsTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<TPitcherFoundationalSkillsRow, keyof TPitcherFoundationalSkillsRow>;
	style?: TPitcherFoundationalSkillsTableStyle;
};

const PitcherFoundationalSkillsTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TPitcherFoundationalSkillsTableProps) => {
	const toast = useToast();
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const [current, send] = useMachine(
		PitcherFoundationalSkillsTableMachine(playerIdProp, data, shouldFetchData, toast)
	);
	const isFetchingPlayerSeasonArsenalScores = current.matches(FETCHING_PLAYER_SEASON_ARSENAL_SCORES);
	const isFetchingPlayerSeasonArsenalScoresByTeam = current.matches(FETCHING_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM);
	const isLoading = isFetchingPlayerSeasonArsenalScores || isFetchingPlayerSeasonArsenalScoresByTeam;

	const context = current.context as TPitcherFoundationalSkillsTableContext;
	const { playerId, filters, playerSeasonArsenalScores, playerSeasonArsenalScoresByTeam } = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonArsenalScores !== playerSeasonArsenalScores && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_ARSENAL_SCORES, value: data?.playerSeasonArsenalScores });
	}, [send, data?.playerSeasonArsenalScores, playerSeasonArsenalScores, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonArsenalScoresByTeam !== playerSeasonArsenalScoresByTeam && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM, value: data?.playerSeasonArsenalScoresByTeam });
	}, [send, data?.playerSeasonArsenalScoresByTeam, playerSeasonArsenalScoresByTeam, shouldFetchData]);

	// Get max and min season
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<IPlayerSeasonArsenalScoresSchema>(playerSeasonArsenalScores ?? []),
		[playerSeasonArsenalScores]
	);

	//
	// Season filter options
	//
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<IPlayerSeasonArsenalScoresLkLevel> = useMemo(
		() =>
			playerSeasonArsenalScoresByTeam
				?.reduce(
					(acc: Array<IPlayerSeasonArsenalScoresLkLevel>, curr: IPlayerSeasonArsenalScoresByTeamSchema) => {
						const levelRel: IPlayerSeasonArsenalScoresLkLevel | undefined = curr.teamBam?.levelRel;
						if (
							levelRel &&
							levelRel.value &&
							!acc.some(val => val.value === levelRel.value) &&
							VALID_PRO_LEVELS.includes(levelRel.value)
						)
							acc.push(levelRel);
						return acc;
					},
					[]
				)
				?.sort(
					(a: IPlayerSeasonArsenalScoresLkLevel, b: IPlayerSeasonArsenalScoresLkLevel) =>
						(a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[playerSeasonArsenalScoresByTeam]
	);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map((option: IPlayerSeasonArsenalScoresLkLevel) => option.value)
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	//
	// Pitch type options
	//

	// Compute the pitch type options
	const pitchTypeOptions: Array<ILkPitchType> = useMemo(
		() =>
			playerSeasonArsenalScoresByTeam
				?.reduce((acc: Array<ILkPitchType>, curr: IPlayerSeasonArsenalScoresByTeamSchema) => {
					const pitchType: ILkPitchType | undefined = curr.lkPitchType;
					if (
						pitchType &&
						pitchType.abbreviation &&
						!acc.some(val => val.abbreviation === pitchType.abbreviation) &&
						PITCH_TYPES.includes(pitchType.abbreviation as TPitchTypes)
					)
						acc.push(pitchType);
					return acc;
				}, [])
				?.sort((a: ILkPitchType, b: ILkPitchType) => a.sortOrder - b.sortOrder) ?? [],
		[playerSeasonArsenalScoresByTeam]
	);

	// Once the pitch type options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.pitchTypes === undefined && pitchTypeOptions.length > 0) {
			const pitchTypeAbbreviations = pitchTypeOptions.map(
				(pitchTypeOption: ILkPitchType) => pitchTypeOption.abbreviation ?? ""
			);
			const newFilters = {
				...filters,
				pitchTypes: [...pitchTypeAbbreviations, PITCH_TYPE_OVERALL]
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, pitchTypeOptions]);

	//
	// Filter data for table
	//

	// Combine game type and bats filters into "OVR" when possible
	const gameTypeFilters: Array<string> = useMemo(() => {
		return filters.gameTypes.includes(GAME_TYPE_REGULAR_SEASON) &&
			filters.gameTypes.includes(GAME_TYPE_POSTSEASON) &&
			!filters.gameTypes.includes(GAME_TYPE_SPRING_TRAINING)
			? [GAME_TYPE_OVERALL]
			: filters.gameTypes;
	}, [filters.gameTypes]);
	const batsFilter: string = useMemo(() => (filters.bats.length === 2 ? BATS_OVERALL : filters.bats?.[0]), [
		filters.bats
	]);

	const filteredPlayerSeasonArsenalScoresData:
		| Array<IPlayerSeasonArsenalScoresSchema>
		| undefined
		| null = useMemo(() => {
		if (isLoading) return undefined;
		if (!playerSeasonArsenalScores) return playerSeasonArsenalScores;
		return playerSeasonArsenalScores.filter(
			(scores: IPlayerSeasonArsenalScoresSchema) =>
				scores.season <= seasonFilters.maxSeason &&
				scores.season >= seasonFilters.minSeason &&
				gameTypeFilters.includes(scores.gameType) &&
				scores.bats === batsFilter
		);
	}, [isLoading, seasonFilters, gameTypeFilters, batsFilter, playerSeasonArsenalScores]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		let availablePitchTypes = pitchTypeOptions.map(
			(pitchTypeOption: ILkPitchType) => pitchTypeOption.abbreviation ?? ""
		);
		availablePitchTypes = [...availablePitchTypes, PITCH_TYPE_OVERALL];
		const availableLevels = levelFilterOptions.map((option: IPlayerSeasonArsenalScoresLkLevel) => option.value);

		return isDefaultFilters(filters, availablePitchTypes, availableLevels, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, pitchTypeOptions, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, value: DEFAULT_STATS_TABLE_FILTERS });
	}, [send]);

	//
	// Combine data for table
	//

	const combinedTableData: Array<TPitcherFoundationalSkillsRow> | undefined = useMemo(() => {
		if (!filteredPlayerSeasonArsenalScoresData) return undefined;
		const combinedData: Array<TPitcherFoundationalSkillsRow> = [];
		const uniqueSeasons = [
			...new Set(
				filteredPlayerSeasonArsenalScoresData.map((scores: IPlayerSeasonArsenalScoresSchema) => scores.season)
			)
		];

		uniqueSeasons.forEach((season: number) => {
			// For each unique season get all pitch types
			const uniquePitchTypes = filteredPlayerSeasonArsenalScoresData.reduce(
				(pitchTypes: Array<string>, scores: IPlayerSeasonArsenalScoresSchema) => {
					if (scores.season === season && !pitchTypes.includes(scores.pitchType))
						pitchTypes.push(scores.pitchType);
					return pitchTypes;
				},
				[]
			);
			uniquePitchTypes.forEach((pitchType: string) => {
				// Create an object with the overall player season arsenal scores and all related player season team arsenal scores
				// For certain game type combinations, we need to aggregate the data into a single row
				const arsenalScores = filteredPlayerSeasonArsenalScoresData?.filter(
					(scores: IPlayerSeasonArsenalScoresSchema) =>
						scores.season === season && scores.pitchType === pitchType
				);
				const arsenalScoresByTeam = playerSeasonArsenalScoresByTeam?.filter(
					(scoresByTeam: IPlayerSeasonArsenalScoresByTeamSchema) =>
						scoresByTeam.season === season &&
						gameTypeFilters.includes(scoresByTeam.gameType) &&
						scoresByTeam.bats === batsFilter &&
						scoresByTeam.pitchType === pitchType
				);
				// Only one top-level row is returned, so just use those stats
				if (arsenalScores?.length === 1) {
					let combinedArsenalScores = arsenalScores[0];
					let combinedChildData = arsenalScoresByTeam?.map(
						(scoresByTeam: IPlayerSeasonArsenalScoresByTeamSchema) => {
							return { playerSeasonArsenalScores: scoresByTeam };
						}
					);
					// If there is only one child row, then we want to display it as the overall row
					if (arsenalScoresByTeam && arsenalScoresByTeam.length === 1) {
						combinedArsenalScores = arsenalScoresByTeam[0];
						combinedChildData = undefined;
					}
					combinedData.push({
						playerSeasonArsenalScores: combinedArsenalScores,
						childData: combinedChildData
					});
				}
				// Multiple top-level rows are returned, aggregate all of the data
				else if (arsenalScores?.length) {
					// Aggregate arsenal scores across different game types into a single top-level row
					let combinedArsenalScores = arsenalScores.reduce(
						(combinedScores: IPlayerSeasonArsenalScoresSchema, scores: IPlayerSeasonArsenalScoresSchema) =>
							aggregateCombinedPlayerSeasonArsenalScoresDataRows(combinedScores, scores)
					);
					// Aggregate team level arsenal scores across different game types into singular team-level rows
					let combinedChildData = arsenalScoresByTeam?.reduce(
						(
							combinedScoresByTeam: Array<TPitcherFoundationalSkillsRow>,
							scoresByTeam: IPlayerSeasonArsenalScoresByTeamSchema
						) => {
							if (!combinedScoresByTeam.length) return [{ playerSeasonArsenalScores: scoresByTeam }];
							const combinedScoresMatchingTeamIndex = combinedScoresByTeam.findIndex(
								(combinedScores: TPitcherFoundationalSkillsRow) =>
									"teamId" in combinedScores.playerSeasonArsenalScores &&
									combinedScores.playerSeasonArsenalScores.teamId === scoresByTeam.teamId
							);
							if (combinedScoresMatchingTeamIndex === -1)
								combinedScoresByTeam.push({ playerSeasonArsenalScores: scoresByTeam });
							else {
								const combinedScoresMatchingTeamArsenalScores =
									combinedScoresByTeam[combinedScoresMatchingTeamIndex].playerSeasonArsenalScores;
								combinedScoresByTeam[combinedScoresMatchingTeamIndex] = {
									playerSeasonArsenalScores: aggregateCombinedPlayerSeasonArsenalScoresDataRows(
										combinedScoresMatchingTeamArsenalScores,
										scoresByTeam
									)
								};
							}
							return combinedScoresByTeam;
						},
						[]
					);
					// If there is only one child row, then we want to display it as the overall row
					if (combinedChildData && combinedChildData.length === 1) {
						combinedArsenalScores = combinedChildData[0].playerSeasonArsenalScores;
						combinedChildData = undefined;
					}
					combinedData.push({
						playerSeasonArsenalScores: combinedArsenalScores,
						childData: combinedChildData
					});
				}
			});
		});

		// Apply level filters. Cannot apply pitch type filters at this point, as they are used to calculate the usage % dict
		const filteredCombinedData: TPitcherFoundationalSkillsRow[] = combinedData.reduce(
			(acc: TPitcherFoundationalSkillsRow[], data: TPitcherFoundationalSkillsRow) => {
				const uniqueLevels: Array<string> = getLevelsFromRow(data);
				const shouldDisplayEntireRow = uniqueLevels.every(level => filters.levels?.includes(level));

				// If all of the levels associated with the row meet the level filters, push the entire row and return early
				if (shouldDisplayEntireRow) {
					acc.push(data);
					return acc;
				}

				// Otherwise, filter the child data and create a new parent row.
				const filteredChildData = data.childData?.filter((c: TPitcherFoundationalSkillsRow) =>
					filters.levels?.includes(
						"teamBam" in c.playerSeasonArsenalScores ? c.playerSeasonArsenalScores.teamBam?.level ?? "" : ""
					)
				);
				// If exactly one item in child data, that becomes parent row
				if (filteredChildData && filteredChildData?.length === 1)
					acc.push({
						playerSeasonArsenalScores: filteredChildData[0].playerSeasonArsenalScores,
						childData: undefined
					});
				// Otherwise, if there multiple items in child data, we must aggregate the child data to create a new parent row
				else if (filteredChildData && filteredChildData?.length > 1) {
					const newParentRow: ICombinedPlayerSeasonArsenalScoresSchema = filteredChildData.reduce(
						(
							prev: ICombinedPlayerSeasonArsenalScoresSchema,
							curr: TPitcherFoundationalSkillsRow,
							index: number
						) => {
							if (index === 0) return prev;
							return aggregateCombinedPlayerSeasonArsenalScoresDataRows(
								prev,
								curr.playerSeasonArsenalScores
							);
						},
						filteredChildData[0].playerSeasonArsenalScores
					);
					// Remove teamId, teamBam props from row before pushing
					const omittedParentRow = omit(newParentRow, [
						"teamId",
						"teamBam"
					]) as ICombinedPlayerSeasonArsenalScoresSchema;
					acc.push({
						playerSeasonArsenalScores: omittedParentRow,
						childData: filteredChildData
					});
				}
				return acc;
			},
			[]
		);

		// After filtering by level, cache totals for usage calculations
		// Mapping: season-teamId -> total pitches thrown for PITCH_TYPE_OVERALL
		const usageDict: Record<string, number> = {};
		filteredCombinedData.forEach((row: TPitcherFoundationalSkillsRow) => {
			// Add data from top level row
			if (
				row.playerSeasonArsenalScores.total != null &&
				row.playerSeasonArsenalScores.pitchType === PITCH_TYPE_OVERALL
			)
				usageDict[
					`${row.playerSeasonArsenalScores.season}-${
						"teamId" in row.playerSeasonArsenalScores ? row.playerSeasonArsenalScores.teamId : undefined
					}`
				] = row.playerSeasonArsenalScores.total;
			// Add data from child rows
			row.childData?.forEach((row: TPitcherFoundationalSkillsRow) => {
				if (
					row.playerSeasonArsenalScores.total != null &&
					"teamId" in row.playerSeasonArsenalScores &&
					row.playerSeasonArsenalScores.pitchType === PITCH_TYPE_OVERALL
				)
					usageDict[`${row.playerSeasonArsenalScores.season}-${row.playerSeasonArsenalScores.teamId}`] =
						row.playerSeasonArsenalScores.total;
			});
		});

		// Instead of just returning the rows, filter pitch types and augment each row with the usage % calculation
		return filteredCombinedData.reduce(
			(acc: TPitcherFoundationalSkillsRow[], data: TPitcherFoundationalSkillsRow) => {
				// Filter pitch types
				if (!filters.pitchTypes?.includes(data.playerSeasonArsenalScores.pitchType)) return acc;

				/* Generate new array of child rows */

				const newChildData = data.childData?.map((childData: TPitcherFoundationalSkillsRow) => {
					// Create the key for total pitch lookup based on season and team id
					const key = `${childData.playerSeasonArsenalScores.season}-${
						(childData.playerSeasonArsenalScores as IPlayerSeasonArsenalScoresByTeamSchema).teamId
					}`;
					// Lookup total in cache
					const usageTotal = usageDict[key];
					// Check if this is an overall row (for which, don't display since it's always 100%)
					const isOverallRow = childData.playerSeasonArsenalScores.pitchType === PITCH_TYPE_OVERALL;
					return {
						playerSeasonArsenalScores: {
							...childData.playerSeasonArsenalScores,
							// Augment with usage calculation (total pitches for this row divided by total pitches for season-teamId)
							usage: isOverallRow
								? null
								: usageTotal
								? (childData.playerSeasonArsenalScores.total ?? 0) / usageTotal
								: null
						}
					};
				});

				/* Augment parent row with usage calculation */

				// Create the key for total pitch lookup based on season and team id
				const key = `${data.playerSeasonArsenalScores.season}-${
					(data.playerSeasonArsenalScores as IPlayerSeasonArsenalScoresByTeamSchema).teamId
				}`;
				// Lookup total in cache
				const usageTotal = usageDict[key];
				// Check if this is an overall row (for which, don't display since it's always 100%)
				const isOverallRow = data.playerSeasonArsenalScores.pitchType === PITCH_TYPE_OVERALL;
				acc.push({
					// Replace child rows with new augmented rows generated above
					childData: newChildData,
					playerSeasonArsenalScores: {
						...data.playerSeasonArsenalScores,
						// Augment with usage calculation (total pitches for this row divided by total pitches for season-teamId)
						usage: isOverallRow
							? null
							: usageTotal
							? (data.playerSeasonArsenalScores.total ?? 0) / usageTotal
							: null
					}
				});
				return acc;
			},
			[]
		);
	}, [
		filters.levels,
		filters.pitchTypes,
		batsFilter,
		gameTypeFilters,
		filteredPlayerSeasonArsenalScoresData,
		playerSeasonArsenalScoresByTeam
	]);

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns) return PITCHER_FOUNDATIONAL_SKILLS_COLUMNS;
		return PITCHER_FOUNDATIONAL_SKILLS_COLUMNS.filter(
			(col: TColumn<TPitcherFoundationalSkillsRow, keyof TPitcherFoundationalSkillsRow>) =>
				columns.includes(col.value)
		);
	}, [columns]);

	// Filtering
	const handleGameTypeSelect = (value: string) => {
		const newFilters = {
			...filters,
			gameTypes: updateFilters(filters.gameTypes, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleBatsSelect = (value: string) => {
		const newFilters = {
			...filters,
			bats: updateFilters(filters.bats, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handlePitchTypesSelect = (value: string) => {
		const newFilters = {
			...filters,
			pitchTypes: updateFilters(filters.pitchTypes ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
				</HStack>
				{isShowFilters && (
					<Menu closeOnSelect={false} placement="left-start">
						<ButtonGroup
							isAttached
							variant={defaultFiltersSet ? "outline" : "solid"}
							colorScheme={defaultFiltersSet ? undefined : "blue"}
						>
							{!defaultFiltersSet && (
								<IconButton
									aria-label="Close"
									icon={<CloseIcon fill="white" />}
									onClick={resetFilters}
								/>
							)}
							<MenuButton
								as={IconButton}
								aria-label="Options"
								icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
							>
								MenuItem
							</MenuButton>
						</ButtonGroup>
						<Portal>
							<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
								<MenuOptionGroup title="Bats" type="checkbox" value={filters.bats}>
									<MenuItemOption value={BATS_L} onClick={() => handleBatsSelect(BATS_L)}>
										Left
									</MenuItemOption>
									<MenuItemOption value={BATS_R} onClick={() => handleBatsSelect(BATS_R)}>
										Right
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuDivider />
								<MenuOptionGroup title="Game Type" type="checkbox" value={filters.gameTypes}>
									<MenuItemOption
										value={GAME_TYPE_SPRING_TRAINING}
										onClick={() => handleGameTypeSelect(GAME_TYPE_SPRING_TRAINING)}
									>
										Spring Training
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_REGULAR_SEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_REGULAR_SEASON)}
									>
										Regular Season
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_POSTSEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_POSTSEASON)}
									>
										Postseason
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuOptionGroup title="Seasons">
									<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
										{minSeason === maxSeason && (
											<Tooltip hasArrow placement="top" label="Only one season of data exists">
												<HStack>
													<OutlineInfo color="gray.500" />
													<Text>{minSeason}</Text>
												</HStack>
											</Tooltip>
										)}
										{minSeason !== maxSeason && (
											<VStack>
												<RangeSlider
													value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
													min={minSeason}
													max={maxSeason}
													step={1}
													onChange={(seasons: number[]) => {
														send({
															type: SET_FILTERS,
															value: {
																...filters,
																minSeason: seasons[0],
																maxSeason: seasons[1]
															}
														});
													}}
													onMouseEnter={() => setShowSeasonRangeTooltip(true)}
													onMouseLeave={() => setShowSeasonRangeTooltip(false)}
												>
													<RangeSliderTrack>
														<RangeSliderFilledTrack bg="black" />
													</RangeSliderTrack>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.minSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
													</Tooltip>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.maxSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
													</Tooltip>
												</RangeSlider>
												<Flex sx={{ width: "100%" }}>
													<Text fontSize="sm">{minSeason}</Text>
													<Spacer />
													<Text fontSize="sm">{maxSeason}</Text>
												</Flex>
											</VStack>
										)}
									</VStack>
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Pitch Types"
									type="checkbox"
									value={filters.pitchTypes ?? [PITCH_TYPE_OVERALL, ...PITCH_TYPES]}
								>
									<MenuItemOption
										value={PITCH_TYPE_OVERALL}
										onClick={() => handlePitchTypesSelect(PITCH_TYPE_OVERALL)}
									>
										Overall
									</MenuItemOption>
									{pitchTypeOptions.map((pitchType: ILkPitchType) => (
										<MenuItemOption
											value={pitchType.abbreviation ?? ""}
											onClick={() => handlePitchTypesSelect(pitchType.abbreviation ?? "")}
										>
											<PitchTypeLabel
												label={pitchType.label ?? ""}
												abbreviation={pitchType.abbreviation ?? ""}
												shape={ICON_CIRCLE}
											/>
										</MenuItemOption>
									))}
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Levels"
									type="checkbox"
									value={filters.levels ?? VALID_PRO_LEVELS}
								>
									{levelFilterOptions.map((option: IPlayerSeasonArsenalScoresLkLevel) => (
										<MenuItemOption
											value={option.value}
											onClick={() => handleLevelSelect(option.value)}
										>
											<TeamLevelBadge level={option.value} />
										</MenuItemOption>
									))}
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
				)}
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<TPitcherFoundationalSkillsRow, keyof TPitcherFoundationalSkillsRow>
					columns={filteredColumns}
					parentColumns={PITCHER_FOUNDATIONAL_SKILLS_PARENT_COLUMNS}
					data={combinedTableData}
					emptyDataDisplayText={"No Foundational Skills Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					isExpandableRows
					getCustomRowKeyFunction={(row: TPitcherFoundationalSkillsRow) => {
						return `${row.playerSeasonArsenalScores.season}-${row.playerSeasonArsenalScores.pitchType}`;
					}}
					defaultSortColumns={[
						{
							columnValue: "pitchType",
							sortDirection: ASC
						},
						{
							columnValue: "season",
							sortDirection: ASC
						},
						{
							columnValue: "level",
							sortDirection: DESC
						}
					]}
					getRowStyleFunction={(
						obj: TPitcherFoundationalSkillsRow,
						index: number,
						data: Array<TPitcherFoundationalSkillsRow>
					) => {
						if (
							index < data.length - 1 &&
							obj.playerSeasonArsenalScores.season !== data[index + 1].playerSeasonArsenalScores.season
						) {
							return {
								borderBottom: "1px solid !important",
								borderBottomColor: "gray.300 !important"
							};
						}
						return {};
					}}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default PitcherFoundationalSkillsTable;
