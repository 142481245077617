import React, { useMemo, useCallback } from "react";
import { Annotation, Connector, Label } from "@visx/annotation";
import { AxisBottom } from "@visx/axis";
import { curveNatural } from "@visx/curve";
import { GridColumns } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { AreaClosed, Line, LinePath } from "@visx/shape";
import { TextProps } from "@visx/text";
import { NumberValue } from "d3";

import { getExtent, getMiddleValue, getPlotDimensions } from "_react/shared/dataviz/_helpers";
import { DEFAULT_FILL_OPACITY } from "_react/shared/dataviz/_constants";
import { useDataVizColors, useAxisLabelProps } from "_react/shared/dataviz/_hooks";
import { TAxisExtrema, TMarginsProps } from "_react/shared/dataviz/_types";
import { KeysOfValue } from "_react/shared/_types/generics";

type TXAxisExtremaProps = TAxisExtrema<number> & {
	isOverrideDistributionExtrema?: boolean;
	isOverridePlayerValue?: boolean;
};

type TXAxisProps = {
	xLabel?: string;
	extrema?: TXAxisExtremaProps;
	isShowXAxisGridLines?: boolean;
	isXAxisReverse?: boolean;
	// Type should be: (value: number) => number | string | undefined
	// But visx throws errors related to the type TickFormatter<NumberValue>
	tickFormat?: Function;
	tickLabelProps?: Partial<TextProps>;
};

type TGradeDistributionPlotProps<T extends object> = {
	distributionData?: Array<T>;
	distributionX: KeysOfValue<T, number> | string;
	getDistributionXFunction?: (obj: T) => number;
	distributionY: KeysOfValue<T, number> | string;
	getDistributionYFunction?: (obj: T) => number;
	playerValue?: number | null;
	playerValueLabel?: string | null;
	playerDistributionData?: Array<T>;
	xAxis?: TXAxisProps;
	defaultYAxisExtrema?: TAxisExtrema<number>;
	// TODO: Decide if we want to add this prop or change the background color on our plots
	backgroundColor?: string;
	// TODO: Need to make this responsive to parent but want to wait until parent components are created
	width?: number;
	height?: number;
	margins?: TMarginsProps;
};

export const GradeDistributionPlot = <T extends object>({
	distributionData,
	distributionX,
	getDistributionXFunction,
	distributionY,
	getDistributionYFunction,
	playerValue,
	playerValueLabel,
	playerDistributionData,
	xAxis,
	defaultYAxisExtrema,
	backgroundColor: backgroundColorProp,
	width,
	height,
	margins
}: TGradeDistributionPlotProps<T>) => {
	// STYLING SETUP
	const {
		axisColor,
		backgroundColor,
		dataColorPrimaryBlue,
		dataColorPrimaryGray,
		gridStrokeColor
	} = useDataVizColors();
	const axisLabelProps = useAxisLabelProps();

	// SIZING SETUP
	const {
		width: WIDTH,
		height: HEIGHT,
		margins: MARGIN,
		innerWidth: INNER_WIDTH,
		innerHeight: INNER_HEIGHT
	} = getPlotDimensions(width, height, margins);

	// DATA SETUP
	const getXValues = useCallback(
		(datum: T) =>
			getDistributionXFunction
				? getDistributionXFunction(datum)
				: ((datum[distributionX as keyof T] as unknown) as number),
		[getDistributionXFunction, distributionX]
	);
	const getYValues = (datum: T) =>
		getDistributionYFunction
			? getDistributionYFunction(datum)
			: ((datum[distributionY as keyof T] as unknown) as number);

	const [xMin, xMax] = useMemo(() => {
		const [xMinDefault, xMaxDefault] = getExtent<number>(
			xAxis?.extrema?.min ?? 20,
			xAxis?.extrema?.max ?? 80,
			distributionData?.map((datum: T) => getXValues(datum) as number)
		);
		const xMin =
			xAxis?.extrema?.isOverrideDistributionExtrema && xAxis?.extrema?.min ? xAxis?.extrema.min : xMinDefault;
		const xMax =
			xAxis?.extrema?.isOverrideDistributionExtrema && xAxis?.extrema?.max ? xAxis?.extrema.max : xMaxDefault;
		if (!xAxis?.extrema?.isOverridePlayerValue) {
			if (playerValue != null && xMax < playerValue) return [xMin, playerValue];
			if (playerValue != null && xMin > playerValue) return [playerValue, xMax];
		}
		return [xMin, xMax];
	}, [playerValue, xAxis?.extrema, distributionData, getXValues]);

	const xMiddle = getMiddleValue(xMin, xMax);

	const [yMin, yMax] = getExtent<number>(
		defaultYAxisExtrema?.min ?? 0,
		defaultYAxisExtrema?.max ?? 0.5,
		distributionData?.map((datum: T) => getYValues(datum) as number)
	);
	const yMiddle = getMiddleValue(yMin, yMax);

	// AXES SETUP
	const xScale = scaleLinear({
		domain: xAxis?.isXAxisReverse ? [xMax, xMin] : [xMin, xMax],
		range: [0, INNER_WIDTH],
		nice: true // If we turn this off, we need to update the `AxisBottom.tickValues` below
	});
	const yScale = scaleLinear({ domain: [yMin, yMax], range: [INNER_HEIGHT, 0], nice: true });

	// The min of the y axis may be a little lower than the data y min because nice = true for the axis scale
	// This gets the y axis min so that the player value line goes all the way to bottom of the y axis
	const yAxisMin = yScale.domain()[0];

	return (
		<>
			<svg width={WIDTH} height={HEIGHT}>
				<rect x={0} y={0} width={WIDTH} height={HEIGHT} fill={backgroundColorProp ?? backgroundColor} />
				<Group left={MARGIN.left} top={MARGIN.top}>
					{xAxis?.isShowXAxisGridLines && (
						<GridColumns
							scale={xScale}
							width={INNER_WIDTH}
							height={INNER_HEIGHT}
							stroke={gridStrokeColor}
							numTicks={7}
						/>
					)}
					<AxisBottom
						scale={xScale}
						top={INNER_HEIGHT}
						label={xAxis?.xLabel}
						labelProps={axisLabelProps}
						stroke={axisColor}
						tickStroke={axisColor}
						hideTicks={true}
						tickLength={2}
						tickFormat={
							xAxis?.tickFormat
								? (value: NumberValue) =>
										xAxis.tickFormat ? xAxis.tickFormat(value as number) : undefined
								: undefined
						}
						tickLabelProps={xAxis?.tickLabelProps}
						tickValues={xScale.ticks(7).filter((tick: number) => Number.isInteger(tick))}
					/>
					{distributionData && (
						<>
							<LinePath
								curve={curveNatural}
								data={distributionData}
								x={(datum: T) => xScale(getXValues(datum) as number)}
								y={(datum: T) => yScale(getYValues(datum) as number)}
								stroke={dataColorPrimaryBlue}
								strokeWidth={1}
								strokeOpacity={0.7}
								shapeRendering="geometricPrecision"
							/>
							<AreaClosed
								yScale={yScale}
								data={distributionData}
								x={(datum: T) => xScale(getXValues(datum) as number)}
								y={(datum: T) => yScale(getYValues(datum) as number)}
								fill={dataColorPrimaryBlue}
								fillOpacity={DEFAULT_FILL_OPACITY}
								curve={curveNatural}
							/>
						</>
					)}
					{playerValue != null && (
						<>
							<Line
								from={{
									x: xScale(playerValue),
									y: yScale(yAxisMin)
								}}
								to={{
									x: xScale(playerValue),
									y: yScale(yMax)
								}}
								stroke={dataColorPrimaryGray}
								strokeWidth={3}
								strokeOpacity={0.8}
							/>
							{playerValueLabel && (
								<Annotation x={xScale(playerValue)} y={yScale(yMiddle - yMiddle / 2)} dx={15}>
									{/* TODO: Add this color to our semantic tokens once we've finalized it */}
									<Connector type="line" stroke={"#A3A3A3"} />
									<Label
										subtitleFontSize={10}
										showAnchorLine={false}
										fontColor={"#404040"}
										showBackground={false}
										horizontalAnchor={playerValue <= xMiddle ? "start" : "end"}
										backgroundPadding={
											playerValue <= xMiddle ? { left: 5, right: 5 } : { left: 5, right: 5 }
										}
										subtitle={playerValueLabel}
										width={100}
									/>
								</Annotation>
							)}
						</>
					)}
					{playerDistributionData && (
						<>
							<LinePath
								curve={curveNatural}
								data={playerDistributionData}
								x={(datum: T) => xScale(getXValues(datum) as number)}
								y={(datum: T) => yScale(getYValues(datum) as number)}
								stroke={dataColorPrimaryGray}
								strokeWidth={1}
								strokeOpacity={0.8}
								shapeRendering="geometricPrecision"
							/>
							<AreaClosed
								yScale={yScale}
								data={playerDistributionData}
								x={(datum: T) => xScale(getXValues(datum) as number)}
								y={(datum: T) => yScale(getYValues(datum) as number)}
								fill={dataColorPrimaryGray}
								fillOpacity={DEFAULT_FILL_OPACITY}
								curve={curveNatural}
							/>
						</>
					)}
				</Group>
			</svg>
		</>
	);
};

export default GradeDistributionPlot;
