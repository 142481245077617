import React from "react";
import Plotly from "plotly.js/lib/core";
import createPlotlyComponent from "react-plotly.js/factory";

import dayjs from "dayjs";

import { $TSAnyRequired } from "utils/tsutils";
import { DATA_COLOR_PRIMARY_ARRAY_HEX } from "_react/shared/dataviz/_constants";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import SwapHorizontalCircle from "_react/shared/legacy/ui/SwapHorizontalCircle";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import { transaction } from "_react/shared/_types/mesa/transaction";

import {
	GoalToggleContainer,
	GoalChartContainer,
	GoalToggleLabelContainer,
	NoGoalGraphDiv
} from "_react/playerplan/shared/_styles";
import {
	TPlayerPlanGoal,
	TOverlayMockGoal,
	TPlayerPlanMetricScaleLabel,
	TPlayerAppearance
} from "_react/playerplan/shared/_types";
import {
	getGoalMetricPlotData,
	getNotesPlotData,
	getTargetPlotData,
	getTransactionsPlotData,
	getOverlayScaleLabel,
	getScaleLabel,
	getAxisRange,
	getAxisTick0,
	getAxisTickFormat,
	getAxisDTick,
	parseSplits,
	parseOverlaySplits,
	getPlayerAppearanceDates,
	getLeftAxisLabel,
	getRightAxisLabel,
	shouldDisplayGraph
} from "_react/playerplan/shared/_helpers";
import {
	HIT,
	LABEL_20_80,
	MEAN_20_80,
	HIT_OVERLAY_LABEL,
	PITCH_OVERLAY_LABEL,
	VERTICAL_BAR_AXIS_SCALE
} from "_react/playerplan/shared/_constants";

const completePlotly: $TSAnyRequired = Plotly;

// eslint-disable-next-line @typescript-eslint/no-var-requires
completePlotly.register(require("plotly.js/lib/scatter"));

// Cannot use types because many attributes are missing:
const Plot: $TSAnyRequired = createPlotlyComponent(Plotly);

export type TGoalChartProps = {
	goal: TPlayerPlanGoal;
	selectedGoalSeason: number;
	secondaryGoal?: TPlayerPlanGoal;
	transactions: Array<transaction> | null;
	isSeasonValue: boolean;
	setIsSeasonValue: (isSeasonValue: boolean) => void;
	colorSchemeGroup?: ColorSchemeGroup;
};

export const GoalChart = ({
	goal,
	selectedGoalSeason,
	secondaryGoal,
	transactions,
	isSeasonValue,
	setIsSeasonValue,
	colorSchemeGroup = defaultColorScheme
}: TGoalChartProps) => {
	// scale used for goal metric
	const yScale: TPlayerPlanMetricScaleLabel = getScaleLabel(goal);
	// scale used for overlay metric
	const y3Scale: TPlayerPlanMetricScaleLabel = getOverlayScaleLabel(goal.overlayMockGoals);

	const plotlyData: Plotly.Data[] = [];
	const splitValues: string[] = [];
	const splitLabels: string[] = [];

	// get split label for the goal metric
	// also populates split values/split labels to be passed to parseOverlaySplits()
	const goalSplitLabel = parseSplits(goal.playerPlanSplits, splitValues, splitLabels);

	// Filter player appearances by selected season
	const filteredPlayerAppearances = goal.playerAppearances?.filter(
		(appearance: TPlayerAppearance) => +appearance.date.substring(0, 4) === selectedGoalSeason
	);
	const appearanceDates = getPlayerAppearanceDates(filteredPlayerAppearances);

	// Min date based on first appearance, fallback to start of selected season otherwise
	const minDate = appearanceDates.length ? dayjs(appearanceDates[0]) : dayjs(`${selectedGoalSeason}-1-1`);

	// Max date based on last appearance
	// If selected year matches this year, fallback to today
	// Otherwise, fallback to end of selected season
	const maxDate = appearanceDates.length
		? dayjs(appearanceDates[appearanceDates.length - 1])
		: selectedGoalSeason === dayjs().year()
		? dayjs().startOf("day")
		: dayjs(`${selectedGoalSeason}-12-31`);

	// Add Goal Metric
	if (goal.playerPlanMetricValues) {
		const goalData: Plotly.Data = getGoalMetricPlotData(
			goal.metricScale,
			goal.playerPlanMetricValues,
			goal.playerPlanMetric.shouldScale,
			goalSplitLabel,
			goal.playerPlanMetric.label,
			goal.playerPlanMetric.rollingAveragePeriodDays,
			filteredPlayerAppearances,
			isSeasonValue,
			goal.playerPlanMetric.format
		);
		plotlyData.push({
			...goalData,
			name: `${goal.playerPlanMetric.label}${goalSplitLabel ? ` (${goalSplitLabel})` : ""}`,
			hoverinfo: "text",
			mode: "lines+markers",
			line: { color: DATA_COLOR_PRIMARY_ARRAY_HEX[0], width: 2.5 },
			marker: { symbol: 0, color: DATA_COLOR_PRIMARY_ARRAY_HEX[0] }
		} as Plotly.Data);
	}

	// Add Targets
	const targetData: Plotly.Data | null = getTargetPlotData(
		goal?.playerPlanTargets,
		minDate,
		maxDate,
		appearanceDates,
		goal.playerPlanMetric.label
	);
	if (targetData) {
		plotlyData.push({
			...targetData,
			hoverinfo: "text",
			name: `${goal.playerPlanMetric.label} Target`,
			yaxis: "y1",
			type: "scatter",
			mode: "lines+markers",
			marker: { symbol: 0, color: DATA_COLOR_PRIMARY_ARRAY_HEX[0].concat("AA") },
			line: {
				dash: "dash",
				width: 5,
				color: DATA_COLOR_PRIMARY_ARRAY_HEX[0].concat("55")
			},
			visible: true
		} as Plotly.Data);
	}

	// Add Secondary Goal Metric
	if (secondaryGoal && secondaryGoal.playerPlanMetricValues) {
		const goalData: Plotly.Data = getGoalMetricPlotData(
			secondaryGoal.metricScale,
			secondaryGoal.playerPlanMetricValues,
			secondaryGoal.playerPlanMetric.shouldScale,
			goalSplitLabel,
			secondaryGoal.playerPlanMetric.label,
			secondaryGoal.playerPlanMetric.rollingAveragePeriodDays,
			filteredPlayerAppearances,
			isSeasonValue,
			secondaryGoal.playerPlanMetric.format
		);
		plotlyData.push({
			...goalData,
			name: `${secondaryGoal.playerPlanMetric.label}${goalSplitLabel ? ` (${goalSplitLabel})` : ""}`,
			hoverinfo: "text",
			mode: "lines+markers",
			line: { color: DATA_COLOR_PRIMARY_ARRAY_HEX[1], width: 2.5 },
			// If primary goal has a metric scale, put the secondary goal on the right axis
			// Otherwise, put it on the left axis
			yaxis: goal.metricScale ? "y3" : "y1",
			marker: { symbol: 1, color: DATA_COLOR_PRIMARY_ARRAY_HEX[1] }
		} as Plotly.Data);
	}

	// Add Secondary Targets
	const secondaryTargetData: Plotly.Data | null = getTargetPlotData(
		secondaryGoal?.playerPlanTargets ?? [],
		minDate,
		maxDate,
		appearanceDates,
		secondaryGoal?.playerPlanMetric.label
	);
	if (secondaryGoal && secondaryTargetData) {
		plotlyData.push({
			...secondaryTargetData,
			hoverinfo: "text",
			name: `${secondaryGoal.playerPlanMetric.label} Target`,
			yaxis: goal.metricScale ? "y3" : "y1",
			type: "scatter",
			mode: "lines+markers",
			marker: { symbol: 1, color: DATA_COLOR_PRIMARY_ARRAY_HEX[1].concat("AA") },
			line: {
				dash: "dash",
				width: 5,
				color: DATA_COLOR_PRIMARY_ARRAY_HEX[1].concat("55")
			},
			visible: true
		} as Plotly.Data);
	}

	// Append overlay mock goals
	goal.overlayMockGoals?.forEach((overlayMockGoal: TOverlayMockGoal, index: number) => {
		const overlaySplitLabel = parseOverlaySplits(overlayMockGoal.splitLks, splitValues, splitLabels);
		const overlayGoalData: Plotly.Data = getGoalMetricPlotData(
			overlayMockGoal.metricScale,
			overlayMockGoal.playerPlanMetricValues,
			overlayMockGoal.shouldScaleOverlay,
			overlaySplitLabel,
			overlayMockGoal.label,
			overlayMockGoal.rollingAveragePeriodDays,
			filteredPlayerAppearances,
			isSeasonValue,
			overlayMockGoal.format
		);
		plotlyData.push({
			...overlayGoalData,
			name: `${overlayMockGoal.label}${overlaySplitLabel ? ` (${overlaySplitLabel})` : ""}`,
			legendgroup: `${index + 2}`,
			// Overlays go on the opposite side as the secondary goal if it exists
			// Overlays go on the right side of the graph if secondary goal does not exist
			yaxis: secondaryGoal && goal.metricScale ? "y1" : "y3",
			type: "scatter",
			mode: "lines+markers",
			line: { color: DATA_COLOR_PRIMARY_ARRAY_HEX[index + 2] },
			marker: { symbol: `${index + 2}`, color: DATA_COLOR_PRIMARY_ARRAY_HEX[index + 2] },
			hoverinfo: "text",
			opacity: 0.75,
			visible: "legendonly"
		} as Plotly.Data);
	});

	// League Average for 20-80 Scale
	if (goal.overlayMockGoals && y3Scale === LABEL_20_80) {
		const avgText = `${
			goal.playerPlanMetric.metricType.value === HIT ? HIT_OVERLAY_LABEL : PITCH_OVERLAY_LABEL
		}<br>MLB Avg: ${MEAN_20_80}`;
		plotlyData.push({
			x: [minDate.toDate(), maxDate.toDate()],
			y: [MEAN_20_80, MEAN_20_80],
			text: [avgText, avgText],
			name: `${
				goal.playerPlanMetric.metricType.value === HIT ? HIT_OVERLAY_LABEL : PITCH_OVERLAY_LABEL
			} MLB Average`,
			hoverinfo: "text",
			yaxis: goal.metricScale ? "y1" : "y3",
			type: "scatter",
			mode: "lines+markers",
			line: { dash: "dash" },
			marker: { color: "black" },
			opacity: 0.25,
			visible: "legendonly"
		});
	}

	// Transactions
	const transactionsData: Plotly.Data = getTransactionsPlotData(
		transactions,
		minDate,
		maxDate,
		["Injured List", "Assignments"],
		false
	);
	const transactionsDataMisc: Plotly.Data = getTransactionsPlotData(
		transactions,
		minDate,
		maxDate,
		["Injured List", "Assignments"],
		true
	);

	const additionalTransactionsData: Plotly.Data = {
		hoverinfo: "text",
		yaxis: "y2",
		type: "scatter",
		mode: "lines+markers",
		marker: { color: "black" },
		line: {
			dash: "dot",
			width: 0.75
		}
	};

	plotlyData.push(
		{
			...transactionsData,
			...additionalTransactionsData,
			name: "Transactions (IL/Assignments)",
			opacity: 0.5
		} as Plotly.Data,
		{
			...transactionsDataMisc,
			...additionalTransactionsData,
			name: "Transactions (Misc)",
			opacity: 0.25,
			visible: "legendonly"
		} as Plotly.Data
	);

	// Notes
	const notesData: Plotly.Data = getNotesPlotData(goal?.playerPlanNotes, minDate);
	plotlyData.push({
		...notesData,
		hoverinfo: "text",
		name: "Notes",
		yaxis: "y2",
		type: "scatter",
		mode: "lines+markers",
		marker: { color: "blue" },
		line: {
			dash: "solid",
			width: 2.5,
			color: "rgba(31, 119, 180, 0.25)" // COLOR_LIST[0] to rgb
		},
		opacity: 0.5,
		visible: "legendonly"
	} as Plotly.Data);

	return (
		<>
			{goal.playerPlanMetric.rollingAveragePeriodDays > 0 && (
				<GoalToggleContainer>
					<GoalToggleLabelContainer>
						<b>
							{selectedGoalSeason} {isSeasonValue ? "Full Season Value" : "Rolling Average"}
						</b>
					</GoalToggleLabelContainer>
					<Tooltip
						title={isSeasonValue ? "View Rolling Average" : "View Full Season Value"}
						placement="right"
						colorScheme={colorSchemeGroup.primary}
					>
						<SwapHorizontalCircle
							onClick={() => {
								setIsSeasonValue(!isSeasonValue);
							}}
						/>
					</Tooltip>
				</GoalToggleContainer>
			)}
			{shouldDisplayGraph(goal, secondaryGoal, selectedGoalSeason) ? (
				<GoalChartContainer>
					<Plot
						// setting style to {} required to enable resizing
						style={{}}
						useResizeHandler={true}
						config={{ responsive: true, modeBarButtonsToRemove: ["select2d", "lasso2d"] }} // remove unnecessary buttons
						data={plotlyData}
						options={{}}
						onClick={function(data: Plotly.PlotMouseEvent) {
							if (filteredPlayerAppearances) {
								const index = filteredPlayerAppearances?.findIndex(playerAppearance => {
									return playerAppearance.date === data.points[0].x;
								});
								if (filteredPlayerAppearances[index]?.gamePk && data.points[0].data.yaxis !== "y2") {
									// disable for Notes/Transactions
									window.open(`/game?id=${filteredPlayerAppearances[index].gamePk}`);
								}
							}
						}}
						layout={{
							xaxis: {
								ticks: "inside",
								showgrid: false
							},
							// goal metric axis
							yaxis: {
								// set range, tick0, tickformat, dtick based on yScale
								range: getAxisRange(yScale),
								tick0: getAxisTick0(yScale),
								tickformat: getAxisTickFormat(yScale, goal.playerPlanMetric.format),
								dtick: getAxisDTick(yScale),
								showgrid: false,
								zerolinecolor: DATA_COLOR_PRIMARY_ARRAY_HEX[0],
								title: `<b>${getLeftAxisLabel(goal, secondaryGoal)}</b>`,
								titlefont: { color: DATA_COLOR_PRIMARY_ARRAY_HEX[0] }
							},
							// transactions/notes axis
							yaxis2: {
								zeroline: false,
								showgrid: false,
								fixedrange: true,
								range: VERTICAL_BAR_AXIS_SCALE,
								visible: false,
								anchor: "free",
								overlaying: "y"
							},
							// overlay metrics axis
							yaxis3: {
								// If overlay metrics will be on this axis, set range, tick 0, tickformat, and dtick based on y3Scale
								range: yScale && secondaryGoal ? undefined : getAxisRange(y3Scale),
								tick0: yScale && secondaryGoal ? undefined : getAxisTick0(y3Scale),
								tickformat: yScale && secondaryGoal ? undefined : getAxisTickFormat(y3Scale, null),
								dtick: yScale && secondaryGoal ? undefined : getAxisDTick(y3Scale),
								title: `<b>${getRightAxisLabel(goal, secondaryGoal)}</b>`,
								showgrid: false,
								anchor: "x",
								overlaying: "y",
								side: "right",
								// If Tier 2 metrics will be on this axis, set axis colors based on the Tier 2 line color
								zerolinecolor: yScale && secondaryGoal ? DATA_COLOR_PRIMARY_ARRAY_HEX[1] : undefined,
								titlefont:
									yScale && secondaryGoal ? { color: DATA_COLOR_PRIMARY_ARRAY_HEX[1] } : undefined
							},
							legend: {
								orientation: "h",
								y: -0.15 // Moves the legend 15% down to prevent x-axis overlap
							},
							margin: {
								t: 30 // Increases the height, while still having the scroll bar be accessible
							},
							hoverlabel: {
								align: "left"
							}
						}}
					/>
				</GoalChartContainer>
			) : (
				<NoGoalGraphDiv>No Data for {selectedGoalSeason}</NoGoalGraphDiv>
			)}
		</>
	);
};
