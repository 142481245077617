import React from "react";
import { Box, Progress, Stat as ChakraStat, StatLabel, StatNumber, Tooltip, VStack } from "@chakra-ui/react";

import { FORMAT_FUNCTIONS, LOADING_HEIGHT, SM } from "_react/shared/ui/presentation/components/stat/shared/_constants";
import { IStatProps } from "_react/shared/ui/presentation/components/stat/shared/_types";

export interface ISecondaryStatProps extends IStatProps {
	isOnlySecondaryStat?: boolean;
	key?: string | number;
	variant?: string;
}

export const SecondaryStat = ({
	label,
	icon,
	value,
	format,
	colorGradientInfo = [undefined, undefined, undefined],
	tooltipLabel,
	tooltipPlacement = "top",
	nullFillerText = "",
	isLoading = false,
	isOnlySecondaryStat = false,
	variant = "pill",
	size = SM,
	style
}: ISecondaryStatProps) => {
	const valueNumber = typeof value === "string" ? parseFloat(value) : value;
	const [getColorFromGradient, colorGradientColors, colorGradientValues] = colorGradientInfo;
	return (
		<ChakraStat sx={style?.stat} size={size}>
			<VStack
				alignItems={variant === "pill" ? "center" : undefined}
				width={isOnlySecondaryStat ? "fit-content" : undefined}
				gap="0"
			>
				<Tooltip
					isDisabled={tooltipLabel == null}
					hasArrow
					placement={tooltipPlacement}
					label={tooltipLabel}
					whiteSpace="pre-wrap"
					sx={style?.tooltip}
				>
					<StatLabel whiteSpace="nowrap" sx={style?.statLabel}>
						{icon}
						{label}
					</StatLabel>
				</Tooltip>
				<StatNumber display="flex" alignItems="center" sx={style?.statNumber}>
					{isLoading && (
						<Box
							className="loading-item-transparent"
							h={LOADING_HEIGHT[size]}
							w="14"
							bg="gray.300"
							paddingLeft="1"
							paddingRight="1"
							marginRight={tooltipLabel ? "1" : undefined}
						/>
					)}
					{!isLoading && (
						<>
							{variant === "bullet" && (
								<Progress
									value={valueNumber ? valueNumber * 100 : undefined}
									size="md"
									width="14"
									variant="stat"
									marginRight="2"
								/>
							)}
							<Box
								paddingLeft={getColorFromGradient ? "4" : "1"}
								paddingRight={getColorFromGradient ? "4" : "1"}
								marginRight={tooltipLabel ? "1" : undefined}
								borderRadius="xl"
								borderWidth={colorGradientValues && value ? "1px" : undefined}
								borderColor={
									colorGradientColors && colorGradientValues && valueNumber
										? valueNumber < colorGradientValues.middleValue
											? `rgb(${colorGradientColors.minColor})`
											: valueNumber > colorGradientValues.middleValue
											? `rgb(${colorGradientColors.maxColor})`
											: undefined
										: undefined
								}
								sx={{
									backgroundColor:
										getColorFromGradient && value
											? `rgb(${getColorFromGradient(value)})`
											: undefined
								}}
							>
								{value ? (format ? FORMAT_FUNCTIONS[format](value) : value) : nullFillerText}
							</Box>
						</>
					)}
				</StatNumber>
			</VStack>
		</ChakraStat>
	);
};

export default SecondaryStat;
