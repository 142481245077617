import React from "react";
import PropTypes from "prop-types";

import { getSelectValue } from "_react/evals/helpers";
import { OUT_PITCH_OPTS } from "_react/evals/_constants";

const FIELDS = [
	{ name: "Curveball", abb: "CB" },
	{ name: "CB Command", abb: "CB_CMD" },
	{ name: "Slider", abb: "SL" },
	{ name: "SL Command", abb: "SL_CMD" },
	{ name: "Change Up", abb: "CH" },
	{ name: "CH Command", abb: "CH_CMD" }
];

const CELL_STYLE = {
	borderRight: "1px solid lightGray",
	paddingLeft: 5,
	paddingRight: 5
};

const getVal = (evaluation, field) => {
	if (evaluation.hasOwnProperty(field)) {
		return evaluation[field];
	}
	return "";
};

const getRange = (evaluation, field1, field2) => {
	if (evaluation.hasOwnProperty(field1) || evaluation.hasOwnProperty(field2)) {
		const low = evaluation[field1] ? evaluation[field1] : "";
		const high = evaluation[field2] ? evaluation[field2] : "";
		if (low || high) {
			return `${low} - ${high}`;
		}
	}
	return "";
};

const PitcherToolsTable = ({ evaluation, fontSize, height }) => {
	return (
		<table
			style={{
				width: "100%",
				maxWidth: "100%",
				tableLayout: "fixed",
				borderCollapse: "collapse"
			}}
		>
			<thead>
				<tr
					style={{
						backgroundColor: "#E8E9ED",
						fontSize,
						fontWeight: 600,
						height
					}}
				>
					<th
						style={{
							...CELL_STYLE,
							width: "20%",
							wordWrap: "break-word"
						}}
					>
						Tool
					</th>
					<th style={{ ...CELL_STYLE, width: "8%" }}>Pres</th>
					<th style={{ ...CELL_STYLE, width: "8%" }}>Fut</th>
					<th style={{ ...CELL_STYLE, width: "36%" }}>Comments</th>
					<th style={{ ...CELL_STYLE, width: "14%" }}>Range</th>
					<th style={{ ...CELL_STYLE, width: "14%" }}>Comfort</th>
				</tr>
			</thead>
			<tbody>
				<tr
					style={{
						backgroundColor: "white",
						fontWeight: "normal",
						height,
						fontSize
					}}
				>
					<td style={{ ...CELL_STYLE, fontWeight: 600 }}>Fastball</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "fb_vel_present")}</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "fb_vel_future")}</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "fb_vel_comments")}</td>
					<td style={CELL_STYLE}>{getRange(evaluation, "fb_vel_low", "fb_vel_high")}</td>
					<td style={CELL_STYLE}>
						{getRange(evaluation, "fb_vel_comfort_zone_low", "fb_vel_comfort_zone_high")}
					</td>
				</tr>
				<tr
					style={{
						backgroundColor: "#E8E9ED",
						fontWeight: "normal",
						height,
						fontSize
					}}
				>
					<td style={{ ...CELL_STYLE, fontWeight: 600 }}>FB Movement</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "fb_mov_present")}</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "fb_mov_future")}</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "fb_mov_comments")}</td>
					<td style={CELL_STYLE} />
					<td style={CELL_STYLE} />
				</tr>
				<tr
					style={{
						backgroundColor: "white",
						fontWeight: "normal",
						height,
						fontSize
					}}
				>
					<td style={{ ...CELL_STYLE, fontWeight: 600 }}>FB Command</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "fb_cmd_present")}</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "fb_cmd_future")}</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "fb_cmd_comments")}</td>
					<td style={CELL_STYLE} />
					<td style={CELL_STYLE} />
				</tr>
				{FIELDS.map(({ name, abb }, idx) => (
					<tr
						key={`${abb}`}
						style={{
							backgroundColor: idx % 2 === 0 ? "#E8E9ED" : "white",
							fontWeight: "normal",
							height,
							fontSize
						}}
					>
						<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{name}</td>
						<td style={CELL_STYLE}>{getVal(evaluation, `${abb.toLowerCase()}_present`)}</td>
						<td style={CELL_STYLE}>{getVal(evaluation, `${abb.toLowerCase()}_future`)}</td>
						<td style={CELL_STYLE}>{getVal(evaluation, `${abb.toLowerCase()}_comments`)}</td>
						<td style={CELL_STYLE}>
							{getRange(evaluation, `${abb.toLowerCase()}_vel_low`, `${abb.toLowerCase()}_vel_high`)}
						</td>
						<td style={CELL_STYLE}>
							{getRange(
								evaluation,
								`${abb.toLowerCase()}_comfort_zone_low`,
								`${abb.toLowerCase()}_comfort_zone_high`
							)}
						</td>
					</tr>
				))}
				{getVal(evaluation, "oth_pitch_type") && (
					<React.Fragment>
						<tr
							style={{
								backgroundColor: "#E8E9ED",
								fontWeight: "normal",
								height,
								fontSize
							}}
						>
							<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{getVal(evaluation, "oth_pitch_type")}</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth_pitch_present")}</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth_pitch_future")}</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth_pitch_comments")}</td>
							<td style={CELL_STYLE}>
								{getRange(evaluation, "oth_pitch_vel_low", "oth_pitch_vel_high")}
							</td>
							<td style={CELL_STYLE}>
								{getRange(evaluation, "oth_pitch_comfort_zone_low", "oth_pitch_comfort_zone_high")}
							</td>
						</tr>
						<tr
							style={{
								backgroundColor: "white",
								fontWeight: "normal",
								height,
								fontSize
							}}
						>
							<td style={{ ...CELL_STYLE, fontWeight: 600 }}>
								{getVal(evaluation, "oth_pitch_type")} Command
							</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth_cmd_present")}</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth_cmd_future")}</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth_cmd_comments")}</td>
							<td style={CELL_STYLE} />
							<td style={CELL_STYLE} />
						</tr>
					</React.Fragment>
				)}
				{getVal(evaluation, "oth2_pitch_type") && (
					<React.Fragment>
						<tr
							style={{
								backgroundColor: "#E8E9ED",
								fontWeight: "normal",
								height,
								fontSize
							}}
						>
							<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{getVal(evaluation, "oth2_pitch_type")}</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth2_pitch_present")}</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth2_pitch_future")}</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth2_pitch_comments")}</td>
							<td style={CELL_STYLE}>
								{getRange(evaluation, "oth2_pitch_vel_low", "oth2_pitch_vel_high")}
							</td>
							<td style={CELL_STYLE}>
								{getRange(evaluation, "oth2_pitch_comfort_zone_low", "oth2_pitch_comfort_zone_high")}
							</td>
						</tr>
						<tr
							style={{
								backgroundColor: "white",
								fontWeight: "normal",
								height,
								fontSize
							}}
						>
							<td style={{ ...CELL_STYLE, fontWeight: 600 }}>
								{getVal(evaluation, "oth2_pitch_type")} Command
							</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth2_cmd_present")}</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth2_cmd_future")}</td>
							<td style={CELL_STYLE}>{getVal(evaluation, "oth2_cmd_comments")}</td>
							<td style={CELL_STYLE} />
							<td style={CELL_STYLE} />
						</tr>
					</React.Fragment>
				)}
				{evaluation != null && evaluation.eval_type.startsWith("PRO") && (
					<tr
						style={{
							backgroundColor: "#E8E9ED",
							fontWeight: "normal",
							height,
							fontSize
						}}
					>
						<td style={{ ...CELL_STYLE, fontWeight: 600 }}>Outpitch</td>
						<td colSpan={5} style={CELL_STYLE}>
							{getSelectValue(evaluation, "outpitch", OUT_PITCH_OPTS)}
							{getSelectValue(evaluation, "outpitch2", OUT_PITCH_OPTS) !== ""
								? `, ${getSelectValue(evaluation, "outpitch2", OUT_PITCH_OPTS)}`
								: ""}
							{getSelectValue(evaluation, "outpitch3", OUT_PITCH_OPTS) !== ""
								? `, ${getSelectValue(evaluation, "outpitch3", OUT_PITCH_OPTS)}`
								: ""}
						</td>
					</tr>
				)}
				<tr
					style={{
						backgroundColor:
							evaluation != null && evaluation.eval_type.startsWith("PRO") ? "white" : "#E8E9ED",
						fontWeight: "normal",
						height,
						fontSize
					}}
				>
					<td style={{ ...CELL_STYLE, fontWeight: 600 }}>CHP/Pitchability</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "pitchability_present")}</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "pitchability_future")}</td>
					<td style={CELL_STYLE}>{getVal(evaluation, "pitchability_comments")}</td>
					<td style={CELL_STYLE} />
					<td style={CELL_STYLE} />
				</tr>
			</tbody>
		</table>
	);
};

PitcherToolsTable.propTypes = {
	evaluation: PropTypes.object,
	fontSize: PropTypes.string,
	height: PropTypes.string
};

PitcherToolsTable.defaultProps = {
	height: "32px",
	fontSize: ".875em"
};

export default PitcherToolsTable;
