import { ASC } from "_react/shared/ui/presentation/components/Table/_constants";
import { TColumn, TParentColumn, TSortColumn } from "_react/shared/ui/presentation/components/Table/_types";

export const filterColumns = <T, K extends keyof T>(
	columns: Array<TColumn<T, K>>,
	isMobile?: boolean,
	data?: Array<T> | null
): Array<TColumn<T, K>> => {
	return columns.filter((column: TColumn<T, K>) => {
		if (!isMobile) return true;
		if (column.isMobile !== undefined) return column.isMobile;
		if (column.isMobileFunction) return column.isMobileFunction(data ?? []);
		return false;
	});
};

export const filterParentColumns = <T, K extends keyof T>(
	filteredChildColumns: Array<TColumn<T, K>>,
	parentColumns?: Array<TParentColumn>
): Array<TParentColumn> | undefined => {
	if (!parentColumns) return parentColumns;
	// Find all child columns that are included
	const allIncludedColumnIds = filteredChildColumns.reduce((columnIds: Array<string>, column: TColumn<T, K>) => {
		if (column.id) columnIds.push(column.id);
		return columnIds;
	}, []);
	// Only include parent column headers that are needed and make sure the spacing with the child columns is correct
	const filteredParentColumns = parentColumns.reduce(
		(filteredParentColumns: Array<TParentColumn>, column: TParentColumn) => {
			const filteredChildColumns = column.childColumnIds.filter((columnKey: string) =>
				allIncludedColumnIds.includes(columnKey)
			);
			if (filteredChildColumns.length)
				filteredParentColumns.push({ ...column, childColumnIds: filteredChildColumns });
			return filteredParentColumns;
		},
		[]
	);
	return filteredParentColumns;
};

export const sortChildColumns = <T, K extends keyof T>(
	childColumns: Array<TColumn<T, K>>,
	parentColumns?: Array<TParentColumn>
): Array<TColumn<T, K>> => {
	if (parentColumns?.length) {
		// Put the child columns in the order that they are defined in the parent column
		return parentColumns.reduce((sortedChildColumns: Array<TColumn<T, K>>, parentColumn: TParentColumn) => {
			parentColumn.childColumnIds.forEach((childColumnId: string) => {
				const correspondingChildColumn = childColumns.find(
					(column: TColumn<T, K>) => column.id === childColumnId
				);
				if (correspondingChildColumn) sortedChildColumns.push(correspondingChildColumn);
			});
			return sortedChildColumns;
		}, []);
	}
	return childColumns;
};

export const getSortedData = <T, K extends keyof T>(
	data: Array<T> | null | undefined,
	columns: Array<TColumn<T, K>>,
	sortColumns: Array<TSortColumn<K>>
) => {
	const newSortedData = data ? [...data] : [];
	newSortedData.sort((a: T, b: T): number => {
		let sortValue = 0;
		sortColumns.every((sortColumn: TSortColumn<K>) => {
			// Get the values we are sorted based off of
			let aValue;
			let bValue;
			const column = columns.find((column: TColumn<T, K>) => column.value === sortColumn.columnValue);
			if (!column) return true;
			else if (column?.getSortValueFunction) {
				aValue = column.getSortValueFunction(a);
				bValue = column.getSortValueFunction(b);
			} else if (column?.getValueFunction) {
				aValue = column.getValueFunction(a);
				bValue = column.getValueFunction(b);
			} else {
				aValue = a[column.value as K];
				bValue = b[column.value as K];
			}

			// Sort using the values
			if (column?.sortFunction) {
				sortValue =
					sortColumn.sortDirection === ASC ? column.sortFunction(a, b) : column.sortFunction(a, b) * -1;
			} else {
				// Always put the empty values at the bottom
				// Otherwise sort based on basic comparisons
				if ((aValue == null || aValue === "") && bValue != null && bValue !== "") sortValue = 1;
				else if (aValue != null && aValue !== "" && (bValue == null || bValue === "")) sortValue = -1;
				else if (aValue === bValue) sortValue = 0;
				else if (aValue == null) sortValue = 1;
				else if (bValue == null) sortValue = -1;
				else if (aValue < bValue) {
					sortValue = sortColumn.sortDirection === ASC ? -1 : 1;
				} else {
					sortValue = sortColumn.sortDirection === ASC ? 1 : -1;
				}
			}

			// Continue if values are equal, otherwise end the loop
			return sortValue === 0;
		});
		return sortValue;
	});
	return newSortedData;
};

//
// TableRow Helpers
//

export const getRowValue = <T, K extends keyof T>(data: T, column: TColumn<T, K>) => {
	return column.getValueFunction ? column.getValueFunction(data) : data[column.value as K];
};

export const getColorGradientStyle = <T, K extends keyof T>(data: T, column: TColumn<T, K>) => {
	const rowValue = getRowValue(data, column);
	const rowColorGradientValue = column.getColorGradientValueFunction
		? column.getColorGradientValueFunction(data)
		: rowValue;
	const colorGradientStyle =
		column.colorGradientFunction &&
		(typeof rowColorGradientValue === "number" || typeof rowColorGradientValue === "string")
			? {
					backgroundColor: `rgb(${column.colorGradientFunction(rowColorGradientValue)?.join(",")})`
			  }
			: {};
	return colorGradientStyle;
};
