import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import Autocomplete from "_react/shared/legacy/autocomplete/Autocomplete";
import { STATES, COUNTRIES, YESNONUM } from "_react/playerpage/shared_js/form_constants";
import { createAgent } from "_redux/Agent/agent.a";
import AddAgencyModal from "_react/playerpage/shared_js/AddAgencyModal";

class AddAgentModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			errorText: "",
			adding: false,
			addingAgency: false
		};
	}

	handleAdd = () => {
		const {
			agencyId,
			scoutAssignedId,
			firstName,
			lastName,
			area,
			address1,
			address2,
			address3,
			city,
			state,
			country,
			zip,
			phone,
			phoneAlt,
			cell,
			email,
			fax,
			isCertified
		} = this.state;

		if (!firstName || !lastName) {
			this.setState({ errorText: "Name is required" });
		} else {
			this.setState({ adding: true });
			const agentDict = {
				agencyId,
				scoutUserId: scoutAssignedId,
				firstName,
				lastName,
				area,
				address1,
				address2,
				address3,
				city,
				state,
				country,
				zip,
				phone,
				phoneAlt,
				cell,
				email,
				fax,
				isCertified,
				isActive: 1
			};

			this.props.createAgent(agentDict);
			this.setState({ errorText: "", adding: false });
			this.props.handleClose(agentDict);
		}
	};

	handleUpdate = value => {
		if (value.id === "agencyId") {
			if (value.value === "addNewAgency") {
				this.setState({ addingAgency: true });
			} else {
				this.setState({ [value.id]: value.value });
			}
		} else {
			this.setState({ [value.id]: value.value });
		}
	};

	handleAgencyAdded = agency => {
		if (agency) {
			this.setState({ agencyId: agency.agencyId });
		}
	};

	render() {
		const { isAgent, agencies } = this.props;

		const {
			agencyId,
			firstName,
			lastName,
			area,
			address1,
			address2,
			address3,
			city,
			state,
			country,
			zip,
			phone,
			phoneAlt,
			cell,
			email,
			fax,
			isCertified,
			addingAgency
		} = this.state;

		const agencyOptions = Object.keys(agencies).map(agency => {
			return {
				value: agency,
				text: `${agencies[agency].agencyName}`
			};
		});
		agencyOptions.unshift({
			value: "addNewAgency",
			text: "+ Add New Agency",
			persistent: true
		});

		const fields = [
			{
				name: "Agency",
				id: "agencyId",
				value: agencyId,
				type: "autocomplete",
				options: agencyOptions
			},
			{
				name: "First Name",
				id: "firstName",
				value: firstName,
				type: "text"
			},
			{
				name: "Last Name",
				id: "lastName",
				value: lastName,
				type: "text"
			},
			{ name: "Area", id: "area", value: area, type: "text" },
			{
				name: "Address 1",
				id: "address1",
				value: address1,
				type: "text"
			},
			{
				name: "Address 2",
				id: "address2",
				value: address2,
				type: "text"
			},
			{
				name: "Address 3",
				id: "address3",
				value: address3,
				type: "text"
			},
			{ name: "City", id: "city", value: city, type: "text" },
			{
				name: "State",
				id: "state",
				value: state,
				type: "autocomplete",
				options: STATES
			},
			{
				name: "Country",
				id: "country",
				value: country,
				type: "autocomplete",
				options: COUNTRIES["three_letter_abbreviations"]
			},
			{ name: "Zip", id: "zip", value: zip, type: "text" },
			{ name: "Phone", id: "phone", value: phone, type: "text" },
			{
				name: "Phone (Alt)",
				id: "phoneAlt",
				value: phoneAlt,
				type: "text"
			},
			{ name: "Cell", id: "cell", value: cell, type: "text" },
			{ name: "E-Mail", id: "email", value: email, type: "text" },
			{ name: "Fax", id: "fax", value: fax, type: "text" },
			{
				name: "Certified",
				id: "isCertified",
				value: isCertified,
				type: "autocomplete",
				options: YESNONUM
			}
		];

		return (
			<div>
				<Dialog
					onClose={() => this.props.handleClose(null)}
					open={true}
					PaperProps={{
						style: {
							overflowY: "visible"
						}
					}}
					scroll="body"
				>
					<DialogTitle>Add {isAgent ? "Agent" : "Advisor"}</DialogTitle>
					<DialogContent>
						<Grid container spacing={8}>
							{fields.map((field, idx) => {
								let selectedOption = null;
								switch (field.type) {
									case "text":
										return (
											<Grid item key={idx} xs={12}>
												<TextField
													fullWidth={true}
													id={field.id}
													label={field.name}
													onChange={e =>
														this.handleUpdate({
															id: field.id,
															value: e.target.value
														})
													}
													style={{ height: "72px" }}
													value={field.value}
												/>
											</Grid>
										);
									case "autocomplete":
										selectedOption = field.options.filter(e => e.value === field.value);
										return (
											<Grid item key={idx} xs={12}>
												<Autocomplete
													autoFocus={false}
													dataSource={field.options}
													getValueSelectedText={option => (option ? option.text : null)}
													handleSelected={value =>
														this.handleUpdate({
															id: field.id,
															value: value.value
														})
													}
													numCharToShowSuggestions={0}
													placeholder={field.name}
													showSelected={true}
													tabSelect={true}
													valueSelected={
														selectedOption.length > 0
															? {
																	text: selectedOption[0].text,
																	value: field.value
															  }
															: null
													}
												/>
											</Grid>
										);
									default:
										return <div />;
								}
							})}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							disabled={firstName === "" || lastName === ""}
							key={"add"}
							onClick={this.handleAdd}
							variant="contained"
						>
							Add {isAgent ? "Agent" : "Advisor"}
						</Button>
						<Button color="primary" key={"close"} onClick={() => this.props.handleClose(null)}>
							Close
						</Button>
					</DialogActions>
				</Dialog>
				{addingAgency && (
					<AddAgencyModal
						handleClose={agencySelected => {
							this.setState({ addingAgency: false });
							this.handleAgencyAdded(agencySelected);
						}}
					/>
				)}
			</div>
		);
	}
}

AddAgentModal.propTypes = {
	agencies: PropTypes.object,
	createAgent: PropTypes.func,
	handleClose: PropTypes.func,
	isAgent: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
	return {
		createAgent: agentDict => dispatch(createAgent(agentDict))
	};
}

export default connect(null, mapDispatchToProps)(AddAgentModal);
