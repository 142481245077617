import React, { useEffect, useState, useMemo } from "react";

import { useFetch } from "utils/url_helpers";
import { isOptionArr } from "_react/inputs";
import { StyledSelect } from "_react/shared/selects";
import {
	TPlayerPlanDrill,
	TLkPlayerPlanMetricType,
	TLkPlayerPlanMetricSubtype,
	TPlayerPlanMetricAvailableDrill
} from "_react/playerplan/shared/_types";
import { getDrillLabel } from "_react/playerplan/shared/_helpers";

export type TDrillOption = {
	id: number;
	label: string;
	value: number;
	metricType: TLkPlayerPlanMetricType;
	metricSubtype?: TLkPlayerPlanMetricSubtype;
	drillAvailableMetrics: Array<TPlayerPlanMetricAvailableDrill>;
};

type TDrillSelect = {
	handleSelect: Function;
	metricTypes?: Array<string>;
	metricSubtypes?: Array<string>; // When set, drills must either have a subtype in the array, or no subtype
	filterMetricType?: string;
	filterMetricSubtype?: string; // This "filter" is used for sorting not filtering
	placeholder?: string;
	value?: Array<number> | null;
	isClearable?: boolean;
	isMulti?: boolean;
	isTeamDrill?: boolean;
	isUseAbbreviations?: boolean;
	forDisplay?: boolean;
	shouldUseAvailabilityMap?: boolean;
	goalMetricId?: number;
	focusAreaMetricId?: number;
	excludeDrillIds?: Array<number>;
	isDisabled?: boolean;
};

function DrillSelect({
	handleSelect,
	metricTypes,
	metricSubtypes,
	filterMetricType,
	filterMetricSubtype, // This "filter" is used for sorting not filtering
	placeholder = "Select how...",
	value,
	isClearable = true,
	isMulti = false,
	isTeamDrill = false,
	isUseAbbreviations = false,
	forDisplay = false,
	shouldUseAvailabilityMap = false,
	goalMetricId,
	focusAreaMetricId,
	excludeDrillIds = [],
	isDisabled = false
}: TDrillSelect) {
	const { responseData: drillResponseData, isInProgress } = useFetch(
		`/playerplan/drill?isDeleted=false${filterMetricType ? `&metricTypeLk=${filterMetricType}` : ""}${
			isTeamDrill ? "&drillTypeLk=team_drill" : ""
		}${value != null && forDisplay ? `&ids=${value.join(",")}` : ""}`,
		[]
	);
	drillResponseData.sort((a: TPlayerPlanDrill, b: TPlayerPlanDrill) => {
		if (filterMetricType) {
			if (a.metricType.value === filterMetricType && b.metricType.value !== filterMetricType) return -1;
			else if (a.metricType.value !== filterMetricType && b.metricType.value === filterMetricType) return 1;
			else if (
				filterMetricSubtype &&
				a.metricType.value === filterMetricType &&
				b.metricType.value === filterMetricType
			) {
				if (a.metricSubtype?.value === filterMetricSubtype && b.metricSubtype?.value !== filterMetricSubtype)
					return -1;
				else if (
					a.metricSubtype?.value !== filterMetricSubtype &&
					b.metricSubtype?.value === filterMetricSubtype
				)
					return 1;
			}
		}
		if (a.metricType.label.toLowerCase() < b.metricType.label.toLowerCase()) return -1;
		else if (a.metricType.label.toLowerCase() > b.metricType.label.toLowerCase()) return 1;
		else {
			if ((a.metricSubtype?.label ?? "a").toLowerCase() < (b.metricSubtype?.label ?? "a").toLowerCase())
				return -1;
			else if ((a.metricSubtype?.label ?? "a").toLowerCase() > (b.metricSubtype?.label ?? "a").toLowerCase())
				return 1;
			else {
				if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
				else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
				return 0;
			}
		}
	});

	const [drillDataSource, setDrillDataSource] = useState([]);

	useEffect(() => {
		if (drillResponseData) {
			setDrillDataSource(
				drillResponseData.map((drill: TPlayerPlanDrill) => ({
					id: drill.id,
					label: getDrillLabel(
						drill.name,
						isUseAbbreviations ? drill.metricType.abbreviation : drill.metricType.label,
						drill.metricSubtype ? drill.metricSubtype.label : undefined
					),
					value: drill.id,
					metricType: drill.metricType,
					metricSubtype: drill.metricSubtype,
					drillAvailableMetrics:
						drill.drillAvailableMetrics?.filter((d: TPlayerPlanMetricAvailableDrill) => !d.isDeleted) ?? []
				}))
			);
		}
	}, [drillResponseData, setDrillDataSource, isUseAbbreviations]);

	const opts = useMemo(() => {
		if (drillDataSource.length && value) {
			const filteredDataSource = drillDataSource.filter((opt: TDrillOption) =>
				value ? value.includes(opt.value) : false
			);
			return filteredDataSource.length ? filteredDataSource : null;
		}
		return null;
	}, [drillDataSource, value]);

	const valueProps = value === undefined ? {} : opts ? { value: opts } : { value: null };

	if (forDisplay) {
		let label = "";
		if (opts != null && isOptionArr<number>(opts, "number")) {
			label = opts.length > 0 && opts[0] != null ? opts.map((value: TDrillOption) => value.label).join(", ") : "";
		}
		return <div>{label}</div>;
	}

	return (
		<StyledSelect
			options={drillDataSource.filter(
				(opt: TDrillOption) =>
					(metricTypes === undefined || metricTypes.includes(opt.metricType.value)) &&
					(metricSubtypes === undefined ||
						!opt.metricSubtype ||
						metricSubtypes.includes(opt.metricSubtype?.value)) &&
					(excludeDrillIds === undefined || !excludeDrillIds.includes(opt.id)) &&
					(!shouldUseAvailabilityMap ||
						opt.drillAvailableMetrics.length === 0 ||
						// If we're using drillAvailableMetrics and it is non-empty,
						// then include option if the goalMetricId is in drillAvailableMetrics, or...
						(goalMetricId !== undefined &&
							opt.drillAvailableMetrics
								.map((m: TPlayerPlanMetricAvailableDrill) => m.metricId)
								.includes(goalMetricId)) ||
						// ...include option if the focusAreaMetricId is in drillAvailableMetrics
						(focusAreaMetricId !== undefined &&
							opt.drillAvailableMetrics
								.map((m: TPlayerPlanMetricAvailableDrill) => m.metricId)
								.includes(focusAreaMetricId)))
			)}
			onChange={handleSelect}
			placeholder={placeholder}
			isClearable={isClearable}
			isMulti={isMulti}
			isLoading={isInProgress}
			{...valueProps}
			isDisabled={isDisabled}
			styles={{ container: { width: "100%" } }}
		/>
	);
}

export default DrillSelect;
