import React, { useContext, useEffect, useMemo, useCallback } from "react";
import styled from "@emotion/styled";
import { useSelector } from "@xstate/react";

import { useQueryString } from "utils/url_helpers";
import { $TSFixMe } from "utils/tsutils";
import { useIsGroupMember, useUserId } from "_react/app/AppContents";
import { ROSEN_USER_ID } from "_react/shared/_constants/ids";
import {
	PLAYER_PAGES_MANUELS_IAM_GROUPS,
	PLAYER_PAGES_OBSERVED_IAM_GROUPS,
	PLAYER_PAGES_PRO_PROJECTIONS_IAM_GROUPS,
	PLAYER_PAGES_SCOUTING_REPORTS_IAM_GROUPS,
	PLAYER_PAGES_AMA_PROJECTIONS_IAM_GROUPS,
	RND_IAM_GROUP,
	AMA_ADMIN_IAM_GROUP
} from "_react/shared/data_models/iam_new/_constants";
import { generateColorSchemeGroup, getColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { Tab } from "_react/shared/legacy/ui/Tab";
import { Tabs } from "_react/shared/legacy/ui/Tabs";
import {
	AMA_NEW_TABS,
	AMA_OLD_TABS,
	INTL_TABS,
	PRO_NEW_TABS,
	PRO_OLD_TABS,
	VIEW_APOLLO,
	VIEW_BIO,
	VIEW_CONTRACT,
	VIEW_INJURIES,
	VIEW_MANUEL,
	VIEW_NOTES,
	VIEW_STATISTICS,
	VIEW_SUMMARY,
	VIEW_TRANSACTIONS,
	VIEW_VIDEO,
	VIEW_PROJECTIONS
} from "_react/shared/_constants/query_strings";
import { PlayerClassificationDialog } from "_react/playerpage/shared/PlayerClassificationDialog";

import { AMA, INTL, ONE, PRO, ZERO } from "_react/playerpage/_constants";
import {
	PlayerPageProvider as PlayerPageProviderLegacy,
	PlayerPageContext as PlayerPageContextLegacy
} from "_react/playerpage/_context";
import { PlayerPageContext } from "_react/playerpage/PlayerPageProvider";
import { TPlayerPageState, SET_VIEW_CLASSIFICATION } from "_react/playerpage/_machine";
import { TPlayerPageCombinedPlayer, TPlayer, TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import PlayerPageAma from "_react/playerpage/ama";
import { Content } from "_react/playerpage/Content";
import { Footer } from "_react/playerpage/Footer";
import { Header } from "_react/playerpage/header/Header";
import PlayerPagePro from "_react/playerpage/pro";
import { countCurrentYearNotes, getUserDefaultView } from "_react/playerpage/_helpers";

const ContainerDiv = styled.div({
	flex: 1,
	display: "flex",
	flexDirection: "column",
	height: "calc(100% - 32px)",
	overflowY: "scroll"
});

const ContentDiv = styled.div({
	flex: 1
});

export const PlayerPageContent = () => {
	const [view, setView] = useQueryString("view", VIEW_SUMMARY) as [
		string | undefined,
		(view: string | null, isReplaceState?: boolean) => void
	];

	const playerPageContext = useContext(PlayerPageContext);

	const philId: number | null | undefined = useSelector(
		playerPageContext.playerPageService,
		(state: TPlayerPageState) => state.context.playerLegacyId
	);
	const playerCombined: TPlayerPageCombinedPlayer | null | undefined = useSelector(
		playerPageContext.playerPageService,
		(state: TPlayerPageState) => state.context.player
	);
	const player: TPlayer | null | undefined = useSelector(
		playerPageContext.playerPageService,
		(state: TPlayerPageState) => state.context.playerLegacy
	);
	const viewClassification: TPlayer | undefined = useSelector(
		playerPageContext.playerPageService,
		(state: TPlayerPageState) => state.context.viewClassification
	);

	const setViewClassification = useCallback(
		(viewClassification: TPlayerPagePlayerClassification | undefined) => {
			playerPageContext.playerPageService.send({ type: SET_VIEW_CLASSIFICATION, value: viewClassification });
		},
		[playerPageContext]
	);

	const {
		setPlayer,
		setPlayerCombined,
		playerCombined: playerCombinedFromLegacyContext,
		player: playerFromLegacyContext,
		evals,
		notes,
		isNotesLoading,
		isNotesFailed,
		getEvals,
		getNotes,
		bioDisplayOnly,
		amaBioDocument,
		setAmaBioDocument,
		setBioDisplayOnly,
		isBioSaving,
		submitAmaBioUpdate
	} = useContext(PlayerPageContextLegacy);

	useEffect(() => {
		if (player == null && playerFromLegacyContext == null) return;
		if (player?.philId !== playerFromLegacyContext?.philId) setPlayer(player);
	}, [player, setPlayer, playerFromLegacyContext]);
	useEffect(() => {
		if (playerCombined == null && playerCombinedFromLegacyContext == null) return;
		if (playerCombined?.id !== playerCombinedFromLegacyContext?.id) setPlayerCombined(playerCombined);
	}, [playerCombined, setPlayerCombined, playerCombinedFromLegacyContext]);

	// Projections content and default tab permissions
	const hasProProjectionsPermissions = useIsGroupMember(PLAYER_PAGES_PRO_PROJECTIONS_IAM_GROUPS);
	const hasObservedPermissions = useIsGroupMember(PLAYER_PAGES_OBSERVED_IAM_GROUPS);
	const hasScoutingReportsPermissions = useIsGroupMember(PLAYER_PAGES_SCOUTING_REPORTS_IAM_GROUPS);
	const hasManuelsPermissions = useIsGroupMember(PLAYER_PAGES_MANUELS_IAM_GROUPS);
	const hasAmaProjectionsPermissions = useIsGroupMember(PLAYER_PAGES_AMA_PROJECTIONS_IAM_GROUPS);
	const userId = useUserId();
	const hasAmaBioEditPermissions =
		useIsGroupMember([RND_IAM_GROUP, AMA_ADMIN_IAM_GROUP]) ||
		userId === ROSEN_USER_ID ||
		playerCombined?.scoutAssigned?.userId === userId;

	const [isBeta, setIsBeta] = useQueryString("isBeta") as [
		string | undefined,
		(isBeta: string | null, isReplaceState?: boolean) => void
	];

	// Set isBeta to 1 by default if PRO and AMA, otherwise 0 for INTL
	useEffect(() => {
		if ((viewClassification === PRO || viewClassification === AMA) && isBeta !== ZERO) setIsBeta(ONE, true);
		else if (viewClassification === INTL && isBeta === ONE) setIsBeta(ZERO, true);
	}, [viewClassification, isBeta, setIsBeta]);

	// Only allow users to access tabs that exist on each view classification
	// Default to a specific tab based on user permissions
	useEffect(() => {
		if (
			(viewClassification === PRO &&
				isBeta === ONE &&
				(!PRO_NEW_TABS.includes(view ?? "") ||
					(!hasProProjectionsPermissions && view === VIEW_PROJECTIONS) ||
					(!hasManuelsPermissions && view === VIEW_MANUEL))) ||
			(viewClassification === PRO && isBeta !== ONE && !PRO_OLD_TABS.includes(view ?? "")) ||
			(viewClassification === AMA &&
				isBeta === ONE &&
				(!AMA_NEW_TABS.includes(view ?? "") ||
					// TODO this should be AMA projections access eventually
					(!hasAmaProjectionsPermissions && view === VIEW_PROJECTIONS))) ||
			(viewClassification === AMA && isBeta !== ONE && !AMA_OLD_TABS.includes(view ?? "")) ||
			(viewClassification === INTL && !INTL_TABS.includes(view ?? ""))
		) {
			const defaultView = getUserDefaultView(
				viewClassification,
				isBeta,
				hasProProjectionsPermissions,
				hasAmaProjectionsPermissions,
				hasObservedPermissions,
				hasScoutingReportsPermissions,
				hasManuelsPermissions
			);
			setView(defaultView, true);
		}
	}, [
		viewClassification,
		view,
		setView,
		isBeta,
		hasProProjectionsPermissions,
		hasAmaProjectionsPermissions,
		hasObservedPermissions,
		hasScoutingReportsPermissions,
		hasManuelsPermissions
	]);

	useMemo(() => {
		if (philId != null) {
			getEvals(philId);
		}
	}, [getEvals, philId]);

	useMemo(() => {
		if (playerCombined?.id) {
			getNotes(playerCombined.id);
		}
	}, [getNotes, playerCombined]);

	const colorSchemeGroup =
		getColorSchemeGroup(playerCombined) ??
		generateColorSchemeGroup({
			primary: defaultColorScheme.secondary.color,
			secondary: defaultColorScheme.primary.color
		});

	const noteCount = useMemo(() => {
		return countCurrentYearNotes(notes);
	}, [notes]);

	const availableClassifications: TPlayerPagePlayerClassification[] = useMemo(() => {
		const classifications: TPlayerPagePlayerClassification[] = [];
		if (playerCombined?.playerAmaId != null) classifications.push(AMA);
		if (playerCombined?.playerIntlId != null) classifications.push(INTL);
		if (playerCombined?.playerProId != null) classifications.push(PRO);
		return classifications;
	}, [playerCombined]);

	const showingInvalidPlayerClassification = useMemo(() => {
		if (viewClassification == null) return true;
		if (playerCombined?.playerAmaId == null && viewClassification === AMA) return true;
		if (playerCombined?.playerIntlId == null && viewClassification === INTL) return true;
		if (playerCombined?.playerProId == null && viewClassification === PRO) return true;
		return false;
	}, [viewClassification, playerCombined]);

	return (
		<ContainerDiv>
			{showingInvalidPlayerClassification && playerCombined != null && (
				<PlayerClassificationDialog
					availableClassifications={availableClassifications}
					onSelectClassification={setViewClassification}
				/>
			)}
			{/* New player pages. Only PRO currently. */}
			{isBeta === ONE && viewClassification === PRO && (
				<PlayerPagePro
					player={playerCombined}
					playerLegacy={player}
					evals={evals}
					noteCount={noteCount}
					isNotesLoading={isNotesLoading}
					isNotesFailed={isNotesFailed}
					setViewClassification={setViewClassification}
					viewClassification={viewClassification as string | null}
					setView={setView}
					view={view as string | undefined}
					setIsBeta={setIsBeta}
					hasProjectionsPermissions={hasProProjectionsPermissions}
					hasPlayerPlanPermissions={hasManuelsPermissions}
				/>
			)}
			{isBeta === ONE && viewClassification === AMA && (
				<PlayerPageAma
					player={playerCombined}
					evals={evals}
					setViewClassification={setViewClassification}
					viewClassification={viewClassification as string | null}
					setView={setView}
					view={view as string | undefined}
					setIsBeta={setIsBeta}
					hasProjectionsPermissions={hasAmaProjectionsPermissions}
					hasAmaBioEditPermissions={hasAmaBioEditPermissions}
					bioDisplayOnly={bioDisplayOnly}
					amaBioDocument={amaBioDocument}
					setAmaBioDocument={setAmaBioDocument}
					setBioDisplayOnly={setBioDisplayOnly}
					isBioSaving={isBioSaving}
					submitAmaBioUpdate={submitAmaBioUpdate}
				/>
			)}
			{/* Old player pages. PRO, AMA, and INTL currently. */}
			{(isBeta !== ONE || viewClassification === INTL) && (
				<>
					<Header
						colorSchemeGroup={colorSchemeGroup}
						evaluations={evals}
						playerCombined={playerCombined}
						viewClassification={viewClassification}
						setViewClassification={setViewClassification}
						showViewOptions={true}
						setIsBeta={setIsBeta}
					/>
					{player == null ||
						(playerCombined == null && (
							<div className="loading-item" style={{ height: "38px", width: "100%" }} />
						))}
					{player != null && playerCombined != null && (
						<Tabs
							colorScheme={colorSchemeGroup.primary}
							fullWidth={true}
							key={"Tabs"}
							onChange={(e: $TSFixMe, value: string) => setView(value)}
							style={{
								paddingLeft: "4px",
								paddingRight: "4px",
								width: "calc(100% - 7px)"
							}}
							value={view}
						>
							<Tab label="Summary" value={VIEW_SUMMARY} />
							<Tab label="Bio" value={VIEW_BIO} />
							{viewClassification === PRO && <Tab label="Statistics" value={VIEW_STATISTICS} />}
							{viewClassification === PRO && <Tab label="Contract" value={VIEW_CONTRACT} />}
							{viewClassification === PRO && <Tab label="Transactions" value={VIEW_TRANSACTIONS} />}
							{(viewClassification === PRO || viewClassification === INTL) && (
								<Tab label="Injuries" value={VIEW_INJURIES} />
							)}
							{viewClassification === PRO && hasManuelsPermissions && (
								<Tab label="Plan" value={VIEW_MANUEL} />
							)}
							{(viewClassification === AMA || viewClassification === INTL) && (
								<Tab label="Video" value={VIEW_VIDEO} />
							)}
							<Tab
								label={"Notes" + (isNotesLoading ? " (...)" : isNotesFailed ? "" : ` (${noteCount})`)}
								value={VIEW_NOTES}
							/>
							{false && viewClassification === PRO && (
								<Tab label="Apollo" style={{ marginRight: "4px" }} value={VIEW_APOLLO} />
							)}
						</Tabs>
					)}
					{playerCombined != null && (
						<ContentDiv>
							<Content
								player={player}
								playerCombined={playerCombined}
								colorSchemeGroup={colorSchemeGroup}
								view={view}
								hasManuelsPermissions={hasManuelsPermissions}
							/>
						</ContentDiv>
					)}
					{player != null && playerCombined != null && (
						<Footer colorSchemeGroup={colorSchemeGroup} player={playerCombined} />
					)}
				</>
			)}
		</ContainerDiv>
	);
};

const PlayerPageWrapped = () => {
	return (
		<PlayerPageProviderLegacy>
			<PlayerPageContent />
		</PlayerPageProviderLegacy>
	);
};

export default PlayerPageWrapped;
