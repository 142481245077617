import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Box, Flex, Tbody, Tr, Th, Td, Tooltip } from "@chakra-ui/react";

import { useIsMobile } from "_react/_hooks";
import { Pulser } from "_react/shared/legacy/ui/loaders/Pulser";
import { TextField, TChangeEvent } from "_react/shared/legacy/ui/TextField";

import { ASC, DESC, VALUE_TYPE_NUMBER } from "_react/shared/ui/presentation/components/Table/_constants";
import {
	filterColumns,
	filterParentColumns,
	sortChildColumns,
	getSortedData
} from "_react/shared/ui/presentation/components/Table/_helpers";
import {
	TTableProps,
	TColumn,
	TSortColumn,
	TParentColumn,
	ITableT
} from "_react/shared/ui/presentation/components/Table/_types";
import {
	DisplayNoneStyle,
	DefaultThTooltipStyle,
	ShowResultsTextFieldStyle,
	DefaultTable,
	DefaultTrEvenStyle,
	DefaultTr,
	DefaultThead,
	DefaultParentTh,
	DefaultThStyle,
	PageBoxActiveStyle,
	PageBox,
	BoxDisabledStyle,
	PreviousBox,
	NextBox,
	NestedContentTr,
	NestedContentTd
} from "_react/shared/ui/presentation/components/Table/_styles";
import TableRow from "_react/shared/ui/presentation/components/Table/TableRow";

const Table = <T extends ITableT<T>, K extends keyof T>({
	columns = [],
	parentColumns,
	data,
	headerTooltipPlacement = "top",
	defaultSortColumns = [],
	defaultPageLimit = 100,
	highlightIndex,
	emptyDataDisplayText = "No Data",
	isUsePagination = false,
	isAllowAdjustNumResults = true,
	isLoadingData = false,
	isMobile: isMobileProp,
	isExpandableRows = false,
	onRowClickFunction,
	highlightIndexFunction,
	getRowStyleFunction,
	nestedContentFunction,
	getCustomRowKeyFunction,
	isCollapseAll = false, // Whether the user has the option to expand or collapse all rows
	style
}: TTableProps<T, K>): JSX.Element => {
	const isMobileHook = useIsMobile();
	const [isMobile, setIsMobile] = useState<boolean | undefined>(isMobileProp);
	const [page, setPage] = useState<number>(0);
	const [pageLimit, setPageLimit] = useState<number>(defaultPageLimit);
	const [isSorting, setIsSorting] = useState<boolean>(false);
	const [sortColumns, setSortColumns] = useState<Array<TSortColumn<K>>>(defaultSortColumns);
	const [sortedData, setSortedData] = useState<Array<T>>(data ?? []);
	const [slicedData, setSlicedData] = useState<Array<T>>(data ?? []);
	const [expandedRows, setExpandedRows] = useState<Array<string>>([]);
	// Used to track the previous state of isCollapseAll.  Initially set to the opposite of isCollapseAll
	const [prevIsCollapseAll, setPrevIsCollapseAll] = useState<boolean>(!isCollapseAll);

	// If the isMobile prop is not set
	// The Table will automatically determine when to display mobile
	useEffect(() => {
		if (isMobileProp === undefined) setIsMobile(isMobileHook);
		else setIsMobile(isMobileProp);
	}, [isMobileProp, isMobileHook]);

	// Determine which columns to display based on whether or not we're in mobile view
	const filteredColumns = useMemo(() => {
		return filterColumns(columns, isMobile, data);
	}, [isMobile, columns, data]);

	// Only include parent columns that are needed and make sure the spacing with the child columns is correct
	const filteredParentColumns = useMemo(() => {
		return filterParentColumns(filteredColumns, parentColumns);
	}, [parentColumns, filteredColumns]);

	// Make sure columns are ordered correctly -> order of top level column array is what determines order
	const filteredColumnsSorted = useMemo(() => {
		return sortChildColumns(filteredColumns, filteredParentColumns);
	}, [filteredColumns, filteredParentColumns]);

	const addSortColumn = (columnValue: K | string) => {
		const sortColumnIndex = sortColumns.findIndex(
			(sortColumn: TSortColumn<K>) => sortColumn.columnValue === columnValue
		);
		if (sortColumnIndex === 0) {
			// Column is already first, so change the direction or if currently DESC remove from sortColumns
			if (sortColumns[0].sortDirection === DESC) setSortColumns([...sortColumns.slice(1)]);
			else {
				setSortColumns(
					sortColumns.map((sortColumn: TSortColumn<K>, index: number) => {
						return index === 0
							? { columnValue: columnValue, sortDirection: sortColumn.sortDirection === ASC ? DESC : ASC }
							: sortColumn;
					})
				);
			}
		} else if (sortColumnIndex !== -1) {
			// Move the column to be first and set the sort direction to ASC
			const newSortColumns = [...sortColumns];
			newSortColumns.splice(sortColumnIndex, 1);
			newSortColumns.unshift({ columnValue: columnValue, sortDirection: ASC });
			setSortColumns(newSortColumns);
		} else {
			setSortColumns([{ columnValue: columnValue, sortDirection: ASC }, ...sortColumns]);
		}
	};

	const getSortIcon = (column: TColumn<T, K>, sortColumns: Array<TSortColumn<K>>) => {
		const sortColumnIndex = sortColumns.findIndex(
			(sortColumn: TSortColumn<K>) => sortColumn.columnValue === column.value
		);
		if (sortColumnIndex === -1) return undefined;

		return (
			<Box color={sortColumnIndex === 0 ? "white" : "#7b7b7b"} fontSize="0.6em" marginLeft="4px">
				{sortColumns[sortColumnIndex].sortDirection === ASC
					? String.fromCharCode(9650)
					: String.fromCharCode(9660)}
			</Box>
		);
	};

	// Sort the data whenever the data or sortColumns change
	useEffect(() => {
		setIsSorting(true);
		const newSortedData = getSortedData(data, columns, sortColumns);
		setSortedData(newSortedData);
		setIsSorting(false);
		// If getCustomRowKeyFunction has not been defined, collapse all rows
		if (!getCustomRowKeyFunction) setExpandedRows([]);
	}, [data, columns, sortColumns, getCustomRowKeyFunction]);

	useEffect(() => {
		if (isUsePagination) setSlicedData(sortedData?.slice(page * pageLimit, page * pageLimit + pageLimit));
		else setSlicedData(sortedData);
	}, [sortedData, page, pageLimit, isUsePagination]);

	if (!highlightIndex && highlightIndexFunction) highlightIndex = highlightIndexFunction(slicedData);

	// Keyed data - used for looking up data by key
	const keyedData: Record<string, T> = useMemo(() => {
		const newKeyedData: Record<string, T> = {};
		data?.forEach((row: T, index: number) => {
			newKeyedData[getCustomRowKeyFunction ? getCustomRowKeyFunction(row) : `${index}`] = row;
		});
		return newKeyedData;
	}, [data, getCustomRowKeyFunction]);

	const renderedNestedContent = useCallback(
		(row: T, index: number) => {
			if (!nestedContentFunction || !keyedData) return;
			const key = getCustomRowKeyFunction ? getCustomRowKeyFunction(row) : `${index}`;
			return nestedContentFunction(keyedData[key]);
		},
		[nestedContentFunction, getCustomRowKeyFunction, keyedData]
	);

	//
	// Whenever isCollapseAll changes, it should now match prevIsCollapseAll.
	// If they don't match, then we know that this hook fired because a different dependency changed.
	//
	// We only want to expand/collapse all rows when the isCollapseAll dependency changes, so we
	// check whether isCollapseAll matches prevIsCollapseAll before changing anything
	//
	useEffect(() => {
		// "isCollapseAll" indicates whether the user can see the "Collapse All" or the "Expand All" button.

		// If "isCollapseAll", the user can see the "Collapse All" button and therefore must have just
		// clicked the "Expand All" button.  Therefore, we should expand all rows.
		if (isCollapseAll && prevIsCollapseAll) {
			// Update prevIsCollapseAll to the previous state of isCollapseAll
			setPrevIsCollapseAll(false);
			// Expand all rows
			setExpandedRows(
				data?.map((row: T, index: number) =>
					getCustomRowKeyFunction ? getCustomRowKeyFunction(row) : `${index}`
				) ?? []
			);
		}
		// Otherwise, the user can see "Expand All" and must have just clicked the "Collapse All" button,
		// so we collapse all rows.
		else if (isCollapseAll === false && prevIsCollapseAll === false) {
			// Update prevIsCollapseAll to the previous state of isCollapseAll
			setPrevIsCollapseAll(true);
			// Collapse all rows
			setExpandedRows([]);
		}
	}, [isCollapseAll, prevIsCollapseAll, setPrevIsCollapseAll, setExpandedRows, data, getCustomRowKeyFunction]);

	const hasOnRowClickFunction = useMemo(() => onRowClickFunction != null || nestedContentFunction != null, [
		nestedContentFunction,
		onRowClickFunction
	]);
	const onRowClick = useCallback(
		(row: T, index: number) => {
			if (onRowClickFunction) onRowClickFunction(row, index);
			else if (nestedContentFunction != null) {
				const key = getCustomRowKeyFunction ? getCustomRowKeyFunction(row) : `${index}`;
				if (expandedRows.includes(key)) {
					setExpandedRows(expandedRows.filter((expandedKey: string) => expandedKey !== key));
				} else {
					setExpandedRows([...expandedRows, key]);
				}
			}
		},
		[onRowClickFunction, getCustomRowKeyFunction, nestedContentFunction, expandedRows]
	);

	return (
		<>
			<DefaultTable sx={style?.table}>
				<DefaultThead sx={style?.thead}>
					{filteredParentColumns && filteredParentColumns.length && (
						<Tr sx={style?.tr}>
							{filteredParentColumns.map((column: TParentColumn) => (
								<DefaultParentTh
									key={`th-${column.id}`}
									colSpan={column.childColumnIds.length}
									sx={style?.parentTh}
								>
									<Flex sx={{ justifyContent: "center", textAlign: "center", ...column.style?.th }}>
										{column.label}
									</Flex>
								</DefaultParentTh>
							))}
						</Tr>
					)}
					<Tr sx={style?.tr}>
						{filteredColumnsSorted.map((column: TColumn<T, K>) => (
							<Th
								key={`th-${column.id ?? String(column.value)}`}
								onClick={() => {
									addSortColumn(column.value);
								}}
								sx={{
									width: column.width,
									...DefaultThStyle(Boolean(filteredParentColumns?.length)),
									...style?.th
								}}
							>
								<Tooltip
									hasArrow
									placement={headerTooltipPlacement}
									label={column.tooltip}
									sx={column.tooltip && !isMobile ? DefaultThTooltipStyle : DisplayNoneStyle}
								>
									<Flex
										justify={column.valueType === VALUE_TYPE_NUMBER ? "end" : "start"}
										textAlign={column.valueType === VALUE_TYPE_NUMBER ? "right" : "left"}
									>
										{isMobile && column.abbreviation ? column.abbreviation : column.label}
										{getSortIcon(column, sortColumns)}
										{/* Note: Pulser won't normally show, but in case the sorting lasts awhile show an indicator */}
										{isSorting && <Pulser backgroundColor={"white"} />}
									</Flex>
								</Tooltip>
							</Th>
						))}
					</Tr>
				</DefaultThead>
				<Tbody sx={style?.tbody}>
					{isLoadingData && (
						<>
							<Tr>
								<Td colSpan={filteredColumnsSorted.length} padding={0}>
									<Box h={100} className="loading-item"></Box>
								</Td>
							</Tr>
						</>
					)}
					{!isLoadingData &&
						slicedData?.map((row: T, index: number) => (
							<>
								<TableRow
									key={`tr-${index}`}
									index={index}
									data={row}
									columns={filteredColumnsSorted}
									sortColumns={sortColumns}
									highlightIndex={highlightIndex}
									onRowClickFunction={hasOnRowClickFunction ? onRowClick : undefined}
									isExpandableRows={isExpandableRows}
									style={{
										row: {
											border: expandedRows.includes(`${index}`)
												? "1px solid !important"
												: undefined,
											borderColor: expandedRows.includes(`${index}`)
												? "gray.500 !important"
												: undefined,
											...(getRowStyleFunction ? getRowStyleFunction(row, index, slicedData) : {})
										}
									}}
									isExpanded={expandedRows.includes(
										getCustomRowKeyFunction ? getCustomRowKeyFunction(row) : `${index}`
									)}
									setIsExpanded={setExpandedRows}
									getCustomRowKeyFunction={getCustomRowKeyFunction}
								/>
								{expandedRows.includes(
									getCustomRowKeyFunction ? getCustomRowKeyFunction(row) : `${index}`
								) &&
									renderedNestedContent(row, index) != null && (
										<NestedContentTr>
											<NestedContentTd colSpan={filteredColumnsSorted.length}>
												{renderedNestedContent(row, index)}
											</NestedContentTd>
										</NestedContentTr>
									)}
							</>
						))}
					{!data?.length && !isLoadingData && (
						<DefaultTr sx={DefaultTrEvenStyle}>
							<Td fontFamily="body" textAlign="center" colSpan={filteredColumnsSorted.length}>
								{emptyDataDisplayText}
							</Td>
						</DefaultTr>
					)}
				</Tbody>
			</DefaultTable>
			{isUsePagination && !!data?.length && (
				<Flex justify="space-between" wrap="wrap">
					<Flex align="center" fontSize="0.8em" margin="5px 0 0 10px">
						<Box as="span">
							Showing {page * pageLimit + 1} to{" "}
							{page * pageLimit + pageLimit < data.length ? page * pageLimit + pageLimit : data.length} of{" "}
							{data?.length ?? 0} results.
						</Box>
						{isAllowAdjustNumResults && (
							<Flex align="center" justify="center">
								<Box as="span" marginLeft="5px">
									Show
								</Box>
								<TextField
									fullWidth
									onChange={(e: TChangeEvent) =>
										e.target.value != null &&
										e.target.value !== "" &&
										parseFloat(e.target.value) > 0
											? setPageLimit(parseInt(e.target.value))
											: null
									}
									value={pageLimit.toString()}
									type={"number"}
									style={ShowResultsTextFieldStyle}
								/>
								<Box as="span">results.</Box>
							</Flex>
						)}
					</Flex>
					<Flex align="center" margin="5px 10px 0 0">
						<PreviousBox
							sx={page === 0 ? BoxDisabledStyle : undefined}
							onClick={() => (page === 0 ? null : setPage(page - 1))}
						>
							Previous
						</PreviousBox>
						{page > 1 && <PageBox onClick={() => setPage(page - 2)}>{page - 1}</PageBox>}
						{page > 0 && <PageBox onClick={() => setPage(page - 1)}>{page}</PageBox>}
						<PageBox sx={PageBoxActiveStyle} onClick={() => setPage(page)}>
							{page + 1}
						</PageBox>
						{(page + 1) * pageLimit + 1 <= data.length && (
							<PageBox onClick={() => setPage(page + 1)}>{page + 2}</PageBox>
						)}
						{(page + 2) * pageLimit + 1 <= data.length && (
							<PageBox onClick={() => setPage(page + 2)}>{page + 3}</PageBox>
						)}
						{(page + 3) * pageLimit + 1 <= data.length && page < 2 && (
							<PageBox onClick={() => setPage(page + 3)}>{page + 4}</PageBox>
						)}
						{(page + 4) * pageLimit + 1 <= data.length && page < 1 && (
							<PageBox onClick={() => setPage(page + 4)}>{page + 5}</PageBox>
						)}
						<NextBox
							sx={(page + 1) * pageLimit + 1 > data.length ? BoxDisabledStyle : undefined}
							onClick={() => ((page + 1) * pageLimit + 1 > data.length ? null : setPage(page + 1))}
						>
							Next
						</NextBox>
					</Flex>
				</Flex>
			)}
		</>
	);
};

export default Table;
