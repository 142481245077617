/*
	Calculate season totals for non event level stats
 */

export const PITCHER = "pitcher";
export const BATTER = "batter";
export const FIELDER = "fielder";
export const PLAYER_TYPES = [BATTER, PITCHER, FIELDER];

export const MAJORS = "majors";
export const MINORS = "minors";
export const OTHER = "other";

export const OTHER_LEVELS = ["WIN"];

const sum = (key, data, noNaN = false) => {
	let foundNaN = false;
	const sum = data.reduce((e, v) => {
		if (v.hasOwnProperty(key) && parseFloat(v[key])) {
			e += v[key];
		} else if (v.hasOwnProperty(key) && isNaN(parseFloat(v[key]))) {
			foundNaN = true;
		}
		return e;
	}, 0);

	if (noNaN && foundNaN) {
		return "-";
	}
	return sum;
};

// so far for calculating weighted average of woba and prc_plus
const calcWeightedAvg = (data, key) => {
	let fullStat = true;
	let notEmpty = false;
	const weightedVariables = data.reduce(
		(e, v) => {
			const { ab, ubb, hbp, sf, in_play, fb_pitches, tbf, vl_woba_n, vr_woba_n } = v;
			try {
				let stat = v[key];
				if (stat != null && typeof stat === "object") {
					if (stat.warn) fullStat = false;
					stat = stat.value;
				}
				if (stat == null) {
					fullStat = false;
					return e;
				} else notEmpty = true;
				let weight;
				switch (key) {
					case "wobacon":
						weight = in_play;
						break;
					case "vl_woba":
						weight = vl_woba_n;
						break;
					case "vr_woba":
						weight = vr_woba_n;
						break;
					case "fip":
					case "xfip":
					case "xfip_minus":
					case "era_minus":
						weight = tbf;
						break;
					case "fbv":
						weight = fb_pitches;
						break;
					default:
						weight = ab + ubb + sf + hbp;
				}
				if (key === "fbv" && (weight === 0 || weight == null)) {
					fullStat = false;
					return e;
				}
				const addToNumerator = stat * weight;
				e.numerator += addToNumerator;
				e.denominator += weight;
			} catch (err) {
				console.error(err);
			}
			return e;
		},
		{ numerator: 0, denominator: 0 }
	);
	const { numerator, denominator } = weightedVariables;
	const decimals = key === "prc_plus" ? 0 : key === "fbv" ? 1 : key === "fip" ? 2 : 3;
	return {
		value: notEmpty && denominator > 0 ? parseFloat((numerator / denominator).toFixed(decimals)) : null,
		warn: notEmpty && denominator > 0 && !fullStat
	};
};

const COMMON_COUNT_CALCULATORS = {
	g: (data, key = "g") => sum(key, data),
	h: (data, key = "h") => sum(key, data),
	k: (data, key = "k") => sum(key, data),
	bb: (data, key = "bb") => sum(key, data),
	ibb: (data, key = "ibb") => sum(key, data),
	ubb: (data, key = "ubb") => sum(key, data),
	singles: (data, key = "singles") => sum(key, data),
	doubles: (data, key = "doubles") => sum(key, data),
	triples: (data, key = "triples") => sum(key, data),
	hr: (data, key = "hr") => sum(key, data),
	hbp: (data, key = "hbp") => sum(key, data),
	sf: (data, key = "sf") => sum(key, data),
	ab: (data, key = "ab") => sum(key, data),
	bip: (data, key = "bip") => sum(key, data),
	gb: (data, key = "gb") => sum(key, data),
	r: (data, key = "r") => sum(key, data),
	sb: (data, key = "sb") => sum(key, data),
	cs: (data, key = "cs") => sum(key, data),
	woba: (data, key = "woba") => calcWeightedAvg(data, key),
	vl_woba: (data, key = "vl_woba") => calcWeightedAvg(data, key),
	vr_woba: (data, key = "vr_woba") => calcWeightedAvg(data, key),
	in_play: (data, key = "in_play") => sum(key, data),
	bunts: (data, key = "bunts") => sum(key, data)
};

export const PITCHER_COUNT_STAT_CALCULATORS = {
	pa: (data, key = "tbf") => sum(key, data),
	w: (data, key = "w") => sum(key, data),
	l: (data, key = "l") => sum(key, data),
	sv: (data, key = "sv") => sum(key, data),
	gs: (data, key = "gs") => sum(key, data),
	ip: (data, key = "ip") => sum(key, data),
	er: (data, key = "er") => sum(key, data),
	fbv: (data, key = "fbv") => calcWeightedAvg(data, key),
	fip: (data, key = "fip") => calcWeightedAvg(data, key),
	xfip: (data, key = "xfip") => calcWeightedAvg(data, key),
	xfip_minus: (data, key = "xfip_minus") => calcWeightedAvg(data, key),
	era_minus: (data, key = "era_minus") => calcWeightedAvg(data, key),
	...COMMON_COUNT_CALCULATORS
};

export const FIELDER_COUNT_STAT_CALCULATORS = {
	assists: (data, key = "assists") => sum(key, data),
	chances: (data, key = "chances") => sum(key, data),
	double_plays: (data, key = "double_plays") => sum(key, data),
	errors: (data, key = "errors") => sum(key, data),
	innings: (data, key = "innings") => sum(key, data),
	put_outs: (data, key = "put_outs") => sum(key, data),
	outs_played: (data, key = "outs_played") => sum(key, data),
	passed_ball: (data, key = "passed_ball") => sum(key, data, true),
	g: (data, key = "g") => sum(key, data),
	gs: (data, key = "gs") => sum(key, data)
	// sb: (data, key = "sb") => sum(key, data, true),
	// cs: (data, key = "cs") => sum(key, data, true)
};

const calcERA = data => {
	return parseFloat(((data.er / data.ip) * 9).toFixed(2));
};

const calcBBPer9 = data => {
	return parseFloat(((9 * data.bb) / data.ip).toFixed(2));
};

const calcKPer9 = data => {
	return parseFloat(((9 * data.k) / data.ip).toFixed(2));
};

export const calcBBPct = data => {
	try {
		if (!data.pa && data.bb > 0) throw new Error("BB > PA");
		return parseFloat((data.bb / data.pa).toFixed(3));
	} catch (err) {
		return null;
	}
};

export const calcKPct = data => {
	try {
		if (!data.pa && data.k > 0) throw new Error("K > PA");
		return parseFloat((data.k / data.pa).toFixed(3));
	} catch (err) {
		return null;
	}
};

const calcHRPer9 = data => {
	return parseFloat(((9 * data.hr) / data.ip).toFixed(2));
};

const calcGBPct = data => {
	return parseFloat((data.gb / (data.in_play - data.bunts)).toFixed(3));
};

const calcBabip = data => {
	const { h, hr, ab, k, sf } = data;
	const numerator = h - hr;
	const denominator = ab - k - hr + sf;

	return parseFloat((numerator / denominator).toFixed(3));
};

const calcFieldingPCT = data => {
	const { chances, errors } = data;

	return parseFloat((chances - errors) / chances);
};

const calcLOBPct = data => {
	const { h, bb, hbp, r, hr } = data;
	try {
		return parseFloat((h + bb + hbp - r) / (h + bb + hbp - 1.4 * hr));
	} catch (err) {
		return null;
	}
};

export const COMMON_COMPOSITE_STAT_CALCULATORS = {
	bb_pct: data => calcBBPct(data),
	k_pct: data => calcKPct(data)
};

export const PITCHER_COMPOSITE_STAT_CALCULATORS = {
	...COMMON_COMPOSITE_STAT_CALCULATORS,
	era: data => calcERA(data),
	bb_per_9: data => calcBBPer9(data),
	k_per_9: data => calcKPer9(data),
	hr_per_9: data => calcHRPer9(data),
	babip: data => calcBabip(data),
	gb_pct: data => calcGBPct(data),
	lob_pct: data => calcLOBPct(data)
};

export const FIELDER_COMPOSITE_STAT_CALCULATORS = {
	fielding_pct: data => calcFieldingPCT(data)
};

export const BATTER_COUNT_STAT_CALCULATORS = {
	pa: (data, key = "pa") => sum(key, data),
	rbi: (data, key = "rbi") => sum(key, data),
	wobacon: (data, key = "wobacon") => calcWeightedAvg(data, key),
	prc_plus: (data, key = "prc_plus") => calcWeightedAvg(data, key),
	...COMMON_COUNT_CALCULATORS
};

export const calcTB = data => {
	const { singles = 0, doubles = 0, triples = 0, hr = 0 } = data;

	return singles + doubles * 2 + triples * 3 + hr * 4;
};

export const calcSlg = data => {
	try {
		return parseFloat((data.tb / data.ab).toFixed(3));
	} catch (err) {
		return null;
	}
};

export const calcBA = data => {
	try {
		return parseFloat((data.h / data.ab).toFixed(3));
	} catch (err) {
		return null;
	}
};

export const calcISO = data => {
	try {
		return parseFloat((data.slg - data.avg).toFixed(3));
	} catch (err) {
		return null;
	}
};

export const calcOBP = data => {
	try {
		const { h, bb, hbp, ab, sf } = data;
		return parseFloat(((h + bb + hbp) / (ab + bb + hbp + sf)).toFixed(3));
	} catch (err) {
		return null;
	}
};

export const calcOPS = data => {
	try {
		const { obp, slg } = data;
		return parseFloat((obp + slg).toFixed(3));
	} catch (err) {
		return null;
	}
};

export const BATTER_COMPOSITE_STAT_CALCULATORS = {
	...COMMON_COMPOSITE_STAT_CALCULATORS,
	avg: data => calcBA(data),
	tb: data => calcTB(data),
	slg: data => calcSlg(data),
	iso: data => calcISO(data),
	babip: data => calcBabip(data),
	obp: data => calcOBP(data),
	ops: data => calcOPS(data)
};

/*
	Pass down data for aggregation
 */
const getAggregatedStats = (data, countStatCalculators, compositeStatCalculators) => {
	if (data.length > 0) {
		const aggregateRow = {};
		for (const [key, val] of Object.entries(countStatCalculators)) {
			aggregateRow[key] = val(data);
		}
		for (const [key, val] of Object.entries(compositeStatCalculators)) {
			aggregateRow[key] = val(aggregateRow);
		}
		return aggregateRow;
	}
	return null;
};

export const getPitcherAggregateRow = data => {
	return getAggregatedStats(data, PITCHER_COUNT_STAT_CALCULATORS, PITCHER_COMPOSITE_STAT_CALCULATORS);
};

export const getBatterAggregateRow = data => {
	return getAggregatedStats(data, BATTER_COUNT_STAT_CALCULATORS, BATTER_COMPOSITE_STAT_CALCULATORS);
};

export const getFielderAggregateRow = data => {
	return getAggregatedStats(data, FIELDER_COUNT_STAT_CALCULATORS, FIELDER_COMPOSITE_STAT_CALCULATORS);
};

export const LEVELS = {
	WIN: 9,
	FRk: 8,
	Rk: 7,
	"A-": 6,
	A: 5,
	"A+": 4,
	AA: 3,
	AAA: 2,
	MLB: 1
};

export const sortByLevel = (a, b) => {
	const { level } = a;
	const { level: levelB } = b;

	const levelNum = level in LEVELS ? LEVELS[level] : -1;
	const levelBNum = levelB in LEVELS ? LEVELS[levelB] : -1;

	if (levelNum < levelBNum) return -1;
	if (levelNum > levelBNum) return 1;
	return 0;
};

export const sortByLevelIncludingTotalRows = (a, b) => {
	const { level } = a;
	const { level: levelB } = b;

	if (!a.is_team && b.is_team) return 1;
	else if (a.is_team && !b.is_team) return -1;

	const levelNum = level in LEVELS ? LEVELS[level] : -1;
	const levelBNum = levelB in LEVELS ? LEVELS[levelB] : -1;

	if (levelNum > levelBNum) return -1;
	if (levelNum < levelBNum) return 1;
	return 0;
};

export const formatAverageAges = averageAgesArray => {
	const averageAges = {};

	averageAgesArray.forEach(averageAge => {
		if (averageAges[averageAge.year] == null) averageAges[averageAge.year] = {};
		if (averageAges[averageAge.year][averageAge.level] == null) averageAges[averageAge.year][averageAge.level] = {};
		averageAges[averageAge.year][averageAge.level][averageAge.playerType] = averageAge.averageAge;
	});
	return averageAges;
};
