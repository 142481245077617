import React from "react";

import {
	TOOLTIP_STUFF_GRADE,
	TOOLTIP_STUFF_RA9,
	TOOLTIP_LOCATION_GRADE,
	TOOLTIP_LOCATION_RA9,
	TOOLTIP_INTRINSIC_GRADE,
	TOOLTIP_INTRINSIC_RA9
} from "_react/shared/_constants/tooltips";
import { getLevelDisplayFromLevels } from "_react/shared/_helpers/stats";
import { TColumn, TParentColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { formatRa9, format2080Grade } from "_react/shared/data_models/seasonal_grades/_helpers";
import { formatPercentage } from "_react/shared/ui/presentation/components/stat/shared/_helpers";
import TeamColumn from "_react/shared/ui/data/tables/shared/TeamColumn";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import { ICON_CIRCLE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";

import { getLevelsFromRow } from "_react/shared/ui/data/tables/PitcherFoundationalSkillsTable/_helpers";
import { TPitcherFoundationalSkillsRow } from "_react/shared/ui/data/tables/PitcherFoundationalSkillsTable/_types";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) => row.playerSeasonArsenalScores.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) => {
		if ("teamBam" in row.playerSeasonArsenalScores)
			return <TeamColumn teamBam={row.playerSeasonArsenalScores.teamBam} childRowCount={row.childData?.length} />;
		return <TeamColumn childRowCount={row.childData?.length} />;
	}
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) =>
		getLevelDisplayFromLevels(getLevelsFromRow(row), NULL_FILLER_TEXT),
	getSortValueFunction: (row: TPitcherFoundationalSkillsRow) => {
		// Child Rows
		if ("teamBam" in row.playerSeasonArsenalScores)
			return row.playerSeasonArsenalScores.teamBam?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
		// Parent Rows with nested data - highest level if multiple levels
		if (row.childData && row.childData.length > 1)
			return Math.min(
				...row.childData.map((childRow: TPitcherFoundationalSkillsRow) => {
					if ("teamBam" in childRow.playerSeasonArsenalScores)
						return (
							childRow.playerSeasonArsenalScores.teamBam?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER
						);
					return Number.MAX_SAFE_INTEGER;
				})
			);
		return NULL_FILLER_TEXT;
	}
};

const PITCH_TYPE_COLUMN = {
	id: "pitchType",
	value: "pitchType",
	label: "Pitch Type",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) =>
		row.playerSeasonArsenalScores.lkPitchType?.label && row.playerSeasonArsenalScores.lkPitchType?.abbreviation ? (
			<PitchTypeLabel
				label={row.playerSeasonArsenalScores.lkPitchType.label}
				abbreviation={row.playerSeasonArsenalScores.lkPitchType.abbreviation}
				shape={ICON_CIRCLE}
			/>
		) : (
			"Overall"
		),
	getSortValueFunction: (row: TPitcherFoundationalSkillsRow) =>
		row.playerSeasonArsenalScores.lkPitchType?.sortOrder ?? 0
};

const PITCHES_COLUMN = {
	id: "pitches",
	value: "pitches",
	label: "Pitches",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) => row.playerSeasonArsenalScores.total ?? NULL_FILLER_TEXT
};

const USAGE_COLUMN = {
	id: "usage",
	value: "usage",
	label: "Usage",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) =>
		formatPercentage(row.playerSeasonArsenalScores.usage) ?? NULL_FILLER_TEXT
};

const STUFF_GRADE_COLUMN = {
	id: "stuffGrade",
	value: "stuffGrade",
	label: "Grade",
	tooltip: TOOLTIP_STUFF_GRADE,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) =>
		format2080Grade(row.playerSeasonArsenalScores.stuffGrade, NULL_FILLER_TEXT)
};

const STUFF_RA9_COLUMN = {
	id: "stuffRa9",
	value: "stuffRa9",
	label: "RA9",
	tooltip: TOOLTIP_STUFF_RA9,
	isMobile: false,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) =>
		formatRa9(row.playerSeasonArsenalScores.stuffRa9, NULL_FILLER_TEXT)
};

const LOCATIONS_GRADE_COLUMN = {
	id: "locationsGrade",
	value: "locationsGrade",
	label: "Grade",
	tooltip: TOOLTIP_LOCATION_GRADE,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) =>
		format2080Grade(row.playerSeasonArsenalScores.locationGrade, NULL_FILLER_TEXT)
};

const LOCATIONS_RA9_COLUMN = {
	id: "locationsRa9",
	value: "locationsRa9",
	label: "RA9",
	tooltip: TOOLTIP_LOCATION_RA9,
	isMobile: false,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) =>
		formatRa9(row.playerSeasonArsenalScores.locationRa9, NULL_FILLER_TEXT)
};

const INTRINSIC_VALUE_GRADE_COLUMN = {
	id: "intrinsicValueGrade",
	value: "intrinsicValueGrade",
	label: "Grade",
	tooltip: TOOLTIP_INTRINSIC_GRADE,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) =>
		format2080Grade(row.playerSeasonArsenalScores.intrinsicGrade, NULL_FILLER_TEXT)
};

const INTRINSIC_VALUE_RA9_COLUMN = {
	id: "intrinsicValueRa9",
	value: "intrinsicValueRa9",
	label: "RA9",
	tooltip: TOOLTIP_INTRINSIC_RA9,
	isMobile: false,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherFoundationalSkillsRow) =>
		formatRa9(row.playerSeasonArsenalScores.intrinsicRa9, NULL_FILLER_TEXT)
};

export const PITCHER_FOUNDATIONAL_SKILLS_COLUMNS: Array<TColumn<
	TPitcherFoundationalSkillsRow,
	keyof TPitcherFoundationalSkillsRow
>> = [
	SEASON_COLUMN,
	TEAM_COLUMN,
	LEVEL_COLUMN,
	PITCH_TYPE_COLUMN,
	PITCHES_COLUMN,
	USAGE_COLUMN,
	STUFF_GRADE_COLUMN,
	STUFF_RA9_COLUMN,
	LOCATIONS_GRADE_COLUMN,
	LOCATIONS_RA9_COLUMN,
	INTRINSIC_VALUE_GRADE_COLUMN,
	INTRINSIC_VALUE_RA9_COLUMN
];

export const PITCHER_FOUNDATIONAL_SKILLS_PARENT_COLUMNS: Array<TParentColumn> = [
	{ label: "", id: "general", childColumnIds: ["season", "team", "level", "pitchType", "pitches", "usage"] },
	{ label: "Stuff", id: "stuff", childColumnIds: ["stuffGrade", "stuffRa9"] },
	{ label: "Locations", id: "locations", childColumnIds: ["locationsGrade", "locationsRa9"] },
	{ label: "Intrinsic", id: "intrinsic", childColumnIds: ["intrinsicValueGrade", "intrinsicValueRa9"] }
];
