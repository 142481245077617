import { Machine, assign } from "xstate";
import { CancelTokenSource } from "axios";

import { getCancelSource } from "utils/url_helpers";

import { TFormStructure, TFormComponent, TFormValidationError, ENTIRE_FORM } from "_react/shared/forms/_types";
import { loadFormStructure } from "_react/shared/forms/_network";
import { removeValidationRule } from "_react/shared/forms/_helpers";

interface IContext {
	formId: string;
	structure?: TFormStructure;
	validationErrors: TFormValidationError[];
	// unsavedChanges: TFormComponent[];
	cancelSource?: CancelTokenSource;
}

interface IStateSchema {
	states: {
		idle: {};
		fetchingFormStructure: {};
		postFetch: {
			states: {
				editing: {};
				viewing: {};
			};
		};
		errored: {};
	};
}

export const FORM_STRUCTURE_FETCH_SUCCESS = "done.invoke.fetchFormStructureService";
export const FORM_STRUCTURE_FETCH_ERROR = "FORM_STRUCTURE_FETCH_ERROR";
export const SET_VIEWING_STATE = "SET_VIEWING";
export const CANCEL_FETCH = "CANCEL_FETCH";
export const FETCH_FORM_STRUCTURE = "FETCH_FORM_STRUCTURE";
export const ADD_VALIDATION_ERROR = "ADD_VALIDATION_ERROR";
export const REMOVE_VALIDATION_ERROR = "REMOVE_VALIDATION_ERROR";

export const VIEWING = "VIEWING";
export const EDITING = "EDITING";

type TFormStructureFetchSuccessEvent = { type: typeof FORM_STRUCTURE_FETCH_SUCCESS; data: TFormStructure };
type TFormStructureFetchErrorEvent = { type: typeof FORM_STRUCTURE_FETCH_ERROR };
type TSetViewStateEvent = { type: typeof SET_VIEWING_STATE; payload: typeof VIEWING | typeof EDITING };
type TCancelFetchEvent = { type: typeof CANCEL_FETCH };
type TFetchFormStructureEvent = { type: typeof FETCH_FORM_STRUCTURE };
type TAddValidationErrorEvent = { type: typeof ADD_VALIDATION_ERROR; payload: TFormValidationError };
type TRemoveValidationErrorEvent = {
	type: typeof REMOVE_VALIDATION_ERROR;
	payload: TFormComponent | typeof ENTIRE_FORM;
};

type TEvent =
	| TFormStructureFetchSuccessEvent
	| TFormStructureFetchErrorEvent
	| TSetViewStateEvent
	| TCancelFetchEvent
	| TFetchFormStructureEvent
	| TAddValidationErrorEvent
	| TRemoveValidationErrorEvent;

export function createFormMachine(formId: string, readOnly: boolean) {
	return Machine<IContext, IStateSchema, TEvent>(
		{
			id: "form-machine",
			context: {
				formId,
				validationErrors: []
			},
			initial: "fetchingFormStructure",
			states: {
				idle: {
					on: {
						[FETCH_FORM_STRUCTURE]: { target: "fetchingFormStructure" }
					}
				},
				fetchingFormStructure: {
					on: {
						[CANCEL_FETCH]: { actions: "cancelFetch", target: "idle" }
					},
					entry: ["getNewCancelSource"],
					invoke: {
						id: "fetchFormStructureService",
						src: "fetchFormStructure",
						onDone: {
							target: "postFetch",
							actions: "handleFetchSuccess"
						},
						onError: {
							target: "errored"
						}
					}
				},
				postFetch: {
					on: {
						[SET_VIEWING_STATE]: [
							{ target: "postFetch.viewing", cond: "setModeToViewing" },
							{ target: "postFetch.editing", cond: "setModeToEditing" }
						]
					},
					initial: (readOnly ? "viewing" : "editing") as "editing" | "viewing" | undefined,
					states: {
						viewing: {},
						editing: {
							on: {
								[ADD_VALIDATION_ERROR]: { actions: "addValidationError" },
								[REMOVE_VALIDATION_ERROR]: { actions: "removeValidationError" }
							}
						}
					}
				},
				errored: {}
			}
		},
		{
			actions: {
				getNewCancelSource: assign({
					cancelSource: (_context, _event) => getCancelSource()
				}),
				cancelFetch: (context, _event) => {
					if (context.cancelSource != null) context.cancelSource.cancel();
				},
				handleFetchSuccess: assign({
					structure: (context, event) => {
						if (event.type !== FORM_STRUCTURE_FETCH_SUCCESS) return context.structure;
						return event.data;
					},
					cancelSource: (_context, _event) => undefined
				}),
				addValidationError: assign({
					validationErrors: (context, event) => {
						if (event.type !== ADD_VALIDATION_ERROR) return context.validationErrors;
						return [...context.validationErrors, event.payload];
					}
				}),
				removeValidationError: assign({
					validationErrors: (context, event) => {
						if (event.type !== REMOVE_VALIDATION_ERROR) return context.validationErrors;
						return removeValidationRule(context.validationErrors, event.payload);
					}
				})
			},
			guards: {
				setModeToViewing: (_context, event) => event.type === SET_VIEWING_STATE && event.payload === VIEWING,
				setModeToEditing: (_context, event) => event.type === SET_VIEWING_STATE && event.payload === EDITING
			},
			services: {
				fetchFormStructure: (context, _event) => {
					const { formId } = context;
					return loadFormStructure(formId);
				}
			}
		}
	);
}
