import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import {
	PLAYING_LEVEL_PRO,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_POSTSEASON,
	BATS_OVERALL,
	THROWS_R,
	THROWS_L
} from "_react/shared/data_models/seasonal_grades/_constants";
import { ISeasonalSwingMetrics } from "_react/shared/data_models/seasonal_grades/_types";
import { fetchSeasonalSwingMetrics } from "_react/shared/data_models/seasonal_grades/_network";
import { SOURCE_GUMBO, SOURCE_STATSAPI, VALID_GAME_TYPES } from "_react/shared/data_models/stats/_constants";
import { IStatsPlayerBatting, IStatsPlayerBattingByTeam } from "_react/shared/data_models/stats/_types";
import { fetchStatsPlayerBatting, fetchStatsPlayerBattingByTeam } from "_react/shared/data_models/stats/_network";

import { TSwingMetricsTableData } from "_react/shared/ui/data/tables/SwingMetricsTable/_types";

export const SEASONAL_SWING_METRICS_CANCEL_SOURCE = "seasonalSwingMetrics";
export const STATS_PLAYER_BATTING_CANCEL_SOURCE = "statsPlayerBatting";
export const STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE = "statsPlayerBattingByTeam";

export type TSwingMetricsTableCancelSource = {
	[SEASONAL_SWING_METRICS_CANCEL_SOURCE]?: CancelTokenSource;
	[STATS_PLAYER_BATTING_CANCEL_SOURCE]?: CancelTokenSource;
	[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TSwingMetricsTableFilters = {
	gameTypes: Array<string>;
	throws: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TSwingMetricsTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TSwingMetricsTableFilters;
	seasonalSwingMetrics?: Array<ISeasonalSwingMetrics> | null;
	statsPlayerBatting?: Array<IStatsPlayerBatting> | null;
	statsPlayerBattingByTeam?: Array<IStatsPlayerBattingByTeam> | null;
	cancelSources: TSwingMetricsTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface ISwingMetricsTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				contextRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all swing metrics by player season
				seasonalSwingMetrics: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all stats player batting
				statsPlayerBatting: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all stats player batting by team
				statsPlayerBattingByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_SEASONAL_SWING_METRICS = { initialized: { seasonalSwingMetrics: "fetching" } };
export const FETCHING_STATS_PLAYER_BATTING = { initialized: { statsPlayerBatting: "fetching" } };
export const FETCHING_STATS_PLAYER_BATTING_BYTEAM = {
	initialized: { statsPlayerBattingByTeam: "fetching" }
};

const FETCH_SEASONAL_SWING_METRICS_DONE = "done.invoke.fetchSeasonalSwingMetrics:invocation[0]";
const FETCH_STATS_PLAYER_BATTING_DONE = "done.invoke.fetchStatsPlayerBatting:invocation[0]";
const FETCH_STATS_PLAYER_BATTING_BYTEAM_DONE = "done.invoke.fetchStatsPlayerBattingByTeam:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_SEASONAL_SWING_METRICS = "SET_SEASONAL_SWING_METRICS";
export const SET_STATS_PLAYER_BATTING = "SET_STATS_PLAYER_BATTING";
export const SET_STATS_PLAYER_BATTING_BYTEAM = "SET_STATS_PLAYER_BATTING_BYTEAM";
export const SET_FILTERS = "SET_FILTERS";

type TFetchSeasonalSwingMetricsEvent = {
	type: typeof FETCH_SEASONAL_SWING_METRICS_DONE;
	data: Array<ISeasonalSwingMetrics> | undefined;
};
type TFetchStatsPlayerBattingEvent = {
	type: typeof FETCH_STATS_PLAYER_BATTING_DONE;
	data: Array<IStatsPlayerBatting> | undefined;
};
type TFetchStatsPlayerBattingByTeamEvent = {
	type: typeof FETCH_STATS_PLAYER_BATTING_BYTEAM_DONE;
	data: Array<IStatsPlayerBattingByTeam> | undefined;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetSeasonalSwingMetricsEvent = {
	type: typeof SET_SEASONAL_SWING_METRICS;
	value?: Array<ISeasonalSwingMetrics>;
};
type TSetStatsPlayerBattingEvent = {
	type: typeof SET_STATS_PLAYER_BATTING;
	value?: Array<IStatsPlayerBatting>;
};
type TSetStatsPlayerBattingByTeamEvent = {
	type: typeof SET_STATS_PLAYER_BATTING_BYTEAM;
	value?: Array<IStatsPlayerBattingByTeam>;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TSwingMetricsTableFilters;
};

type TSwingMetricsTableEvent =
	| TFetchSeasonalSwingMetricsEvent
	| TFetchStatsPlayerBattingEvent
	| TFetchStatsPlayerBattingByTeamEvent
	| TSetPlayerIdEvent
	| TSetSeasonalSwingMetricsEvent
	| TSetStatsPlayerBattingEvent
	| TSetStatsPlayerBattingByTeamEvent
	| TSetFiltersEvent;

export type TSwingMetricsTableSend = Interpreter<
	TSwingMetricsTableContext,
	ISwingMetricsTableStateSchema,
	TSwingMetricsTableEvent
>["send"];

const SwingMetricsTableMachine = (
	playerIdProp?: number,
	data?: TSwingMetricsTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TSwingMetricsTableContext, ISwingMetricsTableStateSchema, TSwingMetricsTableEvent>(
		{
			id: "swingMetricsTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					gameTypes: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_POSTSEASON],
					throws: [THROWS_L, THROWS_R],
					levels: undefined
				},
				seasonalSwingMetrics: data?.seasonalSwingMetrics,
				statsPlayerBatting: data?.statsPlayerBatting,
				statsPlayerBattingByTeam: data?.statsPlayerBattingByTeam,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: { actions: "setPlayerId" },
						SET_SEASONAL_SWING_METRICS: { actions: "setSeasonalSwingMetrics" },
						SET_STATS_PLAYER_BATTING: { actions: "setStatsPlayerBatting" },
						SET_STATS_PLAYER_BATTING_BYTEAM: { actions: "setStatsPlayerBattingByTeam" },
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						contextRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						seasonalSwingMetrics: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchSeasonalSwingMetrics",
												cond: "shouldFetchSeasonalSwingMetrics"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchSeasonalSwingMetrics",
									entry: ["refreshSeasonalSwingMetricsCancelSource"],
									invoke: {
										src: "fetchSeasonalSwingMetrics",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSeasonalSwingMetricsSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSeasonalSwingMetricsErrored"
										}
									}
								}
							}
						},
						statsPlayerBatting: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchStatsPlayerBatting",
												cond: "shouldFetchStatsPlayerBatting"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchStatsPlayerBatting",
									entry: ["refreshStatsPlayerBattingCancelSource"],
									invoke: {
										src: "fetchStatsPlayerBatting",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchStatsPlayerBattingSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchStatsPlayerBattingErrored"
										}
									}
								}
							}
						},
						statsPlayerBattingByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchStatsPlayerBattingByTeam",
												cond: "shouldFetchStatsPlayerBattingByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchStatsPlayerBattingByTeam",
									entry: ["refreshStatsPlayerBattingByTeamCancelSource"],
									invoke: {
										src: "fetchStatsPlayerBattingByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchStatsPlayerBattingByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchStatsPlayerBattingByTeamErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (context: TSwingMetricsTableContext, _event: TSwingMetricsTableEvent) =>
					context.playerId !== context.lastPlayerId,
				shouldFetchSeasonalSwingMetrics: (
					context: TSwingMetricsTableContext,
					_event: TSwingMetricsTableEvent
				) =>
					context.seasonalSwingMetrics === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchStatsPlayerBatting: (context: TSwingMetricsTableContext, _event: TSwingMetricsTableEvent) =>
					context.statsPlayerBatting === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchStatsPlayerBattingByTeam: (
					context: TSwingMetricsTableContext,
					_event: TSwingMetricsTableEvent
				) =>
					context.statsPlayerBattingByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearContext: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>({
					lastPlayerId: (context: TSwingMetricsTableContext, _event: TSwingMetricsTableEvent) =>
						context.playerId,
					seasonalSwingMetrics: (_context: TSwingMetricsTableContext, _event: TSwingMetricsTableEvent) =>
						undefined,
					statsPlayerBatting: (_context: TSwingMetricsTableContext, _event: TSwingMetricsTableEvent) =>
						undefined,
					statsPlayerBattingByTeam: (_context: TSwingMetricsTableContext, _event: TSwingMetricsTableEvent) =>
						undefined,
					cancelSources: (context: TSwingMetricsTableContext, _event: TSwingMetricsTableEvent) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>({
					playerId: (context: TSwingMetricsTableContext, event: TSwingMetricsTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (context: TSwingMetricsTableContext, _event: TSwingMetricsTableEvent) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setSeasonalSwingMetrics: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>({
					seasonalSwingMetrics: (context: TSwingMetricsTableContext, event: TSwingMetricsTableEvent) => {
						if (event.type !== SET_SEASONAL_SWING_METRICS) return context.seasonalSwingMetrics;
						return event.value;
					},
					cancelSources: (context: TSwingMetricsTableContext, event: TSwingMetricsTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_SEASONAL_SWING_METRICS) return cancelSources;
						cancelSources[SEASONAL_SWING_METRICS_CANCEL_SOURCE]?.cancel();
						cancelSources[SEASONAL_SWING_METRICS_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setStatsPlayerBatting: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>({
					statsPlayerBatting: (context: TSwingMetricsTableContext, event: TSwingMetricsTableEvent) => {
						if (event.type !== SET_STATS_PLAYER_BATTING) return context.statsPlayerBatting;
						return event.value;
					},
					cancelSources: (context: TSwingMetricsTableContext, event: TSwingMetricsTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_STATS_PLAYER_BATTING) return cancelSources;
						cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE]?.cancel();
						cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setStatsPlayerBattingByTeam: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>({
					statsPlayerBattingByTeam: (context: TSwingMetricsTableContext, event: TSwingMetricsTableEvent) => {
						if (event.type !== SET_STATS_PLAYER_BATTING_BYTEAM) return context.statsPlayerBattingByTeam;
						return event.value;
					},
					cancelSources: (context: TSwingMetricsTableContext, event: TSwingMetricsTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_STATS_PLAYER_BATTING_BYTEAM) return cancelSources;
						cancelSources[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>({
					filters: (context: TSwingMetricsTableContext, event: TSwingMetricsTableEvent) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshSeasonalSwingMetricsCancelSource: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>({
					cancelSources: (context: TSwingMetricsTableContext, _event: TSwingMetricsTableEvent) => {
						if (context.cancelSources[SEASONAL_SWING_METRICS_CANCEL_SOURCE] != null)
							context.cancelSources[SEASONAL_SWING_METRICS_CANCEL_SOURCE].cancel();
						context.cancelSources[SEASONAL_SWING_METRICS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshStatsPlayerBattingCancelSource: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>({
					cancelSources: (context: TSwingMetricsTableContext, _event: TSwingMetricsTableEvent) => {
						if (context.cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE].cancel();
						context.cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshStatsPlayerBattingByTeamCancelSource: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>(
					{
						cancelSources: (context: TSwingMetricsTableContext, _event: TSwingMetricsTableEvent) => {
							if (context.cancelSources[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE] != null)
								context.cancelSources[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE].cancel();
							context.cancelSources[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE] = getCancelSource();
							return context.cancelSources;
						}
					}
				),
				// Fetch Success Actions
				handleFetchSeasonalSwingMetricsSuccess: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>({
					seasonalSwingMetrics: (context: TSwingMetricsTableContext, event: TSwingMetricsTableEvent) => {
						if (event.type !== FETCH_SEASONAL_SWING_METRICS_DONE) return context.seasonalSwingMetrics;
						return event.data;
					}
				}),
				handleFetchStatsPlayerBattingSuccess: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>({
					statsPlayerBatting: (context: TSwingMetricsTableContext, event: TSwingMetricsTableEvent) => {
						if (event.type !== FETCH_STATS_PLAYER_BATTING_DONE) return context.statsPlayerBatting;
						return event.data;
					}
				}),
				handleFetchStatsPlayerBattingByTeamSuccess: assign<TSwingMetricsTableContext, TSwingMetricsTableEvent>({
					statsPlayerBattingByTeam: (context: TSwingMetricsTableContext, event: TSwingMetricsTableEvent) => {
						if (event.type !== FETCH_STATS_PLAYER_BATTING_BYTEAM_DONE)
							return context.statsPlayerBattingByTeam;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchSeasonalSwingMetricsErrored: (
					context: TSwingMetricsTableContext,
					_event: TSwingMetricsTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Swing Metrics",
							description: "Error fetching swing metrics by player and season.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchStatsPlayerBattingErrored: (
					context: TSwingMetricsTableContext,
					_event: TSwingMetricsTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Swing Metrics - Stats Batting",
							description: "Error fetching stats batting by player and season.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchStatsPlayerBattingByTeamErrored: (
					context: TSwingMetricsTableContext,
					_event: TSwingMetricsTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Swing Metrics - Stats Batting by Team",
							description: "Error fetching stats batting by player, season, and team",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchSeasonalSwingMetrics: (context: TSwingMetricsTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchSeasonalSwingMetrics(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								bats: BATS_OVERALL,
								isUseCache: true
							},
							context.cancelSources[SEASONAL_SWING_METRICS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchStatsPlayerBatting: (context: TSwingMetricsTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchStatsPlayerBatting(
							{
								playerId: playerId,
								"source[in]": [SOURCE_GUMBO, SOURCE_STATSAPI].join(","),
								"gameType[in]": [VALID_GAME_TYPES].join(","),
								isUseCache: true
							},
							context.cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchStatsPlayerBattingByTeam: (context: TSwingMetricsTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchStatsPlayerBattingByTeam(
							{
								playerId: playerId,
								"source[in]": [SOURCE_GUMBO, SOURCE_STATSAPI].join(","),
								"gameType[in]": [VALID_GAME_TYPES].join(","),
								isUseCache: true
							},
							context.cancelSources[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default SwingMetricsTableMachine;
