import React, { FC, useState } from "react";
import dayjs from "dayjs";

import { formatHeight } from "utils/functions";
import { getAgeFromBirthDate } from "utils/helpers";
import { useDraftDate } from "_react/_hooks";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import KeyboardArrowDown from "_react/shared/legacy/ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "_react/shared/legacy/ui/icons/KeyboardArrowUp";
import { TextPair } from "_react/shared/legacy/ui/Text";

import { INTL } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { AgentOrAgencyField } from "_react/playerpage/header/AgentOrAgencyField/Field";
import {
	InfoCardsResponsiveGrid,
	ExtraPlayerInfoButton,
	PlayerInfoCardWrapper,
	CARD_STYLE,
	InnerCardDiv,
	TEXT_PAIR_STYLE
} from "_react/playerpage/header/fields/_styles";

export type HeaderFieldsProps = {
	colorSchemeGroup?: ColorSchemeGroup;
	player: TPlayerPageCombinedPlayer;
	playerType?: string;
	showingAdditionalInfo?: boolean;
};

export const IntlHeaderFields: FC<HeaderFieldsProps> = ({ colorSchemeGroup, player }) => {
	const [showingAdditionalInfo, setShowingAdditionalInfo] = useState(false);
	const org = player && player.proProfile?.orgCode ? player.proProfile.orgCode.toLowerCase() : "none";

	const birthDate = player.intlProfile?.dateOfBirth ?? player.birthDate;
	const birthCity = player.intlProfile?.birthCity ?? player.birthCity;
	const birthState = player.intlProfile?.birthState ?? player.birthState;
	const birthCountry = player.intlProfile?.birthCountry ?? player.birthCountry;
	const age = getAgeFromBirthDate(birthDate);
	const draftDate = useDraftDate(player.intlProfile?.eligibleYear ? player.intlProfile.eligibleYear : undefined);
	const draftAge = getAgeFromBirthDate(birthDate, draftDate);
	const weight = player.intlProfile?.weight ?? player.weight;
	const height = player.intlProfile?.height ?? player.height;

	const agentName =
		!player.intlProfile?.agent?.firstName && !player.intlProfile?.agent?.lastName
			? "--"
			: `${player.intlProfile?.agent?.firstName ?? ""} ${player.intlProfile?.agent?.lastName ?? ""}`;

	const agentField = player.intlProfile?.agentId ? (
		<AgentOrAgencyField
			label={agentName}
			infoId={player.intlProfile?.agentId}
			infoKey={"agent"}
			playerType={INTL}
			org={org}
		/>
	) : (
		"--"
	);

	const agencyField = player.intlProfile?.agent?.agencyId ? (
		<AgentOrAgencyField
			label={player.intlProfile.agent.agency?.agencyName ?? "--"}
			infoId={player.intlProfile.agent.agencyId}
			infoKey={"agency"}
			playerType={INTL}
			org={org}
		/>
	) : (
		"--"
	);

	return (
		<InfoCardsResponsiveGrid playerType={INTL}>
			<PlayerInfoCardWrapper idx={0} showingAdditionalInfo={showingAdditionalInfo}>
				<Card style={CARD_STYLE}>
					<InnerCardDiv rowIdx={0}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"DOB/Age"}
								body={birthDate != null ? `${dayjs(birthDate).format("M/D/YYYY")} (${age})` : "--"}
								height={"max-content"}
							/>
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Draft Eligible"}
								body={`${player.intlProfile?.eligibleYear}`}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={1}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Birthplace"}
								body={`${birthCity ? `${birthCity}, ` : ""}${
									birthState ? birthState : birthCountry ?? "--"
								}`}
								height={"max-content"}
							/>
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Draft Age"} body={`${draftAge ?? ""}`} height={"max-content"} />
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={2}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Weight"} body={weight} height={"max-content"} />
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Height"}
								body={height ? formatHeight(height) : "--"}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<ExtraPlayerInfoButton
						colorSchemeGroup={colorSchemeGroup}
						onClick={() => {
							setShowingAdditionalInfo(val => !val);
						}}
						style={{ marginBottom: "-12px" }}
					>
						{" "}
						{showingAdditionalInfo ? (
							<KeyboardArrowUp style={{ height: "18px", marginBottom: "-5px" }} />
						) : (
							<KeyboardArrowDown style={{ height: "18px", marginBottom: "-5px" }} />
						)}
						More Information
					</ExtraPlayerInfoButton>
				</Card>
			</PlayerInfoCardWrapper>
			<PlayerInfoCardWrapper idx={1} showingAdditionalInfo={showingAdditionalInfo}>
				<Card style={CARD_STYLE}>
					<InnerCardDiv rowIdx={0}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Scout Assigned"}
								body={
									!player.scoutAssigned?.firstName && !player.scoutAssigned?.lastName
										? "--"
										: `${player.scoutAssigned?.firstName ?? ""} ${player.scoutAssigned?.lastName ??
												""}`
								}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={1}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Agent"} body={agentField} height={"max-content"} />
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={2}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Agency"} body={agencyField} height={"max-content"} />
						</div>
					</InnerCardDiv>
				</Card>
			</PlayerInfoCardWrapper>
		</InfoCardsResponsiveGrid>
	);
};
