/*
    Aggregated stat selectors
 */
// import { createSelector } from 'reselect';
import { getSeasonsRange } from "_react/stats/shared/helpers";

const getPlayerSeasonStatsState = state => state.betaStats.playerSeasonStats;
const getPlayerCareerStatsState = state => state.betaStats.playerCareerStats;
const getTeamSeasonsStatsState = state => state.betaStats.teamSeasonStats;
const getPlayerProjectionStatsState = state => state.betaStats.playerProjectionStats;

// Filter by attributes
// TODO: Possibly use lunr, probably overkill
const filterByAttributes = (data, attributes) => {
	if (data && data.length > 0) {
		return data.filter(x => {
			let match = true;
			for (const [key, val] of Object.entries(attributes)) {
				if (x.hasOwnProperty(key) && val.indexOf(x[key]) === -1) {
					match = false;
					break;
				}
			}
			return match;
		});
	}
	return [];
};

/////////////////////////////////////////////////////////////////////
// Player Season Stat selectors
////////////////////////////////////////////////////////////////////
export const selectPlayerSeasonStats = (state, bamId, playerType) => {
	const playerSeasonState = getPlayerSeasonStatsState(state);

	if (playerSeasonState.hasOwnProperty(bamId) && playerSeasonState[bamId].hasOwnProperty(playerType)) {
		return playerSeasonState[bamId][playerType].stats;
	}
	return null;
};

export const selectPlayerSeasonStatsFetching = (state, bamId, playerType) => {
	const playerSeasonState = getPlayerSeasonStatsState(state);

	if (playerSeasonState.hasOwnProperty(bamId) && playerSeasonState[bamId].hasOwnProperty(playerType)) {
		return playerSeasonState[bamId][playerType].fetching;
	}
	return true;
};

export const _selectBrewedPlayerSeasonStats = (state, bamId, playerType) => {
	const playerStats = selectPlayerSeasonStats(state, bamId, playerType);
	const brewed = playerStats.reduce((e, v) => {
		const { season, team_id, game_type } = v;
		// Basically tries to use gumbo stats by default when available
		if (
			e.hasOwnProperty(season) &&
			e[season].hasOwnProperty(team_id) &&
			e[season][team_id].hasOwnProperty(game_type)
		) {
			e[season][team_id][game_type].push(v);
		} else if (
			e.hasOwnProperty(season) &&
			e[season].hasOwnProperty(team_id) &&
			!e[season][team_id].hasOwnProperty(game_type)
		) {
			e[season][team_id][game_type] = [v];
		} else if (e.hasOwnProperty(season) && !e[season].hasOwnProperty(team_id)) {
			e[season][team_id] = { [game_type]: [v] };
		} else {
			e[season] = { [team_id]: { [game_type]: [v] } };
		}
		return e;
	}, {});

	// Choose PBP stats when there are two
	let results = [];
	for (const seasonStats of Object.values(brewed)) {
		// Gets season level stats
		for (const teamStats of Object.values(seasonStats)) {
			//Get teamStats for that season
			for (const gameTypes of Object.values(teamStats)) {
				//Get game level stats
				// If multiple sources choose PBP
				const sources = new Set(gameTypes.map(e => e.source));
				const numberOfSources = sources.size;
				if (numberOfSources > 1) {
					// Get gumbo source from the two. If there is no gumbo source it means the player played at two teams for
					// multiple levels two times in one year. Which means two -1 rows were found where statsapi.mlb.com
					// Gave us aggregated stats
					const gumboStats = gameTypes.filter(e => e.source === "gumbo");

					if (gumboStats.length === 1) results.push(gameTypes.filter(e => e.source === "gumbo")[0]);
				} else if (numberOfSources === 1) {
					results = results.concat(gameTypes);
				}
			}
		}
	}
	return results;
};

const _filterPlayerStatsForSeasonStatsAndRange = (playerStats, filterAttributes) => {
	if (playerStats && playerStats.length > 0) {
		const filteredStats = playerStats.filter(x => {
			let match = true;
			for (const [key, val] of Object.entries(filterAttributes)) {
				if (key === "season") continue;
				if (x.hasOwnProperty(key) && val.indexOf(x[key]) === -1) {
					match = false;
					break;
				}
			}
			return match;
		});
		const seasonsRange = getSeasonsRange(filteredStats);
		const filterSeasonsRange = filterAttributes.hasOwnProperty("season") ? filterAttributes["season"] : null;
		const seasonStats =
			filterSeasonsRange != null
				? filteredStats.filter(x => filterSeasonsRange.includes(x["season"]))
				: filteredStats;
		return {
			seasonStats,
			seasonsRange
		};
	}
	return { seasonStats: [], seasonsRange: [] };
};

export const selectBrewedPlayerSeasonStats = (state, bamId, playerType, filterAttributes) => {
	const playerStats = _selectBrewedPlayerSeasonStats(state, bamId, playerType);
	return _filterPlayerStatsForSeasonStatsAndRange(playerStats, filterAttributes);
};

export const selectPlayerSeasonStatsByAttributes = (state, bamId, playerType, filterAttributes = {}) => {
	const playerStats = selectPlayerSeasonStats(state, bamId, playerType);
	return _filterPlayerStatsForSeasonStatsAndRange(playerStats, filterAttributes);
};

export const selectPlayerCareerStats = (state, bamId, playerType) => {
	const playerCareerStatsState = getPlayerCareerStatsState(state);

	if (playerCareerStatsState.hasOwnProperty(bamId) && playerCareerStatsState[bamId].hasOwnProperty(playerType)) {
		return playerCareerStatsState[bamId][playerType].stats;
	}
	return null;
};

export const selectPlayerCareerStatsFetching = (state, bamId, playerType) => {
	const playerCareerStatsState = getPlayerCareerStatsState(state);

	if (playerCareerStatsState.hasOwnProperty(bamId) && playerCareerStatsState[bamId].hasOwnProperty(playerType)) {
		return playerCareerStatsState[bamId][playerType].fetching;
	}
	return true;
};

const _selectBrewedCareerStats = (state, bamId, playerType) => {
	const playerCareerStats = selectPlayerCareerStats(state, bamId, playerType);

	const brewed = playerCareerStats.reduce((e, v) => {
		const { level, game_type } = v;
		// Basically tries to use gumbo stats by default when available
		if (e.hasOwnProperty(level) && e[level].hasOwnProperty(game_type)) {
			e[level][game_type].push(v);
		} else if (e.hasOwnProperty(level) && !e[level].hasOwnProperty(game_type)) {
			e[level][game_type] = [v];
		} else {
			e[level] = { [game_type]: [v] };
		}
		return e;
	}, {});

	// Select the gumbo stats if the careers are the same length
	const results = [];
	// Hack for FRk
	const fRkSeasons = {};
	if ("FRk" in brewed) {
		for (const [key, val] of Object.entries(brewed["FRk"])) {
			const { seasons } = val.filter(e => e.source === "gumbo")[0];
			if (seasons) {
				fRkSeasons[key] = seasons;
			}
		}
	}
	for (const levelStats of Object.values(brewed)) {
		// If only one level just use it
		for (const careerLevelStats of Object.values(levelStats)) {
			if (careerLevelStats.length === 1) {
				results.push(careerLevelStats[0]);
			} else if (careerLevelStats.length > 1) {
				// If multiple levels pick which one is the longest between statsapi.mlb.com and gumbo
				const careerStats = careerLevelStats.reduce((e, v) => {
					// console.log(v)
					if (e === null) {
						e = v;
					} else {
						// eslint-disable-next-line prefer-const
						let { source, level, game_type, seasons: span } = v;
						// eslint-disable-next-line prefer-const
						let { seasons: currentSpan, source: currentSource } = e;

						// Get length covered
						// let currentSpan = currentLastSeason - currentFirstSeason;
						// let span = last_season - first_season;
						if (level === "Rk" && source === "gumbo" && game_type in fRkSeasons) {
							span += fRkSeasons[game_type];
						} else if (level === "Rk" && currentSource === "gumbo" && game_type in fRkSeasons) {
							currentSpan += fRkSeasons[game_type];
						}

						if (span > currentSpan) {
							e = v;
						} else if (span === currentSpan && level === "Rk") {
							// Hack to get rid of statsapi Rk row in favor of FRk row from gumbo
							if (currentSource === "statsapi.mlb.com") e = v;
						} else if (span === currentSpan && source === "gumbo") {
							e = v;
						}
					}
					return e;
				}, null);

				results.push(careerStats);
			}
		}
	}
	return results;
};

export const selectBrewedPlayerCareerStats = (state, bamId, playerType, attributes) => {
	const playerStats = _selectBrewedCareerStats(state, bamId, playerType);

	return filterByAttributes(playerStats, attributes);
};

export const selectPlayerCareerStatsByAttriubtes = (state, bamId, playerType, filterAttributes = {}) => {
	const playerStats = selectPlayerCareerStats(state, bamId, playerType);

	if (playerStats && playerStats.length > 0) {
		return playerStats.filter(x => {
			let match = true;
			for (const [key, val] of Object.entries(filterAttributes)) {
				if (x.hasOwnProperty(key) && val.indexOf(x[key]) === -1) {
					match = false;
					break;
				}
			}
			return match;
		});
	}
	return [];
};

/*
    Get all season stats for a team no filter
 */
const _selectTeamSeasonStats = (state, bamId, playerType, season) => {
	const teamStatsState = getTeamSeasonsStatsState(state);

	try {
		return teamStatsState[bamId][playerType][season];
	} catch (err) {
		return [];
	}
};

export const selectTeamSeasonStats = (state, bamId, playerType, season, filterAttributes = {}) => {
	const teamStats = _selectTeamSeasonStats(state, bamId, playerType, season);

	if (teamStats && teamStats.stats && teamStats.stats.length > 0) {
		return teamStats.stats.filter(x => {
			let match = true;
			for (const [key, val] of Object.entries(filterAttributes)) {
				if (x.hasOwnProperty(key) && val.indexOf(x[key]) === -1) {
					match = false;
					break;
				}
			}
			return match;
		});
	}
};

export const selectTeamSeasonStatsFetching = (state, bamId, playerType, season) => {
	const teamStats = _selectTeamSeasonStats(state, bamId, playerType, season);
	return teamStats != null ? teamStats.fetching : true;
};

export const selectPlayerProjectionStats = (state, bamId, playerType) => {
	const playerSeasonState = getPlayerProjectionStatsState(state);

	if (playerSeasonState.hasOwnProperty(bamId) && playerSeasonState[bamId].hasOwnProperty(playerType)) {
		return playerSeasonState[bamId][playerType].stats;
	}
	return null;
};

export const selectAverageAges = state => state.averageAges;
