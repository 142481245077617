import React, { useState } from "react";
import PropTypes from "prop-types";

import ArrowRight from "_react/shared/legacy/ui/icons/ArrowRight";
import ArrowDropDown from "_react/shared/legacy/ui/icons/ArrowDropDown";

import {
	rowStyle,
	cellStyle,
	edgeColumnBorder,
	borderColumnBorder,
	nonBorderColumnBorderHeader,
	totalRowCellStyle,
	careerRowCellStyle,
	selectedRow,
	selectedRowEdge,
	steamerProjectionRowCellStyle,
	philliesProjectionRowCellStyle,
	philliesProjectionChildRowCellStyle,
	philliesProjectionRowEndCellStyle
} from "_react/stats/shared/formatting";
import { getCellValue, isRowSelectable } from "_react/stats/shared/helpers";

const StatsTableRow = ({
	headers,
	data,
	hoveredColumn,
	setHoveredColumn,
	index,
	isAChildSelected,
	nestedIndex,
	history,
	expanded,
	expandable,
	setRowExpanded,
	onRowSelect,
	selected,
	selectedEdge,
	colorSchemeGroup
}) => {
	const [hoveredRow, setHoveredRow] = useState(false);

	const mouseOverRowColor = `${colorSchemeGroup.secondary.color}20`;
	const mouseOverColumnColor = `${colorSchemeGroup.secondary.hover}20`;
	const nestedRowEven = `${colorSchemeGroup.primary.color}20`;
	const nestedRowOdd = `${colorSchemeGroup.primary.hover}20`;
	const selectedNestedRowEven = `${colorSchemeGroup.primary.color}50`;
	const selectedNestedRowOdd = `${colorSchemeGroup.primary.hover}50`;
	const childSelectedWarning = `${colorSchemeGroup.secondary.color}50`;

	let rowStyleUsed = rowStyle(index);
	let dataCy = "stats-row";
	if (data.isCareerRow) {
		rowStyleUsed = careerRowCellStyle;
		dataCy = "stats-career-row";
	}
	if (data.isTotalRow) {
		rowStyleUsed = totalRowCellStyle;
		dataCy = "stats-total-row";
	}

	if (data.isSteamerProjection) {
		rowStyleUsed = steamerProjectionRowCellStyle;
		dataCy = "stats-steamer-proj-row";
	}

	if (data.isPhilliesProjection) {
		rowStyleUsed = data.isNestedRow ? philliesProjectionChildRowCellStyle : philliesProjectionRowCellStyle;
		if (data.isPhilliesProjectionEnd) {
			rowStyleUsed = { ...rowStyleUsed, ...philliesProjectionRowEndCellStyle };
		}
		dataCy = "stats-steamer-proj-row";
	}

	let backgroundColor = rowStyleUsed.backgroundColor;
	// if(data.isOnIL) backgroundColor = "#F5CEDF";
	if (data.isNestedRow) {
		backgroundColor = nestedIndex % 2 === 0 ? nestedRowEven : nestedRowOdd;
	}
	if (selected) {
		if (data.isNestedRow) {
			backgroundColor = nestedIndex % 2 === 0 ? selectedNestedRowEven : selectedNestedRowOdd;
		} else backgroundColor = selectedRow;
	} else if (isAChildSelected && !expanded) {
		backgroundColor = childSelectedWarning;
	}
	if (selectedEdge) backgroundColor = selectedRowEdge;
	if (hoveredRow) {
		backgroundColor = mouseOverRowColor;
	}

	const handleCellClick = (event, headerColumn, colIdx) => {
		if (expandable && colIdx === 0) {
			setRowExpanded(index);
		}
		if (headerColumn.onClick != null) {
			headerColumn.onClick({ history, data });
		}
		if (onRowSelect && colIdx > 0 && isRowSelectable(data)) {
			onRowSelect(event.metaKey);
		}
	};

	return (
		<tr
			data-cy={dataCy}
			onMouseEnter={() => setHoveredRow(true)}
			onMouseLeave={() => setHoveredRow(false)}
			style={{
				backgroundColor,
				borderColor: "white",
				border: "none",
				...rowStyleUsed
			}}
		>
			{headers.map((headerColumn, idx) => {
				let val = getCellValue(headerColumn, data);
				if (idx === 0 && expandable) {
					val = (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								marginLeft: "-23px"
							}}
						>
							<span>{expanded ? <ArrowDropDown /> : <ArrowRight />}</span>
							<span>{val}</span>
						</div>
					);
				}

				const clickable =
					(expandable && idx === 0) ||
					headerColumn.onClick != null ||
					(onRowSelect && idx > 0 && isRowSelectable(data));

				return (
					<td
						data-cy="stats-table-cell"
						key={idx}
						onClick={e => handleCellClick(e, headerColumn, idx)}
						onMouseEnter={() => setHoveredColumn(idx > 0 ? headerColumn.key : null)}
						onMouseLeave={() => setHoveredColumn(null)}
						style={{
							...cellStyle,
							borderRight:
								idx === headers.length - 1
									? edgeColumnBorder
									: headerColumn.isBorderColumn
									? borderColumnBorder
									: nonBorderColumnBorderHeader,
							backgroundColor:
								hoveredColumn === headerColumn.key ? mouseOverColumnColor : backgroundColor,
							cursor: clickable ? "pointer" : "default"
						}}
					>
						{val}
					</td>
				);
			})}
		</tr>
	);
};

StatsTableRow.propTypes = {
	colorSchemeGroup: PropTypes.object,
	data: PropTypes.object,
	expandable: PropTypes.bool,
	expanded: PropTypes.bool,
	headers: PropTypes.array,
	history: PropTypes.object,
	hoveredColumn: PropTypes.string,
	index: PropTypes.number,
	isAChildSelected: PropTypes.bool,
	nestedIndex: PropTypes.number,
	onRowSelect: PropTypes.func,
	selected: PropTypes.bool,
	selectedEdge: PropTypes.bool,
	setHoveredColumn: PropTypes.func,
	setRowExpanded: PropTypes.func
};

StatsTableRow.defaultProps = {
	expandable: false,
	expanded: false,
	nestedIndex: 0,
	isAChildSelected: false,
	selected: false,
	selectedEdge: false,
	setHoveredColumn: () => null,
	setRowExpanded: () => null
};

export default StatsTableRow;
