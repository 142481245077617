import React, { CSSProperties } from "react";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { IAddendumBReleaseClause } from "_react/shared/_types/schema/contracts";

const RELEASE_CLAUSE_COLUMNS = [
	{
		title: "Roster Type",
		name: "roster_type",
		getCellValue: (row: IAddendumBReleaseClause) => row.roster_type
	},
	{
		title: "Roster Type Requirement Date",
		name: "roster_1_date",
		getCellValue: (row: IAddendumBReleaseClause) =>
			[row.roster_date_operator_desc, row.roster_1_date].filter(Boolean).join(" ")
	},
	{
		title: "ML Club Decision Deadline",
		name: "ml_club_decision_duration",
		getCellValue: (row: IAddendumBReleaseClause) =>
			[row.ml_club_decision_duration, row.ml_club_decision_duration_type_desc].filter(Boolean).join(" ")
	}
];

type TAddendumBReleaseClauseTableStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

interface IProps {
	addendumBReleaseClauses: IAddendumBReleaseClause[];
	colorSchemeGroup: ColorSchemeGroup;
	style?: TAddendumBReleaseClauseTableStyle;
}

function AddendumBReleaseClauseTable({ addendumBReleaseClauses, colorSchemeGroup, style }: IProps) {
	return (
		<React.Fragment>
			<Card style={style?.card}>
				<ColoredCardHeader
					colorScheme={colorSchemeGroup.secondary}
					text={"Addendum B Release Clauses"}
					style={style?.cardHeader}
				/>
				<AutoFitTable
					columns={RELEASE_CLAUSE_COLUMNS}
					defaultSortColumn={"Roster Type"}
					defaultSortState={"desc"}
					isHighlightOnHover={false}
					rows={addendumBReleaseClauses}
					rowKey="release_clause_id"
				/>
			</Card>
		</React.Fragment>
	);
}

export default AddendumBReleaseClauseTable;
