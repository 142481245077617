import React, { FC, useCallback } from "react";
import styled from "@emotion/styled";
import { ColorScheme, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { IconProps } from "_react/shared/legacy/ui/icons";

import { Style } from "utils/tsutils";

export type TClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

type IconButtonProps = {
	children: React.ReactElement<IconProps>;
	colorScheme?: ColorScheme;
	disabled?: boolean;
	name?: string;
	onClick?(e: TClickEvent): void;
	onClickCapture?(e: TClickEvent): void;
	style?: Style;
};

type TStyledButtonProps = {
	style?: Style;
	colorScheme: ColorScheme;
	disabled: boolean;
};

const StyledIconButton = styled.button<TStyledButtonProps>(({ style, colorScheme, disabled }) => ({
	backgroundColor: colorScheme.color,
	color: colorScheme.text,
	height: style?.height ? style.height : style?.width ? style.width : "34px",
	width: style?.width ? style.width : style?.height ? style.height : "34px",
	borderRadius: style?.height ? `${parseFloat(`${style.height}`.replace("px", "")) / 2.0 + 6}px` : "18px",
	cursor: !disabled ? "pointer" : "not-allowed",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	padding: "5px 5px",
	border: "none",
	":focus": {
		outline: 0
	},
	":hover": {
		backgroundColor: !style?.hasOwnProperty("backgroundColor") ? colorScheme.hover : "",
		opacity: 0.5
	},
	":active": {
		backgroundColor: !style?.hasOwnProperty("backgroundColor") ? colorScheme.hover : "",
		opacity: 0.5
	},
	...style
}));

export const IconButton: FC<IconButtonProps> = ({
	children,
	colorScheme = defaultColorScheme.secondary,
	disabled = false,
	name,
	onClick = () => null,
	onClickCapture,
	style = {}
}) => {
	const handleClick = useCallback(
		(e: TClickEvent) => {
			e.preventDefault();
			if (!disabled) onClick(e);
		},
		[onClick, disabled]
	);

	const handleClickCapture = (e: TClickEvent) => {
		e.preventDefault();
		if (!disabled && onClickCapture != null) {
			onClickCapture(e);
		}
	};

	return (
		<StyledIconButton
			colorScheme={colorScheme}
			disabled={disabled}
			name={name}
			onClick={handleClick}
			onClickCapture={handleClickCapture}
			style={style}
		>
			{React.cloneElement(
				children,
				{
					style: {
						color: colorScheme.text,
						...children.props.style
					}
				},
				null
			)}
		</StyledIconButton>
	);
};
