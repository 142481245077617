import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";

import { fetchPlayerDupeIds } from "_react/shared/data_models/player/_network";
import { IPlayerDupeId } from "_react/shared/data_models/player/_types";

export type TPlayerDupeIdPopoverData = {
	playerDupeIds: Array<IPlayerDupeId>;
};

export type TPlayerDupeIdPopoverContext = {
	idType: string;
	targetId?: number;
	lastTargetId?: number;
	shouldFetchData?: boolean;
	playerDupeIds?: Array<IPlayerDupeId>;
	cancelSources: Record<string, CancelTokenSource>;
	toast?: CreateToastFnReturn;
};

interface IPlayerDupeIdPopoverStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				playerDupeIds: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_TARGET_ID = "SET_TARGET_ID";
export const SET_PLAYER_DUPE_IDS = "SET_PLAYER_DUPE_IDS";
const PLAYER_DUPE_IDS_CANCEL_SOURCE = "playerDupeIds";
export const FETCHING_PLAYER_DUPE_IDS = { initialized: { playerDupeIds: "fetching" } };

const FETCH_PLAYER_DUPE_IDS_DONE = "done.invoke.fetchingPlayerDupeIds:invocation[0]";
type TFetchPlayerDupeIdsEvent = { type: typeof FETCH_PLAYER_DUPE_IDS_DONE; data?: Array<IPlayerDupeId> };

type TSetPlayerIdEvent = { type: typeof SET_TARGET_ID; data?: number };
type TSetPlayerDupeIdsEvent = {
	type: typeof SET_PLAYER_DUPE_IDS;
	data?: Array<IPlayerDupeId>;
};

type TPlayerDupeIdPopoverEvent = TSetPlayerIdEvent | TSetPlayerDupeIdsEvent | TFetchPlayerDupeIdsEvent;

export type TPlayerDupeIdPopoverSend = Interpreter<
	TPlayerDupeIdPopoverContext,
	IPlayerDupeIdPopoverStateSchema,
	TPlayerDupeIdPopoverEvent
>["send"];

const PlayerDupeIdPopoverMachine = (
	idTypeProp: string,
	targetIdProp?: number,
	shouldFetchData = true,
	data?: TPlayerDupeIdPopoverData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPlayerDupeIdPopoverContext, IPlayerDupeIdPopoverStateSchema, TPlayerDupeIdPopoverEvent>(
		{
			id: "PlayerDupeIdPopover",
			initial: "initializing",
			context: {
				idType: idTypeProp,
				targetId: targetIdProp,
				lastTargetId: targetIdProp,
				shouldFetchData: shouldFetchData,
				playerDupeIds: data?.playerDupeIds,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_TARGET_ID]: { actions: ["setTargetId", "clearContext"] },
						[SET_PLAYER_DUPE_IDS]: { actions: "setPlayerDupeIds" }
					},
					states: {
						playerDupeIds: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayerDupeIds",
												cond: "shouldFetchPlayerDupeIds"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerDupeIds",
									entry: ["refreshPlayerDupeIdsCancelSource"],
									invoke: {
										src: "fetchPlayerDupeIds",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerDupeIdsSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerDupeIdsErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldFetchPlayerDupeIds: (context: TPlayerDupeIdPopoverContext, _event: TPlayerDupeIdPopoverEvent) =>
					context.playerDupeIds === undefined && shouldFetchData && context.targetId !== undefined
			},
			actions: {
				setTargetId: assign<TPlayerDupeIdPopoverContext, TPlayerDupeIdPopoverEvent>({
					targetId: (context: TPlayerDupeIdPopoverContext, event: TPlayerDupeIdPopoverEvent) => {
						if (event.type !== SET_TARGET_ID) return context.targetId;
						return event.data;
					}
				}),
				setPlayerDupeIds: assign<TPlayerDupeIdPopoverContext, TPlayerDupeIdPopoverEvent>({
					playerDupeIds: (context: TPlayerDupeIdPopoverContext, event: TPlayerDupeIdPopoverEvent) => {
						if (event.type !== SET_PLAYER_DUPE_IDS) return context.playerDupeIds;
						if (event.data === undefined) return undefined;
						return event.data;
					},
					cancelSources: (context: TPlayerDupeIdPopoverContext, event: TPlayerDupeIdPopoverEvent) => {
						if (event.type !== SET_PLAYER_DUPE_IDS) return context.cancelSources;
						if (context.cancelSources[PLAYER_DUPE_IDS_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_DUPE_IDS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_DUPE_IDS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearContext: assign<TPlayerDupeIdPopoverContext, TPlayerDupeIdPopoverEvent>({
					lastTargetId: (context: TPlayerDupeIdPopoverContext, _event: TPlayerDupeIdPopoverEvent) =>
						context.targetId,
					playerDupeIds: (_context: TPlayerDupeIdPopoverContext, _event: TPlayerDupeIdPopoverEvent) =>
						undefined,
					cancelSources: (context: TPlayerDupeIdPopoverContext, _event: TPlayerDupeIdPopoverEvent) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshPlayerDupeIdsCancelSource: assign<TPlayerDupeIdPopoverContext, TPlayerDupeIdPopoverEvent>({
					cancelSources: (context: TPlayerDupeIdPopoverContext, _event: TPlayerDupeIdPopoverEvent) => {
						if (context.cancelSources[PLAYER_DUPE_IDS_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_DUPE_IDS_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_DUPE_IDS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerDupeIdsSuccess: assign<TPlayerDupeIdPopoverContext, TPlayerDupeIdPopoverEvent>({
					playerDupeIds: (context: TPlayerDupeIdPopoverContext, event: TPlayerDupeIdPopoverEvent) => {
						if (event.type !== FETCH_PLAYER_DUPE_IDS_DONE) return context.playerDupeIds;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchPlayerDupeIdsErrored: (
					context: TPlayerDupeIdPopoverContext,
					_event: TPlayerDupeIdPopoverEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Player Dupe IDs",
							description: "Error fetching player dupe id data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchPlayerDupeIds: (context: TPlayerDupeIdPopoverContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchPlayerDupeIds(
							{
								targetId: context.targetId,
								idType: context.idType
							},
							context.cancelSources[PLAYER_DUPE_IDS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PlayerDupeIdPopoverMachine;
