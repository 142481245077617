import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import KeyboardArrowDown from "_react/shared/legacy/ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "_react/shared/legacy/ui/icons/KeyboardArrowUp";

import BlockLabel from "_react/playerpage/shared_js/evals/BlockLabel";

const AvgOFP = ({ ofp, change, type = "ofp" }) => {
	const color = change > 0 ? "#2E6A1C" : change < 0 ? "#C12A1D" : "gray";
	const content = (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				paddingLeft: change !== 0 && change ? "12px" : ""
			}}
		>
			<div style={{ fontSize: "1.2em" }}>{ofp}</div>
			{change !== 0 && (
				<div style={{ display: "flex", flexDirection: "column" }}>
					{change > 0 && (
						<div
							style={{
								color: "white",
								marginBottom: "-8px",
								marginTop: "-10px"
							}}
						>
							<KeyboardArrowUp fill="white" />
						</div>
					)}
					<div style={{ fontSize: "0.6em", color: "white" }}>{change ? Math.abs(change) : ""}</div>
					{change < 0 && (
						<div
							style={{
								color: "white",
								marginTop: "-5px",
								marginBottom: "-13px"
							}}
						>
							<KeyboardArrowDown fill="white" />
						</div>
					)}
				</div>
			)}
		</div>
	);

	const toolTip = (
		<div>
			Average Scout OFP: {ofp}
			<br />
			Change From Last Year: {change}
		</div>
	);
	return (
		<Tooltip placement="top" title={toolTip}>
			<BlockLabel color={color} content={content} label={`AVG ${type.toUpperCase()}`} />
		</Tooltip>
	);
};

AvgOFP.propTypes = {
	change: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	ofp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	type: PropTypes.string
};

export default AvgOFP;
