/** @jsxImportSource @emotion/react */
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";

import { $TSFixMe } from "utils/tsutils";
import { getArrow } from "_react/apollo/_helpers";
import { CELL_STYLE, TABLE_STYLE } from "_react/css";

export const advPropComparator = (compare: $TSFixMe) => {
	if (compare.hasOwnProperty("sortFunc")) {
		return compare.sortFunc;
	}
	const key = compare["name"];
	let getCellValue = compare["getCellValue"];

	if ("getValueForSort" in compare) {
		getCellValue = compare["getValueForSort"];
	}

	return (a: $TSFixMe, b: $TSFixMe) => {
		if (getCellValue === undefined) {
			if (key in a && key in b) {
				if (a[key] > b[key]) {
					return 1;
				} else {
					return -1;
				}
			} else if (key in a) {
				return 1;
			} else if (key in b) {
				return -1;
			}
			return 0;
		} else {
			let myA = getCellValue(a);
			let myB = getCellValue(b);

			// Handle Percentages
			if (typeof myA === "string" && myA.endsWith("%")) {
				myA = myA.substring(0, myA.indexOf("%"));
			}
			if (typeof myB === "string" && myB.endsWith("%")) {
				myB = myB.substring(0, myB.indexOf("%"));
			}

			myA = isNaN(myA) ? myA : parseFloat(myA);
			myB = isNaN(myB) ? myB : parseFloat(myB);
			if (myA > myB) {
				return 1;
			} else if (myA < myB) {
				return -1;
			} else {
				return 0;
			}
		}
	};
};

type AutoFitTableProps = {
	columns?: $TSFixMe;
	cypress?: string;
	defaultSortColumn?: string;
	defaultSortState?: string;
	highlightIndex?: number;
	highlightIndexFunc?: Function;
	cellBorderFunc?: Function;
	onRowClick?: Function;
	rows?: $TSFixMe;
	rowKey?: string;
	rowKeyFunc?: Function;
	offset?: number;
	limit?: number;
	isHighlightOnHover?: boolean;
	style?: $TSFixMe;
};

const AutoFitTableComp: FunctionComponent<AutoFitTableProps> = ({
	columns,
	cypress = "",
	defaultSortColumn,
	defaultSortState,
	highlightIndex,
	highlightIndexFunc,
	cellBorderFunc,
	onRowClick,
	rows,
	rowKey,
	rowKeyFunc,
	offset = 0,
	limit = rows ? rows?.length ?? 0 : 0,
	isHighlightOnHover = true
}) => {
	const history = useHistory();
	const [sortState, setSortState] = useState(defaultSortState);
	const [sortColumn, setSortColumn] = useState(defaultSortColumn);
	const [sortedRows, setSortedRows] = useState(rows);

	const sortTable = (header: string | undefined, direction: string | undefined) => {
		const headerObj = columns?.filter((entry: $TSFixMe) => entry.title === header)[0];
		if (headerObj != null && !headerObj?.isSortDisabled) {
			const rowsSorted = rows?.slice();
			if (direction === "desc") {
				rowsSorted?.sort(advPropComparator(headerObj)).reverse();
			} else {
				rowsSorted?.sort(advPropComparator(headerObj));
			}
			setSortState(direction);
			setSortColumn(header);
			setSortedRows(rowsSorted);
		} else if (!headerObj?.isSortDisabled) {
			setSortedRows(rows);
			setSortColumn(defaultSortColumn);
			setSortState(defaultSortState);
		}
	};

	if (!highlightIndex && highlightIndexFunc) highlightIndex = highlightIndexFunc(sortedRows);
	useEffect(() => {
		sortTable(sortColumn, sortState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rows]);

	return (
		<table style={{ ...TABLE_STYLE, border: "none" }}>
			<thead>
				<tr>
					{columns?.map((col: $TSFixMe, idx: number) => (
						<th
							key={col.title}
							onClick={() => {
								sortTable(
									col.title,
									col.title === sortColumn ? (sortState === "asc" ? "desc" : "asc") : defaultSortState
								);
							}}
							style={{
								...CELL_STYLE,
								backgroundColor: "black",
								color: "white",
								borderRight: idx !== columns.length - 1 ? "1px solid #808080" : "",
								borderBottom: "0px solid black",
								borderTop: "0px solid black",
								width: col.width ? col.width : ""
							}}
						>
							{col.title}
							{getArrow(col.title, sortColumn, sortState === "asc")}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{sortedRows?.slice(offset, offset + limit).map((row: $TSFixMe, rowIdx: number, array: $TSFixMe) => {
					return (
						<tr
							key={
								rowKey && row.hasOwnProperty(rowKey)
									? row[rowKey]
									: rowKeyFunc
									? rowKeyFunc(row)
									: rowIdx
							}
							style={{
								backgroundColor:
									rowIdx === highlightIndex ? "yellow" : rowIdx % 2 ? "#EEEEEE" : "white",
								color: "black",
								borderBottom: "0px solid black",
								borderTop: "0px solid black"
							}}
							css={
								isHighlightOnHover
									? css`
											:hover {
												background-color: #002b5c20 !important;
											}
									  `
									: undefined
							}
						>
							{columns?.map((col: $TSFixMe, colIdx: number) => (
								<td
									cy-data={`${cypress}-${rowIdx}-${colIdx}`}
									key={col.title}
									onClick={() => {
										if (col.onCellClick != null) col.onCellClick(row, onRowClick, history);
										else if (onRowClick) onRowClick(row, rowIdx);
									}}
									style={{
										...CELL_STYLE,
										borderRight: colIdx === columns.length - 1 ? "" : "1px solid #cbcbcb",
										borderBottom: "0px solid black",
										borderTop: "0px solid black",
										borderLeft: "none",
										cursor: !onRowClick ? undefined : "pointer",
										...(!cellBorderFunc ? {} : cellBorderFunc(row, array[rowIdx + 1] ?? undefined))
									}}
								>
									{col.hasOwnProperty("getCellValueFormat")
										? col.getCellValueFormat(row)
										: col.hasOwnProperty("getCellValue")
										? col.getCellValue(row)
										: row[col.name]}
								</td>
							))}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export const AutoFitTable = React.memo(AutoFitTableComp);
