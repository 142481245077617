import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function KeyboardArrowUp({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
			<path fill="none" d="M0 0h24v24H0z" />
		</IconSVG>
	);
}
