import { getSeasonFilters } from "_react/shared/_helpers/stats";
import {
	BATS_L,
	BATS_R,
	THROWS_L,
	THROWS_R,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON
} from "_react/shared/data_models/arsenal_scores/_constants";

export type TStatsTableFilters = {
	gameTypes?: Array<string>;
	bats?: Array<string>;
	throws?: Array<string>;
	pitchTypes?: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export const DEFAULT_STATS_TABLE_FILTERS = {
	gameTypes: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_POSTSEASON],
	bats: [BATS_L, BATS_R],
	throws: [THROWS_L, THROWS_R]
};

const checkFilterArray = (filter: string[], defaultFilters: string[]): boolean => {
	if (filter.length !== defaultFilters.length) return false;
	for (let i = 0; i < defaultFilters.length; i++) {
		const filterValue = defaultFilters[i];
		if (!filter.includes(filterValue)) return false;
	}

	return true;
};

export const isDefaultFilters = (
	filters: TStatsTableFilters,
	availablePitchTypes?: string[],
	availableLevels?: string[],
	maxSeason?: number,
	numDisplaySeasons?: number
): boolean => {
	// Check Game Types
	if (filters.gameTypes != null && !checkFilterArray(filters.gameTypes, DEFAULT_STATS_TABLE_FILTERS.gameTypes))
		return false;

	// Check Bats
	if (filters.bats != null && !checkFilterArray(filters.bats, DEFAULT_STATS_TABLE_FILTERS.bats)) return false;

	// Check Throws
	if (filters.throws != null && !checkFilterArray(filters.throws, DEFAULT_STATS_TABLE_FILTERS.throws)) return false;

	// Check Pitch Types
	if (
		availablePitchTypes != null &&
		filters.pitchTypes != null &&
		!checkFilterArray(filters.pitchTypes, availablePitchTypes)
	)
		return false;

	// Check Levels
	if (availableLevels != null && filters.levels != null && !checkFilterArray(filters.levels, availableLevels))
		return false;

	// Check Seasons
	if (maxSeason != null && filters.maxSeason != null && filters.minSeason != null && numDisplaySeasons != null) {
		const defaultSeasons = getSeasonFilters(undefined, undefined, maxSeason, numDisplaySeasons);
		if (defaultSeasons.minSeason !== filters.minSeason || defaultSeasons.minSeason !== filters.minSeason)
			return false;
	}

	return true;
};
