import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TProModelPlayer } from "_react/promodel/leaderboard/_types";
import { TSimpleHitterProjectionsPlayer } from "_react/shared/data_models/projections/_types";

import {
	POSITION_GROUP_POSITION_PLAYER,
	POSITION_GROUP_IF,
	POSITION_GROUP_OF,
	POSITION_GROUP_C,
	POSITION_GROUP_1B_DH,
	POSITION_GROUP_RP,
	POSITION_GROUP_SP,
	IF_POSITIONS,
	OF_POSITIONS,
	C_POSITIONS,
	DH_1B_POSITIONS,
	RP_POSITIONS,
	SP_POSITIONS,
	POSITION_GROUP_PITCHER,
	PITCHER_POSITIONS
} from "_react/shared/_constants/position_groups";
import { TPositionGroup } from "_react/shared/_types/position_groups";

export const getPositionGroupFromPosition = (
	position?: string | null,
	useDiscretePositionGroups = false
): TPositionGroup => {
	// Default to position players
	if (!position) return POSITION_GROUP_POSITION_PLAYER;
	// Check the non-discrete position groups
	if (SP_POSITIONS.includes(position)) return POSITION_GROUP_SP;
	if (RP_POSITIONS.includes(position)) return POSITION_GROUP_RP;
	if (!useDiscretePositionGroups) return POSITION_GROUP_POSITION_PLAYER;
	// Check discrete position groups for position players
	if (IF_POSITIONS.includes(position)) return POSITION_GROUP_IF;
	if (OF_POSITIONS.includes(position)) return POSITION_GROUP_OF;
	if (C_POSITIONS.includes(position)) return POSITION_GROUP_C;
	if (DH_1B_POSITIONS.includes(position)) return POSITION_GROUP_1B_DH;
	// Default to position players
	return POSITION_GROUP_POSITION_PLAYER;
};

export const getNonDiscretePositionGroup = (
	projectedPositionGroup?: TPositionGroup | null,
	position?: string | null
): TPositionGroup => {
	if (projectedPositionGroup) {
		// Convert projected position groups to non-discrete versions
		// ie. SP, RP, or Position Player
		if (
			projectedPositionGroup === POSITION_GROUP_SP ||
			projectedPositionGroup === POSITION_GROUP_RP ||
			projectedPositionGroup === POSITION_GROUP_PITCHER
		)
			return projectedPositionGroup;
		return POSITION_GROUP_POSITION_PLAYER;
	}
	return getPositionGroupFromPosition(position, false);
};

export const getPosition = (
	player?: TPlayerPageCombinedPlayer | TProModelPlayer | TSimpleHitterProjectionsPlayer | null,
	isReturnLabel = false
): string | undefined | null => {
	if (isReturnLabel)
		return player?.proProfile?.projectedPositionRelationship?.label ?? player?.positionRelationship?.label;
	return player?.proProfile?.projectedPositionRelationship?.value ?? player?.positionRelationship?.value;
};

export const getPositionGroup = (
	player?: TPlayerPageCombinedPlayer | TProModelPlayer | TSimpleHitterProjectionsPlayer | null
): TPositionGroup => {
	const playerPosition = getPosition(player);

	const isPitcherPosition = PITCHER_POSITIONS.includes(playerPosition ?? "");

	// If they have a pitcher position group and a position player position
	// Get their position player position group from their position
	return getNonDiscretePositionGroup(player?.proProfile?.projectedPositionGroup) !== POSITION_GROUP_POSITION_PLAYER &&
		!isPitcherPosition
		? getPositionGroupFromPosition(player?.position, true)
		: player?.proProfile?.projectedPositionGroup ?? getPositionGroupFromPosition(player?.position, true);
};

export const getPositionGroupLabel = (positionGroup: string): string => {
	if (positionGroup === POSITION_GROUP_1B_DH) return "1B/DH";
	if (positionGroup === POSITION_GROUP_PITCHER) return "Pitcher";
	if (positionGroup === POSITION_GROUP_POSITION_PLAYER) return "Position Player";
	return positionGroup;
};
