import * as _ from "lodash";
import dayjs from "dayjs";

import { ROSTER_STATUS_MAP } from "utils/constants";

export const formatWOBACON = value => {
	if ((!value && value !== 0) || isNaN(value)) {
		return "-";
	} else if (value >= 1) {
		return value.toFixed(3);
	} else if (value < 0) {
		return "-" + value.toFixed(3).substring(2);
	}
	return value.toFixed(3).substring(1);
};

export const formatPct = (value, decimals = 0) => {
	if ((!value && value !== 0) || isNaN(value)) {
		return "-";
	}
	return (100 * value).toFixed(decimals) + "%";
};

export function formatIP(ip) {
	if (ip == null) {
		return ip;
	} else if (ip % 1 === 0) {
		return ip + ".0";
	} else if (!ip.toString().includes(".")) {
		return ip;
	} else if (ip.toString().split(".")[1][0] === "0") {
		return ip.toString().split(".")[0] + ".0";
	} else if (ip.toString().split(".")[1][0] === "3" || ip.toString().split(".")[1][0] === "2") {
		return ip.toString().split(".")[0] + ".1";
	} else if (ip.toString().split(".")[1][0] === "6" || ip.toString().split(".")[1][0] === "7") {
		return ip.toString().split(".")[0] + ".2";
	} else if (ip.toString().split(".")[1][0] === "9" || ip.toString().split(".")[1][0] === "8") {
		return parseInt(ip.toString().split(".")[0], 10) + 1 + ".0";
	}
	return ip.toString();
}

export function formatHeight(height) {
	let heightStr = "";
	if (parseInt(height, 10) > 500) {
		heightStr = String(height).charAt(0) + "-";
		if (String(height).charAt(1) === "0") {
			heightStr += String(height).charAt(2);
		} else {
			heightStr += String(height).charAt(1);
			heightStr += String(height).charAt(2);
		}
	} else {
		const heightFloat = height / 12.0;
		heightStr = String(heightFloat).charAt(0) + "'" + String(Math.round((heightFloat % 1) * 12)) + '"';
	}
	return heightStr;
}

export function isIntegerInput(value) {
	return (
		!isNaN(value) &&
		parseInt(Number(value), 10) == value && // eslint-disable-line eqeqeq
		!isNaN(parseInt(value, 10))
	);
}

export const average = arr => {
	arr = arr.filter(d => d != null);
	return arr.reduce((p, c) => p + c, 0) / arr.length;
};

export function isValidDate(date) {
	const parsedDates = [dayjs(date, "YYYY-MM-DD", true), dayjs(date, "YYYY-MM-DD HH:mm:ss", true)];

	for (let i = 0; i < parsedDates.length; i++) {
		const parsedDate = parsedDates[i];

		// Check overall valid date
		if (!dayjs().isValid(parsedDate)) continue;

		// Check Year
		if (parsedDate.year() > 1900 && parsedDate.year() < 2100) return true;
	}

	// Invalid Date
	return false;
}

export const getdayjsFromUnixTS = unixSeconds => {
	return dayjs(unixSeconds * 1000);
};

export const getRosterStatusFromProPlayer = player => {
	if (player.hasOwnProperty("roster_status_mj") || player.hasOwnProperty("roster_status_mn")) {
		try {
			let status =
				ROSTER_STATUS_MAP[player.roster_status_mj != null ? player.roster_status_mj : player.roster_status_mn];
			if (
				player.roster_status_mj != null &&
				ROSTER_STATUS_MAP[player.roster_status_mj] === "Optioned" &&
				ROSTER_STATUS_MAP[player.roster_status_mn] !== "Active"
			) {
				status = `${status} - ${ROSTER_STATUS_MAP[player.roster_status_mn]}`;
			}
			return status;
		} catch (err) {
			return "";
		}
	}
	try {
		let status =
			ROSTER_STATUS_MAP[
				player.proProfile?.rosterStatusMj != null
					? player.proProfile?.rosterStatusMj
					: player.proProfile?.rosterStatusMn
			];
		if (
			player.proProfile?.rosterStatusMj != null &&
			ROSTER_STATUS_MAP[player.proProfile?.rosterStatusMj] === "Optioned" &&
			ROSTER_STATUS_MAP[player.proProfile?.rosterStatusMn] !== "Active"
		) {
			status = `${status} - ${ROSTER_STATUS_MAP[player.proProfile?.rosterStatusMn]}`;
		}
		return status;
	} catch (err) {
		return "";
	}
};

export const getPriorOutright = player => {
	return player.prior_outright_date != null ? `Yes (${dayjs(player.prior_outright_date).format("M/D/YYYY")})` : "No";
};

export function titleCase(str) {
	const splitStr = str.toLowerCase().split(" ");
	for (let i = 0; i < splitStr.length; i++) {
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	return splitStr.join(" ");
}

export function selectHeight() {
	return window.innerHeight;
}

export function selectWidth() {
	return window.innerWidth;
}

export const mobileMaxWidth = 991;
export function getIsMobile() {
	return selectWidth() <= mobileMaxWidth;
}

export const getColorFromAlpha = (greater, alpha) => {
	if (!greater) {
		// BLUE RANGE
		return {
			fill: `rgb(0, 0, 255, ${alpha})`,
			alpha,
			color: "blue"
		};
	} else {
		// RED RANGE
		return {
			fill: `rgb(255, 0, 0, ${alpha})`,
			alpha,
			color: "red"
		};
	}
};

export const convertCamelToUnderscore = objectToConvert => {
	if (typeof objectToConvert !== "object" || objectToConvert == null) return objectToConvert;
	if (Array.isArray(objectToConvert)) return objectToConvert.map(a => convertCamelToUnderscore(a));
	return Object.keys(objectToConvert).reduce((convertedObject, propertyName) => {
		const newKey = propertyName
			.split(/(?=[A-Z])/)
			.join("_")
			.toLowerCase();
		convertedObject[newKey] = convertCamelToUnderscore(objectToConvert[propertyName]);
		return convertedObject;
	}, {});
};

export const pitchTypeToColor = (pitchType, opacity = 1) => {
	if (pitchType === "FB" || pitchType === 0) {
		return `rgba(210,70,93,${opacity})`;
	} else if (pitchType === "FF" || pitchType === 0) {
		return `rgba(210,70,93,${opacity})`;
	} else if (pitchType === "FT" || pitchType === 1) {
		return `rgba(205,79,171,${opacity})`;
	} else if (pitchType === "SI" || pitchType === 2) {
		return `rgba(190,106,143,${opacity})`;
	} else if (pitchType === "CT" || pitchType === 3) {
		return `rgba(199,110,60,${opacity})`;
	} else if (pitchType === "CH" || pitchType === 4) {
		return `rgba(141,103,203,${opacity})`;
	} else if (pitchType === "SP" || pitchType === 5) {
		return `rgba(101,146,207,${opacity})`;
	} else if (pitchType === "SL" || pitchType === 6) {
		return `rgba(89,169,100,${opacity})`;
	} else if (pitchType === "CB" || pitchType === 7) {
		return `rgba(65,244,205,${opacity})`;
	}
};
