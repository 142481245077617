import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
	useToast,
	VStack,
	HStack,
	Box,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	MenuItemOption,
	MenuDivider,
	Portal,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Tooltip,
	Spacer,
	Text,
	Flex
} from "@chakra-ui/react";

import { useMachine } from "@xstate/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { isDefaultFilters, DEFAULT_STATS_TABLE_FILTERS } from "_react/shared/ui/data/tables/shared/Filters";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import { updateFilters, getMinAndMaxSeason } from "_react/shared/_helpers/stats";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import {
	IPlayerSeasonHitterGrades,
	IPlayerSeasonHitterGradesByTeamApiResponse,
	IPlayerSeasonHitterGradesByTeamLkLevel
} from "_react/shared/data_models/hitter_grades/_types";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import {
	THROWS_L,
	THROWS_OVERALL,
	THROWS_R,
	GAME_TYPE_OVERALL,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_SPRING_TRAINING
} from "_react/shared/data_models/hitter_grades/_constants";
import { VALID_PRO_LEVELS } from "_react/shared/data_models/stats/_constants";

import {
	POSITION_PLAYER_FOUNDATIONAL_SKILLS_COLUMNS,
	NUM_DISPLAY_SEASONS
} from "_react/shared/ui/data/tables/PositionPlayerFoundationalSkillsTable/_constants";
import PositionPlayerFoundationalSkillsTableMachine, {
	TPositionPlayerFoundationalSkillsTableContext,
	FETCHING_PLAYER_SEASON_HITTER_GRADES,
	FETCHING_PLAYER_SEASON_HITTER_GRADES_BYTEAM,
	SET_PLAYER_ID,
	SET_PLAYER_SEASON_HITTER_GRADES,
	SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM,
	SET_FILTERS
} from "_react/shared/ui/data/tables/PositionPlayerFoundationalSkillsTable/_machine";
import {
	TPositionPlayerFoundationalSkillsTableData,
	TPositionPlayerFoundationalSkillsRow,
	ICombinedPlayerSeasonHitterGradesSchema
} from "_react/shared/ui/data/tables/PositionPlayerFoundationalSkillsTable/_types";
import { getSeasonFilters } from "_react/shared/_helpers/stats";
import {
	aggregateCombinedPlayerSeasonHitterGradesDataRows,
	getLevelsFromRow
} from "_react/shared/ui/data/tables/PositionPlayerFoundationalSkillsTable/_helpers";

type TPositionPlayerFoundationalSkillsTableStyle = {
	container?: React.CSSProperties;
	tableContainer?: React.CSSProperties;
};

type TPositionPlayerFoundationalSkillsTableProps = {
	title?: string;
	playerId?: number;
	data?: TPositionPlayerFoundationalSkillsTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<TPositionPlayerFoundationalSkillsRow, keyof TPositionPlayerFoundationalSkillsRow>;
	style?: TPositionPlayerFoundationalSkillsTableStyle;
};

const PositionPlayerFoundationalSkillsTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TPositionPlayerFoundationalSkillsTableProps) => {
	const toast = useToast();
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const [current, send] = useMachine(
		PositionPlayerFoundationalSkillsTableMachine(playerIdProp, data, shouldFetchData, toast)
	);
	const isFetchingPlayerSeasonHitterGrades = current.matches(FETCHING_PLAYER_SEASON_HITTER_GRADES);
	const isFetchingPlayerSeasonHitterGradesByTeam = current.matches(FETCHING_PLAYER_SEASON_HITTER_GRADES_BYTEAM);
	const isLoading = isFetchingPlayerSeasonHitterGrades || isFetchingPlayerSeasonHitterGradesByTeam;

	const context = current.context as TPositionPlayerFoundationalSkillsTableContext;
	const { playerId, filters, playerSeasonHitterGrades, playerSeasonHitterGradesByTeam } = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonHitterGrades !== playerSeasonHitterGrades && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_HITTER_GRADES, value: data?.playerSeasonHitterGrades });
	}, [send, data?.playerSeasonHitterGrades, playerSeasonHitterGrades, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonHitterGradesByTeam !== playerSeasonHitterGradesByTeam && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM, value: data?.playerSeasonHitterGradesByTeam });
	}, [send, data?.playerSeasonHitterGradesByTeam, playerSeasonHitterGradesByTeam, shouldFetchData]);

	// Get max and min season
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<IPlayerSeasonHitterGrades>(playerSeasonHitterGrades ?? []),
		[playerSeasonHitterGrades]
	);

	//
	// Season filter options
	//
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<IPlayerSeasonHitterGradesByTeamLkLevel> = useMemo(
		() =>
			playerSeasonHitterGradesByTeam
				?.reduce(
					(
						acc: Array<IPlayerSeasonHitterGradesByTeamLkLevel>,
						curr: IPlayerSeasonHitterGradesByTeamApiResponse
					) => {
						const levelRel: IPlayerSeasonHitterGradesByTeamLkLevel | undefined = curr.teamBam?.levelRel;
						if (
							levelRel &&
							levelRel.value &&
							!acc.some(val => val.value === levelRel.value) &&
							VALID_PRO_LEVELS.includes(levelRel.value)
						)
							acc.push(levelRel);
						return acc;
					},
					[]
				)
				?.sort(
					(a: IPlayerSeasonHitterGradesByTeamLkLevel, b: IPlayerSeasonHitterGradesByTeamLkLevel) =>
						(a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[playerSeasonHitterGradesByTeam]
	);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map((option: IPlayerSeasonHitterGradesByTeamLkLevel) => option.value)
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	//
	// Filter data for table
	//

	// Combine game type and throws filters into "OVR" when possible
	const gameTypeFilters: Array<string> = useMemo(() => {
		return filters.gameTypes.includes(GAME_TYPE_REGULAR_SEASON) &&
			filters.gameTypes.includes(GAME_TYPE_POSTSEASON) &&
			!filters.gameTypes.includes(GAME_TYPE_SPRING_TRAINING)
			? [GAME_TYPE_OVERALL]
			: filters.gameTypes;
	}, [filters.gameTypes]);
	const throwsFilter: string = useMemo(() => (filters.throws.length === 2 ? THROWS_OVERALL : filters.throws?.[0]), [
		filters.throws
	]);

	const filteredPlayerSeasonHitterGradesData: Array<IPlayerSeasonHitterGrades> | undefined | null = useMemo(() => {
		if (isLoading) return undefined;
		if (!playerSeasonHitterGrades) return playerSeasonHitterGrades;
		return playerSeasonHitterGrades.filter(
			(grades: IPlayerSeasonHitterGrades) =>
				grades.season <= seasonFilters.maxSeason &&
				grades.season >= seasonFilters.minSeason &&
				gameTypeFilters.includes(grades.gameType) &&
				grades.throws === throwsFilter
		);
	}, [isLoading, seasonFilters, gameTypeFilters, throwsFilter, playerSeasonHitterGrades]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		const availableLevels = levelFilterOptions.map(
			(option: IPlayerSeasonHitterGradesByTeamLkLevel) => option.value
		);

		return isDefaultFilters(filters, undefined, availableLevels, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, value: DEFAULT_STATS_TABLE_FILTERS });
	}, [send]);

	//
	// Combine data for table
	//

	const combinedTableData: Array<TPositionPlayerFoundationalSkillsRow> | undefined = useMemo(() => {
		if (!filteredPlayerSeasonHitterGradesData) return undefined;
		const combinedData: Array<TPositionPlayerFoundationalSkillsRow> = [];
		const uniqueSeasons = [
			...new Set(filteredPlayerSeasonHitterGradesData.map((grades: IPlayerSeasonHitterGrades) => grades.season))
		];
		uniqueSeasons.forEach((season: number) => {
			// Create an object with the overall player season arsenal grades and all related player season team arsenal grades
			// For certain game type combinations, we need to aggregate the data into a single row
			const hitterGrades = filteredPlayerSeasonHitterGradesData?.filter(
				(grades: IPlayerSeasonHitterGrades) => grades.season === season
			);
			const hitterGradesByTeam = playerSeasonHitterGradesByTeam?.filter(
				(gradesByTeam: IPlayerSeasonHitterGradesByTeamApiResponse) =>
					gradesByTeam.season === season &&
					gameTypeFilters.includes(gradesByTeam.gameType) &&
					gradesByTeam.throws === throwsFilter
			);
			// Only one top-level row is returned, so just use those stats
			if (hitterGrades?.length === 1) {
				let combinedHitterGrades = hitterGrades[0];
				let combinedChildData = hitterGradesByTeam?.map(
					(gradesByTeam: IPlayerSeasonHitterGradesByTeamApiResponse) => {
						return { playerSeasonHitterGrades: gradesByTeam };
					}
				);
				// If there is only one child row, then we want to display it as the overall row
				// However, sprint speed grade, bat speed grade and VAA are not currently available in the child row due to it not currently being
				// populated in the byteam table, so we will grab it from the parent row instead.
				if (hitterGradesByTeam && hitterGradesByTeam.length === 1) {
					combinedHitterGrades = {
						...hitterGradesByTeam[0],
						sprintSpeedGrade: hitterGrades[0].sprintSpeedGrade,
						isMeasuredSwingMetrics: hitterGrades[0].isMeasuredSwingMetrics,
						batSpeedGrade: hitterGrades[0].batSpeedGrade,
						attackAngleVertical: hitterGrades[0].attackAngleVertical,
						compositeGrade: hitterGrades[0].compositeGrade
					};
					combinedChildData = undefined;
				}
				combinedData.push({
					playerSeasonHitterGrades: combinedHitterGrades,
					childData: combinedChildData
				});
			}
			// Multiple top-level rows are returned, aggregate all of the data
			else if (hitterGrades?.length) {
				// Aggregate arsenal grades across different game types into a single top-level row
				let combinedHitterGrades = hitterGrades.reduce(
					(combinedGrades: IPlayerSeasonHitterGrades, grades: IPlayerSeasonHitterGrades) => {
						if (!combinedGrades) return grades;
						return aggregateCombinedPlayerSeasonHitterGradesDataRows(combinedGrades, grades);
					}
				);
				// Aggregate team level arsenal grades across different game types into singular team-level rows
				let combinedChildData = hitterGradesByTeam?.reduce(
					(
						combinedGradesByTeam: Array<TPositionPlayerFoundationalSkillsRow>,
						gradesByTeam: IPlayerSeasonHitterGradesByTeamApiResponse
					) => {
						if (!combinedGradesByTeam.length) return [{ playerSeasonHitterGrades: gradesByTeam }];
						const combinedGradesMatchingTeamIndex = combinedGradesByTeam.findIndex(
							(combinedGrades: TPositionPlayerFoundationalSkillsRow) =>
								"teamId" in combinedGrades.playerSeasonHitterGrades &&
								combinedGrades.playerSeasonHitterGrades.teamId === gradesByTeam.teamId
						);
						if (combinedGradesMatchingTeamIndex === -1)
							combinedGradesByTeam.push({ playerSeasonHitterGrades: gradesByTeam });
						else {
							const combinedGradesMatchingTeamHitterGrades =
								combinedGradesByTeam[combinedGradesMatchingTeamIndex].playerSeasonHitterGrades;
							combinedGradesByTeam[combinedGradesMatchingTeamIndex] = {
								playerSeasonHitterGrades: aggregateCombinedPlayerSeasonHitterGradesDataRows(
									combinedGradesMatchingTeamHitterGrades,
									gradesByTeam
								)
							};
						}
						return combinedGradesByTeam;
					},
					[]
				);
				// If there is only one child row, then we want to display it as the overall row.
				// However, sprint speed grade is not currently available in the child row due to it not currently being
				// populated in the byteam table, so we will grab it from the parent row instead.
				if (combinedChildData && combinedChildData.length === 1) {
					combinedHitterGrades = {
						...combinedChildData[0].playerSeasonHitterGrades,
						sprintSpeedGrade: combinedHitterGrades.sprintSpeedGrade
					};
					combinedChildData = undefined;
				}
				combinedData.push({
					playerSeasonHitterGrades: combinedHitterGrades,
					childData: combinedChildData
				});
			}
		});
		// Apply level filters
		return combinedData.reduce(
			(acc: TPositionPlayerFoundationalSkillsRow[], row: TPositionPlayerFoundationalSkillsRow) => {
				const uniqueLevels: Array<string> = getLevelsFromRow(row);
				const shouldDisplayEntireRow = uniqueLevels.every(level => filters.levels?.includes(level));

				// If all of the levels associated with the row meet the level filters, push the entire row and return early
				if (shouldDisplayEntireRow) {
					acc.push(row);
					return acc;
				}

				// Otherwise, filter the child data and create a new parent row.
				const filteredChildData = row.childData?.filter((c: TPositionPlayerFoundationalSkillsRow) =>
					filters.levels?.includes(
						"teamBam" in c.playerSeasonHitterGrades ? c.playerSeasonHitterGrades.teamBam?.level ?? "" : ""
					)
				);
				// If exactly one item in child data, that becomes parent row
				if (filteredChildData && filteredChildData?.length === 1)
					acc.push({
						playerSeasonHitterGrades: filteredChildData[0].playerSeasonHitterGrades,
						childData: undefined
					});
				// Otherwise, if there multiple items in child data, we must aggregate the child data to create a new parent row
				else if (filteredChildData && filteredChildData?.length > 1) {
					const newParentRow: ICombinedPlayerSeasonHitterGradesSchema = filteredChildData.reduce(
						(
							prev: ICombinedPlayerSeasonHitterGradesSchema,
							curr: TPositionPlayerFoundationalSkillsRow,
							index: number
						) => {
							if (index === 0) return prev;
							return aggregateCombinedPlayerSeasonHitterGradesDataRows(
								prev,
								curr.playerSeasonHitterGrades
							);
						},
						filteredChildData[0].playerSeasonHitterGrades
					);
					// Remove team, level data from new parent row before pushing
					if ("teamBam" in newParentRow) delete newParentRow.teamBam;
					acc.push({
						playerSeasonHitterGrades: newParentRow,
						childData: filteredChildData
					});
				}
				return acc;
			},
			[]
		);
	}, [
		filters.levels,
		throwsFilter,
		gameTypeFilters,
		filteredPlayerSeasonHitterGradesData,
		playerSeasonHitterGradesByTeam
	]);

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns) return POSITION_PLAYER_FOUNDATIONAL_SKILLS_COLUMNS;
		return POSITION_PLAYER_FOUNDATIONAL_SKILLS_COLUMNS.filter(
			(col: TColumn<TPositionPlayerFoundationalSkillsRow, keyof TPositionPlayerFoundationalSkillsRow>) =>
				columns.includes(col.value)
		);
	}, [columns]);

	// Filtering
	const handleGameTypeSelect = (value: string) => {
		const newFilters = {
			...filters,
			gameTypes: updateFilters(filters.gameTypes, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleThrowsSelect = (value: string) => {
		const newFilters = {
			...filters,
			throws: updateFilters(filters.throws, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
				</HStack>
				{isShowFilters && (
					<Menu closeOnSelect={false} placement="left-start">
						<ButtonGroup
							isAttached
							variant={defaultFiltersSet ? "outline" : "solid"}
							colorScheme={defaultFiltersSet ? undefined : "blue"}
						>
							{!defaultFiltersSet && (
								<IconButton
									aria-label="Close"
									icon={<CloseIcon fill="white" />}
									onClick={resetFilters}
								/>
							)}
							<MenuButton
								as={IconButton}
								aria-label="Options"
								icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
							>
								MenuItem
							</MenuButton>
						</ButtonGroup>
						<Portal>
							<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
								<MenuOptionGroup title="Pitcher Throws" type="checkbox" value={filters.throws}>
									<MenuItemOption value={THROWS_L} onClick={() => handleThrowsSelect(THROWS_L)}>
										Left
									</MenuItemOption>
									<MenuItemOption value={THROWS_R} onClick={() => handleThrowsSelect(THROWS_R)}>
										Right
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuDivider />
								<MenuOptionGroup title="Game Type" type="checkbox" value={filters.gameTypes}>
									<MenuItemOption
										value={GAME_TYPE_SPRING_TRAINING}
										onClick={() => handleGameTypeSelect(GAME_TYPE_SPRING_TRAINING)}
									>
										Spring Training
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_REGULAR_SEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_REGULAR_SEASON)}
									>
										Regular Season
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_POSTSEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_POSTSEASON)}
									>
										Postseason
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuOptionGroup title="Seasons">
									<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
										{minSeason === maxSeason && (
											<Tooltip hasArrow placement="top" label="Only one season of data exists">
												<HStack>
													<OutlineInfo color="gray.500" />
													<Text>{minSeason}</Text>
												</HStack>
											</Tooltip>
										)}
										{minSeason !== maxSeason && (
											<VStack>
												<RangeSlider
													value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
													min={minSeason}
													max={maxSeason}
													step={1}
													onChange={(seasons: number[]) => {
														send({
															type: SET_FILTERS,
															value: {
																...filters,
																minSeason: seasons[0],
																maxSeason: seasons[1]
															}
														});
													}}
													onMouseEnter={() => setShowSeasonRangeTooltip(true)}
													onMouseLeave={() => setShowSeasonRangeTooltip(false)}
												>
													<RangeSliderTrack>
														<RangeSliderFilledTrack bg="black" />
													</RangeSliderTrack>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.minSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
													</Tooltip>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.maxSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
													</Tooltip>
												</RangeSlider>
												<Flex sx={{ width: "100%" }}>
													<Text fontSize="sm">{minSeason}</Text>
													<Spacer />
													<Text fontSize="sm">{maxSeason}</Text>
												</Flex>
											</VStack>
										)}
									</VStack>
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Levels"
									type="checkbox"
									value={filters.levels ?? VALID_PRO_LEVELS}
								>
									{levelFilterOptions.map((option: IPlayerSeasonHitterGradesByTeamLkLevel) => (
										<MenuItemOption
											value={option.value}
											onClick={() => handleLevelSelect(option.value)}
										>
											<TeamLevelBadge level={option.value} />
										</MenuItemOption>
									))}
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
				)}
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<TPositionPlayerFoundationalSkillsRow, keyof TPositionPlayerFoundationalSkillsRow>
					columns={filteredColumns}
					data={combinedTableData}
					emptyDataDisplayText={"No Foundational Skills Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					isExpandableRows
					getCustomRowKeyFunction={(row: TPositionPlayerFoundationalSkillsRow) => {
						return `${row.playerSeasonHitterGrades.season}`;
					}}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: ASC
						},
						{
							columnValue: "pitches",
							sortDirection: DESC
						},
						{
							columnValue: "pitchType",
							sortDirection: ASC
						},
						{
							columnValue: "level",
							sortDirection: DESC
						}
					]}
					getRowStyleFunction={(
						obj: TPositionPlayerFoundationalSkillsRow,
						index: number,
						data: Array<TPositionPlayerFoundationalSkillsRow>
					) => {
						if (
							index < data.length - 1 &&
							obj.playerSeasonHitterGrades.season !== data[index + 1].playerSeasonHitterGrades.season
						) {
							return {
								borderBottom: "1px solid !important",
								borderBottomColor: "gray.300 !important"
							};
						}
						return {};
					}}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default PositionPlayerFoundationalSkillsTable;
