import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const gunTimes = {
	title: "Gun Times",
	columns: ["grade", "value"],
	values: [
		{ grade: 80, value: "98+" },
		{ grade: 70, value: "96-97" },
		{ grade: 60, value: "94-95" },
		{ grade: 55, value: "93" },
		{ grade: 50, value: "92" },
		{ grade: 45, value: "90-91" },
		{ grade: 40, value: "88-89" },
		{ grade: 30, value: "86-87" },
		{ grade: 20, value: "85" }
	]
};

const dashTimes = {
	title: "60 Yard Dash Times",
	columns: ["grade", "value"],
	values: [
		{ grade: 80, value: "6.5 or lower" },
		{ grade: 70, value: "6.6" },
		{ grade: 60, value: "6.7" },
		{ grade: 55, value: "6.8" },
		{ grade: 50, value: "6.9" },
		{ grade: 45, value: "7.0" },
		{ grade: 40, value: "7.1" },
		{ grade: 30, value: "7.3" },
		{ grade: 20, value: "7.4 or above" }
	]
};

const homeToFirstTimes = {
	title: "Home to First Base Times",
	columns: ["grade", "right", "left"],
	values: [
		{ grade: 80, right: "3.9 or lower", left: "3.8 or lower" },
		{ grade: 70, right: "4.0", left: "3.9" },
		{ grade: 60, right: "4.1", left: "4.0" },
		{ grade: 55, right: "4.2", left: "4.1" },
		{ grade: 50, right: "4.25", left: "4.15" },
		{ grade: 45, right: "4.3", left: "4.2" },
		{ grade: 40, right: "4.4", left: "4.3" },
		{ grade: 30, right: "4.5", left: "4.4" },
		{ grade: 20, right: "4.6 or above", left: "4.5 or above" }
	]
};

const releaseTimes = {
	title: "Release Times",
	columns: ["position", "grade", "value"],
	values: [
		{ position: "Pitcher", grade: "Average", value: "1.2-1.3" },
		{ position: "Catcher", grade: "Average", value: "1.9-2.0" }
	]
};

const battingAverage = {
	title: "Batting Average",
	columns: ["grade", "value"],
	values: [
		{ grade: "80", value: ".320 and above" },
		{ grade: "70", value: ".300-.319" },
		{ grade: "60", value: ".286-.299" },
		{ grade: "45-55", value: ".260-.285 (.270 average)" },
		{ grade: "40", value: ".250-.259" },
		{ grade: "30", value: ".230-.249" },
		{ grade: "20", value: ".229 and lower" }
	]
};

const homeRuns = {
	title: "Home Runs",
	columns: ["grade", "value", "grade "],
	values: [
		{ grade: "80", value: "40 or more", "grade ": "Excellent" },
		{ grade: "70", value: "31-39", "grade ": "Very Good" },
		{ grade: "60", value: "21-30", "grade ": "Good" },
		{ grade: "45-55", value: "16-20", "grade ": "(18 Average)" },
		{ grade: "40", value: "10-15", "grade ": "Fair" },
		{ grade: "30", value: "6-9", "grade ": "Well Below Average" },
		{ grade: "20", value: "0-5", "grade ": "Poor" }
	]
};

const amaPlayerOFP = {
	title: "Grades",
	columns: ["grade", "value"],
	values: [
		{ grade: "70-80", value: "Excellent" },
		{ grade: "60-69", value: "Good" },
		{ grade: "55-59", value: "Strong Average" },
		{ grade: "50-54", value: "Average" },
		{ grade: "45-49", value: "Marginal" },
		{ grade: "40-44", value: "Fringe" },
		{ grade: "35-39", value: "Organizational" },
		{ grade: "30", value: "Summary - Only Comments" },
		{ grade: "25", value: "Injured" },
		{ grade: "20", value: "Did Not See" },
		{ grade: "0", value: "Non-Prospect" }
	]
};

const amaPlayerProspectType = {
	title: "Player Profile Prospect Categories",
	columns: ["grade", "value", "description", "example players"],
	values: [
		{
			grade: "70-80",
			value: "Excellent",
			description:
				"Premium player on a championship club. Perennial All-Star. Franchise player. Best in the game at his position. Premium #1 Starter or premium closer.",
			"example players":
				"Miguel Cabrera, Robinson Cano, Mike Trout, Felix Hernandez, Clayton Kershaw, Craig Kimbrel"
		},
		{
			grade: "60-69",
			value: "Good",
			description:
				"All-Star caliber player on a championship club. Among league leaders at his position. #2 Starter. Closer. Premium set-up man with the potential to close.",
			"example players":
				"Jose Bautista, Adrian Beltre, Freddie Freeman, Troy Tulowitzki, Madison Bumgarner, Cole Hamels, Jon Lester"
		},
		{
			grade: "55-59",
			value: "Strong Average",
			description:
				"Quality regular on a championship club. Above average production. Occasional All-Star. Capable of hitting in the top 5 of the batting order. #3 Starter. Quality set-up man, capable of pitching the 8th inning.",
			"example players": "Melky Cabrera, Jason Heyward, Chase Utley, Doug Fister, Gio Gonzalez, Rick Porcello"
		},
		{
			grade: "50-54",
			value: "Average",
			description:
				"Major League regular with offensive and/or defensive value to play every day on a championship club. #4 (OFP 53-54) and #5 Starters (OFP 50-52). Quality middle reliever. Premier situational reliever.",
			"example players": "Brandon Crawford, Mike Moustakas, Jason Hammel, Jake Peavy, Travis Wood"
		},
		{
			grade: "45-49",
			value: "Marginal",
			description:
				"Extra Major League player on a championship club. Utility/platoon type, defensive replacement, pinch hitter, back-up catcher. Middle and situational reliever.",
			"example players": "Mike Aviles, Gregor Blanco, Chris Heisey, Jason Frasor, Joel Peralta"
		},
		{
			grade: "40-44",
			value: "Fringe",
			description:
				"4A type player. Up and down players/pitchers. A player whose lack of skills will not allow him to stay in the Major Leagues. A quality Triple-A player or pitcher.",
			"example players": "Quinton Berry, Pedro Ciriaco, Jimmy Paredes, Matt Daley, David Huff"
		},
		{
			grade: "35-39",
			value: "Organizational",
			description: "Not a Major League player, but can add depth/value to our minor league system.",
			"example players": ""
		}
	]
};

const EvaluationTooltip = ({ type, isMobile }) => {
	let tables = [];
	switch (type) {
		case "AmaTGS":
			tables = [gunTimes, dashTimes, homeToFirstTimes, releaseTimes, battingAverage, homeRuns];
			break;
		case "AmaOFPProspectType":
			tables = [amaPlayerOFP, amaPlayerProspectType];
			break;
		case "AmaProspectType":
			tables = [amaPlayerProspectType];
			break;
		default:
			tables = [];
			break;
	}

	const rows = [];
	let currentRow = [];
	for (let i = 0; i < tables.length; i++) {
		if (i % 3 === 0 && i > 0) {
			rows.push(currentRow);
			currentRow = [];
		}
		currentRow.push(tables[i]);
	}
	if (currentRow.length > 0) rows.push(currentRow);

	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			{rows.map((tables, idx) => {
				return (
					<div
						key={idx}
						style={{
							display: !isMobile ? "flex" : "block",
							justifyContent: "center"
						}}
					>
						{tables.map((table, idx) => {
							return (
								<div
									key={idx}
									style={{
										display: !isMobile ? "inline-block" : "block",
										margin: "10px"
									}}
								>
									<Table>
										<TableHead
											style={{
												backgroundColor: defaultColorScheme.group.backgroundColor
											}}
										>
											<TableRow>
												<TableCell
													colSpan={table.columns.length}
													style={{
														color: defaultColorScheme.group.color,
														fontSize: "16px",
														textAlign: "center"
													}}
												>
													{table.title}
												</TableCell>
											</TableRow>
											<TableRow>
												{table.columns.map((column, idx) => {
													return (
														<TableCell
															key={idx}
															style={{
																color: defaultColorScheme.group.color,
																textAlign: "center",
																textTransform: "capitalize"
															}}
														>
															{column}
														</TableCell>
													);
												})}
											</TableRow>
										</TableHead>
										<TableBody
											style={{
												border: "1px solid #dfe0df"
											}}
										>
											{table.values.map((value, idx) => {
												return (
													<TableRow key={idx}>
														{table.columns.map((column, idx) => {
															return <TableCell key={idx}>{value[column]}</TableCell>;
														})}
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};

EvaluationTooltip.propTypes = {
	isMobile: PropTypes.bool,
	type: PropTypes.string
};

export default EvaluationTooltip;
