import React, { FC } from "react";

import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { PlayerPlan } from "_react/playerplan/shared/PlayerPlan";

type TPlanProps = {
	playerId: number;
	colorScheme?: ColorSchemeGroup;
};

export const Plan: FC<TPlanProps> = ({ playerId, colorScheme = defaultColorScheme }) => {
	return <PlayerPlan playerId={playerId} colorSchemeGroup={colorScheme} isShowDraftGoals />;
};
