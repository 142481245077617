import { aggregateStat, getAge } from "_react/shared/_helpers/stats";
import {
	MODEL_EV,
	MODEL_EV_LA,
	MODEL_EV_LA_HLA,
	THROWS_L,
	THROWS_OVERALL,
	THROWS_R
} from "_react/shared/data_models/baseline_hit_probs/_constants";
import { IPlayerSeasonXwoba, IPlayerSeasonXwobaByTeam } from "_react/shared/data_models/baseline_hit_probs/_types";
import {
	AVERAGE_AGE_PLAYER_TYPE_BATTER,
	SOURCE_GUMBO,
	VALID_PRO_LEVELS
} from "_react/shared/data_models/stats/_constants";
import { getAgeDiff } from "_react/shared/data_models/stats/_helpers";
import { IAverageAge, IStatsPlayerBatting, IStatsPlayerBattingByTeam } from "_react/shared/data_models/stats/_types";
import {
	AGE_BASE_DATE,
	GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP
} from "_react/shared/ui/data/tables/PositionPlayerPerformanceTable/_constants";
import {
	ICombinedPositionPlayerPerformanceData,
	TPositionPlayerPerformanceRow
} from "_react/shared/ui/data/tables/PositionPlayerPerformanceTable/_types";

//
// DATA PREPROCESSING
//

// Given an array of IStatsPlayerBatting filtered by player, dedupe the data based on source
export const dedupePlayerStatsPlayerBattingBySource = (
	statsPlayerBatting: Array<IStatsPlayerBatting>
): Array<IStatsPlayerBatting> => {
	// Populate a record to track relevant statsPlayerBatting instances
	// The record is keyed on "season-gameType"
	const statsPlayerBattingRecord: Record<string, IStatsPlayerBatting> = {};
	statsPlayerBatting.forEach((s: IStatsPlayerBatting) => {
		// If season-gameType does not exist, or if season-gameType has the highest priority source,
		// update/create an entry in the record
		if (!(`${s.season}-${s.gameType}` in statsPlayerBattingRecord) || s.source === SOURCE_GUMBO)
			statsPlayerBattingRecord[`${s.season}-${s.gameType}`] = s;
	});

	// Return based on the nested values of our record.
	return Object.values(statsPlayerBattingRecord);
};

// Given an array of IStatsPlayerBattingByTeam filtered by player, dedupe the data based on source
// Remove teams with invalid levels
export const dedupePlayerStatsPlayerBattingByTeamAndSource = (
	statsPlayerBattingByTeam: Array<IStatsPlayerBattingByTeam>
): Array<IStatsPlayerBattingByTeam> => {
	// Populate a record to track relevant statsPlayerBattingByTeam instances
	// The record is keyed on "season-teamId-gameType"
	const statsPlayerBattingByTeamRecord: Record<string, IStatsPlayerBattingByTeam> = {};
	statsPlayerBattingByTeam.forEach((s: IStatsPlayerBattingByTeam) => {
		// Filter teams down to valid levels
		if (!VALID_PRO_LEVELS.includes(s.teamBam?.level ?? "")) return;
		// If season-teamId-gameType does not exist, or if season-teamId-gameType has the highest priority source,
		// update/create an entry in the record
		if (!(`${s.season}-${s.teamId}-${s.gameType}` in statsPlayerBattingByTeamRecord) || s.source === SOURCE_GUMBO)
			statsPlayerBattingByTeamRecord[`${s.season}-${s.teamId}-${s.gameType}`] = s;
	});

	// Return based on the nested values of our record.
	return Object.values(statsPlayerBattingByTeamRecord);
};

export const aggregateStatsPlayerBattingRows = (
	prev: ICombinedPositionPlayerPerformanceData,
	curr: IStatsPlayerBatting
): ICombinedPositionPlayerPerformanceData => {
	const newWobaN = (curr.ab ?? 0) + (curr.ubb ?? 0) + (curr.sf ?? 0) + (curr.hbp ?? 0);
	return {
		...prev,
		g: (curr.g ?? 0) + (prev.g ?? 0),
		gVl: (curr.gVl ?? 0) + (prev.gVl ?? 0),
		gVr: (curr.gVr ?? 0) + (prev.gVr ?? 0),
		pa: (curr.pa ?? 0) + (prev.pa ?? 0),
		paVl: (curr.paVl ?? 0) + (prev.paVl ?? 0),
		paVr: (curr.paVr ?? 0) + (prev.paVr ?? 0),
		kPct: aggregateStat(prev.pa ?? 0, prev.kPct ?? null, curr.pa ?? 0, curr.kPct ?? null),
		kPctVl: aggregateStat(prev.paVl ?? 0, prev.kPctVl ?? null, curr.paVl ?? 0, curr.kPctVl ?? null),
		kPctVr: aggregateStat(prev.paVr ?? 0, prev.kPctVr ?? null, curr.paVr ?? 0, curr.kPctVr ?? null),
		bbPct: aggregateStat(prev.pa ?? 0, prev.bbPct ?? null, curr.pa ?? 0, curr.bbPct ?? null),
		bbPctVl: aggregateStat(prev.paVl ?? 0, prev.bbPctVl ?? null, curr.paVl ?? 0, curr.bbPctVl ?? null),
		bbPctVr: aggregateStat(prev.paVr ?? 0, prev.bbPctVr ?? null, curr.paVr ?? 0, curr.bbPctVr ?? null),
		woba: aggregateStat(prev.wobaN ?? 0, prev.woba ?? null, newWobaN ?? 0, curr.woba ?? null),
		wobaN: newWobaN + (prev.wobaN ?? 0),
		vlWoba: aggregateStat(prev.vlWobaN ?? 0, prev.vlWoba ?? null, curr.vlWobaN ?? 0, curr.vlWoba ?? null),
		vlWobaN: (curr.vlWobaN ?? 0) + (prev.vlWobaN ?? 0),
		vrWoba: aggregateStat(prev.vrWobaN ?? 0, prev.vrWoba ?? null, curr.vrWobaN ?? 0, curr.vrWoba ?? null),
		vrWobaN: (curr.vrWobaN ?? 0) + (prev.vrWobaN ?? 0),
		prcPlus: aggregateStat(prev.wobaN ?? 0, prev.prcPlus ?? null, newWobaN ?? 0, curr.prcPlus ?? null),
		prcPlusVl: aggregateStat(prev.vlWobaN ?? 0, prev.prcPlusVl ?? null, curr.vlWobaN ?? 0, curr.prcPlusVl ?? null),
		prcPlusVr: aggregateStat(prev.vrWobaN ?? 0, prev.prcPlusVr ?? null, curr.vrWobaN ?? 0, curr.prcPlusVr ?? null)
	};
};

export const aggregateCombinedPositionPlayerPerformanceDataRows = (
	prev: ICombinedPositionPlayerPerformanceData,
	curr: ICombinedPositionPlayerPerformanceData
): ICombinedPositionPlayerPerformanceData => {
	return {
		...prev,
		g: (curr.g ?? 0) + (prev.g ?? 0),
		gVl: (curr.gVl ?? 0) + (prev.gVl ?? 0),
		gVr: (curr.gVr ?? 0) + (prev.gVr ?? 0),
		pa: (curr.pa ?? 0) + (prev.pa ?? 0),
		paVl: (curr.paVl ?? 0) + (prev.paVl ?? 0),
		paVr: (curr.paVr ?? 0) + (prev.paVr ?? 0),
		kPct: aggregateStat(prev.pa ?? 0, prev.kPct ?? null, curr.pa ?? 0, curr.kPct ?? null),
		kPctVl: aggregateStat(prev.paVl ?? 0, prev.kPctVl ?? null, curr.paVl ?? 0, curr.kPctVl ?? null),
		kPctVr: aggregateStat(prev.paVr ?? 0, prev.kPctVr ?? null, curr.paVr ?? 0, curr.kPctVr ?? null),
		bbPct: aggregateStat(prev.pa ?? 0, prev.bbPct ?? null, curr.pa ?? 0, curr.bbPct ?? null),
		bbPctVl: aggregateStat(prev.paVl ?? 0, prev.bbPctVl ?? null, curr.paVl ?? 0, curr.bbPctVl ?? null),
		bbPctVr: aggregateStat(prev.paVr ?? 0, prev.bbPctVr ?? null, curr.paVr ?? 0, curr.bbPctVr ?? null),
		woba: aggregateStat(prev.wobaN ?? 0, prev.woba ?? null, curr.wobaN ?? 0, curr.woba ?? null),
		wobaN: (curr.wobaN ?? 0) + (prev.wobaN ?? 0),
		vlWoba: aggregateStat(prev.vlWobaN ?? 0, prev.vlWoba ?? null, curr.vlWobaN ?? 0, curr.vlWoba ?? null),
		vlWobaN: (curr.vlWobaN ?? 0) + (prev.vlWobaN ?? 0),
		vrWoba: aggregateStat(prev.vrWobaN ?? 0, prev.vrWoba ?? null, curr.vrWobaN ?? 0, curr.vrWoba ?? null),
		vrWobaN: (curr.vrWobaN ?? 0) + (prev.vrWobaN ?? 0),
		prcPlus: aggregateStat(prev.wobaN ?? 0, prev.prcPlus ?? null, curr.wobaN ?? 0, curr.prcPlus ?? null),
		prcPlusVl: aggregateStat(prev.vlWobaN ?? 0, prev.prcPlusVl ?? null, curr.vlWobaN ?? 0, curr.prcPlusVl ?? null),
		prcPlusVr: aggregateStat(prev.vrWobaN ?? 0, prev.prcPlusVr ?? null, curr.vrWobaN ?? 0, curr.prcPlusVr ?? null),
		xwobaEv: aggregateStat(prev.wobaN ?? 0, prev.xwobaEv ?? null, curr.wobaN ?? 0, curr.xwobaEv ?? null),
		xwobaEvVl: aggregateStat(prev.vlWobaN ?? 0, prev.xwobaEvVl ?? null, curr.vlWobaN ?? 0, curr.xwobaEvVl ?? null),
		xwobaEvVr: aggregateStat(prev.vrWobaN ?? 0, prev.xwobaEvVr ?? null, curr.vrWobaN ?? 0, curr.xwobaEvVr ?? null),
		xwobaEvLa: aggregateStat(prev.wobaN ?? 0, prev.xwobaEvLa ?? null, curr.wobaN ?? 0, curr.xwobaEvLa ?? null),
		xwobaEvLaVl: aggregateStat(
			prev.vlWobaN ?? 0,
			prev.xwobaEvLaVl ?? null,
			curr.vlWobaN ?? 0,
			curr.xwobaEvLaVl ?? null
		),
		xwobaEvLaVr: aggregateStat(
			prev.vrWobaN ?? 0,
			prev.xwobaEvLaVr ?? null,
			curr.vrWobaN ?? 0,
			curr.xwobaEvLaVr ?? null
		),
		xwobaEvLaHla: aggregateStat(
			prev.wobaN ?? 0,
			prev.xwobaEvLaHla ?? null,
			curr.wobaN ?? 0,
			curr.xwobaEvLaHla ?? null
		),
		xwobaEvLaHlaVl: aggregateStat(
			prev.vlWobaN ?? 0,
			prev.xwobaEvLaHlaVl ?? null,
			curr.vlWobaN ?? 0,
			curr.xwobaEvLaHlaVl ?? null
		),
		xwobaEvLaHlaVr: aggregateStat(
			prev.vrWobaN ?? 0,
			prev.xwobaEvLaHlaVr ?? null,
			curr.vrWobaN ?? 0,
			curr.xwobaEvLaHlaVr ?? null
		)
	};
};

// Converts raw stats player batting data to a record mapping
// season-gameType to ICombinedPositionPlayerPerformanceData
export const createStatsPlayerBattingRecord = (
	statsPlayerBatting: Array<IStatsPlayerBatting>,
	birthDate: string | null
): Record<string, ICombinedPositionPlayerPerformanceData> => {
	// Populate a record to track relevant statsPlayerBatting instances
	// The record is keyed on "season-gameType"
	return statsPlayerBatting.reduce(
		(acc: Record<string, ICombinedPositionPlayerPerformanceData>, s: IStatsPlayerBatting) => {
			// Convert the IStatsPlayerBattingByTeam game types to PlayerSeasonXwobaByTeam game types
			const convertedGameTypes: Array<string> = GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP[s.gameType];
			convertedGameTypes.forEach((convertedGameType: string) => {
				// If season-convertedGameType does not exist, create an entry in the record
				if (!(`${s.season}-${convertedGameType}` in acc)) {
					acc[`${s.season}-${convertedGameType}`] = {
						season: s.season,
						gameType: convertedGameType,
						age: getAge(birthDate, s.season, AGE_BASE_DATE),
						g: s.g,
						gVl: s.gVl,
						gVr: s.gVr,
						pa: s.pa,
						paVl: s.paVl,
						paVr: s.paVr,
						kPct: s.kPct,
						kPctVl: s.kPctVl,
						kPctVr: s.kPctVr,
						bbPct: s.bbPct,
						bbPctVl: s.bbPctVl,
						bbPctVr: s.bbPctVr,
						woba: s.woba,
						wobaN: (s.ab ?? 0) + (s.ubb ?? 0) + (s.sf ?? 0) + (s.hbp ?? 0),
						vlWoba: s.vlWoba,
						vlWobaN: s.vlWobaN,
						vrWoba: s.vrWoba,
						vrWobaN: s.vrWobaN,
						prcPlus: s.prcPlus,
						prcPlusVl: s.prcPlusVl,
						prcPlusVr: s.prcPlusVr
					};
				}
				// Otherwise, aggregate new row with old row
				else {
					const prev: ICombinedPositionPlayerPerformanceData = acc[`${s.season}-${convertedGameType}`];
					acc[`${s.season}-${convertedGameType}`] = aggregateStatsPlayerBattingRows(prev, s);
				}
			});
			return acc;
		},
		{}
	);
};

// Converts raw stats player batting byteam data to a record mapping
// season-teamId-gameType to ICombinedPositionPlayerPerformanceData
export const createStatsPlayerBattingByTeamRecord = (
	statsPlayerBattingByTeam: Array<IStatsPlayerBattingByTeam>,
	birthDate: string | null,
	averageAges: Array<IAverageAge>
): Record<string, ICombinedPositionPlayerPerformanceData> => {
	// Populate a record to track relevant statsPlayerBattingByTeam instances
	// The record is keyed on "season-teamId-gameType"
	return statsPlayerBattingByTeam.reduce(
		(acc: Record<string, ICombinedPositionPlayerPerformanceData>, s: IStatsPlayerBattingByTeam) => {
			const teamId = s.team?.id; // This gets combined team id.  s.teamId is a bamId
			if (!teamId) return acc;

			// Get age
			const age: number | null = getAge(birthDate, s.season, AGE_BASE_DATE);

			// Convert the IStatsPlayerBattingByTeam game types to PlayerSeasonXwobaByTeam game types
			const convertedGameTypes: Array<string> = GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP[s.gameType];
			convertedGameTypes.forEach((convertedGameType: string) => {
				// If season-teamId-convertedGameType does not exist, create an entry in the record
				if (!(`${s.season}-${teamId}-${convertedGameType}` in acc)) {
					acc[`${s.season}-${teamId}-${convertedGameType}`] = {
						season: s.season,
						gameType: convertedGameType,
						age: age,
						relAge:
							age && s.teamBam?.level
								? getAgeDiff(
										age,
										s.teamBam.level,
										AVERAGE_AGE_PLAYER_TYPE_BATTER,
										s.season,
										averageAges
								  )
								: null,
						// Use combined team ID in order to match PlayerSeasonXwoba info
						teamId: teamId,
						// Use team Bam to properly display team name, org, abbrev, level
						team: s.teamBam,
						g: s.g,
						gVl: s.gVl,
						gVr: s.gVr,
						pa: s.pa,
						paVl: s.paVl,
						paVr: s.paVr,
						kPct: s.kPct,
						kPctVl: s.kPctVl,
						kPctVr: s.kPctVr,
						bbPct: s.bbPct,
						bbPctVl: s.bbPctVl,
						bbPctVr: s.bbPctVr,
						woba: s.woba,
						wobaN: (s.ab ?? 0) + (s.ubb ?? 0) + (s.sf ?? 0) + (s.hbp ?? 0),
						vlWoba: s.vlWoba,
						vlWobaN: s.vlWobaN,
						vrWoba: s.vrWoba,
						vrWobaN: s.vrWobaN,
						prcPlus: s.prcPlus,
						prcPlusVl: s.prcPlusVl,
						prcPlusVr: s.prcPlusVr
					};
				}
				// Otherwise, aggregate new row with old row
				else {
					const prev: ICombinedPositionPlayerPerformanceData =
						acc[`${s.season}-${teamId}-${convertedGameType}`];
					acc[`${s.season}-${teamId}-${convertedGameType}`] = aggregateStatsPlayerBattingRows(prev, s);
				}
			});
			return acc;
		},
		{}
	);
};

// Appends playerSeasonXwoba data to the statsPlayerBatting record, keyed on season-gameId, or
// Appends playerSeasonXwobaByTeam data to the statsPlayerBattingByTeam record, keyed on season-teamId-gameId
export const appendGenericPlayerSeasonXwobaData = <T extends IPlayerSeasonXwoba | IPlayerSeasonXwobaByTeam>(
	playerSeasonXwoba: Array<T>, // statsPlayerBatting or statsPlayerBattingByTeam
	getKey: (x: T) => string, // Function that gets the key used to index the record
	statsPlayerBattingRecord: Record<string, ICombinedPositionPlayerPerformanceData>
): Record<string, ICombinedPositionPlayerPerformanceData> => {
	playerSeasonXwoba.forEach((x: T) => {
		const key = getKey(x);
		// If the key does not exist, we should not create an entry in the record,
		// as we want the ability to aggregate all data in the record, and we need sample-size data
		// from statsPlayerBatting(ByTeam) in order to properly aggregate playerSeasonXwoba(ByTeam)
		// across game types.

		// As such, skip this case.
		if (!(key in statsPlayerBattingRecord)) return;
		// Otherwise, append playerSeasonXwoba(ByTeam) data into the relevant row.
		// Because this function cannot handle aggregation of playerSeasonXwoba(ByTeam) using
		// playerSeasonXwoba(ByTeam) data alone, before calling this function, there should be at most one
		// instance of playerSeasonXwoba(ByTeam) corresponding to each of the xwoba-related fields
		// we want to populate in the record.
		// When we aggregate the playerSeasonXwoba(ByTeam) data in the table, we will use the corresponding sample
		// sizes from statsPlayerBatting(ByTeam)
		else {
			const prev: ICombinedPositionPlayerPerformanceData = statsPlayerBattingRecord[key];
			const newField: Partial<ICombinedPositionPlayerPerformanceData> = {};
			// Map x.model and x.throws to the corresponding field on ICombinedPositionPlayerPerformanceData
			if (x.model === MODEL_EV && x.throws === THROWS_OVERALL) newField.xwobaEv = x.xwoba;
			else if (x.model === MODEL_EV && x.throws === THROWS_L) newField.xwobaEvVl = x.xwoba;
			else if (x.model === MODEL_EV && x.throws === THROWS_R) newField.xwobaEvVr = x.xwoba;
			else if (x.model === MODEL_EV_LA && x.throws === THROWS_OVERALL) newField.xwobaEvLa = x.xwoba;
			else if (x.model === MODEL_EV_LA && x.throws === THROWS_L) newField.xwobaEvLaVl = x.xwoba;
			else if (x.model === MODEL_EV_LA && x.throws === THROWS_R) newField.xwobaEvLaVr = x.xwoba;
			else if (x.model === MODEL_EV_LA_HLA && x.throws === THROWS_OVERALL) newField.xwobaEvLaHla = x.xwoba;
			else if (x.model === MODEL_EV_LA_HLA && x.throws === THROWS_L) newField.xwobaEvLaHlaVl = x.xwoba;
			else if (x.model === MODEL_EV_LA_HLA && x.throws === THROWS_R) newField.xwobaEvLaHlaVr = x.xwoba;

			statsPlayerBattingRecord[key] = {
				...prev,
				...newField
			};
		}
	});
	return statsPlayerBattingRecord;
};

// Used to get the levels associated with a row.
export const getLevelsFromRow = (row: TPositionPlayerPerformanceRow): Array<string> => {
	// Child Rows or rows with no nested data
	if ("team" in row.combinedPositionPlayerPerformanceData)
		return row.combinedPositionPlayerPerformanceData.team?.level
			? [row.combinedPositionPlayerPerformanceData.team?.level]
			: [];
	// Parent Rows with nested data
	if (row.childData && row.childData.length > 1) {
		return [
			...new Set(
				row.childData.reduce((acc: Array<string>, childRow: TPositionPlayerPerformanceRow) => {
					if (
						"team" in childRow.combinedPositionPlayerPerformanceData &&
						childRow.combinedPositionPlayerPerformanceData.team?.level
					)
						acc.push(childRow.combinedPositionPlayerPerformanceData.team?.level);
					return acc;
				}, [])
			)
		];
	}
	return [];
};
