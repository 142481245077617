import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import {
	REDTEXT,
	RED,
	BLUETEXT,
	BLUEDARKER,
	BLUE,
	CREAMTEXT,
	CREAMDARKER,
	CREAMDARK,
	CREAM,
	THROWBACKMAROONTEXT,
	THROWBACKMAROONDARKER,
	THROWBACKMAROONDARK,
	THROWBACKMAROON,
	THROWBACKBLUETEXT,
	THROWBACKBLUEDARKER,
	THROWBACKBLUEDARK,
	THROWBACKBLUE
} from "_react/shared/legacy/ui/Colors";
import { TabMini } from "_react/shared/legacy/ui/TabMini";

import { $TSFixMe, Style } from "utils/tsutils";

interface Dictionary<T> {
	[Key: string]: T;
}

export type TabColorScheme = {
	color: string;
	backgroundColor: string;
	backgroundColorHover: string;
	backgroundColorSelected: string;
};

export const COLOR_TO_TAB_COLOR_SCHEME: Dictionary<TabColorScheme> = {
	primary: {
		color: REDTEXT,
		backgroundColor: THROWBACKMAROONDARK,
		backgroundColorHover: THROWBACKMAROON,
		backgroundColorSelected: RED
	},
	secondary: {
		color: BLUETEXT,
		backgroundColor: BLUEDARKER,
		backgroundColorHover: BLUE,
		backgroundColorSelected: THROWBACKBLUEDARKER
	},
	tertiary: {
		color: CREAMTEXT,
		backgroundColor: CREAMDARKER,
		backgroundColorHover: CREAMDARK,
		backgroundColorSelected: CREAM
	},
	quaternary: {
		color: THROWBACKMAROONTEXT,
		backgroundColor: THROWBACKMAROONDARKER,
		backgroundColorHover: THROWBACKMAROONDARK,
		backgroundColorSelected: THROWBACKMAROON
	},
	quinary: {
		color: THROWBACKBLUETEXT,
		backgroundColor: THROWBACKBLUEDARKER,
		backgroundColorHover: THROWBACKBLUEDARK,
		backgroundColorSelected: THROWBACKBLUE
	}
};

type TabsMiniProps = {
	children: $TSFixMe;
	color?: string;
	colorScheme?: TabColorScheme;
	fullWidth?: boolean;
	onChange?: $TSFixMe;
	style?: Style;
	value?: $TSFixMe;
};

const StyledTabsContainer = styled.div<TabsMiniProps>({}, (props: TabsMiniProps) => ({
	backgroundColor: props.colorScheme!.backgroundColor,
	width: props.fullWidth ? "100%" : "",
	...props.style
}));

const StyledTabs = styled.div<TabsMiniProps>(
	{
		borderRadius: "3px",
		padding: "3px",
		display: "flex"
	},
	(props: $TSFixMe) => ({
		backgroundColor: props.colorScheme!.backgroundColor,
		...props.style
	})
);

export const TabsMini: FunctionComponent<TabsMiniProps> = ({
	children,
	color = "primary",
	onChange = () => null,
	fullWidth = false,
	style = {},
	value
}) => {
	const colorScheme: TabColorScheme = COLOR_TO_TAB_COLOR_SCHEME[color];

	return (
		<StyledTabsContainer colorScheme={colorScheme} fullWidth={fullWidth} style={style}>
			<StyledTabs colorScheme={colorScheme} style={style}>
				{children.map((c: $TSFixMe, idx: number) => {
					const selected = value === c.props.value;
					if (c.type === TabMini) {
						return React.cloneElement(
							c,
							{
								key: idx,
								onClick: () => onChange(c, c.props.value),
								selected,
								colorScheme
							},
							null
						);
					}
					return null;
				})}
			</StyledTabs>
		</StyledTabsContainer>
	);
};
