import React from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";

import { Button } from "_react/shared/legacy/ui/Button";
import { RED } from "_react/shared/legacy/ui/Colors";
import Menu from "@material-ui/core/Menu";
import { withRouter } from "react-router-dom";

import { generatePublishDate } from "_react/evals/helpers";
import { checkIsRequiredFieldsAllPresent, formatEvalDateField } from "_react/evals/ama/_helpers";
import { isMobile } from "utils/_helpers";
import { getPlayerUrl } from "utils/urls";
import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";

class AmaEvalWrapperFooter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			publishing: false,
			saving: false,
			converting: false,
			anchorEl: null
		};
	}

	componentDidMount() {
		const { getPlayerScoutEvalsFromEvalId, evaluation } = this.props;
		if (evaluation != null) {
			getPlayerScoutEvalsFromEvalId(evaluation.eval_id).then(playerScoutEvals =>
				this.setState({ playerScoutEvals })
			);
		}
	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleConvertEval = () => {
		this.setState({ converting: true });
		// Delete old eval
		const { convertEvaluation, evaluation } = this.props;
		convertEvaluation(evaluation);
	};

	publishEval = () => {
		const {
			evaluation,
			publishEval: publishEvalProp,
			history,
			addListPlayerOnPublish,
			enqueueSnackbar,
			closeSnackbar
		} = this.props;
		this.setState({ publishing: true });

		// Publish Checks
		if (!checkIsRequiredFieldsAllPresent(evaluation)) {
			this.setState({ publishing: false });
			return;
		}

		// Create Update Object
		const evaluationUpdateObject = {
			eval_create_user_id: evaluation.eval_create_user_id,
			eval_id: evaluation.eval_id,
			eval_type: evaluation.eval_type,
			eval_report_type: evaluation.eval_report_type,
			phil_id: evaluation.phil_id,
			publish_date: generatePublishDate(),
			record_status: "AP",
			seen_date:
				evaluation.eval_report_type !== "LVR"
					? formatEvalDateField(evaluation.seen_date)
					: generatePublishDate()
		};

		const playerPageRoute = getPlayerUrl(evaluation.phil_id);
		const navigateToPlayerPage = () => history.push(playerPageRoute);

		publishEvalProp(evaluationUpdateObject)
			.then(() => {
				// Skip for AVR reports
				if (["AVR"].includes(evaluation.eval_report_type)) {
					navigateToPlayerPage();
					return;
				}
				// only add list player if publish is successful
				enqueueSnackbar("Publish successful. Attempting add of player to list", { variant: "success" });
				addListPlayerOnPublish(evaluationUpdateObject)
					.then(() => {
						enqueueSnackbar("Added player to list", { variant: "success" });
						navigateToPlayerPage();
					})
					.catch(_error => {
						const onClickPlayer = key => {
							closeSnackbar(key);
							navigateToPlayerPage();
						};
						const action = key => (
							<React.Fragment>
								<Button
									onClick={() => onClickPlayer(key)}
									title={"Go to player"}
									style={{ lineHeight: "16px" }}
								/>
								;
								<Button
									onClick={() => closeSnackbar(key)}
									title={"Dismiss"}
									style={{ lineHeight: "16px" }}
								/>
							</React.Fragment>
						);
						const snackbarMessage = `Did not add player to list. Player may already be in list`;
						const snackbarProps = { variant: "info", persist: true, action };
						enqueueSnackbar(snackbarMessage, snackbarProps);
					});
			})
			.catch(_error => {
				this.setState({ publishing: false });
				const action = key => (
					<Button onClick={() => closeSnackbar(key)} title={"Dismiss"} style={{ lineHeight: "16px" }} />
				);
				const snackbarMessage = "Failed publishing! Did not attempt to add player to list";
				const snackbarProps = { variant: "error", persist: true, action };
				enqueueSnackbar(snackbarMessage, snackbarProps);
			});
	};

	updateEvalError = message => {
		this.props.reportEvalPublishError(message);
	};

	getButton = type => {
		const {
			evaluation,
			triggerSave,
			updatePlayerEval,
			isEvalSaving,
			deletePlayerEval,
			history,
			scoutLastName,
			player
		} = this.props;
		const { publishing, saving, converting, playerScoutEvals } = this.state;

		const scoutEvals = (playerScoutEvals ?? []).filter(
			evaluationEntry => evaluationEntry.scoutId === evaluation.eval_create_user_id
		);
		const currentIndexScout = (scoutEvals ?? []).findIndex(
			evaluationEntry => evaluationEntry.evalId === evaluation.eval_id
		);
		const currentIndex = (playerScoutEvals ?? []).findIndex(
			evaluationEntry => evaluationEntry.evalId === evaluation.eval_id
		);

		switch (type) {
			case "scroll-scout":
				return (
					<div style={{ display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center" }}>
						<Button
							colorScheme={defaultColorScheme.secondary}
							disabled={isEvalSaving || saving || currentIndexScout === 0 || scoutEvals.length === 0}
							fullWidth
							onClick={() => {
								if (currentIndexScout > 0)
									history.push(`/eval?id=${scoutEvals[currentIndexScout - 1].evalId}`);
							}}
							style={{
								display: "block",
								margin: "auto",
								lineHeight: "16px"
							}}
							title={"Previous"}
							variant="contained"
						/>
						<div style={{ paddingLeft: "5px", paddingRight: "5px", textAlign: "center" }}>
							<div>{scoutLastName}</div>
							<div style={{ fontWeight: 900, fontSize: "10px" }}>Scout</div>
						</div>
						<Button
							colorScheme={defaultColorScheme.secondary}
							disabled={
								isEvalSaving ||
								saving ||
								currentIndexScout === (scoutEvals ?? []).length - 1 ||
								scoutEvals.length === 0
							}
							fullWidth
							onClick={() => {
								if (currentIndexScout < scoutEvals.length - 1)
									history.push(`/eval?id=${scoutEvals[currentIndexScout + 1].evalId}`);
							}}
							style={{
								display: "block",
								margin: "auto",
								lineHeight: "16px"
							}}
							title={"Next"}
							variant="contained"
						/>
					</div>
				);
			case "scroll-player":
				return (
					<div style={{ display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center" }}>
						<Button
							colorScheme={defaultColorScheme.secondary}
							disabled={isEvalSaving || saving || currentIndex === 0 || scoutEvals.length === 0}
							fullWidth
							onClick={() => {
								if (currentIndex > 0)
									history.push(`/eval?id=${playerScoutEvals[currentIndex - 1].evalId}`);
							}}
							style={{
								display: "block",
								margin: "auto",
								lineHeight: "16px"
							}}
							title={"Previous"}
							variant="contained"
						/>
						<div style={{ paddingLeft: "5px", paddingRight: "5px", textAlign: "center" }}>
							<div>{player.last_name}</div>
							<div style={{ fontWeight: 900, fontSize: "10px" }}>Player</div>
						</div>
						<Button
							colorScheme={defaultColorScheme.secondary}
							disabled={
								isEvalSaving ||
								saving ||
								currentIndex === (playerScoutEvals ?? []).length - 1 ||
								scoutEvals.length === 0
							}
							fullWidth
							onClick={() => {
								if (currentIndex < playerScoutEvals.length - 1)
									history.push(`/eval?id=${playerScoutEvals[currentIndex + 1].evalId}`);
							}}
							style={{
								display: "block",
								margin: "auto",
								lineHeight: "16px"
							}}
							title={"Next"}
							variant="contained"
						/>
					</div>
				);
			case "convert":
				return (
					<Button
						color="primary"
						disabled={isEvalSaving || publishing || converting}
						fullWidth
						onClick={this.handleConvertEval}
						style={{
							display: "block",
							margin: "auto",
							lineHeight: "16px"
						}}
						title={
							converting
								? "Converting..."
								: `Convert to ${
										evaluation.eval_type === "AMA_HIT" ? "Pitcher" : "Postion Player"
								  } Report`
						}
						variant="contained"
					/>
				);
			case "save":
				return (
					<Button
						color="secondary"
						disabled={isEvalSaving || saving || publishing}
						fullWidth
						onClick={() => {
							this.setState({ saving: true });
							setTimeout(() => {
								triggerSave();
								this.setState({ saving: false });
							}, 1000);
						}}
						style={{
							display: "block",
							margin: "auto",
							lineHeight: "16px"
						}}
						title={isEvalSaving || saving ? "Saving..." : "Save"}
						variant="contained"
					/>
				);

			case "unpublish":
				return (
					<Button
						color="primary"
						disabled={isEvalSaving || publishing}
						fullWidth
						onClick={() => {
							// Create Update Object
							const evaluationUpdateObject = {
								eval_create_user_id: evaluation.eval_create_user_id,
								eval_id: evaluation.eval_id,
								eval_type: evaluation.eval_type,
								phil_id: evaluation.phil_id,
								publish_date: null,
								record_status: "PR"
							};

							updatePlayerEval(evaluationUpdateObject);
						}}
						style={{
							display: "block",
							margin: "auto",
							lineHeight: "16px"
						}}
						title={"Un-Publish"}
						variant="contained"
					/>
				);
			case "publish":
				return (
					<Button
						color="secondary"
						disabled={isEvalSaving || publishing}
						fullWidth
						onClick={() => {
							this.setState({ publishing: true });
							setTimeout(() => {
								this.publishEval();
							}, 1500);
						}}
						style={{
							display: "block",
							margin: "auto",
							lineHeight: "16px"
						}}
						title={publishing ? "Publishing..." : "Publish"}
						variant="contained"
					/>
				);
			case "delete":
				return (
					<Button
						color="primary"
						disabled={isEvalSaving || publishing}
						fullWidth
						onClick={() => {
							deletePlayerEval(evaluation);
							this.props.history.push(`/player?philId=${evaluation.phil_id}`);
						}}
						style={{
							display: "block",
							margin: "auto",
							lineHeight: "16px"
						}}
						title="Delete Eval"
						variant="contained"
					/>
				);
			case "actions":
				return (
					<Button
						color="primary"
						disabled={isEvalSaving || publishing}
						fullWidth
						onClick={this.handleClick}
						style={{
							display: "block",
							margin: "auto",
							lineHeight: "16px"
						}}
						title="Actions"
						variant="contained"
					/>
				);
			default:
				return null;
		}
	};

	render() {
		const { evaluation, isEvalManager, matches, userId, windowWidth } = this.props;
		const { anchorEl } = this.state;

		const buttons = [];
		const actions = [];
		if (isMobile(windowWidth)) buttons.push(this.getButton("actions"));
		if (userId === evaluation.eval_create_user_id && evaluation.publish_date == null) {
			if (!isMobile(windowWidth)) buttons.push(this.getButton("convert"));
			else actions.push(this.getButton("convert"));
			buttons.push(this.getButton("save"));
			buttons.push(this.getButton("publish"));
		}
		if (isEvalManager || (userId === evaluation.eval_create_user_id && evaluation.publish_date == null)) {
			if (!isMobile(windowWidth)) buttons.push(this.getButton("delete"));
			else actions.push(this.getButton("delete"));
		}
		if (isEvalManager && evaluation.publish_date != null) {
			if (!isMobile(windowWidth)) buttons.push(this.getButton("unpublish"));
			else actions.push(this.getButton("unpublish"));
		}
		buttons.push("spacer");
		if (evaluation != null && evaluation.publish_date != null) {
			buttons.push(this.getButton("scroll-player"));
			buttons.push(this.getButton("scroll-scout"));
		}

		return (
			<div
				style={{
					display: !matches ? (!isMobile(windowWidth) ? "flex" : "block") : "none",
					position: "fixed",
					bottom: "0",
					left: "0",
					right: "0",
					backgroundColor: "#f2f2f3",
					height: "40px",
					borderTop: `3px solid ${RED}`,
					alignItems: "center"
				}}
			>
				<div style={{ display: "flex", flexGrow: 1 }}>
					{evaluation != null &&
						buttons.map((button, idx) => {
							if (button === "spacer") {
								return (
									<div
										key={idx}
										style={{
											flexGrow: 1
										}}
									/>
								);
							}
							return (
								<div
									key={idx}
									style={{
										padding: "5px",
										display: "flex",
										marginLeft: idx === 0 ? "10px" : ""
									}}
								>
									{button}
								</div>
							);
						})}
				</div>
				<Menu
					anchorEl={anchorEl}
					id="simple-menu"
					onClose={this.handleClose}
					open={Boolean(anchorEl)}
					style={{ textAlign: "center" }}
				>
					{actions.map((button, idx) => {
						return (
							<div
								key={idx}
								style={{
									padding: "5px",
									display: "flex"
								}}
							>
								{button}
							</div>
						);
					})}
				</Menu>
			</div>
		);
	}
}

AmaEvalWrapperFooter.propTypes = {
	addListPlayerOnPublish: PropTypes.func,
	closeSnackbar: PropTypes.func,
	convertEvaluation: PropTypes.func,
	deletePlayerEval: PropTypes.func,
	enqueueSnackbar: PropTypes.func,
	evaluation: PropTypes.object,
	getPlayerScoutEvalsFromEvalId: PropTypes.func,
	history: PropTypes.object,
	isEvalManager: PropTypes.bool,
	matches: PropTypes.bool,
	player: PropTypes.object,
	publishEval: PropTypes.func,
	reportEvalPublishError: PropTypes.func,
	scoutLastName: PropTypes.string,
	triggerSave: PropTypes.func,
	updatePlayerEval: PropTypes.func,
	isEvalSaving: PropTypes.bool,
	userId: PropTypes.number,
	windowWidth: PropTypes.number
};

export default withSnackbar(withRouter(AmaEvalWrapperFooter));
