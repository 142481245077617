import React, { FunctionComponent, CSSProperties } from "react";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { DetailsTable } from "_react/shared/legacy/tables/DetailsTable";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { CONTRACT_DETAILS_COLUMNS } from "_react/playerpage/contract/_helpers";
import { TContract } from "_react/playerpage/contract/_types";

type TContractDetailsStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

type ContractDetailsProps = {
	colorScheme?: ColorSchemeGroup;
	style?: TContractDetailsStyle;
	contract?: TContract;
	level?: string;
	player?: TPlayerPageCombinedPlayer;
};

export const ContractDetails: FunctionComponent<ContractDetailsProps> = ({
	colorScheme = defaultColorScheme,
	style,
	contract = null,
	level = null,
	player = null
}) => {
	return (
		<Card style={style?.card}>
			<ColoredCardHeader
				colorScheme={colorScheme.secondary}
				text={contract != null ? "Contract Details" : "Loading Contract..."}
				style={style?.cardHeader}
			></ColoredCardHeader>
			{contract != null && level != null && (
				<DetailsTable columns={CONTRACT_DETAILS_COLUMNS(contract, level, player)} row={contract} />
			)}
			{contract == null && (
				<div className="loading-container">
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
					<div className="loading-item" style={{ height: "16px" }} />
				</div>
			)}
		</Card>
	);
};
