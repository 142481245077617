import dayjs from "dayjs";

import { round10 } from "_react/shared/_helpers/numbers";
import { TPlayerPageCombinedPlayer, TPlayerPageMakeupNote } from "_react/playerpage/_types";
import { MODEL_NAME_V2, PLAYER_TYPE_BATTER, PLAYER_TYPE_PITCHER } from "_react/shared/data_models/phred/_constants";
import { IAmaProspectValue } from "_react/shared/data_models/phred/_types";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import { AMA_PITCH, AMA_HIT } from "_react/evals/_constants";
import { getMostRecentDraftPick } from "_react/playerpage/_helpers";
import { AMA_4YR_OBSERVED_SEASON_CUTOFF } from "_react/shared/_constants/cutoff_dates";
import { LEVEL_4YR, PLAYER_CLASSIFICATION_PRO } from "_react/shared/data_models/team/_constants";

export const getHasEligibleYearMakeupNote = (player: TPlayerPageCombinedPlayer): boolean => {
	return Boolean(
		player.makeupNotes?.filter((note: TPlayerPageMakeupNote) => {
			const eligibleYear = player.amaProfile?.eligibleYear ?? dayjs().year();
			const cutoffDate = dayjs(`${eligibleYear - 1}-08-01`);
			return dayjs(note.publishDate).isAfter(cutoffDate, "day");
		})?.length
	);
};

export const formatAmaSurplusValue = (value: IAmaProspectValue | undefined) => {
	return round10(value?.xsurplusMillions, -1);
};

export const getHasTwoWayProjections = (
	amaProspectValues: Array<IAmaProspectValue> | undefined,
	player: TPlayerPageCombinedPlayer
): boolean => {
	// Filter for player's R4 Year
	const currentAmaProspectValues = amaProspectValues?.filter(
		(pv: IAmaProspectValue) =>
			pv.modelName === MODEL_NAME_V2 && pv.r4Year === (player.amaProfile?.eligibleYear ?? dayjs().year())
	);

	// Check for batting projection
	const hasBattingProjection = Boolean(
		currentAmaProspectValues?.find((pv: IAmaProspectValue) => pv.playerType === PLAYER_TYPE_BATTER)
	);

	// Check for pitching projection
	const hasPitchingProjection = Boolean(
		currentAmaProspectValues?.find((pv: IAmaProspectValue) => pv.playerType === PLAYER_TYPE_PITCHER)
	);

	// If both exist
	return hasBattingProjection && hasPitchingProjection;
};

export const getIsTwoWayPlayer = (
	amaProspectValues: Array<IAmaProspectValue> | undefined,
	player: TPlayerPageCombinedPlayer,
	evals: $TSFixMeEval
): boolean => {
	// Check for two way projections
	if (getHasTwoWayProjections(amaProspectValues, player)) {
		return true;
	}

	// Check for both pitch and hit evals in the past two years
	const evalTypes =
		evals
			?.filter(
				(evaluation: $TSFixMeEval) =>
					evaluation.seen_date != null && evaluation.seen_date.year() >= dayjs().year() - 1
			)
			.map((evaluation: $TSFixMeEval) => evaluation.eval_type) ?? [];

	// Two way if both types exist
	return evalTypes.includes(AMA_HIT) && evalTypes.includes(AMA_PITCH);
};

export const getProjectionsAmaSeason = (player: TPlayerPageCombinedPlayer): number => {
	// For PRO players, look for last draft pick or first contract
	if (player?.playerClassification === PLAYER_CLASSIFICATION_PRO) {
		// Try getting the season from draft picks
		const mostRecentDraftPick = getMostRecentDraftPick(player);
		if (mostRecentDraftPick?.r4Year) return mostRecentDraftPick.r4Year;
		// Otherwise, try getting the season from the first pro contract
		const firstProContractYear = parseInt(player?.proProfile?.firstProContractDate?.substring(0, 4) ?? "");
		if (!isNaN(firstProContractYear)) return firstProContractYear;
	}
	// For AMA and INTL players, return eligible year or current year
	return player.amaProfile?.eligibleYear ?? dayjs().year();
};

export const getObservedAmaSeason = (player: TPlayerPageCombinedPlayer): number => {
	// For PRO players, use the same season as projections
	if (player?.playerClassification === PLAYER_CLASSIFICATION_PRO) {
		const projectionsSeason = getProjectionsAmaSeason(player);
		return projectionsSeason;
	}

	// AMA and INTL players
	const today = dayjs();
	const thisYear = today.year();
	const previousYear = thisYear - 1;

	// For 4YR players, show previous year data if its before the cutoff date
	if (player?.amaProfile?.team?.level === LEVEL_4YR) {
		const cutoffDate = dayjs(`${AMA_4YR_OBSERVED_SEASON_CUTOFF}/${thisYear}`);
		return today.isBefore(cutoffDate) ? previousYear : thisYear;
	}
	// Everyone else (ex. JuCo)
	return thisYear;
};
