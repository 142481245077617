import React, { FC, useState, useRef, useEffect } from "react";
import Info from "_react/shared/legacy/ui/icons/Info";
import { Menu } from "_react/shared/legacy/ui/Menu";
import { IconButton } from "_react/shared/legacy/ui/IconButton";
import { Button } from "_react/shared/legacy/ui/Button";

type IconButtonMenuProps = {
	children: React.ReactNode;
	icon?: React.FC;
	onClick: () => void;
	open?: boolean;
	onClose?: () => void;
	hasCloseButton?: boolean;
	buttonStyle?: React.CSSProperties;
	svgStyle?: React.CSSProperties;
	popupMaxHeight?: number;
	iconFill?: string;
};

const iconDivStyle: React.CSSProperties = { padding: 3 };
const menuContentStyle: React.CSSProperties = { display: "flex", flexDirection: "column", alignItems: "center" };
const closeButtonStyle: React.CSSProperties = { padding: 5 };

export const IconButtonMenu: FC<IconButtonMenuProps> = ({
	children,
	icon,
	onClick,
	open = false,
	onClose = () => null,
	hasCloseButton = false,
	buttonStyle = {},
	svgStyle = {},
	popupMaxHeight,
	iconFill
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
	const iconRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (open) {
			setAnchorEl(iconRef.current);
		} else {
			setAnchorEl(null);
		}
	}, [open]);

	const Icon = icon || Info;
	return (
		<React.Fragment>
			<div ref={iconRef} style={iconDivStyle}>
				<IconButton onClick={onClick} style={buttonStyle}>
					<Icon fill={iconFill} style={svgStyle} />
				</IconButton>
			</div>
			<Menu anchorEl={anchorEl} onClose={onClose} open={open} maxHeight={popupMaxHeight}>
				<div style={menuContentStyle}>
					{children}
					{hasCloseButton && (
						<div style={closeButtonStyle}>
							<Button onClick={onClose} title={"CLOSE"} variant={"outlined"} />
						</div>
					)}
				</div>
			</Menu>
		</React.Fragment>
	);
};
