import React from "react";

import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { CollegeSplits } from "_react/playerpage/stat/collegesplits";

type TStatisticsTabProps = {
	player: TPlayerPageCombinedPlayer;
};

const StatisticsTab = ({ player }: TStatisticsTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	return <CollegeSplits player={player} colorScheme={chakraColorSchemeGroup} />;
};

export default StatisticsTab;
