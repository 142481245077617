// Url makers
export function getPlayerUrl(philId: number) {
	return `/player?philId=${philId}`;
}

export function getTeamUrl(id: number) {
	return `/team?rockyId=${id}`;
}

export function getEvalUrl(evalId: string) {
	return `/eval?id=${evalId}`;
}

// Redirects
export function redirectToCurrentLook(evalId: string) {
	window.open(getEvalUrl(evalId), "_blank");
}

export function redirectToPlayerPage(philId: number) {
	window.open(getPlayerUrl(philId), "_blank");
}
