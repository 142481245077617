import React from "react";

import {
	TMinimalGridListItem,
	TMinimalGridListRow,
	TMinimalGridListStyle
} from "_react/shared/ui/presentation/components/MinimalGridList/_types";
import {
	ListSectionVStack,
	ListSectionLabelHStack,
	ListSectionLabelBox,
	ListRowHStack,
	ListItemVStack,
	ListItemLabelBox,
	ListItemValueFlex
} from "_react/shared/ui/presentation/components/MinimalGridList/_styles";

type MinimalGridListProps = {
	label?: string;
	icon?: React.ReactNode;
	data: Array<TMinimalGridListRow>;
	style?: TMinimalGridListStyle;
};

const MinimalGridList = ({ label, icon, data, style }: MinimalGridListProps) => {
	return (
		<ListSectionVStack sx={style?.container}>
			{label !== undefined && (
				<ListSectionLabelHStack sx={style?.header}>
					{icon}
					<ListSectionLabelBox sx={style?.label}>{label}</ListSectionLabelBox>
				</ListSectionLabelHStack>
			)}
			{data.map((row: TMinimalGridListRow, index: number) => (
				<ListRowHStack key={`row-${index}`}>
					{row.items.map((item: TMinimalGridListItem) => (
						<ListItemVStack key={item.label}>
							<ListItemLabelBox>{item.label}</ListItemLabelBox>
							<ListItemValueFlex>{item.value}</ListItemValueFlex>
						</ListItemVStack>
					))}
				</ListRowHStack>
			))}
		</ListSectionVStack>
	);
};

export default MinimalGridList;
