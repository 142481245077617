import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import { tableStyle } from "_react/stats/shared/formatting";
import StatsTableRow from "_react/stats/shared/StatsTableRow";
import StatsTableHeader from "_react/stats/shared/StatsTableHeader";
import { getCellValue, dataTransform } from "_react/stats/shared/helpers";
import {
	BATTER,
	PITCHER,
	FIELDER,
	getBatterAggregateRow,
	getPitcherAggregateRow,
	getFielderAggregateRow,
	LEVELS
} from "_redux/StatsAggregated/_helpers";
import StatsAggregateDialog from "_react/stats/shared/StatsAggregateDialog";
import { getAggregateTotalStatsFromSeasonData } from "_redux/StatsAggregated/betaStats.a";
import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { useSelectGroupedIndices } from "_react/stats/shared/_helpers";

const aggregateFunctionFromPlayerType = playerType => {
	if (playerType === BATTER) return getBatterAggregateRow;
	else if (playerType === PITCHER) return getPitcherAggregateRow;
	else if (playerType === FIELDER) return getFielderAggregateRow;
	return null;
};

const toggleRowsExpanded = (rowsExpanded, row) => {
	const newRowsExpanded = [...rowsExpanded];
	const index = newRowsExpanded.indexOf(row);

	if (index !== -1) newRowsExpanded.splice(index, 1);
	else newRowsExpanded.push(row);

	return newRowsExpanded;
};

const StatsTable = ({
	headers: headersRaw,
	data: rawData,
	initialSortColumn,
	sortable,
	allowUserAggregate,
	playerType,
	history,
	showLimit,
	aggregateMethod,
	tooltips,
	colorScheme = defaultColorScheme
}) => {
	// Setting up headers
	const headers = headersRaw.map(h =>
		tooltips != null
			? {
					...h,
					tooltip: tooltips(h.key, playerType) ? tooltips(h.key, playerType) : null
			  }
			: h
	);

	// Limiting
	const [showLimitValue, setShowLimitValue] = useState(showLimit);

	// Hovering
	const [hoveredColumn, setHoveredColumn] = useState(null);

	// Expanding
	const [rowsExpanded, setRowsExpanded] = useState([]);

	// Aggregate Computation
	let data = rawData;
	if (aggregateMethod !== "all") {
		data = dataTransform(data != null ? data : [], aggregateMethod);
	}

	// Sorting
	const [sortColumn, setSortColumn] = useState(initialSortColumn ? initialSortColumn : headers[0]);
	const [sortAsc, setSortAsc] = useState(false);
	useEffect(() => {
		if (initialSortColumn && initialSortColumn.key !== sortColumn.key) {
			setSortColumn(initialSortColumn);
			setSortAsc(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialSortColumn]);

	const sortedData = useMemo(() => {
		return sortColumn === null || !sortable
			? data
			: data.sort((a, b) => {
					let aValue =
						sortColumn.key !== "player"
							? parseFloat(getCellValue(sortColumn, a), 10)
							: getCellValue(sortColumn, a);
					let bValue =
						sortColumn.key !== "player"
							? parseFloat(getCellValue(sortColumn, b), 10)
							: getCellValue(sortColumn, b);
					if (aValue == null || (sortColumn.key !== "player" && isNaN(aValue)))
						aValue = sortAsc ? 99999 : -99999;
					if (bValue == null || (sortColumn.key !== "player" && isNaN(bValue)))
						bValue = sortAsc ? 99999 : -99999;
					if (aValue < bValue) {
						return sortAsc ? -1 : 1;
					}
					if (aValue > bValue) {
						return sortAsc ? 1 : -1;
					}
					return 0;
			  });
	}, [data, sortColumn, sortable, sortAsc]);

	const { dataWithIndices, indexData, indexGroups, resetAll } = useSelectGroupedIndices(
		data,
		datum => datum.isNestedRow
	);
	const [selectedParents, selectedChildren, setNewSelectedIndex, groupingBounds] = indexData;

	// TODO: more elegant way for this likely
	const seasonLevelTeams =
		sortedData && sortedData.map(statsRow => `${statsRow.season}-${statsRow.team_id}`).join("~");
	useEffect(() => {
		resetAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seasonLevelTeams]);

	const selectedIndices = [...selectedChildren];
	selectedParents.forEach(parent => {
		const children = indexGroups[parent] ?? new Set();
		if (children.length) {
			selectedIndices.push(...children);
		} else {
			selectedIndices.push(parent);
		}
	});

	// Aggregator Popup
	const [openAggregate, setOpenAggregate] = useState(false);
	useEffect(() => {
		if (selectedParents.size + selectedChildren.size > 1) setOpenAggregate(true);
		else setOpenAggregate(false);
	}, [selectedParents.size, selectedChildren.size]);

	const handleRowSelect = idx => isMetaKeyPressed => {
		if (allowUserAggregate) {
			setNewSelectedIndex(idx, isMetaKeyPressed);
		}
	};

	const aggregateFunc = aggregateFunctionFromPlayerType(playerType);
	const aggregateForRow = data => {
		return getAggregateTotalStatsFromSeasonData(data, aggregateFunc);
	};
	const ignoreForAggregate = ["season", "team", "age", "relativateAge", "player"];
	const aggregateHeaders = headers.filter(col => !ignoreForAggregate.includes(col.key));
	const projectionHeaders = headers.map(headerColumn => ({
		...headerColumn,
		name: headerColumn.name === "TEAM" ? "PROJECTION" : headerColumn.name
	}));
	const handleDialogClose = () => {
		setOpenAggregate(false);
	};

	return (
		<div>
			<StatsAggregateDialog
				aggregateFor={Object.keys(LEVELS)}
				aggregateFunc={aggregateForRow}
				aggregateOn={"level"}
				data={sortedData}
				headers={aggregateHeaders}
				onClose={handleDialogClose}
				open={openAggregate && allowUserAggregate}
				selectedIndices={selectedIndices}
			/>
			<table style={{ ...tableStyle, borderColor: "white", border: "none" }}>
				<thead>
					<tr>
						<StatsTableHeader
							headers={headers}
							setSortColumn={setSortColumn}
							sortAsc={sortAsc}
							setSortAsc={setSortAsc}
							sortable={sortable}
							keyIdentifier="team"
						/>
					</tr>
				</thead>
				<tbody>
					{(showLimitValue ? dataWithIndices.slice(0, showLimitValue) : dataWithIndices).map(
						({ datum, indexData }, idx) => {
							if (datum.isNestedRow && !rowsExpanded.includes(indexData.outerIndex)) return null;
							const isParent = indexGroups.hasOwnProperty(`${idx}`);
							const isAChildSelected = isParent && !indexGroups[idx].every(c => !selectedChildren.has(c));

							if (datum.isProjectionStart) {
								return (
									<tr>
										<StatsTableHeader
											headers={projectionHeaders}
											setSortColumn={setSortColumn}
											sortAsc={sortAsc}
											setSortAsc={setSortAsc}
											sortable={sortable}
											keyIdentifier="projection"
										/>
									</tr>
								);
							}

							return (
								<StatsTableRow
									colorSchemeGroup={colorScheme}
									data={datum}
									expandable={datum.expandable}
									expanded={rowsExpanded.includes(indexData.outerIndex) && !datum.isNestedRow}
									onRowSelect={allowUserAggregate ? handleRowSelect(idx) : null}
									headers={headers}
									history={history}
									hoveredColumn={hoveredColumn}
									index={indexData.outerIndex}
									isAChildSelected={isAChildSelected}
									key={idx}
									nestedIndex={indexData.nestedIndex}
									selected={selectedParents.has(idx) || selectedChildren.has(idx)}
									selectedEdge={groupingBounds != null && groupingBounds.includes(idx)}
									setHoveredColumn={setHoveredColumn}
									setRowExpanded={row => setRowsExpanded(toggleRowsExpanded(rowsExpanded, row))}
								/>
							);
						}
					)}
				</tbody>
			</table>
			{showLimitValue && (
				<div>
					<h3
						key={2}
						style={{
							color: "gray",
							textAlign: "center",
							marginTop: "25px"
						}}
					>
						Showing {sortedData.length > showLimitValue ? showLimitValue : sortedData.length} of{" "}
						{sortedData.length} Rows
					</h3>
					{sortedData.length > showLimitValue && (
						<div
							style={{
								margin: "auto",
								textAlign: "center",
								marginTop: "20px",
								marginBottom: "25px"
							}}
						>
							<Button
								onClick={() => setShowLimitValue(showLimitValue + 25)}
								style={{
									backgroundColor: "#01549b",
									color: "white"
								}}
								variant="contained"
							>
								Show More
							</Button>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

StatsTable.propTypes = {
	aggregateMethod: PropTypes.string,
	allowUserAggregate: PropTypes.bool,
	colorScheme: PropTypes.object,
	data: PropTypes.array,
	headers: PropTypes.array,
	history: PropTypes.object,
	initialSortColumn: PropTypes.object,
	playerType: PropTypes.string,
	showLimit: PropTypes.number,
	sortable: PropTypes.bool,
	tooltips: PropTypes.func
};

StatsTable.defaultProps = {
	aggregateMethod: "all",
	sortable: true,
	allowUserAggregate: true,
	showLimit: null
};

export default StatsTable;
