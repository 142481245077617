import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

function Assessment({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={"none"} d="M0 0h24v24H0z" />
			<path
				fill={fill}
				d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"
			/>
		</IconSVG>
	);
}

export default Assessment;
