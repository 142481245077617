import React, { FC, useState } from "react";
import dayjs from "dayjs";

import { getRosterStatusFromProPlayer } from "utils/functions";
import { formatHeight } from "utils/functions";
import { getAgeFromBirthDate } from "utils/helpers";
import { getTeamName } from "_react/shared/_helpers/team";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import KeyboardArrowDown from "_react/shared/legacy/ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "_react/shared/legacy/ui/icons/KeyboardArrowUp";
import { TextPair } from "_react/shared/legacy/ui/Text";

import { PRO } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { AgentOrAgencyField } from "_react/playerpage/header/AgentOrAgencyField/Field";
import {
	RedHightlightText,
	InfoCardsResponsiveGrid,
	ExtraPlayerInfoMobile,
	ExtraPlayerInfoButton,
	PlayerInfoCardWrapper,
	CARD_STYLE,
	InnerCardDiv,
	TEXT_PAIR_STYLE
} from "_react/playerpage/header/fields/_styles";
import { getMostRecentDraftPick } from "_react/playerpage/_helpers";

export type HeaderFieldsProps = {
	colorSchemeGroup?: ColorSchemeGroup;
	player: TPlayerPageCombinedPlayer;
	playerType?: string;
	showingAdditionalInfo?: boolean;
};

export const ProHeaderFields: FC<HeaderFieldsProps> = ({ colorSchemeGroup, player }) => {
	const [showingAdditionalInfo, setShowingAdditionalInfo] = useState(false);
	const org = player && player.proProfile?.orgCode ? player.proProfile.orgCode.toLowerCase() : "none";

	const age = getAgeFromBirthDate(player.birthDate);
	const mostRecentDraftPick = getMostRecentDraftPick(player);

	const agentName =
		!player.proProfile?.agent?.firstName && !player.proProfile?.agent?.lastName
			? "--"
			: `${player.proProfile?.agent?.firstName ?? ""} ${player.proProfile?.agent?.lastName ?? ""}`;

	const agentField = player.proProfile?.agentId ? (
		<AgentOrAgencyField
			label={agentName}
			infoId={player.proProfile?.agentId}
			infoKey={"agent"}
			playerType={PRO}
			org={org}
		/>
	) : (
		"--"
	);
	const agencyField = player.proProfile?.agent?.agencyId ? (
		<AgentOrAgencyField
			label={player.proProfile.agent.agency?.agencyName ?? "--"}
			infoId={player.proProfile.agent.agencyId}
			infoKey={"agency"}
			playerType={PRO}
			org={org}
		/>
	) : (
		"--"
	);

	return (
		<InfoCardsResponsiveGrid playerType={PRO}>
			<PlayerInfoCardWrapper idx={0} showingAdditionalInfo={showingAdditionalInfo}>
				<Card style={CARD_STYLE}>
					<InnerCardDiv rowIdx={0}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Team"} body={getTeamName(player.team)} height={"max-content"} />
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Org/Level"}
								body={`${
									player.proProfile?.orgCode
										? `${player.proProfile.orgCode}${
												player.team?.level ? `/${player.team?.level}` : ""
										  }`
										: "Free Agent"
								}`}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={1}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"DOB/Age"}
								body={
									player.birthDate && player.birthDate != null
										? `${dayjs(player.birthDate).format("M/D/YYYY")} (${age})`
										: "--"
								}
								height={"max-content"}
							/>
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Birthplace"}
								body={`${player.birthCity ? `${player.birthCity}, ` : ""}${
									player.birthState ? player.birthState : player.birthCountry
								}`}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={2}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Weight"} body={player.weight} height={"max-content"} />
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Height"}
								body={player.height ? formatHeight(player.height) : "--"}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<ExtraPlayerInfoMobile showingAdditionalInfo={showingAdditionalInfo}>
						<InnerCardDiv rowIdx={3}>
							<div style={TEXT_PAIR_STYLE}>
								<TextPair
									title={"Options Used"}
									body={`${player.proProfile?.optionsUsed} of ${player.proProfile?.optionsTotal}`}
									height={"max-content"}
								/>
							</div>
						</InnerCardDiv>
						<InnerCardDiv rowIdx={4}>
							<div style={TEXT_PAIR_STYLE}>
								<TextPair
									title={"OD/Cur MLS"}
									// No inaccurate rounding will happen here since these are stored as exact decimals in the db
									body={`${player.proProfile?.mlsOpeningDay?.toFixed(3) ??
										"-"} / ${player.proProfile?.mlsCurrent?.toFixed(3) ?? "-"}`}
									height={"max-content"}
								/>
							</div>
							<div style={TEXT_PAIR_STYLE}>
								<TextPair
									title={"O.D. ML Control"}
									body={`${player.proProfile?.ryc ?? "-"} + ${player.proProfile?.rycOptions ?? "-"}`}
									height={"max-content"}
								/>
							</div>
						</InnerCardDiv>
					</ExtraPlayerInfoMobile>
					<ExtraPlayerInfoButton
						colorSchemeGroup={colorSchemeGroup}
						onClick={() => {
							setShowingAdditionalInfo(val => !val);
						}}
						style={{ marginBottom: "-12px" }}
					>
						{" "}
						{showingAdditionalInfo ? (
							<KeyboardArrowUp style={{ height: "18px", marginBottom: "-5px" }} />
						) : (
							<KeyboardArrowDown style={{ height: "18px", marginBottom: "-5px" }} />
						)}
						More Information
					</ExtraPlayerInfoButton>
				</Card>
			</PlayerInfoCardWrapper>
			<PlayerInfoCardWrapper idx={1} showingAdditionalInfo={showingAdditionalInfo}>
				<Card style={CARD_STYLE}>
					<InnerCardDiv rowIdx={0}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"School"}
								body={`${mostRecentDraftPick?.r4SchoolName ?? ""} ${
									mostRecentDraftPick?.r4State ? `(${mostRecentDraftPick?.r4State})` : ""
								}`}
								height={"max-content"}
							/>
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Drafted"}
								body={
									mostRecentDraftPick
										? mostRecentDraftPick.r4Status === "Drafted"
											? `${mostRecentDraftPick.r4Year} - Rd. ${mostRecentDraftPick.r4Round} - #${mostRecentDraftPick.r4Overall} (${mostRecentDraftPick.r4Org})`
											: `Undrafted FA (${mostRecentDraftPick.r4Year})`
										: player.proProfile?.signingCountry !== "US" &&
										  player.proProfile?.firstProContractDate != null
										? `Int'l FA (${dayjs(player.proProfile?.firstProContractDate).format("YYYY") ??
												"--"})`
										: "--"
								}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={1}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"FYOB"}
								body={
									player.proProfile?.firstProContractDate &&
									player.proProfile?.firstProContractDate != null
										? dayjs(player.proProfile.firstProContractDate).format("YYYY")
										: "-"
								}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={2}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Agent"} body={agentField} height={"max-content"} />
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair title={"Agency"} body={agencyField} height={"max-content"} />
						</div>
					</InnerCardDiv>
				</Card>
			</PlayerInfoCardWrapper>
			<PlayerInfoCardWrapper idx={2} showingAdditionalInfo={showingAdditionalInfo}>
				<Card style={CARD_STYLE}>
					<InnerCardDiv rowIdx={0}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Roster Status"}
								body={getRosterStatusFromProPlayer(player)}
								height={"max-content"}
							/>
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Options Used"}
								body={`${player.proProfile?.optionsUsed} of ${player.proProfile?.optionsTotal}`}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={1}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Rule 5"}
								body={
									player.proProfile?.isR5Eligible
										? `Yes (${player.proProfile?.r5EligibleYear ?? ""})`
										: "No"
								}
								height={"max-content"}
							/>
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"DQ Contract"}
								body={
									player.proProfile?.isDisqualifiedFlag ? (
										<RedHightlightText>{"Yes"}</RedHightlightText>
									) : (
										"No"
									)
								}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={2}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Prior Outright"}
								body={
									player.proProfile?.priorOutrightDate != null
										? `Yes (${dayjs(player.proProfile.priorOutrightDate).format("M/D/YYYY")})`
										: "No"
								}
								height={"max-content"}
							/>
						</div>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"Current Season Options"}
								body={
									player.proProfile?.optionsUsedCurrentSeason != null
										? `${player.proProfile.optionsUsedCurrentSeason} of 5`
										: "-"
								}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
				</Card>
			</PlayerInfoCardWrapper>
			<PlayerInfoCardWrapper idx={3} showingAdditionalInfo={showingAdditionalInfo}>
				<Card style={CARD_STYLE}>
					<InnerCardDiv rowIdx={0}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"OD/Cur MLS"}
								body={`${player.proProfile?.mlsOpeningDay?.toFixed(3) ??
									"-"} / ${player.proProfile?.mlsCurrent?.toFixed(3) ?? "-"}`}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={1}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"O.D. ML Control"}
								body={`${player.proProfile?.ryc ?? "-"} + ${player.proProfile?.rycOptions ?? "-"}`}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
					<InnerCardDiv rowIdx={2}>
						<div style={TEXT_PAIR_STYLE}>
							<TextPair
								title={"MiL FA Year"}
								body={
									!player.proProfile?.mnFaYear
										? "-"
										: player.proProfile.mnFaYear >= dayjs().year()
										? player.proProfile.mnFaYear.toString()
										: "-"
								}
								height={"max-content"}
							/>
						</div>
					</InnerCardDiv>
				</Card>
			</PlayerInfoCardWrapper>
		</InfoCardsResponsiveGrid>
	);
};
