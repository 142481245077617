import React, { useEffect, useState, CSSProperties, useMemo } from "react";

import { capitalizeFirstLetter } from "utils/_helpers";
import { useFetch } from "utils/url_helpers";
import { StyledSelect } from "_react/shared/selects";

export type TAgentSelect = {
	handleSelect: Function;
	placeholder?: string;
	styles?: CSSProperties;
	value?: number | null;
	disabled?: boolean;
	isClearable?: boolean;
	forDisplay?: boolean; // Indicates if this should show a select or just the value (so the data source is still managed here)
};

export type TAgent = {
	agentId: string;
	firstName: string | null;
	lastName: string | null;
	country: string | null;
	agencyFirmName: string | null;
	firmName: string | null;
	isActive: number;
};

export type TAgentOption = {
	label: string;
	value: Array<string>;
};

export const MOCK_AGENT_ID = "1"; // Placeholder value that users can select if a player's agent is Unknown

export const AgentSelect = ({
	handleSelect,
	placeholder = "Select an Agent...",
	disabled = false,
	isClearable = false,
	forDisplay = false,
	value = null
}: TAgentSelect) => {
	const { responseData: agentResponseData, isInProgress } = useFetch(`/agent`, []);
	const [agentDataSource, setAgentDataSource] = useState<Array<{ label: string; value: Array<string> }>>();

	function agentLabel(agent: TAgent) {
		const agentName = `${agent.firstName ? capitalizeFirstLetter(agent.firstName.toLowerCase()) : ""}${
			agent.firstName && agent.lastName ? " " : ""
		}${agent.lastName ? capitalizeFirstLetter(agent.lastName.toLowerCase()) : ""}`;
		const firmName = agent.agencyFirmName ? agent.agencyFirmName : agent.firmName;
		const country = agent.country;
		return `${agentName}${firmName && firmName !== "None" ? ", " + firmName : ""}${
			country ? " (" + country + ")" : ""
		}`;
	}

	// Display agent options as "first_name last_name, firm_name (country)"
	// If two agent options are exactly the same, combine them as one agent option
	useEffect(() => {
		if (agentResponseData) {
			const agentResponsesDeduped: { [index: string]: Array<string> } = {};
			agentResponseData
				.filter((agent: TAgent) => agent.isActive && (agent.firstName || agent.lastName))
				.forEach((agent: TAgent) => {
					const agentLabelString = agentLabel(agent);
					if (agentResponsesDeduped.hasOwnProperty(agentLabelString)) {
						agentResponsesDeduped[agentLabelString].push(agent.agentId);
					} else {
						agentResponsesDeduped[agentLabelString] = [agent.agentId];
					}
				});
			const agentResponseOptions: Array<TAgentOption> = [];
			Object.keys(agentResponsesDeduped).forEach(label => {
				agentResponseOptions.push({ label: label, value: agentResponsesDeduped[label] });
			});
			agentResponseOptions.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
			setAgentDataSource(agentResponseOptions);
		}
	}, [agentResponseData, setAgentDataSource]);

	const agent = useMemo(() => {
		if (agentDataSource && value) {
			return agentDataSource.find((agent: TAgentOption) => agent.value[0] === value.toString());
		}
		return null;
	}, [agentDataSource, value]);

	if (forDisplay) return <span>{agent ? agent.label : ""}</span>;

	return (
		<StyledSelect
			options={agentDataSource}
			onChange={handleSelect}
			placeholder={placeholder}
			disabled={disabled}
			isLoading={isInProgress}
			isClearable={isClearable}
			styles={{ container: { width: "100%" } }}
		/>
	);
};
