import React from "react";

import { ButtonsSelected } from "_react/shared/legacy/ui/ButtonSelected";
import { TOption } from "_react/inputs";

type TVideoViewSelectProps = {
	views: Array<TOption<string>>;
	viewIndex?: string;
	setViewIndex: (viewIndex: string) => void;
};

function VideoViewSelect({ views, viewIndex, setViewIndex }: TVideoViewSelectProps) {
	if (views.length < 2) return null;
	return (
		<div style={{ padding: "2px", display: "inline-block", flexGrow: 1 }}>
			<ButtonsSelected buttons={views} onSelect={setViewIndex} selected={viewIndex} />
		</div>
	);
}

export default VideoViewSelect;
