import React, { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";

import { TLink, ILinkSidebar } from "_react/app/_types";
import { LINKS } from "_react/app/links";
import { filterLinks } from "_react/app/_helpers";
import { useRoute } from "utils/url_helpers";
import { fetchUser } from "_react/shared/data_models/iam_new/_network";
import { IIamUserGroupRouteSchema, IIamUserSchema } from "_react/shared/data_models/iam_new/_types";

import { getSearchResults, formatOptionLabel, onSearchResultSelect } from "_react/shared/searches/_helpers";
import { TSearchResult } from "_react/shared/searches/_types";
import { searchStyles } from "_react/shared/searches/_styles";
import { SEARCH_DEBOUNCE, SEARCH_RESULT_ROUTES } from "_react/shared/searches/_constants";

/*

	NOTE: This component is localized to the new header during the beta period. This will be moved to replace the existing Player and Team searches shared components with the wide release

*/

type SearchProps = {
	classifications: string[];
	orgId?: number;
};

export const Search: FC<SearchProps> = ({ classifications, orgId }) => {
	// User-accessible routes
	const [routes, setRoutes] = useState<ILinkSidebar[]>([]);
	// Navigation
	const history = useHistory();
	const currentRoute = useRoute();

	// Load the routes the user has access to into memory
	useEffect(() => {
		fetchUser({}).then((response: IIamUserSchema | undefined) => {
			const routes = response?.routes?.map((route: IIamUserGroupRouteSchema) => route.route);
			const filteredRoutes = filterLinks(LINKS, routes);

			const routesToSearch: ILinkSidebar[] = filteredRoutes.reduce((routes: ILinkSidebar[], route: TLink) => {
				const routeCasted = route as ILinkSidebar;
				if (routeCasted.children != null) {
					const childRoutesToAdd = (routeCasted.children as ILinkSidebar[]).filter(
						(route: ILinkSidebar) =>
							route.text != null &&
							!routes.some((existingRoute: ILinkSidebar) => existingRoute.text === route.text)
					);
					routes = [...routes, ...childRoutesToAdd];
				}
				if (
					routeCasted.text != null &&
					routeCasted.route != null &&
					!routes.some((existingRoute: ILinkSidebar) => existingRoute.text === routeCasted.text)
				) {
					routes.push(routeCasted);
				}
				return routes;
			}, []);
			setRoutes(routesToSearch);
		});
	}, []);

	// Only search every 0.3 seconds after typing stops
	const debouncedfetchSearchResults = debounce(getSearchResults(routes), SEARCH_DEBOUNCE, {
		leading: true
	});

	return (
		<AsyncSelect<TSearchResult, boolean>
			blurInputOnSelect={true}
			closeMenuOnSelect={true}
			formatOptionLabel={formatOptionLabel}
			isOptionDisabled={(option: TSearchResult) => option.type === SEARCH_RESULT_ROUTES}
			loadOptions={(searchText: string) => debouncedfetchSearchResults(searchText, classifications, orgId)}
			noOptionsMessage={({ inputValue }: { inputValue: string }) =>
				inputValue === ""
					? "Tip: Add 'HS', 'U', or 'JuCo' to filter for only schools of that level"
					: "No Results"
			}
			onChange={onSearchResultSelect(history, currentRoute)}
			openMenuOnClick={true}
			menuPortalTarget={document.body}
			styles={searchStyles("white")}
			placeholder="Search..."
			components={{ DropdownIndicator: null }}
			value={null}
		/>
	);
};
