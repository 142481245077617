import React, { MouseEventHandler } from "react";
import { Stack, Checkbox, HStack, Text } from "@chakra-ui/react";

import { TDPLSurveyYear, IDPLSurveyCheckbox } from "_react/playerpage/ama/profile/DPLQuestionnaire/_types";
import DPLQuestionnaireSurveyResponse from "./DPLQuestionnaireSurveyResponse";

type DPLQuestionnaireYearGroupProps = {
	year: TDPLSurveyYear;
	dplId: number | null;
	onOpenModal: MouseEventHandler<HTMLButtonElement>;
	onCloseModal: () => void;
	isOpenModal: boolean;
};

const DPLQuestionnaireYearGroup = ({
	year,
	dplId,
	onOpenModal,
	onCloseModal,
	isOpenModal
}: DPLQuestionnaireYearGroupProps) => {
	return (
		<Stack spacing={3} direction="column" key={year.year}>
			<Text as="b" color="gray.700">
				{year.year}
			</Text>
			{year.surveys.map((s: IDPLSurveyCheckbox) => (
				<HStack spacing={2} key={s.id}>
					<Checkbox isDisabled isChecked={s.completed} />
					<Text>{s.name}</Text>
					{s.completed && dplId != null && (
						<DPLQuestionnaireSurveyResponse
							dplId={dplId}
							surveyId={s.id}
							surveyName={`${s.year} ${s.name}`}
							onOpenModal={onOpenModal}
							onCloseModal={onCloseModal}
							isOpenModal={isOpenModal}
						/>
					)}
				</HStack>
			))}
			{year.surveys.length === 0 && (
				<Text as="b" color="gray.700">
					"No Surveys"
				</Text>
			)}
		</Stack>
	);
};

export default DPLQuestionnaireYearGroup;
