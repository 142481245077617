import React from "react";
import { useToast } from "@chakra-ui/react";

import { useSearchParam } from "utils/url_helpers";

import { TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import PlayerPageContent from "_react/playerpage/shared/content/PlayerPageContent";
import PlayerPageProvider from "_react/playerpage/PlayerPageProvider";

const PlayerPage = () => {
	const toast = useToast();

	const philIdRaw: string | undefined = useSearchParam("philId");
	const philId: number | undefined = philIdRaw && !isNaN(+philIdRaw) ? +philIdRaw : undefined;

	const playerIdRaw: string | undefined = useSearchParam("id");
	const playerId: number | undefined = playerIdRaw && !isNaN(+playerIdRaw) ? +playerIdRaw : undefined;

	const viewClassification = useSearchParam("viewClassification") as TPlayerPagePlayerClassification | undefined;

	return (
		<PlayerPageProvider philId={philId} playerId={playerId} viewClassification={viewClassification} toast={toast}>
			<PlayerPageContent />
		</PlayerPageProvider>
	);
};

export default PlayerPage;
