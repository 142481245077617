import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { Button } from "_react/shared/legacy/ui/Button";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import Delete from "_react/shared/legacy/ui/icons/Delete";

import { TPlayerPlanGoal, TPlayerPlanGoalDrill } from "_react/playerplan/shared/_types";
import {
	BothButtonStyle,
	DrillAddDiv,
	FocusAreaDeletedTitleDiv,
	FocusAreaFlexDiv,
	FocusAreaWrapper,
	RightButtonStyle,
	SmallCircularProgressStyle
} from "_react/playerplan/shared/_styles";
import { filterFastDeletes } from "_react/playerplan/shared/_helpers";
import { SupportingAction } from "_react/playerplan/shared/SupportingAction";
import { CANCELED, DISABLED_OPACITY, PITCH } from "_react/playerplan/shared/_constants";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import KeyboardArrowDown from "_react/shared/legacy/ui/icons/KeyboardArrowDown";
import KeyboardArrowRight from "_react/shared/legacy/ui/icons/KeyboardArrowRight";

export type TFocusAreaProps = {
	focusArea: TPlayerPlanGoal;
	isReadOnly: boolean;
	collapse: boolean;
	isGoalActionsDisabled: boolean;
	deleteFocusArea: (goal: TPlayerPlanGoal, deleteGoalDrillIds: Array<number>) => void;
	circularProgressFocusAreaId: number | null;
	setCircularProgressFocusAreaId: (focusAreaId: number) => void;
	setIsGoalDrillModalOpen: (value: boolean) => void;
	setModalFocusArea: (value: TPlayerPlanGoal | null) => void;
	circularProgressGoalDrillId: number | null;
	setCircularProgressGoalDrillId: (goalDrillId: number) => void;
	deleteGoalDrill: (goalDrillId: number) => void;
	colorSchemeGroup?: ColorSchemeGroup;
	showDeleted: boolean;
};

export const FocusArea = ({
	focusArea,
	isReadOnly,
	collapse,
	isGoalActionsDisabled,
	deleteFocusArea,
	circularProgressFocusAreaId,
	setCircularProgressFocusAreaId,
	setIsGoalDrillModalOpen,
	setModalFocusArea,
	circularProgressGoalDrillId,
	setCircularProgressGoalDrillId,
	deleteGoalDrill,
	colorSchemeGroup = defaultColorScheme,
	showDeleted
}: TFocusAreaProps) => {
	const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

	const drills: TPlayerPlanGoalDrill[] = focusArea.playerPlanDrills;

	// Number of Supporting Actions that have not been deleted
	const nonDeletedDrills: number = drills.reduce(
		(total: number, goalDrill: TPlayerPlanGoalDrill) => total + Number(!goalDrill.isDeleted),
		0
	);

	// Number of deleted Supporting Actions that have been filtered out due to users deleting them quickly
	const fastDeletedDrills: number = drills.reduce(
		(total: number, goalDrill: TPlayerPlanGoalDrill) =>
			total + Number(goalDrill.isDeleted && !filterFastDeletes(goalDrill.createDate, goalDrill.lastChangeDate)),
		0
	);

	// Whether there are Supporting Actions to display for this Focus Area,
	// given whether deleted Supporting Actions are being shown
	const hasDrillsToDisplay: boolean =
		// If deleted Supporting Actions are not being shown, check whether there is at least one non-deleted Supporting Action
		(!showDeleted && nonDeletedDrills > 0) ||
		// If deleted Supporting Actions are being shown, check whether there is at least one Supporting Action other than
		// Supporting Actions that were deleted quickly
		(showDeleted && drills.length - fastDeletedDrills > 0);

	// Whether a user can add Supporting Actions for this Focus Area
	const canAddDrills: boolean =
		!isReadOnly && focusArea.status.value !== CANCELED && focusArea.playerPlanMetric.metricTypeLk !== PITCH;

	const addNewOnClick = () => {
		setIsGoalDrillModalOpen(true);
		setModalFocusArea(focusArea);
	};

	useEffect(() => {
		setIsCollapsed(!collapse);
	}, [collapse]);

	return (
		<FocusAreaWrapper>
			<FocusAreaFlexDiv>
				{!isCollapsed && <KeyboardArrowDown onClick={() => setIsCollapsed(!isCollapsed)} />}
				{isCollapsed && <KeyboardArrowRight onClick={() => setIsCollapsed(!isCollapsed)} />}
				{focusArea.status.value !== CANCELED ? (
					<div>
						{focusArea.playerPlanMetric.metricType.abbreviation} - {focusArea.playerPlanMetric.label}
					</div>
				) : (
					<FocusAreaDeletedTitleDiv>
						{focusArea.playerPlanMetric.metricType.abbreviation} - {focusArea.playerPlanMetric.label}
						{` (${dayjs
							.utc(focusArea.createDate.substring(0, 23))
							.local()
							.format("MMMM YYYY")} - 
						${dayjs
							.utc(focusArea.lastChangeDate.substring(0, 23))
							.local()
							.format("MMMM YYYY")})`}
					</FocusAreaDeletedTitleDiv>
				)}
				{!isReadOnly &&
					focusArea.status.value !== CANCELED &&
					(circularProgressFocusAreaId === focusArea.id ? (
						<CircularProgress center={false} style={SmallCircularProgressStyle} />
					) : (
						<>
							{!isConfirmDelete && (
								<Tooltip
									title="Delete Focus Area"
									placement="right"
									colorScheme={colorSchemeGroup.primary}
								>
									<Delete
										fill={colorSchemeGroup.secondary.color}
										onClick={() => setIsConfirmDelete(true)}
									/>
								</Tooltip>
							)}
							{isConfirmDelete && (
								<div>
									<Button
										disabled={false}
										onClick={() => setIsConfirmDelete(false)}
										title="Cancel"
										colorScheme={colorSchemeGroup.primary}
										style={BothButtonStyle}
									/>
									<Button
										style={RightButtonStyle}
										disabled={isGoalActionsDisabled}
										onClick={() => {
											setIsConfirmDelete(false);
											setCircularProgressFocusAreaId(focusArea.id);
											deleteFocusArea(
												{
													...focusArea,
													status: {
														...focusArea.status,
														value: CANCELED
													}
												},
												focusArea.playerPlanDrills
													.filter((goalDrill: TPlayerPlanGoalDrill) => {
														return !goalDrill.isDeleted;
													})
													.map((goalDrill: TPlayerPlanGoalDrill) => {
														return goalDrill.id;
													})
											);
										}}
										title="Confirm"
										colorScheme={colorSchemeGroup.secondary}
										disabledOpacity={DISABLED_OPACITY}
									/>
								</div>
							)}
						</>
					))}
			</FocusAreaFlexDiv>
			{!isCollapsed && (
				<>
					<div>
						{drills
							.filter((goalDrill: TPlayerPlanGoalDrill) => {
								if (showDeleted) {
									if (goalDrill.isDeleted) {
										return filterFastDeletes(goalDrill.createDate, goalDrill.lastChangeDate);
									} else {
										return true;
									}
								} else {
									return !goalDrill.isDeleted;
								}
							})
							.sort((a: TPlayerPlanGoalDrill, b: TPlayerPlanGoalDrill) => {
								const dateComparison = a.createDate.localeCompare(b.createDate);
								if (dateComparison) return dateComparison;

								// If dates are the same, sort based on drill name
								const aName = a.playerPlanDrill?.name ?? "";
								const bName = b.playerPlanDrill?.name ?? "";
								return aName.localeCompare(bName);
							})
							.map((goalDrill: TPlayerPlanGoalDrill) => (
								<SupportingAction
									key={goalDrill.id}
									goalDrill={goalDrill}
									isReadOnly={isReadOnly}
									isGoalActionsDisabled={isGoalActionsDisabled}
									circularProgressGoalDrillId={circularProgressGoalDrillId}
									setCircularProgressGoalDrillId={setCircularProgressGoalDrillId}
									deleteGoalDrill={deleteGoalDrill}
									colorSchemeGroup={colorSchemeGroup}
									isDeleted={goalDrill.isDeleted}
								/>
							))}
					</div>
					<DrillAddDiv hasDrillsToDisplay={hasDrillsToDisplay}>
						{!hasDrillsToDisplay ? `${"No Supporting Actions"}` : ""}
						{canAddDrills && (
							<Button
								title={"Add New"}
								disabled={isGoalActionsDisabled}
								onClick={addNewOnClick}
								disabledOpacity={DISABLED_OPACITY}
								colorScheme={colorSchemeGroup.secondary}
							/>
						)}
					</DrillAddDiv>
				</>
			)}
		</FocusAreaWrapper>
	);
};
