import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function HamburgerMenu({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill="none" d="M0 0h24v24H0z"></path>
			<path fill={fill} d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
		</IconSVG>
	);
}
