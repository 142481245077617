import { TPitchTypes } from "_react/shared/_types/pitch_types";

// Fastballs
export const FF = "FF";
export const SI = "SI";

// Breaking Balls
export const CT = "CT";
export const SL = "SL";
export const SW = "SW";
export const SV = "SV";
export const CB = "CB";

// Offspeed
export const CH = "CH";
export const SP = "SP";

// Other
export const KN = "KN";

export const ALL_PITCH_TYPES = "All";

export const PITCH_TYPES: Array<TPitchTypes> = [CH, FF, SI, SL, SW, SV, CT, CB, SP, KN];

export const PITCH_TYPE_LABEL_MAP = {
	[FF]: "4-Seam",
	[SI]: "Sinker",
	[CT]: "Cutter",
	[SL]: "Slider",
	[SW]: "Sweeper",
	[SV]: "Slurve",
	[CB]: "Curveball",
	[CH]: "Changeup",
	[SP]: "Splitter",
	[KN]: "Knuckleball"
} as Record<TPitchTypes, string>;

// Sort order by pitch group
export const PITCH_TYPE_SORT_ORDER_MAP: { [key in TPitchTypes]: number } = {
	// Fastballs
	[FF]: 1,
	[SI]: 2,
	// Breaking Balls
	[CT]: 3,
	[SL]: 4,
	[SW]: 5,
	[SV]: 6,
	[CB]: 7,
	// Offspeed
	[CH]: 8,
	[SP]: 9,
	// Other
	[KN]: 10
};

export const PITCH_GROUP_OVERALL = "OVR";
export const PITCH_GROUP_FB = "FB";
export const PITCH_GROUP_BB = "BB";
export const PITCH_GROUP_OS = "OS";
export const PITCH_GROUP_OTH = "OTH";

// The max and min pitch velocity values were approved by Plunk on 9/14/23
export const MAX_PITCH_VELOCITY = 105;
export const MIN_PITCH_VELOCITY = 70;
