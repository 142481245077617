import { Machine, assign } from "xstate";

import { TVideoWDPL, TVideoWDPLValid } from "_react/video/shared/_types";

interface IContext {
	videos: TVideoWDPLValid[];
	selectedVideoIdx: number | null;
}

interface IStateSchema {
	states: {
		videoSelected: {};
		videoNotSelected: {};
	};
}

export const UPDATE_VIDEOS = "UPDATE_VIDEOS";
export const UPDATE_SELECTED = "UPDATE_SELECTED";
export const SELECT_NEXT_OR_PREV = "SELECT_NEXT_OR_PREV";
export const START_VIDEO = "START_VIDEO";

type TUpdateVideosEvent = { type: typeof UPDATE_VIDEOS; data: TVideoWDPL[] };
type TUpdateSelectedEvent = { type: typeof UPDATE_SELECTED; payload: { idx: number } };
type TSelectNextOrPrevEvent = { type: typeof SELECT_NEXT_OR_PREV; payload: { isNext: boolean } };
type TStartVideoEvent = { type: typeof START_VIDEO };
type TEvent = TUpdateVideosEvent | TUpdateSelectedEvent | TSelectNextOrPrevEvent | TStartVideoEvent;

const playerVideosMachine = Machine<IContext, IStateSchema, TEvent>(
	{
		id: "player-video-machine",
		context: {
			videos: [],
			selectedVideoIdx: null
		},
		initial: "videoNotSelected",
		on: {
			[UPDATE_VIDEOS]: [
				{
					target: "#player-video-machine.videoSelected",
					internal: false,
					actions: ["updateVideos", "clearSelected"],
					cond: "outOfBounds"
				},
				{ actions: "updateVideos" }
			],
			[UPDATE_SELECTED]: { actions: "updateSelected", cond: "validUpdate" }
		},
		states: {
			videoSelected: {
				always: { actions: "selectFirstVideo", cond: "noSelectedAndHasVideo" },
				on: {
					[SELECT_NEXT_OR_PREV]: { actions: "selectNextOrPrev" }
				}
			},
			videoNotSelected: {
				always: { actions: "clearSelected", cond: "isSelected" },
				on: {
					[START_VIDEO]: { target: "videoSelected" },
					[UPDATE_VIDEOS]: { target: "videoSelected", actions: "updateVideos" }
				}
			}
		}
	},
	{
		actions: {
			selectFirstVideo: assign({
				selectedVideoIdx: (context, _event) => {
					if (context.videos.length) {
						return 0;
					}
					return null;
				}
			}),
			updateVideos: assign({
				videos: (context, event) => {
					if (event.type !== UPDATE_VIDEOS) {
						return context.videos;
					}
					return event.data.filter(video => {
						if (video.type === "media") return video.dplSharedLink != null;
						return video.url != null;
					}) as TVideoWDPLValid[];
				}
			}),
			clearSelected: assign({
				selectedVideoIdx: (_context, _event) => null
			}),
			updateSelected: assign({
				selectedVideoIdx: (context, event) => {
					if (event.type !== UPDATE_SELECTED) {
						return context.selectedVideoIdx;
					}
					return event.payload.idx;
				}
			}),
			selectNextOrPrev: assign({
				selectedVideoIdx: (context, event) => {
					const { selectedVideoIdx, videos } = context;
					if (event.type !== SELECT_NEXT_OR_PREV) {
						return selectedVideoIdx;
					}
					if (!videos.length) return null;
					if (event.payload.isNext) {
						if (selectedVideoIdx == null || selectedVideoIdx >= videos.length - 1) return 0;
						return selectedVideoIdx + 1;
					}
					if (selectedVideoIdx == null || selectedVideoIdx === 0) return videos.length - 1;
					return selectedVideoIdx - 1;
				}
			})
		},
		guards: {
			noSelectedAndHasVideo: (context, _event) => context.videos.length > 0 && context.selectedVideoIdx == null,
			isSelected: (context, _event) => context.selectedVideoIdx != null,
			validUpdate: (context, event) => {
				if (event.type !== UPDATE_SELECTED || !context.videos.length) return false;
				const { idx } = event.payload;
				return idx >= 0 && idx < context.videos.length;
			},
			outOfBounds: (context, event) => {
				if (event.type !== UPDATE_VIDEOS) return false;
				const { selectedVideoIdx } = context;
				if (selectedVideoIdx == null) return true;
				return selectedVideoIdx >= event.data.length;
			}
		}
	}
);

export default playerVideosMachine;
