import { round10 } from "_react/shared/_helpers/numbers";
import {
	PITCH_USAGE_METRIC_GROUP,
	STUFF_METRIC_GROUP,
	PITCH_MOVEMENT_GROUP,
	PITCH_VELOCITY_METRIC_GROUP,
	RELEASE_POINT_GROUP
} from "_react/shared/data_models/metric/_constants";
import { TMetricGroup } from "_react/shared/data_models/metric/_types";

export const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const METRIC_GROUP_LABEL_MAP: { [key in TMetricGroup]: string } = {
	[PITCH_USAGE_METRIC_GROUP]: "Pitch Usage",
	[STUFF_METRIC_GROUP]: "Stuff (RA9)",
	[PITCH_MOVEMENT_GROUP]: "Pitch Movement",
	[PITCH_VELOCITY_METRIC_GROUP]: "Pitch Velocity (mph)",
	[RELEASE_POINT_GROUP]: "Release Point"
};

export const METRIC_GROUP_Y_TICK_FORMAT_MAP: {
	[key in TMetricGroup]?: (value: number) => number | string | undefined;
} = {
	[STUFF_METRIC_GROUP]: (value: number) => round10(value, -2)
};
