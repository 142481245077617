import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import { defaultColorScheme } from "_react/forms/Form";

const grades = [
	{
		title: "Role 80 – Elite",
		overall:
			"Best players or pitchers in the game. Dominant player with Hall of Fame ability and credentials. Perennial All-Star and MVP/Cy Young candidate. Extremely durable and consistent elite performer."
	},
	{
		title: "Role 70-79 – Excellent",
		positionPlayer:
			"Premium player on a championship club. Perennial All-Star and MVP candidate. Franchise type player. Best in the game at his position.",
		startingPitcher:
			"#1 Starters who can pitch at the top of any rotation for a championship club. Perennial All-Star and Cy Young candidate. Has durability and will log innings. Multiple plus pitches/out pitches with above average feel and command.",
		reliever:
			"Premium closer and elite late inning high leverage relievers. Type of pitcher who will dominate the 9th inning."
	},
	{
		title: "Role 60-69 – Good",
		positionPlayer:
			"All-Star caliber player on a championship club. Among the league leaders at his position. Above average offensive player. Frequent All-Star and MVP candidate. Has above average to plus tools.",
		startingPitcher:
			"#2 Starters. Type of starter who is capable of leading a staff for a championship club. Durable frequent All-Star and Cy Young candidate. Possesses above average or better pitches and/or above average feel and command.",
		reliever:
			"Dependable quality closer. Premium set-up/high leverage relievers who have the ability to pitch the 9th inning and/or dominate the  8th inning."
	},
	{
		title: "Role 55-59 – Strong Average",
		positionPlayer:
			"Quality Major League regular on a championship club. Above average production. Occasional All-Star and capable of MVP type seasons in his best years. Premium defender at SS/CF/C.",
		startingPitcher:
			"#3 Starters. Solid mid-rotation pitchers capable of pitching in the middle to upper end of a championship rotation. Reliable with average or above pitches, feel and/or command.",
		reliever: "Quality set-up/high leverage relievers. Capable of pitching 8th inning on a championship club."
	},
	{
		title: "Role 50-54 – Average",
		positionPlayer:
			"Major League regular with offensive and defensive value to play everyday on a championship club.",
		startingPitcher:
			"Dependable back of the rotation starter who will spend the entire season in a Major League rotation. Solid full season #4 starters (54-53) with some combination of durability, command or stuff. Reliable full season #5 starters (52-50) who possesses a combination of durability, command and stuff.",
		reliever:
			"Quality middle relievers capable of pitching innings in winning situations and premier situational relievers."
	},
	{
		title: "Role 45-49 – Marginal",
		positionPlayer:
			"Extra Major League player on a championship club. Utility/platoon type, defensive replacement, backup catcher or bench bat. Capable of playing for extended period of time in the event of an injury. This player may be capable of starting on 2nd division clubs.",
		startingPitcher:
			"Low end back of the rotation starters. Pitchers who will not last a full season in a Major League rotation. Capable of being swingmen who can provide solid middle relief innings and spot starts.",
		reliever: "Middle, long and situational relievers. Low leverage innings eaters."
	},
	{
		title: "Role 43-44 – High Fringe",
		positionPlayer:
			"Quality 4A type player. Provides ML emergency up and down value who can provide quality ML depth to an organization. Good 3A performer/producer but lack of skills will not allow him to stay in the Major Leagues for a full season.",
		startingPitcher:
			"Quality 4A type pitcher. Depth starter who has enough ability to make ML spot starts and give you a chance to win in the Major Leagues. Good 3A performer who will help you win in 3A.",
		reliever:
			"Relievers who can fill low leverage middle innings or situational roles in a ML bullpen for an extended period of time."
	},
	{
		title: "Role 40-42 – Low Fringe",
		overall:
			"Quality 3A players. Players and pitchers you want playing at 3A who can fill roles and help you win at the upper levels. May get a ML opportunity in certain situations or in certain seasons."
	},
	{
		title: "Role 35-39 – Organizational",
		overall:
			"Not a Major League player, but one who can add depth and value to our minor league system. Players or pitchers who complete a roster or fill a role but have little chance of playing in the Major Leagues."
	},
	{
		title: "0 - No Prospect",
		overall:
			"For those players who can absolutely not add any value to our organization and are a likely release. This category should be seldom used at the upper levels."
	},
	{
		title: "LL – Limited Look",
		overall:
			"To be used for those players within your coverage who you have not seen enough to write a full report on."
	},
	{
		title: "INJ – Injured",
		overall:
			"For those players on the DL or not playing due to an injury at the time you are seeing a club. Please mention what injury is and any additional information you may gather related to the ailment."
	},
	{
		title: "X – Did Not See",
		overall: "For those players who did not play and were not injured or on the DL."
	}
];

const gradingScale = {
	title: "Grading Scale",
	columns: ["Grade", "Batting Average", "HR", "SB", "Velocity"],
	rows: [
		{
			Grade: "80",
			"Batting Average": ".320 +",
			HR: "40+",
			SB: "35+",
			Velocity: "98+"
		},
		{
			Grade: "70",
			"Batting Average": ".300 - .319",
			HR: "30-39",
			SB: "25-34",
			Velocity: "96-97"
		},
		{
			Grade: "60",
			"Batting Average": ".280 - .299",
			HR: "25-29",
			SB: "16-24",
			Velocity: "94-95"
		},
		{
			Grade: "55",
			"Batting Average": ".270 - .279",
			HR: "20-24",
			SB: "11-15",
			Velocity: "93"
		},
		{
			Grade: "50",
			"Batting Average": ".260 - .269",
			HR: "16-19",
			SB: "6-10",
			Velocity: "92"
		},
		{
			Grade: "45",
			"Batting Average": ".250 - .259",
			HR: "13-15",
			SB: "4-6",
			Velocity: "90-91"
		},
		{
			Grade: "40",
			"Batting Average": ".230 - .249",
			HR: "10-12",
			SB: "1-3",
			Velocity: "88-89"
		},
		{
			Grade: "30",
			"Batting Average": ".210 - .229",
			HR: "3-9",
			SB: "---",
			Velocity: "86-87"
		},
		{
			Grade: "20",
			"Batting Average": ".200 - .209",
			HR: "0-3",
			SB: "---",
			Velocity: "< 86"
		}
	]
};

const reportingSections = [
	{
		title: "OFP",
		description:
			"Based off the prospect categories, the OFP number represents what the player’s role is or will be during the highest point of sustained success in his career (3 years). For established Major League players and experienced upper level minor league players this number should be more exact with little to no projection. For younger major and minor leaguers there should be more projection involved when determining their ultimate upside. "
	},
	{
		title: "Risk",
		description:
			"This category should be used to acknowledge the likelihood of the player achieving and/or sustaining his OFP. This determination will be based off his age and a number of factors, both physical and mental. Remember that this risk assessment should be related to a players future grades.",
		list: [
			{
				title: "None",
				description:
					"You feel there is no reason that this player will not reach his potential or maintain his future OFP."
			},
			{
				title: "Low",
				description: "You feel there is high probability that this player will achieve and maintain his OFP."
			},
			{
				title: "Medium",
				description:
					"You feel there is good reason to believe that this player will achieve and maintain his OFP, but concerns exist."
			},
			{
				title: "High",
				description:
					"You want to give this player a chance to achieve his OFP, but there is a significant reason(s) that you do not have high confidence in this happening."
			},
			{
				title: "Extreme",
				description:
					"You are acknowledging that the player has the potential for a high ceiling, accompanied with great risk of him ever being able to use them. A lottery ticket you would like to have."
			}
		]
	},
	{
		title: "Acquire",
		description:
			"Based on the role grade, Yes or No, would you want to acquire the player for the role you described. When you assign a player an OFP of 50 or above, the acquire should almost always be a Yes, unless for “known” off field or on field issues, that could affect the player or team in a negative way"
	},
	{
		title: "ML Profile",
		description:
			"Similar to the OFP. This box tells us what role the player will have in his more productive, sustainable years. This box should correspond with the role associated with the assigned OFP."
	},
	{
		title: "Trend",
		description:
			"This field should be used for ML and 3A players to depict if their overall abilities are trending up, down or staying the same. This field will be required for all Major League players. It will be available for any Minor League players you have history with."
	},
	{
		title: "Look Type",
		description: "The circumstances under which you saw and reported on a player.",
		list: [
			{
				title: "Coverage",
				description:
					"You are reporting on this player because he is a member of one of your assigned teams. This will hold true no matter what time of the season you see him."
			},
			{
				title: "Special Assignment",
				description:
					"This player falls outside your assigned coverage and you were asked to see him by the Front Office"
			},
			{
				title: "Trade Deadline",
				description:
					"Similar to a Special Assignment, this designation should be used when you are asked to see a potential trade target prior to the Deadline during the month of July."
			},
			{
				title: "Instructional League, Spring Training, Winter Ball, AFL",
				description: "Should be used when reporting on players in those circumstances."
			},
			{
				title: "Workout",
				description:
					"To be used when asked to go see a player or pitcher workout or throw a bullpen both in season and during the offseason. This designation should only be used in association with a Limited Look report."
			}
		]
	}
];

class ProGradeTooltip extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			section: "reporting"
		};
	}

	render() {
		const { section } = this.state;

		return (
			<div
				style={{
					padding: "15px"
				}}
			>
				<div
					style={{
						margin: "auto",
						textAlign: "center",
						marginBottom: "20px",
						marginTop: "10px"
					}}
				>
					<Button
						color="primary"
						onClick={() => this.setState({ section: "reporting" })}
						style={{ marginRight: "10px" }}
						variant={section === "reporting" ? "contained" : "outlined"}
					>
						Reporting
					</Button>
					<Button
						color="primary"
						onClick={() => this.setState({ section: "grade" })}
						style={{ marginLeft: "10px" }}
						variant={section === "grade" ? "contained" : "outlined"}
					>
						Grading Scale
					</Button>
				</div>
				{section === "reporting" && (
					<div>
						<h1
							style={{
								textAlign: "center",
								color: defaultColorScheme.group.backgroundColor,
								fontSize: "1.2em",
								fontWeight: "bold",
								marginTop: "13px",
								marginBottom: "13px"
							}}
						>
							PHILADELPHIA PHILLIES PRO SCOUTING REPORTING
						</h1>
						<div>
							{reportingSections.map((section, idx) => {
								return (
									<div key={idx} style={{ marginBottom: "10px" }}>
										<span
											style={{
												fontSize: "1em",
												color: "#f44335",
												fontWeight: "700"
											}}
										>
											{section.title}
										</span>
										<br />
										<div
											style={{
												borderBottom:
													idx !== reportingSections.length - 1 ? "1px solid gray" : "",
												paddingBottom: idx !== reportingSections.length - 1 ? "10px" : ""
											}}
										>
											<span style={{ fontSize: "0.85em" }}>{section.description}</span>
											{section.list != null && (
												<ul style={{ marginTop: 0, paddingLeft: "40px" }}>
													{section.list.map((listItem, idx) => {
														return (
															<li key={idx}>
																<span
																	style={{
																		fontWeight: "600",
																		fontSize: "0.85em"
																	}}
																>
																	{listItem.title}:{" "}
																</span>
																<span
																	style={{
																		fontSize: "0.85em"
																	}}
																>
																	{listItem.description}
																</span>
															</li>
														);
													})}
												</ul>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				)}
				{section === "grade" && (
					<div>
						<h1
							style={{
								textAlign: "center",
								color: defaultColorScheme.group.backgroundColor,
								fontSize: "1.2em",
								fontWeight: "bold",
								marginTop: "13px",
								marginBottom: "13px"
							}}
						>
							PHILADELPHIA PHILLIES PRO SCOUTING ROLE DEFINITIONS
						</h1>
						<div>
							{grades.map((grade, idx) => {
								return (
									<div key={idx} style={{ marginBottom: "10px" }}>
										<span
											style={{
												fontSize: "1em",
												color: "#f44335",
												fontWeight: "700"
											}}
										>
											{grade.title}
										</span>
										<br />
										<div
											style={{
												borderBottom: "1px solid gray",
												paddingBottom: "10px"
											}}
										>
											{grade.overall != null && <span>{grade.overall}</span>}
											{grade.overall == null && (
												<div>
													<span
														style={{
															fontWeight: "600",
															fontSize: "0.85em"
														}}
													>
														Position Player:{" "}
													</span>
													<span
														style={{
															fontSize: "0.85em"
														}}
													>
														{grade.positionPlayer}
													</span>
													<br />
													<span
														style={{
															fontWeight: "600",
															fontSize: "0.85em"
														}}
													>
														Starting Pitcher:{" "}
													</span>
													<span
														style={{
															fontSize: "0.85em"
														}}
													>
														{grade.startingPitcher}
													</span>
													<br />
													<span
														style={{
															fontWeight: "600",
															fontSize: "0.85em"
														}}
													>
														Relievers:{" "}
													</span>
													<span
														style={{
															fontSize: "0.85em"
														}}
													>
														{grade.reliever}
													</span>
												</div>
											)}
										</div>
									</div>
								);
							})}
							<Table style={{ marginTop: "25px" }}>
								<TableHead
									style={{
										backgroundColor: defaultColorScheme.group.backgroundColor
									}}
								>
									<TableRow>
										<TableCell
											colSpan={gradingScale.columns.length}
											style={{
												color: defaultColorScheme.group.color,
												fontSize: "16px",
												textAlign: "center"
											}}
										>
											{gradingScale.title}
										</TableCell>
									</TableRow>
									<TableRow>
										{gradingScale.columns.map((column, idx) => {
											return (
												<TableCell
													key={idx}
													style={{
														color: defaultColorScheme.group.color,
														textAlign: "center",
														textTransform: "capitalize"
													}}
												>
													{column}
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
								<TableBody
									style={{
										border: "1px solid #dfe0df"
									}}
								>
									{gradingScale.rows.map((row, idx) => {
										return (
											<TableRow key={idx}>
												{gradingScale.columns.map((column, idx) => {
													return (
														<TableCell
															key={idx}
															style={{
																textAlign: "center"
															}}
														>
															{row[column]}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default ProGradeTooltip;
