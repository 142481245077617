import React, { FunctionComponent, useContext, CSSProperties } from "react";

import { useFetch } from "utils/url_helpers";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { InjuriesPage } from "_react/playerpage/injuries/InjuriesPage";
import { PlayerPageContext } from "_react/playerpage/_context";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";

type TInjuriesStyle = {
	cardHeader?: CSSProperties;
};

type TInjuriesProps = {
	player: TPlayerPageCombinedPlayer;
	colorSchemeGroup: ColorSchemeGroup;
	viewClassification: string | null;
	style?: TInjuriesStyle;
};

export const Injuries: FunctionComponent<TInjuriesProps> = ({
	player,
	colorSchemeGroup,
	viewClassification,
	style
}) => {
	const getUrlEbis = `/injury/ebis_id/${player.ebisId}`;
	const { responseData: injuriesEbis, isInProgress: fetchingEbis } = useFetch(getUrlEbis, []);

	const getUrlBIS = `/injury_bis/bam_id/${player.bamId}/${player.fangraphsId}`;
	const { responseData: injuriesBIS, isInProgress: fetchingBIS } = useFetch(getUrlBIS, []);

	// TODO: Add gumbo injury data similar to above

	const { evals } = useContext(PlayerPageContext);

	return (
		<InjuriesPage
			colorSchemeGroup={colorSchemeGroup}
			evaluations={evals}
			fetchingBIS={fetchingBIS}
			fetchingEbis={fetchingEbis}
			injuriesBIS={injuriesBIS}
			injuriesEbis={injuriesEbis}
			philId={player.playerProId}
			viewClassification={viewClassification}
			style={style}
		/>
	);
};
