import { BASE_URL } from "utils/redux_constants";
import axios from "_redux/_utils/_axios";
import { NOTIFY_USER } from "_redux/Error/_types";
// import {selectLoggedInUser} from "../Auth/_selectors";
// import {getErrorFromResponse, getStatusFromResponse} from "./error.r";
// import {Socket} from "../../utils";
// import { selectApiAvailable } from "../App/_selectors";
// import {sendSlackMessage} from "../../utils/functions";

/*
 	Generic Error Action Generator
 */
export const genericErrorAction = (type, error, additionalPayload = {}) => {
	return dispatch => {
		// const { notifyDevs = true } = additionalPayload;
		// if(process.env.REACT_APP_API_URL === 'https://api.phils.io' && notifyDevs){
		// let user = selectLoggedInUser(getState());
		// let userInfo = user.hasOwnProperty("first_name") && user.first_name !== null ? `${user.first_name} ${user.last_name}` : "Unknown User Likely Not Logged In";
		// let text = `${userInfo}, REDUX TYPE: ${type}\n`;
		// if(type !== "AUTH_WARNING_ERROR"){
		//              text = `${text} ERROR: ${getErrorFromResponse({type, error})} \n STATUS: ${getStatusFromResponse({error})}\n LOCATION: ${window.location.href}\n`;
		// 	text = `${text} Socket Available: ${Socket.connected}, API Available: ${selectApiAvailable(getState())}`;
		// }
		// sendSlackMessage(text);
		// }
		dispatch({
			type: type,
			error: error,
			payload: additionalPayload
		});
	};
};

export const open_error_modal = value => ({
	type: NOTIFY_USER,
	value: value
});

export const reportAppError = (url, type, title, content) => {
	return function() {
		const d = new Date();
		const timestamp = Math.round(d.getTime() / 1000);
		return axios.post(`${BASE_URL}/app_error`, {
			url,
			timestamp,
			type,
			title,
			content
		});
	};
};
