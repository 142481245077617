import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import {
	IStatsPlayerFielding,
	IStatsPlayerFieldingOpps,
	IStatsPlayerRangeAmaByLevel,
	IStatsPlayerRangeAmaOpps
} from "_react/shared/data_models/defensive_observed/_types";

export type TStatsPlayerFieldingGetQueryParams = {
	playerId?: number;
	"playerId[in]"?: string;
	bamId?: number;
	"bamId[in]"?: string;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	fields?: string;
};

export type TStatsPlayerFieldingOppsGetQueryParams = {
	season?: number;
	"season[in]"?: string;
	position?: string;
	"position[in]"?: string;
	opportunitiesPerFullSeason?: number;
	"opportunitiesPerFullSeason[gte]"?: number;
	"opportunitiesPerFullSeason[lte]"?: number;
	expectedOutsPerFullSeason?: number;
	"expectedOutsPerFullSeason[gte]"?: number;
	"expectedOutsPerFullSeason[lte]"?: number;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
};

export type TStatsPlayerRangeAmaByLevelQueryParams = {
	playerId?: number;
	"playerId[in]"?: string;
	id?: number;
	"id[in]"?: string;
	bamId?: number;
	"bamId[in]"?: string;
	season?: number;
	"season[in]"?: string;
	level?: string;
	position?: string;
	innings?: number;
	"innings[gte]"?: number;
	"innings[lte]"?: number;
	pctBoxScore?: number;
	"pctBoxScore[gte]"?: number;
	"pctBoxScore[lte]"?: number;
	pctBallTracking?: number;
	"pctBallTracking[gte]"?: number;
	"pctBallTracking[lte]"?: number;
	pctPlayerTracking?: number;
	"pctPlayerTracking[gte]"?: number;
	"pctPlayerTracking[lte]"?: number;
	oaa?: number;
	"oaa[gte]"?: number;
	"oaa[lte]"?: number;
	oaaCentered?: number;
	"oaaCentered[gte]"?: number;
	"oaaCentered[lte]"?: number;
	opportunities?: number;
	"opportunities[gte]"?: number;
	"opportunities[lte]"?: number;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
};

export type TStatsPlayerRangeAmaOppsQueryParams = {
	position?: string;
	"position[in]"?: string;
	opportunitiesPerFullSeason?: number;
	"opportunitiesPerFullSeason[gte]"?: number;
	"opportunitiesPerFullSeason[lte]"?: number;
	meanOaa?: number;
	"meanOaa[gte]"?: number;
	"meanOaa[lte]"?: number;
	sdOaa?: number;
	"sdOaa[gte]"?: number;
	"sdOaa[lte]"?: number;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
};

export const fetchStatsPlayerFielding = (queryParams: TStatsPlayerFieldingGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TStatsPlayerFieldingGetQueryParams, Array<IStatsPlayerFielding>>(
		"/stats_player_fielding",
		queryParams,
		cancelToken
	);

export const fetchStatsPlayerFieldingOpps = (
	queryParams: TStatsPlayerFieldingOppsGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TStatsPlayerFieldingOppsGetQueryParams, IStatsPlayerFieldingOpps>(
		"/stats_player_fielding_opps",
		queryParams,
		cancelToken
	);

export const fetchStatsPlayerRangeAmaByLevel = (
	queryParams: TStatsPlayerRangeAmaByLevelQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TStatsPlayerRangeAmaByLevelQueryParams, Array<IStatsPlayerRangeAmaByLevel>>(
		"/stats_player_range_ama_bylevel",
		queryParams,
		cancelToken
	);

export const fetchStatsPlayerRangeAmaOpps = (
	queryParams: TStatsPlayerRangeAmaOppsQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TStatsPlayerRangeAmaOppsQueryParams, Array<IStatsPlayerRangeAmaOpps>>(
		"/stats_player_range_ama_opps",
		queryParams,
		cancelToken
	);
