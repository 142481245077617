import dayjs, { Dayjs } from "dayjs";

import { TFormFieldValidationRule, TFormValue } from "_react/shared/forms/_types";

// TODO: Expand this to support more than just strings?
export const VALIDATION_RULES: TFormFieldValidationRule[] = [
	{
		code: "NUMBER",
		message: "Must be a number",
		validate: (value: TFormValue) => typeof value === "string" && !isNaN(Number(value))
	},
	{
		code: "INTEGER",
		message: "Must be an integer",
		validate: (value: TFormValue) =>
			typeof value === "string" &&
			!isNaN(Number(value)) &&
			parseInt(value, 10) == Number(value) && // eslint-disable-line eqeqeq
			!isNaN(parseInt(value, 10))
	},
	{
		code: "FUTURE",
		message: "Must be a date in the future",
		validate: (value: TFormValue) => dayjs().isBefore(value as Dayjs, "day")
	},
	{
		code: "TODAY_OR_FUTURE",
		message: "Must be today or a date in the future",
		validate: (value: TFormValue) =>
			dayjs()
				.subtract(1, "day")
				.isBefore(value as Dayjs, "day")
	},
	{
		code: "TWITTER_CHARACTER_LIMIT",
		message: "Must be 280 characters or less",
		validate: (value: TFormValue) => typeof value === "string" && value.length <= 280
	},
	{
		code: "WORD_LIMIT_50",
		message: "Must be 50 words or less",
		validate: (value: TFormValue) => typeof value === "string" && value.split(" ").length <= 50
	}
];

export const VALIDATION_DICTIONARY: { [index: string]: TFormFieldValidationRule } = VALIDATION_RULES.reduce(
	(dictionary, rule) => ({ ...dictionary, [rule.code]: rule }),
	{}
);
