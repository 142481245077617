import React from "react";
import { TAnswer } from "_react/dpl/_types";
import { IconStyle, LoadingStyle } from "_react/dpl/_styles";
import CheckCircle from "_react/shared/legacy/ui/icons/CheckCircle";
import Cancel from "_react/shared/legacy/ui/icons/Cancel";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";

/*
	SURVEY
*/

export const isBoolean = (answer: TAnswer) => answer.type === "boolean";

export const isCheckbox = (answer: TAnswer) => answer.isJson && answer.type === "checkbox";

export const isTable = (answer: TAnswer) =>
	answer.isJson && answer.questionAnswer && ["matrixdynamic", "matrixdropdown"].includes(answer.type);

export const isMatrix = (answer: TAnswer) => answer.isJson && answer.questionAnswer && answer.type === "matrix";

export const isMultipleText = (answer: TAnswer) =>
	answer.isJson && answer.questionAnswer && answer.type === "multipletext";

export const getRowName = (answer: TAnswer, rowId: string) => {
	const rowName = answer.rows.filter(row => row.rowId === rowId).map(row => row.rowName);
	return (rowName.length > 0 ? rowName[0] : rowId).replace("Row ", "");
};

export const getItemName = (answer: TAnswer, itemId: string) =>
	answer.items.filter(item => item.itemId === itemId).map(item => item.itemName);

/*
	SURVEY BUTTON
*/

export const getCheckbox = (loading: boolean, checked: boolean, isAll = false) => {
	return loading ? (
		<CircularProgress style={LoadingStyle} />
	) : isAll ? null : checked ? (
		<CheckCircle fill="white" style={IconStyle} />
	) : (
		<Cancel fill="white" style={IconStyle} />
	);
};
