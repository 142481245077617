import React, { useEffect, useState, CSSProperties } from "react";

import { CELL_STYLE, TABLE_STYLE } from "_react/css";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { advPropComparator } from "_react/shared/legacy/tables/AutoFitTable";

import { IContract as IContractMajors } from "_react/shared/_types/schema/contracts";
import { IContractYear, IContractOptionYear } from "_react/shared/_types/schema/contracts";
import { CONTRACT_YEARS_COLUMNS } from "_react/playerpage/contract/_helpers";
import { ContractYearsTr } from "_react/playerpage/contract/mj/ContractYearsTr";
import { getCellStyle } from "_react/playerpage/contract/_styles";

type TContractYearsTableStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

type TColumn = {
	title: string;
	name: string;
	width?: number;
	getCellValue?: Function;
};

export type TContractYearsTableProps = {
	contract: IContractMajors;
	colorScheme?: ColorSchemeGroup;
	style?: TContractYearsTableStyle;
};

export const ContractYearsTable = ({ contract, colorScheme = defaultColorScheme, style }: TContractYearsTableProps) => {
	const columns = CONTRACT_YEARS_COLUMNS();
	const [sortState, setSortState] = useState("desc");
	const [sortColumn, setSortColumn] = useState("Year");
	const [sortedRows, setSortedRows] = useState([...contract.contract_mj_years, ...contract.contract_mj_option_years]);

	const sortTable = (header: string, direction: string) => {
		const headerObj = columns.find((entry: TColumn) => entry.title === header);
		if (headerObj != null) {
			const rowsSorted = [...contract.contract_mj_years, ...contract.contract_mj_option_years];
			if (direction === "asc") {
				rowsSorted?.sort(advPropComparator(headerObj));
			} else {
				rowsSorted?.sort(advPropComparator(headerObj)).reverse();
			}
			setSortState(direction);
			setSortColumn(header);
			setSortedRows(rowsSorted);
		}
	};

	// TODO change this, just imitating AutoFitTable
	useEffect(() => {
		sortTable(sortColumn, sortState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contract.contract_mj_years, contract.contract_mj_option_years]);

	return (
		<Card style={style?.card}>
			<ColoredCardHeader
				colorScheme={colorScheme.secondary}
				text={"Contract Years"}
				style={style?.cardHeader}
			></ColoredCardHeader>
			<table style={{ ...TABLE_STYLE, border: "none" }}>
				<thead>
					<tr>
						{columns.map((column: TColumn, index: number) => (
							<th
								key={index}
								onClick={() => {
									sortTable(
										column.title,
										column.title === sortColumn ? (sortState === "asc" ? "desc" : "asc") : "desc"
									);
								}}
								style={{
									...CELL_STYLE,
									...getCellStyle(index, columns.length, column.width)
								}}
							>
								{column.title}
								{column.title === sortColumn
									? sortState === "asc"
										? String.fromCharCode(9650)
										: String.fromCharCode(9660)
									: undefined}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{sortedRows?.map((year: IContractYear | IContractOptionYear, rowIndex: number) => (
						<ContractYearsTr
							key={`${year.year}-${rowIndex}`}
							contract={contract}
							year={year}
							rowIndex={rowIndex}
							style={{ cardHeader: style?.cardHeader }}
						/>
					))}
				</tbody>
			</table>
		</Card>
	);
};
