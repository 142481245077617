export const LEVEL_4YR = "4YR";
export const LEVEL_JC = "JC";
export const LEVEL_HS = "HS";
export const LEVEL_NS = "NS";

export const NAME_NS = "No School";

export const PLAYER_CLASSIFICATION_PRO = "PRO";
export const PLAYER_CLASSIFICATION_AMA = "AMA";
export const PLAYER_CLASSIFICATION_INTL = "INTL";
