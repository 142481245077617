import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import { round10 } from "_react/shared/_helpers/numbers";
import { IPrimaryStatProps } from "_react/shared/ui/presentation/components/stat/PrimaryStat/PrimaryStat";
import { ISecondaryStatProps } from "_react/shared/ui/presentation/components/stat/SecondaryStat/SecondaryStat";
import {
	FORMAT_CURRENCY_MILLIONS,
	FORMAT_PERCENTAGE,
	SM
} from "_react/shared/ui/presentation/components/stat/shared/_constants";
import StatCard from "_react/shared/ui/presentation/components/stat/StatCard/StatCard";
import DollarSign from "_react/shared/ui/icons/DollarSign";
import { IBlendedSurplusValue } from "_react/shared/data_models/surplus_value/_types";
import { TAllSurplusValues } from "_react/shared/data_models/surplus_value/_types";
import {
	TOOLTIP_SURPLUS_VALUE,
	TOOLTIP_PROJ_SURPLUS_VALUE,
	TOOLTIP_PRO_MODEL_SURPLUS_VALUE,
	TOOLTIP_PRO_MODEL_WEIGHT
} from "_react/shared/_constants/tooltips";
import {
	NULL_FILLER_TEXT_PROJECTIONS_TAB,
	SV_COLOR_GRADIENT,
	SV_COLOR_GRADIENT_TEXT
} from "_react/playerpage/_constants";

import createSurplusValueStatCardMachine, {
	TSurplusValueStatCardContext,
	SET_PLAYER_ID,
	SET_SURPLUS_VALUES,
	FETCHING_SURPLUS_VALUES
} from "_react/shared/ui/data/cards/SurplusValueStatCard/_machine";

export type TSurplusValueStatCardData = {
	surplusValues?: TAllSurplusValues | null;
	isLoading?: boolean;
};

type TSurplusValueSectionProps = {
	playerId?: number;
	shouldFetchData?: boolean;
	data?: TSurplusValueStatCardData;
	style?: React.CSSProperties;
};

const SurplusValueStatCard = ({ playerId, data, shouldFetchData = true, style }: TSurplusValueSectionProps) => {
	const toast = useToast();
	const [current, send] = useMachine(createSurplusValueStatCardMachine(playerId, shouldFetchData, data, toast));
	const { surplusValues } = current.context as TSurplusValueStatCardContext;

	const fetchingSurplusValues = current.matches(FETCHING_SURPLUS_VALUES);
	const isLoading = shouldFetchData ? fetchingSurplusValues : data?.isLoading;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.surplusValues !== surplusValues && !shouldFetchData) {
			send({ type: SET_SURPLUS_VALUES, data: data?.surplusValues });
		}
	}, [data?.surplusValues, shouldFetchData, send, surplusValues]);

	const currentBlendedSurplusValue = surplusValues?.blendedSurplusValues.find(
		(sv: IBlendedSurplusValue) => sv.isCurrent
	);

	const primaryStatSv: IPrimaryStatProps = {
		label: "SV",
		value: round10(currentBlendedSurplusValue?.blendedSv, -1),
		nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
		colorGradientInfo: SV_COLOR_GRADIENT_TEXT,
		format: FORMAT_CURRENCY_MILLIONS,
		isLoading: isLoading
	};
	const secondaryStatsSv: Array<ISecondaryStatProps> = [
		{
			label: "Proj SV",
			value: round10(currentBlendedSurplusValue?.simSvTotal, -1),
			nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
			format: FORMAT_CURRENCY_MILLIONS,
			colorGradientInfo: SV_COLOR_GRADIENT,
			size: SM,
			tooltipLabel: TOOLTIP_PROJ_SURPLUS_VALUE,
			isLoading: isLoading
		},
		{
			label: "Pro Mod SV",
			value: round10(currentBlendedSurplusValue?.xSv, -1),
			nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
			format: FORMAT_CURRENCY_MILLIONS,
			colorGradientInfo: SV_COLOR_GRADIENT,
			size: SM,
			tooltipLabel: TOOLTIP_PRO_MODEL_SURPLUS_VALUE,
			isLoading: isLoading
		},
		{
			label: "Pro Mod Weight",
			value: currentBlendedSurplusValue
				? 1 - (currentBlendedSurplusValue?.percentSim ?? 0)
				: currentBlendedSurplusValue,
			nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
			format: FORMAT_PERCENTAGE,
			size: SM,
			tooltipLabel: TOOLTIP_PRO_MODEL_WEIGHT,
			isLoading: isLoading
		}
	];
	return (
		<>
			<StatCard
				statIcon={<DollarSign color="gray.600" boxSize={6} />}
				primaryStat={primaryStatSv}
				secondaryStats={secondaryStatsSv}
				tooltipLabel={TOOLTIP_SURPLUS_VALUE}
				style={style}
			/>
		</>
	);
};

export default SurplusValueStatCard;
