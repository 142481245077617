import { AxiosStatic, AxiosInstance } from "axios";

import axios from "_redux/_utils/_axios";
import axiosInference from "_redux/_utils/_axios_inference";

export const SLICE_PIE = "pie";
export const SLICE_INFERENCE = "inference";

export const AXIOS_SLICES = {
	[SLICE_PIE]: axios,
	[SLICE_INFERENCE]: axiosInference
} as { [key: string]: AxiosStatic | AxiosInstance };
