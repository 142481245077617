import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "_react/shared/legacy/ui/IconButton";
import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { Dialog } from "_react/shared/legacy/ui/Dialog";
import HighlightOff from "_react/shared/legacy/ui/icons/HighlightOff";
import { sortByLevel } from "_redux/StatsAggregated/_helpers";
import {
	WHITE_COLOR_SCHEME,
	HeaderDiv,
	HEADER_ICON_STYLE,
	ContentDiv,
	StyledHeaderCell,
	StyledTable,
	StyledTableRow,
	MIN_WIDTH_IDEAL,
	StyledBodyCell
} from "_react/stats/shared/_styles";
import { useIsMobile } from "_react/_hooks";

const StatsAggregateDialog = ({
	data,
	headers,
	aggregateFunc,
	aggregateOn,
	aggregateFor,
	selectedIndices,
	onClose,
	open
}) => {
	const handleClose = () => {
		onClose();
	};

	const isMobile = useIsMobile();

	const sortedIndices = selectedIndices.slice().sort((a, b) => a - b);
	let upper = sortedIndices[sortedIndices.length - 1];
	if (upper) {
		for (let i = upper + 1; i < data.length; i++) {
			if (!data[i].isNestedRow) break;
			upper = i;
		}
	}
	const selectedData = sortedIndices.map(ind => data[ind]);

	const subsets = {};
	const seasonLevels = {};
	// TODO separate data into subsets based on aggregateOn. Figure out how to separate distingusihing between nested and not nested
	// Also only aggregate on the aggregateFor array if provided
	for (const entry of selectedData) {
		if (entry) {
			if (seasonLevels.hasOwnProperty(`${entry.season}`)) {
				seasonLevels[entry.season].push(entry.level);
			} else {
				seasonLevels[entry.season] = [entry.level];
			}
			const aggOnEntry = entry[aggregateOn];
			if (aggOnEntry != null) {
				if (!aggregateFor || aggregateFor.includes(aggOnEntry)) {
					if (subsets.hasOwnProperty(aggOnEntry)) {
						subsets[aggOnEntry].push(entry);
					} else {
						subsets[aggOnEntry] = [entry];
					}
				}
			}
		}
	}
	const subsetAggregates = [];
	Object.values(subsets).forEach(subset => {
		const subsetAggregate = aggregateFunc(subset);
		subsetAggregate[aggregateOn] = subset[0][aggregateOn];
		subsetAggregates.push(subsetAggregate);
	});
	subsetAggregates.sort(sortByLevel);

	let title = null;
	if (aggregateOn === "level") {
		title = "";
		for (const [season, levels] of Object.entries(seasonLevels)) {
			levels.sort(sortByLevel).reverse();
			title = `${title}${season}(${levels.join(", ")})`;
			title = `${title}, `;
		}
		title = title.slice(0, -2);
	}

	const totalAggregate = aggregateFunc(selectedData);

	const Header = (
		<HeaderDiv>
			{title}
			<IconButton colorScheme={WHITE_COLOR_SCHEME} onClick={handleClose} style={HEADER_ICON_STYLE}>
				<HighlightOff fill={defaultColorScheme.primary.color} />
			</IconButton>
		</HeaderDiv>
	);

	return (
		<Dialog
			colorScheme={defaultColorScheme.primary}
			dataCy="agg-dialog"
			header={Header}
			onClose={handleClose}
			isBackgroundDisabled={false}
			open={open}
			noActions
		>
			<ContentDiv title="aggregate-dialog">
				<StyledTable minWidth={isMobile ? undefined : MIN_WIDTH_IDEAL}>
					<thead>
						<tr>
							{headers.map((entry, idx) => (
								<StyledHeaderCell key={idx}>{entry.name}</StyledHeaderCell>
							))}
						</tr>
					</thead>
					<tbody>
						{subsetAggregates.map((subsetAggregate, idx) => {
							return (
								<StyledTableRow key={idx}>
									{headers.map((col, colIdx) => {
										const { format, value, key } = col;
										let val = subsetAggregate.hasOwnProperty(key) ? subsetAggregate[key] : "-";
										if (format && subsetAggregate.hasOwnProperty(key)) {
											val = format(subsetAggregate[key]);
										} else if (value) {
											val = isNaN(value(subsetAggregate)) ? "-" : value(subsetAggregate);
										}

										return <StyledBodyCell key={colIdx}>{val}</StyledBodyCell>;
									})}
								</StyledTableRow>
							);
						})}
						<StyledTableRow backgroundColor={"#C3C3C3"}>
							{headers.map((col, colIdx) => {
								const { format, value, key } = col;
								let val = totalAggregate.hasOwnProperty(key) ? totalAggregate[key] : "-";
								if (format && totalAggregate.hasOwnProperty(key)) {
									val = format(totalAggregate[key]);
								} else if (value) {
									val = isNaN(value(totalAggregate)) ? "-" : value(totalAggregate);
								}

								return <StyledBodyCell key={colIdx}>{val}</StyledBodyCell>;
							})}
						</StyledTableRow>
					</tbody>
				</StyledTable>
			</ContentDiv>
		</Dialog>
	);
};

StatsAggregateDialog.defaultProps = {
	aggregateFor: null,
	aggregateOn: null,
	aggregateSorter: null,
	data: [],
	groupConsecutive: true,
	ignoreNested: null,
	nestedRows: false,
	open: false,
	selectedIndices: [],
	titleKey: null
};
StatsAggregateDialog.propTypes = {
	aggregateFor: PropTypes.array,
	aggregateFunc: PropTypes.func,
	aggregateOn: PropTypes.string,
	// TODO implement this so the rows in the aggregate dialog can be sorted if desired (eg. by "LEVEL" in the stats table)
	// aggregateSorter: PropTypes.func,
	data: PropTypes.array,
	headers: PropTypes.array.isRequired,
	nestedRows: PropTypes.bool,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	selectedIndices: PropTypes.array
};

export default StatsAggregateDialog;
