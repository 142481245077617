import React from "react";

export const getColorFromClassification = type => {
	if (type === "PRO") return "#53B672";
	else if (type === "AMA") return "#f1d602";
	else if (type === "INTL") return "#9765B4";
	return "";
};

export const getPlayerClassificationTag = (playerType, style = {}) => {
	return (
		<div
			style={{
				backgroundColor: getColorFromClassification(playerType),
				color: "white",
				borderRadius: "2px",
				padding: "2px",
				fontSize: "10px",
				fontWeight: "bold",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				...style
			}}
		>
			<span>{playerType}</span>
		</div>
	);
};
