import React, { FC, useState, useEffect, useCallback } from "react";

import { sortBy } from "utils/helpers";
import { IconButtonMenu } from "_react/shared/legacy/ui/IconButtonMenu";
import { IconButtonStyle, IconSvgStyle } from "_react/playerpage/header/AgentOrAgencyField/_styles";
import { AgentOrAgencyPopupInfo } from "_react/playerpage/header/AgentOrAgencyField/PopupInfo";
import { agent } from "_react/shared/_types/mesa/agent";
import { agency } from "_react/shared/_types/mesa/agency";
import axios from "_redux/_utils/_axios";
import { BASE_URL } from "utils/redux_constants";
import {
	TPlayerType,
	TPlayerInfoType,
	INFO_ROWS,
	invalidAgentOrAgency
} from "_react/playerpage/header/AgentOrAgencyField/_helpers";

type AgentOrAgencyPopupProps = {
	playerType: TPlayerType;
	infoKey: "agent" | "agency";
	infoId: string | number;
	org?: string;
};

export const AgentOrAgencyPopup: FC<AgentOrAgencyPopupProps> = ({ playerType, infoKey, infoId, org }) => {
	const [open, setOpen] = useState(false);
	const [infoObject, setInfoObject] = useState<agent | agency | null>(null);
	const [isFetchingInfoObj, setIsFetchingInfoObj] = useState<boolean>(false);
	const [isFailedInfoObj, setIsFailedInfoObj] = useState<boolean>(false);
	const [players, setPlayers] = useState<TPlayerInfoType[]>([]);
	const [infoPlayers, setInfoPlayers] = useState<{ text: string; active: boolean }[]>([]);
	const [isFetchingPlayers, setIsFetchingPlayers] = useState<boolean>(false);
	const [isFailedPlayers, setIsFailedPlayers] = useState<boolean>(false);

	const fetchInfoObject = useCallback(() => {
		if (invalidAgentOrAgency(infoId)) return;
		setIsFailedInfoObj(false);
		setIsFetchingInfoObj(true);
		axios
			.get(
				playerType === "pro" && infoKey === "agent"
					? `${BASE_URL}/${infoKey}?ebisAgentId=${infoId}`
					: `${BASE_URL}/${infoKey}/${infoId}`
			)
			.then(response => (response.data[0] ? setInfoObject(response.data[0]) : setIsFailedInfoObj(true)))
			.catch(() => setIsFailedInfoObj(true))
			.finally(() => setIsFetchingInfoObj(false));
	}, [setIsFailedInfoObj, setIsFetchingInfoObj, setInfoObject, infoKey, infoId, playerType]);
	useEffect(() => {
		if (open && !isFetchingInfoObj && !isFailedInfoObj && infoObject == null) {
			fetchInfoObject();
		}
		if (!open) {
			setIsFailedInfoObj(false);
		}
	}, [open, isFetchingInfoObj, isFailedInfoObj, infoObject, fetchInfoObject, setIsFailedInfoObj]);

	const fetchAgentOrAgencyPlayers = useCallback(() => {
		if (invalidAgentOrAgency(infoId)) return;
		setIsFailedPlayers(false);
		setIsFetchingPlayers(true);
		axios
			.get(
				infoKey === "agent"
					? `${BASE_URL}/agent/${infoId}/player?agentType=${playerType}`
					: `${BASE_URL}/agency/${infoId}/player?agencyType=${playerType}`
			)
			.then(response => {
				const updatedPlayers = [];
				const playersByType: { [key: string]: TPlayerInfoType[] } = response.data;
				for (const playerList of Object.values(playersByType)) {
					updatedPlayers.push(...playerList);
				}
				setPlayers(updatedPlayers);
			})
			.catch(() => setIsFailedPlayers(true))
			.finally(() => setIsFetchingPlayers(false));
	}, [infoId, infoKey, playerType]);
	useEffect(() => {
		if (open && !isFetchingPlayers && !isFailedPlayers && !players.length) {
			fetchAgentOrAgencyPlayers();
		}
		if (!open) {
			setIsFailedPlayers(false);
		}
	}, [open, fetchAgentOrAgencyPlayers, setIsFailedPlayers, isFetchingPlayers, isFailedPlayers, players]);

	useEffect(() => {
		if (players.length) {
			const sortedPlayers = sortBy(players, [
				(p: TPlayerInfoType) => (p.lastName ? p.lastName.toUpperCase() : null),
				(p: TPlayerInfoType) => (p.firstName ? p.firstName.toUpperCase() : null)
			]);
			setInfoPlayers(
				sortedPlayers.map((player: TPlayerInfoType) => ({
					text: `${player.firstName || ""} ${player.lastName || ""}`,
					active: player.active != null ? player.active === 1 : true
				}))
			);
		}
	}, [players]);

	return (
		<IconButtonMenu
			onClick={() => setOpen(!open)}
			onClose={() => setOpen(false)}
			open={open}
			buttonStyle={IconButtonStyle}
			svgStyle={IconSvgStyle}
			popupMaxHeight={600}
			iconFill={"white"}
		>
			<AgentOrAgencyPopupInfo
				isFailedPlayers={isFailedPlayers}
				isFetchingPlayers={isFetchingPlayers}
				isFailedInfoObj={isFailedInfoObj}
				isFetchingInfoObj={isFetchingInfoObj}
				infoId={infoId}
				infoKey={infoKey}
				infoObject={infoObject}
				infoPlayers={infoPlayers}
				infoRows={INFO_ROWS[infoKey]}
				org={org}
			/>
		</IconButtonMenu>
	);
};
