import React from "react";
import { Tooltip } from "@chakra-ui/react";

import { round10 } from "_react/shared/_helpers/numbers";
import { getColorGradientFunctionBlueRed, getColorGradientFunctionBlueGreen } from "_react/shared/dataviz/_helpers";
import { getAgeFromBirthDate } from "utils/helpers";
import { SV_COLOR_GRADIENT_FUNCTION } from "_react/playerpage/_constants";
import { IBlendedSurplusValue } from "_react/shared/data_models/surplus_value/_types";
import { VALUE_TYPE_NUMBER } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";
import {
	TOOLTIP_POSITION_PLAYER_RAR,
	TOOLTIP_RP_RAR,
	TOOLTIP_SP_RAR,
	TOOLTIP_SURPLUS_VALUE
} from "_react/shared/_constants/tooltips";

import { TSimpleHitterProjections, TSimplePitcherProjections } from "_react/shared/data_models/projections/_types";
import { calculateSpRarFromRaa, calculateRpRarFromRaa } from "_react/projections/pro/_helpers";

//
// Text
//

export const REPLACEMENT_LEVEL_DEFINITION =
	"The talent threshold at which players are freely available on the open market (via waivers, minor league free agency, or league minimum major league deals). Our projections attempt to estimate a player's talent level relative to that replacement level benchmark.";
export const POSITIONAL_ADJUSTMENT_DEFINITION =
	"Each position has an implicit run value defined by the offensive expectations at that position, which also serve as a proxy for the relative scarcity of that position on the open market. Higher positional adjustments imply that it is harder to find a player at that position at any given offensive talent level.";

//
// Query Params
//

export const VIEW = "view";

//
// Views
//

export const HITTER_VIEW = "hitter";
export const PITCHER_VIEW = "pitcher";

// Cancel sources

export const SIMPLE_HITTER_PROJECTIONS_CANCEL_SOURCE = "simpleHitterProjections";
export const SIMPLE_PITCHER_PROJECTIONS_CANCEL_SOURCE = "simplePitcherProjections";

//
// Columns for hitter and pitcher leaderboards
//

export const [COLOR_GRADIENT_FUNCTION_BLUE_RED] = getColorGradientFunctionBlueRed(-50, 50);

export const [COLOR_GRADIENT_FUNCTION_BLUE_GREEN] = getColorGradientFunctionBlueGreen(-50, 50);

export const NULL_VALUE = null;

export const PLAYER_NAME_COLUMN = {
	value: "playerName",
	label: "Player",
	isMobile: true,
	getValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) => (
		// Remove this tooltip if the simple projections tables ever switch from `bam_id` to `mesa.player.id`
		<Tooltip
			hasArrow
			placement="top"
			label={`Could not match BAM ID ${projection.bamId} to combined player ID.`}
			isDisabled={projection.player != null}
		>
			{`${projection.player?.lastName ?? NULL_VALUE}, ${projection.player?.firstName ?? NULL_VALUE}`}
		</Tooltip>
	),
	sortFunction: (
		a: TSimpleHitterProjections | TSimplePitcherProjections,
		b: TSimpleHitterProjections | TSimplePitcherProjections
	) =>
		`${a.player?.lastName ?? NULL_VALUE} ${a.player?.firstName ?? NULL_VALUE}`.localeCompare(
			`${b.player?.lastName ?? NULL_VALUE} ${b.player?.firstName ?? NULL_VALUE}`,
			undefined,
			{ sensitivity: "accent" }
		),
	onClickFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections, _index: number) => {
		window.open(`../../player?philId=${projection.player?.playerProId}&view=summary`, "_blank");
	}
};

export const ORG_COLUMN = {
	value: "org",
	label: "Org",
	isMobile: true,
	getValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) =>
		projection.player?.team?.parentOrgCode ?? NULL_VALUE
};

export const LEVEL_COLUMN = {
	value: "level",
	label: "Level",
	getValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) => (
		<TeamLevelBadge level={projection.player?.team?.levelRel?.value} />
	),
	getSortValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) =>
		projection.player?.team?.levelRel?.sortOrder ?? NULL_VALUE
};

export const AGE_COLUMN = {
	value: "age",
	label: "Age",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) =>
		projection.player?.birthDate ? getAgeFromBirthDate(projection.player?.birthDate) : NULL_VALUE
};

const POSITION_COLUMN = {
	value: "position",
	label: "Position",
	getValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) =>
		projection.player?.positionRelationship?.label ?? NULL_VALUE
};

export const BATS_COLUMN = {
	value: "bats",
	label: "Bats",
	getValueFunction: (projection: TSimpleHitterProjections) => projection.player?.bats ?? NULL_VALUE
};

export const THROWS_COLUMN = {
	value: "throws",
	label: "Throws",
	getValueFunction: (projection: TSimplePitcherProjections) => projection.player?.throws ?? NULL_VALUE
};

export const MLS_COLUMN = {
	value: "mls",
	label: "MLS",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) =>
		projection.player?.proProfile?.mlsCurrent
			? `${projection.player?.proProfile?.mlsCurrent < 10 ? "  " : ""}${round10(
					projection.player?.proProfile?.mlsCurrent,
					-3
			  )}`
			: "  0.000"
};

export const OPTIONS_COLUMN = {
	value: "options",
	label: "Options",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) =>
		`${projection.player?.proProfile?.optionsUsed != null ? projection.player.proProfile.optionsUsed : "-"}/${
			projection.player?.proProfile?.optionsTotal != null ? projection.player.proProfile.optionsTotal : "-"
		}`
};

export const SV_COLUMN = {
	value: "sv",
	label: "SV",
	tooltip: TOOLTIP_SURPLUS_VALUE,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) =>
		projection?.player?.blendedSurplusValues?.find((sv: IBlendedSurplusValue) => sv.isCurrent)?.blendedSv != null
			? `$${round10(
					projection.player.blendedSurplusValues.find((sv: IBlendedSurplusValue) => sv.isCurrent)?.blendedSv,
					-1
			  )}M`
			: NULL_VALUE,
	getSortValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) =>
		projection?.player?.blendedSurplusValues?.find((sv: IBlendedSurplusValue) => sv.isCurrent)?.blendedSv != null
			? projection?.player?.blendedSurplusValues?.find((sv: IBlendedSurplusValue) => sv.isCurrent)?.blendedSv ??
			  NULL_VALUE
			: NULL_VALUE,
	colorGradientFunction: SV_COLOR_GRADIENT_FUNCTION,
	getColorGradientValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) =>
		projection?.player?.blendedSurplusValues?.find((sv: IBlendedSurplusValue) => sv.isCurrent)?.blendedSv != null
			? projection?.player?.blendedSurplusValues?.find((sv: IBlendedSurplusValue) => sv.isCurrent)?.blendedSv
			: NULL_VALUE
};

export const TOTAL_BATTING_RAR_COLUMN = {
	value: "totalBattingRar",
	label: "Total RAR",
	abbreviation: "RAR",
	tooltip: TOOLTIP_POSITION_PLAYER_RAR,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (projection: TSimpleHitterProjections) =>
		projection.totalBattingRar != null ? round10(projection.totalBattingRar, -1) : NULL_VALUE,
	getSortValueFunction: (projection: TSimpleHitterProjections) =>
		projection.totalBattingRar != null ? projection.totalBattingRar : NULL_VALUE,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN,
	style: {
		td: {
			borderLeft: "4px double #6f6f6f",
			borderRight: "4px double #6f6f6f"
		}
	}
};

export const BATTING_RAA_COLUMN = {
	value: "battingRaa",
	label: "Hit RAA",
	tooltip: "Projected hitting runs above average (RAA) per 600 PAs (139 G).",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (projection: TSimpleHitterProjections) =>
		projection.battingRaa != null ? round10(projection.battingRaa, -1) : NULL_VALUE,
	getSortValueFunction: (projection: TSimpleHitterProjections) =>
		projection.battingRaa != null ? projection.battingRaa : NULL_VALUE,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
};

export const BASERUNNING_RAA_COLUMN = {
	value: "baserunningRaa",
	label: "Run RAA",
	tooltip: "Projected baserunning runs above average (RAA) per 600 PAs (139 G).",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (projection: TSimpleHitterProjections) =>
		projection.baserunningRaa != null ? round10(projection.baserunningRaa, -1) : NULL_VALUE,
	getSortValueFunction: (projection: TSimpleHitterProjections) =>
		projection.baserunningRaa != null ? projection.baserunningRaa : NULL_VALUE,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
};

export const DEF_RAA_COLUMN = {
	value: "defRaa",
	label: "Field RAA",
	tooltip: "Projected fielding runs above average (RAA) per 139 G.",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (projection: TSimpleHitterProjections) =>
		projection.defRaa != null ? round10(projection.defRaa, -1) : NULL_VALUE,
	getSortValueFunction: (projection: TSimpleHitterProjections) =>
		projection.defRaa != null ? projection.defRaa : NULL_VALUE,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
};

export const POS_ADJ_COLUMN = {
	value: "posAdj",
	label: "Pos Adj",
	tooltip: "Projected positional runs above\naverage (RAA) per 139 G.",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (projection: TSimpleHitterProjections) =>
		projection.posAdj != null ? round10(projection.posAdj, -1) : NULL_VALUE,
	getSortValueFunction: (projection: TSimpleHitterProjections) =>
		projection.posAdj != null ? projection.posAdj : NULL_VALUE,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
};

export const SP_RAR_COLUMN = {
	value: "spRar",
	label: "SP RAR",
	tooltip: TOOLTIP_SP_RAR,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (projection: TSimplePitcherProjections) =>
		projection.projSpRaa != null && projection.replacementLevelActive != null
			? round10(calculateSpRarFromRaa(projection.projSpRaa, projection.replacementLevelActive), -1)
			: NULL_VALUE,
	getSortValueFunction: (projection: TSimplePitcherProjections) =>
		projection.projSpRaa != null && projection.replacementLevelActive != null
			? calculateSpRarFromRaa(projection.projSpRaa, projection.replacementLevelActive)
			: NULL_VALUE,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN,
	style: {
		td: {
			borderLeft: "4px double #6f6f6f"
		}
	}
};

export const RP_RAR_COLUMN = {
	value: "rpRar",
	label: "RP RAR",
	tooltip: TOOLTIP_RP_RAR,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (projection: TSimplePitcherProjections) =>
		projection.projRpRaa != null && projection.replacementLevelActive != null
			? round10(calculateRpRarFromRaa(projection.projRpRaa, projection.replacementLevelActive), -1)
			: NULL_VALUE,
	getSortValueFunction: (projection: TSimplePitcherProjections) =>
		projection.projRpRaa != null && projection.replacementLevelActive != null
			? calculateRpRarFromRaa(projection.projRpRaa, projection.replacementLevelActive)
			: NULL_VALUE,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
};

export const HITTER_LEADERBOARD_COLUMNS = [
	PLAYER_NAME_COLUMN,
	ORG_COLUMN,
	LEVEL_COLUMN,
	AGE_COLUMN,
	POSITION_COLUMN,
	BATS_COLUMN,
	MLS_COLUMN,
	OPTIONS_COLUMN,
	SV_COLUMN,
	TOTAL_BATTING_RAR_COLUMN,
	BATTING_RAA_COLUMN,
	DEF_RAA_COLUMN,
	BASERUNNING_RAA_COLUMN,
	POS_ADJ_COLUMN
];

export const PITCHER_LEADERBOARD_COLUMNS = [
	PLAYER_NAME_COLUMN,
	ORG_COLUMN,
	LEVEL_COLUMN,
	AGE_COLUMN,
	THROWS_COLUMN,
	MLS_COLUMN,
	OPTIONS_COLUMN,
	SV_COLUMN,
	SP_RAR_COLUMN,
	RP_RAR_COLUMN
];
