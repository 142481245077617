import React from "react";

import { apiUptimeCheck, loggedInCheck } from "_react/status/_network";

export const checkStatus = (
	checking: React.MutableRefObject<boolean>,
	checkingDeep: React.MutableRefObject<boolean>,
	setUp: (up: boolean) => void,
	setUpDeep: (up: boolean) => void
) => {
	checking.current = true;
	checkingDeep.current = true;
	apiUptimeCheck()
		.then((result: boolean) => {
			checking.current = false;
			setUp(result);
		})
		.catch(() => {
			checking.current = false;
			setUp(false);
		});
	apiUptimeCheck(true)
		.then((result: boolean) => {
			checkingDeep.current = false;
			setUpDeep(result);
		})
		.catch(() => {
			checkingDeep.current = false;
			setUpDeep(false);
		});
};

export const checkLoggedIn = (
	checkingLoggedIn: React.MutableRefObject<boolean>,
	setLoggedIn: (up: boolean) => void
) => {
	checkingLoggedIn.current = true;
	loggedInCheck()
		.then((result: boolean) => {
			checkingLoggedIn.current = false;
			setLoggedIn(result);
		})
		.catch(() => {
			checkingLoggedIn.current = false;
			setLoggedIn(false);
		});
};
