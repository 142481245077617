import React from "react";

import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";

import { Transactions } from "_react/playerpage/transaction/Transactions";

type TTransactionsTabProps = {
	player: TPlayerPageCombinedPlayer;
};

const TransactionsTab = ({ player }: TTransactionsTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	return <Transactions colorScheme={chakraColorSchemeGroup} player={player} />;
};

export default TransactionsTab;
