import React from "react";

import { SquareBadge } from "_react/shared/legacy/ui/SquareBadge";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { BadgeRow } from "_react/playerpage/header/badges";
import { getProIntlAvgOfp } from "_react/playerpage/shared_js/evals/_helpers";

type TIntlBadgesProps = {
	colorSchemeGroup: ColorSchemeGroup;
	evaluations?: $TSFixMeEval[] | null;
	player: TPlayerPageCombinedPlayer;
};

export default function IntlBadges({ colorSchemeGroup, evaluations, player }: TIntlBadgesProps) {
	return (
		<div>
			<BadgeRow>
				<SquareBadge colorSchemeGroup={colorSchemeGroup} label={"Country"} content={player.birthCountry} />
				<SquareBadge
					colorSchemeGroup={colorSchemeGroup}
					label={"OFP"}
					loading={evaluations == null}
					content={getProIntlAvgOfp(evaluations, "INTL")}
				/>
			</BadgeRow>
			<BadgeRow>
				<SquareBadge
					colorSchemeGroup={colorSchemeGroup}
					label={"Bats/Throws"}
					content={`${player.bats}/${player.throws}`}
				/>
				<SquareBadge
					colorSchemeGroup={colorSchemeGroup}
					label={"Reports"}
					loading={evaluations == null}
					content={evaluations?.length}
				/>
			</BadgeRow>
		</div>
	);
}
