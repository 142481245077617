import React, { useEffect, useMemo } from "react";
import { useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import { HStack, VStack, Box } from "@chakra-ui/react";
import dayjs from "dayjs";
import { scaleSequential, interpolateMagma } from "d3";

import PitchMetricHexbinPlot from "_react/shared/ui/presentation/plots/PitchMetricHexbinPlot/PitchMetricHexbinPlot";
import { IIntrinsicValue } from "_react/shared/data_models/inference/_types";
import {
	BATS_OVERALL,
	BATS_R,
	BATS_L,
	THROWS_R,
	THROWS_L,
	THROWS_OVERALL,
	MIN_INTRINSIC_RUNS_VALUE,
	MAX_INTRINSIC_RUNS_VALUE
} from "_react/shared/data_models/inference/_constants";
import { TMarginsProps } from "_react/shared/dataviz/_types";

import createPitcherIntrinsicValueHexbinPlotMachine, {
	TPitcherIntrinsicValueHexbinPlotContext,
	SET_INTRINSIC_VALUE,
	SET_PLAYER_ID,
	FETCHING_INTRINSIC_VALUE,
	SET_SEASON_FILTER
} from "_react/shared/ui/data/plots/PitcherIntrinsicValueHexbinPlot/_machine";

export type TPitcherIntrinsicValueHexbinPlotData = {
	intrinsicValues?: Array<IIntrinsicValue> | null;
	isLoading?: boolean;
};

type TPitcherIntrinsicValueHexbinPlotStyle = {
	container?: React.CSSProperties;
};

type TPitcherIntrinsicValueHexbinPlotProps = {
	title?: string;
	plotType?: "hexbin" | "scatter";
	playerId?: number;
	seasonFilter?: number;
	data?: TPitcherIntrinsicValueHexbinPlotData;
	shouldFetchData?: boolean;
	batsFilter?: string;
	throwsFilter?: string;
	countSplitFilter?: string;
	pitchTypeFilter?: string;
	size?: { value: number; dimension: "width" | "height" };
	hexbinRadius?: number;
	margins?: TMarginsProps;
	style?: TPitcherIntrinsicValueHexbinPlotStyle;
};

// Lower values are better, so we want to invert the color scale
const customColorScale = (minValue: number, maxValue: number) => {
	const colorScale = scaleSequential<string>()
		.interpolator(interpolateMagma)
		.domain([minValue, maxValue]);

	return colorScale;
};

const PitcherIntrinsicValueHexbinPlot = ({
	title,
	plotType = "hexbin",
	playerId,
	seasonFilter = dayjs().year(),
	data,
	shouldFetchData = true,
	batsFilter = BATS_OVERALL,
	throwsFilter,
	countSplitFilter,
	pitchTypeFilter,
	size,
	hexbinRadius,
	margins,
	style
}: TPitcherIntrinsicValueHexbinPlotProps) => {
	const toast = useToast();
	const [current, send] = useMachine(
		createPitcherIntrinsicValueHexbinPlotMachine(seasonFilter, playerId, shouldFetchData, data, toast)
	);
	const { intrinsicValues } = current.context as TPitcherIntrinsicValueHexbinPlotContext;

	const fetchingProbabilityDensities: boolean = current.matches(FETCHING_INTRINSIC_VALUE);

	const isLoading: boolean = shouldFetchData ? fetchingProbabilityDensities : data?.isLoading ?? false;

	// Update machine context when data prop changes
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_SEASON_FILTER, data: seasonFilter });
	}, [seasonFilter, send]);

	useEffect(() => {
		if (data?.intrinsicValues !== intrinsicValues && !shouldFetchData) {
			send({ type: SET_INTRINSIC_VALUE, data: data?.intrinsicValues });
		}
	}, [data?.intrinsicValues, intrinsicValues, shouldFetchData, send]);

	const filteredIntrinsicValues = useMemo(() => {
		// The data is already fetched by playerId and season
		// Filter by the rest of the possible combinations
		return intrinsicValues?.filter((iv: IIntrinsicValue) => {
			return (
				// Filter by `bats`
				(batsFilter === undefined ||
					batsFilter === BATS_OVERALL ||
					(batsFilter === BATS_R && iv.batsRight) ||
					(batsFilter === BATS_L && !iv.batsRight)) &&
				// Filter by `throws`
				(throwsFilter === undefined ||
					throwsFilter === THROWS_OVERALL ||
					(throwsFilter === THROWS_R && iv.throwsRight) ||
					(throwsFilter === THROWS_L && !iv.throwsRight)) &&
				// Filter by `countSplit`
				(countSplitFilter === undefined || countSplitFilter === iv.countSplit) &&
				// Filter by `pitchType`
				(pitchTypeFilter === undefined || pitchTypeFilter === iv.pitchType)
			);
		});
	}, [intrinsicValues, batsFilter, throwsFilter, countSplitFilter, pitchTypeFilter]);

	return (
		<>
			{isLoading && <Box className="loading-item" height="60" width="44" sx={style?.container} />}
			{!isLoading && (
				<VStack align="start">
					<HStack justifyContent="space-between" width="100%">
						{title && (
							<Box fontFamily="heading" fontSize="md" fontWeight="bold">
								{title}
							</Box>
						)}
					</HStack>
					<PitchMetricHexbinPlot<IIntrinsicValue>
						pitchData={filteredIntrinsicValues}
						xValue="locationX"
						yValue="locationZ"
						colorScaleValue="intrinsicRuns"
						colorScale={{
							minValue: MIN_INTRINSIC_RUNS_VALUE,
							maxValue: MAX_INTRINSIC_RUNS_VALUE,
							isOverrideDistributionExtrema: true
						}}
						customColorScaleFunction={customColorScale}
						plotType={plotType}
						showHomeplate
						showStrikeZone
						xAxis={{ extrema: { min: -2, max: 2, isOverrideDistributionExtrema: true } }}
						yAxis={{ extrema: { min: 0, max: 5, isOverrideDistributionExtrema: true } }}
						size={size}
						hexbinRadius={hexbinRadius}
						margins={margins}
						backgroundColor={"transparent"}
					/>
				</VStack>
			)}
		</>
	);
};

export default PitcherIntrinsicValueHexbinPlot;
