import styled from "@emotion/styled";

import { TPartialStyles } from "utils/tsutils";
import { colorSchemeFactory } from "_react/shared/legacy/ui/Colors";
import { CELL_STYLE, HEADER_STYLE } from "_react/css";

// aggregate dialog styles
export const WHITE_COLOR_SCHEME = colorSchemeFactory("white");

export const HeaderDiv = styled.div({ display: "flex", justifyContent: "space-between" });

export const HEADER_ICON_STYLE = { width: 28, height: 28 };

export const ContentDiv = styled.div({
	boxSizing: "border-box",
	marginLeft: 5,
	marginRight: 5,
	marginTop: 15,
	marginBottom: 5,
	display: "flex",
	justifyContent: "flex-start"
});

export const MIN_WIDTH_IDEAL = 775;

export const StyledTable = styled.table<TPartialStyles<"minWidth">>(({ minWidth }) => ({
	// width: "96%",
	borderCollapse: "collapse",
	fontSize: ".75em",
	tableLayout: "auto",
	border: "2px solid black",
	boxSizing: "border-box",
	marginLeft: 15,
	marginRight: 15,
	marginBottom: 10,
	minWidth
}));

export const StyledHeaderCell = styled.th({
	borderRight: "2px solid gray",
	boxSizing: "border-box",
	...HEADER_STYLE
});

export const StyledTableRow = styled.tr<TPartialStyles<"backgroundColor">>(({ backgroundColor }) => ({
	backgroundColor: backgroundColor ?? "white",
	height: 27,
	borderBottom: "0px solid black",
	...CELL_STYLE,
	"&:hover": {
		backgroundColor: backgroundColor ?? "#FFF472"
	}
}));

export const StyledBodyCell = styled.td({
	borderRight: "2px solid gray",
	borderBottom: "0px solid black"
});
