import React from "react";
import PropTypes from "prop-types";

import FormGroupHeader from "_react/forms/FormGroupHeader";
import FormSection from "_react/forms/FormSection";
import { isMobile } from "utils/_helpers";

class FormGroup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editing: props.editing,
			error: ""
		};

		this.triggerSave = this.triggerSave.bind(this);
		// this.handleEditClick = this.handleEditClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleEditClick = this.handleEditClick.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.editing !== prevProps.editing) {
			this.setState({ editing: this.props.editing });
		}
	}

	sections = [];

	triggerSave = () => {
		let success = true;
		for (let i = 0; i < this.sections.length; i++) {
			if (this.sections[i]) {
				if (!this.sections[i].triggerSave()) success = false;
			}
		}
		// if (success) this.setState({ editing: false });
		return success;
	};

	// handleEditClick = () => {
	// 	const editing = !this.state.editing;
	// 	this.setState({ editing });
	// 	if (this.props.updateEditing) {
	// 		this.props.updateEditing(editing);
	// 	}
	// };

	handleChange = value => {
		this.props.handleChange(value);
	};

	handleEditClick = () => {
		this.setState({
			editing: !this.state.editing
		});
	};

	render() {
		const { colorScheme, editable, configuration, dataSource, autosave, autosaveTimer, windowWidth } = this.props;
		const { editing } = this.state;

		this.sections = [];
		// Check if only labels
		let onlyLabels = true;
		if (configuration.sections != null) {
			for (let i = 0; i < configuration.sections.length; i++) {
				for (let ii = 0; ii < configuration.sections[i].objects.length; ii++) {
					if (configuration.sections[i].objects[ii].type !== "label") onlyLabels = false;
				}
			}
		}

		if (configuration.hasOwnProperty("componentOnly") && configuration.componentOnly) {
			return configuration.displayComponent;
		}

		return (
			<div
				style={{
					border: `2px solid ${colorScheme.group.borderColor}`,
					borderRadius: colorScheme.group.borderRadius,
					padding: "2px",
					paddingTop: "15px",
					backgroundColor: colorScheme.group.backgroundColor,
					marginLeft: !isMobile(windowWidth) ? "5px" : "-12px",
					marginRight: !isMobile(windowWidth) ? "5px" : "-12px",
					marginTop: !isMobile(windowWidth) ? "5px" : "-15px",
					marginBottom: !isMobile(windowWidth) ? "10px" : "0px",
					flex: configuration.partialWidth,
					...configuration.containerStyle
				}}
			>
				{configuration.hasOwnProperty("includeHeader") && configuration.includeHeader ? null : (
					<FormGroupHeader
						colorScheme={colorScheme}
						editable={editable && !onlyLabels}
						editing={editing}
						handleEditClick={this.handleEditClick}
						startAdornment={configuration.startAdornment}
						style={configuration.headerStyle}
						title={configuration.title}
						triggerSave={this.triggerSave}
						windowWidth={windowWidth}
					/>
				)}
				<div
					style={{
						display:
							isMobile(windowWidth) || configuration.sectionDirection === "vertical" ? "block" : "flex",
						alignItems: "top",
						justifyContent: "space-between",
						position: "relative",
						breakInside: "avoid",
						...configuration.style
					}}
				>
					{configuration.sections != null &&
						(configuration.displayComponent == null || editing) &&
						configuration.sections.map((section, idx) => {
							if (section.show === false) return null;
							return (
								<FormSection
									autosave={autosave}
									autosaveTimer={autosaveTimer}
									colorScheme={colorScheme}
									configuration={section}
									dataSource={dataSource}
									editing={editing}
									handleChange={this.handleChange}
									key={idx}
									labelWidth={configuration.labelWidth}
									ref={node => this.sections.push(node)}
									windowWidth={windowWidth}
								/>
							);
						})}
					{configuration.displayComponent != null &&
						(!editing || configuration.sections == null) &&
						configuration.displayComponent}
				</div>
			</div>
		);
	}
}

FormGroup.propTypes = {
	autosave: PropTypes.bool,
	autosaveTimer: PropTypes.number,
	colorScheme: PropTypes.object,
	configuration: PropTypes.object,
	dataSource: PropTypes.object,
	editable: PropTypes.bool,
	editing: PropTypes.bool,
	handleChange: PropTypes.func,
	updateEditing: PropTypes.func,
	windowWidth: PropTypes.number
};

FormSection.displayName = "FormSection";

export default FormGroup;
