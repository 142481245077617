export const DISTRIBUTION_X = "value";
export const DISTRIBUTION_Y = "density";

export const GRADE_DISTRIBUTION_PLOT_PROPS = {
	backgroundColor: "transparent",
	distributionX: DISTRIBUTION_X,
	distributionY: DISTRIBUTION_Y,
	margins: { top: 0, right: 10, bottom: 15, left: 10 }
};

export const PLOT_STAT_LABEL = "RA9";

export const PITCH_STUFF_PLOT_WIDTH = 56;
export const PITCH_STUFF_PLOT_GAP = 4;

export const PLOT_TYPE_AVERAGE = "average";
export const PLOT_TYPE_PROBABILITY_DENSITY = "probability_density";
