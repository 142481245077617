import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
	Box,
	Flex,
	HStack,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Portal,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Spacer,
	Text,
	Tooltip,
	VStack,
	useToast
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { isDefaultFilters, DEFAULT_STATS_TABLE_FILTERS } from "_react/shared/ui/data/tables/shared/Filters";
import { getMinAndMaxSeason, getSeasonFilters, updateFilters } from "_react/shared/_helpers/stats";
import { VALID_PRO_LEVELS } from "_react/shared/data_models/stats/_constants";
import {
	IStatsPlayerPitching,
	IStatsPlayerPitchingByTeam,
	IStatsPlayerPitchingByTeamLkLevel
} from "_react/shared/data_models/stats/_types";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import {
	GAME_TYPE_FILTER_TO_GAME_TYPE_MAP,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_SPRING_TRAINING,
	NUM_DISPLAY_SEASONS,
	PITCHER_PERFORMANCE_COLUMNS
} from "_react/shared/ui/data/tables/PitcherPerformanceTable/_constants";
import {
	aggregatePitcherPerformanceDataRows,
	appendAgeToStatsPlayerPitching,
	appendAgeToStatsPlayerPitchingByTeam,
	dedupePlayerStatsPlayerPitchingBySource,
	dedupePlayerStatsPlayerPitchingByTeamAndSource,
	getLevelsFromRow
} from "_react/shared/ui/data/tables/PitcherPerformanceTable/_helpers";
import PitcherPerformanceTableMachine, {
	FETCHING_AVERAGE_AGES,
	FETCHING_PITCHER_PERFORMANCE_PLAYER,
	FETCHING_STATS_PLAYER_PITCHING,
	FETCHING_STATS_PLAYER_PITCHING_BYTEAM,
	SET_AVERAGE_AGES,
	SET_FILTERS,
	SET_PLAYER_ID,
	SET_PITCHER_PERFORMANCE_PLAYER,
	SET_STATS_PLAYER_PITCHING,
	SET_STATS_PLAYER_PITCHING_BYTEAM,
	TPitcherPerformanceTableContext
} from "_react/shared/ui/data/tables/PitcherPerformanceTable/_machine";
import {
	ICombinedStatsPlayerPitching,
	TPitcherPerformanceRow,
	TPitcherPerformanceTableData
} from "_react/shared/ui/data/tables/PitcherPerformanceTable/_types";

type TPitcherPerformanceTableStyle = {
	container?: React.CSSProperties;
	tableContainer?: React.CSSProperties;
};

type TPitcherPerformanceTableProps = {
	title?: string;
	playerId?: number;
	data?: TPitcherPerformanceTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<TPitcherPerformanceRow, keyof TPitcherPerformanceRow>;
	style?: TPitcherPerformanceTableStyle;
};

const PitcherPerformanceTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TPitcherPerformanceTableProps) => {
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);
	const toast = useToast();

	const [current, send] = useMachine(PitcherPerformanceTableMachine(playerIdProp, data, shouldFetchData, toast));

	const isFetchingStatsPlayerPitching = current.matches(FETCHING_STATS_PLAYER_PITCHING);
	const isFetchingStatsPlayerPitchingByTeam = current.matches(FETCHING_STATS_PLAYER_PITCHING_BYTEAM);
	const isFetchingAverageAges = current.matches(FETCHING_AVERAGE_AGES);
	const isFetchingPitcherPerformancePlayer = current.matches(FETCHING_PITCHER_PERFORMANCE_PLAYER);

	const isLoading =
		isFetchingStatsPlayerPitching ||
		isFetchingStatsPlayerPitchingByTeam ||
		isFetchingAverageAges ||
		isFetchingPitcherPerformancePlayer;

	const context = current.context as TPitcherPerformanceTableContext;
	const {
		playerId,
		statsPlayerPitching,
		statsPlayerPitchingByTeam,
		averageAges,
		pitcherPerformancePlayer,
		filters
	} = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.statsPlayerPitching !== statsPlayerPitching && shouldFetchData === false)
			send({ type: SET_STATS_PLAYER_PITCHING, value: data?.statsPlayerPitching });
	}, [send, data?.statsPlayerPitching, statsPlayerPitching, shouldFetchData]);

	useEffect(() => {
		if (data?.statsPlayerPitchingByTeam !== statsPlayerPitchingByTeam && shouldFetchData === false)
			send({ type: SET_STATS_PLAYER_PITCHING_BYTEAM, value: data?.statsPlayerPitchingByTeam });
	}, [send, data?.statsPlayerPitchingByTeam, statsPlayerPitchingByTeam, shouldFetchData]);

	useEffect(() => {
		if (data?.averageAges !== averageAges && shouldFetchData === false)
			send({ type: SET_AVERAGE_AGES, value: data?.averageAges });
	}, [send, data?.averageAges, averageAges, shouldFetchData]);

	useEffect(() => {
		if (data?.pitcherPerformancePlayer !== pitcherPerformancePlayer && shouldFetchData === false)
			send({ type: SET_PITCHER_PERFORMANCE_PLAYER, value: data?.pitcherPerformancePlayer });
	}, [send, data?.pitcherPerformancePlayer, pitcherPerformancePlayer, shouldFetchData]);

	// Pre-process data
	const dedupedStatsPlayerPitching: Array<IStatsPlayerPitching> = useMemo(() => {
		return dedupePlayerStatsPlayerPitchingBySource(statsPlayerPitching ?? []);
	}, [statsPlayerPitching]);

	const dedupedStatsPlayerPitchingWithAge: Array<ICombinedStatsPlayerPitching> = useMemo(() => {
		if (!pitcherPerformancePlayer) return [];
		return appendAgeToStatsPlayerPitching(dedupedStatsPlayerPitching, pitcherPerformancePlayer.birthDate);
	}, [dedupedStatsPlayerPitching, pitcherPerformancePlayer]);

	const dedupedStatsPlayerPitchingByTeam: Array<IStatsPlayerPitchingByTeam> = useMemo(() => {
		return dedupePlayerStatsPlayerPitchingByTeamAndSource(statsPlayerPitchingByTeam ?? []);
	}, [statsPlayerPitchingByTeam]);

	const dedupedStatsPlayerPitchingByTeamWithAge: Array<ICombinedStatsPlayerPitching> = useMemo(() => {
		if (!pitcherPerformancePlayer || !averageAges) return [];
		return appendAgeToStatsPlayerPitchingByTeam(
			dedupedStatsPlayerPitchingByTeam,
			pitcherPerformancePlayer.birthDate,
			averageAges
		);
	}, [dedupedStatsPlayerPitchingByTeam, pitcherPerformancePlayer, averageAges]);

	//
	// Season filter options
	//

	// Get list of seasons using byTeam data so that the min/max seasons have corresponding entries in the table,
	// as a season must have at least one associated team to be displayed
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<ICombinedStatsPlayerPitching>(dedupedStatsPlayerPitchingByTeamWithAge ?? []),
		[dedupedStatsPlayerPitchingByTeamWithAge]
	);
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<IStatsPlayerPitchingByTeamLkLevel> = useMemo(
		() =>
			dedupedStatsPlayerPitchingByTeamWithAge
				.reduce((acc: Array<IStatsPlayerPitchingByTeamLkLevel>, c: ICombinedStatsPlayerPitching) => {
					if (!("teamBam" in c)) return acc;
					const levelRel: IStatsPlayerPitchingByTeamLkLevel | undefined = c.teamBam?.levelRel;
					if (
						levelRel &&
						levelRel.value &&
						!acc.some(val => val.value === levelRel.value) &&
						VALID_PRO_LEVELS.includes(levelRel.value)
					)
						acc.push(levelRel);
					return acc;
				}, [])
				?.sort(
					(a: IStatsPlayerPitchingByTeamLkLevel, b: IStatsPlayerPitchingByTeamLkLevel) =>
						(a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[dedupedStatsPlayerPitchingByTeamWithAge]
	);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map((option: IStatsPlayerPitchingByTeamLkLevel) => option.value)
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	//
	// Filter data for table
	//

	const gameTypeFilters: Array<string> = useMemo(() => {
		return filters.gameTypes.reduce((acc: Array<string>, curr: string) => {
			return [...acc, ...GAME_TYPE_FILTER_TO_GAME_TYPE_MAP[curr]];
		}, []);
	}, [filters.gameTypes]);

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns) return PITCHER_PERFORMANCE_COLUMNS;
		return PITCHER_PERFORMANCE_COLUMNS.filter(
			(col: TColumn<TPitcherPerformanceRow, keyof TPitcherPerformanceRow>) => columns.includes(col.value)
		);
	}, [columns]);

	const filteredStatsPlayerPitching: Array<ICombinedStatsPlayerPitching> | undefined = useMemo(() => {
		if (isLoading || !dedupedStatsPlayerPitchingWithAge) return undefined;
		return dedupedStatsPlayerPitchingWithAge.filter(
			(s: ICombinedStatsPlayerPitching) =>
				s.season <= seasonFilters.maxSeason &&
				s.season >= seasonFilters.minSeason &&
				gameTypeFilters.includes(s.gameType)
		);
	}, [isLoading, seasonFilters, gameTypeFilters, dedupedStatsPlayerPitchingWithAge]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		const availableLevels = levelFilterOptions.map((option: IStatsPlayerPitchingByTeamLkLevel) => option.value);

		return isDefaultFilters(filters, undefined, availableLevels, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, value: DEFAULT_STATS_TABLE_FILTERS });
	}, [send]);

	//
	// Combine data for table
	//

	const combinedTableData: Array<TPitcherPerformanceRow> | undefined = useMemo(() => {
		if (!filteredStatsPlayerPitching) return undefined;
		const combinedData: Array<TPitcherPerformanceRow> = [];
		const uniqueSeasons = [
			...new Set(filteredStatsPlayerPitching.map((s: ICombinedStatsPlayerPitching) => s.season))
		];
		uniqueSeasons.forEach((season: number) => {
			// Create an object with stats player pitching data and all related
			// stats player pitching data by team.

			// For game type combinations and certain game types, we need to aggregate the data into a single row
			const pitcherPerformanceData: Array<ICombinedStatsPlayerPitching> = filteredStatsPlayerPitching.filter(
				(s: ICombinedStatsPlayerPitching) => s.season === season
			);
			const pitcherPerformanceDataByTeam: Array<ICombinedStatsPlayerPitching> =
				dedupedStatsPlayerPitchingByTeamWithAge?.filter(
					(s: ICombinedStatsPlayerPitching) => s.season === season && gameTypeFilters.includes(s.gameType)
				) ?? [];

			// Only one top-level row is returned, so just use those stats
			if (pitcherPerformanceData.length === 1) {
				let combinedParentData = pitcherPerformanceData[0];
				let combinedChildData: Array<TPitcherPerformanceRow> | undefined = pitcherPerformanceDataByTeam.map(
					(s: ICombinedStatsPlayerPitching) => {
						return { pitcherPerformanceData: s };
					}
				);
				// If there is only one child row, then we want to display it as the overall row
				if (pitcherPerformanceDataByTeam && pitcherPerformanceDataByTeam.length === 1) {
					combinedParentData = pitcherPerformanceDataByTeam[0];
					combinedChildData = undefined;
				}
				// If there are no child rows, then the overall row must be invalid, and we should not push data.
				// Therefore, only push the data if the length of the byTeam data is non-zero
				if (pitcherPerformanceDataByTeam && pitcherPerformanceDataByTeam.length > 0)
					combinedData.push({
						pitcherPerformanceData: combinedParentData,
						childData: combinedChildData
					});
			}
			// Multiple top-level rows are returned, aggregate all of the data
			else if (pitcherPerformanceData?.length) {
				// Aggregate pitcher performance data across different game types into a single top-level row
				let combinedParentData = pitcherPerformanceData.reduce(
					(prev: ICombinedStatsPlayerPitching, curr: ICombinedStatsPlayerPitching) => {
						return aggregatePitcherPerformanceDataRows(prev, curr);
					}
				);
				// Aggregate team level pitcher performance data different game types into singular team-level rows
				let combinedChildData: Array<TPitcherPerformanceRow> | undefined = pitcherPerformanceDataByTeam?.reduce(
					(combinedDataByTeam: Array<TPitcherPerformanceRow>, dataByTeam: ICombinedStatsPlayerPitching) => {
						if (!combinedDataByTeam.length) return [{ pitcherPerformanceData: dataByTeam }];
						const combinedDataMatchingTeamIndex = combinedDataByTeam.findIndex(
							(combinedData: TPitcherPerformanceRow) =>
								"teamId" in combinedData.pitcherPerformanceData &&
								"teamId" in dataByTeam &&
								combinedData.pitcherPerformanceData.teamId === dataByTeam.teamId
						);
						if (combinedDataMatchingTeamIndex === -1)
							combinedDataByTeam.push({ pitcherPerformanceData: dataByTeam });
						else {
							const combinedDataMatchingTeamData =
								combinedDataByTeam[combinedDataMatchingTeamIndex].pitcherPerformanceData;
							combinedDataByTeam[combinedDataMatchingTeamIndex] = {
								pitcherPerformanceData: aggregatePitcherPerformanceDataRows(
									combinedDataMatchingTeamData,
									dataByTeam
								)
							};
						}
						return combinedDataByTeam;
					},
					[]
				);
				// If there are no child rows, then the overall row must be invalid, and we should not push data.
				// Therefore, only push the data if the length of the byTeam data is non-zero
				if (!combinedChildData || combinedChildData.length === 0) return;
				// If there is only one child row, then we want to display it as the overall row
				if (combinedChildData && combinedChildData.length === 1) {
					combinedParentData = combinedChildData[0].pitcherPerformanceData;
					combinedChildData = undefined;
				}
				combinedData.push({
					pitcherPerformanceData: combinedParentData,
					childData: combinedChildData
				});
			}
		});
		// Apply level filters
		return combinedData.reduce((acc: TPitcherPerformanceRow[], row: TPitcherPerformanceRow) => {
			const uniqueLevels: Array<string> = getLevelsFromRow(row) as Array<string>;
			const shouldDisplayEntireRow = uniqueLevels.every(level => filters.levels?.includes(level));

			// If all of the levels associated with the row meet the level filters, push the entire row and return early
			if (shouldDisplayEntireRow) {
				acc.push(row);
				return acc;
			}

			// Otherwise, filter the child data and create a new parent row.
			const filteredChildData = row.childData?.filter((c: TPitcherPerformanceRow) =>
				filters.levels?.includes(
					"teamBam" in c.pitcherPerformanceData ? c.pitcherPerformanceData.teamBam?.level ?? "" : ""
				)
			);
			// If exactly one item in child data, that becomes parent row
			if (filteredChildData && filteredChildData?.length === 1)
				acc.push({
					pitcherPerformanceData: filteredChildData[0].pitcherPerformanceData,
					childData: undefined
				});
			// Otherwise, if there multiple items in child data, we must aggregate the child data to create a new parent row
			else if (filteredChildData && filteredChildData?.length > 1) {
				const newParentRow: ICombinedStatsPlayerPitching = filteredChildData.reduce(
					(prev: ICombinedStatsPlayerPitching, curr: TPitcherPerformanceRow, index: number) => {
						if (index === 0) return prev;
						return aggregatePitcherPerformanceDataRows(prev, curr.pitcherPerformanceData);
					},
					filteredChildData[0].pitcherPerformanceData
				);
				// Remove team, level, rel age data from new parent row before pushing
				if ("teamBam" in newParentRow) delete newParentRow.teamBam;
				delete newParentRow.relAge;
				acc.push({
					pitcherPerformanceData: newParentRow,
					childData: filteredChildData
				});
			}
			return acc;
		}, []);
	}, [filters.levels, gameTypeFilters, filteredStatsPlayerPitching, dedupedStatsPlayerPitchingByTeamWithAge]);

	// Filtering
	const handleGameTypeSelect = (value: string) => {
		const newFilters = {
			...filters,
			gameTypes: updateFilters(filters.gameTypes, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
				</HStack>
				{isShowFilters && (
					<Menu closeOnSelect={false} placement="left-start">
						<ButtonGroup
							isAttached
							variant={defaultFiltersSet ? "outline" : "solid"}
							colorScheme={defaultFiltersSet ? undefined : "blue"}
						>
							{!defaultFiltersSet && (
								<IconButton
									aria-label="Close"
									icon={<CloseIcon fill="white" />}
									onClick={resetFilters}
								/>
							)}
							<MenuButton
								as={IconButton}
								aria-label="Options"
								icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
							>
								MenuItem
							</MenuButton>
						</ButtonGroup>
						<Portal>
							<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
								<MenuOptionGroup title="Game Type" type="checkbox" value={filters.gameTypes}>
									<MenuItemOption
										value={GAME_TYPE_SPRING_TRAINING}
										onClick={() => handleGameTypeSelect(GAME_TYPE_SPRING_TRAINING)}
									>
										Spring Training
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_REGULAR_SEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_REGULAR_SEASON)}
									>
										Regular Season
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_POSTSEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_POSTSEASON)}
									>
										Postseason
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuOptionGroup title="Seasons">
									<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
										{minSeason === maxSeason && (
											<Tooltip hasArrow placement="top" label="Only one season of data exists">
												<HStack>
													<OutlineInfo color="gray.500" />
													<Text>{minSeason}</Text>
												</HStack>
											</Tooltip>
										)}
										{minSeason !== maxSeason && (
											<VStack>
												<RangeSlider
													value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
													min={minSeason}
													max={maxSeason}
													step={1}
													onChange={(seasons: number[]) => {
														send({
															type: SET_FILTERS,
															value: {
																...filters,
																minSeason: seasons[0],
																maxSeason: seasons[1]
															}
														});
													}}
													onMouseEnter={() => setShowSeasonRangeTooltip(true)}
													onMouseLeave={() => setShowSeasonRangeTooltip(false)}
												>
													<RangeSliderTrack>
														<RangeSliderFilledTrack bg="black" />
													</RangeSliderTrack>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.minSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
													</Tooltip>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.maxSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
													</Tooltip>
												</RangeSlider>
												<Flex sx={{ width: "100%" }}>
													<Text fontSize="sm">{minSeason}</Text>
													<Spacer />
													<Text fontSize="sm">{maxSeason}</Text>
												</Flex>
											</VStack>
										)}
									</VStack>
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Levels"
									type="checkbox"
									value={filters.levels ?? VALID_PRO_LEVELS}
								>
									{levelFilterOptions.map((option: IStatsPlayerPitchingByTeamLkLevel) => (
										<MenuItemOption
											value={option.value}
											onClick={() => handleLevelSelect(option.value)}
										>
											<TeamLevelBadge level={option.value} />
										</MenuItemOption>
									))}
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
				)}
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<TPitcherPerformanceRow, keyof TPitcherPerformanceRow>
					columns={filteredColumns}
					data={combinedTableData}
					emptyDataDisplayText={"No Pitcher Performance Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					isExpandableRows
					getCustomRowKeyFunction={(row: TPitcherPerformanceRow) => {
						return `${row.pitcherPerformanceData.season}`;
					}}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: ASC
						},
						{
							columnValue: "pitches",
							sortDirection: DESC
						},
						{
							columnValue: "pitchType",
							sortDirection: ASC
						},
						{
							columnValue: "level",
							sortDirection: DESC
						}
					]}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default PitcherPerformanceTable;
