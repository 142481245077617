import React, { CSSProperties } from "react";

import { $TSFixMePlayer } from "utils/tsutils";

import { NULL_TEXT, FB, CH, CB, SL, OTH } from "_react/evals/list/_constants";
import { TEvalIntlPitch } from "_react/evals/list/_types";
import { GradeSection, EvalSection, EvalAttr, EvalLabel, HideStyle } from "_react/evals/list/_styles";
import { getPlayerAge, getRiskFactor, checkProPitchExists, getVelocity } from "_react/evals/list/_helpers";

export type TEvalIntlPitchGradesProps = {
	evaluation: TEvalIntlPitch;
	player: $TSFixMePlayer;
	style?: CSSProperties;
};

export const EvalIntlPitchGrades = ({ evaluation, player, style }: TEvalIntlPitchGradesProps) => {
	return (
		<>
			<GradeSection width={"23%"} inlineFlex>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Age</EvalLabel> {getPlayerAge(player)}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>B/T</EvalLabel> {evaluation.bats ?? player?.bats ?? NULL_TEXT}/
						{evaluation.throws ?? player?.throws ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Risk</EvalLabel> {getRiskFactor(evaluation)}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
			<GradeSection width={"77%"} inlineFlex>
				<EvalSection style={{ ...(checkProPitchExists(evaluation, FB) ? undefined : HideStyle), ...style }}>
					<EvalAttr>
						<EvalLabel>FB</EvalLabel> {evaluation.fb_vel_present ?? NULL_TEXT}/
						{evaluation.fb_vel_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>FBco</EvalLabel> {evaluation.fb_cmd_present ?? NULL_TEXT}/
						{evaluation.fb_cmd_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>FBv</EvalLabel> {getVelocity(evaluation.fb_vel_low, evaluation.fb_vel_high)}
					</EvalAttr>
				</EvalSection>
				<EvalSection style={{ ...(checkProPitchExists(evaluation, CH) ? undefined : HideStyle), ...style }}>
					<EvalAttr>
						<EvalLabel>CH</EvalLabel> {evaluation.ch_present ?? NULL_TEXT}/
						{evaluation.ch_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>CHco</EvalLabel> {evaluation.ch_cmd_present ?? NULL_TEXT}/
						{evaluation.ch_cmd_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>CHv</EvalLabel> {getVelocity(evaluation.ch_vel_low, evaluation.ch_vel_high)}
					</EvalAttr>
				</EvalSection>
				<EvalSection style={{ ...(checkProPitchExists(evaluation, CB) ? undefined : HideStyle), ...style }}>
					<EvalAttr>
						<EvalLabel>CB</EvalLabel> {evaluation.cb_present ?? NULL_TEXT}/
						{evaluation.cb_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>CBco</EvalLabel> {evaluation.cb_cmd_present ?? NULL_TEXT}/
						{evaluation.cb_cmd_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>CBv</EvalLabel> {getVelocity(evaluation.cb_vel_low, evaluation.cb_vel_high)}
					</EvalAttr>
				</EvalSection>
				<EvalSection style={{ ...(checkProPitchExists(evaluation, SL) ? undefined : HideStyle), ...style }}>
					<EvalAttr>
						<EvalLabel>SL</EvalLabel> {evaluation.sl_present ?? NULL_TEXT}/
						{evaluation.sl_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>SLco</EvalLabel> {evaluation.sl_cmd_present ?? NULL_TEXT}/
						{evaluation.sl_cmd_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>SLv</EvalLabel> {getVelocity(evaluation.sl_vel_low, evaluation.sl_vel_high)}
					</EvalAttr>
				</EvalSection>
				<EvalSection style={{ ...(checkProPitchExists(evaluation, OTH) ? undefined : HideStyle), ...style }}>
					<EvalAttr>
						<EvalLabel>{evaluation.oth_pitch_type}</EvalLabel> {evaluation.oth_pitch_present ?? NULL_TEXT}/
						{evaluation.oth_pitch_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>{evaluation.oth_pitch_type}co</EvalLabel> {evaluation.oth_cmd_present ?? NULL_TEXT}/
						{evaluation.oth_cmd_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>{evaluation.oth_pitch_type}v</EvalLabel>{" "}
						{getVelocity(evaluation.oth_pitch_vel_low, evaluation.oth_pitch_vel_high)}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
		</>
	);
};
