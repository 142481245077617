import { chakra, VStack, HStack, Box, Flex } from "@chakra-ui/react";

// Chakra Styling

export const ListSectionVStack = chakra(VStack, {
	baseStyle: {
		w: "100%",
		color: "gray.600",
		paddingTop: 6,
		gap: 0
	}
});

export const ListSectionLabelHStack = chakra(HStack, {
	baseStyle: {
		width: "100%"
	}
});

export const ListSectionLabelBox = chakra(Box, {
	baseStyle: {
		color: "gray.700",
		fontSize: "sm",
		fontWeight: "bold"
	}
});

export const ListRowHStack = chakra(HStack, {
	baseStyle: {
		width: "100%",
		justifyContent: "space-between",
		alignItems: "start",
		paddingBottom: 1,
		paddingTop: 1,
		margin: "0 !important"
	}
});

export const ListItemVStack = chakra(VStack, {
	baseStyle: {
		width: "100%",
		alignItems: "start",
		paddingTop: 3,
		margin: "0 !important",
		gap: 0
	}
});

export const ListItemLabelBox = chakra(Box, {
	baseStyle: {
		color: "gray.400",
		fontSize: "xs",
		fontWeight: "medium",
		textTransform: "uppercase"
	}
});

export const ListItemValueFlex = chakra(Flex, {
	baseStyle: {
		alignItems: "center",
		textAlign: "left",
		fontSize: "sm",
		fontWeight: "medium",
		color: "black"
	}
});
