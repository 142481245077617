import React, { FunctionComponent, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { $TSFixMe } from "utils/tsutils";
import { useFetch, usePost } from "utils/url_helpers";
import { useUserData } from "_react/app/AppContents";
import { DisplayOptionType, TagOptionType } from "_react/video/shared/_types";

type VideoTaggerProps = {
	classes?: $TSFixMe;
	handleChange: Function;
	theme?: $TSFixMe;
};

function formatSuggestions(suggestions: Array<TagOptionType>): Array<DisplayOptionType> {
	return suggestions.map(suggestion => {
		const groupName = suggestion.group
			? suggestion.group.group_name
				? suggestion.group.group_name
				: "User Created"
			: "User Created";
		return {
			group_id: suggestion.group_id,
			tag_id: suggestion.tag_id,
			tag_type: suggestion.tag_type,
			tag_name: suggestion.tag_name,
			group: suggestion.group,
			value: groupName + " " + suggestion.tag_name,
			label: groupName + ": " + suggestion.tag_name
		};
	});
}

const VideoTagger: FunctionComponent<VideoTaggerProps> = ({ handleChange }) => {
	const [tagOptions, setTagOptions] = useState<Array<TagOptionType>>();
	const [itemTags, setItemTags] = useState<Array<$TSFixMe>>();

	const { responseData: tagResponseData } = useFetch(`/tags/video_tag`, []);
	const { userId } = useUserData();
	const { responseData: addedTagData, setPath: setAddTagPath, setPostObj: setTagObj } = usePost(null, null, []);

	useEffect(() => {
		setTagOptions(formatSuggestions(tagResponseData));
	}, [tagResponseData]);

	useEffect(() => {
		if (addedTagData.length) {
			const previousTagOptions = tagOptions;
			const userCreatedTags = formatSuggestions(addedTagData);
			const newItemTags = itemTags ? [...itemTags, ...userCreatedTags] : userCreatedTags;
			const newTagOptions = previousTagOptions ? [...previousTagOptions, ...userCreatedTags] : userCreatedTags;
			setItemTags(newItemTags);
			setTagOptions(newTagOptions);
			handleChange(newItemTags);
		}
	}, [addedTagData, handleChange, itemTags, tagOptions]);

	function handleChangeInternal(value: $TSFixMe) {
		setItemTags(value);
		handleChange(value);
	}

	function handleTagCreateInternal(value: string) {
		const newTag = [
			{
				tag_name: value,
				tag_type: "video_tag",
				create_user_id: userId
			}
		];
		setAddTagPath(null);
		setTagObj(newTag);
		setAddTagPath(`/tags/video_tag`);
	}

	return (
		<div>
			<CreatableSelect
				isMulti
				onChange={e => handleChangeInternal(e)}
				onCreateOption={e => handleTagCreateInternal(e)}
				options={tagOptions}
				placeholder="Enter tags"
				styles={{
					control: provided => ({
						...provided,
						minHeight: 24,
						fontSize: 11
					}),
					dropdownIndicator: provided => ({
						...provided,
						padding: 0,
						width: 15
					}),
					menu: provided => ({
						...provided,
						fontSize: 11
					}),
					multiValue: provided => ({
						...provided,
						padding: 0,
						fontSize: 11
					}),
					multiValueLabel: provided => ({
						...provided,
						padding: 3
					}),
					multiValueRemove: provided => ({
						...provided,
						padding: 0
					}),
					valueContainer: provided => ({
						...provided,
						minHeight: 24
					})
				}}
				// This prop no longer exists for react-select
				// textFieldProps={{
				// 	label: "Tags",
				// 	InputLabelProps: {
				// 		shrink: true
				// 	}
				// }}
				value={itemTags}
			/>
		</div>
	);
};

export default VideoTagger;
