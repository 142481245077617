import React, { useState } from "react";

import { $TSFixMe } from "utils/tsutils";
import KeyboardArrowRight from "_react/shared/legacy/ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "_react/shared/legacy/ui/icons/KeyboardArrowLeft";

type TShowHide = {
	children: $TSFixMe;
	handleKeyDown?: ((event: React.KeyboardEvent<HTMLDivElement>) => void) | undefined;
	height: string;
	hoverText: string;
	leftRight: string;
	mouseMoving?: boolean;
	top: string;
	width: string;
};

function ShowHide({ children, handleKeyDown, height, hoverText, leftRight, mouseMoving, top, width }: TShowHide) {
	const [show, setShow] = useState(false);
	const [hover, setHover] = useState(false);

	const toggleShow = () => {
		const switchedShow = !show;
		setShow(switchedShow);
		setHover(false);
		console.log(show);
	};

	const borderRadius = leftRight === "left" ? "0px 8px 8px 0px" : "8px 0px 0px 8px";

	const buttonDivStyle: React.CSSProperties = {
		position: "absolute",
		top: `${top}`,
		[leftRight]: show ? `${width}` : 0,
		width: "25px",
		height: "100px",
		zIndex: 1102,
		backgroundColor: "lightGray",
		borderRadius,
		opacity: mouseMoving ? 1 : 0.25
	};
	const childContDivStyle: React.CSSProperties = {
		position: "absolute",
		top: `${top}`,
		[leftRight]: 0,
		width: `${width}`,
		height: `${height}`,
		zIndex: 1102,
		opacity: 0.85,
		backgroundColor: "lightGray",
		display: show ? "block" : "none"
	};
	const iconStyle: React.CSSProperties = {
		position: "absolute",
		top: "calc(10% + 25px)",
		height: "25px"
	};

	const ShowTag = leftRight === "left" ? KeyboardArrowRight : KeyboardArrowLeft;
	const HideTag = leftRight === "left" ? KeyboardArrowLeft : KeyboardArrowRight;

	return (
		<div onKeyDown={handleKeyDown}>
			<div style={buttonDivStyle}>
				{show ? (
					<HideTag
						onClick={toggleShow}
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}
						style={iconStyle}
					/>
				) : (
					<ShowTag
						onClick={toggleShow}
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}
						style={iconStyle}
					/>
				)}
				<span
					style={{
						display: hover ? "block" : "none",
						color: "black",
						fontSize: "16px",
						top: "calc(10% + 28px)",
						position: "absolute",
						[leftRight]: "25px",
						width: "100px",
						backgroundColor: "lightGray",
						opacity: 0.85,
						textAlign: "center",
						borderRadius
					}}
				>
					{show ? "Hide " + hoverText : "Show " + hoverText}
				</span>
			</div>
			<div style={childContDivStyle}>{children}</div>
		</div>
	);
}

export default ShowHide;
