import React from "react";

import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";

import { Contract } from "_react/playerpage/contract/Contract";

type TContractTabProps = {
	player: TPlayerPageCombinedPlayer;
};

const ContractTab = ({ player }: TContractTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	return <Contract colorScheme={chakraColorSchemeGroup} player={player} />;
};

export default ContractTab;
