import React, { useState, useCallback } from "react";
import { Table, Tbody, Tr, Td, Text } from "@chakra-ui/react";
import { Annotation, Connector, Label } from "@visx/annotation";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { curveLinear } from "@visx/curve";
import { localPoint } from "@visx/event";
import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear, scaleTime } from "@visx/scale";
import { Line, LinePath } from "@visx/shape";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { bisectLeft, NumberValue } from "d3";

import { round10 } from "_react/shared/_helpers/numbers";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import { PITCH_TYPES } from "_react/shared/_constants/pitch_types";

import { DEFAULT_OPACITY, IS_HOVERED_OPACITY, IS_ELSEWHERE_HOVERED_OPACITY } from "_react/shared/dataviz/_constants";
import { getExtent, getPlotDimensions } from "_react/shared/dataviz/_helpers";
import { useDataVizColors, useAxisLabelProps } from "_react/shared/dataviz/_hooks";
import { TAxisExtrema, TTooltipData } from "_react/shared/dataviz/_types";
import { tooltipSubtitleStyle } from "_react/shared/dataviz/_styles";

import {
	parsePitchData,
	convertToNumber
} from "_react/shared/ui/presentation/plots/PitchMetricTimeSeriesPlot/_helpers";
import { TParsedPitchData } from "_react/shared/ui/presentation/plots/PitchMetricTimeSeriesPlot/_types";
import { KeysOfValue } from "_react/shared/_types/generics";

dayjs.extend(utc);

type TPitchMetricTimeSeriesPlotProps<T extends object> = {
	highlightPitchTypes?: Array<TPitchTypes>;
	pitchData?: Array<T>;
	xValue: KeysOfValue<T, number | Date> | string;
	getXValueFunction?: ((obj: T) => Date) | ((obj: T) => number);
	xLabel?: string;
	xTickLabels?: Array<{ label: string; value: number | Date }>;
	tooltipValues?: {
		xValue: KeysOfValue<T, string> | string;
		pitchType: KeysOfValue<T, string> | string;
		yValue: KeysOfValue<T, number | null> | string;
	};
	yValue: KeysOfValue<T, number> | string;
	getYValueFunction?: (obj: T) => number;
	yLabel?: string;
	// Type should be: (value: number) => number | string | undefined
	// But visx throws errors related to the type TickFormatter<NumberValue>
	yTickFormat?: Function;
	pitchTypeValue: KeysOfValue<T, string> | string;
	getPitchTypeFunction?: (obj: T) => string;
	defaultXAxisExtrema?: TAxisExtrema<Date | number>;
	defaultYAxisExtrema?: TAxisExtrema<number>;
	isReverseYAxis?: boolean;
	// TODO: Need to make this responsive to parent but want to wait until parent components are created
	width?: number;
	height?: number;
};

export const PitchMetricTimeSeriesPlot = <T extends object>({
	highlightPitchTypes,
	pitchData,
	xValue,
	getXValueFunction,
	xLabel,
	yValue,
	getYValueFunction,
	pitchTypeValue,
	getPitchTypeFunction,
	yLabel,
	yTickFormat,
	xTickLabels,
	tooltipValues,
	defaultXAxisExtrema,
	defaultYAxisExtrema,
	isReverseYAxis,
	width,
	height
}: TPitchMetricTimeSeriesPlotProps<T>) => {
	// STATE MANAGEMENT
	const [isLineHovered, setIsLineHovered] = useState<string>();
	const [mouseXValue, setMouseXValue] = useState<number | Date>();
	const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<
		TTooltipData<number>
	>();
	const { containerRef, TooltipInPortal } = useTooltipInPortal({
		scroll: true
	});

	// CHART SETUP
	const {
		axisColor,
		backgroundColor,
		dataColorSecondary,
		dataColorPrimaryGray,
		gridStrokeColor,
		pitchTypeColorDict
	} = useDataVizColors();
	const axisLabelProps = useAxisLabelProps();
	const {
		width: WIDTH,
		height: HEIGHT,
		margins: MARGIN,
		innerWidth: INNER_WIDTH,
		innerHeight: INNER_HEIGHT
	} = getPlotDimensions(width ?? 500, height, { left: 50, right: 55, top: 20 });

	// DATA SETUP
	const getXValue = useCallback(
		(datum: T) =>
			(getXValueFunction ? getXValueFunction(datum) : (datum[xValue as keyof T] as unknown)) as number | Date,
		[getXValueFunction, xValue]
	);
	const getYValue = (datum: T) =>
		(getYValueFunction ? getYValueFunction(datum) : (datum[yValue as keyof T] as unknown)) as number | null;
	const getPitchTypeValue = useCallback(
		(datum: T) =>
			(getPitchTypeFunction
				? getPitchTypeFunction(datum)
				: (datum[pitchTypeValue as keyof T] as unknown)) as string,
		[getPitchTypeFunction, pitchTypeValue]
	);

	// TODO: Update these default max/min values when we have requirements for date range
	const [xMin, xMax] = getExtent<Date | number>(
		defaultXAxisExtrema?.min ??
			new Date(
				dayjs()
					.subtract(1, "year")
					.format("YYYY-MM-DD")
			),
		defaultXAxisExtrema?.max ?? new Date(dayjs().format("YYYY-MM-DD")),
		pitchData?.map((datum: T) => getXValue(datum) as Date | number)
	);

	const [yMin, yMax] = getExtent<number>(
		defaultYAxisExtrema?.min ?? 0,
		defaultYAxisExtrema?.max ?? 100,
		pitchData?.map((datum: T) => getYValue(datum) ?? 0)
	);
	const yDomain = isReverseYAxis ? [yMax, yMin] : [yMin, yMax];
	const xScale =
		typeof xMin === "number"
			? scaleLinear({ domain: [xMin, xMax], range: [0, INNER_WIDTH] })
			: scaleTime({ domain: [xMin, xMax], range: [0, INNER_WIDTH] });
	const yScale = scaleLinear({ domain: yDomain, range: [INNER_HEIGHT, 0], nice: true });

	const pitchDataParsed: TParsedPitchData<T>[] = parsePitchData(
		getPitchTypeValue,
		getXValue,
		getYValue,
		isReverseYAxis ?? false,
		pitchData
	);

	// How much space we want between the line labels
	const lineLabelSpacing =
		pitchDataParsed.length > 1 ? yScale(isReverseYAxis ? yMax : yMin) / (pitchDataParsed.length + 1) : 0;

	// INTERACTIVITY
	const handleHover = useCallback(
		(event: React.MouseEvent<SVGElement>) => {
			// Get coordinates of mouse in pixels
			const { x, y } = localPoint(event) || { x: null, y: null };

			if (x != null && pitchData) {
				// Convert x mouse location from pixels to an x value
				const xMouseValue = xScale.invert(x - MARGIN.left);
				setMouseXValue(xMouseValue);
				if (xMouseValue >= xMin && xMouseValue <= xMax) {
					// Convert x value from Date to number, if needed
					const xMouseValueNumber = convertToNumber(xMouseValue);

					// For each pitch type, get the closest pitch value to the left of the x mouse location
					const pitchValues: Array<T> = PITCH_TYPES.reduce(
						(pitchValues: Array<T>, pitchType: TPitchTypes) => {
							const pitchTypeData: Array<T> = pitchData
								.filter((datum: T) => getPitchTypeValue(datum) === pitchType)
								.sort((a: T, b: T) => {
									const aValue = convertToNumber(getXValue(a) as number | Date);
									const bValue = convertToNumber(getXValue(b) as number | Date);
									return aValue - bValue;
								});
							const pitchTypeXValues: Array<number> = pitchTypeData.map((datum: T) => {
								const xValue = getXValue(datum) as number | Date;
								return convertToNumber(xValue);
							});
							// Stopping search at `length - 1` so that it will always return an index value within the array
							const pitchTypeIndex = bisectLeft(
								pitchTypeXValues,
								xMouseValueNumber,
								0,
								pitchTypeXValues.length - 1
							);

							if (
								// Make sure the value actually exists in the data
								pitchTypeData[pitchTypeIndex] &&
								// If the mouse value is the same as the value, show it
								(pitchTypeXValues[pitchTypeIndex] === Math.round(xMouseValueNumber) ||
									// If the value is not the beginning or end of the line, show it
									(0 < pitchTypeIndex && pitchTypeIndex < pitchTypeXValues.length - 1) ||
									(pitchTypeXValues.length > 1 &&
										// If the value is the beginning of the line, only show it if it's less than the mouse value
										// This ensures that when the mouse hovers to the left of the beginning of the line, this value doesn't show up
										((pitchTypeIndex === 0 &&
											pitchTypeXValues[pitchTypeIndex] < xMouseValueNumber) ||
											// If the value is the end of the line, only show it if it's greater than the mouse value
											// This ensures that when the mouse hovers to the right of the end of the line, this value doesn't show up
											(pitchTypeXValues.length - 1 === pitchTypeIndex &&
												pitchTypeXValues[pitchTypeIndex] > xMouseValueNumber))))
							) {
								pitchValues.push(pitchTypeData[pitchTypeIndex]);
							}
							return pitchValues;
						},
						[]
					);

					if (pitchValues.length && tooltipValues) {
						// Title is the x value range based on where the mouse is at
						// For now, we are assuming `tooltipValues.xValue` points to a date string field
						const timeValues = pitchValues.map((datum: T) => {
							const dateString = (datum[
								(tooltipValues.xValue as unknown) as keyof T
							] as unknown) as string;
							const date = new Date(dateString);
							const dateUtc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
							return new Date(dateUtc).getTime();
						});
						const minTime = Math.min(...timeValues);
						const maxTime = Math.max(...timeValues);
						const title =
							minTime === maxTime
								? `${dayjs(maxTime)
										.utc()
										.format("M/D/YY")}`
								: `${dayjs(minTime)
										.utc()
										.format("M/D/YY")}\nto\n${dayjs(maxTime)
										.utc()
										.format("M/D/YY")}`;
						// Subtitle is an object keys by pitch type with its associated y value
						const subtitles: { [index: string]: number } = pitchValues
							.sort((a: T, b: T) => {
								const aValue = ((a[tooltipValues.yValue as keyof T] as unknown) as number | null) ?? 0;
								const bValue = ((b[tooltipValues.yValue as keyof T] as unknown) as number | null) ?? 0;
								return aValue - bValue;
							})
							.reduce((subtitles: { [index: string]: number }, pitch: T) => {
								const pitchType = (pitch[tooltipValues.pitchType as keyof T] as unknown) as string;
								const yValue = (pitch[tooltipValues.yValue as keyof T] as unknown) as number | null;
								if (yValue) subtitles[pitchType] = yValue;
								return subtitles;
							}, {});
						showTooltip({
							tooltipLeft: x,
							tooltipTop: y,
							tooltipData: { subtitles: subtitles, title: title }
						});
					}
				}
			}
		},
		[xMax, xMin, pitchData, xScale, getXValue, MARGIN.left, tooltipValues, showTooltip, getPitchTypeValue]
	);

	const handleMouseLeavePlot = () => {
		setMouseXValue(undefined);
		hideTooltip();
	};

	return (
		// Need a box here because mouseEnter/Leave doesn't work as expected w/ the svg
		<>
			<svg width={WIDTH} height={HEIGHT} ref={containerRef} onMouseLeave={handleMouseLeavePlot}>
				<rect
					x={0}
					y={0}
					width={WIDTH}
					height={HEIGHT}
					fill={"transparent"}
					onMouseMove={(event: React.MouseEvent<SVGElement>) => {
						handleHover(event);
					}}
				/>
				<Group left={MARGIN.left} top={MARGIN.top}>
					<Grid
						xScale={xScale}
						yScale={yScale}
						width={INNER_WIDTH}
						height={INNER_HEIGHT}
						stroke={gridStrokeColor}
						columnTickValues={
							xTickLabels
								? xTickLabels
										.filter(
											(tickLabel: { value: number | Date; label: string }) =>
												tickLabel.value > xMin && tickLabel.value < xMax
										)
										.map((tickLabel: { value: number | Date; label: string }) => tickLabel.value)
								: undefined
						}
					/>
					{mouseXValue != null && mouseXValue >= xMin && mouseXValue <= xMax && (
						<Line
							from={{
								x: xScale(mouseXValue),
								y: 0
							}}
							to={{
								x: xScale(mouseXValue),
								y: INNER_HEIGHT
							}}
							stroke={dataColorPrimaryGray}
							strokeWidth={1}
							strokeOpacity={IS_HOVERED_OPACITY}
							strokeDasharray="6,3"
						/>
					)}
					<AxisBottom
						scale={xScale}
						top={INNER_HEIGHT}
						label={xLabel}
						labelProps={axisLabelProps}
						stroke={axisColor}
						tickStroke={axisColor}
						tickValues={
							xTickLabels
								? xTickLabels
										.filter(
											(tickLabel: { value: number | Date; label: string }) =>
												tickLabel.value > xMin && tickLabel.value < xMax
										)
										.map((tickLabel: { value: number | Date; label: string }) => tickLabel.value)
								: undefined
						}
						tickFormat={
							xTickLabels
								? (v: NumberValue) =>
										xTickLabels.find(
											(tickLabel: { value: number | Date; label: string }) =>
												tickLabel.value === v
										)?.label
								: undefined
						}
					/>
					<AxisLeft
						scale={yScale}
						label={yLabel}
						labelProps={axisLabelProps}
						labelOffset={30}
						stroke={axisColor}
						tickStroke={axisColor}
						tickFormat={
							yTickFormat
								? (value: NumberValue) => (yTickFormat ? yTickFormat(value as number) : undefined)
								: undefined
						}
					/>
					{pitchDataParsed &&
						pitchDataParsed.map((dataByPitchType: TParsedPitchData<T>, index: number) => {
							const pitchType = dataByPitchType.pitchType;
							const isHovered = isLineHovered === pitchType;
							// Whatever amount we need to add to get to the "end" of the plot PLUS however far off the plot we want to go
							const xLabelOffset = xScale(xMax) - xScale(dataByPitchType.xMaxDatum.x) + 20;
							// The array is sorted, so a higher furthest right y value will mean a higher label location
							// (rect top where this value is used is 0, rect bottom is INNER_HEIGHT)
							const desiredYLabelLocation = lineLabelSpacing + index * lineLabelSpacing;
							// How far off the coordinate is from the desired location
							// desiredYLabelLocation is already converted to the correct scale
							const yLabelOffset = desiredYLabelLocation - yScale(dataByPitchType.xMaxDatum.y);
							return (
								<Group className={pitchType} key={pitchType}>
									<LinePath
										className={`${pitchType}`}
										key={`linepath-${pitchType}`}
										curve={curveLinear}
										data={dataByPitchType.data}
										x={(datum: unknown) => xScale(getXValue(datum as T) as Date | number)}
										y={(datum: unknown) => yScale(getYValue(datum as T) as number)}
										stroke={
											highlightPitchTypes && highlightPitchTypes.includes(pitchType)
												? pitchTypeColorDict[pitchType]
												: highlightPitchTypes &&
												  !highlightPitchTypes.includes(pitchType) &&
												  !isHovered
												? dataColorSecondary
												: pitchTypeColorDict[pitchType]
										}
										strokeWidth={2}
										strokeOpacity={
											isHovered
												? IS_HOVERED_OPACITY
												: highlightPitchTypes?.includes(dataByPitchType.pitchType)
												? DEFAULT_OPACITY
												: IS_ELSEWHERE_HOVERED_OPACITY
										}
										shapeRendering="geometricPrecision"
										onMouseEnter={() => {
											setIsLineHovered(dataByPitchType.pitchType);
										}}
										onMouseLeave={() => {
											setIsLineHovered(undefined);
										}}
										onMouseMove={(event: React.MouseEvent<SVGElement>) => {
											handleHover(event);
										}}
									/>
									<Annotation
										x={xScale(dataByPitchType.xMaxDatum.x)}
										y={yScale(dataByPitchType.xMaxDatum.y)}
										dx={xLabelOffset}
										dy={yLabelOffset}
									>
										{/* TODO: Add this color to our semantic tokens once we've finalized it */}
										<Connector type="line" stroke={isHovered ? axisColor : "#D4D4D4"} />
										<Label
											subtitle={dataByPitchType.pitchType}
											subtitleFontWeight={isHovered ? 400 : 300}
											subtitleFontSize={12}
											showAnchorLine={false}
											fontColor={isHovered ? axisColor : "#404040"}
											showBackground={isHovered}
											backgroundFill={"#D4D4D4"}
											horizontalAnchor={isHovered ? "middle" : "start"}
											verticalAnchor="middle"
											backgroundPadding={{ left: 5, right: 5, top: 0, bottom: 0 }}
										/>
									</Annotation>
								</Group>
							);
						})}
				</Group>
			</svg>
			{tooltipOpen && mouseXValue != null && mouseXValue >= xMin && mouseXValue <= xMax && (
				<TooltipInPortal
					// set this to random so it correctly updates with parent bounds
					key={Math.random()}
					top={tooltipTop}
					left={tooltipLeft}
					style={{ ...defaultStyles, backgroundColor: backgroundColor }}
				>
					<Text
						fontWeight="bold"
						fontSize="xs"
						margin="1"
						textAlign="center"
						whiteSpace="pre-line"
						height="10"
					>
						{tooltipData?.title}
					</Text>
					<Table variant="unstyled" size="sm">
						<Tbody>
							{tooltipData?.subtitles &&
								Object.entries(tooltipData.subtitles).map(([pitchType, value]: [string, number]) => (
									<Tr key={pitchType}>
										<Td
											key={pitchType}
											fontWeight={pitchType === isLineHovered ? "bold" : undefined}
											sx={tooltipSubtitleStyle}
										>{`${pitchType}:`}</Td>
										<Td
											key={`${pitchType}-avg`}
											fontWeight={pitchType === isLineHovered ? "bold" : undefined}
											textAlign="right"
											sx={tooltipSubtitleStyle}
										>{`${round10(value, -2)}`}</Td>
									</Tr>
								))}
						</Tbody>
					</Table>
				</TooltipInPortal>
			)}
		</>
	);
};

export default PitchMetricTimeSeriesPlot;
