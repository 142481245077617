import { PITCH_MOVEMENT_GROUP, RELEASE_POINT_GROUP } from "_react/shared/data_models/metric/_constants";
import { TMetricJointProbabilityGroup } from "_react/shared/data_models/metric/_types";

import { DATA_COLOR_PRIMARY_GRAY_RGB, IS_ELSEWHERE_HOVERED_OPACITY } from "_react/shared/dataviz/_constants";
import { TContourPlotProps } from "_react/shared/ui/presentation/plots/PitchMetricContourPlot/_types";

const PITCH_MOVEMENT_GROUP_PLOT_PROPS: TContourPlotProps = {
	xAxisRange: [-30, 30] as [number, number],
	yAxisRange: [-30, 30] as [number, number],
	height: 400,
	width: 425,
	yAxisLabel: "Vertical Break (in.)",
	tickInterval: 10,
	ticks: "outside",
	tickLen: 5,
	nContours: 10
};

const RELEASE_POINT_PLOT_PROPS: TContourPlotProps = {
	xAxisRange: [-5, 5] as [number, number],
	yAxisRange: [0, 8] as [number, number],
	height: 400,
	width: 500,
	yAxisLabel: "Release Height (ft.)",
	tickInterval: 1,
	ticks: "outside",
	tickLen: 5,
	nContours: 8
};

export const CONTOUR_PLOT_PROPS: { [key in TMetricJointProbabilityGroup]: TContourPlotProps } = {
	[PITCH_MOVEMENT_GROUP]: PITCH_MOVEMENT_GROUP_PLOT_PROPS,
	[RELEASE_POINT_GROUP]: RELEASE_POINT_PLOT_PROPS
};

// The height of the top of the mound
export const MOUND_HEIGHT = 10 / 12;
// The portion of the mound that is level/flat
const MOUND_LEVEL_X_MIN = -2.5;
const MOUND_LEVEL_X_MAX = 2.5;
const PLATE_X_MIN = -1;
const PLATE_X_MAX = 1;

const PITCHERS_MOUND_PROPS: Partial<Plotly.ScatterData> = {
	opacity: IS_ELSEWHERE_HOVERED_OPACITY,
	showlegend: false,
	name: "Pitchers Mound",
	hoverinfo: "skip",
	type: "scatter",
	mode: "lines"
};

export const PITCHERS_MOUND_TRACES: Array<Partial<Plotly.ScatterData>> = [
	{
		x: [MOUND_LEVEL_X_MIN, MOUND_LEVEL_X_MAX],
		// Convert inches to feet
		y: [MOUND_HEIGHT, MOUND_HEIGHT],
		line: { color: DATA_COLOR_PRIMARY_GRAY_RGB, width: 2 },
		...PITCHERS_MOUND_PROPS
	},
	{
		// guesstimate of how the mound would slope down - total mound is wider than the plot
		x: [-6, MOUND_LEVEL_X_MIN],
		y: [10 / 24, MOUND_HEIGHT],
		line: { color: DATA_COLOR_PRIMARY_GRAY_RGB, width: 2, shape: "spline" },
		...PITCHERS_MOUND_PROPS
	},
	{
		// guesstimate of how the mound would slope down - total mound is wider than the plot
		x: [6, MOUND_LEVEL_X_MAX],
		// Convert inches to feet
		y: [10 / 24, MOUND_HEIGHT],
		line: { color: DATA_COLOR_PRIMARY_GRAY_RGB, width: 2, shape: "spline" },
		...PITCHERS_MOUND_PROPS
	}
];

export const PITCHERS_PLATE_SHAPE: Partial<Plotly.Shape> = {
	type: "rect",
	xref: "x",
	yref: "y",
	x0: PLATE_X_MIN,
	y0: MOUND_HEIGHT,
	x1: PLATE_X_MAX,
	// Not sure the exact height of the pitcher's plate
	y1: MOUND_HEIGHT + 0.125,
	opacity: IS_ELSEWHERE_HOVERED_OPACITY,
	line: {
		color: DATA_COLOR_PRIMARY_GRAY_RGB,
		width: 1
	}
};
