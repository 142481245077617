import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import { round10 } from "_react/shared/_helpers/numbers";
import { IPro2PredictionsSummary } from "_react/shared/data_models/pro2_predictions/_types";
import { FORMAT_PERCENTAGE } from "_react/shared/ui/presentation/components/stat/shared/_constants";
import { IPrimaryStatProps } from "_react/shared/ui/presentation/components/stat/PrimaryStat/PrimaryStat";
import { ISecondaryStatProps } from "_react/shared/ui/presentation/components/stat/SecondaryStat/SecondaryStat";
import StatCard from "_react/shared/ui/presentation/components/stat/StatCard/StatCard";
import OutlineTrophy from "_react/shared/ui/icons/OutlineTrophy";
import { TOOLTIP_XWAR, TOOLTIP_ML_PERC } from "_react/shared/_constants/tooltips";
import { NULL_FILLER_TEXT_PROJECTIONS_TAB } from "_react/playerpage/_constants";
import { XWAR_COLOR_GRADIENT_TEXT } from "_react/playerpage/pro/shared/_constants";
import createProModelStatCardMachine, {
	TProModelStatCardContext,
	SET_PLAYER_ID,
	SET_PRO_MODEL_PREDICTION,
	FETCHING_PRO_MODEL_PREDICTION
} from "_react/shared/ui/data/cards/ProModelStatCard/_machine";

export type TProModelStatCardData = {
	proModelPrediction?: IPro2PredictionsSummary | null;
	isLoading?: boolean;
};

type TProModelSectionProps = {
	playerId: number;
	data?: TProModelStatCardData;
	shouldFetchData?: boolean;
	style?: React.CSSProperties;
};

const ProModelStatCard = ({ playerId, data, shouldFetchData = true, style }: TProModelSectionProps) => {
	const toast = useToast();
	const [current, send] = useMachine(createProModelStatCardMachine(playerId, shouldFetchData, data, toast));
	const { proModelPrediction } = current.context as TProModelStatCardContext;

	const fetchingProModelPrediction = current.matches(FETCHING_PRO_MODEL_PREDICTION);
	const isLoading = shouldFetchData ? fetchingProModelPrediction : data?.isLoading;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.proModelPrediction !== proModelPrediction && !shouldFetchData) {
			send({ type: SET_PRO_MODEL_PREDICTION, data: data?.proModelPrediction });
		}
	}, [data?.proModelPrediction, shouldFetchData, send, proModelPrediction]);

	const primaryStatProModel: IPrimaryStatProps = {
		label: "xWAR",
		value: round10(proModelPrediction?.xwarRemaining, -1),
		nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
		colorGradientInfo: XWAR_COLOR_GRADIENT_TEXT,
		isLoading: isLoading
	};
	const secondaryStatsProModel: Array<ISecondaryStatProps> = [
		{
			label: "ML%",
			value: round10(proModelPrediction?.mlbProb, -2),
			nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
			format: FORMAT_PERCENTAGE,
			tooltipLabel: TOOLTIP_ML_PERC,
			isLoading: isLoading
		}
	];
	return (
		<>
			{(proModelPrediction || isLoading) && (
				<StatCard
					primaryStat={primaryStatProModel}
					secondaryStats={secondaryStatsProModel}
					statIcon={<OutlineTrophy color="gray.600" boxSize={6} />}
					tooltipLabel={TOOLTIP_XWAR}
					style={style}
				/>
			)}
		</>
	);
};

export default ProModelStatCard;
