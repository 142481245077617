import { useEffect, useState } from "react";
import { useToken } from "@chakra-ui/react";
import { TextProps } from "@visx/text";

import { useFetch } from "utils/url_helpers";
import { TDataVizScale } from "_react/shared/data_models/dataviz/_types";

import { TPitchTypeColorDict } from "_react/shared/dataviz/_types";

export const useDataVizColors = () => {
	// Get colors from Chakra theme
	const [
		axisColor,
		backgroundColor,
		dataColorPrimaryBlue,
		dataColorPrimaryGreen,
		dataColorPrimaryPurple,
		dataColorPrimaryRed,
		dataColorPrimaryYellow,
		dataColorPrimaryGray,
		dataColorSecondary,
		gridStrokeColor,
		colorCH,
		colorFF,
		colorSI,
		colorSL,
		colorSW,
		colorSV,
		colorCT,
		colorCB,
		colorSP,
		colorKN,
		colorOVR
	] = useToken("colors", [
		"axisColor",
		"backgroundColor",
		"dataColorPrimaryBlue",
		"dataColorPrimaryGreen",
		"dataColorPrimaryPurple",
		"dataColorPrimaryRed",
		"dataColorPrimaryYellow",
		"dataColorPrimaryGray",
		"dataColorSecondary",
		"gridStrokeColor",
		"colorCH",
		"colorFF",
		"colorSI",
		"colorSL",
		"colorSW",
		"colorSV",
		"colorCT",
		"colorCB",
		"colorSP",
		"colorKN",
		"colorOVR"
	]);

	const dataColorPrimaryArray: [string, string, string, string, string] = [
		dataColorPrimaryBlue,
		dataColorPrimaryGreen,
		dataColorPrimaryPurple,
		dataColorPrimaryRed,
		dataColorPrimaryYellow
	];

	const pitchTypeColorDict: TPitchTypeColorDict = {
		CH: colorCH,
		FF: colorFF,
		SI: colorSI,
		SL: colorSL,
		SW: colorSW,
		SV: colorSV,
		CT: colorCT,
		CB: colorCB,
		SP: colorSP,
		KN: colorKN,
		OVR: colorOVR,
		All: colorOVR
	};
	return {
		axisColor,
		backgroundColor,
		dataColorPrimaryBlue,
		dataColorPrimaryGreen,
		dataColorPrimaryPurple,
		dataColorPrimaryRed,
		dataColorPrimaryYellow,
		dataColorPrimaryArray,
		dataColorPrimaryGray,
		dataColorSecondary,
		gridStrokeColor,
		pitchTypeColorDict
	};
};

export const useAxisLabelProps = () => {
	const { axisColor } = useDataVizColors();
	const axisLabelProps: Partial<TextProps> = { fill: axisColor, fontSize: 10, textAnchor: "middle" };
	return axisLabelProps;
};

export const useDataVizScale = (name: string) => {
	const [dataVizScale, setDataVizScale] = useState<TDataVizScale>();
	const { responseData: dataVizScales, isSucceeded } = useFetch(`data_viz_scale?name=${name}`);

	useEffect(() => {
		if (isSucceeded) setDataVizScale(dataVizScales?.length ? dataVizScales[0] : null);
	}, [dataVizScales, isSucceeded]);

	return dataVizScale;
};
