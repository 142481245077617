import React, { CSSProperties } from "react";
import { Card, CardBody, CardFooter, Square, HStack, Flex, Tooltip, PlacementWithLogical } from "@chakra-ui/react";

import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";

import PrimaryStat, { IPrimaryStatProps } from "_react/shared/ui/presentation/components/stat/PrimaryStat/PrimaryStat";
import SecondaryStat, {
	ISecondaryStatProps
} from "_react/shared/ui/presentation/components/stat/SecondaryStat/SecondaryStat";
import { TStatSize } from "_react/shared/ui/presentation/components/stat/shared/_types";

type StatCardProps = {
	statIcon?: React.ReactNode;
	primaryStat: IPrimaryStatProps;
	secondaryStats?: Array<ISecondaryStatProps>;
	tooltipLabel?: string;
	tooltipPlacement?: PlacementWithLogical;
	tooltipIcon?: React.ReactNode;
	size?: TStatSize;
	style?: CSSProperties;
};

export const StatCard = ({
	statIcon,
	primaryStat,
	secondaryStats,
	tooltipLabel,
	tooltipPlacement = "top",
	tooltipIcon = <OutlineInfo boxSize="5" />,
	size,
	style
}: StatCardProps) => {
	return (
		<Card borderColor="gray.300" maxWidth="md" w={size} sx={style} variant="outline">
			<CardBody>
				<HStack alignItems="start" justifyContent="space-between">
					<HStack>
						{statIcon && (
							<Square
								bg="gray.100"
								borderWidth="1px"
								borderRadius="md"
								borderColor="gray.300"
								padding="5"
								marginRight="5"
							>
								{statIcon}
							</Square>
						)}
						<PrimaryStat {...primaryStat} />
					</HStack>
					{tooltipLabel && (
						<Tooltip hasArrow placement={tooltipPlacement} label={tooltipLabel} whiteSpace="pre-wrap">
							<Flex align="center" color="gray.500">
								{tooltipIcon}
							</Flex>
						</Tooltip>
					)}
				</HStack>
			</CardBody>
			{secondaryStats?.length && (
				<CardFooter
					bg="gray.100"
					borderTop="1px solid"
					borderColor="gray.300"
					flexWrap="wrap"
					paddingTop="3"
					paddingBottom="3"
					paddingLeft="5"
					paddingRight="5"
					borderBottomLeftRadius="md"
					borderBottomRightRadius="md"
				>
					{secondaryStats.map((secondaryStat: ISecondaryStatProps) => (
						<SecondaryStat
							{...secondaryStat}
							isOnlySecondaryStat={secondaryStats.length === 1}
							key={secondaryStat.key ?? secondaryStat.label}
							style={{
								stat: {
									paddingTop: 2,
									paddingBottom: 2
								}
							}}
						/>
					))}
				</CardFooter>
			)}
		</Card>
	);
};

export default StatCard;
