import dayjs from "dayjs";
import { RouterChildContext } from "react-router";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { transaction } from "_react/shared/_types/mesa/transaction";

import { TTransaction, TTransactionRowSnake } from "_react/playerpage/_types";

export const getFrom = (row: TTransaction) => {
	const preOrg = row.fromOrg ? row.fromOrg : "-";
	const preClub = row.fromTeam ? row.fromTeam : "-";

	return `${preOrg}  / ${preClub}`;
};

export const getTo = (row: TTransaction) => {
	const postOrg = row.toOrg ? row.toOrg : "-";
	const postClub = row.toTeam ? row.toTeam : "-";

	return `${postOrg} / ${postClub}`;
};

export const getTransactionName = (row: TTransaction) => {
	const { transactionName, faElectType } = row;

	let name = transactionName;

	if (faElectType && faElectType !== null) {
		name = `${name} (${faElectType})`;
	}
	return name;
};

export const getTransactionDetails = (row: TTransaction) => {
	const { injury } = row;

	if (injury != null) {
		return `${injury.bodyPart}${injury.bodyPartDetail != null ? ` (${injury.bodyPartDetail})` : ""} - ${
			injury.diagnosis
		}`;
	}
	return "";
};

export const formatFieldNames = (fieldName: string) => {
	const find = "_";
	const re = new RegExp(find, "g");
	fieldName = fieldName.replace(re, " ");
	return fieldName.toUpperCase();
};

const DATE_FIELDS = [
	"date",
	"submitted_date",
	"approved_date",
	"ebislink_update_date",
	"ebislink_cache_date",
	"mesa_load_date"
];
const DONT_SHOW = [
	"mesa_load_date",
	"ebis_class",
	"status",
	"to_team_ebis_id",
	"injury",
	"from_team_info",
	"to_team_info",
	"ebis_id",
	"ebislink_cache_date",
	"ebislink_update_date",
	"is_deleted"
];

export const formatFieldValue = (entry: { name: string; value: string | number }) => {
	if (entry.name === "is_major_league_transaction") return entry.value === 1 ? "Yes" : "No";
	return DATE_FIELDS.includes(entry.name) && entry.value != null
		? dayjs(entry.value).format("MMMM D, YYYY")
		: entry.value;
};

export const TRANSACTIONS_COLUMNS = (philId: number | null) => [
	{ title: "Date", name: "date" },
	{
		title: "Transaction Type",
		name: "transactionName",
		getCellValue: (row: TTransaction) => getTransactionName(row)
	},
	{ title: "From", name: "from", getCellValue: (row: TTransaction) => getFrom(row) },
	{ title: "To", name: "to", getCellValue: (row: TTransaction) => getTo(row) },
	{
		title: "Details",
		getCellValue: (row: TTransaction) => getTransactionDetails(row),
		onCellClick: (row: TTransaction, onRowClick: Function, history: RouterChildContext["router"]["history"]) => {
			if (row.injury != null && philId != null) {
				history.push(
					`/player?philId=${philId}&view=injuries&year=${dayjs(
						row.date !== null ? row.date : undefined
					).year()}&injurySource=EBIS&injuryId=${row.transactionId}&viewClassification=pro`
				);
			} else onRowClick(row);
		}
	}
];

export const TRANSACTION_DETAILS_COLUMNS = (transaction: transaction) => {
	return Object.keys(transaction)
		.filter(
			(colName: string) =>
				!DONT_SHOW.includes(colName) &&
				formatFieldValue({
					name: colName,
					value: transaction[colName as keyof typeof TRANSACTION_DETAILS_COLUMNS]
				}) != null
		)
		.map((colName: string) => {
			return {
				name: formatFieldNames(colName),
				getCellValue: (row: TTransactionRowSnake) =>
					formatFieldValue({ name: colName, value: row[colName as keyof typeof TRANSACTION_DETAILS_COLUMNS] })
			};
		});
};
