import React from "react";

import {
	TOOLTIP_ACTUAL_GROUNDBALL_RATE,
	TOOLTIP_ACTUAL_HOME_RUN_RATE,
	TOOLTIP_ACTUAL_PITCHER_STRIKEOUT_RATE,
	TOOLTIP_ACTUAL_PITCHER_WALK_RATE,
	TOOLTIP_ACTUAL_WOBACON,
	TOOLTIP_EXPECTED_GROUNDBALL_RATE,
	TOOLTIP_EXPECTED_HOME_RUN_RATE,
	TOOLTIP_EXPECTED_PITCHER_STRIKEOUT_RATE,
	TOOLTIP_EXPECTED_PITCHER_WALK_RATE,
	TOOLTIP_EXPECTED_WOBACON
} from "_react/shared/_constants/tooltips";
import { getLevelDisplayFromLevels } from "_react/shared/_helpers/stats";
import {
	GAME_TYPE_OVERALL,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_SPRING_TRAINING,
	BATS_L,
	BATS_OVERALL,
	BATS_R
} from "_react/shared/data_models/baseline_hit_probs/_constants";
import {
	GAME_TYPE_D,
	GAME_TYPE_F,
	GAME_TYPE_L,
	GAME_TYPE_R,
	GAME_TYPE_S,
	GAME_TYPE_W
} from "_react/shared/data_models/stats/_constants";
import { TColumn, TParentColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { formatPercentage } from "_react/shared/ui/presentation/components/stat/shared/_helpers";
import TeamColumn from "_react/shared/ui/data/tables/shared/TeamColumn";

import { TPitcherPaOutcomesRow } from "_react/shared/ui/data/tables/PitcherPaOutcomesTable/_types";
import { formatWobacon, getLevelsFromRow } from "_react/shared/ui/data/tables/PitcherPaOutcomesTable/_helpers";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

export const GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP: Record<string, Array<string>> = {
	[GAME_TYPE_R]: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_F]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_D]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_L]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_W]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_S]: [GAME_TYPE_SPRING_TRAINING]
};

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherPaOutcomesRow) => row.combinedPitcherPaOutcomesData.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherPaOutcomesRow) => (
		<TeamColumn teamBam={row.combinedPitcherPaOutcomesData.team} childRowCount={row.childData?.length} />
	)
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherPaOutcomesRow) =>
		getLevelDisplayFromLevels(getLevelsFromRow(row), NULL_FILLER_TEXT),
	getSortValueFunction: (row: TPitcherPaOutcomesRow) => {
		// Child Rows
		if ("team" in row.combinedPitcherPaOutcomesData)
			return row.combinedPitcherPaOutcomesData.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
		// Parent Rows with nested data - highest level if multiple levels
		if (row.childData && row.childData.length > 1)
			return Math.min(
				...row.childData.map((childRow: TPitcherPaOutcomesRow) => {
					if ("team" in childRow.combinedPitcherPaOutcomesData)
						return (
							childRow.combinedPitcherPaOutcomesData.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER
						);
					return Number.MAX_SAFE_INTEGER;
				})
			);
		return NULL_FILLER_TEXT;
	}
};

export const getBfColumn = (batsFilter: string) => {
	return {
		id: "bf",
		value: "bf",
		label: "BF",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPaOutcomesRow) => {
			return batsFilter === BATS_L
				? row.combinedPitcherPaOutcomesData.tbfVl && row.combinedPitcherPaOutcomesData.tbfVl !== 0
					? row.combinedPitcherPaOutcomesData.tbfVl
					: NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? row.combinedPitcherPaOutcomesData.tbfVr && row.combinedPitcherPaOutcomesData.tbfVr !== 0
					? row.combinedPitcherPaOutcomesData.tbfVr
					: NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? row.combinedPitcherPaOutcomesData.tbf && row.combinedPitcherPaOutcomesData.tbf !== 0
					? row.combinedPitcherPaOutcomesData.tbf
					: NULL_FILLER_TEXT
				: NULL_FILLER_TEXT;
		}
	};
};

export const getKPctColumn = (batsFilter: string) => {
	return {
		id: "kPct",
		value: "kPct",
		label: "Act",
		tooltip: TOOLTIP_ACTUAL_PITCHER_STRIKEOUT_RATE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPaOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPaOutcomesData.kPctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPaOutcomesData.kPctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPaOutcomesData.kPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXKPctColumn = (batsFilter: string) => {
	return {
		id: "xKPct",
		value: "xKPct",
		label: "Exp",
		tooltip: TOOLTIP_EXPECTED_PITCHER_STRIKEOUT_RATE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPaOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPaOutcomesData.xKVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPaOutcomesData.xKVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPaOutcomesData.xK) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getBbPctColumn = (batsFilter: string) => {
	return {
		id: "bbPct",
		value: "bbPct",
		label: "Act",
		tooltip: TOOLTIP_ACTUAL_PITCHER_WALK_RATE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPaOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPaOutcomesData.bbPctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPaOutcomesData.bbPctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPaOutcomesData.bbPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXBbPctColumn = (batsFilter: string) => {
	return {
		id: "xBbPct",
		value: "xBbPct",
		label: "Exp",
		tooltip: TOOLTIP_EXPECTED_PITCHER_WALK_RATE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPaOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPaOutcomesData.xUbbVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPaOutcomesData.xUbbVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPaOutcomesData.xUbb) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getGbPctColumn = (batsFilter: string) => {
	return {
		id: "gbPct",
		value: "gbPct",
		label: "Act",
		tooltip: TOOLTIP_ACTUAL_GROUNDBALL_RATE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPaOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPaOutcomesData.gbPctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPaOutcomesData.gbPctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPaOutcomesData.gbPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXGbPctColumn = (batsFilter: string) => {
	return {
		id: "xGbPct",
		value: "xGbPct",
		label: "Exp",
		tooltip: TOOLTIP_EXPECTED_GROUNDBALL_RATE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPaOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPaOutcomesData.xGbVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPaOutcomesData.xGbVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPaOutcomesData.xGb) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getHrPctColumn = (batsFilter: string) => {
	return {
		id: "hrPct",
		value: "hrPct",
		label: "Act",
		tooltip: TOOLTIP_ACTUAL_HOME_RUN_RATE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPaOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPaOutcomesData.hrPctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPaOutcomesData.hrPctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPaOutcomesData.hrPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXHrPctColumn = (batsFilter: string) => {
	return {
		id: "xHrPct",
		value: "xHrPct",
		label: "Exp",
		tooltip: TOOLTIP_EXPECTED_HOME_RUN_RATE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPaOutcomesRow) =>
			batsFilter === BATS_L
				? formatPercentage(row.combinedPitcherPaOutcomesData.xHrVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.combinedPitcherPaOutcomesData.xHrVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.combinedPitcherPaOutcomesData.xHr) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getWobaconColumn = (batsFilter: string) => {
	return {
		id: "wobacon",
		value: "wobacon",
		label: "Act",
		tooltip: TOOLTIP_ACTUAL_WOBACON,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPaOutcomesRow) =>
			batsFilter === BATS_L
				? formatWobacon(row.combinedPitcherPaOutcomesData.wobaconVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatWobacon(row.combinedPitcherPaOutcomesData.wobaconVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatWobacon(row.combinedPitcherPaOutcomesData.wobacon) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXWobaconColumn = (batsFilter: string) => {
	return {
		id: "xwobacon",
		value: "xwobacon",
		label: "Exp",
		tooltip: TOOLTIP_EXPECTED_WOBACON,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPitcherPaOutcomesRow) =>
			batsFilter === BATS_L
				? formatWobacon(row.combinedPitcherPaOutcomesData.xwobaconVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatWobacon(row.combinedPitcherPaOutcomesData.xwobaconVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatWobacon(row.combinedPitcherPaOutcomesData.xwobacon) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getPitcherPaOutcomesColumns = (
	batsFilter: string
): Array<TColumn<TPitcherPaOutcomesRow, keyof TPitcherPaOutcomesRow>> => {
	return [
		SEASON_COLUMN,
		TEAM_COLUMN,
		LEVEL_COLUMN,
		getBfColumn(batsFilter),
		getKPctColumn(batsFilter),
		getXKPctColumn(batsFilter),
		getBbPctColumn(batsFilter),
		getXBbPctColumn(batsFilter),
		getGbPctColumn(batsFilter),
		getXGbPctColumn(batsFilter),
		getHrPctColumn(batsFilter),
		getXHrPctColumn(batsFilter),
		getWobaconColumn(batsFilter),
		getXWobaconColumn(batsFilter)
	];
};

export const PITCHER_PA_OUTCOMES_PARENT_COLUMNS: Array<TParentColumn> = [
	{
		label: "",
		id: "general",
		childColumnIds: ["season", "team", "level", "bf"]
	},
	{ label: "K%", id: "kPct", childColumnIds: ["kPct", "xKPct"] },
	{ label: "BB%", id: "bbPct", childColumnIds: ["bbPct", "xBbPct"] },
	{ label: "GB%", id: "gbPct", childColumnIds: ["gbPct", "xGbPct"] },
	{ label: "HR%", id: "hrPct", childColumnIds: ["hrPct", "xHrPct"] },
	{ label: "wOBAcon", id: "wobacon", childColumnIds: ["wobacon", "xwobacon"] }
];
