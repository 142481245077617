import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { pickHex } from "_react/playerpage/shared_js/evals/_helpers";

const HeatSlider = ({
	value = 50,
	high = 50,
	low = 50,
	highFrame = 80,
	lowFrame = 20,
	highColor = "#FF0000",
	lowColor = "#0000FF",
	label,
	toolTip
}) => {
	const lowColorValue = lowColor.replace("#", "");
	const highColorValue = highColor.replace("#", "");
	// let highValue = (highFrame - high - lowFrame) / (highFrame - lowFrame);
	const highValue = 1 - (high - lowFrame) / (highFrame - lowFrame);
	const lowValue = (low - lowFrame) / (highFrame - lowFrame);
	const valueValue = ((value - lowFrame) / (highFrame - lowFrame)) * 100;

	let component = (
		<div
			style={{
				position: "relative",
				width: "250px",
				height: "70px",
				margin: "auto"
			}}
		>
			<div
				style={{
					border: "2px solid white",
					borderRadius: "5px",
					backgroundImage: `linear-gradient(to right, ${pickHex(highColorValue, lowColorValue, 0)}, ${pickHex(
						highColorValue,
						lowColorValue,
						1
					)})`,
					width: "250px",
					height: "20px"
				}}
			/>
			{high !== low && (
				<div
					style={{
						height: "10px",
						position: "absolute",
						top: "5px",
						backgroundColor: "black",
						borderRadius: "3px",
						border: "2px solid white",
						left: `${lowValue * 100}%`,
						right: `${highValue * 100.0 === 0 ? -1.5 : highValue * 100.0}%`
					}}
				/>
			)}
			<div
				style={{
					height: "34px",
					width: "7px",
					position: "absolute",
					top: "-5px",
					left: `${valueValue - 1.5}%`,
					backgroundColor: "black",
					borderRadius: "5px",
					border: "2px solid white"
				}}
			/>
			{
				// high !== low &&
				// <div
				// 	style={{
				// 		height: "6px",
				// 		position: "absolute",
				// 		top: "9px",
				// 		backgroundColor: "black",
				// 		left: `${low === high ? "50" : valueValue}%`,
				// 		width: "7px"
				// 	}}
				// />
			}
			<div
				style={{
					position: "absolute",
					transform: "translate(-35%)",
					top: "30px",
					left: `${valueValue}%`
				}}
			>
				{value}
			</div>
			{label && (
				<div
					style={{
						position: "absolute",
						transform: "translate(-50%)",
						left: "50%",
						top: "45px",
						fontWeight: "900",
						width: "100%",
						textAlign: "center"
					}}
				>
					{label}
				</div>
			)}
		</div>
	);
	if (toolTip) {
		component = (
			<Tooltip placement="top" title={toolTip}>
				{component}
			</Tooltip>
		);
	}
	return component;
};

export default HeatSlider;
