import React from "react";

import {
	TOOLTIP_POS_PLAYER_STRIKEOUT_RATE,
	TOOLTIP_POS_PLAYER_WALK_RATE,
	TOOLTIP_EV_XWOBA,
	TOOLTIP_EVLA_XWOBA,
	TOOLTIP_EVLAHLA_XWOBA,
	TOOLTIP_WOBA,
	TOOLTIP_PRC_PLUS
} from "_react/shared/_constants/tooltips";
import { getLevelDisplayFromLevels } from "_react/shared/_helpers/stats";
import { round10 } from "_react/shared/_helpers/numbers";
import {
	GAME_TYPE_OVERALL,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_SPRING_TRAINING,
	THROWS_L,
	THROWS_OVERALL,
	THROWS_R
} from "_react/shared/data_models/baseline_hit_probs/_constants";
import {
	GAME_TYPE_D,
	GAME_TYPE_F,
	GAME_TYPE_L,
	GAME_TYPE_R,
	GAME_TYPE_S,
	GAME_TYPE_W
} from "_react/shared/data_models/stats/_constants";
import { formatPlusOrMinusStat, formatThreeDecimal } from "_react/shared/data_models/stats/_helpers";
import { TColumn, TParentColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { formatPercentage } from "_react/shared/ui/presentation/components/stat/shared/_helpers";
import TeamColumn from "_react/shared/ui/data/tables/shared/TeamColumn";

import { getLevelsFromRow } from "_react/shared/ui/data/tables/PositionPlayerPerformanceTable/_helpers";
import { TPositionPlayerPerformanceRow } from "_react/shared/ui/data/tables/PositionPlayerPerformanceTable/_types";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

export const AGE_BASE_DATE = "6/30";

export const GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP: Record<string, Array<string>> = {
	[GAME_TYPE_R]: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_F]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_D]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_L]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_W]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_S]: [GAME_TYPE_SPRING_TRAINING]
};

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerPerformanceRow) => row.combinedPositionPlayerPerformanceData.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPositionPlayerPerformanceRow) => {
		if ("team" in row.combinedPositionPlayerPerformanceData)
			return (
				<TeamColumn
					teamBam={row.combinedPositionPlayerPerformanceData.team}
					childRowCount={row.childData?.length}
				/>
			);
		return <TeamColumn childRowCount={row.childData?.length} />;
	}
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPositionPlayerPerformanceRow) =>
		getLevelDisplayFromLevels(getLevelsFromRow(row), NULL_FILLER_TEXT),
	getSortValueFunction: (row: TPositionPlayerPerformanceRow) => {
		// Child Rows
		if ("team" in row.combinedPositionPlayerPerformanceData)
			return row.combinedPositionPlayerPerformanceData.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
		// Parent Rows with nested data - highest level if multiple levels
		if (row.childData && row.childData.length > 1)
			return Math.min(
				...row.childData.map((childRow: TPositionPlayerPerformanceRow) => {
					if ("team" in childRow.combinedPositionPlayerPerformanceData)
						return (
							childRow.combinedPositionPlayerPerformanceData.team?.levelRel?.sortOrder ??
							Number.MAX_SAFE_INTEGER
						);
					return Number.MAX_SAFE_INTEGER;
				})
			);
		return NULL_FILLER_TEXT;
	}
};

const AGE_COLUMN = {
	id: "age",
	value: "age",
	label: "Age",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerPerformanceRow) =>
		row.combinedPositionPlayerPerformanceData.age ?? NULL_FILLER_TEXT,
	tooltip: `Age on ${AGE_BASE_DATE}`
};

const REL_AGE_COLUMN = {
	id: "relAge",
	value: "relAge",
	label: "Rel. Age",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerPerformanceRow) =>
		round10(row.combinedPositionPlayerPerformanceData.relAge, -1) ?? NULL_FILLER_TEXT,
	tooltip: `Difference between the player's age on ${AGE_BASE_DATE} and the average age of all position players on ${AGE_BASE_DATE} given the season and level`
};

export const getGColumn = (throwsFilter: string) => {
	return {
		id: "g",
		value: "g",
		label: "G",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPositionPlayerPerformanceRow) =>
			throwsFilter === THROWS_L
				? row.combinedPositionPlayerPerformanceData.gVl ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? row.combinedPositionPlayerPerformanceData.gVr ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? row.combinedPositionPlayerPerformanceData.g ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getPaColumn = (throwsFilter: string) => {
	return {
		id: "pa",
		value: "pa",
		label: "PA",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPositionPlayerPerformanceRow) =>
			throwsFilter === THROWS_L
				? row.combinedPositionPlayerPerformanceData.paVl ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? row.combinedPositionPlayerPerformanceData.paVr ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? row.combinedPositionPlayerPerformanceData.pa ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getKPctColumn = (throwsFilter: string) => {
	return {
		id: "kPct",
		value: "kPct",
		label: "K%",
		tooltip: TOOLTIP_POS_PLAYER_STRIKEOUT_RATE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPositionPlayerPerformanceRow) =>
			throwsFilter === THROWS_L
				? formatPercentage(row.combinedPositionPlayerPerformanceData.kPctVl) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? formatPercentage(row.combinedPositionPlayerPerformanceData.kPctVr) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? formatPercentage(row.combinedPositionPlayerPerformanceData.kPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getBbPctColumn = (throwsFilter: string) => {
	return {
		id: "bbPct",
		value: "bbPct",
		label: "BB%",
		tooltip: TOOLTIP_POS_PLAYER_WALK_RATE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPositionPlayerPerformanceRow) =>
			throwsFilter === THROWS_L
				? formatPercentage(row.combinedPositionPlayerPerformanceData.bbPctVl) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? formatPercentage(row.combinedPositionPlayerPerformanceData.bbPctVr) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? formatPercentage(row.combinedPositionPlayerPerformanceData.bbPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getEvColumn = (throwsFilter: string) => {
	return {
		id: "ev",
		value: "ev",
		label: "EV",
		tooltip: TOOLTIP_EV_XWOBA,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPositionPlayerPerformanceRow) =>
			throwsFilter === THROWS_L
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.xwobaEvVl) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.xwobaEvVr) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.xwobaEv) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getEvLaColumn = (throwsFilter: string) => {
	return {
		id: "evLa",
		value: "evLa",
		label: "EVLA",
		tooltip: TOOLTIP_EVLA_XWOBA,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPositionPlayerPerformanceRow) =>
			throwsFilter === THROWS_L
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.xwobaEvLaVl) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.xwobaEvLaVr) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.xwobaEvLa) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getEvLaHlaColumn = (throwsFilter: string) => {
	return {
		id: "evLaHla",
		value: "evLaHla",
		label: "EVLAHLA",
		tooltip: TOOLTIP_EVLAHLA_XWOBA,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPositionPlayerPerformanceRow) =>
			throwsFilter === THROWS_L
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.xwobaEvLaHlaVl) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.xwobaEvLaHlaVr) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.xwobaEvLaHla) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getWobaColumn = (throwsFilter: string) => {
	return {
		id: "woba",
		value: "woba",
		label: "wOBA",
		tooltip: TOOLTIP_WOBA,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPositionPlayerPerformanceRow) =>
			throwsFilter === THROWS_L
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.vlWoba) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.vrWoba) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? formatThreeDecimal(row.combinedPositionPlayerPerformanceData.woba) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getPrcPlusColumn = (throwsFilter: string) => {
	return {
		id: "prcPlus",
		value: "prcPlus",
		label: "pRC+",
		tooltip: TOOLTIP_PRC_PLUS,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TPositionPlayerPerformanceRow) =>
			throwsFilter === THROWS_L
				? formatPlusOrMinusStat(row.combinedPositionPlayerPerformanceData.prcPlusVl) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? formatPlusOrMinusStat(row.combinedPositionPlayerPerformanceData.prcPlusVr) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? formatPlusOrMinusStat(row.combinedPositionPlayerPerformanceData.prcPlus) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getPositionPlayerPerformanceColumns = (
	throwsFilter: string
): Array<TColumn<TPositionPlayerPerformanceRow, keyof TPositionPlayerPerformanceRow>> => {
	return [
		SEASON_COLUMN,
		TEAM_COLUMN,
		LEVEL_COLUMN,
		AGE_COLUMN,
		REL_AGE_COLUMN,
		getGColumn(throwsFilter),
		getPaColumn(throwsFilter),
		getBbPctColumn(throwsFilter),
		getKPctColumn(throwsFilter),
		getEvColumn(throwsFilter),
		getEvLaColumn(throwsFilter),
		getEvLaHlaColumn(throwsFilter),
		getWobaColumn(throwsFilter),
		getPrcPlusColumn(throwsFilter)
	];
};

export const POSITION_PLAYER_PERFORMANCE_PARENT_COLUMNS: Array<TParentColumn> = [
	{
		label: "",
		id: "general",
		childColumnIds: ["season", "team", "level", "age", "relAge", "g", "pa", "bbPct", "kPct"]
	},
	{ label: "xwOBA", id: "xwoba", childColumnIds: ["ev", "evLa", "evLaHla"] },
	{ label: "", id: "woba", childColumnIds: ["woba", "prcPlus"] }
];
