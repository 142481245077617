import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function ArrowRight({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M10 17l5-5-5-5v10z" />
			<path fill={"none"} d="M0 24V0h24v24H0z" />
		</IconSVG>
	);
}
