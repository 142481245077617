import { Options } from "react-select";

import { $TSFixMe, T_DBs, TPlayerType } from "utils/tsutils";

export type TValueConstraint = string | number | boolean;

export type TBaseOption<TValue extends TValueConstraint> = {
	value: TValue;
	label: string;
	__isNew__?: boolean;
	isDisabled?: boolean;
	sortOrder?: number;
	philId?: number;
	playerType?: TPlayerType;
};

export type TExtendedOption<TValue extends TValueConstraint> = TBaseOption<TValue>;

export type TOptions<TOption> = Options<TOption>;
export type TOptionOrGroup<TOption> = TOption | $TSFixMe;

export type TOption<T> = {
	value: T;
	label: string;
	__isNew__?: boolean;
	isDisabled?: boolean;
};
export type TOptionOrNull<T> = TOption<T> | null;

interface ITypeMap {
	string: string;
	number: number;
	boolean: boolean;
}

export type TValueTypeNames = keyof ITypeMap;
export type TValueTypes = ITypeMap[TValueTypeNames];
export function isOption<T extends TValueTypes>(option: $TSFixMe, valueType: TValueTypeNames): option is TOption<T> {
	if (typeof option !== "object" || !option) {
		return false;
	}
	if (!option.hasOwnProperty("label") || typeof option.label !== "string") {
		return false;
	}
	if (!option.hasOwnProperty("value") || typeof option.value !== valueType) {
		return false;
	}
	return true;
}

export function isOptionArr<T extends TValueTypes>(
	options: $TSFixMe,
	valueType: TValueTypeNames
): options is Array<TOption<T>> {
	if (!Array.isArray(options)) {
		return false;
	}
	for (const option of options) {
		if (!isOption<T>(option, valueType)) {
			return false;
		}
	}
	return true;
}

export type TValueType = TOption<string | number>;
export type TValueTypeNum = TOption<number> | null;
export type TValueTypeStr = TOption<string> | null;

export function getTValueTypeStr(vType: TValueType): TValueTypeStr {
	return isOptionArr<string>(vType, "string") ? (vType as TValueTypeStr) : null;
}

export function getTValueTypeNum(vType: TValueType): TValueTypeNum {
	return isOptionArr<number>(vType, "number") ? (vType as TValueTypeNum) : null;
}

export function isValidOption<T>(valueType: T): valueType is T {
	return Boolean(valueType);
}

export type TSearchType = "player" | "team";
export type TFieldType = "var" | "lk" | "rate" | "search";

export type TFieldFilter = {
	fieldId: string;
	filterFieldId: string;
	filterKey: string;
};

export interface IField {
	id: string;
	label?: string;
	abbr?: string;
	lkDb?: T_DBs;
	lkTable?: string;
	searchType?: TSearchType;
	type: TFieldType;
	order: number;
	filters?: TFieldFilter[];
}

export interface ISearchField extends IField {
	searchType: TSearchType;
}

export interface ILkField extends IField {
	lkDb: T_DBs;
	lkTable: string;
}

export function isSearchField(field: IField): field is ISearchField {
	return field.type === "search";
}

export function isLkField(field: IField): field is ILkField {
	return field.type === "lk";
}

export type TDatasource = {
	abbr: string;
	label: string;
	id: string;
	filters: IField[];
};

export type TDatasourceOption = {
	value: string;
	label: string;
	data: TDatasource;
};

export type TFilterValues = Array<string | number>;
