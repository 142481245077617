import { useEffect, useState, useMemo } from "react";

type TBounds = [number, number];

type TIndexData = { outerIndex: number; nestedIndex: number };
type TGetNested<T> = (datum: T) => boolean;

/**
 * Get outer and nested indices for each datum and make maps with global indices
 * Example: [non-nested, nested, nested, non-nested, nested, non-nested, non-nested]
 * would give indexGroups {0: [1, 2], 3: [4], 5: [], 6: []}
 * outerToGlobal: {0: 0, 1: 3, 2: 5, 3: 6}, globalToOuter reverse
 * @param data
 * @param getIsNested function to tell whether a datum is a nested row or not
 */
function getIndexGroups<T>(data: T[], getIsNested: TGetNested<T>) {
	let outerIndex = -1;
	let nestedIndex = 0;
	const indexGroups: { [key: string]: number[] } = {};
	const dataWithIndices: { datum: T; indexData: TIndexData }[] = [];
	const outerToGlobalIdx: { [key: string]: number } = {};
	const globalToOuterIdx: { [key: string]: number } = {};
	data.forEach((datum, idx: number) => {
		let indexData: TIndexData;
		if (getIsNested(datum)) {
			indexData = { outerIndex, nestedIndex };
			nestedIndex += 1;
			indexGroups[idx - nestedIndex].push(idx);
		} else {
			nestedIndex = 0;
			outerIndex += 1;
			indexData = { outerIndex, nestedIndex };
			outerToGlobalIdx[outerIndex] = idx;
			globalToOuterIdx[idx] = outerIndex;
			indexGroups[idx] = [];
		}
		dataWithIndices.push({ datum, indexData });
	});
	return { dataWithIndices, indexGroups, outerToGlobalIdx, globalToOuterIdx };
}

type TSetIndices = (indices: number[]) => void;

function arrayBetweenBounds(lowerBound: number, upperBound: number) {
	return [...Array(upperBound + 1).keys()].slice(lowerBound);
}

// TODO: make test cases with examples below
/**
 * Takes the existing set of selected indices and a newly selected index, calculates what the new set should be,
 * and calls setIndices with the new set
 * If the metakey is pressed, it will only either add or remove that single index
 * If the metakey is not pressed, it will try to work with bounds. If working with bounds and a bound is selected
 * all indices will be deselected except the other bound. If working with bounds and an index
 * between the bounds is selected, the min bound is always preserved and the new selection is
 * the max bound. Otherwise the bounds always expand.
 *
 * Example 1: currently index 1 is selected. Select index 4 no metakey. Work with bounds to 1,2,3,4
 * Example 2: currently index 1 is selected. Select index 4 and metakey. Now 1,4
 * Example 3: currently index 1,2,3,4 is selected. Select index 3 and metakey. Now 1,2,4
 * Example 4: currently index 1,2,3 selected. Select index 6 no metakey. Work with bounds to 1,2,3,4,5,6
 * Example 5: currently index 1,2,3,4,5 selected. Select index 3 no metakey. Work with bounds to 1,2,3
 * Example 6: currently index 3,4,5 selected. Select index 1 no metakey. Work with bounds to 1,2,3,4,5
 * Example 6: currently index 3,4,5 selected. Select index 7 no metakey. Work with bounds to 3,4,5,6,7
 * @param indices set of indices previously selected
 * @param bounds previous bounds
 * @param inputIdx the index newly seleted
 * @param setIndices a function to set the new indices after updating
 * @param metaKeyPressed metakey refers to command key on mac or windows key on PC
 */
function selectIndex(
	indices: Set<number>,
	bounds: TBounds | null,
	inputIdx: number,
	setIndices: TSetIndices,
	metaKeyPressed = false
) {
	if (!indices.size) {
		setIndices([inputIdx]);
	} else if (metaKeyPressed) {
		if (indices.has(inputIdx)) {
			setIndices([...indices].filter(i => i !== inputIdx));
		} else {
			setIndices([...indices, inputIdx]);
		}
	} else if (indices.size === 1) {
		const selectedIdx = [...indices][0];
		if (selectedIdx === inputIdx) setIndices([]);
		else {
			const [lowerBound, upperBound] = selectedIdx < inputIdx ? [selectedIdx, inputIdx] : [inputIdx, selectedIdx];
			setIndices(arrayBetweenBounds(lowerBound, upperBound));
		}
	} else {
		if (bounds) {
			const [minIdx, maxIdx] = bounds;
			if (inputIdx === minIdx || inputIdx === maxIdx) {
				const newEdge = inputIdx === minIdx ? maxIdx : minIdx;
				setIndices([newEdge]);
			} else {
				let [lowerBound, upperBound] = [minIdx, maxIdx];
				if (inputIdx < minIdx) lowerBound = inputIdx;
				else upperBound = inputIdx;
				setIndices(arrayBetweenBounds(lowerBound, upperBound));
			}
		} else {
			setIndices([inputIdx]);
		}
	}
}

// example of current use is selecting rows for aggregate totals in the playerpage stats table
export const useSelectIndices = (initialSelected: number[] = []) => {
	const [indices, setIndices] = useState<Set<number>>(new Set(initialSelected));
	const [groupingBounds, setGroupingBounds] = useState<TBounds | null>(null);

	useEffect(() => {
		let updatedBounds: TBounds | null = null;
		if (indices.size >= 2) {
			const [minIdx, maxIdx] = [Math.min(...indices), Math.max(...indices)];
			let isGrouping = true;
			for (let i = minIdx + 1; i < maxIdx; i++) {
				if (!indices.has(i)) {
					isGrouping = false;
					break;
				}
			}
			if (isGrouping) updatedBounds = [minIdx, maxIdx];
		}
		setGroupingBounds(updatedBounds);
	}, [indices]);

	const setIndicesWrapper = (updatedIndices: number[]) => {
		const updatedSet = new Set(updatedIndices);
		setIndices(updatedSet);
	};

	const selectIndexWrapper = (inputIdx: number, metaKeyPressed = false) => {
		selectIndex(indices, groupingBounds, inputIdx, setIndicesWrapper, metaKeyPressed);
	};

	const reset = (newIndices: number[]) => {
		setIndicesWrapper(newIndices);
	};
	return [indices, selectIndexWrapper, groupingBounds, reset] as const;
};

export function useSelectGroupedIndices<T>(data: T[], getIsNested: TGetNested<T>) {
	const { dataWithIndices, indexGroups, outerToGlobalIdx, globalToOuterIdx } = useMemo(
		() => getIndexGroups(data, getIsNested),
		[data, getIsNested]
	);

	const parents = useMemo(() => new Set(Object.keys(indexGroups).map(k => parseInt(k, 10))), [indexGroups]);
	const childToParent = useMemo(() => {
		const map: { [child: number]: number } = {};
		parents.forEach(parent => {
			indexGroups[parent].forEach(child => {
				map[child] = parent;
			});
		});
		return map;
	}, [parents, indexGroups]);
	const children = new Set(Object.keys(childToParent).map(k => parseInt(k, 10)));

	const [selectedChildren, setSelectedChildren] = useState<Set<number>>(new Set());
	const [_selectedParents, setNewSelectedParent, parentGroupingBounds, resetParents] = useSelectIndices();

	const selectedParents = new Set([..._selectedParents].map(p => outerToGlobalIdx[p]));

	useEffect(() => {
		if (parentGroupingBounds && selectedChildren.size) {
			const [lower, upper] = parentGroupingBounds.map(i => outerToGlobalIdx[i]);
			const filteredChildren = [...selectedChildren].filter(c => {
				const parent = childToParent[c];
				return parent < lower || parent > upper;
			});
			setSelectedChildren(new Set(filteredChildren));
		}
	}, [parentGroupingBounds, selectedChildren, outerToGlobalIdx, childToParent]);

	const groupingBounds = parentGroupingBounds && parentGroupingBounds.map(i => outerToGlobalIdx[i]);

	/**
	 * Wrapper to use the selected index hook with nesting. If a parent is selected, we just use
	 * the function returned by the hook and it's easy. If not, there is logic to update selected children
	 * and reset the selected parents
	 * @param inputIdx
	 * @param metaKeyPressed
	 */
	const setNewSelectedIndex = (inputIdx: number, metaKeyPressed = false) => {
		if (parents.has(inputIdx)) {
			const childrenInGroup = indexGroups[inputIdx];
			setSelectedChildren(new Set([...selectedChildren].filter(c => !childrenInGroup.includes(c))));
			setNewSelectedParent(globalToOuterIdx[inputIdx], metaKeyPressed);
		} else if (children.has(inputIdx)) {
			let updatedChildren = [...selectedChildren];
			let updatedParents = [...selectedParents];
			if (selectedChildren.has(inputIdx)) {
				updatedChildren = updatedChildren.filter(c => c !== inputIdx);
			} else {
				updatedChildren.push(inputIdx);
			}
			const childrenInGroup = indexGroups[childToParent[inputIdx]];
			const allChildrenSelected = childrenInGroup.every(c => updatedChildren.includes(c));
			if (allChildrenSelected) {
				updatedChildren = updatedChildren.filter(c => !childrenInGroup.includes(c));
				updatedParents.push(childToParent[inputIdx]);
			} else {
				updatedParents = updatedParents.filter(p => p !== childToParent[inputIdx]);
			}
			setSelectedChildren(new Set(updatedChildren));
			resetParents(updatedParents.map(i => globalToOuterIdx[i]));
		}
	};

	function resetAll() {
		setSelectedChildren(new Set([]));
		resetParents([]);
	}

	return {
		dataWithIndices,
		indexData: [selectedParents, selectedChildren, setNewSelectedIndex, groupingBounds] as const,
		indexGroups,
		resetAll
	};
}
