import React from "react";
import { VStack, HStack, Box } from "@chakra-ui/react";

import ThresholdIcon, {
	TGradeThresholdIconProps
} from "_react/shared/ui/presentation/components/GradeThresholdIcon/GradeThresholdIcon";
import { TPlotStatLabelStyle } from "_react/shared/ui/presentation/components/PlotStatLabel/_types";

type TPlotStatLabelProps = {
	value: number | string | boolean;
	secondaryValue?: number | string | boolean;
	thresholdIcon?: TGradeThresholdIconProps;
	label?: string;
	style?: TPlotStatLabelStyle;
};

const PlotStatLabel = ({ value, secondaryValue, thresholdIcon, label, style }: TPlotStatLabelProps) => {
	return (
		<VStack alignItems="start" gap={0} sx={style?.container}>
			{label !== undefined && (
				<Box fontSize="xs" fontWeight="semibold" color="gray.500" sx={style?.label}>
					{label}
				</Box>
			)}
			<HStack alignItems="center" gap={0}>
				<Box
					fontSize="2xl"
					lineHeight="8"
					fontWeight="bold"
					color="black"
					marginRight={thresholdIcon?.shape && value !== "" ? "1" : undefined}
					sx={style?.value}
				>
					{value}
				</Box>
				{thresholdIcon && (
					<ThresholdIcon
						shape={thresholdIcon.shape}
						tooltipLabel={thresholdIcon.tooltipLabel}
						style={style?.thresholdIcon}
					/>
				)}
				{secondaryValue !== undefined && (
					<Box marginLeft="3" fontSize="xs" fontWeight="semibold" color="gray.500" sx={style?.secondaryValue}>
						{secondaryValue}
					</Box>
				)}
			</HStack>
		</VStack>
	);
};

export default PlotStatLabel;
