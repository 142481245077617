//
// These are legacy hooks. All new hooks should be added to `/shared/_helpers/hooks.ts`
// These hooks can still be used though.
//
import { useEffect, useState, useRef, useMemo } from "react";
import { fetchLkItems } from "_react/inputs/lks/LkContext";
import dayjs from "dayjs";
import queryString from "query-string";
import { useUserId } from "_react/app/AppContents";
import { useFetch } from "utils/url_helpers";
import { MOBILE_WIDTH, DEFAULT_TITLE } from "utils/constants";

export const useDocumentTitle = title => {
	const [documentTitle, setDocumentTitle] = useState(title);

	useEffect(() => {
		document.title = documentTitle;

		return function cleanup() {
			document.title = DEFAULT_TITLE;
		};
	}, [documentTitle]);

	return [documentTitle, setDocumentTitle];
};

export const useStateQueryString = (name, initialValue, location, history, type = "string") => {
	// Extra && for arrays as they should be null when they are empty

	const formatValue = val => {
		if (type === "int") {
			val = parseInt(val, 10);
		} else if (type === "bool") {
			return Boolean(val);
		} else if (type === "object") {
			return JSON.parse(val);
		}
		return val;
	};

	const search = { ...queryString.parse(location?.search ?? "") };
	if (search.hasOwnProperty(name)) {
		let value = search[name];
		if (type === "object") {
			value = JSON.stringify(value);
		}
		initialValue = formatValue(value);
	} else if (type === "array") {
		initialValue = initialValue.length > 0 ? initialValue.join(",") : undefined;
	}
	const [value, setValue] = useState(initialValue);

	const updateHistory = value => {
		let search = { ...queryString.parse(location?.search ?? "") };
		if (value) {
			if (type === "object") {
				value = JSON.stringify(value);
			}
			search = { ...search, [name]: value };
		} else if (search.hasOwnProperty(name)) {
			delete search[name];
		}
		return history?.push({
			path: location.pathname,
			search: queryString.stringify(search)
		});
	};

	const handleUpdateValue = value => {
		updateHistory(value);
		setValue(formatValue(value));
	};

	if (value && !search.hasOwnProperty(name)) {
		updateHistory(value);
	}

	useEffect(() => {
		return async function() {
			const search = { ...queryString.parse(location?.search ?? "") };
			if (search.hasOwnProperty(name) && search.name === value) {
				await handleUpdateValue(null);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const formattedValue = useMemo(() => {
		if (type === "array") {
			return value ? value.split(",") : [];
		}
		return value;
	}, [value, type]);

	return [formattedValue, handleUpdateValue];
};

export const useToggle = initialBool => {
	const [bool, setBool] = useState(initialBool);

	const handleToggle = () => setBool(prevBool => !prevBool);

	return [bool, handleToggle, () => setBool(initialBool)];
};

export function useDebounce(value, delay, debouncingStateChangedFunction) {
	const [debouncedValue, setDebouncedValue] = useState(value);
	const debouncedValueRef = useRef(debouncedValue);
	useEffect(() => {
		debouncedValueRef.current = debouncedValue;
	}, [debouncedValue, debouncedValueRef]);

	useEffect(() => {
		if (delay <= 0) {
			return;
		}
		const handler = setTimeout(() => {
			if (debouncingStateChangedFunction != null) debouncingStateChangedFunction(false);
			setDebouncedValue(value);
		}, delay);

		// Fired to let the caller know the timeout period has started
		if (debouncingStateChangedFunction != null && debouncedValueRef.current !== value)
			debouncingStateChangedFunction(true);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay, debouncingStateChangedFunction, debouncedValueRef]);

	return delay > 0 ? debouncedValue : value;
}

const GLOBALLY_IGNORED_CLICK_OUTSIDE_PREFIXES = ["react-select"];

export function useOnClickOutside(ref, handler, isDisabled = false, ignoreIds = []) {
	useEffect(() => {
		const listener = event => {
			// Do nothing if clicking ref's element or descendent elements
			const shouldIgnore =
				GLOBALLY_IGNORED_CLICK_OUTSIDE_PREFIXES.filter(e => {
					return event.target && event.target.id.startsWith(e);
				}).length > 0;
			if (
				!isDisabled &&
				ref.current &&
				!ref.current.contains(event.target) &&
				(!event.target || !ignoreIds.includes(event.target.id)) &&
				!shouldIgnore
			) {
				handler(event);
			}
		};

		document.addEventListener("click", listener, true);
		document.addEventListener("touchstart", listener, true);

		return () => {
			document.removeEventListener("click", listener, true);
			document.removeEventListener("touchstart", listener, true);
		};
	}, [handler, ignoreIds, isDisabled, ref]);
}

export const useFocus = () => {
	const htmlElRef = useRef(null);
	const setFocus = () => {
		if (htmlElRef.current) htmlElRef.current.focus();
	};

	return [htmlElRef, setFocus];
};

const useWindowEvent = (event, callback) => {
	useEffect(() => {
		window.addEventListener(event, callback);
		return () => {
			window.removeEventListener(event, callback);
		};
	}, [event, callback]);
};

export const useWindowResize = callback => {
	return useWindowEvent("resize", callback);
};

export const useWindowSize = (debounceDelay = 0) => {
	const [size, setSize] = useState({ height: window.innerHeight, width: window.innerWidth });

	useWindowResize(() => setSize({ height: window.innerHeight, width: window.innerWidth }));

	return useDebounce(size, debounceDelay);
};

export const useWindowClick = callback => {
	return useWindowEvent("click", callback);
};

export const useIsMobile = (width = MOBILE_WIDTH) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= width);
	useWindowResize(() => {
		if (window.innerWidth <= width && !isMobile) setIsMobile(true);
		else if (window.innerWidth > width && isMobile) setIsMobile(false);
	});

	return isMobile;
};

const generateValueId = (valueDict, idCol) => {
	return valueDict[idCol];
};

// A hook to be used with an Autocomplete where multi=true
export const useMultiSelectState = (dataSource, initialValues = [], updateQueryString = () => null) => {
	// Params:
	//   dataSource: AutocompleteDataSource
	//   initialValues: Placeholder for initial selected states
	//   updateQueryString: Optional function to updatae the query string on change
	//
	// Returns:
	//   selectedValues: [AutocompleteDataSource]
	//   select: On select handler
	//   deSelect: On deselect handler

	// Value Container
	const [selectedValues, setSelectedValues] = useState(initialValues);

	const select = value => {
		// Add value to selected values list
		const newValues = [...selectedValues, value];
		setSelectedValues(newValues);
		// Update query string
		updateQueryString(newValues.map(value => value[dataSource.idColumn]).join(","));
	};

	const deSelect = value => {
		// Get the string that identifies the value
		const key = generateValueId(value, dataSource.idColumn);
		// Remove the value from the list of selected values
		const newValues = selectedValues.filter(selectedValue => {
			return key !== generateValueId(selectedValue, dataSource.idColumn);
		});
		setSelectedValues(newValues);
		// Update the query string
		updateQueryString(newValues.map(value => value[dataSource.idColumn]).join(","));
	};

	// When the initial values change, update the selected values to match
	useEffect(() => {
		setSelectedValues(initialValues);
	}, [initialValues]);

	return [selectedValues, select, deSelect];
};

export const useIamGroupMember = iamGroupId => {
	const [isMember, setIsMember] = useState();
	const { responseData: iamGroup, isSucceeded } = useFetch(`iam/group?id=${iamGroupId}`);
	const userId = useUserId();

	useEffect(() => {
		if (isSucceeded) {
			const iamGroupUserIds = iamGroup ? iamGroup.users.map(u => u.user_id) : [];
			setIsMember(iamGroupUserIds.includes(userId));
		}
	}, [iamGroup, userId, isSucceeded]);

	return isMember;
};

export const useDraftDate = (year = dayjs().year()) => {
	const [season, setSeason] = useState();
	const [draftDate, setDraftDate] = useState();
	const { responseData: draftDates, isSucceeded } = useFetch(
		`/important_date?level=MLB&type=mlb_amateur_draft_start`
	);

	useEffect(() => {
		if (season !== year && isSucceeded) {
			setSeason(year);
			const draftDate = draftDates?.find(date => date.season === year);
			setDraftDate(draftDate?.date ? dayjs(draftDate.date) : dayjs(`${year}-06-01`));
		}
	}, [year, season, draftDates, setSeason, setDraftDate, isSucceeded]);

	return draftDate;
};

export const useAllDraftDates = () => {
	const [draftDates, setDraftDates] = useState();
	const [lastSavedResponseDraftDates, setLastSavedResponseDraftDates] = useState();
	const { responseData: responseDraftDates, isSucceeded } = useFetch(
		`/important_date?level=MLB&type=mlb_amateur_draft_start`
	);

	useEffect(() => {
		if (lastSavedResponseDraftDates !== responseDraftDates && isSucceeded) {
			setDraftDates(
				responseDraftDates?.map(draftDate =>
					draftDate.date ? dayjs(draftDate.date) : dayjs(`${draftDate.season}-06-01`)
				)
			);
			setLastSavedResponseDraftDates(responseDraftDates);
		}
	}, [draftDates, lastSavedResponseDraftDates, responseDraftDates, isSucceeded, setDraftDates]);

	return draftDates;
};

export const useSchoolClass = schoolClassKey => {
	const [schoolClass, setSchoolClass] = useState(null);

	useEffect(() => {
		fetchLkItems("lk_school_grade").then(response => {
			const filtered = response.filter(function(lkEntry) {
				return lkEntry.value === schoolClassKey;
			});
			if (typeof filtered !== "undefined" && filtered.length > 0) {
				setSchoolClass(filtered[0].label);
			}
		});
	}, [schoolClassKey, schoolClass]);

	return schoolClass;
};

export const useSchoolGradeMap = () => {
	const [schoolGradeMap, setSchoolGradeMap] = useState({});

	useEffect(() => {
		fetchLkItems("lk_school_grade").then(response => {
			response.map(lkEntry => (schoolGradeMap[lkEntry.value] = lkEntry.label));
		});
		setSchoolGradeMap(schoolGradeMap);
	}, [schoolGradeMap]);

	return schoolGradeMap;
};

export const useScrollPosition = () => {
	const [scrollPosition, setScrollPosition] = useState(0);

	useEffect(() => {
		const updatePosition = () => {
			setScrollPosition(window.pageYOffset);
		};
		window.addEventListener("scroll", updatePosition);
		updatePosition();
		return () => window.removeEventListener("scroll", updatePosition);
	}, []);

	return scrollPosition;
};

export const useDraftYear = () => {
	const today = dayjs();
	const draftDate = useDraftDate();

	if (draftDate) {
		const draftEndDate = draftDate.add(7, "day");
		if (today > draftEndDate) return draftDate.year() + 1;
		else return draftDate.year();
	}
};
