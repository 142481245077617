import React, { createContext } from "react";
import { Interpreter, TypegenDisabled } from "xstate";
import { useInterpret } from "@xstate/react";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { $TSAnyRequired } from "utils/tsutils";

import AppMachine, { TAppContext, TAppEvent } from "_react/app/_machine";

// xState says to use the `InterpreterFrom<typeof yourMachine>` type (which translates to Interpreter<...>), but its' a type mismatch
// Type 'TypegenDisabled' is not assignable to type 'ResolveTypegenMeta<TypegenDisabled, TPlayerPageProEvent, BaseActionObject, ServiceMap>'
// https://xstate.js.org/docs/recipes/react.html#global-state-react-context
// So using a workaround which explicitly creates the `Interpreter` type we expect
// https://github.com/statelyai/xstate/discussions/3130
export const AppContext = createContext({
	appService: {} as Interpreter<TAppContext, $TSAnyRequired, TAppEvent, $TSAnyRequired, TypegenDisabled>
});

type AppProviderProps = {
	toast?: CreateToastFnReturn;
	children?: React.ReactNode;
};

const AppProvider = ({ toast, children }: AppProviderProps) => {
	const appService = useInterpret(AppMachine(toast));

	return <AppContext.Provider value={{ appService }}>{children}</AppContext.Provider>;
};

export default AppProvider;
