import React from "react";
import { ChakraProvider, useToast } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";

import theme from "_react/shared/theme";
import ErrorBoundary from "_react/app/ErrorBoundary";

import AppProvider from "_react/app/AppProvider";
import AppContents from "_react/app/AppContents";

const App = () => {
	const toast = useToast();
	return (
		<ChakraProvider theme={theme}>
			<ErrorBoundary>
				<AppProvider toast={toast}>
					<AppContents />
				</AppProvider>
			</ErrorBoundary>
		</ChakraProvider>
	);
};

export default App;
