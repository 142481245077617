import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function Star(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 -960 960 960" {...props}>
			<path
				fill="currentColor"
				d="m233-80 65-281L80-550l288-25 112-265 112 265 288 25-218 189 65 281-247-149L233-80Z"
			/>
		</Icon>
	);
}
