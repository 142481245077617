import React, { createContext, useEffect } from "react";
import { Interpreter, TypegenDisabled } from "xstate";
import { useInterpret } from "@xstate/react";
import { CreateToastFnReturn } from "@chakra-ui/react";
import { Dayjs } from "dayjs";

import { $TSAnyRequired } from "utils/tsutils";

import PlayerPageAmaMachine, {
	SET_PLAYER_ID,
	SET_PROJECTIONS_SEASON_DATA,
	SET_CURRENT_SEASON_DATA,
	SET_CURRENT_DRAFT_DATE,
	TPlayerPageAmaContext,
	TPlayerPageAmaEvent
} from "_react/playerpage/ama/_machine";

// xState says to use the `InterpreterFrom<typeof yourMachine>` type (which translates to Interpreter<...>), but its' a type mismatch
// Type 'TypegenDisabled' is not assignable to type 'ResolveTypegenMeta<TypegenDisabled, TPlayerPageAmaEvent, BaseActionObject, ServiceMap>'
// https://xstate.js.org/docs/recipes/react.html#global-state-react-context
// So using a workaround which explicitly creates the `Interpreter` type we expect
// https://github.com/statelyai/xstate/discussions/3130
export const PlayerPageAmaContext = createContext({
	playerPageAmaService: {} as Interpreter<
		TPlayerPageAmaContext,
		$TSAnyRequired,
		TPlayerPageAmaEvent,
		$TSAnyRequired,
		TypegenDisabled
	>
});

type PlayerPageAmaProviderProps = {
	playerId?: number;
	projectionsSeasonData?: [number, number, boolean];
	currentSeasonData?: { currentSeason: number | undefined; isFetching: boolean | undefined };
	currentDraftDate?: Dayjs;
	shouldFetchData?: boolean;
	toast?: CreateToastFnReturn;
	children?: React.ReactNode;
};

const PlayerPageAmaProvider = ({
	playerId,
	projectionsSeasonData,
	currentSeasonData,
	currentDraftDate,
	shouldFetchData = true,
	toast,
	children
}: PlayerPageAmaProviderProps) => {
	const playerPageAmaService = useInterpret(
		PlayerPageAmaMachine(
			playerId,
			projectionsSeasonData,
			currentSeasonData,
			currentDraftDate,
			shouldFetchData,
			toast
		)
	);

	// Update the machine's context when prop changes
	useEffect(() => {
		playerPageAmaService.send({ type: SET_PLAYER_ID, value: playerId });
	}, [playerPageAmaService, playerId]);

	useEffect(() => {
		playerPageAmaService.send({ type: SET_PROJECTIONS_SEASON_DATA, value: projectionsSeasonData });
	}, [playerPageAmaService, projectionsSeasonData]);

	useEffect(() => {
		playerPageAmaService.send({ type: SET_CURRENT_SEASON_DATA, value: currentSeasonData });
	}, [playerPageAmaService, currentSeasonData]);

	useEffect(() => {
		playerPageAmaService.send({ type: SET_CURRENT_DRAFT_DATE, value: currentDraftDate });
	}, [playerPageAmaService, currentDraftDate]);

	return <PlayerPageAmaContext.Provider value={{ playerPageAmaService }}>{children}</PlayerPageAmaContext.Provider>;
};

export default PlayerPageAmaProvider;
