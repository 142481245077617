import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function Home({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>
			<path fill={"none"} d="M0 0h24v24H0z"></path>
		</IconSVG>
	);
}
