import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { DropdownSelect } from "_react/shared/legacy/ui/DropdownSelect";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { InjuryDetails } from "_react/playerpage/injuries/InjuryDetails";
import { useQueryString } from "utils/url_helpers";

// TODO: integrate gumbo parts once gumbo data fetching has been added

const SOURCES = ["All", "EBIS", "BIS", "Report"];

export const columns = [
	{
		title: "Year",
		name: "year"
	},
	{
		title: "IL Stints",
		name: "stints"
	},
	{
		title: "Days Missed",
		name: "days"
	},
	{
		title: "BIS Injuries",
		name: "bis_injuries"
	},
	{
		title: "Report Notes",
		name: "report_notes"
	}
];

export const yearColumns = [
	{
		title: "Date",
		getCellValue: row => dayjs(row.date).format("MMMM D"),
		sortFunc: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1)
	},
	{
		title: "Source",
		name: "source"
	},
	{
		title: "Days Missed",
		getCellValue: row => {
			if (row.source === "EBIS") return row.days_missed;
			return "-";
		}
	},
	{
		title: "Injury Details",
		getCellValue: row => {
			if (row.source === "EBIS") {
				const separator = row.body_part != null || row.body_part_detail != null ? " - " : "";
				return `${row.body_part != null ? row.body_part : ""}${
					row.body_part_detail != null ? ` (${row.body_part_detail})` : ""
				}${separator}${row.diagnosis != null ? row.diagnosis : "Other (Illness/Ailment)"}`;
			}
			// if (row.source === "Gumbo")
			// 	return `${titleCase(row.injury_type)} (${titleCase(row.inning_half)} ${
			// 		row.inning
			// 	})`;
			if (row.source === "BIS")
				return `${row.region_descr}${row.part_descr != null ? ` (${row.part_descr})` : ""} - ${row.type_descr}`;
			if (row.source === "Report") return `(${row.scout_last_name}) - ${row.note}`;
			return "-";
		}
	}
];

export const InjuriesPage = ({
	colorSchemeGroup,
	evaluations,
	injuriesEbis,
	injuriesBIS,
	philId,
	fetchingEbis,
	fetchingBIS,
	viewClassification,
	style
}) => {
	const fetchingInjuryInfo = fetchingEbis || fetchingBIS;
	const [injuryId, setInjuryId] = useQueryString("injuryId");
	const [injurySource, setInjurySource] = useQueryString("injurySource");
	const [year, setYear] = useQueryString("year");

	const [source, setSource] = useState("All");
	const onRowClick = row => {
		if (`${row.year}` !== `${year}`) {
			setYear(row.year);
			setInjurySource(undefined, true);
			setInjuryId(undefined, true);
		}
	};

	const onRowClickDetail = row => {
		setInjurySource(row.source);
		setInjuryId(row.injuryId, true);
	};

	const injuryData = [];

	// Get EBIS Data
	if (source === "All" || source === "EBIS") {
		injuriesEbis.forEach(i => {
			if (i.earliest_reinstatement_date != null) {
				injuryData.push({
					date: i.injury_date,
					source: "EBIS",
					injuryId: i.placement_transaction_id,
					...i
				});
			}
		});
	}

	// Get Gumbo Data
	// if (source === "All" || source === "Gumbo") {
	// 	injuriesGumbo.forEach(i => {
	// 		injuryData.push({
	// 			date: i.date,
	// 			source: "Gumbo",
	// 			injuryId: `${i.game_pk}-${i.bam_id}`,
	// 			...i
	// 		});
	// 	});
	// }

	// Get BIS Data
	if (source === "All" || source === "BIS") {
		injuriesBIS.forEach(i => {
			injuryData.push({
				date: i.date_injured != null ? i.date_injured : i.entry_time,
				source: "BIS",
				injuryId: i.tick_id,
				...i
			});
		});
	}

	// Get Report Data
	if (evaluations && (source === "All" || source === "Report")) {
		evaluations.forEach(e => {
			if (e.injury_notes != null && e.injury_notes.trim() !== "") {
				injuryData.push({
					date: e.seen_date,
					source: "Report",
					note: e.injury_notes,
					seen_date: e.seen_date,
					scout_first_name: e.scout ? e.scout.first_name : "",
					scout_last_name: e.scout ? e.scout.last_name : "",
					eval_id: e.eval_id,
					injuryId: e.eval_id
				});
			}
		});
	}

	// Group by year
	const injuryDataByYear = {};
	injuryData.forEach(injDatum => {
		injDatum.date = dayjs(injDatum.date);
		const injYear = injDatum.date.year();
		if (!injuryDataByYear.hasOwnProperty(injYear)) {
			injuryDataByYear[injYear] = {
				entries: [],
				stints: 0,
				days: 0,
				year: injYear,
				report_notes: 0,
				bis_injuries: 0
			};
		}
		const yearData = injuryDataByYear[injYear];
		yearData.entries.push(injDatum);
		if (injDatum.source === "EBIS") {
			yearData.stints += 1;
			yearData.days += injDatum.days_missed;
		} else if (injDatum.source === "Report") {
			yearData.report_notes += 1;
		} else if (injDatum.source === "BIS") {
			yearData.bis_injuries += 1;
		}
	});

	// Determine injury selected
	let injury = null;
	if (injuriesEbis && injuryId && injurySource) {
		const injuryTmp = injuryData.filter(
			e =>
				e.source === injurySource &&
				e.injuryId === (injurySource === "Report" ? injuryId : parseInt(injuryId, 10))
		);
		if (injuryTmp.length === 1) {
			injury = injuryTmp[0];
		}
	}
	const title = injurySource === "Report" ? "Report Injury Note Details" : "Injury Details";

	const dropdownOptions = SOURCES.map(source => ({ value: source, display: source }));

	return (
		<React.Fragment>
			<Card>
				<ColoredCardHeader
					colorScheme={colorSchemeGroup.secondary}
					text={"Career Summary"}
					style={style?.cardHeader}
				>
					<DropdownSelect
						buttonTitle={"Source"}
						colorScheme={colorSchemeGroup.secondary}
						onSelect={setSource}
						options={dropdownOptions}
						style={{ paddingRight: 10 }}
						value={source}
					/>
				</ColoredCardHeader>
				{fetchingInjuryInfo && <div className="loading-item" style={{ height: 150, width: "100%" }} />}
				{!fetchingInjuryInfo && (
					<AutoFitTable
						columns={columns}
						defaultSortColumn={"Year"}
						defaultSortState={"desc"}
						onRowClick={onRowClick}
						rows={Object.values(injuryDataByYear)}
					/>
				)}
			</Card>
			{year != null && (
				<Card>
					<ColoredCardHeader
						colorScheme={colorSchemeGroup.secondary}
						text={`${year} Injuries`}
						style={style?.cardHeader}
					/>
					{fetchingInjuryInfo && <div className="loading-item" style={{ height: 150, width: "100%" }} />}
					{!fetchingInjuryInfo && (
						<AutoFitTable
							columns={yearColumns}
							defaultSortColumn={"Date"}
							defaultSortState={"desc"}
							onRowClick={onRowClickDetail}
							rows={injuryDataByYear.hasOwnProperty(year) ? injuryDataByYear[year].entries : []}
						/>
					)}
				</Card>
			)}
			{year != null && injuryId != null && injurySource != null && (
				<Card>
					<ColoredCardHeader
						colorScheme={colorSchemeGroup.secondary}
						text={title}
						style={style?.cardHeader}
					/>
					{(injury == null || fetchingInjuryInfo) && (
						<div className="loading-item" style={{ height: 150, width: "100%" }} />
					)}
					{!(injury == null || fetchingInjuryInfo) && (
						<InjuryDetails
							colorSchemeGroup={colorSchemeGroup}
							injury={injury}
							philId={philId}
							viewClassification={viewClassification}
						/>
					)}
				</Card>
			)}
		</React.Fragment>
	);
};

InjuriesPage.propTypes = {
	colorSchemeGroup: PropTypes.object,
	evaluations: PropTypes.array | null | undefined,
	fetchingBIS: PropTypes.bool,
	fetchingEbis: PropTypes.bool,
	fetchingEvals: PropTypes.bool,
	injuriesBIS: PropTypes.array,
	injuriesEbis: PropTypes.array,
	philId: PropTypes.number
};
