import React, { FC, useEffect, useState } from "react";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

import { calculateSpRarFromRaa, calculateRpRarFromRaa } from "_react/projections/pro/_helpers";
import {
	fetchSimpleHitterProjections,
	fetchSimplePitcherProjections,
	SimpleHitterProjectionsBasicSchema
} from "_react/shared/data_models/projections/_network";
import { fetchReplacementLevelActive } from "_react/shared/data_models/phred/_network";
import { fetchBlendedSurplusValues } from "_react/shared/data_models/surplus_value/_network";
import { IBlendedSurplusValue } from "_react/shared/data_models/surplus_value/_types";
import { TReplacementLevelActive } from "_react/shared/data_models/phred/_types";
import { TSimpleHitterProjections, TSimplePitcherProjections } from "_react/shared/data_models/projections/_types";
import { useProjectionsSeason } from "_react/shared/_helpers/hooks";

import {
	ProjectionValueContainer,
	ProjectionValueText,
	SurplusValueTextNoValue,
	SurplusValueLoading
} from "_react/playerpage/surplus_value/_styles";

type SurplusValueBoxProps = {
	bamId: number;
	colorSchemeGroup?: ColorSchemeGroup;
};

export const SurplusValueBox: FC<SurplusValueBoxProps> = ({ bamId, colorSchemeGroup = defaultColorScheme }) => {
	const [projectionsSeason, ros, isFetchingProjectionsSeason] = useProjectionsSeason();

	const [blendedSurplusValue, setBlendedSurplusValue] = useState<IBlendedSurplusValue | undefined>();
	const [simpleHitterProjections, setSimpleHitterProjections] = useState<TSimpleHitterProjections | undefined>();
	const [simplePitcherProjections, setSimplePitcherProjections] = useState<TSimplePitcherProjections | undefined>();
	const [replacementLevelActive, setReplacementLevelActive] = useState<TReplacementLevelActive | undefined>();
	const [loadingBlendedSurplusValue, setLoadingBlendedSurplusValue] = useState(true);
	const [loadingSimpleHitterProjections, setLoadingSimpleHitterProjections] = useState(true);
	const [loadingSimplePitcherProjections, setLoadingSimplePitcherProjections] = useState(true);
	const [loadingReplacementLevelActive, setLoadingReplacementLevelActive] = useState(true);

	useEffect(() => {
		setLoadingBlendedSurplusValue(true);
		fetchBlendedSurplusValues({ bamId: bamId }).then((newSurplusValue: Array<IBlendedSurplusValue> | undefined) => {
			setBlendedSurplusValue(newSurplusValue?.length ? newSurplusValue[0] : undefined);
			setLoadingBlendedSurplusValue(false);
		});
	}, [bamId]);

	useEffect(() => {
		if (!isFetchingProjectionsSeason) {
			setLoadingSimpleHitterProjections(true);
			fetchSimpleHitterProjections({
				bamId: bamId,
				season: projectionsSeason,
				ros: ros === 1,
				schema: SimpleHitterProjectionsBasicSchema
			}).then((projections: Array<TSimpleHitterProjections> | undefined) => {
				setSimpleHitterProjections(projections?.length ? projections[0] : undefined);
				setLoadingSimpleHitterProjections(false);
			});
		}
	}, [bamId, projectionsSeason, ros, isFetchingProjectionsSeason]);

	useEffect(() => {
		if (!isFetchingProjectionsSeason) {
			setLoadingSimplePitcherProjections(true);
			fetchSimplePitcherProjections({
				bamId: bamId,
				season: projectionsSeason,
				ros: ros === 1,
				schema: SimpleHitterProjectionsBasicSchema
			}).then((projections: Array<TSimplePitcherProjections> | undefined) => {
				setSimplePitcherProjections(projections?.length ? projections[0] : undefined);
				setLoadingSimplePitcherProjections(false);
			});
		}
	}, [bamId, projectionsSeason, ros, isFetchingProjectionsSeason]);

	useEffect(() => {
		setLoadingReplacementLevelActive(true);
		fetchReplacementLevelActive({ sort: "date", isSortDescending: true, limit: 1 }).then(
			(replacementLevels: Array<TReplacementLevelActive> | undefined) => {
				setReplacementLevelActive(replacementLevels?.length ? replacementLevels[0] : undefined);
				setLoadingReplacementLevelActive(false);
			}
		);
	}, []);

	const isLoadingData =
		loadingBlendedSurplusValue ||
		loadingSimpleHitterProjections ||
		loadingSimplePitcherProjections ||
		loadingReplacementLevelActive ||
		isFetchingProjectionsSeason;
	const isNoProjections =
		!isLoadingData &&
		blendedSurplusValue?.blendedSv == null &&
		simpleHitterProjections?.totalBattingRar == null &&
		simplePitcherProjections?.projSpRaa == null &&
		simplePitcherProjections?.projRpRaa == null;

	return (
		<Card>
			<ColoredCardHeader colorScheme={colorSchemeGroup.secondary} text={"Projected Value"} />
			{isLoadingData && <SurplusValueLoading className="loading-item" />}
			{!isLoadingData && !isNoProjections && (
				<>
					<ProjectionValueContainer>
						{simpleHitterProjections?.totalBattingRar != null && (
							<ProjectionValueText>
								{simpleHitterProjections.season} Total RAR:{" "}
								{simpleHitterProjections.totalBattingRar.toFixed(1)}
							</ProjectionValueText>
						)}
						{simplePitcherProjections?.projSpRaa != null && replacementLevelActive != null && (
							<ProjectionValueText>
								{simplePitcherProjections.season} SP RAR:{" "}
								{calculateSpRarFromRaa(
									simplePitcherProjections.projSpRaa,
									replacementLevelActive
								).toFixed(1)}
							</ProjectionValueText>
						)}
						{simplePitcherProjections?.projRpRaa != null && replacementLevelActive != null && (
							<ProjectionValueText>
								{simplePitcherProjections.season} RP RAR:{" "}
								{calculateRpRarFromRaa(
									simplePitcherProjections.projRpRaa,
									replacementLevelActive
								).toFixed(1)}
							</ProjectionValueText>
						)}
						{blendedSurplusValue?.blendedSv != null && (
							<ProjectionValueText>
								Surplus Value: ${blendedSurplusValue.blendedSv.toFixed(1)}M
							</ProjectionValueText>
						)}
					</ProjectionValueContainer>
				</>
			)}
			{isNoProjections && (
				<ProjectionValueContainer>
					<SurplusValueTextNoValue>No Projected Value</SurplusValueTextNoValue>
				</ProjectionValueContainer>
			)}
		</Card>
	);
};
