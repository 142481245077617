import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function TrendingUp({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z" />
			<path fill={"none"} d="M0 0h24v24H0z" />
		</IconSVG>
	);
}
