import { CancelToken } from "axios";
import { TJsonMessage } from "utils/tsutils";
import axios from "_redux/_utils/_axios";

import { TRockyNote, TRockyNoteStructure } from "_react/shared/data_models/notes/_types";

export type TNotesGetQueryParams = {
	playerId?: number;
	teamId?: number;
};

export type TNotesPostQueryParams = {
	teamPage?: boolean;
};

export type TNotesPutQueryParams = {
	teamPage?: boolean;
};

export type TNotesDeleteQueryParams = {
	noteId?: string;
	teamId?: number;
};

export const fetchNotesStructure = (cancelToken?: CancelToken) => {
	const path = `/notes/structure`;
	return axios
		.get(path, { cancelToken })
		.then(response => response.data as Array<TRockyNoteStructure>)
		.catch(error => {
			return axios.isCancel(error) ? Promise.resolve(undefined) : Promise.reject(error);
		});
};

export const fetchNotes = (queryParams?: TNotesGetQueryParams, cancelToken?: CancelToken) => {
	let queryParamsString = "?";
	if (queryParams)
		Object.entries(queryParams).forEach((param: [string, number | undefined]) => {
			if (param[1] !== undefined)
				queryParamsString = queryParamsString + `${param[0]}=${encodeURIComponent(param[1])}&`;
		});
	const path = `/notes${queryParamsString}`;

	return axios
		.get(path, { cancelToken })
		.then(response => response.data as Array<TRockyNote>)
		.catch(error => {
			return axios.isCancel(error) ? Promise.resolve(undefined) : Promise.reject(error);
		});
};

export const createNote = (note: TRockyNote, queryParams?: TNotesPostQueryParams, cancelToken?: CancelToken) => {
	let queryParamsString = "?";
	if (queryParams)
		Object.entries(queryParams).forEach((param: [string, boolean | undefined]) => {
			if (param[1] !== undefined)
				queryParamsString = queryParamsString + `${param[0]}=${encodeURIComponent(param[1])}&`;
		});
	const path = `/notes${queryParamsString}`;

	return axios
		.post(path, note, { cancelToken })
		.then(response => response.data as TRockyNote)
		.catch(error => {
			return axios.isCancel(error) ? Promise.resolve(undefined) : Promise.reject(error);
		});
};

export const updateNote = (note: TRockyNote, queryParams?: TNotesPutQueryParams, cancelToken?: CancelToken) => {
	let queryParamsString = "?";
	if (queryParams)
		Object.entries(queryParams).forEach((param: [string, boolean | undefined]) => {
			if (param[1] !== undefined)
				queryParamsString = queryParamsString + `${param[0]}=${encodeURIComponent(param[1])}&`;
		});
	const path = `/notes${queryParamsString}`;

	return axios
		.put(path, note, { cancelToken })
		.then(response => response.data as TRockyNote)
		.catch(error => {
			return axios.isCancel(error) ? Promise.resolve(undefined) : Promise.reject(error);
		});
};

export const deleteNote = (queryParams?: TNotesDeleteQueryParams, cancelToken?: CancelToken) => {
	let queryParamsString = "?";
	if (queryParams)
		Object.entries(queryParams).forEach((param: [string, number | string | undefined]) => {
			if (param[1] !== undefined)
				queryParamsString = queryParamsString + `${param[0]}=${encodeURIComponent(param[1])}&`;
		});
	const path = `/notes${queryParamsString}`;

	return axios
		.delete(path, { cancelToken })
		.then(response => response.data as TJsonMessage)
		.catch(error => {
			return axios.isCancel(error) ? Promise.resolve(undefined) : Promise.reject(error);
		});
};
