import dayjs from "dayjs";
import { getAgeFromBirthDate } from "utils/helpers";
import { TColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER } from "_react/shared/ui/presentation/components/Table/_constants";

import { PLAYER_AGE_CUTOFF } from "_react/shared/_constants/cutoff_dates";
import { TSeasonalSurplusValue } from "_react/shared/data_models/surplus_value/_types";
import { SV_COLOR_GRADIENT_FUNCTION } from "_react/playerpage/_constants";
import {
	convertToPercentage,
	convertToCurrency
} from "_react/shared/ui/data/tables/SeasonalSurplusValuesTable/_helpers";
import {
	TOOLTIP_CLUB_DECLINED_PERC,
	TOOLTIP_MINORS_FORTY_MAN_PERC,
	TOOLTIP_MJ_REPLACEMENT_PERC,
	TOOLTIP_NON_TENDER_PERC,
	TOOLTIP_OFF_ROSTER_PERC,
	TOOLTIP_PLAYER_DECLINED_PERC,
	TOOLTIP_QO_COMP_PERC,
	TOOLTIP_ROSTER_PERC,
	TOOLTIP_ROSTER_SALARY,
	TOOLTIP_SURPLUS_VALUE_YEARLY
} from "_react/shared/_constants/tooltips";

export const NULL_FILLER_TEXT = "";

export const SEASON_COLUMN = {
	value: "projectedSeason",
	label: "Season",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true
};

export const AGE_COLUMN = {
	value: "age",
	label: "Age",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	tooltip: `Age on ${PLAYER_AGE_CUTOFF}`,
	getValueFunction: (row: TSeasonalSurplusValue) => {
		if (row?.birthDate == null) return NULL_FILLER_TEXT;
		const age: string = getAgeFromBirthDate(
			row.birthDate,
			dayjs(`${row.projectedSeason}/${PLAYER_AGE_CUTOFF}`)
		) as string;
		if (isNaN(parseFloat(age))) return NULL_FILLER_TEXT;
		return age.includes(".") ? age : `${age}.0`;
	},
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.projectedSeason
};

const ROSTER_SALARY_COLUMN = {
	value: "rosterSalary",
	label: "Roster Salary",
	tooltip: TOOLTIP_ROSTER_SALARY,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.avgCostRoster !== null ? convertToCurrency(row.avgCostRoster) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.avgCostRoster
};

const SV_COLUMN = {
	value: "sv",
	label: "SV",
	tooltip: TOOLTIP_SURPLUS_VALUE_YEARLY,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.surplusValueDiscounted !== null ? convertToCurrency(row.surplusValueDiscounted) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.surplusValueDiscounted,
	getColorGradientValueFunction: (row: TSeasonalSurplusValue) => row.surplusValueDiscounted,
	colorGradientFunction: SV_COLOR_GRADIENT_FUNCTION
};

const ROSTER_PERCENTAGE_COLUMN = {
	value: "rosterPercentage",
	label: "Roster %",
	tooltip: TOOLTIP_ROSTER_PERC,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.pctSimsRoster !== null ? convertToPercentage(row.pctSimsRoster, true) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.pctSimsRoster
};

const MJ_REPLACEMENT_COLUMN = {
	value: "mjReplacement",
	label: "MJ Repl %",
	tooltip: TOOLTIP_MJ_REPLACEMENT_PERC,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.pctMjReplacement !== null ? convertToPercentage(row.pctMjReplacement) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.pctMjReplacement
};

const MINORS_ON_40_COLUMN = {
	value: "minorsOn40",
	label: "40-Man MiL %",
	tooltip: TOOLTIP_MINORS_FORTY_MAN_PERC,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.pctMinorsOn40 !== null ? convertToPercentage(row.pctMinorsOn40) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.pctMinorsOn40
};

const NON_TENDERED_COLUMN = {
	value: "nonTendered",
	label: "Non-Tender %",
	tooltip: TOOLTIP_NON_TENDER_PERC,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.pctNonTendered !== null ? convertToPercentage(row.pctNonTendered) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.pctNonTendered
};

const CLUB_OPTION_DECLINED_COLUMN = {
	value: "clubOptionDeclines",
	label: "Club Decl %",
	tooltip: TOOLTIP_CLUB_DECLINED_PERC,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.pctClubOptionDeclined !== null ? convertToPercentage(row.pctClubOptionDeclined) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.pctClubOptionDeclined
};

const PLAYER_OPTION_DECLINED_COLUMN = {
	value: "playerOptionDeclines",
	label: "Player Decl %",
	tooltip: TOOLTIP_PLAYER_DECLINED_PERC,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.pctPlayerOptionDeclined !== null ? convertToPercentage(row.pctPlayerOptionDeclined) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.pctPlayerOptionDeclined
};

const QUALIFYING_OFFER_COMP_COLUMN = {
	value: "qualifyingOfferComp",
	label: "Q.O. Comp %",
	tooltip: TOOLTIP_QO_COMP_PERC,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.pctQualifyingOfferComp !== null ? convertToPercentage(row.pctQualifyingOfferComp) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.pctQualifyingOfferComp
};

const OFF_ROSTER_COLUMN = {
	value: "offRoster",
	label: "Off Roster %",
	tooltip: TOOLTIP_OFF_ROSTER_PERC,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.pctOffRosterOther !== null ? convertToPercentage(row.pctOffRosterOther) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.pctOffRosterOther
};

// This column is on the original google sheet table, but not shown in Rocky at this point
const _GAMES_HEALTHY_COLUMN = {
	value: "gamesHealty",
	label: "Games Healthy",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.gamesHealthyRoster !== null ? Math.round(row.gamesHealthyRoster).toString() : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.gamesHealthyRoster
};

// This column is on the original google sheet table, but not shown in Rocky at this point
const _PA_TBF_COLUMN = {
	value: "paTbf",
	label: "PA / TBF",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSeasonalSurplusValue) =>
		row.playingTimeRoster !== null ? Math.round(row.playingTimeRoster).toString() : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TSeasonalSurplusValue) => row.playingTimeRoster
};

export const SEASONAL_SURPLUS_VALUE_COLUMNS: Array<TColumn<TSeasonalSurplusValue, keyof TSeasonalSurplusValue>> = [
	SEASON_COLUMN,
	AGE_COLUMN,
	ROSTER_SALARY_COLUMN,
	SV_COLUMN,
	ROSTER_PERCENTAGE_COLUMN
];

export const SEASONAL_SURPLUS_VALUE_PERCENTAGE_COLUMNS: Array<TColumn<
	TSeasonalSurplusValue,
	keyof TSeasonalSurplusValue
>> = [
	SEASON_COLUMN,
	AGE_COLUMN,
	ROSTER_PERCENTAGE_COLUMN,
	MJ_REPLACEMENT_COLUMN,
	MINORS_ON_40_COLUMN,
	NON_TENDERED_COLUMN,
	CLUB_OPTION_DECLINED_COLUMN,
	PLAYER_OPTION_DECLINED_COLUMN,
	QUALIFYING_OFFER_COMP_COLUMN,
	OFF_ROSTER_COLUMN
];
