import React, { CSSProperties, useState, useEffect } from "react";

import { Button } from "_react/shared/legacy/ui/Button";
import { Checkbox } from "_react/shared/legacy/ui/Checkbox";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

import { DISABLED_OPACITY } from "_react/playerplan/shared/_constants";
import {
	SmallCircularProgressRightStyle,
	PaddedDiv,
	FloatRightDiv,
	LeftButtonStyle,
	FlexCenteredColumnDiv,
	ModalContainer,
	FlexCenteredDiv,
	CheckboxRightStyle
} from "_react/playerplan/shared/_styles";
import { TPlayerPlanGoal, TSeasonsForm } from "_react/playerplan/shared/_types";
import { getGoalSeasons } from "_react/playerplan/shared/_helpers";

export type TAddSeasonsModalStyle = {
	checkbox?: CSSProperties;
};

export type TAddSeasonsModalProps = {
	goal: TPlayerPlanGoal;
	secondaryGoal?: TPlayerPlanGoal;
	addSeasons: (goalSeasons: TSeasonsForm) => void;
	isAddingSeasons: boolean;
	additionalSeasonOpts: Array<number>;
	isGoalActionsDisabled: boolean;
	setIsSeasonsModalOpen: (isSeasonsModalOpen: boolean) => void;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TAddSeasonsModalStyle;
};

export const AddSeasonsModal = ({
	goal,
	secondaryGoal,
	addSeasons,
	isAddingSeasons,
	additionalSeasonOpts,
	isGoalActionsDisabled,
	setIsSeasonsModalOpen,
	colorSchemeGroup = defaultColorScheme,
	style
}: TAddSeasonsModalProps) => {
	const [isConfirmingSave, setIsConfirmingSave] = useState<boolean>(false);
	const [additionalSeasons, setAdditionalSeasons] = useState<Record<number, boolean>>({});

	const toggleSeason = (season: number) => {
		setAdditionalSeasons((prevAdditionalSeasons: Record<number, boolean>) => {
			return {
				...prevAdditionalSeasons,
				[season]: !prevAdditionalSeasons[season]
			};
		});
	};

	const isAdditionalSeasonSelected: boolean =
		[...additionalSeasonOpts.filter((season: number) => additionalSeasons[season])].length > 0;

	useEffect(() => {
		setAdditionalSeasons({});
	}, [goal]);

	return (
		<>
			<ModalContainer>{`Select additional season(s)`}</ModalContainer>
			<FlexCenteredColumnDiv>
				{additionalSeasonOpts.map((season: number) => (
					<FlexCenteredDiv key={season}>
						<Checkbox
							checked={additionalSeasons[season]}
							onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
								toggleSeason(season);
							}}
							style={{ ...CheckboxRightStyle, ...style?.checkbox }}
							colorScheme={colorSchemeGroup.tertiary}
						/>
						<div>{`${season}`}</div>
					</FlexCenteredDiv>
				))}
			</FlexCenteredColumnDiv>
			<PaddedDiv>
				{isAddingSeasons ? (
					<CircularProgress center={false} style={SmallCircularProgressRightStyle} />
				) : isConfirmingSave ? (
					<FloatRightDiv>
						<Button
							style={LeftButtonStyle}
							disabled={isGoalActionsDisabled || !isAdditionalSeasonSelected}
							onClick={() => {
								setIsConfirmingSave(false);
								addSeasons({
									playerPlanGoalIds: secondaryGoal ? [goal.id, secondaryGoal.id] : [goal.id],
									seasons: [
										...additionalSeasonOpts.filter((season: number) => additionalSeasons[season]),
										...(getGoalSeasons(goal) ?? [])
									]
								});
								setAdditionalSeasons({});
								setIsSeasonsModalOpen(false);
							}}
							title="Confirm"
							colorScheme={colorSchemeGroup.secondary}
							disabledOpacity={DISABLED_OPACITY}
						/>
						<Button
							disabled={false}
							onClick={() => setIsConfirmingSave(false)}
							title="Cancel"
							colorScheme={colorSchemeGroup.primary}
						/>
					</FloatRightDiv>
				) : (
					<FloatRightDiv>
						<Button
							disabled={false}
							onClick={() => setIsConfirmingSave(true)}
							title="Save"
							colorScheme={colorSchemeGroup.secondary}
						/>
					</FloatRightDiv>
				)}
			</PaddedDiv>
		</>
	);
};
