import styled from "@emotion/styled";
import { Box, chakra } from "@chakra-ui/react";

import Cancel from "_react/shared/legacy/ui/icons/Cancel";
import { TPartialStyles } from "utils/tsutils";

export const ToolbarContainer = styled.div(`
	display: flex;
	flex-wrap: wrap;
	margin: 5px;
	overflow: visible;
	border: 1px solid rgb(200, 200, 200);
	border-radius: 4px;
	box-shadow: 2px 2px 2px 2px #E8E8E8, -2px -2px 2px 2px #E8E8E8;
	& > div {
		margin: 5px;
	}
`);

export const FiltersContainer = styled.div<TPartialStyles<"flex" | "maxWidth">>(
	({ flex = 1, maxWidth = "100%" }) => `
	flex: ${flex};
	display: flex;
	max-width: ${maxWidth};
	flex-wrap: wrap;
	margin: 5px;
	& > div {
		margin: 5px;
	}
`
);

export const FiltersContainerBox = chakra(Box, {
	baseStyle: {
		flex: 1,
		display: "flex",
		maxWidth: "100%",
		flexWrap: "wrap",
		margin: 1,
		"& > div": {
			margin: 1
		}
	}
});

export const FilterLabel = styled("div")(`
	font-size: 11px;
	font-weight: 600;
`);

export const FilterLabelBox = chakra(Box, {
	baseStyle: {
		fontSize: "xs",
		fontWeight: "bold"
	}
});

export const LabelSpan = styled.span({
	fontSize: "14px",
	padding: "1em"
});

export const RangeContainerDiv = styled.div({
	width: "30%",
	display: "inline-flex",
	overflowX: "hidden"
});

export const FilterContainer = styled.div<
	TPartialStyles<"backgroundColor" | "border" | "padding" | "minWidth" | "maxWidth">
>(
	({
		backgroundColor = "rgb(223, 236, 247)",
		border = "1px solid rgba(32, 33, 36, .05)",
		padding = "5px",
		minWidth = "200px",
		maxWidth = "250px"
	}) => `
	flex: 1;
	min-width: ${minWidth};
	max-width: ${maxWidth};
	padding: ${padding};
	border-radius: 5px;
    border: ${border};
	background-color: ${backgroundColor};
	box-sizing: border-box;
	position: relative;
`
);

export const FilterContainerBox = chakra(Box, {
	baseStyle: {
		backgroundColor: "blue.50",
		border: "1px solid rgba(32, 33, 36, .05)",
		padding: 1,
		minWidth: 48,
		maxWidth: 64,
		flex: 1,
		borderRadius: 1,
		boxSizing: "border-box",
		position: "relative"
	}
});

export const CloseIcon = styled(Cancel)`
	position: absolute;
	top: 2px;
	right: 2px;
	padding: 0px;
	height: 14px;
	width: 14px;
	fill: rgb(150, 150, 150);
	cursor: pointer;
	transition: fill 0.2s linear;
	:hover {
		fill: rgb(50, 50, 50);
		transition: fill 0.2s linear;
	}
	:focus {
		fill: rgb(50, 50, 50);
		transition: fill 0.2s linear;
		outline: none;
	}
`;

export const Button = styled.div<{
	disabled?: boolean;
	width?: string;
	height?: string;
	selected?: boolean;
	size?: "small" | "medium" | "large";
	color?: "white" | "red" | "green";
	margin?: string;
}>(
	({
		disabled = false,
		color = "white",
		width = "100%",
		height = "28px",
		selected,
		size = "medium",
		margin = "0px"
	}) => `
	align-items: center;
	background-color: ${
		disabled
			? "rgb(150, 150, 150)"
			: color === "white"
			? "rgb(255, 255, 255)"
			: color === "red"
			? "rgb(210, 25, 25)"
			: "rgb(25, 150, 25)"
	};
	margin: ${margin};
	border: 1px solid #dadce0;
	border-radius: 4px;
	box-sizing: border-box;
	color: ${color === "white" ? "rgb(60,64,67)" : "rgb(250, 250, 250)"};
	cursor: ${disabled ? "default" : "pointer"};
	display: flex;
	font-size: ${size === "small" ? ".7rem" : size === "large" ? "1.25rem" : ".9rem"};
	font-weight: 500;
	height: ${height};
	justify-content: center;
	line-height: 20px;
	opacity: ${disabled ? 0.5 : 1};
	outline: none;
	padding: 4px;
	pointer-events: ${disabled ? "none" : ""};
	text-align: center;
	text-transform: uppercase;
	transition: background-color 100ms linear;
	user-select: none;
	width: ${width};
	${
		selected
			? "background-color: rgba(26, 115, 232, .5);"
			: `
			:hover {
				background-color: ${
					color === "white"
						? "rgba(33, 34, 36, .05)"
						: color === "red"
						? "rgba(210, 25, 25, .65)"
						: "rgba(25, 150, 25, .65)"
				};
			}
		`
	}
`
);
