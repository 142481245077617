import { UseToastOptions } from "@chakra-ui/react";

export const DEFAULT_TOAST_ERROR_PROPS = {
	status: "error",
	duration: 10000,
	isClosable: true,
	position: "bottom-left"
} as UseToastOptions;

export const DEFAULT_TOAST_WARNING_PROPS = {
	status: "warning",
	duration: 10000,
	isClosable: true,
	position: "bottom-left"
} as UseToastOptions;

export const DEFAULT_TOAST_SUCCESS_PROPS = {
	status: "success",
	duration: 10000,
	isClosable: true,
	position: "bottom-left"
} as UseToastOptions;
