import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function MergeType(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 96 960 960" {...props}>
			<path
				fill="currentColor"
				d="M664 896 440 672V371L336 475l-57-57 201-201 200 200-57 57-103-103v269l200 200-56 56Zm-367 1-57-56 128-128 56 57-127 127Z"
			/>
		</Icon>
	);
}
