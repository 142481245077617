import styled from "@emotion/styled";
import { TPickStyles } from "utils/tsutils";

type TStyledHeaderCellProps = {
	flex: string | number;
	rowHeight: number;
	sort: boolean | null;
};

type TStyledFilterProps = {
	flex: string | number;
	focused: boolean;
};

export const ContainerDiv = styled.div<TPickStyles<"height">>(({ height }) => ({
	height: height,
	width: "100%",
	display: "flex",
	flexDirection: "column",
	overflow: "auto"
}));

export const AutosizerContainerDiv = styled.div({ flex: 1 });

export const ContainerPrintDiv = styled.div({
	display: "flex",
	flexDirection: "column",
	overflow: "visible",
	boxSizing: "border-box",
	fontSize: "0.75rem"
});

export const TableBorderDiv = styled.div<{ maxHeight?: number; showScrollBars: boolean }>(
	({ maxHeight, showScrollBars = false }) => ({
		border: "1px solid rgb(200, 200, 200)",
		boxSizing: "border-box",
		overflow: "scroll",
		maxHeight,
		"&::-webkit-scrollbar": {
			display: showScrollBars ? undefined : "none"
		},
		"-ms-overflow-style": showScrollBars ? undefined : "none" /* IE and Edge */,
		"scrollbar-width": showScrollBars ? undefined : "none" /* Firefox */
	})
);

export const TableWrapperDiv = styled.table<{
	maxHeight?: number;
	minWidth?: string | number;
}>(({ maxHeight, minWidth = "100%" }) => ({
	position: "relative",
	maxHeight,
	display: "table",
	marginRight: "auto",
	marginLeft: "auto",
	minWidth,
	borderCollapse: "separate",
	borderSpacing: 0
}));

export const LoadMoreDiv = styled.div<{ fullyLoaded: boolean; minWidth?: string | number }>(
	({ fullyLoaded, minWidth }) => ({
		display: fullyLoaded ? "none" : "block",
		minWidth,
		textAlign: "center",
		boxSizing: "content-box"
	})
);

export const StyledHeader = styled("tr")({
	borderBottom: "1px solid rgb(200, 200, 200)",
	cursor: "pointer"
});

export const StyledHeaderCell = styled("th")<TStyledHeaderCellProps>(({ flex, rowHeight, sort }) => ({
	flex,
	textAlign: "center",
	fontWeight: "bold",
	boxSizing: "border-box",
	lineHeight: `${rowHeight + (sort === true ? -4 : 4)}px`,
	userSelect: "none",
	borderBottom: sort === false ? "4px solid rgba(128, 128, 128)" : "",
	borderTop: sort ? "4px solid rgba(128, 128, 128)" : "",
	"&:hover": {
		backgroundColor: "rgb(240, 240, 240)"
	},
	zIndex: 1,
	paddingLeft: "4px",
	paddingRight: "4px",
	position: "sticky",
	top: 0,
	boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)",
	backgroundColor: "white"
}));

type TStyledFilterContainerProps = {
	paddingRight: number;
	show: boolean;
	width: number;
};

export const StyledFilterContainer = styled("div")<TStyledFilterContainerProps>(({ paddingRight, show, width }) => ({
	paddingRight,
	display: show ? "flex" : "none",
	width
}));

export const StyledFilter = styled("div")<TStyledFilterProps>(({ flex }) => ({
	flex,
	minHeight: "35px",
	height: "auto"
}));

export const StyledFilterLabel = styled("div")({
	position: "absolute",
	top: "2px",
	left: "6px",
	fontSize: "11px",
	fontWeight: 600
});

export const StyledFilterInputContainer = styled("div")({
	height: "100%",
	width: "100%",
	padding: "4px 4px 4px 4px",
	border: "1px solid rgb(190, 190, 190)",
	boxSizing: "border-box",
	borderRadius: "4px",
	position: "relative",
	backgroundColor: "rgb(223, 236, 247)"
	// backgroundColor: focused ? "rgba(255, 80, 80, .5)" : "rgb(223, 236, 247)"
});

export const StyledFilterTextInput = styled("input")({
	width: "100%",
	height: "100%",
	boxSizing: "border-box",
	border: "1px solid rgb(190, 190, 190)",
	borderRadius: "4px",
	fontSize: "12px"
});
export const GRAY_TEXT = "rgb(112, 117, 122)";

// print styles
const ROW_HEIGHT = 25;

export const StyledPrintHeader = styled.div({
	display: "flex",
	height: ROW_HEIGHT,
	borderBottom: "1px solid rgb(200, 200, 200)",
	cursor: "pointer"
});

export const LoadMoreButtonStyle = { margin: "auto", marginTop: "4px", marginBottom: "4px" };
