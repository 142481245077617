import { round10 } from "_react/shared/_helpers/numbers";
import { aggregateStat } from "_react/shared/_helpers/stats";
import { MODEL_EV_LA, BATS_L, BATS_OVERALL, BATS_R } from "_react/shared/data_models/baseline_hit_probs/_constants";
import { IPlayerSeasonXwoba, IPlayerSeasonXwobaByTeam } from "_react/shared/data_models/baseline_hit_probs/_types";
import {
	IPitchOutcomeProbabilitiesPa,
	IPitchOutcomeProbabilitiesPaByTeam
} from "_react/shared/data_models/pitch_outcome_probabilities/_types";
import { SOURCE_GUMBO, VALID_PRO_LEVELS } from "_react/shared/data_models/stats/_constants";
import { IStatsPlayerPitching, IStatsPlayerPitchingByTeam } from "_react/shared/data_models/stats/_types";
import { GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP } from "_react/shared/ui/data/tables/PitcherPaOutcomesTable/_constants";
import {
	ICombinedPitcherPaOutcomesData,
	TPitcherPaOutcomesRow
} from "_react/shared/ui/data/tables/PitcherPaOutcomesTable/_types";

//
// DATA FORMATTING
//

export const formatWobacon = (value?: string | number | null) => {
	if (value == null) return value;
	if (typeof value === "string") value = parseFloat(value);
	return round10(value, -3, true);
};

//
// DATA PREPROCESSING
//

// Given an array of IStatsPlayerPitching filtered by player, dedupe the data based on source
export const dedupePlayerStatsPlayerPitchingBySource = (
	statsPlayerPitching: Array<IStatsPlayerPitching>
): Array<IStatsPlayerPitching> => {
	// Populate a record to track relevant statsPlayerPitching instances
	// The record is keyed on "season-gameType"
	const statsPlayerPitchingRecord: Record<string, IStatsPlayerPitching> = {};
	statsPlayerPitching.forEach((s: IStatsPlayerPitching) => {
		// If season-gameType does not exist, or if season-gameType has the highest priority source,
		// update/create an entry in the record
		if (!(`${s.season}-${s.gameType}` in statsPlayerPitchingRecord) || s.source === SOURCE_GUMBO)
			statsPlayerPitchingRecord[`${s.season}-${s.gameType}`] = s;
	});

	// Return based on the nested values of our record.
	return Object.values(statsPlayerPitchingRecord);
};

// Given an array of IStatsPlayerPitchingByTeam filtered by player, dedupe the data based on source
// Remove teams with invalid levels
export const dedupePlayerStatsPlayerPitchingByTeamAndSource = (
	statsPlayerPitchingByTeam: Array<IStatsPlayerPitchingByTeam>
): Array<IStatsPlayerPitchingByTeam> => {
	// Populate a record to track relevant statsPlayerPitchingByTeam instances
	// The record is keyed on "season-teamId-gameType"
	const statsPlayerPitchingByTeamRecord: Record<string, IStatsPlayerPitchingByTeam> = {};
	statsPlayerPitchingByTeam.forEach((s: IStatsPlayerPitchingByTeam) => {
		// Filter teams down to valid levels
		if (!VALID_PRO_LEVELS.includes(s.teamBam?.level ?? "")) return;
		// If season-teamId-gameType does not exist, or if season-teamId-gameType has the highest priority source,
		// update/create an entry in the record
		if (!(`${s.season}-${s.teamId}-${s.gameType}` in statsPlayerPitchingByTeamRecord) || s.source === SOURCE_GUMBO)
			statsPlayerPitchingByTeamRecord[`${s.season}-${s.teamId}-${s.gameType}`] = s;
	});

	// Return based on the nested values of our record.
	return Object.values(statsPlayerPitchingByTeamRecord);
};

export const aggregateStatsPlayerPitchingRows = (
	prev: ICombinedPitcherPaOutcomesData,
	curr: IStatsPlayerPitching
): ICombinedPitcherPaOutcomesData => {
	return {
		...prev,
		tbf: (curr.tbf ?? 0) + (prev.tbf ?? 0),
		tbfVl: (curr.tbfVl ?? 0) + (prev.tbfVl ?? 0),
		tbfVr: (curr.tbfVr ?? 0) + (prev.tbfVr ?? 0),
		inPlay: (curr.inPlay ?? 0) + (prev.inPlay ?? 0),
		inPlayVl: (curr.inPlayVl ?? 0) + (prev.inPlayVl ?? 0),
		inPlayVr: (curr.inPlayVr ?? 0) + (prev.inPlayVr ?? 0),
		kPct: aggregateStat(prev.tbf ?? 0, prev.kPct ?? null, curr.tbf ?? 0, curr.kPct ?? null),
		kPctVl: aggregateStat(prev.tbfVl ?? 0, prev.kPctVl ?? null, curr.tbfVl ?? 0, curr.kPctVl ?? null),
		kPctVr: aggregateStat(prev.tbfVr ?? 0, prev.kPctVr ?? null, curr.tbfVr ?? 0, curr.kPctVr ?? null),
		bbPct: aggregateStat(prev.tbf ?? 0, prev.bbPct ?? null, curr.tbf ?? 0, curr.bbPct ?? null),
		bbPctVl: aggregateStat(prev.tbfVl ?? 0, prev.bbPctVl ?? null, curr.tbfVl ?? 0, curr.bbPctVl ?? null),
		bbPctVr: aggregateStat(prev.tbfVr ?? 0, prev.bbPctVr ?? null, curr.tbfVr ?? 0, curr.bbPctVr ?? null),
		gbPct: aggregateStat(prev.inPlay ?? 0, prev.gbPct ?? null, curr.inPlay ?? 0, curr.gbPct ?? null),
		gbPctVl: aggregateStat(prev.inPlayVl ?? 0, prev.gbPctVl ?? null, curr.inPlayVl ?? 0, curr.gbPctVl ?? null),
		gbPctVr: aggregateStat(prev.inPlayVr ?? 0, prev.gbPctVr ?? null, curr.inPlayVr ?? 0, curr.gbPctVr ?? null),
		hrPct: aggregateStat(prev.inPlay ?? 0, prev.hrPct ?? null, curr.inPlay ?? 0, curr.hrPct ?? null),
		hrPctVl: aggregateStat(prev.inPlayVl ?? 0, prev.hrPctVl ?? null, curr.inPlayVl ?? 0, curr.hrPctVl ?? null),
		hrPctVr: aggregateStat(prev.inPlayVr ?? 0, prev.hrPctVr ?? null, curr.inPlayVr ?? 0, curr.hrPctVr ?? null),
		wobacon: aggregateStat(prev.inPlay ?? 0, prev.wobacon ?? null, curr.inPlay ?? 0, curr.wobacon ?? null),
		wobaconVl: aggregateStat(
			prev.inPlayVl ?? 0,
			prev.wobaconVl ?? null,
			curr.inPlayVl ?? 0,
			curr.wobaconVl ?? null
		),
		wobaconVr: aggregateStat(prev.inPlayVr ?? 0, prev.wobaconVr ?? null, curr.inPlayVr ?? 0, curr.wobaconVr ?? null)
	};
};

export const aggregateCombinedPitcherPaOutcomesDataRows = (
	prev: ICombinedPitcherPaOutcomesData,
	curr: ICombinedPitcherPaOutcomesData
): ICombinedPitcherPaOutcomesData => {
	return {
		...prev,
		// mesa.stats_player_pitching data
		tbf: (curr.tbf ?? 0) + (prev.tbf ?? 0),
		tbfVl: (curr.tbfVl ?? 0) + (prev.tbfVl ?? 0),
		tbfVr: (curr.tbfVr ?? 0) + (prev.tbfVr ?? 0),
		inPlay: (curr.inPlay ?? 0) + (prev.inPlay ?? 0),
		inPlayVl: (curr.inPlayVl ?? 0) + (prev.inPlayVl ?? 0),
		inPlayVr: (curr.inPlayVr ?? 0) + (prev.inPlayVr ?? 0),
		kPct: aggregateStat(prev.tbf ?? 0, prev.kPct ?? null, curr.tbf ?? 0, curr.kPct ?? null),
		kPctVl: aggregateStat(prev.tbfVl ?? 0, prev.kPctVl ?? null, curr.tbfVl ?? 0, curr.kPctVl ?? null),
		kPctVr: aggregateStat(prev.tbfVr ?? 0, prev.kPctVr ?? null, curr.tbfVr ?? 0, curr.kPctVr ?? null),
		bbPct: aggregateStat(prev.tbf ?? 0, prev.bbPct ?? null, curr.tbf ?? 0, curr.bbPct ?? null),
		bbPctVl: aggregateStat(prev.tbfVl ?? 0, prev.bbPctVl ?? null, curr.tbfVl ?? 0, curr.bbPctVl ?? null),
		bbPctVr: aggregateStat(prev.tbfVr ?? 0, prev.bbPctVr ?? null, curr.tbfVr ?? 0, curr.bbPctVr ?? null),
		gbPct: aggregateStat(prev.inPlay ?? 0, prev.gbPct ?? null, curr.inPlay ?? 0, curr.gbPct ?? null),
		gbPctVl: aggregateStat(prev.inPlayVl ?? 0, prev.gbPctVl ?? null, curr.inPlayVl ?? 0, curr.gbPctVl ?? null),
		gbPctVr: aggregateStat(prev.inPlayVr ?? 0, prev.gbPctVr ?? null, curr.inPlayVr ?? 0, curr.gbPctVr ?? null),
		hrPct: aggregateStat(prev.inPlay ?? 0, prev.hrPct ?? null, curr.inPlay ?? 0, curr.hrPct ?? null),
		hrPctVl: aggregateStat(prev.inPlayVl ?? 0, prev.hrPctVl ?? null, curr.inPlayVl ?? 0, curr.hrPctVl ?? null),
		hrPctVr: aggregateStat(prev.inPlayVr ?? 0, prev.hrPctVr ?? null, curr.inPlayVr ?? 0, curr.hrPctVr ?? null),
		wobacon: aggregateStat(prev.inPlay ?? 0, prev.wobacon ?? null, curr.inPlay ?? 0, curr.wobacon ?? null),
		wobaconVl: aggregateStat(
			prev.inPlayVl ?? 0,
			prev.wobaconVl ?? null,
			curr.inPlayVl ?? 0,
			curr.wobaconVl ?? null
		),
		wobaconVr: aggregateStat(
			prev.inPlayVr ?? 0,
			prev.wobaconVr ?? null,
			curr.inPlayVr ?? 0,
			curr.wobaconVr ?? null
		),
		// mesa.pop_pa data
		xK: aggregateStat(prev.tbf ?? 0, prev.xK ?? null, curr.tbf ?? 0, curr.xK ?? null),
		xKVl: aggregateStat(prev.tbfVl ?? 0, prev.xKVl ?? null, curr.tbfVl ?? 0, curr.xKVl ?? null),
		xKVr: aggregateStat(prev.tbfVr ?? 0, prev.xKVr ?? null, curr.tbfVr ?? 0, curr.xKVr ?? null),
		xUbb: aggregateStat(prev.tbf ?? 0, prev.xUbb ?? null, curr.tbf ?? 0, curr.xUbb ?? null),
		xUbbVl: aggregateStat(prev.tbfVl ?? 0, prev.xUbbVl ?? null, curr.tbfVl ?? 0, curr.xUbbVl ?? null),
		xUbbVr: aggregateStat(prev.tbfVr ?? 0, prev.xUbbVr ?? null, curr.tbfVr ?? 0, curr.xUbbVr ?? null),
		xGb: aggregateStat(prev.inPlay ?? 0, prev.xGb ?? null, curr.inPlay ?? 0, curr.xGb ?? null),
		xGbVl: aggregateStat(prev.inPlayVl ?? 0, prev.xGbVl ?? null, curr.inPlayVl ?? 0, curr.xGbVl ?? null),
		xGbVr: aggregateStat(prev.inPlayVr ?? 0, prev.xGbVr ?? null, curr.inPlayVr ?? 0, curr.xGbVr ?? null),
		xHr: aggregateStat(prev.inPlay ?? 0, prev.xHr ?? null, curr.inPlay ?? 0, curr.xHr ?? null),
		xHrVl: aggregateStat(prev.inPlayVl ?? 0, prev.xHrVl ?? null, curr.inPlayVl ?? 0, curr.xHrVl ?? null),
		xHrVr: aggregateStat(prev.inPlayVr ?? 0, prev.xHrVr ?? null, curr.inPlayVr ?? 0, curr.xHrVr ?? null),
		// player_season.xwoba data
		xwobacon: aggregateStat(prev.inPlay ?? 0, prev.xwobacon ?? null, curr.inPlay ?? 0, curr.xwobacon ?? null),
		xwobaconVl: aggregateStat(
			prev.inPlayVl ?? 0,
			prev.xwobaconVl ?? null,
			curr.inPlayVl ?? 0,
			curr.xwobaconVl ?? null
		),
		xwobaconVr: aggregateStat(
			prev.inPlayVr ?? 0,
			prev.xwobaconVr ?? null,
			curr.inPlayVr ?? 0,
			curr.xwobaconVr ?? null
		)
	};
};

// Converts raw stats player batting data to a record mapping
// season-gameType to ICombinedPitcherPaOutcomesData
export const createStatsPlayerPitchingRecord = (
	statsPlayerPitching: Array<IStatsPlayerPitching>
): Record<string, ICombinedPitcherPaOutcomesData> => {
	// Populate a record to track relevant statsPlayerPitching instances
	// The record is keyed on "season-gameType"
	return statsPlayerPitching.reduce(
		(acc: Record<string, ICombinedPitcherPaOutcomesData>, s: IStatsPlayerPitching) => {
			// Convert the IStatsPlayerPitchingByTeam game types to PlayerSeasonXwobaByTeam game types
			const convertedGameTypes: Array<string> = GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP[s.gameType];
			convertedGameTypes.forEach((convertedGameType: string) => {
				// If season-convertedGameType does not exist, create an entry in the record
				if (!(`${s.season}-${convertedGameType}` in acc)) {
					acc[`${s.season}-${convertedGameType}`] = {
						season: s.season,
						gameType: convertedGameType,
						tbf: s.tbf,
						tbfVl: s.tbfVl,
						tbfVr: s.tbfVr,
						inPlay: s.inPlay,
						inPlayVl: s.inPlayVl,
						inPlayVr: s.inPlayVr,
						kPct: s.kPct,
						kPctVl: s.kPctVl,
						kPctVr: s.kPctVr,
						bbPct: s.bbPct,
						bbPctVl: s.bbPctVl,
						bbPctVr: s.bbPctVr,
						gbPct: s.gbPct,
						gbPctVl: s.gbPctVl,
						gbPctVr: s.gbPctVr,
						hrPct: s.hrPct,
						hrPctVl: s.hrPctVl,
						hrPctVr: s.hrPctVr,
						wobacon: s.wobacon,
						wobaconVl: s.wobaconVl,
						wobaconVr: s.wobaconVr
					};
				}
				// Otherwise, aggregate new row with old row
				else {
					const prev: ICombinedPitcherPaOutcomesData = acc[`${s.season}-${convertedGameType}`];
					acc[`${s.season}-${convertedGameType}`] = aggregateStatsPlayerPitchingRows(prev, s);
				}
			});
			return acc;
		},
		{}
	);
};

// Converts raw stats player batting byteam data to a record mapping
// season-teamId-gameType to ICombinedPitcherPaOutcomesData
export const createStatsPlayerPitchingByTeamRecord = (
	statsPlayerPitchingByTeam: Array<IStatsPlayerPitchingByTeam>
): Record<string, ICombinedPitcherPaOutcomesData> => {
	// Populate a record to track relevant statsPlayerPitchingByTeam instances
	// The record is keyed on "season-teamId-gameType"
	return statsPlayerPitchingByTeam.reduce(
		(acc: Record<string, ICombinedPitcherPaOutcomesData>, s: IStatsPlayerPitchingByTeam) => {
			const teamId = s.team?.id; // This gets combined team id.  s.teamId is a bamId
			if (!teamId) return acc;

			// Convert the IStatsPlayerPitchingByTeam game types to PlayerSeasonXwobaByTeam game types
			const convertedGameTypes: Array<string> = GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP[s.gameType];
			convertedGameTypes.forEach((convertedGameType: string) => {
				// If season-teamId-convertedGameType does not exist, create an entry in the record
				if (!(`${s.season}-${teamId}-${convertedGameType}` in acc)) {
					acc[`${s.season}-${teamId}-${convertedGameType}`] = {
						season: s.season,
						gameType: convertedGameType,
						// Use combined team ID in order to match PlayerSeasonXwoba info
						teamId: teamId,
						// Use team Bam to properly display team name, org, abbrev, level
						team: s.teamBam,
						tbf: s.tbf,
						tbfVl: s.tbfVl,
						tbfVr: s.tbfVr,
						inPlay: s.inPlay,
						inPlayVl: s.inPlayVl,
						inPlayVr: s.inPlayVr,
						kPct: s.kPct,
						kPctVl: s.kPctVl,
						kPctVr: s.kPctVr,
						bbPct: s.bbPct,
						bbPctVl: s.bbPctVl,
						bbPctVr: s.bbPctVr,
						gbPct: s.gbPct,
						gbPctVl: s.gbPctVl,
						gbPctVr: s.gbPctVr,
						hrPct: s.hrPct,
						hrPctVl: s.hrPctVl,
						hrPctVr: s.hrPctVr,
						wobacon: s.wobacon,
						wobaconVl: s.wobaconVl,
						wobaconVr: s.wobaconVr
					};
				}
				// Otherwise, aggregate new row with old row
				else {
					const prev: ICombinedPitcherPaOutcomesData = acc[`${s.season}-${teamId}-${convertedGameType}`];
					acc[`${s.season}-${teamId}-${convertedGameType}`] = aggregateStatsPlayerPitchingRows(prev, s);
				}
			});
			return acc;
		},
		{}
	);
};

// Appends playerSeasonXwoba data to the statsPlayerPitching record, keyed on season-gameId, or
// Appends playerSeasonXwobaByTeam data to the statsPlayerPitchingByTeam record, keyed on season-teamId-gameId
export const appendGenericPlayerSeasonXwobaData = <T extends IPlayerSeasonXwoba | IPlayerSeasonXwobaByTeam>(
	playerSeasonXwoba: Array<T>,
	getKey: (x: T) => string, // Function that gets the key used to index the record
	statsPlayerPitchingRecord: Record<string, ICombinedPitcherPaOutcomesData>
): Record<string, ICombinedPitcherPaOutcomesData> => {
	playerSeasonXwoba.forEach((x: T) => {
		const key = getKey(x);
		// If the key does not exist, we should not create an entry in the record,
		// as we want the ability to aggregate all data in the record, and we need sample-size data
		// from statsPlayerPitching(ByTeam) in order to properly aggregate playerSeasonXwoba(ByTeam)
		// across game types.

		// As such, skip this case.
		if (!(key in statsPlayerPitchingRecord)) return;
		// Otherwise, append playerSeasonXwoba(ByTeam) data into the relevant row.
		// Because this function cannot handle aggregation of playerSeasonXwoba(ByTeam) using
		// playerSeasonXwoba(ByTeam) data alone, before calling this function, there should be at most one
		// instance of playerSeasonXwoba(ByTeam) corresponding to each of the xwoba-related fields
		// we want to populate in the record.
		// When we aggregate the playerSeasonXwoba(ByTeam) data in the table, we will use the corresponding sample
		// sizes from statsPlayerPitching(ByTeam)
		else {
			const prev: ICombinedPitcherPaOutcomesData = statsPlayerPitchingRecord[key];
			const newField: Partial<ICombinedPitcherPaOutcomesData> = {};
			// Map x.model and x.bats to the corresponding field on ICombinedPitcherPaOutcomesData
			if (x.model === MODEL_EV_LA && x.bats === BATS_OVERALL) newField.xwobacon = x.xwobacon;
			else if (x.model === MODEL_EV_LA && x.bats === BATS_L) newField.xwobaconVl = x.xwobacon;
			else if (x.model === MODEL_EV_LA && x.bats === BATS_R) newField.xwobaconVr = x.xwobacon;

			statsPlayerPitchingRecord[key] = {
				...prev,
				...newField
			};
		}
	});
	return statsPlayerPitchingRecord;
};

// Appends playerSeasonPopPa data to the statsPlayerPitching record, keyed on season-gameId, or
// Appends playerSeasonPopPaByTeam data to the statsPlayerPitchingByTeam record, keyed on season-teamId-gameId
export const appendGenericPlayerSeasonPopPaData = <
	T extends IPitchOutcomeProbabilitiesPa | IPitchOutcomeProbabilitiesPaByTeam
>(
	playerSeasonPopPa: Array<T>,
	getKey: (x: T) => string, // Function that gets the key used to index the record
	statsPlayerPitchingRecord: Record<string, ICombinedPitcherPaOutcomesData>
): Record<string, ICombinedPitcherPaOutcomesData> => {
	playerSeasonPopPa.forEach((x: T) => {
		const key = getKey(x);
		// If the key does not exist, we should not create an entry in the record,
		// as we want the ability to aggregate all data in the record, and we need sample-size data
		// from statsPlayerPitching(ByTeam) in order to properly aggregate playerSeasonPopPa(ByTeam)
		// across game types.

		// As such, skip this case.
		if (!(key in statsPlayerPitchingRecord)) return;
		// Otherwise, append playerSeasonPopPa(ByTeam) data into the relevant row.
		// Because this function cannot handle aggregation of playerSeasonPopPa(ByTeam) using
		// playerSeasonPopPa(ByTeam) data alone, before calling this function, there should be at most one
		// instance of playerSeasonPopPa(ByTeam) corresponding to each of the xwoba-related fields
		// we want to populate in the record.
		// When we aggregate the playerSeasonPopPa(ByTeam) data in the table, we will use the corresponding sample
		// sizes from statsPlayerPitching(ByTeam)
		else {
			const prev: ICombinedPitcherPaOutcomesData = statsPlayerPitchingRecord[key];
			const newField: Partial<ICombinedPitcherPaOutcomesData> = {};
			// Map x.bats to the corresponding field on ICombinedPitcherPaOutcomesData
			if (x.bats === BATS_OVERALL) {
				newField.xK = x.xK;
				newField.xUbb = x.xUbb;
				newField.xGb = x.xGb;
				newField.xHr = x.xHr;
			} else if (x.bats === BATS_L) {
				newField.xKVl = x.xK;
				newField.xUbbVl = x.xUbb;
				newField.xGbVl = x.xGb;
				newField.xHrVl = x.xHr;
			} else if (x.bats === BATS_R) {
				newField.xKVr = x.xK;
				newField.xUbbVr = x.xUbb;
				newField.xGbVr = x.xGb;
				newField.xHrVr = x.xHr;
			}

			statsPlayerPitchingRecord[key] = {
				...prev,
				...newField
			};
		}
	});
	return statsPlayerPitchingRecord;
};

// Used to get the level to display for a row
export const getLevelsFromRow = (row: TPitcherPaOutcomesRow): Array<string> => {
	// Child Rows or rows with no nested data
	if ("team" in row.combinedPitcherPaOutcomesData)
		return row.combinedPitcherPaOutcomesData.team?.level ? [row.combinedPitcherPaOutcomesData.team?.level] : [];
	// Parent Rows with nested data
	if (row.childData && row.childData.length > 1) {
		return [
			...new Set(
				row.childData.reduce((acc: Array<string>, childRow: TPitcherPaOutcomesRow) => {
					if (
						"team" in childRow.combinedPitcherPaOutcomesData &&
						childRow.combinedPitcherPaOutcomesData.team?.level
					)
						acc.push(childRow.combinedPitcherPaOutcomesData.team?.level);
					return acc;
				}, [])
			)
		];
	}
	return [];
};
