import React, { useCallback, useMemo, useState, ReactNode } from "react";
import { SnackbarAction, useSnackbar, OptionsObject, WithSnackbarProps } from "notistack";

import { Button } from "_react/shared/legacy/ui/Button";

export type TEnqueueSnackbar = WithSnackbarProps["enqueueSnackbar"];
export type TCloseSnackbar = WithSnackbarProps["closeSnackbar"];
export type TSnackbarOrigin = OptionsObject["anchorOrigin"];
export type TSnackbarKey = OptionsObject["key"];

export const usePersistentErrorSnackbar = () => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const action: SnackbarAction = useCallback(
		(key: TSnackbarKey) => <Button onClick={() => closeSnackbar(key)} title={"Dismiss"} />,
		[closeSnackbar]
	);
	const snackbarProps = useMemo(() => ({ variant: "error", persist: true, action } as const), [action]);

	const enqueue = useCallback(
		(errorMessage?: string) => {
			const snackbarMessage = errorMessage || "Action Failed";
			enqueueSnackbar(snackbarMessage, snackbarProps);
		},
		[enqueueSnackbar, snackbarProps]
	);

	return enqueue;
};

export const SNACKBAR_ANCHOR_LEFT: TSnackbarOrigin = { horizontal: "left", vertical: "bottom" } as const;
export const SNACKBAR_ANCHOR_RIGHT: TSnackbarOrigin = { horizontal: "right", vertical: "bottom" } as const;

export const useAnchoredSnackbar = (snackbarOrigin?: TSnackbarOrigin) => {
	const { enqueueSnackbar: enqueueRaw, closeSnackbar } = useSnackbar();
	const [anchorOrigin, setAnchorOrigin] = useState(snackbarOrigin);
	const enqueueSnackbar = useCallback(
		(message: ReactNode, options?: OptionsObject) => {
			return enqueueRaw(message, { anchorOrigin, ...options });
		},
		[anchorOrigin, enqueueRaw]
	);

	return { enqueueSnackbar, closeSnackbar, setAnchorOrigin, anchorOrigin };
};
