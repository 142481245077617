import React from "react";

import { TColumn, TParentColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER } from "_react/shared/ui/presentation/components/Table/_constants";
import { COLOR_GRADIENT_FUNCTION_BLUE_GREEN } from "_react/projections/pro/leaderboard/_constants";

import { TFieldingProjectionsRow } from "_react/shared/ui/data/tables/FieldingProjectionsTable/_types";
import {
	convertToPercentage,
	convertToDecimal,
	proportionColorGradientFunction
} from "_react/shared/ui/data/tables/FieldingProjectionsTable/_helpers";
import ExpandableRaa from "_react/shared/ui/data/tables/FieldingProjectionsTable/ExpandableRaa";
import ExpandableRaaLabel from "_react/shared/ui/data/tables/FieldingProjectionsTable/ExpandableRaaLabel";

export const DEFAULT_NUM_SEASONS = 10;

export const PROPORTION_UPPER_THRESHOLD = 50;
export const PROPORTION_COLOR_GRADIENT_FUNCTION = proportionColorGradientFunction;

export const NULL_FILLER_TEXT = "";
export const NULL_FILLER_TEXT_COMPONENTS = "-";

export const SEASON_COLUMN = {
	value: "season",
	label: "Season",
	id: "season",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (row: TFieldingProjectionsRow) => row.season
};

const C_RAA_COLUMN = (isShowComponentRaas = false) => ({
	value: "cRaa",
	label: (
		<ExpandableRaaLabel
			totalRaaLabel="RAA"
			componentRaaLabels={["FRM", "BL/THR", "RNG"]}
			isExpanded={isShowComponentRaas}
		/>
	),
	id: "cRaa",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return (
					row.fieldingProjectionsBlend?.rangeRaaC ||
					row.fieldingProjectionsBlend?.framingRaaC ||
					row.fieldingProjectionsBlend?.blockingThrowingRaaC
				);
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) => {
		return (
			<ExpandableRaa
				totalRaa={
					row.fieldingProjectionsBlend?.rangeRaaC ||
					row.fieldingProjectionsBlend?.framingRaaC ||
					row.fieldingProjectionsBlend?.blockingThrowingRaaC
						? (row.fieldingProjectionsBlend?.rangeRaaC ?? 0.0) +
						  (row.fieldingProjectionsBlend?.framingRaaC ?? 0.0) +
						  (row.fieldingProjectionsBlend?.blockingThrowingRaaC ?? 0.0)
						: undefined
				}
				componentRaas={[
					row.fieldingProjectionsBlend?.framingRaaC ?? 0,
					row.fieldingProjectionsBlend?.blockingThrowingRaaC ?? 0,
					row.fieldingProjectionsBlend?.rangeRaaC
				]}
				isExpanded={isShowComponentRaas}
			/>
		);
	},
	getSortValueFunction: (row: TFieldingProjectionsRow) => {
		if (
			row.fieldingProjectionsBlend?.rangeRaaC ||
			row.fieldingProjectionsBlend?.framingRaaC ||
			row.fieldingProjectionsBlend?.blockingThrowingRaaC
		)
			return (
				(row.fieldingProjectionsBlend?.rangeRaaC ?? 0.0) +
				(row.fieldingProjectionsBlend?.framingRaaC ?? 0.0) +
				(row.fieldingProjectionsBlend?.blockingThrowingRaaC ?? 0.0)
			);
		return null;
	},
	getColorGradientValueFunction: (row: TFieldingProjectionsRow) => {
		if (
			row.fieldingProjectionsBlend?.rangeRaaC ||
			row.fieldingProjectionsBlend?.framingRaaC ||
			row.fieldingProjectionsBlend?.blockingThrowingRaaC
		)
			return (
				(row.fieldingProjectionsBlend?.rangeRaaC ?? 0.0) +
				(row.fieldingProjectionsBlend?.framingRaaC ?? 0.0) +
				(row.fieldingProjectionsBlend?.blockingThrowingRaaC ?? 0.0)
			);
		return null;
	},
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
});

const C_PROPORTION_COLUMN = {
	value: "cProportion",
	label: "%",
	id: "cProportion",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return (
					row.fieldingProjectionsBlend?.rangeRaaC ||
					row.fieldingProjectionsBlend?.framingRaaC ||
					row.fieldingProjectionsBlend?.blockingThrowingRaaC
				);
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion?.proportionC != null
			? convertToPercentage(row.projectedPositionalProportion.proportionC)
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion != null ? row.projectedPositionalProportion.proportionC : null,
	colorGradientFunction: PROPORTION_COLOR_GRADIENT_FUNCTION
};

const FIRST_BASE_RAA_COLUMN = (isShowComponentRaas = false) => ({
	value: "1BRaa",
	label: (
		<ExpandableRaaLabel
			totalRaaLabel="RAA"
			componentRaaLabels={["REC", "RNG", "DMG"]}
			isExpanded={isShowComponentRaas}
		/>
	),
	id: "1BRaa",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raa1B && row.fieldingProjectionsBlend?.receivingRaa1B;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) => {
		return (
			<ExpandableRaa
				totalRaa={
					row.fieldingProjectionsBlend?.raa1B && row.fieldingProjectionsBlend?.receivingRaa1B
						? (row.fieldingProjectionsBlend?.raa1B ?? 0.0) +
						  (row.fieldingProjectionsBlend?.receivingRaa1B ?? 0.0)
						: undefined
				}
				componentRaas={[
					row.fieldingProjectionsBlend?.receivingRaa1B,
					row.fieldingProjectionsBlend?.rangeRaa1B,
					row.fieldingProjectionsBlend?.damageRaa1B
				]}
				isExpanded={isShowComponentRaas}
			/>
		);
	},
	getSortValueFunction: (row: TFieldingProjectionsRow) => {
		if (row.fieldingProjectionsBlend?.raa1B && row.fieldingProjectionsBlend?.receivingRaa1B) {
			return (row.fieldingProjectionsBlend?.raa1B ?? 0.0) + (row.fieldingProjectionsBlend?.receivingRaa1B ?? 0.0);
		}
		return null;
	},
	getColorGradientValueFunction: (row: TFieldingProjectionsRow) => {
		if (row.fieldingProjectionsBlend?.raa1B && row.fieldingProjectionsBlend?.receivingRaa1B) {
			return (row.fieldingProjectionsBlend?.raa1B ?? 0.0) + (row.fieldingProjectionsBlend?.receivingRaa1B ?? 0.0);
		}
		return null;
	},
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
});

const FIRST_BASE_PROPORTION_COLUMN = {
	value: "1BProportion",
	label: "%",
	id: "1BProportion",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raa1B && row.fieldingProjectionsBlend?.receivingRaa1B;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion?.proportion1B != null
			? convertToPercentage(row.projectedPositionalProportion.proportion1B)
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion != null ? row.projectedPositionalProportion.proportion1B : null,
	colorGradientFunction: PROPORTION_COLOR_GRADIENT_FUNCTION
};

const SECOND_BASE_RAA_COLUMN = (isShowComponentRaas = false) => ({
	value: "2BRaa",
	label: (
		<ExpandableRaaLabel totalRaaLabel="RAA" componentRaaLabels={["RNG", "DMG"]} isExpanded={isShowComponentRaas} />
	),
	id: "2BRaa",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raa2B;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) => (
		<ExpandableRaa
			totalRaa={row.fieldingProjectionsBlend?.raa2B}
			componentRaas={[row.fieldingProjectionsBlend?.rangeRaa2B, row.fieldingProjectionsBlend?.damageRaa2B]}
			isExpanded={isShowComponentRaas}
		/>
	),
	getSortValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raa2B ?? null,
	getColorGradientValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raa2B ?? null,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
});

const SECOND_BASE_PROPORTION_COLUMN = {
	value: "2BProportion",
	label: "%",
	id: "2BProportion",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raa2B;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion?.proportion2B != null
			? convertToPercentage(row.projectedPositionalProportion.proportion2B)
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion != null ? row.projectedPositionalProportion.proportion2B : null,
	colorGradientFunction: PROPORTION_COLOR_GRADIENT_FUNCTION
};

const THIRD_BASE_RAA_COLUMN = (isShowComponentRaas = false) => ({
	value: "3BRaa",
	label: (
		<ExpandableRaaLabel totalRaaLabel="RAA" componentRaaLabels={["RNG", "DMG"]} isExpanded={isShowComponentRaas} />
	),
	id: "3BRaa",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raa3B;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) => (
		<ExpandableRaa
			totalRaa={row.fieldingProjectionsBlend?.raa3B}
			componentRaas={[row.fieldingProjectionsBlend?.rangeRaa3B, row.fieldingProjectionsBlend?.damageRaa3B]}
			isExpanded={isShowComponentRaas}
		/>
	),
	getSortValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raa3B ?? null,
	getColorGradientValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raa3B ?? null,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
});

const THIRD_BASE_PROPORTION_COLUMN = {
	value: "3BProportion",
	label: "%",
	id: "3BProportion",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raa3B;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion?.proportion3B != null
			? convertToPercentage(row.projectedPositionalProportion.proportion3B)
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion != null ? row.projectedPositionalProportion.proportion3B : null,
	colorGradientFunction: PROPORTION_COLOR_GRADIENT_FUNCTION
};

const SS_RAA_COLUMN = (isShowComponentRaas = false) => ({
	value: "ssRaa",
	label: (
		<ExpandableRaaLabel totalRaaLabel="RAA" componentRaaLabels={["RNG", "DMG"]} isExpanded={isShowComponentRaas} />
	),
	id: "ssRaa",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaSs;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) => (
		<ExpandableRaa
			totalRaa={row.fieldingProjectionsBlend?.raaSs}
			componentRaas={[row.fieldingProjectionsBlend?.rangeRaaSs, row.fieldingProjectionsBlend?.damageRaaSs]}
			isExpanded={isShowComponentRaas}
		/>
	),
	getSortValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raaSs ?? null,
	getColorGradientValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raaSs ?? null,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
});

const SS_PROPORTION_COLUMN = {
	value: "ssProportion",
	label: "%",
	id: "ssProportion",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaSs;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion?.proportionSs != null
			? convertToPercentage(row.projectedPositionalProportion.proportionSs)
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion != null ? row.projectedPositionalProportion.proportionSs : null,
	colorGradientFunction: PROPORTION_COLOR_GRADIENT_FUNCTION
};

const LF_RAA_COLUMN = (isShowComponentRaas = false) => ({
	value: "lfRaa",
	label: (
		<ExpandableRaaLabel totalRaaLabel="RAA" componentRaaLabels={["RNG", "DMG"]} isExpanded={isShowComponentRaas} />
	),
	id: "lfRaa",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaLf;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) => (
		<ExpandableRaa
			totalRaa={row.fieldingProjectionsBlend?.raaLf}
			componentRaas={[row.fieldingProjectionsBlend?.rangeRaaLf, row.fieldingProjectionsBlend?.damageRaaLf]}
			isExpanded={isShowComponentRaas}
		/>
	),
	getSortValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raaLf ?? null,
	getColorGradientValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raaLf ?? null,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
});

const LF_PROPORTION_COLUMN = {
	value: "lfProportion",
	label: "%",
	id: "lfProportion",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaLf;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion?.proportionLf != null
			? convertToPercentage(row.projectedPositionalProportion.proportionLf)
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion != null ? row.projectedPositionalProportion.proportionLf : null,
	colorGradientFunction: PROPORTION_COLOR_GRADIENT_FUNCTION
};

const CF_RAA_COLUMN = (isShowComponentRaas = false) => ({
	value: "cfRaa",
	label: (
		<ExpandableRaaLabel totalRaaLabel="RAA" componentRaaLabels={["RNG", "DMG"]} isExpanded={isShowComponentRaas} />
	),
	id: "cfRaa",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaCf;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) => (
		<ExpandableRaa
			totalRaa={row.fieldingProjectionsBlend?.raaCf}
			componentRaas={[row.fieldingProjectionsBlend?.rangeRaaCf, row.fieldingProjectionsBlend?.damageRaaCf]}
			isExpanded={isShowComponentRaas}
		/>
	),
	getSortValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raaCf ?? null,
	getColorGradientValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raaCf ?? null,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
});

const CF_PROPORTION_COLUMN = {
	value: "cfProportion",
	label: "%",
	id: "cfProportion",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaCf;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion?.proportionCf != null
			? convertToPercentage(row.projectedPositionalProportion.proportionCf)
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion != null ? row.projectedPositionalProportion.proportionCf : null,
	colorGradientFunction: PROPORTION_COLOR_GRADIENT_FUNCTION
};

const RF_RAA_COLUMN = (isShowComponentRaas = false) => ({
	value: "rfRaa",
	label: (
		<ExpandableRaaLabel totalRaaLabel="RAA" componentRaaLabels={["RNG", "DMG"]} isExpanded={isShowComponentRaas} />
	),
	id: "rfRaa",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaRf;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) => (
		<ExpandableRaa
			totalRaa={row.fieldingProjectionsBlend?.raaRf}
			componentRaas={[row.fieldingProjectionsBlend?.rangeRaaRf, row.fieldingProjectionsBlend?.damageRaaRf]}
			isExpanded={isShowComponentRaas}
		/>
	),
	getSortValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raaRf ?? null,
	getColorGradientValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raaRf ?? null,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
});

const RF_PROPORTION_COLUMN = {
	value: "rfProportion",
	label: "%",
	id: "rfProportion",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaRf;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion?.proportionRf != null
			? convertToPercentage(row.projectedPositionalProportion.proportionRf)
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion != null ? row.projectedPositionalProportion.proportionRf : null,
	colorGradientFunction: PROPORTION_COLOR_GRADIENT_FUNCTION
};

const DH_PROPORTION_COLUMN = {
	value: "dhProportion",
	label: "%",
	id: "dhProportion",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.projectedPositionalProportion?.proportionDh;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion?.proportionDh
			? convertToPercentage(row.projectedPositionalProportion.proportionDh)
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPositionalProportion ? row.projectedPositionalProportion.proportionDh : null,
	colorGradientFunction: PROPORTION_COLOR_GRADIENT_FUNCTION
};

const SP_RAA_COLUMN = {
	value: "spRaa",
	label: "RAA",
	id: "spRaa",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaSp;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.fieldingProjectionsBlend?.raaSp ? convertToDecimal(row.fieldingProjectionsBlend.raaSp) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raaSp ?? null,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
};

const SP_PROPORTION_COLUMN = {
	value: "spProportion",
	label: "%",
	id: "spProportion",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaSp;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPitcherProportion?.proportionSp != null
			? convertToPercentage(row.projectedPitcherProportion.proportionSp)
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPitcherProportion != null ? row.projectedPitcherProportion.proportionSp : null,
	colorGradientFunction: PROPORTION_COLOR_GRADIENT_FUNCTION
};

const RP_RAA_COLUMN = {
	value: "rpRaa",
	label: "RAA",
	id: "rpRaa",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaRp;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.fieldingProjectionsBlend?.raaRp ? convertToDecimal(row.fieldingProjectionsBlend.raaRp) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) => row.fieldingProjectionsBlend?.raaRp ?? null,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
};

const RP_PROPORTION_COLUMN = {
	value: "rpProportion",
	label: "%",
	id: "rpProportion",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TFieldingProjectionsRow>) => {
		return (
			data.find((row: TFieldingProjectionsRow) => {
				return row.fieldingProjectionsBlend?.raaRp;
			}) !== undefined
		);
	},
	getValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPitcherProportion?.proportionRp != null
			? convertToPercentage(row.projectedPitcherProportion.proportionRp)
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TFieldingProjectionsRow) =>
		row.projectedPitcherProportion != null ? row.projectedPitcherProportion.proportionRp : null,
	colorGradientFunction: PROPORTION_COLOR_GRADIENT_FUNCTION
};

export const FIELDING_COLUMNS: (
	isShowComponentRaas?: boolean
) => Array<TColumn<TFieldingProjectionsRow, keyof TFieldingProjectionsRow>> = (isShowComponentRaas = false) => [
	C_RAA_COLUMN(isShowComponentRaas),
	C_PROPORTION_COLUMN,
	FIRST_BASE_RAA_COLUMN(isShowComponentRaas),
	FIRST_BASE_PROPORTION_COLUMN,
	SECOND_BASE_RAA_COLUMN(isShowComponentRaas),
	SECOND_BASE_PROPORTION_COLUMN,
	THIRD_BASE_RAA_COLUMN(isShowComponentRaas),
	THIRD_BASE_PROPORTION_COLUMN,
	SS_RAA_COLUMN(isShowComponentRaas),
	SS_PROPORTION_COLUMN,
	LF_RAA_COLUMN(isShowComponentRaas),
	LF_PROPORTION_COLUMN,
	CF_RAA_COLUMN(isShowComponentRaas),
	CF_PROPORTION_COLUMN,
	RF_RAA_COLUMN(isShowComponentRaas),
	RF_PROPORTION_COLUMN,
	DH_PROPORTION_COLUMN
];

export const PITCHING_COLUMNS: Array<TColumn<TFieldingProjectionsRow, keyof TFieldingProjectionsRow>> = [
	SP_RAA_COLUMN,
	SP_PROPORTION_COLUMN,
	RP_RAA_COLUMN,
	RP_PROPORTION_COLUMN
];

const BASE_PARENT_COLUMNS: Array<TParentColumn> = [
	{ label: "", id: "parentColumns", childColumnIds: ["season", "totalRaa"] }
];

const FIELDING_PARENT_COLUMNS: Array<TParentColumn> = [
	{ label: "C", id: "c", childColumnIds: ["cRaa", "cProportion"] },
	{ label: "1B", id: "1b", childColumnIds: ["1BRaa", "1BProportion"] },
	{ label: "2B", id: "2b", childColumnIds: ["2BRaa", "2BProportion"] },
	{ label: "3B", id: "3b", childColumnIds: ["3BRaa", "3BProportion"] },
	{ label: "SS", id: "ss", childColumnIds: ["ssRaa", "ssProportion"] },
	{ label: "LF", id: "lf", childColumnIds: ["lfRaa", "lfProportion"] },
	{ label: "CF", id: "cf", childColumnIds: ["cfRaa", "cfProportion"] },
	{ label: "RF", id: "rf", childColumnIds: ["rfRaa", "rfProportion"] },
	{ label: "DH", id: "dh", childColumnIds: ["dhRaa", "dhProportion"] }
];

const PITCHING_PARENT_COLUMNS: Array<TParentColumn> = [
	{ label: "SP", id: "sp", childColumnIds: ["spRaa", "spProportion"] },
	{ label: "RP", id: "rp", childColumnIds: ["rpRaa", "rpProportion"] }
];

export const PARENT_COLUMNS = [...BASE_PARENT_COLUMNS, ...FIELDING_PARENT_COLUMNS, ...PITCHING_PARENT_COLUMNS];
