import React from "react";

type LinearProgressProps = {
	value: number;
};

export function LinearProgressPopup({ value = 0 }: LinearProgressProps) {
	function formatProgress() {
		if (value < 1) {
			return "1%";
		}
		return value.toString() + "%";
	}
	return (
		<div>
			<style>
				{`
				.progress-container {
					margin: 10px auto;
					width: calc(100% - 20px);;
					text-align: center;
					position: absolute;
					bottom: 10px;
					left: 10px;
				}
				.progress2 {
					padding: 6px;
					border-radius: 30px;
					background: rgba(0, 0, 0, 0.25);  
					box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
				}
				.progress-bar2 {
					height: 18px;
					border-radius: 30px;
					background-image: 
						linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
					transition: 0.3s linear;  
					transition-property: width, background-color;    
				}
				.progress-moved .progress-bar2 {
					background-color: #002D72;  
				}
			`}
			</style>
			<div className="progress-container">
				<div className="progress2 progress-moved">
					<div className="progress-bar2" style={{ width: formatProgress() }}></div>
				</div>
			</div>
		</div>
	);
}
