import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function SkipNext({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z" />
			<path fill={"none"} d="M0 0h24v24H0z" />
		</IconSVG>
	);
}
