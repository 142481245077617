import { round10 } from "_react/shared/_helpers/numbers";
import {
	ICON_CHECK,
	ICON_WARNING,
	ICON_DANGEROUS
} from "_react/shared/ui/presentation/components/GradeThresholdIcon/_constants";
import { TGradeThresholdIconShape } from "_react/shared/ui/presentation/components/GradeThresholdIcon/_types";

import { BATS_OVERALL } from "_react/shared/data_models/hitter_grades/_constants";
import {
	IThreeGradesThresholdApiResponse,
	TThreeGradesThresholdGrade
} from "_react/shared/data_models/hitter_grades/_types";

// Player Season helpers

export const formatRa9 = (input?: number | null, nullFillerText = "") => {
	return round10(input, -2) ?? nullFillerText;
};

export const format2080Grade = (input?: number | null, nullFillerText = "") => {
	return input != null ? Math.round(input).toString() : nullFillerText;
};

export const convertPlayerBatsToSeasonalBats = (playerBats?: string | null) => {
	if (playerBats == null || playerBats === "S") return BATS_OVERALL;
	return playerBats;
};

// Three Grade Threshold helpers

export const getThreeGradeThresholdLevel = (
	sampleSize?: number | null,
	threshold?: number
): TGradeThresholdIconShape | undefined => {
	if (threshold && sampleSize) {
		if (sampleSize > threshold) return ICON_CHECK;
		if (sampleSize > threshold / 2) return ICON_WARNING;
		return ICON_DANGEROUS;
	}
	return undefined;
};

export const getThresholdValue = (
	grade: TThreeGradesThresholdGrade,
	thresholds?: Array<IThreeGradesThresholdApiResponse> | null
): number | undefined => {
	return thresholds?.find(threshold => threshold.grade === grade)?.value;
};
