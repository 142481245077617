import React, { FunctionComponent } from "react";
import Warning from "_react/shared/legacy/ui/icons/Warning";
import { Dialog } from "_react/shared/legacy/ui/Dialog";
import { CardActions, CardActionsSeparator } from "_react/shared/legacy/ui/CardActions";
import { Button } from "_react/shared/legacy/ui/Button";
import { defaultColorScheme, ColorScheme } from "_react/shared/legacy/ui/Colors";
import { CardContents } from "_react/shared/legacy/ui/CardContents";

type ForgotPasswordWarningProps = {
	colorScheme?: ColorScheme;
	onClose: () => void;
	open: boolean;
};

export const ForgotPasswordWarning: FunctionComponent<ForgotPasswordWarningProps> = ({
	colorScheme = defaultColorScheme.secondary,
	onClose,
	open
}) => {
	return (
		<Dialog
			actions={
				<CardActions>
					<Button colorScheme={defaultColorScheme.secondary} onClick={onClose} title="Cancel" />
					<CardActionsSeparator />
					<a
						href="https://mypassword.phillies.com/aims/ps/"
						id="redirect-to-password-station"
						rel="noopener noreferrer"
						target="_blank"
					>
						<Button onClick={onClose} title="I Understand" />
					</a>
				</CardActions>
			}
			colorScheme={colorScheme}
			header="Update Phillies Email Password?"
			onClose={onClose}
			open={open}
			Icon={Warning}
		>
			<CardContents>
				<div>
					Redirecting you to Phillies Corporate password service. When you change this password you are
					changing the password for your email account. Any apps(e.g. Outlook) using the old password will
					need to be updated.
					<br />
					<br />
					{`Users logging in via Corp email can automatically reset their passwords. If you don't have a corp
					email. Email ops@phillies.com or slack 'rnd-help' channel for password reset.`}
				</div>
			</CardContents>
		</Dialog>
	);
};
