import { $TSFixMe } from "utils/tsutils";
import { login } from "_react/shared/_types/phil_data/login";
import { IconProps } from "_react/shared/legacy/ui/icons";

export enum ELinkType {
	sidebar = "sidebar",
	modal = "modal",
	hidden = "hidden"
}

export interface ILinkModal {
	type: ELinkType.modal;
	text: string;
	comp: $TSFixMe;
	route?: string;
	icon: ({ fill, ...props }: IconProps) => JSX.Element;
}

export interface ILinkSidebar {
	type: ELinkType.sidebar;
	text: string;
	icon: ({ fill, ...props }: IconProps) => JSX.Element;
	comp?: $TSFixMe;
	route?: string;
	children?: Array<ILinkSidebar | ILinkModal>;
}

export interface ILinkHidden {
	type: ELinkType.hidden;
	route: string;
	comp: $TSFixMe;
}

export type TLink = ILinkModal | ILinkSidebar | ILinkHidden;

export type ILinkMap = {
	modal: ILinkModal;
	sidebar: ILinkSidebar;
	hidden: ILinkHidden;
};

export interface IIAMState {
	isAuthenticated: boolean;
	isAuthenticating: boolean;
	iamData: $TSFixMe;
	routes: Array<string>;
}

export interface IIAMContext {
	isAuthenticated: boolean;
	isAuthenticating: boolean;
	oktaLogin: Function;
	loginUser: Function;
	validateToken: Function;
	logoutUser: Function;
	iamData: $TSFixMe;
	userId: number;
	routes: Array<string>;
}

export interface IIAMAccessRoute {
	entity_id: number;
	route: string;
	via_id: number;
	is_default: number;
}

export type User = login;
export type UserSet = { [index: string]: User };
