import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function KeyboardArrowRight({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
			<path fill="none" d="M0 0h24v24H0V0z" />
		</IconSVG>
	);
}
