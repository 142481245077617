import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { isEqual } from "lodash";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { fetchSimilarProModelPlayers } from "_react/shared/data_models/pro2_predictions/_network";
import { IPro2PredictionsSummary } from "_react/shared/data_models/pro2_predictions/_types";
import { TSimilarPlayersFilters } from "_react/shared/ui/data/tables/SimilarPlayersProModelTable/_types";
import { TSimilarPlayersProModelTableData } from "_react/shared/ui/data/tables/SimilarPlayersProModelTable/SimilarPlayersProModelTable";

export type TSimilarPlayersProModelTableContext = {
	filters?: TSimilarPlayersFilters;
	lastFilters?: TSimilarPlayersFilters;
	shouldFetchData?: boolean;
	parsedSimilarPlayers?: Array<IPro2PredictionsSummary> | null;
	cancelSource?: CancelTokenSource | null;
	toast?: CreateToastFnReturn;
};

interface ISimilarPlayersProModelTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId or filters props change
				contextRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all similar players data
				similarPlayers: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_SIMILAR_PLAYERS = { initialized: { similarPlayers: "fetching" } };

const FETCH_SIMILAR_PLAYERS_DONE = "done.invoke.fetchSimilarPlayers:invocation[0]";
export const SET_FILTERS = "SET_FILTERS";
export const SET_PARSED_SIMILAR_PLAYERS = "SET_PARSED_SIMILAR_PLAYERS";

type TFetchSimilarPlayersEvent = {
	type: typeof FETCH_SIMILAR_PLAYERS_DONE;
	data: Array<IPro2PredictionsSummary> | undefined;
};

type TSetFiltersEvent = { type: typeof SET_FILTERS; value: TSimilarPlayersFilters };
type TSetParsedSimilarPlayersEvent = {
	type: typeof SET_PARSED_SIMILAR_PLAYERS;
	data: Array<IPro2PredictionsSummary> | undefined;
};

type TSimilarPlayersProModelTableEvent = TFetchSimilarPlayersEvent | TSetFiltersEvent | TSetParsedSimilarPlayersEvent;

export type TSimilarPlayersProModelTableSend = Interpreter<
	TSimilarPlayersProModelTableContext,
	ISimilarPlayersProModelTableStateSchema,
	TSimilarPlayersProModelTableEvent
>["send"];

const SimilarPlayersProModelTableMachine = (
	filtersProp?: TSimilarPlayersFilters,
	data?: TSimilarPlayersProModelTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TSimilarPlayersProModelTableContext,
		ISimilarPlayersProModelTableStateSchema,
		TSimilarPlayersProModelTableEvent
	>(
		{
			id: "similarPlayersProModelTable",
			initial: "initializing",
			context: {
				filters: filtersProp,
				lastFilters: undefined,
				shouldFetchData: shouldFetchDataProp,
				parsedSimilarPlayers: data?.parsedSimilarPlayers,
				cancelSource: undefined,
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_FILTERS]: { actions: "setFilters" },
						[SET_PARSED_SIMILAR_PLAYERS]: { actions: "setParsedSimilarPlayers" }
					},
					states: {
						contextRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						similarPlayers: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchSimilarPlayers",
												cond: "shouldFetchSimilarPlayers"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchSimilarPlayers",
									entry: ["refreshCancelSource"],
									invoke: {
										src: "fetchSimilarPlayers",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSimilarPlayersSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSimilarPlayersErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TSimilarPlayersProModelTableContext,
					_event: TSimilarPlayersProModelTableEvent
				) => !isEqual(context.filters, context.lastFilters),
				shouldFetchSimilarPlayers: (
					context: TSimilarPlayersProModelTableContext,
					_event: TSimilarPlayersProModelTableEvent
				) =>
					context.parsedSimilarPlayers === undefined &&
					context.filters !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearContext: assign<TSimilarPlayersProModelTableContext, TSimilarPlayersProModelTableEvent>({
					lastFilters: (
						context: TSimilarPlayersProModelTableContext,
						_event: TSimilarPlayersProModelTableEvent
					) => context.filters,
					parsedSimilarPlayers: (
						_context: TSimilarPlayersProModelTableContext,
						_event: TSimilarPlayersProModelTableEvent
					) => undefined,
					cancelSource: (
						context: TSimilarPlayersProModelTableContext,
						_event: TSimilarPlayersProModelTableEvent
					) => {
						context.cancelSource?.cancel();
						return undefined;
					}
				}),
				setFilters: assign<TSimilarPlayersProModelTableContext, TSimilarPlayersProModelTableEvent>({
					filters: (
						context: TSimilarPlayersProModelTableContext,
						event: TSimilarPlayersProModelTableEvent
					) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return { ...context.filters, ...event.value };
					},
					cancelSource: (
						context: TSimilarPlayersProModelTableContext,
						_event: TSimilarPlayersProModelTableEvent
					) => {
						context.cancelSource?.cancel();
						return undefined;
					}
				}),
				setParsedSimilarPlayers: assign<TSimilarPlayersProModelTableContext, TSimilarPlayersProModelTableEvent>(
					{
						parsedSimilarPlayers: (
							context: TSimilarPlayersProModelTableContext,
							event: TSimilarPlayersProModelTableEvent
						) => {
							if (event.type !== SET_PARSED_SIMILAR_PLAYERS) return context.parsedSimilarPlayers;
							return event.data;
						},
						cancelSource: (
							context: TSimilarPlayersProModelTableContext,
							_event: TSimilarPlayersProModelTableEvent
						) => {
							context.cancelSource?.cancel();
							return undefined;
						}
					}
				),
				refreshCancelSource: assign<TSimilarPlayersProModelTableContext, TSimilarPlayersProModelTableEvent>({
					cancelSource: (
						context: TSimilarPlayersProModelTableContext,
						_event: TSimilarPlayersProModelTableEvent
					) => {
						context.cancelSource?.cancel();
						return getCancelSource();
					}
				}),
				handleFetchSimilarPlayersSuccess: assign<
					TSimilarPlayersProModelTableContext,
					TSimilarPlayersProModelTableEvent
				>({
					parsedSimilarPlayers: (
						context: TSimilarPlayersProModelTableContext,
						event: TSimilarPlayersProModelTableEvent
					) => {
						if (event.type !== FETCH_SIMILAR_PLAYERS_DONE) return context.parsedSimilarPlayers;
						return event.data !== undefined ? (event.data as IPro2PredictionsSummary[]) : [];
					}
				}),
				handleFetchSimilarPlayersErrored: (
					context: TSimilarPlayersProModelTableContext,
					_event: TSimilarPlayersProModelTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Similar Players",
							description: "Error fetching similar players.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchSimilarPlayers: (context: TSimilarPlayersProModelTableContext, _event: AnyEventObject) => {
					const { filters } = context;
					if (!filters) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchSimilarProModelPlayers(
							{
								playerId: filters.playerId,
								projectedPositionGroup: filters.positionGroup,
								isSameOrg: filters?.isSameOrg,
								isPhillies: filters?.isPhillies,
								isFortyMan: filters?.isFortyMan,
								isProspect: filters?.isProspect,
								numberSimilarPlayers: filters?.numberSimilarPlayers,
								isUseCache: true
							},
							context.cancelSource?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default SimilarPlayersProModelTableMachine;
