import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function FastRewind({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M11 18V6l-8.5 6 8.5 6zm.5-6l8.5 6V6l-8.5 6z" />
			<path fill={"none"} d="M0 0h24v24H0z" />
		</IconSVG>
	);
}
