import React, { FC } from "react";
import { useMachine } from "@xstate/react";
import { EditorState } from "draft-js";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

import { ColorSchemeGroup, defaultColorScheme, BLACKWHITE } from "_react/shared/legacy/ui/Colors";
import { TRockyNoteStructure, TRockyNote } from "_react/shared/data_models/notes/_types";
import { formatFieldContent, buildContentComponent, buildNewNote, humanize } from "_react/playerpage/notes/_helpers";
import createNoteMachine, {
	SAVE_NOTE,
	UPDATE_NOTE,
	EDIT_NOTE,
	CANCEL_EDIT_NOTE,
	DELETE_NOTE,
	RESTORE_NOTE,
	PUBLISH_NOTE
} from "_react/playerpage/notes/_noteMachine";
import { Button } from "_react/shared/legacy/ui/Button";
import Edit from "_react/shared/legacy/ui/icons/Edit";
import Save from "_react/shared/legacy/ui/icons/Save";
import Book from "_react/shared/legacy/ui/icons/Book";
import Cancel from "_react/shared/legacy/ui/icons/Cancel";
import Delete from "_react/shared/legacy/ui/icons/Delete";
import { RockyNoteLoading } from "_react/playerpage/notes/_loading";
import {
	NoteWrapper,
	NoteTypeLabelWrapper,
	NoteTypeWrapper,
	LabelSpan,
	EditButtonsWrapper,
	ButtonContentsContainer,
	BUTTON_ICON_STYLE,
	BUTTON_STYLE,
	NoteErrorContainer,
	LastUpdatedSpan,
	FLEX_DIV_STYLE_LEFT,
	FLEX_DIV_STYLE_RIGHT
} from "_react/playerpage/notes/_styles";

import Lock from "_react/shared/legacy/ui/icons/Lock";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import { FlexDiv } from "../_styles";

type RockyNoteProps = {
	colorScheme?: ColorSchemeGroup;
	note: TRockyNote;
	structure: TRockyNoteStructure[];
	updateNote: (note: TRockyNote) => void;
	deleteNote: (noteId: string) => void;
	showingEditButtons?: boolean;
	showingDeletedNotes: boolean;
};

export const RockyNote: FC<RockyNoteProps> = ({
	note,
	structure,
	colorScheme = defaultColorScheme,
	updateNote,
	deleteNote,
	showingEditButtons = false,
	showingDeletedNotes = false
}) => {
	// Data and Machine
	const { enqueueSnackbar } = useSnackbar();
	const [current, send] = useMachine(createNoteMachine(note, structure, updateNote, deleteNote, enqueueSnackbar));
	const noteContext = current.context.note;
	const editing = current.matches("editing");
	const viewing = current.matches("viewing");
	const saving = current.matches("saving") || current.matches("saveErrored");
	const deleting = current.matches("deleting") || current.matches("deleteErrored");
	const preProcessing = current.matches("preProcessing");

	const isPrivateNote = note.noteType === "private_note";

	const handleEditNote = (structure: TRockyNoteStructure) => (
		updatedContent?: string | number | EditorState | null
	) => {
		send({ type: UPDATE_NOTE, data: buildNewNote(noteContext, structure, updatedContent) });
	};

	if (preProcessing) return <RockyNoteLoading />;

	if (!editing && !viewing && !saving && !deleting) return <NoteErrorContainer>Note Error</NoteErrorContainer>;

	return (
		<NoteWrapper
			hightlight={editing || saving}
			showingDeletedNotes={showingDeletedNotes}
			isPrivateNote={isPrivateNote}
		>
			<NoteTypeWrapper>
				<NoteTypeLabelWrapper>
					<LabelSpan colorScheme={(showingDeletedNotes || isPrivateNote ? BLACKWHITE : colorScheme).primary}>
						Note Type:
					</LabelSpan>
				</NoteTypeLabelWrapper>
				<LabelSpan colorScheme={(showingDeletedNotes || isPrivateNote ? BLACKWHITE : colorScheme).secondary}>
					{humanize(noteContext.noteType)}
				</LabelSpan>
				{isPrivateNote && (
					<Tooltip
						placement={"right"}
						title={"Only you can see this note"}
						colorScheme={BLACKWHITE.primary}
						style={{ width: "130px" }}
					>
						<Lock />
					</Tooltip>
				)}
			</NoteTypeWrapper>
			{structure.map(fieldStructure => {
				const fieldContent = formatFieldContent(noteContext, fieldStructure);
				return buildContentComponent(
					fieldContent,
					fieldStructure,
					showingDeletedNotes || isPrivateNote ? BLACKWHITE : colorScheme,
					editing && !showingDeletedNotes,
					saving,
					deleting,
					showingDeletedNotes,
					handleEditNote(fieldStructure)
				);
			})}
			<EditButtonsWrapper>
				{showingEditButtons && !editing && !saving && !showingDeletedNotes && (
					<>
						<Button
							colorScheme={colorScheme.secondary}
							onClick={() => send({ type: EDIT_NOTE })}
							title={
								<ButtonContentsContainer>
									<Edit fill={colorScheme.secondary.text} style={BUTTON_ICON_STYLE} />
									Edit Note
								</ButtonContentsContainer>
							}
							style={BUTTON_STYLE}
							disabled={deleting}
						/>
						<Button
							colorScheme={colorScheme.primary}
							onClick={() => send({ type: DELETE_NOTE, data: note.noteId })}
							title={
								<ButtonContentsContainer>
									<Delete fill={colorScheme.primary.text} style={BUTTON_ICON_STYLE} />
									{deleting ? "Deleting Note..." : "Delete Note"}
								</ButtonContentsContainer>
							}
							style={BUTTON_STYLE}
							disabled={deleting}
						/>
					</>
				)}
				{(editing || saving) && !showingDeletedNotes && (
					<>
						<FlexDiv style={FLEX_DIV_STYLE_LEFT}>
							<>
								<Button
									colorScheme={colorScheme.secondary}
									onClick={() => send({ type: SAVE_NOTE, data: noteContext })}
									title={
										<ButtonContentsContainer>
											<Save fill={colorScheme.secondary.text} style={BUTTON_ICON_STYLE} />
											{saving ? "Saving Note..." : "Save Note"}
										</ButtonContentsContainer>
									}
									style={BUTTON_STYLE}
									disabled={saving}
								/>
								<Button
									colorScheme={colorScheme.secondary}
									onClick={() => send({ type: CANCEL_EDIT_NOTE })}
									title={
										<ButtonContentsContainer>
											<Cancel fill={colorScheme.secondary.text} style={BUTTON_ICON_STYLE} />
											Cancel
										</ButtonContentsContainer>
									}
									style={BUTTON_STYLE}
									disabled={saving}
								/>
							</>
						</FlexDiv>
						<FlexDiv style={FLEX_DIV_STYLE_RIGHT}>
							<>
								{editing && !saving && note.noteType === "makeup_note" && (
									<Button
										colorScheme={colorScheme.primary}
										onClick={() => send({ type: PUBLISH_NOTE })}
										title={
											<ButtonContentsContainer>
												<Book fill={colorScheme.secondary.text} style={BUTTON_ICON_STYLE} />
												Publish Note
											</ButtonContentsContainer>
										}
										style={BUTTON_STYLE}
										disabled={saving}
									/>
								)}
							</>
						</FlexDiv>
					</>
				)}
				{showingDeletedNotes && (
					<Button
						colorScheme={BLACKWHITE.primary}
						onClick={() => send({ type: RESTORE_NOTE })}
						title={saving ? "Restoring Note..." : "Restore Note"}
						style={BUTTON_STYLE}
						disabled={saving}
					/>
				)}
			</EditButtonsWrapper>
			{note.isBeingCreated !== true && (
				<LastUpdatedSpan showingDeletedNotes={showingDeletedNotes}>
					{showingDeletedNotes ? "Deleted On" : "Last Updated:"}{" "}
					{dayjs(noteContext.lastChangeDate).format("MMM D, YYYY")}
					{noteContext.author !== noteContext.lastChangeAuthor
						? ` (by: ${noteContext.lastChangeAuthor})`
						: ""}
					{noteContext.publishDate != null
						? ", Published: "
						: note.noteType === "makeup_note"
						? ". This note has not been published yet."
						: ""}
					{noteContext.publishDate != null ? dayjs(noteContext.publishDate).format("MMM D, YYYY") : ""}
				</LastUpdatedSpan>
			)}
		</NoteWrapper>
	);
};
