import React from "react";

export const Loading = () => {
	return (
		<style>
			{`
                    .loading-container {
                        position: relative;
                        overflow: hidden;
						background-color: white;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						overflow: hidden;
                    }

                    .loading-item {
						position: relative;
						flex-grow: 1;
                        background-color: lightgray;
						overflow: hidden;
					}

					.loading-container .loading-item {
						margin: 1px 5px;
						width: calc(100% - 4px);
						&:first-child {
							margin-top: 2px;
						}
						&:last-child {
							margin-bottom: 2px;
						}
					}

                    .loading-item:after {
                        animation: shine 1.5s infinite;
                        content: "";
                        position: absolute;
                        left: -50%;
                        width: 50%;
                        height: 100%;
                        background: rgba(255, 255, 255, 0.5);
                        background: linear-gradient(
                            to right,
                            rgba(255, 255, 255, 0.0) 0%,
                            rgba(255, 255, 255, 0.5) 50%,
                            rgba(255, 255, 255, 0.0) 100%
                        );
                        overflow: hidden;
                    }

                    .loading-item-transparent:after {
                        animation: shine 1.5s infinite;
                        content: "";
                        position: absolute;
                        left: -50%;
                        width: 50%;
                        height: 100%;
                        background: rgba(255, 255, 255, 0.5);
                        background: linear-gradient(
                            to right,
                            rgba(255, 255, 255, 0.0) 0%,
                            rgba(255, 255, 255, 0.5) 50%,
                            rgba(255, 255, 255, 0.0) 100%
                        );
                        overflow: hidden;
                    }

                    @keyframes shine{
                        from {
                            left: -50%;
                        }
                        to {
                            left: 150%;
                        }
                    }
                `}
		</style>
	);
};
