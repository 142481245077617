import React, { useState, CSSProperties } from "react";

import { CELL_STYLE } from "_react/css";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { IContract as IContractMajors, IContractOptionYear } from "_react/shared/_types/schema/contracts";
import { IContractYear } from "_react/shared/_types/schema/contracts";

import { CONTRACT_YEARS_COLUMNS } from "_react/playerpage/contract/_helpers";
import { ContractBonuses } from "_react/playerpage/contract/mj/ContractBonuses";
import { ContractCovenants } from "_react/playerpage/contract/mj/ContractCovenants";
import { ContentDiv, TableTr } from "_react/playerpage/contract/_styles";

type TColumn = {
	title: string;
	name: string;
	width?: number;
	getCellValue?: Function;
};

type TContractYearsTrStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

export type TContractYearsTrProps = {
	contract: IContractMajors;
	year: IContractYear | IContractOptionYear;
	rowIndex: number;
	colorScheme?: ColorSchemeGroup;
	style?: TContractYearsTrStyle;
};

export const ContractYearsTr = ({
	contract,
	year,
	rowIndex,
	colorScheme = defaultColorScheme,
	style
}: TContractYearsTrProps) => {
	const columns = CONTRACT_YEARS_COLUMNS();
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	return (
		<>
			<TableTr isExpanded={isExpanded} rowIndex={rowIndex} onClick={() => setIsExpanded(!isExpanded)}>
				{columns.map((column: TColumn, colIndex: number) => (
					<td
						key={colIndex}
						style={{
							...CELL_STYLE,
							borderRight: colIndex === columns.length - 1 ? "" : "1px solid #cbcbcb",
							borderBottom: "0px solid black",
							borderTop: "0px solid black",
							borderLeft: "none",
							cursor: "pointer"
						}}
					>
						{column?.getCellValue ? column.getCellValue(year) : year[column.name]}
					</td>
				))}
			</TableTr>
			{isExpanded && (
				<tr>
					<td colSpan={columns.length}>
						<ContentDiv>
							<ContractBonuses
								contract={contract}
								year={year.year}
								colorScheme={colorScheme}
								style={{ cardHeader: style?.cardHeader }}
							/>
							<ContractCovenants
								contract={contract}
								colorScheme={colorScheme}
								style={{ cardHeader: style?.cardHeader }}
							/>
						</ContentDiv>
					</td>
				</tr>
			)}
		</>
	);
};
