import React, { FC } from "react";

export const RockyNoteLoading: FC = () => (
	<div style={{ padding: "3px", borderBottom: "1px solid gray" }}>
		<div style={{ display: "block", padding: "5px" }}>
			<div
				className="loading-item"
				style={{
					display: "inline-block",
					width: "75px",
					marginRight: "10px",
					height: "15px",
					borderRadius: "3px"
				}}
			/>
			<div
				className="loading-item"
				style={{ display: "inline-block", width: "155px", height: "15px", borderRadius: "3px" }}
			/>
			<div style={{ display: "inline-block" }} />
		</div>
		<div style={{ display: "block", padding: "5px" }}>
			<div
				className="loading-item"
				style={{
					display: "inline-block",
					width: "75px",
					marginRight: "10px",
					height: "15px",
					borderRadius: "3px"
				}}
			/>
			<div
				className="loading-item"
				style={{ display: "inline-block", width: "170px", height: "15px", borderRadius: "3px" }}
			/>
			<div style={{ display: "inline-block" }} />
		</div>
		<div style={{ display: "block", padding: "5px" }}>
			<div
				className="loading-item"
				style={{
					display: "inline-block",
					width: "75px",
					marginRight: "10px",
					height: "15px",
					borderRadius: "3px"
				}}
			/>
			<div
				className="loading-item"
				style={{ display: "inline-block", width: "145px", height: "15px", borderRadius: "3px" }}
			/>
			<div style={{ display: "inline-block" }} />
		</div>
		<div style={{ display: "block", padding: "5px" }}>
			<div
				className="loading-item"
				style={{
					display: "inline-block",
					width: "75px",
					marginRight: "10px",
					height: "15px",
					borderRadius: "3px"
				}}
			/>
			<div style={{ display: "inline-block" }} />
		</div>
		<div style={{ marginLeft: "10px" }}>
			<div style={{ display: "flex", padding: "5px" }}>
				<div className="loading-item" style={{ height: "15px", borderRadius: "3px" }} />
			</div>
			<div style={{ display: "flex", padding: "5px" }}>
				<div className="loading-item" style={{ height: "15px", borderRadius: "3px" }} />
			</div>
			<div style={{ display: "flex", padding: "5px" }}>
				<div className="loading-item" style={{ height: "15px", borderRadius: "3px" }} />
			</div>
		</div>
	</div>
);
