import React, { useEffect, useState, useMemo } from "react";

import { useFetch } from "utils/url_helpers";
import { StyledSelect } from "_react/shared/selects";
import { TLkPlayerPlanNoteType } from "_react/playerplan/shared/_types";

export type TNoteTypeOption = {
	label: string | null;
	value: string;
	prompt: string;
	isDeleted: boolean;
};

type TPlayerPlanNoteTypeSelect = {
	handleSelect: Function;
	placeholder?: string;
	value?: string | null;
	isClearable?: boolean;
};

function PlayerPlanNoteTypeSelect({
	handleSelect,
	placeholder = "Select type...",
	value,
	isClearable = true
}: TPlayerPlanNoteTypeSelect) {
	const { responseData: noteTypeResponseData, isInProgress } = useFetch(
		`lk?lkTableName=lk_player_plan_note_type&dbName=phil_data`,
		[]
	);
	noteTypeResponseData.sort((a: TLkPlayerPlanNoteType, b: TLkPlayerPlanNoteType) => {
		const aValue = a.sortOrder ?? Number.MAX_SAFE_INTEGER;
		const bValue = b.sortOrder ?? Number.MAX_SAFE_INTEGER;
		return aValue - bValue;
	});

	const [noteTypeDataSource, setNoteTypeDataSource] = useState([]);

	useEffect(() => {
		if (noteTypeResponseData) {
			setNoteTypeDataSource(
				noteTypeResponseData.map((noteType: TLkPlayerPlanNoteType) => ({
					label: noteType.label,
					value: noteType.value,
					prompt: noteType.prompt,
					isDeleted: noteType.isDeleted
				}))
			);
		}
	}, [noteTypeResponseData, setNoteTypeDataSource]);

	const noteType = useMemo(() => {
		if (noteTypeDataSource.length && value) {
			return noteTypeDataSource.find((noteType: TNoteTypeOption) => noteType.value === value) ?? null;
		}
		return null;
	}, [noteTypeDataSource, value]);

	const valueProps = value === undefined ? {} : noteType ? { value: noteType } : { value: null };

	return (
		<StyledSelect
			options={noteTypeDataSource.filter((opt: TNoteTypeOption) => !opt.isDeleted)}
			onChange={handleSelect}
			placeholder={placeholder}
			isClearable={isClearable}
			isLoading={isInProgress}
			{...valueProps}
			styles={{ container: { width: "100%" } }}
		/>
	);
}

export default PlayerPlanNoteTypeSelect;
