import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import dayjs from "dayjs";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { fetchCurrentYearDPLSurveys, fetchProspectDPLSurveys } from "_react/shared/data_models/dpl/_network";
import { IDPLSurvey } from "_react/shared/data_models/dpl/_types";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";

import { IDPLSurveyCleaned, TDPLQuestionnairePopoverData } from "_react/playerpage/ama/profile/DPLQuestionnaire/_types";
import { transformAndSortSurveys } from "_react/playerpage/ama/profile/DPLQuestionnaire/_helpers";

export type TDPLQuestionnairePopoverContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	prospectDplSurveys?: Array<IDPLSurveyCleaned>;
	currentYearDplSurveys?: Array<IDPLSurveyCleaned>;
	cancelSources: Record<string, CancelTokenSource>;
	toast?: CreateToastFnReturn;
};

interface IDPLQuestionnairePopoverStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				currentYearDplSurveys: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				prospectDplSurveys: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PROSPECT_DPL_SURVEYS = "SET_PROSPECT_DPL_SURVEYS";
export const SET_CURRENT_YEAR_DPL_SURVEYS = "SET_CURRENT_YEAR_DPL_SURVEYS";
const PROSPECT_DPL_SURVEYS_CANCEL_SOURCE = "prospectDplSurveys";
const CURRENT_YEAR_DPL_SURVEYS_CANCEL_SOURCE = "currentYearDplSurveys";
export const FETCHING_PROSPECT_DPL_SURVEYS = { initialized: { prospectDplSurveys: "fetching" } };
export const FETCHING_CURRENT_YEAR_DPL_SURVEYS = { initialized: { currentYearDplSurveys: "fetching" } };

const FETCH_PROSPECT_DPL_SURVEYS_DONE = "done.invoke.fetchingProspectDPLSurveys:invocation[0]";
type TFetchProspectDPLSurveysEvent = { type: typeof FETCH_PROSPECT_DPL_SURVEYS_DONE; data?: Array<IDPLSurveyCleaned> };
const FETCH_CURRENT_YEAR_DPL_SURVEYS_DONE = "done.invoke.fetchingCurrentYearDPLSurveys:invocation[0]";
type TFetchCurrentYearDPLSurveysEvent = {
	type: typeof FETCH_CURRENT_YEAR_DPL_SURVEYS_DONE;
	data?: Array<IDPLSurveyCleaned>;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; data?: number };
type TSetProspectDPLSurveysEvent = {
	type: typeof SET_PROSPECT_DPL_SURVEYS;
	data?: Array<IDPLSurvey>;
};
type TSetCurrentYearDPLSurveysEvent = {
	type: typeof SET_CURRENT_YEAR_DPL_SURVEYS;
	data?: Array<IDPLSurvey>;
};

type TDPLQuestionnairePopoverEvent =
	| TSetPlayerIdEvent
	| TSetProspectDPLSurveysEvent
	| TFetchProspectDPLSurveysEvent
	| TSetCurrentYearDPLSurveysEvent
	| TFetchCurrentYearDPLSurveysEvent;

export type TDPLQuestionnairePopoverSend = Interpreter<
	TDPLQuestionnairePopoverContext,
	IDPLQuestionnairePopoverStateSchema,
	TDPLQuestionnairePopoverEvent
>["send"];

const DPLQuestionnairePopoverMachine = (
	playerIdProp?: number,
	shouldFetchData = true,
	data?: TDPLQuestionnairePopoverData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TDPLQuestionnairePopoverContext, IDPLQuestionnairePopoverStateSchema, TDPLQuestionnairePopoverEvent>(
		{
			id: "dplQuestionnairePopover",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				shouldFetchData: shouldFetchData,
				currentYearDplSurveys: data?.currentYearDplSurveys,
				prospectDplSurveys: data?.prospectDplSurveys,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_PROSPECT_DPL_SURVEYS]: { actions: "setProspectDPLSurveys" },
						[SET_CURRENT_YEAR_DPL_SURVEYS]: { actions: "setCurrentYearDPLSurveys" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						currentYearDplSurveys: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingCurrentYearDPLSurveys",
												cond: "shouldFetchCurrentYearDPLSurveys"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingCurrentYearDPLSurveys",
									entry: ["refreshCurrentYearDPLSurveysCancelSource"],
									invoke: {
										src: "fetchCurrentYearDPLSurveys",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchCurrentYearDPLSurveysSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchCurrentYearDPLSurveysErrored"
										}
									}
								}
							}
						},
						prospectDplSurveys: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingProspectDPLSurveys",
												cond: "shouldFetchProspectDPLSurveys"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingProspectDPLSurveys",
									entry: ["refreshProspectDPLSurveysCancelSource"],
									invoke: {
										src: "fetchProspectDPLSurveys",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchProspectDPLSurveysSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchProspectDPLSurveysErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (context: TDPLQuestionnairePopoverContext, _event: TDPLQuestionnairePopoverEvent) =>
					context.playerId !== context.lastPlayerId,
				shouldFetchCurrentYearDPLSurveys: (
					context: TDPLQuestionnairePopoverContext,
					_event: TDPLQuestionnairePopoverEvent
				) => context.currentYearDplSurveys === undefined && shouldFetchData && context.playerId !== undefined,
				shouldFetchProspectDPLSurveys: (
					context: TDPLQuestionnairePopoverContext,
					_event: TDPLQuestionnairePopoverEvent
				) => context.prospectDplSurveys === undefined && shouldFetchData && context.playerId !== undefined
			},
			actions: {
				setPlayerId: assign<TDPLQuestionnairePopoverContext, TDPLQuestionnairePopoverEvent>({
					playerId: (context: TDPLQuestionnairePopoverContext, event: TDPLQuestionnairePopoverEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setCurrentYearDPLSurveys: assign<TDPLQuestionnairePopoverContext, TDPLQuestionnairePopoverEvent>({
					currentYearDplSurveys: (
						context: TDPLQuestionnairePopoverContext,
						event: TDPLQuestionnairePopoverEvent
					) => {
						if (event.type !== SET_CURRENT_YEAR_DPL_SURVEYS) return context.currentYearDplSurveys;
						if (event.data === undefined) return undefined;
						return transformAndSortSurveys(event.data);
					},
					cancelSources: (context: TDPLQuestionnairePopoverContext, event: TDPLQuestionnairePopoverEvent) => {
						if (event.type !== SET_CURRENT_YEAR_DPL_SURVEYS) return context.cancelSources;
						if (context.cancelSources[CURRENT_YEAR_DPL_SURVEYS_CANCEL_SOURCE] != null)
							context.cancelSources[CURRENT_YEAR_DPL_SURVEYS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[CURRENT_YEAR_DPL_SURVEYS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setProspectDPLSurveys: assign<TDPLQuestionnairePopoverContext, TDPLQuestionnairePopoverEvent>({
					prospectDplSurveys: (
						context: TDPLQuestionnairePopoverContext,
						event: TDPLQuestionnairePopoverEvent
					) => {
						if (event.type !== SET_PROSPECT_DPL_SURVEYS) return context.prospectDplSurveys;
						if (event.data === undefined) return undefined;
						return transformAndSortSurveys(event.data);
					},
					cancelSources: (context: TDPLQuestionnairePopoverContext, event: TDPLQuestionnairePopoverEvent) => {
						if (event.type !== SET_PROSPECT_DPL_SURVEYS) return context.cancelSources;
						if (context.cancelSources[PROSPECT_DPL_SURVEYS_CANCEL_SOURCE] != null)
							context.cancelSources[PROSPECT_DPL_SURVEYS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PROSPECT_DPL_SURVEYS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearContext: assign<TDPLQuestionnairePopoverContext, TDPLQuestionnairePopoverEvent>({
					lastPlayerId: (context: TDPLQuestionnairePopoverContext, _event: TDPLQuestionnairePopoverEvent) =>
						context.playerId,
					// Note: This intentionally doesn't clear the current year DPL surveys, as those are constant across players
					prospectDplSurveys: (
						_context: TDPLQuestionnairePopoverContext,
						_event: TDPLQuestionnairePopoverEvent
					) => undefined,
					cancelSources: (
						context: TDPLQuestionnairePopoverContext,
						_event: TDPLQuestionnairePopoverEvent
					) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshCurrentYearDPLSurveysCancelSource: assign<
					TDPLQuestionnairePopoverContext,
					TDPLQuestionnairePopoverEvent
				>({
					cancelSources: (
						context: TDPLQuestionnairePopoverContext,
						_event: TDPLQuestionnairePopoverEvent
					) => {
						if (context.cancelSources[CURRENT_YEAR_DPL_SURVEYS_CANCEL_SOURCE] != null)
							context.cancelSources[CURRENT_YEAR_DPL_SURVEYS_CANCEL_SOURCE].cancel();
						context.cancelSources[CURRENT_YEAR_DPL_SURVEYS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshProspectDPLSurveysCancelSource: assign<
					TDPLQuestionnairePopoverContext,
					TDPLQuestionnairePopoverEvent
				>({
					cancelSources: (
						context: TDPLQuestionnairePopoverContext,
						_event: TDPLQuestionnairePopoverEvent
					) => {
						if (context.cancelSources[PROSPECT_DPL_SURVEYS_CANCEL_SOURCE] != null)
							context.cancelSources[PROSPECT_DPL_SURVEYS_CANCEL_SOURCE].cancel();
						context.cancelSources[PROSPECT_DPL_SURVEYS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchCurrentYearDPLSurveysSuccess: assign<
					TDPLQuestionnairePopoverContext,
					TDPLQuestionnairePopoverEvent
				>({
					currentYearDplSurveys: (
						context: TDPLQuestionnairePopoverContext,
						event: TDPLQuestionnairePopoverEvent
					) => {
						if (event.type !== FETCH_CURRENT_YEAR_DPL_SURVEYS_DONE) return context.currentYearDplSurveys;
						return event.data;
					}
				}),
				handleFetchProspectDPLSurveysSuccess: assign<
					TDPLQuestionnairePopoverContext,
					TDPLQuestionnairePopoverEvent
				>({
					prospectDplSurveys: (
						context: TDPLQuestionnairePopoverContext,
						event: TDPLQuestionnairePopoverEvent
					) => {
						if (event.type !== FETCH_PROSPECT_DPL_SURVEYS_DONE) return context.prospectDplSurveys;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetcCurrentYearDPLSurveysErrored: (
					context: TDPLQuestionnairePopoverContext,
					_event: TDPLQuestionnairePopoverEvent
				) => {
					if (context.toast)
						context.toast({
							title: "DPL Questionnaires",
							description: "Error fetching current year questionnaire data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchProspectDPLSurveysErrored: (
					context: TDPLQuestionnairePopoverContext,
					_event: TDPLQuestionnairePopoverEvent
				) => {
					if (context.toast)
						context.toast({
							title: "DPL Questionnaires",
							description: "Error fetching prospect questionnaire data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchCurrentYearDPLSurveys: (context: TDPLQuestionnairePopoverContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchCurrentYearDPLSurveys(
							{
								year: dayjs().year()
							},
							context.cancelSources[CURRENT_YEAR_DPL_SURVEYS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc).then(transformAndSortSurveys);
				},
				fetchProspectDPLSurveys: (context: TDPLQuestionnairePopoverContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchProspectDPLSurveys(
							{
								playerId: context.playerId
							},
							context.cancelSources[PROSPECT_DPL_SURVEYS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc).then(transformAndSortSurveys);
				}
			}
		}
	);

export default DPLQuestionnairePopoverMachine;
