import React from "react";
import { Text } from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import TeamBadge from "_react/shared/ui/presentation/components/TeamBadge/TeamBadge";

const NULL_FILLER_TEXT = "";

type TDataRowWithTeamBam = {
	name?: string | null;
	organization?: TDataRowWithTeamBamOrganization | null;
};

type TDataRowWithTeamBamOrganization = {
	abbreviation?: string | null;
};

type TTeamColumnProps = {
	teamBam?: TDataRowWithTeamBam;
	childRowCount?: number;
};

export const TeamColumn = ({ teamBam, childRowCount }: TTeamColumnProps) => {
	const isMobile = useBreakpointValue({
		base: true,
		xl: false
	});

	// Child Rows or rows with no nested data
	if (teamBam != null)
		return (
			<>
				<TeamBadge teamAbbreviation={teamBam?.organization?.abbreviation} style={{ marginRight: 2 }} />
				{!isMobile && (teamBam?.name ?? NULL_FILLER_TEXT)}
			</>
		);
	// Parent Rows with nested data
	if (childRowCount && childRowCount > 1) return <Text>{`${childRowCount} teams`}</Text>;
	return <Text>{NULL_FILLER_TEXT}</Text>;
};

export default TeamColumn;
