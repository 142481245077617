import { chakra, Divider } from "@chakra-ui/react";

// Chakra Styling

export const SectionDivider = chakra(Divider, {
	baseStyle: {
		borderBottomWidth: "2px",
		borderColor: "gray.200",
		opacity: "1",
		paddingTop: "10"
	}
});
