const colors = {
	maroon: "#6F263D",
	powderBlue: "#4299E1",

	gray: {
		50: "#FAFAFA",
		100: "#F5F5F5",
		200: "#E5E5E5",
		300: "#D4D4D4",
		400: "#A3A3A3",
		500: "#737373",
		600: "#525252",
		700: "#404040",
		800: "#262626",
		900: "#171717"
	}
};

export default colors;
