import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { StylesConfig } from "react-select";
import { useCookies } from "react-cookie";
import debounce from "debounce-promise";
import { Props, MultiValue, SingleValue, CSSObjectWithLabel } from "react-select";
import AsyncSelect from "react-select/async";

import { isEqual } from "utils/helpers";
import { DEFAULT_STYLES, TOption, isOptionArr } from "_react/inputs";
import axios from "_redux/_utils/_axios";
import { makeStyledSelectStyles } from "_react/shared/selects";
import { Style, $TSFixMe } from "utils/tsutils";
import { getPlayerClassificationTag } from "utils/PlayerTypeHelpers";
import { Button } from "_react/shared/legacy/ui/Button";
import { RED } from "_react/shared/legacy/ui/Colors";
import { Menu } from "_react/shared/legacy/ui/Menu";
import { MenuItem } from "_react/shared/legacy/ui/MenuItem";
import Check from "_react/shared/legacy/ui/icons/Check";
import {
	PLAYER_CLASSIFICATION_SEARCH_COOKIE,
	PLAYER_CLASSIFICATION_SEARCH_ALL,
	getPlayerSearchClassifications,
	getNewPlayerSearchClassifications,
	getPlayerSearchClassificationsButtonLabel,
	PLAYER_CLASSIFICATION_SEARCH_OPTIONS,
	includesPlayerClassification,
	CHECK_STYLE,
	CheckPlaceholder
} from "_react/app/_search_cookies";
import { createReactSelectPlayerOption } from "_react/shared/searches/_helpers";
import { TSearchResultPlayerObject } from "_react/shared/searches/_types";

export type TPlayerClassification = "PRO" | "AMA" | "INTL";
export type TPlayerSearchOption = TOption<number> & {
	playerClassification?: TPlayerClassification;
	weight?: number;
	bamId?: number | null;
	philId?: number | null;
};

export type PlayerSearchProps = {
	autoFocus?: boolean;
	clearOnBlur?: boolean;
	isClearable?: boolean;
	isStyledSelectStyles?: boolean | {};
	onSelect?: Function;
	isMulti?: boolean;
	style?: Style;
	placeholder?: string;
	playerClassification?: TPlayerClassification;
	valueOnlyValue?: Array<number> | number;
	initialValue?: TPlayerSearchOption;
	disabled?: boolean;
	includeClassificationButton?: boolean;
	keyIndex?: string;
	orgId?: number;
	forDisplay?: boolean;
	forDisplayShort?: boolean;
	value?: MultiValue<TOption<number>> | SingleValue<TOption<number>> | null;
	omitPlayerIds?: Set<number>;
};

export const formatOptionLabel = ({ label, playerClassification }: TPlayerSearchOption) => (
	<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
		<div style={{ marginRight: 10 }}>{label}</div>
		{getPlayerClassificationTag(playerClassification, { fontSize: "12px" })}
	</div>
);

const playerSearchStyles: (color: string) => StylesConfig<TOption<number>, boolean> = color => ({
	control: (provided: CSSObjectWithLabel, state) => ({
		display: "flex",
		transition: "all 100ms",
		fontSize: 17,
		borderBottom: `1px solid ${color}`,
		marginBottom: "2px",
		opacity: state.isDisabled ? 0.33 : 1
	}),
	input: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontSize: 17,
		color: color,
		padding: 0
	}),
	loadingIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: color
	}),
	option: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: "black",
		cursor: "pointer"
	}),
	placeholder: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: color,
		fontSize: 17,
		fontWeight: 400,
		opacity: 0.5
	}),
	singleValue: (provided: CSSObjectWithLabel, state) => ({
		...provided,
		display: state.selectProps.menuIsOpen ? "none" : "block",
		color: color,
		fontSize: 17
	}),
	menuPortal: (provided: CSSObjectWithLabel) => ({
		...provided,
		zIndex: 1005
	}),
	valueContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontWeight: 400
	})
});

export const PlayerSearch: FunctionComponent<PlayerSearchProps> = ({
	autoFocus = false,
	isClearable = false,
	isStyledSelectStyles = false,
	onSelect,
	placeholder = "Player Search...",
	playerClassification,
	style = {},
	isMulti = false,
	initialValue,
	valueOnlyValue,
	disabled = false,
	includeClassificationButton = false,
	keyIndex = "id",
	orgId,
	forDisplay = false,
	forDisplayShort = false,
	omitPlayerIds = new Set([])
}) => {
	const history = useHistory();
	if (!onSelect) {
		onSelect = (p: TPlayerSearchOption) => history.push(`/player?philId=${p.philId}`);
	}
	const color = style.color ? style.color : "black";

	const selectStyles =
		isStyledSelectStyles === true
			? makeStyledSelectStyles()
			: isStyledSelectStyles === false
			? playerSearchStyles(color)
			: makeStyledSelectStyles(isStyledSelectStyles);

	// Player Classification Select
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
	const [cookies, setCookie] = useCookies([PLAYER_CLASSIFICATION_SEARCH_COOKIE]);
	const playerClassificationSearch = cookies.playerClassificationSearch ?? PLAYER_CLASSIFICATION_SEARCH_ALL;
	const playerSearchClassifications = getPlayerSearchClassifications(playerClassificationSearch);
	const setPlayerSearchClassifications = (playerSearchClassification: string) => {
		const newPlayerSearchClassifications = getNewPlayerSearchClassifications(
			playerSearchClassifications,
			playerSearchClassification
		);

		setCookie(PLAYER_CLASSIFICATION_SEARCH_COOKIE, newPlayerSearchClassifications.join(","));
	};

	return (
		<div style={{ flex: 1, display: "flex", ...style }}>
			<div style={{ flexGrow: 1 }}>
				<PlayerSearchComponent
					autoFocus={autoFocus}
					components={{ DropdownIndicator: null }}
					closeMenuOnSelect={true}
					formatOptionLabel={formatOptionLabel}
					isClearable={isClearable}
					isMulti={isMulti}
					onChange={(selectedValue: MultiValue<TOption<number>> | SingleValue<TOption<number>> | null) => {
						if (onSelect != null) onSelect(selectedValue);
					}}
					placeholder={placeholder}
					playerClassifications={playerClassification ?? playerClassificationSearch}
					styles={selectStyles}
					valueOnlyValue={valueOnlyValue ?? initialValue?.value}
					isDisabled={disabled}
					keyIndex={keyIndex}
					orgId={orgId}
					forDisplay={forDisplay}
					forDisplayShort={forDisplayShort}
					omitPlayerIds={omitPlayerIds}
				/>
			</div>
			{includeClassificationButton && (
				<div style={{ marginLeft: "5px" }}>
					<Button
						title={getPlayerSearchClassificationsButtonLabel(playerSearchClassifications)}
						variant="outlined"
						onClick={e => setAnchorEl(e.currentTarget)}
						colorScheme={{
							text: "white",
							hover: "white",
							hoverText: RED,
							color: "white",
							selected: "white",
							selectedText: RED
						}}
					/>
					<Menu
						absolute={true}
						anchorEl={anchorEl}
						id="userMenu"
						onClose={() => setAnchorEl(null)}
						open={Boolean(anchorEl)}
					>
						{PLAYER_CLASSIFICATION_SEARCH_OPTIONS.map(classification => (
							<MenuItem
								key={classification.value}
								handleClick={() => {
									setPlayerSearchClassifications(classification.value);
								}}
								style={{ display: "flex", alignItems: "center" }}
							>
								{includesPlayerClassification(playerSearchClassifications, classification.value) ? (
									<Check style={CHECK_STYLE} />
								) : (
									<CheckPlaceholder />
								)}
								{classification.text}
							</MenuItem>
						))}
					</Menu>
				</div>
			)}
		</div>
	);
};

interface IPlayerSearchItem extends TOption<number> {
	label: string;
	value: number;
	playerClassification: TPlayerClassification;
}

interface TPlayerSearchProps extends Props<TOption<number>> {
	valueOnlyValue?: Array<number> | number;
	isMulti?: boolean;
	keyIndex?: string;
	forDisplay?: boolean;
	forDisplayShort?: boolean;
	playerClassifications: string;
	orgId?: number;
	omitPlayerIds?: Set<number>;
}

// Avoid using this component - use the one above instead
const PlayerSearchComponent = ({
	valueOnlyValue,
	isMulti = true,
	keyIndex = "id",
	forDisplay = false,
	forDisplayShort = false,
	playerClassifications,
	orgId,
	omitPlayerIds = new Set([]),
	...props
}: TPlayerSearchProps) => {
	const [initialValues, setInitialValues] = useState<
		MultiValue<TOption<number>> | SingleValue<TOption<number>> | null
	>();
	const valueOnlyValueRef = useRef(valueOnlyValue);
	useEffect(() => {
		const hasValidInitialValue = valueOnlyValue && (!isMulti || (valueOnlyValue as Array<number>).length > 0);
		if (
			hasValidInitialValue &&
			(!isEqual(valueOnlyValue, valueOnlyValueRef.current) || initialValues === undefined)
		) {
			valueOnlyValueRef.current = valueOnlyValue;
			axios
				.get(
					`/search/player/initial?values=${
						isMulti && Array.isArray(valueOnlyValue)
							? (valueOnlyValue as Array<number>).join(",")
							: valueOnlyValue
					}&key=${keyIndex}`
				)
				.then(response => {
					setInitialValues(response.data.map(createReactSelectPlayerOption(keyIndex)));
				});
		} else if (!valueOnlyValue) {
			setInitialValues(undefined);
		}
	}, [valueOnlyValue, isMulti, keyIndex, valueOnlyValueRef, initialValues]);

	const fetchPlayerSuggestions = (playerClassifications: string, orgId?: number) => (query: string) => {
		let queryString = `q=${query}&classifications=${playerClassifications}`;
		queryString = orgId ? `${queryString}&orgId=${orgId}` : queryString;
		return axios.get(`/search/player?${queryString}`).then(response => {
			// Transform to Select Results
			return response.data
				.filter((result: TSearchResultPlayerObject) => !omitPlayerIds.has(result.result.id))
				.map(createReactSelectPlayerOption(keyIndex));
		});
	};

	const debouncedFetchPlayerSuggestions = (playerClassifications: string, orgId?: number) =>
		debounce(fetchPlayerSuggestions(playerClassifications, orgId), 1500, {
			leading: true
		});

	const additionalProps =
		initialValues && isOptionArr<number>(initialValues, "number")
			? { ...props, defaultOptions: true, value: initialValues }
			: props;

	if (forDisplay || forDisplayShort) {
		let label = "";
		if (initialValues != null && isOptionArr<number>(initialValues, "number")) {
			label =
				initialValues.length > 0 && initialValues[0] != null
					? initialValues
							.map(value => (forDisplayShort ? value.label?.split(" (")[0] : value.label))
							.join(", ")
					: "";
		}
		return <div>{label}</div>;
	}
	return (
		<AsyncSelect<TOption<number>, boolean>
			formatOptionLabel={(option: TOption<number>) =>
				formatOptionLabel((option as $TSFixMe) as IPlayerSearchItem)
			}
			isClearable={true}
			isMulti={isMulti}
			loadOptions={debouncedFetchPlayerSuggestions(playerClassifications, orgId)}
			noOptionsMessage={() => "Search for Players..."}
			menuPortalTarget={document.body}
			styles={DEFAULT_STYLES}
			{...additionalProps}
		/>
	);
};
