import React from "react";
import { ColorScheme, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { Style } from "utils/tsutils";
import { v4 } from "uuid";

type CircularProgressProps = {
	center?: boolean;
	className?: string;
	colorScheme?: ColorScheme;
	style?: Style;
	text?: string;
};

export default function CircularProgress({
	center = false,
	className,
	colorScheme = defaultColorScheme.secondary,
	style = {},
	text
}: CircularProgressProps) {
	const uuid = v4();
	const height: number = style.height != null ? (style.height as number) : 64;
	return (
		<div
			className={className}
			style={
				center
					? {
							position: "absolute",
							width: height,
							height: height,
							zIndex: 20,
							top: "50%",
							left: "50%",
							margin: "-32px, 0, 0, -32px",
							...style
					  }
					: { ...style }
			}
		>
			<style>
				{`
					#lds-ring-${uuid} {
						display: inline-block;
						position: relative;
						width: ${height}px;
						height: ${height}px;
					}
					#lds-ring-${uuid} div {
						box-sizing: border-box;
						display: block;
						position: absolute;
						width: ${0.9 * height}px;
						height: ${0.9 * height}px;
						margin: ${0.1 * height}px;
						border: ${0.1 * height}px solid ${colorScheme.color};
						border-radius: 50%;
						animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
						border-color: ${colorScheme.color} transparent transparent transparent;
					}
					#lds-ring-${uuid} div:nth-child(1) {
						animation-delay: -0.45s;
					}
					#lds-ring-${uuid} div:nth-child(2) {
						animation-delay: -0.3s;
					}
					#lds-ring-${uuid} div:nth-child(3) {
						animation-delay: -0.15s;
					}
					@keyframes lds-ring {
						0% {
						transform: rotate(0deg);
						}
						100% {
						transform: rotate(360deg);
						}
					}
				`}
			</style>
			<div className="lds-ring" id={`lds-ring-${uuid}`}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<div
				style={{
					fontSize: "16px",
					textAlign: "center",
					maxWidth: "500px",
					margin: "auto",
					fontWeight: 600
				}}
			>
				{text}
			</div>
		</div>
	);
}
