import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const CELL_STYLE = {
	borderRight: "1px solid lightGray",
	paddingLeft: 5,
	paddingRight: 5
};

const getVal = (evaluation, field) => {
	if (evaluation.hasOwnProperty(field)) {
		return evaluation[field];
	}
	return "";
};

const getDateField = (evaluation, field) => {
	const fieldUsed = field === "seen_date" && evaluation.eval_report_type === "LVR" ? "seen_date_reference" : field;
	return dayjs(getVal(evaluation, fieldUsed)).format("MMMM D, YYYY");
};

const getPositions = evaluation => {
	if (evaluation.hasOwnProperty("position")) {
		let positionString = evaluation.position;
		if (evaluation.hasOwnProperty("additional_positions") && evaluation.additional_positions) {
			const additionalPositions = evaluation.additional_positions.replace(/,/gi, ", ");
			positionString = positionString + ", " + additionalPositions;
		}
		return positionString;
	}
	return "";
};

const BioFields = ({ evaluation, fontSize, height }) => {
	return (
		<div style={{ display: "flex" }}>
			<table
				style={{
					width: "50%",
					maxWidth: "50%",
					tableLayout: "fixed",
					borderCollapse: "collapse",
					borderRight: "4px solid #223f8d"
				}}
			>
				<tbody>
					<tr
						style={{
							backgroundColor: "white",
							fontWeight: "normal",
							height,
							fontSize
						}}
					>
						<td style={{ ...CELL_STYLE, fontWeight: 600 }}>
							{evaluation.hasOwnProperty("additional_positions") && evaluation.additional_positions
								? "Positions Seen"
								: "Position Seen"}
						</td>
						<td style={CELL_STYLE}>{getPositions(evaluation)}</td>
					</tr>
					<tr
						style={{
							backgroundColor: "#E8E9ED",
							fontWeight: "normal",
							height,
							fontSize
						}}
					>
						<td style={{ ...CELL_STYLE, fontWeight: 600 }}>Seen Date</td>
						<td style={CELL_STYLE}>{getDateField(evaluation, "seen_date")}</td>
					</tr>
					<tr
						style={{
							backgroundColor: "white",
							fontWeight: "normal",
							height,
							fontSize
						}}
					>
						<td style={{ ...CELL_STYLE, fontWeight: 600 }}>Report Date</td>
						<td style={CELL_STYLE}>{getDateField(evaluation, "eval_create_date")}</td>
					</tr>
				</tbody>
			</table>
			<table
				style={{
					width: "50%",
					maxWidth: "50%",
					tableLayout: "fixed",
					borderCollapse: "collapse"
				}}
			>
				<thead>
					<tr
						style={{
							backgroundColor: "#E8E9ED",
							fontSize,
							fontWeight: 600,
							height
						}}
					>
						<th
							style={{
								...CELL_STYLE,
								width: "20%",
								wordWrap: "break-word"
							}}
						/>
						<th style={CELL_STYLE}>G</th>
						{evaluation.eval_type === "AMA_PITCH" && <th style={CELL_STYLE}>IP</th>}
						{evaluation.eval_type === "AMA_HIT" && <th style={CELL_STYLE}>PA</th>}
						{evaluation.eval_type === "AMA_HIT" && <th style={CELL_STYLE}>AB</th>}
						<th style={CELL_STYLE}>H</th>
						{evaluation.eval_type === "AMA_HIT" && <th style={CELL_STYLE}>XBH</th>}
						<th style={CELL_STYLE}>K</th>
						<th style={CELL_STYLE}>BB</th>
					</tr>
				</thead>
				<tbody>
					<tr
						style={{
							backgroundColor: "white",
							fontWeight: "normal",
							height,
							fontSize
						}}
					>
						<td style={{ ...CELL_STYLE, fontWeight: 600 }}>Seen</td>
						<td style={CELL_STYLE}>{getVal(evaluation, "games_seen")}</td>
						{evaluation.eval_type === "AMA_PITCH" && (
							<td style={CELL_STYLE}>{getVal(evaluation, "innings_seen_thirds")}</td>
						)}
						{evaluation.eval_type === "AMA_HIT" && (
							<td style={CELL_STYLE}>{getVal(evaluation, "plate_appearances_seen")}</td>
						)}
						{evaluation.eval_type === "AMA_HIT" && (
							<td style={CELL_STYLE}>{getVal(evaluation, "at_bats_seen")}</td>
						)}
						<td style={CELL_STYLE}>{getVal(evaluation, "hits_seen")}</td>
						{evaluation.eval_type === "AMA_HIT" && (
							<td style={CELL_STYLE}>{getVal(evaluation, "extra_base_hits_seen")}</td>
						)}
						<td style={CELL_STYLE}>{getVal(evaluation, "strikeouts_seen")}</td>
						<td style={CELL_STYLE}>{getVal(evaluation, "walks_seen")}</td>
					</tr>
					<tr
						style={{
							backgroundColor: "#E8E9ED",
							fontWeight: "normal",
							height,
							fontSize
						}}
					>
						<td style={{ ...CELL_STYLE, fontWeight: 600 }}>Total Seen</td>
						<td style={CELL_STYLE}>{getVal(evaluation, "total_games_seen")}</td>
						{evaluation.eval_type === "AMA_PITCH" && (
							<td style={CELL_STYLE}>{getVal(evaluation, "total_innings_seen_thirds")}</td>
						)}
						{evaluation.eval_type === "AMA_HIT" && (
							<td style={CELL_STYLE}>{getVal(evaluation, "total_plate_appearances_seen")}</td>
						)}
						{evaluation.eval_type === "AMA_HIT" && (
							<td style={CELL_STYLE}>{getVal(evaluation, "total_at_bats_seen")}</td>
						)}
						<td style={CELL_STYLE}>{getVal(evaluation, "total_hits_seen")}</td>
						{evaluation.eval_type === "AMA_HIT" && (
							<td style={CELL_STYLE}>{getVal(evaluation, "total_extra_base_hits_seen")}</td>
						)}
						<td style={CELL_STYLE}>{getVal(evaluation, "total_strikeouts_seen")}</td>
						<td style={CELL_STYLE}>{getVal(evaluation, "total_walks_seen")}</td>
					</tr>
					<tr
						style={{
							backgroundColor: "white",
							fontWeight: "normal",
							height,
							fontSize
						}}
					>
						<td style={{ ...CELL_STYLE, fontWeight: 600 }}>Game View Comments</td>
						<td colSpan={evaluation.eval_type === "AMA_HIT" ? 7 : 5} style={CELL_STYLE}>
							{getVal(evaluation, "game_view_comments")}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

BioFields.propTypes = {
	evaluation: PropTypes.object,
	fontSize: PropTypes.string,
	height: PropTypes.string
};

BioFields.defaultProps = {
	height: "32px",
	fontSize: ".875em"
};

export default BioFields;
