import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";

import { $TSFixMe, Style } from "utils/tsutils";

type CardActionsSeparatorProps = {
	size?: number;
};

export const CardActionsSeparator: FunctionComponent<CardActionsSeparatorProps> = ({ size = 1 }) => {
	return <div style={{ flex: size }} />;
};

type CardActionsProps = {
	children?: $TSFixMe;
	style?: Style;
};

const StyledCardActions = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	marginTop: "15px"
});
const StyledAction = styled.div({ padding: 3 });
const DEFAULT_STYLE = {};

export const CardActions: FunctionComponent<CardActionsProps> = ({ children, style = DEFAULT_STYLE }) => {
	return (
		<StyledCardActions style={{ display: "flex", ...style }}>
			{React.Children.map(children, child => {
				if (child == null || child.type.name === "CardActionsSeparator") return child;
				return <StyledAction>{child}</StyledAction>;
			})}
		</StyledCardActions>
	);
};
