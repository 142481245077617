import React, { useState } from "react";
import dayjs from "dayjs";

import Delete from "_react/shared/legacy/ui/icons/Delete";
import { Button } from "_react/shared/legacy/ui/Button";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";

import { DISABLED_OPACITY } from "_react/playerplan/shared/_constants";
import {
	DeletedSupportingActionDiv,
	DrillContentDiv,
	DrillDateAuthorDiv,
	DrillNameDiv,
	DrillWrapper,
	FloatRightDiv,
	LeftButtonStyle,
	PaddedDiv,
	SmallCircularProgressRightStyle,
	SupportingActionFlexDiv,
	WideFloatRightDiv
} from "_react/playerplan/shared/_styles";
import { TPlayerPlanGoalDrill } from "_react/playerplan/shared/_types";

export type TSupportingActionProps = {
	goalDrill: TPlayerPlanGoalDrill;
	isReadOnly: boolean;
	isGoalActionsDisabled: boolean;
	circularProgressGoalDrillId: number | null;
	setCircularProgressGoalDrillId: Function;
	deleteGoalDrill: Function;
	colorSchemeGroup?: ColorSchemeGroup;
	isDeleted: boolean;
};

export const SupportingAction = ({
	goalDrill,
	isReadOnly,
	isGoalActionsDisabled,
	circularProgressGoalDrillId,
	setCircularProgressGoalDrillId,
	deleteGoalDrill,
	colorSchemeGroup = defaultColorScheme,
	isDeleted
}: TSupportingActionProps) => {
	const [isConfirmingDelete, setIsConfirmingDelete] = useState<boolean>(false);

	return (
		<DrillWrapper>
			<SupportingActionFlexDiv>
				{!isDeleted ? (
					<div>
						{goalDrill.playerPlanDrill?.name && (
							<DrillNameDiv>{goalDrill.playerPlanDrill?.name ?? ""}</DrillNameDiv>
						)}
						<DrillDateAuthorDiv>
							{goalDrill.lastChangeUser &&
								`${goalDrill.lastChangeUser?.firstName} ${goalDrill.lastChangeUser?.lastName} - `}
							{goalDrill.lastChangeDate
								? dayjs
										.utc(goalDrill.createDate.substring(0, 23))
										.local()
										.format("MMMM D, YYYY")
								: "No Date"}
						</DrillDateAuthorDiv>
						<DrillContentDiv>{goalDrill.content}</DrillContentDiv>
					</div>
				) : (
					<DeletedSupportingActionDiv>
						{goalDrill.playerPlanDrill?.name && (
							<DrillNameDiv>{goalDrill.playerPlanDrill?.name ?? ""}</DrillNameDiv>
						)}
						<DrillDateAuthorDiv>
							{goalDrill.lastChangeUser &&
								`${goalDrill.lastChangeUser?.firstName} ${goalDrill.lastChangeUser?.lastName}: `}
							{`${dayjs
								.utc(goalDrill.createDate.substring(0, 23))
								.local()
								.format("MMMM D, YYYY")} - 
							${dayjs
								.utc(goalDrill.lastChangeDate.substring(0, 23))
								.local()
								.format("MMMM D, YYYY")} `}
						</DrillDateAuthorDiv>
						<DrillContentDiv>{goalDrill.content}</DrillContentDiv>
					</DeletedSupportingActionDiv>
				)}
				{!isReadOnly && !isDeleted && (
					<PaddedDiv>
						{circularProgressGoalDrillId === goalDrill.id ? (
							<CircularProgress center={false} style={SmallCircularProgressRightStyle} />
						) : isConfirmingDelete ? (
							<WideFloatRightDiv>
								<Button
									style={LeftButtonStyle}
									disabled={isGoalActionsDisabled}
									onClick={() => {
										setIsConfirmingDelete(false);
										setCircularProgressGoalDrillId(goalDrill.id);
										deleteGoalDrill(goalDrill.id);
									}}
									title="Confirm"
									colorScheme={colorSchemeGroup.secondary}
									disabledOpacity={DISABLED_OPACITY}
								/>
								<Button
									disabled={false}
									onClick={() => setIsConfirmingDelete(false)}
									title="Cancel"
									colorScheme={colorSchemeGroup.primary}
								/>
							</WideFloatRightDiv>
						) : (
							<FloatRightDiv>
								<Tooltip
									title="Delete Supporting Action"
									placement="left"
									colorScheme={colorSchemeGroup.primary}
								>
									<Delete
										fill={colorSchemeGroup.secondary.color}
										onClick={() => setIsConfirmingDelete(true)}
									/>
								</Tooltip>
							</FloatRightDiv>
						)}
					</PaddedDiv>
				)}
			</SupportingActionFlexDiv>
		</DrillWrapper>
	);
};
