import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function BarChart(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M24 21.6667V22.3333C24 22.7013 23.7013 23 23.3333 23H0.666667C0.298667 23 0 22.7013 0 22.3333V21.6667C0 21.2987 0.298667 21 0.666667 21H23.3333C23.7013 21 24 21.2987 24 21.6667ZM4 19.6667H6.66667C7.03467 19.6667 7.33333 19.368 7.33333 19V6.33333C7.33333 5.96533 7.03467 5.66667 6.66667 5.66667H4C3.632 5.66667 3.33333 5.96533 3.33333 6.33333V19C3.33333 19.368 3.632 19.6667 4 19.6667ZM17.3333 19.6667H20C20.368 19.6667 20.6667 19.368 20.6667 19V11C20.6667 10.632 20.368 10.3333 20 10.3333H17.3333C16.9653 10.3333 16.6667 10.632 16.6667 11V19C16.6667 19.368 16.9653 19.6667 17.3333 19.6667ZM10.6667 19.6667H13.3333C13.7013 19.6667 14 19.368 14 19V1.66667C14 1.29867 13.7013 1 13.3333 1H10.6667C10.2987 1 10 1.29867 10 1.66667V19C10 19.368 10.2987 19.6667 10.6667 19.6667Z"
			/>
		</Icon>
	);
}
