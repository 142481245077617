import React from "react";
import PropTypes from "prop-types";

import { HIT_SVG } from "_react/shared/legacy/ui/icons/hit_svg";
import { PITCH_SVG } from "_react/shared/legacy/ui/icons/pitch_svg";
import { FIELDER_SVG } from "_react/shared/legacy/ui/icons/fielder_svg";
import { PITCHER, BATTER, FIELDER } from "_redux/StatsAggregated/_helpers";

// use batter fielder and pitcher constants in case those change from the ones below
const TYPE_TO_SVG = {
	hitter: HIT_SVG,
	batter: HIT_SVG,
	[BATTER]: HIT_SVG,
	fielder: FIELDER_SVG,
	[FIELDER]: FIELDER_SVG,
	pitcher: PITCH_SVG,
	[PITCHER]: PITCH_SVG
};

const PlayerTypeSVG = ({ playerType, fill, ...props }) => {
	let PlayerTypeSVG;
	if (TYPE_TO_SVG.hasOwnProperty(playerType)) {
		PlayerTypeSVG = TYPE_TO_SVG[playerType];
	} else {
		const lowerCasePlayerType = playerType.toLowerCase();
		PlayerTypeSVG = TYPE_TO_SVG[lowerCasePlayerType];
	}
	if (PlayerTypeSVG == null) return null;
	return (
		<div {...props}>
			<PlayerTypeSVG fill={fill} />
		</div>
	);
};

PlayerTypeSVG.propTypes = {
	fill: PropTypes.string,
	playerType: PropTypes.string.isRequired
};

PlayerTypeSVG.defaultProps = {
	fill: "white"
};

export default PlayerTypeSVG;
