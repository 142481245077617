import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import { useUserId } from "_react/app/AppContents";
import { logoURL } from "_react/shared/_helpers/team_logo";
import { LineScore } from "_react/shared/baseball/LineScore";
import { IIamGroupUser } from "_react/shared/_types/schema/iam";
import { Button } from "_react/shared/legacy/ui/Button";
import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { useFetch } from "utils/url_helpers";

import { loadGameSummaryGames } from "_react/gamesummary/_network";
import {
	GameSummaryHeader,
	GameSummaryTeamImageStyle,
	GameSummaryDate,
	GameSummaryVenue,
	GameSummaryDoubleheader,
	GameSummaryGameContainer,
	GameSummaryTeamImageContainer,
	GameSummaryGameLoadingContainer,
	GameSummaryGameLoading,
	GameSummaryImageContainer,
	GameSummaryContainer,
	SUMMARY_BUTTON_STYLE
} from "_react/gamesummary/_styles";
import { TGameSummaryWidgetGame } from "_react/gamesummary/_types";

const GAME_REPORT_IAM_GROUP = 88;

type GameSummaryWidgetGameProps = {
	game: TGameSummaryWidgetGame;
	openLinkInNewWindow?: boolean;
};

export const GameSummaryWidgetGame: FC<GameSummaryWidgetGameProps> = ({ game, openLinkInNewWindow }) => {
	const history = useHistory();

	// Checks if user is in the Admin Group for Game Report
	const userId = useUserId();
	const { responseData: gameReportGroup } = useFetch(`iam/group?id=${GAME_REPORT_IAM_GROUP}&isCamelCase=True`);
	const gameReportAdminIds = gameReportGroup ? gameReportGroup.users.map((u: IIamGroupUser) => u.userId) : [];
	const isGameReportAdmin = gameReportAdminIds.includes(userId);

	return (
		<GameSummaryGameContainer>
			<GameSummaryHeader>
				<GameSummaryContainer>
					<GameSummaryDate>{dayjs(game.metadata.date).format("MMM D")}</GameSummaryDate>
					<GameSummaryImageContainer>
						{game.metadata.awayTeamBamId != null && (
							<GameSummaryTeamImageContainer>
								<img
									alt="teamImg"
									src={logoURL(game.metadata.awayTeamBamId)}
									style={GameSummaryTeamImageStyle}
								/>
							</GameSummaryTeamImageContainer>
						)}
						{game.metadata.homeTeamBamId != null && (
							<GameSummaryTeamImageContainer>
								<img
									alt="teamImg"
									src={logoURL(game.metadata.homeTeamBamId)}
									style={GameSummaryTeamImageStyle}
								/>
							</GameSummaryTeamImageContainer>
						)}
					</GameSummaryImageContainer>
					{game.metadata.isDoubleHeader === 1 && (
						<GameSummaryDoubleheader>Game {game.metadata.gameNumber}</GameSummaryDoubleheader>
					)}
					<LineScore lineScore={game.lineScore} showInnings={false} />
					{game.metadata.venue != null && <GameSummaryVenue>{game.metadata.venue.name}</GameSummaryVenue>}
				</GameSummaryContainer>
			</GameSummaryHeader>
			{isGameReportAdmin && (
				<Button
					colorScheme={defaultColorScheme.secondary}
					title={game.metadata.gameReportId != null ? "Game Report" : "Create Game Report"}
					onClick={
						game.metadata.gameReportId != null
							? () =>
									openLinkInNewWindow
										? window.open(`../gamereport?id=${game.metadata.gameReportId}`, "_blank")
										: history.push(`/gamereport?id=${game.metadata.gameReportId}`)
							: () =>
									openLinkInNewWindow
										? window.open(`../gamereport?&view=edit`, "_blank")
										: history.push(`/gamereport?&view=edit`)
					}
				/>
			)}
			{isGameReportAdmin && game.metadata.gameReportId == null && (
				<Button
					style={SUMMARY_BUTTON_STYLE}
					colorScheme={defaultColorScheme.primary}
					title="Game Summary"
					onClick={() =>
						openLinkInNewWindow
							? window.open(`../game?id=${game.metadata.id}`, "_blank")
							: history.push(`/game?id=${game.metadata.id}`)
					}
				/>
			)}
			{!isGameReportAdmin && (
				<Button
					colorScheme={defaultColorScheme.primary}
					title="Game Summary"
					onClick={() =>
						openLinkInNewWindow
							? window.open(`../game?id=${game.metadata.id}`, "_blank")
							: history.push(`/game?id=${game.metadata.id}`)
					}
				/>
			)}
		</GameSummaryGameContainer>
	);
};

type GameSummaryWidgetProps = {
	orgId?: number;
	teamId?: number;
	teamIds?: number[];
	playerId?: number;
	openLinksInNewWindow?: boolean;
};

export const GameSummaryWidget: FC<GameSummaryWidgetProps> = ({
	orgId,
	teamId,
	teamIds,
	playerId,
	openLinksInNewWindow
}) => {
	const [games, setGames] = useState<TGameSummaryWidgetGame[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	// Loads game summaries and saves them to games
	useEffect(() => {
		setIsLoading(true);
		loadGameSummaryGames(orgId, teamId, teamIds, playerId).then(newGames => {
			setGames(newGames.sort((a, b) => (dayjs(a.metadata.date).isBefore(dayjs(b.metadata.date)) ? -1 : 1)));
			setIsLoading(false);
		});
	}, [orgId, teamId, teamIds, playerId]);

	return (
		<GameSummaryGameLoadingContainer>
			{isLoading &&
				[0, 1, 2, 3, 4].map(game => (
					<GameSummaryGameContainer key={game} style={GameSummaryGameLoading} className="loading-item" />
				))}
			{games.map(game => (
				<GameSummaryWidgetGame key={game.metadata.id} game={game} openLinkInNewWindow={openLinksInNewWindow} />
			))}
		</GameSummaryGameLoadingContainer>
	);
};

export default GameSummaryWidget;
