import React from "react";
import PropTypes from "prop-types";

import FormObject from "_react/forms/FormObject";
import { DEFAULT_VALIDATION } from "_react/forms/FormObjects";
import { isMobile } from "utils/_helpers";

class FormSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			labelWidth: props.labelWidth != null ? props.labelWidth : 0
		};

		this.reportLabelWidth = this.reportLabelWidth.bind(this);
		this.triggerSave = this.triggerSave.bind(this);
		this.generateGroup = this.generateGroup.bind(this);
	}

	labelWidth = 0;
	objects = [];

	reportLabelWidth = labelWidth => {
		if (labelWidth > this.labelWidth && this.props.labelWidth == null) {
			this.labelWidth = labelWidth;
			this.setState({
				labelWidth: labelWidth
			});
		}
	};

	triggerSave = () => {
		let success = true;
		for (let i = 0; i < this.objects.length; i++) {
			if (
				this.objects[i] &&
				this.objects[i].props.configuration.type !== "button" &&
				this.objects[i].props.configuration.type !== "label"
			) {
				if (!this.objects[i].triggerSave()) success = false;
			}
		}
		return success;
	};

	generateGroup = (layoutGroup, colorScheme, idx) => {
		return (
			<div
				key={idx}
				style={{
					display: "flex",
					backgroundColor:
						idx % 2 === 0 ? colorScheme.section.backgroundColorEven : colorScheme.section.backgroundColorOdd
				}}
			>
				{layoutGroup}
			</div>
		);
	};

	render() {
		const {
			editing,
			configuration,
			handleChange,
			colorScheme,
			dataSource,
			autosave,
			autosaveTimer,
			windowWidth
		} = this.props;
		const { labelWidth } = this.state;

		this.objects = [];

		const layoutGroups = [];
		let currentLayoutGroup = [];
		let runningTotal = 0;
		for (let i = 0; i < configuration.objects.length; i++) {
			if (
				configuration.objects[i].show === false ||
				(configuration.objects[i].conditionalShow != null &&
					configuration.objects[i].conditionalShow(this.props) === false)
			)
				continue;

			if (
				configuration.objects[i].validation == null &&
				DEFAULT_VALIDATION[configuration.objects[i].type] != null
			) {
				configuration.objects[i].validation = DEFAULT_VALIDATION[configuration.objects[i].type];
			}

			// Generate the actual FormObject
			const formObject = (
				<FormObject
					autosave={autosave}
					autosaveTimer={autosaveTimer}
					colorScheme={colorScheme}
					configuration={configuration.objects[i]}
					dataSource={dataSource}
					editing={editing}
					handleChange={handleChange}
					idx={layoutGroups.length}
					key={i}
					labelWidth={labelWidth}
					ref={node => this.objects.push(node)}
					reportLabelWidth={this.reportLabelWidth}
					windowWidth={windowWidth}
				/>
			);

			// Add FormObject to current layout group
			currentLayoutGroup.push(formObject);
			runningTotal += configuration.objects[i].partialWidth;

			// Check if current FormGroup is partial
			if (
				(isMobile(windowWidth) && configuration.objects[i].preventMobileBreak !== true) ||
				configuration.objects[i].partialWidth == null ||
				i === configuration.objects.length - 1 ||
				configuration.objects[i + 1].partialWidth == null ||
				runningTotal >= 12
			) {
				// Add this group to layout
				layoutGroups.push(this.generateGroup(currentLayoutGroup, colorScheme, layoutGroups.length));
				currentLayoutGroup = [];
				runningTotal = 0;
			}
		}

		return (
			<div
				style={{
					display: "block",
					flex: configuration.width ? configuration.width : 1,
					backgroundColor:
						configuration.objects.length % 2 === 0
							? colorScheme.section.backgroundColorEven
							: colorScheme.section.backgroundColorOdd,
					borderRadius: colorScheme.section.borderRadius,
					// overflow: "hidden",
					margin: "2px",
					...configuration.style
				}}
			>
				{layoutGroups}
			</div>
		);
	}
}

FormSection.propTypes = {
	autosave: PropTypes.bool,
	autosaveTimer: PropTypes.number,
	colorScheme: PropTypes.object,
	configuration: PropTypes.object,
	dataSource: PropTypes.object,
	editing: PropTypes.bool,
	handleChange: PropTypes.func,
	labelWidth: PropTypes.number,
	windowWidth: PropTypes.number
};

FormSection.displayName = "FormSection";

export default FormSection;
