import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = {
	suggestionListItemStyle: {
		zIndex: 2000000000,
		background: "white",
		opacity: 1
	}
};

const AutocompleteSuggestionsListItem = ({ suggestion, handleSelected, hover }) => (
	<ListItem
		button
		onClick={() => handleSelected(suggestion)}
		style={{
			...styles.suggestionListItemStyle,
			backgroundColor: hover ? "lightGray" : "white"
		}}
	>
		{suggestion.tag != null && <span>{suggestion.tag}</span>}
		<ListItemText
			// Removes extra padding introduced from Chakra Provider
			style={{ paddingRight: "0px" }}
		>
			{suggestion.text}
		</ListItemText>
	</ListItem>
);

AutocompleteSuggestionsListItem.propTypes = {
	handleSelected: PropTypes.func,
	hover: PropTypes.bool,
	suggestion: PropTypes.object
};

export default AutocompleteSuggestionsListItem;
