import React, { CSSProperties } from "react";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { getCurrency } from "utils/helpers";
import { IAddendumBMjBaseSalary } from "_react/shared/_types/schema/contracts";

const MJ_BASE_SALARY_COLUMNS = [
	{
		title: "Year",
		name: "year",
		getCellValue: (row: IAddendumBMjBaseSalary) => row.year
	},
	{
		title: "ML Base Salary",
		name: "salary_mj_base",
		getCellValue: (row: IAddendumBMjBaseSalary) => getCurrency(row.salary_mj_base)
	}
];

type TAddendumBMjBaseSalaryTableStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

interface IProps {
	addendumBMjBaseSalaries: IAddendumBMjBaseSalary[];
	colorSchemeGroup: ColorSchemeGroup;
	style?: TAddendumBMjBaseSalaryTableStyle;
}

function AddendumBMjBaseSalaryTable({ addendumBMjBaseSalaries, colorSchemeGroup, style }: IProps) {
	return (
		<React.Fragment>
			<Card style={style?.card}>
				<ColoredCardHeader
					colorScheme={colorSchemeGroup.secondary}
					text={"Addendum B Major League Base Salary"}
					style={style?.cardHeader}
				/>
				<AutoFitTable
					columns={MJ_BASE_SALARY_COLUMNS}
					defaultSortColumn={"Year"}
					defaultSortState={"desc"}
					isHighlightOnHover={false}
					rows={addendumBMjBaseSalaries}
					rowKey="addendum_b_id"
				/>
			</Card>
		</React.Fragment>
	);
}

export default AddendumBMjBaseSalaryTable;
