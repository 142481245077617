import dayjs from "dayjs";

export function selectProTeamsState(state, year = dayjs().year()) {
	return state.team.pro[year];
}

export function selectProTeams(state, season = dayjs().year()) {
	return selectProTeamsState(state, season) && selectProTeamsState(state, season).bam_id
		? selectProTeamsState(state, season).bam_id
		: {};
}
