import React, { useState, useEffect } from "react";

import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { MinorSection } from "_react/shared/legacy/ui/SectionWrapper";
import { transaction } from "_react/shared/_types/mesa/transaction";

import { PHP_TIER } from "_react/playerplan/metrics/_constants";
import { GoalContent, WrapperStyle, DetailsWrapperStyle } from "_react/playerplan/shared/_styles";
import { getInitialGoalDetailsSeason, shouldDisplayGraph } from "_react/playerplan/shared/_helpers";
import {
	TGoalDrillForm,
	TGoalForm,
	TNoteForm,
	TPlayerPlanGoal,
	TPlayerPlanNote,
	TPlayerPlanTarget,
	TSeasonsForm,
	TTargetForm
} from "_react/playerplan/shared/_types";
import { GoalAdjustments, TGoalAdjustmentsStyle } from "_react/playerplan/shared/GoalAdjustments";
import { GoalChart } from "_react/playerplan/shared/GoalChart";
import { GoalDetails, TGoalDetailsStyle } from "_react/playerplan/shared/GoalDetails";
import { FocusAreasWrapper, TFocusAreasWrapperStyle } from "_react/playerplan/shared/FocusAreasWrapper";
import { PlayerPlanNotes, TPlayerPlanNotesStyle } from "_react/playerplan/shared/PlayerPlanNotes";
import { GoalStatusCheckboxes, TGoalStatusCheckboxesStyle } from "_react/playerplan/shared/GoalStatusCheckboxes";

export type TGoalContentWrapperStyle = {
	goalAdjustmentsStyle?: TGoalAdjustmentsStyle;
	goalDetailsStyle?: TGoalDetailsStyle;
	goalStatusCheckboxesStyle?: TGoalStatusCheckboxesStyle;
	playerPlanNotesStyle?: TPlayerPlanNotesStyle;
	focusAreasWrapperStyle?: TFocusAreasWrapperStyle;
};

export type TGoalContentWrapperProps = {
	userId: number;
	goal: TPlayerPlanGoal;
	secondaryGoal?: TPlayerPlanGoal;
	focusAreas?: Array<TPlayerPlanGoal>;
	addFocusArea: (focusAreaForm: TGoalForm) => void;
	deleteFocusArea: (goal: TPlayerPlanGoal, deleteGoalDrillIds: Array<number>) => void;
	isAddingFocusArea: boolean;
	circularProgressFocusAreaId: number | null;
	setCircularProgressFocusAreaId: (focusAreaId: number) => void;
	circularProgressGoalDrillId: number | null;
	setCircularProgressGoalDrillId: (goalDrillId: number) => void;
	transactions: Array<transaction> | null;
	isGoalActionsDisabled?: boolean;
	updateGoal: (goal: TPlayerPlanGoal, target?: TPlayerPlanTarget, updateApprovalDate?: boolean) => void;
	isAddingGoalDrill?: boolean;
	isAddingNote?: boolean;
	isGroup1?: boolean;
	isGroup2?: boolean;
	isReadOnly?: boolean;
	addGoalDrill: (goalDrillForm: TGoalDrillForm) => void;
	deleteGoalDrill: (goalDrillId: number) => void;
	notes: TPlayerPlanNote[] | null;
	addNote: (noteForm: TNoteForm) => void;
	addTarget?: (targetForm: TTargetForm) => void;
	isAddingTarget?: boolean;
	addSeasons?: (seasonsForm: TSeasonsForm) => void;
	isAddingSeasons?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TGoalContentWrapperStyle;
	setIsSecondaryGoalModalOpen: (value: boolean) => void;
	setModalPrimaryGoal: (goal: TPlayerPlanGoal | null) => void;
	canAddTierTwo?: boolean;
	canAddTierTwoStrength?: boolean;
};

export const GoalContentWrapper = ({
	userId,
	goal,
	secondaryGoal,
	focusAreas,
	addFocusArea,
	isAddingFocusArea,
	deleteFocusArea,
	circularProgressFocusAreaId,
	setCircularProgressFocusAreaId,
	circularProgressGoalDrillId,
	setCircularProgressGoalDrillId,
	transactions,
	isGoalActionsDisabled = false,
	updateGoal,
	isAddingGoalDrill = false,
	isAddingNote = false,
	isGroup1 = false,
	isGroup2 = false,
	isReadOnly = false,
	addGoalDrill,
	deleteGoalDrill,
	addNote,
	addTarget,
	notes,
	isAddingTarget = false,
	addSeasons,
	isAddingSeasons = false,
	colorSchemeGroup = defaultColorScheme,
	style,
	setIsSecondaryGoalModalOpen,
	setModalPrimaryGoal,
	canAddTierTwo = false,
	canAddTierTwoStrength = false
}: TGoalContentWrapperProps) => {
	const isPHP = goal.playerPlanMetric.metricTierLk === PHP_TIER;

	// Handle season selection
	const [selectedGoalSeason, setSelectedGoalSeason] = useState<number>(getInitialGoalDetailsSeason(goal));

	// Whether to show season value in Goal Details/Goal Graph section
	const [isSeasonValue, setIsSeasonValue] = useState<boolean>(true);

	// When goal changes, reset season value to initial state
	useEffect(() => {
		setIsSeasonValue(true);
		setSelectedGoalSeason(getInitialGoalDetailsSeason(goal));
	}, [goal]);

	return (
		<GoalContent>
			{!isPHP && (
				<MinorSection title="Goal Details" style={DetailsWrapperStyle}>
					<GoalDetails
						goal={goal}
						selectedGoalSeason={selectedGoalSeason}
						setSelectedGoalSeason={setSelectedGoalSeason}
						secondaryGoal={secondaryGoal}
						isSeasonValue={isSeasonValue}
						colorSchemeGroup={colorSchemeGroup}
						canAddTierTwo={canAddTierTwo}
						setIsSecondaryGoalModalOpen={setIsSecondaryGoalModalOpen}
						setModalPrimaryGoal={setModalPrimaryGoal}
						addSeasons={addSeasons}
						isAddingSeasons={isAddingSeasons}
						isGoalActionsDisabled={isGoalActionsDisabled}
						canAddTierTwoStrength={canAddTierTwoStrength}
						isSeasonsEditable={isGroup1 || isGroup2}
						style={style?.goalDetailsStyle}
					/>
				</MinorSection>
			)}
			{shouldDisplayGraph(goal, secondaryGoal) && (
				<MinorSection title={"Goal Graph"} style={WrapperStyle}>
					<GoalChart
						goal={goal}
						selectedGoalSeason={selectedGoalSeason}
						secondaryGoal={secondaryGoal}
						transactions={transactions}
						isSeasonValue={isSeasonValue}
						setIsSeasonValue={setIsSeasonValue}
						colorSchemeGroup={colorSchemeGroup}
					/>
				</MinorSection>
			)}
			{!isPHP && (
				<MinorSection title={"Focus Areas & Supporting Actions"} style={WrapperStyle}>
					<FocusAreasWrapper
						goal={goal}
						isReadOnly={isReadOnly}
						focusAreas={focusAreas}
						addFocusArea={addFocusArea}
						isAddingFocusArea={isAddingFocusArea}
						addGoalDrill={addGoalDrill}
						isAddingGoalDrill={isAddingGoalDrill}
						deleteFocusArea={deleteFocusArea}
						circularProgressFocusAreaId={circularProgressFocusAreaId}
						setCircularProgressFocusAreaId={setCircularProgressFocusAreaId}
						circularProgressGoalDrillId={circularProgressGoalDrillId}
						setCircularProgressGoalDrillId={setCircularProgressGoalDrillId}
						deleteGoalDrill={deleteGoalDrill}
						isGoalActionsDisabled={isGoalActionsDisabled}
						colorSchemeGroup={colorSchemeGroup}
						style={style?.focusAreasWrapperStyle}
					/>
				</MinorSection>
			)}
			<MinorSection title={"Notes"} style={WrapperStyle}>
				<PlayerPlanNotes
					userId={userId}
					isReadOnly={isReadOnly}
					isAddingNote={isAddingNote}
					goalId={goal.id}
					notes={notes}
					addNote={addNote}
					isShowGoalStatement={false}
					colorSchemeGroup={colorSchemeGroup}
					style={style?.playerPlanNotesStyle}
				/>
			</MinorSection>
			{!isPHP && (
				<MinorSection title={"Adjustments"} style={WrapperStyle}>
					<GoalAdjustments
						goal={goal}
						isGoalActionsDisabled={isGoalActionsDisabled}
						secondaryGoal={secondaryGoal}
						addTarget={addTarget}
						isAddingTarget={isAddingTarget}
						isGroup1={isGroup1}
						isGroup2={isGroup2}
						colorSchemeGroup={colorSchemeGroup}
						style={style?.goalAdjustmentsStyle}
					/>
				</MinorSection>
			)}
			<GoalStatusCheckboxes
				userId={userId}
				goal={goal}
				isPHP={isPHP}
				isGoalActionsDisabled={isGoalActionsDisabled}
				updateGoal={updateGoal}
				isGroup1={isGroup1}
				isGroup2={isGroup2}
				colorSchemeGroup={colorSchemeGroup}
				style={style?.goalStatusCheckboxesStyle}
			/>
		</GoalContent>
	);
};
