import React from "react";
import PropTypes from "prop-types";

import { BLUE } from "_react/css";

export const PLAYER_PAGE_HEADER_STYLE = {
	backgroundColor: BLUE,
	width: "100%",
	color: "white",
	display: "flex",
	alignItems: "center",
	justifyContent: "left",
	borderTopLeftRadius: "3px",
	borderTopRightRadius: "3px",
	fontSize: "17px",
	textTransform: "uppercase",
	minHeight: "35px",
	fontWeight: 600,
	fontFamily: "Oswald, sans-serif"
};

const PlayerPageHeader = ({ text, height }) => {
	return (
		<div style={{ ...PLAYER_PAGE_HEADER_STYLE, height }}>
			<span style={{ paddingLeft: "8px" }}>{text}</span>
		</div>
	);
};

PlayerPageHeader.propTypes = {
	height: PropTypes.number,
	text: PropTypes.string
};

PlayerPageHeader.defaultProps = {
	height: 30
};

export default PlayerPageHeader;
