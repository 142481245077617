import { round10 } from "_react/shared/_helpers/numbers";
import {
	NULL_VALUE,
	TOTAL_BATTING_RAR_COLUMN,
	BATTING_RAA_COLUMN,
	BASERUNNING_RAA_COLUMN,
	DEF_RAA_COLUMN,
	POS_ADJ_COLUMN,
	COLOR_GRADIENT_FUNCTION_BLUE_GREEN
} from "_react/projections/pro/leaderboard/_constants";
import { VALUE_TYPE_NUMBER } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { TSimpleHitterProjections } from "_react/shared/data_models/projections/_types";

export const DEFAULT_NUM_SEASONS = 10;

export const SIMPLE_PROJECTIONS_CANCEL_SOURCE = "simpleProjections";
export const REPLACEMENT_LEVEL_CANCEL_SOURCE = "replacementLevel";

const SEASON_COLUMN = {
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType
};

const BATTING_RAA_VL_COLUMN = {
	value: "battingRaaVl",
	label: "Hit vL RAA",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (projection: TSimpleHitterProjections) =>
		projection?.platoonProjection?.blendedBattingRaaVl
			? round10(projection.platoonProjection.blendedBattingRaaVl, -1)
			: NULL_VALUE,
	getSortValueFunction: (projection: TSimpleHitterProjections) =>
		projection?.platoonProjection?.blendedBattingRaaVl
			? projection.platoonProjection.blendedBattingRaaVl
			: NULL_VALUE,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
};

const BATTING_RAA_VR_COLUMN = {
	value: "battingRaaVr",
	label: "Hit vR RAA",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (projection: TSimpleHitterProjections) =>
		projection?.platoonProjection?.blendedBattingRaaVr
			? round10(projection.platoonProjection.blendedBattingRaaVr, -1)
			: NULL_VALUE,
	getSortValueFunction: (projection: TSimpleHitterProjections) =>
		projection?.platoonProjection?.blendedBattingRaaVr
			? projection.platoonProjection.blendedBattingRaaVr
			: NULL_VALUE,
	colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
};

export const PROJECTIONS_HITTERS_COLUMNS = [
	SEASON_COLUMN,
	TOTAL_BATTING_RAR_COLUMN,
	BATTING_RAA_COLUMN,
	BATTING_RAA_VL_COLUMN,
	BATTING_RAA_VR_COLUMN,
	DEF_RAA_COLUMN,
	BASERUNNING_RAA_COLUMN,
	POS_ADJ_COLUMN
];
