import React from "react";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import { Injuries } from "_react/playerpage/injuries/Injuries";

type TInjuriesTabProps = {
	player: TPlayerPageCombinedPlayer;
	viewClassification: string | null;
};

const InjuriesTab = ({ player, viewClassification }: TInjuriesTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	return (
		<Injuries colorSchemeGroup={chakraColorSchemeGroup} player={player} viewClassification={viewClassification} />
	);
};

export default InjuriesTab;
