import { CancelToken } from "axios";

import axios from "_redux/_utils/_axios";

import { IImportantDate } from "_react/shared/data_models/important_date/_types";

// arsenal_scores_params

export type TImportantDateGetQueryParams = {
	season?: number;
	"season[in]"?: string;
	"season[gte]"?: string;
	"season[lte]"?: string;
	level?: string;
	"level[in]"?: string;
	type?: string;
	"type[in]"?: string;
	date?: string;
	"date[in]"?: string;
	"date[gte]"?: string;
	"date[lte]"?: string;
};

export const fetchImportantDate = (queryParams: TImportantDateGetQueryParams, cancelToken?: CancelToken) => {
	let queryParamsString = "?";
	if (queryParams)
		Object.entries(queryParams).forEach((param: [string, string | number | boolean | undefined]) => {
			if (param[1] !== undefined)
				queryParamsString = queryParamsString + `${param[0]}=${encodeURIComponent(param[1])}&`;
		});
	const path = `/important_date${queryParamsString}`;
	return axios
		.get(path, { cancelToken })
		.then(response => response.data as Array<IImportantDate>)
		.catch(error => {
			return axios.isCancel(error) ? Promise.resolve(undefined) : Promise.reject(error);
		});
};
