import React, { useEffect, useMemo } from "react";
import { Box, HStack, IconButton, Text, Tooltip, VStack, useToast, useBreakpointValue } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import Leaderboard from "_react/shared/ui/icons/Leaderboard";
import { POSITION_GROUPS_PITCHER } from "_react/shared/_constants/position_groups";
import { getPositionGroupLabel } from "_react/shared/_helpers/position_groups";
import { TPositionGroup } from "_react/shared/_types/position_groups";
import {
	TAmaProspectValueSimilarPlayersModelName,
	TAmaProspectValueSimilarPlayersPlayerType
} from "_react/shared/data_models/phred/_network";
import { StyledSelect } from "_react/shared/selects";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import {
	DEFAULT_MIN_R4_YEAR,
	POSITION_GROUPS_PITCHER_OPTIONS,
	POSITION_GROUPS_POSITION_PLAYER_OPTIONS
} from "_react/shared/ui/data/tables/AmaSurplusValueSimilarPlayersTable/_constants";
import {
	createAmaSurplusValueSimilarPlayersColumns,
	getR4YearOptions,
	parseSimilarPlayersData
} from "_react/shared/ui/data/tables/AmaSurplusValueSimilarPlayersTable/_helpers";
import AmaSurplusValueSimilarPlayersTableMachine, {
	FETCHING_SIMILAR_PLAYERS,
	SET_MODEL_NAME,
	SET_PLAYER_ID,
	SET_PLAYER_TYPE,
	SET_POSITION,
	SET_POSITION_GROUP,
	SET_R4_YEAR_PLAYER,
	SET_MIN_R4_YEAR,
	SET_MAX_R4_YEAR
} from "_react/shared/ui/data/tables/AmaSurplusValueSimilarPlayersTable/_machine";
import {
	TAmaSurplusValueSimilarPlayersTableData,
	TAmaSurplusValueSimilarPlayer
} from "_react/shared/ui/data/tables/AmaSurplusValueSimilarPlayersTable/_types";

type TAmaSurplusValueSimilarPlayersTableProps = {
	title?: string;
	playerId?: number;
	playerType?: TAmaProspectValueSimilarPlayersPlayerType;
	modelName?: TAmaProspectValueSimilarPlayersModelName;
	r4YearPlayer?: number;
	minR4Year?: number;
	maxR4Year?: number;
	position?: string;
	positionGroup?: string;
	data?: TAmaSurplusValueSimilarPlayersTableData;
	shouldFetchData?: boolean;
	currentDraftYear?: number;
	positionSelect?: { showSelect: boolean; positionGroup?: TPositionGroup };
	columns?: Array<string>;
	tableProps?: TTableProps<TAmaSurplusValueSimilarPlayer, keyof TAmaSurplusValueSimilarPlayer>;
};

const AmaSurplusValueSimilarPlayersTable = ({
	title,
	playerId,
	playerType,
	modelName,
	r4YearPlayer,
	minR4Year = DEFAULT_MIN_R4_YEAR,
	maxR4Year,
	position,
	positionGroup,
	data,
	shouldFetchData = true,
	currentDraftYear,
	positionSelect,
	columns,
	tableProps
}: TAmaSurplusValueSimilarPlayersTableProps) => {
	const parsedMaxR4Year = maxR4Year ? maxR4Year : currentDraftYear ? currentDraftYear - 1 : undefined;
	const parsedPositionGroup =
		positionSelect?.showSelect && positionSelect.positionGroup ? positionSelect.positionGroup : positionGroup;
	const toast = useToast();
	const [current, send] = useMachine(
		AmaSurplusValueSimilarPlayersTableMachine(
			playerId,
			playerType,
			modelName,
			r4YearPlayer,
			minR4Year,
			parsedMaxR4Year,
			position,
			parsedPositionGroup,
			data,
			shouldFetchData,
			toast
		)
	);
	const context = current.context;
	const {
		similarPlayers,
		minR4Year: selectedMinR4Year,
		maxR4Year: selectedMaxR4Year,
		positionGroup: selectedPositionGroup
	} = context;
	const isFetchingSimilarPlayers = current.matches(FETCHING_SIMILAR_PLAYERS);
	const isLoading = shouldFetchData ? isFetchingSimilarPlayers : data?.isLoading;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, value: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_PLAYER_TYPE, value: playerType });
	}, [playerType, send]);

	useEffect(() => {
		send({ type: SET_MODEL_NAME, value: modelName });
	}, [modelName, send]);

	useEffect(() => {
		send({ type: SET_R4_YEAR_PLAYER, value: r4YearPlayer });
	}, [r4YearPlayer, send]);

	useEffect(() => {
		send({ type: SET_MIN_R4_YEAR, value: minR4Year });
	}, [minR4Year, send]);

	useEffect(() => {
		send({ type: SET_MAX_R4_YEAR, value: parsedMaxR4Year });
	}, [parsedMaxR4Year, send]);

	useEffect(() => {
		send({ type: SET_POSITION, value: position });
	}, [position, send]);

	useEffect(() => {
		send({ type: SET_POSITION_GROUP, value: parsedPositionGroup });
	}, [parsedPositionGroup, send]);

	const parsedSimilarPlayers: Array<TAmaSurplusValueSimilarPlayer> | undefined = useMemo(() => {
		return parseSimilarPlayersData(similarPlayers);
	}, [similarPlayers]);

	const tableColumns = createAmaSurplusValueSimilarPlayersColumns(currentDraftYear);
	const filteredColumns = useMemo(() => {
		if (!columns) return tableColumns;
		return tableColumns.filter((col: TColumn<TAmaSurplusValueSimilarPlayer, keyof TAmaSurplusValueSimilarPlayer>) =>
			columns.includes(col.value)
		);
	}, [columns, tableColumns]);

	// Position group options
	const positionGroupOptions: Array<{ label: string; value: string }> = useMemo(() => {
		if (parsedPositionGroup) {
			if (POSITION_GROUPS_PITCHER.includes(parsedPositionGroup)) {
				return POSITION_GROUPS_PITCHER_OPTIONS;
			} else {
				return POSITION_GROUPS_POSITION_PLAYER_OPTIONS;
			}
		}
		return POSITION_GROUPS_PITCHER_OPTIONS.concat(POSITION_GROUPS_POSITION_PLAYER_OPTIONS);
	}, [parsedPositionGroup]);

	// All available year options
	const r4YearOptions: Array<{ label: string; value: number }> = getR4YearOptions();

	// Only show options that are less than the current selected max r4 year
	const minR4YearOptions = useMemo(() => {
		return r4YearOptions.filter((option: { label: string; value: number }) =>
			selectedMaxR4Year ? option.value <= selectedMaxR4Year : true
		);
	}, [r4YearOptions, selectedMaxR4Year]);
	// Only show options that are greater than the current selected min r4 year
	const maxR4YearOptions = useMemo(() => {
		return r4YearOptions.filter((option: { label: string; value: number }) =>
			selectedMinR4Year ? option.value >= selectedMinR4Year : true
		);
	}, [r4YearOptions, selectedMinR4Year]);

	const isMobile = useBreakpointValue({
		base: true,
		xl: false
	});

	return (
		<VStack alignItems="start">
			<HStack w="100%" justify="space-between">
				<HStack>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
							{positionSelect?.showSelect || !selectedPositionGroup
								? ""
								: ` (${getPositionGroupLabel(selectedPositionGroup)})`}
						</Box>
					)}
					<Tooltip hasArrow placement="top" label="Go to Projections Leaderboard">
						<IconButton
							aria-label="Go to projections leaderboard"
							variant="outline"
							size="xs"
							icon={<Leaderboard sx={{ color: "gray", height: 13, width: 13 }} />}
							onClick={() => window.open(`../projections/ama/leaderboard`, "_blank")}
						/>
					</Tooltip>
					{positionSelect?.showSelect && (
						<StyledSelect
							options={positionGroupOptions}
							value={positionGroupOptions.find(
								positionOpt => positionOpt.value === selectedPositionGroup
							)}
							onChange={(selectedPositionGroup: { label: string; value: string }) => {
								send({ type: SET_POSITION_GROUP, value: selectedPositionGroup.value });
							}}
						/>
					)}
				</HStack>
				<HStack gap={1}>
					{/* Using px to set the width of the select to always accomodate the text so that the text never wraps*/}
					<Box width="70px">
						<StyledSelect
							options={minR4YearOptions}
							value={minR4YearOptions.find(yearOpt => yearOpt.value === selectedMinR4Year)}
							onChange={(selectedYear: { label: string; value: number }) => {
								send({ type: SET_MIN_R4_YEAR, value: selectedYear.value });
							}}
						/>
					</Box>
					<Text fontSize="s">to</Text>
					{/* Using px to set the width of the select to always accomodate the text so that the text never wraps*/}
					<Box width="70px">
						<StyledSelect
							options={maxR4YearOptions}
							value={maxR4YearOptions.find(yearOpt => yearOpt.value === selectedMaxR4Year)}
							onChange={(selectedYear: { label: string; value: number }) => {
								send({ type: SET_MAX_R4_YEAR, value: selectedYear.value });
							}}
						/>
					</Box>
				</HStack>
			</HStack>
			<Table<TAmaSurplusValueSimilarPlayer, keyof TAmaSurplusValueSimilarPlayer>
				columns={filteredColumns}
				data={parsedSimilarPlayers}
				isLoadingData={isLoading}
				isMobile={isMobile}
				emptyDataDisplayText={"No Similar Players"}
				highlightIndexFunction={(data: Array<TAmaSurplusValueSimilarPlayer>) => {
					return data.findIndex((player: TAmaSurplusValueSimilarPlayer) => player?.playerId === playerId);
				}}
				style={{
					table: { tableLayout: "fixed" }
				}}
				{...tableProps}
			/>
		</VStack>
	);
};

export default AmaSurplusValueSimilarPlayersTable;
