import React, { CSSProperties } from "react";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { getCurrency } from "utils/helpers";
import { IAddendumC } from "_react/shared/_types/schema/contracts";

const COLUMNS = [
	{
		title: "Year",
		name: "year",
		getCellValue: (row: IAddendumC) => row.year
	},
	{
		title: "Status",
		name: "status",
		getCellValue: (row: IAddendumC) => row.status
	},
	{
		title: "AAA Salary",
		name: "salary_aaa",
		getCellValue: (row: IAddendumC) => getCurrency(row.salary_aaa)
	},
	{
		title: "AA Salary",
		name: "salary_aax",
		getCellValue: (row: IAddendumC) => getCurrency(row.salary_aax)
	},
	{
		title: "A+ Salary",
		name: "salary_afa",
		getCellValue: (row: IAddendumC) => getCurrency(row.salary_afa)
	},
	{
		title: "A Salary",
		name: "salary_afx",
		getCellValue: (row: IAddendumC) => getCurrency(row.salary_afx)
	},
	{
		title: "A- Salary",
		name: "salary_asa",
		getCellValue: (row: IAddendumC) => getCurrency(row.salary_asa)
	},
	{
		title: "Rk+ Salary",
		name: "salary_rka",
		getCellValue: (row: IAddendumC) => getCurrency(row.salary_rka)
	},
	{
		title: "Rk salary",
		name: "salary_rk",
		getCellValue: (row: IAddendumC) => getCurrency(row.salary_rk)
	},
	{
		title: "DSL Salary",
		name: "salary_dsl",
		getCellValue: (row: IAddendumC) => getCurrency(row.salary_dsl)
	}
];

type TAddendumCTableStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

interface IProps {
	addendumCs: IAddendumC[];
	colorSchemeGroup: ColorSchemeGroup;
	style?: TAddendumCTableStyle;
}

function AddendumCTable({ addendumCs, colorSchemeGroup, style }: IProps) {
	return (
		<Card style={style?.card}>
			<ColoredCardHeader colorScheme={colorSchemeGroup.secondary} text={"Addendum C"} style={style?.cardHeader} />
			<AutoFitTable
				columns={COLUMNS}
				defaultSortColumn={"Year"}
				defaultSortState={"desc"}
				rows={addendumCs}
				rowKey="addendum_c_id"
			/>
		</Card>
	);
}

export default AddendumCTable;
