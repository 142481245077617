import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";

import { IPlayerSeasonXwoba, IPlayerSeasonXwobaByTeam } from "_react/shared/data_models/baseline_hit_probs/_types";

// xwOBA

export type TPlayerSeasonXwobaGetQueryParams = {
	playerId?: number;
	isHitter?: boolean;
	season?: number;
	"season[in]"?: string;
	playingLevel?: string;
	throws?: string;
	bats?: string;
	gameType?: string;
	model?: string;
	"model[in]"?: string;
	trackedBips?: number;
	xwobacon?: number;
	xwoba?: number;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchPlayerSeasonXwoba = (queryParams: TPlayerSeasonXwobaGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TPlayerSeasonXwobaGetQueryParams, Array<IPlayerSeasonXwoba>>(
		"/xwoba/player_season",
		queryParams,
		cancelToken
	);

export type TPlayerSeasonXwobaByTeamGetQueryParams = TPlayerSeasonXwobaGetQueryParams & {
	teamId?: number;
	"teamId[in]"?: string;
};

export const fetchPlayerSeasonXwobaByTeam = (
	queryParams: TPlayerSeasonXwobaByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPlayerSeasonXwobaByTeamGetQueryParams, Array<IPlayerSeasonXwobaByTeam>>(
		"/xwoba/player_season/team",
		queryParams,
		cancelToken
	);
