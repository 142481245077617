import { ComponentType } from "react";
import { Dayjs } from "dayjs";

import { T_DBs, $TSFixMe } from "utils/tsutils";

export type TFormStructure = {
	id: string;
	sections: TFormSection[];
};

export type TFormSection = {
	id: string;
	rank?: number;
	heading?: string;
	isRowBased?: boolean;
	width?: number;
	components: TFormComponent[];

	// If this is a collection, these fields are required
	isCollection?: boolean; // Flag to indicate this is a collection
	key?: string; // Key corresponding to the array in the document that corresponds to this collection
};

export type TFormSectionRow = {
	rowNumber: number; // For iterative key purposes only
	sections: TFormSection[];
	width: number;
};

export type TFormComponentColumn = {
	columnNumber: number; // For iterative key purposes only
	components: TFormComponent[];
};

export type TFormComponentColumnRow = {
	rowNumber: number; // For iterative key purposes only
	components: TFormComponentColumn[];
};

export type TFormComponentRow = {
	rowNumber: number; // For iterative key purposes only
	components: TFormComponent[];
};

export const INCREMENT = "INCREMENT";
export const VALUE = "VALUE";
export type TDefaultValueType = typeof INCREMENT | typeof VALUE;

export type TFormComponent = {
	id: string;
	label?: string;
	placeholder?: string;
	key: string;
	type: TFormComponentType;
	rank: number;
	column?: number;
	row?: number; // Used for collections
	validation?: string;
	debounce?: boolean;
	width?: number;

	// Might move these elsewhere
	lkName?: string;
	dbName?: T_DBs;
	isCustomLk?: boolean | number;
	lkQuery?: string;
	lkParameters?: { [index: string]: string | number };
	multiline?: boolean;

	default?: string;
};

export const TEXT = "TEXT";
export const DATE = "DATE";
export const SELECT = "SELECT";
export const DROPDOWN = "DROPDOWN";
export const CHECKBOX = "CHECKBOX";
export const LABEL = "LABEL";
export const LOADING = "LOADING";

export type TFormComponentType =
	| typeof TEXT
	| typeof DATE
	| typeof SELECT
	| typeof DROPDOWN
	| typeof CHECKBOX
	| typeof LABEL
	| typeof LOADING
	| string;

export type TFormValuePrimitive = string | number | boolean | Dayjs | undefined | null;

export type TFormValueNested = { [key: string]: TFormValue };

export type TFormValue = TFormValuePrimitive | TFormValueNested[] | TFormValueNested;

export type TDocumentBase = { [key: string]: TFormValue };
export const TDocumentBaseEmpty: TDocumentBase = {};

// Dropdown options
export type TDropdownComponentOptions = Array<{ value: string | number; label: string }>;
export type TDropdownComponentProps = { [index: string]: TDropdownComponentOptions };

// LK Props
export type TLkPropComponentEntry = { [index: string]: number | string };
export type TLkProps = { [index: string]: TLkPropComponentEntry };

// Form Component Props (here so they can be defined in the plugin type definition)
export interface FormComponentInternalProps<_T extends TDocumentBase> {
	submitUpdate: (value: TFormValue) => void;
	component: TFormComponent;
	readOnly: boolean;
	value?: TFormValuePrimitive;
	placeholder?: string;
	invalid?: boolean;
	lkProps?: TLkProps;
	disabled?: boolean;
	dropdownComponents?: TDropdownComponentProps;
	focusedRef?: $TSFixMe;
}

// Generic type that corresponds to a custom form component
export type TCustomFormComponent<T extends TDocumentBase> = ComponentType<FormComponentInternalProps<T>>;

// Validation
// Validation rule for a specific field
export type TFormFieldValidationRule = {
	code: string;
	message: string;
	validate: (value: TFormValue) => boolean;
};

// Validation rule for an entire document
export type TFormValidationRule<T extends TDocumentBase> = {
	message: string;
	validate: (document: T) => boolean;
};

export type TFormValidationResult = {
	success: boolean;
	messages: string[];
};

export const ENTIRE_FORM = "ENTIRE_FORM";
export type TFormValidationError = { component: TFormComponent | typeof ENTIRE_FORM; message: string };

// Save Options
export const INSTANT = "INSTANT"; // Call onDocumentUpdate after every single update (field level, not keystroke level)
export const DEBOUNCE = "DEBOUNCE"; // Call onDocumentUpdate, debouncing at the specified timeout
export const MANUAL = "MANUAL"; // Don't call onDocumentUpdate automatically, render a save button to trigger it
export type TFormSaveMode = typeof INSTANT | typeof DEBOUNCE | typeof MANUAL;

export type TFormSaveOptions = {
	mode: TFormSaveMode;
	timeout?: number; // For debouncing
	saveEntireDocument?: boolean; // TODO: When handling saving (NOT YET IN USE)
	identifyingFields?: string[]; // TODO: When handling saving (NOT YET IN USE)
	urlPath?: string; // TODO: When handling saving (NOT YET IN USE)
};

export const SHOW = "SHOW";
export const HIDE = "HIDE";
export const DISABLED = "DISABLED";
export const AS_LABEL = "AS_LABEL";

export type TFieldState = typeof SHOW | typeof HIDE | typeof DISABLED | typeof AS_LABEL;
export type TSectionState = typeof SHOW | typeof HIDE;

// Plugin (Anything that cannot be *completely* DB-driven)
export type TFormPlugin<T extends TDocumentBase, S = T> = {
	// Identifying Information
	formId: string; // Database key for the form structure

	// TODO: Move to DB
	saveOptions: TFormSaveOptions; // How often to call onDocumentUpdate
	dontCreateUUIDWhenCreatingCollectionItem?: boolean; // A flag to prevent an id field with a UUID to be created when a new collection row is added

	// Properties/Components/Functions
	customComponents: { [index: string]: TCustomFormComponent<T> }; // Dictionary of custom form components
	dropdownComponents?: TDropdownComponentProps;
	processUpdate?: (newDocument: T, key: keyof T, value: TFormValue) => T; // Function to run after editing a field but before saving it
	debouncingStateChanged?: (isDebouncing: boolean) => void; // Fired when the debouncing state has changed
	formatForSaving?: (document: T) => S; // Function to convert the document to the type it should be sent to the save endpoint/callback in
	validate?: (newDocument: T, key?: keyof T, value?: TFormValue) => TFormValidationResult; // Function to run before processing to validate the most recent change to the document;
	getFieldState?: (component: TFormComponent, document: T) => TFieldState; // Function to determine to show/hide/disable a field
	getSectionState?: (sectionId: string, document: T) => TSectionState; // Function to determine to show/hide a section
	updateLkProps?: (
		newDocument: T,
		oldLkProps: TLkProps,
		key: keyof T,
		value: TFormValue,
		callback: (newLkProps: TLkProps) => void,
		component?: TFormComponent
	) => void; // Function to update any custom lk select properties, fired right after saving. Uses a callback for asynchronous requests (ex: API calls)
};

export type TFormSaveState = {
	saving: boolean;
	publishing?: boolean;
	published?: Dayjs;
	lastSaved?: Dayjs;
	saveErrored?: boolean;
	unsavedChanges?: boolean;
};

export type TSaveFunction<T extends TDocumentBase> = (document: T) => void;
