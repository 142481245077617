import { createMachine, assign, StateFrom } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource, setQueryStringValue } from "utils/url_helpers";

import { AMA, PRO, INTL } from "_react/playerpage/_constants";
import { fetchPlayer, fetchPlayerFromPhilId, fetchPlayerLegacy } from "_react/shared/data_models/player/_network";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TPlayer } from "_react/playerpage/_types";
import { TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";

const PLAYER_CANCEL_SOURCE = "player";
const PLAYER_LEGACY_CANCEL_SOURCE = "playerLegacy";

export type TPlayerPageCancelSource = {
	[PLAYER_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_LEGACY_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPlayerPageContext = {
	playerLegacyId?: number;
	playerId?: number;
	viewClassification?: TPlayerPagePlayerClassification;
	player?: TPlayerPageCombinedPlayer | null;
	playerLegacy?: TPlayer | null;
	cancelSources: TPlayerPageCancelSource;
	toast?: CreateToastFnReturn;
};

export const FETCHING_PLAYER_LEGACY = { initialized: { playerLegacy: "fetching" } };
export const FETCHING_PLAYER = { initialized: { player: "fetching" } };

const FETCH_PLAYER_DONE = "done.invoke.fetchingPlayer:invocation[0]";
type TFetchPlayerEvent = { type: typeof FETCH_PLAYER_DONE; data?: TPlayerPageCombinedPlayer };

const FETCH_PLAYER_LEGACY_DONE = "done.invoke.fetchingPlayerLegacy:invocation[0]";
type TFetchPlayerLegacyEvent = { type: typeof FETCH_PLAYER_LEGACY_DONE; data?: TPlayer };

export const SET_VIEW_CLASSIFICATION = "SET_VIEW_CLASSIFICATION";
export const SET_PLAYER = "SET_PLAYER";

export const SET_PLAYER_LEGACY_ID = "SET_PLAYER_LEGACY_ID";
export const SET_PLAYER_LEGACY = "SET_PLAYER_LEGACY";

export const SET_PLAYER_ID = "SET_PLAYER_ID";

type TSetViewClassificationEvent = { type: typeof SET_VIEW_CLASSIFICATION; value?: TPlayerPagePlayerClassification };
type TSetPlayerEvent = { type: typeof SET_PLAYER; value?: TPlayerPageCombinedPlayer };

type TSetPlayerLegacyIdEvent = { type: typeof SET_PLAYER_LEGACY_ID; value?: number };
type TSetPlayerLegacyEvent = { type: typeof SET_PLAYER_LEGACY; value?: TPlayer };

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value?: number };

export type TPlayerPageEvent =
	| TSetViewClassificationEvent
	| TSetPlayerEvent
	| TFetchPlayerEvent
	| TSetPlayerLegacyIdEvent
	| TSetPlayerIdEvent
	| TSetPlayerLegacyEvent
	| TFetchPlayerLegacyEvent;

const PlayerPageMachine = (
	playerLegacyIdProp?: number,
	playerIdProp?: number,
	viewClassification?: TPlayerPagePlayerClassification,
	toastProp?: CreateToastFnReturn
) =>
	createMachine<TPlayerPageContext, TPlayerPageEvent>(
		{
			id: "playerPage",
			predictableActionArguments: true,
			initial: "initializing",
			context: {
				playerLegacyId: playerLegacyIdProp,
				playerId: playerIdProp,
				viewClassification: viewClassification,
				player: undefined,
				playerLegacy: undefined,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					initial: "idle",
					states: {
						idle: {
							always: "#playerPage.initialized"
						}
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_VIEW_CLASSIFICATION]: {
							actions: ["setViewClassification", "clearPlayer"],
							cond: "shouldSetViewClassification"
						},
						[SET_PLAYER]: { actions: "setPlayer" },
						[SET_PLAYER_LEGACY_ID]: {
							actions: ["setPlayerLegacyId", "clearPlayer"],
							cond: "shouldSetPlayerLegacyId"
						},
						[SET_PLAYER_ID]: { actions: ["setPlayerId", "clearPlayer"], cond: "shouldSetPlayerId" },
						[SET_PLAYER_LEGACY]: { actions: "setPlayerLegacy" }
					},
					states: {
						player: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayer",
												cond: "shouldFetchPlayer"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayer",
									entry: ["refreshPlayerCancelSource"],
									invoke: {
										src: "fetchPlayer",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerErrored"
										}
									}
								}
							}
						},
						playerLegacy: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayerLegacy",
												cond: "shouldFetchPlayerLegacy"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerLegacy",
									entry: ["refreshPlayerLegacyCancelSource"],
									invoke: {
										src: "fetchPlayerLegacy",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerLegacySuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerLegacyErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetViewClassification: (context: TPlayerPageContext, event: TPlayerPageEvent) =>
					event.type === SET_VIEW_CLASSIFICATION && context.viewClassification !== event.value,
				shouldSetPlayerId: (context: TPlayerPageContext, event: TPlayerPageEvent) =>
					event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldSetPlayerLegacyId: (context: TPlayerPageContext, event: TPlayerPageEvent) =>
					event.type === SET_PLAYER_LEGACY_ID && context.playerLegacyId !== event.value,
				shouldFetchPlayer: (context: TPlayerPageContext, _event: TPlayerPageEvent) =>
					context.player == null && (context.playerLegacyId != null || context.playerId != null),
				shouldFetchPlayerLegacy: (context: TPlayerPageContext, _event: TPlayerPageEvent) => {
					return context.playerLegacy == null && context.playerLegacyId != null;
				}
			},
			actions: {
				clearPlayer: assign<TPlayerPageContext, TPlayerPageEvent>({
					player: (_context: TPlayerPageContext, _event: TPlayerPageEvent) => undefined,
					playerLegacy: (_context: TPlayerPageContext, _event: TPlayerPageEvent) => undefined,
					cancelSources: (context: TPlayerPageContext, _event: TPlayerPageEvent) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) => {
							if ([PLAYER_CANCEL_SOURCE, PLAYER_LEGACY_CANCEL_SOURCE].includes(cancelSource[0]))
								cancelSource[1]?.cancel();
						});
						return {};
					}
				}),
				setViewClassification: assign<TPlayerPageContext, TPlayerPageEvent>({
					viewClassification: (context: TPlayerPageContext, event: TPlayerPageEvent) => {
						if (event.type !== SET_VIEW_CLASSIFICATION) return context.viewClassification;
						setQueryStringValue("viewClassification", event.value, undefined, false);
						return event.value;
					}
				}),
				setPlayerLegacyId: assign<TPlayerPageContext, TPlayerPageEvent>({
					playerLegacyId: (context: TPlayerPageContext, event: TPlayerPageEvent) => {
						if (event.type !== SET_PLAYER_LEGACY_ID) return context.playerLegacyId;
						return event.value;
					},
					playerId: (_context: TPlayerPageContext, _event: TPlayerPageEvent) => undefined
				}),
				setPlayerId: assign<TPlayerPageContext, TPlayerPageEvent>({
					playerId: (context: TPlayerPageContext, event: TPlayerPageEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					playerLegacyId: (_context: TPlayerPageContext, _event: TPlayerPageEvent) => undefined
				}),
				setPlayer: assign<TPlayerPageContext, TPlayerPageEvent>({
					player: (context: TPlayerPageContext, event: TPlayerPageEvent) => {
						if (event.type !== SET_PLAYER) return context.player;
						return event.value;
					}
				}),
				setPlayerLegacy: assign<TPlayerPageContext, TPlayerPageEvent>({
					playerLegacy: (context: TPlayerPageContext, event: TPlayerPageEvent) => {
						if (event.type !== SET_PLAYER_LEGACY) return context.playerLegacy;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshPlayerCancelSource: assign<TPlayerPageContext, TPlayerPageEvent>({
					cancelSources: (context: TPlayerPageContext, _event: TPlayerPageEvent) => {
						if (context.cancelSources[PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerLegacyCancelSource: assign<TPlayerPageContext, TPlayerPageEvent>({
					cancelSources: (context: TPlayerPageContext, _event: TPlayerPageEvent) => {
						if (context.cancelSources[PLAYER_LEGACY_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_LEGACY_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_LEGACY_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSuccess: assign<TPlayerPageContext, TPlayerPageEvent>({
					player: (context: TPlayerPageContext, event: TPlayerPageEvent) => {
						if (event.type !== FETCH_PLAYER_DONE) return context.player;
						return event.data;
					},
					playerLegacyId: (context: TPlayerPageContext, event: TPlayerPageEvent) => {
						if (event.type !== FETCH_PLAYER_DONE || context.playerLegacyId != null)
							return context.playerLegacyId;
						const classification = context.viewClassification ?? event.data?.playerClassification;
						if (classification === AMA) {
							return event.data?.playerAmaId ?? undefined;
						} else if (classification === PRO) {
							return event.data?.playerProId ?? undefined;
						} else if (classification === INTL) {
							return event.data?.playerIntlId ?? undefined;
						}
						// Fallback
						return context.playerLegacyId;
					},
					playerId: (context: TPlayerPageContext, event: TPlayerPageEvent) => {
						if (event.type !== FETCH_PLAYER_DONE) return context.playerId;
						return event.data?.id;
					}
				}),
				handleFetchPlayerLegacySuccess: assign<TPlayerPageContext, TPlayerPageEvent>({
					playerLegacy: (context: TPlayerPageContext, event: TPlayerPageEvent) => {
						if (event.type !== FETCH_PLAYER_LEGACY_DONE) return context.player;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchPlayerErrored: (context: TPlayerPageContext, _event: TPlayerPageEvent) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Player",
							description: "Error fetching combined player."
						});
				},
				handleFetchPlayerLegacyErrored: (context: TPlayerPageContext, _event: TPlayerPageEvent) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Player Legacy",
							description: "Error fetching legacy player."
						});
				}
			},
			services: {
				fetchPlayer: (context: TPlayerPageContext, _event: TPlayerPageEvent) => {
					const fetchFunc = () => {
						if (context.playerId != null)
							return fetchPlayer(
								{
									id: context.playerId,
									playerClassification: context.viewClassification,
									isUseCombinedId: true
								},
								context.cancelSources[PLAYER_CANCEL_SOURCE]?.token
							);
						return fetchPlayerFromPhilId(
							{ philId: context.playerLegacyId!, modelType: "combined" },
							context.cancelSources[PLAYER_CANCEL_SOURCE]?.token
						);
					};
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerLegacy: (context: TPlayerPageContext, _event: TPlayerPageEvent) => {
					const fetchFunc = () =>
						fetchPlayerLegacy(
							{
								philId: context.playerLegacyId!,
								viewClassification: context.viewClassification
							},
							context.cancelSources[PLAYER_LEGACY_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export type TPlayerPageState = StateFrom<typeof PlayerPageMachine>;

export default PlayerPageMachine;
