import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function ArrowLeft({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M14 7l-5 5 5 5V7z" />
			<path fill={"none"} d="M24 0v24H0V0h24z" />
		</IconSVG>
	);
}
