import React from "react";
import { $TSFixMe } from "utils/tsutils";
import dayjs from "dayjs";
import { getCurrency } from "utils/helpers";
import { TContract, isContractMajors, isContractMinors, ContractBonusColumn } from "_react/playerpage/contract/_types";
import { contract_mj_year } from "_react/shared/_types/mesa/contract_mj_year";
import { contract_mj_covenant } from "_react/shared/_types/mesa/contract_mj_covenant";
import { contract_mj_assignment_bonus } from "_react/shared/_types/mesa/contract_mj_assignment_bonus";
import { contract_mj_assignment_bonus_earned } from "_react/shared/_types/mesa/contract_mj_assignment_bonus_earned";
import { contract_mj_bonus } from "_react/shared/_types/mesa/contract_mj_bonus";
import { contract_mj_bonus_threshold } from "_react/shared/_types/mesa/contract_mj_bonus_threshold";
import { contract_mj_bonus_term } from "_react/shared/_types/mesa/contract_mj_bonus_term";
import { contract_mj_bonus_salary_escalator } from "_react/shared/_types/mesa/contract_mj_bonus_salary_escalator";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import Info from "_react/shared/legacy/ui/icons/Info";

import { MAJOR } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TooltipStyle, InfoStyle } from "_react/playerpage/contract/_styles";

export const getOptions = (row: $TSFixMe) => {
	return `${row.non_option_years} + ${row.option_years}`;
};

export const getMnSigningBonus = (row: $TSFixMe) => {
	let signingBonus = "-";
	if (row.hasOwnProperty("signingBonusDetails") && row.signingBonusDetails !== null) {
		signingBonus = getCurrency(row.signingBonusDetails.amount);
	}
	return signingBonus;
};

export const formatAddendumBDateRange = (
	operator: string | null | undefined,
	date_one: Date | null | undefined,
	date_two: Date | null | undefined
) => {
	if (operator === "Between") {
		return `Between ${date_one} - ${date_two}`;
	}
	return [operator, date_one, date_two].filter(Boolean).join(" ");
};

export const MAJOR_COLUMNS = [
	{ title: "Start Year", name: "startYear" },
	{ title: "Status", name: "status" },
	{
		title: "Confirmed Terms MLS",
		name: "mlsSigning",
		getCellValue: (row: $TSFixMe) => row.mlsSigning.toFixed(3)
	},
	{
		title: "Confirmed Terms Date",
		name: "confirmedDateAsString",
		getCellValue: (row: $TSFixMe) => dayjs(row.confirmedDateAsString).format("YYYY-MM-DD")
	},
	{ title: "Signing ORG", name: "org" },
	{
		title: "Total Guaranteed",
		name: "totalGuarantee",
		getCellValue: (row: $TSFixMe) => getCurrency(row.totalGuarantee)
	},
	{ title: "Years", name: "startYear", getCellValue: getOptions },
	{ title: "AAV", name: "aav", getCellValue: (row: $TSFixMe) => getCurrency(row.aav) }
];

export const MINOR_COLUMNS = [
	{ title: "Start Year", name: "startYear" },
	{ title: "Status", name: "status" },
	{
		title: "Signing Date",
		name: "signingDate",
		getCellValue: (row: $TSFixMe) => dayjs(row.signed_date).format("YYYY-MM-DD")
	},
	{
		title: "Confirmed Terms Date",
		name: "approvedDate",
		getCellValue: (row: $TSFixMe) => {
			if (row.hasOwnProperty("approvedDate") && row.approved_date !== null) {
				return dayjs(row.approved_date).format("YYYY-MM-DD");
			}
			return "-";
		}
	},
	{ title: "Years", name: "numYears" },
	{ title: "ORG", name: "org" },
	{ title: "Club", name: "team" },
	{
		title: "Signing Bonus",
		name: "signingBonusDetails",
		getCellValue: (row: $TSFixMe) => getMnSigningBonus(row)
	}
];

export const CONTRACT_DETAILS_COLUMNS = (
	contract: TContract,
	level: string,
	player: TPlayerPageCombinedPlayer | null
) => {
	if (level === MAJOR) {
		if (contract && isContractMajors(contract)) {
			const {
				contract_id,
				contract_type,
				signed_date,
				start_year,
				non_option_years,
				option_years,
				mls_signing,
				agent,
				agency,
				aav,
				signing_bonus, // from contract_mj
				signing_org: org,
				approval_date_as_string: approved_date
			} = contract;

			const currentOrg = player?.proProfile?.orgCode;
			return [
				{ name: "Contract Type", value: contract_type },
				{ name: "Contract Id", value: contract_id },
				{ name: "Signing Org", value: org },
				{ name: "Current Org", value: currentOrg },
				{
					name: "Signed Date",
					value: signed_date ? dayjs(signed_date).format("YYYY-MM-DD") : "-"
				},
				{
					name: "Approved Date",
					value: approved_date ? dayjs(approved_date).format("YYYY-MM-DD") : "-"
				},
				{ name: "Start Year", value: start_year },
				{ name: "Guaranteed Years", value: non_option_years },
				{ name: "Option Years", value: option_years },
				{ name: "Signing MLS", value: (mls_signing as number)?.toFixed(3) },
				{ name: "Signing Agent", value: agent },
				{ name: "Signing Agency", value: agency },
				{ name: "Signing Bonus", value: getCurrency(signing_bonus) },
				{ name: "AAV", value: getCurrency(aav) }
			];
		}
	} else {
		if (contract && isContractMinors(contract)) {
			const {
				org,
				contract_type,
				contract_id,
				team,
				num_years,
				signed_date,
				approved_date,
				spr_invite
			} = contract;
			const currentOrg = player?.proProfile?.orgCode;

			return [
				{ name: "Contract Type", value: contract_type },
				{ name: "Contract Id", value: contract_id },
				{ name: "Signing Org", value: org },
				{ name: "Signing Club", value: team },
				{ name: "Current Org", value: currentOrg },
				{ name: "Signed Date", value: signed_date ? dayjs(signed_date).format("YYYY-MM-DD") : "" },
				{
					name: "Approved Date",
					value: approved_date ? dayjs(approved_date).format("YYYY-MM-DD") : ""
				},
				{ name: "Contract Length", value: num_years },
				{ name: "Spring Training Invite", value: spr_invite ? "Yes" : "No" }
			];
		}
	}
	return [];
};

export const CONTRACT_YEARS_COLUMNS = () => {
	return [
		{
			title: "Year",
			name: "year",
			getCellValue: (row: $TSFixMe) => {
				const { option_id } = row;
				if (option_id) {
					return `${row.year} (${row.option_type})`;
				}
				return row.year;
			}
		},
		{
			title: "Base Salary",
			name: "base_salary",
			getCellValue: (row: contract_mj_year) => getCurrency(row.base_salary)
		},
		{
			title: "Guaranteed Salary",
			name: "guaranteed_salary",
			getCellValue: (row: contract_mj_year) => getCurrency(row.guaranteed_salary)
		},
		{
			title: "Split Salary",
			name: "split_salary",
			getCellValue: (row: $TSFixMe) => getCurrency(row.split_salary)
		},
		{
			title: "Prorated Signing Bonus",
			name: "signing_bonus_earned",
			getCellValue: (row: contract_mj_year) => getCurrency(row.signing_bonus_earned)
		},
		{
			title: "Earned Escalator",
			name: "escalator_earned",
			getCellValue: (row: contract_mj_year) => getCurrency(row.earned_escalator_amount)
		},
		{
			title: "Buyout",
			name: "buyout",
			getCellValue: (row: $TSFixMe) => getCurrency(row.buyout)
		},
		{
			title: "Earned Bonus",
			name: "bonus_earned",
			getCellValue: (row: contract_mj_year) => getCurrency(row.earned_bonus_amount)
		},
		{
			title: "Total LRD Salary",
			name: "lrd_salary",
			getCellValue: (row: contract_mj_year) => getCurrency(row.lrd_salary)
		},
		{
			title: "Award Offered",
			name: "award_bonus_offered",
			getCellValue: (row: contract_mj_year) => getCurrency(row.offered_award_bonus_amount)
		},
		{
			title: "Performance Offered",
			name: "perf_bonus_offered",
			getCellValue: (row: contract_mj_year) => getCurrency(row.offered_performance_bonus_amount)
		},
		{
			title: "Escalator Offered",
			name: "escalator_offered",
			getCellValue: (row: contract_mj_year) => getCurrency(row.offered_escalator_amount)
		}
	];
};

export const CONTRACT_COVENANTS_COLUMNS = () => {
	return [
		{
			title: "Type",
			name: "covenant_type",
			width: "10%"
		},
		{
			title: "Multi Year Term",
			name: "multi_year_term_flag",
			width: "5%",
			getCellValue: (row: contract_mj_covenant) => (row.multi_year_term_flag ? "Yes" : "No")
		},
		{
			title: "Language",
			name: "language",
			getCellValue: (row: contract_mj_covenant) => {
				return row.language ? (
					// TODO: this can expose site to XSS, although low risk given HTML is coming from EBIS
					<div dangerouslySetInnerHTML={{ __html: row.language }} />
				) : (
					undefined
				);
			}
		}
	];
};

export const CONTRACT_ASSIGNMENT_BONUSES_COLUMNS = () => {
	return [
		{
			title: "Bonus Amount",
			name: "bonus_amount",
			getCellValue: (row: contract_mj_assignment_bonus) => getCurrency(row.bonus_amount)
		},
		{
			title: "Becomes Escalator",
			name: "becomes_escalator_flag",
			getCellValue: (row: contract_mj_assignment_bonus) => (row.becomes_escalator_flag ? "Yes" : "No")
		},
		{
			title: "Payor Assignee",
			name: "payor_assignee_flag",
			getCellValue: (row: contract_mj_assignment_bonus) => (row.payor_assignee_flag ? "Yes" : "No")
		},
		{
			title: "Assign to any Club",
			name: "assignment_to_any_club_flag",
			getCellValue: (row: contract_mj_assignment_bonus) => (row.assignment_to_any_club_flag ? "Yes" : "No")
		},
		{
			title: "Criteria",
			name: "criteria"
		},
		{
			title: "Start Date",
			name: "start_date",
			getCellValue: (row: contract_mj_assignment_bonus) =>
				row.start_date ? dayjs(row.start_date).format("YYYY-MM-DD") : null
		},
		{
			title: "End Date",
			name: "end_date",
			getCellValue: (row: contract_mj_assignment_bonus) =>
				row.end_date ? dayjs(row.end_date).format("YYYY-MM-DD") : null
		},
		{
			title: "End Year",
			name: "end_year"
		}
	];
};

export const CONTRACT_ASSIGNMENT_BONUSES_EARNED_COLUMNS = () => {
	return [
		{
			title: "Earned Amount",
			name: "earned_amount",
			getCellValue: (row: contract_mj_assignment_bonus_earned) => getCurrency(row.earned_amount)
		},
		{
			title: "Earned Date",
			name: "earned_date",
			getCellValue: (row: contract_mj_assignment_bonus_earned) =>
				row.earned_date ? dayjs(row.earned_date).format("YYYY-MM-DD") : null
		},
		{
			title: "Assignor Org",
			name: "assignor_org"
		},
		{
			title: "Assignee Org",
			name: "assignee_org"
		}
	];
};

export const CONTRACT_BONUS_COLUMNS = (includeComments = false) => {
	const columns = [] as ContractBonusColumn[];
	columns.push(
		{
			title: "Year(s)",
			name: "year",
			getCellValue: (row: contract_mj_bonus) =>
				row.all_years_flag
					? "All"
					: row.start_year === row.end_year
					? row.start_year
					: `${row.start_year} - ${row.end_year}`
		},
		{
			title: "Amount",
			name: "amount",
			isSortDisabled: true,
			getCellValue: (row: contract_mj_bonus) => getCurrency(row.amount)
		},
		{
			title: "Earned",
			name: "earned",
			isSortDisabled: true,
			getCellValue: (row: contract_mj_bonus) => getCurrency(row.earned)
		},
		{
			title: "Criteria",
			name: "criteria",
			isSortDisabled: true
		}
	);

	if (includeComments) {
		columns.push({
			title: "Comments",
			name: "comments",
			isSortDisabled: true,
			getCellValue: (row: contract_mj_bonus) => {
				return row.comments ? (
					<Tooltip title={row.comments} style={TooltipStyle}>
						<Info style={InfoStyle} />
					</Tooltip>
				) : (
					undefined
				);
			}
		});
	}

	return columns;
};

export const CONTRACT_BONUS_THRESHOLD_COLUMNS = () => {
	return [
		{
			title: "Threshold",
			name: "threshold"
		},
		{
			title: "Criteria",
			name: "criteria"
		},
		{
			title: "Amount",
			name: "amount",
			getCellValue: (row: contract_mj_bonus_threshold) => getCurrency(row.amount)
		},
		{
			title: "Earned",
			name: "earned",
			getCellValue: (row: contract_mj_bonus_threshold) => getCurrency(row.earned)
		},
		{
			title: "Earned Date",
			name: "earned_date",
			getCellValue: (row: contract_mj_bonus_threshold) =>
				row.earned_date ? dayjs(row.earned_date).format("YYYY-MM-DD") : null
		}
	];
};

export const CONTRACT_BONUS_TERM_COLUMNS = (isAwardBonus = false) => {
	const columns = [] as ContractBonusColumn[];
	columns.push(
		{
			title: "Threshold",
			name: "threshold"
		},
		{
			title: "Criteria",
			name: "criteria"
		},
		{
			title: "And/Or",
			name: "bonus_expression"
		}
	);
	if (!isAwardBonus)
		columns.push(
			{
				title: "Amount",
				name: "amount",
				getCellValue: (row: contract_mj_bonus_term) => getCurrency(row.amount)
			},
			{
				title: "Earned",
				name: "earned",
				getCellValue: (row: contract_mj_bonus_term) => getCurrency(row.earned)
			}
		);
	columns.push({
		title: "Earned Date",
		name: "earned_date",
		getCellValue: (row: contract_mj_bonus_term) =>
			row.earned_date ? dayjs(row.earned_date).format("YYYY-MM-DD") : null
	});
	return columns;
};

export const CONTRACT_BONUS_SALARY_ESCALATOR_COLUMNS = () => {
	return [
		{
			title: "Year(s)",
			name: "year",
			getCellValue: (row: contract_mj_bonus) =>
				row.all_years_flag
					? "All"
					: row.start_year === row.end_year
					? row.start_year
					: `${row.start_year} - ${row.end_year}`
		},
		{
			title: "Amount",
			name: "amount",
			isSortDisabled: true,
			getCellValue: (row: contract_mj_bonus) =>
				getCurrency(
					row.contract_mj_bonus_salary_escalators?.reduce((previous, current) => {
						return previous + (current.salary_increase_amount ?? 0);
					}, 0)
				)
		},
		{
			title: "Earned",
			name: "earned",
			isSortDisabled: true,
			getCellValue: (row: contract_mj_bonus) =>
				getCurrency(
					row.contract_mj_bonus_salary_escalators?.reduce((previous, current) => {
						return previous + (current.earned_amount ?? 0);
					}, 0)
				)
		},
		{
			title: "Criteria",
			name: "criteria",
			isSortDisabled: true
		},
		{
			title: "Comments",
			name: "comments",
			isSortDisabled: true,
			getCellValue: (row: contract_mj_bonus) => {
				return row.comments ? (
					<Tooltip title={row.comments} style={TooltipStyle}>
						<Info style={InfoStyle} />
					</Tooltip>
				) : (
					undefined
				);
			}
		}
	];
};

export const CONTRACT_SALARY_ESCALATORS_COLUMNS = () => {
	return [
		{
			title: "Year",
			name: "year"
		},
		{
			title: "Amount",
			name: "amount",
			getCellValue: (row: contract_mj_bonus_salary_escalator) => getCurrency(row.salary_increase_amount)
		},
		{
			title: "Earned",
			name: "earned",
			getCellValue: (row: contract_mj_bonus_salary_escalator) => getCurrency(row.earned_amount)
		}
	];
};
