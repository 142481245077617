import React, { FunctionComponent, useContext, useState, useEffect, CSSProperties } from "react";
import styled from "@emotion/styled";

import { useQueryString, getQueryStringValue } from "utils/url_helpers";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";

import { MAJOR, MINORS } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { ContractContext, ContractProvider } from "_react/playerpage/contract/_context";
import { ContractsTable } from "_react/playerpage/contract/ContractsTable";
import { ContractDetails } from "_react/playerpage/contract/ContractDetails";
import { ContractYearsTable } from "_react/playerpage/contract/mj/ContractYearsTable";
import {
	TContract,
	isContractMajors,
	isContractMinors,
	isNonNullContractMinors
} from "_react/playerpage/contract/_types";
import {
	getMjMiscellaneousClauses,
	getPerformanceBonuses,
	hasAssignmentClauses,
	hasForeignInquiries,
	hasMjBaseSalaries,
	hasMjMiscellaneousClauses,
	hasMnAssignmentSalaryClauses,
	hasPerformanceBonuses,
	hasReleaseClauses
} from "_react/playerpage/contract/_types";
import AddendumBAssignmentClauseTable from "_react/playerpage/contract/AddendumBAssignmentClauseTable";
import AddendumBForeignInquiryTable from "_react/playerpage/contract/AddendumBForeignInquiryClauseTable";
import AddendumBLanguageDataTable from "_react/playerpage/contract/AddendumBLanguageDataTable";
import AddendumBMjBaseSalaryTable from "_react/playerpage/contract/AddendumBAssignmentMjBaseSalaryTable";
import AddendumBMnAssignmentSalaryTable from "_react/playerpage/contract/AddendumBMnAssignmentSalaryTable";
import AddendumBReleaseClauseTable from "_react/playerpage/contract/AddendumBReleaseClauseTable";
import AddendumCTable from "_react/playerpage/contract/AddendumCTable";

type TContractStyle = {
	cardHeader?: CSSProperties;
};

type ContractProps = {
	colorScheme: ColorSchemeGroup;
	player: TPlayerPageCombinedPlayer;
	style?: TContractStyle;
};

type contractIdQS = [string | undefined, (level: number | null, isReplaceState?: boolean) => void];

const TableDiv = styled.div({ marginTop: "10px", boxSizing: "border-box" });

const ContractComp: FunctionComponent<ContractProps> = ({ colorScheme, player, style }) => {
	const contractsMj = player.proProfile?.contractsMj
		? player.proProfile?.contractsMj.map(e => ({ ...e, major: true }))
		: [];
	const contractsMn = player.proProfile?.contractsMn
		? player.proProfile?.contractsMn.map(e => ({ ...e, major: false }))
		: [];

	const { getContract } = useContext(ContractContext);

	const [contractIdRaw, setContractId] = useQueryString("contractId") as contractIdQS;
	const contractId = contractIdRaw != null ? parseInt(contractIdRaw, 10) : null;

	const levelRaw = getQueryStringValue("level") as string | undefined;
	const level = levelRaw === MINORS ? levelRaw : MAJOR;

	const [contract, setContract] = useState<TContract | null>(null);
	useEffect(() => {
		if (contractId != null) {
			getContract(contractId, level).then(newContract => {
				setContract(newContract);
			});
		} else {
			setContract(null);
		}
	}, [contractId, getContract, level]);

	const onClickContract = (contractId: number) => {
		setContractId(contractId);
	};

	return (
		<React.Fragment>
			<ContractsTable
				colorScheme={colorScheme}
				contracts={[...contractsMj, ...contractsMn]}
				selectedContractId={contractId}
				onClickContract={onClickContract}
				onChangeLevel={() => setContractId(null, true)}
				style={{ cardHeader: style?.cardHeader }}
			/>
			{contract !== null && (
				<TableDiv>
					<ContractDetails
						colorScheme={colorScheme}
						contract={contract}
						level={level}
						player={player}
						style={{ cardHeader: style?.cardHeader }}
					/>
					{contract != null && level === MAJOR && isContractMajors(contract) && (
						<>
							<TableDiv>
								<ContractYearsTable
									contract={contract}
									colorScheme={colorScheme}
									style={{ cardHeader: style?.cardHeader }}
								/>
							</TableDiv>
						</>
					)}
					{isNonNullContractMinors(contract, level) && hasAssignmentClauses(contract) && (
						<TableDiv>
							<AddendumBAssignmentClauseTable
								colorSchemeGroup={colorScheme}
								addendumBAssignmentClauses={contract.addendum_b_assignment_clauses ?? []}
								style={{ cardHeader: style?.cardHeader }}
							/>
						</TableDiv>
					)}
					{isNonNullContractMinors(contract, level) && hasReleaseClauses(contract) && (
						<TableDiv>
							<AddendumBReleaseClauseTable
								colorSchemeGroup={colorScheme}
								addendumBReleaseClauses={contract.addendum_b_release_clauses ?? []}
								style={{ cardHeader: style?.cardHeader }}
							/>
						</TableDiv>
					)}
					{isNonNullContractMinors(contract, level) && hasForeignInquiries(contract) && (
						<TableDiv>
							<AddendumBForeignInquiryTable
								colorSchemeGroup={colorScheme}
								addendumBForeignInquiries={contract.addendum_b_foreign_inquiries ?? []}
								style={{ cardHeader: style?.cardHeader }}
							/>
						</TableDiv>
					)}
					{contract != null && level === MINORS && isContractMinors(contract) && hasMjBaseSalaries(contract) && (
						<TableDiv>
							<AddendumBMjBaseSalaryTable
								colorSchemeGroup={colorScheme}
								addendumBMjBaseSalaries={contract.addendum_b_mj_base_salaries ?? []}
								style={{ cardHeader: style?.cardHeader }}
							/>
						</TableDiv>
					)}
					{contract != null &&
						level === MINORS &&
						isContractMinors(contract) &&
						hasMnAssignmentSalaryClauses(contract) && (
							<TableDiv>
								<AddendumBMnAssignmentSalaryTable
									colorSchemeGroup={colorScheme}
									addendumBMnAssignmentSalaries={contract.addendum_b_mn_assignment_salaries ?? []}
									style={{ cardHeader: style?.cardHeader }}
								/>
							</TableDiv>
						)}
					{contract != null &&
						level === MINORS &&
						isContractMinors(contract) &&
						hasPerformanceBonuses(contract) && (
							<TableDiv>
								<AddendumBLanguageDataTable
									tableText={"Addendum B Performance Bonus"}
									colorSchemeGroup={colorScheme}
									addendumBLanguageData={getPerformanceBonuses(contract) ?? []}
									style={{ cardHeader: style?.cardHeader }}
								/>
							</TableDiv>
						)}
					{contract != null &&
						level === MINORS &&
						isContractMinors(contract) &&
						hasMjMiscellaneousClauses(contract) && (
							<TableDiv>
								<AddendumBLanguageDataTable
									tableText={"Addendum B Major League Miscellaneous"}
									colorSchemeGroup={colorScheme}
									addendumBLanguageData={getMjMiscellaneousClauses(contract) ?? []}
									style={{ cardHeader: style?.cardHeader }}
								/>
							</TableDiv>
						)}
					{contract != null && level === MINORS && isContractMinors(contract) && (
						<TableDiv>
							<AddendumCTable
								colorSchemeGroup={colorScheme}
								addendumCs={contract.addendum_c ?? []}
								style={{ cardHeader: style?.cardHeader }}
							/>
						</TableDiv>
					)}
				</TableDiv>
			)}
		</React.Fragment>
	);
};

export const Contract: FunctionComponent<ContractProps> = props => (
	<ContractProvider>
		<ContractComp {...props} />
	</ContractProvider>
);
