import React, { useState } from "react";
import PropTypes from "prop-types";

import { Card } from "_react/shared/legacy/ui/Card";
import { TextField } from "_react/shared/legacy/ui/TextField";
import { Button } from "_react/shared/legacy/ui/Button";
import { IconButton } from "_react/shared/legacy/ui/IconButton";
import { RED, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { CardActions, CardActionsSeparator } from "_react/shared/legacy/ui/CardActions";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import { CardContents } from "_react/shared/legacy/ui/CardContents";
import Invisible from "_react/shared/legacy/ui/icons/Invisible";
import Visible from "_react/shared/legacy/ui/icons/Visible";
import { ForgotPasswordWarning } from "_react/app/ForgotPasswordWarning";
import { StatusIndicator } from "_react/status/StatusIndicator";

function Login({ handleLogin, loading }) {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
	const handleKeyPress = event => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleLogin(username, password);
		}
	};
	return (
		<div
			style={{
				backgroundColor: RED,
				position: "absolute",
				top: 0,
				bottom: 0,
				left: 0,
				right: 0
			}}
		>
			<div
				style={{
					width: "400px",
					maxWidth: "calc(100% - 20px)",
					padding: "10px",
					margin: "auto",
					position: "fixed",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)"
				}}
			>
				<Card>
					<ColoredCardHeader text="Rocky" />
					<CardContents>
						<form>
							<TextField
								autoComplete="username"
								fullWidth={true}
								name="username"
								onChange={event => setUsername(event.target.value)}
								placeholder={"Enter username or Phillies email"}
								style={{
									fontSize: "17px",
									marginBottom: "25px",
									marginTop: "25px"
								}}
								value={username}
							/>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								<TextField
									autoComplete="current-password"
									fullWidth={true}
									name="password"
									onChange={event => setPassword(event.target.value)}
									onKeyDown={handleKeyPress}
									placeholder={"Enter Password"}
									style={{ fontSize: "17px" }}
									type={showPassword ? "text" : "password"}
									value={password}
								/>
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									style={{ marginLeft: "15px" }}
									tabIndex="-1"
								>
									{showPassword ? <Invisible fill="white" /> : <Visible fill="white" />}
								</IconButton>
							</div>
						</form>
						<CardActions>
							{!loading ? (
								<Button
									disabled={username.length === 0 || password.length === 0}
									onClick={() => handleLogin(username, password)}
									title={"Login"}
								/>
							) : (
								<CircularProgress style={{ height: 28 }} />
							)}
							<CardActionsSeparator />
							<Button
								colorScheme={defaultColorScheme.secondary}
								onClick={() => setForgotPasswordOpen(true)}
								title="Forgot Password?"
							/>
						</CardActions>
					</CardContents>
				</Card>
				<StatusIndicator />
			</div>
			<ForgotPasswordWarning onClose={() => setForgotPasswordOpen(false)} open={forgotPasswordOpen} />
		</div>
	);
}

Login.propTypes = {
	handleLogin: PropTypes.func,
	loading: PropTypes.bool
};

export default Login;
