import React, { useState } from "react";
import { Avatar } from "@chakra-ui/react";

import {
	getAmaHeadshot,
	getMajorLeagueHeadshot,
	getMinorLeagueHeadshot
} from "_react/shared/data_models/player/_helpers";
import { AMA, PRO } from "_react/playerpage/_constants";
import { TPlayerPagePlayerClassification } from "_react/playerpage/_types";

type PlayerHeadshotProps = {
	playerClassification?: TPlayerPagePlayerClassification;
	bamId?: number | null;
	dplId?: number | null;
	alt?: string;
	size?: string;
	style?: React.CSSProperties;
};

const PlayerHeadshot = ({
	playerClassification = PRO,
	bamId,
	dplId,
	alt = "Player Headshot",
	size = "lg",
	style
}: PlayerHeadshotProps) => {
	const [imageSrc, setImageSrc] = useState<string | undefined>(
		playerClassification === PRO && bamId
			? getMajorLeagueHeadshot(bamId)
			: playerClassification === AMA && dplId
			? getAmaHeadshot(dplId)
			: undefined
	);
	const [hasTriedMilb, setHasTriedMiLB] = useState<boolean>(false);

	const handleError = () => {
		// Try MLB image, then MiLB image, then use Avatar defaults
		// TODO: use PlayerSilhouette.jpg instead of Avatar defaults?
		if (playerClassification === PRO && bamId && !hasTriedMilb) {
			setHasTriedMiLB(true);
			setImageSrc(getMinorLeagueHeadshot(bamId));
		}
	};

	return (
		<>
			<Avatar
				size={size}
				name={alt}
				src={imageSrc}
				bg={"gray.300"}
				onError={handleError}
				sx={{ border: "2px solid white", ...style }}
			/>
		</>
	);
};

export default PlayerHeadshot;
