import styled from "@emotion/styled";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";

// Used in Field.tsx
export const FieldDiv = styled.div({ display: "flex" });
export const LabelSpan = styled.span({ display: "flex", alignItems: "center", justifyContent: "center" });

// Used in Popup.tsx
export const IconButtonStyle = { padding: 2, height: 18, width: 18 };
export const IconSvgStyle = { height: 14, width: 14 };

// Used in PopupInfo.tsx
export const ActiveOnlyToggle = styled.div({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "space-between",
	padding: 3
});

type TActiveOnlyProps = { activeOnly: boolean; colorSchemeGroup?: ColorSchemeGroup };
export const ActiveOnly = styled.span<TActiveOnlyProps>(({ activeOnly, colorSchemeGroup }) => ({
	paddingRight: 3,
	flex: 2,
	fontSize: 14,
	fontWeight: activeOnly ? "bold" : "normal",
	color: activeOnly ? colorSchemeGroup?.secondary?.text : colorSchemeGroup?.secondary?.hoverText
}));

type TIncludeInactiveProps = { activeOnly: boolean; colorSchemeGroup?: ColorSchemeGroup };
export const IncludeInactive = styled.span<TIncludeInactiveProps>(({ activeOnly, colorSchemeGroup }) => ({
	paddingLeft: 3,
	flex: 2,
	fontSize: 14,
	fontWeight: !activeOnly ? "bold" : "normal",
	color: !activeOnly ? colorSchemeGroup?.secondary?.text : colorSchemeGroup?.secondary?.hoverText
}));

export const ActiveOnlyToggleButton = { flex: 1, height: 20, width: 20, padding: 0 };

export const ArrowLeftStyle = { height: 18, width: 18 };

export const ArrowRightStyle = { height: 18, width: 18 };

type TPopupInfoProps = { colorSchemeGroup?: ColorSchemeGroup };
export const PopupInfo = styled.div<TPopupInfoProps>(({ colorSchemeGroup }) => ({
	backgroundColor: colorSchemeGroup?.secondary?.color,
	border: "1px solid black",
	borderRadius: 5
}));

export const PopupInfoPadding = styled.div({
	padding: 5
});

export const ContactInfo = styled.div({
	maxHeight: 250,
	overflow: "auto"
});

type TNoContactInfoProps = { colorSchemeGroup?: ColorSchemeGroup };
export const NoContactInfo = styled.div<TNoContactInfoProps>(({ colorSchemeGroup }) => ({
	color: colorSchemeGroup?.secondary?.text
}));

export const LoadingContactInfo = styled.div({ height: 120, width: 200 });

type TPopupRowStyleProps = { ind: number };
export const PopupRowStyle = styled.tr<TPopupRowStyleProps>(({ ind }) => ({
	fontWeight: "bold",
	backgroundColor: ind % 2 === 0 ? "white" : "#EEEEEE"
}));

export const ContactInfoColumn = styled.span({ padding: 5 });

export const PlayerList = styled.div({
	marginTop: 3,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center"
});

export const LoadingPlayer = styled.div({ height: 300, width: 200 });

type TPlayerListHeaderProps = { colorSchemeGroup?: ColorSchemeGroup };
export const PlayerListHeader = styled.span<TPlayerListHeaderProps>(({ colorSchemeGroup }) => ({
	paddingTop: 10,
	fontWeight: "bold",
	color: colorSchemeGroup?.secondary?.text
}));

export const PlayerListTableDiv = styled.div({
	maxHeight: 300,
	overflow: "auto"
});

export const PlayerListTableRow = styled.div({
	padding: 2
});

type TDisplayPlayerFailureProps = { colorSchemeGroup?: ColorSchemeGroup };
export const DisplayPlayerFailure = styled.span<TDisplayPlayerFailureProps>(({ colorSchemeGroup }) => ({
	marginTop: 3,
	color: colorSchemeGroup?.secondary?.text,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center"
}));

type TDisplayNoPlayersProps = { colorSchemeGroup?: ColorSchemeGroup };
export const DisplayNoPlayers = styled.span<TDisplayNoPlayersProps>(({ colorSchemeGroup }) => ({
	marginTop: 3,
	color: colorSchemeGroup?.secondary?.text,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center"
}));
