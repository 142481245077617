import React from "react";
import styled from "@emotion/styled";

import { $TSFixMe } from "utils/tsutils";
import { SquareBadge } from "_react/shared/legacy/ui/SquareBadge";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { AMA, INTL, PRO } from "_react/playerpage/_constants";
import AmaBadges from "_react/playerpage/header/badges/AmaBadges";
import ProBadges from "_react/playerpage/header/badges/ProBadges";
import IntlBadges from "_react/playerpage/header/badges/IntlBadges";
import { $TSFixMeEval } from "_react/evals/shared/_types";

type HeaderBadgesProps = {
	colorSchemeGroup: ColorSchemeGroup;
	evaluations?: $TSFixMeEval[] | null;
	playerCombined: $TSFixMe;
	style?: object;
	viewClassification?: string | null;
};

export const BadgeRow = styled.div(`
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:first-of-type) {
        margin-top: 5px;
    }
    & > div {
        margin-right: 8px;
    }
`);

export default function HeaderBadges({
	colorSchemeGroup,
	evaluations,
	playerCombined,
	viewClassification
}: HeaderBadgesProps) {
	if (playerCombined) {
		if (viewClassification === AMA)
			return <AmaBadges colorSchemeGroup={colorSchemeGroup} evaluations={evaluations} player={playerCombined} />;
		if (viewClassification === PRO)
			return <ProBadges colorSchemeGroup={colorSchemeGroup} evaluations={evaluations} player={playerCombined} />;
		if (viewClassification === INTL)
			return <IntlBadges colorSchemeGroup={colorSchemeGroup} evaluations={evaluations} player={playerCombined} />;
	}

	return (
		<div>
			<BadgeRow>
				<SquareBadge loading={true} />
				<SquareBadge loading={true} />
			</BadgeRow>
			<BadgeRow>
				<SquareBadge loading={true} />
				<SquareBadge loading={true} />
			</BadgeRow>
		</div>
	);
}
