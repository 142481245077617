import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";

import {
	TSimpleProjections,
	TSimpleHitterProjections,
	TSimplePitcherProjections,
	TSimpleProjectionsAll,
	TFutureSeasonBattingProj,
	IProjectedPositionalProportions,
	IProjectedPitcherProportions
} from "_react/shared/data_models/projections/_types";

export type TSimilarProjectionsPlayerGetQueryParams = {
	playerId: number;
	season: number;
	ros: boolean;
	positionGroup?: string;
	projectedPositionGroup?: string;
	isSameOrg?: boolean;
	isPhillies?: boolean;
	isFortyMan?: boolean;
	isProspect?: boolean;
	numberSimilarPlayers?: number;
	isUseCache?: boolean;
};

export type TSimpleProjectionsAllGetQueryParams = {
	playerId?: number;
	bamId?: number;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const SimpleHitterProjectionsBasicSchema = "basic";
export type TSimpleHitterProjectionsGetQueryParams = {
	bamId?: number;
	bats?: string;
	season?: number;
	ros?: boolean;
	"totalBattingRar[notNull]"?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	schema?: typeof SimpleHitterProjectionsBasicSchema;
	isUseCache?: boolean;
};

export type TFutureSeasonBattingProjGetQueryParams = {
	playerId?: number;
	bamId?: number;
	season?: number;
	blendedBattingRaaVl?: number;
	blendedBattingRaaVr?: number;
	blendedBattingRaa?: number;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const SimplePitcherProjectionsBasicSchema = "basic";
export type TSimplePitcherProjectionsGetQueryParams = {
	bamId?: number;
	throws?: string;
	season?: number;
	ros?: boolean;
	isNonNullRaa?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	schema?: typeof SimplePitcherProjectionsBasicSchema;
	isUseCache?: boolean;
};

export type TProjectedProportionalPositionsGetQueryParams = {
	playerId?: number;
	bamId?: number;
	season?: number;
	proportionDh?: number;
	proportionC?: number;
	proportion1B?: number;
	proportion2B?: number;
	proportion3B?: number;
	proportionSs?: number;
	proportionLf?: number;
	proportionCf?: number;
	proportionRf?: number;
	projectedPosition?: string;
	limit?: number;
	offset?: number;
	sort?: string;
	isUseCache?: boolean;
};

export type TProjectedPitcherProportionsGetQueryParams = {
	playerId?: number;
	bamId?: number;
	projectedSeason?: number;
	proportionSp?: number;
	proportionRp?: number;
	limit?: number;
	offset?: number;
	sort?: string;
	isUseCache?: boolean;
};

export const fetchSimilarProjectionsPlayers = (
	queryParams?: TSimilarProjectionsPlayerGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TSimilarProjectionsPlayerGetQueryParams | undefined, Array<TSimpleProjections>>(
		"/projection/similar_player",
		queryParams,
		cancelToken
	);

export const fetchSimpleProjectionsAll = (
	queryParams?: TSimpleProjectionsAllGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TSimpleProjectionsAllGetQueryParams | undefined, Array<TSimpleProjectionsAll>>(
		"/projection/all",
		queryParams,
		cancelToken
	);

export const fetchSimpleHitterProjections = (
	queryParams?: TSimpleHitterProjectionsGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TSimpleHitterProjectionsGetQueryParams | undefined, Array<TSimpleHitterProjections>>(
		"/projection/simple_hitter_projections",
		queryParams,
		cancelToken
	);

export const fetchFutureSeasonBattingProj = (
	queryParams?: TFutureSeasonBattingProjGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TFutureSeasonBattingProjGetQueryParams | undefined, Array<TFutureSeasonBattingProj>>(
		"/projection/future_season_batting_proj",
		queryParams,
		cancelToken
	);

export const fetchSimplePitcherProjections = (
	queryParams: TSimplePitcherProjectionsGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TSimplePitcherProjectionsGetQueryParams, Array<TSimplePitcherProjections>>(
		"/projection/simple_pitcher_projections",
		queryParams,
		cancelToken
	);

export const fetchProjectedPositionalProportions = (
	queryParams: TProjectedProportionalPositionsGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TProjectedProportionalPositionsGetQueryParams, Array<IProjectedPositionalProportions>>(
		"/projection/projected_positional_proportions",
		queryParams,
		cancelToken
	);

export const fetchProjectedPitcherProportions = (
	queryParams: TProjectedPitcherProportionsGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TProjectedPitcherProportionsGetQueryParams, Array<IProjectedPitcherProportions>>(
		"/projection/projected_pitcher_proportions",
		queryParams,
		cancelToken
	);
