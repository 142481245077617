import React from "react";
import { VStack } from "@chakra-ui/react";

import { useIsGroupMember } from "_react/app/AppContents";
import { PRO_STATS_TABLES_BETA_IAM_GROUPS } from "_react/shared/data_models/iam_new/_constants";
import { PRO } from "utils/tsutils";

import PitcherFoundationalSkillsTable from "_react/shared/ui/data/tables/PitcherFoundationalSkillsTable/PitcherFoundationalSkillsTable";
import PitcherPerformanceTable from "_react/shared/ui/data/tables/PitcherPerformanceTable/PitcherPerformanceTable";
import PitcherPaOutcomesTable from "_react/shared/ui/data/tables/PitcherPaOutcomesTable/PitcherPaOutcomesTable";
import PitcherPitchOutcomesTable from "_react/shared/ui/data/tables/PitcherPitchOutcomesTable/PitcherPitchOutcomesTable";

type TPitcherStatisticsTablesProps = {
	playerId: number;
};

const PitcherStatisticsTables = ({ playerId }: TPitcherStatisticsTablesProps) => {
	const isBetaGroup = useIsGroupMember(PRO_STATS_TABLES_BETA_IAM_GROUPS);

	return (
		<VStack marginBottom="10" gap="10">
			<PitcherFoundationalSkillsTable
				title="Foundational Skills"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<PitcherPerformanceTable
				title="Performance"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<PitcherPaOutcomesTable
				title="PA Outcomes"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			{isBetaGroup && (
				<PitcherPitchOutcomesTable
					title="Pitch Outcomes"
					playerId={playerId}
					playingLevel={PRO}
					style={{
						container: { width: "100%" },
						tableContainer: { width: "100%", overflowX: "scroll" }
					}}
				/>
			)}
		</VStack>
	);
};

export default PitcherStatisticsTables;
