import React from "react";
import PropTypes from "prop-types";

export const HIT_SVG = ({ fill }) => (
	<svg fill={fill} height={18} viewBox="0 0 512.027 512.027" width={18} xmlns="http://www.w3.org/2000/svg">
		<path
			d="m490.053 21.984c-29.308-29.309-76.753-29.313-106.065 0-2.377 2.376-268.499 303.096-320.32 361.657-16.589-7.533-36.844-4.494-50.468 9.129-17.544 17.545-17.544 46.094 0 63.64l42.426 42.426c17.586 17.586 46.051 17.589 63.64 0 13.895-13.895 16.571-34.198 9.144-50.48 58.599-51.855 359.294-317.958 361.643-320.307 29.243-29.242 29.243-76.823 0-106.065zm-403.234 380.765c5.177-5.85 12.323-13.924 21.023-23.755l25.201 25.2c-9.831 8.7-17.905 15.846-23.755 21.023zm40.942-46.261c5.911-6.679 12.203-13.788 18.816-21.259l30.232 30.232c-7.471 6.613-14.581 12.905-21.259 18.816zm-29.707 121.135c-5.863 5.862-15.35 5.863-21.213 0l-42.426-42.426c-5.849-5.849-5.849-15.365 0-21.213 5.848-5.848 15.364-5.849 21.214 0l42.426 42.427c5.861 5.862 5.861 15.35-.001 21.212zm370.992-370.993c-7.177 6.495-162.537 144.031-269.731 238.91l-32.818-32.818c94.86-107.174 232.362-262.496 238.91-269.73 17.61-17.39 45.924-17.304 63.433.205 17.5 17.499 17.604 45.814.206 63.433z"
			strokeWidth="3"
		/>
		<path
			d="m360.02 332.017c-49.626 0-90 40.374-90 90s40.374 90 90 90 90-40.374 90-90-40.374-90-90-90zm60 90c0 10.533-2.737 20.435-7.524 29.048-4.876-8.783-7.476-18.704-7.476-29.048s2.6-20.265 7.476-29.048c4.787 8.613 7.524 18.515 7.524 29.048zm-120 0c0-10.533 2.737-20.435 7.524-29.048 4.876 8.783 7.476 18.704 7.476 29.048s-2.6 20.265-7.476 29.048c-4.786-8.613-7.524-18.515-7.524-29.048zm28.946 51.321c10.428-14.948 16.055-32.675 16.055-51.321s-5.627-36.373-16.055-51.321c9.065-5.506 19.696-8.679 31.055-8.679s21.99 3.173 31.055 8.679c-10.428 14.948-16.055 32.675-16.055 51.321s5.627 36.373 16.055 51.321c-9.065 5.506-19.696 8.679-31.055 8.679s-21.99-3.174-31.055-8.679z"
			strokeWidth="3"
		/>
	</svg>
);

HIT_SVG.propTypes = { fill: PropTypes.string };
HIT_SVG.defaultProps = { fill: "white" };
