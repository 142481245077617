import React from "react";
import { Box, Stat as ChakraStat, StatLabel, StatNumber, Tooltip, Text } from "@chakra-ui/react";

import { getColorGradientBinary } from "_react/shared/dataviz/_helpers";

import { FORMAT_FUNCTIONS, LOADING_HEIGHT, MD } from "_react/shared/ui/presentation/components/stat/shared/_constants";
import { IStatProps } from "_react/shared/ui/presentation/components/stat/shared/_types";

export interface IPrimaryStatProps extends IStatProps {
	valueTextColor?: string;
}

const PrimaryStat = ({
	label,
	value,
	format,
	valueTextColor = "gray.600",
	colorGradientInfo = [undefined, undefined, undefined],
	tooltipLabel,
	tooltipPlacement = "top",
	nullFillerText = "",
	isLoading = false,
	size = MD,
	style
}: IPrimaryStatProps) => {
	const valueNumber = typeof value === "string" ? parseFloat(value) : value;

	return (
		<ChakraStat sx={style?.stat} size={size}>
			<Tooltip
				isDisabled={tooltipLabel == null}
				hasArrow
				placement={tooltipPlacement}
				label={tooltipLabel}
				whiteSpace="pre-wrap"
				sx={style?.tooltip}
			>
				<StatLabel fontWeight="semibold" color="gray.900" whiteSpace="nowrap" sx={style?.statLabel}>
					{label}
				</StatLabel>
			</Tooltip>
			<StatNumber display="flex" alignItems="center" sx={style?.statNumber}>
				{isLoading && (
					<Box
						className="loading-item-transparent"
						h={LOADING_HEIGHT[size]}
						w="14"
						bg="gray.300"
						marginRight="1"
					/>
				)}
				{!isLoading && (
					<Box paddingRight="1" marginRight="1" borderRadius="md">
						<Text color={getColorGradientBinary(valueNumber, colorGradientInfo) ?? valueTextColor}>
							{value ? (format ? FORMAT_FUNCTIONS[format](value) : value) : nullFillerText}
						</Text>
					</Box>
				)}
			</StatNumber>
		</ChakraStat>
	);
};

export default PrimaryStat;
