import React, { FunctionComponent, useState, CSSProperties } from "react";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup, defaultColorScheme, BLACKGRAY } from "_react/shared/legacy/ui/Colors";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import Info from "_react/shared/legacy/ui/icons/Info";
import ExpandLess from "_react/shared/legacy/ui/icons/ExpandLess";
import ExpandMore from "_react/shared/legacy/ui/icons/ExpandMore";

import {
	CONTRACT_BONUS_COLUMNS,
	CONTRACT_BONUS_THRESHOLD_COLUMNS,
	CONTRACT_BONUS_TERM_COLUMNS,
	CONTRACT_BONUS_SALARY_ESCALATOR_COLUMNS,
	CONTRACT_SALARY_ESCALATORS_COLUMNS
} from "_react/playerpage/contract/_helpers";
import {
	IContract as IContractMajors,
	IContractBonus as IContractMjBonus,
	IContractBonusTerm
} from "_react/shared/_types/schema/contracts";
import {
	FlexDiv,
	ContractBonusTablesContainer,
	LeftTableContainer,
	TableText,
	ColorWhiteStyle,
	ExpandStyle,
	CommentTooltipStyle,
	CommentInfoStyle
} from "_react/playerpage/contract/_styles";

type TContractBonusesStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

type ContractBonusesProps = {
	contract: IContractMajors;
	year?: number | null;
	colorScheme?: ColorSchemeGroup;
	style?: TContractBonusesStyle;
};

export const ContractBonuses: FunctionComponent<ContractBonusesProps> = ({
	contract,
	year,
	colorScheme = defaultColorScheme,
	style
}) => {
	const [isAwardExpanded, setIsAwardExpanded] = useState<boolean>(false);
	const [isPerformanceExpanded, setIsPerformanceExpanded] = useState<boolean>(false);
	const [isEscalatorExpanded, setIsEscalatorExpanded] = useState<boolean>(false);
	const [isOtherExpanded, setIsOtherExpanded] = useState<boolean>(false);
	const [selectedAwardBonus, setSelectedAwardBonus] = useState<IContractMjBonus>();
	const [selectedPerformanceBonus, setSelectedPerformanceBonus] = useState<IContractMjBonus>();
	const [selectedSalaryEscalator, setSelectedSalaryEscalator] = useState<IContractMjBonus>();
	const [selectedOtherBonus, setSelectedOtherBonus] = useState<IContractMjBonus>();
	const { contract_mj_bonuses } = contract;

	const awardBonuses = contract_mj_bonuses?.filter(
		(bonus: IContractMjBonus) =>
			bonus.type === "AWARD" &&
			(bonus.all_years_flag ||
				((bonus.start_year ?? 0) <= (year ?? 0) && (bonus.end_year ?? 9999) >= (year ?? 9999)))
	);
	const performanceBonuses = contract_mj_bonuses?.filter(
		(bonus: IContractMjBonus) =>
			bonus.type === "PERFORMANCE" &&
			(bonus.all_years_flag ||
				((bonus.start_year ?? 0) <= (year ?? 0) && (bonus.end_year ?? 9999) >= (year ?? 9999)))
	);
	const salaryEscalators = contract_mj_bonuses?.filter(
		(bonus: IContractMjBonus) =>
			bonus.type === "SALARY_ESCALATOR" &&
			(bonus.all_years_flag ||
				((bonus.start_year ?? 0) <= (year ?? 0) && (bonus.end_year ?? 9999) >= (year ?? 9999)))
	);
	const otherBonuses = contract_mj_bonuses?.filter(
		(bonus: IContractMjBonus) =>
			bonus.type === "OTHER" &&
			(bonus.all_years_flag ||
				((bonus.start_year ?? 0) <= (year ?? 0) && (bonus.end_year ?? 9999) >= (year ?? 9999)))
	);

	const bonusCellBorderFunc = (row: IContractMjBonus, nextRow: IContractMjBonus | undefined) => {
		if (nextRow?.start_year == null || nextRow?.start_year === row?.start_year) return {};
		return { borderBottom: "1px solid black" };
	};

	return (
		<>
			<Card style={style?.card}>
				<ColoredCardHeader
					colorScheme={awardBonuses?.length ? colorScheme.secondary : BLACKGRAY.secondary}
					text={
						<FlexDiv>
							{awardBonuses?.length ? (
								isAwardExpanded ? (
									<ExpandMore style={ExpandStyle} fill={"white"} />
								) : (
									<ExpandLess style={ExpandStyle} fill={"white"} />
								)
							) : (
								undefined
							)}{" "}
							Award Bonuses
						</FlexDiv>
					}
					onClick={() => (awardBonuses?.length ? setIsAwardExpanded(!isAwardExpanded) : null)}
					style={{ ...ColorWhiteStyle, ...style?.cardHeader }}
				>
					{contract.award_bonus_comments != null && (
						<Tooltip title={contract.award_bonus_comments} placement={"left"} style={CommentTooltipStyle}>
							<Info fill={"white"} style={CommentInfoStyle} />
						</Tooltip>
					)}
				</ColoredCardHeader>
				{isAwardExpanded && (
					<ContractBonusTablesContainer>
						<LeftTableContainer>
							<AutoFitTable
								columns={CONTRACT_BONUS_COLUMNS(true)}
								defaultSortColumn={"Year(s)"}
								defaultSortState={"desc"}
								highlightIndexFunc={(rows: Array<IContractMjBonus>) =>
									rows.findIndex(
										(row: IContractMjBonus) => row.bonus_id === selectedAwardBonus?.bonus_id
									)
								}
								cellBorderFunc={bonusCellBorderFunc}
								onRowClick={(row: IContractMjBonus) => setSelectedAwardBonus(row)}
								rows={awardBonuses}
								rowKey="bonus_id"
							/>
							{!awardBonuses?.length && <TableText>No award bonuses</TableText>}
						</LeftTableContainer>
						<div>
							<AutoFitTable
								columns={CONTRACT_BONUS_TERM_COLUMNS(true)}
								defaultSortColumn={"Season"}
								defaultSortState={"desc"}
								cellBorderFunc={bonusCellBorderFunc}
								rows={selectedAwardBonus?.contract_mj_bonus_terms?.filter(
									(term: IContractBonusTerm) =>
										(term.start_year ?? 0) <= (year ?? 0) &&
										(term.end_year ?? 9999) >= (year ?? 9999)
								)}
								rowKey="bonus_term_id"
							/>
							{!selectedAwardBonus && <TableText>Select an award bonus to see terms...</TableText>}
							{selectedAwardBonus && !selectedAwardBonus?.contract_mj_bonus_terms?.length && (
								<TableText>No award bonus terms</TableText>
							)}
						</div>
					</ContractBonusTablesContainer>
				)}
			</Card>
			<Card style={style?.card}>
				<ColoredCardHeader
					colorScheme={performanceBonuses?.length ? colorScheme.secondary : BLACKGRAY.secondary}
					text={
						<FlexDiv>
							{performanceBonuses?.length ? (
								isPerformanceExpanded ? (
									<ExpandMore style={ExpandStyle} fill={"white"} />
								) : (
									<ExpandLess style={ExpandStyle} fill={"white"} />
								)
							) : (
								undefined
							)}{" "}
							Performance Bonuses
						</FlexDiv>
					}
					onClick={() =>
						performanceBonuses?.length ? setIsPerformanceExpanded(!isPerformanceExpanded) : null
					}
					style={{ ...ColorWhiteStyle, ...style?.cardHeader }}
				>
					{contract.perf_bonus_comments != null && (
						<Tooltip title={contract.perf_bonus_comments} placement={"left"} style={CommentTooltipStyle}>
							<Info fill={"white"} style={CommentInfoStyle} />
						</Tooltip>
					)}
				</ColoredCardHeader>
				{isPerformanceExpanded && (
					<ContractBonusTablesContainer>
						<LeftTableContainer>
							<AutoFitTable
								columns={CONTRACT_BONUS_COLUMNS()}
								defaultSortColumn={"Year(s)"}
								defaultSortState={"desc"}
								highlightIndexFunc={(rows: Array<IContractMjBonus>) =>
									rows.findIndex(
										(row: IContractMjBonus) => row.bonus_id === selectedPerformanceBonus?.bonus_id
									)
								}
								cellBorderFunc={bonusCellBorderFunc}
								onRowClick={(row: IContractMjBonus) => setSelectedPerformanceBonus(row)}
								rows={performanceBonuses}
								rowKey="bonus_id"
							/>
							{!performanceBonuses?.length && <TableText>No performance bonuses</TableText>}
						</LeftTableContainer>
						<div>
							<AutoFitTable
								columns={CONTRACT_BONUS_THRESHOLD_COLUMNS()}
								defaultSortColumn={"Threshold"}
								defaultSortState={"asc"}
								rows={selectedPerformanceBonus?.contract_mj_bonus_thresholds}
								rowKey="bonus_threshold_id"
							/>
							{!selectedPerformanceBonus && (
								<TableText>Select a performance bonus to see thresholds...</TableText>
							)}
							{selectedPerformanceBonus &&
								!selectedPerformanceBonus?.contract_mj_bonus_thresholds?.length && (
									<TableText>No performance bonus thresholds</TableText>
								)}
							<AutoFitTable
								columns={CONTRACT_BONUS_TERM_COLUMNS()}
								defaultSortColumn={"Year(s)"}
								defaultSortState={"desc"}
								cellBorderFunc={bonusCellBorderFunc}
								rows={selectedPerformanceBonus?.contract_mj_bonus_terms?.filter(
									(term: IContractBonusTerm) =>
										(term.start_year ?? 0) <= (year ?? 0) &&
										(term.end_year ?? 9999) >= (year ?? 9999)
								)}
								rowKey="bonus_term_id"
							/>
							{!selectedPerformanceBonus && (
								<TableText>Select a performance bonus to see terms...</TableText>
							)}
							{selectedPerformanceBonus && !selectedPerformanceBonus?.contract_mj_bonus_terms?.length && (
								<TableText>No performance bonus terms</TableText>
							)}
						</div>
					</ContractBonusTablesContainer>
				)}
			</Card>
			<Card style={style?.card}>
				<ColoredCardHeader
					colorScheme={salaryEscalators?.length ? colorScheme.secondary : BLACKGRAY.secondary}
					text={
						<FlexDiv>
							{salaryEscalators?.length ? (
								isEscalatorExpanded ? (
									<ExpandMore style={ExpandStyle} fill={"white"} />
								) : (
									<ExpandLess style={ExpandStyle} fill={"white"} />
								)
							) : (
								undefined
							)}{" "}
							Salary Escalators
						</FlexDiv>
					}
					onClick={() => (salaryEscalators?.length ? setIsEscalatorExpanded(!isEscalatorExpanded) : null)}
					style={{ ...ColorWhiteStyle, ...style?.cardHeader }}
				>
					{contract.salary_escalator_comments != null && (
						<Tooltip
							title={contract.salary_escalator_comments}
							placement={"left"}
							style={CommentTooltipStyle}
						>
							<Info fill={"white"} style={CommentInfoStyle} />
						</Tooltip>
					)}
				</ColoredCardHeader>
				{isEscalatorExpanded && (
					<ContractBonusTablesContainer>
						<LeftTableContainer>
							<AutoFitTable
								columns={CONTRACT_BONUS_SALARY_ESCALATOR_COLUMNS()}
								defaultSortColumn={"Year(s)"}
								defaultSortState={"desc"}
								highlightIndexFunc={(rows: Array<IContractMjBonus>) =>
									rows.findIndex(
										(row: IContractMjBonus) => row.bonus_id === selectedSalaryEscalator?.bonus_id
									)
								}
								cellBorderFunc={bonusCellBorderFunc}
								onRowClick={(row: IContractMjBonus) => setSelectedSalaryEscalator(row)}
								rows={salaryEscalators}
								rowKey="bonus_id"
							/>
							{!salaryEscalators?.length && <TableText>No salary escalators</TableText>}
						</LeftTableContainer>
						<div>
							<AutoFitTable
								columns={CONTRACT_SALARY_ESCALATORS_COLUMNS()}
								defaultSortColumn={"Threshold"}
								defaultSortState={"asc"}
								rows={selectedSalaryEscalator?.contract_mj_bonus_salary_escalators}
								rowKey="bonus_id"
							/>
							{!selectedSalaryEscalator && (
								<TableText>Select a salary escalator to see additional info...</TableText>
							)}
							{selectedSalaryEscalator &&
								!selectedSalaryEscalator?.contract_mj_bonus_salary_escalators?.length && (
									<TableText>No additional info</TableText>
								)}
						</div>
					</ContractBonusTablesContainer>
				)}
			</Card>
			<Card style={style?.card}>
				<ColoredCardHeader
					colorScheme={otherBonuses?.length ? colorScheme.secondary : BLACKGRAY.secondary}
					text={
						<FlexDiv>
							{otherBonuses?.length ? (
								isOtherExpanded ? (
									<ExpandMore style={ExpandStyle} fill={"white"} />
								) : (
									<ExpandLess style={ExpandStyle} fill={"white"} />
								)
							) : (
								undefined
							)}{" "}
							Other Bonuses
						</FlexDiv>
					}
					onClick={() => (otherBonuses?.length ? setIsOtherExpanded(!isOtherExpanded) : null)}
					style={{ ...ColorWhiteStyle, ...style?.cardHeader }}
				>
					{contract.other_bonus_comments != null && (
						<Tooltip title={contract.other_bonus_comments} placement={"left"} style={CommentTooltipStyle}>
							<Info fill={"white"} style={CommentInfoStyle} />
						</Tooltip>
					)}
				</ColoredCardHeader>
				{isOtherExpanded && (
					<ContractBonusTablesContainer>
						<LeftTableContainer>
							<AutoFitTable
								columns={CONTRACT_BONUS_COLUMNS(true)}
								defaultSortColumn={"Year(s)"}
								defaultSortState={"desc"}
								highlightIndexFunc={(rows: Array<IContractMjBonus>) =>
									rows.findIndex(
										(row: IContractMjBonus) => row.bonus_id === selectedOtherBonus?.bonus_id
									)
								}
								cellBorderFunc={bonusCellBorderFunc}
								onRowClick={(row: IContractMjBonus) => setSelectedOtherBonus(row)}
								rows={otherBonuses}
								rowKey="bonus_id"
							/>
							{!otherBonuses?.length && <TableText>No other bonuses</TableText>}
						</LeftTableContainer>
						<div>
							<AutoFitTable
								columns={CONTRACT_BONUS_THRESHOLD_COLUMNS()}
								defaultSortColumn={"Threshold"}
								defaultSortState={"asc"}
								rows={selectedOtherBonus?.contract_mj_bonus_thresholds}
								rowKey="bonus_threshold_id"
							/>
							{!selectedOtherBonus && <TableText>Select an other bonus to see thresholds...</TableText>}
							{selectedOtherBonus && !selectedOtherBonus?.contract_mj_bonus_thresholds?.length && (
								<TableText>No other bonus thresholds</TableText>
							)}
							<AutoFitTable
								columns={CONTRACT_BONUS_TERM_COLUMNS()}
								defaultSortColumn={"Year(s)"}
								defaultSortState={"desc"}
								cellBorderFunc={bonusCellBorderFunc}
								rows={selectedOtherBonus?.contract_mj_bonus_terms?.filter(
									(term: IContractBonusTerm) =>
										(term.start_year ?? 0) <= (year ?? 0) &&
										(term.end_year ?? 9999) >= (year ?? 9999)
								)}
								rowKey="bonus_term_id"
							/>
							{!selectedOtherBonus && <TableText>Select an other bonus to see terms...</TableText>}
							{selectedOtherBonus && !selectedOtherBonus?.contract_mj_bonus_terms?.length && (
								<TableText>No other bonus terms</TableText>
							)}
						</div>
					</ContractBonusTablesContainer>
				)}
			</Card>
		</>
	);
};
