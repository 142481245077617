import { round10 } from "_react/shared/_helpers/numbers";

export const convertToPercentage = (input: number, includeZeroValues = false) => {
	if (input === 0) return includeZeroValues ? "0%" : null;
	const value = Math.round(input * 100);
	if (value === 0) return "<1%";
	return `${value}%`;
};

export const convertToDecimal = (input: number) => {
	const value = round10(input, -1);
	// handle small negative numbers that are rounded to -0.0
	return value === "-0.0" ? "0.0" : value;
};

export const convertToCurrency = (input: number) => {
	return `$${round10(input, -1)}M`;
};
