import axios from "_redux/_utils/_axios";

import { TEval, TPlayersResponse } from "_react/evals/list/_types";

export const fetchEvalData = (evalIds: Array<string>) => {
	const path = `/evals`;
	return axios
		.post(path, { eval_ids: evalIds })
		.then(response => response.data as Array<TEval>)
		.catch(error => {
			console.log("Error");
			console.log(error);
			return Promise.reject();
		});
};

export const fetchPlayerData = (philIds: Array<number>) => {
	const uniquePhilIds = philIds.filter((v, i, a) => a.indexOf(v) === i);
	const path = `/players`;
	return axios
		.post(path, { phil_ids: uniquePhilIds })
		.then(response => response.data as TPlayersResponse)
		.catch(error => {
			console.log("Error");
			console.log(error);
			return Promise.reject();
		});
};
