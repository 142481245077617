import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { fetchAmaProspectValue } from "_react/shared/data_models/phred/_network";
import { IAmaProspectValue } from "_react/shared/data_models/phred/_types";
import { getCancelSource } from "utils/url_helpers";
import { MODEL_NAME_V2 } from "_react/shared/data_models/phred/_constants";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";

import { TAmaProspectValuesStatCardData } from "_react/shared/ui/data/cards/AmaProspectValuesStatCard/AmaProspectValuesStatCard";

export type TAmaProspectValuesStatCardContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	amaProspectValues?: Array<IAmaProspectValue> | null;
	cancelSources: Record<string, CancelTokenSource>;
	toast?: CreateToastFnReturn;
};

interface IAmaProspectValuesStatCardStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all APV data
				amaProspectValues: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_AMA_PROSPECT_VALUES = "SET_AMA_PROSPECT_VALUES";
const AMA_PROSPECT_VALUES_CANCEL_SOURCE = "amaProspectValues";
export const FETCHING_AMA_PROSPECT_VALUES = { initialized: { amaProspectValues: "fetching" } };

const FETCH_AMA_PROSPECT_VALUES_DONE = "done.invoke.fetchingAmaProspectValues:invocation[0]";
type TFetchAmaProspectValuesEvent = { type: typeof FETCH_AMA_PROSPECT_VALUES_DONE; data?: Array<IAmaProspectValue> };

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; data: number | undefined };
type TSetAmaProspectValuesEvent = {
	type: typeof SET_AMA_PROSPECT_VALUES;
	data: Array<IAmaProspectValue> | null | undefined;
};

type TAmaProspectValuesStatCardEvent = TSetPlayerIdEvent | TSetAmaProspectValuesEvent | TFetchAmaProspectValuesEvent;

export type TAmaProspectValuesValuesStatCardSend = Interpreter<
	TAmaProspectValuesStatCardContext,
	IAmaProspectValuesStatCardStateSchema,
	TAmaProspectValuesStatCardEvent
>["send"];

const AmaProspectValuesStatCardMachine = (
	playerIdProp?: number,
	shouldFetchData = true,
	data?: TAmaProspectValuesStatCardData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TAmaProspectValuesStatCardContext, IAmaProspectValuesStatCardStateSchema, TAmaProspectValuesStatCardEvent>(
		{
			id: "amaProspectValuesStatCard",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				shouldFetchData: shouldFetchData,
				amaProspectValues: data?.amaProspectValues,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_AMA_PROSPECT_VALUES]: { actions: "setAmaProspectValues" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						amaProspectValues: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingAmaProspectValues",
												cond: "shouldFetchAmaProspectValues"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingAmaProspectValues",
									entry: ["refreshAmaProspectValuesCancelSource"],
									invoke: {
										src: "fetchAmaProspectValues",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchAmaProspectValuesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchAmaProspectValuesErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TAmaProspectValuesStatCardContext,
					_event: TAmaProspectValuesStatCardEvent
				) => context.playerId !== context.lastPlayerId,
				shouldFetchAmaProspectValues: (
					context: TAmaProspectValuesStatCardContext,
					_event: TAmaProspectValuesStatCardEvent
				) => context.amaProspectValues === undefined && shouldFetchData && context.playerId !== undefined
			},
			actions: {
				setPlayerId: assign<TAmaProspectValuesStatCardContext, TAmaProspectValuesStatCardEvent>({
					playerId: (context: TAmaProspectValuesStatCardContext, event: TAmaProspectValuesStatCardEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setAmaProspectValues: assign<TAmaProspectValuesStatCardContext, TAmaProspectValuesStatCardEvent>({
					amaProspectValues: (
						context: TAmaProspectValuesStatCardContext,
						event: TAmaProspectValuesStatCardEvent
					) => {
						if (event.type !== SET_AMA_PROSPECT_VALUES) return context.amaProspectValues;
						return event.data;
					},
					cancelSources: (
						context: TAmaProspectValuesStatCardContext,
						event: TAmaProspectValuesStatCardEvent
					) => {
						if (event.type !== SET_AMA_PROSPECT_VALUES) return context.cancelSources;
						if (context.cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE] != null)
							context.cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearContext: assign<TAmaProspectValuesStatCardContext, TAmaProspectValuesStatCardEvent>({
					lastPlayerId: (
						context: TAmaProspectValuesStatCardContext,
						_event: TAmaProspectValuesStatCardEvent
					) => context.playerId,
					amaProspectValues: (
						_context: TAmaProspectValuesStatCardContext,
						_event: TAmaProspectValuesStatCardEvent
					) => undefined,
					cancelSources: (
						context: TAmaProspectValuesStatCardContext,
						_event: TAmaProspectValuesStatCardEvent
					) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshAmaProspectValuesCancelSource: assign<
					TAmaProspectValuesStatCardContext,
					TAmaProspectValuesStatCardEvent
				>({
					cancelSources: (
						context: TAmaProspectValuesStatCardContext,
						_event: TAmaProspectValuesStatCardEvent
					) => {
						if (context.cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE] != null)
							context.cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE].cancel();
						context.cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchAmaProspectValuesSuccess: assign<
					TAmaProspectValuesStatCardContext,
					TAmaProspectValuesStatCardEvent
				>({
					amaProspectValues: (
						context: TAmaProspectValuesStatCardContext,
						event: TAmaProspectValuesStatCardEvent
					) => {
						if (event.type !== FETCH_AMA_PROSPECT_VALUES_DONE) return context.amaProspectValues;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchAmaProspectValuesErrored: (
					context: TAmaProspectValuesStatCardContext,
					_event: TAmaProspectValuesStatCardEvent
				) => {
					if (context.toast)
						context.toast({
							title: "AMA Prospect Value",
							description: "Error fetching ama prospect value data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchAmaProspectValues: (context: TAmaProspectValuesStatCardContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchAmaProspectValue(
							{
								playerId: context.playerId,
								modelName: MODEL_NAME_V2,
								isUseCache: true
							},
							context.cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default AmaProspectValuesStatCardMachine;
