import React, { useEffect, useMemo } from "react";
import { useToast, VStack, HStack, Box, IconButton, Tooltip } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import { IPro2PredictionsSummary } from "_react/shared/data_models/pro2_predictions/_types";
import Leaderboard from "_react/shared/ui/icons/Leaderboard";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";
import { TToggleOption } from "_react/shared/ui/presentation/components/ToggleButton/_types";

import { SIMILAR_PLAYERS_PRO_MODEL_COLUMNS } from "_react/shared/ui/data/tables/SimilarPlayersProModelTable/_constants";
import SimilarPlayersProModelTableMachine, {
	TSimilarPlayersProModelTableContext,
	FETCHING_SIMILAR_PLAYERS,
	SET_FILTERS,
	SET_PARSED_SIMILAR_PLAYERS
} from "_react/shared/ui/data/tables/SimilarPlayersProModelTable/_machine";
import {
	TSimilarPlayersFilters,
	TSimilarPlayersToggleOptions
} from "_react/shared/ui/data/tables/SimilarPlayersProModelTable/_types";

export type TSimilarPlayersProModelTableData = {
	parsedSimilarPlayers: Array<IPro2PredictionsSummary>;
	isLoading?: boolean;
};

type TSimilarPlayersProModelTableProps = {
	title?: string;
	filters?: TSimilarPlayersFilters;
	data?: TSimilarPlayersProModelTableData;
	shouldFetchData?: boolean;
	columns?: Array<string>;
	toggleOptions?: TSimilarPlayersToggleOptions;
	tableProps?: TTableProps<IPro2PredictionsSummary, keyof IPro2PredictionsSummary>;
};

const SimilarPlayersProModelTable = ({
	title,
	filters: filtersProp,
	data,
	shouldFetchData = true,
	columns,
	toggleOptions,
	tableProps
}: TSimilarPlayersProModelTableProps) => {
	const toast = useToast();

	const [current, send] = useMachine(SimilarPlayersProModelTableMachine(filtersProp, data, shouldFetchData, toast));
	const context = current.context as TSimilarPlayersProModelTableContext;
	const { filters, parsedSimilarPlayers } = context;

	const isFetchingSimilarPlayers = current.matches(FETCHING_SIMILAR_PLAYERS);
	const isLoading = shouldFetchData ? isFetchingSimilarPlayers : data?.isLoading;

	useEffect(() => {
		if (filtersProp) send({ type: SET_FILTERS, value: filtersProp });
	}, [filtersProp, send]);

	useEffect(() => {
		if (data?.parsedSimilarPlayers !== parsedSimilarPlayers && !shouldFetchData) {
			send({ type: SET_PARSED_SIMILAR_PLAYERS, data: data?.parsedSimilarPlayers });
		}
	}, [data?.parsedSimilarPlayers, shouldFetchData, send, parsedSimilarPlayers]);

	const filteredColumns = useMemo(() => {
		if (!columns) return SIMILAR_PLAYERS_PRO_MODEL_COLUMNS;
		return SIMILAR_PLAYERS_PRO_MODEL_COLUMNS.filter(
			(col: TColumn<IPro2PredictionsSummary, keyof IPro2PredictionsSummary>) => columns.includes(col.value)
		);
	}, [columns]);

	const toggleButtonOptions = useMemo(() => {
		const options: Array<TToggleOption<string>> = [];
		if (toggleOptions?.isFortyMan) options.push({ value: "isFortyMan", label: "40 Man" });
		if (toggleOptions?.isPhillies) options.push({ value: "isPhillies", label: "PHI" });
		if (toggleOptions?.isSameOrg) options.push({ value: "isSameOrg", label: "Org" });
		if (toggleOptions?.isLeague) options.push({ value: "isLeague", label: "League" });
		return options;
	}, [toggleOptions]);

	const handleToggleButtonSelect = (value: string) => {
		if (!filters) return;
		const newFilters = {
			...filters,
			isFortyMan: value === "isFortyMan" ? true : undefined,
			isPhillies: value === "isPhillies" ? true : undefined,
			isSameOrg: value === "isFortyMan" || value === "isSameOrg" ? true : undefined
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start">
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
					<Tooltip hasArrow placement="top" label="Go to Pro Model Leaderboard">
						<IconButton
							aria-label="Go to pro model leaderboard"
							variant="outline"
							size="xs"
							icon={<Leaderboard sx={{ color: "gray", height: 13, width: 13 }} />}
							onClick={() => window.open(`../promodel/leaderboard`, "_blank")}
						/>
					</Tooltip>
				</HStack>
				{toggleOptions && (
					<ToggleButton<string>
						toggleOptions={toggleButtonOptions}
						value={
							filters?.isFortyMan
								? "isFortyMan"
								: filters?.isSameOrg
								? "isSameOrg"
								: filters?.isPhillies
								? "isPhillies"
								: "isLeague"
						}
						onSelect={handleToggleButtonSelect}
						isDisabled={isFetchingSimilarPlayers}
					/>
				)}
			</HStack>
			<Table<IPro2PredictionsSummary, keyof IPro2PredictionsSummary>
				columns={filteredColumns as Array<TColumn<IPro2PredictionsSummary, keyof IPro2PredictionsSummary>>}
				data={parsedSimilarPlayers}
				emptyDataDisplayText={"No Similar Players Found"}
				isLoadingData={isLoading}
				highlightIndexFunction={(data: Array<IPro2PredictionsSummary>) => {
					return data.findIndex((pred: IPro2PredictionsSummary) => pred.player.id === filters?.playerId);
				}}
				defaultSortColumns={[{ columnValue: "blendedSv", sortDirection: DESC }]}
				{...tableProps}
			/>
		</VStack>
	);
};

export default SimilarPlayersProModelTable;
