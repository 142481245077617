import React, { useRef, CSSProperties, useMemo } from "react";
import ReactModal from "react-modal";
import styled from "@emotion/styled";
import ReactToPrint from "react-to-print";

import ClearIcon from "_react/shared/legacy/ui/icons/Clear";
import PrintIcon from "_react/shared/legacy/ui/icons/Print";
import { CardHeader } from "_react/shared/legacy/ui/CardHeader";
import { $TSFixMe } from "utils/tsutils";

if (process.env.NODE_ENV !== "test") ReactModal.setAppElement("#root");

type THeader = {
	fontSize?: CSSProperties["fontSize"];
	text: string;
};

export interface IModalProps extends ReactModal.Props {
	children?: React.ReactChild;
	showX?: boolean;
	showPrint?: boolean;
	header?: THeader;
}

const DEFAULT_STYLES = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		maxWidth: "calc(100vw - 100px)",
		maxHeight: "calc(100vh - 100px)",
		minWidth: "50%",
		overflow: "inherit"
	},
	overlay: { zIndex: 1000 }
};

const PrintDiv = styled.div(`
    @media print {
        padding: 20px;
        * {
            page-break-inside: avoid;
        }
    }
`);

const StyledClearIcon = styled(ClearIcon)`
	position: absolute;
	top: 2px;
	right: 2px;
	height: 16px;
	width: 16px;
	cursor: pointer;
`;

const StyledPrintIcon = styled(PrintIcon)`
	position: absolute;
	top: 2px;
	right: 20px;
	height: 16px;
	width: 16px;
	cursor: pointer;
`;

export default function Modal({ children, showX, showPrint, header, ...modalProps }: IModalProps) {
	const printRef = useRef<$TSFixMe>();

	const Header = useMemo(() => {
		return header == null ? null : <CardHeader text={header.text} fontSize={header.fontSize || "1.2rem"} />;
	}, [header]);

	return (
		<ReactModal style={DEFAULT_STYLES} {...modalProps}>
			{Header}
			{showX && <StyledClearIcon onClick={modalProps.onRequestClose as $TSFixMe} />}
			{showPrint && <ReactToPrint content={() => printRef.current} trigger={() => <StyledPrintIcon />} />}
			<PrintDiv ref={printRef}>{children}</PrintDiv>
		</ReactModal>
	);
}

type TContainerDivProps = Partial<Pick<CSSProperties, "minWidth" | "minHeight" | "paddingTop">>;
export const ContainerDiv = styled.div<TContainerDivProps>(
	({ minHeight = 500, minWidth = 400, paddingTop }) => `
	min-height: ${typeof minHeight === "number" ? minHeight + "px" : minHeight};
	min-width: ${typeof minWidth === "number" ? minWidth + "px" : minWidth};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	padding-top: ${typeof paddingTop === "number" ? paddingTop + "px" : paddingTop};
`
);

export const ContentDiv = styled.div(`
	box-sizing: border-box;
	padding-bottom: 10px;
	padding-left: 3px
	padding-right: 3px;
`);

export const ActionsDiv = styled.div(`
	box-sizing: border-box;
	padding-bottom: 5px;
	padding-left: 3px;
	padding-right: 3px;
	display: flex;
	justify-content: flex-end;
`);

export const ActionDiv = styled.div(`
	box-sizing: border-box;
	padding-right: 8px;
	:last-child {
		padding-right: 0px;
	}
`);
