import React, { CSSProperties } from "react";
import { Box } from "@chakra-ui/react";

type TabContainerProps = {
	children: React.ReactNode;
	style?: CSSProperties;
};

const TabContainer = ({ children, style }: TabContainerProps) => {
	return (
		<Box paddingTop="12" sx={style}>
			{children}
		</Box>
	);
};

export default TabContainer;
