import React from "react";

import { Button } from "_react/shared/legacy/ui/Button";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

import { DISABLED_OPACITY } from "_react/playerplan/shared/_constants";
import { LeftButtonStyle, NotesConfirmButtonDiv, NotesConfirmDiv } from "_react/playerplan/shared/_styles";
import { TGoalNoteFilters, TNoteForm } from "_react/playerplan/shared/_types";

export type TPlayerPlanNoteConfirmationProps = {
	noteForm: TNoteForm;
	noteTypePrompt?: string;
	setIsConfirmingAddNote: (isConfirmingAddNote: boolean) => void;
	setNoteForm: (noteForm: TNoteForm) => void;
	defaultNoteForm: TNoteForm;
	setNoteTypePrompt: (noteTypePrompt?: string) => void;
	setNoteFilters: (noteFilters: TGoalNoteFilters) => void;
	defaultNoteFilters: TGoalNoteFilters;
	setIsSortAsc: (isSortAsc: boolean) => void;
	addNote: (noteForm: TNoteForm) => void;
	colorSchemeGroup?: ColorSchemeGroup;
};

export const PlayerPlanNoteConfirmation = ({
	noteForm,
	noteTypePrompt,
	setIsConfirmingAddNote,
	setNoteForm,
	defaultNoteForm,
	setNoteTypePrompt,
	setNoteFilters,
	defaultNoteFilters,
	setIsSortAsc,
	addNote,
	colorSchemeGroup = defaultColorScheme
}: TPlayerPlanNoteConfirmationProps) => {
	return (
		<>
			<NotesConfirmDiv>
				<p>
					Are you sure you want to create a note connected to the following?
					<br />
					<br />
					<b>Goal: </b>
					{`${noteForm.playerPlanGoalLabel ?? "None"}`}
					<br />
					<b>Focus Area: </b>
					{`${noteForm.focusAreaLabel ?? "None"}`}
					<br />
					<b>Supporting Action: </b>
					{`${noteForm.drillLabel ?? "None"}`}
					<br />
					<br />
				</p>
			</NotesConfirmDiv>
			<NotesConfirmButtonDiv isTranslate={noteForm.playerPlanGoalId != null}>
				<Button
					onClick={() => {
						addNote({
							...noteForm,
							content: `${noteTypePrompt ?? ""}${noteTypePrompt ? "-----\n" : ""}${noteForm.content}`
						});
						setIsConfirmingAddNote(false);
						setNoteForm(defaultNoteForm);
						setNoteTypePrompt(undefined);
						setNoteFilters(defaultNoteFilters);
						setIsSortAsc(false);
					}}
					colorScheme={colorSchemeGroup.secondary}
					title="Confirm"
					disabled={!noteForm.content}
					style={LeftButtonStyle}
					disabledOpacity={DISABLED_OPACITY}
				/>
				<Button
					disabled={false}
					onClick={() => setIsConfirmingAddNote(false)}
					title="Cancel"
					colorScheme={colorSchemeGroup.primary}
				/>
			</NotesConfirmButtonDiv>
		</>
	);
};
