import { CancelToken } from "axios";

import { promiseWRetry } from "utils/helpers";
import { IDataModel, TCacheContents } from "_react/shared/data_models/_types";
import { TDict } from "_react/shared/_helpers/axios";

import {
	postIntrinsicValueHeatmap,
	TIntrinsicValueHeatmapPostBody
} from "_react/shared/data_models/inference/_network";
import { IIntrinsicValue } from "_react/shared/data_models/inference/_types";

class IntrinsicValueDataModel implements IDataModel<IIntrinsicValue> {
	_cache: Record<string, TCacheContents<IIntrinsicValue>>;

	constructor() {
		this._cache = {};
	}

	postResource = (body: TDict, cancelToken?: CancelToken): Promise<Array<IIntrinsicValue> | undefined | null> => {
		const postFunc = () => postIntrinsicValueHeatmap(body as TIntrinsicValueHeatmapPostBody, cancelToken);
		// TODO handle the new API response structure instead of returning just the `data`
		return promiseWRetry(postFunc).then(response => response?.data);
	};
}

const IntrinsicValue = new IntrinsicValueDataModel();

export default IntrinsicValue;
