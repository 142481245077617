import React from "react";
import PropTypes from "prop-types";

import { PLAYER_OFFENSE_TOOL_FIELDS, HIT_TYPE_OPTS } from "_react/evals/_constants";

const CELL_STYLE = {
	borderRight: "1px solid lightGray",
	paddingLeft: 10,
	paddingRight: 10
};

const getVal = (evaluation, field) => {
	if (evaluation.hasOwnProperty(field)) {
		return evaluation[field];
	}
	return "";
};

const ProPlayerOffenseToolsTable = ({ evaluation, fontSize, height }) => {
	return (
		<table
			style={{
				width: "100%",
				tableLayout: "auto",
				borderCollapse: "collapse"
			}}
		>
			<thead>
				<tr
					style={{
						backgroundColor: "#E8E9ED",
						fontSize,
						fontWeight: 600,
						height
					}}
				>
					<th style={CELL_STYLE}>Tool</th>
					<th style={CELL_STYLE}>Present</th>
					<th style={CELL_STYLE}>Future</th>
					{evaluation.publish_date != null && evaluation.publish_date.year() < 2019 && (
						<th style={CELL_STYLE}>Comments</th>
					)}
				</tr>
			</thead>
			<tbody>
				{PLAYER_OFFENSE_TOOL_FIELDS.map(({ name, id }, idx) => {
					if (id === "hit_type") {
						name =
							evaluation.publish_date != null && evaluation.publish_date.year() < 2019
								? "Hitter Type"
								: "Type of Hitter";
						let value =
							getVal(evaluation, "hit_type") !== ""
								? HIT_TYPE_OPTS.filter(e => e.value === getVal(evaluation, "hit_type"))
								: [""];
						if (value.length > 0) value = value[0].text;
						return (
							<tr
								key={id}
								style={{
									backgroundColor: idx % 2 === 0 ? "white" : "#E8E9ED",
									fontWeight: "normal",
									height,
									fontSize
								}}
							>
								<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{name}</td>
								<td colSpan={2} style={CELL_STYLE}>
									{evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0 ? value : ""}
								</td>
								{evaluation.publish_date != null && evaluation.publish_date.year() < 2019 && (
									<td style={CELL_STYLE} />
								)}
							</tr>
						);
					} else if (id === "home_to_1b") {
						return (
							<tr
								key={id}
								style={{
									backgroundColor: idx % 2 === 0 ? "white" : "#E8E9ED",
									fontWeight: "normal",
									height,
									fontSize
								}}
							>
								<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{name}</td>
								<td colSpan={2} style={CELL_STYLE}>
									{evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
										? getVal(evaluation, id)
										: ""}
								</td>
								{evaluation.publish_date != null && evaluation.publish_date.year() < 2019 && (
									<td style={CELL_STYLE} />
								)}
							</tr>
						);
					}

					return (
						<tr
							key={id}
							style={{
								backgroundColor: idx % 2 === 0 ? "white" : "#E8E9ED",
								fontWeight: "normal",
								height,
								fontSize
							}}
						>
							<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{name}</td>
							<td style={CELL_STYLE}>
								{evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
									? getVal(evaluation, `${id.toLowerCase()}_present`)
									: ""}
							</td>
							<td style={CELL_STYLE}>
								{evaluation.ofp > 30 || evaluation.ofp === 25 || evaluation.ofp === 0
									? getVal(evaluation, `${id.toLowerCase()}_future`)
									: ""}
							</td>
							{evaluation.publish_date != null && evaluation.publish_date.year() < 2019 && (
								<td style={CELL_STYLE}>
									{evaluation[`${id}_comments`] != null ? evaluation[`${id}_comments`] : ""}
								</td>
							)}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

ProPlayerOffenseToolsTable.propTypes = {
	evaluation: PropTypes.object,
	fontSize: PropTypes.string,
	height: PropTypes.string
};

ProPlayerOffenseToolsTable.defaultProps = {
	height: "32px",
	fontSize: ".875em"
};

export default ProPlayerOffenseToolsTable;
