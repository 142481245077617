// Sort directions

export const ASC = "asc";
export const DESC = "desc";

// Value Types

export const VALUE_TYPE_STRING = "string";
export const VALUE_TYPE_NUMBER = "number";
export const VALUE_TYPE_OTHER = "other";
