import React, { FunctionComponent, useMemo, CSSProperties } from "react";
import dayjs from "dayjs";
import styled from "@emotion/styled";

import { getCurrency } from "utils/helpers";
import { $TSFixMe } from "utils/tsutils";
import { useQueryString } from "utils/url_helpers";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { Button } from "_react/shared/legacy/ui/Button";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

import { TLevel } from "_react/playerpage/contract/_types";
import { getMnSigningBonus } from "_react/playerpage/contract/_helpers";
import { MAJOR, MINORS } from "_react/playerpage/_constants";
import { TPlayerPageContractsMj, TPlayerPageContractsMn } from "_react/playerpage/_types";

export const getOptions = (row: $TSFixMe) => {
	return `${row.nonOptionYears} + ${row.optionYears}`;
};

export const MAJOR_COLUMNS = [
	{ title: "Start Year", name: "startYear" },
	{ title: "Status", name: "status" },
	{
		title: "Confirmed Terms MLS",
		name: "mlsSigning",
		getCellValue: (row: $TSFixMe) => (row.mlsSigning != null ? row.mlsSigning.toFixed(3) : "")
	},
	{
		title: "Confirmed Terms Date",
		name: "confirmedDateAsString",
		getCellValue: (row: $TSFixMe) =>
			row.confirmedDateAsString != null ? dayjs(row.confirmedDateAsString).format("YYYY-MM-DD") : ""
	},
	{ title: "Signing ORG", name: "signingOrg" },
	{
		title: "Total Guaranteed",
		name: "totalGuarantee",
		getCellValue: (row: $TSFixMe) => getCurrency(row.totalGuarantee)
	},
	{ title: "Years", name: "startYear", getCellValue: getOptions },
	{ title: "AAV", name: "aav", getCellValue: (row: $TSFixMe) => getCurrency(row.aav) },
	{ title: "Signing Bonus", name: "signingBonus", getCellValue: (row: $TSFixMe) => getCurrency(row.signingBonus) }
];

export const MINOR_COLUMNS = [
	{ title: "Start Year", name: "startYear" },
	{ title: "Status", name: "status" },
	{
		title: "Signing Date",
		name: "signingDate",
		getCellValue: (row: $TSFixMe) => (row.signedDate != null ? dayjs(row.signedDate).format("YYYY-MM-DD") : "")
	},
	{
		title: "Confirmed Terms Date",
		name: "approvedDate",
		getCellValue: (row: $TSFixMe) => {
			if (row.hasOwnProperty("approvedDate") && row.approvedDate !== null) {
				return dayjs(row.approvedDate).format("YYYY-MM-DD");
			}
			return "-";
		}
	},
	{ title: "Years", name: "numYears" },
	{ title: "ORG", name: "org" },
	{ title: "Club", name: "team" },
	{
		title: "Signing Bonus",
		name: "signingBonusDetails",
		getCellValue: (row: $TSFixMe) => getMnSigningBonus(row)
	}
];

type TContractsTableStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

type ContractsTableProps = {
	colorScheme?: ColorSchemeGroup;
	contracts?: Array<$TSFixMe>;
	selectedContractId?: number | null;
	style?: TContractsTableStyle;
	onClickContract(contractId: number): void;
	onChangeLevel?(): void;
	tableMaxHeight?: React.CSSProperties["maxHeight"];
};

type levelQS = [string | undefined, (level: string) => void];

const TableDiv = styled.div<{ maxHeight?: React.CSSProperties["maxHeight"] }>(({ maxHeight }) => ({
	maxHeight,
	overflow: "auto"
}));

export const ContractsTable: FunctionComponent<ContractsTableProps> = ({
	colorScheme = defaultColorScheme,
	contracts = [],
	selectedContractId,
	style,
	onClickContract,
	onChangeLevel = () => null,
	tableMaxHeight
}) => {
	const mjContracts = useMemo(() => contracts.filter(c => c.major), [contracts]);
	const mnContracts = useMemo(() => contracts.filter(c => !c.major), [contracts]);
	const [levelRaw, setLevel] = useQueryString("level") as levelQS;
	const level = levelRaw === MINORS ? levelRaw : MAJOR;
	const isMajors = level === MAJOR;

	const columns = isMajors ? MAJOR_COLUMNS : MINOR_COLUMNS;
	const rows = (isMajors ? mjContracts : mnContracts) ?? [];

	const handleSetLevel = (level: TLevel) => {
		setLevel(level);
		onChangeLevel();
	};

	return (
		<Card style={style?.card}>
			<ColoredCardHeader
				colorScheme={colorScheme.secondary}
				text={isMajors ? `ML Contracts` : `MiL Contracts`}
				style={style?.cardHeader}
			>
				<div style={{ paddingRight: "8px" }}>
					<Button
						colorScheme={colorScheme.primary}
						onClick={() => handleSetLevel(MAJOR)}
						style={{ marginRight: 5 }}
						title="Majors"
						variant={isMajors ? "contained" : "outlined"}
					/>
					<Button
						colorScheme={colorScheme.primary}
						onClick={() => handleSetLevel(MINORS)}
						title="Minors"
						variant={!isMajors ? "contained" : "outlined"}
					/>
				</div>
			</ColoredCardHeader>
			<TableDiv maxHeight={tableMaxHeight}>
				<AutoFitTable
					columns={columns}
					defaultSortColumn={"Start Year"}
					defaultSortState={"desc"}
					highlightIndexFunc={(rows: Array<TPlayerPageContractsMj | TPlayerPageContractsMn>) =>
						rows.findIndex(
							(row: TPlayerPageContractsMj | TPlayerPageContractsMn) =>
								row.contractId === selectedContractId
						)
					}
					onRowClick={(row: TPlayerPageContractsMj | TPlayerPageContractsMn) =>
						onClickContract(row.contractId)
					}
					rows={rows}
					rowKey="contractId"
				/>
			</TableDiv>
		</Card>
	);
};
