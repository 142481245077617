import React, { FC, useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";

import { REDDARK, BLUEDARK } from "_react/shared/legacy/ui/Colors";

import { checkStatus, checkLoggedIn } from "_react/status/_helpers";

const StatusContainer = styled.div<{ color?: string }>(({ color = REDDARK }) => ({
	padding: "4px",
	backgroundColor: color,
	color: "white",
	textAlign: "center",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	fontWeight: "bold"
}));

const MINISECOND_INTERVAL = 60000; // Every minute
const MINISECOND_FLASH_INTERVAL = 1000; // Every second

export const StatusBanner: FC = () => {
	const checking = useRef(false);
	const [up, setUp] = useState(true);
	const checkingDeep = useRef(false);
	const [upDeep, setUpDeep] = useState(true);
	const checkingLoggedIn = useRef(false);
	const [loggedIn, setLoggedIn] = useState(true);
	const [color, setColor] = useState(REDDARK);

	// Poll API and Logged In Check
	useEffect(() => {
		const interval = setInterval(() => {
			if (checking.current || checkingDeep.current || checkingLoggedIn.current) return;
			checkStatus(checking, checkingDeep, setUp, setUpDeep);
			checkLoggedIn(checkingLoggedIn, setLoggedIn);
		}, MINISECOND_INTERVAL);

		return () => {
			clearInterval(interval);
		};
	}, [checking, checkingDeep, checkingLoggedIn]);

	// Flashing Interval
	useEffect(() => {
		const flashInterval = setInterval(() => {
			if (!up || !upDeep || !loggedIn) setColor(color === REDDARK ? BLUEDARK : REDDARK);
		}, MINISECOND_FLASH_INTERVAL);

		return () => {
			clearInterval(flashInterval);
		};
	}, [color, up, upDeep, loggedIn]);

	// Showing Checking Status
	if ((checking.current || checkingDeep.current || checkingLoggedIn.current) && (!up || !upDeep || !loggedIn))
		return <StatusContainer>Checking Rocky Status...</StatusContainer>;

	// Show Banner if API is down or user's token is invalid
	if (checking.current || checkingDeep.current || checkingLoggedIn.current || (up && upDeep && loggedIn)) return null;
	if (up && upDeep && !loggedIn)
		return (
			<StatusContainer color={color}>Login Expired. Please Refresh the Page and Log in Again.</StatusContainer>
		);
	return <StatusContainer color={color}>Rocky is Disconnected. Please Refresh the Page.</StatusContainer>;
};
