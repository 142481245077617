import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const tables = [
	{
		title: "Stuff Grades",
		columns: ["Grade", "CB/SL", "CH", "Split"],
		rows: [
			["20", "No action, no feel for", "No action, no feel for", "No action, no feel for"],
			[
				"30",
				"No action to pitch, minimal feel for",
				"No action to pitch, minimal feel for",
				"No action to pitch, minimal feel for"
			],
			[
				"40",
				"Inconsistent shape, size, and/or spin. Rarely gets swing and miss.",
				"Inconsistent. Pushed w/ little action may slow arm and/or delivery. Lacks trust.",
				"Inconsistent. Fails to get swing and miss."
			],
			[
				"50",
				"Average shape, size, and/or spin. Some swing and miss and/or poor contact.",
				"Maintains FB arm speed and slot. May have sink, fade, and/or bottom creating some swing and miss and/or poor contact.",
				"Maintains FB arm speed. Some swing and miss or occ poor contact. Avg down action"
			],
			[
				"60",
				"Above average shape, size, and/or spin with power. Creates swing and miss and/or poor contact against.",
				"Maintains FB arm speed and slot. Has sink, fade, and/or bottom creating swing and miss and/or poor contact. Can be effective in any count.",
				"Swing and miss ability. Has power dive or depth."
			],
			[
				"70",
				"Has well above average size, shape, depth and/or spin and power. Typically freezes hitters.",
				"Power sink, fade, and/or bottom creating consistent swing and miss and/or poor contact against both RHH and LHH. Effective in any count.",
				"Forces hitters to commit. Throws to both LH and RH. Has power dive or depth."
			],
			[
				"80",
				"Elite size, shape, depth and/or spin and power. Gets misses. Can use vs LH or RH hitters",
				"Has fade/depth with swing and miss. Can use to LH and RH hitters in any count",
				"Forces hitters to commit. Throws to both LH and RH. Has power dive or depth."
			]
		]
	},
	{
		title: "Curveball Command",
		columns: ["Grade", "Present Command", "Future Command"],
		rows: [
			["20", "Unable to locate or throw for strikes.", "Very wild. Not usable at ML level."],
			["30", "Limited strikes, rare ability to locate.", "Minimal strikes. Rarely to his spot."],
			[
				"40",
				"Inconsistent location but does show feel for glove side.",
				"Below average strikes with inconsistent location."
			],
			[
				"50",
				"Quality location consistently to glove side of plate.",
				"Consistent strikes with inconsistent location."
			],
			[
				"60",
				"Elite location with ability to spot to both sides of plate.",
				"Consistent strikes with good command to glove side."
			],
			["70", "", "Quality strikes with very good ability to command to both sides of plate."],
			["80", "", "Premium strikes with elite ability to command both sides of plate."]
		]
	},
	{
		title: "Slider Command",
		columns: ["Grade", "Present Command", "Future Command"],
		rows: [
			["20", "Unable to locate or throw for strikes.", "Very wild. Not usable at ML level."],
			["30", "Limited strikes, rare ability to locate.", "Minimal strikes. Rarely to his spot."],
			[
				"40",
				"Inconsistent location but does show feel for glove side.",
				"Below average strikes with inconsistent location."
			],
			[
				"50",
				"Quality location consistently to glove side of plate.",
				"Consistent strikes with inconsistent location."
			],
			[
				"60",
				"Elite location with ability to spot to both sides of plate.",
				"Consistent strikes with good command to glove side."
			],
			["70", "", "Quality strikes with very good ability to command to both sides of plate."],
			["80", "", "Premium strikes with elite ability to command both sides of plate."]
		]
	},
	{
		title: "Change-Up Command",
		columns: ["Grade", "Present Command", "Future Command"],
		rows: [
			["20", "Unable to locate or throw for strikes.", "Very wild. Not usable at ML level."],
			["30", "Limited strikes, rare ability to locate.", "Minimal strikes. Rarely to his spot."],
			[
				"40",
				"Inconsistent location but does show feel for glove side.",
				"Below average strikes with inconsistent location."
			],
			[
				"50",
				"Quality location consistently to glove side of plate.",
				"Consistent strikes with inconsistent location."
			],
			[
				"60",
				"Elite location with ability to spot to both sides of plate.",
				"Consistent strikes with good command to glove side."
			],
			["70", "", "Quality strikes with very good ability to command to both sides of plate."],
			["80", "", "Premium strikes with elite ability to command both sides of plate."]
		]
	}
];

const SecondaryPitchesTooltip = () => {
	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<div
					style={{
						overflow: "scroll"
					}}
				>
					{tables.map((table, idx) => {
						return (
							<Table key={idx} style={{ marginTop: "25px" }}>
								<TableHead
									style={{
										backgroundColor: defaultColorScheme.group.backgroundColor
									}}
								>
									<TableRow>
										<TableCell
											colSpan={table.columns.length}
											style={{
												color: defaultColorScheme.group.color,
												fontSize: "16px",
												textAlign: "center"
											}}
										>
											{table.title}
										</TableCell>
									</TableRow>
									{table.description != null && (
										<TableRow>
											<TableCell
												colSpan={table.columns.length}
												style={{
													color: defaultColorScheme.group.color,
													textAlign: "center"
												}}
											>
												{table.description}
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										{table.columns.map((column, idx) => {
											return (
												<TableCell
													key={idx}
													style={{
														color: defaultColorScheme.group.color,
														textAlign: "center",
														textTransform: "capitalize"
													}}
												>
													{column}
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
								<TableBody
									style={{
										border: "1px solid #dfe0df"
									}}
								>
									{table.rows.map((row, idx) => {
										return (
											<TableRow key={idx}>
												{row.map((cell, idx) => {
													return (
														<TableCell
															key={idx}
															style={{
																textAlign: "center",
																fontWeight: idx === 0 ? "700" : ""
															}}
														>
															{cell}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default SecondaryPitchesTooltip;
