import React from "react";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import SurplusValueSection from "_react/playerpage/pro/content/projections/SurplusValueSection";
import ProjectionsSection from "_react/playerpage/pro/content/projections/ProjectionsSection";
import ProModelSection from "_react/playerpage/pro/content/projections/ProModelSection";

type TProjectionsTabProps = {
	player: TPlayerPageCombinedPlayer;
};

const ProjectionsTab = ({ player }: TProjectionsTabProps) => {
	return (
		<>
			<SurplusValueSection player={player} />
			<ProjectionsSection player={player} />
			<ProModelSection player={player} />
		</>
	);
};

export default ProjectionsTab;
