import { IDPLSurvey } from "_react/shared/data_models/dpl/_types";

import {
	IDPLSurveyCleaned,
	TDPLSurveyYear,
	IDPLSurveyCheckbox
} from "_react/playerpage/ama/profile/DPLQuestionnaire/_types";

/*
	Transforms a raw DPL Survey to one with a year property and said year removed from the name
	@param surveys (Array<IDPLSurvey>): Raw DPL surveys
	@return Array<IDPLSurveyCleaned>: Surveys with a year property and transformed name
*/
export const transformAndSortSurveys = (surveys: Array<IDPLSurvey>) =>
	surveys
		?.map((survey: IDPLSurvey) => {
			// The survey doesn't have a year property, have to extract it from the start of the name
			const year = parseInt(survey.name.split(" ")[0]);
			// Remove the year from the name
			return { ...survey, year: year, name: survey.name.replace(`${year} `, "") };
		})
		.sort((a: IDPLSurveyCleaned, b: IDPLSurveyCleaned) => (b.year ?? 0) - (a.year ?? 0));

/*
	Transforms a list of current year surveys into a checkbox representation, with a property indicating if the survey has been completed by the specified prospect
	@param currentYear (number): The current year
	@param currentYearDplSurveys (Array<IDPLSurveyCleaned>): Available DPL surveys for the current year
	@param prospectDplSurveys (Array<IDPLSurveyCleaned>): All DPL surveys completed by the prospect
	@return Array<IDPLSurveyCheckbox>: A list of surveys for the current year, with a completed boolean property
*/
export const transformCurrentYearSurveys = (
	currentYear: number,
	currentYearDplSurveys?: Array<IDPLSurveyCleaned>,
	prospectDplSurveys?: Array<IDPLSurveyCleaned>
) => ({
	year: currentYear,
	surveys:
		currentYearDplSurveys?.map((survey: IDPLSurveyCleaned) => ({
			...survey,
			// Check if the survey exists in the prospects list of completed surveys
			completed: Boolean(
				prospectDplSurveys?.find((prospectSurvey: IDPLSurveyCleaned) => survey.id === prospectSurvey.id)
			)
		})) ?? []
});

/*
	Groups a list of DPL surveys completed by a prospect into year clusters
	@param currentYear (number): The current year
	@param prospectDplSurveys (Array<IDPLSurveyCleaned>): All DPL surveys completed by the prospect
	@return Array<TDPLSurveyYear>: A list of year groups with surveys
*/
export const transformPreviousYearSurveys = (currentYear: number, prospectDplSurveys?: Array<IDPLSurveyCleaned>) =>
	prospectDplSurveys
		?.filter((survey: IDPLSurveyCleaned) => survey.year !== currentYear) // Filter out current year surveys
		.map((survey: IDPLSurveyCleaned) => ({ ...survey, completed: true })) // Mark as completed surveys
		.reduce((years: Array<TDPLSurveyYear>, survey: IDPLSurveyCheckbox) => {
			// Find year this survey belongs to
			const surveyYear = years.find((year: TDPLSurveyYear) => year.year === survey.year);
			if (surveyYear != null) {
				// Add survey to year's surveys
				surveyYear.surveys.push(survey);
			} else {
				// Create survey year
				years.push({ year: survey.year!, surveys: [survey] });
			}
			return years;
		}, []);
