import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Box, CircularProgress, HStack, Switch, Text, Tooltip, VStack, useToast, useToken } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import dayjs from "dayjs";

import { round10 } from "_react/shared/_helpers/numbers";
import { useBreakpointValue, convertRemTokenToPixelInt } from "_react/shared/_helpers/chakra";
import { PLAYING_LEVEL_AMA } from "_react/shared/data_models/arsenal_scores/_constants";
import { IMetricProbabilityDensities } from "_react/shared/data_models/metric/_types";
import { formatRa9, format2080Grade } from "_react/shared/data_models/seasonal_grades/_helpers";
import {
	BATS_OVERALL,
	PITCH_TYPE_OVERALL,
	THRESHOLD_GRADE_STUFF
} from "_react/shared/data_models/arsenal_scores/_constants";
import {
	getAllStuffRa9NormalDistributionsMinMax,
	getThresholdLevel
} from "_react/shared/data_models/arsenal_scores/_helpers";
import { PITCH_GROUP_OVERALL, PITCH_TYPES } from "_react/shared/_constants/pitch_types";
import { getStuffThresholdTooltip } from "_react/shared/_helpers/tooltips";
import { BatterHandednessToggleOptions } from "_react/playerpage/_constants";
import { TPitchTypes } from "_react/shared/_types/pitch_types";

import { getPlayerSeasonArsenalScoresByThrowsFilterStatement } from "_react/shared/data_models/arsenal_scores/_helpers";
import {
	IArsenalScoresParamsApiResponse,
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import { INormalDistributionStep } from "_react/shared/data_models/dataviz/_types";
import { TPlayingLevel } from "_react/shared/data_models/hitter_grades/_types";
import PitchUsageDistributionPlot from "_react/shared/ui/presentation/plots/PitchUsageDistributionPlot/PitchUsageDistributionPlot";
import { ICON_STAR } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import { TToggleOption } from "_react/shared/ui/presentation/components/ToggleButton/_types";
import GradeDistributionPlot from "_react/shared/ui/presentation/plots/GradeDistributionPlot/GradeDistributionPlot";
import PlotStatLabel from "_react/shared/ui/presentation/components/PlotStatLabel/PlotStatLabel";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";

import createArsenalStuffDistributionsMachine, {
	TProbabilityDensities,
	TArsenalStuffDistributionsContext,
	SET_PLAYER_SEASON_ARSENAL_SCORES,
	SET_ARSENAL_SCORES_PARAMS,
	SET_ARSENAL_SCORES_THRESHOLD,
	SET_STUFF_PROBABILITY_DENSITIES,
	SET_PLAYER_ID,
	SET_PLAYING_LEVEL,
	SET_SEASON_FILTER,
	SET_BATS_FILTER,
	SET_THROWS_FILTER,
	SET_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER,
	FETCHING_PLAYER_SEASON_ARSENAL_SCORES,
	FETCHING_ARSENAL_SCORES_PARAMS,
	FETCHING_ARSENAL_SCORES_THRESHOLD,
	FETCHING_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER,
	FETCHING_STUFF_PROBABILITY_DENSITIES
} from "_react/shared/ui/data/plots/ArsenalStuffDistributions/_machine";
import {
	GRADE_DISTRIBUTION_PLOT_PROPS,
	PLOT_STAT_LABEL,
	PITCH_STUFF_PLOT_WIDTH,
	PITCH_STUFF_PLOT_GAP,
	PLOT_TYPE_AVERAGE,
	PLOT_TYPE_PROBABILITY_DENSITY
} from "_react/shared/ui/data/plots/ArsenalStuffDistributions/_constants";
import { PitchTypeLabelContainerStyle } from "_react/shared/ui/data/plots/ArsenalStuffDistributions/_styles";
import {
	TArsenalStuffDistributionsPlayer,
	TDistributionPlotType
} from "_react/shared/ui/data/plots/ArsenalStuffDistributions/_types";
import PitchDistributionPlot from "_react/shared/ui/data/plots/ArsenalStuffDistributions/PitchDistributionPlot";

export type TArsenalStuffDistributionsPrimaryData = {
	playerSeasonArsenalScores?: Array<IPlayerSeasonArsenalScoresSchema> | null;
	arsenalScoresParams?: Array<IArsenalScoresParamsApiResponse> | null;
	isLoading?: boolean;
};

export type TArsenalStuffDistributionsSecondaryData = {
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	arsenalStuffDistributionsPlayer?: TArsenalStuffDistributionsPlayer;
	isLoading?: boolean;
};

export type TArsenalStuffDistributionsTertiaryData = {
	stuffProbabilityDensities?: TProbabilityDensities;
	isLoading?: boolean;
};

type TArsenalStuffDistributionsStyle = {
	container?: React.CSSProperties;
};

type TArsenalStuffDistributionsProps = {
	playerId?: number;
	playingLevel?: TPlayingLevel;
	batsFilter?: string;
	throwsFilter?: string;
	seasonFilter?: number;
	primaryData?: TArsenalStuffDistributionsPrimaryData;
	shouldFetchPrimaryData?: boolean;
	secondaryData?: TArsenalStuffDistributionsSecondaryData;
	shouldFetchSecondaryData?: boolean;
	tertiaryData?: TArsenalStuffDistributionsTertiaryData;
	shouldFetchTertiaryData?: boolean;
	style?: TArsenalStuffDistributionsStyle;
};

const ArsenalStuffDistributions = ({
	playerId,
	playingLevel,
	primaryData,
	shouldFetchPrimaryData = true,
	secondaryData,
	shouldFetchSecondaryData = true,
	tertiaryData,
	shouldFetchTertiaryData = true,
	batsFilter = BATS_OVERALL,
	throwsFilter,
	seasonFilter = dayjs().year(),
	style
}: TArsenalStuffDistributionsProps) => {
	const [selectedPlotType, setSelectedPlotType] = useState<TDistributionPlotType>(PLOT_TYPE_AVERAGE);
	const toast = useToast();
	const [current, send] = useMachine(
		createArsenalStuffDistributionsMachine(
			seasonFilter,
			batsFilter,
			throwsFilter,
			playerId,
			playingLevel,
			shouldFetchPrimaryData,
			primaryData,
			shouldFetchSecondaryData,
			secondaryData,
			shouldFetchTertiaryData,
			tertiaryData,
			toast
		)
	);
	const {
		playerSeasonArsenalScores,
		arsenalScoresParams,
		arsenalScoresThreshold,
		arsenalStuffDistributionsPlayer,
		stuffProbabilityDensities
	} = current.context as TArsenalStuffDistributionsContext;

	const fetchingPlayerSeasonArsenalScores = current.matches(FETCHING_PLAYER_SEASON_ARSENAL_SCORES);
	const fetchingArsenalScoresParams = current.matches(FETCHING_ARSENAL_SCORES_PARAMS);
	const fetchingArsenalScoresThreshold = current.matches(FETCHING_ARSENAL_SCORES_THRESHOLD);
	const fetchingArsenalStuffDistributionsPlayer = current.matches(FETCHING_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER);
	const fetchingStuffProbabilityDensities: boolean = current.matches(FETCHING_STUFF_PROBABILITY_DENSITIES);

	const isLoadingPrimary = shouldFetchPrimaryData
		? fetchingPlayerSeasonArsenalScores || fetchingArsenalScoresParams
		: primaryData?.isLoading;

	const isLoadingSecondary = shouldFetchSecondaryData
		? (playingLevel === PLAYING_LEVEL_AMA && fetchingArsenalScoresThreshold) ||
		  fetchingArsenalStuffDistributionsPlayer
		: secondaryData?.isLoading;

	const isLoadingTertiary = shouldFetchTertiaryData
		? playingLevel === PLAYING_LEVEL_AMA && fetchingStuffProbabilityDensities
		: tertiaryData?.isLoading;

	const isLoadingPrimaryOrSecondary = isLoadingPrimary || isLoadingSecondary;

	// Update machine context when playerId changes
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	// Update machine context when playingLevel changes
	useEffect(() => {
		send({ type: SET_PLAYING_LEVEL, data: playingLevel });
	}, [playingLevel, send]);

	// Update machine context when season changes
	useEffect(() => {
		send({ type: SET_SEASON_FILTER, data: seasonFilter });
	}, [seasonFilter, send]);

	// Update machine context when bats filter changes
	useEffect(() => {
		send({ type: SET_BATS_FILTER, data: batsFilter });
	}, [batsFilter, send]);

	// Update machine context when throws filter changes
	useEffect(() => {
		send({ type: SET_THROWS_FILTER, data: throwsFilter });
	}, [throwsFilter, send]);

	// Update machine context when data prop changes
	useEffect(() => {
		if (primaryData?.playerSeasonArsenalScores !== playerSeasonArsenalScores && !shouldFetchPrimaryData) {
			send({ type: SET_PLAYER_SEASON_ARSENAL_SCORES, data: primaryData?.playerSeasonArsenalScores });
		}
	}, [primaryData?.playerSeasonArsenalScores, playerSeasonArsenalScores, shouldFetchPrimaryData, send]);
	useEffect(() => {
		if (primaryData?.arsenalScoresParams !== arsenalScoresParams && !shouldFetchPrimaryData) {
			send({ type: SET_ARSENAL_SCORES_PARAMS, data: primaryData?.arsenalScoresParams });
		}
	}, [primaryData?.arsenalScoresParams, arsenalScoresParams, shouldFetchPrimaryData, send]);
	useEffect(() => {
		if (secondaryData?.arsenalScoresThreshold !== arsenalScoresThreshold && !shouldFetchSecondaryData) {
			send({ type: SET_ARSENAL_SCORES_THRESHOLD, data: secondaryData?.arsenalScoresThreshold });
		}
	}, [secondaryData?.arsenalScoresThreshold, arsenalScoresThreshold, shouldFetchSecondaryData, send]);
	useEffect(() => {
		if (
			secondaryData?.arsenalStuffDistributionsPlayer !== arsenalStuffDistributionsPlayer &&
			!shouldFetchSecondaryData
		) {
			send({
				type: SET_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER,
				data: secondaryData?.arsenalStuffDistributionsPlayer
			});
		}
	}, [
		secondaryData?.arsenalStuffDistributionsPlayer,
		arsenalStuffDistributionsPlayer,
		shouldFetchSecondaryData,
		send
	]);
	useEffect(() => {
		if (tertiaryData?.stuffProbabilityDensities !== stuffProbabilityDensities && !shouldFetchTertiaryData) {
			send({ type: SET_STUFF_PROBABILITY_DENSITIES, data: tertiaryData?.stuffProbabilityDensities ?? {} });
		}
	}, [tertiaryData?.stuffProbabilityDensities, stuffProbabilityDensities, shouldFetchTertiaryData, send]);

	// `GradeDistributionPlot` needs Chakra tokens converted to pixel values
	const [size8, size12, size32, size44, size72, sizePitchStuffPlotWidth, sizePitchStuffPlotGap] = useToken("sizes", [
		8,
		12,
		32,
		44,
		72,
		PITCH_STUFF_PLOT_WIDTH,
		PITCH_STUFF_PLOT_GAP
	]);

	// Do not allow wrap of PitchDistributionPlot to single column on larger screens
	const plotContainerMinWidth = useBreakpointValue({
		base: convertRemTokenToPixelInt(sizePitchStuffPlotWidth) + convertRemTokenToPixelInt(sizePitchStuffPlotGap),
		lg: (convertRemTokenToPixelInt(sizePitchStuffPlotWidth) + convertRemTokenToPixelInt(sizePitchStuffPlotGap)) * 2
	});

	// Threshold is only fetched (and will only display) when playingLevel = AMA
	const stuffThreshold = arsenalScoresThreshold?.find(
		(threshold: IArsenalScoresThresholdApiResponse) =>
			threshold.grade === THRESHOLD_GRADE_STUFF && threshold.pitchTypeGrouping === null
	)?.value;

	// Filter arsenal scores
	const arsenalScoreOverall = useMemo(() => {
		return playerSeasonArsenalScores?.find(
			(scores: IPlayerSeasonArsenalScoresSchema) =>
				scores.pitchType === PITCH_TYPE_OVERALL &&
				scores.bats === batsFilter &&
				getPlayerSeasonArsenalScoresByThrowsFilterStatement(
					scores,
					throwsFilter,
					arsenalStuffDistributionsPlayer
				)
		);
	}, [playerSeasonArsenalScores, batsFilter, throwsFilter, arsenalStuffDistributionsPlayer]);

	const getArsenalScoresPitchTypeSplits = useCallback(
		(pitchType: string) => {
			return playerSeasonArsenalScores
				?.filter(
					(scores: IPlayerSeasonArsenalScoresSchema) =>
						scores.pitchType === pitchType &&
						scores.bats !== BATS_OVERALL &&
						getPlayerSeasonArsenalScoresByThrowsFilterStatement(
							scores,
							throwsFilter,
							arsenalStuffDistributionsPlayer
						)
				)
				.sort((a: IPlayerSeasonArsenalScoresSchema, b: IPlayerSeasonArsenalScoresSchema) =>
					a.bats.localeCompare(b.bats)
				);
		},
		[playerSeasonArsenalScores, throwsFilter, arsenalStuffDistributionsPlayer]
	);

	const arsenalScoresOverallSplits = useMemo(() => {
		return getArsenalScoresPitchTypeSplits(PITCH_TYPE_OVERALL);
	}, [getArsenalScoresPitchTypeSplits]);

	const arsenalScoresPitchTypes = useMemo(() => {
		return playerSeasonArsenalScores
			?.filter(
				(scores: IPlayerSeasonArsenalScoresSchema) =>
					scores.pitchType !== PITCH_TYPE_OVERALL &&
					scores.bats === batsFilter &&
					getPlayerSeasonArsenalScoresByThrowsFilterStatement(
						scores,
						throwsFilter,
						arsenalStuffDistributionsPlayer
					)
			)
			?.sort((a: IPlayerSeasonArsenalScoresSchema, b: IPlayerSeasonArsenalScoresSchema) => {
				if (stuffThreshold) {
					// If a is below the display threshold and b is above it, but b first
					if ((!a.total || a.total <= stuffThreshold / 2) && b.total && b.total > stuffThreshold / 2)
						return 1;
					// If b is below the display threshold and a is above it, but a first
					if ((!b.total || b.total <= stuffThreshold / 2) && a.total && a.total > stuffThreshold / 2)
						return -1;
				}
				// Otherwise just sort on stuff RA9
				return (a.stuffRa9 ?? 0) - (b.stuffRa9 ?? 0);
			});
	}, [playerSeasonArsenalScores, batsFilter, throwsFilter, arsenalStuffDistributionsPlayer, stuffThreshold]);
	const arsenalScoresScoredPitchTypes = useMemo(() => {
		return arsenalScoresPitchTypes?.filter(
			(scores: IPlayerSeasonArsenalScoresSchema) => scores.lkPitchType?.isScored === 1
		);
	}, [arsenalScoresPitchTypes]);
	const arsenalScoresPitchTypesHalfLength = arsenalScoresScoredPitchTypes
		? Math.ceil(arsenalScoresScoredPitchTypes.length / 2)
		: 0;

	const bestPitchType = arsenalScoresScoredPitchTypes?.length
		? arsenalScoresScoredPitchTypes[0].pitchType
		: undefined;

	const allStuffRa9NormalDistributionsMinMax = getAllStuffRa9NormalDistributionsMinMax(arsenalScoresParams);

	// Filter stuff probability densities
	const stuffProbabilityDensitiesSelected = useMemo(() => {
		if (stuffProbabilityDensities.hasOwnProperty(`${batsFilter}-${throwsFilter}`)) {
			return stuffProbabilityDensities[`${batsFilter}-${throwsFilter}`];
		}
		return undefined;
	}, [stuffProbabilityDensities, batsFilter, throwsFilter]);

	const stuffProbabilityDensitiesOverall = useMemo(() => {
		return stuffProbabilityDensitiesSelected?.find(
			(densities: IMetricProbabilityDensities) => densities.requestArgs?.pitchType === null
		);
	}, [stuffProbabilityDensitiesSelected]);

	// Threshold is only fetched (and will only display) when playingLevel = AMA
	const stuffThresholdIconShape = getThresholdLevel(arsenalScoreOverall?.total, stuffThreshold);
	const stuffThresholdIconTooltip = getStuffThresholdTooltip(arsenalScoreOverall?.total, stuffThreshold);

	const batsFilterLabel = BatterHandednessToggleOptions.find(
		(option: TToggleOption<string>) => option.value === batsFilter
	)?.label;

	return (
		<>
			{isLoadingPrimaryOrSecondary && (
				<Box className="loading-item" height="md" width="100%" sx={style?.container} />
			)}
			{!isLoadingPrimaryOrSecondary && (
				<HStack flexWrap="wrap" alignItems="start" gap="6">
					<VStack gap="7">
						<VStack>
							<HStack sx={PitchTypeLabelContainerStyle}>
								<HStack>
									<PitchTypeLabel
										label={`Overall ${batsFilter !== BATS_OVERALL ? batsFilterLabel : ""}`}
										abbreviation={PITCH_GROUP_OVERALL}
										shape={ICON_STAR}
										thresholdIcon={
											stuffThresholdIconShape
												? {
														shape: stuffThresholdIconShape,
														tooltipLabel: stuffThresholdIconTooltip
												  }
												: undefined
										}
										isBold
									/>
									{arsenalScoresOverallSplits && batsFilter === BATS_OVERALL && (
										<PitchUsageDistributionPlot<IPlayerSeasonArsenalScoresSchema>
											pitchData={arsenalScoresOverallSplits}
											xValue="bats"
											yValue="total"
											height={convertRemTokenToPixelInt(size8)}
											width={convertRemTokenToPixelInt(size12)}
											orientation="horizontal"
										/>
									)}
								</HStack>

								{//Only show this component for AMA players
								playingLevel === PLAYING_LEVEL_AMA && (
									<HStack gap="1">
										<Tooltip
											hasArrow
											placement="top"
											label="Show the probability density of the player's stuff scores"
										>
											<Text fontSize="xs">Prob. Density:</Text>
										</Tooltip>
										{// Show Circular Progress if the data is loading
										isLoadingTertiary && (
											<CircularProgress isIndeterminate size="4" color="gray.400" />
										)}
										{// Show the Switch if the data is not loading
										!isLoadingTertiary && (
											<Switch
												isChecked={selectedPlotType === PLOT_TYPE_PROBABILITY_DENSITY}
												onChange={() => {
													setSelectedPlotType(
														selectedPlotType === PLOT_TYPE_PROBABILITY_DENSITY
															? PLOT_TYPE_AVERAGE
															: PLOT_TYPE_PROBABILITY_DENSITY
													);
												}}
												size="sm"
												colorScheme="gray"
												// Disable when we don't have any data
												disabled={
													!stuffProbabilityDensitiesOverall?.probabilityDensities?.length
												}
											></Switch>
										)}
									</HStack>
								)}
							</HStack>
							<HStack alignItems="start" marginBottom="7">
								<PlotStatLabel
									label={PLOT_STAT_LABEL}
									value={formatRa9(arsenalScoreOverall?.stuffRa9)}
									secondaryValue={format2080Grade(arsenalScoreOverall?.stuffGrade)}
								/>
								<GradeDistributionPlot<INormalDistributionStep>
									{...GRADE_DISTRIBUTION_PLOT_PROPS}
									distributionData={
										arsenalScoresParams?.find(
											(params: IArsenalScoresParamsApiResponse) =>
												params.pitchTypeGrouping === PITCH_GROUP_OVERALL
										)?.stuffRa9NormalDistribution ?? undefined
									}
									playerValue={
										selectedPlotType === PLOT_TYPE_AVERAGE
											? arsenalScoreOverall?.stuffRa9
											: undefined
									}
									playerDistributionData={
										selectedPlotType === PLOT_TYPE_PROBABILITY_DENSITY
											? stuffProbabilityDensitiesOverall?.probabilityDensities
											: undefined
									}
									width={convertRemTokenToPixelInt(size72)}
									height={convertRemTokenToPixelInt(size32)}
									xAxis={{
										extrema: {
											min: allStuffRa9NormalDistributionsMinMax?.min,
											max: allStuffRa9NormalDistributionsMinMax?.max,
											isOverrideDistributionExtrema: true
										},
										isXAxisReverse: true,
										tickFormat: (value: number) => round10(value, -2)
									}}
								/>
							</HStack>
						</VStack>
						<VStack width="100%" alignItems="start">
							<Text fontWeight="bold" fontSize="md">
								Usage
							</Text>
							<Box width="100%">
								<PitchUsageDistributionPlot<IPlayerSeasonArsenalScoresSchema>
									highlightPitchTypes={
										bestPitchType && (PITCH_TYPES as Array<string>)?.includes(bestPitchType)
											? [bestPitchType as TPitchTypes]
											: undefined
									}
									pitchData={arsenalScoresPitchTypes}
									xValue="pitchType"
									yValue="total"
									sortByYValue
									height={convertRemTokenToPixelInt(size44)}
								/>
							</Box>
						</VStack>
					</VStack>
					<HStack
						flex="1 1 0"
						flexWrap="wrap"
						alignItems="start"
						gap={PITCH_STUFF_PLOT_GAP}
						width="100%"
						// Set max width to be half of the pitch types, so that we always have two rows
						maxWidth={
							(convertRemTokenToPixelInt(sizePitchStuffPlotWidth) +
								convertRemTokenToPixelInt(sizePitchStuffPlotGap)) *
							arsenalScoresPitchTypesHalfLength
						}
						// Set min width to stop wrapping of PitchDistributionPlot to single column on larger screens
						minWidth={plotContainerMinWidth}
					>
						{arsenalScoresScoredPitchTypes?.map((scores: IPlayerSeasonArsenalScoresSchema) => {
							const stuffProbabilityDensitiesPitchType = stuffProbabilityDensitiesSelected?.find(
								(densities: IMetricProbabilityDensities) =>
									densities.requestArgs?.pitchType === scores.pitchType
							);
							return (
								<PitchDistributionPlot
									key={scores.pitchType}
									playerSeasonArsenalScoresPitchType={scores}
									playerSeasonArsenalScoresSplitsPitchType={getArsenalScoresPitchTypeSplits(
										scores.pitchType
									)}
									showProbabilityDensities={batsFilter === BATS_OVERALL}
									arsenalScoresParams={arsenalScoresParams}
									stuffProbabilityDensitiesPitchType={stuffProbabilityDensitiesPitchType}
									threshold={stuffThreshold}
									plotType={selectedPlotType}
									style={{ container: { width: PITCH_STUFF_PLOT_WIDTH } }}
								/>
							);
						})}
					</HStack>
				</HStack>
			)}
		</>
	);
};

export default ArsenalStuffDistributions;
