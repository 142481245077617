export { default as LkSelect } from "_react/inputs/lks/LkSelect";

export type TLkValueConstraint = string | number;

export type TLkFilter<T = TLkValueConstraint> = {
	key: string;
	value: Array<T>;
};

export type TLk<T> = {
	value: T;
	label: string;
	abbreviation?: string;
	sortOrder?: number;
	color?: string;
	country?: string;
};

export type TLkExtended<T, V> = TLk<T> & V;

export function isLkKey<T>(key: string, lkItem: TLk<T>): key is keyof TLk<T> {
	return lkItem.hasOwnProperty(key);
}

export function filterLkItems<T extends TLkValueConstraint>(
	lkItems: Array<TLk<T>>,
	lkFilters: Array<TLkFilter<TLkValueConstraint>>
) {
	if (!lkFilters.length) {
		return lkItems;
	}
	const filteredItems = lkItems.filter(lkItem => {
		for (const { key, value } of lkFilters) {
			if (!value) return false;
			if (isLkKey<T>(key, lkItem)) {
				const lkValue = lkItem[key];
				if (lkValue !== undefined && !value.includes(lkValue)) return false;
			}
		}
		return true;
	});
	return filteredItems;
}
