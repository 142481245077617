import React, { CSSProperties } from "react";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { getCurrency } from "utils/helpers";
import { formatAddendumBDateRange } from "_react/playerpage/contract/_helpers";
import { IAddendumBForeignInquiry } from "_react/shared/_types/schema/contracts";

const FOREIGN_INQUIRY_COLUMNS = [
	{
		title: "Country",
		name: "country",
		getCellValue: (row: IAddendumBForeignInquiry) => row.country
	},
	{
		title: "Inquiry Date",
		name: "inquiry_date",
		getCellValue: (row: IAddendumBForeignInquiry) =>
			formatAddendumBDateRange(row.date_operator_lookup, row.inquiry_1_date, row.inquiry_2_date)
	},
	{
		title: "Acquisition Amount",
		name: "acquisition_amount",
		getCellValue: (row: IAddendumBForeignInquiry) =>
			[row.amount_operator_lookup, getCurrency(row.acquisition_1_amount)].filter(Boolean).join(" ")
	},
	{
		title: "ML Club Decision Deadline",
		name: "ml_club_decision_duration",
		getCellValue: (row: IAddendumBForeignInquiry) =>
			[row.ml_club_decision_duration, row.ml_club_decision_duration_type].filter(Boolean).join(" ")
	},
	{
		title: "Roster Type Requirement",
		name: "roster_type_description",
		getCellValue: (row: IAddendumBForeignInquiry) => row.roster_type_description
	}
];

type TAddendumBForeignInquiryTableStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

interface IProps {
	addendumBForeignInquiries: IAddendumBForeignInquiry[];
	colorSchemeGroup: ColorSchemeGroup;
	style?: TAddendumBForeignInquiryTableStyle;
}

function AddendumBForeignInquiryTable({ addendumBForeignInquiries, colorSchemeGroup, style }: IProps) {
	return (
		<React.Fragment>
			<Card style={style?.card}>
				<ColoredCardHeader
					colorScheme={colorSchemeGroup.secondary}
					text={"Addendum B Foreign Team Inquiry Language"}
					style={style?.cardHeader}
				/>
				<AutoFitTable
					columns={FOREIGN_INQUIRY_COLUMNS}
					defaultSortColumn={"Country"}
					defaultSortState={"desc"}
					isHighlightOnHover={false}
					rows={addendumBForeignInquiries}
					rowKey="addendum_b_id"
				/>
			</Card>
		</React.Fragment>
	);
}

export default AddendumBForeignInquiryTable;
