import React from "react";
import styled from "@emotion/styled";

import { useDraftDate } from "_react/_hooks";
import { SquareBadge } from "_react/shared/legacy/ui/SquareBadge";
import { BLUE, ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import CheckBox from "_react/shared/legacy/ui/icons/CheckBox";
import CheckBoxOutlineBlank from "_react/shared/legacy/ui/icons/CheckBoxOutlineBlank";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import SurveyButton from "_react/dpl/SurveyButton";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { getAmaAvgGroup } from "_react/playerpage/shared_js/evals/_helpers";
import { BadgeRow } from "_react/playerpage/header/badges";
import { getHasEligibleYearMakeupNote } from "_react/playerpage/_helpers";

type TAmaBadgesProps = {
	colorSchemeGroup: ColorSchemeGroup;
	evaluations?: $TSFixMeEval[] | null;
	player: TPlayerPageCombinedPlayer;
};

const ICON_STYLE = { height: "35px", width: "35px" };
const DPL_BADGE_STYLE = { height: "131px", width: "85px" };
const ContainerDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	marginRight: "8px"
});
const InnerDiv = styled.div({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "space-evenly",
	height: "100%"
});

function getCheckbox(checked: boolean) {
	return checked ? (
		<CheckBox fill={BLUE} style={ICON_STYLE} />
	) : (
		<CheckBoxOutlineBlank fill={BLUE} style={ICON_STYLE} />
	);
}

export default function AmaBadges({ colorSchemeGroup, evaluations, player }: TAmaBadgesProps) {
	const currentDraftDate = useDraftDate();

	return (
		<ContainerDiv>
			<div>
				<BadgeRow>
					<SquareBadge colorSchemeGroup={colorSchemeGroup} label={"Position"} content={player.position} />
					<SquareBadge
						colorSchemeGroup={colorSchemeGroup}
						label={"Group"}
						loading={evaluations == null}
						content={getAmaAvgGroup(evaluations, currentDraftDate)}
					/>
					<SquareBadge
						colorSchemeGroup={colorSchemeGroup}
						data-cy={"dpl-profile-badge"}
						label={"DPL Profile"}
						content={getCheckbox(Boolean(player.dplId))}
					/>
				</BadgeRow>
				<BadgeRow>
					<SquareBadge
						colorSchemeGroup={colorSchemeGroup}
						label={"Bats/Throws"}
						content={`${player.bats}/${player.throws}`}
					/>
					<SquareBadge
						colorSchemeGroup={colorSchemeGroup}
						label={"Reports"}
						loading={evaluations == null}
						content={evaluations?.length}
					/>
					<SquareBadge
						colorSchemeGroup={colorSchemeGroup}
						data-cy={"makeup-notes-badge"}
						label={"Makeup Notes"}
						content={getCheckbox(getHasEligibleYearMakeupNote(player))}
					/>
				</BadgeRow>
			</div>
			<SquareBadge
				colorSchemeGroup={colorSchemeGroup}
				data-cy={"phillies-survey-badge"}
				label={"DPL Q's"}
				content={
					<InnerDiv>
						<SurveyButton height={"40px"} width={"30px"} philId={player?.playerAmaId} sType={"general"} />
						<SurveyButton height={"40px"} width={"30px"} philId={player?.playerAmaId} sType={"phillies"} />
						<SurveyButton height={"40px"} width={"30px"} philId={player?.playerAmaId} sType={"rp"} />
						<SurveyButton height={"40px"} width={"30px"} philId={player?.playerAmaId} sType={"medical"} />
						<SurveyButton height={"40px"} width={"30px"} philId={player?.playerAmaId} sType={"update"} />
						<SurveyButton height={"40px"} width={"30px"} philId={player?.playerAmaId} sType={"all"} />
					</InnerDiv>
				}
				style={DPL_BADGE_STYLE}
			/>
		</ContainerDiv>
	);
}
