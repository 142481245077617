import React, { useEffect, useMemo } from "react";
import { Box, HStack, useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import dayjs from "dayjs";

import {
	ISwingMetricsReferenceApiResponse,
	ISwingMetricsInferredReferenceApiResponse
} from "_react/shared/data_models/seasonal_grades/_types";
import {
	PLAYING_LEVEL_AMA,
	PLAYING_LEVEL_PRO,
	THROWS_OVERALL,
	THRESHOLD_ATTACK_ANGLE,
	THRESHOLD_BAT_SPEED,
	THRESHOLD_BAT_TO_BALL,
	THRESHOLD_SWING_DECISION,
	BATS_OVERALL,
	GAME_TYPE_OVERALL
} from "_react/shared/data_models/hitter_grades/_constants";
import {
	format2080Grade,
	convertPlayerBatsToSeasonalBats,
	getThreeGradeThresholdLevel,
	getThresholdValue
} from "_react/shared/data_models/hitter_grades/_helpers";
import {
	IPlayerSeasonHitterGrades,
	IThreeGradesThresholdApiResponse,
	TPlayingLevel
} from "_react/shared/data_models/hitter_grades/_types";
import { ITwentyEightyGradeDistribution } from "_react/shared/data_models/dataviz/_types";
import {
	TOOLTIP_ATTACK_ANGLE_INFERRED,
	TOOLTIP_ATTACK_ANGLE_MEASURED,
	TOOLTIP_BAT_SPEED_INFERRED,
	TOOLTIP_BAT_SPEED_MEASURED,
	TOOLTIP_BAT_TO_BALL,
	TOOLTIP_SWING_DECISIONS,
	TOOLTIP_SPRINT_SPEED
} from "_react/shared/_constants/tooltips";
import Warning from "_react/shared/ui/icons/Warning";

import createPositionStatDistributionsMachine, {
	TPositionStatDistributionsContext,
	SET_PLAYER_ID,
	SET_SEASON_FILTER,
	SET_PLAYER_SEASON_HITTER_GRADES,
	SET_SWING_METRICS_INFERRED_REFERENCE,
	SET_SWING_METRICS_REFERENCE,
	SET_THREE_GRADES_THRESHOLD,
	SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION,
	SET_POSITION_STAT_DISTRIBUTIONS_PLAYER,
	FETCHING_PLAYER_SEASON_HITTER_GRADES,
	FETCHING_SWING_METRICS_INFERRED_REFERENCE,
	FETCHING_SWING_METRICS_REFERENCE,
	FETCHING_TWENTY_EIGHTY_GRADE_DISTRIBUTION,
	FETCHING_THREE_GRADES_THRESHOLD,
	FETCHING_POSITION_STAT_DISTRIBUTIONS_PLAYER,
	SET_PLAYING_LEVEL
} from "_react/shared/ui/data/plots/PositionStatDistributions/_machine";
import { TPositionStatDistributionsPlayer } from "_react/shared/ui/data/plots/PositionStatDistributions/_types";
import PositionStatDistributionPlot from "_react/shared/ui/data/plots/PositionStatDistributions/PositionStatDistributionPlot";
import {
	getAttackAngleThresholdTooltip,
	getBatSpeedThresholdTooltip,
	getBatToBallThresholdTooltip,
	getSwingDecisionThresholdTooltip
} from "_react/shared/_helpers/tooltips";

export type TPositionStatDistributionsData = {
	playerSeasonHitterGrades?: Array<IPlayerSeasonHitterGrades> | null;
	twentyEightyGradeDistribution?: Array<ITwentyEightyGradeDistribution> | null;
	swingMetricsInferredReference?: ISwingMetricsInferredReferenceApiResponse | null;
	swingMetricsReference?: ISwingMetricsReferenceApiResponse | null;
	threeGradesThreshold?: Array<IThreeGradesThresholdApiResponse> | null;
	positionStatDistributionsPlayer?: TPositionStatDistributionsPlayer;
	isLoading?: boolean;
};

type TPositionStatDistributionsStyle = {
	container?: React.CSSProperties;
};

type TPositionStatDistributionsProps = {
	playerId?: number;
	playingLevel?: TPlayingLevel;
	data?: TPositionStatDistributionsData;
	throwsFilter?: string;
	seasonFilter?: number;
	shouldFetchData?: boolean;
	style?: TPositionStatDistributionsStyle;
};

const PositionStatDistributions = ({
	playerId,
	playingLevel,
	data,
	shouldFetchData = true,
	throwsFilter = THROWS_OVERALL,
	seasonFilter = dayjs().year(),
	style
}: TPositionStatDistributionsProps) => {
	const toast = useToast();
	const [current, send] = useMachine(
		createPositionStatDistributionsMachine(seasonFilter, playerId, playingLevel, shouldFetchData, data, toast)
	);
	const {
		playerSeasonHitterGrades,
		twentyEightyGradeDistribution,
		swingMetricsInferredReference,
		swingMetricsReference,
		threeGradesThreshold,
		positionStatDistributionsPlayer
	} = current.context as TPositionStatDistributionsContext;
	const fetchingPlayerSeasonHitterGrades = current.matches(FETCHING_PLAYER_SEASON_HITTER_GRADES);
	const fetchingTwentyEightyGradeDistribution = current.matches(FETCHING_TWENTY_EIGHTY_GRADE_DISTRIBUTION);
	const fetchingSwingMetricsInferredReference = current.matches(FETCHING_SWING_METRICS_INFERRED_REFERENCE);
	const fetchingSwingMetricsReference = current.matches(FETCHING_SWING_METRICS_REFERENCE);
	const fetchingThreeGradesThreshold = current.matches(FETCHING_THREE_GRADES_THRESHOLD);
	const fetchingPositionStatDistributionsPlayer = current.matches(FETCHING_POSITION_STAT_DISTRIBUTIONS_PLAYER);

	const isLoading = shouldFetchData
		? fetchingPlayerSeasonHitterGrades ||
		  fetchingTwentyEightyGradeDistribution ||
		  fetchingSwingMetricsInferredReference ||
		  fetchingSwingMetricsReference ||
		  fetchingPositionStatDistributionsPlayer ||
		  (playingLevel === PLAYING_LEVEL_AMA && fetchingThreeGradesThreshold)
		: data?.isLoading;

	// Update machine context when playerId changes
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	// Update machine context when playingLevel changes
	useEffect(() => {
		send({ type: SET_PLAYING_LEVEL, data: playingLevel });
	}, [playingLevel, send]);

	// Update machine context when season filter changes
	useEffect(() => {
		send({ type: SET_SEASON_FILTER, data: seasonFilter });
	}, [seasonFilter, send]);

	// Update machine context when data prop changes
	useEffect(() => {
		if (data?.playerSeasonHitterGrades !== playerSeasonHitterGrades && !shouldFetchData) {
			send({ type: SET_PLAYER_SEASON_HITTER_GRADES, data: data?.playerSeasonHitterGrades });
		}
	}, [data?.playerSeasonHitterGrades, playerSeasonHitterGrades, shouldFetchData, send]);
	useEffect(() => {
		if (data?.twentyEightyGradeDistribution !== twentyEightyGradeDistribution && !shouldFetchData) {
			send({ type: SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION, data: data?.twentyEightyGradeDistribution });
		}
	}, [data?.twentyEightyGradeDistribution, twentyEightyGradeDistribution, shouldFetchData, send]);
	useEffect(() => {
		if (data?.swingMetricsInferredReference !== swingMetricsInferredReference && !shouldFetchData) {
			send({ type: SET_SWING_METRICS_INFERRED_REFERENCE, data: data?.swingMetricsInferredReference });
		}
	}, [data?.swingMetricsInferredReference, swingMetricsInferredReference, shouldFetchData, send]);
	useEffect(() => {
		if (data?.swingMetricsReference !== swingMetricsReference && !shouldFetchData) {
			send({ type: SET_SWING_METRICS_REFERENCE, data: data?.swingMetricsReference });
		}
	}, [data?.swingMetricsReference, swingMetricsReference, shouldFetchData, send]);
	useEffect(() => {
		if (data?.threeGradesThreshold !== threeGradesThreshold && !shouldFetchData) {
			send({ type: SET_THREE_GRADES_THRESHOLD, data: data?.threeGradesThreshold });
		}
	}, [data?.threeGradesThreshold, threeGradesThreshold, shouldFetchData, send]);
	useEffect(() => {
		if (data?.positionStatDistributionsPlayer !== positionStatDistributionsPlayer && !shouldFetchData) {
			send({ type: SET_POSITION_STAT_DISTRIBUTIONS_PLAYER, data: data?.positionStatDistributionsPlayer });
		}
	}, [data?.positionStatDistributionsPlayer, positionStatDistributionsPlayer, shouldFetchData, send]);

	// Filter three grades
	const playerSeasonHitterGradesSelected: IPlayerSeasonHitterGrades | undefined = useMemo(() => {
		const playerSeasonHitterGradesFiltered = playerSeasonHitterGrades?.filter(
			(scores: IPlayerSeasonHitterGrades) =>
				scores.throws === throwsFilter &&
				(positionStatDistributionsPlayer === undefined ||
					scores.bats === convertPlayerBatsToSeasonalBats(positionStatDistributionsPlayer.bats))
		);
		return playerSeasonHitterGradesFiltered?.length ? playerSeasonHitterGradesFiltered[0] : undefined;
	}, [playerSeasonHitterGrades, throwsFilter, positionStatDistributionsPlayer]);

	// Three grade threshold icons and tooltips
	const attackAngleThreshold = getThresholdValue(THRESHOLD_ATTACK_ANGLE, threeGradesThreshold);
	const attackAngleThresholdIconShape = getThreeGradeThresholdLevel(
		playerSeasonHitterGradesSelected?.bips,
		attackAngleThreshold
	);
	const attackAngleThresholdTooltip = getAttackAngleThresholdTooltip(
		playerSeasonHitterGradesSelected?.bips,
		attackAngleThreshold
	);
	const batSpeedThreshold = getThresholdValue(THRESHOLD_BAT_SPEED, threeGradesThreshold);
	const batSpeedThresholdIconShape = getThreeGradeThresholdLevel(
		playerSeasonHitterGradesSelected?.bips,
		batSpeedThreshold
	);
	const batSpeedThresholdTooltip = getBatSpeedThresholdTooltip(
		playerSeasonHitterGradesSelected?.bips,
		batSpeedThreshold
	);
	const batToBallThreshold = getThresholdValue(THRESHOLD_BAT_TO_BALL, threeGradesThreshold);
	const batToBallThresholdIconShape = getThreeGradeThresholdLevel(
		playerSeasonHitterGradesSelected?.swings,
		batToBallThreshold
	);
	const batToBallThresholdTooltip = getBatToBallThresholdTooltip(
		playerSeasonHitterGradesSelected?.swings,
		batToBallThreshold
	);
	const swingDecisionThreshold = getThresholdValue(THRESHOLD_SWING_DECISION, threeGradesThreshold);
	const swingDecisionThresholdIconShape = getThreeGradeThresholdLevel(
		playerSeasonHitterGradesSelected?.pitches,
		swingDecisionThreshold
	);
	const swingDecisionThresholdTooltip = getSwingDecisionThresholdTooltip(
		playerSeasonHitterGradesSelected?.pitches,
		swingDecisionThreshold
	);

	// Get the spring speed from the overall hitter grades row
	const sprintSpeedGrade = useMemo(() => {
		return playerSeasonHitterGrades?.find(
			(grade: IPlayerSeasonHitterGrades) =>
				grade.playingLevel === PLAYING_LEVEL_PRO &&
				grade.throws === THROWS_OVERALL &&
				grade.bats === BATS_OVERALL &&
				grade.gameType === GAME_TYPE_OVERALL
		)?.sprintSpeedGrade;
	}, [playerSeasonHitterGrades]);

	return (
		<>
			{isLoading && <Box className="loading-item" height={40} width="100%" sx={style?.container} />}
			{!isLoading && (
				<HStack flexWrap="wrap" alignItems="start" gap="4" width="100%" sx={style?.container}>
					<PositionStatDistributionPlot
						plotLabel="Bat Speed"
						value={
							playerSeasonHitterGradesSelected?.batSpeedGrade
								? parseInt(format2080Grade(playerSeasonHitterGradesSelected.batSpeedGrade))
								: undefined
						}
						distributionData={twentyEightyGradeDistribution}
						thresholdIcon={
							batSpeedThresholdIconShape
								? { shape: batSpeedThresholdIconShape, tooltipLabel: batSpeedThresholdTooltip }
								: undefined
						}
						tooltipLabel={
							playingLevel === PLAYING_LEVEL_PRO &&
							playerSeasonHitterGradesSelected?.isMeasuredSwingMetrics
								? TOOLTIP_BAT_SPEED_MEASURED
								: TOOLTIP_BAT_SPEED_INFERRED
						}
						tooltipIcon={
							playingLevel === PLAYING_LEVEL_PRO ? (
								playerSeasonHitterGradesSelected?.isMeasuredSwingMetrics ? (
									undefined
								) : (
									<Warning />
								)
							) : (
								undefined
							)
						}
					/>
					<PositionStatDistributionPlot
						plotLabel="Bat to Ball"
						value={
							playerSeasonHitterGradesSelected?.batToBallGrade
								? parseInt(format2080Grade(playerSeasonHitterGradesSelected.batToBallGrade))
								: undefined
						}
						distributionData={twentyEightyGradeDistribution}
						thresholdIcon={
							batToBallThresholdIconShape
								? { shape: batToBallThresholdIconShape, tooltipLabel: batToBallThresholdTooltip }
								: undefined
						}
						tooltipLabel={TOOLTIP_BAT_TO_BALL}
					/>
					<PositionStatDistributionPlot
						plotLabel="Swing Decisions"
						value={
							playerSeasonHitterGradesSelected?.swingDecisionsGrade
								? parseInt(format2080Grade(playerSeasonHitterGradesSelected.swingDecisionsGrade))
								: undefined
						}
						distributionData={twentyEightyGradeDistribution}
						thresholdIcon={
							swingDecisionThresholdIconShape
								? {
										shape: swingDecisionThresholdIconShape,
										tooltipLabel: swingDecisionThresholdTooltip
								  }
								: undefined
						}
						tooltipLabel={TOOLTIP_SWING_DECISIONS}
					/>
					<PositionStatDistributionPlot
						plotLabel="Attack Angle"
						value={playerSeasonHitterGradesSelected?.attackAngleVertical}
						valueLabel={
							playerSeasonHitterGradesSelected?.attackAngleVertical != null
								? `${playerSeasonHitterGradesSelected?.attackAngleVertical}\u00b0`
								: "-"
						}
						distributionData={
							playerSeasonHitterGradesSelected?.isMeasuredSwingMetrics
								? swingMetricsReference?.attackAngleVerticalNormalDistribution
								: swingMetricsInferredReference?.attackAngleVerticalNormalDistribution
						}
						thresholdIcon={
							attackAngleThresholdIconShape
								? {
										shape: attackAngleThresholdIconShape,
										tooltipLabel: attackAngleThresholdTooltip
								  }
								: undefined
						}
						tooltipLabel={
							playingLevel === PLAYING_LEVEL_PRO &&
							playerSeasonHitterGradesSelected?.isMeasuredSwingMetrics
								? TOOLTIP_ATTACK_ANGLE_MEASURED
								: TOOLTIP_ATTACK_ANGLE_INFERRED
						}
						tooltipIcon={
							playingLevel === PLAYING_LEVEL_PRO ? (
								playerSeasonHitterGradesSelected?.isMeasuredSwingMetrics ? (
									undefined
								) : (
									<Warning />
								)
							) : (
								undefined
							)
						}
					/>
					{playingLevel === PLAYING_LEVEL_PRO && throwsFilter === THROWS_OVERALL && (
						<PositionStatDistributionPlot
							plotLabel="Sprint Speed"
							value={sprintSpeedGrade ? Math.round(sprintSpeedGrade) : sprintSpeedGrade}
							distributionData={twentyEightyGradeDistribution}
							tooltipLabel={TOOLTIP_SPRINT_SPEED}
						/>
					)}
				</HStack>
			)}
		</>
	);
};
export default PositionStatDistributions;
