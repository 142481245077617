import React, { useMemo, useEffect, useState } from "react";
import { useMachine } from "@xstate/react";

import { FormNavigation } from "_react/shared/forms/FormNavigation";
import { FormSection } from "_react/shared/forms/FormSection";
import { TDocumentBase, TFormPlugin, TFormStructure, TFormSaveState, HIDE } from "_react/shared/forms/_types";
import { DEFAULT_SAVING_STATE } from "_react/shared/forms/_constants";
import { buildSectionRowArray, getFormNavigationHeightOffset } from "_react/shared/forms/_helpers";
import { FormContainer, FormSectionRowContainer, FormSectionRow } from "_react/shared/forms/_styles";
import { createFormMachine, SET_VIEWING_STATE, VIEWING, EDITING } from "_react/shared/forms/_machine";
import { FormFooter } from "_react/shared/forms/FormFooter";
import { FormProvider } from "_react/shared/forms/_context";
import { $TSFixMe } from "utils/tsutils";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

interface FormProps<T extends TDocumentBase, S = T> {
	colorScheme?: ColorSchemeGroup;
	document: T;
	onDocumentUpdate?: (newDocument: T) => void;
	plugin: TFormPlugin<T, S>;
	structure?: TFormStructure;
	readOnly?: boolean;
	hidden?: boolean;
	showFooter?: boolean;
	showSaveButton?: boolean;
	floatingFooter?: boolean;
	additionalFooterContents?: $TSFixMe;
	savingState?: TFormSaveState; // Managing the saving state upstream of the form
	setSavingState?: (savingState: TFormSaveState) => void; // Managing the saving state upstream of the form
}

export function Form<T extends TDocumentBase, S = T>({
	colorScheme = defaultColorScheme,
	document,
	plugin,
	onDocumentUpdate,
	structure: structureProp,
	readOnly = false,
	showFooter = false,
	showSaveButton = true,
	savingState: savingStateProp = DEFAULT_SAVING_STATE,
	setSavingState: setSavingStateProp,
	floatingFooter = true,
	hidden = false,
	additionalFooterContents
}: FormProps<T, S>) {
	/* State Management */

	// Form Machine
	const [current, send] = useMachine(createFormMachine(plugin.formId, readOnly));

	// Toggling between editing and read only
	useEffect(() => {
		if (readOnly) send({ type: SET_VIEWING_STATE, payload: VIEWING });
		else send({ type: SET_VIEWING_STATE, payload: EDITING });
	}, [readOnly, send]);

	// Get Validation Errors
	const { validationErrors } = current.context;

	// Get State
	const isReadOnly = current.matches("postFetch.viewing");

	/* Document Structure Management */
	// const formStructure = structureProp ?? current.context.structure;
	const formStructure = current.context.structure ?? structureProp;

	const sectionRows = useMemo(() => (formStructure ? buildSectionRowArray(formStructure.sections) : []), [
		formStructure
	]);

	// Saving State Management
	const [savingStateInternal, setSavingStateInternal] = useState(savingStateProp);
	const savingState = savingStateProp ?? savingStateInternal;
	const setSavingState = setSavingStateProp ?? setSavingStateInternal;

	if (formStructure == null || document == null || hidden) return null;

	return (
		<FormProvider
			plugin={plugin}
			document={document}
			send={send}
			validationErrors={validationErrors}
			readOnly={isReadOnly}
			// TODO: Remove
			onDocumentUpdate={onDocumentUpdate}
			savingState={savingState}
			setSavingState={setSavingState}
		>
			<FormContainer
				formNavigationHeightOffset={getFormNavigationHeightOffset()}
				paddingBottom={showFooter ? "50px" : ""}
			>
				<FormNavigation formStructure={formStructure} />
				{sectionRows.map(sectionRow => (
					<FormSectionRow key={sectionRow.rowNumber}>
						{sectionRow.sections
							.filter(section =>
								plugin.getSectionState ? plugin.getSectionState(section.id, document) !== HIDE : true
							)
							.map(section => (
								<FormSectionRowContainer
									id={section.id}
									key={section.id}
									flexBasis={((section.width ?? 12) / sectionRow.width) * 100}
								>
									<FormSection<T> colorScheme={colorScheme} section={section} />
								</FormSectionRowContainer>
							))}
					</FormSectionRow>
				))}
				{showFooter && (
					<FormFooter
						showSaveButton={showSaveButton}
						savingState={savingState}
						floating={floatingFooter}
						additionalFooterContents={additionalFooterContents}
					/>
				)}
			</FormContainer>
		</FormProvider>
	);
}
