import { TSectionStyle } from "_react/shared/ui/presentation/components/Section/_types";

// Style Objects

export const StatCardMarginTopStyle = {
	marginTop: 10
};

export const SectionStyleObject = {
	container: { overflowX: "scroll" }
} as TSectionStyle;

export const BaseballBatIconStyle = {
	marginRight: 1,
	color: "gray",
	height: 13,
	width: 13
};

export const BaseballIconStyle = {
	marginRight: 1,
	color: "gray",
	height: 13,
	width: 13,
	marginBottom: 0.5
};
