import React, { CSSProperties } from "react";

import { NULL_TEXT } from "_react/evals/list/_constants";
import { TEvalAmaHit } from "_react/evals/list/_types";
import { GradeSection, EvalSection, EvalAttr, EvalLabel, HideStyle, LongStyle } from "_react/evals/list/_styles";

export type TEvalAmaHitGradesProps = {
	evaluation: TEvalAmaHit;
	isShowRound?: boolean;
	style?: CSSProperties;
};

export const EvalAmaHitGrades = ({ evaluation, isShowRound = true, style }: TEvalAmaHitGradesProps) => {
	return (
		<>
			<GradeSection width={"9%"} inlineFlex style={isShowRound ? undefined : HideStyle}>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Round</EvalLabel> {evaluation.round_recommendation ?? NULL_TEXT}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
			<GradeSection width={isShowRound ? "41%" : "50%"} inlineFlex>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Bat Sp</EvalLabel> {evaluation.bat_speed_present ?? NULL_TEXT}/
						{evaluation.bat_speed_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Raw</EvalLabel> {evaluation.raw_power_present ?? NULL_TEXT}/
						{evaluation.raw_power_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Arm Str</EvalLabel> {evaluation.arm_strength_present ?? NULL_TEXT}/
						{evaluation.arm_strength_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Speed</EvalLabel> {evaluation.run_speed_present ?? NULL_TEXT}/
						{evaluation.run_speed_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr style={LongStyle}>
						<EvalLabel>HP to 1B</EvalLabel> {evaluation.home_to_1b ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>60 yard</EvalLabel> {evaluation["60_yard"] ?? NULL_TEXT}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
			<GradeSection width={"50%"} inlineFlex>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Str</EvalLabel> {evaluation.strength_present ?? NULL_TEXT}/
						{evaluation.strength_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Ease</EvalLabel> {evaluation.ease_of_actions_present ?? NULL_TEXT}/
						{evaluation.ease_of_actions_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Body Ctrl</EvalLabel> {evaluation.body_control_present ?? NULL_TEXT}/
						{evaluation.body_control_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Expl</EvalLabel> {evaluation.explosiveness_present ?? NULL_TEXT}/
						{evaluation.explosiveness_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Athl</EvalLabel> {evaluation.ath_present ?? NULL_TEXT}/
						{evaluation.ath_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Ov Proj</EvalLabel> {evaluation.ath_body_overall_proj ?? NULL_TEXT}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
			<GradeSection>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Hit</EvalLabel> {evaluation.hitting_ability_present ?? NULL_TEXT}/
						{evaluation.hitting_ability_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Pwr</EvalLabel> {evaluation.power_production_present ?? NULL_TEXT}/
						{evaluation.power_production_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Plt Disc</EvalLabel> {evaluation.plate_discipline_present ?? NULL_TEXT}/
						{evaluation.plate_discipline_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>P Recog</EvalLabel> {evaluation.pitch_recognition_present ?? NULL_TEXT}/
						{evaluation.pitch_recognition_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Contact</EvalLabel> {evaluation.contact_present ?? NULL_TEXT}/
						{evaluation.contact_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Calm</EvalLabel> {evaluation.calmness_box_presence_present ?? NULL_TEXT}/
						{evaluation.calmness_box_presence_future ?? NULL_TEXT}
					</EvalAttr>
				</EvalSection>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Field</EvalLabel> {evaluation.fielding_ability_present ?? NULL_TEXT}/
						{evaluation.fielding_ability_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Range</EvalLabel> {evaluation.fielding_range_present ?? NULL_TEXT}/
						{evaluation.fielding_range_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Arm Acc</EvalLabel> {evaluation.arm_accuracy_present ?? NULL_TEXT}/
						{evaluation.arm_accuracy_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Hands</EvalLabel> {evaluation.hands_present ?? NULL_TEXT}/
						{evaluation.hands_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Feet</EvalLabel> {evaluation.feet_present ?? NULL_TEXT}/
						{evaluation.feet_future ?? NULL_TEXT}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
		</>
	);
};
