import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import dayjs from "dayjs";
import Dialog from "@material-ui/core/Dialog";
import Info from "_react/shared/legacy/ui/icons/Info";
import Button from "@material-ui/core/Button";

import { getTeamName } from "_react/shared/_helpers/team";
import Form from "_react/forms/Form";
import {
	OFP_OPTS_INTL,
	GRADE_OPTS,
	MISC_OPTS,
	RISK_OPTS,
	EVAL_MISC_TEXT_FIELDS_INTL,
	PROSPECT_TYPE_OPTS,
	MAKEUP_FIELDS_INTL,
	MISC_FIELDS_PITCH,
	MISC_FIELDS_HIT,
	SNAP_SUM_FIELDS,
	OTH_PITCH_OPTS,
	ARM_ANGLE_OPTS,
	PLAYER_TOOL_FIELDS_INTL,
	HIT_TYPE_OPTS,
	countries,
	DEFAULT_PITCH_TYPES,
	HITTING_RISK_OPTIONS
} from "_react/evals/_constants";
import { getProspectType, checkSeenDate, addInnings, isNumeric } from "_react/evals/helpers";
import { isIntegerInput, formatHeight } from "utils/functions";
import IntlPitcherToolsTable from "_react/evals/intl/IntlPitcherToolsTable";
import IntlPlayerToolsTable from "_react/evals/intl/IntlPlayerToolsTable";
import IntlEvalHeader from "_react/evals/intl/IntlEvalHeader";
import IntlEvalFooter from "_react/evals/intl/IntlEvalFooter";
import { getAgeFromBirthDate } from "utils/helpers";
import IntlEvaluationPrint from "_react/evals/intl/IntlEvaluationPrint";
import EvaluationTooltip from "_react/evals/EvaluationTooltip";
import AddAgentModal from "_react/playerpage/shared_js/AddAgentModal";

export const CHUNG_UID = 19539;
export const ORTIZ_UID = 41179;
export const SAL_UID = 1006;

const SUMMARY_CHECKLIST_PITCHING = (
	<div>
		<ul style={{ paddingLeft: "40px" }}>
			<li>RHP or LHP/Age/eligibility year/country/agent/academy/location</li>
			<li>Body Type (Parents)</li>
			<li>Makeup – Schooling, Parents Occupation</li>
			<li>Arm Slot/Delivery/Arm Action</li>
			<li>Consistency of Release Point</li>
			<li>Describe each pitch</li>
			<li>FB - Velocity/Grade/Movement/Command</li>
			<li>CB – Velocity/Grade/Shape/Command</li>
			<li>SL - Velocity/Grade/Shape/Command</li>
			<li>CH Velocity/Grade/Action/Deception/Command</li>
			<li>Other Velocity/Movement/Command</li>
			<li>Present/Future Grades of the Above Pitches</li>
			<li style={{ fontWeight: "bold" }}>Overall Assessment of Future Role</li>
		</ul>
	</div>
);

const SUMMARY_CHECKLIST_HITTING = (
	<div>
		<ul style={{ paddingLeft: "40px" }}>
			<li>Position/Age/Eligibility Year/Country/Agent/Location</li>
			<li>Body Type (Parents)</li>
			<li>Makeup – Schooling, Parents Occupation</li>
			<li style={{ fontWeight: "bold" }}>Best Tool Listed First</li>
			<li>Present/Future Grades of Each Tool Included.</li>
			<li>Explain each tool listed below:</li>
			<li>Hitting Ability– Present/Future (Swing/Contact/Bat Control/Pitch Recognition/Location)</li>
			<li>
				Risk Assessment (DEFINITE, LIKELY, UNLIKELY, POOR) – example “what is the risk of this prospect’s
				chances of hitting?
			</li>
			<li style={{ marginLeft: "15px" }}>
				Prospect A: has big power and holes in swings. He would have UNLIKELY OR POOR risk assessment, meaning
				that the risk is higher.
			</li>
			<li style={{ marginLeft: "15px" }}>
				Prospect B: has real good contact skills. He would have LIKELY or DEFINITE risk assessment, meaning the
				risk is lower.
			</li>
			<li>Raw Power – Present/Future</li>
			<li>Power Production – Present/Future (What type: pull, straight away, spray, etc.)</li>
			<li>Field – Present/Future (Arm/Accuracy/Range/Instincts/Hands/Action)</li>
			<li>Run– Present/Future (60 Yard Time/Home to 1st Time)</li>
			<li>Usable Speed – Present/Future (Base Stealer, Average, Clogger)</li>
			<li>Further Explanation of the Player</li>
			<li>
				Categorize/Future Role/Overall – <span style={{ fontWeight: "bold" }}>What is he?</span>
			</li>
		</ul>
	</div>
);

function getDateOfBirth(player) {
	const dateStr =
		player.player_classification === "pro" || player.date_of_birth == null
			? player.birth_date
			: player.date_of_birth;
	return dateStr ? dayjs(dateStr) : null;
}

function displayDOBAndAge(player) {
	const dateOfBirth = getDateOfBirth(player);
	return dateOfBirth ? `${dateOfBirth.format("M/D/YYYY")} (${getAgeFromBirthDate(dateOfBirth)})` : "";
}

function getAgent(evaluation, player, agents) {
	if (player.player_classification === "pro") {
		const agentEbisId = player.agent_id;
		if (agentEbisId == null) return null;
		const agent = Object.values(agents).find(agent => agent.ebisAgentId === agentEbisId);
		return agent ?? null;
	}
	const agentId = evaluation.agent_id ? evaluation.agent_id : player.agent_id;
	return agentId != null && agents != null ? agents[agentId] ?? null : null;
}

// eslint-disable-next-line no-unused-vars
const BIO_FIELDS = (evaluation, player, agent, scout, team, startAdornment, agentOptions, includeAgent) => ({
	title: `International ${evaluation.eval_type === "INTL_PITCH" ? "Pitcher" : "Position Player"} Report`,
	mobileTab: "Bio",
	sections: [
		{
			objects: [
				{
					type: "label",
					label: "Player",
					value: `${player.first_name} ${player.last_name}`
				},
				{
					id: "position",
					label: "Position",
					type: "label",
					value: player.position
				},
				{
					id: "date_of_birth",
					label: "D.O.B.",
					type: "label",
					value: displayDOBAndAge(player)
				},
				{
					id: "bats",
					label: "Bats",
					type: "label",
					partialWidth: 6,
					objectStyle: { maxWidth: "70px" },
					value: player.bats
				},
				{
					id: "throws",
					label: "Throws",
					type: "label",
					partialWidth: 6,
					objectStyle: { maxWidth: "70px" },
					value: player.throws
				},
				{
					id: "height",
					label: "Height",
					type: "label",
					value: player.height
						? `${Math.floor(parseInt(player.height, 10) / 12.0)}" ${parseInt(player.height, 10) % 12}'`
						: "",
					partialWidth: 6,
					objectStyle: { maxWidth: "70px" }
				},
				{
					id: "weight",
					label: "Weight",
					type: "label",
					partialWidth: 6,
					objectStyle: { maxWidth: "70px" },
					value: player.weight
				},
				{ id: "seen_date", label: "Seen Date", type: "date" }
			]
		},
		{
			objects: [
				{
					id: "country",
					label: "Birth Country",
					type: "label",
					value: countries[player.birth_country]
				},
				{ label: "Team", type: "label", value: team },
				{
					id: "agent_id",
					label: "Agent",
					partialWidth: 8,
					type: "autocomplete",
					options: agentOptions,
					value: agent && agent.agentId,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" },
					noWidthConform: true,
					noRightPad: true,
					numCharToShowSuggestions: 0
				},
				{
					id: "includeAgent",
					label: "No Agent",
					partialWidth: 4,
					type: "checkbox",
					value: !includeAgent,
					noWidthConform: true,
					noLeftPad: true
				},
				{ id: "scout", label: "Scout", type: "label", value: scout },
				{
					id: "games_seen",
					label: "Games Seen",
					type: "text",
					validation: [
						{
							message: "Must be an integer",
							validate: isIntegerInput
						}
					]
				},
				{
					id: "innings_seen_tenths",
					label: "Innings Seen",
					type: "text",
					show: evaluation.eval_type === "INTL_PITCH",
					validation: [{ message: "Must be a number", validate: isNumeric }]
				},
				{
					id: "plate_appearances_seen",
					label: "PAs Seen",
					type: "text",
					show: evaluation.eval_type === "INTL_HIT",
					validation: [{ message: "Must be a number", validate: isNumeric }]
				}
			]
		},
		{
			objects: [
				{
					id: "ofp",
					label: "OFP",
					type: "autocomplete",
					options: OFP_OPTS_INTL,
					numCharToShowSuggestions: 0
				},
				{
					id: "category",
					label: "Category",
					type: "autocomplete",
					options: PROSPECT_TYPE_OPTS,
					numCharToShowSuggestions: 0
				},
				{
					id: "risk_factor",
					label: "Risk",
					type: "autocomplete",
					options: RISK_OPTS,
					numCharToShowSuggestions: 0
				},
				{
					label: "Eligible Year",
					type: "label",
					value: player.eligible_year
				},
				{
					id: "total_games_seen",
					label: "Total Games Seen",
					type: "label"
				},
				{
					id: "total_innings_seen_thirds",
					label: "Total Innings Seen",
					type: "label",
					show: evaluation.eval_type === "INTL_PITCH"
				},
				{
					id: "total_plate_appearances_seen",
					label: "Total PAs Seen",
					type: "label",
					show: evaluation.eval_type === "INTL_HIT"
				}
			]
		}
	],
	startAdornment
});

// eslint-disable-next-line no-unused-vars
const TOOLS_GRADING_STANDARDS_PITCH = (evaluation, startAdornment) => {
	const objects = DEFAULT_PITCH_TYPES.map(pitchType => {
		return [
			{ label: pitchType.name, type: "grade", key: "none" },
			{ label: `Velocity`, type: "vel", key: "vel" },
			{ label: `Movement`, type: "grade", key: "mov" },
			{ label: `Command`, type: "grade", key: "cmd" }
		].map(row => {
			if (pitchType.abb !== "FB" && row.label === "Movement") return null;

			// OTH issue (TODO: Fix in DB)
			if (row.key === "cmd" && pitchType.abb === "OTH_PITCH") {
				pitchType.abb = "OTH";
			} else if (row.key !== "cmd" && pitchType.abb === "OTH") {
				pitchType.abb = "OTH_PITCH";
			}

			let rows = [];
			if (pitchType.abb === "OTH_PITCH" && row.label === pitchType.name) {
				rows.push({
					id: "oth_pitch_type",
					type: "autocomplete",
					options: OTH_PITCH_OPTS,
					placeholder: "Select Pitch",
					partialWidth: 3,
					numCharToShowSuggestions: 0,
					containerStyle: {
						fontSize: "14px",
						flex: null,
						width: "132px"
					},
					objectStyle: { fontSize: "14px", width: "132px" }
				});
			} else if (pitchType.abb === "OTH_PITCH" || pitchType.abb === "OTH") {
				rows.push({
					type: "none",
					label: `${evaluation.oth_pitch_type ? `${evaluation.oth_pitch_type} ` : ""}${row.label}`,
					partialWidth: 3,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				});
			} else {
				rows.push({
					type: "none",
					label: row.label === pitchType.name ? pitchType.name : `${pitchType.abb} ${row.label}`,
					partialWidth: pitchType.abb === "FB" && row.type === "vel" ? 1 : 3,
					containerStyle: { fontSize: "14px", flex: null },
					objectStyle: { fontSize: "14px" }
				});
			}

			if (row.type === "grade") {
				rows = [
					...rows,
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: `_${row.key}`
						}_present`,
						type: "autocomplete",
						options: GRADE_OPTS,
						numCharToShowSuggestions: 0,
						partialWidth: 3,
						style: { maxWidth: "75px" },
						placeholder: "Present",
						preventMobileBreak: true,
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: `_${row.key}`
						}_future`,
						type: "autocomplete",
						options: GRADE_OPTS,
						numCharToShowSuggestions: 0,
						partialWidth: 3,
						style: { maxWidth: "75px" },
						placeholder: "Future",
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: `_${row.key}`
						}_comments`,
						type: "text",
						partialWidth: 3,
						placeholder: "Comments",
						multiline: true,
						containerStyle: { fontSize: "14px" },
						objectStyle: { fontSize: "14px" }
					}
				];
			} else if (row.type === "vel") {
				rows = [
					...rows,
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_low`,
						type: "text",
						partialWidth: pitchType.abb === "FB" ? 3 : 6,
						style: { maxWidth: "75px" },
						placeholder: "MPH",
						preventMobileBreak: true,
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_high`,
						type: "text",
						partialWidth: pitchType.abb === "FB" ? 3 : 6,
						style: { maxWidth: "75px" },
						placeholder: "MPH",
						preventMobileBreak: pitchType.abb === "FB" && row.type === "vel",
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					}
				];
			}

			if (pitchType.abb === "FB" && row.type === "vel") {
				rows = [
					...rows,
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_comfort_zone_low`,
						type: "text",
						partialWidth: 3,
						style: { maxWidth: "75px" },
						placeholder: "Comfort",
						preventMobileBreak: true,
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					},
					{
						id: `${pitchType.abb.toLowerCase()}${
							row.key === "vel" || (row.key === "none" && pitchType.abb === "FB")
								? "_vel"
								: row.key === "none"
								? ""
								: row.key
						}_comfort_zone_high`,
						type: "text",
						partialWidth: 3,
						style: { maxWidth: "75px" },
						placeholder: "Comfort",
						containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
						objectStyle: { fontSize: "14px" }
					}
				];
			}
			return rows;
		});
	});

	objects.push([
		{ type: "none", label: "CHP / Pitchability", partialWidth: 1 },
		{
			id: evaluation.eval_type === "INTL_PITCH" ? "pitchability_present" : "can_he_play_present",
			type: "autocomplete",
			options: GRADE_OPTS,
			numCharToShowSuggestions: 0,
			partialWidth: 3,
			style: { maxWidth: "75px" },
			placeholder: "Present",
			preventMobileBreak: true,
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: evaluation.eval_type === "INTL_PITCH" ? "pitchability_future" : "can_he_play_future",
			type: "autocomplete",
			options: GRADE_OPTS,
			numCharToShowSuggestions: 0,
			partialWidth: 3,
			style: { maxWidth: "75px" },
			placeholder: "Future",
			containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
			objectStyle: { fontSize: "14px" }
		},
		{
			id: evaluation.eval_type === "INTL_PITCH" ? "pitchability_comments" : "can_he_play_comments",
			type: "text",
			partialWidth: 3,
			placeholder: "Comments",
			multiline: true,
			containerStyle: { fontSize: "14px" },
			objectStyle: { fontSize: "14px" }
		}
	]);

	return {
		title: "Tools Grading Standards",
		mobileTab: "Tools",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.flat(2).filter(e => e !== null) : []
			}
		],
		displayComponent: <IntlPitcherToolsTable evaluation={evaluation} />,
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const TOOLS_GRADING_STANDARDS_HIT = (evaluation, startAdornment) => {
	const objects = PLAYER_TOOL_FIELDS_INTL.map(field => {
		return [
			{ type: "none", label: field.name, partialWidth: 3 },
			{
				id: `${field.id}_present`,
				type: "autocomplete",
				options: GRADE_OPTS,
				numCharToShowSuggestions: 0,
				partialWidth: 3,
				style: { maxWidth: "75px" },
				placeholder: "Present",
				preventMobileBreak: true,
				containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
				objectStyle: { fontSize: "14px" }
			},
			{
				id: `${field.id}_future`,
				type: "autocomplete",
				options: GRADE_OPTS,
				numCharToShowSuggestions: 0,
				partialWidth: 3,
				style: { maxWidth: "75px" },
				placeholder: "Future",
				containerStyle: { fontSize: "14px", display: "inline-flex", maxWidth: "100px" },
				objectStyle: { fontSize: "14px" }
			},
			{
				id: `${field.id}_comments`,
				type: "text",
				partialWidth: 3,
				placeholder: "Comments",
				multiline: true,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			}
		];
	});

	return {
		title: "Tools Grading Standards",
		mobileTab: "Tools",
		partialWidth: 6,
		sections: [
			{
				objects: objects != null && objects.length ? objects.flat(1).filter(e => e !== null) : []
			}
		],
		displayComponent: <IntlPlayerToolsTable evaluation={evaluation} />,
		startAdornment: startAdornment,
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	};
};

// eslint-disable-next-line no-unused-vars
const MAKEUP = () => ({
	title: "Makeup",
	mobileTab: "Makeup",
	partialWidth: 6,
	sections: [
		{
			objects: MAKEUP_FIELDS_INTL.map(rating => ({
				id: rating.id,
				label: rating.name,
				type: "autocomplete",
				options: MISC_OPTS,
				numCharToShowSuggestions: 0,
				containerStyle: { fontSize: "14px" },
				objectStyle: { fontSize: "14px" }
			}))
		}
	],
	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
});

// eslint-disable-next-line no-unused-vars
const MISCELLANEOUS_PITCH = evaluation => ({
	title: `Miscellaneous`,
	mobileTab: "Misc",
	partialWidth: 6,
	sections: [
		{
			objects: [
				{
					id: "angle",
					label: "Arm Angle",
					type: "autocomplete",
					options: ARM_ANGLE_OPTS,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				...MISC_FIELDS_PITCH.map(field => ({
					id: field.id,
					label: field.name,
					type: "autocomplete",
					options: MISC_OPTS,
					numCharToShowSuggestions: 0,
					value:
						field.id === "del" && evaluation._del
							? evaluation._del
							: evaluation[field.id]
							? evaluation[field.id]
							: null,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				}))
			]
		}
	],
	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
});

// eslint-disable-next-line no-unused-vars
const MISCELLANEOUS_HIT = evaluation => ({
	title: `Miscellaneous`,
	mobileTab: "Misc",
	partialWidth: 6,
	sections: [
		{
			objects: [
				...MISC_FIELDS_HIT.map(field => ({
					id: field.id,
					label: field.name,
					type: "autocomplete",
					options: MISC_OPTS,
					numCharToShowSuggestions: 0,
					value:
						field.id === "del" && evaluation._del
							? evaluation._del
							: evaluation[field.id]
							? evaluation[field.id]
							: null,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				})),
				{
					id: "hit_type",
					label: "Hitter Type",
					type: "autocomplete",
					options: HIT_TYPE_OPTS,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "home_to_1b",
					label: "Home to 1B",
					type: "text",
					validation: [{ validate: isNumeric, message: "Must be a number" }],
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "60_yard",
					label: "60 Yard",
					type: "text",
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				},
				{
					id: "hitting_risk",
					label: "Hitting Risk Assessment",
					type: "autocomplete",
					options: HITTING_RISK_OPTIONS,
					numCharToShowSuggestions: 0,
					containerStyle: { fontSize: "14px" },
					objectStyle: { fontSize: "14px" }
				}
			]
		}
	],
	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
});

// eslint-disable-next-line no-unused-vars
const MISC_TEXT_FIELDS = () =>
	EVAL_MISC_TEXT_FIELDS_INTL.map(descriptionField => ({
		title: descriptionField.name,
		mobileTab: descriptionField.mobileTab,
		partialWidth: 6,
		sections: [
			{
				objects: [
					{
						id: descriptionField.id,
						type: "text",
						multiline: true,
						containerStyle: { fontSize: "14px" },
						objectStyle: { fontSize: "14px" }
					}
				]
			}
		],
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
	}));

// eslint-disable-next-line no-unused-vars
// const OPF = (evaluation, startAdornment) => ({
// 	title: evaluation.eval_report_type === "FRE" ? "OFP/Prospect Type" : "Prospect Type",
// 	mobileTab: "OFP",
// 	partialWidth: 6,
// 	sections: [
// 		{
// 			objects: [
// 				{
// 					id: "ofp",
// 					label: "OFP",
// 					type: "autocomplete",
// 					options: OFP_OPTS_INTL,
// 					numCharToShowSuggestions: 0,
// 					show: evaluation.eval_report_type === "FRE",
// 					containerStyle: { fontSize: "14px" },
// 					objectStyle: { fontSize: "14px" }
// 				},
// 				{
// 					id: "prospect_type",
// 					label: "Prospect Type",
// 					type: "autocomplete",
// 					options: PROSPECT_TYPE_OPTS,
// 					numCharToShowSuggestions: 0,
// 					containerStyle: { fontSize: "14px" },
// 					objectStyle: { fontSize: "14px" }
// 				},
// 				{
// 					id: "risk_factor",
// 					label: "Risk Factor",
// 					type: "autocomplete",
// 					options: RISK_OPTS,
// 					numCharToShowSuggestions: 0,
// 					containerStyle: { fontSize: "14px" },
// 					objectStyle: { fontSize: "14px" }
// 				}
// 			]
// 		}
// 	],
// 	startAdornment: startAdornment,
// 	headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" }
// });

// eslint-disable-next-line no-unused-vars
const SNAP_SUM = evaluation =>
	SNAP_SUM_FIELDS.map((descriptionField, idx) => ({
		title: descriptionField.name,
		mobileTab: descriptionField.mobileTab,
		sections: [
			{
				objects: [
					{
						id: descriptionField.id,
						type: "text",
						multiline: true,
						format: descriptionField.format,
						maxLength: descriptionField.id === "current_snapshot" ? 140 : null,
						onBottom:
							idx === SNAP_SUM_FIELDS.length - 1 && evaluation != null && evaluation.publish_date == null,
						containerStyle: { fontSize: "14px" },
						objectStyle: { fontSize: "14px" }
					}
				]
			}
		],
		headerStyle: { marginTop: "-15px", marginBottom: "", fontSize: "14px" },
		partialWidth: descriptionField.name === "Summary" ? 6 : 12,
		style: {
			minHeight:
				descriptionField.name === "Summary" ? (evaluation.eval_type === "INTL_PITCH" ? "326px" : "406px") : ""
		}
	}));

// eslint-disable-next-line no-unused-vars
const SUMMARY_CHECKLIST = evaluation => ({
	title: "Summary Checklist",
	mobileTab: "Summary Checklist",
	sections: [
		{
			objects: [
				{
					type: "label",
					value:
						evaluation.eval_type === "INTL_PITCH" ? SUMMARY_CHECKLIST_PITCHING : SUMMARY_CHECKLIST_HITTING,
					style: {
						height: evaluation.eval_type === "INTL_PITCH" ? "315px" : "395px",
						overflow: "scroll"
					},
					objectStyle: {
						height: null
					}
				}
			]
		}
	],
	partialWidth: 6
});

// eslint-disable-next-line no-unused-vars
class IntlEval extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			includeAgent: true
		};

		// if (this.props.evaluation != null) {
		// 	this.props.fetchPlayerEvalsByPhilId(this.props.evaluation.phil_id);
		// } else {
		// 	this.props.fetchPlayerEvalByEvalId(
		// 		"INTL",
		// 		"HIT",
		// 		this.props.match ? this.props.match.params.evalId : this.props.evalId,
		// 		true
		// 	);
		// 	this.props.fetchPlayerEvalByEvalId(
		// 		"INTL",
		// 		"PITCH",
		// 		this.props.match ? this.props.match.params.evalId : this.props.evalId,
		// 		true
		// 	);
		// }
	}

	// componentDidUpdate(prevProps) {
	// 	if (
	// 		(prevProps.evaluation == null && this.props.evaluation != null) ||
	// 		(prevProps.evaluation != null &&
	// 			this.props.evaluation != null &&
	// 			prevProps.evaluation.phil_id !== this.props.evaluation.phil_id)
	// 	) {
	// 		this.props.fetchPlayerEvalsByPhilId(this.props.evaluation.phil_id);
	// 	}
	// }

	onSave = value => {
		const { evaluation } = this.props;
		// Manage Other Pitch
		if (value.id === "oth_pitch_type") {
			this.setState({ extraPitch: value.value });
		}

		// Manage Height
		if (value.id === "team_id" && value.value === "addNewTeam") {
			this.setState({ addingTeam: true });
			return;
		}

		// add a new agent
		if (value.id === "agent_id" && value.value === "addNewAgent") {
			this.setState({ addingAgent: true });
			return;
		}

		// update state to toggle whether including the agent
		if (value.id === "includeAgent") {
			this.setState({ includeAgent: !value.value });
			return;
		}

		// TODO: this is a hacky fix
		if (value.id.includes("vel")) {
			if (!value.id.includes("present") && !value.id.includes("future") && !value.id.includes("comment")) {
				value.value = value.value === "" ? null : value.value;
			}
		}

		if (value.id === "seen_date" || value.id === "report_date") {
			value.value = dayjs(value.value).format("YYYY-MM-DD HH:mm:ss");
		}

		if (value.id === "agent_id") {
			this.handleUpdateAgent(value.value);
		}

		if (evaluation[value.id] !== value.value) {
			// Create Update Object
			const evaluationUpdateObject = {
				eval_create_user_id: evaluation.eval_create_user_id,
				eval_id: evaluation.eval_id,
				eval_type: evaluation.eval_type,
				phil_id: evaluation.phil_id,
				[value.id]: value.value,
				total_innings_seen_thirds: evaluation.total_innings_seen_thirds,
				total_plate_appearances_seen: evaluation.total_plate_appearances_seen,
				total_games_seen: evaluation.total_games_seen
			};

			if (value.id === "ofp") {
				evaluationUpdateObject["category"] = getProspectType(value.value);
			}

			// Manage Total Plate Appearances Seen
			if (value.id === "plate_appearances_seen" && checkSeenDate(evaluation["seen_date"])) {
				evaluationUpdateObject["total_plate_appearances_seen"] =
					(parseInt(evaluation["total_plate_appearances_seen"], 10) || 0) +
					((parseInt(value.value, 10) || 0) - (parseInt(evaluation["plate_appearances_seen"], 10) || 0));
			}

			// Manage Total Innings Seen Thirds
			if (value.id === "innings_seen_thirds" && checkSeenDate(evaluation["seen_date"])) {
				evaluationUpdateObject["total_innings_seen_thirds"] = addInnings(
					evaluation["total_innings_seen_thirds"],
					addInnings(value.value, -1 * (parseFloat(evaluation["innings_seen_thirds"], 10.0) || 0.0))
				);
			}

			// Manage Total Games Seen
			if (value.id === "games_seen" && checkSeenDate(evaluation["seen_date"])) {
				evaluationUpdateObject["total_games_seen"] =
					(parseInt(evaluation["total_games_seen"], 10) || 0) +
					((parseInt(value.value, 10) || 0) - (parseInt(evaluation["games_seen"], 10) || 0));
			}

			// Manage Seen Date
			if (value.id === "seen_date" && checkSeenDate(value.value) !== checkSeenDate(evaluation["seen_date"])) {
				const addSubtract = checkSeenDate(value.value) ? 1 : -1;
				if (evaluation.eval_type === "INTL_PITCH")
					evaluationUpdateObject["total_innings_seen_thirds"] = addInnings(
						evaluation["total_innings_seen_thirds"],
						addSubtract * (parseFloat(evaluation["innings_seen_thirds"], 10.0) || 0.0)
					);
				if (evaluation.eval_type === "INTL_HIT")
					evaluationUpdateObject["total_plate_appearances_seen"] =
						(parseInt(evaluation["total_plate_appearances_seen"], 10) || 0) +
						addSubtract * (parseInt(evaluation["plate_appearances_seen"], 10) || 0);
				evaluationUpdateObject["total_games_seen"] =
					(parseInt(evaluation["total_games_seen"], 10) || 0) +
					addSubtract * (parseInt(evaluation["games_seen"], 10) || 0);
			}

			this.props.updatePlayerEval(evaluationUpdateObject);
		}
	};

	handleUpdateAgent = agentId => {
		if (this.props.player.agent_id !== agentId) {
			const updatedPlayer = {
				phil_id: this.props.player.phil_id,
				agent_id: agentId
			};
			this.props.updateIntlPlayer(updatedPlayer);
		}
	};

	handleCloseAgentModal = agent => {
		if (agent != null && agent.agentId != null) {
			if (this.props.evaluation.agent_id !== agent.agentId) {
				this.onSave({ id: "agent_id", value: agent.agentId });
			} else {
				this.handleUpdateAgent(agent.agentId);
			}
		}
	};

	resetPrint = () => this.setState({ print: false });

	triggerSave = () => {
		return this.form.getWrappedInstance().triggerSave();
	};

	getStartAdornment = type => {
		return (
			<div>
				<Info
					onClick={() => this.setState({ [type]: true })}
					style={{ cursor: "pointer", marginRight: "5px" }}
				/>
				<Dialog
					header="Evaluation Reference"
					maxWidth={"lg"}
					onClose={() => this.setState({ [type]: false })}
					open={Boolean(this.state[type])}
				>
					<EvaluationTooltip isMobile={this.props.isMobile} type={type} />
					<Button
						onClick={() => this.setState({ [type]: false })}
						style={{ margin: "15px" }}
						variant={"contained"}
					>
						Close
					</Button>
				</Dialog>
			</div>
		);
	};

	render() {
		const {
			evaluation,
			userId,
			agents,
			agencies,
			player,
			scout,
			teams,
			team,
			deletePlayerEval,
			updatePlayerEval,
			isEvalSaving,
			windowWidth,
			isEvalManager,
			reportEvalPublishError,
			previousEval,
			nextEval,
			previousEvalScout,
			nextEvalScout,
			getEvalForCreate,
			createPlayerEval,
			scoutLastName,
			getPlayerScoutEvalsFromEvalId,
			handlePrint,
			printRef
		} = this.props;
		const { print, addingAgent, includeAgent } = this.state;

		if (evaluation == null) {
			return (
				<div
					style={{
						margin: "auto",
						textAlign: "center",
						marginTop: "60px"
					}}
				>
					<CircularProgress size={50} />
					<div style={{ fontSize: 18, marginTop: "30px" }}>Loading...</div>
				</div>
			);
		}

		const toolsStartAdornment = this.getStartAdornment("AmaTGS");
		const ofpAdornment = this.getStartAdornment("AmaOFPProspectType");

		const editing =
			evaluation != null && !print
				? evaluation.eval_create_user_id === userId && evaluation.publish_date == null
				: false;
		const canEdit =
			(evaluation.eval_create_user_id === userId && !print && evaluation.publish_date == null) ||
			userId === CHUNG_UID ||
			userId === ORTIZ_UID ||
			userId === SAL_UID;

		const agentOptions = Object.entries(agents).map(([agentId, agent]) => ({
			value: agentId,
			text: agent != null ? `${agent.firstName || ""} ${agent.lastName}` : ""
		}));
		agentOptions.unshift({
			value: "addNewAgent",
			text: "+ Add New",
			persistent: true
		});
		const agent = getAgent(evaluation, player, agents);

		const teamUsed =
			teams != null && player != null && teams[player.current_team_phil_id] != null
				? teams[player.current_team_phil_id].team_name
				: team
				? getTeamName(team)
				: "";

		const configuration = {
			style: {},
			groups: [
				BIO_FIELDS(evaluation, player, agent, scout, teamUsed, ofpAdornment, agentOptions, includeAgent),
				evaluation.eval_type === "INTL_PITCH"
					? TOOLS_GRADING_STANDARDS_PITCH(evaluation, toolsStartAdornment)
					: TOOLS_GRADING_STANDARDS_HIT(evaluation, toolsStartAdornment),
				MAKEUP(),
				evaluation.eval_type === "INTL_PITCH" ? MISCELLANEOUS_PITCH(evaluation) : MISCELLANEOUS_HIT(evaluation),
				...MISC_TEXT_FIELDS(),
				...SNAP_SUM(evaluation),
				SUMMARY_CHECKLIST(evaluation)
			]
		};

		const author = scout;

		const handlePrintClick = () => {
			this.setState({ print: true }, () => {
				handlePrint();
				setTimeout(this.resetPrint, 100);
			});
		};

		if (print) {
			return (
				<div ref={printRef}>
					<IntlEvaluationPrint
						evaluation={evaluation}
						player={player}
						scout={scout}
						teamName={team ? team.team_name : ""}
					/>
				</div>
			);
		}

		return (
			<div>
				{!print && (
					<IntlEvalHeader
						author={author}
						evaluation={evaluation}
						handlePrintClick={handlePrintClick}
						key={"header"}
						player={player}
						print={print}
						windowWidth={windowWidth}
					/>
				)}
				{print && (
					<div style={{ display: "flex" }}>
						<div
							style={{
								flex: 1,
								fontWeight: "bold",
								display: "inline-block",
								fontSize: ".775em"
							}}
						>
							{`Intl ${evaluation.eval_type === "INTL_PITCH" ? "Pitcher" : "Player"} Report`}
							<br />
							{scout}
							<br />
							{evaluation.publish_date == null
								? `Saved: ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`
								: `Published: ${dayjs(evaluation.publish_date).format("YYYY-MM-DD HH:mm:ss")}`}
						</div>
						<div
							style={{
								flex: 2,
								fontWeight: "bold",
								fontSize: "1.2em",
								display: "inline-block",
								textAlign: "center",
								verticalAlign: "top"
							}}
						>
							{`${player.roster_first_name ? player.roster_first_name : player.first_name} ${
								player.last_name
							}`}
						</div>
						<div
							style={{
								flex: 1,
								fontWeight: "bold",
								display: "inline-block",
								textAlign: "right",
								fontSize: ".775em"
							}}
						>
							{`OFP: ${evaluation.ofp ? evaluation.ofp : ""}`}
							<br />
							{`Risk: ${evaluation.risk_factor ? evaluation.risk_factor : ""}`}
							<br />
							{`Ht/Wt: ${player.height ? formatHeight(player.height) : ""}${
								player.weight ? `/${player.weight}` : ""
							}`}
						</div>
					</div>
				)}
				<div
					key={"form"}
					style={{
						marginBottom: !print ? "65px" : ""
					}}
				>
					<Form
						configuration={configuration}
						dataSource={evaluation}
						editing={editing}
						onSave={this.onSave}
						ref={node => (this.form = node)}
						showEditButtons={canEdit}
					/>
				</div>
				{evaluation != null && (
					<IntlEvalFooter
						createPlayerEval={createPlayerEval}
						deletePlayerEval={deletePlayerEval}
						evaluation={evaluation}
						getEvalForCreate={getEvalForCreate}
						getPlayerScoutEvalsFromEvalId={getPlayerScoutEvalsFromEvalId}
						ignorePublishErrors={includeAgent ? [] : ["agent_id"]}
						isEvalManager={isEvalManager}
						key={"footer"}
						nextEval={nextEval != null ? nextEval : {}}
						nextEvalScout={nextEvalScout != null ? nextEvalScout : {}}
						player={player}
						previousEval={previousEval != null ? previousEval : {}}
						previousEvalScout={previousEvalScout != null ? previousEvalScout : {}}
						print={print}
						reportEvalPublishError={reportEvalPublishError}
						scout={scout}
						scoutLastName={scoutLastName}
						triggerSave={this.triggerSave}
						updatePlayerEval={updatePlayerEval}
						isEvalSaving={isEvalSaving}
						userId={userId}
						windowWidth={windowWidth}
					/>
				)}

				{addingAgent && (
					<AddAgentModal
						agencies={agencies}
						handleClose={agentSelected => {
							this.setState({ addingAgent: false });
							this.handleCloseAgentModal(agentSelected);
						}}
						isAgent={true}
					/>
				)}
			</div>
		);
	}
}

IntlEval.propTypes = {
	agencies: PropTypes.object,
	agents: PropTypes.object,
	createPlayerEval: PropTypes.func,
	deletePlayerEval: PropTypes.func,
	evaluation: PropTypes.object,
	getEvalForCreate: PropTypes.func,
	getPlayerScoutEvalsFromEvalId: PropTypes.func,
	isEvalManager: PropTypes.bool,
	isMobile: PropTypes.bool,
	nextEval: PropTypes.object,
	nextEvalScout: PropTypes.object,
	player: PropTypes.object,
	previousEval: PropTypes.object,
	previousEvalScout: PropTypes.object,
	reportEvalPublishError: PropTypes.func,
	scout: PropTypes.string,
	scoutLastName: PropTypes.string,
	teams: PropTypes.object,
	team: PropTypes.object,
	updateIntlPlayer: PropTypes.func,
	isEvalSaving: PropTypes.func,
	updatePlayerEval: PropTypes.func,
	userId: PropTypes.number,
	windowWidth: PropTypes.number,
	handlePrint: PropTypes.func,
	printRef: PropTypes.object
};

export default IntlEval;
