import React, { FC, useMemo, useState } from "react";

import { useScrollPosition } from "_react/_hooks";
import KeyboardArrowLeft from "_react/shared/legacy/ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "_react/shared/legacy/ui/icons/KeyboardArrowRight";
import { Menu } from "_react/shared/legacy/ui/Menu";
import { MenuItem } from "_react/shared/legacy/ui/MenuItem";

import { FORM_NAVIGATION_ID } from "_react/shared/forms/_constants";
import { TFormStructure } from "_react/shared/forms/_types";
import {
	getFormNavigationHeightOffset,
	handleSectionScroll,
	scrollToSection,
	getElementOffset
} from "_react/shared/forms/_helpers";
import {
	FormNavigationContainer,
	FormNavigationJumpTo,
	FORM_NAVIGATION_ARROW_STYLE
} from "_react/shared/forms/_styles";

type FormNavigationProps = {
	formStructure: TFormStructure;
};

export const FormNavigation: FC<FormNavigationProps> = ({ formStructure }) => {
	// State Management
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();

	const scrollToSectionAndCloseMenu = (id: string) => () => {
		scrollToSection(id);
		setAnchorEl(null);
	};

	const scrollPosition = useScrollPosition();

	// Get the correct text to be displayed in the center section
	const headerText = useMemo(() => {
		let currentHeading = "Jump to Section...";
		// If at the top, show placeholder
		if (scrollPosition === 0) return currentHeading;
		// Get the height of the navigation header (multiplied by two so it occurs midway through scrolling)
		const formNavigationHeight = getFormNavigationHeightOffset() * 2;
		// Loop over each section to see which section you're currently scrolled to
		for (let i = 0; i < formStructure.sections.length; i++) {
			const section = formStructure.sections[i];
			// Get the section position
			const position = getElementOffset(section.id) - formNavigationHeight;
			// Check if you've scrolled past this section's header
			if (scrollPosition > position) currentHeading = section.heading ?? "Jump to Section...";
			// Check if you haven't scrolled pased this section's header, stop iterating and use the previous section's header text
			if (scrollPosition < position) break;
		}
		return currentHeading;
	}, [scrollPosition, formStructure.sections]);

	return (
		<FormNavigationContainer id={FORM_NAVIGATION_ID}>
			<KeyboardArrowLeft
				fill="white"
				style={FORM_NAVIGATION_ARROW_STYLE}
				onClick={handleSectionScroll(formStructure, false)}
			/>
			<FormNavigationJumpTo onClick={e => setAnchorEl(e.currentTarget)}>{headerText}</FormNavigationJumpTo>
			<KeyboardArrowRight
				fill="white"
				style={FORM_NAVIGATION_ARROW_STYLE}
				onClick={handleSectionScroll(formStructure, true)}
			/>
			<Menu
				anchorEl={anchorEl}
				id="formSectionScrollToMenu"
				onClose={() => setAnchorEl(null)}
				open={Boolean(anchorEl)}
			>
				{formStructure.sections
					.filter(section => section.heading != null)
					.map(section => (
						<MenuItem
							id={`${section.id}-nav-button`}
							key={section.id}
							handleClick={scrollToSectionAndCloseMenu(section.id)}
						>{`${section.heading}`}</MenuItem>
					))}
			</Menu>
		</FormNavigationContainer>
	);
};
