import React from "react";
import ReactSelect, { StylesConfig, CSSObjectWithLabel } from "react-select";
import CreatableSelect from "react-select/creatable";

import { $TSFixMe } from "utils/tsutils";
import { TOption, TValueTypes } from "_react/inputs";
import { TUmpire } from "_react/shared/selects/UmpireSelect";
import { AutocompleteDataSourceItem } from "_react/shared/selects/VenueSelect";

export type TReactSelectProps = {
	closeMenuOnSelect?: boolean;
	getOptionLabel?: Function;
	filterOption?: Function;
	formatOptionLabel?: Function;
	isClearable?: boolean;
	isDisabled?: boolean;
	isOptionDisabled?: (option: TOption<TValueTypes>) => boolean;
	isMulti?: boolean;
	onChange?: Function;
	options?: Array<TOption<TValueTypes>>;
	placeholder?: string;
	value?: string | TOption<TValueTypes>[] | TOption<TValueTypes> | null;
	style?: $TSFixMe;
	styles?: object;
};

export type SelectStyles = { [index: string]: $TSFixMe };

export function makeSelectStylesString(styles: SelectStyles) {
	return (Object.keys(styles) as (keyof StylesConfig)[]).reduce(
		(obj: StylesConfig<TOption<string>, boolean>, styleKey: keyof StylesConfig) => {
			obj[styleKey] = provided => ({
				...provided,
				...styles[styleKey]
			});
			return obj;
		},
		{} as StylesConfig<TOption<string>, boolean>
	);
}

export function makeStyledSelectStylesString(styles: SelectStyles = {}): StylesConfig<TOption<string>, boolean> {
	const newStyles = makeSelectStylesString(styles);
	return {
		...newStyles,
		control: (provided: CSSObjectWithLabel) => ({
			...provided,
			minHeight: 24,
			fontSize: 11,
			...styles.control
		}),
		dropdownIndicator: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			width: 15,
			...styles.dropdownIndicator
		}),
		indicatorsContainer: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			height: 24,
			...styles.indicatorsContainer
		}),
		menu: (provided: CSSObjectWithLabel) => ({
			...provided,
			fontSize: 11,
			...styles.menu
		}),
		menuPortal: (provided: CSSObjectWithLabel) => ({
			...provided,
			zIndex: 1003,
			...styles.menuPortal
		}),
		multiValue: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			fontSize: 11,
			...styles.multiValue
		}),
		multiValueLabel: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 3,
			...styles.multiValueLabel
		}),
		multiValueRemove: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			...styles.multiValueRemove
		}),
		valueContainer: (provided: CSSObjectWithLabel) => ({
			...provided,
			minHeight: 24,
			...styles.valueContainer
		})
	};
}

export function makeSelectStyles(styles: SelectStyles) {
	return (Object.keys(styles) as (keyof StylesConfig)[]).reduce(
		(obj: StylesConfig<TOption<number>, boolean>, styleKey: keyof StylesConfig) => {
			obj[styleKey] = provided => ({
				...provided,
				...styles[styleKey]
			});
			return obj;
		},
		{} as StylesConfig<TOption<number>, boolean>
	);
}

export function makeStyledSelectStyles(styles: SelectStyles = {}): StylesConfig<TOption<number>, boolean> {
	const newStyles = makeSelectStyles(styles);
	return {
		...newStyles,
		control: (provided: CSSObjectWithLabel) => ({
			...provided,
			minHeight: 24,
			fontSize: 11,
			...styles.control
		}),
		dropdownIndicator: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			width: 15,
			...styles.dropdownIndicator
		}),
		indicatorsContainer: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			height: 24,
			...styles.indicatorsContainer
		}),
		menu: (provided: CSSObjectWithLabel) => ({
			...provided,
			fontSize: 11,
			...styles.menu
		}),
		menuPortal: (provided: CSSObjectWithLabel) => ({
			...provided,
			zIndex: 1800,
			...styles.menuPortal
		}),
		multiValue: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			fontSize: 11,
			...styles.multiValue
		}),
		multiValueLabel: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 3,
			...styles.multiValueLabel
		}),
		multiValueRemove: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			...styles.multiValueRemove
		}),
		valueContainer: (provided: CSSObjectWithLabel) => ({
			...provided,
			minHeight: 24,
			...styles.valueContainer
		})
	};
}

export function makeSelectStylesUmpire(styles: SelectStyles) {
	return (Object.keys(styles) as (keyof StylesConfig)[]).reduce(
		(obj: StylesConfig<TUmpire, boolean>, styleKey: keyof StylesConfig) => {
			obj[styleKey] = provided => ({
				...provided,
				...styles[styleKey]
			});
			return obj;
		},
		{} as StylesConfig<TUmpire, boolean>
	);
}

export function makeStyledSelectStylesUmpire(styles: SelectStyles = {}): StylesConfig<TUmpire, boolean> {
	const newStyles = makeSelectStylesUmpire(styles);
	return {
		...newStyles,
		control: (provided: CSSObjectWithLabel) => ({
			...provided,
			minHeight: 24,
			fontSize: 11,
			...styles.control
		}),
		dropdownIndicator: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			width: 15,
			...styles.dropdownIndicator
		}),
		indicatorsContainer: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			height: 24,
			...styles.indicatorsContainer
		}),
		menu: (provided: CSSObjectWithLabel) => ({
			...provided,
			fontSize: 11,
			...styles.menu
		}),
		menuPortal: (provided: CSSObjectWithLabel) => ({
			...provided,
			zIndex: 1003,
			...styles.menuPortal
		}),
		multiValue: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			fontSize: 11,
			...styles.multiValue
		}),
		multiValueLabel: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 3,
			...styles.multiValueLabel
		}),
		multiValueRemove: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			...styles.multiValueRemove
		}),
		valueContainer: (provided: CSSObjectWithLabel) => ({
			...provided,
			minHeight: 24,
			...styles.valueContainer
		})
	};
}

export function makeSelectStylesVenue(styles: SelectStyles) {
	return (Object.keys(styles) as (keyof StylesConfig)[]).reduce(
		(obj: StylesConfig<AutocompleteDataSourceItem, boolean>, styleKey: keyof StylesConfig) => {
			obj[styleKey] = provided => ({
				...provided,
				...styles[styleKey]
			});
			return obj;
		},
		{} as StylesConfig<AutocompleteDataSourceItem, boolean>
	);
}

export function makeStyledSelectStylesVenue(
	styles: SelectStyles = {}
): StylesConfig<AutocompleteDataSourceItem, boolean> {
	const newStyles = makeSelectStylesVenue(styles);
	return {
		...newStyles,
		control: (provided: CSSObjectWithLabel) => ({
			...provided,
			minHeight: 24,
			fontSize: 11,
			...styles.control
		}),
		dropdownIndicator: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			width: 15,
			...styles.dropdownIndicator
		}),
		indicatorsContainer: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			height: 24,
			...styles.indicatorsContainer
		}),
		menu: (provided: CSSObjectWithLabel) => ({
			...provided,
			fontSize: 11,
			...styles.menu
		}),
		menuPortal: (provided: CSSObjectWithLabel) => ({
			...provided,
			zIndex: 1003,
			...styles.menuPortal
		}),
		multiValue: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			fontSize: 11,
			...styles.multiValue
		}),
		multiValueLabel: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 3,
			...styles.multiValueLabel
		}),
		multiValueRemove: (provided: CSSObjectWithLabel) => ({
			...provided,
			padding: 0,
			...styles.multiValueRemove
		}),
		valueContainer: (provided: CSSObjectWithLabel) => ({
			...provided,
			minHeight: 24,
			...styles.valueContainer
		})
	};
}

// TODO: this is used all over and should be in the shared/ directory
export function StyledSelect(props: $TSFixMe) {
	if (props.dontAnchorOnBody) {
		return <ReactSelect {...props} styles={makeStyledSelectStyles(props.styles || {})} />;
	}
	return (
		<ReactSelect {...props} menuPortalTarget={document.body} styles={makeStyledSelectStyles(props.styles || {})} />
	);
}

export function StyledCreatableSelect(props: $TSFixMe) {
	if (props.dontAnchorOnBody) {
		return <CreatableSelect {...props} styles={makeStyledSelectStyles(props.styles || {})} />;
	}
	return (
		<CreatableSelect
			{...props}
			menuPortalTarget={document.body}
			styles={makeStyledSelectStyles(props.styles || {})}
		/>
	);
}
