import React from "react";

import { getRgbNumberArray } from "utils/color";
import { round10 } from "_react/shared/_helpers/numbers";
import { DATA_COLOR_PRIMARY_RED_MIXED_WITH_WHITE_RGB } from "_react/shared/dataviz/_constants";
import { getZScore } from "_react/shared/dataviz/_helpers";
import { VALUE_TYPE_NUMBER } from "_react/shared/ui/presentation/components/Table/_constants";
import { TColumn, TParentColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";

import {
	COLOR_GRADIENT_FUNCTION_BLUE_GREEN,
	NULL_FILLER_TEXT,
	PROPORTION_UPPER_THRESHOLD
} from "_react/shared/ui/data/tables/AmaFieldingOaaTable/_constants";
import { TAmaFieldingOaaBasic, TAmaFieldingOaaRow } from "_react/shared/ui/data/tables/AmaFieldingOaaTable/_types";
import ExpandableOaaLabel from "_react/shared/ui/data/tables/AmaFieldingOaaTable/ExpandableOaaLabel";
import ExpandableOaaOpps from "_react/shared/ui/data/tables/AmaFieldingOaaTable/ExpandableOaaOpps";

export const proportionColorGradientFunction = (proportion: string | number): Array<number> | null => {
	if (typeof proportion === "string") return null;
	return proportion > PROPORTION_UPPER_THRESHOLD
		? getRgbNumberArray(DATA_COLOR_PRIMARY_RED_MIXED_WITH_WHITE_RGB)
		: null;
};

export const getPositionProportionFromAmaFieldingOaa = (
	amaFieldingOaa: TAmaFieldingOaaBasic | undefined
): number | null => {
	if (!amaFieldingOaa) return null;
	const opportunitiesPerFullSeason = amaFieldingOaa.opportunitiesPerFullSeason;
	// Return null if opportunitiesPerFullSeason is not a valid divisor
	if (!opportunitiesPerFullSeason) return null;

	// Return null if opportunities is not a valid dividend
	const opportunities = amaFieldingOaa.opportunities;
	if (opportunities === null) return null;

	return opportunities / opportunitiesPerFullSeason;
};

const getInningColumnId = (position: string): string => `${position}_inn`;
const getOpportunityColumnId = (position: string): string => `${position}_opp`;
const getOaaColumnId = (position: string): string => `${position}_oaa`;

const createInningsColumn = (position: string): TColumn<TAmaFieldingOaaRow, keyof TAmaFieldingOaaRow> => {
	return {
		value: `${position}Inn`,
		id: getInningColumnId(position),
		label: `Inn`,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		isMobile: true,
		getValueFunction: (row: TAmaFieldingOaaRow) => {
			if (row.amaFieldingStatsByPosition === undefined) return NULL_FILLER_TEXT;
			const innings = row.amaFieldingStatsByPosition[position]?.innings ?? null;
			return innings === null ? NULL_FILLER_TEXT : round10(innings, -1);
		},
		getSortValueFunction: (row: TAmaFieldingOaaRow) => row.amaFieldingStatsByPosition[position]?.innings
	};
};

const createOppColumn = (position: string): TColumn<TAmaFieldingOaaRow, keyof TAmaFieldingOaaRow> => {
	return {
		value: `${position}Opp`,
		id: getOpportunityColumnId(position),
		label: <ExpandableOaaLabel totalOaaLabel="OPP" componentOaaLabels={["BOX", "BALL", "POS"]} isExpanded />,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		isMobile: true,
		getValueFunction: (row: TAmaFieldingOaaRow) => (
			<ExpandableOaaOpps
				totalOaaOpps={row.amaFieldingStatsByPosition[position]?.opportunities}
				componentOaaOpps={[
					row.amaFieldingStatsByPosition[position]?.pctBoxScore,
					row.amaFieldingStatsByPosition[position]?.pctBallTracking,
					row.amaFieldingStatsByPosition[position]?.pctPlayerTracking
				]}
				isExpanded
			/>
		),
		getSortValueFunction: (row: TAmaFieldingOaaRow) => row.amaFieldingStatsByPosition[position]?.opportunities,
		getColorGradientValueFunction: (row: TAmaFieldingOaaRow): number | null =>
			getPositionProportionFromAmaFieldingOaa(row.amaFieldingStatsByPosition[position]),
		colorGradientFunction: proportionColorGradientFunction
	};
};

const createOaaColumn = (position: string): TColumn<TAmaFieldingOaaRow, keyof TAmaFieldingOaaRow> => {
	return {
		value: `${position}Oaa`,
		id: getOaaColumnId(position),
		label: `OAA`,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		isMobile: true,
		getValueFunction: (row: TAmaFieldingOaaRow) => {
			if (row.amaFieldingStatsByPosition === undefined) return NULL_FILLER_TEXT;
			const oaa = row.amaFieldingStatsByPosition[position]?.oaaCentered ?? null;
			return oaa === null ? NULL_FILLER_TEXT : round10(oaa, -1);
		},
		getSortValueFunction: (row: TAmaFieldingOaaRow) => row.amaFieldingStatsByPosition[position]?.oaaCentered,
		getColorGradientValueFunction: (row: TAmaFieldingOaaRow): number | null => {
			const meanOaa: number | null | undefined = row.amaFieldingStatsByPosition[position]?.meanOaa;
			const sdOaa: number | null | undefined = row.amaFieldingStatsByPosition[position]?.sdOaa;
			const oaa: number | null | undefined = row.amaFieldingStatsByPosition[position]?.oaaCentered;
			const zScore = getZScore(meanOaa, sdOaa, oaa);
			if (zScore === null) return null;
			if (zScore < -3) return 0;
			if (zScore < -2) return 1;
			if (zScore < -1) return 2;
			if (zScore < -0.5) return 3;
			if (zScore < 0.5) return 4;
			if (zScore < 1) return 5;
			if (zScore < 2) return 6;
			if (zScore < 3) return 7;
			return 8;
		},
		colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
	};
};

const createPositionGroupColumns = (position: string): Array<TColumn<TAmaFieldingOaaRow, keyof TAmaFieldingOaaRow>> => {
	return [createInningsColumn(position), createOppColumn(position), createOaaColumn(position)];
};

export const createPositionToColumnsMapping = (positions: Array<string>) => {
	const positionToColumnMapping: Record<string, Array<TColumn<TAmaFieldingOaaRow, keyof TAmaFieldingOaaRow>>> = {};
	positions.forEach((position: string) => (positionToColumnMapping[position] = createPositionGroupColumns(position)));
	return positionToColumnMapping;
};

export const createPositionsToParentColumnsMapping = (positions: Array<string>): Array<TParentColumn> => {
	return positions.map((position: string) => {
		return {
			label: position.toLocaleUpperCase(),
			id: position,
			childColumnIds: [getInningColumnId(position), getOpportunityColumnId(position), getOaaColumnId(position)]
		};
	});
};
