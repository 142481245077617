import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import { ITwentyEightyGradeDistribution } from "_react/shared/data_models/dataviz/_types";

// twenty eighty grade distribution response

export const fetchTwentyEightyGradeDistribution = (cancelToken?: CancelToken) =>
	fetchResource<undefined, Array<ITwentyEightyGradeDistribution>>(
		"/twenty_eighty_grade_distribution",
		undefined,
		cancelToken
	);
