import React, { FunctionComponent, useRef, useMemo } from "react";
import styled from "@emotion/styled";
import { IconProps } from "_react/shared/legacy/ui/icons";
import { Card } from "_react/shared/legacy/ui/Card";
import { CardActions } from "_react/shared/legacy/ui/CardActions";
import { Button, TButtonClickEvent } from "_react/shared/legacy/ui/Button";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorScheme, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { $TSFixMe } from "utils/tsutils";
import { useOnClickOutside, useWindowSize } from "_react/_hooks";

type DialogProps = {
	cardOverflow?: string;
	contentOverflow?: string;
	children: $TSFixMe;
	colorScheme?: ColorScheme;
	Icon?: ({ fill, ...props }: IconProps) => JSX.Element;
	minHeight?: string | number;
	minWidth?: string | number;
	open?: boolean;
	onClose?: Function;
	header?: $TSFixMe;
	actions?: $TSFixMe;
	isBackgroundDisabled?: boolean; // Normally you want the background to be blurred and disabled. Otherwise set to false
	noActions?: boolean;
};

const StyledDialogBackground = styled.div<{ isVisible: boolean }>(
	{
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(0, 0, 0, 0.7)",
		zIndex: 1002,
		height: "100%"
	},
	({ isVisible }) => ({
		visibility: isVisible ? "visible" : "hidden"
	})
);

const StyledDialogWrapper = styled.div({
	position: "relative",
	height: "100%",
	width: "100%"
});

const StyledDialog = styled("div")({
	position: "absolute",
	left: "50%",
	top: "50%",
	transform: "translate(-50%,-50%)",
	overflow: "hide"
});

const StyledDialogContents = styled("div")<$TSFixMe>(
	({ maxHeight, maxWidth, minHeight, minWidth, contentOverflow }) => {
		return {
			minWidth,
			minHeight,
			maxHeight,
			maxWidth,
			overflow: contentOverflow
		};
	}
);

export const Dialog: FunctionComponent<DialogProps> = ({
	cardOverflow = "visible",
	children,
	colorScheme = defaultColorScheme.primary,
	Icon,
	minHeight,
	minWidth,
	open = false,
	onClose = () => null,
	header,
	actions = null,
	contentOverflow = "scroll",
	isBackgroundDisabled = true,
	noActions = false
}) => {
	const dialogRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(dialogRef, onClose, !open);
	const size = useWindowSize();

	const defaultCardActions = useMemo(
		() => (
			<CardActions style={{ marginTop: "5px" }}>
				<Button colorScheme={colorScheme} onClick={onClose as TButtonClickEvent} title="Close" />
			</CardActions>
		),
		[colorScheme, onClose]
	);
	const cardActionsUsed = actions != null ? actions : noActions ? null : defaultCardActions;

	const maxWidth = size.width * 0.9;
	const maxHeight = size.height * 0.8;
	const minWidthUsed = minWidth == null || minWidth < maxWidth ? minWidth : maxWidth;
	const minHeightUsed = minHeight == null || minHeight < maxHeight ? minHeight : maxHeight;

	if (!isBackgroundDisabled) {
		return open ? (
			<StyledDialog>
				<Card colorScheme={colorScheme} Icon={Icon} overflow={cardOverflow}>
					<ColoredCardHeader colorScheme={colorScheme} text={header} />
					<StyledDialogContents
						maxHeight={maxHeight}
						maxWidth={maxWidth}
						minHeight={minHeightUsed}
						minWidth={minWidthUsed}
						contentOverflow={contentOverflow}
					>
						{children}
					</StyledDialogContents>
					{cardActionsUsed}
				</Card>
			</StyledDialog>
		) : null;
	}
	return (
		<StyledDialogBackground isVisible={open}>
			<StyledDialogWrapper>
				<StyledDialog ref={dialogRef}>
					<Card colorScheme={colorScheme} Icon={Icon} overflow={cardOverflow}>
						<ColoredCardHeader colorScheme={colorScheme} text={header} />
						<StyledDialogContents
							maxHeight={maxHeight}
							maxWidth={maxWidth}
							minHeight={minHeightUsed}
							minWidth={minWidthUsed}
							contentOverflow={contentOverflow}
						>
							{children}
						</StyledDialogContents>
						{cardActionsUsed}
					</Card>
				</StyledDialog>
			</StyledDialogWrapper>
		</StyledDialogBackground>
	);
};
