import React from "react";
import dayjs from "dayjs";
import { HStack, Text, Stat, StatNumber, StatArrow, StatHelpText, Box } from "@chakra-ui/react";

import { POSITION_GROUPS_POSITION_PLAYER, POSITION_GROUPS_PITCHER } from "_react/shared/_constants/position_groups";
import { round10 } from "_react/shared/_helpers/numbers";
import { getPositionGroupLabel } from "_react/shared/_helpers/position_groups";
import { SV_COLOR_GRADIENT_FUNCTION } from "_react/playerpage/_constants";
import SparklinePlot from "_react/shared/ui/presentation/plots/SparklinePlot/SparklinePlot";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { IAmaProspectValueDraftedPlayer } from "_react/shared/data_models/phred/_types";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";
import Check from "_react/shared/ui/icons/Check";
import { getMostRecentDraftPick } from "_react/shared/data_models/player/_helpers";
import {
	getPlotDataForPoints,
	getXAxisLabels
} from "_react/shared/ui/data/tables/AmaSurplusValueSimilarPlayersTable/_helpers";
import {
	TSvData,
	TAmaSurplusValueSimilarPlayer
} from "_react/shared/ui/data/tables/AmaSurplusValueSimilarPlayersTable/_types";
import { convertToCurrency } from "_react/shared/ui/data/tables/SeasonalSurplusValuesTable/_helpers";

export const NULL_VALUE = null;
export const NULL_FILLER_TEXT = "-";

// Earliest year we have AMA predictions for
export const MIN_R4_YEAR = 2002;
// Earliest year we have pro model SV for
export const DEFAULT_MIN_R4_YEAR = 2013;

// We want to sort first by draft year, then by overall pick, but the shared table sort function only takes one value.
// As a workaround, define an upper bound on the number of players that could potentially be drafted in a given year.
// We generate a decimal by dividing overall pick by this upper bound, and add it to the year.
// This allows us to sort based on the year and break ties based on the overall pick.
export const PLAYERS_DRAFTED_UPPER_BOUND = 100000;

export const NUMBER_OF_SEASONS = 4;

export const TOOLTIP_AMA_SURPLUS_VALUE =
	"Amateur Surplus Value (AMA SV) is a forecast of the player's expected production at the Major League level\
	relative to expected compensation over his team control years. Due to the long and uncertain time-horizon for amateur players to realize surplus value,\
	the amateur model predicts an expectation of production in units of wins above replacement over the full controllable period (xWAR). A separate mapping\
	approximates expected compensation by converting xWAR to AMA SV.";
export const TOOLTIP_AMA_SV_OVER_TIME =
	"SV Over Time illustrates how a player's surplus value evolved from their\
	platform year as an amateur (left-most point) through their third season in domestic, professional baseball (right-most point).";
export const TOOLTIP_AMA_SV_CHANGE =
	"SV CHNG shows the player's current surplus value, along with an arrow depicting the direction of change for the player's\
	surplus value relative to their platform year as an amateur (a blue, downward-facing arrow indicates a decrease, while a green, upward-facing arrow indicates\
	an increase). The magnitude of the surplus value change is shown to the right of the arrow.";
export const TOOLTIP_HIGHEST_LEVEL =
	"This column shows the highest level of domestic, professional baseball the corresponding player has reached.";

const MIN_SV = 0;
const MAX_SV = 125;

export const POSITION_GROUPS_POSITION_PLAYER_OPTIONS = POSITION_GROUPS_POSITION_PLAYER.map((positionGroup: string) => {
	return {
		label: getPositionGroupLabel(positionGroup),
		value: positionGroup
	};
});

export const POSITION_GROUPS_PITCHER_OPTIONS = POSITION_GROUPS_PITCHER.map((positionGroup: string) => {
	return { label: positionGroup, value: positionGroup };
});

export const BATS_THROWS_COLUMN = {
	value: "bt",
	label: "B/T",
	valueType: VALUE_TYPE_STRING as TValueType,
	width: 16,
	isMobile: false,
	getValueFunction: (row: TAmaSurplusValueSimilarPlayer) => {
		return `${row.player?.bats ?? NULL_FILLER_TEXT}/${row.player?.throws ?? NULL_FILLER_TEXT}`;
	}
};

export const SV_COLUMN = {
	value: "sv",
	label: "SV",
	tooltip: TOOLTIP_AMA_SURPLUS_VALUE,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	width: 20,
	getValueFunction: (row: TAmaSurplusValueSimilarPlayer) =>
		row.xsurplusMillions !== null ? convertToCurrency(row.xsurplusMillions) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TAmaSurplusValueSimilarPlayer) => row.xsurplusMillions,
	getColorGradientValueFunction: (row: TAmaSurplusValueSimilarPlayer) => row.xsurplusMillions,
	colorGradientFunction: SV_COLOR_GRADIENT_FUNCTION
};

export const SV_OVER_TIME_COLUMN = {
	value: "sv_over_time",
	label: "SV OVER TIME",
	tooltip: TOOLTIP_AMA_SV_OVER_TIME,
	width: 44,
	isMobile: false,
	getValueFunction: (row: TAmaSurplusValueSimilarPlayer) => {
		const plotDataForPoints = getPlotDataForPoints(row?.parsedPlotData, row?.parsedR4Year);
		const xAxisLabels = getXAxisLabels(plotDataForPoints);

		return (
			<SparklinePlot<TSvData>
				xValue="date"
				yValue="sv"
				getYValueFormattedFunction={(y: number) => `$${round10(y, -1)}M`}
				xAxisExtrema={{
					min: dayjs(`${row?.parsedR4Year}-01-01`).toDate(),
					max: row?.parsedR4Year ? dayjs(`${row.parsedR4Year + 3}-01-01`).toDate() : undefined
				}}
				yAxisExtrema={{ min: MIN_SV, max: MAX_SV }}
				xTickLabels={xAxisLabels}
				plotData={row?.parsedPlotData}
				pointsOnLine={{ showPoints: true, pointData: plotDataForPoints }}
				// Use baseline data from the first point of the plot data
				baselineData={
					row?.parsedPlotData?.length && row?.parsedPlotData.length > 1
						? { y: row?.parsedPlotData[0].sv, includeDifferenceFill: true }
						: undefined
				}
				width={150}
				height={45}
			/>
		);
	},
	getSortValueFunction: (row: TAmaSurplusValueSimilarPlayer) => row.xsurplusMillions
};

export const SV_CHANGE_COLUMN = {
	value: "sv_change",
	label: "SV Chng",
	tooltip: TOOLTIP_AMA_SV_CHANGE,
	key: "sv_change",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	width: 20,
	isMobile: false,
	getValueFunction: (row: TAmaSurplusValueSimilarPlayer) => {
		const finalChangeDatum = row?.parsedPlotData?.length
			? row.parsedPlotData[row.parsedPlotData.length - 1]
			: undefined;
		const finalChangeFormatted = finalChangeDatum?.totalDifference
			? `${round10(Math.abs(finalChangeDatum.totalDifference), -1)}`
			: undefined;
		return (
			<Box marginTop={1}>
				{row?.parsedR4Year != null &&
					finalChangeDatum?.season === row.parsedR4Year + 3 &&
					finalChangeDatum?.sv != null && (
						<Stat size="xs">
							<StatNumber fontWeight="normal">{`$${round10(finalChangeDatum.sv, -1)}M`}</StatNumber>
							{finalChangeFormatted && (
								<>
									<StatHelpText>
										{finalChangeDatum.totalDifference && finalChangeDatum.totalDifference !== 0 && (
											<StatArrow
												type={finalChangeDatum?.totalDifference > 0 ? "increase" : "decrease"}
												color={finalChangeDatum?.totalDifference > 0 ? "green.500" : "blue.400"}
											/>
										)}
										{finalChangeFormatted}
									</StatHelpText>
								</>
							)}
						</Stat>
					)}
			</Box>
		);
	},
	getSortValueFunction: (row: TAmaSurplusValueSimilarPlayer) => {
		const finalSv = row?.parsedPlotData?.length ? row.parsedPlotData[row.parsedPlotData.length - 1].sv : null;
		return finalSv;
	}
};

export const DRAFT_YEAR_PICK_COLUMN = {
	value: "draft_year_pick",
	label: "Draft Yr (Pick)",
	width: 36,
	isMobile: true,
	getValueFunction: (row: TAmaSurplusValueSimilarPlayer) => {
		const mostRecentDraftPick: IAmaProspectValueDraftedPlayer | null = row?.player
			? getMostRecentDraftPick(row.player)
			: null;
		// Has a draft pick and was actually drafted
		const hasMostRecentDraftPick =
			mostRecentDraftPick !== null &&
			mostRecentDraftPick.r4Status === "Drafted" &&
			mostRecentDraftPick.r4Year === row.r4Year;
		return (
			<HStack>
				<Check boxSize={5} sx={{ visibility: hasMostRecentDraftPick ? undefined : "hidden" }}></Check>
				<Text>
					{hasMostRecentDraftPick
						? `${mostRecentDraftPick?.r4Year ?? NULL_FILLER_TEXT}${
								mostRecentDraftPick?.r4Overall ? ` (${mostRecentDraftPick.r4Overall})` : ""
						  }`
						: `${row?.r4Year ?? NULL_FILLER_TEXT}`}
				</Text>
			</HStack>
		);
	},
	getSortValueFunction: (row: TAmaSurplusValueSimilarPlayer) => {
		const mostRecentDraftPick: IAmaProspectValueDraftedPlayer | null = row?.player
			? getMostRecentDraftPick(row.player)
			: null;
		const sortYear = mostRecentDraftPick?.r4Year ?? row?.r4Year ?? NULL_VALUE;
		const sortPick = mostRecentDraftPick?.r4Overall ?? NULL_VALUE;
		return sortYear ? sortYear + (sortPick ? sortPick / PLAYERS_DRAFTED_UPPER_BOUND : 0) : NULL_VALUE;
	}
};

export const HIGHEST_LEVEL_COLUMN = {
	value: "highest_lvl",
	label: "Highest Lvl",
	tooltip: TOOLTIP_HIGHEST_LEVEL,
	width: 20,
	isMobile: true,
	getValueFunction: (row: TAmaSurplusValueSimilarPlayer) => (
		<TeamLevelBadge style={{ fontSize: "l" }} level={row.player?.proProfile?.highestLevelAppearance} />
	),
	getSortValueFunction: (row: TAmaSurplusValueSimilarPlayer) =>
		row.player?.proProfile?.highestLevelAppearance ?? NULL_VALUE
};
