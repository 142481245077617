//
// Our axios configuration for the inference API slice to be reused throughout the application
//
import axios from "axios";

import { cookies } from "utils/redux_constants";

export const _axiosInference = axios.create({
	baseURL: process.env.REACT_APP_INFERENCE_API_URL,
	headers: {
		Authorization: cookies.get("token")
	}
});

// TODO not sure if this is the fix for okta logins
_axiosInference.interceptors.request.use(
	config => {
		if (cookies.get("token")) {
			config.headers.common.Authorization = cookies.get("token");
		}
		return config;
	},
	function(error) {
		return Promise.reject(error);
	}
);

export default _axiosInference;
