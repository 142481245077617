import React, { FC } from "react";
import styled from "@emotion/styled";

const MajorSectionBorder = styled.div({
	borderRadius: "3px",
	padding: "6px",
	paddingTop: "10px",
	position: "relative",
	marginBottom: "10px",
	pageBreakInside: "avoid"
});

const MajorSectionTitle = styled.div({
	margin: "auto",
	fontSize: "14px",
	padding: "6px 8px",
	borderRadius: "3px",
	backgroundColor: "black",
	color: "white",
	fontWeight: 900,
	display: "inline-block",
	marginBottom: "10px"
});

const MajorSectionTitleWrapper = styled.div({
	textAlign: "center"
});

type MajorSectionProps = {
	title: string;
	children: React.ReactNode;
	style?: React.CSSProperties;
	id?: string;
};

export const MajorSection: FC<MajorSectionProps> = ({ title, children, style, id }) => {
	return (
		<MajorSectionBorder className="noPageBreak" id={id} style={style}>
			<MajorSectionTitleWrapper>
				<MajorSectionTitle>{title}</MajorSectionTitle>
			</MajorSectionTitleWrapper>
			{children}
		</MajorSectionBorder>
	);
};

const MinorSectionBorder = styled.div({
	border: "2px solid gray",
	borderRadius: "3px",
	padding: "6px",
	paddingTop: "10px",
	position: "relative",
	pageBreakInside: "avoid"
});

const MinorSectionTitle = styled.div({
	position: "absolute",
	top: "-9px",
	backgroundColor: "white",
	color: "gray",
	padding: "0px 5px"
});

type MinorSectionProps = {
	title?: string;
	children: React.ReactNode;
	style?: React.CSSProperties;
};

export const MinorSection: FC<MinorSectionProps> = ({ title, children, style }) => {
	return (
		<MinorSectionBorder className="noPageBreak" style={style}>
			<MinorSectionTitle>{title}</MinorSectionTitle>
			{children}
		</MinorSectionBorder>
	);
};
