import styled from "@emotion/styled";

import { TPartialStyles } from "utils/tsutils";

export const TabContainerDiv = styled.div({
	display: "flex",
	flexDirection: "column",
	height: "100%"
});

export const ToolbarDiv = styled.div({
	display: "flex",
	justifyContent: "space-between",
	boxSizing: "border-box",
	paddingBottom: 5
});

export const ToolbarSectionDiv = styled.div<TPartialStyles<"flex" | "justifyContent">>(({ flex, justifyContent }) => ({
	flex,
	display: "flex",
	justifyContent,
	boxSizing: "border-box",
	paddingLeft: 5,
	paddingRight: 5,
	":first-of-type": {
		paddingLeft: 0
	},
	":last-of-type": {
		paddingRight: 0
	}
}));

export const ToolbarItemDiv = styled.div<TPartialStyles<"minWidth">>(({ minWidth }) => ({
	// flex: 1,
	boxSizing: "border-box",
	paddingLeft: 3,
	paddingRight: 3,
	minWidth,
	":first-of-type": {
		paddingLeft: 0
	},
	":last-of-type": {
		paddingRight: 0
	}
}));
