import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function Assignment(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1m2 14H7v-2h7zm3-4H7v-2h10zm0-4H7V7h10z"
			/>
		</Icon>
	);
}
