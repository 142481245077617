import { CancelToken } from "axios";

import { postResource } from "_react/shared/_helpers/axios";
import { SLICE_INFERENCE } from "_react/shared/_constants/axios";

import { IIntrinsicValue } from "_react/shared/data_models/inference/_types";

export type TIntrinsicValueHeatmapPostBody = {
	// Which heatmap data to generate
	playerId: number;
	pitcherName?: string;
	season?: number;
	throws?: string;
	bats?: string;
	countSplit?: string;
	theCount?: string;
	pitchType?: string;
	pitchTypeGrouping?: string;
	batsRight?: boolean;
	throwsRight?: boolean;
	platoonAdv?: boolean;

	// Override values from the database
	releaseVelo?: number;
	pfxXDragless?: number;
	pfxZDragless?: number;
	releaseX?: number;
	releaseZ?: number;
	extension?: number;
	armSlot?: number;
	sswX?: number;
	sswZ?: number;
	veloDiff?: number;
	pfxXDraglessDiff?: number;
	pfxZDraglessDiff?: number;
	total?: number;

	// The number of locations to generate
	numLocX?: number;
	numLocZ?: number;

	// General API params
	offset?: number;
	limit?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const postIntrinsicValueHeatmap = (body: TIntrinsicValueHeatmapPostBody, cancelToken?: CancelToken) =>
	postResource<TIntrinsicValueHeatmapPostBody, Array<IIntrinsicValue>>(
		"/inference/intrinsic-value/heatmap",
		body,
		undefined,
		cancelToken,
		SLICE_INFERENCE
	);
