import React, { FC, useState, useMemo } from "react";
import { Editor, EditorState, RichUtils } from "draft-js";

import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { TRockyNoteStructure, TColorDict, TColor } from "_react/shared/data_models/notes/_types";
import {
	TEXT_FIELD_STYLE,
	LongTextFieldWrapper,
	ButtonContentsContainer,
	LINK_BUTTON_ICON_STYLE,
	TEXT_EDITOR_BUTTON_CONTAINER,
	TEXT_EDITOR_BUTTON_STYLE,
	LinkHelpTextSpan,
	RICH_TEXT_STYLES,
	TEXT_COLORS,
	MENU_ITEM_STYLE,
	CHECK_STYLE,
	CheckPlaceholder
} from "_react/playerpage/notes/_styles";
import { humanize } from "_react/playerpage/notes/_helpers";
import LinkIcon from "_react/shared/legacy/ui/icons/Link";
import Cancel from "_react/shared/legacy/ui/icons/Cancel";
import Delete from "_react/shared/legacy/ui/icons/Delete";
import FormatListBulleted from "_react/shared/legacy/ui/icons/FormatListBulleted";
import FormatListNumbered from "_react/shared/legacy/ui/icons/FormatListNumbered";
import { TextField } from "_react/shared/legacy/ui/TextField";
import { Button } from "_react/shared/legacy/ui/Button";
import { Menu } from "_react/shared/legacy/ui/Menu";
import { MenuItem } from "_react/shared/legacy/ui/MenuItem";
import Check from "_react/shared/legacy/ui/icons/Check";
import { useIsMobile } from "_react/_hooks";

type RichTextEditorProps = {
	showingEditUI: boolean;
	content: EditorState;
	structure: TRockyNoteStructure;
	disabled: boolean;
	editing: boolean;
	saving: boolean;
	datepickerClassName: string;
	colorScheme: ColorSchemeGroup;
	handleEditNote: (note?: string | number | EditorState | null) => void;
};

export const RichTextEditor: FC<RichTextEditorProps> = ({
	showingEditUI,
	handleEditNote,
	content,
	structure,
	disabled,
	colorScheme,
	editing,
	saving,
	datepickerClassName
}) => {
	const isMobile = useIsMobile();
	const [showingLinkURL, setShopwingLinkURL] = useState(false);
	const [linkURL, setLinkURL] = useState<string | null>(null);
	const [colorAnchorEl, setColorAnchorEl] = useState<HTMLButtonElement | null>();
	const [colorPickerField, setColorPickerField] = useState<string | null>(null);

	const highlightingText = !content.getSelection().isCollapsed();

	const highlightingALink = useMemo(() => {
		const contentState = content.getCurrentContent();
		const startKey = content.getSelection().getStartKey();
		const startOffset = content.getSelection().getStartOffset();
		const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
		const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
		return linkKey != null;
	}, [content]);

	const linkButtonAction = (showingLinkURL: boolean) => () => {
		if (showingLinkURL) {
			const contentState = content.getCurrentContent();
			const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", { url: linkURL });
			const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
			const newEditorState = EditorState.set(content, { currentContent: contentStateWithEntity });
			handleEditNote(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
			setLinkURL(null);
			setShopwingLinkURL(false);
		} else {
			const contentState = content.getCurrentContent();
			const startKey = content.getSelection().getStartKey();
			const startOffset = content.getSelection().getStartOffset();
			const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
			const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
			if (linkKey) {
				const linkInstance = contentState.getEntity(linkKey);
				const url = linkInstance.getData().url;
				setLinkURL(url);
			}
			setShopwingLinkURL(true);
		}
	};

	const removeLink = () => {
		const selection = content.getSelection();
		if (!selection.isCollapsed()) {
			handleEditNote(RichUtils.toggleLink(content, selection, null));
			setLinkURL(null);
			setShopwingLinkURL(false);
		}
	};

	const toggleStyle = (property: string) => () => {
		handleEditNote(RichUtils.toggleInlineStyle(content, property));
	};

	const hasStyle = (property: string) => content.getCurrentInlineStyle().has(property);

	const toggleBlockType = (property: string) => () => {
		handleEditNote(RichUtils.toggleBlockType(content, property));
	};

	const handleKeyCommand = (command: string) => {
		const newState = RichUtils.handleKeyCommand(content, command);
		if (newState != null) {
			handleEditNote(newState);
			return "handled";
		}
		return "not-handled";
	};

	const hasBlockTyoe = (property: string) => {
		const selection = content.getSelection();
		return (
			content
				.getCurrentContent()
				.getBlockForKey(selection.getStartKey())
				.getType() === property
		);
	};

	const getEditFunction = (category: string) => {
		if (category === "style") return toggleStyle;
		return toggleBlockType;
	};

	const getHasFunction = (category: string) => {
		if (category === "style") return hasStyle;
		return hasBlockTyoe;
	};

	const removeAllColors = () =>
		Object.keys(TEXT_COLORS).reduce((compositeEditorState, textColor) => {
			const property = `${textColor}${colorPickerField === "highlightColor" ? "Highlight" : ""}`;
			if (hasStyle(property)) {
				const newStyle = RichUtils.toggleInlineStyle(compositeEditorState, property);
				return newStyle;
			}
			return compositeEditorState;
		}, content);

	const currentColor = useMemo(
		() =>
			Object.keys(TEXT_COLORS).reduce((foundColor: string | undefined, textColor: string) => {
				if (foundColor != null) return foundColor;
				if (content.getCurrentInlineStyle().has(textColor))
					return (TEXT_COLORS[textColor as keyof typeof TEXT_COLORS] as TColor).color;
				return undefined;
			}, undefined),
		[content]
	);

	const currentHighlightColor = useMemo(
		() =>
			Object.keys(TEXT_COLORS).reduce((foundColor: string | undefined, textColor: string) => {
				if (foundColor != null) return foundColor;
				if (content.getCurrentInlineStyle().has(`${textColor}Highlight`))
					return (TEXT_COLORS[textColor as keyof typeof TEXT_COLORS] as TColor).color;
				return undefined;
			}, undefined),
		[content]
	);

	const handleColorPicked = (name: string) => {
		handleEditNote(
			RichUtils.toggleInlineStyle(
				removeAllColors(),
				`${name}${colorPickerField === "highlightColor" ? "Highlight" : ""}`
			)
		);
	};

	const STYLE_BUTTONS = [
		{ key: "BOLD", category: "style", title: "B", style: { fontWeight: 900 } },
		{ key: "UNDERLINE", category: "style", title: "U", style: { textDecoration: "underline" } },
		{ key: "ITALIC", category: "style", title: "I", style: { fontStyle: "italic" } },
		{ key: "STRIKETHROUGH", category: "style", title: "S", style: { textDecoration: "line-through" } },
		{
			key: "ordered-list-item",
			category: "block_type",
			title: (
				<FormatListNumbered
					fill={colorScheme[hasBlockTyoe("ordered-list-item") ? "primary" : "secondary"].text}
					style={LINK_BUTTON_ICON_STYLE}
				/>
			),
			style: {}
		},
		{
			key: "unordered-list-item",
			category: "block_type",
			title: (
				<FormatListBulleted
					fill={colorScheme[hasBlockTyoe("unordered-list-item") ? "primary" : "secondary"].text}
					style={LINK_BUTTON_ICON_STYLE}
				/>
			),
			style: {}
		},
		{
			key: "text-color",
			onClick: () => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				setColorPickerField("textColor");
				setColorAnchorEl(e.currentTarget);
			},
			title: "A",
			style: {
				fontWeight: 900,
				color:
					currentColor == null || currentColor === TEXT_COLORS.black.color
						? TEXT_COLORS.red.color
						: currentColor === TEXT_COLORS.white.color
						? TEXT_COLORS.black.color
						: currentColor
			}
		},
		{
			key: "highlight-color",
			onClick: () => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				setColorPickerField("highlightColor");
				setColorAnchorEl(e.currentTarget);
			},
			title: "A",
			style: {
				fontWeight: 900,
				backgroundColor: currentHighlightColor ?? TEXT_COLORS.black.color,
				color: currentHighlightColor === TEXT_COLORS.white.color ? "black" : "white",
				borderColor: currentHighlightColor ?? TEXT_COLORS.black.color
			}
		}
	];

	return (
		<div>
			<style>
				{`
					.${datepickerClassName} input {
						background-color: white;
						border: 1px solid #CCCCCC;
						border-radius: 3px;
						padding: 4px;
						font-size: 12px;
						min-width: 225px;
					}
					.${datepickerClassName} .react-datepicker__close-icon:after {
						background-color: ${colorScheme.primary.color};
						color: ${colorScheme.primary.text};
						height: 14px;
						width: 14px;
						font-weight: 900;
						font-size: 14px;
						padding: 0px
					}
				`}
			</style>
			{(editing || saving) && showingEditUI && (
				<div style={{ ...TEXT_EDITOR_BUTTON_CONTAINER, display: isMobile ? "block" : "flex" }}>
					<div style={{ display: "flex", alignItems: "center" }}>
						{STYLE_BUTTONS.map((styleButton, idx) => (
							<Button
								key={styleButton.key}
								colorScheme={
									styleButton.category && styleButton.key
										? getHasFunction(styleButton.category)(styleButton.key)
											? colorScheme.primary
											: colorScheme.secondary
										: colorScheme.secondary
								}
								onClick={
									styleButton.category && styleButton.key
										? getEditFunction(styleButton.category)(styleButton.key)
										: styleButton.onClick!()
								}
								title={styleButton.title}
								style={{ ...TEXT_EDITOR_BUTTON_STYLE(idx > 0), ...styleButton.style }}
								disabled={disabled}
							/>
						))}
					</div>
					<div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
						{showingLinkURL && (
							<TextField
								value={linkURL ? linkURL : ""}
								onChange={e => setLinkURL(e.target.value)}
								style={TEXT_FIELD_STYLE}
								disabled={disabled}
								placeholder="Link URL..."
							/>
						)}
						<Button
							colorScheme={colorScheme.primary}
							onClick={linkButtonAction(showingLinkURL)}
							title={
								<ButtonContentsContainer>
									<LinkIcon fill={colorScheme.primary.text} style={LINK_BUTTON_ICON_STYLE} />
									{highlightingALink ? (showingLinkURL ? "Save Edit" : "Edit Link") : "Add Link"}
								</ButtonContentsContainer>
							}
							style={TEXT_EDITOR_BUTTON_STYLE(!isMobile)}
							disabled={disabled || !highlightingText}
						/>
						{!showingLinkURL && !highlightingText && (
							<LinkHelpTextSpan>Highlight text to add a link</LinkHelpTextSpan>
						)}
						{showingLinkURL && (
							<Button
								colorScheme={colorScheme.primary}
								onClick={() => {
									setLinkURL(null);
									setShopwingLinkURL(false);
								}}
								title={
									<ButtonContentsContainer>
										<Cancel fill={colorScheme.primary.text} style={LINK_BUTTON_ICON_STYLE} />
										Cancel
									</ButtonContentsContainer>
								}
								style={TEXT_EDITOR_BUTTON_STYLE(true)}
								disabled={disabled || !highlightingText}
							/>
						)}
						{highlightingALink && highlightingText && (!isMobile || !showingLinkURL) && (
							<Button
								colorScheme={colorScheme.secondary}
								onClick={removeLink}
								title={
									<ButtonContentsContainer>
										<Delete fill={colorScheme.secondary.text} style={LINK_BUTTON_ICON_STYLE} />
										Remove Link
									</ButtonContentsContainer>
								}
								style={TEXT_EDITOR_BUTTON_STYLE(true)}
								disabled={disabled || !highlightingText}
							/>
						)}
					</div>
				</div>
			)}
			<LongTextFieldWrapper showingEditUI={showingEditUI}>
				<Editor
					customStyleMap={RICH_TEXT_STYLES}
					onChange={e => handleEditNote(e)}
					editorState={content as EditorState}
					readOnly={!showingEditUI || structure.readOnly || disabled}
					textAlignment="left"
					handleKeyCommand={handleKeyCommand}
				/>
			</LongTextFieldWrapper>
			<Menu
				anchorEl={colorAnchorEl}
				id="colorPicker"
				onClose={() => setColorAnchorEl(null)}
				open={Boolean(colorAnchorEl)}
			>
				{Object.keys(TEXT_COLORS).map(textColor => {
					const color = (TEXT_COLORS as TColorDict)[textColor];
					return (
						<MenuItem
							key={textColor}
							handleClick={() => {
								handleColorPicked(textColor);
								setColorAnchorEl(null);
							}}
							style={MENU_ITEM_STYLE}
						>
							<span
								style={{
									alignSelf: "center",
									margin: "3px",
									border: `2px solid ${color.color === TEXT_COLORS.white.color ? "black" : "white"}`,
									borderRadius: "3px",
									backgroundColor: color.color,
									height: "18px",
									width: "18px"
								}}
							/>
							{hasStyle(`${textColor}${colorPickerField === "highlightColor" ? "Highlight" : ""}`) ? (
								<Check style={CHECK_STYLE} />
							) : (
								<CheckPlaceholder />
							)}
							{humanize(textColor)}
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};
