import { createMachine, assign, StateFrom, AnyEventObject } from "xstate";
import axios, { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";
import queryString from "query-string";

import { $TSFixMe } from "utils/tsutils";
import rockyAxiosInstance from "_redux/_utils/_axios";
import _axiosInference from "_redux/_utils/_axios_inference";
import { BASE_URL, cookies } from "utils/redux_constants";
import { promiseWRetry } from "utils/helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { fetchUser } from "_react/shared/data_models/iam_new/_network";
import { IIamUserSchema, IIamUserGroupRouteSchema } from "_react/shared/data_models/iam_new/_types";
import { getCancelSource } from "utils/url_helpers";

import { LINKS } from "_react/app/links";
import { TLink } from "_react/app/_types";
import { filterLinks, getNonceFromToken, setUpOktaCookies, clearCookies, refreshOktaTokens } from "_react/app/_helpers";
import {
	OKTA_CLIENT,
	BASE_IDENTITY_PROVIDER_URL,
	OKTA_CLIENT_ID,
	OKTA_AXIOS_INSTANCE,
	OKTA_BOOKMARK_APP_REDIRECT_URI
} from "_react/app/_constants";

const USER_CANCEL_SOURCE = "user";

export type TAppCancelSource = {
	[USER_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TAppContext = {
	user?: IIamUserSchema | null;
	viewableRoutes: Array<TLink>;
	cancelSources: TAppCancelSource;
	toast?: CreateToastFnReturn;
};

export const SET_USER = "SET_USER";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FETCHING_USER = { initialized: { user: "fetching" } };

const FETCH_USER_DONE = "done.invoke.fetchingUser:invocation[0]";

type TSetUserEvent = {
	type: typeof SET_USER;
	data: IIamUserSchema | null | undefined;
};
type TLoginEvent = {
	type: typeof LOGIN;
	data: { username: string; password: string };
};
type TLogoutEvent = {
	type: typeof LOGOUT;
};
type TFetchUserEvent = {
	type: typeof FETCH_USER_DONE;
	data: IIamUserSchema | null | undefined;
};
export type TAppEvent = TSetUserEvent | TLoginEvent | TLogoutEvent | TFetchUserEvent;

const AppMachine = (toastProp?: CreateToastFnReturn) =>
	createMachine<TAppContext, TAppEvent>(
		{
			id: "app",
			initial: "initializing",
			strict: true,
			context: {
				user: undefined,
				viewableRoutes: [],
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					initial: "unauthenticated",
					on: {
						[SET_USER]: { actions: "setUser" }
					},
					states: {
						unauthenticated: {
							onEntry: "resetContext",
							always: [
								{
									target: "handleOktaCallback",
									cond: "isOktaCallback"
								},
								{
									target: "validatingOktaTokens",
									cond: "hasOktaRefreshToken"
								},
								{
									target: "validatingCustomAuthToken",
									cond: "hasCustomAuthToken"
								},
								{
									target: "#app.initialized.loginRequired"
								}
							]
						},
						handleOktaCallback: {
							invoke: {
								id: "handleOktaCallback",
								src: "handleOktaRedirectAuthCode",
								onDone: "#app.initialized.user",
								onError: "#app.initialized.unauthenticated"
							}
						},
						validatingOktaTokens: {
							initial: "checkingIdToken",
							states: {
								checkingIdToken: {
									invoke: {
										id: "oktaIdTokenValidator",
										src: "hasValidOktaIdToken",
										onDone: "#app.initialized.user",
										onError: "refreshingTokens"
									}
								},
								refreshingTokens: {
									invoke: {
										id: "oktaTokenRefresher",
										src: "refreshOktaTokens",
										onDone: "#app.initialized.user",
										onError: {
											target: "#app.initialized.loginRequired",
											actions: "handleExpiredLoginError"
										}
									}
								}
							}
						},
						validatingCustomAuthToken: {
							invoke: {
								id: "customAuthTokenValidator",
								src: "hasValidCustomAuthToken",
								onDone: "#app.initialized.user",
								onError: {
									target: "#app.initialized.loginRequired",
									actions: "handleExpiredLoginError"
								}
							}
						},
						user: {
							initial: "fetchingUserData",
							states: {
								fetchingUserData: {
									id: "fetchingUser",
									entry: ["refreshUserCancelSource"],
									invoke: {
										src: "fetchUser",
										onDone: {
											target: "#app.initialized.authenticated",
											actions: "handleFetchUserSuccess"
										},
										onError: {
											target: "#app.initialized.loginRequired",
											actions: "handleFetchUserErrored"
										}
									}
								}
							}
						},
						loginRequired: {
							initial: "idle",
							states: {
								idle: {},
								validatingOktaLogin: {
									invoke: {
										id: "oktaLogin",
										src: "oktaLogin",
										onDone: "#app.initialized.user",
										onError: {
											target: "#app.initialized.loginRequired",
											actions: "handleInvalidLoginError"
										}
									}
								},
								validatingCustomAuthLogin: {
									invoke: {
										id: "customAuthLogin",
										src: "customAuthLogin",
										onDone: "#app.initialized.user",
										onError: {
											target: "#app.initialized.loginRequired",
											actions: "handleInvalidLoginError"
										}
									}
								}
							},
							on: {
								[LOGIN]: [
									{
										target: ".validatingOktaLogin",
										cond: "isOktaLoginAttempt"
									},
									{
										target: ".validatingCustomAuthLogin"
									}
								]
							}
						},
						authenticated: {
							initial: "idle",
							on: {
								[LOGOUT]: {
									target: ".loggingOut"
								}
							},
							states: {
								idle: {},
								loggingOut: {
									invoke: {
										id: "logoutUser",
										src: "logoutUser",
										onDone: "#app.initialized.unauthenticated",
										onError: "#app.initialized.unauthenticated"
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				hasOktaRefreshToken: (_context: TAppContext, _event: TAppEvent) => {
					return cookies.get("refreshToken") != null;
				},
				hasCustomAuthToken: (_context: TAppContext, _event: TAppEvent) => cookies.get("token") != null,
				isOktaLoginAttempt: (_context: TAppContext, event: TAppEvent) => {
					if (event.type !== LOGIN) return false;
					return event.data.username.endsWith("phillies.com") || event.data.username.endsWith("gmail.com");
				},
				isOktaCallback: (_context: TAppContext, _event: TAppEvent) =>
					window.location.pathname === "/okta/callback"
			},
			actions: {
				resetContext: assign<TAppContext, TAppEvent>({
					user: (_context: TAppContext, _event: TAppEvent) => undefined,
					viewableRoutes: (_context: TAppContext, _event: TAppEvent) => []
				}),
				setUser: assign<TAppContext, TAppEvent>({
					user: (context: TAppContext, event: TAppEvent) => {
						if (event.type !== SET_USER) return context.user;
						return event.data;
					},
					cancelSources: (context: TAppContext, event: TAppEvent) => {
						if (event.type !== SET_USER) return context.cancelSources;
						if (context.cancelSources[USER_CANCEL_SOURCE] != null)
							context.cancelSources[USER_CANCEL_SOURCE].cancel();
						delete context.cancelSources[USER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				// Cancel Source Actions
				refreshUserCancelSource: assign<TAppContext, TAppEvent>({
					cancelSources: (context: TAppContext, _event: TAppEvent) => {
						if (context.cancelSources[USER_CANCEL_SOURCE] != null)
							context.cancelSources[USER_CANCEL_SOURCE].cancel();
						context.cancelSources[USER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchUserSuccess: assign<TAppContext, TAppEvent>({
					user: (context: TAppContext, event: TAppEvent) => {
						if (event.type !== FETCH_USER_DONE) return context.user;
						return event.data;
					},
					viewableRoutes: (context: TAppContext, event: TAppEvent) => {
						if (event.type !== FETCH_USER_DONE) return context.viewableRoutes;
						const routes = event.data?.routes?.map((route: IIamUserGroupRouteSchema) => route.route);
						return filterLinks(LINKS, routes);
					}
				}),
				// Errored Actions
				handleFetchUserErrored: (context: TAppContext, _event: TAppEvent) => {
					if (context.toast) {
						context.toast({
							title: "Login Failed",
							description: "Error fetching user data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
					}
				},
				handleExpiredLoginError: (context: TAppContext, _event: TAppEvent) => {
					if (context.toast)
						context.toast({
							title: "Login expired",
							description: "Login again with credentials.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				// Using $TSFixMe because the type of event is unknown and not trivial to type out
				handleInvalidLoginError: (context: TAppContext, event: $TSFixMe) => {
					if (event?.data?.response?.data === "Invalid password") {
						if (context.toast)
							context.toast({
								title: "Incorrect username or password",
								description: 'Use the "Forgot Password?" button if you dont know your credentials',
								...DEFAULT_TOAST_ERROR_PROPS
							});
					} else if (event?.data?.message === "Network Error") {
						if (context.toast)
							context.toast({
								title: "Network Error",
								description: "Check your internet connection or reach out on Slack",
								...DEFAULT_TOAST_ERROR_PROPS
							});
					} else {
						if (context.toast)
							context.toast({
								title: "Logon failed",
								description:
									"Please try logging in through phillies.okta.com. If you are unable to log into Okta please try resetting your password using the password station or by emailing helpdesk@phillies.com",
								...DEFAULT_TOAST_ERROR_PROPS
							});
					}
				}
			},
			services: {
				fetchUser: (context: TAppContext, _event: AnyEventObject) => {
					const fetchFunc = () => fetchUser({}, context.cancelSources[USER_CANCEL_SOURCE]?.token);
					return promiseWRetry(fetchFunc);
				},
				customAuthLogin: (_context: TAppContext, event: TAppEvent) => {
					if (event.type !== LOGIN) return Promise.resolve();
					const auth = {
						username: event.data.username.trim(),
						password: event.data.password.trim()
					};
					// Authenticate user
					return rockyAxiosInstance.get("/user/authenticate", { auth }).then((response: $TSFixMe) => {
						// Set up token cookie and default header
						cookies.set("token", response.data.message, { path: "/" });
						rockyAxiosInstance.defaults.headers.common["Authorization"] = response.data.message;
						_axiosInference.defaults.headers.Authorization = response.data.message;
					});
				},
				oktaLogin: (_context: TAppContext, event: TAppEvent) => {
					if (event.type !== LOGIN) return;
					return OKTA_CLIENT.signIn(event.data).then((signInResp: $TSFixMe) =>
						axios
							.get(`${BASE_URL}/okta/proxy/auth?session_token=${signInResp.data.sessionToken}`)
							.then(setUpOktaCookies)
					);
				},
				handleOktaRedirectAuthCode: () => {
					const { code } = queryString.parse(window.location.search);
					return rockyAxiosInstance
						.get(`/okta/authorization_flow?code=${code}&redirect_uri=${OKTA_BOOKMARK_APP_REDIRECT_URI}`)
						.then((response: $TSFixMe) => {
							// Set up Cookies
							setUpOktaCookies(response);
							// Clean up URL
							window.history.pushState({}, "", "/");
						})
						.catch(() => {
							window.history.pushState({}, "", "/");
							throw new Error();
						});
				},
				hasValidCustomAuthToken: (_context: TAppContext, _event: TAppEvent) =>
					rockyAxiosInstance.get("/token/valid"),
				hasValidOktaIdToken: (_context: TAppContext, _event: TAppEvent) => {
					return new Promise((resolve, reject) => {
						const decodedToken = OKTA_CLIENT.token.decode(cookies.get("idToken"));
						const nonceMatch = getNonceFromToken(cookies.get("idToken")) === cookies.get("nonce");
						const authServerMatch = decodedToken.payload.iss === BASE_IDENTITY_PROVIDER_URL;
						const audienceMatch = decodedToken.payload.aud === OKTA_CLIENT_ID;
						if (!nonceMatch || !authServerMatch || !audienceMatch) {
							reject(`
								Invalid Okta ID Token
								Nonce Match: ${nonceMatch}
								Auth Server Match: ${authServerMatch}
								Audience Match: ${audienceMatch}
							`);
						}
						OKTA_AXIOS_INSTANCE.get(`${BASE_URL}/okta/keys`).then((keysResponse: $TSFixMe) => {
							let signatureMatch = false;
							for (const key of keysResponse.data) {
								if (decodedToken.header.kid === key.kid) {
									signatureMatch = true;
									break;
								}
							}
							if (!signatureMatch) {
								reject("Invalid signature match");
							}
						});
						resolve("Current Okta ID Token is Valid");
					});
				},
				refreshOktaTokens: (_context: TAppContext, _event: TAppEvent) => refreshOktaTokens(),
				logoutUser: (_context: TAppContext, event: TAppEvent) => {
					if (event.type !== LOGOUT) return Promise.resolve();
					return new Promise<void>(resolve => {
						const oktaSignout = cookies.get("refreshToken") != null || cookies.get("idToken") != null;
						clearCookies();
						if (oktaSignout) {
							return OKTA_CLIENT.signOut()
								.then(() => resolve())
								.catch(() => resolve());
						}
						resolve();
					});
				}
			}
		}
	);

export type TAppState = StateFrom<typeof AppMachine>;

export default AppMachine;
