/*
 * Class of helper functions for various parts of the app
 */
import dayjs from "dayjs";

export function titleCase(phrase) {
	/* eslint-disable */
	return phrase.replace(/\b\w+/g, function(s) {
		return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
	});
	/* eslint-enable */
}

export function camelize(str) {
	return str.replace(/([-_][a-z])/gi, $1 => {
		return $1
			.toUpperCase()
			.replace("-", "")
			.replace("_", "");
	});
}

export function decimalToPercent(value, digitsAfterDecimal = 0, includeSign = false) {
	const numberStr = (value * 100).toFixed(digitsAfterDecimal);
	if (includeSign) {
		return `${numberStr}%`;
	}
	return numberStr;
}

function isDictNest(arg) {
	const isNonNestType = ["string", "number", "boolean"].includes(typeof arg);
	return !isNonNestType && arg != null && !Array.isArray(arg);
}

export function camelizeKeys(dict) {
	// Note: currently only works if inner things are strings, numbers, bools, arrays, or nested dicts
	return Object.entries(dict).reduce((dict, entry) => {
		const [key, val] = entry;
		const keyNew = camelize(key);
		const valNew = isDictNest(val) ? camelizeKeys(val) : val;
		dict[keyNew] = valNew;
		return dict;
	}, {});
}

export function promiseWRetry(
	promiseFunc,
	triesLeft = 3,
	interval = 50,
	exponentialIntervals = true,
	intervalMax = 5000
) {
	return new Promise((resolve, reject) => {
		promiseFunc()
			.then(resolve)
			.catch(error => {
				if (triesLeft === 0) {
					reject(error);
					return;
				}
				const intervalNext = exponentialIntervals ? Math.min(intervalMax, interval * 10) : interval;
				setTimeout(() => {
					promiseWRetry(promiseFunc, triesLeft - 1, intervalNext, exponentialIntervals, intervalMax).then(
						resolve,
						reject
					);
				}, interval);
			});
	});
}

export const getAgeFromBirthDate = (birth_date, ref_date = dayjs(), truncate = true, precise = false) => {
	const now = dayjs(ref_date);

	let birthDate = dayjs(birth_date, "YYYY-MM-DD");
	if (!birthDate.isValid()) {
		birthDate = dayjs(birth_date).format();
	} else {
		birthDate = birthDate.format("YYYY-MM-DD");
	}

	const age = now.diff(birthDate, "year", true);

	if (isNaN(age)) {
		return "";
	}
	if (truncate) return age.toString().slice(0, 4);
	// assumes ages are between 10 and 99
	else return precise ? age : age.toFixed(1);
};

// Helper method for rounding numbers
export const precisionRound = (number, precision = 2) => {
	const factor = Math.pow(10, precision);
	return Math.round(number * factor) / factor;
};

export const getCurrency = (amount, code = "USD") => {
	const amountNumber = amount ? amount : 0;
	const currencyFormatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: code,
		maximumFractionDigits: 0,
		minimumFractionDigits: 0
	});
	return currencyFormatter.format(amountNumber);
};

/*
    Helper function to control how often you can
    call a function
    https://github.com/paypal/downshift/blob/master/stories/examples/axios.js
 */
export const debounce = (fn, time) => {
	let timeoutId;

	function wrapper(...args) {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(() => {
			timeoutId = null;
			fn(...args);
		}, time);
	}

	return wrapper;
};

export const getCurrentDraftCutoffDate = draftDate => {
	const today = dayjs();
	const estimatedDraftDate = dayjs(draftDate);
	if (today.isBefore(estimatedDraftDate)) return `${estimatedDraftDate.subtract(1, "year")}`;
	return estimatedDraftDate;
};

export const convertDates = (data, dateFields) =>
	dateFields.reduce((correctedData, field) => {
		if (data[field] != null) {
			correctedData[field] = dayjs(data[field].split("T")[0]);
		}
		return correctedData;
	}, data);

export function isEqual(a, b) {
	if (Array.isArray(a)) {
		if (!Array.isArray(b) || a.length !== b.length) return false;
		for (let i = 0; i < a.length; i++) {
			if (!isEqual(a[i], b[i])) return false;
		}
		return true;
	}
	if (typeof a === "object" && a !== null && b !== null) {
		if (!(typeof b === "object")) return false;
		const keys = Object.keys(a);
		if (keys.length !== Object.keys(b).length) return false;
		for (const key in a) {
			if (!isEqual(a[key], b[key])) return false;
		}
		return true;
	}
	return a === b;
}

export function sortBy(data, sortOnFuncs) {
	return data.slice().sort((a, b) => {
		for (const sortOn of sortOnFuncs) {
			const attrA = sortOn(a);
			const attrB = sortOn(b);

			if (attrA == null && attrB == null) continue;
			if (attrA == null) return 1;
			else if (attrB == null) return -1;

			let result;
			result = attrA - attrB;
			if (isNaN(result)) {
				result = attrA.localeCompare(attrB);
			}
			if (result !== 0) {
				return result;
			}
		}
		return 0;
	});
}

export function pick(obj, allowedKeys) {
	return Object.keys(obj)
		.filter(key => allowedKeys.includes(key))
		.reduce((newObj, key) => {
			newObj[key] = obj[key];
			return newObj;
		}, {});
}

export function omit(obj, omitKeys) {
	return Object.keys(obj)
		.filter(key => !omitKeys.includes(key))
		.reduce((newObj, key) => {
			newObj[key] = obj[key];
			return newObj;
		}, {});
}

export function arrayRemove(array, itemToRemove) {
	return array.filter(value => {
		return value !== itemToRemove;
	});
}

export function isEmpty(obj) {
	if (obj == null) return true;
	if (Array.isArray(obj)) return obj.length === 0;
	if (typeof obj === "string") return obj === "";
	if (obj.constructor === Object) return Object.entries(obj).length === 0;
	return false;
}

export function cloneDeep(src) {
	const target = Array.isArray(src) ? [] : {};
	for (const prop in src) {
		if (Array.isArray(prop)) {
			// Array Clone
			target.push(cloneDeep(prop));
		} else {
			// Object  Clone
			if (src.hasOwnProperty(prop)) {
				if (src[prop] != null && typeof src[prop] === "object") {
					target[prop] = cloneDeep(src[prop]);
				} else {
					target[prop] = src[prop];
				}
			}
		}
	}
	return target;
}

export function setCookieRaw(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookieRaw(cname) {
	const name = cname + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		const c = ca[i].trimLeft();
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}
