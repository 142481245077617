import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const fields = [
	{
		title: "FB Velocity",
		overall: "This grade is tied to our FB velocity scale based off the comfort zone observed."
	},
	{
		title: "FB Movement",
		overall: "This grade is to describe the quality of sink, run, ride, bore or cut on the pitchers FB."
	},
	{
		title: "FB Command",
		overall: "This grade represents how well the pitcher throws his FB where he wants to."
	},
	{
		title: "FB Quality",
		overall:
			"This grade represents the overall quality of the FB. How well the FB plays when you take into account the velocity, command, deception and movement."
	},
	{
		title: "CB",
		overall: "This grade represents the quality of the CB based upon its break, depth, command and velocity."
	},
	{
		title: "CB Command",
		overall: "This grade represents how well the pitcher throws his CB where he wants to."
	},
	{
		title: "SL",
		overall: "This grade represents the quality of the SL based upon its break, depth, command and velocity."
	},
	{
		title: "SL Command",
		overall: "This grade represents how well the pitcher throws his SL where he wants to."
	},
	{
		title: "CH",
		overall: "This grade represents the quality of the CH based upon its movement, deception, command and velocity."
	},
	{
		title: "CH Command",
		overall: "This grade represents how well the pitcher throws his CH where he wants to."
	},
	{
		title: "OTH",
		overall:
			"This grade represents the quality of another pitch not listed above (Cutter, Split, Knuckleball etc.) based upon its action, deception, command and velocity."
	},
	{
		title: "OTH Command",
		overall: "This grade represents how well the pitcher throws the specified pitch where he wants too."
	},
	{
		title: "Can He Pitch",
		overall:
			"This grades identifies to what extent a pitcher can use his pitches, command, instincts and velocity to have success."
	}
];

const tables = [
	{
		title:
			"Average Major League starting pitcher performance by rotation tier from 2016-2018   (130+ innings pitched)",
		columns: ["Rotation Tier", "GS", "IP", "K/9", "BB/9", "HR/9", "GB%", "WHIP", "ERA", "FIP", "ERA+"],
		rows: [
			["ACE", "32", "205", "11.71", "2.11", "0.85", "44.4%", "0.97", "2.53", "2.50", "165"],
			["TOP", "31", "190", "9.21", "2.46", "0.97", "46.8%", "1.15", "3.31", "3.52", "130"],
			["MIDDLE", "30", "170", "8.17", "2.93", "1.18", "45.5%", "1.28", "3.97", "4.10", "105"],
			["BOTTOM", "28", "156", "7.16", "3.43", "1.39", "44.7%", "1.41", "4.79", "4.84", "90"]
		]
	},
	{
		title: "Average Major League relief pitcher performance by bullpen role 2016-2018",
		columns: ["Bullpen Role", "G", "IP", "K/9", "BB/9", "HR/9", "GB%", "WHIP", "ERA", "FIP", "ERA+"],
		rows: [
			["9TH INNING", "63", "64", "10.86", "3.10", "0.78", "44.5%", "1.12", "2.72", "3.11", "160"],
			["HIGH LEV.", "65", "62", "10.13", "3.23", "0.79", "47.2%", "1.21", "3.13", "3.40", "140"],
			["MEDIUM LEV.", "63", "58", "9.30", "3.28", "0.90", "44.7%", "1.22", "3.43", "3.62", "130"],
			["LOW LEV.", "50", "49", "8.63", "3.41", "1.06", "44.3%", "1.34", "4.05", "4.01", "110"]
		]
	}
];

const ProPitcherToolsTooltip = () => {
	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<h1
					style={{
						textAlign: "center",
						color: defaultColorScheme.group.backgroundColor,
						fontSize: "1.2em",
						fontWeight: "bold",
						marginTop: "13px",
						marginBottom: "13px"
					}}
				>
					PITCHER TOOLS GRADING STANDARDS
				</h1>
				<div>
					<div style={{ marginBottom: "10px" }}>
						<span
							style={{
								fontSize: "1em",
								color: "#f44335",
								fontWeight: "700"
							}}
						>
							Pitch Description Boxes
						</span>
						<br />
						<span style={{ fontSize: "0.85em" }}>
							In the text box, next to each pitch grade you will be able to describe or talk about each
							pitch. It is in this area where you should note the shape or action of the pitch as well as
							how the pitcher uses and commands it.
						</span>
					</div>
					<div style={{ marginBottom: "10px", marginTop: "25px" }}>
						<div
							style={{
								fontSize: "1em",
								color: defaultColorScheme.group.backgroundColor,
								fontWeight: "700",
								marginTop: "25px",
								marginBottom: "10px"
							}}
						>
							Pitch Grades
						</div>
						<div>
							{fields.map((field, idx) => {
								return (
									<div key={idx} style={{ marginBottom: "10px" }}>
										<span
											style={{
												fontSize: "1em",
												color: "#f44335",
												fontWeight: "700"
											}}
										>
											{field.title}
										</span>
										<br />
										<div
											style={{
												borderBottom: "1px solid gray",
												paddingBottom: "10px",
												fontSize: "0.85em"
											}}
										>
											<span>{field.overall}</span>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div style={{ marginBottom: "10px", marginTop: "25px" }}>
						<span
							style={{
								fontSize: "1em",
								color: defaultColorScheme.group.backgroundColor,
								fontWeight: "700"
							}}
						>
							Pitchers
						</span>
						<br />
						<span style={{ fontSize: "0.85em" }}>
							When determining what type of starter or reliever a player will be, keep in mind the below
							stat lines. These lines represent the averages over the last three Major League seasons for
							the various pitching tiers. These statistics can be used as a guideline to help with
							determining a pitcher’s OFP. Keep in mind that these numbers are not the definitive
							indicators of a pitchers value but rather provide a baseline for the type of production each
							group has produced since 2016. When labeling a starting pitcher please be sure the future
							grades agree with the definition of the role.
						</span>
					</div>
					<div
						style={{
							overflow: "scroll"
						}}
					>
						{tables.map((table, idx) => {
							return (
								<Table key={idx} style={{ marginTop: "25px" }}>
									<TableHead
										style={{
											backgroundColor: defaultColorScheme.group.backgroundColor
										}}
									>
										<TableRow>
											<TableCell
												colSpan={table.columns.length}
												style={{
													color: defaultColorScheme.group.color,
													fontSize: "16px",
													textAlign: "center"
												}}
											>
												{table.title}
											</TableCell>
										</TableRow>
										<TableRow>
											{table.columns.map((column, idx) => {
												return (
													<TableCell
														key={idx}
														style={{
															color: defaultColorScheme.group.color,
															textAlign: "center",
															textTransform: "capitalize"
														}}
													>
														{column}
													</TableCell>
												);
											})}
										</TableRow>
									</TableHead>
									<TableBody
										style={{
											border: "1px solid #dfe0df"
										}}
									>
										{table.rows.map((row, idx) => {
											return (
												<TableRow key={idx}>
													{row.map((cell, idx) => {
														return (
															<TableCell
																key={idx}
																style={{
																	textAlign: "center",
																	fontWeight: idx === 0 ? "700" : ""
																}}
															>
																{cell}
															</TableCell>
														);
													})}
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProPitcherToolsTooltip;
