import React, { useState, useEffect, useMemo, CSSProperties } from "react";
import dayjs from "dayjs";

import { MultiValue, SingleValue } from "react-select";
import { TOption } from "_react/inputs/_types";
import LkSelect from "_react/inputs/lks/LkSelect";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import { Button } from "_react/shared/legacy/ui/Button";
import Info from "_react/shared/legacy/ui/icons/Info";
import SwapHorizontalCircle from "_react/shared/legacy/ui/SwapHorizontalCircle";
import { TextField, TChangeEvent } from "_react/shared/legacy/ui/TextField";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";

import { createGoalStatement, isValidFormat } from "_react/playerplan/shared/_helpers";
import {
	TargetTable,
	TargetTr,
	TargetTd,
	MediumCol,
	AdjustmentButtonStyle,
	AddTargetContainer,
	AddTargetSelectDiv,
	NoteTextFieldStyle,
	AdjustmentTierContainer,
	MinWidthDiv,
	SmallCircularProgressStyle,
	DimGraySpan,
	FlexStartDiv
} from "_react/playerplan/shared/_styles";
import { DISABLED_OPACITY, PERCENTAGE_FORMAT } from "_react/playerplan/shared/_constants";

import { TPlayerPlanGoal, TPlayerPlanTarget, TTargetForm } from "_react/playerplan/shared/_types";

export type TGoalAdjustmentsStyle = {
	textField?: CSSProperties;
};

export type TGoalAdjustmentsProps = {
	goal: TPlayerPlanGoal;
	secondaryGoal?: TPlayerPlanGoal;
	isGoalActionsDisabled: boolean;
	addTarget?: Function;
	isAddingTarget?: boolean;
	isGroup1?: boolean;
	isGroup2?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TGoalAdjustmentsStyle;
};

export const GoalAdjustments = ({
	goal,
	secondaryGoal,
	isGoalActionsDisabled,
	addTarget,
	isAddingTarget = false,
	isGroup1,
	isGroup2,
	colorSchemeGroup = defaultColorScheme,
	style
}: TGoalAdjustmentsProps) => {
	// Manage which goal is displayed
	const [isDisplayingSecondaryGoal, setIsDisplayingSecondaryGoal] = useState<boolean>(false);
	const displayGoal = isDisplayingSecondaryGoal && secondaryGoal ? secondaryGoal : goal;
	const activeTarget = displayGoal.playerPlanTargets.find((target: TPlayerPlanTarget) => target.isActive);

	useEffect(() => {
		setIsDisplayingSecondaryGoal(false);
	}, [goal, secondaryGoal]);

	// Manage Target Form state
	const defaultTargetForm = useMemo(() => {
		return {
			playerPlanGoalId: displayGoal.id,
			value: activeTarget?.value?.toString() ?? null,
			directionLk: activeTarget?.directionLk ?? null,
			directionLabel: activeTarget?.direction?.label ?? null,
			description: activeTarget?.description ?? null
		};
	}, [displayGoal, activeTarget]);

	const [targetForm, setTargetForm] = useState<TTargetForm>(defaultTargetForm);

	useEffect(() => {
		setTargetForm(defaultTargetForm);
	}, [displayGoal, defaultTargetForm]);

	const isTargetFormChanges = !(
		targetForm.directionLk === defaultTargetForm.directionLk &&
		// Check if both descriptions are the same
		(targetForm.description === defaultTargetForm.description ||
			// Check if both descriptions are the same
			(!targetForm.description?.trim() && !defaultTargetForm.description?.trim())) &&
		targetForm.value === defaultTargetForm.value
	);

	return (
		<TargetTable>
			<colgroup>
				<MediumCol />
				<MediumCol />
				<col />
				<col />
			</colgroup>
			<tbody>
				{secondaryGoal && (
					<TargetTr>
						<TargetTd colSpan={2}>
							<AdjustmentTierContainer>
								<MinWidthDiv minWidth="40px">
									<b>{isDisplayingSecondaryGoal ? "Tier 2" : "Tier 1"}</b>
								</MinWidthDiv>

								<Tooltip
									title={isDisplayingSecondaryGoal ? "View Tier 1" : "View Tier 2"}
									placement="right"
									colorScheme={colorSchemeGroup.primary}
								>
									<SwapHorizontalCircle
										onClick={() => {
											setIsDisplayingSecondaryGoal(!isDisplayingSecondaryGoal);
										}}
									/>
								</Tooltip>
							</AdjustmentTierContainer>
						</TargetTd>
					</TargetTr>
				)}
				{displayGoal.playerPlanTargets
					.sort((a: TPlayerPlanTarget, b: TPlayerPlanTarget) => b.createDate?.localeCompare(a.createDate))
					.map((target: TPlayerPlanTarget) => (
						<TargetTr key={target.id}>
							<TargetTd>
								{target.createDate
									? dayjs
											.utc(target.createDate.substring(0, 23))
											.local()
											.format("M/D/YY")
									: "No Date"}
							</TargetTd>
							<TargetTd>
								{target.lastChangeUser?.firstName} {target.lastChangeUser?.lastName}
							</TargetTd>
							<TargetTd>
								{createGoalStatement(
									displayGoal.playerPlanMetric?.label,
									undefined,
									displayGoal.playerPlanSplits,
									target?.direction?.label,
									target?.value,
									displayGoal.processUsed,
									displayGoal.playerPlanMetric?.format
								)}
							</TargetTd>
							<TargetTd>{target.description}</TargetTd>
						</TargetTr>
					))}
				{(isGroup1 || isGroup2) &&
					addTarget &&
					(isAddingTarget || isGoalActionsDisabled ? (
						<TargetTr>
							<TargetTd colSpan={4}>
								<CircularProgress center={false} style={SmallCircularProgressStyle} />
							</TargetTd>
						</TargetTr>
					) : (
						<>
							<TargetTr>
								<TargetTd colSpan={2}>
									<Button
										onClick={() => {
											addTarget(targetForm);
											setTargetForm(defaultTargetForm);
										}}
										colorScheme={colorSchemeGroup.secondary}
										title="Add Adjustment"
										disabled={
											// Disabled if the inputs have not changed at all
											!isTargetFormChanges ||
											// If there was value set previously, disable if no value set now
											(defaultTargetForm.value && !targetForm.value) ||
											// If there was a direction set previously, disable if no direction set now
											(defaultTargetForm.directionLk && !targetForm.directionLk) ||
											// Value must be valid format if it exists
											(targetForm.value
												? !isValidFormat(targetForm.value, displayGoal.playerPlanMetric.format)
												: false) ||
											// If value set, must also have a direction set
											(targetForm.value ? !targetForm.directionLk : false)
										}
										style={AdjustmentButtonStyle}
										disabledOpacity={DISABLED_OPACITY}
									/>
								</TargetTd>
								<TargetTd>
									<AddTargetContainer>
										<AddTargetSelectDiv>
											<LkSelect<string>
												lkName={"lk_player_plan_target_direction"}
												onChange={(
													opt:
														| MultiValue<TOption<string>>
														| SingleValue<TOption<string>>
														| null
												) =>
													setTargetForm({
														...targetForm,
														directionLk: (opt as TOption<string>)?.value ?? null,
														directionLabel: (opt as TOption<string>)?.label ?? null
													})
												}
												menuPlacement="top"
												placeholder={"Select direction..."}
												valueOnlyValue={targetForm.directionLk}
												isClearable={true}
											/>
										</AddTargetSelectDiv>
										<AddTargetSelectDiv>
											<FlexStartDiv>
												{
													<TextField
														name="goal_adjustments_target_value_textfield"
														onChange={(e: TChangeEvent) =>
															setTargetForm({
																...targetForm,
																value: e.target.value === "" ? null : e.target.value
															})
														}
														value={targetForm.value}
														fullWidth
														type={"number"}
														placeholder={"Enter a target value..."}
														style={{ ...NoteTextFieldStyle, ...style?.textField }}
													/>
												}

												{displayGoal.playerPlanMetric.format === PERCENTAGE_FORMAT && (
													<Tooltip
														placement="left"
														title={"Must be a number between 0 and 1"}
														colorScheme={colorSchemeGroup.primary}
													>
														<Info />
													</Tooltip>
												)}
											</FlexStartDiv>
										</AddTargetSelectDiv>
									</AddTargetContainer>
								</TargetTd>
								<TargetTd>
									<TextField
										name="goal_adjustments_description_textfield"
										onChange={(e: TChangeEvent) =>
											setTargetForm({ ...targetForm, description: e.target.value })
										}
										value={targetForm.description}
										fullWidth
										placeholder={`(Optional) Description... `}
										style={{ ...NoteTextFieldStyle, ...style?.textField }}
									/>
								</TargetTd>
							</TargetTr>
							{isTargetFormChanges && (
								<TargetTr>
									<TargetTd colSpan={2}>
										<DimGraySpan>
											<b>Adjusted Goal Statement:</b>
										</DimGraySpan>
									</TargetTd>
									<TargetTd colSpan={2}>
										<DimGraySpan>
											{createGoalStatement(
												displayGoal.playerPlanMetric.label,
												displayGoal.playerPlanMetric.metricSubtype?.label,
												displayGoal.playerPlanSplits,
												targetForm.directionLabel ? targetForm.directionLabel : undefined,
												targetForm.value ? parseFloat(targetForm.value) : null,
												null,
												displayGoal.playerPlanMetric.format,
												false,
												displayGoal.playerPlanMetric.metricType.abbreviation,
												displayGoal.isStrength
											)}
										</DimGraySpan>
									</TargetTd>
								</TargetTr>
							)}
						</>
					))}
			</tbody>
		</TargetTable>
	);
};
