import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";

import {
	IAverageAge,
	IStatsPlayerBatting,
	IStatsPlayerBattingByTeam,
	IStatsPlayerPitching,
	IStatsPlayerPitchingByTeam
} from "_react/shared/data_models/stats/_types";

// Average Ages

export type TAverageAgeGetQueryParams = {
	since?: number;
};

export const fetchAverageAges = (queryParams: TAverageAgeGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TAverageAgeGetQueryParams, Array<IAverageAge>>("/stats/season/average_age", queryParams, cancelToken);

// Stats Player Pitching

export type TStatsPlayerPitchingGetQueryParams = {
	playerId?: number;
	bamId?: number;
	gameType?: string;
	"gameType[in]"?: string;
	season?: number;
	source?: string;
	"source[in]"?: string;
	g?: number;
	"g[gte]"?: number;
	"g[lte]"?: number;
	gs?: number;
	"gs[gte]"?: number;
	"gs[lte]"?: number;
	gf?: number;
	"gf[gte]"?: number;
	"gf[lte]"?: number;
	ip?: number;
	"ip[gte]"?: number;
	"ip[lte]"?: number;

	// Lots more params available, add as needed

	schema?: string;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchStatsPlayerPitching = (queryParams: TStatsPlayerPitchingGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TStatsPlayerPitchingGetQueryParams, Array<IStatsPlayerPitching>>(
		"/stats_player_pitching",
		queryParams,
		cancelToken
	);

export type TStatsPlayerPitchingByTeamGetQueryParams = TStatsPlayerPitchingGetQueryParams & {
	teamId?: number;
};

export const fetchStatsPlayerPitchingByTeam = (
	queryParams: TStatsPlayerPitchingByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TStatsPlayerPitchingByTeamGetQueryParams, Array<IStatsPlayerPitchingByTeam>>(
		"/stats_player_pitching/team",
		queryParams,
		cancelToken
	);

// Stats Player Batting

export type TStatsPlayerBattingGetQueryParams = {
	playerId?: number;
	bamId?: number;
	gameType?: string;
	"gameType[in]"?: string;
	season?: number;
	source?: string;
	"source[in]"?: string;
	g?: number;
	"g[gte]"?: number;
	"g[lte]"?: number;
	gs?: number;
	"gs[gte]"?: number;
	"gs[lte]"?: number;
	pa?: number;
	"pa[gte]"?: number;
	"pa[lte]"?: number;
	ab?: number;
	"ab[gte]"?: number;
	"ab[lte]"?: number;

	// Lots more params available, add as needed

	schema?: string;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchStatsPlayerBatting = (queryParams: TStatsPlayerBattingGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TStatsPlayerBattingGetQueryParams, Array<IStatsPlayerBatting>>(
		"/stats_player_batting",
		queryParams,
		cancelToken
	);

export type TStatsPlayerBattingByTeamGetQueryParams = TStatsPlayerBattingGetQueryParams & {
	teamId?: number;
};

export const fetchStatsPlayerBattingByTeam = (
	queryParams: TStatsPlayerBattingByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TStatsPlayerBattingByTeamGetQueryParams, Array<IStatsPlayerBattingByTeam>>(
		"/stats_player_batting/team",
		queryParams,
		cancelToken
	);
