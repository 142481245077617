import React from "react";
import PropTypes from "prop-types";

import { PLAYER_TOOL_FIELDS_INTL } from "_react/evals/_constants";

const CELL_STYLE = {
	borderRight: "1px solid lightGray",
	paddingLeft: 10,
	paddingRight: 10
};

const getVal = (evaluation, field) => {
	if (evaluation.hasOwnProperty(field)) {
		return evaluation[field];
	}
	return "";
};

const IntlPlayerToolsTable = ({ evaluation, fontSize, height }) => {
	return (
		<table
			style={{
				width: "100%",
				tableLayout: "auto",
				borderCollapse: "collapse"
			}}
		>
			<thead>
				<tr
					style={{
						backgroundColor: "#E8E9ED",
						fontSize,
						fontWeight: 600,
						height
					}}
				>
					<th style={CELL_STYLE}>Tool</th>
					<th style={CELL_STYLE}>Present</th>
					<th style={CELL_STYLE}>Future</th>
					<th style={CELL_STYLE}>Comments</th>
				</tr>
			</thead>
			<tbody>
				{PLAYER_TOOL_FIELDS_INTL.map(({ name, id }, idx) => (
					<tr
						key={id}
						style={{
							backgroundColor: idx % 2 === 0 ? "white" : "#E8E9ED",
							fontWeight: "normal",
							height,
							fontSize
						}}
					>
						<td style={{ ...CELL_STYLE, fontWeight: 600 }}>{name}</td>
						<td style={CELL_STYLE}>{getVal(evaluation, `${id.toLowerCase()}_present`)}</td>
						<td style={CELL_STYLE}>{getVal(evaluation, `${id.toLowerCase()}_future`)}</td>
						<td style={CELL_STYLE}>{getVal(evaluation, `${id.toLowerCase()}_comments`)}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

IntlPlayerToolsTable.propTypes = {
	evaluation: PropTypes.object,
	fontSize: PropTypes.string,
	height: PropTypes.string
};

IntlPlayerToolsTable.defaultProps = {
	height: "32px",
	fontSize: ".875em"
};

export default IntlPlayerToolsTable;
