import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const stats = {
	columns: [
		"Pos",
		"G",
		"PA",
		"2B",
		"3B",
		"HR",
		"SB",
		"AVG",
		"OBP",
		"SLG",
		"OPS",
		"ISO",
		"wOBA",
		"pRC+",
		"Hit",
		"Pwr",
		"Off Total"
	],
	rows: [
		[
			"C",
			"125",
			"471",
			"23",
			"1",
			"16",
			"1",
			".251",
			".323",
			".419",
			".742",
			".169",
			".322",
			"95",
			"45",
			"50",
			"95"
		],
		[
			"1B",
			"148",
			"608",
			"29",
			"1",
			"25",
			"2",
			".265",
			".352",
			".460",
			".812",
			".195",
			".349",
			"113",
			"50",
			"60",
			"110"
		],
		[
			"2B",
			"149",
			"630",
			"30",
			"3",
			"18",
			"8",
			".272",
			".338",
			".444",
			".782",
			".167",
			".338",
			"105",
			"55",
			"50",
			"105"
		],
		[
			"3B",
			"150",
			"622",
			"32",
			"2",
			"24",
			"3",
			".272",
			".341",
			".472",
			".813",
			".200",
			".348",
			"111",
			"55",
			"55",
			"110"
		],
		[
			"SS",
			"146",
			"583",
			"28",
			"3",
			"15",
			"9",
			".263",
			".320",
			".415",
			".735",
			".152",
			".319",
			"93",
			"50",
			"45",
			"95"
		],
		[
			"LF",
			"145",
			"609",
			"28",
			"2",
			"21",
			"8",
			".271",
			".338",
			".465",
			".802",
			".194",
			".343",
			"111",
			"55",
			"55",
			"110"
		],
		[
			"CF",
			"140",
			"584",
			"24",
			"4",
			"16",
			"14",
			".266",
			".329",
			".430",
			".759",
			".164",
			".332",
			"101",
			"50",
			"50",
			"100"
		],
		[
			"RF",
			"146",
			"593",
			"29",
			"2",
			"22",
			"5",
			".263",
			".340",
			".459",
			".799",
			".196",
			".347",
			"111",
			"50",
			"55",
			"105"
		]
	]
};

const ProPlayerSnapshotTooltip = () => {
	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<h1
					style={{
						textAlign: "center",
						color: defaultColorScheme.group.backgroundColor,
						fontSize: "1.2em",
						fontWeight: "bold",
						marginTop: "13px",
						marginBottom: "13px"
					}}
				>
					POSITION PLAYER SNAPSHOT
				</h1>
				<div>
					<div style={{ marginBottom: "10px" }}>
						<span
							style={{
								fontSize: "1em",
								color: "#f44335",
								fontWeight: "700"
							}}
						>
							Snapshot
						</span>
						<br />
						<span style={{ fontSize: "0.85em" }}>
							This is where you should pull together both the Offensive and Defensive summaries to
							ultimately summarize the player and his role. As in the past, always lead the Snapshot off
							with what you think the player is or will eventually be based upon your OFP. Following that
							initial valuation, mention the player’s strengths or weaknesses that led you to your
							decision.
						</span>
					</div>
					<div
						style={{
							overflow: "scroll"
						}}
					>
						<Table style={{ marginTop: "25px" }}>
							<TableHead
								style={{
									backgroundColor: defaultColorScheme.group.backgroundColor
								}}
							>
								<TableRow>
									<TableCell
										colSpan={stats.columns.length}
										style={{
											color: defaultColorScheme.group.color,
											fontSize: "16px",
											textAlign: "center"
										}}
									>
										Average Full Season Offensive Production for Major League starting players by
										position from 2016-2018
									</TableCell>
								</TableRow>
								<TableRow>
									{stats.columns.map((column, idx) => {
										return (
											<TableCell
												key={idx}
												style={{
													color: defaultColorScheme.group.color,
													textAlign: "center",
													textTransform: "capitalize"
												}}
											>
												{column}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody
								style={{
									border: "1px solid #dfe0df"
								}}
							>
								{stats.rows.map((row, idx) => {
									return (
										<TableRow key={idx}>
											{row.map((cell, idx) => {
												return (
													<TableCell
														key={idx}
														style={{
															textAlign: "center",
															fontWeight: idx === 0 ? "700" : ""
														}}
													>
														{cell}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProPlayerSnapshotTooltip;
