import React, { createContext, useState, useMemo, useCallback } from "react";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import axios from "_redux/_utils/_axios";
import { $TSFixMe } from "utils/tsutils";
import { convertDates } from "utils/helpers";
import { usePersistentErrorSnackbar } from "_react/shared/_helpers/snackbar";

import { EvalDateFields, $TSFixMeEval, TEvalPlayerScoutEntry } from "_react/evals/shared/_types";
import dayjs from "dayjs";

interface IEvalContext {
	createEval: Function;
	deleteEval: Function;
	getEval: Function;
	getEvalForCreate: Function;
	updateEval: Function;
	isEvalSaving: boolean;
	publishEval: (evaluation: $TSFixMe) => Promise<$TSFixMeEval>;
	addListPlayerOnPublish: (evaluation: $TSFixMe) => Promise<$TSFixMe>;
	isEvalSaveError: boolean;
	getPlayerScoutEvalsFromEvalId: (evalId: string) => Promise<TEvalPlayerScoutEntry[]>;
}

const EvalContext = createContext<IEvalContext>({
	createEval: () => Promise.resolve({}),
	deleteEval: () => Promise.resolve({}),
	getEval: () => Promise.resolve({}),
	getEvalForCreate: () => Promise.resolve([]),
	updateEval: () => Promise.resolve({}),
	isEvalSaving: false,
	publishEval: _evaluation => Promise.resolve({}),
	addListPlayerOnPublish: _evaluation => Promise.resolve(),
	isEvalSaveError: false,
	getPlayerScoutEvalsFromEvalId: _evalId => Promise.resolve([])
});

export default function getPreferenceListMetaData(props: $TSFixMe) {
	const { type, name, description, year } = props;
	const source = "ROCKY";
	switch (type) {
		case "ORG":
		case "ORGANIZATION":
		case "ORG_SHEET":
		case "CLUB": {
			const { team } = props;
			const { bam_id: team_bam_id, org_id: organization_id, level } = team;
			return {
				name,
				description,
				type,
				team_bam_id,
				level,
				year,
				organization_id,
				source
			};
		}
		default:
			return {
				name,
				type,
				description,
				year,
				source
			};
	}
}

const addListPlayerOnPublish = (evaluation: $TSFixMe): Promise<$TSFixMe> => {
	const phil_id = evaluation.phil_id;
	const eval_id = evaluation.eval_id;
	const eval_type = evaluation.eval_type;
	const report_type = evaluation.eval_report_type;
	const team_bam_id = evaluation.team_bam_id;
	const position = evaluation.position_reported ?? evaluation.position;
	const required = [eval_id, eval_type, phil_id, report_type];
	if (required.some(entry => entry == null)) {
		return Promise.reject("cannot add list without type, phil_id, report_type, and an eval_id");
	}
	const postUrl = `/eval/publish/list`;
	return axios.post(postUrl, { phil_id, eval_id, eval_type, report_type, team_bam_id, position });
};

const getEvalForCreate = (philId: number, level: string, player_type: string): Promise<Array<$TSFixMeEval>> => {
	return axios
		.get(`/evals/recent_create/${philId}/${level}/${player_type}`)
		.then((response: { data: Array<$TSFixMeEval> }) => {
			return response.data;
		});
};

function EvalProvider(props: $TSFixMe) {
	const [evals, setEvals] = useState<{ [index: string]: $TSFixMe }>({});
	const [isEvalSaving, setIsEvalSaving] = useState<boolean>(false);
	const [isEvalSaveError, setIsEvalSaveError] = useState(false);
	const enqueueErrorSnackbar = usePersistentErrorSnackbar();
	const updateEvalsWithResponse = useCallback((responseData: $TSFixMe) => {
		const data = convertDates(responseData, EvalDateFields);
		setEvals(prevEvals => ({
			...prevEvals,
			[responseData.eval_id]: data
		}));
		setIsEvalSaving(false);
		return data;
	}, []);

	const createEval = useCallback(
		(evaluation: $TSFixMe): Promise<$TSFixMeEval> => {
			const level = evaluation.eval_type.split("_")[0];
			const type = evaluation.eval_type.split("_")[1];
			return axios
				.post(`/eval/${level.toLowerCase()}/${type.toLowerCase()}`, evaluation)
				.then((response: { data: $TSFixMe }) => updateEvalsWithResponse(response.data));
		},
		[updateEvalsWithResponse]
	);

	const updateEval = useCallback(
		(evaluation: $TSFixMe): Promise<$TSFixMeEval> => {
			setIsEvalSaving(true);
			const level = evaluation.eval_type.split("_")[0];
			const type = evaluation.eval_type.split("_")[1];
			return axios
				.put(`/eval/${level.toLowerCase()}/${type.toLowerCase()}/${evaluation.eval_id}`, evaluation)
				.then((response: { data: $TSFixMe }) => updateEvalsWithResponse(response.data))
				.catch(error => {
					Sentry.captureMessage("Error Updating Evaluation", Sentry.Severity.Debug);
					Sentry.captureException(error);
					enqueueErrorSnackbar(`Error Updating Evaluation. Please Refresh the Page`);
					setIsEvalSaveError(true);
					setIsEvalSaving(false);
				});
		},
		[updateEvalsWithResponse, enqueueErrorSnackbar]
	);

	// used for ama evals. Pro should be moved over eventaully but backend more complex. Intl just update the eval
	const publishEval = useCallback(
		(evaluation: $TSFixMe): Promise<$TSFixMeEval> => {
			return axios
				.put("/eval/publish", evaluation)
				.then((response: { data: $TSFixMe }) => updateEvalsWithResponse(response.data));
		},
		[updateEvalsWithResponse]
	);

	const deleteEval = useCallback((evaluation: $TSFixMe): Promise<null> => {
		const level = evaluation.eval_type.split("_")[0];
		const type = evaluation.eval_type.split("_")[1];
		return axios.delete(`/eval/${level.toLowerCase()}/${type.toLowerCase()}/${evaluation.eval_id}`).then(() => {
			setEvals(prevEvals => {
				const newEvals = { ...prevEvals };
				delete newEvals[evaluation.eval_id];
				return newEvals;
			});
			return null;
		});
	}, []);

	const getEval = useCallback(
		(evalId: string): Promise<$TSFixMeEval> => {
			if (evals.hasOwnProperty(evalId)) {
				return Promise.resolve(evals[evalId]);
			} else {
				return axios
					.get(`/eval/${evalId}`)
					.then((response: { data: $TSFixMe }) => updateEvalsWithResponse(response.data));
			}
		},
		[evals, updateEvalsWithResponse]
	);

	const [playerScoutEvals, setPlayerScoutEvals] = useState<TEvalPlayerScoutEntry[]>([]);
	const getPlayerScoutEvalsFromEvalId = useCallback(
		(evalId: string, isAll = false): Promise<TEvalPlayerScoutEntry[]> => {
			if (playerScoutEvals.length > 0) {
				return Promise.resolve(playerScoutEvals);
			}
			return axios
				.get(`/eval/playerScoutEvals?evalId=${evalId}&all=${isAll}`)
				.then((response: { data: TEvalPlayerScoutEntry[] }) => {
					const newPlayerScoutEvals = response.data.sort((a, b) => {
						const aDayJs = a.publishDate ? dayjs(a.publishDate) : dayjs();
						const bDayJs = b.publishDate ? dayjs(b.publishDate) : dayjs();
						return aDayJs.isBefore(bDayJs) ? -1 : bDayJs.isBefore(aDayJs) ? 1 : 0;
					});
					setPlayerScoutEvals(newPlayerScoutEvals);
					return newPlayerScoutEvals;
				});
		},
		[playerScoutEvals]
	);

	const providerValue = useMemo(
		() => ({
			createEval,
			deleteEval,
			getEval,
			updateEval,
			isEvalSaving,
			getEvalForCreate,
			publishEval,
			addListPlayerOnPublish,
			isEvalSaveError,
			getPlayerScoutEvalsFromEvalId
		}),
		[
			createEval,
			deleteEval,
			getEval,
			publishEval,
			updateEval,
			isEvalSaving,
			isEvalSaveError,
			getPlayerScoutEvalsFromEvalId
		]
	);

	return <EvalContext.Provider value={providerValue}>{props.children}</EvalContext.Provider>;
}

EvalProvider.propTypes = {
	children: PropTypes.element
};

export { EvalContext, EvalProvider };
