import React from "react";

import { PlayerPlan } from "_react/playerplan/shared/PlayerPlan";
import { useChakraBlueColorScheme, useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";

type TPlayerPlanTabProps = {
	playerId: number;
};

const PlayerPlanTab = ({ playerId }: TPlayerPlanTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	const chakraBlueColorScheme = useChakraBlueColorScheme();

	return (
		<>
			{playerId && (
				<PlayerPlan
					playerId={playerId}
					isShowBioDiv={false}
					colorSchemeGroup={chakraColorSchemeGroup}
					isShowDraftGoals
					style={{
						addGoalModalStyle: {
							stepCircle: {
								height: "65px",
								width: "65px",
								lineHeight: "65px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							},
							submitButtonColorScheme: chakraBlueColorScheme
						},
						submitAttachmentButtonColorScheme: chakraBlueColorScheme
					}}
				/>
			)}
		</>
	);
};

export default PlayerPlanTab;
