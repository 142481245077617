import React, { FC, CSSProperties } from "react";
import styled from "@emotion/styled";
import { CardHeader } from "_react/shared/legacy/ui/CardHeader";
import { ColorScheme, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { IconProps } from "_react/shared/legacy/ui/icons";

type CardProps = {
	children: React.ReactNode;
	colorScheme?: ColorScheme;
	header?: string;
	overflow?: string;
	marginBottom?: string | number;
	Icon?: ({ fill, ...props }: IconProps) => JSX.Element;
	style?: CSSProperties;
};
type StyledCardProps = {
	overflow: CSSProperties["overflow"];
	marginBottom: CSSProperties["marginBottom"];
	marginTop: CSSProperties["marginTop"];
	height: CSSProperties["height"];
	margin: CSSProperties["padding"];
};

const StyledCard = styled.div<StyledCardProps>(({ overflow, marginBottom = "10px", marginTop, height, margin }) => ({
	backgroundColor: "#FFFFFF",
	fontSize: "14px",
	fontWeight: 500,
	textAlign: "left",
	borderRadius: "3px",
	boxSizing: "border-box",
	boxShadow:
		"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
	overflow,
	marginBottom,
	marginTop,
	height,
	margin
}));

const StyledCardContents = styled.div({
	borderBottomLeftRadius: "3px",
	borderBottomRightRadius: "3px",
	borderRadius: "inherit",
	boxSizing: "border-box"
});

export const Card: FC<CardProps> = ({
	children,
	colorScheme = defaultColorScheme.primary,
	header = null,
	overflow = "hidden",
	Icon = null,
	style = {}
}) => {
	const { height, margin, marginTop, marginBottom, ...contentStyle } = style;
	return (
		<StyledCard
			height={height}
			margin={margin}
			marginBottom={marginBottom}
			marginTop={marginTop}
			overflow={overflow}
		>
			{header != null && <CardHeader colorScheme={colorScheme} Icon={Icon} text={header} />}
			<StyledCardContents style={contentStyle}>{children}</StyledCardContents>
		</StyledCard>
	);
};

// Styled Components for use in card
export const CardContainer = styled.div({
	height: "100%",
	width: "100%",
	borderRadius: "inherit",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between"
});

export const CardHeaderAndContent = styled.div({
	boxSizing: "border-box",
	borderRadius: "inherit",
	display: "flex",
	flexDirection: "column"
});

export const CardActionsDiv = styled.div({
	boxSizing: "border-box",
	paddingTop: 5,
	paddingBottom: 5,
	display: "flex",
	justifyContent: "flex-end",
	alignItems: "center"
});

const CardActionDiv = styled.div({ boxSizing: "border-box", paddingRight: 5 });

type TCardProps = {
	children: React.ReactNode;
};

export const CardActions: FC<TCardProps> = ({ children }) => {
	if (children == null) return null;
	return (
		<CardActionsDiv>
			{React.Children.map(children, action => (
				<CardActionDiv>{action}</CardActionDiv>
			))}
		</CardActionsDiv>
	);
};
