import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";

import { TSimpleProjectionsAll, IProjectedPitcherProportions } from "_react/shared/data_models/projections/_types";
import {
	fetchSimpleProjectionsAll,
	fetchProjectedPitcherProportions
} from "_react/shared/data_models/projections/_network";
import { TReplacementLevelActive } from "_react/shared/data_models/phred/_types";
import { fetchReplacementLevelActive } from "_react/shared/data_models/phred/_network";
import { getCancelSource } from "utils/url_helpers";
import { extractFromEventDataArray } from "_react/shared/_helpers/xstate";

import { TProjectionsStatCardData } from "_react/shared/ui/data/cards/ProjectionsStatCard/ProjectionsStatCard";

const PROJECTIONS_CANCEL_SOURCE = "projections";
const PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE = "projectedPitcherProportions";
const REPLACEMENT_LEVEL_CANCEL_SOURCE = "replacementLevel";

export type TProjectionsStatCardCancelSource = {
	[PROJECTIONS_CANCEL_SOURCE]?: CancelTokenSource;
	[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE]?: CancelTokenSource;
	[REPLACEMENT_LEVEL_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TProjectionsStatCardContext = {
	playerId?: number;
	lastPlayerId?: number; // Not currently used
	season?: number;
	lastSeason?: number; // Not currently used
	shouldFetchData?: boolean;
	projections?: TSimpleProjectionsAll | null;
	projectedPitcherProportions?: IProjectedPitcherProportions | null;
	replacementLevel?: TReplacementLevelActive | null;
	cancelSources: TProjectionsStatCardCancelSource;
	toast?: CreateToastFnReturn;
};

interface IProjectionsStatCardStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all projections data
				projections: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches projected pitcher proportions data
				projectedPitcherProportions: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches replacement level data
				replacementLevel: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_SEASON = "SET_SEASON";
export const SET_PROJECTIONS = "SET_PROJECTIONS";
export const SET_PROJECTED_PITCHER_PROPORTIONS = "SET_PROJECTED_PITCHER_PROPORTIONS";
export const SET_REPLACEMENT_LEVEL = "SET_REPLACEMENT_LEVEL";
export const FETCHING_PROJECTIONS = { initialized: { projections: "fetching" } };
export const FETCHING_PROJECTED_PITCHER_PROPORTIONS = { initialized: { projectedPitcherProportions: "fetching" } };
export const FETCHING_REPLACEMENT_LEVEL = { initialized: { replacementLevel: "fetching" } };

const FETCH_PROJECTIONS_DONE = "done.invoke.fetchingProjections:invocation[0]";
const FETCH_PROJECTED_PITCHER_PROPORTIONS_DONE = "done.invoke.fetchingProjectedPitcherProportions:invocation[0]";
const FETCH_REPLACEMENT_LEVEL_DONE = "done.invoke.fetchingReplacementLevel:invocation[0]";

type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TSetSeasonEvent = {
	type: typeof SET_SEASON;
	data: number | undefined;
};
type TSetProjectionsEvent = { type: typeof SET_PROJECTIONS; data: TSimpleProjectionsAll | null | undefined };
type TSetProjectedPitcherProportionsEvent = {
	type: typeof SET_PROJECTED_PITCHER_PROPORTIONS;
	data: IProjectedPitcherProportions | null | undefined;
};
type TSetReplacementLevelEvent = {
	type: typeof SET_REPLACEMENT_LEVEL;
	data: TReplacementLevelActive | null | undefined;
};
type TFetchProjectionsEvent = { type: typeof FETCH_PROJECTIONS_DONE; data?: Array<TSimpleProjectionsAll> };
type TFetchProjectedPitcherProportionsEvent = {
	type: typeof FETCH_PROJECTED_PITCHER_PROPORTIONS_DONE;
	data?: Array<IProjectedPitcherProportions>;
};
type TFetchReplacementLevelEvent = { type: typeof FETCH_REPLACEMENT_LEVEL_DONE; data?: Array<TReplacementLevelActive> };

type TProjectionsStatCardEvent =
	| TSetPlayerIdEvent
	| TSetSeasonEvent
	| TSetProjectionsEvent
	| TSetProjectedPitcherProportionsEvent
	| TSetReplacementLevelEvent
	| TFetchProjectionsEvent
	| TFetchProjectedPitcherProportionsEvent
	| TFetchReplacementLevelEvent;

export type TProjectionsStatCardSend = Interpreter<
	TProjectionsStatCardContext,
	IProjectionsStatCardStateSchema,
	TProjectionsStatCardEvent
>["send"];

const ProjectionsStatCardMachine = (
	playerIdProp?: number,
	seasonProp?: number,
	shouldFetchData = true,
	data?: TProjectionsStatCardData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TProjectionsStatCardContext, IProjectionsStatCardStateSchema, TProjectionsStatCardEvent>(
		{
			id: "projectionsStatCard",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				season: seasonProp,
				lastSeason: seasonProp,
				shouldFetchData: shouldFetchData,
				projections: data?.projections,
				projectedPitcherProportions: data?.projectedPitcherProportions,
				replacementLevel: data?.replacementLevel,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: {
							actions: ["setPlayerId", "clearProjections", "clearProjectedPitcherProportions"],
							cond: "shouldSetPlayerId"
						},
						[SET_SEASON]: {
							actions: ["setSeason", "clearProjectedPitcherProportions"],
							cond: "shouldSetSeason"
						},
						[SET_PROJECTIONS]: { actions: "setProjections" },
						[SET_PROJECTED_PITCHER_PROPORTIONS]: { actions: "setProjectedPitcherProportions" },
						[SET_REPLACEMENT_LEVEL]: { actions: "setReplacementLevel" }
					},
					states: {
						projections: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: { target: "#fetchingProjections", cond: "shouldFetchProjections" },
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingProjections",
									entry: ["refreshProjectionsCancelSource"],
									invoke: {
										src: "fetchProjections",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchProjectionsSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchProjectionsErrored"
										}
									}
								}
							}
						},
						projectedPitcherProportions: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingProjectedPitcherProportions",
												cond: "shouldFetchProjectedPitcherProportions"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingProjectedPitcherProportions",
									entry: ["refreshProjectedPitcherProportionsCancelSource"],
									invoke: {
										src: "fetchProjectedPitcherProportions",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handlefetchProjectedPitcherProportionSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchProjectedPitcherProportionsErrored"
										}
									}
								}
							}
						},
						replacementLevel: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingReplacementLevel",
												cond: "shouldFetchReplacementLevel"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingReplacementLevel",
									entry: ["refreshReplacementLevelCancelSource"],
									invoke: {
										src: "fetchReplacementLevel",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchReplacementLevelSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchReplacementLevelErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (context: TProjectionsStatCardContext, event: TProjectionsStatCardEvent) =>
					event.type === SET_PLAYER_ID && context.playerId !== event.data,
				shouldSetSeason: (context: TProjectionsStatCardContext, event: TProjectionsStatCardEvent) =>
					event.type === SET_SEASON && context.season !== event.data,
				shouldFetchProjections: (context: TProjectionsStatCardContext, _event: TProjectionsStatCardEvent) =>
					context.projections === undefined && shouldFetchData && context.playerId !== undefined,
				shouldFetchProjectedPitcherProportions: (
					context: TProjectionsStatCardContext,
					_event: TProjectionsStatCardEvent
				) =>
					context.projectedPitcherProportions === undefined &&
					shouldFetchData &&
					context.playerId !== undefined &&
					context.season !== undefined,
				shouldFetchReplacementLevel: (
					context: TProjectionsStatCardContext,
					_event: TProjectionsStatCardEvent
				) => context.replacementLevel === undefined && shouldFetchData
			},
			actions: {
				clearProjections: assign<TProjectionsStatCardContext, TProjectionsStatCardEvent>({
					projections: (_context: TProjectionsStatCardContext, _event: TProjectionsStatCardEvent) =>
						undefined,
					cancelSources: (context: TProjectionsStatCardContext, _event: TProjectionsStatCardEvent) => {
						if (context.cancelSources[PROJECTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTIONS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PROJECTIONS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearProjectedPitcherProportions: assign<TProjectionsStatCardContext, TProjectionsStatCardEvent>({
					projectedPitcherProportions: (
						_context: TProjectionsStatCardContext,
						_event: TProjectionsStatCardEvent
					) => undefined,
					cancelSources: (context: TProjectionsStatCardContext, _event: TProjectionsStatCardEvent) => {
						if (context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TProjectionsStatCardContext, TProjectionsStatCardEvent>({
					playerId: (context: TProjectionsStatCardContext, event: TProjectionsStatCardEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setSeason: assign<TProjectionsStatCardContext, TProjectionsStatCardEvent>({
					season: (context: TProjectionsStatCardContext, event: TProjectionsStatCardEvent) => {
						if (event.type !== SET_SEASON) return context.season;
						return event.data;
					}
				}),
				setProjections: assign<TProjectionsStatCardContext, TProjectionsStatCardEvent>({
					projections: (context: TProjectionsStatCardContext, event: TProjectionsStatCardEvent) => {
						if (event.type !== SET_PROJECTIONS) return context.projections;
						return event.data;
					},
					cancelSources: (context: TProjectionsStatCardContext, event: TProjectionsStatCardEvent) => {
						if (event.type !== SET_PROJECTIONS) return context.cancelSources;
						if (context.cancelSources[PROJECTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTIONS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PROJECTIONS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setProjectedPitcherProportions: assign<TProjectionsStatCardContext, TProjectionsStatCardEvent>({
					projectedPitcherProportions: (
						context: TProjectionsStatCardContext,
						event: TProjectionsStatCardEvent
					) => {
						if (event.type !== SET_PROJECTED_PITCHER_PROPORTIONS)
							return context.projectedPitcherProportions;
						return event.data;
					},
					cancelSources: (context: TProjectionsStatCardContext, event: TProjectionsStatCardEvent) => {
						if (event.type !== SET_PROJECTED_PITCHER_PROPORTIONS) return context.cancelSources;
						if (context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setReplacementLevel: assign<TProjectionsStatCardContext, TProjectionsStatCardEvent>({
					replacementLevel: (context: TProjectionsStatCardContext, event: TProjectionsStatCardEvent) => {
						if (event.type !== SET_REPLACEMENT_LEVEL) return context.replacementLevel;
						return event.data;
					},
					cancelSources: (context: TProjectionsStatCardContext, event: TProjectionsStatCardEvent) => {
						if (event.type !== SET_REPLACEMENT_LEVEL) return context.cancelSources;
						if (context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE] != null)
							context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE].cancel();
						delete context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				// Cancel Source Actions
				refreshProjectionsCancelSource: assign<TProjectionsStatCardContext, TProjectionsStatCardEvent>({
					cancelSources: (context: TProjectionsStatCardContext, _event: TProjectionsStatCardEvent) => {
						if (context.cancelSources[PROJECTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTIONS_CANCEL_SOURCE].cancel();
						context.cancelSources[PROJECTIONS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshProjectedPitcherProportionsCancelSource: assign<
					TProjectionsStatCardContext,
					TProjectionsStatCardEvent
				>({
					cancelSources: (context: TProjectionsStatCardContext, _event: TProjectionsStatCardEvent) => {
						if (context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE].cancel();
						context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshReplacementLevelCancelSource: assign<TProjectionsStatCardContext, TProjectionsStatCardEvent>({
					cancelSources: (context: TProjectionsStatCardContext, _event: TProjectionsStatCardEvent) => {
						if (context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE] != null)
							context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE].cancel();
						context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchProjectionsSuccess: assign<TProjectionsStatCardContext, TProjectionsStatCardEvent>({
					projections: (context: TProjectionsStatCardContext, event: TProjectionsStatCardEvent) => {
						if (event.type !== FETCH_PROJECTIONS_DONE) return context.projections;
						return extractFromEventDataArray<TProjectionsStatCardEvent>(event);
					}
				}),
				handlefetchProjectedPitcherProportionSuccess: assign<
					TProjectionsStatCardContext,
					TProjectionsStatCardEvent
				>({
					projectedPitcherProportions: (
						context: TProjectionsStatCardContext,
						event: TProjectionsStatCardEvent
					) => {
						if (event.type !== FETCH_PROJECTED_PITCHER_PROPORTIONS_DONE)
							return context.projectedPitcherProportions;
						return extractFromEventDataArray<TProjectionsStatCardEvent>(event);
					}
				}),
				handleFetchReplacementLevelSuccess: assign<TProjectionsStatCardContext, TProjectionsStatCardEvent>({
					replacementLevel: (context: TProjectionsStatCardContext, event: TProjectionsStatCardEvent) => {
						if (event.type !== FETCH_REPLACEMENT_LEVEL_DONE) return context.replacementLevel;
						return extractFromEventDataArray<TProjectionsStatCardEvent>(event);
					}
				}),
				// Fetch Errored Actions
				handleFetchProjectionsErrored: (
					context: TProjectionsStatCardContext,
					_event: TProjectionsStatCardEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Projections Stat Card",
							description: "Error fetching projection data."
						});
				},
				handleFetchProjectedPitcherProportionsErrored: (
					context: TProjectionsStatCardContext,
					_event: TProjectionsStatCardEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Projections Stat Card",
							description: "Error fetching projected pitcher proportions data."
						});
				},
				handleFetchReplacementLevelErrored: (
					context: TProjectionsStatCardContext,
					_event: TProjectionsStatCardEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Projections Stat Card",
							description: "Error fetching replacement level data."
						});
				}
			},
			services: {
				fetchProjections: (context: TProjectionsStatCardContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchSimpleProjectionsAll(
							{
								playerId: context.playerId,
								isUseCache: true
							},
							context.cancelSources[PROJECTIONS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchReplacementLevel: (context: TProjectionsStatCardContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchReplacementLevelActive(
							{
								sort: "date",
								isSortDescending: true,
								limit: 1
							},
							context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchProjectedPitcherProportions: (context: TProjectionsStatCardContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchProjectedPitcherProportions(
							{
								playerId: context.playerId,
								projectedSeason: context.season,
								isUseCache: true
							},
							context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default ProjectionsStatCardMachine;
