export const DISTRIBUTION_X = "value";
export const DISTRIBUTION_Y = "density";

export const GRADE_DISTRIBUTION_PLOT_PROPS = {
	backgroundColor: "transparent",
	distributionX: DISTRIBUTION_X,
	distributionY: DISTRIBUTION_Y,
	margins: { top: 0, right: 5, bottom: 15, left: 5 }
};

export const GAME_TYPE_OVERALL = "OVR";
