import { makeColumn } from "_react/table";
import { TCollegeSplitsRow, TCollegeSplitsSplitRow } from "_react/playerpage/stat/collegesplits/_types";
import { $TSFixMe } from "utils/tsutils";

const METADATA_COLS = [
	"createDate",
	"ftpFilename",
	"ftpModifyDate",
	"lastChangeDate",
	"playerId",
	"position",
	"season",
	"teamId",
	"teamName",
	"split",
	"splitLabel"
];

type StatColumn = {
	key: string;
	label: string;
	decimalPlaces?: number;
	getValue?: (row: $TSFixMe) => string | number;
};

const formatStat = (value: number) => {
	if (value > 1) return value.toFixed(3);
	if (value) return `.${`${value.toFixed(3)}`.split(".")[1]}`;
	return "";
};

const calculateSingles = (datum: TCollegeSplitsRow) => {
	let singles = 0;
	if (datum.h) singles = datum.h;
	if (datum.double) singles -= datum.double;
	if (datum.triple) singles -= datum.triple;
	if (datum.homeRun) singles -= datum.homeRun;
	return singles;
};

const BATTING_COLUMNS: Array<StatColumn> = [
	{ key: "g", label: "G" },
	{ key: "pa", label: "PA" },
	{ key: "homeRun", label: "HR" },
	{ key: "r", label: "R" },
	{ key: "avg", label: "BA", getValue: ({ datum }) => formatStat(datum.avg) },
	{ key: "obp", label: "OBP", getValue: ({ datum }) => formatStat(datum.obp) },
	{ key: "slg", label: "SLG", getValue: ({ datum }) => formatStat(datum.slg) },
	{ key: "ops", label: "OPS", getValue: ({ datum }) => formatStat(datum.ops) },
	{ key: "bbPct", label: "BBpct", getValue: ({ datum }) => (datum.bbPct * 100).toFixed(1) },
	{ key: "kPct", label: "Kpct", getValue: ({ datum }) => (datum.kPct * 100).toFixed(1) },
	{ key: "iso", label: "ISO", getValue: ({ datum }) => formatStat(datum.iso) },
	{ key: "babip", label: "BABIP", getValue: ({ datum }) => formatStat(datum.babip) },
	{ key: "sb", label: "SB" },
	{ key: "cs", label: "CS" },
	{ key: "h", label: "H" },
	{ key: "single", label: "H1B", getValue: ({ datum }) => calculateSingles(datum) },
	{ key: "double", label: "H2B" },
	{ key: "triple", label: "H3B" },
	{ key: "k", label: "K" },
	{ key: "bb", label: "BB" }
];

const PITCHING_COLUMNS: Array<StatColumn> = [
	{ key: "g", label: "G" },
	{ key: "gs", label: "GS" },
	{ key: "ip", label: "IP" },
	{ key: "k", label: "K" },
	{ key: "era", label: "ERA", decimalPlaces: 2 },
	{ key: "ra9", label: "RA9", decimalPlaces: 2 },
	{ key: "whip", label: "WHIP", decimalPlaces: 2 },
	{ key: "kPer9", label: "K9", decimalPlaces: 2 },
	{ key: "bbPer9", label: "BB9", decimalPlaces: 2 },
	{ key: "hitsPer9", label: "H9", decimalPlaces: 2 },
	{ key: "hrPer9", label: "HR9", decimalPlaces: 2 },
	{ key: "gbPct", label: "GBpct", getValue: ({ datum }) => (datum.gbPct * 100).toFixed(1) },
	{ key: "babip", label: "BABIP", getValue: ({ datum }) => formatStat(datum.babip) },
	{ key: "oppAvg", label: "OppBA", getValue: ({ datum }) => formatStat(datum.oppAvg) },
	{ key: "oppObp", label: "OppOBP", getValue: ({ datum }) => formatStat(datum.oppObp) },
	{ key: "oppSlg", label: "OppSLG", getValue: ({ datum }) => formatStat(datum.oppSlg) },
	{ key: "oppOps", label: "OppOPS", getValue: ({ datum }) => formatStat(datum.oppOps) },
	{ key: "kPct", label: "Kpct", getValue: ({ datum }) => (datum.kPct * 100).toFixed(1) },
	{ key: "bbPct", label: "BBpct", getValue: ({ datum }) => (datum.bbPct * 100).toFixed(1) },
	{ key: "oppIso", label: "OppISO", getValue: ({ datum }) => formatStat(datum.oppIso) }
];

const POSITIONS: { [index: string]: string } = {
	"0": "DH",
	"1": "P",
	"2": "C",
	"3": "1B",
	"4": "2B",
	"5": "3B",
	"6": "SS",
	"7": "LF",
	"8": "CF",
	"9": "RF",
	"10": "PH",
	"11": "PH",
	"12": "PH"
};

export const getCollegeSplitsStatColumns = (playerType: string) =>
	playerType === "PITCHER" ? PITCHING_COLUMNS : BATTING_COLUMNS;

const isSplitRow = (object: $TSFixMe): object is TCollegeSplitsSplitRow => {
	return "splitLabel" in object;
};

export const columnGenerator = (statColumns: Array<StatColumn>) => [
	makeColumn({ key: "season" }),
	makeColumn({
		key: "splitLabel",
		label: "Split",
		flex: 2,
		getValue: ({ datum }: { datum: TCollegeSplitsRow }) => (isSplitRow(datum) ? datum.splitLabel : "Total")
	}),
	makeColumn({ key: "teamName", label: "Team", flex: 3 }),
	makeColumn({
		key: "position",
		getValue: ({ datum }: { datum: TCollegeSplitsRow }) =>
			datum.position && POSITIONS[datum.position] ? POSITIONS[datum.position] : ""
	}),
	...statColumns.map((column: StatColumn) =>
		makeColumn({
			key: column.key,
			label: column.label,
			type: "number",
			getValue: ({ datum }: { datum: TCollegeSplitsRow }) =>
				column.getValue
					? column.getValue({ datum })
					: column.decimalPlaces && datum[column.key as keyof TCollegeSplitsRow]
					? parseFloat(`${datum[column.key as keyof TCollegeSplitsRow]}`).toFixed(column.decimalPlaces)
					: (datum[column.key as keyof TCollegeSplitsRow] as string)
		})
	)
];

export const getStatColumns = (rawRowKeys: Array<string>) =>
	rawRowKeys.filter((rowKey: string) => !METADATA_COLS.includes(rowKey));

export const INITIAL_SORT = { key: "season", asc: true };

export const PLAYER_TYPE_OPTS = [
	{ label: "Hitting", value: "BATTER" },
	{ label: "Pitching", value: "PITCHER" }
];
