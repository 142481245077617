import React from "react";
import PropTypes from "prop-types";

const StatsTooltip = ({ tooltip }) => {
	return (
		<div>
			<div style={{ fontWeight: "bold", textAlign: "center" }}>{tooltip.name}</div>
			{tooltip.units != null && <div style={{ fontStyle: "italic", textAlign: "center" }}>{tooltip.units}</div>}
			{tooltip.formula != null && <div style={{ textAlign: "center" }}>{tooltip.formula}</div>}
			<div style={{ fontStyle: "italic", textAlign: "center" }}>{tooltip.description}</div>
		</div>
	);
};

StatsTooltip.propTypes = {
	tooltip: PropTypes.object
};

export default StatsTooltip;
