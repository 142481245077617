import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function ArrowForwardIos({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
			<path fill={"none"} d="M0 0h24v24H0z" />
		</IconSVG>
	);
}
