import React, { createContext, useEffect } from "react";
import { Interpreter, TypegenDisabled } from "xstate";
import { useInterpret } from "@xstate/react";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { $TSAnyRequired } from "utils/tsutils";
import { TPlayerPagePlayerClassification } from "_react/playerpage/_types";

import PlayerPageMachine, {
	SET_PLAYER_LEGACY_ID,
	SET_PLAYER_ID,
	SET_VIEW_CLASSIFICATION,
	TPlayerPageContext,
	TPlayerPageEvent
} from "_react/playerpage/_machine";

// xState says to use the `InterpreterFrom<typeof yourMachine>` type (which translates to Interpreter<...>), but its' a type mismatch
// Type 'TypegenDisabled' is not assignable to type 'ResolveTypegenMeta<TypegenDisabled, TPlayerPageProEvent, BaseActionObject, ServiceMap>'
// https://xstate.js.org/docs/recipes/react.html#global-state-react-context
// So using a workaround which explicitly creates the `Interpreter` type we expect
// https://github.com/statelyai/xstate/discussions/3130
export const PlayerPageContext = createContext({
	playerPageService: {} as Interpreter<
		TPlayerPageContext,
		$TSAnyRequired,
		TPlayerPageEvent,
		$TSAnyRequired,
		TypegenDisabled
	>
});

type PlayerPageProviderProps = {
	philId?: number;
	playerId?: number;
	viewClassification?: TPlayerPagePlayerClassification;
	toast?: CreateToastFnReturn;
	children?: React.ReactNode;
};

const PlayerPageProvider = ({ philId, playerId, viewClassification, toast, children }: PlayerPageProviderProps) => {
	const playerPageService = useInterpret(PlayerPageMachine(philId, playerId, viewClassification, toast));

	// Update the machine's context when prop changes
	useEffect(() => {
		playerPageService.send({ type: SET_PLAYER_LEGACY_ID, value: philId });
	}, [playerPageService, philId]);

	useEffect(() => {
		playerPageService.send({ type: SET_PLAYER_ID, value: playerId });
	}, [playerPageService, playerId]);

	useEffect(() => {
		playerPageService.send({ type: SET_VIEW_CLASSIFICATION, value: viewClassification });
	}, [playerPageService, viewClassification]);

	return <PlayerPageContext.Provider value={{ playerPageService }}>{children}</PlayerPageContext.Provider>;
};

export default PlayerPageProvider;
