export const PLAYING_LEVEL_PRO = "PRO";
export const PLAYING_LEVEL_AMA = "AMA";
export const PLAYING_LEVEL_INTL = "INTL";

export const MODEL_EV = "ev";
export const MODEL_EV_LA = "ev_la";
export const MODEL_EV_LA_HLA = "ev_la_hla";
export const MODEL_EV_LA_HLA_SS = "ev_la_hla_ss";
export const MODEL_EV_LA_SS = "ev_la_ss";

export const BATS_OVERALL = "OVR";
export const BATS_L = "L";
export const BATS_R = "R";

export const THROWS_OVERALL = "OVR";
export const THROWS_L = "L";
export const THROWS_R = "R";

export const GAME_TYPE_OVERALL = "OVR"; // Overall is Postseason and Regular Season
export const GAME_TYPE_POSTSEASON = "PS";
export const GAME_TYPE_REGULAR_SEASON = "RS";
export const GAME_TYPE_SPRING_TRAINING = "ST";
