import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import { Button } from "_react/shared/legacy/ui/Button";
import { titleCase } from "utils/functions";

export const tableStyle = {
	width: "100%",
	border: "1px solid black",
	borderCollapse: "collapse",
	tableLayout: "fixed",
	fontSize: "12px"
};

export const cellStyle = {
	border: "1px solid black",
	borderCollapse: "collapse",
	padding: "1px",
	wordWrap: "break-word"
};

const formatFieldNames = fieldName => {
	const find = "_";
	const re = new RegExp(find, "g");
	fieldName = fieldName.replace(re, " ");
	return fieldName.toUpperCase();
};

const formatFieldValues = (field, history, colorScheme) => {
	if (field.type === "button")
		return (
			<Button
				colorScheme={colorScheme}
				onClick={() => history.push(field.value)}
				style={{ fontSize: 12 }}
				title={field.name}
				variant="contained"
			/>
		);
	return field.value != null ? field.value : "-";
};

export const InjuryDetails = ({ injury, colorSchemeGroup, philId, viewClassification }) => {
	const history = useHistory();
	let dynamicAttributes = [];

	if (injury.source === "EBIS") {
		dynamicAttributes = [
			{
				name: "Injury Date",
				value: injury.injury_date != null ? dayjs(injury.injury_date).format("MMMM D[,] YYYY") : "-"
			},
			{
				name: "Original IL Placement Date",
				value: dayjs(injury.date).format("MMMM D[,] YYYY")
			},
			{ name: "Body Side", value: injury.body_side },
			{ name: "Body Part", value: injury.body_part },
			{ name: "Body Part Detail", value: injury.body_part_detail },
			{ name: "Diagnosis", value: injury.diagnosis },
			{ name: "Ailment", value: injury.ailment },
			{ name: "Sustained Place", value: injury.sustained_place },
			{
				name: "Reinstatement Date",
				value: injury.reinstatement_date ? dayjs(injury.reinstatement_date).format("MMMM D[,] YYYY") : null
			},
			{ name: "Days Missed", value: injury.days_missed },
			{ name: "IL Type", value: injury.il_type },
			{
				name: "Last Game Date",
				value: injury.last_game_date ? dayjs(injury.last_game_date).format("MMMM D[,] YYYY") : null
			},
			{
				name: "Roster Affected Date",
				value: injury.roster_affected_date ? dayjs(injury.roster_affected_date).format("MMMM D[,] YYYY") : null
			},
			{
				name: "Earliest Reinstatement Date",
				value: injury.earliest_reinstatement_date
					? dayjs(injury.earliest_reinstatement_date).format("MMMM D[,] YYYY")
					: null
			},
			{
				name: "Exam Date",
				value: injury.exam_date ? dayjs(injury.exam_date).format("MMMM D[,] YYYY") : null
			},
			{ name: "Org", value: injury.org },
			{ name: "Team", value: injury.team }
		];
	} else if (injury.source === "Gumbo") {
		dynamicAttributes = [
			{ name: "Date", value: dayjs(injury.date).format("MMMM D[,] YYYY") },
			{ name: "Team", value: injury.teamName },
			{ name: "Game Type", value: injury.game_type },
			{ name: "Level", value: injury.level },
			{ name: "Injury Type", value: titleCase(injury.injury_type) },
			{
				name: "Inning",
				value: `${titleCase(injury.inning_half)} ${injury.inning}`
			},
			{ name: "Pitch Number", value: injury.pitch_number },
			{
				name: "Removed From Game",
				value: `${injury.is_removed === 1 ? "Yes" : "No"}`
			}
		];
	} else if (injury.source === "BIS") {
		dynamicAttributes = [
			{ name: "Date", value: dayjs(injury.date).format("MMMM D[,] YYYY") },
			{
				name: "Inning",
				value:
					injury.inning != null
						? `${injury.inning_half === 0 ? "Top" : injury.inning_half === 1 ? "Bottom" : "-"} ${
								injury.inning
						  }`
						: "-"
			},
			{
				name: "Left Immediately",
				value: injury.left_immediately === 1 ? "Yes" : "No"
			},
			{ name: "Type", value: injury.type_descr },
			{ name: "Region", value: injury.region_descr },
			{ name: "Part", value: injury.part_descr },
			{ name: "Side", value: injury.side_descr },
			{ name: "Diagnosis", value: injury.diagnosis_descr }
		];
	} else if (injury.source === "Report") {
		dynamicAttributes = [
			{
				name: "Seen Date",
				value: injury.seen_date != null ? dayjs(injury.seen_date).format("MMMM D[,] YYYY") : null
			},
			{
				name: "Scout",
				value: `${injury.scout_first_name} ${injury.scout_last_name}`
			},
			{ name: "Injury Notes", value: injury.note },
			{
				name: "View Report",
				type: "button",
				value: `/eval?id=${injury.eval_id}`
			}
		];
	}

	const injuryNotes = injury.notes
		? injury.notes.sort((a, b) => (dayjs(a.update_time).isAfter(dayjs(b.update_time)) ? 1 : -1))
		: [];

	return (
		<table style={tableStyle}>
			<tbody>
				{dynamicAttributes.map((entry, idx) => {
					return (
						<tr
							key={idx}
							style={{
								...cellStyle,
								backgroundColor: idx % 2 ? "#EEEEEE" : "white",
								borderBottom: "0px solid black",
								borderTop: "0px solid black"
							}}
						>
							<td>
								<b>{formatFieldNames(entry.name)}</b>
							</td>
							<td>{formatFieldValues(entry, history, colorSchemeGroup.secondary)}</td>
						</tr>
					);
				})}
				{injury.source === "EBIS" && (
					<tr
						key={"transactiondetails"}
						style={{
							...cellStyle,
							backgroundColor: dynamicAttributes.length % 2 ? "#EEEEEE" : "white",
							borderBottom: "0px solid white",
							borderTop: "0px solid black"
						}}
					>
						<td>
							<b>TRANSACTION DETAILS</b>
						</td>
						<td>
							<Button
								colorScheme={colorSchemeGroup.secondary}
								onClick={() => {
									history.push(
										`?philId=${philId}&transactionId=${injury.placement_transaction_id}&view=transactions&viewClassification=${viewClassification}`
									);
								}}
								style={{ fontSize: 12 }}
								title={"View Transaction"}
								variant="contained"
							/>
						</td>
					</tr>
				)}
				{injury.source === "BIS" &&
					injuryNotes.map((note, idx) => {
						return (
							<tr
								key={"transactiondetails"}
								style={{
									...cellStyle,
									backgroundColor: dynamicAttributes.length % 2 ? "#EEEEEE" : "white",
									borderBottom: "0px solid white",
									borderTop: "0px solid black"
								}}
							>
								{idx === 0 && (
									<td rowSpan={injuryNotes.length > 0 ? injuryNotes.length : 1}>
										<b>NOTES</b>
									</td>
								)}
								<td>
									{injuryNotes.length && (
										<div>
											<span style={{ fontWeight: "bold" }}>
												{`${dayjs(injuryNotes[idx].update_time).format("MM/DD/YYYY")}`} (
												{injuryNotes[idx].note_source})
											</span>{" "}
											- {`${injuryNotes[idx].notes}`}
										</div>
									)}
									{!injuryNotes.length && "None"}
								</td>
							</tr>
						);
					})}
			</tbody>
		</table>
	);
};

InjuryDetails.propTypes = {
	colorSchemeGroup: PropTypes.object,
	injury: PropTypes.object,
	philId: PropTypes.number
};
