import React, { useState } from "react";

import { Sidebar } from "_react/app/Sidebar";
import { Header } from "_react/app/header";
import { $TSFixMe } from "utils/tsutils";

type NavigationProps = {
	handleLogout: Function;
	links: $TSFixMe;
	hideModal?: Function;
	showModal?: Function;
};

export const Navigation: React.FC<NavigationProps> = ({ handleLogout, links }) => {
	const [navDrawerOpen, setNavDrawerOpen] = useState(false);
	return (
		<div>
			<Header handleLogout={handleLogout} handleToggleNavDrawer={() => setNavDrawerOpen(prevOpen => !prevOpen)} />
			<Sidebar handleNavClose={() => setNavDrawerOpen(false)} links={links} show={navDrawerOpen} />
		</div>
	);
};
