import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import {
	generateHeadSlider,
	generateBadges,
	generatePitchBadges,
	generateOtherPitchCommandHeatSlider,
	getOthPitches
} from "_react/playerpage/shared_js/evals/_componentGenerators";
import EvalTableGroupRow from "_react/playerpage/shared_js/evals/EvalTableGroupRow";

const StyledHighlights = styled("div")`
	flex-basis: 50%;
	@media (max-width: 991px) {
		display: none;
	}
`;

const StyledReports = styled("div")`
	flex-basis: 50%;
	border: 2px solid #e2e2e2;
	borderradius: 3px;
	overflow: hidden;
	@media (max-width: 991px) {
		flex-basis: 100%;
	}
`;

const EvalTableGroupRows = ({ evaluations, isProEvalAdmin }) => {
	const groupedEvals = [...evaluations].reverse().reduce((dict, e) => {
		if (dict[e.eval_create_user_id] == null) dict[e.eval_create_user_id] = [];
		dict[e.eval_create_user_id].push(e);
		return dict;
	}, {});
	const evalKeys = Object.keys(groupedEvals).sort((a, b) =>
		(groupedEvals[b][groupedEvals[b].length - 1].seen_date
			? groupedEvals[b][groupedEvals[b].length - 1].seen_date
			: dayjs()
		).diff(
			groupedEvals[a][groupedEvals[a].length - 1].seen_date
				? groupedEvals[a][groupedEvals[a].length - 1].seen_date
				: dayjs()
		)
	);

	let sliders = (isProEvalAdmin
		? [
				{ field: "ofp", label: "OFP" },
				{ field: "hitting_ability_present", label: "Hitting Ability" },
				{ field: "ab_quality_present", label: "AB Quality" },
				{ field: "raw_power_present", label: "Raw Power" },
				{ field: "power_production_present", label: "Power Production" },
				{ field: "run_speed_present", label: "Run Speed" },
				{ field: "base_running_present", label: "Baserunning" },
				{ field: "fb_vel_present", label: "Fastball Velocity" },
				{ field: "fb_mov_present", label: "Fastball Movement" },
				{ field: "fb_cmd_present", label: "Fastball Command" },
				{ field: "cb_cmd_present", label: "Curveball Command" },
				{ field: "sl_cmd_present", label: "Slider Command" },
				{ field: "ch_cmd_present", label: "Changeup Command" },
				{ field: "pitchability_present", label: "CHP/Pitchability" },
				{ field: "arm_strength_present", label: "Arm Strength" },
				{ field: "arm_accuracy_present", label: "Arm Accuracy" },
				{ field: "fielding_ability_present", label: "Fielding Ability" },
				{ field: "receiving_present", label: "Receiving" },
				{ field: "blocking_present", label: "Blocking" },
				{ field: "fielding_range_present", label: "Fielding Range" },
				{ field: "defense_present", label: "Overall Defense" }
		  ]
		: [
				{ field: "ofp", label: "OFP" },
				{ field: "hitting_ability_future", label: "Hitting Ability" },
				{ field: "ab_quality_future", label: "AB Quality" },
				{ field: "raw_power_future", label: "Raw Power" },
				{ field: "power_production_future", label: "Power Production" },
				{ field: "run_speed_future", label: "Run Speed" },
				{ field: "base_running_future", label: "Baserunning" },
				{ field: "fb_vel_future", label: "Fastball Velocity" },
				{ field: "fb_mov_future", label: "Fastball Movement" },
				{ field: "fb_cmd_future", label: "Fastball Command" },
				{ field: "cb_cmd_future", label: "Curveball Command" },
				{ field: "sl_cmd_future", label: "Slider Command" },
				{ field: "ch_cmd_future", label: "Changeup Command" },
				{ field: "pitchability_future", label: "CHP/Pitchability" },
				{ field: "arm_strength_future", label: "Arm Strength" },
				{ field: "arm_accuracy_future", label: "Arm Accuracy" },
				{ field: "fielding_ability_future", label: "Fielding Ability" },
				{ field: "receiving_future", label: "Receiving" },
				{ field: "blocking_future", label: "Blocking" },
				{ field: "fielding_range_future", label: "Fielding Range" },
				{ field: "defense_future", label: "Overall Defense" }
		  ]
	)
		.map(f => generateHeadSlider(evaluations, f.field, f.label))
		.filter(e => e !== null);

	sliders = [
		...sliders,
		...getOthPitches(evaluations, isProEvalAdmin).map(p => generateOtherPitchCommandHeatSlider(p))
	];

	const halfWayThough = Math.ceil(sliders.length / 2);

	const leftSide = sliders.slice(0, halfWayThough);
	const rightSide = sliders.slice(halfWayThough, sliders.length);

	const badges = generateBadges(evaluations);
	const pitchBadges = generatePitchBadges(evaluations, isProEvalAdmin);

	return (
		<div
			style={{
				backgroundColor: "#F2F2F2",
				padding: "5px",
				display: "flex",
				alignItems: "center"
			}}
		>
			<StyledHighlights>
				<div
					style={{
						fontWeight: "700",
						fontSize: "1.3em",
						textAlign: "center",
						marginBottom: "5px"
					}}
				>
					Highlights
				</div>
				{pitchBadges}
				<div style={{ display: "flex", justifyContent: "center" }}>
					<div style={{ flexGrow: 1 }}>
						{leftSide.map((s, idx) => (
							<div key={idx} style={{ marginTop: "10px" }}>
								{s}
							</div>
						))}
					</div>
					{rightSide.length > 0 && (
						<div style={{ flexGrow: 1 }}>
							{rightSide.map((s, idx) => (
								<div key={idx} style={{ marginTop: "10px" }}>
									{s}
								</div>
							))}
						</div>
					)}
				</div>
				{badges}
			</StyledHighlights>
			<StyledReports>
				{evalKeys.map((user, idx) => (
					<EvalTableGroupRow
						evaluations={groupedEvals[user]}
						idx={idx}
						isProEvalAdmin={isProEvalAdmin}
						key={idx}
					/>
				))}
			</StyledReports>
		</div>
	);
};

EvalTableGroupRows.propTypes = {
	evaluations: PropTypes.array,
	isProEvalAdmin: PropTypes.bool
};

export default EvalTableGroupRows;
