/*
    Centralized error handler
 */
import { NOTIFY_USER } from "_redux/Error/_types";

export const INITIAL_STATE = {
	errors: [],
	error: null,
	errorResponse: null,
	errorType: null,
	notify_user: false
};

export const getErrorFromResponse = action => {
	const { error, type } = action;

	if (error && error.response !== undefined) {
		return JSON.stringify(error.response.data);
	} else if (error && error instanceof Object && "message" in error) {
		return error.message + " type: " + type;
	} else if (error && error instanceof Object) {
		return error;
	} else {
		return `${error} [type ${type}]`;
	}
};

export const getStatusFromResponse = action => {
	const { error } = action;
	if (error && error.hasOwnProperty("response")) {
		try {
			return error.response.status;
		} catch (err) {
			console.error(err);
			return err;
		}
	}
	return null;
};

export const updateErrorMessage = (state, action) => {
	const latest_errors = state.errors.slice();
	const error_message = getErrorFromResponse(action);
	// eslint-disable-next-line no-console
	latest_errors.push({
		type: action.type,
		error: error_message,
		timestamp: new Date()
	});

	let notify_user;
	try {
		if (action.payload.hasOwnProperty("notify_user")) {
			notify_user = action.payload.notify_user;
		} else {
			notify_user = true;
		}
	} catch (err) {
		notify_user = true;
	}

	return {
		...state,
		errors: latest_errors,
		error: error_message,
		errorStatus: getStatusFromResponse(action),
		errorType: action.type,
		notify_user
	};
};

const error = (state = INITIAL_STATE, action) => {
	const { type } = action;
	if (type.toLowerCase().includes("failure") || type.toLowerCase().includes("error")) {
		return updateErrorMessage(state, action);
	}

	switch (action.type) {
		case NOTIFY_USER:
			return { ...state, notify_user: action.value };
		default:
			return state;
	}
};

export default error;
