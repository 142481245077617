import React, { useEffect, useState, useMemo, CSSProperties } from "react";

import { TOption } from "_react/inputs";
import { ILoginBasic } from "_react/shared/_types/schema/login";
import { useFetch } from "utils/url_helpers";

import { StyledSelect } from "_react/shared/selects";

type TUserSelect = {
	handleSelect: Function;
	placeholder?: string;
	styles?: CSSProperties;
	value?: number | null;
	activeOnly?: boolean;
	disabled?: boolean;
	forDisplay?: boolean; // Indicates if this should show a select or just the value (so the data source is still managed here)
	isClearable?: boolean;
	groupIds?: Array<number>; // Passed to the API request
	userIds?: Array<number>; // Not passed to the API request; only used to filter the response data
};

export type TUserOption = TOption<number>;

const UserSelect = ({
	handleSelect,
	placeholder = "Select a User...",
	value = null,
	activeOnly = false,
	disabled = false,
	forDisplay = false,
	isClearable = false,
	groupIds,
	userIds
}: TUserSelect) => {
	let userRoute = `/logins?isBasic=True${groupIds ? `&groupIds=${groupIds.join(",")}` : ""}`;
	if (activeOnly) userRoute = userRoute + "&isActiveOnly=True";
	const { responseData: userResponseData, isInProgress } = useFetch(userRoute, []);
	const [userDataSource, setUserDataSource] = useState<Array<TUserOption>>([]);

	function userName(user: ILoginBasic) {
		return user.firstName + " " + user.lastName;
	}
	useEffect(() => {
		if (userResponseData) {
			setUserDataSource(
				userResponseData
					.sort((a: ILoginBasic, b: ILoginBasic) => userName(a).localeCompare(userName(b)))
					.map((user: ILoginBasic) => ({
						label: userName(user),
						value: user.userId
					}))
			);
		}
	}, [userResponseData, setUserDataSource, activeOnly]);

	const user = useMemo(() => {
		if (userDataSource.length && value) return userDataSource.find((user: TUserOption) => user.value === value);
		return null;
	}, [userDataSource, value]);

	const valueProps = value === undefined ? {} : user ? { value: user } : { value: null };

	if (forDisplay) return <span>{user ? (user as TOption<number>).label : ""}</span>;

	return (
		<StyledSelect
			options={userDataSource.filter((opt: TUserOption) => userIds === undefined || userIds.includes(opt.value))}
			onChange={handleSelect}
			placeholder={placeholder}
			disabled={disabled || isInProgress}
			isClearable={isClearable}
			isLoading={isInProgress}
			{...valueProps}
			styles={{ container: { width: "100%" } }}
		/>
	);
};

export default UserSelect;
