import React from "react";
import { VStack } from "@chakra-ui/react";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TPlayingLevel } from "_react/shared/data_models/hitter_grades/_types";
import Section from "_react/shared/ui/presentation/components/Section/Section";
import ArsenalStuffDistributions, {
	TArsenalStuffDistributionsSecondaryData
} from "_react/shared/ui/data/plots/ArsenalStuffDistributions/ArsenalStuffDistributions";
import { TOOLTIP_STUFF } from "_react/shared/_constants/tooltips";

import { SectionStyleObject } from "_react/playerpage/pro/shared/_styles";

type TStuffSectionProps = {
	player: TPlayerPageCombinedPlayer;
	playingLevel: TPlayingLevel;
	seasonFilter: number;
	batsFilter: string;
	throwsFilter?: string;
	shouldFetchArsenalStuffDistributionsSecondaryData?: boolean;
	arsenalStuffDistributionsSecondaryData?: TArsenalStuffDistributionsSecondaryData;
};

const StuffSection = ({
	player,
	playingLevel,
	seasonFilter,
	batsFilter,
	throwsFilter,
	shouldFetchArsenalStuffDistributionsSecondaryData,
	arsenalStuffDistributionsSecondaryData
}: TStuffSectionProps) => {
	return (
		<Section label="Stuff" tooltipLabel={TOOLTIP_STUFF} style={SectionStyleObject}>
			<VStack align="start">
				<ArsenalStuffDistributions
					playerId={player.id}
					playingLevel={playingLevel}
					batsFilter={batsFilter}
					throwsFilter={throwsFilter}
					seasonFilter={seasonFilter}
					shouldFetchSecondaryData={shouldFetchArsenalStuffDistributionsSecondaryData}
					secondaryData={arsenalStuffDistributionsSecondaryData}
				/>
			</VStack>
		</Section>
	);
};

export default StuffSection;
