import {
	CREATE_AGENT_SUCCESS,
	CREATE_AGENT_FAILURE,
	CREATE_AGENCY_SUCCESS,
	CREATE_AGENCY_FAILURE
} from "_redux/Agent/_types";
import { BASE_URL } from "utils/redux_constants";
import axios from "_redux/_utils/_axios";
import { genericErrorAction } from "_redux/Error/error.a";
import { normalize } from "_redux/_utils/functions";
import { AGENT_METADATA, AGENCY_METADATA } from "_redux/Agent/_helpers";

export const createAgent = agentDict => {
	return function(dispatch) {
		return axios
			.post(`${BASE_URL}/agent`, agentDict)
			.then(response => {
				dispatch({
					type: CREATE_AGENT_SUCCESS,
					payload: normalize(response.data, AGENT_METADATA)
				});
			})
			.catch(error => {
				dispatch(genericErrorAction(CREATE_AGENT_FAILURE, error));
			});
	};
};

export const createAgency = agencyDict => {
	return function(dispatch) {
		return axios
			.post(`${BASE_URL}/agency`, agencyDict)
			.then(response => {
				dispatch({
					type: CREATE_AGENCY_SUCCESS,
					payload: normalize(response.data, AGENCY_METADATA)
				});
			})
			.catch(error => {
				dispatch(genericErrorAction(CREATE_AGENCY_FAILURE, error));
			});
	};
};
