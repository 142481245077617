import { StylesConfig, CSSObjectWithLabel } from "react-select";

import { TLkValueConstraint } from "_react/inputs";
import { TLkOption } from "_react/inputs/lks/LKSelectCustom";

export const selectStyles: (color: string) => StylesConfig<TLkOption<TLkValueConstraint>, boolean> = color => ({
	menu: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontSize: 11,
		position: "relative",
		overflow: "visible"
	}),
	input: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontSize: 11,
		color: color
	}),
	loadingIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: color
	}),
	valueContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontWeight: 400
	})
});
