import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { TReplacementLevelActive } from "_react/shared/data_models/phred/_types";
import {
	TSimpleProjections,
	TFutureSeasonBattingProj,
	TSimpleProjectionsAll
} from "_react/shared/data_models/projections/_types";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";

import { fetchSimpleProjectionsAll } from "_react/shared/data_models/projections/_network";
import { fetchReplacementLevelActive } from "_react/shared/data_models/phred/_network";

import {
	SIMPLE_PROJECTIONS_CANCEL_SOURCE,
	REPLACEMENT_LEVEL_CANCEL_SOURCE
} from "_react/shared/ui/data/tables/ProjectionsTable/_constants";
import { TProjectionsTableData } from "_react/shared/ui/data/tables/ProjectionsTable/_types";
import { extractFromEventDataArray } from "_react/shared/_helpers/xstate";

export type TProjectionsTableCancelSource = {
	[SIMPLE_PROJECTIONS_CANCEL_SOURCE]?: CancelTokenSource;
	[REPLACEMENT_LEVEL_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TProjectionsTableContext = {
	playerId?: number;
	lastPlayerId?: number; // Not currently used
	shouldFetchData?: boolean;
	simpleProjections?: Array<TSimpleProjections> | null;
	platoonProjections?: Array<TFutureSeasonBattingProj> | null;
	replacementLevel?: TReplacementLevelActive | null;
	cancelSources: TProjectionsTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IProjectionsTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all simple projections
				simpleProjections: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches the replacement levels
				replacementLevel: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_SIMPLE_PROJECTIONS = { initialized: { simpleProjections: "fetching" } };
export const FETCHING_REPLACEMENT_LEVEL = { initialized: { replacementLevel: "fetching" } };

const FETCH_SIMPLE_PROJECTIONS_DONE = "done.invoke.fetchSimpleProjections:invocation[0]";
const FETCH_REPLACEMENT_LEVEL_DONE = "done.invoke.fetchReplacementLevel:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_SIMPLE_PROJECTIONS = "SET_SIMPLE_PROJECTIONS";
export const SET_PLATOON_PROJECTIONS = "SET_PLATOON_PROJECTIONS";
export const SET_REPLACEMENT_LEVEL = "SET_REPLACEMENT_LEVEL";

type TFetchSimpleProjectionsEvent = {
	type: typeof FETCH_SIMPLE_PROJECTIONS_DONE;
	data: Array<TSimpleProjectionsAll> | undefined;
};
type TFetchReplacementLevelEvent = {
	type: typeof FETCH_REPLACEMENT_LEVEL_DONE;
	data: Array<TReplacementLevelActive> | undefined;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetSimpleProjectionsEvent = { type: typeof SET_SIMPLE_PROJECTIONS; value?: Array<TSimpleProjections> };
type TSetPlatoonProjectionsEvent = { type: typeof SET_PLATOON_PROJECTIONS; value?: Array<TFutureSeasonBattingProj> };
type TSetReplacementLevelEvent = { type: typeof SET_REPLACEMENT_LEVEL; value?: TReplacementLevelActive | null };

type TProjectionsTableEvent =
	| TFetchSimpleProjectionsEvent
	| TFetchReplacementLevelEvent
	| TSetPlayerIdEvent
	| TSetSimpleProjectionsEvent
	| TSetPlatoonProjectionsEvent
	| TSetReplacementLevelEvent;

export type TProjectionsTableSend = Interpreter<
	TProjectionsTableContext,
	IProjectionsTableStateSchema,
	TProjectionsTableEvent
>["send"];

const ProjectionsTableMachine = (
	playerIdProp?: number,
	data?: TProjectionsTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TProjectionsTableContext, IProjectionsTableStateSchema, TProjectionsTableEvent>(
		{
			id: "projectionsTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				simpleProjections: data?.simpleProjections,
				platoonProjections: data?.platoonProjections,
				replacementLevel: data?.replacementLevel,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: {
							actions: ["setPlayerId", "clearSimpleProjections", "clearPlatoonProjections"],
							cond: "shouldSetPlayerId"
						},
						SET_SIMPLE_PROJECTIONS: { actions: "setSimpleProjections" },
						SET_PLATOON_PROJECTIONS: { actions: "setPlatoonProjections" },
						SET_REPLACEMENT_LEVEL: { actions: "setReplacementLevel" }
					},
					states: {
						simpleProjections: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchSimpleProjections",
												cond: "shouldFetchSimpleProjections"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchSimpleProjections",
									entry: ["refreshSimpleProjectionsCancelSource"],
									invoke: {
										src: "fetchSimpleProjections",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSimpleProjectionsSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSimpleProjectionsErrored"
										}
									}
								}
							}
						},
						replacementLevel: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchReplacementLevel",
												cond: "shouldFetchReplacementLevel"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchReplacementLevel",
									entry: ["refreshReplacementLevelCancelSource"],
									invoke: {
										src: "fetchReplacementLevel",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchReplacementLevelSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchReplacementLevelErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (context: TProjectionsTableContext, event: TProjectionsTableEvent) =>
					event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldFetchSimpleProjections: (context: TProjectionsTableContext, _event: TProjectionsTableEvent) =>
					context.simpleProjections === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchReplacementLevel: (context: TProjectionsTableContext, _event: TProjectionsTableEvent) =>
					context.replacementLevel === undefined && context.shouldFetchData === true
			},
			actions: {
				clearSimpleProjections: assign<TProjectionsTableContext, TProjectionsTableEvent>({
					simpleProjections: (_context: TProjectionsTableContext, _event: TProjectionsTableEvent) =>
						undefined,
					cancelSources: (context: TProjectionsTableContext, _event: TProjectionsTableEvent) => {
						const { cancelSources } = context;
						cancelSources[SIMPLE_PROJECTIONS_CANCEL_SOURCE]?.cancel();
						cancelSources[SIMPLE_PROJECTIONS_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				clearPlatoonProjections: assign<TProjectionsTableContext, TProjectionsTableEvent>({
					platoonProjections: (_context: TProjectionsTableContext, _event: TProjectionsTableEvent) =>
						undefined
				}),
				// Set Context Actions
				setPlayerId: assign<TProjectionsTableContext, TProjectionsTableEvent>({
					playerId: (context: TProjectionsTableContext, event: TProjectionsTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (context: TProjectionsTableContext, _event: TProjectionsTableEvent) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) => {
							if (cancelSource[0] !== REPLACEMENT_LEVEL_CANCEL_SOURCE) cancelSource[1]?.cancel();
						});
						return {};
					}
				}),
				setSimpleProjections: assign<TProjectionsTableContext, TProjectionsTableEvent>({
					simpleProjections: (context: TProjectionsTableContext, event: TProjectionsTableEvent) => {
						if (event.type !== SET_SIMPLE_PROJECTIONS) return context.simpleProjections;
						return event.value;
					},
					cancelSources: (context: TProjectionsTableContext, event: TProjectionsTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_SIMPLE_PROJECTIONS) return cancelSources;
						cancelSources[SIMPLE_PROJECTIONS_CANCEL_SOURCE]?.cancel();
						cancelSources[SIMPLE_PROJECTIONS_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlatoonProjections: assign<TProjectionsTableContext, TProjectionsTableEvent>({
					platoonProjections: (context: TProjectionsTableContext, event: TProjectionsTableEvent) => {
						if (event.type !== SET_PLATOON_PROJECTIONS) return context.platoonProjections;
						return event.value;
					}
				}),
				setReplacementLevel: assign<TProjectionsTableContext, TProjectionsTableEvent>({
					replacementLevel: (context: TProjectionsTableContext, event: TProjectionsTableEvent) => {
						if (event.type !== SET_REPLACEMENT_LEVEL) return context.replacementLevel;
						return event.value;
					},
					cancelSources: (context: TProjectionsTableContext, event: TProjectionsTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_REPLACEMENT_LEVEL) return cancelSources;
						cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE]?.cancel();
						cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				// Cancel Source Actions
				refreshSimpleProjectionsCancelSource: assign<TProjectionsTableContext, TProjectionsTableEvent>({
					cancelSources: (context: TProjectionsTableContext, _event: TProjectionsTableEvent) => {
						if (context.cancelSources[SIMPLE_PROJECTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[SIMPLE_PROJECTIONS_CANCEL_SOURCE].cancel();
						context.cancelSources[SIMPLE_PROJECTIONS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshReplacementLevelCancelSource: assign<TProjectionsTableContext, TProjectionsTableEvent>({
					cancelSources: (context: TProjectionsTableContext, _event: TProjectionsTableEvent) => {
						if (context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE] != null)
							context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE].cancel();
						context.cancelSources[REPLACEMENT_LEVEL_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchSimpleProjectionsSuccess: assign<TProjectionsTableContext, TProjectionsTableEvent>({
					simpleProjections: (context: TProjectionsTableContext, event: TProjectionsTableEvent) => {
						if (event.type !== FETCH_SIMPLE_PROJECTIONS_DONE) return context.simpleProjections;
						// API returns an object with hitter and pitcher projections
						// But for the first iteration, we only care to show the hitter projections
						return event.data == null
							? null
							: event.data?.length
							? event.data[0].simpleHitterProjections
							: null;
					},
					platoonProjections: (context: TProjectionsTableContext, event: TProjectionsTableEvent) => {
						if (event.type !== FETCH_SIMPLE_PROJECTIONS_DONE) return context.platoonProjections;
						return event.data == null
							? null
							: event.data?.length
							? event.data[0].futureSeasonBattingProjs
							: null;
					}
				}),
				handleFetchReplacementLevelSuccess: assign<TProjectionsTableContext, TProjectionsTableEvent>({
					replacementLevel: (context: TProjectionsTableContext, event: TProjectionsTableEvent) => {
						if (event.type !== FETCH_REPLACEMENT_LEVEL_DONE) return context.replacementLevel;
						return extractFromEventDataArray<TProjectionsTableEvent>(event);
					}
				}),
				// Fetch Error Actions
				handleFetchSimpleProjectionsErrored: (
					context: TProjectionsTableContext,
					_event: TProjectionsTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Simple Projections",
							description: "Error fetching simple projections.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchReplacementLevelErrored: (
					context: TProjectionsTableContext,
					_event: TProjectionsTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Replacement Level",
							description: "Error fetching replacement level data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchSimpleProjections: (context: TProjectionsTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchSimpleProjectionsAll(
							{
								playerId: playerId,
								isUseCache: true
							},
							context.cancelSources[SIMPLE_PROJECTIONS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchReplacementLevel: (_context: TProjectionsTableContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchReplacementLevelActive({
							sort: "date",
							isSortDescending: true,
							limit: 1
						});
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default ProjectionsTableMachine;
