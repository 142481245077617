// This has to be a .js file because okta doesn't have a type declaration to install
import axios from "axios";
import OktaAuth from "@okta/okta-auth-js";

export const ALL_ACCESS_ROUTES = ["/", "/videolink"];

export const OKTA_BOOKMARK_APP_REDIRECT_URI = process.env.REACT_APP_OKTA_BOOKMARK_APP_REDIRECT_URI;
export const BASE_IDENTITY_PROVIDER_URL = "https://phillies.okta.com";
export const OKTA_CLIENT_ID = "0oaa9zcpji1s3TfqQ1t7";
export const OKTA_CLIENT = new OktaAuth({
	url: BASE_IDENTITY_PROVIDER_URL,
	clientId: OKTA_CLIENT_ID,
	redirectUri: OKTA_BOOKMARK_APP_REDIRECT_URI
});
export const OKTA_AXIOS_INSTANCE = axios.create({
	headers: {
		"Content-Type": "application/json"
	}
});
