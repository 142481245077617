import { round10 } from "_react/shared/_helpers/numbers";
import { getRgbNumberArray } from "utils/color";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { DATA_COLOR_PRIMARY_RED_MIXED_WITH_WHITE_RGB } from "_react/shared/dataviz/_constants";
import { VALUE_TYPE_NUMBER } from "_react/shared/ui/presentation/components/Table/_constants";
import { COLOR_GRADIENT_FUNCTION_BLUE_GREEN } from "_react/projections/pro/leaderboard/_constants";
import { IFieldingProjectionsBlend } from "_react/shared/data_models/defensive_projections/_types";

import {
	NULL_FILLER_TEXT,
	PROPORTION_UPPER_THRESHOLD
} from "_react/shared/ui/data/tables/FieldingProjectionsTable/_constants";
import { TFieldingProjectionsRow } from "_react/shared/ui/data/tables/FieldingProjectionsTable/_types";

export const convertToPercentage = (input: number) => {
	const value = Math.round(input * 100);
	// Do not want to show zero values
	if (value === 0) return NULL_FILLER_TEXT;
	return `${value}%`;
};

export const convertToDecimal = (input: number) => {
	return round10(input, -1);
};

export const checkIfFieldingProjectionsExist = (projections?: Array<IFieldingProjectionsBlend> | null) => {
	if (!projections?.length) return false;
	const nonEmptyProjection = projections?.find((projection: IFieldingProjectionsBlend) => {
		return (
			projection.raaC ||
			projection.framingRaaC ||
			projection.blockingThrowingRaaC ||
			(projection.raa1B && projection.receivingRaa1B) ||
			projection.raa2B ||
			projection.raa3B ||
			projection.raaSs ||
			projection.raaLf ||
			projection.raaCf ||
			projection.raaRf
		);
	});
	return nonEmptyProjection !== undefined;
};

export const checkIfPitchingProjectionsExist = (projections?: Array<IFieldingProjectionsBlend> | null) => {
	if (!projections?.length) return false;
	const nonEmptyProjection = projections?.find((projection: IFieldingProjectionsBlend) => {
		return projection.raaSp || projection.raaRp;
	});
	return nonEmptyProjection !== undefined;
};

export const proportionColorGradientFunction = (value: number | string): Array<number> | null => {
	const parsedValue = typeof value === "string" ? parseFloat(value) : value;
	return parsedValue >= PROPORTION_UPPER_THRESHOLD
		? getRgbNumberArray(DATA_COLOR_PRIMARY_RED_MIXED_WITH_WHITE_RGB)
		: null;
};

export const calculateTotalRaa = (
	row: TFieldingProjectionsRow,
	isPitcherPositionGroup: boolean,
	isConvertToDecimal = true,
	nullFillerText = NULL_FILLER_TEXT
) => {
	if (
		row.fieldingProjectionsBlend?.raaC == null &&
		row.fieldingProjectionsBlend?.framingRaaC == null &&
		row.fieldingProjectionsBlend?.blockingThrowingRaaC == null &&
		row.fieldingProjectionsBlend?.raa1B == null &&
		row.fieldingProjectionsBlend?.receivingRaa1B == null &&
		row.fieldingProjectionsBlend?.raa2B == null &&
		row.fieldingProjectionsBlend?.raa3B == null &&
		row.fieldingProjectionsBlend?.raaSs == null &&
		row.fieldingProjectionsBlend?.raaLf == null &&
		row.fieldingProjectionsBlend?.raaCf == null &&
		row.fieldingProjectionsBlend?.raaRf == null &&
		row.fieldingProjectionsBlend?.raaSp == null &&
		row.fieldingProjectionsBlend?.raaRp == null
	)
		return nullFillerText;
	if (isPitcherPositionGroup) {
		const raaTotal =
			(row.fieldingProjectionsBlend?.raaSp ?? 0.0) * (row.projectedPitcherProportion?.proportionSp ?? 0.0) +
			(row.fieldingProjectionsBlend?.raaRp ?? 0.0) * (row.projectedPitcherProportion?.proportionRp ?? 0.0);
		return isConvertToDecimal ? convertToDecimal(raaTotal) : raaTotal;
	}
	const raaTotal =
		((row.fieldingProjectionsBlend?.raaC ?? 0.0) +
			(row.fieldingProjectionsBlend?.framingRaaC ?? 0.0) +
			(row.fieldingProjectionsBlend?.blockingThrowingRaaC ?? 0.0)) *
			(row.projectedPositionalProportion?.proportionC ?? 0.0) +
		((row.fieldingProjectionsBlend?.raa1B ?? 0.0) + (row.fieldingProjectionsBlend?.receivingRaa1B ?? 0.0)) *
			(row.projectedPositionalProportion?.proportion1B ?? 0.0) +
		(row.fieldingProjectionsBlend?.raa2B ?? 0.0) * (row.projectedPositionalProportion?.proportion2B ?? 0.0) +
		(row.fieldingProjectionsBlend?.raa3B ?? 0.0) * (row.projectedPositionalProportion?.proportion3B ?? 0.0) +
		(row.fieldingProjectionsBlend?.raaSs ?? 0.0) * (row.projectedPositionalProportion?.proportionSs ?? 0.0) +
		(row.fieldingProjectionsBlend?.raaLf ?? 0.0) * (row.projectedPositionalProportion?.proportionLf ?? 0.0) +
		(row.fieldingProjectionsBlend?.raaCf ?? 0.0) * (row.projectedPositionalProportion?.proportionCf ?? 0.0) +
		(row.fieldingProjectionsBlend?.raaRf ?? 0.0) * (row.projectedPositionalProportion?.proportionRf ?? 0.0);
	return isConvertToDecimal ? convertToDecimal(raaTotal) : raaTotal;
};

export const getTotalRaaColumn = (isPitcherPositionGroup: boolean) => {
	return {
		value: "totalRaa",
		label: "Total RAA",
		id: "totalRaa",
		valueType: VALUE_TYPE_NUMBER as TValueType,
		isMobile: true,
		getValueFunction: (row: TFieldingProjectionsRow) => calculateTotalRaa(row, isPitcherPositionGroup),
		getSortValueFunction: (row: TFieldingProjectionsRow) =>
			calculateTotalRaa(row, isPitcherPositionGroup, false) ?? null,
		colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN,
		style: {
			td: {
				borderRight: "4px double",
				borderColor: "gray.500"
			}
		}
	};
};
