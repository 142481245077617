import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function OutlineNoteAdd(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 96 960 960" {...props}>
			<path
				fill="currentColor"
				d="M440 816h80V696h120v-80H520V496h-80v120H320v80h120v120ZM240 976q-33 0-56.5-23.5T160 896V256q0-33 23.5-56.5T240 176h320l240 240v480q0 33-23.5 56.5T720 976H240Zm280-520V256H240v640h480V456H520ZM240 256v200-200 640-640Z"
			/>
		</Icon>
	);
}
