import React, { FunctionComponent, CSSProperties, useMemo, useEffect } from "react";
import styled from "@emotion/styled";
import { $TSFixMe } from "utils/tsutils";
import { IconButton } from "_react/shared/legacy/ui/IconButton";
import Cancel from "_react/shared/legacy/ui/icons/Cancel";
import { useFocus } from "_react/_hooks";
import { pick } from "utils/helpers";

type TCustomChangeEvent = { target: { value: string } };
export type TChangeEvent = React.ChangeEvent<HTMLInputElement> | TCustomChangeEvent;
type TCustomStyles = { placeholderColor?: string; underlineColor?: string };
export type TextFieldProps = {
	autofocus?: boolean;
	autoComplete?: string;
	disabled?: boolean;
	endAdornment?: $TSFixMe;
	fullWidth?: boolean;
	multiline?: boolean;
	name?: string;
	onBlur?: $TSFixMe;
	onChange?: (e: TChangeEvent) => void;
	onClear?: () => void;
	onFocus?: $TSFixMe;
	onKeyDown?: $TSFixMe;
	persistentPlaceholder?: boolean;
	persistentPlaceholderOverride?: boolean;
	placeholder?: string;
	showClear?: boolean;
	startAdornment?: $TSFixMe;
	style?: CSSProperties & TCustomStyles;
	type?: string;
	value?: string | null;
	reference?: $TSFixMe;
	key?: string;
	highlighted?: boolean;
};

type TStyledTextFieldProps = { style: CSSProperties; placeholderColor?: string; type?: string; disabled: boolean };
const StyledTextField = styled.input<TStyledTextFieldProps>(
	{
		border: "none",
		borderRadius: "0px",
		backgroundColor: "transparent",
		fontWeight: 500,
		fontSize: "15px",
		":focus": {
			outline: 0
		},
		"::placeholder": {
			opacity: 0.5
		}
	},
	(props: TStyledTextFieldProps) => {
		let color = "";
		if (props.style && (props.style.color || props.placeholderColor)) {
			color = (props.placeholderColor ? props.placeholderColor : props.style.color) as string;
		}
		return {
			cursor: props.disabled ? "not-allowed" : "inherit",
			"::placeholder": {
				opacity: 0.5,
				color
			}
		};
	}
);
const StyledTextFieldMultiline = styled.textarea<TStyledTextFieldProps>(
	{
		overflow: "hidden",
		resize: "none",
		border: "none",
		borderRadius: "0px",
		backgroundColor: "transparent",
		fontWeight: 500,
		fontSize: "15px",
		fontFamily: "Roboto, Helvetica, Arial, sans-serif",
		":focus": {
			outline: 0
		},
		"::placeholder": {
			opacity: 0.5
		}
	},
	(props: TStyledTextFieldProps) => {
		let color = "";
		if (props.style && (props.style.color || props.placeholderColor)) {
			color = (props.placeholderColor ? props.placeholderColor : props.style.color) as string;
		}
		return {
			cursor: props.disabled ? "not-allowed" : "inherit",
			"::placeholder": {
				opacity: 0.5,
				color
			}
		};
	}
);

const StyledTextFieldContainer = styled.div<TStyledTextFieldProps>(
	{
		display: "flex",
		boxSizing: "border-box",
		borderBottom: `1px solid black`,
		paddingBottom: "1px",
		":focus": {
			borderBottomWidth: `2px`,
			paddingBottom: "0px"
		}
	},
	(props: TStyledTextFieldProps) => {
		let color = "";
		if (props.style && props.style.color) {
			color = props.placeholderColor ? props.placeholderColor : props.style.color;
		}
		return {
			cursor: props.disabled ? "not-allowed" : "inherit",
			"::placeholder": {
				opacity: 0.5,
				color
			},
			":hover": {
				borderBottomWidth: props.disabled ? "1px" : "2px",
				paddingBottom: props.disabled ? "1px" : "0px"
			}
		};
	}
);

const StyledSectionTitle = styled.div`
	margin-top: -14px;
	margin-left: 2px;
	display: table;
	padding: 0px 1px;
	font-weight: 600;
	font-size: 0.7em;
	position: absolute;
	color: black;
	width: 100%;
`;

export const TextField: FunctionComponent<TextFieldProps> = ({
	autofocus = false,
	disabled = false,
	endAdornment = null,
	fullWidth = false,
	name,
	onBlur = () => null,
	onChange = () => null,
	onClear = () => null,
	onFocus = () => null,
	onKeyDown = () => null,
	multiline = false,
	persistentPlaceholder = false,
	persistentPlaceholderOverride,
	placeholder = "",
	showClear = false,
	startAdornment = null,
	style = {},
	type = "text",
	value = "",
	reference,
	key,
	highlighted = false
}) => {
	// Color Correction
	if (style.color) style.borderBottomColor = style.color;
	if (style.underlineColor) style.borderBottomColor = style.underlineColor;
	const [inputRef, setInputFocus] = useFocus();
	useEffect(() => {
		if (autofocus) (setInputFocus as Function)();
	}, [autofocus, setInputFocus]);

	const textFieldStyleItems = ["fontSize", "color", "textDecoration", "textAlign"];
	const bothStyleItems = ["flexGrow"];
	const textFieldStyle: CSSProperties = pick(style, [...textFieldStyleItems, ...bothStyleItems]);
	const containerStyleKeys = Object.keys(style).filter(field => {
		return !(textFieldStyleItems.includes(field) || field === "placeholderColor");
	});
	const containerStyle: CSSProperties = pick(style, containerStyleKeys);

	let endAdornmentUsed: $TSFixMe = endAdornment;
	let size;
	if (showClear && value != null && `${value}`.length > 0) {
		const fontSize = `${style.fontSize ? style.fontSize : "15px"}`;
		size = `${parseInt(fontSize.split("em")[0].split("px")[0], 10) * 1.5}${fontSize.includes("px") ? "px" : "em"}`;
		endAdornmentUsed = (
			<IconButton
				onClick={() => {
					onChange({ target: { value: "" } });
					onClear();
					setTimeout(() => (setInputFocus as Function)(), 10);
				}}
				style={{
					backgroundColor: "transparent",
					padding: "0px",
					height: size,
					width: size
				}}
			>
				<Cancel
					style={{
						height: size,
						width: size,
						color: style.color || "black"
					}}
				/>
			</IconButton>
		);
	}

	const TextFieldComponent = useMemo(() => (multiline ? StyledTextFieldMultiline : StyledTextField), [multiline]);

	useEffect(() => {
		// Autosizing
		(reference ?? inputRef).current.style.height = "inherit";
		(reference ?? inputRef).current.style.height = `${(reference ?? inputRef).current.scrollHeight - 5}px`;
	}, [value, inputRef, reference]);

	return (
		<StyledTextFieldContainer
			placeholderColor={style.placeholderColor}
			disabled={disabled}
			style={{
				display: "flex",
				position: "relative",
				flexGrow: fullWidth ? 1 : undefined,
				width: fullWidth ? "100%" : undefined,
				cursor: disabled ? "not-allowed" : "inherit",
				backgroundColor: disabled ? "lightGray" : "transparent",
				...containerStyle
			}}
		>
			{((persistentPlaceholder && value != null && `${value}`.length > 0) ||
				persistentPlaceholderOverride === true) && (
				<StyledSectionTitle>{placeholder.split("...")[0]}</StyledSectionTitle>
			)}
			{startAdornment}
			<TextFieldComponent
				autoComplete={"new-password"}
				disabled={disabled}
				name={name}
				onBlur={onBlur}
				onChange={(e: $TSFixMe) => {
					onChange(e);
				}}
				onFocus={onFocus}
				placeholder={persistentPlaceholderOverride !== true ? placeholder : undefined}
				// ref={ref ?? inputRef}
				ref={(el: $TSFixMe) => {
					if (reference != null) reference.current = el;
					if (inputRef != null) (inputRef as $TSFixMe).current = el;
				}}
				placeholderColor={style.placeholderColor}
				style={{
					flexGrow: fullWidth ? 1 : undefined,
					width: fullWidth ? "100%" : undefined,
					backgroundColor: highlighted ? "yellow" : undefined,
					...textFieldStyle
				}}
				type={type}
				value={value || ""}
				key={key}
				rows={1}
				onKeyDown={onKeyDown}
			/>
			{endAdornmentUsed}
		</StyledTextFieldContainer>
	);
};
