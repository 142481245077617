import React, { FC, forwardRef, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import dayjs, { Dayjs } from "dayjs";

import { TextField } from "_react/shared/legacy/ui/TextField";

const COMMON_DATE_PROPS = {
	// Not sure there is a way to do this without a ts-ignore
	// https://github.com/Hacker0x01/react-datepicker/issues/3180
	// popperModifiers: {
	// 	preventOverflow: {
	// 		enabled: true
	// 	}
	// },
	showMonthDropdown: true,
	showPopperArrow: false,
	showYearDropdown: true
};

type DatePickerCustomInputProps = {
	onUpdate: (value: Dayjs | undefined) => void;
	setTextValue: (value: string) => void;
	textValue?: string;
	onClick?: () => void;
	lastTextValue?: string;
	placeholder?: string;
	borderBottomStyle?: React.CSSProperties;
};

const DatePickerCustomInput = forwardRef(
	(
		{
			onClick,
			textValue,
			setTextValue,
			lastTextValue,
			onUpdate,
			placeholder,
			borderBottomStyle
		}: DatePickerCustomInputProps,
		ref
	) => {
		return (
			<TextField
				style={{ ...borderBottomStyle, flexGrow: 1 }}
				value={textValue}
				reference={ref}
				onFocus={onClick}
				onChange={e => setTextValue(e.target.value)}
				onBlur={() => {
					// Check for valid date
					if (textValue && textValue !== lastTextValue && dayjs(textValue).isValid())
						onUpdate(dayjs(textValue));
					// Invalid date, revert
					else if (textValue) setTextValue(lastTextValue ?? "");
					// Cleared date
					else if (textValue === "" && lastTextValue) onUpdate(undefined);
				}}
				placeholder={placeholder}
			/>
		);
	}
);

type DatePickerProps = {
	onChange: (value: Dayjs | undefined) => void;
	value?: Dayjs;
	dateFormatString?: string;
	maxDate?: Dayjs;
	placeholder?: string;
	disabled?: boolean;
	showMonthYearPicker?: boolean;
	isClearable?: boolean;
	showBorderBottom?: boolean;
	wrapperClassName?: string;
};

const DatePickerComponent: FC<DatePickerProps> = ({
	onChange,
	value,
	dateFormatString = "M/D/YYYY",
	maxDate,
	placeholder,
	disabled = false,
	showMonthYearPicker = false,
	isClearable = false,
	showBorderBottom = true, // When set to false, removes bottom border under custom input
	wrapperClassName
}) => {
	// Text field state
	const [textValue, setTextValue] = useState(value ? dayjs(value).format(dateFormatString) : "");
	useEffect(() => {
		setTextValue(value ? dayjs(value).format(dateFormatString) : "");
	}, [value, dateFormatString]);
	/*
		There is an issue where if you select a date, both the onChange callback
		as well as the onBlur callback are called, causing the onChange prop to
		be called twice.

		To get around this, when updating you update local state, which causes
		this useCallback function to only call onChange once.

		This also ensures that onChange is only called if the value is actually changing
		(blocking when you select the already selected date from calling onChange)
	*/
	const [newValue, setNewValue] = useState<Dayjs | undefined>(value);
	// const debouncedNewValue = useDebounce(newValue, 600);
	useEffect(() => {
		if (
			((value == null && newValue) ||
				(newValue == null && value) ||
				(value && newValue && !value.isSame(newValue)) ||
				(newValue && value && !newValue.isSame(value))) &&
			(maxDate == null || newValue == null || newValue.diff(maxDate, "day") <= 0)
		) {
			onChange(newValue);
		}
	}, [onChange, maxDate, newValue, value]);

	return (
		<DatePicker
			{...COMMON_DATE_PROPS}
			onChange={d => setNewValue(d ? dayjs(d) : undefined)}
			selected={value ? value.toDate() : undefined}
			disabled={disabled}
			customInput={
				<DatePickerCustomInput
					textValue={textValue}
					setTextValue={setTextValue}
					onUpdate={setNewValue}
					lastTextValue={value?.format(dateFormatString)}
					borderBottomStyle={showBorderBottom ? {} : { borderBottom: "none" }}
				/>
			}
			showMonthYearPicker={showMonthYearPicker}
			isClearable={isClearable}
			placeholderText={placeholder}
			wrapperClassName={wrapperClassName}
		/>
	);
};

export { DatePickerComponent as DatePicker };
