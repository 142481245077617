import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import React, { FunctionComponent, useState } from "react";

import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { IVideo } from "_react/shared/_types/schema/video";
import { selectHeight } from "utils/functions";
import { isEmpty } from "utils/helpers";
import { $TSFixMe, TCustomPlayerClassification } from "utils/tsutils";
import { PlayerSearch, TPlayerSearchOption } from "_react/shared/searches/PlayerSearch";
import { DatePicker } from "_react/shared/legacy/ui/DatePicker";
import { LinearProgressPopup } from "_react/shared/legacy/ui/LinearProgressPopup";
import { Radio } from "_react/shared/legacy/ui/Radio";
import { TextField } from "_react/shared/legacy/ui/TextField";
import VideoTagger from "_react/video/shared/VideoTagger";
import { TagOptionType } from "_react/video/shared/_types";
import FileUploadClip from "_react/video/VideoUploader/FileUploadClip";
import FileUploadContainer from "_react/video/VideoUploader/FileUploadContainer";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";

type VideoDictProps = {
	video: $TSFixMe;
	playerId: number;
	type: string;
	description: string;
	date: string;
	tags: Array<TagOptionType>;
	createdDate: string;
};

type VideoUploaderProps = {
	colorScheme?: ColorSchemeGroup;
	defaultPlayerSelected: TPlayerPageCombinedPlayer | null;
	playerClassification: TCustomPlayerClassification;
	uploadSuccess(vidDicts: IVideo[]): void;
};

const VideoUploader: FunctionComponent<VideoUploaderProps> = ({
	colorScheme = defaultColorScheme,
	defaultPlayerSelected,
	playerClassification,
	uploadSuccess
}) => {
	const [clipDict, setClipDict] = useState<{ [index: string]: $TSFixMe }>({});
	const [clipDescriptions, setClipDescriptions] = useState<{ [index: string]: $TSFixMe }>({});
	const [clipTypes, setClipTypes] = useState<{ [index: string]: $TSFixMe }>({});
	const [clipPlayers, setClipPlayers] = useState<{ [index: string]: TPlayerPageCombinedPlayer }>({});
	const [clipErrors, setClipErrors] = useState<{ [index: string]: $TSFixMe }>({});
	const [selectedClips, setSelectedClips] = useState<Array<$TSFixMe>>([]);
	const [uploadProgress, setUploadProgress] = useState<number | null>(null);
	const [clipDates, setClipDates] = useState<{ [index: string]: $TSFixMe }>({});
	const [clipTags, setClipTags] = useState<{ [index: string]: $TSFixMe }>({});

	const height = selectHeight();

	const uploadVideo = async (videoDict: VideoDictProps) => {
		const formData = new FormData();
		formData.append("video", videoDict.video);
		formData.append("player_id", videoDict.playerId.toString());
		formData.append("type", videoDict.type);
		formData.append("description", videoDict.description);
		formData.append("video_date", videoDict.date);
		formData.append("created_date", videoDict.createdDate);
		formData.append("tags", JSON.stringify(videoDict.tags));
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};

		const response = await axios.post(`/video`, formData, config);
		return response.data as IVideo;
	};
	function resetState() {
		setClipDict({});
		setClipDescriptions({});
		setClipTypes({});
		setClipPlayers({});
		setClipErrors({});
		setSelectedClips([]);
		setUploadProgress(null);
		setClipDates({});
		setClipTags({});
	}

	function handleDrop(e: $TSFixMe) {
		e.preventDefault();
		let files = [];
		if (e.target.files) {
			files = e.target.files;
		} else if (e.dataTransfer && e.dataTransfer.files) {
			files = e.dataTransfer.files;
		}
		const localClipDict: { [id: string]: $TSFixMe } = {};
		const localClipDates: { [id: string]: string } = {};
		const localClipPlayers = { ...clipPlayers };
		Array.from(files).forEach(clip => {
			const clipId = URL.createObjectURL(clip as Blob | MediaSource);
			localClipDict[clipId] = clip;
			localClipDates[clipId] = dayjs().format("YYYY-MM-DD");
			if (defaultPlayerSelected) {
				localClipPlayers[clipId] = defaultPlayerSelected;
			}
		});
		setClipDict({ ...clipDict, ...localClipDict });
		setSelectedClips(Object.keys(localClipDict));
		setClipPlayers(localClipPlayers);
		setClipDates({ ...clipDates, ...localClipDates });
	}

	function handleSelectClip(clipId: $TSFixMe) {
		let localSelectedClips = [...selectedClips];
		if (localSelectedClips.indexOf(clipId) > -1) {
			localSelectedClips = localSelectedClips.filter(c => c !== clipId);
		} else {
			localSelectedClips = localSelectedClips.concat([clipId]);
		}
		setSelectedClips(localSelectedClips);
	}

	function handleSelectAll() {
		const clipIds = Object.keys(clipDict);
		if (clipIds.length === selectedClips.length) {
			setSelectedClips([]);
		} else {
			setSelectedClips([...clipIds]);
		}
	}

	function handleClipDescriptionChange(e: $TSFixMe, clipId: $TSFixMe) {
		e.preventDefault();
		setClipDescriptions({
			...clipDescriptions,
			[clipId]: e.target.value
		});
	}

	function handleClipTypesChange(e: string, clipId: $TSFixMe) {
		setClipTypes({
			...clipTypes,
			[clipId]: e
		});
	}

	function updateDate(date: $TSFixMe, clipId: $TSFixMe) {
		setClipDates({
			...clipDates,
			[clipId]: date
		});
	}

	function handlePlayerSelect(playerDict: TPlayerSearchOption) {
		const philId = playerDict.philId;
		axios.get(`/player/${philId}?modelType=combined`).then(response => {
			const localClipPlayers = clipPlayers;
			selectedClips.forEach((clipId: string) => {
				localClipPlayers[clipId] = response.data;
			});
			setClipPlayers(localClipPlayers);
			setSelectedClips([]);
		});
	}

	function handleDeleteSelected() {
		const localClipDict = { ...clipDict };
		selectedClips.forEach((clipId: string) => {
			delete localClipDict[clipId];
		});
		setClipDict(localClipDict);
		setSelectedClips([]);
	}

	function getErrors() {
		const clipIds = Object.keys(clipDict);
		const localClipErrors: { [id: string]: Array<string> } = {};
		clipIds.forEach(clipId => {
			if (!clipTypes.hasOwnProperty(clipId) || !clipPlayers.hasOwnProperty(clipId)) {
				localClipErrors[clipId] = [];
				if (!clipTypes.hasOwnProperty(clipId)) {
					localClipErrors[clipId].push("type");
				}
				if (!clipPlayers.hasOwnProperty(clipId)) {
					localClipErrors[clipId].push("player");
				}
			}
		});
		return localClipErrors;
	}

	function uploadClips() {
		const clipIds = Object.keys(clipDict);
		setUploadProgress(0);
		let uploaded = 0;
		const vidDicts: IVideo[] = [];
		clipIds.forEach(clipId => {
			const vidDict: VideoDictProps = {
				video: clipDict[clipId],
				playerId: clipPlayers[clipId].id,
				type: clipTypes[clipId],
				tags: clipTags.hasOwnProperty(clipId) ? clipTags[clipId] : [],
				description: clipDescriptions.hasOwnProperty(clipId) ? clipDescriptions[clipId] : "",
				date: clipDates[clipId],
				createdDate: dayjs().format("YYYY-MM-DD")
			};
			Promise.resolve(uploadVideo(vidDict))
				.then(uploadedVideo => {
					vidDicts.push(uploadedVideo);
					uploaded += 1;
					const uploadProgress = 100 * (uploaded / clipIds.length);
					setUploadProgress(uploadProgress);
					if (uploadProgress === 100) {
						setTimeout(() => {
							uploadSuccess(vidDicts);
							resetState();
						}, 500);
					}
				})
				.catch(() => {
					setClipErrors({ ...clipErrors, [clipId]: ["upload"] });
				});
		});
	}

	function handleUploadVideos() {
		const localClipErrors = getErrors();
		if (!isEmpty(localClipErrors)) {
			setClipErrors(localClipErrors);
		} else {
			uploadClips();
		}
	}

	function formatTags(items: Array<Partial<TagOptionType>>) {
		if (items != null) {
			return items.map(item => ({
				tag_type: item.tag_type,
				group_id: item.group_id,
				tag_id: item.tag_id
			}));
		}
		return [];
	}

	function handleTagChange(item: Array<Partial<TagOptionType>>, clipId: string) {
		setClipTags({
			...clipTags,
			[clipId]: formatTags(item)
		});
	}
	const radioButtons = [
		{ label: "Hit", value: "hitting" },
		{ label: "Pit", value: "pitching" },
		{ label: "Fld", value: "fielding" },
		{ label: "Run", value: "running" },
		{ label: "Other", value: "other" }
	];
	const clipIds = Object.keys(clipDict);
	const searchComponent = (
		<PlayerSearch
			disabled={selectedClips.length === 0}
			onSelect={handlePlayerSelect}
			placeholder={
				selectedClips.length
					? `Select player for ${selectedClips.length} videos...`
					: "Select videos to attach player"
			}
			playerClassification={playerClassification}
		/>
	);

	return (
		<div
			style={{
				height: "calc(100% - 50px)",
				width: "100%",
				position: "relative",
				maxHeight: "100%"
			}}
		>
			<div
				style={{
					height: height - 55,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					maxHeight: "100%"
				}}
			>
				{!defaultPlayerSelected && (
					<div
						style={{
							marginTop: 10,
							width: "calc(100% - 20px)",
							marginLeft: "10px",
							marginRight: "10px"
						}}
					>
						{searchComponent}
					</div>
				)}
				<FileUploadContainer
					clipIds={clipIds}
					handleDeleteSelected={handleDeleteSelected}
					handleDrop={handleDrop}
					handleSelectAll={handleSelectAll}
					handleUploadVideos={handleUploadVideos}
					selectedClips={selectedClips}
				>
					{clipIds.map(clipId => {
						const typeError =
							clipErrors.hasOwnProperty(clipId) &&
							clipErrors[clipId].indexOf("type") > -1 &&
							!clipTypes.hasOwnProperty(clipId);
						return (
							<FileUploadClip
								clipId={clipId}
								handleSelect={() => handleSelectClip(clipId)}
								header={
									clipPlayers.hasOwnProperty(clipId) ? (
										<div style={{ textAlign: "center" }}>
											{`${clipPlayers[clipId].firstName ??
												clipPlayers[clipId].firstNameLegal} ${clipPlayers[clipId].lastName ??
												clipPlayers[clipId].lastNameExtended}`}
										</div>
									) : clipErrors.hasOwnProperty(clipId) &&
									  clipErrors[clipId].indexOf("player") > -1 ? (
										<div
											style={{
												textAlign: "center",
												color: "red"
											}}
										>
											Select a player
										</div>
									) : null
								}
								key={clipId}
								selected={selectedClips.indexOf(clipId) > -1}
							>
								<div
									style={{
										textAlign: "center",
										color: typeError ? "red" : "black"
									}}
								>
									{typeError ? "Select a Type" : "Type"}
								</div>
								<div style={{ width: "100%", display: "flex" }}>
									{radioButtons.map(({ label, value }) => {
										return (
											<div
												style={{ display: "flex", margin: "1px", alignItems: "center" }}
												onClick={() => handleClipTypesChange(value, clipId)}
												key={value}
											>
												{label}
												<Radio
													colorScheme={colorScheme.primary}
													style={{
														padding: 3,
														height: 24,
														width: 24
													}}
													checked={
														clipTypes.hasOwnProperty(clipId) && clipTypes[clipId] === value
													}
												/>
											</div>
										);
									})}
								</div>
								<div style={{ padding: "5px", paddingTop: "10px", display: "flex" }}>
									Date Seen:
									<DatePicker
										maxDate={dayjs()}
										onChange={(value: Dayjs | undefined) =>
											updateDate(value?.format("YYYY-MM-DD"), clipId)
										}
										value={clipDates[clipId] ? dayjs(clipDates[clipId]) : undefined}
										dateFormatString={"MM/DD/YYYY"}
									/>
								</div>
								<div style={{ padding: "5px", paddingTop: "10px" }}>
									<VideoTagger
										handleChange={(e: Array<Partial<TagOptionType>>) => handleTagChange(e, clipId)}
									/>
								</div>
								<div style={{ padding: "5px", paddingTop: "10px" }}>
									<TextField
										multiline
										onChange={e => handleClipDescriptionChange(e, clipId)}
										placeholder="Notes"
										style={{
											width: "100%",
											maxWidth: "100%",
											boxSizing: "border-box",
											fontSize: "16px"
										}}
										value={clipDescriptions.hasOwnProperty(clipId) ? clipDescriptions[clipId] : ""}
									/>
								</div>
								{clipErrors.hasOwnProperty(clipId) && clipErrors[clipId].indexOf("upload") > -1 && (
									<div
										style={{
											textAlign: "center",
											color: "red"
										}}
									>
										{"Upload failed :("}
									</div>
								)}
							</FileUploadClip>
						);
					})}
					{uploadProgress != null && <LinearProgressPopup value={uploadProgress} />}
				</FileUploadContainer>
			</div>
		</div>
	);
};

export default VideoUploader;
