import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import Select, { StylesConfig, CSSObjectWithLabel } from "react-select";
import dayjs, { Dayjs } from "dayjs";

import { $TSFixMe } from "utils/tsutils";
import { TLkOption } from "_react/inputs/lks/LKSelectCustom";
import { useDebounce } from "_react/_hooks";
import { TextField } from "_react/shared/legacy/ui/TextField";
import { Checkbox } from "_react/shared/legacy/ui/Checkbox";
import { LkSelect, TLkValueConstraint } from "_react/inputs";
import LkSelectCustom from "_react/inputs/lks/LKSelectCustom";
import { DatePicker } from "_react/shared/legacy/ui/DatePicker";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import Info from "_react/shared/legacy/ui/icons/Info";
import { defaultColorScheme } from "_react/shared/legacy/ui/Colors";

import { TEXT_DEBOUNCE_TIMEOUT } from "_react/shared/forms/_constants";
import {
	FormComponentInternalProps,
	TFormComponent,
	TDocumentBase,
	TFormValue,
	TFormComponentType,
	TCustomFormComponent,
	TEXT,
	DATE,
	DROPDOWN,
	SELECT,
	CHECKBOX,
	LABEL,
	LOADING,
	TFormValuePrimitive,
	ENTIRE_FORM
} from "_react/shared/forms/_types";
import { FormComponentContainer, FormComponentLabelSpan } from "_react/shared/forms/_styles";
import { extractValueUsingKey, getLkParametersFromComponentKey } from "_react/shared/forms/_helpers";
import { useFormContext } from "_react/shared/forms/_context";

export interface FormComponentProps {
	component: TFormComponent;
	evenRow?: boolean;
	disabled?: boolean;
	isRowBased?: boolean;
	width?: number;
}

function FormComponentText<T extends TDocumentBase>({
	component,
	submitUpdate,
	value,
	readOnly,
	disabled,
	focusedRef
}: FormComponentInternalProps<T>) {
	// Extract field value
	const valueUsed = `${value ?? ""}`;

	// Ref for detecting focus
	const [focused, setFocused] = useState(false);
	useEffect(() => {
		focusedRef.current = focused;
	}, [focused, focusedRef]);

	// Read Only Label
	if (readOnly)
		return (
			<FormComponentLabel<T> component={component} submitUpdate={submitUpdate} value={value} readOnly={true} />
		);

	return (
		<TextField
			style={{ flexGrow: 1 }}
			value={valueUsed}
			onChange={e => submitUpdate(e.target.value !== "" ? e.target.value : undefined)}
			multiline={component.multiline}
			placeholder={component.placeholder}
			disabled={disabled}
			onBlur={() => setFocused(false)}
			onFocus={() => setFocused(false)}
		/>
	);
}

function FormComponentCheckbox<T extends TDocumentBase>({
	submitUpdate,
	value,
	readOnly,
	disabled
}: FormComponentInternalProps<T>) {
	// Extract field value
	const valueUsed = value === true;

	return (
		<Checkbox
			checked={valueUsed}
			onChange={(checked: boolean) => submitUpdate(checked)}
			disabled={readOnly || disabled}
		/>
	);
}

export const selectStylesLk: (color: string) => StylesConfig<TLkOption<TLkValueConstraint>, boolean> = color => ({
	container: () => ({
		flexGrow: 1,
		position: "relative"
	}),
	control: () => ({
		fontFamily: "Arial",
		display: "flex",
		transition: "all 100ms",
		fontSize: 15,
		borderBottom: `1px solid ${color}`,
		marginBottom: "2px"
	}),
	dropdownIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0,
		width: 15
	}),
	indicatorsContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0,
		height: 24
	}),
	indicatorSeparator: () => ({}),
	menu: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontSize: 11
	}),
	input: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontFamily: "Arial",
		fontSize: 15,
		color: color
	}),
	loadingIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: color
	}),
	menuPortal: (provided: CSSObjectWithLabel) => ({
		...provided,
		zIndex: 1005
	}),
	option: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: "black",
		cursor: "pointer"
	}),
	placeholder: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontFamily: "Arial",
		color: color,
		fontSize: 15,
		fontWeight: 400,
		opacity: 0.5
	}),
	singleValue: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontFamily: "Arial",
		color: color,
		fontSize: 15
	}),
	valueContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0
	})
});

export const selectStyles: (color: string) => StylesConfig = color => ({
	container: () => ({
		flexGrow: 1,
		position: "relative"
	}),
	control: () => ({
		fontFamily: "Arial",
		display: "flex",
		transition: "all 100ms",
		fontSize: 15,
		borderBottom: `1px solid ${color}`,
		marginBottom: "2px"
	}),
	dropdownIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0,
		width: 15
	}),
	indicatorsContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0,
		height: 24
	}),
	indicatorSeparator: () => ({}),
	menu: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontSize: 11
	}),
	input: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontFamily: "Arial",
		fontSize: 15,
		color: color
	}),
	loadingIndicator: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: color
	}),
	menuPortal: (provided: CSSObjectWithLabel) => ({
		...provided,
		zIndex: 1005
	}),
	option: (provided: CSSObjectWithLabel) => ({
		...provided,
		color: "black",
		cursor: "pointer"
	}),
	placeholder: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontFamily: "Arial",
		color: color,
		fontSize: 15,
		fontWeight: 400,
		opacity: 0.5
	}),
	singleValue: (provided: CSSObjectWithLabel) => ({
		...provided,
		fontFamily: "Arial",
		color: color,
		fontSize: 15
	}),
	valueContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0
	})
});

export function FormComponentSelect<T extends TDocumentBase>({
	component,
	submitUpdate,
	value,
	readOnly,
	lkProps,
	disabled
}: FormComponentInternalProps<T>) {
	// Extract field value
	const valueUsed = value as TLkValueConstraint;

	const onChange = (value: $TSFixMe) => {
		// TSFixMe because react-select types absolutely suck
		if (Array.isArray(value)) {
			console.log("Multi-Select Not Yet Supported");
			return;
		}
		submitUpdate(value?.value);
	};

	const parameters = getLkParametersFromComponentKey(component.key, lkProps);

	if (component.isCustomLk === true || component.isCustomLk === 1) {
		return (
			<LkSelectCustom
				query={component.lkQuery!}
				parameters={parameters}
				valueOnlyValue={valueUsed}
				onChange={onChange}
				styles={selectStylesLk("black")}
				forDisplay={readOnly}
				placeholder={component.placeholder}
				isDisabled={disabled}
				isClearable={true}
			/>
		);
	}
	return (
		<LkSelect
			lkName={component.lkName!}
			dbName={component.dbName}
			valueOnlyValue={valueUsed}
			onChange={onChange}
			styles={selectStylesLk("black")}
			forDisplay={readOnly}
			placeholder={component.placeholder}
			isDisabled={disabled}
			isClearable={true}
		/>
	);
}

export function FormComponentDropdown<T extends TDocumentBase>({
	component,
	submitUpdate,
	value,
	readOnly,
	disabled,
	dropdownComponents
}: FormComponentInternalProps<T>) {
	const onChange = (value: $TSFixMe) => {
		// TSFixMe because react-select types absolutely suck
		if (Array.isArray(value)) {
			console.log("Multi-Select Not Yet Supported");
			return;
		}
		submitUpdate(value?.value);
	};

	const options =
		dropdownComponents && dropdownComponents[component.key] ? dropdownComponents[component.key] : undefined;
	// Extract field value
	const valueUsed = options?.filter(option => option.value === value);

	if (readOnly)
		return (
			<FormComponentLabel<T>
				component={component}
				submitUpdate={submitUpdate}
				value={valueUsed && valueUsed.length > 0 ? valueUsed[0].label : ""}
				readOnly={true}
			/>
		);

	return (
		<Select
			onChange={onChange}
			options={options}
			value={valueUsed}
			placeholder={component.placeholder}
			isClearable={true}
			isDisabled={disabled}
			styles={selectStyles("black")}
		/>
	);
}

export function FormComponentDate<T extends TDocumentBase>({
	component,
	submitUpdate,
	value,
	readOnly,
	disabled
}: FormComponentInternalProps<T>) {
	// Extract field value
	const valueUsed = value ? (typeof value === "string" ? (dayjs(value) as Dayjs) : (value as Dayjs)) : undefined;
	const onChange = useCallback((d: Dayjs | undefined) => submitUpdate(d ? dayjs(d) : undefined), [submitUpdate]);
	if (readOnly)
		return (
			<FormComponentLabel<T>
				component={component}
				submitUpdate={submitUpdate}
				value={valueUsed ? dayjs(valueUsed).format("M/D/YYYY") : valueUsed}
				readOnly={true}
			/>
		);

	return (
		<DatePicker
			onChange={onChange}
			value={valueUsed}
			key={component.key}
			placeholder={component.placeholder}
			disabled={disabled}
		/>
	);
}

export function FormComponentLabel<T extends TDocumentBase>({ value }: FormComponentInternalProps<T>) {
	// Extract field value
	const valueUsed = `${value ?? ""}`;

	return <div style={{ marginLeft: "5px" }}>{valueUsed}</div>;
}

function FormComponentLoading<_T extends TDocumentBase>() {
	return <div className="loading-item" style={{ width: "100%", height: "20px" }} />;
}

function formComponentTypeToComponentInternal<T extends TDocumentBase>(
	type: TFormComponentType,
	customComponents: { [index: string]: TCustomFormComponent<T> },
	readOnly: boolean,
	value?: TFormValue
) {
	if (value === LOADING) return FormComponentLoading;
	if (customComponents[type]) return customComponents[type];
	if (type === TEXT) return FormComponentText;
	if (type === CHECKBOX) return FormComponentCheckbox;
	if (type === SELECT) return FormComponentSelect;
	if (type === DROPDOWN) return FormComponentDropdown;
	if (type === DATE) return FormComponentDate;
	if (type === LABEL) return FormComponentLabel;
	if (type === LOADING) return FormComponentLoading;

	// Default
	// TODO: Change this to a label component
	return FormComponentLabel;
}

function formComponentTypeUsesDebounce<T extends TDocumentBase>(
	type: TFormComponentType,
	value?: TFormValue,
	customComponentsUsesDebounce?: { [index: string]: TCustomFormComponent<T> }
) {
	if (value === LOADING) return false;
	if (customComponentsUsesDebounce != null) return customComponentsUsesDebounce[type] ?? false;
	if (type === TEXT) return true;
	if (type === CHECKBOX) return false;
	if (type === SELECT) return false;
	if (type === DATE) return false;
	if (type === LABEL) return false;
	if (type === LOADING) return false;

	// Default
	return false;
}

export function FormComponent<T extends TDocumentBase>({
	component,
	evenRow,
	disabled,
	isRowBased,
	width
}: FormComponentProps) {
	const {
		document,
		submitUpdate,
		plugin,
		validationErrors,
		readOnly,
		// relaySaveFunction,
		lkProps
	} = useFormContext<T>();

	const value = extractValueUsingKey(component.key, document);

	// ref to indicate if focused or not
	const focusedRef = useRef(false);
	const focused = focusedRef.current === true;

	const validationError = validationErrors.find(
		error => error.component !== ENTIRE_FORM && error.component.key === component.key
	);
	const formLevelValidationErrors = validationErrors.find(error => error.component === ENTIRE_FORM);
	const isInvalid = validationError != null;
	const isFormLevelInvalid = formLevelValidationErrors != null;
	// This is solely for validation. Since validation happens at the Form level (not FormComponent level)
	//    so since we cannot validate here, we need a way to decipher between the existing value that
	//    failed validation, so that a new attempt is actually fired
	const previousAttempt = useRef(value);

	// Use if a hook needs access to a doument, but should not re-run when document changes
	const documentRef = useRef(document);
	useEffect(() => {
		documentRef.current = document;
	}, [document, documentRef]);

	// Debounce
	const usesDebounce = component.debounce === true || formComponentTypeUsesDebounce<T>(component.type, value);
	const [internalValue, setInternalValue] = useState(value);
	const internalValueRef = useRef(internalValue);
	useEffect(() => {
		internalValueRef.current = internalValue;
	}, [internalValue, internalValueRef]);
	useEffect(() => {
		// If the underlying value changes, update the internal value unless the field is in focus and uses debouncing
		if (internalValueRef.current !== value && (!focused || !usesDebounce)) {
			setInternalValue(value);
		}
	}, [value, internalValueRef, focused, usesDebounce]);
	const handleSetDebouncing = useCallback(
		(isDebouncing: boolean) => {
			// At the component level, this should only be called when debouncing has *started*
			// This is because right when it finishes, the overall form will start debouncing, so this
			// prevents that from being toggled off just to be toggled right back on
			if (isDebouncing && plugin.debouncingStateChanged) plugin.debouncingStateChanged(true);
		},
		[plugin]
	);
	const debouncedValue = useDebounce(internalValue, TEXT_DEBOUNCE_TIMEOUT, handleSetDebouncing);

	useEffect(() => {
		const newValue = debouncedValue !== "" ? debouncedValue : undefined;
		// Like, woof to the next conditional, but hear me out (and pls improve if possible)
		if (
			// If this uses debounce, the debounced value is different from the value, debounce an update
			usesDebounce &&
			(newValue !== value || ((isInvalid || isFormLevelInvalid) && newValue !== previousAttempt.current))
		) {
			submitUpdate(component.key, newValue, documentRef.current, component);
		}
		previousAttempt.current = newValue;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedValue]); // TODO: Need to eslint ignore here for now, should replace that with references though

	const FormComponentInternal = formComponentTypeToComponentInternal<T>(
		component.type,
		plugin.customComponents,
		readOnly,
		value
	);

	const onUpdate = useCallback(
		(value: TFormValue) => {
			if (component.debounce === true || formComponentTypeUsesDebounce<T>(component.type)) {
				setInternalValue(value);
			} else {
				submitUpdate(component.key, value, document, component);
			}
		},
		[component, document, submitUpdate]
	);

	// const saveFunction = useCallback(() => submitUpdate(component.key, internalValue, document, component, true), [
	// 	component,
	// 	internalValue,
	// 	document,
	// 	submitUpdate
	// ]);
	// useEffect(() => relaySaveFunction(component.key, saveFunction), [component.key, relaySaveFunction, saveFunction]);

	return useMemo(
		() => (
			<FormComponentContainer
				evenRow={evenRow}
				padding={component.type === SELECT ? "1px 5px 3px" : "5px"}
				isInvalid={isInvalid}
				isRowBased={isRowBased}
				flex={width}
			>
				<FormComponentLabelSpan>{component.label}</FormComponentLabelSpan>
				<FormComponentInternal
					submitUpdate={onUpdate}
					component={component}
					value={internalValue as TFormValuePrimitive}
					invalid={isInvalid}
					readOnly={readOnly}
					lkProps={lkProps}
					disabled={disabled}
					dropdownComponents={plugin.dropdownComponents}
					focusedRef={focusedRef}
				/>
				{isInvalid && (
					<Tooltip
						placement="left"
						title={validationError?.message ?? ""}
						colorScheme={defaultColorScheme.secondary}
						componentWrapperStyle={{ height: "21px" }}
					>
						<Info fill="red" style={{ fill: "red", height: "21px", width: "21px" }} />
					</Tooltip>
				)}
			</FormComponentContainer>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			evenRow,
			component,
			isInvalid,
			internalValue,
			readOnly,
			lkProps,
			onUpdate,
			validationError,
			disabled,
			plugin,
			isRowBased,
			width
		]
	);
}
