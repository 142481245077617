import React from "react";
import dayjs from "dayjs";

import ThumbsUp from "_react/shared/legacy/ui/icons/ThumbsUp";
import ThumbsDown from "_react/shared/legacy/ui/icons/ThumbsDown";
import Lock from "_react/shared/legacy/ui/icons/Lock";

import { METRIC_TYPE_TO_COLOR } from "_react/playerplan/shared/_constants";
import {
	getDrillLabelFromNote,
	getFocusAreaLabelFromNote,
	getGoalStatementFromNote
} from "_react/playerplan/shared/_helpers";
import {
	PlayerPlanNoteContainer,
	PlayerPlanNoteHeaderDiv,
	NoteGoalDiv,
	NoteNameDiv,
	NoteDateDiv,
	NoteMetricTypeSpan,
	NoteVideoDiv,
	ThumbsIconSmallStyle,
	LockIconStyle,
	NoteContentSpan,
	NoteContentDiv
} from "_react/playerplan/shared/_styles";
import { TPlayerPlanNote } from "_react/playerplan/shared/_types";
import { FOCUS_AREA_TIER } from "_react/playerplan/metrics/_constants";

export type TPlayerPlanNoteProps = {
	note: TPlayerPlanNote;
	isShowGoalStatement?: boolean;
	isShowBorder: boolean;
};

export const PlayerPlanNote = ({ note, isShowGoalStatement = false, isShowBorder }: TPlayerPlanNoteProps) => {
	// Determines whether goal associated with note is a Focus Area
	const isFocusAreaNote =
		note.playerPlanGoal?.parentGoal != null &&
		note.playerPlanGoal?.playerPlanMetric?.metricTierLk === FOCUS_AREA_TIER;
	const goal = isFocusAreaNote ? note.playerPlanGoal?.parentGoal : note.playerPlanGoal;
	const focusArea = isFocusAreaNote ? note.playerPlanGoal : undefined;
	const drill = note.playerPlanDrill;

	return (
		<PlayerPlanNoteContainer isShowBorder={isShowBorder}>
			<PlayerPlanNoteHeaderDiv>
				{note.isPrivate && <Lock style={LockIconStyle} />}
				<NoteNameDiv>
					{note.lastChangeUser?.firstName} {note.lastChangeUser?.lastName}
				</NoteNameDiv>
				<NoteDateDiv>
					{note.createDate
						? dayjs
								.utc(note.createDate.substring(0, 23))
								.local()
								.format("MMMM D, YYYY")
						: "No Date"}
				</NoteDateDiv>
				{note.isPositive !== null &&
					(note.isPositive ? (
						<ThumbsUp fill={"#036f03"} style={ThumbsIconSmallStyle} />
					) : (
						<ThumbsDown fill={"#b50000"} style={ThumbsIconSmallStyle} />
					))}
			</PlayerPlanNoteHeaderDiv>
			{isShowGoalStatement && goal != null && (
				<NoteGoalDiv>
					Goal: <i>{getGoalStatementFromNote(note)}</i>
				</NoteGoalDiv>
			)}
			{focusArea && (
				<NoteGoalDiv>
					Focus Area: <i>{getFocusAreaLabelFromNote(note)}</i>
				</NoteGoalDiv>
			)}
			{drill && (
				<NoteGoalDiv>
					Supporting Action: <i>{getDrillLabelFromNote(note)}</i>
				</NoteGoalDiv>
			)}
			<NoteContentDiv>
				{(note.metricType || note.type?.label) && (
					<NoteMetricTypeSpan
						color={note.metricType ? METRIC_TYPE_TO_COLOR[note.metricType.value] : undefined}
					>{`${note.metricType ? `${note.metricType.abbreviation ?? note.metricType.label}` : ""}
				${note.metricType && note.type?.label ? " - " : ""}
				${note.type?.label ?? ""}`}</NoteMetricTypeSpan>
				)}
				<NoteContentSpan>{note.content}</NoteContentSpan>
			</NoteContentDiv>
			{note.videoGcsUri && (
				<NoteVideoDiv>
					<a href={`/gcsredirect?uri=${note.videoGcsUri}`} target="_blank" rel="noopener noreferrer">
						File
					</a>
				</NoteVideoDiv>
			)}
		</PlayerPlanNoteContainer>
	);
};
