import React, { FunctionComponent, useState } from "react";
import { $TSFixMe } from "utils/tsutils";
type HoverButtonProps = {
	handleClick?: Function;
	render: Function;
	style: $TSFixMe;
};

const HoverButton: FunctionComponent<HoverButtonProps> = ({ handleClick = () => null, render, style = {} }) => {
	const [hover, setHover] = useState<boolean>(false);

	function handleMouseOver() {
		setHover(true);
	}

	function handleMouseOut() {
		setHover(false);
	}

	return (
		<div onClick={e => handleClick(e)} onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} style={style}>
			{render(hover)}
		</div>
	);
};

export default HoverButton;
