import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { fetchListRank } from "_react/shared/data_models/list_new/_network";
import { getCancelSource } from "utils/url_helpers";
import { IListRank } from "_react/shared/data_models/list_new/_types";

// import { PLAYING_LEVEL_PRO } from "_react/shared/ui/chakra/dataviz/MyListsMenu/_constants";
import { TMyListsMenuData } from "_react/shared/ui/data/other/MyListsMenu/MyListsMenu";

const LIST_RANKS_CANCEL_SOURCE = "listRanks";

export type TMyListsMenuCancelSource = {
	[LIST_RANKS_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TMyListsMenuContext = {
	playerId?: number;
	shouldFetchData?: boolean;
	listRanks?: Array<IListRank> | null;
	cancelSources: TMyListsMenuCancelSource;
	toast?: CreateToastFnReturn;
};

type TMyListsMenuStateSchema = {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all list rank data
				listRanks: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
};

export const SET_LIST_RANKS = "SET_LIST_RANKS";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const FETCHING_LIST_RANKS = { initialized: { listRanks: "fetching" } };

const FETCH_LIST_RANKS_DONE = "done.invoke.fetchingListRanks:invocation[0]";

type TSetListRanksEvent = {
	type: typeof SET_LIST_RANKS;
	data: Array<IListRank> | null | undefined;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TFetchListRanksEvent = {
	type: typeof FETCH_LIST_RANKS_DONE;
	data: Array<IListRank> | undefined;
};

type TMyListsMenuEvent = TSetListRanksEvent | TSetPlayerIdEvent | TFetchListRanksEvent;

export type TMyListsMenuSend = Interpreter<TMyListsMenuContext, TMyListsMenuStateSchema, TMyListsMenuEvent>["send"];

const MyListsMenuMachine = (
	playerIdProp?: number,
	shouldFetchData = true,
	data?: TMyListsMenuData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TMyListsMenuContext, TMyListsMenuStateSchema, TMyListsMenuEvent>(
		{
			id: "MyListsMenu",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				shouldFetchData: shouldFetchData,
				listRanks: data?.listRanks,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_LIST_RANKS]: { actions: "setListRanks" },
						[SET_PLAYER_ID]: {
							actions: ["setPlayerId", "clearListRanks"],
							cond: "shouldSetPlayerId"
						}
					},
					states: {
						listRanks: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingListRanks",
												cond: "shouldFetchListRanks"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingListRanks",
									entry: ["refreshListRanksCancelSource"],
									invoke: {
										src: "fetchListRanks",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchListRanksSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchListRanksErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (context: TMyListsMenuContext, event: TMyListsMenuEvent) =>
					context.playerId !== event.data,
				shouldFetchListRanks: (context: TMyListsMenuContext, _event: TMyListsMenuEvent) =>
					context.listRanks === undefined && shouldFetchData && context.playerId !== undefined
			},
			actions: {
				setListRanks: assign<TMyListsMenuContext, TMyListsMenuEvent>({
					listRanks: (context: TMyListsMenuContext, event: TMyListsMenuEvent) => {
						if (event.type !== SET_LIST_RANKS) return context.listRanks;
						return event.data;
					},
					cancelSources: (context: TMyListsMenuContext, event: TMyListsMenuEvent) => {
						if (event.type !== SET_LIST_RANKS) return context.cancelSources;
						if (context.cancelSources[LIST_RANKS_CANCEL_SOURCE] != null)
							context.cancelSources[LIST_RANKS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[LIST_RANKS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TMyListsMenuContext, TMyListsMenuEvent>({
					playerId: (context: TMyListsMenuContext, event: TMyListsMenuEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				clearListRanks: assign<TMyListsMenuContext, TMyListsMenuEvent>({
					listRanks: (_context: TMyListsMenuContext, _event: TMyListsMenuEvent) => undefined,
					cancelSources: (context: TMyListsMenuContext, _event: TMyListsMenuEvent) => {
						if (context.cancelSources[LIST_RANKS_CANCEL_SOURCE] != null) {
							context.cancelSources[LIST_RANKS_CANCEL_SOURCE].cancel();
							delete context.cancelSources[LIST_RANKS_CANCEL_SOURCE];
						}
						return context.cancelSources;
					}
				}),
				// Cancel Source Actions
				refreshListRanksCancelSource: assign<TMyListsMenuContext, TMyListsMenuEvent>({
					cancelSources: (context: TMyListsMenuContext, _event: TMyListsMenuEvent) => {
						if (context.cancelSources[LIST_RANKS_CANCEL_SOURCE] != null)
							context.cancelSources[LIST_RANKS_CANCEL_SOURCE].cancel();
						context.cancelSources[LIST_RANKS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchListRanksSuccess: assign<TMyListsMenuContext, TMyListsMenuEvent>({
					listRanks: (context: TMyListsMenuContext, event: TMyListsMenuEvent) => {
						if (event.type !== FETCH_LIST_RANKS_DONE) return context.listRanks;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchListRanksErrored: (context: TMyListsMenuContext, _event: TMyListsMenuEvent) => {
					if (context.toast)
						context.toast({
							title: "My Lists",
							description: "Error fetching list ranking data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchListRanks: (context: TMyListsMenuContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchListRank(
							{
								playerId: context.playerId
							},
							context.cancelSources[LIST_RANKS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default MyListsMenuMachine;
