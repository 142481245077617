import React, { CSSProperties } from "react";

import { NULL_TEXT, FB, CH, CB, SL, OTH } from "_react/evals/list/_constants";
import { TEvalAmaPitch } from "_react/evals/list/_types";
import {
	GradeSection,
	EvalSection,
	EvalAttr,
	EvalLabel,
	HideStyle,
	StartStyle,
	EndStyle
} from "_react/evals/list/_styles";
import { getVelocity, checkAmaPitchExists, getArmAngle, getArmSpeed } from "_react/evals/list/_helpers";

export type TEvalAmaPitchGradesProps = {
	evaluation: TEvalAmaPitch;
	isShowRound?: boolean;
	style?: CSSProperties;
};

export const EvalAmaPitchGrades = ({ evaluation, isShowRound = true, style }: TEvalAmaPitchGradesProps) => {
	return (
		<>
			<GradeSection width={"7%"} inlineFlex style={isShowRound ? undefined : HideStyle}>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Round</EvalLabel> {evaluation.round_recommendation ?? NULL_TEXT}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
			<GradeSection width={isShowRound ? "93%" : "100%"} inlineFlex>
				<EvalSection style={{ ...(checkAmaPitchExists(evaluation, FB) ? EndStyle : HideStyle), ...style }}>
					<EvalAttr>
						<EvalLabel>FB</EvalLabel> {evaluation.fb_overall_present ?? NULL_TEXT}/
						{evaluation.fb_overall_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>FBv</EvalLabel> {evaluation.fb_vel_present ?? NULL_TEXT}/
						{evaluation.fb_vel_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>FBmo</EvalLabel> {evaluation.fb_mov_present ?? NULL_TEXT}/
						{evaluation.fb_mov_future ?? NULL_TEXT}
					</EvalAttr>
				</EvalSection>
				<EvalSection style={{ ...(checkAmaPitchExists(evaluation, FB) ? StartStyle : HideStyle), ...style }}>
					<EvalAttr>
						<EvalLabel>FBco</EvalLabel> {evaluation.fb_cmd_present ?? NULL_TEXT}/
						{evaluation.fb_cmd_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Velo</EvalLabel> {getVelocity(evaluation.fb_vel_low, evaluation.fb_vel_high)}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Velo Comf</EvalLabel>{" "}
						{getVelocity(evaluation.fb_vel_comfort_zone_low, evaluation.fb_vel_comfort_zone_high)}
					</EvalAttr>
				</EvalSection>
				<EvalSection style={{ ...(checkAmaPitchExists(evaluation, CB) ? undefined : HideStyle), ...style }}>
					<EvalAttr>
						<EvalLabel>CB</EvalLabel> {evaluation.cb_present ?? NULL_TEXT}/
						{evaluation.cb_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>CBco</EvalLabel> {evaluation.cb_cmd_present ?? NULL_TEXT}/
						{evaluation.cb_cmd_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>CBv</EvalLabel> {getVelocity(evaluation.cb_vel_low, evaluation.cb_vel_high)}
					</EvalAttr>
				</EvalSection>
				<EvalSection style={{ ...(checkAmaPitchExists(evaluation, SL) ? undefined : HideStyle), ...style }}>
					<EvalAttr>
						<EvalLabel>SL</EvalLabel> {evaluation.sl_present ?? NULL_TEXT}/
						{evaluation.sl_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>SLco</EvalLabel> {evaluation.sl_cmd_present ?? NULL_TEXT}/
						{evaluation.sl_cmd_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>SLv</EvalLabel> {getVelocity(evaluation.sl_vel_low, evaluation.sl_vel_high)}
					</EvalAttr>
				</EvalSection>
				<EvalSection style={{ ...(checkAmaPitchExists(evaluation, CH) ? undefined : HideStyle), ...style }}>
					<EvalAttr>
						<EvalLabel>CH</EvalLabel> {evaluation.ch_present ?? NULL_TEXT}/
						{evaluation.ch_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>CHco</EvalLabel> {evaluation.ch_cmd_present ?? NULL_TEXT}/
						{evaluation.ch_cmd_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>CHv</EvalLabel> {getVelocity(evaluation.ch_vel_low, evaluation.ch_vel_high)}
					</EvalAttr>
				</EvalSection>
				<EvalSection style={{ ...(checkAmaPitchExists(evaluation, OTH) ? undefined : HideStyle), ...style }}>
					<EvalAttr>
						<EvalLabel>{evaluation.oth_pitch_type}</EvalLabel> {evaluation.oth_pitch_present ?? NULL_TEXT}/
						{evaluation.oth_pitch_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>{evaluation.oth_pitch_type}co</EvalLabel> {evaluation.oth_cmd_present ?? NULL_TEXT}/
						{evaluation.oth_cmd_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>{evaluation.oth_pitch_type}v</EvalLabel>{" "}
						{getVelocity(evaluation.oth_pitch_vel_low, evaluation.oth_pitch_vel_high)}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
			<GradeSection>
				<EvalSection style={style}>
					<EvalAttr>
						<EvalLabel>Arm Angle</EvalLabel> {getArmAngle(evaluation)}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Delivery</EvalLabel> {evaluation._del_present ?? NULL_TEXT}/
						{evaluation._del_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Arm Action</EvalLabel> {evaluation.arm_act_present ?? NULL_TEXT}/
						{evaluation.arm_act_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Arm Speed</EvalLabel> {getArmSpeed(evaluation)}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Deception</EvalLabel> {evaluation.deception_present ?? NULL_TEXT}/
						{evaluation.deception_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Feel</EvalLabel> {evaluation.feel_present ?? NULL_TEXT}/
						{evaluation.feel_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Control</EvalLabel> {evaluation.control_present ?? NULL_TEXT}/
						{evaluation.control_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Strength</EvalLabel> {evaluation.strength_present ?? NULL_TEXT}/
						{evaluation.strength_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Athleticism</EvalLabel> {evaluation.ath_present ?? NULL_TEXT}/
						{evaluation.ath_future ?? NULL_TEXT}
					</EvalAttr>
					<EvalAttr>
						<EvalLabel>Overall Projection</EvalLabel> {evaluation.ath_body_overall_proj ?? NULL_TEXT}
					</EvalAttr>
				</EvalSection>
			</GradeSection>
		</>
	);
};
