import { statAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const sizes = {
	xl: definePartsStyle({
		label: { fontSize: "lg" },
		helpText: { fontSize: "lg" },
		number: { fontSize: "4xl" }
	}),
	lg: definePartsStyle({
		label: { fontSize: "md" },
		helpText: { fontSize: "md" },
		number: { fontSize: "3xl" }
	}),
	md: definePartsStyle({
		label: { fontSize: "sm" },
		helpText: { fontSize: "sm" },
		number: { fontSize: "2xl" }
	}),
	sm: definePartsStyle({
		label: { fontSize: "sm" },
		helpText: { fontSize: "sm" },
		number: { fontSize: "md" }
	}),
	xs: definePartsStyle({
		label: { fontSize: "xs" },
		helpText: { fontSize: "xs" },
		number: { fontSize: "sm" }
	})
};

export const statTheme = defineMultiStyleConfig({ sizes });
