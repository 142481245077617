import React, { useState, useEffect, useMemo, useContext } from "react";
import dayjs from "dayjs";

import { $TSFixMeTeam, $TSFixMeGame, $TSFixMe, Style } from "utils/tsutils";
import { getTeamName } from "_react/shared/_helpers/team";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { defaultColorScheme, ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { PlayerPageContext } from "_react/playerpage/_context";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { LoadingStyle } from "_react/teampage/schedule/_styles";

type ScheduleTableProps = {
	colorSchemeGroup?: ColorSchemeGroup;
	player?: TPlayerPageCombinedPlayer;
	team?: $TSFixMeTeam;
	style?: Style;
};

const SCHEDULE_COLUMNS = (team: $TSFixMeTeam) => [
	{
		title: "Home/Away",
		getCellValue: (row: $TSFixMeGame) => {
			if (row.homeTeam && row.homeTeam.id === team.id) return "Home";
			else if (row.awayTeam && row.awayTeam.id === team.id) return "Away";
			return "TBD";
		}
	},
	{
		title: "Opponent",
		getCellValue: (row: $TSFixMeGame) => {
			if (row.homeTeam && row.awayTeam && row.homeTeam.id === team.id) return row.awayTeam.name;
			else if (row.homeTeam && row.awayTeam && row.awayTeam.id === team.id) return row.homeTeam.name;
			return "TBD";
		}
	},
	{
		title: "Location",
		getCellValue: (row: $TSFixMeGame) =>
			row.venue ? `${row.venue.name} (${row.venue.city}, ${row.venue.state})` : "TBD"
	},
	{
		title: "Date",
		key: "date",
		getCellValueFormat: (row: $TSFixMeGame) =>
			row.datetime
				? dayjs(row.datetime).format("ddd MMM D, YYYY h:mm A")
				: dayjs(row.date).format("ddd MMM D, YYYY"),
		getCellValue: (row: $TSFixMeGame) => row.datetime ?? row.date
	}
];

export function ScheduleTable({ colorSchemeGroup = defaultColorScheme, player, team, style = {} }: ScheduleTableProps) {
	const teamUsed = useMemo(() => (team ? team : player?.team), [team, player]);
	const teamId = teamUsed?.id;

	// Schedule Management
	const [schedule, setSchedule] = useState<Array<$TSFixMeGame>>();
	const { getSchedule } = useContext(PlayerPageContext);
	useEffect(() => {
		if (teamId) getSchedule(teamId).then((schedule: Array<$TSFixMe>) => setSchedule(schedule));
	}, [teamId, getSchedule]);

	const title =
		player != null && player.team != null
			? `Upcoming Schedule - ${getTeamName(player?.team)}`
			: "Upcoming Schedule";

	return (
		<Card>
			<ColoredCardHeader colorScheme={colorSchemeGroup.secondary} text={title} />
			{schedule == null && (
				<div className="loading-container">
					<div className="loading-item" style={LoadingStyle} />
					<div className="loading-item" style={LoadingStyle} />
					<div className="loading-item" style={LoadingStyle} />
					<div className="loading-item" style={LoadingStyle} />
					<div className="loading-item" style={LoadingStyle} />
					<div className="loading-item" style={LoadingStyle} />
					<div className="loading-item" style={LoadingStyle} />
					<div className="loading-item" style={LoadingStyle} />
					<div className="loading-item" style={LoadingStyle} />
				</div>
			)}
			{schedule != null && (
				<div style={{ maxHeight: "300px", overflow: "scroll" }}>
					<AutoFitTable
						columns={SCHEDULE_COLUMNS(teamUsed)}
						defaultSortColumn={"Date"}
						defaultSortState={"asc"}
						rows={schedule}
						style={{ maxHeight: style.maxHeight }}
					/>
				</div>
			)}
		</Card>
	);
}
