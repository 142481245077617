import React from "react";
import PropTypes from "prop-types";

import { defaultColorScheme } from "_react/forms/Form";

const playerFields = [
	{
		title: "On Field Makeup",
		overall: "How does this player carry himself on the baseball field both pregame and in game?"
	},
	{
		title: "Winning Player",
		overall:
			"Is this player the kind of player you would want playing for our team? Does he play the game the right way?"
	},
	{
		title: "MiFA",
		overall:
			"Would you consider signing this player as a Minor League FA? This field will populate an overall list of candidates from your coverage. The box will only appear if the player will be eligible to be a Minor League Free Agent that year."
	},
	{
		title: "Rule 5",
		overall:
			"Would you consider selecting this player in the Rule 5 Draft? This field will populate an overall list of candidates from your coverage. The box will only appear if the player will be eligible for the Rule 5 Draft that year."
	},
	{
		title: "Conversion Candidate",
		overall:
			"Would you recommend trying to play this player at position other than the one he is playing? This box is not to be used for recommending moving a player from SS to 2B, but for more extreme changes; infield to outfield, infield to catcher, position player to pitcher."
	}
];

const pitcherFields = [
	{
		title: "On Field Makeup",
		overall: "How does this player carry himself on the baseball field both pregame and in game?"
	},
	{
		title: "Winning Player",
		overall:
			"Is this player the kind of player you would want playing for our team? Does he play the game the right way?"
	},
	{
		title: "Outpitch",
		overall: "What pitch does he go to for outs? If he doesn’t have one, none is a possible answer."
	},
	{
		title: "Delivery",
		overall: "How good or bad are the pitchers mechanics? How well can he repeat his delivery?"
	},
	{
		title: "Arm Slot",
		overall: "What angle does the pitcher throw from?"
	},
	{
		title: "Deception",
		overall: "How well does the pitcher hide the ball or deceive the hitter?"
	},
	{
		title: "Athleticism",
		overall: "What kind of natural athlete is this player?"
	},
	{
		title: "Control Run Game",
		overall:
			"What type of job does he do shutting down potential base stealing threats? Does he throw over? Use a slide step? Hold the ball? Disrupt timing?"
	},
	{
		title: "Quick to Plate",
		overall: "What type of tempo does he have when pitching with baserunners?"
	}
];

const ProMiscTooltip = ({ type }) => {
	const fields = type === "player" ? playerFields : pitcherFields;

	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<h1
					style={{
						textAlign: "center",
						color: defaultColorScheme.group.backgroundColor,
						fontSize: "1.2em",
						fontWeight: "bold",
						marginTop: "13px",
						marginBottom: "13px"
					}}
				>
					{type === "player" ? "POSITION PLAYER" : "PITCHER"} MISCELLANEOUS FIELDS
				</h1>
				<div>
					{fields.map((field, idx) => {
						return (
							<div key={idx} style={{ marginBottom: "10px" }}>
								<span
									style={{
										fontSize: "1em",
										color: "#f44335",
										fontWeight: "700"
									}}
								>
									{field.title}
								</span>
								<br />
								<div
									style={{
										borderBottom: idx !== fields.length - 1 ? "1px solid gray" : "",
										paddingBottom: "10px",
										fontSize: "0.85em"
									}}
								>
									<span>{field.overall}</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

ProMiscTooltip.propTypes = {
	type: PropTypes.string
};

export default ProMiscTooltip;
