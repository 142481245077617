import { Machine, assign } from "xstate";

import axios from "_redux/_utils/_axios";
import { TVideoWDPL, TVideo, TVideoWID } from "_react/video/shared/_types";
import { TEnqueueSnackbar } from "_react/shared/_helpers/snackbar";
import { omit } from "utils/helpers";
import { TTypeOpt, getVideoTypes } from "_react/playerpage/video/_helpers";

const MAX_RETRIES = 2;

type TVideoId = string | number;

type TVideoDelete = { videoId: TVideoId; video: TVideo };

type TVideoMap = { [key: number]: TVideoWID; [key: string]: TVideoWID };

interface IContext {
	enqueueSnackbar: TEnqueueSnackbar;
	videoTypeOpt: TTypeOpt | null;
	videos: TVideoWID[] | null;
	videosFiltered: TVideoWID[] | null;
	videoIdsSelected: Set<TVideoId>;
	videosSelected: TVideoWID[];
	videoMap: TVideoMap;
	playerId: number | null | undefined;
	retries: number;
	videoDelete: TVideoDelete | null;
}

interface IStateSchema {
	states: {
		data: {
			states: {
				fetching: {
					states: {
						prefetchCheck: {};
						fetching: {};
					};
				};
				deleting: {};
				idle: {};
			};
		};
		ui: {
			states: {
				uploading: {};
				viewingVideo: {};
				idle: {
					states: {
						filtering: {};
						updateSelected: {};
						idle: {};
					};
				};
				fetchErrored: {};
				invalidFetch: {};
			};
		};
	};
}

export const UPDATE_SNACKBAR = "UPDATE_SNACKBAR";
export const UPDATE_PLAYER_ID = "UPDATE_PLAYER_ID";
export const UPDATE_TYPE_OPT = "UPDATE_TYPE_OPT";
export const CHANGE_SELECT_VIDEO = "CHANGE_SELECT_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const EXIT_VIDEO = "EXIT_VIDEO";
export const PLAY_VIDEO = "PLAY_VIDEO";
export const TOGGLE_OPEN_UPLOADER = "TOGGLE_OPEN_UPLOADER";
export const TOGGLE_SELECT_ALL = "TOGGLE_SELECT_ALL";
export const INSERT_NEW_VIDEOS = "INSERT_NEW_VIDEOS";
const FETCH_VIDEOS_DONE = "done.invoke.fetchVideoService";

type TUpdateSnackbarEvent = { type: typeof UPDATE_SNACKBAR; data: { enqueueSnackbar: TEnqueueSnackbar } };
type TUpdatePhilIdEvent = { type: typeof UPDATE_PLAYER_ID; data: { playerId: number } };
type TUpdateTypeOptEvent = { type: typeof UPDATE_TYPE_OPT; data: { typeOpt: TTypeOpt | null } };

type TChangeSelectedEvent = { type: typeof CHANGE_SELECT_VIDEO; data: { videoId: TVideoId } };
type TDeleteVideoEvent = { type: typeof DELETE_VIDEO; data: { videoId: TVideoId } };
type TExitVideoEvent = { type: typeof EXIT_VIDEO };
type TPlayVideoEvent = { type: typeof PLAY_VIDEO };
type TToggleOpenUploader = { type: typeof TOGGLE_OPEN_UPLOADER };
type TSelectAllEvent = { type: typeof TOGGLE_SELECT_ALL };
type TInsertNewVideosEvent = { type: typeof INSERT_NEW_VIDEOS; data: TVideo[] };
type TFetchVideoSuccessEvent = {
	type: typeof FETCH_VIDEOS_DONE;
	data: { video: TVideoWDPL[] | null; isDplPass: boolean; isOtherPass: boolean };
};
type TEvent =
	| TUpdateSnackbarEvent
	| TUpdatePhilIdEvent
	| TUpdateTypeOptEvent
	| TChangeSelectedEvent
	| TExitVideoEvent
	| TPlayVideoEvent
	| TToggleOpenUploader
	| TSelectAllEvent
	| TInsertNewVideosEvent
	| TFetchVideoSuccessEvent
	| TDeleteVideoEvent;

const createVideoMachine = (enqueueSnackbar: TEnqueueSnackbar, playerId: number | null | undefined) =>
	Machine<IContext, IStateSchema, TEvent>(
		{
			id: "playerpage-video-machine",
			context: {
				enqueueSnackbar,
				playerId,
				videos: null,
				videosFiltered: null,
				retries: 0,
				videoIdsSelected: new Set<TVideoId>(),
				videosSelected: [],
				videoMap: {},
				videoDelete: null,
				videoTypeOpt: null
			},
			type: "parallel",
			on: {
				[UPDATE_PLAYER_ID]: {
					target: ".data.fetching",
					internal: false,
					actions: "updatePlayerId",
					cond: "playerIdNew"
				},
				[UPDATE_SNACKBAR]: { actions: "updateSnackbar" },
				[UPDATE_TYPE_OPT]: { actions: "updateTypeOpt" }
			},
			states: {
				data: {
					initial: "fetching",
					states: {
						fetching: {
							initial: "prefetchCheck",
							states: {
								prefetchCheck: {
									always: [
										{ target: "fetching", cond: "validFetchParams" },
										{
											target: [
												"#playerpage-video-machine.ui.invalidFetch",
												"#playerpage-video-machine.data.idle"
											]
										}
									]
								},
								fetching: {
									invoke: {
										id: "fetchVideoService",
										src: "fetchVideo",
										onDone: [
											{
												target: "prefetchCheck",
												actions: "incrementRetry",
												cond: "shouldFetchRetry"
											},
											{
												target: [
													"#playerpage-video-machine.ui.fetchErrored",
													"#playerpage-video-machine.data.idle"
												],
												actions: "enqueueFetchError",
												cond: "fetchFullFailure"
											},
											{
												target: [
													"#playerpage-video-machine.data.idle",
													"#playerpage-video-machine.ui.idle"
												],
												actions: ["handleFetchSuccess", "enqueuePartialFailure"],
												cond: "fetchPartialFailure"
											},
											{
												target: [
													"#playerpage-video-machine.data.idle",
													"#playerpage-video-machine.ui.idle"
												],
												actions: "handleFetchSuccess"
											}
										],
										onError: [
											{
												target: "prefetchCheck",
												actions: "incrementRetry",
												cond: "notMaxRetries"
											},
											{
												target: [
													"#playerpage-video-machine.ui.fetchErrored",
													"#playerpage-video-machine.data.idle"
												],
												actions: "enqueueFetchError"
											}
										]
									}
								}
							}
						},
						deleting: {
							invoke: {
								id: "deleteVideoService",
								src: "deleteVideo",
								onDone: { target: "idle", actions: ["handleDeleteSuccess", "enqueueDeleteSuccess"] },
								onError: { target: "idle", actions: ["revertDelete", "enqueueDeleteError"] }
							}
						},
						idle: {
							on: {
								[DELETE_VIDEO]: {
									target: "deleting",
									actions: ["setVideoDelete", "spliceOut"],
									cond: "validIdx"
								},
								[INSERT_NEW_VIDEOS]: {
									actions: "insertNewVideos"
								}
							}
						}
					}
				},
				ui: {
					initial: "idle",
					on: { [TOGGLE_OPEN_UPLOADER]: { target: ".uploading" } },
					states: {
						uploading: {
							on: {
								[TOGGLE_OPEN_UPLOADER]: { target: "idle" }
							}
						},
						viewingVideo: {
							on: {
								[EXIT_VIDEO]: { target: "idle" }
							}
						},
						idle: {
							on: {
								[CHANGE_SELECT_VIDEO]: {
									actions: "updateSelectedId",
									target: ".updateSelected",
									internal: false
								},
								[TOGGLE_SELECT_ALL]: {
									actions: "toggleSelectAll",
									target: ".updateSelected",
									internal: false
								},
								[UPDATE_TYPE_OPT]: { actions: "updateTypeOpt", target: ".filtering", internal: false },
								[PLAY_VIDEO]: [
									{ target: "viewingVideo", cond: "someSelected" },
									{ actions: "enqueueNoneSelected" }
								]
							},
							initial: "filtering",
							states: {
								filtering: {
									always: { target: "updateSelected", actions: "filterVideos" }
								},
								updateSelected: {
									always: { target: "idle", actions: "updateSelectedVideos" }
								},
								idle: {}
							}
						},
						fetchErrored: {},
						invalidFetch: {}
					}
				}
			}
		},
		{
			actions: {
				incrementRetry: assign({
					retries: (context, _event) => context.retries + 1
				}),
				updatePlayerId: assign({
					playerId: (context, event) => {
						if (event.type !== UPDATE_PLAYER_ID) return context.playerId;
						return event.data.playerId;
					}
				}),
				updateTypeOpt: assign({
					videoTypeOpt: (context, event) => {
						if (event.type !== UPDATE_TYPE_OPT) return context.videoTypeOpt;
						return event.data.typeOpt;
					}
				}),
				updateSelectedId: assign({
					videoIdsSelected: (context, event) => {
						const { videoIdsSelected } = context;
						if (event.type !== CHANGE_SELECT_VIDEO) return videoIdsSelected;
						const videoId = event.data.videoId;
						if (videoIdsSelected.has(videoId)) {
							return new Set(
								[...videoIdsSelected].filter(videoIdSelected => videoIdSelected !== videoId)
							);
						}
						return new Set([...videoIdsSelected, videoId]);
					}
				}),
				toggleSelectAll: assign({
					videoIdsSelected: (context, event) => {
						const { videos, videoIdsSelected } = context;
						if (event.type !== TOGGLE_SELECT_ALL || videos == null) return videoIdsSelected;
						const allSelected = videos.every(v => videoIdsSelected.has(v.videoId));
						if (allSelected) return new Set();
						return new Set([...videos.map(v => v.videoId)]);
					}
				}),
				setVideoDelete: assign({
					videoDelete: (context, event) => {
						const { videoMap, videoDelete } = context;
						if (event.type !== DELETE_VIDEO) return videoDelete;
						const videoId = event.data.videoId;
						const video = videoMap[videoId] ?? null;
						if (video != null && video.type !== "media") {
							return { videoId, video };
						}
						return null;
					}
				}),
				spliceOut: assign({
					videos: (context, event) => {
						const { videos } = context;
						if (event.type !== DELETE_VIDEO || videos == null) return videos;
						return videos.filter(video => video.videoId !== event.data.videoId);
					},
					videoMap: (context, event) => {
						const { videoMap } = context;
						if (event.type !== DELETE_VIDEO) return context.videoMap;
						return omit(videoMap, [event.data.videoId]) as TVideoMap;
					}
				}),
				revertDelete: assign({
					videoDelete: (_context, _event) => null,
					videos: (context, _event) => {
						const { videos, videoDelete } = context;
						if (videos == null || videoDelete == null) return videos;
						return [...videos, videoDelete.video];
					},
					videoMap: (context, _event) => {
						const { videoMap, videoDelete } = context;
						if (videoDelete == null) return videoMap;
						const { videoId, video } = videoDelete;
						return { ...videoMap, [videoId]: video };
					}
				}),
				insertNewVideos: assign({
					videos: (context, event) => {
						const { videos } = context;
						if (event.type !== INSERT_NEW_VIDEOS) return videos;
						if (videos == null) return event.data;
						return [...videos, ...event.data];
					},
					videoMap: (context, event) => {
						const { videoMap } = context;
						if (event.type !== INSERT_NEW_VIDEOS) return videoMap;
						const newMap = { ...videoMap };
						event.data.forEach(video => {
							newMap[video.videoId] = video;
						});
						return newMap;
					}
				}),
				filterVideos: assign({
					videosFiltered: (context, _event) => {
						const { videos, videoTypeOpt } = context;
						if (videos == null) return null;
						const videoTypes = getVideoTypes(videoTypeOpt);
						return videoTypes == null ? videos : videos.filter(video => videoTypes.includes(video.type));
					}
				}),
				updateSelectedVideos: assign({
					videosSelected: (context, _event) => {
						const { videosFiltered, videoIdsSelected } = context;
						if (videosFiltered == null) return [];
						return videosFiltered.filter(video => videoIdsSelected.has(video.videoId));
					}
				}),
				enqueueFetchError: (context, _event) => {
					context.enqueueSnackbar("Failed fetching videos for this player", { variant: "error" });
				},
				enqueuePartialFailure: (context, event) => {
					if (event.type === FETCH_VIDEOS_DONE) {
						const failText = event.data.isDplPass ? "Rocky" : "DPL";
						context.enqueueSnackbar(`Failed fetching ${failText} videos for this player`, {
							variant: "error"
						});
					}
				},
				enqueueDeleteError: (context, _event) => {
					context.enqueueSnackbar("Failed to delete the video", { variant: "error" });
				},
				enqueueDeleteSuccess: (context, _event) => {
					context.enqueueSnackbar("Success deleting the video", { variant: "success" });
				},
				enqueueNoneSelected: (context, _event) => {
					context.enqueueSnackbar("No selected video to play", { variant: "info" });
				},
				handleFetchSuccess: assign({
					videos: (context, event) => {
						if (event.type !== FETCH_VIDEOS_DONE || event.data.video == null) return context.videos;
						return event.data.video.map(video =>
							video.type === "media" ? { ...video, videoId: video.dplSharedLink } : video
						);
					},
					videoMap: (context, event) => {
						const { videoMap } = context;
						if (event.type !== FETCH_VIDEOS_DONE || event.data.video == null) return videoMap;
						event.data.video.forEach(function(video) {
							if (video.type === "media")
								videoMap[video.dplSharedLink] = { ...video, videoId: video.dplSharedLink };
							else videoMap[video.videoId] = video;
						});
						return videoMap;
					}
				}),
				handleDeleteSuccess: assign({
					videoDelete: (_context, _event) => null,
					videos: (context, _event) => {
						const { videoDelete, videos } = context;
						if (videoDelete && videos) {
							const index = videos.findIndex(video => video.videoId === videoDelete?.videoId);
							if (index !== -1) videos.splice(index, 1);
						}
						return videos;
					},
					videoMap: (context, _event) => {
						const { videoDelete, videoMap } = context;
						if (videoDelete && videoMap) delete videoMap[videoDelete?.videoId];
						return videoMap;
					},
					videosFiltered: (context, _event) => {
						const { videoDelete, videosFiltered } = context;
						if (videoDelete && videosFiltered) {
							return videosFiltered.filter(video => video.videoId !== videoDelete?.videoId);
						}
						return videosFiltered;
					}
				}),
				updateSnackbar: assign((context, event) => {
					if (event.type !== UPDATE_SNACKBAR) return context;
					return {
						...context,
						enqueueSnackbar: event.data.enqueueSnackbar
					};
				})
			},
			services: {
				fetchVideo: (context, _event) => {
					const requestDPL = axios
						.get(`/video/dpl?playerId=${context.playerId}&gcsPublicUrlNotNull=True`)
						.then(response => response.data)
						.catch(() => null);
					const requestOther = axios
						.get(`/video?playerId=${context.playerId}`)
						.then(response => response.data)
						.catch(() => null);
					return Promise.all([requestDPL, requestOther]).then(([videoDPL, videoOther]) => {
						if (videoDPL == null && videoOther == null) {
							return { video: null, isDplPass: false, isOtherPass: false };
						}
						if (videoDPL == null) {
							return { video: videoOther, isDplPass: false, isOtherPass: true };
						}
						if (videoOther == null) {
							return { video: videoDPL, isDplPass: true, isOtherPass: false };
						}
						return { video: [...videoDPL, ...videoOther], isDplPass: true, isOtherPass: true };
					});
				},
				deleteVideo: (context, _event) => {
					if (context.videoDelete == null)
						return Promise.reject("No stored video to delete when delete called");
					return axios.delete(`/video/${context.videoDelete.video.videoId}`);
				}
			},
			guards: {
				notMaxRetries: (context, _event) => context.retries < MAX_RETRIES,
				shouldFetchRetry: (context, event) => {
					if (event.type !== FETCH_VIDEOS_DONE) return false;
					const { isDplPass, isOtherPass } = event.data;
					return (!isDplPass || !isOtherPass) && context.retries < MAX_RETRIES;
				},
				fetchFullFailure: (_context, event) => {
					if (event.type !== FETCH_VIDEOS_DONE) return false;
					return event.data.video == null;
				},
				fetchPartialFailure: (_context, event) => {
					if (event.type !== FETCH_VIDEOS_DONE) return false;
					const { isDplPass, isOtherPass } = event.data;
					return !isDplPass || !isOtherPass;
				},
				playerIdNew: (context, event) =>
					event.type === UPDATE_PLAYER_ID && context.playerId !== event.data.playerId,
				validFetchParams: (context, _event) => context.playerId != null,
				validIdx: (context, event) => {
					if (event.type !== DELETE_VIDEO || context.videos == null) return false;
					const videoId = event.data.videoId;
					return context.videoMap.hasOwnProperty(videoId);
				},
				someSelected: (context, _event) => context.videos != null && context.videoIdsSelected.size > 0
			}
		}
	);

export default createVideoMachine;
