import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function Target(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M17.4557 2.83714L17.91 4.2L15.9214 6.18857C14.8714 5.27572 13.5043 4.71429 12 4.71429C8.68714 4.71429 6 7.40143 6 10.7143C6 14.0271 8.68714 16.7143 12 16.7143C15.3129 16.7143 18 14.0271 18 10.7143C18 9.52286 17.6529 8.41286 17.0529 7.47857L15.1586 9.37286C15.3343 9.78857 15.4286 10.2429 15.4286 10.7143C15.4286 12.6086 13.8943 14.1429 12 14.1429C10.1057 14.1429 8.57143 12.6086 8.57143 10.7143C8.57143 8.82 10.1057 7.28572 12 7.28572C12.7929 7.28572 13.5171 7.55572 14.1 8.01L12.5529 9.55714C12.3857 9.47572 12.2014 9.42857 12 9.42857C11.2886 9.42857 10.7143 10.0029 10.7143 10.7143C10.7143 11.4257 11.2886 12 12 12C12.7114 12 13.2857 11.4257 13.2857 10.7143C13.2857 10.5171 13.2386 10.3329 13.1571 10.1614L18.5143 4.80429L19.8771 5.25857L22.9071 2.22857L21.5443 1.77429L22.3029 1.01572C22.47 0.848573 22.47 0.578573 22.3029 0.41143C22.1357 0.244287 21.8657 0.244287 21.6986 0.41143L20.94 1.17L20.4857 -0.192856L17.4557 2.83714Z M20.4857 5.86715C20.3228 6.03 20.1043 6.12 19.8814 6.12C19.7914 6.12 19.6971 6.10714 19.6114 6.07714L19.0971 5.90572C20.0314 7.27715 20.5757 8.93572 20.5757 10.7186C20.5757 15.4543 16.74 19.29 12.0043 19.29C7.26855 19.29 3.43284 15.4543 3.43284 10.7186C3.43284 5.98286 7.26855 2.14714 12.0043 2.14714C13.7871 2.14714 15.4457 2.69572 16.8171 3.62572L16.6457 3.11143C16.5428 2.80286 16.6243 2.46429 16.8514 2.23286L17.5414 1.54286C15.9257 0.565716 14.0314 0.00428772 12.0043 0.00428772C6.08569 0.00428772 1.28998 4.8 1.28998 10.7186C1.28998 14.5371 3.29141 17.8886 6.29998 19.7871L5.16855 22.1829C4.86426 22.8257 5.13855 23.5929 5.78141 23.8929C6.42426 24.1971 7.19141 23.9229 7.49141 23.28L8.62284 20.8843C9.68569 21.2357 10.8214 21.4329 12 21.4329C13.1785 21.4329 14.3186 21.24 15.3771 20.8843L16.5086 23.28C16.8128 23.9229 17.58 24.1971 18.2185 23.8929C18.8614 23.5886 19.1357 22.8214 18.8314 22.1829L17.7 19.7871C20.7086 17.8886 22.71 14.5371 22.71 10.7186C22.71 8.69143 22.1485 6.79714 21.1714 5.18143L20.4814 5.87143L20.4857 5.86715Z"
			/>
		</Icon>
	);
}
