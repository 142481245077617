import styled from "@emotion/styled";

import { BLUE, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

export const SUMMARY_BUTTON_STYLE = { margin: "10px" };

export const GameSummaryHeader = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
});

export const GameSummaryTeamImageStyle = {
	height: "35px",
	width: "auto",
	display: "block",
	margin: "auto"
};

export const GameSummaryDate = styled.h4({
	marginBottom: "2px",
	textAlign: "center",
	padding: "2px 4px",
	backgroundColor: BLUE,
	borderRadius: "2px",
	color: "white",
	display: "inline-block",
	marginTop: "0px",
	fontWeight: "bold"
});

export const GameSummaryVenue = styled.h5({
	marginTop: "6px",
	marginBottom: "4px",
	fontStyle: "italic",
	fontWeight: "bold",
	fontSize: "11px"
});

export const GameSummaryDoubleheader = styled.h4({
	marginTop: "4px",
	color: "darkGray",
	fontSize: "14px",
	fontWeight: "bold",
	marginBottom: "18px"
});

export const GameSummaryGameContainer = styled.div({
	// border: `2px solid ${BLUE}`,
	borderRadius: "3px",
	padding: "10px",
	boxShadow:
		"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
	// flexShrink: 1,
	flex: 1,
	marginLeft: "5px",
	marginRight: "5px",
	textAlign: "center",
	"&:first-child": {
		marginLeft: "0px"
	},
	"&:last-child": {
		marginRight: "0px"
	}
});

export const GameSummaryTeamImageContainer = styled.div({
	flexGrow: 1
});

export const GameSummaryGameLoadingContainer = styled.div({
	display: "flex",
	alignItems: "stretch",
	justifyContent: "center",
	flex: 1
});

export const GameSummaryGameLoading = { height: "188px", minWidth: "178px" };

export const GameSummaryImageContainer = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	margin: "10px"
});

export const GameSummaryContainer = styled.div({
	textAlign: "center",
	fontSize: "13px"
});

export const PlayerLink = styled.a({
	color: defaultColorScheme.primary.color,
	"&:hover": {
		color: defaultColorScheme.secondary.color
	}
});
