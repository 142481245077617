import React, { useContext, useMemo, useState } from "react";
import { useSelector } from "@xstate/react";
import { Box, HStack } from "@chakra-ui/react";

import { THROWS_S } from "_react/shared/data_models/player/_constants";
import { getPositionGroup } from "_react/shared/data_models/player/_helpers";
import { BATS_OVERALL, THROWS_L, THROWS_OVERALL, THROWS_R } from "_react/shared/data_models/seasonal_grades/_constants";
import { POSITION_GROUP_POSITION_PLAYER } from "_react/shared/_constants/position_groups";
import Frog from "_react/shared/ui/icons/Frog";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import {
	ToggleTopBreakpointValue,
	TogglePositionBreakpointValue,
	ToggleJustifyContentBreakpointValue,
	ToggleGapBreakpointValue,
	ContentPaddingTopBreakpointValue,
	TOGGLE_STYLE
} from "_react/playerpage/_styles";
import { PlayerPageProContext } from "_react/playerpage/pro/PlayerPageProProvider";
import { TPlayerPageProState } from "_react/playerpage/pro/_machine";
import {
	BatterHandednessToggleOptions,
	PitcherHandednessToggleOptions,
	SHOHEI_PLAYER_ID
} from "_react/playerpage/_constants";
import FieldingSection from "_react/playerpage/pro/content/observed/FieldingSection";
import ArsenalTrendsSection from "_react/playerpage/shared/content/observed/ArsenalTrendsSection";
import HittingSection from "_react/playerpage/shared/content/observed/HittingSection";
import StuffSection from "_react/playerpage/shared/content/observed/StuffSection";
import LocationsSection from "_react/playerpage/shared/content/observed/LocationsSection";
import { PLAYING_LEVEL_PRO } from "_react/shared/data_models/seasonal_grades/_constants";
import { getDefaultSeason, getSeasonOptions } from "_react/playerpage/pro/shared/_helpers";

type TObservedTabProps = {
	player: TPlayerPageCombinedPlayer;
};

const ObservedTab = ({ player }: TObservedTabProps) => {
	// Toggles for handedness and season
	const [batsFilter, setBatsFilter] = useState<string>(BATS_OVERALL);
	const [throwsFilter, setThrowsFilter] = useState<string>(THROWS_OVERALL);
	const [seasonFilter, setSeasonFilter] = useState<number>(getDefaultSeason());

	const playerPageProContext = useContext(PlayerPageProContext);

	const isFetchingCurrentSeason: boolean | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.currentSeasonData?.isFetching
	);

	const currentSeason: number | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.currentSeasonData?.currentSeason
	);

	const seasonOptions = useMemo(() => getSeasonOptions(currentSeason, isFetchingCurrentSeason), [
		isFetchingCurrentSeason,
		currentSeason
	]);

	// Want Shohei to display as a position player regardless of his position
	const positionGroup = player.id === SHOHEI_PLAYER_ID ? POSITION_GROUP_POSITION_PLAYER : getPositionGroup(player);

	// Switch pitcher toggle
	const throws = player?.throws;
	const isSwitchPitcher = Boolean(throws && throws === THROWS_S && positionGroup !== POSITION_GROUP_POSITION_PLAYER);
	const [pitcherThrowsFilter, setPitcherThrowsFilter] = useState<string | undefined>(
		isSwitchPitcher ? THROWS_L : undefined
	);

	const setPitcherThrowsToggle = (playerType: string | number) => {
		if (typeof playerType !== "string") return;
		setPitcherThrowsFilter(playerType);
	};

	return (
		<Box position="relative" top="-8">
			<HStack
				top={ToggleTopBreakpointValue}
				position={TogglePositionBreakpointValue}
				justifyContent={ToggleJustifyContentBreakpointValue}
				gap={ToggleGapBreakpointValue}
				// Necessary otherwise the bars on the highcharts bar chart are on top of the toggle buttons
				zIndex={1}
			>
				{isSwitchPitcher && (
					<ToggleButton
						toggleOptions={[
							{
								value: THROWS_L,
								label: "L",
								icon:
									pitcherThrowsFilter === THROWS_L ? (
										<Frog sx={{ color: "gray.500", marginRight: "1" }} />
									) : (
										undefined
									)
							},
							{
								value: THROWS_R,
								label: "R",
								icon:
									pitcherThrowsFilter === THROWS_R ? (
										<Frog sx={{ color: "gray.500", marginRight: "1" }} />
									) : (
										undefined
									)
							}
						]}
						value={pitcherThrowsFilter}
						onSelect={setPitcherThrowsToggle}
						style={TOGGLE_STYLE}
					/>
				)}
				<ToggleButton<number>
					toggleOptions={seasonOptions}
					value={seasonFilter}
					onSelect={(value: number) => setSeasonFilter(value)}
					isLoading={isFetchingCurrentSeason}
					style={TOGGLE_STYLE}
				/>
				{positionGroup === POSITION_GROUP_POSITION_PLAYER && (
					<ToggleButton<string>
						toggleOptions={PitcherHandednessToggleOptions}
						value={throwsFilter}
						onSelect={(value: string) => setThrowsFilter(value)}
						style={TOGGLE_STYLE}
					/>
				)}
				{positionGroup !== POSITION_GROUP_POSITION_PLAYER && (
					<ToggleButton<string>
						toggleOptions={BatterHandednessToggleOptions}
						value={batsFilter}
						onSelect={(value: string) => setBatsFilter(value)}
						style={TOGGLE_STYLE}
					/>
				)}
			</HStack>

			<Box paddingTop={ContentPaddingTopBreakpointValue}>
				{isFetchingCurrentSeason && !seasonFilter && <Box className="loading-item" height="md" width="100%" />}

				{positionGroup === POSITION_GROUP_POSITION_PLAYER && seasonFilter && (
					<>
						<HittingSection
							player={player}
							playingLevel={PLAYING_LEVEL_PRO}
							seasonFilter={seasonFilter}
							throwsFilter={throwsFilter}
						/>
						<FieldingSection player={player} />
					</>
				)}
				{positionGroup !== POSITION_GROUP_POSITION_PLAYER && seasonFilter && (
					<>
						<StuffSection
							player={player}
							playingLevel={PLAYING_LEVEL_PRO}
							seasonFilter={seasonFilter}
							batsFilter={batsFilter}
							throwsFilter={pitcherThrowsFilter}
						/>
						<LocationsSection
							player={player}
							seasonFilter={seasonFilter}
							batsFilter={batsFilter}
							useCountSplits={true}
							playingLevel={PLAYING_LEVEL_PRO}
							throwsFilter={pitcherThrowsFilter}
						/>
						<ArsenalTrendsSection
							player={player}
							seasonFilter={seasonFilter}
							batsFilter={batsFilter}
							playingLevel={PLAYING_LEVEL_PRO}
							throwsFilter={pitcherThrowsFilter}
						/>
					</>
				)}
			</Box>
		</Box>
	);
};

export default ObservedTab;
