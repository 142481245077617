import React, { CSSProperties } from "react";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { IAddendumBAssignmentClause } from "_react/shared/_types/schema/contracts";

const ASSIGNMENT_CLAUSE_COLUMNS = [
	{
		title: "Roster Type",
		name: "roster_type",
		getCellValue: (row: IAddendumBAssignmentClause) => row.assignee_roster
	},
	{
		title: "Roster Date",
		name: "roster_date",
		getCellValue: (row: IAddendumBAssignmentClause) => row.roster_date
	},
	{
		title: "ML Club Response Period",
		name: "club_response_hours",
		getCellValue: (row: IAddendumBAssignmentClause) => `${row.club_response_hours ?? "-"} Hours`
	},
	{
		title: "Notification Period",
		name: "notification_period_hours",
		getCellValue: (row: IAddendumBAssignmentClause) => `${row.notification_period_hours ?? "-"} Hours`
	},
	{
		title: "Decision Period",
		name: "decision_period_hours",
		getCellValue: (row: IAddendumBAssignmentClause) => `${row.decision_period_hours ?? "-"} Hours`
	}
];

type TAddendumBAssignmentClauseTableStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

interface IProps {
	addendumBAssignmentClauses: IAddendumBAssignmentClause[];
	colorSchemeGroup: ColorSchemeGroup;
	style?: TAddendumBAssignmentClauseTableStyle;
}

function AddendumBAssignmentClauseTable({ addendumBAssignmentClauses, colorSchemeGroup, style }: IProps) {
	return (
		<React.Fragment>
			<Card style={style?.card}>
				<ColoredCardHeader
					colorScheme={colorSchemeGroup.secondary}
					text={"Addendum B Assignment Clauses"}
					style={style?.cardHeader}
				/>
				<AutoFitTable
					columns={ASSIGNMENT_CLAUSE_COLUMNS}
					defaultSortColumn={"Roster Type"}
					defaultSortState={"desc"}
					isHighlightOnHover={false}
					rows={addendumBAssignmentClauses}
					rowKey="contractId"
				/>
			</Card>
		</React.Fragment>
	);
}

export default AddendumBAssignmentClauseTable;
