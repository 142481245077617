import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function CheckBoxOutlineBlank({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={"none"} d="M0 0h24v24H0z" />
			<path
				d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
				fill={fill}
			/>
		</IconSVG>
	);
}
