import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import {
	IPlayerSeasonArsenalScoresSchema,
	IPlayerSeasonArsenalScoresByTeamSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import {
	BATS_L,
	BATS_R,
	COUNT_SPLIT_OVERALL,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	PLAYING_LEVEL_PRO,
	SCHEMA_TYPE_EXPANDED
} from "_react/shared/data_models/arsenal_scores/_constants";
import {
	fetchPlayerSeasonArsenalScores,
	fetchPlayerSeasonArsenalScoresByTeam
} from "_react/shared/data_models/arsenal_scores/_network";

import { TPitcherFoundationalSkillsTableData } from "_react/shared/ui/data/tables/PitcherFoundationalSkillsTable/_types";

export const PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE = "playerSeasonArsenalScores";
export const PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE = "playerSeasonArsenalScoresByTeam";

export type TPitcherFoundationalSkillsTableCancelSource = {
	[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPitcherFoundationalSkillsTableFilters = {
	gameTypes: Array<string>;
	bats: Array<string>;
	pitchTypes?: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TPitcherFoundationalSkillsTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TPitcherFoundationalSkillsTableFilters;
	playerSeasonArsenalScores?: Array<IPlayerSeasonArsenalScoresSchema> | null;
	playerSeasonArsenalScoresByTeam?: Array<IPlayerSeasonArsenalScoresByTeamSchema> | null;
	cancelSources: TPitcherFoundationalSkillsTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPitcherFoundationalSkillsTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				contextRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all arsenal scores by player season
				playerSeasonArsenalScores: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all arsenal scores by player season team
				playerSeasonArsenalScoresByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES = { initialized: { playerSeasonArsenalScores: "fetching" } };
export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM = {
	initialized: { playerSeasonArsenalScoresByTeam: "fetching" }
};

const FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE = "done.invoke.fetchPlayerSeasonArsenalScores:invocation[0]";
const FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonArsenalScoresByTeam:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_SEASON_ARSENAL_SCORES = "SET_PLAYER_SEASON_ARSENAL_SCORES";
export const SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM = "SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM";
export const SET_FILTERS = "SET_FILTERS";

type TFetchPlayerSeasonArsenalScoresEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE;
	data: Array<IPlayerSeasonArsenalScoresSchema> | undefined;
};
type TFetchPlayerSeasonArsenalScoresByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE;
	data: Array<IPlayerSeasonArsenalScoresByTeamSchema> | undefined;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetPlayerSeasonArsenalScoresEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES;
	value?: Array<IPlayerSeasonArsenalScoresSchema>;
};
type TSetPlayerSeasonArsenalScoresByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM;
	value?: Array<IPlayerSeasonArsenalScoresByTeamSchema>;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TPitcherFoundationalSkillsTableFilters;
};

type TPitcherFoundationalSkillsTableEvent =
	| TFetchPlayerSeasonArsenalScoresEvent
	| TFetchPlayerSeasonArsenalScoresByTeamEvent
	| TSetPlayerIdEvent
	| TSetPlayerSeasonArsenalScoresEvent
	| TSetPlayerSeasonArsenalScoresByTeamEvent
	| TSetFiltersEvent;

export type TPitcherFoundationalSkillsTableSend = Interpreter<
	TPitcherFoundationalSkillsTableContext,
	IPitcherFoundationalSkillsTableStateSchema,
	TPitcherFoundationalSkillsTableEvent
>["send"];

const PitcherFoundationalSkillsTableMachine = (
	playerIdProp?: number,
	data?: TPitcherFoundationalSkillsTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TPitcherFoundationalSkillsTableContext,
		IPitcherFoundationalSkillsTableStateSchema,
		TPitcherFoundationalSkillsTableEvent
	>(
		{
			id: "projectionsTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					gameTypes: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_POSTSEASON],
					bats: [BATS_L, BATS_R],
					pitchTypes: undefined,
					levels: undefined
				},
				playerSeasonArsenalScores: data?.playerSeasonArsenalScores,
				playerSeasonArsenalScoresByTeam: data?.playerSeasonArsenalScoresByTeam,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: { actions: "setPlayerId" },
						SET_PLAYER_SEASON_ARSENAL_SCORES: { actions: "setPlayerSeasonArsenalScores" },
						SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM: { actions: "setPlayerSeasonArsenalScoresByTeam" },
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						contextRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						playerSeasonArsenalScores: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonArsenalScores",
												cond: "shouldFetchPlayerSeasonArsenalScores"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonArsenalScores",
									entry: ["refreshPlayerSeasonArsenalScoresCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScores",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresErrored"
										}
									}
								}
							}
						},
						playerSeasonArsenalScoresByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonArsenalScoresByTeam",
												cond: "shouldFetchPlayerSeasonArsenalScoresByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonArsenalScoresByTeam",
									entry: ["refreshPlayerSeasonArsenalScoresByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScoresByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresByTeamErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TPitcherFoundationalSkillsTableContext,
					_event: TPitcherFoundationalSkillsTableEvent
				) => context.playerId !== context.lastPlayerId,
				shouldFetchPlayerSeasonArsenalScores: (
					context: TPitcherFoundationalSkillsTableContext,
					_event: TPitcherFoundationalSkillsTableEvent
				) =>
					context.playerSeasonArsenalScores === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonArsenalScoresByTeam: (
					context: TPitcherFoundationalSkillsTableContext,
					_event: TPitcherFoundationalSkillsTableEvent
				) =>
					context.playerSeasonArsenalScoresByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearContext: assign<TPitcherFoundationalSkillsTableContext, TPitcherFoundationalSkillsTableEvent>({
					lastPlayerId: (
						context: TPitcherFoundationalSkillsTableContext,
						_event: TPitcherFoundationalSkillsTableEvent
					) => context.playerId,
					playerSeasonArsenalScores: (
						_context: TPitcherFoundationalSkillsTableContext,
						_event: TPitcherFoundationalSkillsTableEvent
					) => undefined,
					playerSeasonArsenalScoresByTeam: (
						_context: TPitcherFoundationalSkillsTableContext,
						_event: TPitcherFoundationalSkillsTableEvent
					) => undefined,
					cancelSources: (
						context: TPitcherFoundationalSkillsTableContext,
						_event: TPitcherFoundationalSkillsTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TPitcherFoundationalSkillsTableContext, TPitcherFoundationalSkillsTableEvent>({
					playerId: (
						context: TPitcherFoundationalSkillsTableContext,
						event: TPitcherFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (
						context: TPitcherFoundationalSkillsTableContext,
						_event: TPitcherFoundationalSkillsTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setPlayerSeasonArsenalScores: assign<
					TPitcherFoundationalSkillsTableContext,
					TPitcherFoundationalSkillsTableEvent
				>({
					playerSeasonArsenalScores: (
						context: TPitcherFoundationalSkillsTableContext,
						event: TPitcherFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.playerSeasonArsenalScores;
						return event.value;
					},
					cancelSources: (
						context: TPitcherFoundationalSkillsTableContext,
						event: TPitcherFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return cancelSources;
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerSeasonArsenalScoresByTeam: assign<
					TPitcherFoundationalSkillsTableContext,
					TPitcherFoundationalSkillsTableEvent
				>({
					playerSeasonArsenalScoresByTeam: (
						context: TPitcherFoundationalSkillsTableContext,
						event: TPitcherFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM)
							return context.playerSeasonArsenalScoresByTeam;
						return event.value;
					},
					cancelSources: (
						context: TPitcherFoundationalSkillsTableContext,
						event: TPitcherFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TPitcherFoundationalSkillsTableContext, TPitcherFoundationalSkillsTableEvent>({
					filters: (
						context: TPitcherFoundationalSkillsTableContext,
						event: TPitcherFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonArsenalScoresCancelSource: assign<
					TPitcherFoundationalSkillsTableContext,
					TPitcherFoundationalSkillsTableEvent
				>({
					cancelSources: (
						context: TPitcherFoundationalSkillsTableContext,
						_event: TPitcherFoundationalSkillsTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonArsenalScoresByTeamCancelSource: assign<
					TPitcherFoundationalSkillsTableContext,
					TPitcherFoundationalSkillsTableEvent
				>({
					cancelSources: (
						context: TPitcherFoundationalSkillsTableContext,
						_event: TPitcherFoundationalSkillsTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonArsenalScoresSuccess: assign<
					TPitcherFoundationalSkillsTableContext,
					TPitcherFoundationalSkillsTableEvent
				>({
					playerSeasonArsenalScores: (
						context: TPitcherFoundationalSkillsTableContext,
						event: TPitcherFoundationalSkillsTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE)
							return context.playerSeasonArsenalScores;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonArsenalScoresByTeamSuccess: assign<
					TPitcherFoundationalSkillsTableContext,
					TPitcherFoundationalSkillsTableEvent
				>({
					playerSeasonArsenalScoresByTeam: (
						context: TPitcherFoundationalSkillsTableContext,
						event: TPitcherFoundationalSkillsTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_DONE)
							return context.playerSeasonArsenalScoresByTeam;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchPlayerSeasonArsenalScoresErrored: (
					context: TPitcherFoundationalSkillsTableContext,
					_event: TPitcherFoundationalSkillsTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Foundational Skills - Arsenal Scores",
							description: "Error fetching arsenal scores by player and season.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchPlayerSeasonArsenalScoresByTeamErrored: (
					context: TPitcherFoundationalSkillsTableContext,
					_event: TPitcherFoundationalSkillsTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Foundational Skills - Arsenal Scores by Team",
							description: "Error fetching arsenal scores by player, season, and team",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchPlayerSeasonArsenalScores: (
					context: TPitcherFoundationalSkillsTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScores(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								countSplit: COUNT_SPLIT_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonArsenalScoresByTeam: (
					context: TPitcherFoundationalSkillsTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScoresByTeam(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								countSplit: COUNT_SPLIT_OVERALL,
								schema: SCHEMA_TYPE_EXPANDED,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherFoundationalSkillsTableMachine;
