import React, { useContext } from "react";
import { useSelector } from "@xstate/react";
import { SimpleGrid, GridItem } from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import Section from "_react/shared/ui/presentation/components/Section/Section";
import SurplusValueStatCard from "_react/shared/ui/data/cards/SurplusValueStatCard/SurplusValueStatCard";
import SeasonalSurplusValuesTable from "_react/shared/ui/data/tables/SeasonalSurplusValuesTable/SeasonalSurplusValuesTable";
import { TAllSurplusValues } from "_react/shared/data_models/surplus_value/_types";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TPlayerPageProState, FETCHING_SURPLUS_VALUES } from "_react/playerpage/pro/_machine";
import { PlayerPageProContext } from "_react/playerpage/pro/PlayerPageProProvider";
import { StatCardMarginTopStyle, SectionStyleObject } from "_react/playerpage/pro/shared/_styles";

type TSurplusValueSectionProps = {
	player: TPlayerPageCombinedPlayer;
};

const SurplusValueSection = ({ player }: TSurplusValueSectionProps) => {
	const playerPageProContext = useContext(PlayerPageProContext);

	const surplusValues: TAllSurplusValues | null | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.surplusValues
	);
	const isFetchingSurplusValues: boolean = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.matches(FETCHING_SURPLUS_VALUES)
	);

	// Only need margin top when there is a table with a title next to the StatCard
	const statCardStyling = useBreakpointValue({
		base: undefined,
		"2xl": StatCardMarginTopStyle
	});

	return (
		<Section label="Surplus Value" style={SectionStyleObject}>
			<SimpleGrid
				columns={3}
				minChildWidth={{
					md: "sm"
				}}
				spacing={6}
			>
				<GridItem>
					<SurplusValueStatCard
						playerId={player.id}
						data={{ surplusValues: surplusValues, isLoading: isFetchingSurplusValues }}
						shouldFetchData={false}
						style={statCardStyling}
					/>
				</GridItem>
				<GridItem colSpan={2}>
					<SeasonalSurplusValuesTable title={"Seasonal Surplus Values"} playerId={player.id} />
				</GridItem>
			</SimpleGrid>
		</Section>
	);
};

export default SurplusValueSection;
