import { ALL_POSITIONS } from "_react/shared/_constants/stats_player_fielding_internal";
import { getColorGradientFunctionBlueGreen } from "_react/shared/dataviz/_helpers";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import { VALUE_TYPE_NUMBER, VALUE_TYPE_OTHER } from "_react/shared/ui/presentation/components/Table/_constants";
import {
	createPositionToColumnsMapping,
	createPositionsToParentColumnsMapping
} from "_react/shared/ui/data/tables/AmaFieldingOaaTable/_helpers";
import { TAmaFieldingOaaRow } from "_react/shared/ui/data/tables/AmaFieldingOaaTable/_types";

export const PROPORTION_UPPER_THRESHOLD = 0.5;
export const OAA_OPPS_THRESHOLD = 100;

// We want to create a discrete color gradient function that can display NUM_DISCRETE_COLORS colors
// To achieve this effect, create a continuous color gradient function from 0 to NUM_DISCRETE_COLORS - 1,
// and only pass in integers from 0 to NUM_DISCRETE_COLORS - 1, meaning that we will only show
// NUM_DISCRETE_COLORS colors from the continuous gradient.
export const NUM_DISCRETE_COLORS = 9;
export const [COLOR_GRADIENT_FUNCTION_BLUE_GREEN] = getColorGradientFunctionBlueGreen(0, NUM_DISCRETE_COLORS - 1);

export const NULL_FILLER_TEXT = "";
export const NULL_FILLER_TEXT_COMPONENTS = "-";

export const POSITION_TO_COLUMN_MAPPING = createPositionToColumnsMapping(ALL_POSITIONS);

export const SEASON_COLUMN_ID = "season";
export const LEVEL_COLUMN_ID = "level";

const SEASON_COLUMN = {
	value: "season",
	id: SEASON_COLUMN_ID,
	label: "Season",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (row: TAmaFieldingOaaRow) => row.season
};

const LEVEL_COLUMN = {
	value: "level",
	id: LEVEL_COLUMN_ID,
	label: "Level",
	valueType: VALUE_TYPE_OTHER as TValueType,
	isMobile: true,
	getValueFunction: (row: TAmaFieldingOaaRow) => TeamLevelBadge({ level: row.level })
};

export const AMA_FIELDING_OAA_COLUMNS = [SEASON_COLUMN, LEVEL_COLUMN];

export const AMA_FIELDING_OAA_PARENT_COLUMNS = [
	{ label: "", id: "parentColumns", childColumnIds: [SEASON_COLUMN_ID, LEVEL_COLUMN_ID] },
	...createPositionsToParentColumnsMapping(ALL_POSITIONS)
];
