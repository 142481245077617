import React from "react";
import { Box, VStack } from "@chakra-ui/react";

type TExpandableRaaLabelProps = {
	totalRaaLabel?: string;
	componentRaaLabels?: Array<string>;
	isExpanded?: boolean;
};

const ExpandableRaaLabel = ({ totalRaaLabel, componentRaaLabels, isExpanded }: TExpandableRaaLabelProps) => {
	return (
		<VStack align="start" gap="0">
			<Box width="100%">{totalRaaLabel}</Box>
			{isExpanded && (
				<Box width="100%" fontSize="2xs" color="gray.200" sx={{ textWrap: "nowrap" }}>
					{componentRaaLabels?.map((componentRaaLabel: string, index: number) => {
						const spacer = index !== componentRaaLabels.length - 1 ? " | " : "";
						return componentRaaLabel + spacer;
					})}
				</Box>
			)}
		</VStack>
	);
};

export default ExpandableRaaLabel;
