import React, { FC, useState } from "react";

import { getRosterStatusFromProPlayer } from "utils/functions";
import { $TSFixMe } from "utils/tsutils";
import { getMajorLeagueHeadshot, getMinorLeagueHeadshot } from "_react/shared/data_models/player/_helpers";

import { PRO } from "_react/playerpage/_constants";
import { getRosterStatusColor } from "_react/playerpage/_helpers";
import playerSilhouette from "_react/playerpage/header/PlayerSilhouette.jpg";

type PlayerImageProps = {
	player: $TSFixMe;
	height: number;
	heightUnit?: string;
	includeRosterStatus?: boolean;
	style?: object;
};

// should probably make sure that people who weren't added don't have dpl ids
function getAmaOrDefaultHeadshot(dplId: number): string {
	if (dplId === undefined) {
		return playerSilhouette;
	}

	return `https://storage.cloud.google.com/phillies-uploader/mlb/draft-prospect-headshots/${dplId}.png`;
}

export const PlayerImage: FC<PlayerImageProps> = ({
	player,
	height,
	heightUnit = "px",
	includeRosterStatus = false,
	style = {}
}) => {
	const { playerClassification } = player;
	// Fix until batting advance uses camel cased combined player also
	const bamId = player.hasOwnProperty("bamId")
		? player.bamId
		: player.hasOwnProperty("playerBam")
		? player.playerBam
		: undefined;
	const dplId = player.hasOwnProperty("dplId") ? player.dplId : undefined;
	const isPro = playerClassification ? playerClassification.toLowerCase() === PRO : false;
	const [isTriedMilb, setIsTriedMiLB] = useState(false);
	const [imageSrc, setImageSrc] = useState(isPro ? getMajorLeagueHeadshot(bamId) : getAmaOrDefaultHeadshot(dplId));

	const handleError = () => {
		if (isPro) {
			if (!isTriedMilb) {
				setIsTriedMiLB(true);
				setImageSrc(getMinorLeagueHeadshot(bamId));
			} else {
				setImageSrc(playerSilhouette);
			}
		} else {
			setImageSrc(playerSilhouette);
		}
	};

	return (
		<div
			style={{
				height: height + heightUnit,
				borderRadius: "3px",
				overflow: "hidden",
				position: "relative",
				display: "flex",
				justifyContent: "center",
				...style
			}}
		>
			<img
				alt="Player"
				onError={handleError}
				src={imageSrc}
				style={{ height: height + heightUnit, minWidth: "fit-content" }}
			/>
			{includeRosterStatus && (
				<div
					style={{
						fontSize: "10px",
						fontWeight: 800,
						color: getRosterStatusColor(
							player.proProfile?.rosterStatusMj
								? player.proProfile.rosterStatusMj
								: player.proProfile?.rosterStatusMn
								? player.proProfile.rosterStatusMn
								: null
						).color,
						backgroundColor: getRosterStatusColor(
							player.proProfile?.rosterStatusMj
								? player.proProfile.rosterStatusMj
								: player.proProfile?.rosterStatusMn
								? player.proProfile?.rosterStatusMn
								: null
						).text,
						position: "absolute",
						bottom: 0,
						left: 0,
						right: 0,
						paddingTop: "1px",
						paddingBottom: "2px"
					}}
				>
					{getRosterStatusFromProPlayer(player)}
				</div>
			)}
		</div>
	);
};
