import { getRgbNumberArray } from "utils/color";
import { round10 } from "_react/shared/_helpers/numbers";
import { _1B } from "_react/shared/_constants/stats_player_fielding_internal";
import { DATA_COLOR_PRIMARY_RED_MIXED_WITH_WHITE_RGB } from "_react/shared/dataviz/_constants";
import { TColumn, TParentColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER } from "_react/shared/ui/presentation/components/Table/_constants";
import { TOOLTIP_OAA } from "_react/shared/_constants/tooltips";

import {
	COLOR_GRADIENT_FUNCTION_BLUE_GREEN,
	NULL_FILLER_TEXT,
	PROPORTION_UPPER_THRESHOLD
} from "_react/shared/ui/data/tables/FieldingOaaTable/_constants";
import {
	TFieldingOaaRow,
	TFieldingOaaRowMobile,
	TStatsPlayerFieldingBasic
} from "_react/shared/ui/data/tables/FieldingOaaTable/_types";

export const proportionColorGradientFunction = (proportion: string | number): Array<number> | null => {
	if (typeof proportion === "string") return null;
	return proportion > PROPORTION_UPPER_THRESHOLD
		? getRgbNumberArray(DATA_COLOR_PRIMARY_RED_MIXED_WITH_WHITE_RGB)
		: null;
};

// Helper functions to parse data from Stats Player Fielding
export const getOpportunitiesPerFullSeasonFromStatsPlayerFielding = (
	statsPlayerFieldingBasicData: Array<TStatsPlayerFieldingBasic> | undefined
): number | null => {
	if (statsPlayerFieldingBasicData === undefined || statsPlayerFieldingBasicData.length === 0) return null;
	return statsPlayerFieldingBasicData[0].opportunitiesPerFullSeason ?? null;
};

export const getInningsFromStatsPlayerFielding = (
	statsPlayerFieldingBasicData: Array<TStatsPlayerFieldingBasic> | undefined
): number | typeof NULL_FILLER_TEXT => {
	if (statsPlayerFieldingBasicData === undefined || statsPlayerFieldingBasicData.length === 0)
		return NULL_FILLER_TEXT;
	return statsPlayerFieldingBasicData[0].innings ?? NULL_FILLER_TEXT;
};

export const getOpportunitiesFromStatsPlayerFielding = (
	statsPlayerFieldingBasicData: Array<TStatsPlayerFieldingBasic> | undefined,
	isSum = false
): number | typeof NULL_FILLER_TEXT => {
	if (statsPlayerFieldingBasicData === undefined || statsPlayerFieldingBasicData.length === 0)
		return NULL_FILLER_TEXT;
	if (isSum)
		return (
			statsPlayerFieldingBasicData.reduce(
				(total: number | null, statsPlayerFieldingBasic: TStatsPlayerFieldingBasic) => {
					return total === null
						? statsPlayerFieldingBasic.opportunities
						: total + (statsPlayerFieldingBasic.opportunities ?? 0);
				},
				null
			) ?? NULL_FILLER_TEXT
		);
	return statsPlayerFieldingBasicData[0].opportunities ?? NULL_FILLER_TEXT;
};

export const getOaaFromStatsPlayerFielding = (
	statsPlayerFieldingBasicData: Array<TStatsPlayerFieldingBasic> | undefined,
	isSum = false
): number | typeof NULL_FILLER_TEXT => {
	if (statsPlayerFieldingBasicData === undefined || statsPlayerFieldingBasicData.length === 0)
		return NULL_FILLER_TEXT;
	if (isSum)
		return (
			statsPlayerFieldingBasicData.reduce(
				(total: number | null, statsPlayerFieldingBasic: TStatsPlayerFieldingBasic) => {
					return total === null
						? statsPlayerFieldingBasic.fullSeasonScaledOaaCentered
						: total + (statsPlayerFieldingBasic.fullSeasonScaledOaaCentered ?? 0);
				},
				null
			) ?? NULL_FILLER_TEXT
		);
	return statsPlayerFieldingBasicData[0].fullSeasonScaledOaaCentered ?? NULL_FILLER_TEXT;
};

export const getPositionProportionFromStatsPlayerFielding = (
	statsPlayerFieldingBasicData: Array<TStatsPlayerFieldingBasic> | undefined,
	isSum = false
): number | null => {
	const opportunitiesPerFullSeason: number | null = getOpportunitiesPerFullSeasonFromStatsPlayerFielding(
		statsPlayerFieldingBasicData
	);
	// Return null if opportunitiesPerFullSeason is not a valid divisor
	if (opportunitiesPerFullSeason === null || opportunitiesPerFullSeason === 0) return null;

	const opportunities: number | typeof NULL_FILLER_TEXT = getOpportunitiesFromStatsPlayerFielding(
		statsPlayerFieldingBasicData,
		isSum
	);
	if (opportunities === NULL_FILLER_TEXT) return null;

	return opportunities / opportunitiesPerFullSeason;
};

// Helper functions to get data for non-mobile table
const getInningColumnId = (position: string): string => `${position}_inn`;
const getOpportunityColumnId = (position: string): string => `${position}_opp`;
const getOaaColumnId = (position: string): string => `${position}_oaa`;

const createInningsColumn = (position: string): TColumn<TFieldingOaaRow, keyof TFieldingOaaRow> => {
	return {
		value: `${position}Inn`,
		id: getInningColumnId(position),
		label: `Inn`,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		isMobile: false,
		getValueFunction: (row: TFieldingOaaRow) => {
			if (row.fieldingOaaByPosition === undefined) return NULL_FILLER_TEXT;
			const innings = getInningsFromStatsPlayerFielding(row.fieldingOaaByPosition[position]);
			return innings === NULL_FILLER_TEXT ? innings : round10(innings, -1);
		},
		getSortValueFunction: (row: TFieldingOaaRow) =>
			getInningsFromStatsPlayerFielding(row.fieldingOaaByPosition[position])
	};
};

const createOppColumn = (position: string): TColumn<TFieldingOaaRow, keyof TFieldingOaaRow> => {
	return {
		value: `${position}Opp`,
		id: getOpportunityColumnId(position),
		label: `Opp`,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		isMobile: false,
		getValueFunction: (row: TFieldingOaaRow) => {
			if (row.fieldingOaaByPosition === undefined) return NULL_FILLER_TEXT;
			const opportunities: number | typeof NULL_FILLER_TEXT = getOpportunitiesFromStatsPlayerFielding(
				row.fieldingOaaByPosition[position],
				position === _1B
			);
			return opportunities === NULL_FILLER_TEXT ? opportunities : Math.round(opportunities).toString();
		},
		getSortValueFunction: (row: TFieldingOaaRow) =>
			getOpportunitiesFromStatsPlayerFielding(row.fieldingOaaByPosition[position], position === _1B),
		getColorGradientValueFunction: (row: TFieldingOaaRow): number | string => {
			if (row.fieldingOaaByPosition === undefined) return "";
			return (
				getPositionProportionFromStatsPlayerFielding(row.fieldingOaaByPosition[position], position === _1B) ??
				""
			);
		},
		colorGradientFunction: proportionColorGradientFunction
	};
};

const createOaaColumn = (position: string): TColumn<TFieldingOaaRow, keyof TFieldingOaaRow> => {
	return {
		value: `${position}Oaa`,
		id: getOaaColumnId(position),
		label: `OAA`,
		tooltip: TOOLTIP_OAA,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		isMobile: false,
		getValueFunction: (row: TFieldingOaaRow) => {
			if (row.fieldingOaaByPosition === undefined) return NULL_FILLER_TEXT;
			const oaa: number | typeof NULL_FILLER_TEXT = getOaaFromStatsPlayerFielding(
				row.fieldingOaaByPosition[position],
				position === _1B
			);
			return oaa === NULL_FILLER_TEXT ? oaa : round10(oaa, -1);
		},
		getSortValueFunction: (row: TFieldingOaaRow) =>
			getOaaFromStatsPlayerFielding(row.fieldingOaaByPosition[position], position === _1B),
		getColorGradientValueFunction: (row: TFieldingOaaRow) => {
			if (row.fieldingOaaByPosition === undefined) return null;
			const proportion: number | null = getPositionProportionFromStatsPlayerFielding(
				row.fieldingOaaByPosition[position],
				position === _1B
			);
			if (proportion !== null && proportion < 0.1) return null;
			return getOaaFromStatsPlayerFielding(row.fieldingOaaByPosition[position], position === _1B);
		},
		colorGradientFunction: COLOR_GRADIENT_FUNCTION_BLUE_GREEN
	};
};

const createPositionGroupColumns = (position: string): Array<TColumn<TFieldingOaaRow, keyof TFieldingOaaRow>> => {
	return [createInningsColumn(position), createOppColumn(position), createOaaColumn(position)];
};

export const createPositionToColumnsMapping = (positions: Array<string>) => {
	const positionToColumnMapping: Record<string, Array<TColumn<TFieldingOaaRow, keyof TFieldingOaaRow>>> = {};
	positions.forEach((position: string) => (positionToColumnMapping[position] = createPositionGroupColumns(position)));
	return positionToColumnMapping;
};

export const createPositionsToParentColumnsMapping = (positions: Array<string>): Array<TParentColumn> => {
	return positions.map((position: string) => {
		return {
			label: position.toLocaleUpperCase(),
			id: position,
			childColumnIds: [getInningColumnId(position), getOpportunityColumnId(position), getOaaColumnId(position)]
		};
	});
};

// Helper functions to get data for mobile table
export const createInningsColumnMobile = (): TColumn<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile> => {
	return {
		value: `innMobile`,
		id: `inn_mobile`,
		label: `Innings`,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		isMobile: true,
		getValueFunction: (row: TFieldingOaaRowMobile) => {
			const innings = getInningsFromStatsPlayerFielding(row.fieldingOaa);
			return innings === NULL_FILLER_TEXT ? innings : round10(innings, -1);
		},
		getSortValueFunction: (row: TFieldingOaaRowMobile) => getInningsFromStatsPlayerFielding(row.fieldingOaa)
	};
};

export const createOppColumnMobile = (): TColumn<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile> => {
	return {
		value: `oppMobile`,
		id: `opp_mobile`,
		label: `Opportunities`,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		isMobile: true,
		getValueFunction: (row: TFieldingOaaRowMobile) => {
			const opportunities: number | typeof NULL_FILLER_TEXT = getOpportunitiesFromStatsPlayerFielding(
				row.fieldingOaa,
				row.position === _1B
			);
			return opportunities === NULL_FILLER_TEXT ? opportunities : Math.round(opportunities).toString();
		},
		getSortValueFunction: (row: TFieldingOaaRowMobile) =>
			getOpportunitiesFromStatsPlayerFielding(row.fieldingOaa, row.position === _1B)
	};
};
