/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

export type $TSFixMe = any;
export type $TSFixMeTeam = any;
export type $TSFixMePlayer = any;
export type $TSFixMeGame = any;
export type $TSFixMeComponent = any;
export type $TSAnyRequired = any;

export type T_DBs = "phil_data" | "mesa" | "collegesplits" | "lk";

// TODO: lk_iam_object_type table should have these and should maybe have a col for category
export type TObjType =
	| "LIST"
	| "AMA_EVAL_FRE"
	| "AMA_EVAL_FOL"
	| "EVAL"
	| "EVENT"
	| "DRAFT_PANEL"
	| "MOCKDRAFT_PANEL"
	| "MOCKDRAFT_PICKS"
	| "AMA_MEDICAL"
	// Legacy Batting Advance
	| "BATTING_ADVANCE_SHEET"
	| "BATTING_ADVANCE_SHEET_NOTES"
	// New Batting Advance
	| "BATTING_ADVANCE_SHEET_EDIT_ML"
	| "BATTING_ADVANCE_SHEET_EDIT_PD";
export type TPermType = "READ" | "WRITE" | "EXECUTE";

export type TPerm = {
	permType: TPermType;
	objType: TObjType;
};

export interface IDefaultAction<TAction, TPayload> {
	type: TAction;
	payload: TPayload;
}
export interface IDefaultActionNoPayload<TAction> {
	type: TAction;
}
export interface IKeyDefaultAction<TAction, TPayload, TKey> {
	type: TAction;
	payload: TPayload;
	key: TKey;
}

export interface IReducer<TState, TAction> {
	(state: TState, action: TAction): TState;
}
export interface IKeyValPayload<TKey, TValue> {
	key: TKey;
	value: TValue;
}

export interface IDefaultInput {
	target: { [index: string]: $TSFixMe };
}

export type TKeyDown = { preventDefault: () => void; key: string };

export type TOptionalExceptFor<T, TRequired extends keyof T> = Partial<T> & Pick<T, TRequired>;

export type Style = React.CSSProperties;
export type TPartialStyles<T extends keyof Style> = Partial<Pick<Style, T>>;
export type TPickStyles<T extends keyof Style> = Required<Pick<Style, T>>;

export const AMA = "AMA" as const;
export const INTL = "INTL" as const;
export const PRO = "PRO" as const;
export type TCustomPlayerClassification = typeof AMA | typeof INTL;
export type TPlayerClassification = TCustomPlayerClassification | typeof PRO;

export type TPlayerType = "BATTER" | "PITCHER" | "BOTH";

export type TPlayerHandedness = "L" | "R" | "S";

export type TJsonMessage = { success: boolean; message: string };
