import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const tables = [
	{
		title: "Present Hitting Ability",
		subTitle:
			"Descriptions are in comparison to their peer group, which are draftable prospects. All descriptions describe a player’s first full season.",
		columns: ["Scale", "Description", "AVG", "K%"],
		rows: [
			[
				"20",
				"Will be overmatched. Probable 2 yr rookie ball. Swing/approach overhaul is necessary. May lack strength necessary to hit.",
				">.190",
				">40%"
			],
			[
				"30",
				"Will struggle. Below average AB's with infrequent hard contact.  Swing/approach changes will be necessary. May lack strength necessary to hit.",
				".200-.230",
				"30%"
			],
			[
				"40",
				"Will compete, but have ups and downs. Has positive attributes, but lacks consistency. More than likely to need swing/approach/strength improvements to reach ceiling.",
				".230-.250",
				"25%"
			],
			[
				"50",
				"Will be solid. Has combination of hit, swing and Ab's. Will require standard swing/approach adjustments along the way to reach ceiling. Strength may/may not be a factor.",
				".260-.280",
				"20%"
			],
			[
				"60",
				"Will excel. Quality hitting attributes w/ a history of success. Will go through standard swing/approach changes, but not 100% necessary to reach ceiling. Strength likely not a factor.",
				".280-.300",
				"15%"
			],
			[
				"70",
				"Premium hitter. Minimal swing/approach changes. Advanced hitting attributes that aid ability to hit and make hard contact. Strength not a factor.",
				".300-.330",
				"10%"
			],
			[
				"80",
				"Will overmatch. Elite combination of hitting tools, talent, swing and performance. Best hitter in league with all positive attributes. Strength not a factor.",
				".330+",
				"5%"
			]
		]
	},
	{
		title: "Future Hitting Ability",
		columns: ["Scale", "AVG", "BB%", "K%"],
		rows: [
			["20", ".165", "0%", "41%"],
			["30", ".195", "3%", "35%"],
			["40", ".220", "6%", "29%"],
			["45", ".235", "7%", "26%"],
			["50", ".250", "9%", "23%"],
			["55", ".265", "10%", "19%"],
			["60", ".280", "12%", "16%"],
			["70", ".305", "15%", "10%"],
			["80", ".335", "17%", "4%"]
		]
	},
	{
		title: "Future Power Production",
		columns: ["Scale", "HR/600", "SLG", "ISO"],
		rows: [
			["20", "0", ".215", ".005"],
			["30", "1", ".285", ".060"],
			["40", "10", ".350", ".115"],
			["45", "15", ".385", ".140"],
			["50", "20", ".420", ".170"],
			["55", "25", ".455", ".195"],
			["60", "30", ".485", ".225"],
			["70", "38", ".555", ".275"],
			["80", "45", ".620", ".330"]
		]
	},
	{
		title: "Hitter Descriptions",
		columns: ["Grade", "AB Quality", "Bat to Ball"],
		rows: [
			[
				"20",
				"Seldom gets good pitch to hit. Hits early in counts and swings at pitches 'out of hand'. Flinches or buckles against most all breaking pitches. Lots of head movement.",
				"Swing and Miss in FB in hitters’ counts. Pull approach, abundant holes. Bat plane is the same no matter the location of the pitch. Bat ‘sweeps’ thru the zone. Bat is in/out of zone. Often 'fooled' with swing and miss result. Overmatched by fringe to average velocity. Rare contact vs secondary regardless of quality."
			],
			[
				"40",
				"Tries to get good pitch to hit. Loses discipline behind in counts. Doesn't let off speed travel. Tends to chase vs average pitching behind in count. Flinches or buckles against average breaking pitches. Has head movement that impacts ability to see the ball 'out of hand'",
				"Regular contact in advantage/FB counts. MOF approach suffers vs average competition.  Swing path is inconsistent. Doesn't show great extension on thru swing. Often 'fooled' with swing and miss result. Struggles vs average velocity. Inconsistent contact vs mistake breaking ball."
			],
			[
				"50",
				"More often than not gets good pitch to hit. Disciplined approach early in count, fights for contact behind. Occasional chase vs average pitching behind in count. Takes average breaking balls with balance. Is able to recognize and attack hanging breaking pitches. Occ flinches on plus breaking pitches. Head movement is stable.",
				"Regular hard contact in advantage/FB counts. MOF approach sometimes suffers vs quality comp. Shows length thru the zone with swing. Occasionally 'fooled' with swing and miss result. Good FB hitter vs average velocity. Regular hard contact vs mistake breaking ball.  Shows situational hitting ability"
			],
			[
				"60",
				"Typically gets good pitch to hit. Lets ball travel very well, especially early in cnts. Fights off pitches behind in cnt, is on FB regardless of cnt. Seldom chases vs ave+ pitching. Takes most breaking balls with good balance. Recognizes good breaking ball and can lay off most dirt balls. Stays back and drives hanging breaking balls. Head is still.",
				"Consistent hard contact in advantage/FB counts. MOF approach sometimes suffers vs quality comp. Good length and extension thru zone. When 'fooled' makes FB hitter regardless of velo. Consistent hard contact vs mistake breaking ball. Good situational hitter."
			],
			[
				"80",
				"Always seems to get good pitch to hit, 'always in hitters cnt'. Lets ball travel/get deep. Doesn't miss FB and is on good BB. Appears to 'give up' on pitches early like he knew it was coming. Rare chase vs top flight pitching.  Rarely 'fooled'. Sees the ball early, evidenced by 'EZ' takes. Recognizes good breaking balls and has very rare chases in dirt. Punishes hanging breaking balls. Still head with great balance.",
				"Consistent hard contact regardless of competition level. MOF approach vs quality competition. Excellent thru length and extension on swing. When he is 'fooled' the barrel finds the ball. Does not miss FB's. Regardless of velo he's on it. Squares up ave secondary pitches, punishes mistakes. Is able to square up any type of pitch. Spoils ‘pitchers pitches’"
			]
		]
	}
];

const AmaHittingTooltip = () => {
	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<div
					style={{
						overflow: "scroll"
					}}
				>
					{tables.map((table, idx) => {
						return (
							<Table key={idx} style={{ marginTop: "25px" }}>
								<TableHead
									style={{
										backgroundColor: defaultColorScheme.group.backgroundColor
									}}
								>
									<TableRow>
										<TableCell
											colSpan={table.columns.length}
											style={{
												color: defaultColorScheme.group.color,
												fontSize: "16px",
												textAlign: "center"
											}}
										>
											{table.title}
										</TableCell>
									</TableRow>
									{table.subTitle != null && (
										<TableRow>
											<TableCell
												colSpan={table.columns.length}
												style={{
													color: defaultColorScheme.group.color,
													fontSize: "12px",
													textAlign: "center"
												}}
											>
												{table.subTitle}
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										{table.columns.map((column, idx) => {
											return (
												<TableCell
													key={idx}
													style={{
														color: defaultColorScheme.group.color,
														textAlign: "center",
														textTransform: "capitalize"
													}}
												>
													{column}
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
								<TableBody
									style={{
										border: "1px solid #dfe0df"
									}}
								>
									{table.rows.map((row, idx) => {
										return (
											<TableRow key={idx}>
												{row.map((cell, idx) => {
													return (
														<TableCell
															key={idx}
															style={{
																textAlign: "center",
																fontWeight: idx === 0 ? "700" : ""
															}}
														>
															{cell}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default AmaHittingTooltip;
