export const GAME_TYPE_R = "R";
export const GAME_TYPE_F = "F";
export const GAME_TYPE_D = "D";
export const GAME_TYPE_L = "L";
export const GAME_TYPE_W = "W";
export const GAME_TYPE_S = "S";

export const VALID_GAME_TYPES = [GAME_TYPE_R, GAME_TYPE_F, GAME_TYPE_D, GAME_TYPE_L, GAME_TYPE_W, GAME_TYPE_S];

export const LEVEL_MLB = "MLB";
export const LEVEL_AAA = "AAA";
export const LEVEL_AA = "AA";
export const LEVEL_A_PLUS = "A+";
export const LEVEL_A = "A";
export const LEVEL_A_MINUS = "A-";
export const LEVEL_RK = "Rk";
export const LEVEL_FRK = "FRk";
export const VALID_PRO_LEVELS = [
	LEVEL_MLB,
	LEVEL_AAA,
	LEVEL_AA,
	LEVEL_A_PLUS,
	LEVEL_A,
	LEVEL_A_MINUS,
	LEVEL_RK,
	LEVEL_FRK
];

export const SOURCE_GUMBO = "gumbo";
export const SOURCE_STATSAPI = "statsapi.mlb.com";

export const AVERAGE_AGE_PLAYER_TYPE_BATTER = "BATTER";
export const AVERAGE_AGE_PLAYER_TYPE_PITCHER = "PITCHER";
