import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import {
	PHILLIES_SEASON_END_DEFAULT_DATE,
	PHILLIES_SEASON_END_IMPORTANT_DATE_TYPE,
	REGULAR_SEASON_START_IMPORTANT_DATE_TYPE
} from "_react/shared/data_models/important_date/_constants";
import { TImportantDateGetQueryParams, fetchImportantDate } from "_react/shared/data_models/important_date/_network";
import { IImportantDate } from "_react/shared/data_models/important_date/_types";

export const useImportantDate = (
	type: string,
	season = dayjs().year(),
	level = "MLB"
): [Dayjs | undefined, boolean] => {
	const [lastQueryParams, setLastQueryParams] = useState<TImportantDateGetQueryParams>({});
	const [importantDate, setImportantDate] = useState<Dayjs | undefined>();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [hasFetched, setHasFetched] = useState<boolean>(false);

	useEffect(() => {
		// If not fetching and the function params do not match the last used query params
		if (
			!isFetching &&
			(type !== lastQueryParams.type || season !== lastQueryParams.season || level !== lastQueryParams.level)
		) {
			const queryParams: TImportantDateGetQueryParams = {
				type: type,
				season: season,
				level: level
			};
			setIsFetching(true);
			fetchImportantDate(queryParams).then((importantDates: Array<IImportantDate> | undefined) => {
				setImportantDate(importantDates?.length ? dayjs(importantDates[0].date) : undefined);
				setLastQueryParams(queryParams);
				setIsFetching(false);
				setHasFetched(true);
			});
		}
	}, [type, season, level, lastQueryParams, isFetching]);

	return [importantDate, isFetching || !hasFetched];
};

export const useListYear = (): [number, boolean] => {
	const now = dayjs();
	const [listChangeover, isFetching] = useImportantDate("list_year_changeover");
	const listChangeoverDate = listChangeover ?? now;
	if (now.isAfter(listChangeoverDate)) {
		return [now.year() + 1, isFetching];
	}
	return [now.year(), isFetching];
};

export const useProjectionsSeason = (): [number, number, boolean] => {
	const [philliesSeasonEndDate, isFetchingPhilliesSeasonEnd] = useImportantDate(
		PHILLIES_SEASON_END_IMPORTANT_DATE_TYPE
	);
	const [regularSeasonStartDate, isFetchingRegularSeasonStart] = useImportantDate(
		REGULAR_SEASON_START_IMPORTANT_DATE_TYPE
	);
	const today = dayjs();
	const currentYear = today.year();
	// If today is after the phillies season end date, then use next season's projections
	const season =
		(philliesSeasonEndDate ?? dayjs(PHILLIES_SEASON_END_DEFAULT_DATE)) <= today ? currentYear + 1 : currentYear;
	// If today is between the regular season start and the phillies season end, then use "rest of season" projections
	const ros =
		regularSeasonStartDate !== undefined &&
		today >= regularSeasonStartDate &&
		(philliesSeasonEndDate === undefined || today <= philliesSeasonEndDate)
			? 1
			: 0;
	const isFetching = isFetchingPhilliesSeasonEnd || isFetchingRegularSeasonStart;
	return [season, ros, isFetching];
};

export const useCurrentSeason = (): [number | undefined, boolean] => {
	const [regularSeasonStart, isFetchingRegularSeasonStart] = useImportantDate(
		REGULAR_SEASON_START_IMPORTANT_DATE_TYPE
	);
	const currentSeason: number | undefined = regularSeasonStart
		? dayjs().isSameOrAfter(regularSeasonStart, "day")
			? regularSeasonStart.year()
			: regularSeasonStart.year() - 1
		: isFetchingRegularSeasonStart
		? undefined
		: dayjs().year();
	return [currentSeason, isFetchingRegularSeasonStart];
};
