import React, { useMemo, useEffect } from "react";
import { useToast, VStack, HStack, Box, IconButton, Tooltip } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import Leaderboard from "_react/shared/ui/icons/Leaderboard";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";
import { TToggleOption } from "_react/shared/ui/presentation/components/ToggleButton/_types";
import {
	TSimpleHitterProjections,
	TSimplePitcherProjections,
	TSimpleProjections
} from "_react/shared/data_models/projections/_types";
import { POSITION_GROUPS_PITCHER } from "_react/shared/_constants/position_groups";
import { useProjectionsSeason } from "_react/shared/_helpers/hooks";

import {
	PROJECTIONS_PITCHERS_COLUMNS,
	PROJECTIONS_HITTERS_COLUMNS
} from "_react/shared/ui/data/tables/SimilarPlayersProjectionTable/_constants";
import SimilarPlayersProjectionTableMachine, {
	TSimilarPlayersProjectionTableContext,
	FETCHING_REPLACEMENT_LEVEL,
	FETCHING_SIMILAR_PLAYERS,
	SET_FILTERS,
	SET_SIMPLE_PROJECTIONS,
	SET_SEASON,
	SET_ROS
} from "_react/shared/ui/data/tables/SimilarPlayersProjectionTable/_machine";
import {
	TSimilarPlayersFilters,
	TSimilarPlayersToggleOptions,
	TSimilarPlayersProjectionTableData
} from "_react/shared/ui/data/tables/SimilarPlayersProjectionTable/_types";

type TSimilarPlayersProjectionTableProps = {
	title?: string;
	filters?: TSimilarPlayersFilters;
	data?: TSimilarPlayersProjectionTableData;
	columns?: Array<string>;
	toggleOptions?: TSimilarPlayersToggleOptions;
	shouldFetchData?: boolean;
	tableProps?: TTableProps<TSimpleProjections, keyof TSimpleProjections>;
};

const SimilarPlayersProjectionTable = ({
	title,
	filters: filtersProp,
	data,
	columns,
	toggleOptions,
	shouldFetchData = true,
	tableProps
}: TSimilarPlayersProjectionTableProps) => {
	const toast = useToast();
	const [projectionsSeason, ros, isFetchingProjectionsSeason] = useProjectionsSeason();

	const [current, send] = useMachine(
		SimilarPlayersProjectionTableMachine(
			filtersProp,
			projectionsSeason,
			ros,
			data?.projections,
			shouldFetchData,
			toast
		)
	);
	const isFetchingSimilarPlayers = current.matches(FETCHING_SIMILAR_PLAYERS);
	const isFetchingReplacementLevel = current.matches(FETCHING_REPLACEMENT_LEVEL);
	const context = current.context as TSimilarPlayersProjectionTableContext;
	const { filters, simpleProjections, replacementLevel } = context;

	useEffect(() => {
		if (!isFetchingProjectionsSeason) send({ type: SET_SEASON, value: projectionsSeason });
	}, [projectionsSeason, isFetchingProjectionsSeason, send]);

	useEffect(() => {
		send({ type: SET_ROS, value: ros });
	}, [ros, send]);

	useEffect(() => {
		if (data?.projections !== simpleProjections && shouldFetchData === false)
			send({ type: SET_SIMPLE_PROJECTIONS, value: data?.projections });
	}, [send, data, simpleProjections, shouldFetchData]);

	useEffect(() => {
		if (filtersProp) send({ type: SET_FILTERS, value: filtersProp });
	}, [filtersProp, send]);

	const simpleProjectionsWithReplacementLevel = useMemo(() => {
		if (!simpleProjections) return simpleProjections;
		return simpleProjections.map((projection: TSimpleProjections) => ({
			...projection,
			replacementLevelActive: replacementLevel ?? undefined
		}));
	}, [simpleProjections, replacementLevel]);

	const filteredColumns = useMemo(() => {
		if (filters && POSITION_GROUPS_PITCHER.includes(filters.positionGroup)) {
			if (!columns) return PROJECTIONS_PITCHERS_COLUMNS;
			return PROJECTIONS_PITCHERS_COLUMNS.filter(
				(col: TColumn<TSimplePitcherProjections, keyof TSimplePitcherProjections>) =>
					columns.includes(col.value)
			);
		}
		if (!columns) return PROJECTIONS_HITTERS_COLUMNS;
		return PROJECTIONS_HITTERS_COLUMNS.filter(
			(col: TColumn<TSimpleHitterProjections, keyof TSimpleHitterProjections>) => columns.includes(col.value)
		);
	}, [columns, filters]);

	const toggleButtonOptions = useMemo(() => {
		const options: Array<TToggleOption<string>> = [];
		if (toggleOptions?.isFortyMan) options.push({ value: "isFortyMan", label: "40 Man" });
		if (toggleOptions?.isPhillies) options.push({ value: "isPhillies", label: "PHI" });
		if (toggleOptions?.isSameOrg) options.push({ value: "isSameOrg", label: "Org" });
		if (toggleOptions?.isLeague) options.push({ value: "isLeague", label: "League" });
		return options;
	}, [toggleOptions]);

	const handleToggleButtonSelect = (value: string) => {
		if (!filters) return;
		const newFilters = {
			...filters,
			isFortyMan: value === "isFortyMan" ? true : undefined,
			isPhillies: value === "isPhillies" ? true : undefined,
			isSameOrg: value === "isFortyMan" || value === "isSameOrg" ? true : undefined
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start">
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
					<Tooltip hasArrow placement="top" label="Go to Projections Leaderboard">
						<IconButton
							aria-label="Go to projections leaderboard"
							variant="outline"
							size="xs"
							icon={<Leaderboard sx={{ color: "gray", height: 13, width: 13 }} />}
							onClick={() =>
								window.open(
									`../projections/pro/leaderboard?view=${
										filters && POSITION_GROUPS_PITCHER.includes(filters.positionGroup)
											? "pitcher"
											: "hitter"
									}`,
									"_blank"
								)
							}
						/>
					</Tooltip>
				</HStack>
				{toggleOptions && (
					<ToggleButton<string>
						toggleOptions={toggleButtonOptions}
						value={
							filters?.isFortyMan
								? "isFortyMan"
								: filters?.isSameOrg
								? "isSameOrg"
								: filters?.isPhillies
								? "isPhillies"
								: "isLeague"
						}
						onSelect={handleToggleButtonSelect}
						isDisabled={isFetchingSimilarPlayers}
					/>
				)}
			</HStack>
			<Table<TSimpleProjections, keyof TSimpleProjections>
				columns={filteredColumns as Array<TColumn<TSimpleProjections, keyof TSimpleProjections>>}
				data={simpleProjectionsWithReplacementLevel}
				emptyDataDisplayText={"No Similar Players Found"}
				isLoadingData={
					isFetchingSimilarPlayers ||
					isFetchingReplacementLevel ||
					isFetchingProjectionsSeason ||
					(!shouldFetchData && data?.isLoading)
				}
				highlightIndexFunction={(data: Array<TSimpleProjections>) => {
					return data.findIndex((proj: TSimpleProjections) => proj.player?.id === filters?.playerId);
				}}
				{...tableProps}
			/>
		</VStack>
	);
};

export default SimilarPlayersProjectionTable;
