import React from "react";
import { Box, HStack, Tooltip, VStack } from "@chakra-ui/react";
import { round10 } from "_react/shared/_helpers/numbers";
import OutlineWarning from "_react/shared/ui/icons/OutlineWarning";
import {
	NULL_FILLER_TEXT,
	NULL_FILLER_TEXT_COMPONENTS,
	OAA_OPPS_THRESHOLD
} from "_react/shared/ui/data/tables/AmaFieldingOaaTable/_constants";

type TExpandableOaaOppsProps = {
	totalOaaOpps?: number | null;
	componentOaaOpps?: Array<number | null | undefined>;
	isExpanded?: boolean;
};

const ExpandableOaaOpps = ({ totalOaaOpps, componentOaaOpps, isExpanded }: TExpandableOaaOppsProps) => {
	return (
		<VStack align="right" gap="0">
			<HStack justify="right" align="center">
				<>{totalOaaOpps ?? NULL_FILLER_TEXT}</>
				{(totalOaaOpps ?? OAA_OPPS_THRESHOLD) < OAA_OPPS_THRESHOLD ? (
					<Tooltip
						hasArrow
						label="OAA is not reliable when a player has fewer than 100 opportunities at that position."
					>
						<Box>
							<OutlineWarning color="gray.500" />
						</Box>
					</Tooltip>
				) : (
					<></>
				)}
			</HStack>
			{totalOaaOpps != null && isExpanded && (
				<Box width="100%" fontSize="xs" color="gray.600" fontFamily="body">
					{componentOaaOpps?.map((componentOaa: number | null | undefined, index: number) => {
						const value =
							componentOaa != null ? `${round10(componentOaa * 100, 0)}%` : NULL_FILLER_TEXT_COMPONENTS;
						const spacer = index !== componentOaaOpps.length - 1 ? " | " : "";
						return (
							<React.Fragment key={index}>
								{value}
								<Box as="span" color="gray.400">
									{spacer}
								</Box>
							</React.Fragment>
						);
					})}
				</Box>
			)}
		</VStack>
	);
};

export default ExpandableOaaOpps;
