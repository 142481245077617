import React, { useEffect, useMemo } from "react";
import { useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import { VStack, HStack, Box } from "@chakra-ui/react";
import dayjs from "dayjs";

import { BATS_OVERALL, PLAYING_LEVEL_AMA } from "_react/shared/data_models/arsenal_scores/_constants";
import {
	IMetricProbabilityDensity,
	IMetricProbabilityDensities,
	TMetricGroup
} from "_react/shared/data_models/metric/_types";
import { getStuffThresholdPitchTypesToDisplay } from "_react/shared/data_models/arsenal_scores/_helpers";
import {
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import { TPlayingLevel } from "_react/shared/data_models/hitter_grades/_types";
import { METRIC_GROUP_LABEL_MAP } from "_react/shared/ui/data/plots/PitcherMetricOverTimePlot/_constants";
import PitchMetricRidgelinePlot, {
	TXAxisExtremaProps
} from "_react/shared/ui/presentation/plots/PitchMetricRidgelinePlot/PitchMetricRidgelinePlot";

import createPitcherMetricRidgelinePlotMachine, {
	TPitcherMetricRidgelinePlotContext,
	TProbabilityDensities,
	SET_PROBABILITY_DENSITIES,
	SET_PLAYER_SEASON_ARSENAL_SCORES,
	SET_ARSENAL_SCORES_THRESHOLD,
	SET_PLAYER_ID,
	SET_PLAYER_CLASSIFICATION,
	SET_PLAYING_LEVEL,
	SET_METRIC_GROUP,
	FETCHING_PROBABILITY_DENSITIES,
	FETCHING_PLAYER_SEASON_ARSENAL_SCORES,
	FETCHING_ARSENAL_SCORES_THRESHOLD,
	SET_BATS_FILTER,
	SET_THROWS_FILTER,
	SET_SEASON_FILTER
} from "_react/shared/ui/data/plots/PitcherMetricRidgelinePlot/_machine";

export type TPitcherMetricRidgelinePrimaryPlotData = {
	probabilityDensities: TProbabilityDensities;
	metricGroup: TMetricGroup;
	isLoading?: boolean;
};

export type TPitcherMetricRidgelineSecondaryPlotData = {
	playerSeasonArsenalScores: Array<IPlayerSeasonArsenalScoresSchema> | undefined;
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	isLoading?: boolean;
};

type TPitcherMetricRidgelinePlotStyle = {
	container?: React.CSSProperties;
};

type TPitcherMetricRidgelinePlotProps = {
	title?: string;
	playerId?: number;
	playerClassification?: string;
	playingLevel: TPlayingLevel;
	metricGroup: TMetricGroup;
	primaryData?: TPitcherMetricRidgelinePrimaryPlotData;
	shouldFetchPrimaryData?: boolean;
	secondaryData?: TPitcherMetricRidgelineSecondaryPlotData;
	shouldFetchSecondaryData?: boolean;
	batsFilter?: string;
	throwsFilter?: string;
	seasonFilter?: number;
	width?: number;
	xAxisExtrema?: TXAxisExtremaProps;
	style?: TPitcherMetricRidgelinePlotStyle;
};

const PitcherMetricRidgelinePlot = ({
	title,
	playerId,
	playerClassification,
	playingLevel,
	metricGroup,
	primaryData,
	shouldFetchPrimaryData = true,
	secondaryData,
	shouldFetchSecondaryData = true,
	batsFilter = BATS_OVERALL,
	throwsFilter,
	seasonFilter = dayjs().year(),
	width,
	xAxisExtrema,
	style
}: TPitcherMetricRidgelinePlotProps) => {
	const toast = useToast();
	const [current, send] = useMachine(
		createPitcherMetricRidgelinePlotMachine(
			batsFilter,
			seasonFilter,
			throwsFilter,
			xAxisExtrema,
			playerId,
			playerClassification,
			playingLevel,
			metricGroup,
			shouldFetchPrimaryData,
			primaryData,
			shouldFetchSecondaryData,
			secondaryData,
			toast
		)
	);
	const {
		probabilityDensities,
		playerSeasonArsenalScores,
		arsenalScoresThreshold
	} = current.context as TPitcherMetricRidgelinePlotContext;

	const fetchingProbabilityDensities: boolean = current.matches(FETCHING_PROBABILITY_DENSITIES);
	const fetchingPlayerSeasonArsenalScores: boolean = current.matches(FETCHING_PLAYER_SEASON_ARSENAL_SCORES);
	const fetchingArsenalScoresThreshold: boolean = current.matches(FETCHING_ARSENAL_SCORES_THRESHOLD);
	const isLoadingPrimary: boolean = shouldFetchPrimaryData
		? fetchingProbabilityDensities
		: primaryData?.isLoading ?? false;

	const isLoadingSecondary: boolean = shouldFetchSecondaryData
		? fetchingPlayerSeasonArsenalScores || (playingLevel === PLAYING_LEVEL_AMA && fetchingArsenalScoresThreshold)
		: secondaryData?.isLoading ?? false;

	const isLoading = isLoadingPrimary || isLoadingSecondary;

	// Update machine context when data prop changes
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_PLAYER_CLASSIFICATION, data: playerClassification });
	}, [playerClassification, send]);

	useEffect(() => {
		send({ type: SET_PLAYING_LEVEL, data: playingLevel });
	}, [playingLevel, send]);

	useEffect(() => {
		send({ type: SET_METRIC_GROUP, data: metricGroup });
	}, [metricGroup, send]);

	useEffect(() => {
		send({ type: SET_BATS_FILTER, data: batsFilter });
	}, [batsFilter, send]);

	useEffect(() => {
		send({ type: SET_THROWS_FILTER, data: throwsFilter });
	}, [throwsFilter, send]);

	useEffect(() => {
		send({ type: SET_SEASON_FILTER, data: seasonFilter });
	}, [seasonFilter, send]);

	useEffect(() => {
		if (primaryData?.probabilityDensities !== probabilityDensities && !shouldFetchPrimaryData) {
			send({ type: SET_PROBABILITY_DENSITIES, data: primaryData?.probabilityDensities ?? {} });
		}
	}, [primaryData?.probabilityDensities, probabilityDensities, shouldFetchPrimaryData, send]);
	useEffect(() => {
		if (secondaryData?.playerSeasonArsenalScores !== playerSeasonArsenalScores && !shouldFetchSecondaryData) {
			send({ type: SET_PLAYER_SEASON_ARSENAL_SCORES, data: secondaryData?.playerSeasonArsenalScores });
		}
	}, [secondaryData?.playerSeasonArsenalScores, playerSeasonArsenalScores, shouldFetchSecondaryData, send]);
	useEffect(() => {
		if (secondaryData?.arsenalScoresThreshold !== arsenalScoresThreshold && !shouldFetchSecondaryData) {
			send({ type: SET_ARSENAL_SCORES_THRESHOLD, data: secondaryData?.arsenalScoresThreshold });
		}
	}, [secondaryData?.arsenalScoresThreshold, arsenalScoresThreshold, shouldFetchSecondaryData, send]);

	const pitchTypesToDisplay: Array<string> | undefined = useMemo(() => {
		return getStuffThresholdPitchTypesToDisplay(
			batsFilter,
			throwsFilter,
			arsenalScoresThreshold,
			playerSeasonArsenalScores
		);
	}, [playerSeasonArsenalScores, arsenalScoresThreshold, batsFilter, throwsFilter]);

	const distributionData: { [index: string]: Array<IMetricProbabilityDensity> } = useMemo(() => {
		const probabilityDensityRecord: { [index: string]: Array<IMetricProbabilityDensity> } = {};
		probabilityDensities[`${batsFilter}-${throwsFilter}`]?.forEach(
			(metricProbabilityDensities: IMetricProbabilityDensities) => {
				const pitchType = metricProbabilityDensities?.requestArgs?.pitchType;
				if (
					pitchType &&
					(!pitchTypesToDisplay || pitchTypesToDisplay?.includes(pitchType)) &&
					metricProbabilityDensities?.probabilityDensities?.length
				)
					probabilityDensityRecord[pitchType] = metricProbabilityDensities.probabilityDensities;
			}
		);
		return probabilityDensityRecord;
	}, [probabilityDensities, batsFilter, throwsFilter, pitchTypesToDisplay]);

	const averageReleaseVeloData: { [index: string]: number } = useMemo(() => {
		const releaseVeloRecord: { [index: string]: number } = {};
		playerSeasonArsenalScores?.forEach((arsenalScore: IPlayerSeasonArsenalScoresSchema) => {
			if (
				(throwsFilter === undefined || arsenalScore.throws === throwsFilter) &&
				arsenalScore.bats === batsFilter &&
				arsenalScore.releaseVelo
			) {
				releaseVeloRecord[arsenalScore.pitchType] = arsenalScore.releaseVelo;
			}
		});
		return releaseVeloRecord;
	}, [playerSeasonArsenalScores, throwsFilter, batsFilter]);

	return (
		<>
			{isLoading && <Box className="loading-item" height="xs" width="lg" sx={style?.container} />}
			{!isLoading && (
				<VStack align="start" sx={style?.container}>
					<HStack w="100%" justify="start">
						{title && (
							<Box fontFamily="heading" fontSize="md" fontWeight="bold">
								{title}
							</Box>
						)}
					</HStack>
					<PitchMetricRidgelinePlot<IMetricProbabilityDensity>
						distributionData={distributionData}
						distributionX="value"
						distributionY="density"
						averageReleaseVeloData={averageReleaseVeloData}
						xAxis={{
							xLabel: `${batsFilter === BATS_OVERALL ? "Overall" : `v${batsFilter}HB`} ${
								METRIC_GROUP_LABEL_MAP[metricGroup]
							}`,
							extrema: xAxisExtrema
						}}
						width={width}
						backgroundColor="transparent"
					/>
				</VStack>
			)}
		</>
	);
};

export default PitcherMetricRidgelinePlot;
