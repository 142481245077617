import React from "react";
import { $TSFixMe } from "utils/tsutils";
import HoverButton from "_react/video/VideoUploader/HoverButton";

type FileUploadClipProps = {
	children: $TSFixMe;
	clipId: $TSFixMe;
	handleSelect: Function;
	header: $TSFixMe;
	selected: boolean;
};

function FileUploadClip({ children, clipId, header, handleSelect, selected }: FileUploadClipProps) {
	return (
		<HoverButton
			render={(hover: boolean) => (
				<React.Fragment>
					{header}
					<video
						controls
						onClick={e => handleSelect(e)}
						src={clipId}
						style={{
							width: "auto",
							height: "auto",
							maxWidth: "100%",
							maxHeight: "100%",
							opacity: hover ? 1 : 0.5,
							border: selected ? "3px solid yellow" : "",
							cursor: "pointer"
						}}
						tabIndex={-1}
					/>
					{children}
				</React.Fragment>
			)}
			style={{
				display: "inline-block",
				maxWidth: "calc(50% - 20px)",
				maxHeight: "calc(60% - 45px)",
				marginBottom: "5px",
				marginRight: "10px",
				marginLeft: "10px",
				marginTop: "40px"
			}}
		/>
	);
}

export default FileUploadClip;
