import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function ExpandMore(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 96 960 960" {...props}>
			<path fill="currentColor" d="M480 711 240 471l56-56 184 184 184-184 56 56-240 240Z" />
		</Icon>
	);
}
