import React from "react";
import PropTypes from "prop-types";

import Form from "_react/forms/Form";
import { getProspectType } from "_react/evals/helpers";

import { getEvalTemplate, isEvalDateField, formatEvalDateField, computeTotalFields } from "_react/evals/ama/_helpers";
import Info from "_react/shared/legacy/ui/icons/Info";
import { Dialog } from "_react/shared/legacy/ui/Dialog";
import ProPitcherToolsTooltip from "_react/evals/pro/tooltips/ProPitcherToolsTooltip";
import ProPlayerToolsTooltip from "_react/evals/pro/tooltips/ProPlayerToolsTooltip";
import EvaluationTooltip from "_react/evals/EvaluationTooltip";
import GroupOfpTooltipHitting from "_react/evals/pro/tooltips/GroupOfpTooltipHitting";
import GroupOfpTooltipPitching from "_react/evals/pro/tooltips/GroupOfpTooltipPitching";
import PhysicalTooltip from "_react/evals/pro/tooltips/PhysicalTooltip";
import AmaPitchingTooltip from "_react/evals/pro/tooltips/AmaPitchingTooltip";
import FastballTooltip from "_react/evals/pro/tooltips/FastballTooltip";
import SecondaryPitchesTooltip from "_react/evals/pro/tooltips/SecondaryPitchesTooltip";
import FieldingTooltip from "_react/evals/pro/tooltips/FieldingTooltip";
import PhysicalHittingTooltip from "_react/evals/pro/tooltips/PhysicalHittingTooltip";
import AmaHittingTooltip from "_react/evals/pro/tooltips/AmaHittingTooltip";
import AmaEvalWrapperFooter from "_react/evals/ama/AmaEvalWrapperFooter";

// eslint-disable-next-line no-unused-vars
class AmaEval extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showingTGSTooltip: false
		};
	}

	onSave = value => {
		const { evaluation } = this.props;

		// Manage Other Pitch
		if (value.id === "oth_pitch_type") {
			this.setState({ extraPitch: value.value });
		}

		// Manage Adding Team
		if (value.id === "team_id" && value.value === "addNewTeam") {
			this.setState({ addingTeam: true });
			return;
		}

		// TODO: this is a hacky fix
		if (value.id.includes("vel")) {
			if (!value.id.includes("present") && !value.id.includes("future") && !value.id.includes("comment")) {
				value.value = value.value === "" ? null : value.value;
			}
		}

		// Correct Date Field Formats
		if (isEvalDateField(value.id)) value.value = formatEvalDateField(value.value);

		if (evaluation[value.id] !== value.value) {
			// Create Update Object
			const evaluationUpdateObject = {
				eval_create_user_id: evaluation.eval_create_user_id,
				eval_id: evaluation.eval_id,
				eval_type: evaluation.eval_type,
				phil_id: evaluation.phil_id,
				[value.id]: value.value
			};

			if (value.id === "ofp") {
				evaluationUpdateObject["prospect_type"] = getProspectType(value.value);
			}

			// Manage Total Fields
			computeTotalFields(evaluation, evaluationUpdateObject, value);

			this.props.updatePlayerEval(evaluationUpdateObject);
		}
	};

	triggerSave = () => {
		return this.form.getWrappedInstance().triggerSave();
	};

	handleTeamAdded = team => {
		const updatedEvaluationRecord = {
			id: "team_id",
			value: team ? team.team_id : null
		};

		// Update the player
		this.onSave(updatedEvaluationRecord);
	};

	updateAdornmentState = (type, open) => {
		this.setState({ [type]: open });
	};

	getStartAdornment = type => {
		return (
			<div>
				<Info
					onClick={() => this.setState({ [type]: true })}
					style={{ cursor: "pointer", marginRight: "5px" }}
				/>
				<Dialog
					header="Evaluation Reference"
					onClose={() => this.setState({ [type]: false })}
					open={Boolean(this.state[type])}
				>
					<div style={{ textAlign: "center" }}>
						{type === "group_ofp_hitting" && <GroupOfpTooltipHitting />}
						{type === "group_ofp_pitching" && <GroupOfpTooltipPitching />}
						{type === "physical" && <PhysicalTooltip />}
						{type === "pitching" && <AmaPitchingTooltip />}
						{type === "fastball" && <FastballTooltip />}
						{type === "secondary_pitches" && <SecondaryPitchesTooltip />}
						{type === "fielding" && <FieldingTooltip />}
						{type === "physical_hitting" && <PhysicalHittingTooltip />}
						{type === "hitting" && <AmaHittingTooltip />}

						{type === "AMA_PITCH" && <ProPitcherToolsTooltip />}
						{type === "AMA_HIT" && (
							<div>
								<ProPlayerToolsTooltip type={"offense"} />
								<ProPlayerToolsTooltip type={"defense"} />
							</div>
						)}
						{type !== "AMA_PITCH" && type !== "AMA_HIT" && (
							<EvaluationTooltip isMobile={this.props.isMobile} type={type} />
						)}
					</div>
				</Dialog>
			</div>
		);
	};

	render() {
		const {
			addListPlayerOnPublish,
			adminIds,
			evaluation,
			userId,
			player,
			publishEval,
			deletePlayerEval,
			updatePlayerEval,
			isEvalSaving,
			convertEvaluation,
			isEvalSaveError,
			getPlayerScoutEvalsFromEvalId,
			scoutLastName,
			isEvalManager
		} = this.props;

		const canEdit =
			(evaluation.eval_create_user_id === userId && evaluation.publish_date == null) || adminIds.includes(userId);

		const editing = evaluation.eval_create_user_id === userId && evaluation.publish_date == null;

		const configuration = getEvalTemplate(evaluation, this.getStartAdornment);

		return (
			<div style={{ paddingBottom: "60px" }}>
				<Form
					configuration={configuration}
					dataSource={evaluation}
					editing={isEvalSaveError ? false : editing}
					onSave={this.onSave}
					ref={node => (this.form = node)}
					showEditButtons={canEdit}
				/>
				<AmaEvalWrapperFooter
					addListPlayerOnPublish={addListPlayerOnPublish}
					convertEvaluation={convertEvaluation}
					deletePlayerEval={deletePlayerEval}
					evaluation={evaluation}
					getPlayerScoutEvalsFromEvalId={getPlayerScoutEvalsFromEvalId}
					isEvalManager={isEvalManager}
					key={2}
					player={player}
					scoutLastName={scoutLastName}
					triggerSave={() => {
						if (this.evaluationForm) {
							return this.evaluationForm.triggerSave();
						}
						return false;
					}}
					publishEval={publishEval}
					updatePlayerEval={updatePlayerEval}
					isEvalSaving={isEvalSaving}
					userId={userId}
				/>
			</div>
		);
	}
}

AmaEval.propTypes = {
	addListPlayerOnPublish: PropTypes.func,
	adminIds: PropTypes.array,
	convertEvaluation: PropTypes.func,
	deletePlayerEval: PropTypes.func,
	isEvalManager: PropTypes.bool,
	isEvalSaveError: PropTypes.bool,
	evaluation: PropTypes.object,
	getPlayerScoutEvalsFromEvalId: PropTypes.func,
	isMobile: PropTypes.bool,
	player: PropTypes.object,
	publishEval: PropTypes.func,
	scoutLastName: PropTypes.string,
	updatePlayerEval: PropTypes.func,
	isEvalSaving: PropTypes.bool,
	userId: PropTypes.number
};

export default AmaEval;
