import styled from "@emotion/styled";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";

export const StyledHeader = styled("div")`
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
	background-color: rgb(241, 242, 244);
	@media (max-width: 991px) {
		paddingtop: 50px;
	}
`;

export const PlayerInfo = styled.div({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	padding: "5px",
	paddingBottom: "0px",
	boxSizing: "border-box",
	"@media (max-width: 991px)": {
		display: "block",
		textAlign: "center",
		paddingTop: "50px"
	}
});

export const StyledPlayerBar = styled.div<{ colorSchemeGroup: ColorSchemeGroup }>(
	{
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		padding: "10px",
		boxSizing: "border-box",
		"@media (max-width: 991px)": {
			zIndex: 900,
			position: "fixed",
			top: 40,
			left: 0,
			right: 0
		}
	},
	(props: { colorSchemeGroup: ColorSchemeGroup }) => ({
		color: props.colorSchemeGroup!.secondary.text,
		backgroundColor: props.colorSchemeGroup!.secondary.color
	})
);

export const MainPlayerInfo = styled.div({
	display: "flex",
	justifyContent: "center",
	boxSizing: "border-box",
	"@media (max-width: 991px)": {
		marginBottom: "6px"
	}
});

export const ClassificationButtonStyle = {
	marginLeft: "10px",
	padding: "revert",
	height: "20px",
	fontSize: "14px",
	fontWeight: "bold",
	display: "flex",
	alignItems: "center"
};
