import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import {
	fetchStatsPlayerFielding,
	fetchStatsPlayerFieldingOpps,
	TStatsPlayerFieldingGetQueryParams,
	TStatsPlayerFieldingOppsGetQueryParams
} from "_react/shared/data_models/defensive_observed/_network";
import {
	IStatsPlayerFielding,
	IStatsPlayerFieldingOpps,
	IStatsPlayerRangeByLevel,
	IStatsPlayerReceivingByLevel
} from "_react/shared/data_models/defensive_observed/_types";

import {
	FIELDING_OAA_CANCEL_SOURCE,
	FIELDING_OAA_OPPS_CANCEL_SOURCE
} from "_react/shared/ui/data/tables/FieldingOaaTable/_constants";
import { TFieldingOaaCancelSource } from "_react/shared/ui/data/tables/FieldingOaaTable/_types";
import { TFieldingOaaTableData } from "_react/shared/ui/data/tables/FieldingOaaTable/FieldingOaaTable";

export type TFieldingOaaTableFilters = {
	maxSeason?: number;
	minSeason?: number;
};

export type TFieldingOaaTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TFieldingOaaTableFilters;
	fieldingOaa?: IStatsPlayerFielding | null;
	fieldingOaaOpps?: IStatsPlayerFieldingOpps | null;
	cancelSources: TFieldingOaaCancelSource;
	toast?: CreateToastFnReturn;
};

interface IFieldingOaaTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId changes
				contextRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches fielding OAA data
				fieldingOaa: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all fielding OAA Opps data
				fieldingOaaOpps: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_FIELDING_OAA = "SET_FIELDING_OAA";
export const SET_FIELDING_OAA_OPPS = "SET_FIELDING_OAA_OPPS";
export const FETCHING_FIELDING_OAA = { initialized: { fieldingOaa: "fetching" } };
export const FETCHING_FIELDING_OAA_OPPS = { initialized: { fieldingOaaOpps: "fetching" } };
export const SET_FILTERS = "SET_FILTERS";

const FETCH_FIELDING_OAA_DONE = "done.invoke.fetchingFieldingOaa:invocation[0]";
const FETCH_FIELDING_OAA_OPPS_DONE = "done.invoke.fetchingFieldingOaaOpps:invocation[0]";

type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | null | undefined;
};
type TSetFieldingOaaEvent = {
	type: typeof SET_FIELDING_OAA;
	data: IStatsPlayerFielding | null | undefined;
};
type TSetFieldingOaaOppsEvent = {
	type: typeof SET_FIELDING_OAA_OPPS;
	data: IStatsPlayerFieldingOpps | null | undefined;
};
type TFetchFieldingOaaEvent = {
	type: typeof FETCH_FIELDING_OAA_DONE;
	data: Array<IStatsPlayerFielding> | undefined;
};
type TFetchFieldingOaaOppsEvent = {
	type: typeof FETCH_FIELDING_OAA_OPPS_DONE;
	data: IStatsPlayerFieldingOpps | undefined;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TFieldingOaaTableFilters;
};

type TFieldingOaaTableEvent =
	| TSetPlayerIdEvent
	| TSetFieldingOaaEvent
	| TSetFieldingOaaOppsEvent
	| TFetchFieldingOaaEvent
	| TFetchFieldingOaaOppsEvent
	| TSetFiltersEvent;

export type TFieldingOaaTableSend = Interpreter<
	TFieldingOaaTableContext,
	IFieldingOaaTableStateSchema,
	TFieldingOaaTableEvent
>["send"];

const FieldingOaaTableMachine = (
	playerIdProp?: number,
	shouldFetchData = true,
	data?: TFieldingOaaTableData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TFieldingOaaTableContext, IFieldingOaaTableStateSchema, TFieldingOaaTableEvent>(
		{
			id: "fieldingOaaTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchData,
				filters: {},
				fieldingOaa: data?.fieldingOaa,
				fieldingOaaOpps: data?.fieldingOaaOpps,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_FIELDING_OAA]: { actions: "setFieldingOaa" },
						[SET_FIELDING_OAA_OPPS]: { actions: "setFieldingOaaOpps" },
						[SET_FILTERS]: { actions: "setFilters" }
					},
					states: {
						contextRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						fieldingOaa: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingFieldingOaa",
												cond: "shouldFetchFieldingOaa"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingFieldingOaa",
									entry: ["refreshFieldingOaaCancelSource"],
									invoke: {
										src: "fetchFieldingOaa",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchFieldingOaaSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchFieldingOaaErrored"
										}
									}
								}
							}
						},
						fieldingOaaOpps: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingFieldingOaaOpps",
												cond: "shouldFetchFieldingOaaOpps"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingFieldingOaaOpps",
									entry: ["refreshFieldingOaaOppsCancelSource"],
									invoke: {
										src: "fetchFieldingOaaOpps",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchFieldingOaaOppsSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchFieldingOaaOppsErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (context: TFieldingOaaTableContext, _event: TFieldingOaaTableEvent) =>
					context.playerId !== context.lastPlayerId,
				shouldFetchFieldingOaa: (context: TFieldingOaaTableContext, _event: TFieldingOaaTableEvent) =>
					context.fieldingOaa === undefined && context.playerId !== undefined && shouldFetchData,
				shouldFetchFieldingOaaOpps: (context: TFieldingOaaTableContext, _event: TFieldingOaaTableEvent) =>
					context.fieldingOaaOpps === undefined && shouldFetchData
			},
			actions: {
				setPlayerId: assign<TFieldingOaaTableContext, TFieldingOaaTableEvent>({
					playerId: (context: TFieldingOaaTableContext, event: TFieldingOaaTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data ?? undefined;
					}
				}),
				setFieldingOaa: assign<TFieldingOaaTableContext, TFieldingOaaTableEvent>({
					fieldingOaa: (context: TFieldingOaaTableContext, event: TFieldingOaaTableEvent) => {
						if (event.type !== SET_FIELDING_OAA) return context.fieldingOaa;
						return event.data;
					},
					cancelSources: (context: TFieldingOaaTableContext, event: TFieldingOaaTableEvent) => {
						if (event.type !== SET_FIELDING_OAA) return context.cancelSources;
						if (context.cancelSources[FIELDING_OAA_CANCEL_SOURCE] != null)
							context.cancelSources[FIELDING_OAA_CANCEL_SOURCE].cancel();
						delete context.cancelSources[FIELDING_OAA_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setFieldingOaaOpps: assign<TFieldingOaaTableContext, TFieldingOaaTableEvent>({
					fieldingOaaOpps: (context: TFieldingOaaTableContext, event: TFieldingOaaTableEvent) => {
						if (event.type !== SET_FIELDING_OAA_OPPS) return context.fieldingOaaOpps;
						return event.data;
					},
					cancelSources: (context: TFieldingOaaTableContext, event: TFieldingOaaTableEvent) => {
						if (event.type !== SET_FIELDING_OAA_OPPS) return context.cancelSources;
						if (context.cancelSources[FIELDING_OAA_OPPS_CANCEL_SOURCE] != null)
							context.cancelSources[FIELDING_OAA_OPPS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[FIELDING_OAA_OPPS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setFilters: assign<TFieldingOaaTableContext, TFieldingOaaTableEvent>({
					filters: (context: TFieldingOaaTableContext, event: TFieldingOaaTableEvent) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				clearContext: assign<TFieldingOaaTableContext, TFieldingOaaTableEvent>({
					lastPlayerId: (context: TFieldingOaaTableContext, _event: TFieldingOaaTableEvent) =>
						context.playerId,
					fieldingOaa: (_context: TFieldingOaaTableContext, _event: TFieldingOaaTableEvent) => undefined,
					cancelSources: (context: TFieldingOaaTableContext, _event: TFieldingOaaTableEvent) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshFieldingOaaCancelSource: assign<TFieldingOaaTableContext, TFieldingOaaTableEvent>({
					cancelSources: (context: TFieldingOaaTableContext, _event: TFieldingOaaTableEvent) => {
						if (context.cancelSources[FIELDING_OAA_CANCEL_SOURCE] != null)
							context.cancelSources[FIELDING_OAA_CANCEL_SOURCE].cancel();
						context.cancelSources[FIELDING_OAA_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshFieldingOaaOppsCancelSource: assign<TFieldingOaaTableContext, TFieldingOaaTableEvent>({
					cancelSources: (context: TFieldingOaaTableContext, _event: TFieldingOaaTableEvent) => {
						if (context.cancelSources[FIELDING_OAA_OPPS_CANCEL_SOURCE] != null)
							context.cancelSources[FIELDING_OAA_OPPS_CANCEL_SOURCE].cancel();
						context.cancelSources[FIELDING_OAA_OPPS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchFieldingOaaSuccess: assign<TFieldingOaaTableContext, TFieldingOaaTableEvent>({
					fieldingOaa: (context: TFieldingOaaTableContext, event: TFieldingOaaTableEvent) => {
						if (event.type !== FETCH_FIELDING_OAA_DONE) return context.fieldingOaa;
						if (!event.data?.length) return null;

						// Filter out game types since we cannot do it in the API
						const statsPlayerFielding = event.data[0];
						return {
							...statsPlayerFielding,
							statsPlayerRangeBylevel: statsPlayerFielding.statsPlayerRangeBylevel?.filter(
								(stat: IStatsPlayerRangeByLevel) => stat.gameType === "R"
							),
							statsPlayerReceivingBylevel: statsPlayerFielding.statsPlayerReceivingBylevel?.filter(
								(stat: IStatsPlayerReceivingByLevel) => stat.gameType === "R"
							)
						};
					}
				}),
				handleFetchFieldingOaaOppsSuccess: assign<TFieldingOaaTableContext, TFieldingOaaTableEvent>({
					fieldingOaaOpps: (context: TFieldingOaaTableContext, event: TFieldingOaaTableEvent) => {
						if (event.type !== FETCH_FIELDING_OAA_OPPS_DONE) return context.fieldingOaaOpps;
						return event.data ?? null;
					}
				}),
				// Fetch Errored Actions
				handleFetchFieldingOaaErrored: (context: TFieldingOaaTableContext, _event: TFieldingOaaTableEvent) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Fielding OAA",
							description: "Error fetching fielding OAA data."
						});
				},
				handleFetchFieldingOaaOppsErrored: (
					context: TFieldingOaaTableContext,
					_event: TFieldingOaaTableEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Fielding OAA Opportunities",
							description: "Error fetching fielding OAA opportunities data."
						});
				}
			},
			services: {
				fetchFieldingOaa: (context: TFieldingOaaTableContext, _event: AnyEventObject) => {
					if (!context.playerId) return Promise.resolve(null);
					const queryParams: TStatsPlayerFieldingGetQueryParams = {
						playerId: context.playerId,
						isUseCache: true
					};
					const fetchFunc = () =>
						fetchStatsPlayerFielding(queryParams, context.cancelSources[FIELDING_OAA_CANCEL_SOURCE]?.token);
					return promiseWRetry(fetchFunc);
				},
				fetchFieldingOaaOpps: (context: TFieldingOaaTableContext, _event: AnyEventObject) => {
					const queryParams: TStatsPlayerFieldingOppsGetQueryParams = { isUseCache: true };
					const fetchFunc = () =>
						fetchStatsPlayerFieldingOpps(
							queryParams,
							context.cancelSources[FIELDING_OAA_OPPS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default FieldingOaaTableMachine;
