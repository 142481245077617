import React from "react";
import { VStack } from "@chakra-ui/react";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import Section from "_react/shared/ui/presentation/components/Section/Section";
import PositionStatDistributions from "_react/shared/ui/data/plots/PositionStatDistributions/PositionStatDistributions";

import { SectionStyleObject } from "_react/playerpage/pro/shared/_styles";
import { TPlayingLevel } from "_react/shared/data_models/hitter_grades/_types";

type THittingSectionProps = {
	player: TPlayerPageCombinedPlayer;
	playingLevel: TPlayingLevel;
	seasonFilter: number;
	throwsFilter: string;
};

const HittingSection = ({ player, playingLevel, seasonFilter, throwsFilter }: THittingSectionProps) => {
	return (
		<Section label="Hitting" style={SectionStyleObject}>
			<VStack align="start">
				<PositionStatDistributions
					playerId={player.id}
					playingLevel={playingLevel}
					throwsFilter={throwsFilter}
					seasonFilter={seasonFilter}
				/>
			</VStack>
		</Section>
	);
};

export default HittingSection;
