import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

import {
	correctOFPLabel,
	EVAL_GROUP_VALUE_MAP,
	EVAL_GROUP_VALUE_MAP_REVERSE
} from "_react/playerpage/shared_js/evals/_helpers";
import EvalTableGroupRowNested from "_react/playerpage/shared_js/evals/EvalTableGroupRowNested";

function formatSeenDate(seenDate) {
	return seenDate?.isValid() ? seenDate.format("MMM D") : "Seen Unknown";
}

const EvalTableGroupRow = ({ evaluations: evaluationsRaw, idx, isProEvalAdmin }) => {
	const [rowHover, setRowHover] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [evaluations, setEvaluations] = useState([]);
	const history = useHistory();

	useEffect(() => {
		let includingMostRecentPro = false;
		const newEvaluations = evaluationsRaw
			.sort((a, b) => {
				const aDate = dayjs(a.seen_date ?? a.eval_create_date);
				const bDate = dayjs(b.seen_date ?? b.eval_create_date);
				return aDate.isBefore(bDate) ? 1 : bDate.isBefore(aDate) ? -1 : 0;
			})
			.filter(evaluation => {
				if (
					isProEvalAdmin ||
					!evaluation.eval_type.startsWith("PRO") ||
					evaluation.publish_date == null ||
					dayjs(evaluation.eval_create_date).year() < 2021
				)
					return true;
				if (!includingMostRecentPro) {
					includingMostRecentPro = true;
					return true;
				}
				return false;
			})
			// Workaround - The filtering logic above relies on a different sort order than the components
			.sort((a, b) => {
				const aDate = dayjs(a.seen_date ?? a.eval_create_date);
				const bDate = dayjs(b.seen_date ?? b.eval_create_date);
				return aDate.isBefore(bDate) ? -1 : bDate.isBefore(aDate) ? 1 : 0;
			});
		setEvaluations(newEvaluations);
	}, [evaluationsRaw, isProEvalAdmin]);

	if (evaluations.length === 0) return null;

	const e = evaluations[evaluations.length - 1] ?? {};
	const latestOFPLabel = correctOFPLabel(e.eval_report_type, e.ofp, e);

	const min = Math.min(
		...evaluations
			.filter(e => e.ofp || e.group)
			.map(e =>
				(e.eval_report_type === "FRE" || e.eval_report_type === "LVR" || e.eval_report_type === "AVR") &&
				dayjs(e.seen_date).year() >= 2020
					? EVAL_GROUP_VALUE_MAP[e.group]
					: e.ofp
			)
	);
	const max = Math.max(
		...evaluations
			.filter(e => e.ofp || e.group)
			.map(e =>
				(e.eval_report_type === "FRE" || e.eval_report_type === "LVR" || e.eval_report_type === "AVR") &&
				dayjs(e.seen_date).year() >= 2020
					? EVAL_GROUP_VALUE_MAP[e.group]
					: e.ofp
			)
	);

	const link = `eval?id=${evaluations.length > 0 ? evaluations[0].eval_id : ""}`;

	return (
		<div
			key={idx}
			onClick={() => (evaluations.length > 1 ? setExpanded(!expanded) : history.push(link))}
			onMouseEnter={() => setRowHover(true)}
			onMouseLeave={() => setRowHover(false)}
			style={{
				padding: "2px 4px",
				border: "none",
				cursor: "pointer",
				overflow: "hidden",
				backgroundColor: "#FFFFFF"
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: expanded ? "#A5D7FC" : rowHover ? "#FDF7AE" : idx % 2 === 0 ? "#EEEEEE" : "#FFFFFF"
				}}
			>
				<Tooltip
					placement="top"
					title={
						<div>
							{evaluations.map((ev, idx) => (
								<div key={idx}>{formatSeenDate(ev.seen_date)}</div>
							))}
						</div>
					}
				>
					<div
						style={{
							backgroundColor: "#EA7862",
							borderRadius: "2px",
							color: "white",
							padding: "3px",
							width: "75px",
							textAlign: "center",
							fontSize: "0.9em",
							fontWeight: "bold",
							minWidth: "81px"
						}}
					>
						{formatSeenDate(e ? e.seen_date : null)}
						<div
							style={{
								fontSize: "0.8em",
								color: "#f2f2f2",
								marginLeft: "5px",
								fontWeight: "400"
							}}
						>
							{evaluations.length > 0 && evaluations[0].eval_type.startsWith("PRO")
								? 1
								: evaluations.length}{" "}
							Report
							{evaluations.length > 1 &&
							!(evaluations.length > 0 && evaluations[0].eval_type.startsWith("PRO"))
								? "s"
								: ""}
						</div>
					</div>
				</Tooltip>
				<div style={{ flexGrow: 1, textAlign: "center" }}>
					<div
						style={{
							marginLeft: "10px",
							marginRight: "10px",
							fontWeight: "700"
						}}
					>{`${e.scout.first_name} ${e.scout.last_name}`}</div>

					<div
						style={{
							flexGrow: 1,
							marginLeft: "10px",
							marginRight: "10px",
							fontStyle: "italic",
							fontWeight: "600",
							color: "#535353",
							textAlign: "center",
							fontSize: "0.80em"
						}}
					>
						{e.current_snapshot}
					</div>
				</div>
				<Tooltip
					placement="top"
					title={
						<div>
							Average Current OFP: {e.ofp}
							{evaluations.length > 1 && min !== max && (
								<div>
									High: {max}
									<br />
									Low: {min}
								</div>
							)}
						</div>
					}
				>
					<div
						style={{
							color: "white",
							backgroundColor: "blue",
							padding: "2px",
							paddingLeft: "5px",
							paddingRight: "5px",
							borderRadius: "2px",
							fontWeight: "700",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column",
							height: "33px",
							minWidth: "44px"
						}}
					>
						<div>{e.ofp || e.group ? latestOFPLabel : "-"}</div>
						{evaluations.length > 1 &&
							(min !== max ||
								(EVAL_GROUP_VALUE_MAP_REVERSE[min] ?? min) !== latestOFPLabel ||
								(EVAL_GROUP_VALUE_MAP_REVERSE[max] ?? max) !== latestOFPLabel) && (
								<div
									style={{
										fontWeight: "400",
										fontStyle: "italic",
										fontSize: "0.8em",
										textAlign: "center"
									}}
								>
									({EVAL_GROUP_VALUE_MAP_REVERSE[min] ?? correctOFPLabel(e.eval_report_type, min, e)}/
									{EVAL_GROUP_VALUE_MAP_REVERSE[max] ?? correctOFPLabel(e.eval_report_type, max, e)})
								</div>
							)}
					</div>
				</Tooltip>
			</div>
			{expanded && (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: "#A5D7FC",
						marginTop: "-4px",
						paddingTop: "6px",
						paddingBottom: "2px",
						borderBottomLeftRadius: "2px",
						borderBottomRightRadius: "2px"
					}}
				>
					{[...evaluations].reverse().map((e, idx) => (
						<EvalTableGroupRowNested evaluation={e} idx={idx} key={idx} />
					))}
				</div>
			)}
		</div>
	);
};

EvalTableGroupRow.propTypes = {
	evaluations: PropTypes.array,
	idx: PropTypes.number,
	isProEvalAdmin: PropTypes.bool
};

export default EvalTableGroupRow;
