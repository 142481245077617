/* eslint @typescript-eslint/no-var-requires: "off" */
import React, { useMemo } from "react";

import Plotly from "plotly.js/lib/core";
import createPlotlyComponent from "react-plotly.js/factory";

import { colorToRGBA } from "utils/color";
import { formatHeight } from "utils/functions";
import { $TSAnyRequired } from "utils/tsutils";

import { PITCH_MOVEMENT_GROUP, RELEASE_POINT_GROUP } from "_react/shared/data_models/metric/_constants";
import {
	IMetricJointProbabilityDensityPitch,
	TMetricJointProbabilityGroup
} from "_react/shared/data_models/metric/_types";
import { TPitchTypes } from "_react/shared/_types/pitch_types";

import {
	AXIS_COLOR_RGB,
	DATA_COLOR_PRIMARY_GRAY_RGB,
	DEFAULT_OPACITY,
	IS_ELSEWHERE_HOVERED_OPACITY
} from "_react/shared/dataviz/_constants";

import { PITCH_TYPE_COLORS } from "_react/shared/dataviz/_constants";
import { getPitchTypeColorScale } from "_react/shared/dataviz/_helpers";

import {
	CONTOUR_PLOT_PROPS,
	PITCHERS_MOUND_TRACES,
	PITCHERS_PLATE_SHAPE,
	MOUND_HEIGHT
} from "_react/shared/ui/presentation/plots/PitchMetricContourPlot/_constants";
import { getSteps } from "_react/shared/ui/presentation/plots/PitchMetricContourPlot/_helpers";
import { TPitchTypeDistributionData } from "_react/shared/ui/presentation/plots/PitchMetricContourPlot/_types";

// Set up Plotly with only the traces needed for this component
const completePlotly: $TSAnyRequired = Plotly;
completePlotly.register([require("plotly.js/lib/scatter"), require("plotly.js/lib/contour")]);

// Cannot use types because many attributes are missing:
// "contour/heatmap": x0, y0, dx, dy
// "colorbar": dictionary with options rather than string
// "dtick": layout option not included

const Plot: $TSAnyRequired = createPlotlyComponent(Plotly);

export type TPitcherMetricContourPlotProps = {
	metricGroup: TMetricJointProbabilityGroup;
	data: {
		distributionData: TPitchTypeDistributionData;
		expectedMovement?: Array<{ x: number; y: number }>;
		playerHeight?: { playerId: number; height: number | null };
	};
	xAxisLabel?: string;
	height?: number;
	width?: number;
	plotType?: "gaussian" | "scatter";
};

const PitchMetricContourPlot = ({
	metricGroup,
	data,
	xAxisLabel,
	plotType = "gaussian",
	height,
	width
}: TPitcherMetricContourPlotProps) => {
	const { distributionData, expectedMovement, playerHeight: playerHeightObject } = data;
	const playerHeight = playerHeightObject?.height;
	// Plot props for the specific metric group
	const {
		xAxisRange: defaultXAxisRange,
		yAxisRange: defaultYAxisRange,
		height: default_height,
		width: default_width,
		yAxisLabel,
		tickInterval,
		nContours,
		ticks,
		tickLen
	} = CONTOUR_PLOT_PROPS[metricGroup];

	// Parse the data so that Plotly can display it
	const contourData: Array<{
		// Plotly doesn't have a type for contour data
		contour: $TSAnyRequired;
		scatter: Partial<Plotly.ScatterData>;
		pitchType: TPitchTypes;
	}> = useMemo(() => {
		const PITCH_TYPE_COLORSCALE = getPitchTypeColorScale();
		const pitchTypes = Object.keys(distributionData) as Array<TPitchTypes>;
		if (pitchTypes) {
			const traceData: Array<{
				contour: $TSAnyRequired;
				scatter: Partial<Plotly.ScatterData>;
				pitchType: TPitchTypes;
			}> = pitchTypes.map((pitchType: TPitchTypes) => {
				const pitchTypeData = distributionData[pitchType];

				// For some metric groups, the density data is not calculated for the entirety of the plot
				// Metadata includes what the extrema for the predictions was which can then be used to plot the data properly
				const predictedXAxisRange =
					pitchTypeData &&
					pitchTypeData.densityMetadata.xMin !== null &&
					pitchTypeData.densityMetadata.xMax !== null
						? [pitchTypeData.densityMetadata.xMin, pitchTypeData.densityMetadata.xMax]
						: defaultXAxisRange;
				const predictedYAxisRange =
					pitchTypeData &&
					pitchTypeData?.densityMetadata.yMin !== null &&
					pitchTypeData.densityMetadata.yMax !== null
						? [pitchTypeData.densityMetadata.yMin, pitchTypeData.densityMetadata.yMax]
						: defaultYAxisRange;

				const pitchTypeTrace = {
					z: pitchTypeData?.densityData,
					colorscale: PITCH_TYPE_COLORSCALE[pitchType],
					zsmooth: "best",
					type: "contour",
					ncontours: nContours,
					showscale: false,
					hoverinfo: "skip",
					// Sets scale to the same as that of the axes
					x0: predictedXAxisRange[0],
					dx: pitchTypeData ? getSteps("x", predictedXAxisRange, pitchTypeData.densityData) : undefined,
					y0: predictedYAxisRange[0],
					dy: pitchTypeData ? getSteps("y", predictedYAxisRange, pitchTypeData.densityData) : undefined
				};

				const scatterTrace = {
					x: pitchTypeData?.pitchData?.map((pitch: IMetricJointProbabilityDensityPitch) => pitch.xValue),
					y: pitchTypeData?.pitchData?.map((pitch: IMetricJointProbabilityDensityPitch) => pitch.yValue),
					name: pitchType,
					marker: {
						color: colorToRGBA(PITCH_TYPE_COLORS[pitchType as TPitchTypes], 0.25),
						size: 4
					},
					hoverinfo: "skip",
					showlegend: false,
					type: "scatter",
					mode: "markers"
				};

				return {
					contour: pitchTypeTrace,
					scatter: scatterTrace as Partial<Plotly.ScatterData>,
					pitchType: pitchType
				};
			});
			return traceData;
		}
		return [];
	}, [distributionData, defaultXAxisRange, defaultYAxisRange, nContours]);

	// Traces
	const plotlyData: Array<Partial<Plotly.ScatterData> | $TSAnyRequired> = useMemo(() => {
		const plotlyData = contourData.map(plotData => (plotType === "gaussian" ? plotData.contour : plotData.scatter));

		if (metricGroup === PITCH_MOVEMENT_GROUP && expectedMovement) {
			const lineTrace: Partial<Plotly.ScatterData> = {
				x: expectedMovement.map((datum: { x: number; y: number }) => datum.x),
				y: expectedMovement.map((datum: { x: number; y: number }) => datum.y),
				line: { color: DATA_COLOR_PRIMARY_GRAY_RGB, width: 2, dash: "dashdot" },
				opacity: IS_ELSEWHERE_HOVERED_OPACITY,
				showlegend: false,
				name: "Expected Movement",
				hoverinfo: "skip",
				type: "scatter",
				mode: "lines"
			};
			plotlyData.push(lineTrace);
		}

		if (metricGroup === RELEASE_POINT_GROUP) {
			// Pitcher's Mound
			plotlyData.push(...PITCHERS_MOUND_TRACES);
			// Player's Height
			if (playerHeight) {
				const heightTrace: Partial<Plotly.ScatterData> = {
					// Add a cushion to ensure the line spans the entire x axis
					x: [defaultXAxisRange[0] - 1, defaultXAxisRange[1] + 1],
					y: [playerHeight / 12 + MOUND_HEIGHT, playerHeight / 12 + MOUND_HEIGHT],
					line: { color: DATA_COLOR_PRIMARY_GRAY_RGB, width: 1, dash: "dashdot" },
					opacity: DEFAULT_OPACITY,
					showlegend: false,
					name: "Player's Height",
					hoverinfo: "skip",
					type: "scatter",
					mode: "lines"
				};
				plotlyData.push(heightTrace);
			}
		}
		return plotlyData;
	}, [contourData, expectedMovement, plotType, metricGroup, playerHeight, defaultXAxisRange]);

	// Shapes
	const plotlyShapes: Array<Partial<Plotly.Shape>> = useMemo(() => {
		if (metricGroup === RELEASE_POINT_GROUP) {
			// Pitcher's Plate
			return [PITCHERS_PLATE_SHAPE];
		}
		return [];
	}, [metricGroup]);

	// Annotations
	const plotlyAnnotations: Array<Partial<Plotly.Annotations>> = useMemo(() => {
		if (metricGroup === RELEASE_POINT_GROUP && playerHeight) {
			const heightLabel = {
				x: defaultXAxisRange[1] - 1,
				// Convert inches to feet
				y: playerHeight / 12 + MOUND_HEIGHT,
				text: `Player's Height: ${formatHeight(playerHeight)}`,
				xref: "x" as Plotly.XAxisName,
				yref: "y" as Plotly.YAxisName,
				showarrow: true,
				arrowhead: 0,
				arrowcolor: DATA_COLOR_PRIMARY_GRAY_RGB,
				arrowwidth: 1,
				ax: 0,
				ay: -20,
				font: {
					color: DATA_COLOR_PRIMARY_GRAY_RGB,
					size: 10
				}
			};
			return [heightLabel];
		}
		return [];
	}, [metricGroup, playerHeight, defaultXAxisRange]);

	return (
		<Plot
			data={plotlyData}
			layout={{
				height: height ?? default_height,
				width: width ?? default_width,
				font: {
					family: `11px Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
					color: AXIS_COLOR_RGB,
					size: 10
				},
				margin: { l: 60, r: 10, t: 20, b: 50 },
				xaxis: {
					range: defaultXAxisRange,
					title: { text: xAxisLabel, font: { size: 10 } },
					dtick: tickInterval,
					ticks: ticks,
					tickLen: tickLen
				},
				yaxis: {
					range: defaultYAxisRange,
					title: { text: yAxisLabel, font: { size: 10 } },
					scaleanchor: "x",
					scaleratio: 1,
					dtick: tickInterval,
					ticks: ticks,
					tickLen: tickLen
				},
				shapes: plotlyShapes,
				annotations: plotlyAnnotations
			}}
			config={{ displayModeBar: false, staticPlot: true }}
		/>
	);
};

export default PitchMetricContourPlot;
