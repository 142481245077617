import React, { useEffect, CSSProperties } from "react";
import { useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import { Box, Button, Menu, MenuButton, MenuList, MenuItem, Spinner, Tooltip } from "@chakra-ui/react";

import ExpandMore from "_react/shared/ui/icons/ExpandMore";

import createMyListsMenuMachine, {
	TMyListsMenuContext,
	SET_PLAYER_ID,
	SET_LIST_RANKS,
	FETCHING_LIST_RANKS
} from "_react/shared/ui/data/other/MyListsMenu/_machine";
import { getNumberWithOrdinal } from "_react/shared/ui/data/other/MyListsMenu/_helpers";
import { IListRank } from "_react/shared/data_models/list_new/_types";

export type TMyListsMenuData = {
	listRanks?: Array<IListRank> | null;
	isLoading?: boolean;
};

type TMyListsMenuStyle = {
	button?: CSSProperties;
	list?: CSSProperties;
	item?: CSSProperties;
};

type TMyListsMenuProps = {
	playerId?: number;
	data?: TMyListsMenuData;
	shouldFetchData?: boolean;
	style?: TMyListsMenuStyle;
};

const MyListsMenu = ({ playerId, data, shouldFetchData = true, style }: TMyListsMenuProps) => {
	const toast = useToast();
	const [current, send] = useMachine(createMyListsMenuMachine(playerId, shouldFetchData, data, toast));
	const { listRanks } = current.context as TMyListsMenuContext;

	const fetchingListRanks = current.matches(FETCHING_LIST_RANKS);
	const isLoading = shouldFetchData ? fetchingListRanks : data?.isLoading;
	const isNoResults = Boolean(!isLoading && listRanks && listRanks.length === 0);

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.listRanks !== listRanks && !shouldFetchData) {
			send({ type: SET_LIST_RANKS, data: data?.listRanks });
		}
	}, [data?.listRanks, shouldFetchData, send, listRanks]);

	return (
		<Menu>
			<Tooltip hasArrow placement="bottom" label="Player is not in any list" isDisabled={!isNoResults}>
				<MenuButton
					as={Button}
					rightIcon={<ExpandMore />}
					h="8"
					bg="transparent"
					color="gray.700"
					borderColor="gray.700"
					border="1px solid"
					fontSize="sm"
					fontWeight="semibold"
					paddingInlineStart="3"
					paddingInlineEnd="3"
					isLoading={isLoading}
					isDisabled={isNoResults}
					spinner={<Spinner marginRight="2" />}
					sx={style?.button}
				>
					My Lists
				</MenuButton>
			</Tooltip>
			<MenuList sx={style?.list}>
				{listRanks?.map((listRank: IListRank) => (
					<MenuItem
						key={`${listRank.listId}-${listRank.rank}`}
						onClick={() => window.open(`/list?listId=${listRank.listId}`, "_blank")}
						sx={style?.item}
					>
						<Box as="span" fontWeight="bold" paddingRight="1">
							{getNumberWithOrdinal(listRank.rank)}
						</Box>
						<Box as="span">{listRank.name}</Box>
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
};

export default MyListsMenu;
