import React from "react";
import dayjs from "dayjs";

import { $TSFixMePlayer } from "utils/tsutils";
import { getPlayerClassificationTag } from "utils/PlayerTypeHelpers";
import { ColorSchemeGroup, defaultColorScheme, RED } from "_react/shared/legacy/ui/Colors";
import Check from "_react/shared/legacy/ui/icons/Check";
import Clear from "_react/shared/legacy/ui/icons/Clear";

import { NULL_TEXT, PRO_HIT, PRO_PITCH } from "_react/evals/list/_constants";
import { TEvalProHit, TEvalProPitch, TEvalStyle } from "_react/evals/list/_types";
import {
	EvalListCard,
	EvalListCardHeader,
	LeftDiv,
	RightDiv,
	NameDiv,
	PositionDiv,
	OrgTeamDiv,
	PlayerClassificationDiv,
	LabelSpan,
	IpDiv,
	GamesDiv,
	DateSeenDiv,
	ScoutDiv,
	EvalContentDiv,
	SummarySection,
	EvalHeader,
	EvalHeaderAttr,
	EvalHeaderLabel,
	EvalContainer,
	EvalAttr
} from "_react/evals/list/_styles";
import {
	getPlayerName,
	getPlayerOrgTeam,
	getScoutName,
	getOfp,
	getPitcherSnapshotSummary
} from "_react/evals/list/_helpers";
import { EvalProHitGrades } from "_react/evals/list/shared/EvalProHitGrades";
import { EvalProPitchGrades } from "_react/evals/list/shared/EvalProPitchGrades";
import { EvalProHitSummary } from "_react/evals/list/shared/EvalProHitSummary";

export type TPlayerEvalProProps = {
	evals: Array<TEvalProHit | TEvalProPitch>;
	player: $TSFixMePlayer;
	isShowSummaries?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TEvalStyle;
};

export const PlayerEvalPro = ({
	evals,
	player,
	isShowSummaries = true,
	colorSchemeGroup = defaultColorScheme,
	style
}: TPlayerEvalProProps) => {
	return (
		<EvalListCard>
			{evals.length > 0 && (
				<>
					<EvalListCardHeader colorSchemeGroup={colorSchemeGroup}>
						<LeftDiv>
							<PlayerClassificationDiv>
								{getPlayerClassificationTag("PRO", { fontSize: "12px" })}
							</PlayerClassificationDiv>
							<NameDiv>{getPlayerName(player)}</NameDiv>
							<PositionDiv>({player?.position ?? NULL_TEXT})</PositionDiv>
							<OrgTeamDiv>({getPlayerOrgTeam(player)})</OrgTeamDiv>
						</LeftDiv>
					</EvalListCardHeader>
					{evals.map((e: TEvalProHit | TEvalProPitch) => (
						<EvalContainer key={e.eval_id}>
							<EvalHeader>
								<LeftDiv>
									<EvalHeaderAttr width={"50px"}>
										<EvalHeaderLabel>OFP:</EvalHeaderLabel> {getOfp(e.ofp)}
									</EvalHeaderAttr>
									<EvalHeaderAttr>
										<EvalHeaderLabel>Acquire:</EvalHeaderLabel>
										{(e.acquire?.toLowerCase() === "y" || e.acquire?.toLowerCase() === "yes") && (
											<Check fill={"#008000"} />
										)}
										{(e.acquire?.toLowerCase() === "n" || e.acquire?.toLowerCase() === "no") && (
											<Clear fill={RED} />
										)}
										{!e.acquire && <Clear fill={"#FFFBEC"} />}
									</EvalHeaderAttr>
								</LeftDiv>
								<RightDiv>
									{e.eval_type === PRO_PITCH && (
										<IpDiv>
											{e.innings_seen_thirds?.toFixed(1) ?? NULL_TEXT}
											<LabelSpan>IP</LabelSpan>
										</IpDiv>
									)}
									<GamesDiv>
										{e.games_seen ?? NULL_TEXT}
										<LabelSpan>G</LabelSpan>
									</GamesDiv>
									<DateSeenDiv>
										{e.seen_date ? dayjs(e.seen_date).format("M/D/YY") : NULL_TEXT}
									</DateSeenDiv>
									<ScoutDiv>{getScoutName(e?.scout)}</ScoutDiv>
								</RightDiv>
							</EvalHeader>
							{e.eval_type === PRO_HIT && (
								<EvalContentDiv style={style?.evalContentDiv}>
									<EvalProHitGrades
										evaluation={e as TEvalProHit}
										player={player}
										style={style?.evalSection}
									/>
									<EvalProHitSummary
										evaluation={e as TEvalProHit}
										isShowSummaries={isShowSummaries}
										style={style?.summarySection}
									/>
								</EvalContentDiv>
							)}
							{e.eval_type === PRO_PITCH && (
								<EvalContentDiv style={style?.evalContentDiv}>
									<EvalProPitchGrades
										evaluation={e as TEvalProPitch}
										player={player}
										style={style?.evalSection}
									/>
									<SummarySection style={style?.summarySection}>
										<EvalAttr>
											{getPitcherSnapshotSummary(e as TEvalProPitch, isShowSummaries)}
										</EvalAttr>
									</SummarySection>
								</EvalContentDiv>
							)}
						</EvalContainer>
					))}
				</>
			)}
		</EvalListCard>
	);
};
