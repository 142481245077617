export function getRgbArray(color: string): Array<string> {
	return color.replace(/[^\d,]/g, "").split(",");
}

export function getRgbNumberArray(color: string): [number, number, number] {
	const colorArr = getRgbArray(color);
	const numberColorArr: [number, number, number] = [
		parseInt(colorArr[0]),
		parseInt(colorArr[1]),
		parseInt(colorArr[2])
	];
	return numberColorArr;
}

export function colorToRGBA(color: string, alpha: number): string {
	const colorArr = getRgbArray(color);
	return `rgba(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]}, ${alpha})`;
}

// Creates a function that calculates the color of a value on a three step gradient with a given min and max value
// Requires min and max value for gradient
// Can set custom colors (each desired color should be an array of 3 rgb values) or can use default (blue, white, red)
export type TGetColorGradientFunction = (value: number | string) => Array<number>;
export type TColorGradientColors = {
	minColor: [number, number, number];
	middleColor: [number, number, number];
	maxColor: [number, number, number];
};
export type TColorGradientValues = {
	minValue: number;
	middleValue: number;
	maxValue: number;
};
export type TColorGradientFunction = [TGetColorGradientFunction, TColorGradientColors, TColorGradientValues];

export const getColorGradientFunction = (
	minValue: number,
	maxValue: number,
	minColor?: [number, number, number],
	middleColor?: [number, number, number],
	maxColor?: [number, number, number],
	middleValue?: number
): TColorGradientFunction => {
	const firstColor = minColor ?? [0, 0, 255];
	const secondColor = middleColor ?? [255, 255, 255];
	const thirdColor = maxColor ?? [255, 0, 0];
	const parsedMiddleValue = middleValue !== undefined ? middleValue : (maxValue + minValue) / 2;
	const getColorFromGradient = (value: number | string) => {
		const valueNumber = typeof value === "string" ? parseFloat(value) : value;

		if (valueNumber >= maxValue) {
			return thirdColor;
		} else if (valueNumber <= minValue) {
			return firstColor;
		} else if (valueNumber >= parsedMiddleValue) {
			const w1 = (valueNumber - parsedMiddleValue) / (maxValue - parsedMiddleValue);
			const w2 = 1 - w1;
			const rgbColor = [
				Math.round(secondColor[0] * w2 + thirdColor[0] * w1),
				Math.round(secondColor[1] * w2 + thirdColor[1] * w1),
				Math.round(secondColor[2] * w2 + thirdColor[2] * w1)
			];
			return rgbColor;
		}
		const w1 = (valueNumber - minValue) / (parsedMiddleValue - minValue);
		const w2 = 1 - w1;
		const rgbColor = [
			Math.round(firstColor[0] * w2 + secondColor[0] * w1),
			Math.round(firstColor[1] * w2 + secondColor[1] * w1),
			Math.round(firstColor[2] * w2 + secondColor[2] * w1)
		];
		return rgbColor;
	};
	const colors = {
		minColor: firstColor,
		middleColor: secondColor,
		maxColor: thirdColor
	};
	const values = { minValue, middleValue: parsedMiddleValue, maxValue };
	return [getColorFromGradient, colors, values];
};
