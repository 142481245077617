import { $TSFixMe } from "utils/tsutils";
import { setCookieRaw, getCookieRaw } from "utils/helpers";

// Theme Resources
export let retroGlobal = getCookieRaw("theme") === "retro";
export const setRetroGlobal = (retro: boolean) => {
	retroGlobal = retro;
	setCookieRaw("theme", retro ? "retro" : "normal", 365);
	window.location.reload();
};

// Red
export const RED = !retroGlobal ? "#BA0C2F" : "#6BACE4";
export const REDDARK = !retroGlobal ? "#8B1E2A" : "#6188B3";
export const REDDARKER = "#6E2226";
export const REDTEXT = "#FFFFFF";

// Green
export const GREEN = "#4caf50";
export const GREENDARK = "#388e3c";
export const GREENTEXT = "#FFFFFF";

// Blue
export const BLUE = "#002D72";
export const BLUEDARK = "#0B2256";
export const BLUEDARKER = "#0F1D48";
export const BLUETEXT = "#FFFFFF";

// Cream Alternate
export const CREAM = "#FFFBEC";
export const CREAMDARK = "#D1CFC4";
export const CREAMDARKER = "#A1A097";
export const CREAMTEXT = "#BA0C2F";
export const CREAMTEXTDARK = "#8B1E2A";

// Gray
export const GRAY = "#9BA7A2";
export const GRAYTEXT = "#FFFFFF";

// ThrowbackMaroon
export const THROWBACKMAROON = "#6F263D";
export const THROWBACKMAROONDARK = "#562434";
export const THROWBACKMAROONDARKER = "#461D2A";
export const THROWBACKMAROONTEXT = "#6BACE4";

// ThrowbackBlue
export const THROWBACKBLUE = "#6BACE4";
export const THROWBACKBLUEDARK = "#6188B3";
export const THROWBACKBLUEDARKER = "#507094";
export const THROWBACKBLUETEXT = "#6F263D";

export interface ColorSchemeRaw {
	primary: ColorScheme;
	secondary?: ColorScheme;
	tertiary?: ColorScheme;
	quaternary?: ColorScheme;
	quinary?: ColorScheme;
}

export interface ColorSchemeGroup {
	primary: ColorScheme;
	secondary: ColorScheme;
	tertiary: ColorScheme;
	quaternary: ColorScheme;
	quinary: ColorScheme;
}

export interface ColorScheme {
	color: string;
	text: string;
	hover: string;
	hoverText: string;
	selected: string;
	selectedText: string;
}

// Helper Functions

function shadeColor(color: string, percent: number) {
	let R = parseInt(color.substring(1, 3), 16);
	let G = parseInt(color.substring(3, 5), 16);
	let B = parseInt(color.substring(5, 7), 16);

	R = parseInt(`${(R * (100 + percent)) / 100}`, 10);
	G = parseInt(`${(G * (100 + percent)) / 100}`, 10);
	B = parseInt(`${(B * (100 + percent)) / 100}`, 10);

	R = R < 255 ? R : 255;
	G = G < 255 ? G : 255;
	B = B < 255 ? B : 255;

	const RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
	const GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
	const BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

	return "#" + RR + GG + BB;
}

function getLightDarkPercentage(color: string) {
	const c = color.includes("#") ? color.substring(1) : color;
	const rgb = parseInt(c, 16);
	const r = (rgb >> 16) & 0xff;
	const g = (rgb >> 8) & 0xff;
	const b = (rgb >> 0) & 0xff;

	const uma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

	return uma / 255.0;
}

function getText(color: $TSFixMe) {
	if (color == null) return "#000000";
	if (getLightDarkPercentage(color) > 0.5) return "#000000";
	return "#FFFFFF";
}

function getHover(color: $TSFixMe) {
	if (color == null) return "#000000";
	if (getLightDarkPercentage(color) > 0.5) return shadeColor(color, -20);
	return shadeColor(color, 20);
}

function getSelected(color: $TSFixMe) {
	if (color == null) return "#000000";
	if (getLightDarkPercentage(color) > 0.5) return shadeColor(color, -40);
	return shadeColor(color, 40);
}

export const generateColorScheme = (colorScheme: $TSFixMe): ColorScheme => {
	if (colorScheme == null) return generateColorScheme("#000000");
	return {
		color: colorScheme.color,
		text: colorScheme.text ? colorScheme.text : getText(colorScheme.color),
		hover: colorScheme.hover ? colorScheme.hover : getHover(colorScheme.color),
		hoverText: colorScheme.hoverText
			? colorScheme.hoverText
			: getText(colorScheme.hover ? colorScheme.hover : getHover(colorScheme.color)),
		selected: colorScheme.selected ? colorScheme.selected : getSelected(colorScheme.color),
		selectedText: colorScheme.selectedText
			? colorScheme.selectedText
			: getText(colorScheme.selected ? colorScheme.selected : getSelected(colorScheme.color))
	};
};

export const colorSchemeFactory = (color: string) => {
	return generateColorScheme({ color });
};

export const generateColorSchemeGroup = (colorScheme: $TSFixMe): ColorSchemeGroup => {
	return {
		primary: colorSchemeFactory(colorScheme.primary),
		secondary: colorSchemeFactory(colorScheme.secondary),
		tertiary: colorSchemeFactory(colorScheme.tertiary),
		quaternary: colorSchemeFactory(colorScheme.quaternary),
		quinary: colorSchemeFactory(colorScheme.quinary)
	};
};

export const GREEN_SCHEME = colorSchemeFactory(GREEN);

export const RED_SCHEME = colorSchemeFactory(RED);

export const TEAM_COLORS: { [index: string]: ColorSchemeGroup } = {
	ari: generateColorSchemeGroup({ secondary: "#A71930", primary: "#E3D4AD" }),
	atl: generateColorSchemeGroup({ secondary: "#CE1141", primary: "#13274F" }),
	bal: generateColorSchemeGroup({ secondary: "#DF4601", primary: "#000000" }),
	bos: generateColorSchemeGroup({ secondary: "#BD3039", primary: "#0C2340" }),
	chc: generateColorSchemeGroup({ secondary: "#0E3386", primary: "#CC3433" }),
	cws: generateColorSchemeGroup({ secondary: "#27251F", primary: "#C4CED4" }),
	cin: generateColorSchemeGroup({ secondary: "#C6011F", primary: "#000000" }),
	cle: generateColorSchemeGroup({ secondary: "#0C2340", primary: "#E31937" }),
	col: generateColorSchemeGroup({ secondary: "#33006F", primary: "#C4CED4" }),
	det: generateColorSchemeGroup({ secondary: "#0C2340", primary: "#FA4616" }),
	hou: generateColorSchemeGroup({ secondary: "#002D62", primary: "#EB6E1F" }),
	kc: generateColorSchemeGroup({ secondary: "#004687", primary: "#BD9B60" }),
	laa: generateColorSchemeGroup({ secondary: "#BA0021", primary: "#FFFFFF" }),
	lad: generateColorSchemeGroup({ secondary: "#005A9C", primary: "#FFFFFF" }),
	mia: generateColorSchemeGroup({ secondary: "#00A3E0", primary: "#EF3340" }),
	mil: generateColorSchemeGroup({ secondary: "#0A2351", primary: "#B6922E" }),
	min: generateColorSchemeGroup({ secondary: "#002B5C", primary: "#D31145" }),
	nym: generateColorSchemeGroup({ secondary: "#002D72", primary: "#FF5910" }),
	nyy: generateColorSchemeGroup({ secondary: "#0C2340", primary: "#FFFFFF" }),
	oak: generateColorSchemeGroup({ secondary: "#003831", primary: "#EFB21E" }),
	phi: retroGlobal
		? generateColorSchemeGroup({ secondary: THROWBACKBLUE, primary: THROWBACKMAROON, tertiary: CREAM })
		: generateColorSchemeGroup({ secondary: RED, primary: BLUE, tertiary: CREAM }),
	pit: generateColorSchemeGroup({ secondary: "#27251F", primary: "#FDB827" }),
	stl: generateColorSchemeGroup({ secondary: "#C41E3A", primary: "#0C2340" }),
	sd: generateColorSchemeGroup({ secondary: "#002D62", primary: "#A2AAAD" }),
	sf: generateColorSchemeGroup({ secondary: "#FD5A1E", primary: "#27251F" }),
	sea: generateColorSchemeGroup({ secondary: "#0C2C56", primary: "#005C5C" }),
	tb: generateColorSchemeGroup({ secondary: "#092C5C", primary: "#8FBCE6" }),
	tex: generateColorSchemeGroup({ secondary: "#003278", primary: "#C0111F" }),
	tor: generateColorSchemeGroup({ secondary: "#134A8E", primary: "#E8291C" }),
	wsh: generateColorSchemeGroup({ secondary: "#AB0003", primary: "#14225A" }),
	none: generateColorSchemeGroup({ secondary: RED, primary: BLUE })
};

export const defaultColorScheme = TEAM_COLORS["phi"];
export const BLACKWHITE = generateColorSchemeGroup({ secondary: "#FFFFFF", primary: "#000000" });
export const BLACKGRAY = generateColorSchemeGroup({ secondary: "#5f5f5f", primary: "#000000" });

export const getColorSchemeGroup = (player: $TSFixMe) => {
	const org =
		player && player._org && player._org.abbreviation
			? player._org.abbreviation.toLowerCase()
			: player?.proProfile?.orgCode
			? player.proProfile.orgCode.toLowerCase()
			: "phi";

	// Switching phi colors (TODO: Fix)
	if (org === "phi") {
		const group = TEAM_COLORS["phi"];
		return generateColorSchemeGroup({
			primary: group.secondary.color,
			secondary: group.primary.color
		});
	}
	return TEAM_COLORS[org];
};
