import React from "react";

import {
	TOOLTIP_SWING_METRICS_BAT_SPEED,
	TOOLTIP_SWING_METRICS_VERTICAL_ATTACK_ANGLE,
	TOOLTIP_HORIZONTAL_ATTACK_ANGLE,
	TOOLTIP_VERTICAL_BAT_ANGLE,
	TOOLTIP_HORIZONTAL_BAT_ANGLE
} from "_react/shared/_constants/tooltips";
import { round10 } from "_react/shared/_helpers/numbers";
import { getLevelDisplayFromLevels } from "_react/shared/_helpers/stats";
import {
	GAME_TYPE_OVERALL,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_SPRING_TRAINING,
	THROWS_L,
	THROWS_OVERALL,
	THROWS_R
} from "_react/shared/data_models/seasonal_grades/_constants";
import {
	GAME_TYPE_D,
	GAME_TYPE_F,
	GAME_TYPE_L,
	GAME_TYPE_R,
	GAME_TYPE_S,
	GAME_TYPE_W
} from "_react/shared/data_models/stats/_constants";
import { TColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import TeamColumn from "_react/shared/ui/data/tables/shared/TeamColumn";

import { getLevelsFromRow } from "_react/shared/ui/data/tables/SwingMetricsTable/_helpers";
import { TSwingMetricsRow } from "_react/shared/ui/data/tables/SwingMetricsTable/_types";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

export const GUMBO_GAME_TYPE_TO_GAME_TYPE_MAP: Record<string, Array<string>> = {
	[GAME_TYPE_R]: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_F]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_D]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_L]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_W]: [GAME_TYPE_POSTSEASON, GAME_TYPE_OVERALL],
	[GAME_TYPE_S]: [GAME_TYPE_SPRING_TRAINING]
};

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TSwingMetricsRow) => row.combinedSwingMetricsData.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TSwingMetricsRow) => {
		if ("team" in row.combinedSwingMetricsData)
			return <TeamColumn teamBam={row.combinedSwingMetricsData.team} childRowCount={row.childData?.length} />;
		return <TeamColumn childRowCount={row.childData?.length} />;
	}
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TSwingMetricsRow) => getLevelDisplayFromLevels(getLevelsFromRow(row), NULL_FILLER_TEXT),
	getSortValueFunction: (row: TSwingMetricsRow) => {
		// Child Rows
		if ("team" in row.combinedSwingMetricsData)
			return row.combinedSwingMetricsData.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
		// Parent Rows with nested data - highest level if multiple levels
		if (row.childData && row.childData.length > 1)
			return Math.min(
				...row.childData.map((childRow: TSwingMetricsRow) => {
					if ("team" in childRow.combinedSwingMetricsData)
						return childRow.combinedSwingMetricsData.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
					return Number.MAX_SAFE_INTEGER;
				})
			);
		return NULL_FILLER_TEXT;
	}
};

export const getSwingsColumn = (throwsFilter: string) => {
	return {
		id: "swings",
		value: "swings",
		label: "Swings",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TSwingMetricsRow) => {
			const value =
				throwsFilter === THROWS_L
					? row.combinedSwingMetricsData.nSwingsVl
					: throwsFilter === THROWS_R
					? row.combinedSwingMetricsData.nSwingsVr
					: throwsFilter === THROWS_OVERALL
					? row.combinedSwingMetricsData.nSwings
					: undefined;
			return value && value !== 0 ? round10(value) : NULL_FILLER_TEXT;
		}
	};
};

export const getBatSpeedColumn = (throwsFilter: string) => {
	return {
		id: "batSpeed",
		value: "batSpeed",
		label: "Bat Speed",
		tooltip: TOOLTIP_SWING_METRICS_BAT_SPEED,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TSwingMetricsRow) =>
			throwsFilter === THROWS_L
				? round10(row.combinedSwingMetricsData.batSpeedVl, -1) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? round10(row.combinedSwingMetricsData.batSpeedVr, -1) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? round10(row.combinedSwingMetricsData.batSpeed, -1) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getVaaColumn = (throwsFilter: string) => {
	return {
		id: "vaa",
		value: "vaa",
		label: "VAA",
		tooltip: TOOLTIP_SWING_METRICS_VERTICAL_ATTACK_ANGLE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TSwingMetricsRow) =>
			throwsFilter === THROWS_L
				? round10(row.combinedSwingMetricsData.attackAngleVerticalVl, -1) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? round10(row.combinedSwingMetricsData.attackAngleVerticalVr, -1) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? round10(row.combinedSwingMetricsData.attackAngleVertical, -1) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getHaaColumn = (throwsFilter: string) => {
	return {
		id: "haa",
		value: "haa",
		label: "HAA",
		tooltip: TOOLTIP_HORIZONTAL_ATTACK_ANGLE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TSwingMetricsRow) =>
			throwsFilter === THROWS_L
				? round10(row.combinedSwingMetricsData.attackAngleHorizontalVl, -1) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? round10(row.combinedSwingMetricsData.attackAngleHorizontalVr, -1) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? round10(row.combinedSwingMetricsData.attackAngleHorizontal, -1) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getVbaColumn = (throwsFilter: string) => {
	return {
		id: "vba",
		value: "vba",
		label: "VBA",
		tooltip: TOOLTIP_VERTICAL_BAT_ANGLE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TSwingMetricsRow) =>
			throwsFilter === THROWS_L
				? round10(row.combinedSwingMetricsData.batAngleVerticalVl, -1) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? round10(row.combinedSwingMetricsData.batAngleVerticalVr, -1) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? round10(row.combinedSwingMetricsData.batAngleVertical, -1) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getHbaColumn = (throwsFilter: string) => {
	return {
		id: "hba",
		value: "hba",
		label: "HBA",
		tooltip: TOOLTIP_HORIZONTAL_BAT_ANGLE,
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: TSwingMetricsRow) =>
			throwsFilter === THROWS_L
				? round10(row.combinedSwingMetricsData.batAngleHorizontalVl, -1) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_R
				? round10(row.combinedSwingMetricsData.batAngleHorizontalVr, -1) ?? NULL_FILLER_TEXT
				: throwsFilter === THROWS_OVERALL
				? round10(row.combinedSwingMetricsData.batAngleHorizontal, -1) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getSwingMetricsColumns = (
	throwsFilter: string
): Array<TColumn<TSwingMetricsRow, keyof TSwingMetricsRow>> => {
	return [
		SEASON_COLUMN,
		TEAM_COLUMN,
		LEVEL_COLUMN,
		getSwingsColumn(throwsFilter),
		getBatSpeedColumn(throwsFilter),
		getVaaColumn(throwsFilter),
		getHaaColumn(throwsFilter),
		getVbaColumn(throwsFilter),
		getHbaColumn(throwsFilter)
	];
};
