import React, { useEffect, useState, useMemo } from "react";

import { useFetch } from "utils/url_helpers";
import { StyledSelect } from "_react/shared/selects";
import {
	TPlayerPlanMetric,
	TLkPlayerPlanMetricType,
	TLkPlayerPlanMetricSubtype,
	TLkPlayerPlanMetricTier,
	TPlayerPlanMetricAvailableFocusArea
} from "_react/playerplan/shared/_types";
import { getMetricLabel } from "_react/playerplan/shared/_helpers";

export type TMetricOption = {
	label: string;
	rawLabel: string;
	value: number;
	format: string | null;
	metricType: TLkPlayerPlanMetricType;
	metricSubtype: TLkPlayerPlanMetricSubtype | null;
	metricTier: TLkPlayerPlanMetricTier | null;
	isQualitative: boolean;
	focusAreaAvailableGoalMetrics: Array<TPlayerPlanMetricAvailableFocusArea>;
};

type TMetricSelect = {
	handleSelect: Function;
	metricTypes?: Array<string>;
	metricSubtypes?: Array<string>;
	metricTiers?: Array<number | null>;
	placeholder?: string;
	value?: Array<number> | null;
	isClearable?: boolean;
	isMulti?: boolean;
	isLoading?: boolean;
	shouldUseAvailabilityMap?: boolean;
	goalMetricId?: number;
	menuPlacement?: "bottom" | "top" | "auto";
	excludeMetricIds?: Array<number>;
	isDisabled?: boolean;
};

function MetricSelect({
	handleSelect,
	metricTypes,
	metricSubtypes,
	metricTiers,
	placeholder = "Select metric...",
	value,
	isClearable = true,
	isMulti = false,
	isLoading = false, // To be used when the select should display loading symbol, even though data has already loaded
	shouldUseAvailabilityMap = false,
	goalMetricId,
	menuPlacement = "auto",
	excludeMetricIds = [],
	isDisabled = false
}: TMetricSelect) {
	const { responseData: metricResponseData, isInProgress } = useFetch(
		`/playerplan/metric?isDeleted=false${metricTypes?.length === 1 ? `&metricTypeLk=${metricTypes[0]}` : ""}${
			metricTiers?.length ? `&metricTierLks=${metricTiers.join()}` : ""
		}`,
		[]
	);
	metricResponseData.sort((a: TPlayerPlanMetric, b: TPlayerPlanMetric) => {
		// first, compare based on tier
		if (typeof a.metricTier?.sortOrder === "number" || typeof b.metricTier?.sortOrder === "number") {
			if (typeof a.metricTier?.sortOrder === "number" && typeof b.metricTier?.sortOrder !== "number") return -1;
			else if (typeof a.metricTier?.sortOrder !== "number" && typeof b.metricTier?.sortOrder === "number")
				return 1;
			else if (
				typeof a.metricTier?.sortOrder === "number" &&
				typeof b.metricTier?.sortOrder === "number" &&
				a.metricTier.sortOrder < b.metricTier.sortOrder
			)
				return -1;
			else if (
				typeof a.metricTier?.sortOrder === "number" &&
				typeof b.metricTier?.sortOrder === "number" &&
				a.metricTier.sortOrder > b.metricTier.sortOrder
			)
				return 1;
		}
		// then, compare based on metric type
		if (a.metricType.label.toLowerCase() < b.metricType.label.toLowerCase()) return -1;
		if (a.metricType.label.toLowerCase() > b.metricType.label.toLowerCase()) return 1;
		// then, compare based on subtype
		if (a.metricSubtype || b.metricSubtype) {
			if (a.metricSubtype && !b.metricSubtype) return -1;
			else if (!a.metricSubtype && b.metricSubtype) return 1;
			else if (
				a.metricSubtype &&
				b.metricSubtype &&
				a.metricSubtype.label.toLowerCase() < b.metricSubtype.label.toLowerCase()
			)
				return -1;
			else if (
				a.metricSubtype &&
				b.metricSubtype &&
				a.metricSubtype.label.toLowerCase() > b.metricSubtype.label.toLowerCase()
			)
				return 1;
		}
		// finally, compare based on label
		if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
		else if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
		return 0;
	});

	const [metricDataSource, setMetricDataSource] = useState<Array<TMetricOption>>([]);

	useEffect(() => {
		if (metricResponseData) {
			setMetricDataSource(
				metricResponseData.map(
					(metric: TPlayerPlanMetric): TMetricOption => ({
						label: getMetricLabel(
							metric.metricTier?.label ?? "",
							metric.label,
							metric.metricType.abbreviation,
							metric.metricSubtype?.label ?? undefined
						),
						rawLabel: metric.label,
						value: metric.id,
						format: metric.format,
						metricType: metric.metricType,
						metricSubtype: metric.metricSubtype ?? null,
						metricTier: metric.metricTier ?? null,
						isQualitative: metric.isQualitative,
						focusAreaAvailableGoalMetrics:
							metric.focusAreaAvailableGoalMetrics?.filter(
								(m: TPlayerPlanMetricAvailableFocusArea) => !m.isDeleted
							) ?? []
					})
				)
			);
		}
	}, [metricResponseData, setMetricDataSource]);

	const metrics: Array<TMetricOption> | null = useMemo(() => {
		if (metricDataSource.length && value) {
			const filteredDataSource = metricDataSource.filter((opt: TMetricOption) =>
				value ? value.includes(opt.value) : false
			);
			return filteredDataSource.length ? filteredDataSource : null;
		}
		return null;
	}, [metricDataSource, value]);

	const valueProps = value === undefined ? {} : metrics ? { value: metrics } : { value: null };

	return (
		<StyledSelect
			options={metricDataSource.filter(
				(opt: TMetricOption) =>
					(metricTypes === undefined || metricTypes.includes(opt.metricType.value)) &&
					(metricSubtypes === undefined || metricSubtypes.includes(opt.metricSubtype?.value ?? "")) &&
					(metricTiers === undefined || metricTiers.includes(opt.metricTier?.value ?? null)) &&
					(excludeMetricIds === undefined || !excludeMetricIds.includes(opt.value)) &&
					(!shouldUseAvailabilityMap ||
						opt.focusAreaAvailableGoalMetrics.length === 0 ||
						(goalMetricId !== undefined &&
							opt.focusAreaAvailableGoalMetrics
								.map((m: TPlayerPlanMetricAvailableFocusArea) => m.goalMetricId)
								.includes(goalMetricId)))
			)}
			onChange={handleSelect}
			placeholder={placeholder}
			isClearable={isClearable}
			isLoading={isInProgress || isLoading}
			{...valueProps}
			isMulti={isMulti}
			styles={{ container: { width: "100%" } }}
			menuPlacement={menuPlacement}
			isDisabled={isDisabled}
		/>
	);
}

export default MetricSelect;
