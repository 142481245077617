import React from "react";

import {
	NULL_VALUE,
	PLAYER_NAME_COLUMN,
	ORG_COLUMN,
	LEVEL_COLUMN,
	AGE_COLUMN,
	THROWS_COLUMN,
	BATS_COLUMN,
	MLS_COLUMN,
	SV_COLUMN,
	SP_RAR_COLUMN,
	RP_RAR_COLUMN,
	TOTAL_BATTING_RAR_COLUMN,
	BATTING_RAA_COLUMN,
	BASERUNNING_RAA_COLUMN,
	DEF_RAA_COLUMN,
	POS_ADJ_COLUMN
} from "_react/projections/pro/leaderboard/_constants";
import { TSimpleHitterProjections, TSimplePitcherProjections } from "_react/shared/data_models/projections/_types";
import PlayerPosition from "_react/shared/ui/presentation/components/PlayerPosition/PlayerPosition";

export const PROJECTIONS_PITCHERS_COLUMNS = [
	PLAYER_NAME_COLUMN,
	ORG_COLUMN,
	LEVEL_COLUMN,
	AGE_COLUMN,
	THROWS_COLUMN,
	MLS_COLUMN,
	SV_COLUMN,
	SP_RAR_COLUMN,
	RP_RAR_COLUMN
];

const POSITION_COLUMN = {
	value: "position",
	label: "Position",
	getValueFunction: (projection: TSimpleHitterProjections | TSimplePitcherProjections) => (
		<PlayerPosition player={projection.player} nullFillerText={NULL_VALUE} isTooltipDisabled={false} />
	)
};

export const PROJECTIONS_HITTERS_COLUMNS = [
	PLAYER_NAME_COLUMN,
	ORG_COLUMN,
	LEVEL_COLUMN,
	AGE_COLUMN,
	POSITION_COLUMN,
	BATS_COLUMN,
	MLS_COLUMN,
	SV_COLUMN,
	TOTAL_BATTING_RAR_COLUMN,
	BATTING_RAA_COLUMN,
	DEF_RAA_COLUMN,
	BASERUNNING_RAA_COLUMN,
	POS_ADJ_COLUMN
];
