import React, { CSSProperties } from "react";

import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { AutoFitTable } from "_react/shared/legacy/tables/AutoFitTable";
import { stripHtml } from "utils/_helpers";
import { IAddendumBLanguage } from "_react/shared/_types/schema/contracts";

const LANGUAGE_DATA_COLUMNS = [
	{
		title: "Custom",
		name: "language_data",
		getCellValue: (row: IAddendumBLanguage) => stripHtml(row.language_data)
	}
];

type TAddendumBLanguageDataTableStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
};

interface IProps {
	tableText: string;
	addendumBLanguageData: IAddendumBLanguage[];
	colorSchemeGroup: ColorSchemeGroup;
	style?: TAddendumBLanguageDataTableStyle;
}

function AddendumBLanguageDataTable({ tableText, addendumBLanguageData, colorSchemeGroup, style }: IProps) {
	return (
		<React.Fragment>
			<Card style={style?.card}>
				<ColoredCardHeader
					colorScheme={colorSchemeGroup.secondary}
					text={tableText}
					style={style?.cardHeader}
				/>
				<AutoFitTable
					columns={LANGUAGE_DATA_COLUMNS}
					isHighlightOnHover={false}
					rows={addendumBLanguageData}
					rowKey="addendum_b_id"
				/>
			</Card>
		</React.Fragment>
	);
}

export default AddendumBLanguageDataTable;
