import React from "react";

import {
	NULL_VALUE,
	F40_MAN_R5_COLUMN,
	PLAYER_COLUMN,
	XWAR_COLUMN,
	SV_COLUMN,
	MLB_PROB_COLUMN,
	LEVEL_COLUMN,
	AGE_COLUMN,
	BATS_COLUMN,
	THROWS_COLUMN
} from "_react/promodel/leaderboard/_constants";
import { IPro2PredictionsSummary } from "_react/shared/data_models/pro2_predictions/_types";
import PlayerPosition from "_react/shared/ui/presentation/components/PlayerPosition/PlayerPosition";

const POSITION_COLUMN = {
	value: "position",
	label: "Position",
	getValueFunction: (leaderboardData: IPro2PredictionsSummary) => (
		<PlayerPosition player={leaderboardData.player} nullFillerText={NULL_VALUE} isTooltipDisabled={false} />
	)
};

export const SIMILAR_PLAYERS_PRO_MODEL_COLUMNS = [
	F40_MAN_R5_COLUMN,
	PLAYER_COLUMN,
	XWAR_COLUMN,
	SV_COLUMN,
	MLB_PROB_COLUMN,
	LEVEL_COLUMN,
	AGE_COLUMN,
	POSITION_COLUMN,
	BATS_COLUMN,
	THROWS_COLUMN
];
