export const AMA = "AMA";
export const PRO = "PRO";
export const INTL = "INTL";

export const SEARCH_RESULT_PLAYER = "player";
export const SEARCH_RESULT_TEAM = "team";
export const SEARCH_RESULT_ROUTES = "routes";
export const SEARCH_RESULT_UNKNOWN = "unknown";

export const SEARCH_DEBOUNCE = 300;
