import React from "react";

import { Flex, Tooltip } from "@chakra-ui/react";

import {
	TGradeThresholdIconShape,
	TGradeThresholdIconStyle
} from "_react/shared/ui/presentation/components/GradeThresholdIcon/_types";
import Check from "_react/shared/ui/icons/Check";
import Dangerous from "_react/shared/ui/icons/Dangerous";
import OutlineWarning from "_react/shared/ui/icons/OutlineWarning";

import { ICON_DANGEROUS, ICON_WARNING } from "_react/shared/ui/presentation/components/GradeThresholdIcon/_constants";

export type TGradeThresholdIconProps = {
	shape: TGradeThresholdIconShape;
	tooltipLabel?: string;
	style?: TGradeThresholdIconStyle;
};

const GradeThresholdIcon = ({ shape, tooltipLabel, style }: TGradeThresholdIconProps) => {
	return (
		<Tooltip hasArrow placement="top" label={tooltipLabel} whiteSpace="pre-wrap" sx={style?.tooltip}>
			<Flex color="gray.500">
				{shape === ICON_WARNING ? (
					<OutlineWarning boxSize="4" sx={style?.icon}></OutlineWarning>
				) : shape === ICON_DANGEROUS ? (
					<Dangerous boxSize="4" sx={style?.icon}></Dangerous>
				) : (
					<Check boxSize="4" sx={style?.icon}></Check>
				)}
			</Flex>
		</Tooltip>
	);
};

export default GradeThresholdIcon;
