import React from "react";
import dayjs from "dayjs";
import { v4 } from "uuid";
import {
	EditorState,
	ContentState,
	convertToRaw,
	convertFromRaw,
	RawDraftContentState,
	CompositeDecorator
} from "draft-js";
import DatePicker from "react-datepicker";

import LkSelect from "_react/inputs/lks/LkSelect";
import UserSelect, { TUserOption } from "_react/shared/selects/UserSelect";
import { $TSFixMe } from "utils/tsutils";
import { ColorSchemeGroup, BLACKWHITE } from "_react/shared/legacy/ui/Colors";
import { TRockyNoteStructure, TRockyNote, TRockyNoteStructureDictionary } from "_react/shared/data_models/notes/_types";
import {
	LinkStyles,
	EditingFieldWrapper,
	TEXT_FIELD_STYLE,
	RowWrapper,
	LabelWrapper,
	LabelSpan,
	NoteLinkTitle,
	NoteLink,
	FieldWrapper
} from "_react/playerpage/notes/_styles";
import { TextField } from "_react/shared/legacy/ui/TextField";
import { RichTextEditor } from "_react/playerpage/notes/RichTextEditor";
import { AgentSelect, TAgentOption } from "_react/shared/selects/AgentSelect";
import AttachFile from "_react/shared/legacy/ui/icons/AttachFile";
import ExitToApp from "_react/shared/legacy/ui/icons/ExitToApp";
import { PlayerSearch, TPlayerSearchOption } from "_react/shared/searches/PlayerSearch";
import { DATEPICKER_WRAPPER_CLASS_NAME } from "_react/playerpage/notes/_constants";

// DraftJS Customizations
function findLinkEntities(contentBlock: $TSFixMe, callback: $TSFixMe, contentState: $TSFixMe) {
	contentBlock.findEntityRanges((character: $TSFixMe) => {
		const entityKey = character.getEntity();
		return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
	}, callback);
}

const Link = (props: $TSFixMe) => {
	const { url } = props.contentState.getEntity(props.entityKey).getData();
	return (
		<a href={url} style={LinkStyles.link} target="_blank">
			{props.children}
		</a>
	);
};

// Helper Functions
export const getNoteTypesFiltered = (cookieValue: string) => {
	return cookieValue.split(",");
};

export const includesNoteType = (noteTypesFiltered: string[], noteType: string) => {
	return noteTypesFiltered.includes(noteType);
};

export const getNewNoteTypesFilters = (noteTypesFiltered: string[], noteType: string) => {
	if (noteTypesFiltered.includes(noteType)) {
		// Remove
		const newNoteTypesFilters = noteTypesFiltered.filter(noteTypeIncluded => noteTypeIncluded !== noteType);
		return newNoteTypesFilters.length === 0 ? ["none"] : newNoteTypesFilters;
	} else {
		// Add
		return [...noteTypesFiltered, noteType];
	}
};

export const isUsingFilters = (noteTypesFiltered: string[], noteTypesAll: string[]) => {
	for (let i = 0; i < noteTypesAll.length; i++) {
		if (!noteTypesFiltered.includes(noteTypesAll[i])) return true;
	}
	return false;
};

export const createNoteStructureDictionary = (rawStructures: TRockyNoteStructure[]) => {
	const noteStructureDictionary: TRockyNoteStructureDictionary = {};

	for (let i = 0; i < rawStructures.length; i++) {
		const noteStructureEntry = rawStructures[i];
		if (noteStructureDictionary[noteStructureEntry.noteType] == null) {
			noteStructureDictionary[noteStructureEntry.noteType] = [noteStructureEntry];
		} else {
			noteStructureDictionary[noteStructureEntry.noteType].push(noteStructureEntry);
			noteStructureDictionary[noteStructureEntry.noteType].sort((a, b) => a.rank - b.rank);
		}
	}
	return noteStructureDictionary;
};

export const formatFieldContent = (note: TRockyNote, structure: TRockyNoteStructure) => {
	let value: string | number | boolean | EditorState | undefined = structure.isInContent
		? note.content[structure.key]
		: note[structure.key as keyof TRockyNote];
	if (structure.type === "date") value = value ? dayjs(value as string).format("MMM D, YYYY") : undefined;
	return value;
};

export const formatFieldEditing = (
	value: string | number | null,
	type: string,
	editing: boolean,
	disabled: boolean,
	onUpdate: (note?: string | number | EditorState | null) => void
) => {
	if (!editing) return value;
	if (type === "text" || type === "link")
		return (
			<TextField
				value={value ? `${value}` : ""}
				onChange={e => onUpdate(e.target.value)}
				style={TEXT_FIELD_STYLE}
				disabled={disabled}
			/>
		);
	if (type === "date") {
		const date = value ? new Date(value) : value;
		return (
			<DatePicker
				onChange={e => onUpdate(e != null ? dayjs(e).format("MMM D, YYYY") : null)}
				placeholderText={"From..."}
				selected={date as Date | null | undefined}
				disabled={disabled}
				isClearable={true}
				showMonthDropdown={true}
				showPopperArrow={false}
				showYearDropdown={true}
				wrapperClassName={DATEPICKER_WRAPPER_CLASS_NAME}
			/>
		);
	}

	return value;
};

export const buildContentComponent = (
	content: string | number | boolean | EditorState | undefined,
	structure: TRockyNoteStructure,
	colorScheme: ColorSchemeGroup,
	editing = false,
	saving = false,
	deleting = false,
	showingDeletedNotes = false,
	handleEditNote: (note?: string | number | EditorState | null) => void
) => {
	const disabled = saving || deleting;
	const showingEditUI = editing || (disabled && !deleting && !showingDeletedNotes);
	const contentLength = `${content}`.length;

	let wrappedContent = (
		<EditingFieldWrapper structure={structure} contentLength={contentLength} showingEditUI={showingEditUI}>
			{structure.type === "long_text"
				? ""
				: formatFieldEditing(
						content as string | number | null,
						structure.type,
						showingEditUI && !structure.readOnly,
						disabled,
						handleEditNote
				  )}
		</EditingFieldWrapper>
	);
	if (structure.type === "long_text") {
		wrappedContent = (
			<RichTextEditor
				showingEditUI={showingEditUI && !showingDeletedNotes}
				handleEditNote={handleEditNote}
				content={content as EditorState}
				structure={structure}
				disabled={disabled}
				colorScheme={BLACKWHITE}
				editing={editing}
				saving={saving}
				datepickerClassName={DATEPICKER_WRAPPER_CLASS_NAME}
			/>
		);
	} else if (structure.type === "select") {
		// TODO: Fix whatever the value needs to be for onChange (the type you have to use is different from what actually comes back)
		wrappedContent = (
			<FieldWrapper>
				<LkSelect
					lkName={structure.source!}
					isClearable={true}
					isMulti={false}
					onChange={(e: $TSFixMe) => handleEditNote(e ? e.value : null)}
					placeholder={`Select ${structure.label}...`}
					valueOnlyValue={content as string | undefined | null}
					forDisplay={!showingEditUI}
					isDisabled={disabled}
				/>
			</FieldWrapper>
		);
	} else if (structure.type === "link") {
		if (showingEditUI) {
			wrappedContent = (
				<TextField
					value={content as string | undefined | null}
					onChange={e => handleEditNote(e.target.value)}
					style={TEXT_FIELD_STYLE}
					disabled={disabled}
				/>
			);
		} else {
			wrappedContent = (
				<NoteLink href={content as string} target="_blank">
					<AttachFile />
					<NoteLinkTitle>View Link</NoteLinkTitle>
					<ExitToApp />
				</NoteLink>
			);
		}
	} else if (structure.type === "custom") {
		if (structure.source === "users") {
			// TODO: Fix whatever the value needs to be for onChange (the type you have to use is different from what actually comes back)
			wrappedContent = (
				<FieldWrapper>
					<UserSelect
						handleSelect={(e: TUserOption) => handleEditNote(e ? e.value : null)}
						placeholder={`Select ${structure.label}...`}
						value={content as number | undefined | null}
						forDisplay={!showingEditUI}
						disabled={disabled}
					/>
				</FieldWrapper>
			);
		} else if (structure.source === "agents") {
			// TODO: Fix whatever the value needs to be for onChange (the type you have to use is different from what actually comes back)
			wrappedContent = (
				<FieldWrapper>
					<AgentSelect
						handleSelect={(e: TAgentOption) =>
							handleEditNote(e ? (e.value && e.value.length > 0 ? e.value[0] : null) : null)
						}
						placeholder={`Select ${structure.label}...`}
						value={content as number | undefined | null}
						disabled={disabled}
						forDisplay={!showingEditUI}
					/>
				</FieldWrapper>
			);
		} else if (structure.source === "player_search" || structure.source === "player_search_by_org") {
			wrappedContent = (
				<FieldWrapper>
					<PlayerSearch
						valueOnlyValue={content as number}
						onSelect={(e: TPlayerSearchOption) => handleEditNote(e?.value ?? null)}
						forDisplay={!editing}
						playerClassification={"PRO"}
						isClearable={true}
						isStyledSelectStyles={true}
					/>
				</FieldWrapper>
			);
		} else if (structure.source === "pitch_type") {
			const pitch_type_filters = [
				{
					key: "value",
					value: ["CB", "CH", "CT", "EP", "FF", "KN", "SI", "SL", "SP", "SV", "SW"]
				}
			];
			wrappedContent = (
				<FieldWrapper>
					<LkSelect
						lkName={"lk_pitch_type"}
						lkFilters={pitch_type_filters}
						isClearable={true}
						isMulti={false}
						onChange={(e: $TSFixMe) => handleEditNote(e ? e.value : null)}
						placeholder={`Select ${structure.label}...`}
						valueOnlyValue={content as string | undefined | null}
						forDisplay={!showingEditUI}
						isDisabled={disabled}
					/>
				</FieldWrapper>
			);
		}
	}
	return (
		<RowWrapper key={structure.key} structure={structure}>
			<LabelWrapper structure={structure}>
				<LabelSpan colorScheme={colorScheme.primary}>{structure.label}:</LabelSpan>
			</LabelWrapper>
			{wrappedContent}
		</RowWrapper>
	);
};

export const generateNewNotePlaceholder = (
	noteType: string,
	playerId: number,
	userId: number | null,
	author: string
): TRockyNote => ({
	createDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
	createUserId: userId,
	date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
	lastChangeDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
	lastChangeUserId: userId,
	noteId: v4(),
	playerId,
	recordStatus: "AP",
	author,
	lastChangeAuthor: author,
	content: "{}",
	noteType,
	isBeingCreated: true
});

// Function to generate a dictionary of values available to pre-populate various fields on a new note
export const getPrepopulationValues = (
	user: $TSFixMe // TODO: Update this when the file it's sourced from is typed
) => ({
	user
});

// Function to take an empty new note and populate it with any designated pre-population values
export const processPrepopulation = (
	newNote: TRockyNote,
	structure: TRockyNoteStructure[],
	prePopulationFields: $TSFixMe
) => {
	// Generate new note object
	let prePopulatedNote = { ...newNote, content: JSON.parse(newNote.content) };
	// Iterate over each field
	for (let i = 0; i < structure.length; i++) {
		// Check if the field should be pre-populated
		if (structure[i].prePopulate != null) {
			// Extract the pre-population rule
			const prePopulateInstructions = structure[i].prePopulate!.split(".");
			// Get the actual pre-population value
			const prePopulateValue = prePopulateInstructions.reduce((currentValue, nextProperty) => {
				if (currentValue[nextProperty]) return currentValue[nextProperty];
				return currentValue;
			}, prePopulationFields);
			// Set the pre-population value
			if (structure[i].isInContent) {
				prePopulatedNote.content[structure[i].key] = prePopulateValue;
			} else {
				prePopulatedNote = { ...prePopulatedNote, [structure[i].key as keyof TRockyNote]: prePopulateValue };
			}
		}
	}
	prePopulatedNote.content = JSON.stringify(prePopulatedNote.content);
	return prePopulatedNote;
};

export const buildNewNote = (
	note: TRockyNote,
	structure: TRockyNoteStructure,
	updatedContent?: string | number | EditorState | null
) => {
	if (!structure.isInContent) return { ...note, [structure.key]: updatedContent };
	return { ...note, content: { ...note.content, [structure.key]: updatedContent } };
};

export const getValueContentStateFromDBValue = (value?: string | null | RawDraftContentState | EditorState) => {
	const legacyNotePlaintext = value != null && typeof value !== "string" ? false : true;

	const decorator = new CompositeDecorator([
		{
			strategy: findLinkEntities,
			component: Link
		}
	]);
	return EditorState.createWithContent(
		legacyNotePlaintext
			? ContentState.createFromText(value ? (value as string) : "")
			: convertFromRaw(value as RawDraftContentState),
		decorator
	);
};

const preProcessNoteField = (note: TRockyNote, structure: TRockyNoteStructure) => {
	const content = note.content
		? structure.isInContent
			? note.content[structure.key]
			: note[structure.key as keyof TRockyNote]
		: null;

	return structure.type === "long_text"
		? getValueContentStateFromDBValue(
				typeof content === "string" && content.includes("{") ? JSON.parse(content) : content
		  )
		: content;
};

export const preProcessNote = (note: TRockyNote, structure: TRockyNoteStructure[]): TRockyNote => {
	let processedNote = {
		...note,
		content: typeof note.content === "string" ? JSON.parse(note.content) : note.content
	} as TRockyNote;
	for (let i = 0; i < structure.length; i++) {
		const fieldStructure = structure[i];
		if (fieldStructure.isInContent)
			processedNote = {
				...processedNote,
				content: {
					...processedNote.content,
					[fieldStructure.key]: preProcessNoteField(processedNote, fieldStructure)
				}
			};
		else
			processedNote = {
				...processedNote,
				[fieldStructure.key]: preProcessNoteField(processedNote, fieldStructure)
			};
	}

	return processedNote;
};

export const exportNoteForSaving = (note: TRockyNote, structure: TRockyNoteStructure[]) => {
	let noteJson: TRockyNote = JSON.parse(JSON.stringify(note));
	for (let i = 0; i < structure.length; i++) {
		const fieldStructure = structure[i];
		// Get Value
		let value = fieldStructure.isInContent
			? note.content[fieldStructure.key]
			: note[fieldStructure.key as keyof TRockyNote];
		// Convert Value
		if (fieldStructure.type === "date" && value != null) value = dayjs(value).format("YYYY-MM-DD HH:mm:ss");
		else if (fieldStructure.type === "long_text") {
			value = JSON.stringify(convertToRaw(value.getCurrentContent())).replace(/  +/g, " ");
		}
		// Add value back
		if (fieldStructure.isInContent) noteJson.content[fieldStructure.key] = value;
		else {
			noteJson = { ...noteJson, [fieldStructure.key as keyof TRockyNote]: value };
		}
	}
	delete noteJson.author;
	delete noteJson.lastChangeAuthor;
	delete noteJson.isBeingCreated;
	delete noteJson.canEdit;
	noteJson.content = JSON.stringify(noteJson.content);
	return noteJson;
};

export const humanize = (str: string) => {
	const frags = str.split("_");
	for (let i = 0; i < frags.length; i++) {
		frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
	}
	return frags.join(" ");
};
