import React, { useEffect, useMemo } from "react";
import { useToast, VStack, HStack, Box } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";
import { ASC } from "_react/shared/ui/presentation/components/Table/_constants";
import { TSeasonalSurplusValue } from "_react/shared/data_models/surplus_value/_types";

import SeasonalSurplusValuesTableMachine, {
	FETCHING_SEASONAL_SURPLUS_VALUES,
	SET_BIRTH_DATE,
	SET_SEASONAL_SURPLUS_VALUES,
	SET_PLAYER_ID,
	SET_VIEW
} from "_react/shared/ui/data/tables/SeasonalSurplusValuesTable/_machine";
import {
	SEASONAL_SURPLUS_VALUE_COLUMNS,
	SEASONAL_SURPLUS_VALUE_PERCENTAGE_COLUMNS
} from "_react/shared/ui/data/tables/SeasonalSurplusValuesTable/_constants";

export type TSeasonalSurplusValuesTableData = {
	seasonalSurplusValues?: Array<TSeasonalSurplusValue>;
	birthDate?: string | null;
	isLoading?: boolean;
};

type TSeasonalSurplusValuesTableProps = {
	title?: string;
	playerId?: number;
	data?: TSeasonalSurplusValuesTableData;
	shouldFetchData?: boolean;
	columns?: Array<string>;
	tableProps?: TTableProps<TSeasonalSurplusValue, keyof TSeasonalSurplusValue>;
};

const SeasonalSurplusValuesTable = ({
	title,
	playerId,
	data,
	shouldFetchData = true,
	columns,
	tableProps
}: TSeasonalSurplusValuesTableProps) => {
	const toast = useToast();

	const [current, send] = useMachine(SeasonalSurplusValuesTableMachine(playerId, data, shouldFetchData, toast));
	const context = current.context;
	const { seasonalSurplusValues, birthDate, view } = context;

	const isFetchingSeasonalSurplusValues = current.matches(FETCHING_SEASONAL_SURPLUS_VALUES);
	const isLoading = shouldFetchData ? isFetchingSeasonalSurplusValues : data?.isLoading;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.birthDate !== birthDate && !shouldFetchData) {
			send({ type: SET_BIRTH_DATE, data: data?.birthDate });
		}
	}, [data?.birthDate, shouldFetchData, send, birthDate]);

	useEffect(() => {
		if (data?.seasonalSurplusValues !== seasonalSurplusValues && !shouldFetchData) {
			send({ type: SET_SEASONAL_SURPLUS_VALUES, data: data?.seasonalSurplusValues });
		}
	}, [data?.seasonalSurplusValues, shouldFetchData, send, seasonalSurplusValues]);

	const filteredColumns = useMemo(() => {
		if (view === "probabilities") {
			if (!columns) return SEASONAL_SURPLUS_VALUE_PERCENTAGE_COLUMNS;
			return SEASONAL_SURPLUS_VALUE_PERCENTAGE_COLUMNS.filter(
				(col: TColumn<TSeasonalSurplusValue, keyof TSeasonalSurplusValue>) => columns.includes(col.value)
			);
		}
		if (!columns) return SEASONAL_SURPLUS_VALUE_COLUMNS;
		return SEASONAL_SURPLUS_VALUE_COLUMNS.filter(
			(col: TColumn<TSeasonalSurplusValue, keyof TSeasonalSurplusValue>) => columns.includes(col.value)
		);
	}, [columns, view]);

	const setView = (view: string) => {
		send({ type: SET_VIEW, value: view });
	};

	const seasonalSurplusValuesDetailed = useMemo(() => {
		return seasonalSurplusValues?.map((seasonalSurplusValue: TSeasonalSurplusValue) => ({
			...seasonalSurplusValue,
			birthDate: birthDate
		}));
	}, [seasonalSurplusValues, birthDate]);

	return (
		<VStack alignItems="start">
			<HStack w="100%" justify="space-between">
				{title && (
					<Box fontFamily="heading" fontSize="md" fontWeight="bold">
						{title}
					</Box>
				)}
				<ToggleButton<string>
					toggleOptions={[
						{ value: "overview", label: "Overview" },
						{ value: "probabilities", label: "Probabilities" }
					]}
					value={view}
					onSelect={setView}
				/>
			</HStack>
			<Table<TSeasonalSurplusValue, keyof TSeasonalSurplusValue>
				columns={filteredColumns as Array<TColumn<TSeasonalSurplusValue, keyof TSeasonalSurplusValue>>}
				data={seasonalSurplusValuesDetailed}
				isLoadingData={isLoading}
				emptyDataDisplayText={"No Seasonal Surplus Values"}
				defaultSortColumns={[
					{
						columnValue: "projectedSeason",
						sortDirection: ASC
					}
				]}
				{...tableProps}
			/>
		</VStack>
	);
};

export default SeasonalSurplusValuesTable;
