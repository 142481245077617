import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { CREAM } from "_react/shared/legacy/ui/Colors";
import styled from "@emotion/styled";
import { withSize } from "react-sizeme";
import { $TSFixMe } from "utils/tsutils";

type MenuProps = {
	absolute?: boolean;
	anchorEl?: $TSFixMe;
	autocomplete?: boolean;
	boundsOverride?: $TSFixMe;
	children: $TSFixMe;
	id?: string;
	maxHeight?: number;
	onClose?: $TSFixMe;
	overflow?: string;
	open?: boolean;
	style?: $TSFixMe;
	size?: $TSFixMe;
};

const StyledMenu = styled.div<{ overflow: string; absolute: boolean }>(
	{
		borderRadius: "3px",
		boxShadow:
			"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
		backgroundColor: CREAM,
		zIndex: 1000
	},
	props => ({
		position: props.absolute ? "absolute" : "fixed",
		overflow: props.overflow
	})
);

export const MenuComponent: FunctionComponent<MenuProps> = ({
	absolute = false,
	anchorEl,
	autocomplete = false,
	boundsOverride,
	children,
	id,
	maxHeight,
	onClose = () => null,
	overflow = "auto",
	open = false,
	style = {},
	size
}) => {
	const [boundingClientRect, setBoundingClientRect] = useState({
		top: 0,
		left: 0,
		width: null,
		bottom: null,
		valid: false
	});
	const [scrollOffsetStartHeight, setScrollOffsetStartHeight] = useState(window.scrollY);
	const [scrollOffsetStartWidth, setScrollOffsetStartWidth] = useState(window.scrollX);
	const [scrollOffsetHeight, setScrollOffsetHeight] = useState(window.scrollY);
	const [scrollOffsetWidth, setScrollOffsetWidth] = useState(window.scrollY);

	// Set the anchorEl Position
	const valueToUse = anchorEl ? anchorEl.getBoundingClientRect() : { top: 0, left: 0, width: null, bottom: null };
	useEffect(() => {
		if (valueToUse != null) {
			const { top, left } = valueToUse;
			const newRect = {
				top: boundsOverride ? boundsOverride.top : top,
				left: boundsOverride ? boundsOverride.left : left,
				width: boundsOverride ? boundsOverride.width : null,
				bottom: boundsOverride ? boundsOverride.bottom : null,
				valid: true
			};
			if (
				(top > 0 || left > 0) &&
				(newRect.top !== boundingClientRect.top ||
					newRect.left !== boundingClientRect.left ||
					newRect.width !== boundingClientRect.width ||
					newRect.bottom !== boundingClientRect.bottom)
			) {
				// Got a new, valid, boundingRect
				setBoundingClientRect({
					top: boundsOverride ? boundsOverride.top : top,
					left: boundsOverride ? boundsOverride.left : left,
					width: boundsOverride ? boundsOverride.width : null,
					bottom: boundsOverride ? boundsOverride.bottom : null,
					valid: true
				});
				setScrollOffsetStartHeight(window.scrollY);
				setScrollOffsetStartWidth(window.scrollX);
				setScrollOffsetHeight(window.scrollY);
				setScrollOffsetWidth(window.scrollX);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueToUse, boundsOverride]);

	// Handle Scrolling and Clicking
	const openRef = useRef(open);
	useEffect(() => {
		openRef.current = open;
	}, [open, openRef]);
	useEffect(() => {
		const scrollHandler = () => {
			if (openRef?.current) {
				setScrollOffsetHeight(window.scrollY);
				setScrollOffsetWidth(window.scrollX);
			}
		};
		const clickHandler = () => {
			if (openRef?.current) {
				// Hide
				if (!autocomplete) {
					onClose();
				}
			}
		};
		window.addEventListener("scroll", scrollHandler);
		window.addEventListener("mousedown", clickHandler);
		return () => {
			window.removeEventListener("scroll", scrollHandler);
			window.removeEventListener("mousedown", clickHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Reset
	useEffect(() => {
		if (!open) {
			setBoundingClientRect({
				top: 0,
				left: 0,
				width: null,
				bottom: null,
				valid: false
			});
		}
	}, [open]);

	// Construct the initial position
	const { top, left, width, bottom } = boundingClientRect;
	const bounds: $TSFixMe = {
		top: top,
		left: left,
		width,
		bottom,
		right: null
	};

	// Scroll Offset
	if (!absolute) {
		bounds.left = bounds.left == null ? null : bounds.left - (scrollOffsetWidth - scrollOffsetStartWidth);
		bounds.top = bounds.top == null ? null : bounds.top - (scrollOffsetHeight - scrollOffsetStartHeight);
	}

	if (autocomplete) bounds.top = anchorEl != null ? anchorEl.getBoundingClientRect().height + 1 : 0;

	// Position Corrections
	if (boundsOverride == null) {
		// Position just above and to the left
		bounds.top = bounds.top - 5;
		bounds.left = bounds.left - 5;
	}
	// Trim at min 10
	bounds.top = bounds.top > 10 ? bounds.top : 10;
	bounds.left = bounds.left > 10 ? bounds.left : 10;

	// If too far left (<100) then switch to right justified
	if (size && size.width > 0 && bounds.left + size.width > window.innerWidth) {
		bounds.left = null;
		bounds.right = 10;
	} else if ((size == null || size.width <= 0) && bounds.left + 100 > window.innerWidth) {
		bounds.left = null;
		bounds.right = 10;
	}
	// if(bounds.left + 400 > window.innerWidth){
	// 	bounds.left = null;
	// 	bounds.right = 10;
	// }

	// If too far down, pin bottom to the top of the textfield
	if (bounds.top + 74 > window.innerHeight) {
		bounds.top = null;
		bounds.bottom = 10;
		if (boundsOverride != null && boundsOverride.autocompleteHeight != null && !absolute) {
			// 10 + TextField height + scroll offset
			const newBottom =
				10 +
				boundsOverride.autocompleteHeight +
				(window.innerHeight - boundsOverride.top) +
				(scrollOffsetHeight - scrollOffsetStartHeight);
			bounds.bottom = newBottom > 10 ? newBottom : bounds.bottom;
		}
	}

	// Max Width and Height
	const max: $TSFixMe = {
		width: null,
		height: null
	};
	if (bounds.left != null) {
		max.width = window.innerWidth - 10 - bounds.left;
	} else if (bounds.right != null) {
		max.width = window.innerWidth - 10 - bounds.right;
	}
	if (bounds.top != null) {
		max.height = window.innerHeight - 10 - bounds.top;
	} else if (bounds.bottom != null) {
		max.height = window.innerHeight - 10 - bounds.bottom;
	}
	// Absolute Max Height
	const absoluteMax = maxHeight || 350;
	if (max.height != null && max.height > absoluteMax) max.height = absoluteMax;

	return (
		<StyledMenu
			absolute={absolute}
			id={id}
			onClick={e => e.stopPropagation()}
			overflow={overflow}
			// TODO: add these prop derived styles to the StyledMenu
			style={{
				width: bounds.width,
				top: bounds.top,
				left: bounds.left,
				right: bounds.right,
				bottom: bounds.bottom,
				maxWidth: max.width,
				maxHeight: max.height,
				visibility: open && boundingClientRect.valid ? "visible" : "hidden",
				...style
			}}
		>
			{open && children}
		</StyledMenu>
	);
};

export const Menu = withSize()(MenuComponent);
// export const Menu = MenuComponent;
