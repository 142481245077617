import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { defaultColorScheme } from "_react/forms/Form";

const tables = [
	{
		title: "Delivery",
		columns: ["Scale", "Present Delivery", "Future Delivery"],
		rows: [
			["20", "Brutal movement patterns and stability.", "Brutal movement patterns and stability."],
			[
				"30",
				"Well below average movement patterns, sequencing or stability. Rigid or violent. Poor positions that are difficult to repeat. Major red flags.",
				"Well below average movement patterns, sequencing or stability. Rigid or violent. Poor positions that are difficult to repeat. Major red flags."
			],
			[
				"40",
				"Has inefficiencies or lacks strength to repeat consistently. Below average movement patterns, sequencing or stability",
				"Has inefficiencies or lacks strength to repeat consistently. Below average movement patterns, sequencing or stability (unstable posting leg, posture change, late head movement)"
			],
			[
				"50",
				"Stable, repeatable, efficient mechanics with either average movement patterns and sequencing OR above average movement pattern s with below avg stability (unstable posting leg, posture change, late head movement)",
				"Stable, repeatable, efficient mechanics with either average movement patterns and sequencing OR above average movement pattern s with below avg stability (unstable posting leg, posture change, late head movement)"
			],
			[
				"60",
				"Athletic, repeatable, efficient delivery with above average movement patterns, sequencing and stability.",
				"Athletic, repeatable, efficient delivery with above average movement patterns, sequencing and stability."
			],
			[
				"70",
				"",
				"Elite athletic, repeatable, efficient delivery with plus movement patterns, sequencing and stability."
			],
			[
				"80",
				"",
				"Elite athletic, repeatable, efficient delivery with plus, plus movement patterns, sequencing and stability."
			]
		]
	},
	{
		title: "Control",
		columns: ["Scale", "SP Strike%", "SP BB%", "RP Strike%", "RP BB%"],
		rows: [
			["20", "57%", "15%", "56%", "17%"],
			["30", "59%", "13%", "59%", "15%"],
			["40", "62%", "10%", "61%", "12%"],
			["45", "63%", "9%", "63%", "11%"],
			["50", "64%", "8%", "64%", "9%"],
			["55", "65%", "7%", "65%", "8%"],
			["60", "67%", "5%", "67%", "7%"],
			["70", "69%", "3%", "69%", "4%"],
			["80", "71%", "1%", "72%", "1%"]
		]
	},
	{
		title: "Arm Action",
		columns: ["Scale", "Description"],
		rows: [
			[
				"30",
				"Brutal - hinders command/control. Major injury risk. Inverted or off-line. Long outside 90 degrees. Late at foot plant. One piece. Below avg external rotation."
			],
			[
				"50",
				"Consistent arm action that is smooth. Any wrap, plunge or other flaw is minor and does not hinder his strike throwing ability or give major injury concern."
			],
			[
				"70",
				"Clean, effortless arm action with no wrap, hook, or other flaw. On-time in good, slightly inside 90 degree position at foot plant with above average external rotation."
			]
		]
	},
	{
		title: "Feel for Pitching",
		columns: ["Scale", "Description"],
		rows: [
			["20", "Struggles to get the ball over the plate. No touch and feel. Poor FB command"],
			[
				"30",
				"Frequently behind in the count with little feel to land secondary pitches. Very little touch and feel"
			],
			[
				"40",
				"Gets FB over the plate with occasional ability to land secondary pitches. Struggles to finish hitters"
			],
			[
				"50",
				"Consistently works ahead in the count primarily with FB, occ with a secondary pitch. Shows the ability to expand the zone when ahead in the count. Occasional use of secondary when behind in the count. Ability to execute a modest game plan based on strengths"
			],
			[
				"60",
				"Consistently executes quality pitches early in the count with equal confidence in FB and secondary stuff. Confident use of secondary when behind in the count, consistently executes and expands when ahead in the count. Pitches to his strengths effectively"
			],
			[
				"70",
				"Makes rare mistakes over the middle of the plate. Ability to avoid patterns with consistent quality mix of stuff. Can execute precise game plan and is able to attack hitters based on their weaknesses"
			],
			[
				"80",
				"Master of the art of pitching. Is able to pitch with no identifiable pattern. All pitches are equally effective. Shows extraordinary touch and feel"
			]
		]
	},
	{
		title: "Deception",
		columns: ["Scale", "Description"],
		rows: [
			[
				"30",
				"Ball is easily visible at separation thru release. Consistent quality swings against on any FB over the plate with rare swing & miss"
			],
			["50", "Ball may or may not be visible thru arm circle, but it does not add nor detract from deception"],
			[
				"70",
				"Creates swing and miss and/or poor contact with regularity. Hides by really well and tunnels pitches"
			]
		]
	}
];

const AmaPitchingTooltip = () => {
	return (
		<div
			style={{
				padding: "15px"
			}}
		>
			<div>
				<div
					style={{
						overflow: "scroll"
					}}
				>
					{tables.map((table, idx) => {
						return (
							<Table key={idx} style={{ marginTop: "25px" }}>
								<TableHead
									style={{
										backgroundColor: defaultColorScheme.group.backgroundColor
									}}
								>
									<TableRow>
										<TableCell
											colSpan={table.columns.length}
											style={{
												color: defaultColorScheme.group.color,
												fontSize: "16px",
												textAlign: "center"
											}}
										>
											{table.title}
										</TableCell>
									</TableRow>
									{table.subTitle != null && (
										<TableRow>
											<TableCell
												colSpan={table.columns.length}
												style={{
													color: defaultColorScheme.group.color,
													fontSize: "12px",
													textAlign: "center"
												}}
											>
												{table.subTitle}
											</TableCell>
										</TableRow>
									)}
									{table.description != null && (
										<TableRow>
											<TableCell
												colSpan={table.columns.length}
												style={{
													color: defaultColorScheme.group.color,
													textAlign: "center"
												}}
											>
												{table.description}
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										{table.columns.map((column, idx) => {
											return (
												<TableCell
													key={idx}
													style={{
														color: defaultColorScheme.group.color,
														textAlign: "center",
														textTransform: "capitalize"
													}}
												>
													{column}
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
								<TableBody
									style={{
										border: "1px solid #dfe0df"
									}}
								>
									{table.rows.map((row, idx) => {
										return (
											<TableRow key={idx}>
												{row.map((cell, idx) => {
													return (
														<TableCell
															key={idx}
															style={{
																textAlign: "center",
																fontWeight: idx === 0 ? "700" : ""
															}}
														>
															{cell}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default AmaPitchingTooltip;
