import React from "react";
import { Badge } from "@chakra-ui/react";

import { NO_LEVEL } from "_react/shared/ui/presentation/components/TeamLevelBadge/_constants";

type TTeamLevelBadgeProps = {
	level?: string | null;
	nullFillerText?: string;
	style?: React.CSSProperties;
};

const TeamLevelBadge = ({ level, nullFillerText = "", style }: TTeamLevelBadgeProps) => {
	return (
		<Badge
			variant="solid"
			borderRadius="lg"
			bg={`bg${level ?? NO_LEVEL}`}
			color={`color${level ?? NO_LEVEL}`}
			sx={style}
		>
			{level ?? nullFillerText}
		</Badge>
	);
};

export default TeamLevelBadge;
