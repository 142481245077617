import React from "react";
import PropTypes from "prop-types";

const BlockLabel = ({ label, content, height = 40, width = 85, color = "#767676" }) => {
	return (
		<div
			style={{
				height,
				width,
				backgroundColor: color,
				position: "relative"
			}}
		>
			<div
				style={{
					height: height * 0.3,
					width: "100%",
					display: "inline-block",
					backgroundColor: "#333",
					top: 0,
					position: "absolute",
					left: 0
				}}
			>
				<div
					style={{
						textAlign: "center",
						color: "white",
						fontSize: 10
					}}
				>
					{label}
				</div>
			</div>
			<div
				style={{
					height: height * 0.7,
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					top: height * 0.3,
					position: "absolute",
					left: 0
				}}
			>
				<div
					style={{
						textAlign: "center",
						color: "white",
						verticalAlign: "middle"
					}}
				>
					{content}
				</div>
			</div>
		</div>
	);
};

BlockLabel.propTypes = {
	color: PropTypes.string,
	content: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	height: PropTypes.number,
	label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	width: PropTypes.number
};

export default BlockLabel;
