import React from "react";
import { Box, Flex } from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import PlayerHeadshot from "_react/shared/ui/presentation/components/PlayerHeadshot/PlayerHeadshot";

const EntityHeaderLoading = () => {
	const loadingContent = useBreakpointValue({
		base: (
			<>
				<Flex className="loading-item-transparent" height="8xl" bg="gray.800" justify="center">
					<Box position="relative" marginTop="6" zIndex="docked">
						<PlayerHeadshot />
					</Box>
				</Flex>
			</>
		),
		md: (
			<>
				<Flex className="loading-item-transparent" height="24" bg="gray.800" padding="2">
					<Box position="relative" top="2" marginLeft="12" zIndex="docked">
						<PlayerHeadshot />
					</Box>
				</Flex>
			</>
		),
		lg: (
			<>
				<Flex className="loading-item-transparent" height="16" bg="gray.800" padding="2">
					<Box position="relative" top="2" marginLeft="12" zIndex="docked">
						<PlayerHeadshot />
					</Box>
				</Flex>
			</>
		)
	});

	return <>{loadingContent}</>;
};

export default EntityHeaderLoading;
