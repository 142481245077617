import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { fetchPlayerMetricRollingAverages } from "_react/shared/data_models/metric/_network";
import { getCancelSource } from "utils/url_helpers";
import { IMetricRollingAverages, TMetricGroup } from "_react/shared/data_models/metric/_types";
import { BATS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";

import { TPitcherMetricOverTimeStreamGraphData } from "_react/shared/ui/data/plots/PitcherMetricOverTimeStreamGraph/PitcherMetricOverTimeStreamGraph";

const ROLLING_AVERAGES_CANCEL_SOURCE = "rollingAverages";

export type TPitcherMetricOverTimeStreamGraphCancelSource = {
	[ROLLING_AVERAGES_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TRollingAverages = Record<string, Array<IMetricRollingAverages> | null>;

export type TPitcherMetricOverTimeStreamGraphContext = {
	batsFilter: string;
	throwsFilter?: string;
	playerId?: number;
	lastPlayerId?: number;
	playerClassification?: string;
	lastPlayerClassification?: string;
	metricGroup?: TMetricGroup;
	lastMetricGroup?: TMetricGroup;
	seasonFilter: number;
	lastSeasonFilter: number;
	shouldFetchData?: boolean;
	rollingAverages: TRollingAverages;
	cancelSources: TPitcherMetricOverTimeStreamGraphCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPitcherMetricOverTimeStreamGraphStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches rolling averages data for a player and metric group
				rollingAverages: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_ROLLING_AVERAGES = "SET_ROLLING_AVERAGES";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_CLASSIFICATION = "SET_PLAYER_CLASSIFICATION";
export const SET_METRIC_GROUP = "SET_METRIC_GROUP";
export const SET_SEASON_FILTER = "SET_SEASON_FILTER";
export const SET_BATS_FILTER = "SET_BATS_FILTER";
export const SET_THROWS_FILTER = "SET_THROWS_FILTER";
export const FETCHING_ROLLING_AVERAGES = {
	initialized: { rollingAverages: "fetching" }
};

const FETCH_ROLLING_AVERAGES_DONE = "done.invoke.fetchingRollingAverages:invocation[0]";

type TRollingAveragesEvent = {
	type: typeof SET_ROLLING_AVERAGES;
	data: TRollingAverages;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TSetPlayerClassificationEvent = {
	type: typeof SET_PLAYER_CLASSIFICATION;
	data: string | undefined;
};
type TSetMetricGroupEvent = {
	type: typeof SET_METRIC_GROUP;
	data: TMetricGroup | undefined;
};
type TSetSeasonFilterEvent = {
	type: typeof SET_SEASON_FILTER;
	data: number;
};
type TSetBatsFilterEvent = {
	type: typeof SET_BATS_FILTER;
	data: string;
};
type TSetThrowsFilterEvent = {
	type: typeof SET_THROWS_FILTER;
	data: string | undefined;
};
type TFetchRollingAveragesEvent = {
	type: typeof FETCH_ROLLING_AVERAGES_DONE;
	data: Array<IMetricRollingAverages>;
};

type TPitcherMetricOverTimeStreamGraphEvent =
	| TRollingAveragesEvent
	| TSetPlayerIdEvent
	| TSetPlayerClassificationEvent
	| TSetMetricGroupEvent
	| TSetSeasonFilterEvent
	| TSetBatsFilterEvent
	| TSetThrowsFilterEvent
	| TFetchRollingAveragesEvent;

export type TPitcherMetricOverTimeStreamGraphSend = Interpreter<
	TPitcherMetricOverTimeStreamGraphContext,
	IPitcherMetricOverTimeStreamGraphStateSchema,
	TPitcherMetricOverTimeStreamGraphEvent
>["send"];

const PitcherMetricOverTimeStreamGraphMachine = (
	batsFilterProp: string,
	throwsFilterProp: string | undefined,
	seasonFilterProp: number,
	playerIdProp?: number,
	playerClassificationProp?: string,
	metricGroupProp?: TMetricGroup,
	shouldFetchData = true,
	data?: TPitcherMetricOverTimeStreamGraphData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TPitcherMetricOverTimeStreamGraphContext,
		IPitcherMetricOverTimeStreamGraphStateSchema,
		TPitcherMetricOverTimeStreamGraphEvent
	>(
		{
			id: "PitcherMetricOverTimeStreamGraph",
			initial: "initializing",
			context: {
				batsFilter: batsFilterProp,
				throwsFilter: throwsFilterProp,
				seasonFilter: seasonFilterProp,
				lastSeasonFilter: seasonFilterProp,
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				playerClassification: playerClassificationProp,
				lastPlayerClassification: playerClassificationProp,
				metricGroup: metricGroupProp,
				lastMetricGroup: metricGroupProp,
				shouldFetchData: shouldFetchData,
				rollingAverages: data?.rollingAverages ?? {},
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: {
						target: "initialized"
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_ROLLING_AVERAGES]: { actions: "setRollingAverages" },
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_PLAYER_CLASSIFICATION]: { actions: "setPlayerClassification" },
						[SET_METRIC_GROUP]: { actions: "setMetricGroup" },
						[SET_SEASON_FILTER]: { actions: "setSeasonFilter" },
						[SET_BATS_FILTER]: { actions: "setBatsFilter" },
						[SET_THROWS_FILTER]: { actions: "setThrowsFilter" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						rollingAverages: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingRollingAverages",
												cond: "shouldFetchRollingAverages"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingRollingAverages",
									entry: ["refreshRollingAveragesCancelSource"],
									invoke: {
										src: "fetchRollingAverages",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchRollingAveragesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchRollingAveragesErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TPitcherMetricOverTimeStreamGraphContext,
					_event: TPitcherMetricOverTimeStreamGraphEvent
				) =>
					context.playerId !== context.lastPlayerId ||
					context.playerClassification !== context.lastPlayerClassification ||
					context.metricGroup !== context.lastMetricGroup ||
					context.seasonFilter !== context.lastSeasonFilter,
				shouldFetchRollingAverages: (
					context: TPitcherMetricOverTimeStreamGraphContext,
					_event: TPitcherMetricOverTimeStreamGraphEvent
				) => {
					const { rollingAverages, batsFilter, throwsFilter, playerId } = context;
					return (
						rollingAverages[`${batsFilter}-${throwsFilter}`] === undefined &&
						shouldFetchData &&
						playerId !== undefined
					);
				}
			},
			actions: {
				setRollingAverages: assign<
					TPitcherMetricOverTimeStreamGraphContext,
					TPitcherMetricOverTimeStreamGraphEvent
				>({
					rollingAverages: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						event: TPitcherMetricOverTimeStreamGraphEvent
					) => {
						if (event.type !== SET_ROLLING_AVERAGES) return context.rollingAverages;
						return event.data;
					},
					cancelSources: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						event: TPitcherMetricOverTimeStreamGraphEvent
					) => {
						if (event.type !== SET_ROLLING_AVERAGES) return context.cancelSources;
						if (context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE] != null)
							context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TPitcherMetricOverTimeStreamGraphContext, TPitcherMetricOverTimeStreamGraphEvent>({
					playerId: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						event: TPitcherMetricOverTimeStreamGraphEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setPlayerClassification: assign<
					TPitcherMetricOverTimeStreamGraphContext,
					TPitcherMetricOverTimeStreamGraphEvent
				>({
					playerClassification: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						event: TPitcherMetricOverTimeStreamGraphEvent
					) => {
						if (event.type !== SET_PLAYER_CLASSIFICATION) return context.playerClassification;
						return event.data;
					}
				}),
				setMetricGroup: assign<
					TPitcherMetricOverTimeStreamGraphContext,
					TPitcherMetricOverTimeStreamGraphEvent
				>({
					metricGroup: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						event: TPitcherMetricOverTimeStreamGraphEvent
					) => {
						if (event.type !== SET_METRIC_GROUP) return context.metricGroup;
						return event.data;
					}
				}),
				setSeasonFilter: assign<
					TPitcherMetricOverTimeStreamGraphContext,
					TPitcherMetricOverTimeStreamGraphEvent
				>({
					seasonFilter: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						event: TPitcherMetricOverTimeStreamGraphEvent
					) => {
						if (event.type !== SET_SEASON_FILTER) return context.seasonFilter;
						return event.data;
					}
				}),
				setBatsFilter: assign<TPitcherMetricOverTimeStreamGraphContext, TPitcherMetricOverTimeStreamGraphEvent>(
					{
						batsFilter: (
							context: TPitcherMetricOverTimeStreamGraphContext,
							event: TPitcherMetricOverTimeStreamGraphEvent
						) => {
							if (event.type !== SET_BATS_FILTER) return context.batsFilter;
							return event.data;
						}
					}
				),
				setThrowsFilter: assign<
					TPitcherMetricOverTimeStreamGraphContext,
					TPitcherMetricOverTimeStreamGraphEvent
				>({
					throwsFilter: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						event: TPitcherMetricOverTimeStreamGraphEvent
					) => {
						if (event.type !== SET_THROWS_FILTER) return context.throwsFilter;
						return event.data;
					}
				}),
				clearContext: assign<TPitcherMetricOverTimeStreamGraphContext, TPitcherMetricOverTimeStreamGraphEvent>({
					lastPlayerId: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						_event: TPitcherMetricOverTimeStreamGraphEvent
					) => context.playerId,
					lastPlayerClassification: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						_event: TPitcherMetricOverTimeStreamGraphEvent
					) => context.playerClassification,
					lastMetricGroup: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						_event: TPitcherMetricOverTimeStreamGraphEvent
					) => context.metricGroup,
					lastSeasonFilter: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						_event: TPitcherMetricOverTimeStreamGraphEvent
					) => context.seasonFilter,
					rollingAverages: (
						_context: TPitcherMetricOverTimeStreamGraphContext,
						_event: TPitcherMetricOverTimeStreamGraphEvent
					) => {
						return {};
					},
					cancelSources: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						_event: TPitcherMetricOverTimeStreamGraphEvent
					) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshRollingAveragesCancelSource: assign<
					TPitcherMetricOverTimeStreamGraphContext,
					TPitcherMetricOverTimeStreamGraphEvent
				>({
					cancelSources: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						_event: TPitcherMetricOverTimeStreamGraphEvent
					) => {
						if (context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE] != null)
							context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE].cancel();
						context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchRollingAveragesSuccess: assign<
					TPitcherMetricOverTimeStreamGraphContext,
					TPitcherMetricOverTimeStreamGraphEvent
				>({
					rollingAverages: (
						context: TPitcherMetricOverTimeStreamGraphContext,
						event: TPitcherMetricOverTimeStreamGraphEvent
					) => {
						const { rollingAverages, batsFilter, throwsFilter } = context;
						if (event.type !== FETCH_ROLLING_AVERAGES_DONE) return rollingAverages;
						// Use the bats filter of the returned data to make sure we have the correct data
						// otherwise default to the context's bats filter
						const bats = event.data?.length ? event.data[0].requestArgs.bats ?? BATS_OVERALL : batsFilter;
						// Repeat the process with the throws filter
						const throws = event.data?.length
							? event.data[0].requestArgs.throws ?? throwsFilter
							: throwsFilter;
						return {
							...rollingAverages,
							[`${bats}-${throws}`]: event.data
						};
					}
				}),
				// Fetch Errored Actions
				handleFetchRollingAveragesErrored: (
					context: TPitcherMetricOverTimeStreamGraphContext,
					_event: TPitcherMetricOverTimeStreamGraphEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Rolling Averages",
							description: "Error fetching rolling averages data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchRollingAverages: (context: TPitcherMetricOverTimeStreamGraphContext, _event: AnyEventObject) => {
					const {
						playerId,
						playerClassification,
						metricGroup,
						batsFilter,
						throwsFilter,
						seasonFilter,
						rollingAverages
					} = context;
					const existingData = rollingAverages[`${batsFilter}-${throwsFilter}`];
					if (existingData) return Promise.resolve(existingData);
					if (!playerId || !metricGroup) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayerMetricRollingAverages(
							{
								playerId: playerId,
								playerClassification: playerClassification,
								// BATS_OVERALL is equivalent to having no `bats` filter
								bats: batsFilter !== BATS_OVERALL ? batsFilter : undefined,
								throws: throwsFilter,
								metricGroup: metricGroup,
								season: seasonFilter,
								isUseCache: true
							},
							context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherMetricOverTimeStreamGraphMachine;
