import React from "react";

import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import { POSITION_GROUP_POSITION_PLAYER } from "_react/shared/_constants/position_groups";
import { getPositionGroup } from "_react/shared/data_models/player/_helpers";
import { PlayerProSummary } from "_react/shared/legacy/globalcontext/player/_types";

import { SHOHEI_PLAYER_ID } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { Statistics } from "_react/playerpage/stat/Statistics";
import PitcherStatisticsTables from "_react/playerpage/pro/content/statistics/PitcherStatisticsTables";
import PositionPlayerStatisticsTables from "_react/playerpage/pro/content/statistics/PositionPlayerStatisticsTables";

type TStatisticsTabProps = {
	player: TPlayerPageCombinedPlayer;
	// TODO get rid of playerOld when the old <Statistics> component is removed
	playerOld: PlayerProSummary;
};

const StatisticsTab = ({ player, playerOld }: TStatisticsTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	const positionGroup = player.id === SHOHEI_PLAYER_ID ? POSITION_GROUP_POSITION_PLAYER : getPositionGroup(player);

	return (
		<>
			{positionGroup === POSITION_GROUP_POSITION_PLAYER && (
				<PositionPlayerStatisticsTables playerId={player.id} />
			)}
			{positionGroup !== POSITION_GROUP_POSITION_PLAYER && <PitcherStatisticsTables playerId={player.id} />}
			<Statistics player={playerOld} colorScheme={chakraColorSchemeGroup} allowProjections={false} />
		</>
	);
};

export default StatisticsTab;
