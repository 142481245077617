// Get Headers
import React from "react";
import { isEqual } from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import CheckBox from "_react/shared/legacy/ui/icons/CheckBox";
import { IndeterminateCheckBox } from "_react/shared/legacy/ui/icons/IndeterminateCheckBox";

import { STAT_MAP, LEVELS } from "_react/apollo/_helpers";
import { getBatterAgeDiff, getPitcherAgeDiff } from "utils/average_ages";
import { getAgeFromBirthDate } from "utils/helpers";
import { MINORS, MAJORS, BATTER, PITCHER, FIELDER } from "_redux/StatsAggregated/_helpers";
import {
	BORDER_AFTER_COLS_PITCHER,
	BORDER_AFTER_COLS_HITTER,
	BORDER_AFTER_COLS_FIELDER
} from "_react/stats/shared/formatting";

export const BETA_HITTER_STATS = [
	"g",
	"pa",
	"h",
	"doubles",
	"triples",
	"hr",
	"r",
	"rbi",
	"hbp",
	"sb",
	"cs",
	"bb_pct",
	"k_pct",
	"iso",
	"babip",
	"avg",
	"obp",
	"slg",
	"ops",
	"woba",
	"prc_plus",
	"vl_woba",
	"vr_woba",
	"wobacon"
];

export const BETA_PITCHER_STATS = [
	"g",
	"gs",
	"ip",
	"w",
	"l",
	"sv",
	"h",
	"k",
	"bb",
	"k_pct",
	"bb_pct",
	"hr_per_9",
	"gb_pct",
	"lob_pct",
	"babip",
	"woba",
	"vl_woba",
	"vr_woba",
	"fbv",
	"era",
	"fip",
	"xfip",
	"era_minus",
	"xfip_minus"
];
export const FIELDER_STATS = [
	"position",
	"g",
	"gs",
	"innings",
	"chances",
	"put_outs",
	"assists",
	"errors",
	"double_plays",
	"passed_ball",
	"sb",
	"cs",
	"range_factor_per_game",
	"fielding_pct",
	"phillies_oaa",
	"uzr",
	"uzr_150"
];
const WEIGHTED_AVG_STATS = [
	"woba",
	"vl_woba",
	"vr_woba",
	"fbv",
	"fip",
	"prc_plus",
	"wobacon",
	"xfip",
	"xfip_minus",
	"era_minus"
];
const formatWeightedAvg = (value, warn) => {
	if (!warn) return value;
	return (
		<Tooltip title="Aggregation ignoring missing entries">
			<div style={{ fontStyle: "italic" }}>{value}*</div>
		</Tooltip>
	);
};

export const SOURCES = ["brew", "statsapi.mlb.com", "gumbo"];
export const GAME_TYPES = [
	{ text: "Regular Season", value: "R" },
	{ text: "Spring Training", value: "S" },
	{ text: "Wild Card Game", value: "F" },
	{ text: "Division Series", value: "D" },
	{ text: "League Championship Series", value: "L" },
	{ text: "World Series", value: "W" },
	{ text: "Championship", value: "C" },
	{ text: "Playoffs", value: "P" },
	{ text: "All-Star Game", value: "A" },
	{ text: "Exhibition", value: "E" },
	{ text: "Intrasquad", value: "I" },
	{ text: "Nineteenth Century Series", value: "N" }
];

const playerInitialCols = ["season", "team", "level", "age", "relativeAge"];

const teamInitialCols = ["player", "age", "relativeAge"];

export const getHeaders = (isPlayer, playerType, averageAges) => {
	let statsToDisplay;
	if (playerType === PITCHER) {
		statsToDisplay = BETA_PITCHER_STATS;
	} else if (playerType === BATTER) {
		statsToDisplay = BETA_HITTER_STATS;
	} else if (playerType === FIELDER) {
		statsToDisplay = FIELDER_STATS;
	}
	const initialCols = isPlayer ? playerInitialCols : teamInitialCols;

	// Put together headers
	let headers = [...initialCols, ...statsToDisplay].map(entry => {
		if (STAT_MAP.hasOwnProperty(entry)) {
			const header = { ...STAT_MAP[entry] };
			if (WEIGHTED_AVG_STATS.includes(entry)) {
				const defaultFormat = header.format;
				header.format = value => {
					let val = value != null && typeof value === "object" ? value.value : value;
					const warn = value != null && typeof value === "object" ? value.warn : false;
					if (defaultFormat) val = defaultFormat(parseFloat(val));
					return formatWeightedAvg(val, warn);
				};
			}
			return header;
		} else if (entry === "player") {
			return {
				name: "PLAYER",
				key: "player",
				onClick: ({ history, data }) => {
					history.push(`/pro/player/${data.playerRef.phil_id}/summary`);
				},
				render: row => {
					if (row.isOnIL) {
						return (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									height: "27px"
								}}
							>
								<Tooltip placement="top" title="On IL">
									<CheckBox
										style={{
											color: "#f44236",
											height: "15px"
										}}
									/>
								</Tooltip>
								{row.player}
							</div>
						);
					} else if (row.isNonRoster) {
						return (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									height: "27px"
								}}
							>
								<Tooltip placement="top" title="Not on Roster">
									<IndeterminateCheckBox
										style={{
											color: "#1f88e5",
											height: "15px"
										}}
									/>
								</Tooltip>
								{row.player}
							</div>
						);
					}
					return row.player;
				}
			};
		} else if (entry === "age") {
			return {
				name: "AGE",
				key: "age",
				render: row => {
					const { birthDate, season } = row;
					if (birthDate == null) return "-";
					const openingDay = `${season}-03-28`;
					if (birthDate) return getAgeFromBirthDate(birthDate, openingDay);
					return "-";
				}
			};
		} else if (entry === "relativeAge") {
			return {
				name: "REL AGE",
				key: "relativateAge",
				render: (row, pitcher = playerType === PITCHER) => {
					const { birthDate, season } = row;
					const openingDay = `${season}-03-28`;
					if (birthDate) {
						const age = getAgeFromBirthDate(birthDate, openingDay);

						if (pitcher) {
							return getPitcherAgeDiff(age, row.level, row.season, averageAges);
						}
						return getBatterAgeDiff(age, row.level, row.season, averageAges);
					}
					return "-";
				}
			};
		} else if (entry === "team") {
			return {
				name: "TEAM",
				key: "team",
				render: row => {
					let teamDisplay = row.team;
					const { team_bam } = row;
					if (team_bam) {
						const { organization, bam_id, season, location_name } = team_bam;
						const isOrgDifferent =
							organization && (organization.bam_id !== bam_id || organization.season !== season);
						if (isOrgDifferent && organization.abbreviation) {
							teamDisplay = location_name ?? teamDisplay;
							teamDisplay = `${teamDisplay} (${organization.abbreviation})`;
						}
					}
					return teamDisplay;
				}
			};
		}
		return {
			name: entry.toUpperCase(),
			key: entry
		};
	});

	// Set Header Columns
	let headerBorderColumns;
	if (playerType === PITCHER) {
		headerBorderColumns = BORDER_AFTER_COLS_PITCHER;
	} else if (playerType === BATTER) {
		headerBorderColumns = BORDER_AFTER_COLS_HITTER;
	} else if (playerType === FIELDER) {
		headerBorderColumns = BORDER_AFTER_COLS_FIELDER;
	}

	headers = headers.map(h => ({
		...h,
		isBorderColumn: headerBorderColumns.includes(h.name)
	}));

	return headers;
};

export const getLevelsFromString = levelString => {
	const levels = ["AAA", "AA", "A+", "A", "A-", "Rk", "FRk", "MEX", "WIN"];

	if (levelString === MAJORS) return ["MLB"];
	if (levelString === MINORS) return levels;

	levels.push("MLB");
	return levels;
};

export const getCellValue = (headerColumn, data) => {
	const { format, render, value, key } = headerColumn;
	let val = data.hasOwnProperty(key) && data[key] !== null ? data[key] : "-";
	if (format && data.hasOwnProperty(key)) {
		val = format(data[key]);
	} else if (render) {
		val = render(data);
	} else if (value) {
		val = value(data);
	}

	return val;
};

export const getLastThreeSeasons = () => {
	const currentDate = new Date();

	let latestYear = currentDate.getFullYear();
	const month = currentDate.getMonth();

	// If before march subtract a year, month starts at 0(BLAH)
	if (month < 2) {
		latestYear = latestYear - 1;
	}
	const years = [latestYear];
	for (let i = 1; i < 3; i++) {
		years.push(latestYear - i);
	}
	return years;
};

export function getDefaultBounds() {
	const lastThreeSeasons = getLastThreeSeasons();
	const defaultBegin = lastThreeSeasons[2];
	const defaultEnd = lastThreeSeasons[0];
	return [defaultBegin, defaultEnd];
}

export const getYearRange = (begin, end) => {
	return Array.from({ length: end - (begin - 1) }, (x, i) => end - i);
};

export const getSeasonRange = (begin, end) => {
	return Array.from({ length: end - (begin - 1) }, (x, i) => end - i);
};

export const getSeasonsRange = playerStats => {
	if (playerStats.length === 0) return [];
	const years = playerStats.map(item => item.season);
	const first = Math.min(...years);
	const last = Math.max(...years);
	return getYearRange(first, last);
};

export const isRowSelectable = data => {
	return data.isCareerRow !== true && data.isTotalRow !== true;
};

/* Legacy */
export const ALL_LEVEL_OPTION = "ALL";

export const ML_LEVEL_OPTION = "MAJORS";

export const MINOR_LEVEL_OPTION = "MINORS";

export const LEVEL_MAP = {
	[ALL_LEVEL_OPTION]: LEVELS,
	[ML_LEVEL_OPTION]: LEVELS.filter(entry => entry === "MLB"),
	[MINOR_LEVEL_OPTION]: LEVELS.filter(entry => entry !== "MLB")
};

export const getSeasonSplits = (bam_id, seasons, levels = null) => {
	const seasonSplits = [];
	if (levels) {
		for (const season of seasons) {
			seasonSplits.push({
				player_id: [bam_id],
				season: [season],
				level: LEVEL_MAP[levels]
			});
		}
	} else {
		// Get season splits for all levels
		for (const season of seasons) {
			for (const level of Object.values(LEVEL_MAP)) {
				seasonSplits.push({
					player_id: [bam_id],
					season: [season],
					level
				});
			}
		}
	}

	return seasonSplits;
};

export const getTotalSplits = (levels = null) => {
	const totalSplits = [];
	if (levels) {
		totalSplits.push({
			total: ["total"],
			level: LEVEL_MAP[levels]
		});
	} else {
		for (const level of Object.values(LEVEL_MAP)) {
			totalSplits.push({
				total: ["total"],
				level
			});
		}
	}
	return totalSplits;
};

// Construct the filters and splits
export const getFiltersAndSplits = (bam_id, seasons = getLastThreeSeasons(), no_cache = [false], levels = null) => {
	const filter = {
		player_id: [bam_id],
		season: seasons,
		no_cache
	};

	// If levels is null by default the level splits will be for all
	const filterLevels = levels ? LEVEL_MAP[levels] : LEVEL_MAP[ALL_LEVEL_OPTION];

	// Add total over last three seasons to split list
	const totalSplits = getTotalSplits(levels);
	const totalSplit = totalSplits[0];

	const seasonSplits = getSeasonSplits(bam_id, seasons, levels);
	const levelSplits = [];
	for (const season of seasons) {
		// Level totals per season
		for (const level of filterLevels) {
			const levelSplit = {
				player_id: [bam_id],
				season: [season],
				level: [level]
			};

			levelSplits.push(levelSplit);
		}
	}
	const splits = [].concat(seasonSplits, levelSplits, totalSplits);

	return {
		filter,
		splits,
		levelSplits,
		seasonSplits,
		totalSplit
	};
};

function percentileValueCalc(arrayRaw, percentile) {
	const array = [...arrayRaw].sort((a, b) => a - b);
	const index = (percentile / 100) * (array.length - 1);
	let result;
	if (Math.floor(index) === index) {
		result = array[index];
	} else {
		const i = Math.floor(index);
		const fraction = index - i;
		result = array[i] + (array[i + 1] - array[i]) * fraction;
	}
	return result;
}

const IGNORED_COLUMNS_AGGREGATE = ["player"];

function calculateAggregate(column, array, aggregateFunction) {
	if (IGNORED_COLUMNS_AGGREGATE.includes(column) || isNaN(array[0][column])) return array[0][column];
	return aggregateFunction(array, column);
}

function getColumnValues(rows, column) {
	return rows
		.map(r => r[column])
		.filter(v => {
			return v !== "-" && v != null;
		});
}

export const REVERSE_PERCENTILE_CALC_COLUMNS = new Set([
	"time_max_speed_contact",
	"time_max_speed_release",
	"time_first_step_contact",
	"time_first_step_release",
	"time_hp_fb_contact",
	"time_hp_sb_contact",
	"time_hp_tb_contact",
	"time_hp_hp_contact",
	"time_fb_sb_contact",
	"time_fb_sb_first_move",
	"time_fb_tb_contact",
	"time_fb_tb_first_move",
	"time_fb_hp_contact",
	"time_fb_hp_first_move",
	"time_sb_tb_contact",
	"time_sb_tb_first_move",
	"time_sb_hp_contact",
	"time_sb_hp_first_move",
	"time_tb_hp_contact",
	"time_tb_hp_first_move",
	"time_first_step",
	"time_max_speed",
	"time_startup",
	"time_exchange",
	"time_pop"
]);

export const NON_PERCENTILE_CALC_COLUMNS = new Set([
	"base_receiver",
	"base_relay_receiver",
	"id_receiver",
	"is_bounced",
	"is_double_play_start",
	"is_double_play_turn",
	"is_from_cutoff",
	"is_relayed",
	"is_relay",
	"is_to_cutoff",
	"position_thrower",
	"position_receiver",
	"position_relay_receiver",
	"timestamp_release",
	"timestamp_contact",
	"timestamp_acquired",
	"timestamp_throw_start",
	"timestamp_throw_bounce",
	"timestamp_throw_end",
	"timestamp_bounce",
	"position",
	"shift_classification",
	"number_throw"
]);

export function dataTransform(data, aggregateMethod) {
	const dataIndex = data.reduce((dict, dataPoint) => {
		if (dataPoint.player == null) return dict; // Error!
		if (dict[dataPoint.player.bam_id] == null) dict[dataPoint.player.bam_id] = [];
		dict[dataPoint.player.bam_id].push(dataPoint);
		return dict;
	}, {});
	return Object.keys(dataIndex).map(d => {
		let aggregateFunction;
		switch (aggregateMethod) {
			case "90th_percentile":
				aggregateFunction = (rows, column) => {
					const rowSet = getColumnValues(rows, column);
					if (rowSet.length === 0 || NON_PERCENTILE_CALC_COLUMNS.has(column)) return "-";
					const percentileValue = REVERSE_PERCENTILE_CALC_COLUMNS.has(column) ? 10 : 90;
					return percentileValueCalc(getColumnValues(rows, column), percentileValue);
				};
				break;
			case "max":
				aggregateFunction = (rows, column) => {
					const rowSet = getColumnValues(rows, column);
					if (rowSet.length === 0) return "-";
					return Math.max(...rowSet);
				};
				break;
			case "average":
				aggregateFunction = (rows, column) => {
					const rowSet = getColumnValues(rows, column);
					if (rowSet.length === 0) return "-";
					return rowSet.reduce((a, b) => a + b, 0) / rowSet.length;
				};
				break;
			default:
				aggregateFunction = (rows, column) => rows[0][column];
				break;
		}

		return Object.keys(dataIndex[d][0]).reduce((dict, column) => {
			dict[column] = calculateAggregate(column, dataIndex[d], aggregateFunction);
			return dict;
		}, {});
	});
}

export const METRIC_TO_PLAYER_KEY = {
	br: "runner_id",
	fld: "fielder_id",
	thr: "id_thrower"
};

export const compareLevelArray = (levelsA, levelsB) => {
	const a = [...new Set(levelsA)].sort();
	const b = [...new Set(levelsB)].sort();
	return isEqual(a, b);
};

export const getLevelSelected = levels => {
	if (levels.length === 10) return "all";
	else if (levels.length === 1 && levels[0] === "MLB") return MAJORS;
	else if (levels.length === 9 && !levels.includes("MLB")) return MINORS;
	else return "custom";
};

const LEVEL_GROUPS = {
	[MAJORS]: ["MLB"],
	[MINORS]: ["AAA", "AA", "A+", "A", "A-", "Rk", "FRk"],
	all: ["MLB", "AAA", "AA", "A+", "A", "A-", "Rk", "FRk", "MEX", "WIN"],
	affiliates: ["MLB", "AAA", "AA", "A+", "A", "A-", "Rk", "FRk"]
};

export function levelsForGroup(group) {
	return LEVEL_GROUPS[group];
}

const PROJECTION_DESCRIPTOR_MAP = {
	five_year_batting: "Phillies Internal Five Year",
	five_year_pitching: "Phillies Internal Five Year",
	projections_batter: "Phillies Internal ROS",
	projections_pitcher: "Phillies Internal ROS",
	ros_projection: "Steamer ROS",
	full_season_projection: "Steamer SOS"
};

const addMappedObjKeys = (data, mapped_fields_obj = {}, options = { deleteOriginalKey: false }) => {
	for (const obj of data) {
		for (const [key, value] of Object.entries(mapped_fields_obj)) {
			if (key in obj) {
				obj[value] = obj[key];
				if (options.deleteOriginalKey) {
					delete obj[key];
				}
			}
		}
	}
	return data;
};

function transformSteamerKeys(data, key) {
	let mapped_fields_dict = { bb_rate: "bb_pct", k_rate: "k_pct" };
	if (key === "steamer_pitching") {
		mapped_fields_dict = { ...mapped_fields_dict, hr9: "hr_per_9", lob_rate: "lob_pct" };
	}

	if (key === "steamer_batting") {
		mapped_fields_dict = { ...mapped_fields_dict, _2b: "doubles", _3b: "triples" };
	}

	data = addMappedObjKeys(data, mapped_fields_dict, { deleteOriginalKey: true });
	return data;
}

const transformPhilliesInternalROSKeys = data => {
	const mapped_fields_obj = { lwoba: "vl_woba", rwoba: "vr_woba", groundball_pct: "gb_pct" };

	data = addMappedObjKeys(data, mapped_fields_obj, { deleteOriginalKey: true });
	return data;
};

const formatSteamerRows = (data, key, player) => {
	data = transformSteamerKeys(data, key);
	return data.map(row => ({
		...row,
		team: PROJECTION_DESCRIPTOR_MAP[row.type],
		birthDate: player.birth_date,
		isSteamerProjection: true
	}));
};

const formatPhilliesInternalFiveYearRows = (data, key, player) => {
	if (key === "five_year_pitching") {
		data = data.filter(row =>
			["LHS", "RHS"].includes(player.position) ? row.is_starter === 1 : row.is_starter === 0
		);
	}
	const descriptorObject = {
		expandable: true,
		season: data[0]?.season && `${data[0]?.season}-${data[data.length - 1]?.season}`,
		team: PROJECTION_DESCRIPTOR_MAP[key],
		level: "MLB",
		isPhilliesProjection: true
	};
	data = data.map(row => ({
		...row,
		isNestedRow: true,
		team: PROJECTION_DESCRIPTOR_MAP[key],
		birthDate: player.birth_date,
		level: "MLB",
		isPhilliesProjection: true
	}));
	return [descriptorObject, ...data];
};

const formatPhilliesInternalROSRows = (data, key, player) => {
	data = transformPhilliesInternalROSKeys(data, key);
	data = data.map((row, idx) => ({
		...row,
		team: PROJECTION_DESCRIPTOR_MAP[key],
		birthDate: player.birth_date,
		level: "MLB",
		isPhilliesProjection: true,
		isPhilliesProjectionEnd: idx === data.length - 1
	}));
	return data;
};

export const formatProjectionDataForRows = (data, key, player) => {
	if (data.length === 0) return data;

	if (["steamer_pitching", "steamer_batting"].includes(key)) {
		data = formatSteamerRows(data, key, player);
	} else if (["five_year_batting", "five_year_pitching"].includes(key)) {
		data = formatPhilliesInternalFiveYearRows(data, key, player);
	} else if (["projections_batter", "projections_pitcher"].includes(key)) {
		data = formatPhilliesInternalROSRows(data, key, player);
	}

	return data;
};
