import { eval_ama_hit } from "_react/shared/_types/phil_data/eval_ama_hit";
import { eval_ama_pitch } from "_react/shared/_types/phil_data/eval_ama_pitch";
import { eval_intl_hit } from "_react/shared/_types/phil_data/eval_intl_hit";
import { eval_intl_pitch } from "_react/shared/_types/phil_data/eval_intl_pitch";
import { eval_pro_hit } from "_react/shared/_types/phil_data/eval_pro_hit";
import { eval_pro_pitch } from "_react/shared/_types/phil_data/eval_pro_pitch";
import ProEval from "_react/evals/pro/ProEval";
import IntlEval from "_react/evals/intl/IntlEval";
import AmaEval from "_react/evals/ama/AmaEval";
import AmaEvalWrapperHeader from "_react/evals/ama/AmaEvalWrapperHeader";
import { PlayerProSummary, PlayerProContract } from "_react/shared/legacy/globalcontext/player/_types";
import { TAgentById, TAgencyById } from "_react/agents/_helpers";
import { $TSFixMe } from "utils/tsutils";

export type $TSFixMeEval =
	| eval_ama_hit
	| eval_ama_pitch
	| eval_intl_hit
	| eval_intl_pitch
	| eval_pro_hit
	| eval_pro_pitch
	| $TSFixMe;

export const EvalDateFields = ["eval_create_date", "seen_date", "publish_date"];

// TODO: instead of | null, why were these not made optional
export interface Eval {
	type: string | null;
	eval: $TSFixMeEval;
	player: PlayerProSummary | null;
	contract: PlayerProContract | null;
	team: $TSFixMe | null;
	teams: $TSFixMe | null;
	agents: TAgentById | null;
	agencies: TAgencyById | null;
}

export const TYPE_TO_COMPONENT: $TSFixMe = {
	pro: ProEval,
	intl: IntlEval,
	ama: AmaEval
};

export const TYPE_TO_COMPONENT_HEADER: $TSFixMe = {
	pro: null,
	intl: null,
	ama: AmaEvalWrapperHeader
};

export type TEvalPlayerScoutEntry = {
	scoutId: number;
	evalId: string;
	publishDate: string | null;
};
