import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function FireExtinguisher(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 0 22 22" {...props}>
			<path fill="currentColor" d="M14.0264 11.1258H6.64679V15.8986H14.0264V11.1258Z" />
			<path
				fill="currentColor"
				d="M6.64679 18.722C6.64679 19.3718 7.17879 19.9 7.82479 19.9H12.8484C13.4982 19.9 14.0264 19.3718 14.0264 18.722V16.6586H6.64679V18.722Z"
			/>
			<path
				fill="currentColor"
				d="M19.0538 0.907603L13.5818 1.8614C13.3994 1.8956 13.2664 2.0514 13.2664 2.2376V3.0166H11.6894V2.6252C11.6894 1.8804 11.0814 1.2762 10.3366 1.2762C9.59179 1.2762 8.98759 1.8804 8.98759 2.6252V3.0584C7.50179 3.2256 6.16419 4.0426 5.35859 5.3118C5.24459 5.4904 5.29779 5.7222 5.47639 5.8362C5.54099 5.878 5.60939 5.897 5.68159 5.897C5.80699 5.897 5.92859 5.8362 6.00079 5.7222C6.66959 4.6734 7.76779 3.9856 8.99139 3.8222V5.9388C7.66519 6.1592 6.65059 7.3106 6.65059 8.6976V10.3658H14.0302V8.6976C14.0302 7.3106 13.0156 6.1592 11.6932 5.9388V3.7766H13.2702V4.1832C13.2702 4.3694 13.4032 4.5252 13.5856 4.5594L19.0576 5.5132C19.0804 5.517 19.1032 5.5208 19.126 5.5208C19.2134 5.5208 19.3008 5.4904 19.3692 5.4296C19.4528 5.3574 19.506 5.251 19.506 5.1408V1.28C19.506 1.1698 19.4528 1.0634 19.3692 0.987403C19.278 0.919003 19.164 0.888603 19.0538 0.907603Z"
			/>
		</Icon>
	);
}
