import React, { useCallback, useMemo, useState } from "react";
import { Box, VStack, HStack, Table, Tbody, Tr, Td, Text } from "@chakra-ui/react";
import { AxisBottom } from "@visx/axis";
import { curveNatural } from "@visx/curve";
import { localPoint } from "@visx/event";
import { GridColumns } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Line, Stack } from "@visx/shape";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import dayjs from "dayjs";
import { bisectLeft, stackOrderAscending, stackOffsetSilhouette, stack as stackFunction, NumberValue } from "d3";

import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import { $TSAnyRequired } from "utils/tsutils";

import { DEFAULT_OPACITY, IS_HOVERED_OPACITY } from "_react/shared/dataviz/_constants";
import { getExtent, getPlotDimensions } from "_react/shared/dataviz/_helpers";
import { useDataVizColors, useAxisLabelProps } from "_react/shared/dataviz/_hooks";
import { TAxisExtrema, TTooltipData, TOrderOptions, TOffsetOptions } from "_react/shared/dataviz/_types";
import { tooltipSubtitleStyle } from "_react/shared/dataviz/_styles";

import {
	LEGEND_WIDTH,
	PITCH_TYPE_LABEL_STYLE,
	STACK_ORDER_DICT
} from "_react/shared/ui/presentation/plots/PitchMetricStreamGraphPlot/_constants";
import { KeysOfValue } from "_react/shared/_types/generics";

type TPitchMetricStreamGraphPlotProps<T extends object> = {
	pitchData?: {
		combinedPitchData: Array<T>;
		stackedPitchData: { [index: number]: Array<number> };
		// this is the order of the pitch types in stacked pitch data
		// the index in this array + 1 corresponds to the index in stackedPitchData
		pitchTypeKeys: Array<string>;
	};
	xValue: KeysOfValue<T, number> | string;

	getXValueFunction?: (obj: T) => number;
	xLabel?: string;
	xTickLabels?: Array<{ label: string; value: number }>;
	tooltipValues?: {
		xValue: KeysOfValue<T, string> | string;
		pitchType: KeysOfValue<T, string> | string;
		yValue: KeysOfValue<T, number | null> | string;
	};

	defaultXAxisExtrema?: TAxisExtrema<number>;
	yExtrema: { min: number; max: number };
	stack?: {
		order?: TOrderOptions;
		offset?: TOffsetOptions;
	};
	// TODO: Need to make this responsive to parent but want to wait until parent components are created
	width?: number;
	height?: number;
};

export const PitchMetricStreamGraphPlot = <T extends object>({
	pitchData,
	xValue,
	getXValueFunction,
	xLabel,
	xTickLabels,
	tooltipValues,
	defaultXAxisExtrema,
	yExtrema,
	stack,
	width,
	height
}: TPitchMetricStreamGraphPlotProps<T>) => {
	// STATE MANAGEMENT
	const { combinedPitchData, stackedPitchData, pitchTypeKeys } = pitchData || {
		combinedPitchData: undefined,
		stackedPitchData: undefined,
		pitchTypeKeys: undefined
	};
	const [hoveredPitchType, setHoveredPitchType] = useState<string>();
	const [hoveredXValue, setHoveredXValue] = useState<number>();
	const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<
		TTooltipData<number>
	>();
	const { containerRef, TooltipInPortal } = useTooltipInPortal({
		scroll: true
	});

	// STYLING
	const {
		axisColor,
		backgroundColor,
		dataColorSecondary,
		dataColorPrimaryGray,
		gridStrokeColor,
		pitchTypeColorDict
	} = useDataVizColors();
	const pitchTypeColorDictModified = { ...pitchTypeColorDict } as { [index: string]: string };
	pitchTypeColorDictModified["UN"] = dataColorSecondary;

	const axisLabelProps = useAxisLabelProps();

	// CHART SETUP
	const {
		width: WIDTH,
		height: HEIGHT,
		margins: MARGIN,
		innerWidth: INNER_WIDTH,
		innerHeight: INNER_HEIGHT
	} = getPlotDimensions(width ?? 500 - LEGEND_WIDTH, height, { left: 25, right: 10, top: 20 });

	// Get the order of the pitch types (if possible)
	// This is pretty hacky
	// It only works for ascending, descending, insideout and none ordering of usage data
	const stackPitchTypeOrder: Array<string> | undefined = useMemo(() => {
		if (stack?.order && STACK_ORDER_DICT.hasOwnProperty(stack.order) && pitchTypeKeys) {
			if (stackedPitchData) {
				// Determine which order function we're using
				const stackOrderFunction = STACK_ORDER_DICT[stack.order];
				// Create stacks but use a silhouette offset so that the bottom stack will have the lowest values and the highest stack will have the highest values
				const stackedData = stackFunction<Array<number>>()
					.order(stackOrderFunction)
					.offset(stackOffsetSilhouette)
					.keys(pitchTypeKeys.map((_pitchType: string, index: number) => (index + 1).toString()))(
					(Object.values(stackedPitchData) as unknown) as Array<Array<number>>
				);
				// Derive the order of the stacks from the stack data
				// This is supposed to be of the type Series<any, any> which is a weird D3 type
				const stackOrder = stackOrderAscending(stackedData as $TSAnyRequired);
				// Match this to pitch types
				const stackOrderPitchTypes = stackOrder.map((pitchTypeIndex: number) => pitchTypeKeys[pitchTypeIndex]);
				return stackOrderPitchTypes;
			}
		}
		return pitchTypeKeys;
	}, [pitchTypeKeys, stackedPitchData, stack?.order]);

	// DATA SETUP
	const getXValue = useCallback(
		(datum: T) =>
			getXValueFunction ? getXValueFunction(datum) : ((datum[xValue as keyof T] as unknown) as number),
		[getXValueFunction, xValue]
	);

	const [xMin, xMax] = getExtent<number>(
		defaultXAxisExtrema?.min ?? 100,
		defaultXAxisExtrema?.max ?? 2500,
		combinedPitchData?.map((datum: T) => getXValue(datum) as number)
	);

	const xScale = scaleLinear({ domain: [xMin, xMax], range: [0, INNER_WIDTH] });
	const yScale = scaleLinear({ domain: [yExtrema.min, yExtrema.max], range: [0, INNER_HEIGHT], nice: true });

	const getXScaledValue = (d: { data: Array<number> }) => {
		return xScale(d.data[0] ?? 0);
	};
	const getY0ScaledValue = (d: Array<number>) => {
		return yScale(d[0] ?? 0);
	};
	const getY1ScaledValue = (d: Array<number>) => yScale(d[1] ?? 0);

	// INTERACTIVITY
	const handleHover = useCallback(
		(event: React.MouseEvent<SVGElement>) => {
			// Get coordinates of mouse in pixels
			const { x, y } = localPoint(event) || { x: null, y: null };

			if (x && combinedPitchData && stackedPitchData) {
				// Convert x mouse location from pixels to an x value
				const xMouseValue = xScale.invert(x - MARGIN.left);
				if (xMouseValue >= xMin && xMouseValue <= xMax) {
					// Get array of all x values
					const allXValues = Object.keys(stackedPitchData).map((rowNumber: string) => parseInt(rowNumber));

					// Get the x value closest to the x mouse value
					const hoveredIndex = bisectLeft(allXValues, xMouseValue, 1);
					const hoveredXValue = allXValues[hoveredIndex];
					setHoveredXValue(hoveredXValue);

					// Get the pitch data associated with that x value
					const pitchValues = combinedPitchData.filter((pitch: T) => getXValue(pitch) === hoveredXValue);

					if (pitchValues && tooltipValues) {
						// Title is the x value where the mouse is at
						const title = (pitchValues[0][tooltipValues.xValue as keyof T] as unknown) as string;
						// Subtitle is each pitch type and its associated y value
						const subtitleDict: { [index: string]: number } = {};
						pitchValues.forEach((pitch: T) => {
							const pitchType = (pitch[tooltipValues.pitchType as keyof T] as unknown) as string;
							const yValue = (pitch[tooltipValues.yValue as keyof T] as unknown) as number | null;
							if (yValue) subtitleDict[pitchType] = yValue;
						});
						showTooltip({
							tooltipLeft: x,
							tooltipTop: y,
							tooltipData: { subtitles: subtitleDict, title: title }
						});
					}
				}
			}
		},
		[xMax, xMin, combinedPitchData, stackedPitchData, xScale, getXValue, MARGIN.left, tooltipValues, showTooltip]
	);

	const handleMouseLeavePlot = () => {
		setHoveredXValue(undefined);
		hideTooltip();
	};

	return (
		<HStack>
			<svg width={WIDTH} height={HEIGHT} ref={containerRef}>
				<rect x={0} y={0} width={WIDTH} height={HEIGHT} fill={"transparent"} />
				<Group left={MARGIN.left} top={MARGIN.top} onMouseLeave={handleMouseLeavePlot}>
					<GridColumns
						scale={xScale}
						width={INNER_WIDTH}
						height={INNER_HEIGHT}
						stroke={gridStrokeColor}
						tickValues={
							xTickLabels
								? xTickLabels
										.filter(
											(tickLabel: { value: number; label: string }) =>
												tickLabel.value > xMin && tickLabel.value < xMax
										)
										.map((tickLabel: { value: number; label: string }) => tickLabel.value)
								: undefined
						}
					/>
					<AxisBottom
						scale={xScale}
						top={INNER_HEIGHT}
						label={xLabel}
						labelProps={axisLabelProps}
						stroke={axisColor}
						tickStroke={axisColor}
						tickValues={
							xTickLabels
								? xTickLabels
										.filter(
											(tickLabel: { value: number; label: string }) =>
												tickLabel.value > xMin && tickLabel.value < xMax
										)
										.map((tickLabel: { value: number; label: string }) => tickLabel.value)
								: undefined
						}
						tickFormat={
							xTickLabels
								? (v: NumberValue) =>
										xTickLabels.find(
											(tickLabel: { value: number; label: string }) => tickLabel.value === v
										)?.label
								: undefined
						}
					/>
					{hoveredXValue && (
						<Line
							from={{
								x: xScale(hoveredXValue),
								y: yScale(yExtrema.min)
							}}
							to={{
								x: xScale(hoveredXValue),
								y: yScale(yExtrema.max)
							}}
							stroke={dataColorPrimaryGray}
							strokeWidth={1}
							strokeOpacity={IS_HOVERED_OPACITY}
							strokeDasharray="6,3"
						/>
					)}
					{stackedPitchData && pitchTypeKeys && (
						<Stack<number[], number>
							data={Object.values(stackedPitchData)}
							keys={pitchTypeKeys.map((_pitchType: string, index: number) => index + 1)}
							x={getXScaledValue}
							y0={getY0ScaledValue}
							y1={getY1ScaledValue}
							offset={stack?.offset ? stack?.offset : "wiggle"}
							order={stack?.order ? stack?.order : "insideout"}
							curve={curveNatural}
						>
							{/* These types are bizarre and not worth defining */}
							{/* stacks is in the format [number, number, key: number, index: number][] */}
							{/* path is function that you can pass stack to and it will generate the svg path from that dataset */}
							{({ stacks, path }) =>
								// stack: [number, number, key: number, index: number]
								stacks.map(stack => {
									const pathString = path(stack) || "";
									const pitchType = pitchTypeKeys[(stack.key as number) - 1];
									const color = pitchTypeColorDictModified[pitchType];

									return (
										<Group key={`series-${stack.key}`}>
											<path
												d={pathString}
												fill={color}
												fillOpacity={DEFAULT_OPACITY}
												onMouseEnter={() => {
													setHoveredPitchType(pitchType);
												}}
												onMouseMove={event => {
													handleHover(event);
												}}
												onMouseLeave={() => {
													setHoveredPitchType(undefined);
												}}
											/>
										</Group>
									);
								})
							}
						</Stack>
					)}
				</Group>
			</svg>
			{tooltipOpen && (
				<TooltipInPortal
					// set this to random so it correctly updates with parent bounds
					key={Math.random()}
					top={tooltipTop}
					left={tooltipLeft}
					style={{ ...defaultStyles, backgroundColor: backgroundColor }}
				>
					<Text fontWeight="bold" fontSize="xs" margin="1" textAlign="center">
						{dayjs(tooltipData?.title).format("M/D/YY")}
					</Text>
					<Table variant="unstyled" size="sm">
						<Tbody>
							{tooltipData?.subtitles &&
								stackPitchTypeOrder?.map((pitchType: string) => (
									<Tr key={pitchType}>
										<Td
											fontWeight={pitchType === hoveredPitchType ? "bold" : undefined}
											sx={tooltipSubtitleStyle}
										>{`${pitchType}:`}</Td>
										<Td
											fontWeight={pitchType === hoveredPitchType ? "bold" : undefined}
											textAlign="right"
											sx={tooltipSubtitleStyle}
										>{`${Math.round(tooltipData.subtitles[pitchType] * 100).toString()}%`}</Td>
									</Tr>
								))}
						</Tbody>
					</Table>
				</TooltipInPortal>
			)}
			<VStack align="start">
				{stackPitchTypeOrder &&
					stackPitchTypeOrder.map((pitchType: string) => (
						<Box
							key={pitchType}
							onMouseEnter={() => {
								setHoveredPitchType(pitchType);
							}}
							onMouseLeave={() => {
								setHoveredPitchType(undefined);
							}}
						>
							<PitchTypeLabel
								label={pitchType}
								abbreviation={pitchType}
								shape="circle"
								style={
									pitchType === hoveredPitchType
										? { ...PITCH_TYPE_LABEL_STYLE, label: { fontWeight: "bold", fontSize: "sm" } }
										: PITCH_TYPE_LABEL_STYLE
								}
							/>
						</Box>
					))}
			</VStack>
		</HStack>
	);
};

export default PitchMetricStreamGraphPlot;
