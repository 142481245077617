import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { fetchFieldingProjectionsBlend } from "_react/shared/data_models/defensive_projections/_network";
import { fetchPlayer } from "_react/shared/data_models/player/_network";
import { IFieldingProjectionsBlend } from "_react/shared/data_models/defensive_projections/_types";
import {
	IProjectedPositionalProportions,
	IProjectedPitcherProportions
} from "_react/shared/data_models/projections/_types";
import { TFieldingProjectionsTableData } from "_react/shared/ui/data/tables/FieldingProjectionsTable/FieldingProjectionsTable";
import { TFieldingProjectionsTablePlayer } from "_react/shared/ui/data/tables/FieldingProjectionsTable/_types";
import {
	fetchProjectedPositionalProportions,
	fetchProjectedPitcherProportions
} from "_react/shared/data_models/projections/_network";

const FIELDING_PROJECTIONS_CANCEL_SOURCE = "fieldingProjections";
const PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE = "projectedPositionalProportionsCancelSource";
const PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE = "projectedPitcherProportions";
const PLAYER_CANCEL_SOURCE = "player";

type TFieldingProjectionsTableCancelSources = {
	[FIELDING_PROJECTIONS_CANCEL_SOURCE]?: CancelTokenSource;
	[PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE]?: CancelTokenSource;
	[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TFieldingProjectionsTableContext = {
	playerId?: number;
	lastPlayerId?: number; // Not currently used
	shouldFetchData?: boolean;
	fieldingProjections?: Array<IFieldingProjectionsBlend> | null;
	projectedPositionalProportions?: Array<IProjectedPositionalProportions> | null;
	projectedPitcherProportions?: Array<IProjectedPitcherProportions> | null;
	player?: TFieldingProjectionsTablePlayer | null;
	isExpanded?: boolean;
	cancelSources: TFieldingProjectionsTableCancelSources;
	toast?: CreateToastFnReturn;
};

interface IFieldingProjectionsTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all fielding projections data
				fieldingProjections: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetch projected positional proportions data
				projectedPositionalProportions: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetch projected pitcher proportions data
				projectedPitcherProportions: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetch player data
				player: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_FIELDING_PROJECTIONS = "SET_FIELDING_PROJECTIONS";
export const SET_PROJECTED_POSITIONAL_PROPORTIONS = "SET_PROJECTED_POSITIONAL_PROPORTIONS";
export const SET_PROJECTED_PITCHER_PROPORTIONS = "SET_PROJECTED_PITCHER_PROPORTIONS";
export const SET_PLAYER = "SET_PLAYER";
export const SET_IS_EXPANDED = "SET_IS_EXPANDED";
export const FETCHING_FIELDING_PROJECTIONS = { initialized: { fieldingProjections: "fetching" } };
export const FETCHING_PROJECTED_POSITIONAL_PROPORTIONS = {
	initialized: { projectedPositionalProportions: "fetching" }
};
export const FETCHING_PROJECTED_PITCHER_PROPORTIONS = { initialized: { projectedPitcherProportions: "fetching" } };
export const FETCHING_PLAYER = { initialized: { player: "fetching" } };

const FETCH_FIELDING_PROJECTIONS_DONE = "done.invoke.fetchingFieldingProjections:invocation[0]";
const FETCH_PROJECTED_POSITIONAL_PROPORTIONS_DONE = "done.invoke.fetchingProjectedPositionalProportions:invocation[0]";
const FETCH_PROJECTED_PITCHER_PROPORTIONS_DONE = "done.invoke.fetchingProjectedPitcherProportions:invocation[0]";
const FETCH_PLAYER_DONE = "done.invoke.fetchingPlayer:invocation[0]";

type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | null | undefined;
};
type TSetFieldingProjectionsEvent = {
	type: typeof SET_FIELDING_PROJECTIONS;
	data: Array<IFieldingProjectionsBlend> | undefined;
};
type TSetProjectedPositionalProportionsEvent = {
	type: typeof SET_PROJECTED_POSITIONAL_PROPORTIONS;
	data: Array<IProjectedPositionalProportions> | undefined;
};
type TSetProjectedPitcherProportionsEvent = {
	type: typeof SET_PROJECTED_PITCHER_PROPORTIONS;
	data: Array<IProjectedPitcherProportions> | undefined;
};
type TSetPlayerEvent = {
	type: typeof SET_PLAYER;
	data: TFieldingProjectionsTablePlayer | undefined;
};
type TSetIsExpandedEvent = {
	type: typeof SET_IS_EXPANDED;
	data: boolean;
};
type TFetchFieldingProjectionsEvent = {
	type: typeof FETCH_FIELDING_PROJECTIONS_DONE;
	data: Array<IFieldingProjectionsBlend> | undefined;
};
type TFetchProjectedPositionalProportionsEvent = {
	type: typeof FETCH_PROJECTED_POSITIONAL_PROPORTIONS_DONE;
	data: Array<IProjectedPositionalProportions> | undefined;
};
type TFetchProjectedPitcherProportionsEvent = {
	type: typeof FETCH_PROJECTED_PITCHER_PROPORTIONS_DONE;
	data: Array<IProjectedPitcherProportions> | undefined;
};
type TFetchPlayerEvent = {
	type: typeof FETCH_PLAYER_DONE;
	data: TFieldingProjectionsTablePlayer | undefined;
};

type TFieldingProjectionsTableEvent =
	| TSetPlayerIdEvent
	| TSetFieldingProjectionsEvent
	| TSetProjectedPositionalProportionsEvent
	| TSetProjectedPitcherProportionsEvent
	| TSetPlayerEvent
	| TSetIsExpandedEvent
	| TFetchFieldingProjectionsEvent
	| TFetchProjectedPositionalProportionsEvent
	| TFetchProjectedPitcherProportionsEvent
	| TFetchPlayerEvent;

export type TFieldingProjectionsTableSend = Interpreter<
	TFieldingProjectionsTableContext,
	IFieldingProjectionsTableStateSchema,
	TFieldingProjectionsTableEvent
>["send"];

const FieldingProjectionsTableMachine = (
	playerIdProp?: number,
	data?: TFieldingProjectionsTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TFieldingProjectionsTableContext, IFieldingProjectionsTableStateSchema, TFieldingProjectionsTableEvent>(
		{
			id: "fieldingProjectionsTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				fieldingProjections: data?.fieldingProjections,
				projectedPositionalProportions: data?.projectedPositionalProportions,
				projectedPitcherProportions: data?.projectedPitcherProportions,
				player: data?.player,
				isExpanded: false,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: {
							actions: [
								"setPlayerId",
								"clearFieldingProjections",
								"clearProjectedPositionalProportions",
								"clearProjectedPitcherProportions",
								"clearPlayer"
							],
							cond: "shouldSetPlayerId"
						},
						[SET_FIELDING_PROJECTIONS]: { actions: "setFieldingProjections" },
						[SET_PROJECTED_POSITIONAL_PROPORTIONS]: { actions: "setProjectedPositionalProportions" },
						[SET_PROJECTED_PITCHER_PROPORTIONS]: { actions: "setProjectedPitcherProportions" },
						[SET_PLAYER]: { actions: "setPlayer" },
						[SET_IS_EXPANDED]: { actions: "setIsExpanded" }
					},
					states: {
						fieldingProjections: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingFieldingProjections",
												cond: "shouldFetchFieldingProjections"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingFieldingProjections",
									entry: ["refreshFieldingProjectionsCancelSource"],
									invoke: {
										src: "fetchFieldingProjections",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchFieldingProjectionsSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchFieldingProjectionsErrored"
										}
									}
								}
							}
						},
						projectedPositionalProportions: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingProjectedPositionalProportions",
												cond: "shouldFetchProjectedPositionalProportions"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingProjectedPositionalProportions",
									entry: ["refreshProjectedPositionalProportionsCancelSource"],
									invoke: {
										src: "fetchProjectedPositionalProportions",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchProjectedPositionalProportionsSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchProjectedPositionalProportionsErrored"
										}
									}
								}
							}
						},
						projectedPitcherProportions: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingProjectedPitcherProportions",
												cond: "shouldFetchProjectedPitcherProportions"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingProjectedPitcherProportions",
									entry: ["refreshProjectedPitcherProportionsCancelSource"],
									invoke: {
										src: "fetchProjectedPitcherProportions",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchProjectedPitcherProportionsSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchProjectedPitcherProportionsErrored"
										}
									}
								}
							}
						},
						player: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayer",
												cond: "shouldFetchPlayer"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayer",
									entry: ["refreshPlayerCancelSource"],
									invoke: {
										src: "fetchPlayer",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (context: TFieldingProjectionsTableContext, event: TFieldingProjectionsTableEvent) =>
					event.type === SET_PLAYER_ID && context.playerId !== event.data,
				shouldFetchFieldingProjections: (
					context: TFieldingProjectionsTableContext,
					_event: TFieldingProjectionsTableEvent
				) =>
					context.fieldingProjections === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchProjectedPositionalProportions: (
					context: TFieldingProjectionsTableContext,
					_event: TFieldingProjectionsTableEvent
				) =>
					context.projectedPositionalProportions === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchProjectedPitcherProportions: (
					context: TFieldingProjectionsTableContext,
					_event: TFieldingProjectionsTableEvent
				) =>
					context.projectedPitcherProportions === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayer: (
					context: TFieldingProjectionsTableContext,
					_event: TFieldingProjectionsTableEvent
				) => context.player === undefined && context.playerId !== undefined && context.shouldFetchData === true
			},
			actions: {
				clearFieldingProjections: assign<TFieldingProjectionsTableContext, TFieldingProjectionsTableEvent>({
					fieldingProjections: (
						_context: TFieldingProjectionsTableContext,
						_event: TFieldingProjectionsTableEvent
					) => undefined,
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						_event: TFieldingProjectionsTableEvent
					) => {
						if (context.cancelSources[FIELDING_PROJECTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[FIELDING_PROJECTIONS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[FIELDING_PROJECTIONS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearProjectedPositionalProportions: assign<
					TFieldingProjectionsTableContext,
					TFieldingProjectionsTableEvent
				>({
					projectedPositionalProportions: (
						_context: TFieldingProjectionsTableContext,
						_event: TFieldingProjectionsTableEvent
					) => undefined,
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						_event: TFieldingProjectionsTableEvent
					) => {
						if (context.cancelSources[PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearProjectedPitcherProportions: assign<
					TFieldingProjectionsTableContext,
					TFieldingProjectionsTableEvent
				>({
					projectedPitcherProportions: (
						_context: TFieldingProjectionsTableContext,
						_event: TFieldingProjectionsTableEvent
					) => undefined,
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						_event: TFieldingProjectionsTableEvent
					) => {
						if (context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearPlayer: assign<TFieldingProjectionsTableContext, TFieldingProjectionsTableEvent>({
					player: (_context: TFieldingProjectionsTableContext, _event: TFieldingProjectionsTableEvent) =>
						undefined,
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						_event: TFieldingProjectionsTableEvent
					) => {
						if (context.cancelSources[PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TFieldingProjectionsTableContext, TFieldingProjectionsTableEvent>({
					playerId: (context: TFieldingProjectionsTableContext, event: TFieldingProjectionsTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data ?? undefined;
					}
				}),
				setFieldingProjections: assign<TFieldingProjectionsTableContext, TFieldingProjectionsTableEvent>({
					fieldingProjections: (
						context: TFieldingProjectionsTableContext,
						event: TFieldingProjectionsTableEvent
					) => {
						if (event.type !== SET_FIELDING_PROJECTIONS) return context.fieldingProjections;
						return event.data;
					},
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						event: TFieldingProjectionsTableEvent
					) => {
						if (event.type !== SET_FIELDING_PROJECTIONS) return context.cancelSources;
						if (context.cancelSources[FIELDING_PROJECTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[FIELDING_PROJECTIONS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[FIELDING_PROJECTIONS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setProjectedPositionalProportions: assign<
					TFieldingProjectionsTableContext,
					TFieldingProjectionsTableEvent
				>({
					projectedPositionalProportions: (
						context: TFieldingProjectionsTableContext,
						event: TFieldingProjectionsTableEvent
					) => {
						if (event.type !== SET_PROJECTED_POSITIONAL_PROPORTIONS)
							return context.projectedPositionalProportions;
						return event.data;
					},
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						event: TFieldingProjectionsTableEvent
					) => {
						if (event.type !== SET_PROJECTED_POSITIONAL_PROPORTIONS) return context.cancelSources;
						if (context.cancelSources[PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setProjectedPitcherProportions: assign<
					TFieldingProjectionsTableContext,
					TFieldingProjectionsTableEvent
				>({
					projectedPitcherProportions: (
						context: TFieldingProjectionsTableContext,
						event: TFieldingProjectionsTableEvent
					) => {
						if (event.type !== SET_PROJECTED_PITCHER_PROPORTIONS)
							return context.projectedPitcherProportions;
						return event.data;
					},
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						event: TFieldingProjectionsTableEvent
					) => {
						if (event.type !== SET_PROJECTED_PITCHER_PROPORTIONS) return context.cancelSources;
						if (context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayer: assign<TFieldingProjectionsTableContext, TFieldingProjectionsTableEvent>({
					player: (context: TFieldingProjectionsTableContext, event: TFieldingProjectionsTableEvent) => {
						if (event.type !== SET_PLAYER) return context.player;
						return event.data;
					},
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						event: TFieldingProjectionsTableEvent
					) => {
						if (event.type !== SET_PLAYER) return context.cancelSources;
						if (context.cancelSources[PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setIsExpanded: assign<TFieldingProjectionsTableContext, TFieldingProjectionsTableEvent>({
					isExpanded: (context: TFieldingProjectionsTableContext, event: TFieldingProjectionsTableEvent) => {
						if (event.type !== SET_IS_EXPANDED) return context.isExpanded;
						return event.data;
					}
				}),
				refreshFieldingProjectionsCancelSource: assign<
					TFieldingProjectionsTableContext,
					TFieldingProjectionsTableEvent
				>({
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						_event: TFieldingProjectionsTableEvent
					) => {
						if (context.cancelSources[FIELDING_PROJECTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[FIELDING_PROJECTIONS_CANCEL_SOURCE].cancel();
						context.cancelSources[FIELDING_PROJECTIONS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshProjectedPositionalProportionsCancelSource: assign<
					TFieldingProjectionsTableContext,
					TFieldingProjectionsTableEvent
				>({
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						_event: TFieldingProjectionsTableEvent
					) => {
						if (context.cancelSources[PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE].cancel();
						context.cancelSources[PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshProjectedPitcherProportionsCancelSource: assign<
					TFieldingProjectionsTableContext,
					TFieldingProjectionsTableEvent
				>({
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						_event: TFieldingProjectionsTableEvent
					) => {
						if (context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE] != null)
							context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE].cancel();
						context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerCancelSource: assign<TFieldingProjectionsTableContext, TFieldingProjectionsTableEvent>({
					cancelSources: (
						context: TFieldingProjectionsTableContext,
						_event: TFieldingProjectionsTableEvent
					) => {
						if (context.cancelSources[PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				handleFetchFieldingProjectionsSuccess: assign<
					TFieldingProjectionsTableContext,
					TFieldingProjectionsTableEvent
				>({
					fieldingProjections: (
						context: TFieldingProjectionsTableContext,
						event: TFieldingProjectionsTableEvent
					) => {
						if (event.type !== FETCH_FIELDING_PROJECTIONS_DONE) return context.fieldingProjections;
						return event.data;
					}
				}),
				handleFetchProjectedPositionalProportionsSuccess: assign<
					TFieldingProjectionsTableContext,
					TFieldingProjectionsTableEvent
				>({
					projectedPositionalProportions: (
						context: TFieldingProjectionsTableContext,
						event: TFieldingProjectionsTableEvent
					) => {
						if (event.type !== FETCH_PROJECTED_POSITIONAL_PROPORTIONS_DONE)
							return context.projectedPositionalProportions;
						return event.data;
					}
				}),
				handleFetchProjectedPitcherProportionsSuccess: assign<
					TFieldingProjectionsTableContext,
					TFieldingProjectionsTableEvent
				>({
					projectedPitcherProportions: (
						context: TFieldingProjectionsTableContext,
						event: TFieldingProjectionsTableEvent
					) => {
						if (event.type !== FETCH_PROJECTED_PITCHER_PROPORTIONS_DONE)
							return context.projectedPitcherProportions;
						return event.data;
					}
				}),
				handleFetchPlayerSuccess: assign<TFieldingProjectionsTableContext, TFieldingProjectionsTableEvent>({
					player: (context: TFieldingProjectionsTableContext, event: TFieldingProjectionsTableEvent) => {
						if (event.type !== FETCH_PLAYER_DONE) return context.player;
						return event.data;
					}
				}),
				handleFetchFieldingProjectionsErrored: (
					context: TFieldingProjectionsTableContext,
					_event: TFieldingProjectionsTableEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Fielding Projections",
							description: "Error fetching fielding projections data."
						});
				},
				handleFetchProjectedPositionalProportionsErrored: (
					context: TFieldingProjectionsTableContext,
					_event: TFieldingProjectionsTableEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Fielding Projections",
							description: "Error fetching projected positional proportions data."
						});
				},
				handleFetchProjectedPitcherProportionsErrored: (
					context: TFieldingProjectionsTableContext,
					_event: TFieldingProjectionsTableEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Fielding Projections",
							description: "Error fetching projected pitcher proportions data."
						});
				},
				handleFetchPlayerErrored: (
					context: TFieldingProjectionsTableContext,
					_event: TFieldingProjectionsTableEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Fielding Projections",
							description: "Error fetching player data."
						});
				}
			},
			services: {
				fetchFieldingProjections: (context: TFieldingProjectionsTableContext, _event: AnyEventObject) => {
					if (!context.playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchFieldingProjectionsBlend(
							{
								playerId: context.playerId,
								isUseCache: true
							},
							context.cancelSources[FIELDING_PROJECTIONS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchProjectedPositionalProportions: (
					context: TFieldingProjectionsTableContext,
					_event: AnyEventObject
				) => {
					if (!context.playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchProjectedPositionalProportions(
							{
								playerId: context.playerId,
								isUseCache: true
							},
							context.cancelSources[PROJECTED_POSITIONAL_PROPORTIONS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchProjectedPitcherProportions: (
					context: TFieldingProjectionsTableContext,
					_event: AnyEventObject
				) => {
					if (!context.playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchProjectedPitcherProportions(
							{
								playerId: context.playerId,
								isUseCache: true
							},
							context.cancelSources[PROJECTED_PITCHER_PROPORTIONS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayer: (context: TFieldingProjectionsTableContext, _event: AnyEventObject) => {
					if (!context.playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayer(
							{
								id: context.playerId as number // We know this is a number based on the if statement above
							},
							context.cancelSources[PLAYER_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default FieldingProjectionsTableMachine;
