import { MultiValue, SingleValue } from "react-select";
import { Align } from "react-window";
import { Dayjs } from "dayjs";

import { $TSFixMe, TOptionalExceptFor } from "utils/tsutils";
import { TTableDataRow } from "_react/table/machine";
import { TOption, TLk } from "_react/inputs";

export type $TSFixMeFilters = $TSFixMe;

export type TLkMap = { [key: string]: { [key: string]: TLk<string | number> } };

export type TSort = {
	key: string | null;
	asc: boolean;
};

export type TCheckboxProps<T> = {
	checked: Array<T[keyof T]>; // TODO: ideally we'd pass in a K that way checked and checked key are linked
	checkedKey?: keyof T;
	handleClick(datum: TTableDataRow<T>): void;
};

export type IMetaBase =
	| {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			[key: string]: any;
	  }
	| {};

export type $TSFixMeTableData = Array<$TSFixMe>;

export type TTableProps<T, M extends IMetaBase = IMetaBase> = {
	checkbox?: TCheckboxProps<T>;
	columns: Array<TColumn<T, M>>;
	data: $TSFixMeTableData;
	getRowKey: Function;
	filters?: { [key: string]: Array<TOption<string | number>> };
	handleRowClick?: Function;
	rowHeight?: number;
	showFilters?: boolean;
	sort: TSort;
};

export function isLkColumn<T, M>(column: TColumn<T, M> | TMakeColumnOptParams<T, M>): column is TColumnLk<T, M> {
	return column.type === "lk";
}

export function isDateColumn<T, M>(column: TColumn<T, M> | TMakeColumnOptParams<T, M>): column is TColumnDate<T, M> {
	return column.type === "date";
}

export function isComponentColumn<T, M>(
	column: TColumn<T, M> | TMakeColumnOptParams<T, M>
): column is TColumnComponent<T, M> {
	return column.type === "component";
}

export type TColumnTypes = "text" | "number" | "options" | "lk" | "date" | "component";
export type TLkColumnFilter = { key: string; getValue: Function };
export type TValueSort = number | string | Dayjs | null;

export type TColumnBase<T, M extends IMetaBase = IMetaBase> = {
	key: string;
	type: TColumnTypes;
	label: string;
	getValue({ datum, metadata }: { datum: T; metadata: M }): number | string;
	getValueDisplay({ datum, metadata }: { datum: T; metadata: M }): React.ReactChild;
	getValueSort({ datum, metadata }: { datum: T; metadata: M }): TValueSort;
	flex: string | number;
	options: Array<TOption<string | number>>;
	showFilters?: boolean;
	computeColor?(val: string | number): string;
	allowWrapping?: boolean;
};

export interface TColumnDate<T, M extends IMetaBase = IMetaBase> extends TColumnBase<T, M> {
	dateFormat: string;
}

export interface TColumnLk<T, M extends IMetaBase = IMetaBase> extends TColumnBase<T, M> {
	lkGetValueDisplay({ datum, lkItem, metadata }: { datum: T; lkItem: TLk<$TSFixMe>; metadata: M }): string;
	lkGetValueSort({ datum, lkItem, metadata }: { datum: T; lkItem: TLk<$TSFixMe>; metadata: M }): TValueSort;
	lkTable: string;
}

export interface TColumnComponent<T, M extends IMetaBase = IMetaBase> extends TColumnBase<T, M> {
	type: "component";
	getValueDisplay({ datum, metadata }: { datum: T; metadata: M }): React.ReactElement;
}

export type TColumn<T, M extends IMetaBase = IMetaBase> =
	| TColumnBase<T, M>
	| TColumnLk<T, M>
	| TColumnDate<T, M>
	| TColumnComponent<T, M>;

export type TMakeColumnOptParams<T, M extends IMetaBase = IMetaBase> = TOptionalExceptFor<TColumn<T, M>, "key">;

export type TFilterData = {
	columnKey: string;
	filterVal: MultiValue<TOption<string | number>> | SingleValue<TOption<string | number>> | null | string;
};

export type TableRowData = string[];
export type $TSFixMeTableDataFormatted = {
	data: TableRowData;
	metadata: { filteredDataIdx: number };
}[];

export type TScrollTo = { scrollIdx: number; align?: Align };

export interface IGet<T, M extends IMetaBase = IMetaBase> {
	datum: T;
	metadata: M;
}

export interface IGetLk<T, M extends IMetaBase = IMetaBase> extends IGet<T, M> {
	lkItem: TLk<$TSFixMe>;
}
