import { CSSProperties } from "react";
import { chakra, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { defaultColorScheme, ColorSchemeGroup, RED } from "_react/shared/legacy/ui/Colors";

type FooterProps = {
	colorSchemeGroup?: ColorSchemeGroup;
};

export const StyledFooter = styled.div<FooterProps>(({ colorSchemeGroup = defaultColorScheme }) => ({
	position: "fixed",
	bottom: 0,
	left: 0,
	right: 0,
	height: "40px",
	backgroundColor: "white",
	borderTop: `3px solid ${colorSchemeGroup.primary.color}`,
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	paddingLeft: "10px",
	paddingRight: "10px",
	lineHeight: "16px",
	zIndex: 1000
}));

export const FlexDiv = styled.div({
	display: "flex"
});

export const ModalContainer = styled.div({
	textAlign: "center"
});

export const ModalContent = styled.div({
	display: "flex",
	width: "100%",
	alignItems: "center",
	justifyContent: "center",
	marginBottom: "20px",
	"@media (max-width: 625px)": {
		flexDirection: "column"
	}
});

export const PlayersContainer = styled.div({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	marginRight: "20px",
	"@media (max-width: 545px)": {
		marginRight: "0"
	}
});

export const PlayerRow = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	width: "100%",
	margin: "10px",
	"@media (max-width: 545px)": {
		flexDirection: "column"
	}
});

export const MergeLabel = styled.div({
	width: "90px",
	"@media (max-width: 545px)": {
		marginBottom: "10px"
	}
});

export const PlayerLabel = styled.div({
	width: "250px",
	fontWeight: 600,
	"@media (max-width: 545px)": {
		marginBottom: "10px"
	}
});

export const StyledLink = styled.a({
	textDecoration: "underline",
	color: "blue",
	"&:hover": {
		opacity: 0.5
	},
	"&:visited": {
		color: "purple"
	},
	"&:active": {
		color: "red",
		opacity: 0.5
	}
});

export const LinkStyle = {
	fontSize: "14px",
	width: "130px"
};

export const HiddenStyle = {
	...LinkStyle,
	visibility: "hidden"
} as CSSProperties;

export const FooterAmaButtonStyle = {
	"@media (maxWidth: 825px)": {
		fontSize: "14px"
	},
	"@media (maxWidth: 810px)": {
		fontSize: "12px"
	},
	"@media (maxWidth: 715px)": {
		fontSize: "10px"
	},
	"@media (maxWidth: 620px)": {
		margin: "2px",
		padding: "5px 2px"
	},
	"@media (maxWidth: 410px)": {
		margin: "2px",
		padding: "0"
	}
} as CSSProperties;

export const FooterAmaBioButtonStyle = { marginRight: "10px", ...FooterAmaButtonStyle };

export const FooterProIntlButtonStyle = {
	"@media (maxWidth: 425px)": {
		fontSize: "12px",
		padding: "5px"
	},
	"@media (maxWidth: 360px)": {
		fontSize: "12px",
		padding: "2px"
	}
} as CSSProperties;

export const FooterIntlBioButtonStyle = { marginRight: "10px", ...FooterProIntlButtonStyle };

export const FooterBioDivStyle = { display: "flex", alignItems: "center", justifyContent: "center" };

export const FooterUnsavedChangesSpanStyle = { fontSize: "14px", fontWeight: 600, paddingRight: "5px", color: "red" };

export const FooterMergePlayerPrimaryStyle = { color: "green", fontSize: "16px", fontWeight: 600 };

export const FooterMergePlayerSecondaryStyle = { color: "red", fontSize: "16px", fontWeight: 600 };

export const FooterModalStyle = { margin: "10px" };

export const TooltipStyle = {
	width: "150px",
	whiteSpace: "pre-wrap",
	transform: "translate(0px, 20px)",
	left: "0px",
	backgroundColor: RED,
	fontSize: "14px"
};

export const HideStyle = {
	display: "none"
};

export const MinimalGridListContainerStyle = {
	borderTop: "1px solid",
	marginTop: 4,
	borderColor: "gray.300"
};

export const ToggleTopBreakpointValue = {
	base: undefined,
	md: "4px"
};

export const TogglePositionBreakpointValue = {
	base: undefined,
	md: "sticky"
	// Doing this so the value passes type checks as a valid `Position` and not a `string`
} as { [index: string]: "sticky" | undefined };

export const ToggleJustifyContentBreakpointValue = {
	base: "start",
	md: "end"
};
export const ToggleGapBreakpointValue = {
	base: "2",
	sm: "5"
};
export const ContentPaddingTopBreakpointValue = {
	base: "12",
	lg: "0"
};

// Chakra styles

export const AgentValueFlex = chakra(Flex, {
	baseStyle: {
		alignItems: "center",
		fontSize: "md",
		marginLeft: 1
	}
});

export const TOGGLE_STYLE = { container: { backgroundColor: "white", borderRadius: "base" } };
