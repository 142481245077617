import React, { createContext, useEffect } from "react";
import { Interpreter, TypegenDisabled } from "xstate";
import { useInterpret } from "@xstate/react";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { $TSAnyRequired } from "utils/tsutils";

import PlayerPageProMachine, {
	SET_PLAYER_ID,
	SET_PROJECTIONS_SEASON_DATA,
	SET_CURRENT_SEASON_DATA,
	TPlayerPageProContext,
	TPlayerPageProEvent
} from "_react/playerpage/pro/_machine";

// xState says to use the `InterpreterFrom<typeof yourMachine>` type (which translates to Interpreter<...>), but its' a type mismatch
// Type 'TypegenDisabled' is not assignable to type 'ResolveTypegenMeta<TypegenDisabled, TPlayerPageProEvent, BaseActionObject, ServiceMap>'
// https://xstate.js.org/docs/recipes/react.html#global-state-react-context
// So using a workaround which explicitly creates the `Interpreter` type we expect
// https://github.com/statelyai/xstate/discussions/3130
export const PlayerPageProContext = createContext({
	playerPageProService: {} as Interpreter<
		TPlayerPageProContext,
		$TSAnyRequired,
		TPlayerPageProEvent,
		$TSAnyRequired,
		TypegenDisabled
	>
});

type PlayerPageProProviderProps = {
	playerId?: number;
	projectionsSeasonData?: [number, number, boolean];
	currentSeasonData?: { currentSeason: number | undefined; isFetching: boolean | undefined };
	shouldFetchData?: boolean;
	toast?: CreateToastFnReturn;
	children?: React.ReactNode;
};

const PlayerPageProProvider = ({
	playerId,
	projectionsSeasonData,
	currentSeasonData,
	shouldFetchData = true,
	toast,
	children
}: PlayerPageProProviderProps) => {
	const playerPageProService = useInterpret(
		PlayerPageProMachine(playerId, projectionsSeasonData, currentSeasonData, shouldFetchData, toast)
	);

	// Update the machine's context when prop changes
	useEffect(() => {
		playerPageProService.send({ type: SET_PLAYER_ID, value: playerId });
	}, [playerPageProService, playerId]);

	useEffect(() => {
		playerPageProService.send({ type: SET_PROJECTIONS_SEASON_DATA, value: projectionsSeasonData });
	}, [playerPageProService, projectionsSeasonData]);

	useEffect(() => {
		playerPageProService.send({ type: SET_CURRENT_SEASON_DATA, value: currentSeasonData });
	}, [playerPageProService, currentSeasonData]);

	return <PlayerPageProContext.Provider value={{ playerPageProService }}>{children}</PlayerPageProContext.Provider>;
};

export default PlayerPageProProvider;
