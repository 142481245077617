import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import { round10 } from "_react/shared/_helpers/numbers";
import {
	NULL_FILLER_TEXT_PROJECTIONS_TAB,
	NULL_FILLER_TEXT_PERCENTAGE_PROJECTIONS_TAB
} from "_react/playerpage/_constants";
import { RAR_COLOR_GRADIENT, RAR_COLOR_GRADIENT_TEXT } from "_react/playerpage/pro/shared/_constants";
import {
	getCurrentSimpleHitterProjections,
	getCurrentSimplePitcherProjections
} from "_react/playerpage/pro/shared/_helpers";
import { TSimpleProjectionsAll, IProjectedPitcherProportions } from "_react/shared/data_models/projections/_types";
import { calculateSpRarFromRaa, calculateRpRarFromRaa } from "_react/projections/pro/_helpers";
import { TReplacementLevelActive } from "_react/shared/data_models/phred/_types";
import AceOfDiamonds from "_react/shared/ui/icons/AceOfDiamonds";
import BaseballBat from "_react/shared/ui/icons/BaseballBat";
import FireExtinguisher from "_react/shared/ui/icons/FireExtinguisher";
import { IPrimaryStatProps } from "_react/shared/ui/presentation/components/stat/PrimaryStat/PrimaryStat";
import { ISecondaryStatProps } from "_react/shared/ui/presentation/components/stat/SecondaryStat/SecondaryStat";
import { SM, FORMAT_PERCENTAGE } from "_react/shared/ui/presentation/components/stat/shared/_constants";
import StatCard from "_react/shared/ui/presentation/components/stat/StatCard/StatCard";
import { TOOLTIP_POSITION_PLAYER_RAR, TOOLTIP_RP_RAR, TOOLTIP_SP_RAR } from "_react/shared/_constants/tooltips";
import { useProjectionsSeason } from "_react/shared/_helpers/hooks";

import createProjectionsStatCardMachine, {
	TProjectionsStatCardContext,
	SET_PLAYER_ID,
	SET_SEASON,
	SET_PROJECTIONS,
	SET_PROJECTED_PITCHER_PROPORTIONS,
	FETCHING_PROJECTIONS,
	FETCHING_PROJECTED_PITCHER_PROPORTIONS,
	FETCHING_REPLACEMENT_LEVEL,
	SET_REPLACEMENT_LEVEL
} from "_react/shared/ui/data/cards/ProjectionsStatCard/_machine";

export type TProjectionsStatCardData = {
	projections?: TSimpleProjectionsAll | null;
	replacementLevel?: TReplacementLevelActive | null;
	isLoadingProjections?: boolean;
	projectionsSeasonData?: [number, number, boolean];
	projectedPitcherProportions?: IProjectedPitcherProportions | null;
	isLoadingProjectedPitcherProportions?: boolean;
};

// Remove the deconstructed data after the state machine is set up
type TProjectionsStatCardProps = {
	playerId?: number;
	showProjections?: {
		hitter?: boolean;
		startingPitcher?: boolean;
		reliefPitcher?: boolean;
	};
	data?: TProjectionsStatCardData;
	shouldFetchData?: boolean;
	style?: React.CSSProperties;
};

const ProjectionsStatCard = ({
	playerId,
	showProjections = { hitter: true, startingPitcher: true, reliefPitcher: true },
	data,
	shouldFetchData = true,
	style
}: TProjectionsStatCardProps) => {
	const toast = useToast();
	const [projectionsSeason, ros, isFetchingProjectionsSeason] =
		data?.projectionsSeasonData !== undefined ? data.projectionsSeasonData : useProjectionsSeason();

	const [current, send] = useMachine(
		createProjectionsStatCardMachine(playerId, projectionsSeason, shouldFetchData, data, toast)
	);
	const {
		projections,
		projectedPitcherProportions,
		replacementLevel
	} = current.context as TProjectionsStatCardContext;

	const fetchingProjections = current.matches(FETCHING_PROJECTIONS);
	const fetchingProjectedPitcherProportions = current.matches(FETCHING_PROJECTED_PITCHER_PROPORTIONS);
	const fetchingReplacementLevel = current.matches(FETCHING_REPLACEMENT_LEVEL);
	const isLoadingProjections = shouldFetchData
		? fetchingProjections || fetchingReplacementLevel || isFetchingProjectionsSeason
		: data?.isLoadingProjections;
	const isLoadingProjectedPitcherProportions = shouldFetchData
		? isFetchingProjectionsSeason || fetchingProjectedPitcherProportions
		: data?.isLoadingProjectedPitcherProportions;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_SEASON, data: projectionsSeason });
	}, [projectionsSeason, send]);

	useEffect(() => {
		if (data?.projections !== projections && !shouldFetchData) {
			send({ type: SET_PROJECTIONS, data: data?.projections });
		}
	}, [data?.projections, shouldFetchData, send, projections]);

	useEffect(() => {
		if (data?.projectedPitcherProportions !== projectedPitcherProportions && !shouldFetchData) {
			send({ type: SET_PROJECTED_PITCHER_PROPORTIONS, data: data?.projectedPitcherProportions });
		}
	}, [data?.projectedPitcherProportions, shouldFetchData, send, projectedPitcherProportions]);

	useEffect(() => {
		if (data?.replacementLevel !== replacementLevel && !shouldFetchData) {
			send({ type: SET_REPLACEMENT_LEVEL, data: data?.replacementLevel });
		}
	}, [data?.replacementLevel, shouldFetchData, send, replacementLevel]);

	const currentSimpleHitterProjections = getCurrentSimpleHitterProjections(projectionsSeason, projections, ros);
	const currentSimplePitcherProjections = getCurrentSimplePitcherProjections(projectionsSeason, projections, ros);

	const primaryStatRar: IPrimaryStatProps = {
		label: "RAR",
		value: round10(currentSimpleHitterProjections?.totalBattingRar, -1),
		nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
		colorGradientInfo: RAR_COLOR_GRADIENT_TEXT
	};

	const secondaryStatsRar: Array<ISecondaryStatProps> = [
		{
			label: "Hit RAA",
			value: round10(currentSimpleHitterProjections?.battingRaa, -1),
			nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
			colorGradientInfo: RAR_COLOR_GRADIENT,
			size: SM
		},
		{
			label: "Field RAA",
			value: round10(currentSimpleHitterProjections?.defRaa, -1),
			nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
			colorGradientInfo: RAR_COLOR_GRADIENT,
			size: SM
		},
		{
			label: "Run RAA",
			value: round10(currentSimpleHitterProjections?.baserunningRaa, -1),
			nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
			colorGradientInfo: RAR_COLOR_GRADIENT,
			size: SM
		},
		{
			label: "Pos Adj",
			value: round10(currentSimpleHitterProjections?.posAdj, -1),
			nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
			colorGradientInfo: RAR_COLOR_GRADIENT,
			size: SM
		}
	];

	const primaryStatSpRar: IPrimaryStatProps = {
		label: "SP RAR",
		value:
			currentSimplePitcherProjections?.projSpRaa != null && replacementLevel != null
				? round10(calculateSpRarFromRaa(currentSimplePitcherProjections.projSpRaa, replacementLevel), -1)
				: null,
		nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
		colorGradientInfo: RAR_COLOR_GRADIENT_TEXT
	};

	const secondaryStatSpRar: Array<ISecondaryStatProps> = [
		{
			label: "SP%",
			value: projectedPitcherProportions?.proportionSp,
			format: FORMAT_PERCENTAGE,
			nullFillerText: NULL_FILLER_TEXT_PERCENTAGE_PROJECTIONS_TAB,
			isLoading: isLoadingProjectedPitcherProportions,
			variant: "bullet"
		}
	];

	const primaryStatRpRar: ISecondaryStatProps = {
		label: "RP RAR",
		value:
			currentSimplePitcherProjections?.projRpRaa != null && replacementLevel != null
				? round10(calculateRpRarFromRaa(currentSimplePitcherProjections.projRpRaa, replacementLevel), -1)
				: null,
		nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
		colorGradientInfo: RAR_COLOR_GRADIENT_TEXT
	};

	const secondaryStatRpRar: Array<ISecondaryStatProps> = [
		{
			label: "RP%",
			value: projectedPitcherProportions?.proportionRp,
			format: FORMAT_PERCENTAGE,
			nullFillerText: NULL_FILLER_TEXT_PERCENTAGE_PROJECTIONS_TAB,
			isLoading: isLoadingProjectedPitcherProportions,
			variant: "bullet"
		}
	];
	return (
		<>
			{isLoadingProjections && (
				<StatCard
					primaryStat={{
						label: "RAR",
						tooltipLabel: TOOLTIP_POSITION_PLAYER_RAR,
						isLoading: isLoadingProjections
					}}
					statIcon={<BaseballBat color="gray.600" boxSize={6} />}
					style={style}
				/>
			)}
			{!isLoadingProjections && (
				<>
					{/* Checking for totalBattingRar instead of simpleHitterProjections because pitchers can have null hitter projections */}
					{currentSimpleHitterProjections?.totalBattingRar != null && showProjections.hitter && (
						<StatCard
							statIcon={<BaseballBat color="gray.600" boxSize={6} />}
							primaryStat={primaryStatRar}
							secondaryStats={secondaryStatsRar}
							tooltipLabel={TOOLTIP_POSITION_PLAYER_RAR}
							style={style}
						/>
					)}
					{currentSimplePitcherProjections && replacementLevel && (
						<>
							{currentSimplePitcherProjections.projSpRaa != null && showProjections.startingPitcher && (
								<StatCard
									statIcon={<AceOfDiamonds color="gray.600" boxSize={6} />}
									primaryStat={primaryStatSpRar}
									secondaryStats={secondaryStatSpRar}
									tooltipLabel={TOOLTIP_SP_RAR}
									style={style}
								/>
							)}
							{currentSimplePitcherProjections.projRpRaa != null && showProjections.reliefPitcher && (
								<StatCard
									statIcon={<FireExtinguisher color="gray.600" boxSize={6} />}
									primaryStat={primaryStatRpRar}
									secondaryStats={secondaryStatRpRar}
									tooltipLabel={TOOLTIP_RP_RAR}
									style={style}
								/>
							)}
						</>
					)}
					{/* Show an empty Statcard if no projections */}
					{!currentSimpleHitterProjections?.totalBattingRar &&
						!currentSimplePitcherProjections &&
						replacementLevel && (
							<StatCard
								primaryStat={{
									label: "RAR",
									nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
									isLoading: false
								}}
								statIcon={<BaseballBat color="gray.600" boxSize={6} />}
								tooltipLabel={TOOLTIP_POSITION_PLAYER_RAR}
								style={style}
							/>
						)}
				</>
			)}
		</>
	);
};

export default ProjectionsStatCard;
