import React, { FC, useState, useMemo, useEffect } from "react";
import dayjs from "dayjs";

import axios from "_redux/_utils/_axios";
import { isOption } from "_react/inputs/_types";
import LkSelect from "_react/inputs/lks/LkSelect";
import Table from "_react/table";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ButtonsSelected } from "_react/shared/legacy/ui/ButtonSelected";
import { DropdownSelect } from "_react/shared/legacy/ui/DropdownSelect";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import {
	getCollegeSplitsStatColumns,
	columnGenerator,
	INITIAL_SORT,
	PLAYER_TYPE_OPTS
} from "_react/playerpage/stat/collegesplits/_helpers";
import { SplitOption, TCollegeSplitsRow, TCollegeSplitsSplitRow } from "_react/playerpage/stat/collegesplits/_types";
import { SelectWrapper, OptionWrapper } from "_react/playerpage/stat/collegesplits/_styles";

type CollegeSplitsProps = {
	colorScheme: ColorSchemeGroup;
	player: TPlayerPageCombinedPlayer;
};

const PITCHER = "PITCHER";
const BATTER = "BATTER";
const MONTHS = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

type TPlayerType = typeof PITCHER | typeof BATTER; // don't include BOTH

function fetchCollegesplits(fetchURL: string) {
	return axios.get(fetchURL);
}

function makeCollegesplitsFetchURL(
	philId: number,
	playerType: TPlayerType,
	split: string | null,
	from: string,
	to: string
) {
	const baseURL = `/collegesplits/stats?philId=${philId}&playerType=${playerType}`;
	const splitGroup = split ? `&splitGroup=${split}` : "";
	const yearMin = from !== "all" ? `&yearMin=${from}` : "";
	const yearMax = to !== "all" ? `&yearMax=${to}` : "";
	return `${baseURL}${splitGroup}${yearMin}${yearMax}`;
}

export const CollegeSplits: FC<CollegeSplitsProps> = ({ colorScheme, player }) => {
	// Select Options
	const [playerType, setPlayerType] = useState<TPlayerType>(player.isPitcher ? PITCHER : BATTER);
	const [splitOption, setSplitOption] = useState<SplitOption>();
	const [from, setFrom] = useState("all");
	const [to, setTo] = useState("all");

	// Data Management
	const [collegesplitsData, setCollegesplitsData] = useState<TCollegeSplitsRow[] | null>(null);
	const [isFetching, setIsFetching] = useState(false);
	const fetchURL = useMemo(() => {
		if (player.playerAmaId == null) return null;
		const split = isOption<string>(splitOption, "string") ? splitOption.value : null;
		return makeCollegesplitsFetchURL(player.playerAmaId, playerType, split, from, to);
	}, [splitOption, player, playerType, from, to]);

	useEffect(() => {
		if (fetchURL) {
			setIsFetching(true);
			fetchCollegesplits(fetchURL)
				.then(response => {
					// For months splits, sort by month order instead of alphabetically
					const sortedData =
						isOption<string>(splitOption, "string") && splitOption?.value === "month"
							? response.data?.sort(
									(a: TCollegeSplitsSplitRow, b: TCollegeSplitsSplitRow) =>
										MONTHS.indexOf(a.splitLabel) - MONTHS.indexOf(b.splitLabel)
							  )
							: response.data;
					setCollegesplitsData(sortedData);
					setIsFetching(false);
				})
				.catch(() => {
					setIsFetching(false);
				});
		}
	}, [fetchURL, splitOption]);

	// Column Creation
	const columns = useMemo(() => columnGenerator(getCollegeSplitsStatColumns(playerType)), [playerType]);

	// Year range Options
	const dropdownOptions = useMemo(
		() => [
			{ value: "all", display: "All" },
			...[0, 1, 2, 3, 4, 5, 6].map(offset => ({
				value: `${dayjs().year() - offset}`,
				display: `${dayjs().year() - offset}`
			}))
		],
		[]
	);

	return (
		<Card overflow="hidden">
			<ColoredCardHeader colorScheme={colorScheme.secondary} text="College Splits">
				<SelectWrapper>
					<LkSelect
						dbName="lk"
						isClearable
						lkName={"collegesplits_lk_split_group"}
						menuPortalTarget={document.body}
						onChange={setSplitOption}
						placeholder={"Split"}
					/>
				</SelectWrapper>
				<OptionWrapper>
					<DropdownSelect
						buttonTitle={"From"}
						colorScheme={colorScheme.secondary}
						onSelect={setFrom}
						options={dropdownOptions}
						value={from}
					/>
				</OptionWrapper>
				<OptionWrapper>
					<DropdownSelect
						buttonTitle={"To"}
						colorScheme={colorScheme.secondary}
						onSelect={setTo}
						options={dropdownOptions}
						value={to}
					/>
				</OptionWrapper>
				<OptionWrapper>
					<ButtonsSelected
						colorScheme={colorScheme.primary}
						buttons={PLAYER_TYPE_OPTS}
						selected={playerType}
						onSelect={setPlayerType}
					/>
				</OptionWrapper>
			</ColoredCardHeader>
			<Table<TCollegeSplitsRow>
				columns={columns}
				data={collegesplitsData ?? []}
				maxHeight={276}
				rowHeight={25}
				sort={INITIAL_SORT}
				isLoading={isFetching}
				loadingRowsNum={6}
			/>
		</Card>
	);
};
