import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIos from "_react/shared/legacy/ui/icons/ArrowForwardIos";
import ArrowBackIos from "_react/shared/legacy/ui/icons/ArrowBackIos";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { isMobile } from "utils/_helpers";
import FormGroup from "_react/forms/FormGroup";

export const defaultColorScheme = {
	form: {
		searchBackground: "#BABABA",
		borderRadius: "2px",
		backgroundColor: "transparent",
		borderColor: "transparent",
		color: "#000000"
	},
	group: {
		backgroundColor: "#223f8d",
		borderColor: "#092B60",
		color: "#ffffff",
		borderRadius: "2px"
	},
	section: {
		backgroundColorOdd: "#E8E9ED",
		backgroundColorEven: "white",
		borderRadius: "2px"
	}
};

class Form extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			groupSelected: 0
		};

		this.triggerSave = this.triggerSave.bind(this);
		this.generateGroup = this.generateGroup.bind(this);
	}

	groups = [];

	triggerSave = () => {
		let success = true;
		for (let i = 0; i < this.groups.length; i++) {
			if (this.groups[i]) {
				if (!this.groups[i].triggerSave()) success = false;
			}
		}
		return success;
	};

	generateGroup = (layoutGroup, colorScheme, idx) => {
		return (
			<div
				key={idx}
				style={{
					backgroundColor: colorScheme.form.backgroundColor,
					border: `3px solid ${colorScheme.form.borderColor}`,
					paddingLeft: "8px",
					paddingRight: "8px",
					alignItems: "flex-start",
					display: layoutGroup.length > 1 ? "flex" : "block",
					...this.props.configuration.groupContainerStyle
				}}
			>
				{layoutGroup}
			</div>
		);
	};

	getMobileTabs = () => {
		const { configuration } = this.props;
		const { groupSelected, anchorEl } = this.state;

		const groups = configuration.groups.filter(e => e.show !== false);

		return (
			<AppBar key={"tabs1"} position="static">
				<div
					style={{
						display: "flex"
					}}
				>
					<IconButton
						aria-label="Prev"
						onClick={() => {
							if (groupSelected <= 0) return;
							this.setState({ groupSelected: groupSelected - 1 });
						}}
					>
						<ArrowBackIos fill={groupSelected > 0 ? "white" : "#f44236"} />
					</IconButton>
					<div
						onClick={e => {
							this.setState({ anchorEl: e.currentTarget });
						}}
						style={{
							flexGrow: 1,
							border: "2px solid #92352B",
							backgroundColor: "#92352B",
							borderRadius: "3px",
							margin: "10px",
							marginTop: "8px",
							marginBottom: "8px",
							display: "flex",
							alignItems: "center",
							textAlign: "center",
							fontSize: "1em",
							fontWeight: "600"
						}}
					>
						<div style={{ margin: "auto" }}>
							{groups[groupSelected] != null ? groups[groupSelected].title : ""}
						</div>
					</div>
					<IconButton
						aria-label="Prev"
						onClick={() => {
							if (groupSelected >= groups.length - 1) return;
							this.setState({ groupSelected: groupSelected + 1 });
						}}
					>
						<ArrowForwardIos fill={groupSelected < groups.length - 1 ? "white" : "#f44236"} />
					</IconButton>
				</div>
				<Menu
					anchorEl={anchorEl}
					onClose={() => this.setState({ anchorEl: null })}
					open={Boolean(anchorEl)}
					style={{
						width: "300px"
					}}
				>
					{groups.map((group, idx) => {
						return (
							<MenuItem
								key={idx}
								onClick={() =>
									this.setState({
										anchorEl: null,
										groupSelected: idx
									})
								}
								style={{ textAlign: "center" }}
							>
								{group.title}
							</MenuItem>
						);
					})}
				</Menu>
			</AppBar>
		);
	};

	render() {
		const {
			configuration,
			colorScheme,
			showEditButtons,
			onSave,
			editing,
			dataSource,
			autosave,
			autosaveTimer
		} = this.props;
		const { groupSelected } = this.state;

		const windowWidth = window.innerWidth;

		const fullColorScheme = {
			...defaultColorScheme,
			...colorScheme
		};

		this.groups = [];

		const layoutGroups = [];
		let currentLayoutGroup = [];
		let runningTotal = 0;
		for (let i = 0; i < configuration.groups.length; i++) {
			if (configuration.groups[i].show === false) continue;

			// Generate the actual FormGroup
			const formGroup = (
				<FormGroup
					autosave={autosave}
					autosaveTimer={autosaveTimer}
					colorScheme={fullColorScheme}
					configuration={configuration.groups[i]}
					dataSource={dataSource}
					editable={showEditButtons}
					editing={editing}
					handleChange={value => {
						onSave(value);
					}}
					key={i}
					ref={node => this.groups.push(node)}
					windowWidth={windowWidth}
				/>
			);

			// Add FormGroup to current layout group
			currentLayoutGroup.push(formGroup);
			runningTotal += configuration.groups[i].partialWidth;

			// Check if current FormGroup is partial
			if (
				isMobile(windowWidth) ||
				configuration.groups[i].partialWidth == null ||
				i === configuration.groups.length - 1 ||
				configuration.groups[i + 1].partialWidth == null ||
				runningTotal >= 12
			) {
				// Add this group to layout
				layoutGroups.push(this.generateGroup(currentLayoutGroup, fullColorScheme, layoutGroups.length));
				currentLayoutGroup = [];
				runningTotal = 0;
			}
		}

		if (isMobile(windowWidth))
			return [
				layoutGroups.length > 1 ? this.getMobileTabs() : null,
				<div key={0} style={{ marginTop: "10px" }}>
					{layoutGroups[groupSelected]}
				</div>
			];

		return layoutGroups;
	}
}

Form.propTypes = {
	autosave: PropTypes.bool,
	autosaveTimer: PropTypes.number,
	colorScheme: PropTypes.object,
	configuration: PropTypes.object,
	dataSource: PropTypes.object,
	editing: PropTypes.bool,
	onSave: PropTypes.func,
	showEditButtons: PropTypes.bool
};

Form.defaultProps = {
	autosave: true,
	autosaveTimer: 3000
};

Form.displayName = "Form";

export default connect(null, null, null, { withRef: true })(Form);
