import { round10 } from "_react/shared/_helpers/numbers";

export const formatCurrencyMillions = (value?: string | number | null) => {
	if (value == null) return value;
	return `$${value}M`;
};

export const formatPercentage = (value?: string | number | null) => {
	if (value == null) return value;
	if (typeof value === "string") value = parseFloat(value);
	return `${round10(value * 100, -1)}%`;
};
