import React, { useMemo, useContext } from "react";
import dayjs from "dayjs";
import { useSelector } from "@xstate/react";
import {
	Box,
	VStack,
	HStack,
	Tooltip,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel
} from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import { round10 } from "_react/shared/_helpers/numbers";
import Circle from "_react/shared/ui/icons/Circle";
import MinimalGridList from "_react/shared/ui/presentation/components/MinimalGridList/MinimalGridList";
import {
	TMinimalGridListItem,
	TMinimalGridListRow
} from "_react/shared/ui/presentation/components/MinimalGridList/_types";
import SecondaryStat from "_react/shared/ui/presentation/components/stat/SecondaryStat/SecondaryStat";
import { FORMAT_CURRENCY_MILLIONS, SM } from "_react/shared/ui/presentation/components/stat/shared/_constants";
import { IBlendedSurplusValue } from "_react/shared/data_models/surplus_value/_types";
import { TAllSurplusValues } from "_react/shared/data_models/surplus_value/_types";
import { TReplacementLevelActive } from "_react/shared/data_models/phred/_types";
import { TSimpleProjectionsAll } from "_react/shared/data_models/projections/_types";
import { calculateSpRarFromRaa, calculateRpRarFromRaa } from "_react/projections/pro/_helpers";
import { PRO } from "utils/tsutils";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { getMostRecentDraftPick, getAgentName } from "_react/playerpage/_helpers";
import { NULL_FILLER_TEXT_PROFILE } from "_react/playerpage/_constants";
import { AgentValueFlex, MinimalGridListContainerStyle } from "_react/playerpage/_styles";
import {
	TPlayerPageProState,
	FETCHING_SURPLUS_VALUES,
	FETCHING_PROJECTIONS,
	FETCHING_REPLACEMENT_LEVEL
} from "_react/playerpage/pro/_machine";
import { PlayerPageProContext } from "_react/playerpage/pro/PlayerPageProProvider";
import { FILLED_CIRCLE_COLOR, EMPTY_CIRCLE_COLOR } from "_react/playerpage/pro/shared/_styles";
import { SV_COLOR_GRADIENT } from "_react/playerpage/_constants";
import { RAR_COLOR_GRADIENT } from "_react/playerpage/pro/shared/_constants";
import {
	getCurrentSimpleHitterProjections,
	getCurrentSimplePitcherProjections,
	getR5Eligible,
	getPriorOutright,
	getMifaYear,
	getMls,
	getRyc,
	getOptionsUsed,
	getCurrentSeasonOptions,
	getDraftPick,
	getDraftPickSchool,
	getBirthplace
} from "_react/playerpage/pro/shared/_helpers";
import AgentAgencyPopover from "_react/playerpage/shared/profile/AgentAgencyPopover";

type PlayerPageProProfileProps = {
	player: TPlayerPageCombinedPlayer;
	hasProjectionsPermissions?: boolean;
};

const PlayerPageProProfile = ({ player, hasProjectionsPermissions = false }: PlayerPageProProfileProps) => {
	const playerPageProContext = useContext(PlayerPageProContext);

	const projectionsSeasonData: [number, number, boolean] | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.projectionsSeasonData
	);
	const [projectionsSeason, ros, isFetchingProjectionsSeason] = projectionsSeasonData
		? projectionsSeasonData
		: [dayjs().year(), 0, false];

	const surplusValues: TAllSurplusValues | null | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.surplusValues
	);
	const projections: TSimpleProjectionsAll | null | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.projections
	);
	const replacementLevel: TReplacementLevelActive | null | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.replacementLevel
	);

	const fetchingSurplusValues: boolean = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.matches(FETCHING_SURPLUS_VALUES)
	);
	const fetchingProjections: boolean = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.matches(FETCHING_PROJECTIONS)
	);
	const fetchingReplacementLevel: boolean = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.matches(FETCHING_REPLACEMENT_LEVEL)
	);
	const isLoadingProjections = fetchingProjections || fetchingReplacementLevel || isFetchingProjectionsSeason;

	const currentBlendedSurplusValue = surplusValues?.blendedSurplusValues.find(
		(sv: IBlendedSurplusValue) => sv.isCurrent
	);

	const currentYear = dayjs().year();
	const currentSimpleHitterProjections = getCurrentSimpleHitterProjections(
		projectionsSeason ?? currentYear,
		projections,
		ros
	);
	const currentSimplePitcherProjections = getCurrentSimplePitcherProjections(
		projectionsSeason ?? currentYear,
		projections,
		ros
	);

	const mostRecentDraftPick = getMostRecentDraftPick(player);
	const org = player && player.proProfile?.orgCode ? player.proProfile.orgCode.toLowerCase() : "none";

	const rosterData: Array<TMinimalGridListRow> = useMemo(() => {
		const newRosterData: Array<TMinimalGridListRow> = [];
		const rosterStatusItems: Array<TMinimalGridListItem> = [];

		if (player.proProfile?.rosterStatusMj != null)
			rosterStatusItems.push({
				label: "MJ Roster Status",
				value: (
					<Tooltip
						hasArrow
						placement="bottom"
						label={
							player.proProfile?.ebisRosterStatusMj?.desc !==
							player.proProfile?.ebisRosterStatusMj?.descShort
								? player.proProfile?.ebisRosterStatusMj?.desc
								: undefined
						}
					>
						<Box>
							{player.proProfile?.ebisRosterStatusMj?.descShort ??
								player.proProfile?.rosterStatusMj ??
								"-"}
						</Box>
					</Tooltip>
				)
			});

		if (player.proProfile?.rosterStatusMn != null)
			rosterStatusItems.push({
				label: "MN Roster Status",
				value: (
					<Tooltip
						hasArrow
						placement="bottom"
						label={
							player.proProfile?.ebisRosterStatusMn?.desc !==
							player.proProfile?.ebisRosterStatusMn?.descShort
								? player.proProfile?.ebisRosterStatusMn?.desc
								: undefined
						}
					>
						<Box>
							{player.proProfile?.ebisRosterStatusMn?.descShort ??
								player.proProfile?.rosterStatusMn ??
								"-"}
						</Box>
					</Tooltip>
				)
			});

		if (rosterStatusItems.length > 0) newRosterData.push({ items: rosterStatusItems });

		return newRosterData.concat([
			{
				items: [
					{
						label: "40-Man Roster",
						value: player.proProfile?.isOn40ManRoster ? "Yes" : "No"
					},
					{
						label: "DQ Contract",
						value: player.proProfile?.isDisqualifiedFlag ? "Yes" : "No"
					}
				]
			},
			{
				items: [
					{
						label: "OD/Cur MLS",
						value: getMls(player.proProfile, NULL_FILLER_TEXT_PROFILE)
					},
					{
						label: "RYC",
						value: getRyc(player.proProfile, NULL_FILLER_TEXT_PROFILE)
					}
				]
			},
			{
				items: [
					{
						label: "Options Used",
						value: (
							<>
								{player.proProfile?.optionsTotal &&
									Array.from(Array(player.proProfile.optionsTotal)).map((_value, index: number) => (
										<Circle
											key={index}
											color={
												(player.proProfile?.optionsUsed ?? 0) > index
													? FILLED_CIRCLE_COLOR
													: EMPTY_CIRCLE_COLOR
											}
										/>
									))}
								<Box as="span" fontSize="xs" paddingLeft="1">
									{getOptionsUsed(player.proProfile, NULL_FILLER_TEXT_PROFILE)}
								</Box>
							</>
						)
					},
					{
						label: "Cur Season Opt",
						value: (
							<>
								{player.proProfile?.optionsUsedCurrentSeason != null &&
									Array.from(Array(5)).map((_value, index: number) => (
										<Circle
											key={index}
											color={
												(player.proProfile?.optionsUsedCurrentSeason ?? 0) > index
													? FILLED_CIRCLE_COLOR
													: EMPTY_CIRCLE_COLOR
											}
										/>
									))}
								<Box as="span" fontSize="xs" paddingLeft="1">
									{getCurrentSeasonOptions(player.proProfile, NULL_FILLER_TEXT_PROFILE)}
								</Box>
							</>
						)
					}
				]
			},
			{
				items: [
					{
						label: "Prior Outright",
						value: getPriorOutright(player.proProfile)
					},
					{
						label: "Rule 5",
						value: getR5Eligible(player.proProfile)
					}
				]
			},
			{
				items: [
					{
						label: "MiFA Year",
						value: getMifaYear(player.proProfile, NULL_FILLER_TEXT_PROFILE)
					},
					{
						label: "",
						value: ""
					}
				]
			}
		]);
	}, [player]);

	const bioData: Array<TMinimalGridListRow> = useMemo(() => {
		return [
			{
				items: [
					{
						label: "Agent",
						value: (
							<>
								<Box width="min-content" paddingRight="1">
									{getAgentName(player.proProfile, NULL_FILLER_TEXT_PROFILE)}
								</Box>
								<AgentValueFlex>
									{player.proProfile?.agentId && (
										<AgentAgencyPopover
											playerType="pro"
											infoKey="agent"
											infoId={player.proProfile.agentId}
											org={org}
											playerClassification={PRO}
										/>
									)}
								</AgentValueFlex>
							</>
						)
					},
					{
						label: "Agency",
						value: (
							<>
								<Box width="min-content" paddingRight="1">
									{player.proProfile?.agent?.agency?.agencyName ?? NULL_FILLER_TEXT_PROFILE}
								</Box>
								<AgentValueFlex>
									{player.proProfile?.agent?.agencyId && (
										<AgentAgencyPopover
											playerType="pro"
											infoKey="agency"
											infoId={player.proProfile.agent.agencyId}
											org={org}
											playerClassification={PRO}
										/>
									)}
								</AgentValueFlex>
							</>
						)
					}
				]
			},
			{
				items: [
					{
						label: "Drafted",
						value: getDraftPick(mostRecentDraftPick, player.proProfile, NULL_FILLER_TEXT_PROFILE)
					},
					{
						label: "School",
						value: mostRecentDraftPick ? getDraftPickSchool(mostRecentDraftPick) : NULL_FILLER_TEXT_PROFILE
					}
				]
			},
			{
				items: [
					{
						label: "Birthdate",
						value:
							player.birthDate && player.birthDate != null
								? `${dayjs(player.birthDate).format("M/D/YYYY")}`
								: NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "Birthplace",
						value: getBirthplace(player)
					}
				]
			}
		];
	}, [player, mostRecentDraftPick, org]);

	const idData: Array<TMinimalGridListRow> = useMemo(() => {
		return [
			{
				items: [
					{
						label: "Combined ID",
						value: player.id ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "Phil ID (PRO)",
						value: player.playerProId ?? NULL_FILLER_TEXT_PROFILE
					}
				]
			},
			{
				items: [
					{
						label: "BAM ID",
						value: player.bamId ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "eBIS ID",
						value: player.ebisId ?? NULL_FILLER_TEXT_PROFILE
					}
				]
			},
			{
				items: [
					{
						label: "FanGraphs ID",
						value: player.fangraphsId ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "",
						value: ""
					}
				]
			}
		];
	}, [player]);

	const topMinimalGridListContainerStyle = useBreakpointValue({
		base: { paddingTop: "0" },
		md: undefined
	});

	return (
		<VStack alignItems="start" paddingLeft="12" paddingRight="12" paddingTop="6" paddingBottom="6" gap="0">
			{hasProjectionsPermissions && (
				<HStack spacing="4" paddingBottom="3">
					<SecondaryStat
						label="SV"
						value={round10(currentBlendedSurplusValue?.blendedSv, -1)}
						nullFillerText={NULL_FILLER_TEXT_PROFILE}
						format={FORMAT_CURRENCY_MILLIONS}
						colorGradientInfo={SV_COLOR_GRADIENT}
						size={SM}
						isLoading={fetchingSurplusValues}
					/>
					{isLoadingProjections && <SecondaryStat label="RAR" isLoading size={SM} />}
					{!isLoadingProjections && (
						<>
							{currentSimpleHitterProjections?.totalBattingRar && (
								<SecondaryStat
									label="RAR"
									value={round10(currentSimpleHitterProjections.totalBattingRar, -1)}
									nullFillerText={NULL_FILLER_TEXT_PROFILE}
									colorGradientInfo={RAR_COLOR_GRADIENT}
									size={SM}
								/>
							)}
							{currentSimplePitcherProjections && replacementLevel && (
								<>
									{currentSimplePitcherProjections.projSpRaa != null && (
										<SecondaryStat
											label="SP RAR"
											value={round10(
												calculateSpRarFromRaa(
													currentSimplePitcherProjections.projSpRaa,
													replacementLevel
												),
												-1
											)}
											nullFillerText={NULL_FILLER_TEXT_PROFILE}
											colorGradientInfo={RAR_COLOR_GRADIENT}
											size={SM}
										/>
									)}
									{currentSimplePitcherProjections.projRpRaa != null && (
										<SecondaryStat
											label="RP RAR"
											value={round10(
												calculateRpRarFromRaa(
													currentSimplePitcherProjections.projRpRaa,
													replacementLevel
												),
												-1
											)}
											nullFillerText={NULL_FILLER_TEXT_PROFILE}
											colorGradientInfo={RAR_COLOR_GRADIENT}
											size={SM}
										/>
									)}
								</>
							)}
						</>
					)}
				</HStack>
			)}
			<MinimalGridList
				label="Roster"
				data={rosterData}
				style={{
					container: hasProjectionsPermissions
						? {
								...MinimalGridListContainerStyle,
								...topMinimalGridListContainerStyle
						  }
						: {
								paddingTop: "0"
						  }
				}}
			/>
			<MinimalGridList
				label="Bio"
				data={bioData}
				style={{
					container: MinimalGridListContainerStyle
				}}
			/>
			<Accordion allowMultiple width="100%">
				<AccordionItem
					paddingTop="6"
					marginTop="4"
					borderTop="1px solid"
					borderColor="gray.300"
					borderBottom="none"
				>
					<AccordionButton padding="0">
						<Box color="gray.700" fontSize="sm" fontWeight="bold">
							System Information
						</Box>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel padding="0">
						<MinimalGridList data={idData} style={{ container: { paddingTop: 0 } }} />
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		</VStack>
	);
};

export default PlayerPageProProfile;
