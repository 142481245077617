import React from "react";

import EntityHeader, { EntityHeaderProps } from "_react/shared/ui/presentation/layouts/EntityHeader/EntityHeader";
import TabsWithSidebar, {
	TabsWithSidebarProps
} from "_react/shared/ui/presentation/layouts/TabsWithSidebar/TabsWithSidebar";

type EntityPageProps = {
	entityHeaderProps: EntityHeaderProps;
	tabsWithSidebarProps: TabsWithSidebarProps;
	isLoading?: boolean;
};

const EntityPage = ({ entityHeaderProps, tabsWithSidebarProps, isLoading = false }: EntityPageProps) => {
	return (
		<>
			<EntityHeader isLoading={isLoading} {...entityHeaderProps} />
			<TabsWithSidebar isLoading={isLoading} {...tabsWithSidebarProps} />
		</>
	);
};

export default EntityPage;
