import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "_redux";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();
const middleWares = [thunkMiddleware];

const middleware = applyMiddleware(...middleWares);

// TODO: Just go fix the setup and have package.json based on .env(prod.env,dev.env,local.env) files
const composedEnhancers = compose(middleware);

// https://github.com/zalmoxisus/redux-devtools-extension#2-use-with-redux
// https://stackoverflow.com/questions/38074154/redux-the-previous-state-received-by-the-reducer-has-unexpected-type-of-functi
const store = createStore(rootReducer, undefined, composedEnhancers);

export const getTestStore = () => {
	return createStore(rootReducer);
};

export default store;
