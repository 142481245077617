import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import PlayerPageHeader from "_react/playerpage/shared_js/PlayerPageHeader";
import { BLUE } from "_react/css";
import { PROSPECT_TYPE_MAP, MAKEUP_FIELDS } from "_react/evals/_constants";
import { formatHeight } from "utils/functions";
import { formatDraftAge } from "_react/playerpage/_helpers";
import { useDraftDate, useSchoolClass } from "_react/_hooks";
import IntlPitcherToolsTable from "_react/evals/intl/IntlPitcherToolsTable";
import IntlPlayerToolsTable from "_react/evals/intl/IntlPlayerToolsTable";

const IntlEvaluationPrint = ({ evaluation, player, scout, teamName }) => {
	const draftDate = useDraftDate(player?.eligible_year);
	const reportType = "International";
	const evalType = evaluation.eval_type === "INTL_HIT" ? "Postion Player" : "Pitcher";
	const reportTypeName = `${reportType} ${evalType} Report`;
	const schoolClass = useSchoolClass(evaluation.school_grade == null ? player.school_class : evaluation.school_grade);
	const gradDate =
		evaluation.school_grad_date != null
			? dayjs(evaluation.school_grad_date).format("YYYY-MM-DD")
			: player.school_grad_date
			? dayjs(player.school_grad_date).format("YYYY-MM-DD")
			: "";
	const rosterFirstName = evaluation.roster_first_name ? evaluation.roster_first_name : player.roster_first_name;
	let miscItems = [
		{ name: "Arm Angle", field: "angle" },
		{ name: "Delivery", field: "_del" },
		{ name: "Arm Action", field: "arm_act" },
		{ name: "Deception", field: "deception" }
	];
	if (evaluation.eval_type === "INTL_HIT") {
		miscItems = [
			{ name: "Hitting Mechanics", field: "hit_mech" },
			{ name: "Contact", field: "contact" },
			{ name: "Hitter Type", field: "hit_type" },
			{ name: "Home to 1B", field: "home_to_1b" },
			{ name: "60 Yard", field: "_60_yard" },
			{
				name: "Hitting Risk Assessment",
				field: "hitting_risk_assessment"
			}
		];
	}

	return (
		<div
			style={{
				padding: "10px",
				width: "100%",
				height: "100%",
				position: "relative",
				display: "block",
				pageBreakAfter: "auto"
			}}
		>
			<div style={{ display: "flex" }}>
				<div
					style={{
						flex: 1,
						fontWeight: "bold",
						display: "inline-block",
						fontSize: ".875em"
					}}
				>
					{reportTypeName}
					<br />
					{`${scout}`}
					<br />
					{`Seen: ${evaluation.seen_date != null ? evaluation.seen_date.format("M/D/YYYY") : ""}`}
					<br />
					{`Published: ${evaluation.publish_date != null ? evaluation.publish_date.format("M/D/YYYY") : ""}`}
				</div>
				<div
					style={{
						flex: 2,
						fontWeight: "bold",
						fontSize: "1.5em",
						display: "inline-block",
						textAlign: "center",
						verticalAlign: "top"
					}}
				>
					{`${player.roster_first_name ? player.roster_first_name : player.first_name} ${player.last_name}`}
				</div>
				<div
					style={{
						flex: 1,
						fontWeight: "bold",
						display: "inline-block",
						textAlign: "right",
						fontSize: ".875em"
					}}
				>
					{`OFP: ${evaluation.ofp ? evaluation.ofp : ""}`}
					<br />
					{`Interest: ${evaluation.interest_level ? evaluation.interest_level : ""}`}
					<br />
					{`Risk: ${evaluation.risk_factor ? evaluation.risk_factor : ""}`}
					<br />
					{`Ht/Wt: ${player.height ? formatHeight(player.height) : ""}${
						player.weight ? `/${player.weight}` : ""
					}`}
					<br />
					{`Draft Age: ${formatDraftAge(player.date_of_birth, draftDate)}`}
				</div>
			</div>
			<div
				style={{
					width: "100%",
					border: `2px solid ${BLUE}`,
					boxSizing: "border-box",
					marginTop: "5px"
				}}
			>
				<PlayerPageHeader height={20} text={reportTypeName} />
				<div style={{ width: "50%", display: "inline-block" }}>
					<table
						style={{
							tableLayout: "auto",
							borderCollapse: "collapse",
							width: "100%",
							fontSize: ".75em"
						}}
					>
						<tbody>
							<tr style={{ height: 15 }}>
								<td style={{ fontWeight: 600 }}>School</td>
								<td>{teamName}</td>
							</tr>
							<tr
								style={{
									backgroundColor: "#E8E8E8",
									height: 15
								}}
							>
								<td style={{ fontWeight: 600 }}>School Year</td>
								<td>{schoolClass}</td>
							</tr>
							<tr style={{ height: 15 }}>
								<td style={{ fontWeight: 600 }}>Graduation Date</td>
								<td>{gradDate}</td>
							</tr>
							<tr
								style={{
									backgroundColor: "#E8E8E8",
									height: 15
								}}
							>
								<td style={{ fontWeight: 600 }}>Roster First Name</td>
								<td>{rosterFirstName}</td>
							</tr>
							<tr style={{ height: 15 }}>
								<td style={{ fontWeight: 600 }}>Position Reported</td>
								<td>{evaluation.position}</td>
							</tr>
							<tr
								style={{
									backgroundColor: "#E8E8E8",
									height: 15
								}}
							>
								<td style={{ fontWeight: 600 }}>Seen Date</td>
								<td>{evaluation.seen_date ? evaluation.seen_date.format("YYYY-MM-DD") : ""}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div style={{ width: "50%", display: "inline-block" }}>
					<table
						style={{
							tableLayout: "auto",
							borderCollapse: "collapse",
							width: "100%",
							fontSize: ".75em"
						}}
					>
						<tbody>
							{evaluation.eval_type === "INTL_HIT" && (
								<tr style={{ height: 15 }}>
									<td style={{ fontWeight: 600 }}>Games Seen</td>
									<td>{evaluation.games_seen}</td>
								</tr>
							)}
							{evaluation.eval_type === "INTL_HIT" && (
								<tr style={{ height: 15 }}>
									<td style={{ fontWeight: 600 }}>Player Appearances Seen</td>
									<td>{evaluation.plate_appearances_seen}</td>
								</tr>
							)}
							{evaluation.eval_type !== "INTL_HIT" && (
								<tr style={{ height: 15 }}>
									<td style={{ fontWeight: 600 }}>Total Innings</td>
									<td>{evaluation.total_innings_seen_thirds}</td>
								</tr>
							)}
							{evaluation.eval_type !== "INTL_HIT" && (
								<tr style={{ height: 15 }}>
									<td style={{ fontWeight: 600 }}>60 Yard Time</td>
									<td>{evaluation["60_yard"]}</td>
								</tr>
							)}
							<tr
								style={{
									backgroundColor: "#E8E8E8",
									height: 15
								}}
							>
								<td style={{ fontWeight: 600 }}>Workouts</td>
								<td>{evaluation.workouts}</td>
							</tr>
							<tr style={{ height: 15 }}>
								<td style={{ fontWeight: 600 }}>Interest</td>
								<td>{evaluation.interest_level}</td>
							</tr>
							<tr style={{ height: 15 }}>
								<td style={{ fontWeight: 600 }}>Report Date</td>
								<td>
									{evaluation.eval_create_date
										? evaluation.eval_create_date.format("YYYY-MM-DD")
										: ""}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div style={{ width: "100%", marginTop: "5px" }}>
				<div
					style={{
						width: "calc(75% - 10px)",
						border: `2px solid ${BLUE}`,
						boxSizing: "border-box",
						marginRight: "10px",
						display: "inline-block",
						verticalAlign: "top"
					}}
				>
					<PlayerPageHeader height={20} text={"Tools"} />
					{evaluation.eval_type === "INTL_HIT" ? (
						<IntlPlayerToolsTable evaluation={evaluation} fontSize={".7em"} height={"23px"} />
					) : (
						<IntlPitcherToolsTable evaluation={evaluation} fontSize={".7em"} height={"23px"} />
					)}
				</div>
				<div
					style={{
						width: "25%",
						display: "inline-block",
						verticalAlign: "top"
					}}
				>
					<div
						style={{
							width: "100%",
							border: `2px solid ${BLUE}`,
							boxSizing: "border-box"
						}}
					>
						<PlayerPageHeader height={20} text={"Makeup"} />
						<table
							style={{
								tableLayout: "auto",
								borderCollapse: "collapse",
								width: "100%",
								fontSize: ".65em"
							}}
						>
							<tbody>
								{MAKEUP_FIELDS.map(({ name, id }, idx) => (
									<tr
										key={id}
										style={{
											height: 15,
											backgroundColor: idx % 2 === 0 ? "white" : "#E8E8E8"
										}}
									>
										<td style={{ fontWeight: 600 }}>{name}</td>
										<td>{evaluation[id]}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div
						style={{
							width: "100%",
							border: `2px solid ${BLUE}`,
							boxSizing: "border-box",
							marginTop: "5px"
						}}
					>
						<PlayerPageHeader height={20} text={"Miscellaneous"} />
						<table
							style={{
								tableLayout: "auto",
								borderCollapse: "collapse",
								width: "100%",
								fontSize: ".65em"
							}}
						>
							<tbody>
								{miscItems.map(({ name, field }, idx) => (
									<tr
										key={field}
										style={{
											height: 15,
											backgroundColor: idx % 2 === 0 ? "white" : "#E8E8E8"
										}}
									>
										<td style={{ fontWeight: 600 }}>{name}</td>
										<td>{evaluation[field]}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div style={{ width: "100%", marginTop: "5px" }}>
				<div
					style={{
						width: "calc(50% - 5px)",
						marginRight: "5px",
						display: "inline-block",
						verticalAlign: "top"
					}}
				>
					<div
						style={{
							width: "100%",
							fontSize: ".9em",
							border: `2px solid ${BLUE}`,
							boxSizing: "border-box"
						}}
					>
						<PlayerPageHeader height={15} text={"Player Comp"} />
						<div
							style={{
								padding: 3,
								width: "100%",
								boxSizing: "border-box",
								fontSize: ".7em"
							}}
						>
							{evaluation.player_comparison}
						</div>
					</div>
					<div
						style={{
							width: "100%",
							fontSize: ".9em",
							border: `2px solid ${BLUE}`,
							boxSizing: "border-box",
							marginTop: "5px"
						}}
					>
						<PlayerPageHeader height={15} text={"OFP/Prospect Type"} />
						<table
							style={{
								tableLayout: "auto",
								borderCollapse: "collapse",
								width: "100%",
								fontSize: ".7em"
							}}
						>
							<tbody>
								{[
									{ name: "OFP", field: "ofp" },
									{
										name: "Prospect Type",
										field: "prospect_type"
									},
									{
										name: "Risk Factor",
										field: "risk_factor"
									}
								].map(({ name, field }, idx) => (
									<tr
										key={field}
										style={{
											height: 15,
											backgroundColor: idx % 2 === 0 ? "white" : "#E8E8E8"
										}}
									>
										<td style={{ fontWeight: 600 }}>{name}</td>
										<td>
											{evaluation[field] === "prospect_type" &&
											PROSPECT_TYPE_MAP.hasOwnProperty(evaluation[field])
												? PROSPECT_TYPE_MAP[evaluation[field]]
												: evaluation[field]}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div
					style={{
						width: "calc(50% - 5px)",
						marginLeft: "5px",
						display: "inline-block",
						verticalAlign: "top"
					}}
				>
					<div
						style={{
							width: "100%",
							fontSize: ".9em",
							border: `2px solid ${BLUE}`,
							boxSizing: "border-box"
						}}
					>
						<PlayerPageHeader height={20} text={"Player Comp"} />
						<div
							style={{
								padding: 3,
								width: "100%",
								boxSizing: "border-box",
								fontSize: ".7em"
							}}
						>
							{evaluation.physical_description}
						</div>
					</div>
					<div
						style={{
							width: "100%",
							fontSize: ".9em",
							border: `2px solid ${BLUE}`,
							boxSizing: "border-box",
							marginTop: "5px"
						}}
					>
						<PlayerPageHeader height={20} text={"Injury Notes"} />
						<div
							style={{
								padding: 3,
								width: "100%",
								boxSizing: "border-box",
								fontSize: ".7em"
							}}
						>
							{evaluation.injury_notes}
						</div>
					</div>
				</div>
			</div>
			{[
				{ name: "Current Snapshot", field: "current_snapshot" },
				{ name: "Summary", field: "summary" }
			].map(({ name, field }) => (
				<div
					key={field}
					style={{
						width: "100%",
						fontSize: ".9em",
						border: `2px solid ${BLUE}`,
						boxSizing: "border-box",
						marginTop: "5px",
						pageBreakAfter: "auto"
					}}
				>
					<PlayerPageHeader height={20} text={name} />
					<div
						style={{
							padding: 3,
							width: "100%",
							boxSizing: "border-box",
							fontSize: ".7em"
						}}
					>
						{evaluation[field]
							? evaluation[field].split("\n").map((item, idx) => (
									<span key={idx}>
										{item}
										<br />
									</span>
							  ))
							: ""}
					</div>
				</div>
			))}
		</div>
	);
};

IntlEvaluationPrint.propTypes = {
	evaluation: PropTypes.object,
	player: PropTypes.object,
	scout: PropTypes.string,
	teamName: PropTypes.string
};

export default IntlEvaluationPrint;
