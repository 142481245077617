import styled from "@emotion/styled";

export const TooltipStyle = {
	width: "270px",
	transform: "translate(-100%)",
	backgroundColor: "rgb(125,125,125)",
	whiteSpace: "pre-wrap"
};

export const InfoStyle = {
	height: "14px",
	width: "14px"
};

export const CommentTooltipStyle = {
	width: "800px",
	// transform: "translate(-100%)",
	backgroundColor: "rgb(125,125,125)",
	whiteSpace: "pre-wrap"
};

export const CommentInfoStyle = {
	height: "24px",
	width: "24px"
};

export const ColorWhiteStyle = {
	color: "white"
};

export const ExpandStyle = {
	marginRight: "5px"
};

export const FlexDiv = styled.div({
	display: "flex"
});

export const ContractBonusTablesContainer = styled.div({
	display: "flex",
	"@media (max-width: 850px)": {
		display: "block"
	}
});

export const LeftTableContainer = styled.div({
	borderRight: "1px solid black"
});

export const TableText = styled.div({
	width: "100%",
	margin: "10px 0",
	textAlign: "center",
	fontSize: "12px",
	fontWeight: 600
});

export const ContentDiv = styled.div({
	padding: "10px 5px",
	borderTop: "1px solid black",
	borderBottom: "1px solid black"
});

export type TableTrProps = {
	isExpanded?: boolean;
	rowIndex?: number;
};

export const TableTr = styled.tr<TableTrProps>(({ isExpanded = false, rowIndex = 0 }) => {
	return {
		backgroundColor: isExpanded ? "rgb(255,226,223)" : rowIndex % 2 ? "#EEEEEE" : "white",
		color: "black",
		borderBottom: "0px solid black",
		borderTop: "0px solid black",
		"&:hover": {
			backgroundColor: "#002b5c20"
		}
	};
});

export const getCellStyle = (index: number, columnsLength: number, columnWidth?: number | string | null) => {
	return {
		backgroundColor: "black",
		color: "white",
		borderRight: index !== columnsLength - 1 ? "1px solid #808080" : "",
		borderBottom: "0px solid black",
		borderTop: "0px solid black",
		width: columnWidth ? columnWidth : ""
	};
};
