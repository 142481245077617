// Strike zone and zone section constants (in ft.)
export const PLATE_WIDTH = 17 / 12.0;
export const PLATE_HALF = PLATE_WIDTH / 2.0;
export const SZ_TOP = 3.405;
export const SZ_BOTTOM = 1.541;
export const SZ_HEIGHT = SZ_TOP - SZ_BOTTOM;
export const SZ_HALF = (SZ_TOP - SZ_BOTTOM) / 2.0 + SZ_BOTTOM;
export const MEATBALL_EDGE = 0.3649949577;
export const MEATBALL_TOP = SZ_HALF + MEATBALL_EDGE;
export const MEATBALL_BOTTOM = SZ_HALF - MEATBALL_EDGE;

export const HOME_PLATE_PATH_FT = (xScale: Function, yScale: Function) => {
	const xMiddleOfPlate = 0;
	const yTopMiddleOfPlate = 0.4;
	const yTopSidesOfPlate = 0.175;
	const yBottomOfPlate = 0;

	const path1 = `M ${xScale(xMiddleOfPlate)} ${yScale(yTopMiddleOfPlate + yBottomOfPlate)} L ${xScale(
		-PLATE_HALF
	)} ${yScale(yTopSidesOfPlate + yBottomOfPlate)} L ${xScale(-PLATE_HALF)} ${yScale(yBottomOfPlate)} L ${xScale(
		PLATE_HALF
	)} ${yScale(yBottomOfPlate)} L ${xScale(PLATE_HALF)} ${yScale(yTopSidesOfPlate + yBottomOfPlate)} Z`;
	return path1;
};
