import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { ColorScheme, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

import { $TSFixMe, Style } from "utils/tsutils";

type ColoredCardHeaderProps = {
	children?: $TSFixMe;
	colorScheme?: ColorScheme;
	icon?: $TSFixMe;
	style?: Style;
	text?: $TSFixMe;
	onClick?: Function;
};

type StyledColoredCardHeaderProps = {
	backgroundColor: Style["backgroundColor"];
	color: Style["color"];
	extraStyle: Style;
};
const StyledColoredCardHeader = styled.div<StyledColoredCardHeaderProps>(({ backgroundColor, color, extraStyle }) => ({
	padding: "4px",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	height: "100%",
	borderBottomLeftRadius: "0px",
	borderBottomRightRadius: "0px",
	backgroundColor,
	color,
	borderTopLeftRadius: "3px",
	borderTopRightRadius: "3px",
	...extraStyle
}));

const StylesColoredCardHeaderIcon = styled.div({
	textAlign: "center",
	display: "flex",
	alignItems: "center",
	padding: "5px"
});

export const StyledColoredCardHeaderTitle = styled.div({
	flex: 1,
	fontSize: "18px",
	padding: "4px 3px",
	fontWeight: 600,
	textAlign: "left"
});

const StyledColoredCardHeaderContents = styled.div({
	fontSize: "14px",
	fontWeight: 500,
	display: "flex",
	alignItems: "center"
});

export const ColoredCardHeader: FunctionComponent<ColoredCardHeaderProps> = ({
	children,
	colorScheme = defaultColorScheme.primary,
	icon = null,
	style = {},
	text,
	onClick
}) => {
	if (text) {
		return (
			<StyledColoredCardHeader
				backgroundColor={colorScheme.color}
				color={colorScheme.text}
				extraStyle={style}
				onClick={() => (onClick ? onClick() : null)}
			>
				{icon != null && (
					<StylesColoredCardHeaderIcon>
						{React.cloneElement(
							icon,
							{
								style: {
									color: colorScheme.color
								}
							},
							null
						)}
					</StylesColoredCardHeaderIcon>
				)}
				<StyledColoredCardHeaderTitle>{text}</StyledColoredCardHeaderTitle>
				<StyledColoredCardHeaderContents>{children}</StyledColoredCardHeaderContents>
			</StyledColoredCardHeader>
		);
	} else {
		return (
			<StyledColoredCardHeader backgroundColor={colorScheme.color} color={colorScheme.text} extraStyle={style}>
				{children}
			</StyledColoredCardHeader>
		);
	}
};
