import React from "react";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import AmaFieldingOaaTable from "_react/shared/ui/data/tables/AmaFieldingOaaTable/AmaFieldingOaaTable";
import Section from "_react/shared/ui/presentation/components/Section/Section";

import { SectionStyleObject } from "_react/playerpage/ama/shared/_styles";

type TFieldingSectionProps = {
	player: TPlayerPageCombinedPlayer;
};

const FieldingSection = ({ player }: TFieldingSectionProps) => {
	return (
		<Section label="Fielding" placement="start" style={SectionStyleObject}>
			<AmaFieldingOaaTable title="Fielding OAA" playerId={player.id} />
		</Section>
	);
};

export default FieldingSection;
