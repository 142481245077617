import React, { FunctionComponent } from "react";
import { TABLE_STYLE, CELL_STYLE } from "_react/css";
import { useHistory } from "react-router-dom";
import { $TSFixMe } from "utils/tsutils";

type DetailsTableProps = {
	columns?: $TSFixMe;
	cypress?: string;
	highlightIndex?: number;
	onColClick?: Function;
	row?: $TSFixMe;
	style?: $TSFixMe;
};

export const DetailsTable: FunctionComponent<DetailsTableProps> = ({
	columns,
	cypress = "",
	highlightIndex,
	onColClick,
	row
}) => {
	const history = useHistory();
	return (
		<table style={{ ...TABLE_STYLE, border: "none" }}>
			<tbody>
				{columns.map((col: $TSFixMe, colIdx: number) => {
					return (
						<tr
							key={colIdx}
							style={{
								backgroundColor:
									colIdx === highlightIndex ? "yellow" : colIdx % 2 ? "#EEEEEE" : "white",
								color: "black",
								borderBottom: "0px solid black",
								borderTop: "0px solid black"
							}}
						>
							<td
								cy-data={`${cypress}-${colIdx}-0`}
								onClick={() => {
									if (col.onCellClick != null) col.onCellClick(row, onColClick, history);
									else if (onColClick) onColClick(row, colIdx);
								}}
								style={{
									...CELL_STYLE,
									borderRight: colIdx === columns.length ? "" : "1px solid #cbcbcb",
									borderBottom: "0px solid black",
									borderTop: "0px solid black",
									borderLeft: "none",
									fontWeight: 600,
									textAlign: "left",
									paddingLeft: "10px",
									cursor: !onColClick ? undefined : "pointer"
								}}
							>
								{col.name}
							</td>
							<td
								cy-data={`${cypress}-${colIdx}-0`}
								onClick={() => {
									if (col.onCellClick != null) col.onCellClick(row, onColClick, history);
									else if (onColClick) onColClick(row, colIdx);
								}}
								style={{
									...CELL_STYLE,
									borderRight: colIdx === columns.length ? "" : "1px solid #cbcbcb",
									borderBottom: "0px solid black",
									borderTop: "0px solid black",
									borderLeft: "none",
									cursor: !onColClick ? undefined : "pointer"
								}}
							>
								{col.hasOwnProperty("getCellValue")
									? col.getCellValue(row)
									: col.value
									? col.value
									: row[col.name]}
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};
