import { aggregateStat } from "_react/shared/_helpers/stats";
import { BATS_L, BATS_OVERALL, BATS_R } from "_react/shared/data_models/baseline_hit_probs/_constants";
import {
	IPitchOutcomeObservedPitch,
	IPitchOutcomeObservedPitchByTeam
} from "_react/shared/data_models/pitch_outcome_observed/_types";
import {
	IPitchOutcomeProbabilitiesPitch,
	IPitchOutcomeProbabilitiesPitchByTeam
} from "_react/shared/data_models/pitch_outcome_probabilities/_types";
import {
	ICombinedPitcherPitchOutcomesData,
	TPitcherPitchOutcomesRow
} from "_react/shared/ui/data/tables/PitcherPitchOutcomesTable/_types";

//
// DATA PREPROCESSING
//

// Converts raw pitch outcome observed data to a record mapping
// season-gameType-pitchType to ICombinedPitcherPitchOutcomesData
export const createPitchOutcomeObservedRecord = (
	pichOutcomeObserved: Array<IPitchOutcomeObservedPitch>
): Record<string, ICombinedPitcherPitchOutcomesData> => {
	// Populate a record to track relevant pichOutcomeObserved instances
	// The record is keyed on "season-gameType-pitchType"
	return pichOutcomeObserved.reduce(
		(acc: Record<string, ICombinedPitcherPitchOutcomesData>, s: IPitchOutcomeObservedPitch) => {
			// If season-convertedGameType-pitchType does not exist, create an entry in the record
			if (!(`${s.season}-${s.gameType}-${s.pitchType}` in acc)) {
				acc[`${s.season}-${s.gameType}-${s.pitchType}`] = {
					season: s.season,
					gameType: s.gameType,
					pitchType: s.pitchType,
					lkPitchType: s.lkPitchType,
					total: s.bats === BATS_OVERALL ? s.total : 0,
					totalVl: s.bats === BATS_L ? s.total : 0,
					totalVr: s.bats === BATS_R ? s.total : 0,

					swingPct: s.bats === BATS_OVERALL ? s.swingPct : 0,
					swingPctVl: s.bats === BATS_L ? s.swingPct : 0,
					swingPctVr: s.bats === BATS_R ? s.swingPct : 0,

					whiffPct: s.bats === BATS_OVERALL ? s.whiffPct : 0,
					whiffPctVl: s.bats === BATS_L ? s.whiffPct : 0,
					whiffPctVr: s.bats === BATS_R ? s.whiffPct : 0,

					chasePct: s.bats === BATS_OVERALL ? s.chasePct : 0,
					chasePctVl: s.bats === BATS_L ? s.chasePct : 0,
					chasePctVr: s.bats === BATS_R ? s.chasePct : 0,

					cswPct: s.bats === BATS_OVERALL ? s.cswPct : 0,
					cswPctVl: s.bats === BATS_L ? s.cswPct : 0,
					cswPctVr: s.bats === BATS_R ? s.cswPct : 0
				};
			}
			// Otherwise, aggregate new row with old row
			else {
				const prev: ICombinedPitcherPitchOutcomesData = acc[`${s.season}-${s.gameType}-${s.pitchType}`];
				acc[`${s.season}-${s.gameType}-${s.pitchType}`] = aggregatePitchOutcomeObservedRows(prev, s);
			}
			return acc;
		},
		{}
	);
};

// Converts raw pitch outcome observed data to a record mapping
// season-gameType-pitchType to ICombinedPitcherPitchOutcomesData
export const createPitchOutcomeObservedByTeamRecord = (
	pichOutcomeObservedByTeam: Array<IPitchOutcomeObservedPitchByTeam>
): Record<string, ICombinedPitcherPitchOutcomesData> => {
	// Populate a record to track relevant pichOutcomeObservedByTeam instances
	// The record is keyed on "season-teamId-gameType-pitchType"
	return pichOutcomeObservedByTeam.reduce(
		(acc: Record<string, ICombinedPitcherPitchOutcomesData>, s: IPitchOutcomeObservedPitchByTeam) => {
			// If season-convertedGameType-pitchType does not exist, create an entry in the record
			if (!(`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}` in acc)) {
				acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`] = {
					season: s.season,
					gameType: s.gameType,
					pitchType: s.pitchType,
					lkPitchType: s.lkPitchType,
					teamId: s.teamId,
					team: s.teamBam,
					total: s.bats === BATS_OVERALL ? s.total : 0,
					totalVl: s.bats === BATS_L ? s.total : 0,
					totalVr: s.bats === BATS_R ? s.total : 0,

					swingPct: s.bats === BATS_OVERALL ? s.swingPct : 0,
					swingPctVl: s.bats === BATS_L ? s.swingPct : 0,
					swingPctVr: s.bats === BATS_R ? s.swingPct : 0,

					whiffPct: s.bats === BATS_OVERALL ? s.whiffPct : 0,
					whiffPctVl: s.bats === BATS_L ? s.whiffPct : 0,
					whiffPctVr: s.bats === BATS_R ? s.whiffPct : 0,

					chasePct: s.bats === BATS_OVERALL ? s.chasePct : 0,
					chasePctVl: s.bats === BATS_L ? s.chasePct : 0,
					chasePctVr: s.bats === BATS_R ? s.chasePct : 0,

					cswPct: s.bats === BATS_OVERALL ? s.cswPct : 0,
					cswPctVl: s.bats === BATS_L ? s.cswPct : 0,
					cswPctVr: s.bats === BATS_R ? s.cswPct : 0
				};
			}
			// Otherwise, aggregate new row with old row
			else {
				const prev: ICombinedPitcherPitchOutcomesData =
					acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`];
				acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`] = aggregatePitchOutcomeObservedRows(
					prev,
					s
				);
			}
			return acc;
		},
		{}
	);
};

const aggregatePitchOutcomeObservedRows = (
	prev: ICombinedPitcherPitchOutcomesData,
	s: IPitchOutcomeObservedPitch
): ICombinedPitcherPitchOutcomesData => {
	return {
		...prev,
		total: s.bats === BATS_OVERALL ? s.total : prev.total,
		totalVl: s.bats === BATS_L ? s.total : prev.totalVl,
		totalVr: s.bats === BATS_R ? s.total : prev.totalVr,

		swingPct: s.bats === BATS_OVERALL ? s.swingPct : prev.swingPct,
		swingPctVl: s.bats === BATS_L ? s.swingPct : prev.swingPctVl,
		swingPctVr: s.bats === BATS_R ? s.swingPct : prev.swingPctVr,

		whiffPct: s.bats === BATS_OVERALL ? s.whiffPct : prev.whiffPct,
		whiffPctVl: s.bats === BATS_L ? s.whiffPct : prev.whiffPctVl,
		whiffPctVr: s.bats === BATS_R ? s.whiffPct : prev.whiffPctVr,

		chasePct: s.bats === BATS_OVERALL ? s.chasePct : prev.chasePct,
		chasePctVl: s.bats === BATS_L ? s.chasePct : prev.chasePctVl,
		chasePctVr: s.bats === BATS_R ? s.chasePct : prev.chasePctVr,

		cswPct: s.bats === BATS_OVERALL ? s.cswPct : prev.cswPct,
		cswPctVl: s.bats === BATS_L ? s.cswPct : prev.cswPctVl,
		cswPctVr: s.bats === BATS_R ? s.cswPct : prev.cswPctVr
	};
};

// Converts raw pitch outcome probabilities data to a record mapping
// season-gameType-pitchType to ICombinedPitcherPitchOutcomesData
export const createPitchOutcomeProbabilitiesRecord = (
	pichOutcomeProbabilities: Array<IPitchOutcomeProbabilitiesPitch>
): Record<string, ICombinedPitcherPitchOutcomesData> => {
	// Populate a record to track relevant pichOutcomeProbabilities instances
	// The record is keyed on "season-gameType-pitchType"
	return pichOutcomeProbabilities.reduce(
		(acc: Record<string, ICombinedPitcherPitchOutcomesData>, s: IPitchOutcomeProbabilitiesPitch) => {
			// If season-convertedGameType-pitchType does not exist, create an entry in the record
			if (!(`${s.season}-${s.gameType}-${s.pitchType}` in acc)) {
				acc[`${s.season}-${s.gameType}-${s.pitchType}`] = {
					season: s.season,
					gameType: s.gameType,
					pitchType: s.pitchType,

					swingPctPIntrinsic: s.bats === BATS_OVERALL ? s.pSwingIntrinsic : 0,
					swingPctPIntrinsicVr: s.bats === BATS_R ? s.pSwingIntrinsic : 0,
					swingPctPIntrinsicVl: s.bats === BATS_L ? s.pSwingIntrinsic : 0,
					swingPctPStuff: s.bats === BATS_OVERALL ? s.pSwingStuff : 0,
					swingPctPStuffVr: s.bats === BATS_R ? s.pSwingStuff : 0,
					swingPctPStuffVl: s.bats === BATS_L ? s.pSwingStuff : 0,

					whiffPctPIntrinsic: s.bats === BATS_OVERALL ? s.pWhiffIntrinsic : 0,
					whiffPctPIntrinsicVr: s.bats === BATS_R ? s.pWhiffIntrinsic : 0,
					whiffPctPIntrinsicVl: s.bats === BATS_L ? s.pWhiffIntrinsic : 0,
					whiffPctPStuff: s.bats === BATS_OVERALL ? s.pWhiffStuff : 0,
					whiffPctPStuffVr: s.bats === BATS_R ? s.pWhiffStuff : 0,
					whiffPctPStuffVl: s.bats === BATS_L ? s.pWhiffStuff : 0,

					chasePctPIntrinsic: s.bats === BATS_OVERALL ? s.pChaseIntrinsic : 0,
					chasePctPIntrinsicVr: s.bats === BATS_R ? s.pChaseIntrinsic : 0,
					chasePctPIntrinsicVl: s.bats === BATS_L ? s.pChaseIntrinsic : 0,
					chasePctPStuff: s.bats === BATS_OVERALL ? s.pChaseStuff : 0,
					chasePctPStuffVr: s.bats === BATS_R ? s.pChaseStuff : 0,
					chasePctPStuffVl: s.bats === BATS_L ? s.pChaseStuff : 0,

					cswPctPIntrinsic: s.bats === BATS_OVERALL ? s.pCswIntrinsic : 0,
					cswPctPIntrinsicVr: s.bats === BATS_R ? s.pCswIntrinsic : 0,
					cswPctPIntrinsicVl: s.bats === BATS_L ? s.pCswIntrinsic : 0,
					cswPctPStuff: s.bats === BATS_OVERALL ? s.pCswStuff : 0,
					cswPctPStuffVr: s.bats === BATS_R ? s.pCswStuff : 0,
					cswPctPStuffVl: s.bats === BATS_L ? s.pCswStuff : 0
				};
			}
			// Otherwise, aggregate new row with old row
			else {
				const prev: ICombinedPitcherPitchOutcomesData = acc[`${s.season}-${s.gameType}-${s.pitchType}`];
				acc[`${s.season}-${s.gameType}-${s.pitchType}`] = aggregatePitchOutcomeProbabilitiesRows(prev, s);
			}

			return acc;
		},
		{}
	);
};

// Converts raw pitch outcome probabilities data to a record mapping
// season-gameType-pitchType to ICombinedPitcherPitchOutcomesData
export const createPitchOutcomeProbabilitiesByTeamRecord = (
	pichOutcomeProbabilitiesByTeam: Array<IPitchOutcomeProbabilitiesPitchByTeam>
): Record<string, ICombinedPitcherPitchOutcomesData> => {
	// Populate a record to track relevant pichOutcomeProbabilitiesByTeam instances
	// The record is keyed on "season-teamId-gameType-pitchType"
	return pichOutcomeProbabilitiesByTeam.reduce(
		(acc: Record<string, ICombinedPitcherPitchOutcomesData>, s: IPitchOutcomeProbabilitiesPitchByTeam) => {
			// If season-convertedGameType-pitchType does not exist, create an entry in the record
			if (!(`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}` in acc)) {
				acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`] = {
					season: s.season,
					gameType: s.gameType,
					pitchType: s.pitchType,
					teamId: s.teamId,

					swingPctPIntrinsic: s.bats === BATS_OVERALL ? s.pSwingIntrinsic : 0,
					swingPctPIntrinsicVr: s.bats === BATS_R ? s.pSwingIntrinsic : 0,
					swingPctPIntrinsicVl: s.bats === BATS_L ? s.pSwingIntrinsic : 0,
					swingPctPStuff: s.bats === BATS_OVERALL ? s.pSwingStuff : 0,
					swingPctPStuffVr: s.bats === BATS_R ? s.pSwingStuff : 0,
					swingPctPStuffVl: s.bats === BATS_L ? s.pSwingStuff : 0,

					whiffPctPIntrinsic: s.bats === BATS_OVERALL ? s.pWhiffIntrinsic : 0,
					whiffPctPIntrinsicVr: s.bats === BATS_R ? s.pWhiffIntrinsic : 0,
					whiffPctPIntrinsicVl: s.bats === BATS_L ? s.pWhiffIntrinsic : 0,
					whiffPctPStuff: s.bats === BATS_OVERALL ? s.pWhiffStuff : 0,
					whiffPctPStuffVr: s.bats === BATS_R ? s.pWhiffStuff : 0,
					whiffPctPStuffVl: s.bats === BATS_L ? s.pWhiffStuff : 0,

					chasePctPIntrinsic: s.bats === BATS_OVERALL ? s.pChaseIntrinsic : 0,
					chasePctPIntrinsicVr: s.bats === BATS_R ? s.pChaseIntrinsic : 0,
					chasePctPIntrinsicVl: s.bats === BATS_L ? s.pChaseIntrinsic : 0,
					chasePctPStuff: s.bats === BATS_OVERALL ? s.pChaseStuff : 0,
					chasePctPStuffVr: s.bats === BATS_R ? s.pChaseStuff : 0,
					chasePctPStuffVl: s.bats === BATS_L ? s.pChaseStuff : 0,

					cswPctPIntrinsic: s.bats === BATS_OVERALL ? s.pCswIntrinsic : 0,
					cswPctPIntrinsicVr: s.bats === BATS_R ? s.pCswIntrinsic : 0,
					cswPctPIntrinsicVl: s.bats === BATS_L ? s.pCswIntrinsic : 0,
					cswPctPStuff: s.bats === BATS_OVERALL ? s.pCswStuff : 0,
					cswPctPStuffVr: s.bats === BATS_R ? s.pCswStuff : 0,
					cswPctPStuffVl: s.bats === BATS_L ? s.pCswStuff : 0
				};
			}
			// Otherwise, aggregate new row with old row
			else {
				const prev: ICombinedPitcherPitchOutcomesData =
					acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`];
				acc[`${s.season}-${s.teamId}-${s.gameType}-${s.pitchType}`] = aggregatePitchOutcomeProbabilitiesRows(
					prev,
					s
				);
			}

			return acc;
		},
		{}
	);
};

const aggregatePitchOutcomeProbabilitiesRows = (
	prev: ICombinedPitcherPitchOutcomesData,
	s: IPitchOutcomeProbabilitiesPitch
): ICombinedPitcherPitchOutcomesData => {
	return {
		...prev,

		swingPctPIntrinsic: s.bats === BATS_OVERALL ? s.pSwingIntrinsic : prev.swingPctPIntrinsic,
		swingPctPIntrinsicVr: s.bats === BATS_R ? s.pSwingIntrinsic : prev.swingPctPIntrinsicVr,
		swingPctPIntrinsicVl: s.bats === BATS_L ? s.pSwingIntrinsic : prev.swingPctPIntrinsicVl,
		swingPctPStuff: s.bats === BATS_OVERALL ? s.pSwingStuff : prev.swingPctPStuff,
		swingPctPStuffVr: s.bats === BATS_R ? s.pSwingStuff : prev.swingPctPStuffVr,
		swingPctPStuffVl: s.bats === BATS_L ? s.pSwingStuff : prev.swingPctPStuffVl,

		whiffPctPIntrinsic: s.bats === BATS_OVERALL ? s.pWhiffIntrinsic : prev.whiffPctPIntrinsic,
		whiffPctPIntrinsicVr: s.bats === BATS_R ? s.pWhiffIntrinsic : prev.whiffPctPIntrinsicVr,
		whiffPctPIntrinsicVl: s.bats === BATS_L ? s.pWhiffIntrinsic : prev.whiffPctPIntrinsicVl,
		whiffPctPStuff: s.bats === BATS_OVERALL ? s.pWhiffStuff : prev.whiffPctPStuff,
		whiffPctPStuffVr: s.bats === BATS_R ? s.pWhiffStuff : prev.whiffPctPStuffVr,
		whiffPctPStuffVl: s.bats === BATS_L ? s.pWhiffStuff : prev.whiffPctPStuffVl,

		chasePctPIntrinsic: s.bats === BATS_OVERALL ? s.pChaseIntrinsic : prev.chasePctPIntrinsic,
		chasePctPIntrinsicVr: s.bats === BATS_R ? s.pChaseIntrinsic : prev.chasePctPIntrinsicVr,
		chasePctPIntrinsicVl: s.bats === BATS_L ? s.pChaseIntrinsic : prev.chasePctPIntrinsicVl,
		chasePctPStuff: s.bats === BATS_OVERALL ? s.pChaseStuff : prev.chasePctPStuff,
		chasePctPStuffVr: s.bats === BATS_R ? s.pChaseStuff : prev.chasePctPStuffVr,
		chasePctPStuffVl: s.bats === BATS_L ? s.pChaseStuff : prev.chasePctPStuffVl,

		cswPctPIntrinsic: s.bats === BATS_OVERALL ? s.pCswIntrinsic : prev.cswPctPIntrinsic,
		cswPctPIntrinsicVr: s.bats === BATS_R ? s.pCswIntrinsic : prev.cswPctPIntrinsicVr,
		cswPctPIntrinsicVl: s.bats === BATS_L ? s.pCswIntrinsic : prev.cswPctPIntrinsicVl,
		cswPctPStuff: s.bats === BATS_OVERALL ? s.pCswStuff : prev.cswPctPStuff,
		cswPctPStuffVr: s.bats === BATS_R ? s.pCswStuff : prev.cswPctPStuffVr,
		cswPctPStuffVl: s.bats === BATS_L ? s.pCswStuff : prev.cswPctPStuffVl
	};
};

export const mergePitchOutcomeAggregations = (
	pichOutcomeObserved: Record<string, ICombinedPitcherPitchOutcomesData>,
	pichOutcomeProbabilities: Record<string, ICombinedPitcherPitchOutcomesData>,
	byTeam = false
): Record<string, ICombinedPitcherPitchOutcomesData> => {
	return Object.values(pichOutcomeObserved).reduce(
		(acc: Record<string, ICombinedPitcherPitchOutcomesData>, o: ICombinedPitcherPitchOutcomesData) => {
			// Get record
			const key = byTeam
				? `${o.season}-${o.teamId}-${o.gameType}-${o.pitchType}`
				: `${o.season}-${o.gameType}-${o.pitchType}`;
			const record = pichOutcomeProbabilities[key];
			acc[key] = {
				...o,
				...record
			};
			return acc;
		},
		{}
	);
};

export const aggregateCombinedPitcherPitchOutcomesDataRows = (
	prev: ICombinedPitcherPitchOutcomesData,
	curr: ICombinedPitcherPitchOutcomesData
): ICombinedPitcherPitchOutcomesData => {
	return {
		...prev,

		total: (prev.total ?? 0) + (curr.total ?? 0),
		totalVl: (prev.totalVl ?? 0) + (curr.totalVl ?? 0),
		totalVr: (prev.totalVl ?? 0) + (curr.totalVl ?? 0),

		swingPct: aggregateStat(prev.total ?? 0, prev.swingPct ?? null, curr.total ?? 0, curr.swingPct ?? null),
		swingPctVl: aggregateStat(
			prev.totalVl ?? 0,
			prev.swingPctVl ?? null,
			curr.totalVl ?? 0,
			curr.swingPctVl ?? null
		),
		swingPctVr: aggregateStat(
			prev.totalVr ?? 0,
			prev.swingPctVr ?? null,
			curr.totalVr ?? 0,
			curr.swingPctVr ?? null
		),

		whiffPct: aggregateStat(prev.total ?? 0, prev.whiffPct ?? null, curr.total ?? 0, curr.whiffPct ?? null),
		whiffPctVl: aggregateStat(
			prev.totalVl ?? 0,
			prev.whiffPctVl ?? null,
			curr.totalVl ?? 0,
			curr.whiffPctVl ?? null
		),
		whiffPctVr: aggregateStat(
			prev.totalVr ?? 0,
			prev.whiffPctVr ?? null,
			curr.totalVr ?? 0,
			curr.whiffPctVr ?? null
		),

		chasePct: aggregateStat(prev.total ?? 0, prev.chasePct ?? null, curr.total ?? 0, curr.chasePct ?? null),
		chasePctVl: aggregateStat(
			prev.totalVl ?? 0,
			prev.chasePctVl ?? null,
			curr.totalVl ?? 0,
			curr.chasePctVl ?? null
		),
		chasePctVr: aggregateStat(
			prev.totalVr ?? 0,
			prev.chasePctVr ?? null,
			curr.totalVr ?? 0,
			curr.chasePctVr ?? null
		),

		cswPct: aggregateStat(prev.total ?? 0, prev.cswPct ?? null, curr.total ?? 0, curr.cswPct ?? null),
		cswPctVl: aggregateStat(prev.totalVl ?? 0, prev.cswPctVl ?? null, curr.totalVl ?? 0, curr.cswPctVl ?? null),
		cswPctVr: aggregateStat(prev.totalVr ?? 0, prev.cswPctVr ?? null, curr.totalVr ?? 0, curr.cswPctVr ?? null),

		swingPctPIntrinsic: aggregateStat(
			prev.total ?? 0,
			prev.swingPctPIntrinsic ?? null,
			curr.total ?? 0,
			curr.swingPctPIntrinsic ?? null
		),
		swingPctPIntrinsicVl: aggregateStat(
			prev.totalVl ?? 0,
			prev.swingPctPIntrinsicVl ?? null,
			curr.totalVl ?? 0,
			curr.swingPctPIntrinsicVl ?? null
		),
		swingPctPIntrinsicVr: aggregateStat(
			prev.totalVr ?? 0,
			prev.swingPctPIntrinsicVr ?? null,
			curr.totalVr ?? 0,
			curr.swingPctPIntrinsicVr ?? null
		),
		swingPctPStuff: aggregateStat(
			prev.total ?? 0,
			prev.swingPctPStuff ?? null,
			curr.total ?? 0,
			curr.swingPctPStuff ?? null
		),
		swingPctPStuffVl: aggregateStat(
			prev.totalVl ?? 0,
			prev.swingPctPStuffVl ?? null,
			curr.totalVl ?? 0,
			curr.swingPctPStuffVl ?? null
		),
		swingPctPStuffVr: aggregateStat(
			prev.totalVr ?? 0,
			prev.swingPctPStuffVr ?? null,
			curr.totalVr ?? 0,
			curr.swingPctPStuffVr ?? null
		),

		whiffPctPIntrinsic: aggregateStat(
			prev.total ?? 0,
			prev.whiffPctPIntrinsic ?? null,
			curr.total ?? 0,
			curr.whiffPctPIntrinsic ?? null
		),
		whiffPctPIntrinsicVl: aggregateStat(
			prev.totalVl ?? 0,
			prev.whiffPctPIntrinsicVl ?? null,
			curr.totalVl ?? 0,
			curr.whiffPctPIntrinsicVl ?? null
		),
		whiffPctPIntrinsicVr: aggregateStat(
			prev.totalVr ?? 0,
			prev.whiffPctPIntrinsicVr ?? null,
			curr.totalVr ?? 0,
			curr.whiffPctPIntrinsicVr ?? null
		),
		whiffPctPStuff: aggregateStat(
			prev.total ?? 0,
			prev.whiffPctPStuff ?? null,
			curr.total ?? 0,
			curr.whiffPctPStuff ?? null
		),
		whiffPctPStuffVl: aggregateStat(
			prev.totalVl ?? 0,
			prev.whiffPctPStuffVl ?? null,
			curr.totalVl ?? 0,
			curr.whiffPctPStuffVl ?? null
		),
		whiffPctPStuffVr: aggregateStat(
			prev.totalVr ?? 0,
			prev.whiffPctPStuffVr ?? null,
			curr.totalVr ?? 0,
			curr.whiffPctPStuffVr ?? null
		),

		chasePctPIntrinsic: aggregateStat(
			prev.total ?? 0,
			prev.chasePctPIntrinsic ?? null,
			curr.total ?? 0,
			curr.chasePctPIntrinsic ?? null
		),
		chasePctPIntrinsicVl: aggregateStat(
			prev.totalVl ?? 0,
			prev.chasePctPIntrinsicVl ?? null,
			curr.totalVl ?? 0,
			curr.chasePctPIntrinsicVl ?? null
		),
		chasePctPIntrinsicVr: aggregateStat(
			prev.totalVr ?? 0,
			prev.chasePctPIntrinsicVr ?? null,
			curr.totalVr ?? 0,
			curr.chasePctPIntrinsicVr ?? null
		),
		chasePctPStuff: aggregateStat(
			prev.total ?? 0,
			prev.chasePctPStuff ?? null,
			curr.total ?? 0,
			curr.chasePctPStuff ?? null
		),
		chasePctPStuffVl: aggregateStat(
			prev.totalVl ?? 0,
			prev.chasePctPStuffVl ?? null,
			curr.totalVl ?? 0,
			curr.chasePctPStuffVl ?? null
		),
		chasePctPStuffVr: aggregateStat(
			prev.totalVr ?? 0,
			prev.chasePctPStuffVr ?? null,
			curr.totalVr ?? 0,
			curr.chasePctPStuffVr ?? null
		),

		cswPctPIntrinsic: aggregateStat(
			prev.total ?? 0,
			prev.cswPctPIntrinsic ?? null,
			curr.total ?? 0,
			curr.cswPctPIntrinsic ?? null
		),
		cswPctPIntrinsicVl: aggregateStat(
			prev.totalVl ?? 0,
			prev.cswPctPIntrinsicVl ?? null,
			curr.totalVl ?? 0,
			curr.cswPctPIntrinsicVl ?? null
		),
		cswPctPIntrinsicVr: aggregateStat(
			prev.totalVr ?? 0,
			prev.cswPctPIntrinsicVr ?? null,
			curr.totalVr ?? 0,
			curr.cswPctPIntrinsicVr ?? null
		),
		cswPctPStuff: aggregateStat(
			prev.total ?? 0,
			prev.cswPctPStuff ?? null,
			curr.total ?? 0,
			curr.cswPctPStuff ?? null
		),
		cswPctPStuffVl: aggregateStat(
			prev.totalVl ?? 0,
			prev.cswPctPStuffVl ?? null,
			curr.totalVl ?? 0,
			curr.cswPctPStuffVl ?? null
		),
		cswPctPStuffVr: aggregateStat(
			prev.totalVr ?? 0,
			prev.cswPctPStuffVr ?? null,
			curr.totalVr ?? 0,
			curr.cswPctPStuffVr ?? null
		)
	};
};

// Used to get the level to display for a row
export const getLevelsFromRow = (row: TPitcherPitchOutcomesRow): Array<string> => {
	// Child Rows or rows with no nested data
	if ("team" in row.combinedPitcherPitchOutcomesData)
		return row.combinedPitcherPitchOutcomesData.team?.level
			? [row.combinedPitcherPitchOutcomesData.team?.level]
			: [];
	// Parent Rows with nested data
	if (row.childData && row.childData.length > 1) {
		return [
			...new Set(
				row.childData.reduce((acc: Array<string>, childRow: TPitcherPitchOutcomesRow) => {
					if (
						"team" in childRow.combinedPitcherPitchOutcomesData &&
						childRow.combinedPitcherPitchOutcomesData.team?.level
					)
						acc.push(childRow.combinedPitcherPitchOutcomesData.team?.level);
					return acc;
				}, [])
			)
		];
	}
	return [];
};
