import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { fetchAllSurplusValues } from "_react/shared/data_models/surplus_value/_network";
import { TAllSurplusValues, TSeasonalSurplusValue } from "_react/shared/data_models/surplus_value/_types";
import { TSeasonalSurplusValuesTableData } from "_react/shared/ui/data/tables/SeasonalSurplusValuesTable/SeasonalSurplusValuesTable";

export type TSeasonalSurplusValuesTableContext = {
	view: string;
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	seasonalSurplusValues?: Array<TSeasonalSurplusValue> | null;
	birthDate?: string | null;
	cancelSource?: CancelTokenSource | null;
	toast?: CreateToastFnReturn;
};

interface ISeasonalSurplusValuesTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId changes
				contextRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all seasonal surplus value data
				seasonalSurplusValues: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_BIRTH_DATE = "SET_BIRTH_DATE";
export const SET_SEASONAL_SURPLUS_VALUES = "SET_SEASONAL_SURPLUS_VALUES";
export const FETCHING_SEASONAL_SURPLUS_VALUES = { initialized: { seasonalSurplusValues: "fetching" } };

const FETCH_SEASONAL_SURPLUS_VALUES_DONE = "done.invoke.fetchingSeasonalSurplusValues:invocation[0]";

export const SET_VIEW = "SET_VIEW";

type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | null | undefined;
};
type TSetBirthDateEvent = {
	type: typeof SET_BIRTH_DATE;
	data: string | null | undefined;
};
type TSetSeasonalSurplusValuesEvent = {
	type: typeof SET_SEASONAL_SURPLUS_VALUES;
	data: Array<TSeasonalSurplusValue> | undefined;
};
type TFetchSeasonalSurplusValuesEvent = {
	type: typeof FETCH_SEASONAL_SURPLUS_VALUES_DONE;
	data?: Array<TAllSurplusValues>;
};
type TSetViewEvent = { type: typeof SET_VIEW; value: string };

type TSeasonalSurplusValuesTableEvent =
	| TSetPlayerIdEvent
	| TSetBirthDateEvent
	| TSetSeasonalSurplusValuesEvent
	| TFetchSeasonalSurplusValuesEvent
	| TSetViewEvent;

export type TSeasonalSurplusValuesTableSend = Interpreter<
	TSeasonalSurplusValuesTableContext,
	ISeasonalSurplusValuesTableStateSchema,
	TSeasonalSurplusValuesTableEvent
>["send"];

const SeasonalSurplusValuesTableMachine = (
	playerIdProp?: number,
	data?: TSeasonalSurplusValuesTableData,
	shouldFetchData = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TSeasonalSurplusValuesTableContext,
		ISeasonalSurplusValuesTableStateSchema,
		TSeasonalSurplusValuesTableEvent
	>(
		{
			id: "seasonalSurplusValueTable",
			initial: "initializing",
			context: {
				view: "overview",
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchData,
				seasonalSurplusValues: data?.seasonalSurplusValues,
				birthDate: data?.birthDate,
				cancelSource: undefined,
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_BIRTH_DATE]: { actions: "setBirthDate" },
						[SET_SEASONAL_SURPLUS_VALUES]: { actions: "setSeasonalSurplusValues" },
						[SET_VIEW]: { actions: "setView" }
					},
					states: {
						contextRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						seasonalSurplusValues: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									always: {
										target: "#fetchingSeasonalSurplusValues",
										cond: "shouldFetchSeasonalSurplusValues"
									},
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingSeasonalSurplusValues",
									entry: ["refreshCancelSource"],
									invoke: {
										src: "fetchSeasonalSurplusValues",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSeasonalSurplusValuesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSeasonalSurplusValuesErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TSeasonalSurplusValuesTableContext,
					_event: TSeasonalSurplusValuesTableEvent
				) => context.playerId !== context.lastPlayerId,
				shouldFetchSeasonalSurplusValues: (
					context: TSeasonalSurplusValuesTableContext,
					_event: TSeasonalSurplusValuesTableEvent
				) => context.seasonalSurplusValues === undefined && context.playerId !== undefined && shouldFetchData
			},
			actions: {
				clearContext: assign<TSeasonalSurplusValuesTableContext, TSeasonalSurplusValuesTableEvent>({
					lastPlayerId: (
						context: TSeasonalSurplusValuesTableContext,
						_event: TSeasonalSurplusValuesTableEvent
					) => context.playerId,
					seasonalSurplusValues: (
						_context: TSeasonalSurplusValuesTableContext,
						_event: TSeasonalSurplusValuesTableEvent
					) => undefined,
					birthDate: (
						_context: TSeasonalSurplusValuesTableContext,
						_event: TSeasonalSurplusValuesTableEvent
					) => undefined,
					cancelSource: (
						context: TSeasonalSurplusValuesTableContext,
						_event: TSeasonalSurplusValuesTableEvent
					) => {
						context.cancelSource?.cancel();
						return undefined;
					}
				}),
				setView: assign<TSeasonalSurplusValuesTableContext, TSeasonalSurplusValuesTableEvent>({
					view: (context: TSeasonalSurplusValuesTableContext, event: TSeasonalSurplusValuesTableEvent) => {
						if (event.type !== SET_VIEW) return context.view;
						return event.value;
					}
				}),
				setPlayerId: assign<TSeasonalSurplusValuesTableContext, TSeasonalSurplusValuesTableEvent>({
					playerId: (
						context: TSeasonalSurplusValuesTableContext,
						event: TSeasonalSurplusValuesTableEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data ?? undefined;
					}
				}),
				setBirthDate: assign<TSeasonalSurplusValuesTableContext, TSeasonalSurplusValuesTableEvent>({
					birthDate: (
						context: TSeasonalSurplusValuesTableContext,
						event: TSeasonalSurplusValuesTableEvent
					) => {
						if (event.type !== SET_BIRTH_DATE) return context.birthDate;
						return event.data ?? undefined;
					}
				}),
				setSeasonalSurplusValues: assign<TSeasonalSurplusValuesTableContext, TSeasonalSurplusValuesTableEvent>({
					seasonalSurplusValues: (
						context: TSeasonalSurplusValuesTableContext,
						event: TSeasonalSurplusValuesTableEvent
					) => {
						if (event.type !== SET_SEASONAL_SURPLUS_VALUES) return context.seasonalSurplusValues;
						return event.data;
					},
					cancelSource: (
						context: TSeasonalSurplusValuesTableContext,
						_event: TSeasonalSurplusValuesTableEvent
					) => {
						context.cancelSource?.cancel();
						return undefined;
					}
				}),
				refreshCancelSource: assign<TSeasonalSurplusValuesTableContext, TSeasonalSurplusValuesTableEvent>({
					cancelSource: (
						context: TSeasonalSurplusValuesTableContext,
						_event: TSeasonalSurplusValuesTableEvent
					) => {
						context.cancelSource?.cancel();
						return getCancelSource();
					}
				}),
				handleFetchSeasonalSurplusValuesSuccess: assign<
					TSeasonalSurplusValuesTableContext,
					TSeasonalSurplusValuesTableEvent
				>({
					seasonalSurplusValues: (
						context: TSeasonalSurplusValuesTableContext,
						event: TSeasonalSurplusValuesTableEvent
					) => {
						if (event.type !== FETCH_SEASONAL_SURPLUS_VALUES_DONE || event.data === undefined)
							return context.seasonalSurplusValues;
						if (!event.data.length) return null;

						// As of 5/25, a player will only have hitter OR pitcher seasonal SVs. Never both.
						const surplusValues = event.data[0];
						if (surplusValues.projectedHitterSurplusValues?.length)
							return surplusValues.projectedHitterSurplusValues;
						if (surplusValues.projectedPitcherSurplusValues?.length)
							return surplusValues.projectedPitcherSurplusValues;
						return null;
					},
					birthDate: (
						context: TSeasonalSurplusValuesTableContext,
						event: TSeasonalSurplusValuesTableEvent
					) => {
						if (event.type !== FETCH_SEASONAL_SURPLUS_VALUES_DONE || event.data === undefined)
							return context.birthDate;
						if (!event.data.length) return null;
						return event.data[0].birthDate;
					}
				}),
				handleFetchSeasonalSurplusValuesErrored: (
					context: TSeasonalSurplusValuesTableContext,
					_event: TSeasonalSurplusValuesTableEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Seasonal Surplus Values",
							description: "Error fetching seasonal surplus values data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchSeasonalSurplusValues: (context: TSeasonalSurplusValuesTableContext, _event: AnyEventObject) => {
					if (!context.playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchAllSurplusValues(
							{
								playerId: context.playerId,
								isUseCache: true
							},
							context.cancelSource?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default SeasonalSurplusValuesTableMachine;
