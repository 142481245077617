import React, { useEffect, useState, useMemo } from "react";

import { useFetch } from "utils/url_helpers";
import { StyledSelect } from "_react/shared/selects";
import {
	TLkPlayerPlanSplit,
	TLkPlayerPlanSplitType,
	TPitchGroupPitchType,
	TPlayerPlanMetricTypeAvailableSplitType
} from "_react/playerplan/shared/_types";

export type TSplitOption = {
	label: string;
	rawLabel: string;
	value: string;
	splitType: TLkPlayerPlanSplitType;
	pitchGroupPitchTypes: Array<TPitchGroupPitchType>;
};

type TSplitSelect = {
	handleSelect: Function;
	metricTypeLk?: string;
	excludeSplitTypes?: Array<string>;
	excludeSplitTypesBySplits?: Array<string>;
	placeholder?: string;
	value?: Array<string> | null;
	isClearable?: boolean;
	isMulti?: boolean;
	isMetricSplits?: boolean;
	isDrillSplits?: boolean;
};

function SplitSelect({
	handleSelect,
	metricTypeLk,
	excludeSplitTypes,
	excludeSplitTypesBySplits,
	placeholder = `Select split...`,
	value,
	isClearable = true,
	isMulti = false,
	isMetricSplits,
	isDrillSplits
}: TSplitSelect) {
	const { responseData: splitResponseData, isInProgress } = useFetch("/playerplan/split?isDeleted=false", []);
	const [splitDataSource, setSplitDataSource] = useState([]);

	useEffect(() => {
		if (splitResponseData) {
			setSplitDataSource(
				splitResponseData.map((split: TLkPlayerPlanSplit) => ({
					label: `${split.label}${
						split.pitchGroupPitchTypes.length
							? ` [${split.pitchGroupPitchTypes.map(x => x.pitchType).join(", ")}]`
							: ""
					} (${split.splitType.label})`,
					rawLabel: split.label,
					value: split.value,
					splitType: split.splitType
				}))
			);
		}
	}, [splitResponseData, setSplitDataSource]);

	const opts = useMemo(() => {
		if (splitDataSource.length && value) {
			const filteredDataSource = splitDataSource.filter((opt: TSplitOption) =>
				value ? value.includes(opt.value) : false
			);
			return filteredDataSource.length ? filteredDataSource : null;
		}
		return null;
	}, [splitDataSource, value]);

	const valueProps = value === undefined ? {} : opts ? { value: opts } : { value: null };

	const splitTypeFiltersBySplits = useMemo(() => {
		if (excludeSplitTypesBySplits?.length && splitResponseData) {
			const splitTypes = splitDataSource
				.filter((opt: TSplitOption) => excludeSplitTypesBySplits.includes(opt.value))
				.map((opt: TSplitOption) => opt.splitType?.value);
			return splitTypes.length ? splitTypes : null;
		}
		return null;
	}, [splitDataSource, splitResponseData, excludeSplitTypesBySplits]);

	return (
		<StyledSelect
			options={splitDataSource.filter((opt: TSplitOption) => {
				const isCorrectSplitType =
					(excludeSplitTypes === undefined || !excludeSplitTypes.includes(opt.splitType.value)) &&
					(metricTypeLk === undefined ||
						opt.splitType.splitTypeAvailableMetricTypes.some(
							(s: TPlayerPlanMetricTypeAvailableSplitType) => s.metricTypeLk === metricTypeLk
						));
				const isCorrectSplitTypeBySplit =
					splitTypeFiltersBySplits == null || !splitTypeFiltersBySplits.includes(opt.splitType.value);
				const isMetricSplit =
					isMetricSplits === undefined ||
					(isMetricSplits ? opt.splitType.isMetricSplit : !opt.splitType.isMetricSplit);
				const isDrillSplit =
					isDrillSplits === undefined ||
					(isDrillSplits ? opt.splitType.isDrillSplit : !opt.splitType.isDrillSplit);
				return isCorrectSplitType && isCorrectSplitTypeBySplit && (isMetricSplit || isDrillSplit);
			})}
			onChange={handleSelect}
			placeholder={placeholder}
			isClearable={isClearable}
			isLoading={isInProgress}
			isMulti={isMulti}
			{...valueProps}
			styles={{ container: { width: "100%" } }}
		/>
	);
}

export default SplitSelect;
