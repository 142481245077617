import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import { IPro2PredictionsSummary } from "_react/shared/data_models/pro2_predictions/_types";

export const PRO_2_PREDICTIONS_SUMMARY_BASIC_SCHEMA = "basic";
export type TPro2PredictionsSummaryGetQueryParams = {
	playerId?: number;
	bamId?: number;
	playerType?: string;
	validDate?: string;
	isCurrent?: boolean;
	mesaUploadDate?: string;
	mlbProb?: number;
	"mlbProb[lte]"?: number;
	"mlbProb[gte]"?: number;
	xwarControl?: number;
	"xwarControl[lte]"?: number;
	"xwarControl[gte]"?: number;
	warRunning?: number;
	"warRunning[lte]"?: number;
	"warRunning[gte]"?: number;
	xwarRemaining?: number;
	"xwarRemaining[lte]"?: number;
	"xwarRemaining[gte]"?: number;
	q75GivenMlb?: number;
	"q75GivenMlb[lte]"?: number;
	"q75GivenMlb[gte]"?: number;
	q95GivenMlb?: number;
	"q95GivenMlb[lte]"?: number;
	"q95GivenMlb[gte]"?: number;
	mlsCurrent?: number;
	"mlsCurrent[lte]"?: number;
	"mlsCurrent[gte]"?: number;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	schema?: typeof PRO_2_PREDICTIONS_SUMMARY_BASIC_SCHEMA;
};

export type TPro2PredictionsSimilarPlayersGetQueryParams = {
	playerId: number;
	playerType?: string;
	positionGroup?: string;
	projectedPositionGroup?: string;
	isSameOrg?: boolean;
	isPhillies?: boolean;
	isFortyMan?: boolean;
	isProspect?: boolean;
	numberSimilarPlayers?: number;
	isUseCache?: boolean;
	isBasicSchema?: boolean;
};

export const fetchProModelPredictions = (
	queryParams: TPro2PredictionsSummaryGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPro2PredictionsSummaryGetQueryParams, Array<IPro2PredictionsSummary>>(
		"/pro2_predictions_summary",
		queryParams,
		cancelToken
	);

export const fetchSimilarProModelPlayers = (
	queryParams: TPro2PredictionsSimilarPlayersGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPro2PredictionsSimilarPlayersGetQueryParams, Array<IPro2PredictionsSummary>>(
		"/pro2_predictions_summary/similar_player",
		queryParams,
		cancelToken
	);
