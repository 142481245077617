import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function FilterAlt(props: ChakraIconProps) {
	return (
		<Icon viewBox="0 -960 960 960" {...props}>
			<path
				fill="currentColor"
				d="M440-160q-17 0-28.5-11.5T400-200v-240L168-736q-15-20-4.5-42t36.5-22h560q26 0 36.5 22t-4.5 42L560-440v240q0 17-11.5 28.5T520-160h-80Zm40-308 198-252H282l198 252Zm0 0Z"
			/>
		</Icon>
	);
}
