import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import {
	fetchStatsPlayerRangeAmaByLevel,
	fetchStatsPlayerRangeAmaOpps,
	TStatsPlayerRangeAmaByLevelQueryParams,
	TStatsPlayerRangeAmaOppsQueryParams
} from "_react/shared/data_models/defensive_observed/_network";
import {
	IStatsPlayerRangeAmaByLevel,
	IStatsPlayerRangeAmaOpps
} from "_react/shared/data_models/defensive_observed/_types";
import { TAmaFieldingOaaTableData } from "_react/shared/ui/data/tables/AmaFieldingOaaTable/_types";

// Cancel Sources
const STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE = "statsPlayerRangeAmaByLevelCancelSource";
const STATS_PLAYER_RANGE_AMA_OPPS_CANCEL_SOURCE = "statsPlayerRangeAmaOppsCancelSource";

type TAmaFieldingOaaTableCancelSources = {
	[STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE]?: CancelTokenSource;
	[STATS_PLAYER_RANGE_AMA_OPPS_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TAmaFieldingOaaTableContext = {
	playerId?: number;
	shouldFetchData?: boolean;
	statsPlayerRangeAmaByLevel?: Array<IStatsPlayerRangeAmaByLevel> | null;
	statsPlayerRangeAmaOpps?: Array<IStatsPlayerRangeAmaOpps> | null;
	cancelSources: TAmaFieldingOaaTableCancelSources;
	toast?: CreateToastFnReturn;
};

interface IAmaFieldingOaaTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches ama fielding OAA data
				statsPlayerRangeAmaByLevel: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches ama fielding OAA Opps data
				statsPlayerRangeAmaOpps: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_STATS_PLAYER_RANGE_AMA_BY_LEVEL = "SET_STATS_PLAYER_RANGE_AMA_BY_LEVEL";
export const SET_STATS_PLAYER_RANGE_AMA_OPPS = "SET_STATS_PLAYER_RANGE_AMA_OPPS";
export const FETCHING_STATS_PLAYER_RANGE_AMA_BY_LEVEL = { initialized: { statsPlayerRangeAmaByLevel: "fetching" } };
export const FETCHING_STATS_PLAYER_RANGE_AMA_OPPS = { initialized: { statsPlayerRangeAmaOpps: "fetching" } };

const FETCH_STATS_PLAYER_RANGE_AMA_BY_LEVEL_DONE = "done.invoke.fetchingStatsPlayerRangeAmaByLevel:invocation[0]";
const FETCH_STATS_PLAYER_RANGE_AMA_OPPS_DONE = "done.invoke.fetchingStatsPlayerRangeAmaOpps:invocation[0]";

type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | null | undefined;
};
type TSetStatsPlayerRangeAmaByLevelEvent = {
	type: typeof SET_STATS_PLAYER_RANGE_AMA_BY_LEVEL;
	data: Array<IStatsPlayerRangeAmaByLevel> | null | undefined;
};
type TSetStatsPlayerRangeAmaOppsEvent = {
	type: typeof SET_STATS_PLAYER_RANGE_AMA_OPPS;
	data: Array<IStatsPlayerRangeAmaOpps> | null | undefined;
};
type TFetchStatsPlayerRangeAmaByLevelEvent = {
	type: typeof FETCH_STATS_PLAYER_RANGE_AMA_BY_LEVEL_DONE;
	data: Array<IStatsPlayerRangeAmaByLevel> | undefined;
};
type TFetchStatsPlayerRangeAmaOppsEvent = {
	type: typeof FETCH_STATS_PLAYER_RANGE_AMA_OPPS_DONE;
	data: Array<IStatsPlayerRangeAmaOpps> | undefined;
};

type TAmaFieldingOaaTableEvent =
	| TSetPlayerIdEvent
	| TSetStatsPlayerRangeAmaByLevelEvent
	| TSetStatsPlayerRangeAmaOppsEvent
	| TFetchStatsPlayerRangeAmaByLevelEvent
	| TFetchStatsPlayerRangeAmaOppsEvent;

export type TAmaFieldingOaaTableSend = Interpreter<
	TAmaFieldingOaaTableContext,
	IAmaFieldingOaaTableStateSchema,
	TAmaFieldingOaaTableEvent
>["send"];

const AmaFieldingOaaTableMachine = (
	playerIdProp?: number,
	shouldFetchData = true,
	data?: TAmaFieldingOaaTableData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TAmaFieldingOaaTableContext, IAmaFieldingOaaTableStateSchema, TAmaFieldingOaaTableEvent>(
		{
			id: "amaFieldingOaaTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				shouldFetchData: shouldFetchData,
				statsPlayerRangeAmaByLevel: data?.statsPlayerRangeAmaByLevel,
				statsPlayerRangeAmaOpps: data?.statsPlayerRangeAmaOpps,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: {
							actions: ["setPlayerId", "clearStatsPlayerRangeAmaByLevel"],
							cond: "shouldSetPlayerId"
						},
						[SET_STATS_PLAYER_RANGE_AMA_BY_LEVEL]: { actions: "setStatePlayerRangeAmaByLevel" },
						[SET_STATS_PLAYER_RANGE_AMA_OPPS]: { actions: "setStatsPlayerRangeAmaOpps" }
					},
					states: {
						statsPlayerRangeAmaByLevel: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingStatsPlayerRangeAmaByLevel",
												cond: "shouldFetchStatsPlayerRangeAmaByLevel"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingStatsPlayerRangeAmaByLevel",
									entry: ["refreshStatsPlayerRangeAmaByLevelCancelSource"],
									invoke: {
										src: "fetchStatsPlayerRangeAmaByLevel",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchStatsPlayerRangeAmaByLevelSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchStatsPlayerRangeAmaByLevelErrored"
										}
									}
								}
							}
						},
						statsPlayerRangeAmaOpps: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingStatsPlayerRangeAmaOpps",
												cond: "shouldFetchStatsPlayerRangeAmaOpps"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingStatsPlayerRangeAmaOpps",
									entry: ["refreshStatsPlayerRangeAmaOppsCancelSource"],
									invoke: {
										src: "fetchStatsPlayerRangeAmaOpps",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchStatsPlayerRangeAmaOppsSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchStatsPlayerRangeAmaOppsErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (context: TAmaFieldingOaaTableContext, event: TAmaFieldingOaaTableEvent) =>
					event.type === SET_PLAYER_ID && context.playerId !== event.data,
				shouldFetchStatsPlayerRangeAmaByLevel: (
					context: TAmaFieldingOaaTableContext,
					_event: TAmaFieldingOaaTableEvent
				) =>
					context.statsPlayerRangeAmaByLevel === undefined &&
					context.playerId !== undefined &&
					shouldFetchData,
				shouldFetchStatsPlayerRangeAmaOpps: (
					context: TAmaFieldingOaaTableContext,
					_event: TAmaFieldingOaaTableEvent
				) => context.statsPlayerRangeAmaOpps === undefined && shouldFetchData
			},
			actions: {
				clearStatsPlayerRangeAmaByLevel: assign<TAmaFieldingOaaTableContext, TAmaFieldingOaaTableEvent>({
					statsPlayerRangeAmaByLevel: (
						_context: TAmaFieldingOaaTableContext,
						_event: TAmaFieldingOaaTableEvent
					) => undefined,
					cancelSources: (context: TAmaFieldingOaaTableContext, _event: TAmaFieldingOaaTableEvent) => {
						if (context.cancelSources[STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE].cancel();
						delete context.cancelSources[STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TAmaFieldingOaaTableContext, TAmaFieldingOaaTableEvent>({
					playerId: (context: TAmaFieldingOaaTableContext, event: TAmaFieldingOaaTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data ?? undefined;
					}
				}),
				setStatePlayerRangeAmaByLevel: assign<TAmaFieldingOaaTableContext, TAmaFieldingOaaTableEvent>({
					statsPlayerRangeAmaByLevel: (
						context: TAmaFieldingOaaTableContext,
						event: TAmaFieldingOaaTableEvent
					) => {
						if (event.type !== SET_STATS_PLAYER_RANGE_AMA_BY_LEVEL)
							return context.statsPlayerRangeAmaByLevel;
						return event.data;
					},
					cancelSources: (context: TAmaFieldingOaaTableContext, event: TAmaFieldingOaaTableEvent) => {
						if (event.type !== SET_STATS_PLAYER_RANGE_AMA_BY_LEVEL) return context.cancelSources;
						if (context.cancelSources[STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE].cancel();
						delete context.cancelSources[STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setStatsPlayerRangeAmaOpps: assign<TAmaFieldingOaaTableContext, TAmaFieldingOaaTableEvent>({
					statsPlayerRangeAmaOpps: (
						context: TAmaFieldingOaaTableContext,
						event: TAmaFieldingOaaTableEvent
					) => {
						if (event.type !== SET_STATS_PLAYER_RANGE_AMA_OPPS) return context.statsPlayerRangeAmaOpps;
						return event.data;
					},
					cancelSources: (context: TAmaFieldingOaaTableContext, event: TAmaFieldingOaaTableEvent) => {
						if (event.type !== SET_STATS_PLAYER_RANGE_AMA_OPPS) return context.cancelSources;
						if (context.cancelSources[STATS_PLAYER_RANGE_AMA_OPPS_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_RANGE_AMA_OPPS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[STATS_PLAYER_RANGE_AMA_OPPS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				// Cancel Source Actions
				refreshStatsPlayerRangeAmaByLevelCancelSource: assign<
					TAmaFieldingOaaTableContext,
					TAmaFieldingOaaTableEvent
				>({
					cancelSources: (context: TAmaFieldingOaaTableContext, _event: TAmaFieldingOaaTableEvent) => {
						if (context.cancelSources[STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE].cancel();
						context.cancelSources[STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshStatsPlayerRangeAmaOppsCancelSource: assign<
					TAmaFieldingOaaTableContext,
					TAmaFieldingOaaTableEvent
				>({
					cancelSources: (context: TAmaFieldingOaaTableContext, _event: TAmaFieldingOaaTableEvent) => {
						if (context.cancelSources[STATS_PLAYER_RANGE_AMA_OPPS_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_RANGE_AMA_OPPS_CANCEL_SOURCE].cancel();
						context.cancelSources[STATS_PLAYER_RANGE_AMA_OPPS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchStatsPlayerRangeAmaByLevelSuccess: assign<
					TAmaFieldingOaaTableContext,
					TAmaFieldingOaaTableEvent
				>({
					statsPlayerRangeAmaByLevel: (
						context: TAmaFieldingOaaTableContext,
						event: TAmaFieldingOaaTableEvent
					) => {
						if (event.type !== FETCH_STATS_PLAYER_RANGE_AMA_BY_LEVEL_DONE)
							return context.statsPlayerRangeAmaByLevel;
						if (!event.data?.length) return null;
						return event.data;
					}
				}),
				handleFetchStatsPlayerRangeAmaOppsSuccess: assign<
					TAmaFieldingOaaTableContext,
					TAmaFieldingOaaTableEvent
				>({
					statsPlayerRangeAmaOpps: (
						context: TAmaFieldingOaaTableContext,
						event: TAmaFieldingOaaTableEvent
					) => {
						if (event.type !== FETCH_STATS_PLAYER_RANGE_AMA_OPPS_DONE)
							return context.statsPlayerRangeAmaOpps;
						return event.data ?? null;
					}
				}),
				// Fetch Errored Actions
				handleFetchStatsPlayerRangeAmaByLevelErrored: (
					context: TAmaFieldingOaaTableContext,
					_event: TAmaFieldingOaaTableEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Fielding OAA",
							description: "Error fetching fielding OAA data."
						});
				},
				handleFetchStatsPlayerRangeAmaOppsErrored: (
					context: TAmaFieldingOaaTableContext,
					_event: TAmaFieldingOaaTableEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Fielding OAA Opportunities",
							description: "Error fetching fielding OAA opportunities data."
						});
				}
			},
			services: {
				fetchStatsPlayerRangeAmaByLevel: (context: TAmaFieldingOaaTableContext, _event: AnyEventObject) => {
					if (!context.playerId) return Promise.resolve(null);
					const queryParams: TStatsPlayerRangeAmaByLevelQueryParams = {
						playerId: context.playerId,
						isUseCache: true
					};
					const fetchFunc = () =>
						fetchStatsPlayerRangeAmaByLevel(
							queryParams,
							context.cancelSources[STATS_PLAYER_RANGE_AMA_BY_LEVEL_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchStatsPlayerRangeAmaOpps: (context: TAmaFieldingOaaTableContext, _event: AnyEventObject) => {
					const queryParams: TStatsPlayerRangeAmaOppsQueryParams = { isUseCache: true };
					const fetchFunc = () =>
						fetchStatsPlayerRangeAmaOpps(
							queryParams,
							context.cancelSources[STATS_PLAYER_RANGE_AMA_OPPS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default AmaFieldingOaaTableMachine;
