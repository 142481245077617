import React, { CSSProperties } from "react";

import { SummarySection, EvalAttr } from "_react/evals/list/_styles";

export type TEvalSnapshotSummaryProps = {
	snapshot?: string;
	summary?: string;
	isShowSummaries?: boolean;
	style?: CSSProperties;
};

export const EvalSnapshotSummary = ({
	snapshot,
	summary,
	isShowSummaries = true,
	style
}: TEvalSnapshotSummaryProps) => {
	return (
		<SummarySection style={style}>
			<EvalAttr>
				<b>Snapshot:</b> {snapshot}
				{isShowSummaries && (
					<>
						<br />
						<b>Summary:</b> {summary}
					</>
				)}
			</EvalAttr>
		</SummarySection>
	);
};
