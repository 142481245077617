import React from "react";
import { IconSVG, IconProps, FILL } from "_react/shared/legacy/ui/icons";

export default function ArrowDropUp({ fill = FILL, ...props }: IconProps) {
	return (
		<IconSVG {...props}>
			<path fill={fill} d="M7 14l5-5 5 5z" />
			<path fill={"none"} d="M0 0h24v24H0z" />
		</IconSVG>
	);
}
