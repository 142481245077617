import React, { useMemo, useContext } from "react";
import {
	useBoolean,
	Button,
	Flex,
	Text,
	IconButton,
	Tooltip,
	Link,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	HStack,
	CircularProgress
} from "@chakra-ui/react";
import { useSelector } from "@xstate/react";

import { useDraftDate } from "_react/_hooks";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import {
	EVENT_TYPE_SELECT_CONTENT,
	CONTENT_TYPE_PLAYER_PAGES_TAB,
	CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION
} from "_react/shared/_constants/google_analytics";
import { sendReactGAEvent } from "_react/shared/_helpers/google_analytics";
import EntityPage from "_react/shared/ui/presentation/layouts/EntityPage/EntityPage";
import { TTab } from "_react/shared/ui/presentation/layouts/TabsWithSidebar/_types";
import { useIsGroupMember } from "_react/app/AppContents";
import { getAgeFromBirthDate } from "utils/helpers";
import { formatHeight } from "utils/functions";
import { getTeamName } from "_react/shared/_helpers/team";
import MergeType from "_react/shared/ui/icons/MergeType";
import ExpandMore from "_react/shared/ui/icons/ExpandMore";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";
import {
	VIEW_PROJECTIONS,
	VIEW_OBSERVED,
	VIEW_STATISTICS,
	VIEW_SCOUTING_REPORTS,
	VIEW_SCHEDULE,
	VIEW_BIO,
	VIEW_VIDEO,
	VIEW_NOTES
} from "_react/shared/_constants/query_strings";
import MyListsMenu from "_react/shared/ui/data/other/MyListsMenu/MyListsMenu";

import {
	PRO,
	AMA,
	INTL,
	ZERO,
	ONE,
	NULL_FILLER_TEXT_HEADER,
	NULL_FILLER_TEXT_TEAM_NAME
} from "_react/playerpage/_constants";
import { getPlayerFullName, getAmaPosition } from "_react/shared/data_models/player/_helpers";
import { TPlayerPageCombinedPlayer, TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import { MERGE_PLAYER_ADMIN_IAM_GROUP, getMostRecentDraftPick } from "_react/playerpage/_helpers";
import PlayerHeadshot from "_react/shared/ui/presentation/components/PlayerHeadshot/PlayerHeadshot";
import MergePlayersModal from "_react/playerpage/shared/header/MergePlayersModal";
import CreateReportMenu from "_react/playerpage/shared/header/CreateReportMenu";
import EditBioButton from "_react/playerpage/shared/header/EditBioButton";
// TODO: Fill this out
import PlayerPageAmaProfile from "_react/playerpage/ama/profile/PlayerPageAmaProfile";
import ProjectionsTab from "_react/playerpage/ama/content/projections/ProjectionsTab";
import ObservedTab from "_react/playerpage/ama/content/observed/ObservedTab";
import StatisticsTab from "_react/playerpage/ama/content/statistics/StatisticsTab";
import ScoutingReportsTab from "_react/playerpage/shared/content/scouting_reports/ScoutingReportsTab";
import ScheduleTab from "_react/playerpage/ama/content/schedule/ScheduleTab";
import BioTab from "_react/playerpage/ama/content/bio/BioTab";
import VideoTab from "_react/playerpage/ama/content/video/VideoTab";
import NotesTab from "_react/playerpage/shared/content/notes/NotesTab";
import { TAmaBioDocument } from "_react/playerpage/bio/_types";
import { PlayerPageAmaContext } from "_react/playerpage/ama/PlayerPageAmaProvider";
import { getIsTwoWayPlayer } from "_react/playerpage/ama/shared/_helpers";
import { IAmaProspectValue } from "_react/shared/data_models/phred/_types";
import { TPlayerPageAmaState } from "_react/playerpage/ama/_machine";
import { getDraftPickSchool } from "_react/playerpage/pro/shared/_helpers";

type PlayerPageAmaContentProps = {
	player?: TPlayerPageCombinedPlayer | null;
	evals?: $TSFixMeEval[] | null;
	setViewClassification?: (viewClassification?: TPlayerPagePlayerClassification) => void;
	viewClassification: string | null;
	setView?: (key: string, isReplaceState?: boolean) => void;
	view?: string;
	setIsBeta?: (isBeta: string | null, isReplaceState?: boolean) => void;
	hasProjectionsPermissions?: boolean;
	hasAmaBioEditPermissions?: boolean;
	bioDisplayOnly: boolean;
	amaBioDocument: TAmaBioDocument | null;
	setAmaBioDocument: (newDocument: TAmaBioDocument) => void;
	setBioDisplayOnly: (bioDisplayOnly: boolean) => void;
	isBioSaving: boolean;
	submitAmaBioUpdate: () => Promise<void>;
};

const PlayerPageAmaContent = ({
	player,
	evals,
	setViewClassification,
	setView,
	view,
	setIsBeta,
	hasProjectionsPermissions = false,
	hasAmaBioEditPermissions = false,
	bioDisplayOnly,
	amaBioDocument,
	setAmaBioDocument,
	setBioDisplayOnly,
	isBioSaving,
	submitAmaBioUpdate
}: PlayerPageAmaContentProps) => {
	const [isMergeModalOpen, setIsMergeModalOpen] = useBoolean(false);
	const canMergePlayers = useIsGroupMember(MERGE_PLAYER_ADMIN_IAM_GROUP);
	const draftDate = useDraftDate(player?.amaProfile?.eligibleYear ?? undefined);
	//
	// Header Content
	//

	// Player's Content

	const playerFullName = getPlayerFullName(player, false, AMA);
	const playerHeadshot = (
		<PlayerHeadshot playerClassification={AMA} dplId={player?.dplId} alt={playerFullName} style={{ zIndex: "1" }} />
	);

	const birthDate = player?.amaProfile?.birthDate ?? player?.birthDate;
	const height = player?.amaProfile?.height ?? player?.height;
	const weight = player?.amaProfile?.weight ?? player?.weight;
	const playerAttributesText = (
		<>
			<HStack>
				{birthDate ? (
					<>
						<Text fontSize="xs" fontWeight="bold">
							Draft Age
						</Text>
						{draftDate ? (
							<Text>{getAgeFromBirthDate(birthDate, draftDate)}</Text>
						) : (
							<CircularProgress isIndeterminate color="grey" size="14px" />
						)}
					</>
				) : (
					NULL_FILLER_TEXT_HEADER
				)}
			</HStack>
			<Text>|</Text>
			<Text>{height ? formatHeight(height) : NULL_FILLER_TEXT_HEADER}</Text>
			<Text>|</Text>
			<Text>{weight ? `${weight}lb` : NULL_FILLER_TEXT_HEADER}</Text>
			<Text>|</Text>
			<Text>{player?.amaProfile?.eligibleYear ?? NULL_FILLER_TEXT_HEADER}</Text>
		</>
	);

	// Player's Team Content

	const isProPlayer = player?.playerClassification === "PRO";
	const team = player?.amaProfile?.team ?? player?.team;
	const draftPick = isProPlayer ? getMostRecentDraftPick(player) : undefined;
	const teamId = isProPlayer
		? draftPick?.team.id
		: player?.amaProfile?.team?.id ?? player?.amaProfile?.currentTeamId ?? player?.team?.id;
	const teamName = (
		<Link fontWeight="bold" fontSize="lg" textAlign="center" href={`/team?rockyId=${teamId}`} isExternal>
			{isProPlayer ? getDraftPickSchool(draftPick) : getTeamName(team, NULL_FILLER_TEXT_TEAM_NAME)}
		</Link>
	);
	const teamDetails = (
		<>
			{isProPlayer ? (
				<TeamLevelBadge level={draftPick?.team.level} nullFillerText={NULL_FILLER_TEXT_HEADER} />
			) : (
				getTeamName(team) !== "" &&
				team?.level != null && <TeamLevelBadge level={team?.level} nullFillerText={NULL_FILLER_TEXT_HEADER} />
			)}
			<Text>|</Text>
			<Text>{getAmaPosition(player) ?? NULL_FILLER_TEXT_HEADER}</Text>
			<Text>|</Text>
			<Text>
				{player?.amaProfile?.bats ?? player?.bats ?? NULL_FILLER_TEXT_HEADER}/
				{player?.amaProfile?.throws ?? player?.throws ?? NULL_FILLER_TEXT_HEADER}
			</Text>
		</>
	);

	// Two-Way Check
	const playerPageAmaContext = useContext(PlayerPageAmaContext);
	const amaProspectValues: Array<IAmaProspectValue> | undefined = useSelector(
		playerPageAmaContext.playerPageAmaService,
		(state: TPlayerPageAmaState) => state.context.amaProspectValues
	);
	const isTwoWayPlayer = useMemo(() => player != null && getIsTwoWayPlayer(amaProspectValues, player, evals), [
		amaProspectValues,
		player,
		evals
	]);

	//
	// Tab and Sidebar Content
	//

	const amaTabs: Array<TTab> = useMemo(() => {
		// Tabs for which all users have permissions
		const newAmaTabs: Array<TTab> = [
			{
				label: "Observed",
				content: player ? <ObservedTab player={player} isTwoWayPlayer={isTwoWayPlayer} /> : <></>,
				queryStringValue: VIEW_OBSERVED
			},
			{
				label: "Statistics",
				content: player ? <StatisticsTab player={player} /> : <></>,
				queryStringValue: VIEW_STATISTICS
			},
			{
				label: "Scouting Reports",
				content: <ScoutingReportsTab evals={evals} playerId={player?.id} />,
				queryStringValue: VIEW_SCOUTING_REPORTS
			},
			{
				label: "Schedule",
				content: player ? <ScheduleTab player={player} /> : <></>,
				queryStringValue: VIEW_SCHEDULE
			},
			{
				label: "Bio",
				content: (
					<BioTab
						bioDisplayOnly={bioDisplayOnly}
						amaBioDocument={amaBioDocument}
						setAmaBioDocument={setAmaBioDocument}
					/>
				),
				queryStringValue: VIEW_BIO
			},
			{
				label: "Notes",
				content: player ? <NotesTab player={player} /> : <></>,
				queryStringValue: VIEW_NOTES
			},
			{
				label: "Video",
				content: player ? <VideoTab player={player} /> : <></>,
				queryStringValue: VIEW_VIDEO
			}
		];

		// Add Projections tab if user has permissions
		if (hasProjectionsPermissions) {
			newAmaTabs.splice(0, 0, {
				label: "Projections",
				content: player ? <ProjectionsTab player={player} /> : <></>,
				queryStringValue: VIEW_PROJECTIONS
			});
		}

		return newAmaTabs;
	}, [player, evals, hasProjectionsPermissions, bioDisplayOnly, amaBioDocument, setAmaBioDocument, isTwoWayPlayer]);

	const amaTabsHeaderChildren = useMemo(
		() => (
			<Flex
				sx={{
					justifyContent: "center",
					flexDirection: "row",
					paddingRight: {
						base: undefined,
						md: "12"
					},
					paddingBottom: "1",
					paddingTop: "6",
					gap: "3"
				}}
			>
				<MyListsMenu playerId={player?.id} />
				<Menu>
					<MenuButton
						as={Button}
						rightIcon={<ExpandMore />}
						h="8"
						bg="transparent"
						color="gray.700"
						borderColor="gray.700"
						border="1px solid"
						fontSize="sm"
						fontWeight="semibold"
						paddingInlineStart="3"
						paddingInlineEnd="3"
					>
						AMA (NEW)
					</MenuButton>
					<MenuList>
						<MenuItem
							onClick={() => {
								setViewClassification ? setViewClassification(AMA) : null;
								setIsBeta ? setIsBeta(ZERO) : null;
								sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
									contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
									contentId: AMA
								});
							}}
						>
							{`AMA (OLD)`}
						</MenuItem>
						{player?.playerProId && (
							<>
								<MenuItem
									onClick={() => {
										setViewClassification ? setViewClassification(PRO) : null;
										setIsBeta ? setIsBeta(ONE) : null;
										sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
											contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
											contentId: `${PRO}-new`
										});
									}}
								>
									PRO (NEW)
								</MenuItem>
								<MenuItem
									onClick={() => {
										setIsBeta ? setIsBeta(ZERO) : null;
										sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
											contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
											contentId: PRO
										});
									}}
								>
									PRO (OLD)
								</MenuItem>
							</>
						)}
						{player?.playerIntlId && (
							<MenuItem
								onClick={() => {
									setViewClassification ? setViewClassification(INTL) : null;
									sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
										contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
										contentId: INTL
									});
								}}
							>
								INTL
							</MenuItem>
						)}
					</MenuList>
				</Menu>
			</Flex>
		),
		[player?.id, player?.playerIntlId, player?.playerProId, setIsBeta, setViewClassification]
	);

	const activeTabIndex = useMemo(() => {
		const activeIndex = amaTabs.findIndex((tab: TTab) => view && tab.queryStringValue === view);
		if (activeIndex === -1) return undefined;
		return activeIndex;
	}, [amaTabs, view]);

	const handleSetTabIndex = (index: number) => {
		if (setView) setView(amaTabs[index].queryStringValue ?? "");
		sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
			contentType: CONTENT_TYPE_PLAYER_PAGES_TAB,
			contentId: amaTabs[index].queryStringValue
		});
	};

	// Header actions

	const bioTabIndex = useMemo(() => {
		const bioTabIndex = amaTabs.findIndex((tab: TTab) => view && tab.queryStringValue === VIEW_BIO);
		if (bioTabIndex === -1) return undefined;
		return bioTabIndex;
	}, [amaTabs, view]);

	const redirectToBioTab = () => {
		if (view !== VIEW_BIO && bioTabIndex) handleSetTabIndex(bioTabIndex);
	};

	const headerActions = (
		<>
			{canMergePlayers && (
				<Tooltip hasArrow placement="top" label="Merge Players">
					<IconButton
						colorScheme="gray"
						aria-label="Merge Players"
						borderRadius="full"
						marginRight="3"
						icon={<MergeType boxSize={5} />}
						onClick={() => setIsMergeModalOpen.on()}
						size="sm"
					/>
				</Tooltip>
			)}
			{player && hasAmaBioEditPermissions && (
				<EditBioButton
					redirectToBioTab={redirectToBioTab}
					setBioDisplayOnly={setBioDisplayOnly}
					isBioSaving={isBioSaving}
					submitAmaBioUpdate={submitAmaBioUpdate}
					bioDisplayOnly={bioDisplayOnly}
				/>
			)}
			{player && <CreateReportMenu player={player} />}
		</>
	);

	//
	// Custom styles for layout
	//

	// If you change the height here, make sure to update `tabsWithSidebarStyle` below
	const entityHeaderStyle = useBreakpointValue({
		md: { container: { height: "24" } },
		lg: {}
	});

	// Manually account for header height for vertical scrolling and footer
	// Not an optimal way to do this...
	const tabsWithSidebarStyle = useBreakpointValue({
		base: { tabs: { height: "calc(100% - 91px)" } },
		lg: { tabs: { height: "calc(100% - 64px)" } }
	});

	return (
		<>
			<EntityPage
				entityHeaderProps={{
					image: playerHeadshot,
					startContent: {
						primaryContent: playerFullName,
						secondaryContent: playerAttributesText
					},
					middleContent: {
						primaryContent: teamName,
						secondaryContent: teamDetails
					},
					endContent: headerActions,
					style: entityHeaderStyle
				}}
				tabsWithSidebarProps={{
					tabsHeaderChildren: amaTabsHeaderChildren,
					tabs: amaTabs,
					tabIndex: activeTabIndex,
					handleSetTabIndex: handleSetTabIndex,
					sidebarChildren: player ? (
						<PlayerPageAmaProfile
							player={player}
							evals={evals}
							hasProjectionsPermissions={hasProjectionsPermissions}
						/>
					) : (
						undefined
					),
					style: tabsWithSidebarStyle
				}}
				isLoading={player == null}
			/>
			{player && <MergePlayersModal player={player} isOpen={isMergeModalOpen} setIsOpen={setIsMergeModalOpen} />}
		</>
	);
};

export default PlayerPageAmaContent;
