/*
    Fetch Season Stats
 */
import axios from "_redux/_utils/_axios";
import { genericErrorAction } from "_redux/Error/error.a";
import {
	LOAD_SEASON_STATS,
	LOAD_SEASON_STATS_FAILURE,
	LOAD_CAREER_STATS,
	LOAD_CAREER_STATS_FAILURE,
	LOAD_TEAM_STATS,
	LOAD_TEAM_STATS_FAILURE,
	LOAD_PROJECTION_STATS,
	FETCH_TEAM_STATS,
	FETCH_SEASON_STATS,
	FETCH_CAREER_STATS,
	FETCH_PROJECTION_STATS,
	LOAD_PROJECTION_STATS_FAILURE,
	FETCH_AVERAGE_AGES
} from "_redux/StatsAggregated/_betaTypes";
import {
	selectPlayerSeasonStats,
	selectPlayerCareerStats,
	selectPlayerSeasonStatsByAttributes,
	selectPlayerCareerStatsByAttriubtes,
	selectTeamSeasonStats,
	selectBrewedPlayerSeasonStats,
	selectBrewedPlayerCareerStats,
	selectPlayerProjectionStats,
	selectAverageAges
} from "_redux/StatsAggregated/_selectors";
import {
	BATTER,
	PITCHER,
	PLAYER_TYPES,
	FIELDER,
	getBatterAggregateRow,
	getPitcherAggregateRow,
	sortByLevelIncludingTotalRows,
	MAJORS,
	MINORS,
	OTHER,
	OTHER_LEVELS,
	getFielderAggregateRow,
	formatAverageAges
} from "_redux/StatsAggregated/_helpers";
import { selectProTeams } from "_redux/Team/_selectors";
import { getYearRange } from "_react/stats/shared/helpers";

export const loadSeasonStats = (bamId, playerType, stats) => ({
	type: LOAD_SEASON_STATS,
	payload: {
		bamId,
		playerType,
		stats
	}
});

export const loadCareerStats = (bamId, playerType, stats) => ({
	type: LOAD_CAREER_STATS,
	payload: {
		bamId,
		playerType,
		stats
	}
});

export const _fetchSeasonStats = (bamId, playerType) => ({
	type: FETCH_SEASON_STATS,
	payload: {
		bamId,
		playerType
	}
});

export const _fetchCareerStats = (bamId, playerType) => ({
	type: FETCH_CAREER_STATS,
	payload: {
		bamId,
		playerType
	}
});

export const loadTeamStats = (bamId, playerType, season, stats) => ({
	type: LOAD_TEAM_STATS,
	payload: {
		bamId,
		playerType,
		season,
		stats
	}
});

export const _fetchProjectionStats = (bamId, playerType) => ({
	type: FETCH_PROJECTION_STATS,
	payload: {
		bamId,
		playerType
	}
});

export const loadProjectionStats = (bamId, playerType, stats) => ({
	type: LOAD_PROJECTION_STATS,
	payload: {
		bamId,
		playerType,
		stats
	}
});

export const _fetchTeamStats = (bamId, playerType, season) => ({
	type: FETCH_TEAM_STATS,
	payload: {
		bamId,
		playerType,
		season
	}
});

export const _fetchAverageAges = averageAgesArray => ({
	type: FETCH_AVERAGE_AGES,
	payload: formatAverageAges(averageAgesArray)
});

export const fetchAverageAges = () => {
	return async (dispatch, getState) => {
		const averageAges = selectAverageAges(getState());
		if (averageAges) return Promise.resolve(selectAverageAges);
		try {
			const response = await axios.get(`/stats/season/average_age?since=1900`);
			dispatch(_fetchAverageAges(response.data));
			return Promise.resolve(formatAverageAges(response.data));
		} catch (err) {
			console.log({ err });
		}
	};
};

export const fetchCareerStats = (bamId, playerType, forceRefresh = false) => {
	return async (dispatch, getState) => {
		const playerCareerStats = selectPlayerCareerStats(getState(), bamId, playerType);
		if (playerCareerStats && !forceRefresh) {
			return Promise.resolve(playerCareerStats);
		} else {
			dispatch(_fetchCareerStats(bamId, playerType));
			try {
				const response = await axios.get(`/stats/bq/career/${playerType}/${bamId}`);
				dispatch(loadCareerStats(bamId, playerType, response.data));
				return Promise.resolve(response.data);
			} catch (err) {
				dispatch(genericErrorAction(LOAD_CAREER_STATS_FAILURE, err));
			}
		}
	};
};

export const fetchSeasonStats = (bamId, playerType, forceRefresh = false) => {
	return async (dispatch, getState) => {
		const playerSeasonStats = selectPlayerSeasonStats(getState(), bamId, playerType);
		if (playerSeasonStats && !forceRefresh) {
			return Promise.resolve(playerSeasonStats);
		} else {
			dispatch(_fetchSeasonStats(bamId, playerType));
			try {
				const response = await axios.get(`/stats/bq/season/${playerType}/${bamId}`);
				dispatch(loadSeasonStats(bamId, playerType, response.data));
				return Promise.resolve(response.data);
			} catch (err) {
				dispatch(genericErrorAction(LOAD_SEASON_STATS_FAILURE, err));
			}
		}
	};
};

export const fetchTeamStats = (bamId, playerType, season, forceRefresh = false) => {
	return async (dispatch, getState) => {
		const teamStats = selectTeamSeasonStats(getState(), bamId, playerType, season);

		if (teamStats && !forceRefresh) return Promise.resolve(teamStats);

		dispatch(_fetchTeamStats(bamId, playerType, season));
		try {
			const response = await axios.get(`/stats/team/${bamId}/${playerType}/season/${season}`);
			dispatch(loadTeamStats(bamId, playerType, season, response.data));
			return Promise.resolve(response.data);
		} catch (err) {
			dispatch(genericErrorAction(LOAD_TEAM_STATS_FAILURE, err));
		}
	};
};

export const fetchSeasonStatsAll = (bamId, forceRefresh = false, playerTypes = PLAYER_TYPES) => {
	return async dispatch => {
		for (let i = 0; i < playerTypes.length; i++) {
			dispatch(fetchSeasonStats(bamId, playerTypes[i], forceRefresh));
		}
	};
};
export const fetchCareerStatsAll = (bamId, forceRefresh = false, playerTypes = PLAYER_TYPES) => {
	return async dispatch => {
		for (let i = 0; i < playerTypes.length; i++) {
			dispatch(fetchCareerStats(bamId, playerTypes[i], forceRefresh));
		}
	};
};
export const fetchTeamStatsAll = (bamId, season, forceRefresh = false, playerTypes = PLAYER_TYPES) => {
	return async dispatch => {
		for (let i = 0; i < playerTypes.length; i++) {
			dispatch(fetchTeamStats(bamId, playerTypes[i], season, forceRefresh));
		}
	};
};

export const fetchProjectionStats = (bamId, playerType) => {
	return async (dispatch, getState) => {
		const playerProjectionStats = selectPlayerProjectionStats(getState(), bamId, playerType);
		if (playerProjectionStats) {
			return Promise.resolve(playerProjectionStats);
		} else {
			dispatch(_fetchProjectionStats(bamId, playerType));
			try {
				const response = await axios.get(`/stats/projections/${playerType}/${bamId}`);
				dispatch(loadProjectionStats(bamId, playerType, response.data));
				return Promise.resolve(response.data);
			} catch (err) {
				dispatch(genericErrorAction(LOAD_PROJECTION_STATS_FAILURE, err));
			}
		}
	};
};

/*
    Helpers to get table data
 */
export const getLevelString = v => {
	const levels = {};
	let winter = false;
	for (let i = 0; i < v.length; i++) {
		if (!v[i].is_team) continue;
		if (v[i].level === "WIN") {
			winter = true;
			continue;
		}
		if (!levels.hasOwnProperty(v[i].level)) levels[v[i].level] = { level: v[i].level, ab: 0 };
		levels[v[i].level].ab += v[i].ab;
	}

	let levelsUsed = Object.values(levels)
		.sort((a, b) => b.ab - a.ab)
		.map(e => e.level);
	if (winter) levelsUsed.push("WIN");
	let extra = "";
	if (levelsUsed.length > 3) {
		extra = ` (+${levelsUsed.length - 3})`;
		levelsUsed = levelsUsed.slice(0, 3);
	}
	return `${levelsUsed.join(" / ")}${extra}`;
};

export const getPositionString = v => {
	const positions = v.reduce((e, v) => {
		const { position, is_team, chances } = v;
		if (is_team && e.hasOwnProperty(position)) {
			e[position] += chances;
		} else if (is_team && !e.hasOwnProperty(position)) {
			e[position] = chances;
		}
		return e;
	}, {});
	const sorted = Object.keys(positions)
		.map(e => {
			return { position: e, tc: positions[e] };
		})
		.sort((a, b) => a["tc"] - b["tc"])
		.reverse();

	const positionArray = [];
	for (const [i, entry] of sorted.entries()) {
		if (i < 3) positionArray.push(entry["position"]);
		else break;
	}
	const positionString = positionArray.join(" / ");

	return sorted.length > 3 ? `${positionString} (+${sorted.length - 3})` : positionString;
};

export const getSeasonRowsForTable = (data, aggregateFunc, additionalMetaData = null) => {
	for (const [k, vRaw] of Object.entries(data)) {
		const v = [...vRaw].filter(e => e.is_team);
		let seasonTotal = aggregateFunc(v);
		if (seasonTotal) {
			// TODO: Think about this logic some more should be easier
			if (v.length > 1) {
				const numTeams = new Set(v.filter(e => e.is_team).map(e => e.team_id)).size;
				seasonTotal["level"] = getLevelString(v);
				seasonTotal["isMultiLevel"] = true;
				seasonTotal["season"] = k;
				seasonTotal["fullSeasonRow"] = true;
				seasonTotal["isNestedRow"] = false;
				seasonTotal["expandable"] = true;
				seasonTotal["team"] = `${numTeams} Teams`;
				seasonTotal["isMultiTeam"] = numTeams > 1;
				if (aggregateFunc === getFielderAggregateRow) {
					// Add in position
					seasonTotal["position"] = getPositionString(v);
				}
			} else {
				seasonTotal = {
					...v[0],
					fullSeasonRow: true,
					isMultiLevel: false,
					isNestedRow: false
				};
			}

			if (additionalMetaData) {
				for (const [k, v] of Object.entries(additionalMetaData)) {
					seasonTotal[k] = v;
				}
			}

			vRaw.unshift(seasonTotal);
		}
	}

	return data;
};

export const getAggregateTotalStatsFromSeasonData = (data, aggregateFunc) => {
	try {
		const totalData = aggregateFunc(data);
		const yearRange = data.map(entry => entry.season.toString());
		let minYear = Math.min(...yearRange);
		let maxYear = Math.max(...yearRange);
		minYear = isFinite(minYear) ? minYear : "-";
		maxYear = isFinite(maxYear) ? maxYear : "-";
		const yearRangeString = minYear === maxYear ? minYear : `${minYear}-${maxYear}`;
		totalData["season"] = `Total (${yearRangeString})`;

		return totalData;
	} catch (err) {
		return {};
	}
};

const aggregateRowFromPlayerType = playerType => {
	if (playerType === BATTER) return getBatterAggregateRow;
	else if (playerType === PITCHER) return getPitcherAggregateRow;
	else if (playerType === FIELDER) return getFielderAggregateRow;
	return null;
};

// TODO: Check everywhere this function was used and make sure changing the summary parameter from boolean doesn't break it
export const getSeasonTableData = (
	player,
	playerType,
	gameType,
	source,
	level,
	summary = null,
	otherLeagues = false
) => {
	return (dispatch, getState) => {
		try {
			const { bam_id, birth_date: birthDate } = player;

			const attributes = { game_type: [gameType], source: [source], level };
			if (summary) attributes["season"] = getYearRange(summary.begin, summary.end);

			let seasonStats = [];
			let seasonsRange = [];
			if (source.indexOf("brew") !== -1) {
				// Brew is a mixture of either statsapi.mlb.com or gumbo data so the source needs to change
				attributes.source = ["statsapi.mlb.com", "gumbo"];
				({ seasonStats, seasonsRange } = selectBrewedPlayerSeasonStats(
					getState(),
					bam_id,
					playerType,
					attributes
				));
			} else {
				({ seasonStats, seasonsRange } = selectPlayerSeasonStatsByAttributes(
					getState(),
					bam_id,
					playerType,
					attributes
				));
			}
			const aggregateFunc = aggregateRowFromPlayerType(playerType);

			let seasonData = seasonStats;
			if (!otherLeagues) {
				seasonData = seasonData.filter(row => !OTHER_LEVELS.includes(row.level));
			}
			seasonData = seasonData.reduce((e, v) => {
				const { season, team_id, team_bam } = v;
				v["fullSeasonRow"] = false;
				v["isNestedRow"] = true;
				v["birthDate"] = birthDate;
				const teams = selectProTeams(getState(), season);
				// TODO: Display of data is too closely tied to team
				if (team_bam) {
					const { name } = team_bam;
					v["team"] = name;
					v["is_team"] = true;
				} else if (team_id in teams) {
					v["team"] = teams[team_id].name;
					v["is_team"] = true;
				} else {
					v["is_team"] = false;
					v["team"] = `All ${v["level"]}`;
					v["isLevelTotalRow"] = true;
				}
				if (e.hasOwnProperty(season)) {
					e[season].push(v);
				} else {
					e[season] = [v];
				}
				return e;
			}, {});

			// Calculate Totals where applicable before adding in season totals
			const totalData =
				seasonStats.length > 0
					? getAggregateTotalStatsFromSeasonData(
							[...seasonStats].filter(e => e.is_team),
							aggregateFunc
					  )
					: {};

			// Sort data by level
			for (const v of Object.values(seasonData)) {
				v.sort(sortByLevelIncludingTotalRows);
			}

			// Add in season totals
			seasonData = getSeasonRowsForTable(seasonData, aggregateFunc, {
				birthDate
			});

			return {
				seasonData: seasonData,
				totalData: totalData,
				yearRange: seasonsRange
			};
		} catch (err) {
			return {};
		}
	};
};

// TODO: Make sure this can handle level
export const getCareerTableData = (player, playerType, gameType, source, level) => {
	return (dispatch, getState) => {
		try {
			const { bam_id } = player;
			const aggregateFunc = aggregateRowFromPlayerType(playerType);
			const attributes = { game_type: [gameType], source: [source], level };
			let careerStats = [];

			if (source.indexOf("brew") !== -1) {
				// Brew is a mixture of either statsapi.mlb.com or gumbo data so the source needs to change
				attributes.source = ["statsapi.mlb.com", "gumbo"];
				careerStats = selectBrewedPlayerCareerStats(getState(), bam_id, playerType, attributes);
			} else {
				careerStats = selectPlayerCareerStatsByAttriubtes(getState(), bam_id, playerType, attributes);
			}

			let seasonLabel = "Career";
			if (source === "gumbo") seasonLabel = "Total";

			const careerData = careerStats.reduce((e, v) => {
				const { level } = v;
				const levelKey = level === "MLB" ? MAJORS : MINORS;
				v["season"] = seasonLabel;
				if (e.hasOwnProperty(levelKey)) {
					e[levelKey].push(v);
				} else {
					e[levelKey] = [v];
				}
				return e;
			}, {});

			// Sort data by level
			for (const v of Object.values(careerData)) {
				v.sort(sortByLevelIncludingTotalRows);
			}

			// TODO: Add aggregate row
			if (careerData.hasOwnProperty(MINORS)) {
				// Extract other rows from minors data
				careerData[OTHER] = careerData[MINORS].filter(e => OTHER_LEVELS.includes(e.level));
				careerData[MINORS] = careerData[MINORS].filter(e => !OTHER_LEVELS.includes(e.level));

				// Aggregate Minors Data
				const minorsTotal = aggregateFunc(careerData[MINORS]);
				minorsTotal["level"] = MINORS.toUpperCase();
				minorsTotal["season"] = seasonLabel;
				careerData[MINORS].unshift(minorsTotal);

				// Aggregate Other Data
				const otherTotal = aggregateFunc(careerData[OTHER]);
				if (otherTotal) {
					otherTotal["level"] = OTHER.toUpperCase();
					otherTotal["season"] = seasonLabel;
					careerData[OTHER].unshift(otherTotal);
				}
			}
			return careerData;
		} catch (err) {
			return {};
		}
	};
};
