import { round10 } from "_react/shared/_helpers/numbers";

// Seasonal Arsenal Scores Helpers

export const formatRa9 = (input?: number | null, nullFillerText = "") => {
	return round10(input, -2) ?? nullFillerText;
};

export const format2080Grade = (input?: number | null, nullFillerText = "") => {
	return input != null ? Math.round(input).toString() : nullFillerText;
};
