import React, { ReactNode } from "react";
import { Box, Text, Tooltip, Flex, PlacementWithLogical } from "@chakra-ui/react";

import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";

import { PLACEMENT_START, PLACEMENT_END } from "_react/shared/ui/presentation/components/Section/_constants";
import { TSectionStyle } from "_react/shared/ui/presentation/components/Section/_types";
import { SectionDivider } from "_react/shared/ui/presentation/components/Section/_styles";

type SectionProps = {
	label?: string;
	placement?: typeof PLACEMENT_START | typeof PLACEMENT_END;
	tooltipIcon?: ReactNode;
	tooltipLabel?: string;
	tooltipPlacement?: PlacementWithLogical;
	children?: React.ReactNode;
	style?: TSectionStyle;
};

const Section = ({
	label,
	placement,
	tooltipLabel,
	tooltipPlacement = "top",
	tooltipIcon = <OutlineInfo />,
	children,
	style
}: SectionProps) => {
	return (
		<Box sx={style?.container}>
			{placement === PLACEMENT_START && <SectionDivider sx={style?.divider} />}
			<Flex
				alignItems="center"
				fontWeight="bold"
				fontSize="2xl"
				paddingTop={placement === PLACEMENT_START ? "8" : "0"}
				paddingBottom="4"
			>
				<Text sx={style?.label}>{label}</Text>
				{tooltipLabel && (
					<Tooltip hasArrow placement={tooltipPlacement} label={tooltipLabel} whiteSpace="pre-wrap">
						<Flex align="center" marginLeft="1" color="gray.500">
							{tooltipIcon}
						</Flex>
					</Tooltip>
				)}
			</Flex>
			{children}
			{placement === PLACEMENT_END && <SectionDivider sx={style?.divider} />}
		</Box>
	);
};

export default Section;
