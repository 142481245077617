////////////////////////////
// REDUX HELPER FUNCTIONS //
////////////////////////////
import dayjs from "dayjs";

export const convertDates = (datum, metadata) => {
	if (metadata.dateFields) {
		metadata.dateFields.forEach(dateField => {
			if (datum.hasOwnProperty(dateField) && datum[dateField]) {
				const originalDate = datum[dateField];
				datum[dateField] = dayjs(datum[dateField], "%YYYY-%MM-%DD %HH:%mm:%ss");
				if (!datum[dateField].isValid()) {
					datum[dateField] = dayjs(originalDate.replace("GMT", "EST"));
				}
			}
		});
	}
};

export function combine(original, update) {
	return Object.keys(update).reduce(
		(combined, key) => {
			if (combined.hasOwnProperty(key)) {
				combined[key] = { ...combined[key], ...update[key] };
			} else {
				combined[key] = update[key];
			}
			return combined;
		},
		{ ...original }
	);
}

export const serializeIdFields = idFields => {
	idFields.sort();
	let key = "";
	idFields.forEach((idField, idx) => {
		key += idField;
		if (idx < idFields.length - 1) {
			key += "~";
		}
	});
	return key;
};

export const serializeDatumId = (datum, idFields) => {
	idFields.sort();
	let key = "";
	idFields.forEach((idField, idx) => {
		let val = datum[idField];
		if (Array.isArray(val)) {
			val = [...val].sort();
		}
		key += val;
		if (idx < idFields.length - 1) {
			key += "~";
		}
	});
	return key;
};

export const selectFromNormalizedData = (data, idDict, idFields = null, dateFields = null) => {
	if (!idFields) {
		idFields = Object.keys(idDict);
	}
	const datumId = serializeDatumId(idDict, idFields);
	const serializedIdFields = serializeIdFields(idFields);
	if (data.hasOwnProperty(serializedIdFields)) {
		if (data[serializedIdFields].hasOwnProperty(datumId)) {
			const datum = data[serializedIdFields][datumId];
			if (dateFields) {
				convertDates(datum, { dateFields });
			}
			return datum;
		}
	}
	return null;
};

export const selectByOtherId = (normData, otherIdDict, uniqueFields, dateFields = null) => {
	const serializedIdKey = serializeIdFields(Object.keys(otherIdDict));
	const serializedUniqueKey = serializeIdFields(uniqueFields);
	const serializedDatumIdSelecting = serializeDatumId(otherIdDict, Object.keys(otherIdDict));
	if (!normData.hasOwnProperty(serializedUniqueKey)) {
		return [];
	}
	if (normData.hasOwnProperty(serializedIdKey)) {
		if (normData[serializedIdKey].hasOwnProperty(serializedDatumIdSelecting)) {
			return Object.keys(normData[serializedIdKey][serializedDatumIdSelecting]).map(dataKey => {
				const datum = normData[serializedUniqueKey][dataKey];
				if (dateFields) {
					convertDates(datum, { dateFields });
				}
				return datum;
			});
		}
	}
	return [];
};

export const checkIsFetching = (fetchingData, otherIdDict) => {
	const serializedIdKey = serializeIdFields(Object.keys(otherIdDict));
	if (fetchingData.hasOwnProperty(serializedIdKey)) {
		const serializedDatumId = serializeDatumId(otherIdDict, Object.keys(otherIdDict));
		if (fetchingData[serializedIdKey].hasOwnProperty(serializedDatumId)) {
			if (Object.keys(fetchingData[serializedIdKey][serializedDatumId]).length) {
				return true;
			}
		}
	}
	return false;
};

const initializeNormDict = metadata => {
	const normalizedData = {};
	const uniqueIdKey = serializeIdFields(metadata.uniqueFields);
	normalizedData[uniqueIdKey] = {};
	if (metadata.otherFields) {
		metadata.otherFields.forEach(idFields => {
			const otherIdKey = serializeIdFields(idFields);
			normalizedData[otherIdKey] = {};
		});
	}
	return normalizedData;
};

export function addToNormalizedData(data, datum, metadata) {
	const uniqueIdKey = serializeIdFields(metadata.uniqueFields);
	convertDates(datum, metadata);
	const uniqueField = serializeDatumId(datum, metadata.uniqueFields);
	data[uniqueIdKey][uniqueField] = datum;
	if (metadata.otherFields) {
		metadata.otherFields.forEach(otherField => {
			const idFieldKey = serializeIdFields(otherField);
			const datumIdKey = serializeDatumId(datum, otherField);

			if (data.hasOwnProperty(idFieldKey)) {
				if (!data[idFieldKey].hasOwnProperty(datumIdKey)) {
					data[idFieldKey][datumIdKey] = {};
				}
				data[idFieldKey][datumIdKey][uniqueField] = {};
			}
		});
	}
	return data;
}

export function normalize(data, metadata) {
	const normalizedData = initializeNormDict(metadata);
	data.forEach(datum => addToNormalizedData(normalizedData, datum, metadata));
	return normalizedData;
}

export function combineNormalizedData(data1, data2, metadata) {
	if (!data2) {
		return data1;
	}
	const uniqueFields = metadata.uniqueFields;
	const combinedDict = {};
	let data1Keys = Object.keys(data1);
	let data2Keys = Object.keys(data2);
	const combinedKeys = data1Keys.concat(data2Keys);
	const dupeKeys = combinedKeys.filter(key => data1Keys.indexOf(key) > -1 && data2Keys.indexOf(key) > -1);
	data1Keys = data1Keys.filter(key => dupeKeys.indexOf(key) === -1);
	data2Keys = data2Keys.filter(key => dupeKeys.indexOf(key) === -1);
	data1Keys.forEach(key => {
		combinedDict[key] = data1[key];
	});
	data2Keys.forEach(key => {
		combinedDict[key] = data2[key];
	});
	const serializedUniqueKey = serializeIdFields(uniqueFields);
	if (!data1.hasOwnProperty(serializedUniqueKey)) {
		data1[serializedUniqueKey] = {};
	}
	dupeKeys.forEach(key => {
		combinedDict[key] = {};
		if (key === serializedUniqueKey) {
			combinedDict[key] = {
				...combinedDict[key],
				...data1[key],
				...data2[key]
			};
		} else {
			// Remove the data from data2 from any 'otherField' sets in data1
			if (data2.hasOwnProperty(serializedUniqueKey) && data1.hasOwnProperty(key)) {
				Object.values(data1[key]).forEach(keySet => {
					Object.keys(data2[serializedUniqueKey]).forEach(uniqueIdKey => {
						delete keySet[uniqueIdKey];
					});
				});
			}
			Object.keys(data1[key]).forEach(setKey => {
				combinedDict[key][setKey] = { ...data1[key][setKey] };
			});
			Object.keys(data2[key]).forEach(setKey => {
				if (combinedDict[key].hasOwnProperty(setKey)) {
					combinedDict[key][setKey] = {
						...combinedDict[key][setKey],
						...data2[key][setKey]
					};
				} else {
					combinedDict[key][setKey] = { ...data2[key][setKey] };
				}
			});
		}
	});
	return combinedDict;
}

export function combineFetchingData(data1, data2DictOrArr, isArr = false) {
	// for items in data2, if item exists in data1 it toggles the fetching from true/false
	// 					   if item doesn't exist in data1 it sets fetching to true
	if (!data2DictOrArr) {
		return data1;
	}
	if (isArr && !data2DictOrArr.length) {
		return data1;
	}
	if (!isArr) {
		data2DictOrArr = [data2DictOrArr];
	}
	data1 = { ...data1 };
	data2DictOrArr.forEach(data2 => {
		const serializedUniqueKey = serializeIdFields(Object.keys(data2));
		if (!data1.hasOwnProperty(serializedUniqueKey)) {
			data1[serializedUniqueKey] = {};
		}
		if (!data2.hasOwnProperty(serializedUniqueKey)) {
			data2[serializedUniqueKey] = {};
		}
		Object.keys(data2[serializedUniqueKey]).forEach(data2Key => {
			const fetchingId = data2[serializedUniqueKey][data2Key];
			if (!data1[serializedUniqueKey].hasOwnProperty(data2Key)) {
				data1[serializedUniqueKey][data2Key] = {};
			}
			if (data1[serializedUniqueKey][data2Key].hasOwnProperty(fetchingId)) {
				delete data1[serializedUniqueKey][data2Key][fetchingId];
			} else {
				data1[serializedUniqueKey][data2Key][fetchingId] = true;
			}
		});
	});
	return data1;
}

export function combineFailedData(failedData, idDict, success) {
	// call combineFetchingData to toggle failed if necessary
	if (success === true && !checkIsFetching(failedData, idDict)) {
		return failedData;
	}
	if (success === false && checkIsFetching(failedData, idDict)) {
		return failedData;
	}
	return combineFetchingData(failedData, idDict);
}

export function removeFromNormalizedData(data, datumToRemove, metadata) {
	// data: normalized data dict
	// datumToRemove: non-normalized individual datum
	// metadata: metadata described data's normalization
	const uniqueFields = Array.isArray(metadata) ? metadata : metadata.uniqueFields;
	data = { ...data };
	const removeId = serializeDatumId(datumToRemove, uniqueFields);
	const uniqueField = serializeIdFields(uniqueFields);
	Object.keys(data).forEach(keyField => {
		if (keyField === uniqueField) {
			delete data[keyField][removeId];
		} else {
			Object.keys(data[keyField]).forEach(keyFieldId => {
				delete data[keyField][keyFieldId][removeId];
			});
		}
	});
	return data;
}
