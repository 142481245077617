import React, { useState } from "react";
import { Flex, Box, Spinner } from "@chakra-ui/react";

import { TToggleOption, TToggleButtonStyle } from "_react/shared/ui/presentation/components/ToggleButton/_types";

type TToggleButtonProps<T> = {
	toggleOptions: Array<TToggleOption<T>>;
	value?: T;
	onSelect?: (value: T) => void;
	isDisabled?: boolean;
	isLoading?: boolean;
	style?: TToggleButtonStyle;
};

const ToggleButton = <T extends string | number | boolean>({
	toggleOptions,
	value,
	onSelect,
	isDisabled,
	isLoading,
	style
}: TToggleButtonProps<T>) => {
	const [activeButton, setActiveButton] = useState<T | undefined>(value);

	return (
		<Flex sx={style?.container}>
			{toggleOptions.map((opt: TToggleOption<T>, index: number) => (
				<Box
					key={String(opt.value)}
					paddingTop="1"
					paddingBottom="1"
					paddingRight="2"
					paddingLeft="2"
					fontSize="sm"
					fontWeight={(value ?? activeButton) === opt.value ? "bold" : "normal"}
					bg={(value ?? activeButton) !== opt.value ? "gray.100" : undefined}
					borderTopLeftRadius={index === 0 ? "base" : undefined}
					borderBottomLeftRadius={index === 0 ? "base" : undefined}
					borderTopRightRadius={index === toggleOptions.length - 1 ? "base" : undefined}
					borderBottomRightRadius={index === toggleOptions.length - 1 ? "base" : undefined}
					borderTop="1px solid"
					borderRight="1px solid"
					borderBottom="1px solid"
					borderLeft={index === 0 ? "1px solid" : undefined}
					borderColor={"gray.300"}
					cursor={isDisabled || isLoading ? "not-allowed" : "pointer"}
					sx={{
						"&:hover": {
							bg: "gray.200"
						},
						...style?.button
					}}
					onClick={() => {
						if (isDisabled) return;
						if ((value ?? activeButton) !== opt.value && onSelect) onSelect(opt.value);
						if (!value) setActiveButton(opt.value);
					}}
				>
					{isLoading && (
						<>
							<Spinner size="xs" marginRight="2" />
							{"Loading "}
						</>
					)}
					{opt.icon}
					{opt.label}
				</Box>
			))}
		</Flex>
	);
};

export default ToggleButton;
